import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-export-calendar-dialog',
  templateUrl: './export-calendar-dialog.component.html',
  styleUrls: ['./export-calendar-dialog.component.scss']
})
export class ExportCalendarDialogComponent implements OnInit, OnDestroy {

  public assets: any = [];
  public assetsSelected: any = [];
  public machineId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<ExportCalendarDialogComponent>,
    public apiService: ApiService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public clonerService: ClonerService,
    public cacheService: CacheService,
  ) {

  }

  allSelected: boolean = false;

  toggleAll() {
    if (this.assetsSelected.length === this.assets.length) {
      this.assetsSelected = [];
    } else if (this.assetsSelected.length >= 0) {
      this.assetsSelected = [];
      this.assets.forEach((element: any) => this.assetsSelected.push(element.machineId));
    }
    this.checkAllSelected();
  };

  checkAllSelected() {
    this.allSelected = this.assetsSelected.length === this.assets.length;
  }

  exists(item: any, list: any) {
    return list.filter((x: any) => x == item).length > 0;
  };

  toggle(item: any, list: any) {

    let idx = list.findIndex((x: any) => x == item);

    if (idx > -1) list.splice(idx, 1);
    else list.push(item);

    this.checkAllSelected();
  };

  closeDialog() {
    this.dialogRef.close(this.assets.filter(asset => this.assetsSelected.includes(asset.machineId)));
  }

  ngOnInit(): void {
    this.machineId = this.dialog.machineId;
    let assets = this.cacheService.get("assets") != null ? this.cacheService.get("assets") : [];
    this.assets = assets.filter(x => x.machineId != this.machineId);
  }

  ngOnDestroy() {
  }

}
