import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import { SVG } from '@svgdotjs/svg.js';
import * as moment from 'moment';
import { FiltersDialogComponent } from 'src/app/components/filters-dialog/filters-dialog.component';
import { CustomIntervalDialogComponent } from 'src/app/components/interval-selector/custom-interval-dialog/custom-interval-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'cloth-monitoring',
  templateUrl: './cloth-monitoring.component.html',
  styleUrls: ['./cloth-monitoring.component.scss']
})
export class ClothMonitoringComponent implements OnInit, OnDestroy {

  test = false;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public clothsInfo: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public pollingTime: any;
  public pollingCloths: any;

  public interval: any;
  public intervalConfig: any;

  public cloths: any;

  public filterButtons: any;
  public sliderConf: any;
  public clothsFilters: any;

  public draw: any;

  @ViewChild('clothSvg')
  clothSvg: ElementRef | any;
  @ViewChild('clothList')
  clothList: ElementRef | any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getClothsPolling, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public router: Router,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.clothsInfo = this.appConfig.machineMonitoring.clothsInfo;

    this.breadcrumb = ['MACHINE_MONITORING.TITLE', 'CLOTH_MONITORING.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs('machineMonitoring');

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    // this.pollingTime = 5000;
    this.pollingTime = this.appConfig.machineMonitoring.polling;
    this.pollingCloths = Subscription;

    this.interval = this.intervalService.getIntervalById('today');

    this.intervalConfig = {
      list: this.intervalService.getDefaultIntervals(2),
      selected: this.interval
    };

    this.cloths = {
      list: [],
      filtered: [],
      selected: null,
      pageOptions: [25, 50, 100],
      pageSize: 50,
      search: "",
      sapColors: {}
    };

    this.clothsFilters = {
      search: null,
      cyclesMax: null,
      cyclesMin: null,
      sapCodes: []
    };

    // this.clothsData = new MatTableDataSource<any[]>(this.cloths.list);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'machineMonitoring');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) {

    try {
      this.pollingCloths.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    if (interval != null) {

      if (interval.id == 'custom') {
        // console.log(interval);

        let filtersDialog = this.dialog.open(CustomIntervalDialogComponent, {
          panelClass: 'ff-dialog',
          disableClose: true,
          data: {
            // singleDate: true,
            title: this.translate.instant('INTERVAL.CUSTOM')
          },
        });

        filtersDialog.afterClosed().subscribe((result: any) => {
          // console.log('afterClosed', result);
          if (result != null && result != '') {

            interval.start = JSON.parse(JSON.stringify(result.interval.value.start));
            interval.end = moment(result.interval.value.end).endOf("day");
            if (moment().diff(interval.end) < 0) interval.end = moment();
            interval.end = JSON.parse(JSON.stringify(interval.end));
            interval.startF = this.intervalService.strFormat(interval, interval.start) + ' - ' + this.intervalService.strFormat(interval, interval.end);

            this.interval = interval;
            this.pageState.next(4);
            this.getCloths(this, 0);

          }
        });

      } else if (!interval.enabledPolling) {

        this.interval = interval;

        this.pageState.next(4);
        this.getCloths(this, 0);

      } else {

        this.interval = interval;

        this.pageState.next(4);
        this.getClothsPolling(this);
      }
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // cloths

  // polling
  getClothsPolling(_this: any) {
    try {

      if (_this.pollingTime > 0) {
        _this.pollingCloths = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getCloths(_this, count);
        });
      } else {
        _this.getCloths(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get cloths
  getCloths(_this: any, count?: any) {
    try {

      // console.log('getCloths', _this.interval)

      let payload = { filters: {} } //_this.internalDataService.buildMachinePayload(machine);
      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone,
      };

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      if (_this.machine.clothNumber != null) {
        query.clothNumber = _this.machine.clothNumber;
      }

      _this.apiService.sendPostRequest('/apif/remote-monitoring/cloths/' + _this.machineId, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingCloths))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.parseCloths(_this, data.body, count);

            setTimeout(() => {
              _this.filterCloths();
            }, 100);

            if (count == 0) {
              _this.filterButtons = this.buildFilterButtons(_this);

              setTimeout(() => {
                // draw svg cloths
                _this.drawClothsSvg(_this);
              }, 100);

              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseCloths(_this: any, data: any, count: number) {
    // init clothConfig object
    _this.machine.clothConfig = {
      max: 0,
      colorFail: null,
      colorOver: null,
      colorUnder: null,
      thresholds: [] // {min: 0, max: 1000, color: "#00cd00"}
    };

    _this.machine.clothThresholdsArray = _this.machine.clothThresholds.split(';');
    _this.machine.clothConfig.max = parseFloat(_this.machine.clothThresholdsArray[_this.machine.clothThresholdsArray.length - 1]);
    _this.machine.clothConfig.colorFail = _this.appConfig.machineMonitoring.clothColors[0];
    _this.machine.clothConfig.colorUnder = _this.appConfig.machineMonitoring.clothColors[1];
    _this.machine.clothConfig.colorOver = _this.appConfig.machineMonitoring.clothColors[_this.appConfig.machineMonitoring.clothColors.length - 1];

    let min = 0;
    _this.machine.clothThresholdsArray.forEach((thr: any, i: number) => {
      let threshold = {
        min: min,
        max: parseFloat(thr),
        color: _this.machine.clothConfig.colorFail
      };
      if ((i + 1) < _this.appConfig.machineMonitoring.clothColors.length) {
        threshold.color = _this.appConfig.machineMonitoring.clothColors[i + 1];
      } else {
        threshold.color = _this.machine.clothConfig.colorOver;
      }
      min = threshold.max;
      _this.machine.clothConfig.thresholds.push(threshold);
    });

    // first load
    if (count == 0) {
      let maxNumCycles = _this.machine.clothConfig.max;
      // cloth list from data
      if (data != null && data.length > 0) {
        data.forEach((element: any) => {
          element.selected = false;
          element.idP = element.id.split('Cloth')[1];

          if (this.test) element.sapCode = 'sc_' + parseInt(' ' + Math.random() * (10 - 0) + 0);
          element.sapCode = element.sapCode.toString();

          if (this.test) element.nCycles = Math.random() * ((_this.machine.clothConfig.max + 500) - 0) + 0; // TEST

          element.nCycles = parseInt(element.nCycles);
          element.color = this.getClothColor(element);

          if (element.nCycles > maxNumCycles) maxNumCycles = element.nCycles;
        });
        _this.cloths.list = data;
        _this.cloths.filtered = data;
      } else {
        _this.cloths.list = [];
        _this.cloths.filtered = [];
      }

      // init slider
      _this.sliderConf = {
        value: 0,
        highValue: maxNumCycles,
        options: {
          floor: 0,
          ceil: maxNumCycles,
          ticksArray: [_this.machine.clothConfig.max],
          showTicksValues: true
        },
        userChangeEnd: function () {
          _this.clothsFilters.cyclesMin = _this.sliderConf.value;
          _this.clothsFilters.cyclesMax = _this.sliderConf.highValue;
          _this.filterCloths();
        }
      };

    }
    else {
      let maxNumCycles = _this.machine.clothConfig.max;
      // update each cloth
      data.forEach((element: any) => {
        _this.cloths.list.forEach((element2: any) => {
          if (element.id === element2.id) {

            if (this.test) element2.nCycles = Math.random() * ((_this.machine.clothConfig.max + 500) - 0) + 0; // TEST

            element2.nCycles = parseInt(element2.nCycles);
            element2.color = this.getClothColor(element2);

            if (element.nCycles > maxNumCycles) maxNumCycles = element.nCycles;
          }
        });
      });

      _this.sliderConf.options.ceil = maxNumCycles;
    }
  }

  drawClothsSvg(_this: any) {
    _this.clothSvg.nativeElement.innerHTML = '';

    // create svg
    let clothSvgW = _this.clothSvg.nativeElement.offsetWidth;
    let clothSvgH = _this.clothSvg.nativeElement.offsetHeight;
    _this.draw = SVG().addTo('#clothSvg').size(clothSvgW, clothSvgH);

    // load svg image
    let sources40F = _this.appInfo.sources40F != null ? _this.appInfo.sources40F : 'assets/images/';
    let imagePath = sources40F + 'cloths-machine.svg';

    fetch(imagePath)
      .then(res => res.text())
      .then(svg => {
        // create svg with image
        let svgContainer = _this.draw.svg(svg);
        svgContainer.viewbox(0, 0, clothSvgW, clothSvgH);
        svgContainer.size(clothSvgW, clothSvgH);

        _this.updateClothsSvg(_this, 0);
      });
  }

  updateClothsSvg(_this: any, count?: number) {
    if (_this.draw) {
      // console.log('updateClothsSvg')
      let clothSvgW = _this.clothSvg.nativeElement.offsetWidth;
      let clothSvgH = _this.clothSvg.nativeElement.offsetHeight;

      // find cloth container
      let svgContentList = _this.draw.children();
      if (svgContentList.length > 0) {
        // set svg size on container card
        let svgContent = svgContentList[0];
        svgContent.size(clothSvgW, clothSvgH);

        let svgContentList2 = svgContent.children();
        if (svgContentList2.length > 1) {

          let clothsWidth: any;
          let maxHeight: null;
          svgContentList2.forEach((element: any) => {
            if (element.node.id == 'clothsContainer') {
              clothsWidth = element.width() / _this.cloths.list.length;
              maxHeight = element.height();
            }
          });

          svgContentList2.forEach((element: any) => {
            if (element.node.id == 'clothsContainer') {
              maxHeight = element.height();
            }

            // remove old cloths
            if (element.node.id == '') element.remove();

            if (count == 0) {
              // console.log('count 0');
              if (element.node.id == 'clothsContainer') {
                // update cloths 
                _this.cloths.list.forEach((clt: any, i: number) => {

                  let cW = clothsWidth;
                  let cH = _this.getClothHeight(clt, maxHeight);

                  let cltRect = SVG().rect(cW, cH);

                  cltRect.id(clt.id);

                  // cltRect.move(element.x() + (cW * i), element.y());
                  cltRect.move(element.x() + (cW * i), (element.y() + maxHeight - cH));
                  cltRect.attr({
                    'fill': clt.color,
                    'cursor': 'pointer'
                  });
                  cltRect.click(function () {
                    _this.selectCloth(clt);
                  })

                  element.parent().add(cltRect)
                });
              }
            }
            else {
              // console.log('count NOT0');
              if (element.node.id.startsWith('Cloth')) {
                let ix = _this.cloths.list.findIndex((x: any) => {
                  return x.id === element.node.id;
                });
                if (ix != -1) {
                  let clt = _this.cloths.list[ix];
                  let cW = clothsWidth;
                  let cH = _this.getClothHeight(clt, maxHeight);

                  let cltRect = SVG(element);
                  cltRect.attr({
                    'width': cW,
                    'height': cH,
                    'cursor': 'pointer',
                    'stroke': 'none',
                    'stroke-width': '0'
                  });

                  if (_this.cloths.filtered.findIndex((x: any) => { return x.id === clt.id; }) != -1) {
                    if (clt.selected) {
                      cltRect.attr({
                        'stroke': 'black',
                        'stroke-width': '3px'
                      })
                      cltRect.front();
                    }
                    else {
                      cltRect.attr({
                        'fill': clt.color,
                        'stroke': 'none',
                        'stroke-width': '0'
                      })
                    }
                  }
                  else {
                    cltRect.attr({
                      'fill': '#ffffff4d',
                      'stroke': 'none',
                      'stroke-width': '0'
                    })
                  }

                }
              }
            }

          });
        }
      }
      else {
        // TODO, remove svg and show error
      }
    }
  }

  selectCloth(clt: any) {
    // reset selected
    this.cloths.list.forEach((element: any) => {
      element.selected = false;
    });
    // get all cloths cards
    let clothListElem = this.clothList.nativeElement.childNodes;
    // find selected cloth card
    clothListElem.forEach((element: any) => {
      if (element.id == clt.id) {
        clt.selected = true;
        element.scrollIntoView({ behavior: 'smooth', block: "end", inline: "nearest" });
      }
    });

    this.updateClothsSvg(this);
  }

  getClothColor(clt: any) {
    if (this.machine.hasOwnProperty('clothConfig')) {
      // console.log('clothConfig', clt)

      try {
        if (this.appConfig.machineMonitoring.sapCodeColors
          && this.appConfig.machineMonitoring.sapCodeColors.length > 0) {
          // couple sapCode-color NOT already assigned
          if (this.cloths.sapColors.hasOwnProperty(clt.sapCode)) {
            return this.cloths.sapColors[clt.sapCode];
          }
          // couple sapCode-color already assigned
          else {
            for (let color of this.appConfig.machineMonitoring.sapCodeColors) {
              let assigned = false;
              for (let sapcode in this.cloths.sapColors) {
                if (color == this.cloths.sapColors[sapcode]) {
                  assigned = true;
                  break;
                }
              }
              if (!assigned) {
                this.cloths.sapColors[clt.sapCode] = color;
                return color;
              }
            }
          }
        }

        // let color = this.machine.clothConfig.colorFail;

        // for (let i = 0; i < this.machine.clothConfig.thresholds.length; i++) {
        //   let threshold = this.machine.clothConfig.thresholds[i];
        //   if (clt.nCycles < threshold.min) {
        //     color = this.machine.clothConfig.colorUnder;
        //     break;
        //   }
        //   else if (clt.nCycles >= threshold.min
        //     // && clt.nCycles < (this.machine.clothConfig.max * threshold.max / 100)) {
        //     && clt.nCycles < threshold.max) {
        //     color = threshold.color;
        //     break;
        //   }
        //   else if (clt.nCycles >= threshold.max
        //     && i == this.machine.clothConfig.thresholds.length - 1) {
        //     color = this.machine.clothConfig.colorOver;
        //     break;
        //   }
        // };
        // return color;

      } catch (error) {
        console.log('Failed getClothColor: ' + error)
      }
    }
    return "#999999";
  }

  getClothHeight(clt: any, maxHeight: any) {
    let h = 1;
    if (this.machine.hasOwnProperty('clothConfig')
      && this.machine.clothConfig.hasOwnProperty('max')
      && this.machine.clothConfig.max != null) {
      if (clt.nCycles > this.machine.clothConfig.max)
        h = maxHeight;
      else
        h = maxHeight * clt.nCycles / this.machine.clothConfig.max;
      if (h == 0) h = 1;
    }
    return h;
  }

  buildFilterButtons(_this: any) {
    let buttons: any[] = [];

    for (let attribute of _this.appConfig.machineMonitoring.clothFilters) {
      if (attribute.filtrable) {
        attribute = JSON.parse(JSON.stringify(attribute));
        let options = [];
        for (let item of _this.cloths.list) {
          if (item.hasOwnProperty(attribute.variable) && item[attribute.variable] != null) {
            // options.push((item[attribute.variable]).toString());
            if (!attribute.type) {
              let x = (item[attribute.variable]).toString();
              options.push({
                id: x,
                label: this.translate.instant(x),
                selected: true,
                color: null
              });
            }
          }
        }

        // remove duplicates
        let optionsClear: any = [];
        let counter = 0;
        for (let opt of options) {
          if (attribute.variable == 'sapCode') {
            if (optionsClear.findIndex((x: any) => { return x.id == opt.id }) == -1) {
              opt.color = this.appConfig.machineMonitoring.sapCodeColors[counter];
              optionsClear.push(opt);
              counter += 1;
            }
          } else {
            if (optionsClear.findIndex((x: any) => { return x.id == opt.id }) == -1) optionsClear.push(opt);
          }
        }
        attribute.options = optionsClear;
        buttons.push(attribute);
      }

      if (attribute.variable == 'sapCode') {
        this.clothsFilters.sapCodes = attribute.options;
      }
    }

    return buttons;
  }

  openFilterDialog(button: any) {
    // console.log(button)
    let filtersDialog = this.dialog.open(FiltersDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(button.label),
        variable: button.variable,
        options: this.clonerService.deepClone(button.options)
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') {
        // console.log('afterClosed', result)
        result = JSON.parse(JSON.stringify(result));

        button.options = result.options;
        this.clothsFilters.sapCodes = result.options;
        this.filterCloths();

      }
    });
  }

  searchCloths(searchEvent?: any) {
    if (searchEvent != null && searchEvent.target != null && searchEvent.target.value) {
      this.clothsFilters.search = searchEvent.target.value;
    } else {
      this.clothsFilters.search = null;
    }
    this.filterCloths();
  }

  filterCloths() {
    // console.log('filterCloths')

    // clothsFilters: any = {
    //   search: null,
    //   cyclesMax: null,
    //   cyclesMin: null,
    //   sapCodes: []
    // };

    this.cloths.filtered = this.cloths.list.filter((data: any) => {
      let show = false;

      let filterVariables = this.appConfig.machineMonitoring.filterVariables != null ? this.appConfig.machineMonitoring.filterVariablesCloth : ["id", "sapCode", "nCycles"];
      // filter on search input
      if (!this.clothsFilters.search
        || filterVariables.some((x: any) => {
          if (typeof data[x] == 'string') return data[x].toLowerCase().includes(this.clothsFilters.search.toLowerCase());
          if (typeof data[x] == 'number') return data[x].toString().includes(this.clothsFilters.search);
        })
      ) {
        // filter on slider (min-max)
        if ((this.machine.clothConfig.max == this.clothsFilters.cyclesMax && data.nCycles >= this.clothsFilters.cyclesMin)
          || (!this.clothsFilters.cyclesMax && data.nCycles >= this.clothsFilters.cyclesMin)
          || (data.nCycles >= this.clothsFilters.cyclesMin && data.nCycles <= this.clothsFilters.cyclesMax)) {
          // filter on sapCode
          if (this.clothsFilters.sapCodes.length > 0
            && data.sapCode != null
            && this.clothsFilters.sapCodes.some((x: any) => { return x.selected && data.sapCode.toLowerCase() === x.id.toLowerCase(); })
          ) {
            return true;
          }
        }
      }
      return show;
    });

    this.updateClothsSvg(this);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  navigateToMachineSettings() {
    let url: any = [this.machineId, 'machine-settings'].map((x: any) => "/" + x).join("");
    this.router.navigateByUrl(url);
  };

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pageState.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.pollingCloths.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // RESIZE
  timeOutFunctionId: any;

  @HostListener('window:resize', ['$event'])
  onResize() {
    // this.drawClothsSvg(this);

    clearTimeout(this.timeOutFunctionId);
    this.timeOutFunctionId = setTimeout(() => { this.drawClothsSvg(this) }, 100);
  }

}