import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FfLoggingService {

  constructor() { }

  log(obj: any, color?: any) {

    try {
      console.log(obj);
    } catch (error) {
      console.log(error);
    }
  }
}
