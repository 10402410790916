import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

declare var Plotly: any;

@Component({
  selector: 'app-annealer-dialog',
  templateUrl: './annealer-dialog.component.html',
  styleUrls: ['./annealer-dialog.component.scss']
})
export class AnnealerDialogComponent implements OnInit {
  public monitoringData: any;
  public annealerData: any;
  public kFactor: any;
  public plotState: any;
  public monitoringDataSub: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public apiService: ApiService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public internalDataService: InternalDataService,
    public clonerService: ClonerService,
  ) {

    this.plotState = 0;

    try {
      this.annealerData = this.dialog.machine.profile.annealerCurves?.annealerData ?? [];
      this.monitoringData = this.dialog.monitoringData;
      this.kFactor = this.monitoringData?.[this.dialog.machine.profile?.annealerCurves?.kFactorDatapoint ?? "RBDAnnealerSet.kFactor"];
    } catch (error) {
      console.log(error);
    }

    this.monitoringDataSub = this.internalDataService.monitoringData.subscribe(value => {
      // console.log(value);
      if (value != null) this.monitoringData = this.clonerService.deepClone(value);
    });

  }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    if (this.dialog.widget?.getDataByRequest && this.dialog.widget?.endpointUrl != null) {
      try {

        var url = this.dialog.widget.endpointUrl + this.dialog.machine.machineId;

        this.apiService.sendGetRequest(url).pipe(
          retryWhen(this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {
            if (error.error instanceof ErrorEvent) {
              console.log(`Error: ${error.error.message}`);
            } else {
              console.log(`Error: ${error.message}`);
            }

            let testError = {
              type: 0,
              status: error.status,
              message: error.statusText
            };

            return throwError(
              'Something bad happened; please try again later.');
          }))
          .subscribe(
            (data: any) => {

              let newData = data.body
              //init params
              let params = {
                kFactor: this.kFactor
              };

              this.plotState = 1;

              let plotData: any = this.setWidgetCustomData(newData, params)

              let plotConfig: any = {
                responsive: true,
                displaylogo: false
              };

              try {
                if (plotData.data.every((x: any) => x.x.length == 0)) {
                  this.plotState = 2;
                } else {
                  setTimeout(() => {
                    try {
                      Plotly.react("annealer", plotData.data, plotData.layout, plotConfig);
                    } catch (error) {
                      console.log(error);
                    }
                  }, 50);
                }
              } catch (error) {
                console.log(error);
              }
            }
          );
      } catch (error) {
        console.log(error);
      }
    } else {
      let newData = []
      //init params
      let params = {
        kFactor: this.kFactor
      };

      this.plotState = 1;

      let plotData: any = this.setWidgetCustomData(newData, params)

      let plotConfig: any = {
        responsive: true,
        displaylogo: false
      };

      try {
        if (plotData.data.every((x: any) => x.x.length == 0)) {
          this.plotState = 2;
        } else {
          setTimeout(() => {
            try {
              Plotly.react("annealer", plotData.data, plotData.layout, plotConfig);
            } catch (error) {
              console.log(error);
            }
          }, 50);
        }
      } catch (error) {
        console.log(error);
      }
    }

  }

  setWidgetCustomData(data, params) {
    // console.log(widget);
    // console.log(data);
    // console.log(request);
    // console.log(params);

    var values = {};
    var plotData = {};
    var plotLayout = {};
    var updatemenus = [];
    var widgetParams = {};

    var oddTraces = [];
    var evenTraces = [];

    var i = 0;
    var j = 0;
    var traces = [];
    var trace = {};

    var timestamps = [];

    var bottom = null;
    var top = null;

    var maxRecipes = null;

    var valueH = null;
    var valueHMS = null;

    try {

      if (data == null || data.length == 0) {
        data = { x: [] }
      }

      traces = [];

      if (params.kFactor != null) {
        traces.push({
          x: data.x,
          y: data.x.map(x => params.kFactor * Math.sqrt(x)),
          name: 'Calc',
          line: {
            color: "#e1e1e1"
          }
        });
      }

      traces.push({
        x: data.x,
        y: data.x.map((x, idx) => params.kFactor * Math.sqrt(x) * data.yUp[idx] / 100),
        name: 'Up'
      });

      traces.push({
        x: data.x,
        y: data.x.map((x, idx) => params.kFactor * Math.sqrt(x) * data.yDown[idx] / 100),
        name: 'Down'
      });

      try {
        if (traces.every(x => x.x.length == 0)) {
          // TODO
        }
      } catch (error) {
        console.log(error);
      }

      plotLayout = {
        // font: {
        //   family: 'Nunito, sans-serif',
        //   size: 20,
        // },
        showlegend: true,
        margin: {
          t: 30,
          r: 30,
          b: 30,
          l: 60,
          pad: 10
        },
        legend: {
          orientation: 'h',
          traceorder: 'normal',
          x: 0,
          y: -0.2
        },
        yaxis: {
          automargin: true,
          showgrid: false,
          title: this.translate.instant("REMOTE_MONITORING.ANNEALER_VOLTS") + " [V]"
        },
        xaxis: {
          showgrid: false,
          title: this.translate.instant("REMOTE_MONITORING.SPEED") + " [m/s]"
        }
      };

      widgetParams = {
        data: traces,
        layout: plotLayout
      };

      return widgetParams
    } catch (error) {
      console.log(error);
      // TODO
    }

  }

  onDestroy() {
    try { this.monitoringDataSub.unsubscribe() } catch (error) { }
  }

}
