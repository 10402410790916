import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'app-edit-variable-dialog',
  templateUrl: './edit-variable-dialog.component.html',
  styleUrls: ['./edit-variable-dialog.component.scss']
})
export class EditVariableDialogComponent implements OnInit, OnDestroy {

  public currentParameter: any = {};
  public searchAspect: any = '';
  public searchVariable: any = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<EditVariableDialogComponent>,
    public clonerService: ClonerService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public internalDataService: InternalDataService,
  ) {

    this.currentParameter.filteredList = this.clonerService.deepClone(this.dialog.currentParameter.list);

    try {
      this.currentParameter.aspect = this.dialog.currentParameter.value.split(".")[0];
    } catch (error) { }

    try {
      this.currentParameter.variable = this.dialog.currentParameter.value.split(".")[1];
    } catch (error) { }

    if (this.dialog.addMachineSelection) {

      try {
        this.currentParameter.machine = this.dialog.currentParameter.value.split(".")[0];
        this.currentParameter.filteredList = this.filterService.removeKeysWithCustomRule(this.dialog.currentParameter.list, (x: any) => x[0] == this.currentParameter.machine);
      } catch (error) { }
    }
  }

  updateAspectSearch(searchAspect: any) {

    if (searchAspect == '' || searchAspect == null || searchAspect == undefined) {

      this.currentParameter.filteredList = !this.dialog.addMachineSelection ?
        this.clonerService.deepClone(this.dialog.currentParameter.list) :
        this.filterService.removeKeysWithCustomRule(this.dialog.currentParameter.list, (x: any) => x[0] == this.currentParameter.machine);
    } else {

      let newObj: any = {};

      for (const [a, v] of Object.entries(!this.dialog.addMachineSelection ?
        this.clonerService.deepClone(this.dialog.currentParameter.list) :
        this.filterService.removeKeysWithCustomRule(this.dialog.currentParameter.list, (x: any) => x[0] == this.currentParameter.machine))) {
        try {
          if (a.toLowerCase().includes(searchAspect.toLowerCase())) {
            newObj[a] = this.clonerService.deepClone(v);
          }

        } catch (error) {
          console.log(error);
        }
      }

      this.currentParameter.filteredList = this.clonerService.deepClone(newObj);

    }

  };

  updateVariableSearch(searchVariable: any) {

    if (this.currentParameter.aspect == null) return;

    if (searchVariable == '' || searchVariable == null || searchVariable == undefined) {

      this.currentParameter.filteredList[this.currentParameter.aspect] = this.dialog.currentParameter.list[this.currentParameter.aspect];

    } else {

      this.currentParameter.filteredList[this.currentParameter.aspect] = this.dialog.currentParameter.list[this.currentParameter.aspect].filter((x: any) => {
        return this.internalDataService.parseDatapointLabel(this.currentParameter.aspect, x, this.dialog.machineId, true).toLowerCase().includes(searchVariable.toLowerCase());
        // return (this.translate.instant("DATAPOINTS." + this.currentParameter.aspect + '.' + x) == this.currentParameter.aspect + '.' + x) ?
        //   x.toLowerCase().includes(searchVariable.toLowerCase()) :
        //   (this.translate.instant("DATAPOINTS." + this.currentParameter.aspect + '.' + x)).toLowerCase().includes(searchVariable.toLowerCase());
      });

      if (this.currentParameter.filteredList[this.currentParameter.aspect].length == 0) this.currentParameter.variable = null;
    }

  };

  resetAll() {

    this.currentParameter.filteredList = this.filterService.removeKeysWithCustomRule(this.dialog.currentParameter.list, (x: any) => x[0] == this.currentParameter.machine);

    this.searchAspect = '';
    this.currentParameter.aspect = null;

    this.searchVariable = '';
    this.currentParameter.variable = null;
  }

  resetVariable() {
    this.searchVariable = '';
    this.currentParameter.variable = null;
  };

  closeDialog() {
    this.dialogRef.close(this.currentParameter);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

}
