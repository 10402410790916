import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'ff-multiple-tables',
  templateUrl: './multiple-tables.component.html',
  styleUrls: ['./multiple-tables.component.scss']
})
export class MultipleTablesComponent implements OnInit {

  @Input() widget: any;
  @Output() clicked = new EventEmitter<any>();

  public tabs: any = [];
  public mobileListener: Subscription;
  public isSmThanTablet: boolean;
  public isMobile: boolean;

  constructor(
    public translate: FfTranslateService,
    public clonerService: ClonerService,
    public mobile: MobileService,
  ) {

    this.mobileListener = this.mobile.mobileListener.subscribe((value) => {

      this.isMobile = value.isMobile;
      this.isSmThanTablet = value.isSmThanTablet

      this.buildTables();

    })
  }

  @HostBinding('style.min-height') minHeight: any = '600px';

  buildTables() {
    // console.log('buildTables', this.widget);

    this.tabs = this.clonerService.deepClone(this.widget?.config?.tabs ?? this.tabs);

    if (this.isMobile) {
      this.tabs = this.tabs.filter((tab: any) => !tab.hideMobile);
      try {
        this.tabs[0].selected = true;
      } catch (error) { }
    }

    this.minHeight = this.widget?.config?.minHeight ?? this.minHeight;

    // let widgetData = this.clonerService.deepClone(this.widget?.data ?? {});

    // this.tabs?.forEach(tab => {

    //   let tabConfig: any = tab?.config ?? {};

    //   let tableInfos: any = tabConfig?.tableInfos ?? [];

    //   let rowsVariable: any = tabConfig?.rowsVariable ?? 'table';

    //   let table: any = {
    //     rows: widgetData?.[rowsVariable] ?? [],
    //     rowsFiltered: widgetData?.[rowsVariable] ?? [],
    //     sort: { sortVariables: tableInfos?.filter(info => info.orderBy) }
    //   };

    //   table = Object.assign(table, tabConfig)
    //   // if (Object.keys(tabConfig?.sort ?? {})?.length > 0) table.sort = Object.assign(table.sort, tabConfig?.sort);
    //   // if (Object.keys(tabConfig?.export ?? {})?.length > 0) table.export = tabConfig?.export;

    //   // if (tabConfig?.additionalButtons?.length > 0) table.additionalButtons = tabConfig?.additionalButtons;

    //   tab.table = this.clonerService.deepClone(table);
    // });

  }

  // EVENT EMITTERS
  onButtonClick(item) {
    this.clicked.emit(item)
  }

  ngOnInit(): void {
    this.buildTables();
  }

  ngOnChanges(): void {
    this.buildTables();
  }

}
