export class Stop {
	public cause: string;
	public trafila: string;
	public startDate: string;
    public endDate: string;
	public startTime: string;
    public endTime: string;

	constructor(cause: string, trafila: string, startDate: string, endDate: string, startTime: string, endTime: string) {
		this.cause = cause;
		this.trafila = trafila;
		this.startDate = startDate;
		this.endDate = endDate;
		this.startTime = startTime;
		this.endTime = endTime;
	}
}