import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-add-downtime-dialog',
  templateUrl: './add-downtime-dialog.component.html',
  styleUrls: ['./add-downtime-dialog.component.scss']
})
export class AddDowntimeDialogComponent implements OnInit, OnDestroy {

  public downtimeEvent: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<AddDowntimeDialogComponent>,
    public apiService: ApiService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public clonerService: ClonerService,
  ) {

    let filterServiceP = this.filterService;

    setTimeout(() => {
      this.downtimeEvent = {
        from: 0,
        to: 24 * 60 * 4,
        invalidity: 3,
        slider: {
          show: true,
          max: 24 * 3600,
          min: 0,
          options: {
            floor: 0,
            ceil: 24 * 3600,
            step: 1800,
            translate: function (index: any) {
              try {
                return filterServiceP.parseTime24H(filterServiceP, index, 's', 'HH:mm');
              } catch (error) {
                return index;
              }
            },
          }
        }
      };
    }, 250);
  }

  closeDialog() {
    this.dialogRef.close(this.downtimeEvent);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

}
