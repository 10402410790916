<div mat-dialog-title fxLayout="row" fxLayoutAlign="start center" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography">
  <div *ngFor="let elem of dialog.synopticList" fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 0;"
    class="clickable" [mat-dialog-close]="elem.id">
    <mat-icon *ngIf="elem.id == dialog.currentSynoptic" svgIcon="radio_button" class="icon left radio_button">
    </mat-icon>
    <mat-icon *ngIf="elem.id != dialog.currentSynoptic" svgIcon="radio_button_disabled"
      class="icon left radio_button_disabled"></mat-icon>
    <span style="margin-left: 12px;">{{elem.label}}</span>
  </div>
</mat-dialog-content>