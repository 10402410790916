<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <div *ngIf="showCalendarSwitch" fxLayout="row" fxLayoutAlign="center center" class="switch-container"
      [style.margin-right]="shiftTemplates.length > 0 && isAllowedUserWrite ? '8px' : '0'">
      <span style="margin: 0 8px;">
        {{ "CALENDAR.ENABLE_CALENDAR_PER_ASSET" | translate }}
      </span>
      <ui-switch [checked]="isEnabledCalendarAsset" (change)="toggleEnableCalendarAsset($event)" checkedLabel="ON"
        uncheckedLabel="OFF">
      </ui-switch>
    </div>

    <button mat-button (click)="openExportCalendar()" class="filter-button"
      *ngIf="shiftTemplates.length > 0 && isAllowedUserWrite && machine.profile?.flags?.exportCalendar" fxLayout="row"
      fxLayoutAlign="center center">
      <span>{{'CALENDAR.EXPORT_CALENDAR' | translate}}</span>
      <mat-icon class="icon right">import_export</mat-icon>
    </button>

    <button mat-button (click)="copyWeek()" class="filter-button"
      *ngIf="shiftTemplates.length > 0 && isAllowedUserWrite" fxLayout="row" fxLayoutAlign="center center">
      <span>{{'CALENDAR.COPY_WEEK' | translate}}</span>
      <mat-icon class="icon right">content_copy</mat-icon>
    </button>

  </div>

  <!-- Assign buttons toolbar -->
  <div *ngIf="!isMobile" fxLayout="row" fxLayoutAlign="start center" class="w100 transition-01"
    [ngStyle]="{'max-height': (selectedDays.length > 0 && isAllowedUserWrite) ? '800px':'0', 'margin': (selectedDays.length > 0 && isAllowedUserWrite) ? '12px 0':'0' }"
    style="overflow: hidden; height: auto;">
    <!-- *ngIf="selectedDays.length > 0" -->

    <button mat-button *ngIf="shiftTemplates.length == 0" class="md-orange circular-icon-button left not-clickable"
      style="margin-right: 12px;" #tooltip="matTooltip" matTooltip="{{ 'CALENDAR.NO_SHIFT_TEMPLATE' | translate }}"
      matTooltipPosition="below">
      <mat-icon class="icon">warning</mat-icon>
    </button>

    <button mat-button (click)="openShiftTemplate(selectedDays)" class="filter-button"
      [disabled]="shiftTemplates.length == 0" fxLayout="row" fxLayoutAlign="center center">
      <span>{{'CALENDAR.SHIFT_TEMPLATE' | translate}}</span>
      <mat-icon class="icon right">playlist_add</mat-icon>
    </button>

    <button mat-button (click)="openDowntimePlanning(selectedDays)" class="filter-button" fxLayout="row"
      fxLayoutAlign="center center">
      <span>{{'CALENDAR.EVENT_CREATION' | translate}}</span>
      <mat-icon class="icon right">cancel</mat-icon>
    </button>

    <button *ngIf="operatorsList?.length > 0" mat-button (click)="openOperatorAssign(selectedDays)"
      class="filter-button" fxLayout="row" fxLayoutAlign="center center"
      [disabled]="!pageStateReady || shiftTemplates.length == 0 || operatorsList.length == 0 || disableOperatorsList">
      <span>{{'CALENDAR.OPERATOR_ID' | translate}}</span>
      <mat-icon class="icon right">people</mat-icon>
    </button>

    <span fxFlex></span>

    <button mat-button (click)="unselectDays()" class="md-gray filter-button-no-background" fxLayout="row"
      fxLayoutAlign="center center">
      <span>{{'CALENDAR.UNSELECT' | translate}}</span>
      <mat-icon class="icon right">block</mat-icon>
    </button>

    <button mat-button (click)="removeShiftTemplate(selectedDays, 0)" class="md-red filter-button-no-background"
      [disabled]="shiftTemplates.length == 0" fxLayout="row" fxLayoutAlign="center center">
      <span>{{'CALENDAR.REMOVE_SHIFT_TEMPLATE' | translate}}</span>
      <mat-icon class="icon right">delete</mat-icon>
    </button>

    <button mat-button (click)="removeShiftTemplate(selectedDays, 1)" class="md-red filter-button-no-background"
      fxLayout="row" fxLayoutAlign="center center">
      <span>{{'CALENDAR.CLEAR_DOWNTIME' | translate}}</span>
      <mat-icon class="icon right">delete</mat-icon>
    </button>

    <button mat-button (click)="removeShiftTemplate(selectedDays, 2)" class="md-red filter-button-no-background"
      [disabled]="shiftTemplates.length == 0" fxLayout="row" fxLayoutAlign="center center">
      <span>{{'CALENDAR.CLEAR_ALL' | translate}}</span>
      <mat-icon class="icon right">delete</mat-icon>
    </button>

  </div>

  <div *ngIf="isMobile" fxFlex fxLayout="column" class="tab-content" [ngClass]="{ 'without-tabs': tabs.length <= 1}"
    fxLayoutAlign="center center">
    <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
  </div>

  <!-- tab content -->
  <div *ngIf="!isMobile" fxFlex fxLayout="column" class="tab-content" [ngClass]="{ 'without-tabs': tabs.length <= 1}"
    fxLayoutAlign="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start start' }}">

    <!-- error -->
    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
    </ff-loading-card>

    <div id="fullcalendar" class="h100" *ngIf="pageState.value >= pageStateReady"
      style="padding: 16px; width: calc(100% - 32px);">
      <full-calendar [options]="calendarOptions" class="w100 h100"></full-calendar>
    </div>

  </div>

</div>