import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { CustomIntervalDialogComponent } from '../interval-selector/custom-interval-dialog/custom-interval-dialog.component';

@Component({
  selector: 'app-maintenance-bom-generator-dialog',
  templateUrl: './maintenance-bom-generator-dialog.component.html',
  styleUrls: ['./maintenance-bom-generator-dialog.component.scss']
})
export class MaintenanceBomGeneratorDialogComponent implements OnInit, OnDestroy {

  public mailRegex: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public state: any = 0;
  public mailingList: any = [];
  public mailText: any = this.translate.instant("MAINTENANCE_BOM.DEFAULT_MAIL_TEXT");
  public statesConfig: any = {
    componentsSelectionWidget: 1,
    generatedComponents: 0,
  };

  public exportTableConfig: any;
  public nowTime: any;
  public errors: any = [];
  public intervalConfig: any;
  public sapButton: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<MaintenanceBomGeneratorDialogComponent>,
    public newDialog: MatDialog,
    public http: ApiService,
    public intervalService: IntervalService,
    public clonerService: ClonerService,
    public translate: FfTranslateService,
    public internalDataService: InternalDataService,
    public apiService: ApiService,
    public filterService: FiltersService,
  ) { }

  tabs: any = [
    {
      "id": "components",
      "title": "MAINTENANCE_HISTORY.COMPONENTS",
      "icon": {
        "icon": "list_alt",
        "type": "icon"
      },
      "selected": true
    },
    {
      "id": "notes",
      "title": "MAINTENANCE_HISTORY.NOTES",
      "icon": {
        "icon": "list_alt",
        "type": "icon"
      },
      "right": true
    },
  ];

  ngOnInit() {

    this.nowTime = new Date().toISOString().substring(0, 16);

    this.state = 1;
    this.dialogRef.updateSize('30%');

    this.sapButton = this.dialog.buttonInfos?.sapIntegrationButton;

  }

  // // // // // // // // // // // // // // // // // // //
  // DATES HANDLING
  // // // // // // // // // // // // // // // // // // //

  // AUTO MODE == FALSE --> OPEN CUSTOM INTERVAL DIALOG
  openDateSelector() {

    const customIntervalDialog = this.newDialog.open(CustomIntervalDialogComponent, {
      panelClass: 'ff-dialog',
      disableClose: true,
      data: {
        title: this.translate.instant('INTERVAL.CUSTOM')
      },
    });

    customIntervalDialog.afterClosed().subscribe((result: any) => {
      // console.log('afterClosed', result);
      if (result != null && result != '') {

        let interval: any = {};
        let tz = this.dialog.timezone;

        interval.start = JSON.parse(JSON.stringify(result.interval.value.start));
        interval.end = moment(result.interval.value.end).endOf("day");

        this.intervalService.formatTimezone(interval, tz);
        // if (moment().diff(moment(interval.end), 'm') < 0) interval.end = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        interval.startF = this.intervalService.strFormat(interval, interval.start, tz) + ' - ' + this.intervalService.strFormat(interval, interval.end, tz);

        let selected = this.clonerService.deepClone(interval);

        this.intervalConfig = selected;
        this.generateComponents();

      }
    });
  }

  // AUTO MODE == FALSE --> GENERATE DATE
  generateAutomaticDate() {

    this.state = 0;

    setTimeout(() => {

      let interval: any = {};
      let tz = this.dialog.timezone;

      interval.start = this.dialog.dashboardData?.nextBomPredicted?.from;
      interval.end = moment(this.dialog.dashboardData?.nextBomPredicted?.to).endOf("day");

      this.intervalService.formatTimezone(interval, tz);
      // if (moment().diff(moment(interval.end), 'm') < 0) interval.end = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      interval.startF = this.intervalService.strFormat(interval, interval.start, tz) + ' - ' + this.intervalService.strFormat(interval, interval.end, tz);

      let selected = this.clonerService.deepClone(interval);

      this.intervalConfig = selected;
      this.generateComponents();
    }, 500);

  }

  // // // // // // // // // // // // // // // // // // //
  // COMPONENTS
  // // // // // // // // // // // // // // // // // // //

  generateComponents() {

    this.state = 0;

    // // // // // // // // // // // // // // // // // // // // // // 
    // COMPONENTS 
    // // // // // // // // // // // // // // // // // // // // // // 

    // Initialize data as empty array (NEW MAINTENANCE) or pre-existing list of replaced components (EDIT MAINTENANCE)
    try {
      this.dialog.componentsTableConfig.data = { selectedComponentsTable: [] };
      this.dialog.componentsTableConfig = this.clonerService.deepClone(this.dialog.componentsTableConfig);
    } catch (error) {
      console.log(error);
    }

    this.statesConfig.generatedComponents = 0;

    (async () => {
      let endpointUrl = this?.dialog?.endpointUrl?.replaceAll("{machineId}", this.dialog.machineId);

      let query: any = {
        lang: this.translate?.currentLang ?? 'en',
        tz: this.dialog?.timezone,
        from: this.intervalConfig.start,
        to: this.intervalConfig.end,
      };

      let payload: any = this.internalDataService.buildMachinePayload(this.dialog.machine);

      try {

        let method = 'POST';
        if (endpointUrl.includes(':')) {
          endpointUrl = endpointUrl.split(':');
          method = endpointUrl[0];
          endpointUrl = endpointUrl[1];
        }

        let requestSent = null;
        if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
        else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
        else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

        const data: any = await requestSent.toPromise();

        //------------------------------------------------------------------------//
        // RESPONSE PARSING

        let copyData: any = this.clonerService.deepClone(data?.body ?? {});

        this.dialog.componentsSelectionWidget.data = copyData;

        this.dialog.componentsSelectionWidget = this.clonerService.deepClone(this.dialog.componentsSelectionWidget);

        this.dialogRef.updateSize('90%');
        console.log(copyData);

        this.parseExistingComponents(copyData?.bomComponents ?? []);

        this.dialog.componentsSelectionWidget = this.clonerService.deepClone(this.dialog.componentsSelectionWidget);

        this.statesConfig.generatedComponents = 1;
        this.checkState();

      } catch (error) {
        this.statesConfig.generatedComponents = 1;
        this.checkState();
      }

    })();

  }

  parseExistingComponents(components) {
    try {
      this.dialog.componentsTableConfig.data.selectedComponentsTable = components;

      this.dialog.componentsSelectionWidget.data.hierarchy.forEach(group => {
        group.subgroups.forEach(subgroup => {
          subgroup.components.forEach(comp => {
            if (components.findIndex(component => comp.id == component.id) > -1) {
              comp.selected = true;
            }
          });
        });
      });

      this.dialog.row.selectedComponents = components?.map(x => { return { id: x.id } });
      this.dialog.componentsTableConfig = this.clonerService.deepClone(this.dialog.componentsTableConfig);
    } catch (error) {
      console.log(error);
    }
  }

  checkState() {
    try { this.state = Object.values(this.statesConfig).every(x => x == 1) ? 1 : 0 }
    catch (error) {
      console.log(error);
      return 0;
    }
  }

  onButtonClick(item) {
    try {

      console.log(item);

      let rv = item?.tableConfig?.rowsVariable;
      switch (rv) {
        case "hierarchy":

          let settings: any = {
            tableConfigName: 'componentsTableConfig',
            selectedTableName: 'selectedComponentsTable',
            nestedKeyLabel: 'components',
            finalElement: 'id',
          };

          let nestedTableIndex = item?.nestedTableIndex ?? 0;

          let compTable: any = this.dialog[settings.tableConfigName].data[settings.selectedTableName];

          if (nestedTableIndex == 2) {
            let compIdx = compTable.findIndex(comp => comp[settings.finalElement] == item.row[settings.finalElement]);
            if (compIdx == -1) {
              compTable.push(item.row);
            } else {
              compTable.splice(compIdx, 1);
            }
          } else if (nestedTableIndex == 1) {
            let toggle = item.row.selected;
            item.row[settings.nestedKeyLabel].forEach(component => {
              let compIdx = compTable.findIndex(comp => comp[settings.finalElement] == component[settings.finalElement]);
              if (compIdx == -1) {
                if (toggle) compTable.push(component);
              } else {
                if (!toggle) compTable.splice(compIdx, 1);
              }
            })
          } else if (nestedTableIndex == 0) {

            let toggle = item.row.selected;
            item.row.subgroups.forEach(subgroup => {
              subgroup[settings.nestedKeyLabel].forEach(component => {
                let compIdx = compTable.findIndex(comp => comp[settings.finalElement] == component[settings.finalElement]);
                if (compIdx == -1) {
                  if (toggle) compTable.push(component);
                } else {
                  if (!toggle) compTable.splice(compIdx, 1);
                }
              })
            })
          }

          if (rv == 'hierarchy') compTable?.forEach(comp => comp.interventionType = comp.interventionType ?? 1);

          this.dialog[settings.tableConfigName].data[settings.selectedTableName] = compTable;

          if (rv == 'hierarchy') {
            this.dialog.row.selectedComponents = compTable?.map(x => {
              return {
                id: x.id,
              };
            });
          } else {
            this.dialog.row.selectedActivities = compTable?.map(x => x.id);
          }
          this.dialog[settings.tableConfigName] = this.clonerService.deepClone(this.dialog[settings.tableConfigName]);
          break;

      }

    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy() { }

  checkDisabledSendMail() {

    let isEmptyMailList = !this.mailingList?.length;
    if (isEmptyMailList) return true;

    let isEmptyMailText = !this.mailText?.length;
    if (isEmptyMailText) return true;

    let isInvalidMail = !this.mailingList.every((mail: any) => mail.value != null && mail.value != '' && RegExp(this.mailRegex, 'g').exec(mail.value));

    return isInvalidMail;
  }

  onDaySelection(date: any, key: string) {

    this.dialog.row[key] = JSON.parse(JSON.stringify(moment(date.value)));

  }

  downloadPdf() {

    this.exportTableConfiguration(this.dialog.componentsTableConfig?.config);

  }

  // ADD MAIL
  addMail(mailingList: any) {
    mailingList.push({
      id: mailingList.length,
      value: ""
    });
  }

  // DELETE MAIL
  deleteMail(mailingList: any, index: any) {
    mailingList.splice(index, 1);
  };


  sendMails() {

    if (!this.mailingList?.length) return;

    this.state = 0;
    console.log(this.mailingList);

    (async () => {
      let endpointUrl: any = '/apif/cmms/send-bom-to-mailing-list/' + this.dialog.machineId;

      let query: any = {};

      let mailText: any = this.translate.instant("MAINTENANCE_BOM.DISCLAIMER");
      mailText = mailText + '<br>' + this.mailText;

      try {
        mailText = mailText.replaceAll("\n", "<br>");
      } catch (error) {
        console.log(error);
      }

      let payload: any = {
        pdf: this.exportTableConfig,
        mailingList: this.mailingList?.map(x => x.value),
        message: mailText,
        sender: this.dialog.user ?? 'unknown',
        subject: this.exportTableConfig?.title,
      };

      try {

        let method = 'POST';
        if (endpointUrl.includes(':')) {
          endpointUrl = endpointUrl.split(':');
          method = endpointUrl[0];
          endpointUrl = endpointUrl[1];
        }

        let requestSent = null;
        if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
        else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
        else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

        const data: any = await requestSent.toPromise();

        //------------------------------------------------------------------------//
        // RESPONSE PARSING

        this.state = 1;
        this.closeDialog();

      } catch (error) {
        this.state = 1;
      }

    })();

  }

  selectTab(sel: any) {
    this.downloadPdf();
    // reset position of tabs content
    this.tabs.forEach((t: any) => {
      t.selected = false;
      delete t.left;
      delete t.right;
    });
    // set as selected
    sel.selected = true;
    // loop on tabs to set on left previous and right nexts
    let selPassed = false;
    this.tabs.forEach((t: any) => {
      if (t.id != sel.id) {
        if (!selPassed) t.left = true;
        else t.right = true;
      } else {
        selPassed = true;
      }
    });
  }


  exportTableConfiguration(tableConfig) {
    // if (!tableConfig.export) return;

    // let exportConfig: any = this.clonerService.deepClone(tableConfig.export);

    // export file name
    // let filename = this.translate.instant(exportConfig?.filename ?? 'GLOBAL.EXPORT');
    let filename = this.translate.instant('MAINTENANCE.BOM_LONG') + ' - ' + (this.dialog?.machine?.name ?? this.dialog?.machine?.machineName);

    // Add export date
    filename = filename + ' - ' + moment(this.intervalConfig?.end).format("YYYY/MM/DD");

    // Export table config
    this.exportTableConfig = {
      title: filename,
      type: 'pdf',
      payload: {
        translations: {},
        rows: []
      }
    };
    this.exportTableConfig.buttonText = this.translate.instant("GLOBAL.DOWNLOAD_PDF");

    let actualRows: any = this.clonerService.deepClone(tableConfig?.rowsFiltered ?? tableConfig?.rows ?? []);

    try {
      let translation = {};
      tableConfig.tableInfos?.filter(x => !x.excludeDownload)?.forEach(info => {
        let unit = info?.suffix ?? info?.unit;
        translation[info.variable] = this.translate.instant(info.label ?? '-') + (unit != null ? (' [' + this.translate.instant(unit) + ']') : '')
      });
      this.exportTableConfig.payload.translations = translation;
      actualRows?.sort(this.filterService.sortByProperty(tableConfig.sort?.variable ?? 'timeStart', 'desc', true)).forEach((prod) => {
        let row = {};
        tableConfig.tableInfos?.filter(x => !x.excludeDownload)?.forEach(info => row[info.variable] = this.filterService.parseObjFromConfig(prod, info));
        this.exportTableConfig.payload.rows.push(row);
      });
    } catch (error) {
      console.log(error);
    }
  }

  async downloadPdfButtonAction() {

    (async () => {
      let endpointUrl: any = '/apif/cmms/download-bom-pdf/' + this.dialog.machineId;

      let payload: any = {
        pdf: this.exportTableConfig,
      };

      let resp = await this.apiService.asyncDownload(endpointUrl, payload);
      this.apiService.handleDownload(resp);
      return;

    })();
  }

  async closeDialog() {

    let inputDateTime = this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'inputDateTime');

    inputDateTime?.forEach(x => {
      try { this.dialog.row[x.variable] = this.intervalService.formatStringDate(this.dialog?.row?.[x.variable] + ':00.000', this.dialog?.machineId) }
      catch (error) { console.log(error) }
    });

    // let payload = this.dialog?.row;
    // let resp = null;
    // if (this.dialog.dialogType != 'edit') {
    //   resp = await this.apiService.sendPostRequestAwait('/apif/cmms/maintenance/' + this.dialog?.machineId, payload);
    // } else {
    //   resp = await this.apiService.sendPutRequestAwait('/apif/cmms/maintenance/' + this.dialog?.machineId, payload);
    // }

    this.dialogRef.close(this.dialog?.row);
  }

}
