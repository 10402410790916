<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <button *ngIf="!isMobile" class="filter-button" (click)="updateSettings()"
      [disabled]="pageState.value < pageStateReady || settings == null || checkUpdates() || !isAllowedUserWrite"
      fxLayout="row" fxLayoutAlign="center center">
      <mat-icon class="icon" aria-label="save">save</mat-icon>
      <span>{{'GLOBAL.SAVE'|translate}}</span>
    </button>

  </div>

  <div *ngIf="isMobile" fxFlex fxLayout="column" class="tab-content h100 w100"
    [ngClass]="{ 'without-tabs': tabs.length <= 1}" fxLayoutAlign="center center">
    <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
  </div>

  <!-- tab content -->
  <div *ngIf="!isMobile" class="tab-content h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1}">

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorDataMobile"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <mat-card class="rba-container" style="margin-bottom: 20px;"
      *ngIf="pageState.value > 0 && pageState.value >= pageStateReady">

      <div class="title">
        <span>{{'CONSUMPTION_SUPPLY_SETTINGS.SUPPLIER_MAILING_LIST' | translate}}</span>
        <span class="number"
          *ngIf="settings != null && settings.supplierMailingList != null && settings.supplierMailingList.length > 0">
          &nbsp;({{ settings.supplierMailingList.length }})
        </span>

        <span fxFlex></span>

        <button mat-button
          *ngIf="settings != null && settings.supplierMailingList != null && settings.supplierMailingList.length < 6"
          class="md-gray-i rounded-button elliptic" (click)="addMail('supplier')"
          [disabled]="isPageAdmin ? false : (!isAllowedUserWrite || !isSupplier)" fxLayout="row"
          fxLayoutAlign="space-evenly center" #tooltip="matTooltip" matTooltip="{{ 'RBA.ADD_MAIL' | translate }}"
          matTooltipPosition="above">
          <mat-icon class="icon side">add</mat-icon>
        </button>

      </div>

      <div class="subtitle">
        <span *ngIf="settings?.supplierMailingList?.length > 0">
          {{'CONSUMPTION_SUPPLY_SETTINGS.SUPPLIER_MAILING_LIST_DESCRIPTION' | translate}}.
        </span>
        <span *ngIf="settings?.supplierMailingList?.length == null || settings?.supplierMailingList?.length == 0">
          {{ 'RBA.NO_MAILS_CONFIGURED' | translate}}!
        </span>
      </div>

      <mat-card-content>

        <div fxLayout="row wrap"
          *ngIf="settings != null && settings.supplierMailingList != null && settings.supplierMailingList.length > 0"
          fxLayoutGap="16px">
          <div *ngFor="let mail of settings.supplierMailingList; let i = index" [fxFlex]="'calc(33.3% - 16px)'"
            class="single-event-container three" style="background-color: transparent !important;" fxLayout="row"
            fxLayoutAlign="start center">

            <input class="ff-input mail" [(ngModel)]="mail.value" type="email" required name="mail"
              [ngClass]="{'ff-disabled': isPageAdmin ? false : (!isAllowedUserWrite || !isSupplier)}"
              [disabled]="isPageAdmin ? false : (!isAllowedUserWrite || !isSupplier)">

            <button *ngIf="isAllowedUserWrite" mat-button class="md-red rounded-button elliptic"
              [disabled]="isPageAdmin ? false : (!isSupplier)" (click)="deleteMail('supplier', i)" fxLayout="row"
              fxLayoutAlign="space-evenly center" #tooltip="matTooltip" matTooltip="{{ 'RBA.DELETE_MAIL' | translate }}"
              matTooltipPosition="above" style="margin-left: 12px !important;">
              <mat-icon class="icon side">delete</mat-icon>
            </button>

          </div>
        </div>
      </mat-card-content>

    </mat-card>

    <!-- client mailing list -->
    <mat-card class="rba-container" style="margin-bottom: 20px;"
      *ngIf="pageState.value > 0 && pageState.value >= pageStateReady">

      <div class="title">
        <span>{{'CONSUMPTION_SUPPLY_SETTINGS.CLIENT_MAILING_LIST' | translate}}</span>
        <span class="number"
          *ngIf="settings != null && settings.clientMailingList != null && settings.clientMailingList.length > 0">
          &nbsp;({{ settings.clientMailingList.length }})
        </span>

        <span fxFlex></span>

        <button mat-button
          *ngIf="settings != null && settings.clientMailingList != null && settings.clientMailingList.length < 6"
          class="md-gray-i rounded-button elliptic" (click)="addMail('client')"
          [disabled]="isPageAdmin ? false : (!isAllowedUserWrite || !isClient)" fxLayout="row"
          fxLayoutAlign="space-evenly center" #tooltip="matTooltip" matTooltip="{{ 'RBA.ADD_MAIL' | translate }}"
          matTooltipPosition="above">
          <mat-icon class="icon side">add</mat-icon>
        </button>

      </div>

      <div class="subtitle">
        <span *ngIf="settings?.clientMailingList?.length > 0">
          {{ 'CONSUMPTION_SUPPLY_SETTINGS.CLIENT_MAILING_LIST_DESCRIPTION' | translate}}.
        </span>
        <span *ngIf="settings?.clientMailingList?.length == null || settings?.clientMailingList?.length == 0">
          {{ 'RBA.NO_MAILS_CONFIGURED' | translate}}!
        </span>
      </div>

      <mat-card-content>

        <div fxLayout="row wrap"
          *ngIf="settings != null && settings.clientMailingList != null && settings.clientMailingList.length > 0"
          fxLayoutGap="16px">
          <div *ngFor="let mail of settings.clientMailingList; let i = index" [fxFlex]="'calc(33.3% - 16px)'"
            class="single-event-container three" fxLayout="column">

            <div style="background-color: transparent !important;" fxLayout="row" fxLayoutAlign="start center">

              <input class="ff-input mail" [(ngModel)]="mail.value" type="email" required name="mail"
                [ngClass]="{'ff-disabled': isPageAdmin ? false : (!isAllowedUserWrite || !isClient || (!mail.superClient ? false : !isSuperClient))}"
                [disabled]="isPageAdmin ? false : (!isAllowedUserWrite || !isClient || (!mail.superClient ? false : !isSuperClient))">

              <button *ngIf="isAllowedUserWrite" mat-button class="md-red rounded-button elliptic"
                [disabled]="isPageAdmin ? false : (!isClient || (!mail.superClient ? false : !isSuperClient))"
                (click)="deleteMail('client', i)" fxLayout="row" fxLayoutAlign="space-evenly center"
                #tooltip="matTooltip" matTooltip="{{ 'RBA.DELETE_MAIL' | translate }}" matTooltipPosition="above"
                style="margin-left: 12px !important;">
                <mat-icon class="icon side">delete</mat-icon>
              </button>

            </div>

            <div style="margin-top: 12px;" fxLayout="row" fxLayoutAlign="start center" class="completely"
              [ngClass]="{'ff-disabled': isPageAdmin ? false : (!isAllowedUserWrite || !isSuperClient)}">

              <div fxLayout="row" fxLayoutAlign="start center" class="clickable" (click)="mail.superClient = false">
                <mat-icon *ngIf="!mail.superClient" svgIcon="radio_button" class="icon small left radio_button">
                </mat-icon>
                <mat-icon *ngIf="mail.superClient" svgIcon="radio_button_disabled"
                  class="icon small left radio_button_disabled">
                </mat-icon>
                <span>{{ 'CONSUMPTION_SUPPLY_SETTINGS.BASE_CLIENT' | translate }}</span>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center" style="margin-left: 12px;" class="clickable"
                (click)="mail.superClient = true">
                <mat-icon *ngIf="mail.superClient" svgIcon="radio_button" class="icon small left radio_button">
                </mat-icon>
                <mat-icon *ngIf="!mail.superClient" svgIcon="radio_button_disabled"
                  class="icon small left radio_button_disabled">
                </mat-icon>
                <span>{{ 'CONSUMPTION_SUPPLY_SETTINGS.SUPER_CLIENT' | translate }}</span>
              </div>

            </div>

          </div>
        </div>
      </mat-card-content>

    </mat-card>

    <!-- <div fxLayout.gt-md="row wrap" fxLayout="column" class="w100" fxLayoutGap="8px"
      *ngIf="pageState.value > 0 && pageState.value >= pageStateReady">

      <div *ngFor="let setting of settings.materials" fxFlex="100" fxFlex.gt-md="33">
        <mat-card class="card">
          <div class="header" fxLayout="column" fxLayout.gt-sm="row">
            <div class="title" fxFlex fxLayout="row" fxLayout="start center">

              <span *ngIf="setting.icon != null" style="margin-right: 8px;" fxLayout="row" fxLayoutAlign="start center">

                <ng-container *ngIf="setting.icon?.type == null">
                  <mat-icon class="icon">{{ setting.icon }}</mat-icon>
                </ng-container>

                <ng-container *ngIf="setting.icon?.type != null">
                  <mat-icon *ngIf="setting.icon.type == 'icon'" class="icon">
                    {{setting.icon.icon }}
                  </mat-icon>
                  <mat-icon *ngIf="setting.icon.type == 'svg'" svgIcon="{{ setting.icon.icon }}" class="icon">
                  </mat-icon>
                  <i *ngIf="setting.icon.type == 'fontawesome' || settings.icon.type == 'phosphor'" class="icon {{ setting.icon.icon }}"></i>
                </ng-container>
              </span>

              <span>{{ setting.label | translate }}</span>

            </div>
          </div>
          <div class="content" fxLayout="column">
            <div class="w100" fxLayout="row" fxLayoutAlign="space-evenly center" *ngFor="let info of inputInfos"
              style="margin: 4px 0;">

              <span fxFlex="45" style="overflow: hidden; text-overflow: ellipsis;">{{ info.label | translate }}</span>

              <input fxFlex="45" type="{{ info.type }}" [(ngModel)]="setting[info.variable]"
                [disabled]="!isAllowedUserWrite || info.type == null" class="ff-input">

              <span fxFlex="10" style="margin-left: 8px; font-size: 1.2rem;">
                {{ (info.unitFromConfig && setting.unit != null ? setting.unit : '') | translate }}
              </span>

            </div>
          </div>
        </mat-card>
      </div>

    </div> -->


  </div>

</div>