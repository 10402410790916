<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= 6" fxLayout="column" fxLayoutAlign="start start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <button class="filter-button" fxLayout="row" fxLayoutAlign="center center" (click)="updateSettings()"
      [disabled]="!isAllowedUserWrite">
      <mat-icon class="icon" aria-label="save">save</mat-icon>
      <span>{{'GLOBAL.SAVE'|translate}}</span>
    </button>

  </div>

  <!-- content -->
  <div class="tab-content" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-md="row wrap"
    fxLayoutAlign.gt-md="start stretch">

    <div *ngIf="tabs.length == 1" fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 12px" class="w100">

      <span fxFlex></span>

      <button class="filter-button" fxLayout="row" fxLayoutAlign="center center" (click)="updateSettings()"
        [disabled]="!isAllowedUserWrite">
        <mat-icon class="icon" aria-label="save">save</mat-icon>
        <span>{{'GLOBAL.SAVE'|translate}}</span>
      </button>

    </div>

    <div *ngFor="let setting of settingsList" fxFlex="100" fxFlex.gt-md="33" style="padding: 0.5rem;">
      <mat-card class="machine-settings-card">
        <div class="header" fxLayout="column" fxLayout.gt-sm="row">
          <div class="title" fxFlex>
            {{setting.title | translate}}
          </div>
        </div>
        <div class="content" fxLayout="column" fxLayout.gt-sm="row">
          <div class="w100" fxLayout="row" fxLayoutAlign="space-evenly center">
            <input type="number" id="test" name="test" placeholder="" [(ngModel)]="settingsVariables[setting.variable]"
              [disabled]="!isAllowedUserWrite">
            <span *ngIf="setting.unit" style="margin-left: 8px;">{{setting.unit}}</span>
          </div>
        </div>
      </mat-card>
    </div>

  </div>

</div>