import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import * as moment from 'moment';

import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'app-consumables-settings',
  templateUrl: './consumables-settings.component.html',
  styleUrls: ['./consumables-settings.component.scss']
})
export class ConsumablesSettingsComponent implements OnInit, OnChanges {

  public isAllowedUser: any = true;
  public isAllowedUserWrite: any = true;

  loadingData: any;
  errorData: any;

  appConfig: any;
  appInfo: any;
  machineProfiles: any;
  machine: any;
  machineId: any;
  breadcrumb: any;
  public tabs: any;

  selectedTrainingStart: any = null;
  selectedTrainingEnd: any = null;

  settingsList: any;
  settingsData: any;
  settingsVariables: any;

  trainingNeeded: boolean = true;

  public sectionName: any = 'machineSettings';
  public dashboardName: any = 'complete-consumption';

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getMachineSettings, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public route: ActivatedRoute,
    public translate: FfTranslateService,
    public clonerService: ClonerService,
    public cacheService: CacheService,
  ) {

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.settingsList = this.appConfig.machineSettings.settings;
    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.breadcrumb = ['CONSUMABLES_SETTINGS.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUserWrite = _this.internalDataService.getSpecificPermission("mat-settings-rw");
    } catch (error) { console.log(error) }

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(["mat-settings-r", "mat-settings-rw"], 'or');
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  

  public getMachineSettings(_this: any) {
    try {

      let query: any = {};

      _this.apiService.sendGetRequest('/apif/settings/' + _this.machineId, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy()),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.settingsData = _this.clonerService.deepClone(data.body);
            _this.settingsVariables = _this.parseSettingsVariables(data.body);
            // console.log(_this.settingsVariables);

            _this.dispatcherService.getDispatch(_this, 300);

          },
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }


  onDaySelection(date: any, key: string) {

    // TODO METTERE CONTROLLO CHE END > START e CHE SIANO TUTTI E DUE DEFINITI PRIMA DI SALVARE (o nessuno dei due)
    // MAGARI SI PUÒ USARE SOLO UN PICKER
    this.settingsVariables[key] = JSON.parse(JSON.stringify(moment(date.value).startOf("day")));
    this.trainingNeeded = true;

  }


  parseSettingsVariables(data: any) {
    // console.log(data);

    Object.entries(data)?.filter(kv => kv?.[0]?.startsWith("cons") && kv?.[0]?.endsWith("Cost"))?.forEach((cons: any) => {
      try {
        let consId = cons?.[0]?.substring(0, 5);
        let consConfig = this.machine.profile.consumables.find(c => c.id == consId);
        if (cons[1] != null && consConfig?.settingsMultiplier != null) data[cons[0]] = cons[1] * consConfig?.settingsMultiplier;
      } catch (error) { }
    });

    return this.clonerService.deepClone(data);
  }

  parseSettingsVariablesPayload(data: any) {
    // console.log(data);

    Object.entries(data)?.filter(kv => kv?.[0]?.startsWith("cons") && kv?.[0]?.endsWith("Cost"))?.forEach((cons: any) => {
      try {
        let consId = cons?.[0]?.substring(0, 5);
        let consConfig = this.machine.profile.consumables.find(c => c.id == consId);
        if (cons[1] != null && consConfig?.settingsMultiplier != null) data[cons[0]] = cons[1] / consConfig?.settingsMultiplier;
      } catch (error) { }
    });

    return this.clonerService.deepClone(data);
  }

  updateSettings() {

    this.pageState.next(4);

    let variables = this.clonerService.deepClone(this.parseSettingsVariablesPayload(this.settingsVariables));

    let payload: any
    if (this.appConfig.MAT2) {
      payload = this.internalDataService.buildMachinePayload(this.machine);
      payload.settings.dynamics = variables;
    } else {
      payload = variables;
    }


    console.log({ payload });

    let query = {
      tz: this.machine.timezone,
      n: this.machine.profile?.settings?.n ?? 3
    };

    if (this.trainingNeeded) query['trainingNeeded'] = true;

    let _this = this;
    try {

      this.apiService.sendPutRequest('/apif/settings/' + this.machineId, payload, query)
        .pipe(
          retryWhen(this.apiService.genericRetryStrategy()),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
        )
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.settingsData = data.body;
            _this.settingsVariables = _this.parseSettingsVariables(data.body);

            try {
              let assets = _this.cacheService.get("assets");
              assets.forEach(asset => {
                if (asset.machineId == _this.machineId) asset.settings = _this.clonerService.deepClone(_this.settingsData);
              });

              try { _this.internalDataService.setSelectedAssetsList(data?.body ?? []) }
              catch (error) { console.log(error) }

            } catch (error) { console.log(error) }

            _this.pageState.next(6);

          },
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      this.dispatcherService.getDispatch(this, 301, errorData);
    }
  }

  checkUpdates(checkExit: any = false) {

    try {

      if (!checkExit) return Object.entries(this.settingsVariables)?.every(([key, val]) => this.settingsData[key] == val);

      let checkLength = Object.keys(this.settingsVariables)?.length == Object.keys(this.settingsData)?.length;
      if (!checkLength) return false;

      return Object.entries(this.settingsVariables)?.every(([key, val]) => this.settingsData[key] == val);

    } catch (error) {
      console.log(error);
      if (checkExit) return true;
      return false;
    }
  }

  canDeactivate() {
    return this.checkUpdates(true);
  }

  ngOnInit(): void {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);
  }

  ngOnChanges() { }

}
