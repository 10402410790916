import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LastUpdatesDetailDialogComponent } from 'src/app/components/last-updates-detail-dialog/last-updates-detail-dialog.component';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'homepage-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class HomepageGridCardComponent implements OnInit, OnChanges {

  @Input() machineData: any;

  public appConfig: any;
  public sidenavItems: any;

  public isEnabledMachineCalendar: Boolean = true;
  public enableCalendarSwitch: Boolean = false;

  cardStyle: any;
  cardConfig: any = { config: {} };

  machineInfos: any = [];
  machineDatas: any = [];
  otherData: any = [];
  worstComponentsInfos: any = [];
  anomalyInfos: any = [];
  oeeInfos: any = [];
  productivity: any = {};
  materials: any = null;
  productionOrder: any = null;
  productionData: any = null;

  prodOrderColumns: any;

  public homepageDataSub: any;
  public interval: any;

  constructor(
    private appConfigService: AppConfigService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public dialog: MatDialog,
    public cacheService: CacheService,
    private router: Router,
    public translate: FfTranslateService,
  ) {

    this.appConfig = this.appConfigService.getAppConfig;
    this.sidenavItems = appConfigService.getSidenavItems;
    this.cardStyle = this.appConfig.homepage.gridCards.cardStyle ?? 0;

    // card-style != 0
    this.machineInfos = this.appConfig.homepage.machineInfos;
    this.machineDatas = this.appConfig.homepage.machineDatas;
    this.otherData = this.appConfig.homepage.otherData;
    this.worstComponentsInfos = this.appConfig.homepage.worstComponentsInfos;
    this.anomalyInfos = this.appConfig.homepage.anomalyInfos;
    this.oeeInfos = this.appConfig.homepage.oeeInfos;

    this.homepageDataSub = this.internalDataService.homepageData.subscribe(value => {
      this.interval = value.interval;
    });

  }

  ngOnInit() {
    this.initCardInfos();
  }

  initCardInfos() {

    try {
      if (this.appConfig?.homepage?.migrationFromAnyStyleToStyle0InProgress) this.cardConfig = this.machineData?.profile?.homepage ?? this.appConfig?.homepage;
      else this.cardConfig = this.machineData?.profile?.homepage ?? { config: {} };

      if (this.machineData?.profile?.homepage?.config == null) this.machineData.profile.homepage.config = {};

      let prodOrder = this.cardConfig?.config?.productionOrder;
      this.prodOrderColumns = prodOrder?.columns ? 100 / prodOrder?.columns : 50;
    } catch (error) { }

    try {
      // card-style != 0
      this.machineInfos = this.cardConfig?.machineInfos ?? this.appConfig.homepage.machineInfos;
      this.machineDatas = this.cardConfig?.machineDatas ?? this.appConfig.homepage.machineDatas;
      this.otherData = this.cardConfig?.otherData ?? this.appConfig.homepage.otherData;
      this.worstComponentsInfos = this.cardConfig?.worstComponentsInfos ?? this.appConfig.homepage.worstComponentsInfos;
      this.anomalyInfos = this.cardConfig?.anomalyInfos ?? this.appConfig.homepage.anomalyInfos;
      this.oeeInfos = this.cardConfig?.oeeInfos ?? this.appConfig.homepage.oeeInfos;
      this.productivity = this.cardConfig?.productivity ?? this.appConfig.homepage.productivity;
      this.materials = this.cardConfig?.materials ?? this.appConfig.homepage.materials;
      this.productionOrder = this.cardConfig?.productionOrder ?? this.appConfig.homepage.productionOrder;
      this.productionData = this.cardConfig?.productionData ?? this.appConfig.homepage.productionData;
    } catch (error) { }



    try {
      // this.enableCalendarSwitch = sidenavItems.find(sItem => (sItem.id == 'planning-calendar' && !sItem.disabled)) != null
      if (this.appConfig?.homepage?.showCalendarSwitch) {
        let calendarItem = this.sidenavItems.find(sItem => (sItem.id == 'planning-calendar'));
        let machineId = this.machineData.machineId;
        if (calendarItem == null) {
          this.enableCalendarSwitch = false;
        } else if (calendarItem.disabled == true) {
          this.enableCalendarSwitch = false;
        } else if (Array.isArray(calendarItem.enabledForAssets) && !calendarItem.enabledForAssets.includes(machineId)) {
          this.enableCalendarSwitch = false;
        } else if (Array.isArray(calendarItem.disabledForAssets) && calendarItem.disabledForAssets.includes(machineId)) {
          this.enableCalendarSwitch = false;
        }
        else {
          this.enableCalendarSwitch = true;
        }

        this.isEnabledMachineCalendar = this.filterService.isActiveCalendar(this.machineData.machineId);;
      }

    } catch (error) {
      console.log(error);
    }
  }

  selectMachine() {

    if (localStorage.getItem("hierarchyMode") == null) localStorage.setItem('hierarchyMode', 'false');

    let hierarchyMode = JSON.parse(localStorage.getItem("hierarchyMode"));

    if (hierarchyMode && this.machineData.isHierarchicElement) {
      this.internalDataService.setHierarchyHistory(this.cacheService.get("hierarchy"));
      this.internalDataService.setHierarchyElementSelected(this.machineData);
      return;
    }
    this.internalDataService.setMachineSelected(this.machineData);
  }

  public toggleEnableCalendarAsset(value) {

    try { localStorage.setItem(this.machineData.machineId + '_calendar', value) }
    catch (error) { console.log(error) }


    if (value == false) {
      try { if (this.cacheService.get("intervalAggregation")?.id == 'shift') this.cacheService.set("intervalAggregation", null) }
      catch (error) { console.log(error) }
    }

    // this.router.navigateByUrl(this.router.url);
    this.machineData.loading = true;
    this.internalDataService.setHomepageCardReload(true);
  }


  onClickFunction(info: any) {

    console.log(info);
    if (info?.clickFunction == null) return;

    switch (info?.clickFunction) {
      case 'openInNewTab':
        let linkConfig = info?.linkConfig;
        if (linkConfig == null) return;

        let url = linkConfig?.url;
        if (url != null) {
          window.open(url, '_blank');
          return;
        }

        let variable = linkConfig?.variable;
        if (this.machineData?.[variable] != null) window.open(this.machineData?.[variable], '_blank');

        return;
    }
  }

  ngOnChanges() {
    if (this.appConfig.MAT2) this.initCardInfos();
  }

  // Last updates dialog
  openLastUpdatesDialog() {
    this.dialog.open(LastUpdatesDetailDialogComponent, {
      panelClass: 'ff-dialog',
      width: "30%",
      data: {
        machine: this.machineData,
        list: this.machineData.lastUpdatesDetail,
        title: this.translate.instant("HOMEPAGE.LAST_UPDATES_DETAIL"),

      },
    });
  }

  ngOnDestroy() {
    try { this.homepageDataSub.unsubscribe() } catch (error) { }
  }

}
