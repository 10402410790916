import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'ff-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.scss']
})
export class MonitorComponent implements OnInit {
  @Input() props: any;
  color: any;

  constructor(
    private filterService: FiltersService
  ) { }

  ngOnInit(): void {
    this.color = this.getColor(this.props)
  }

  ngOnChanges(changes: SimpleChange): void {
    this.color = this.getColor(this.props)
  }

  getColor(props: any) {
    let value = props.value != null ? this.filterService.parseNumber(props.value) : null
    let colorConfigs = props.colorConfigs != null ?  props.colorConfigs : null
    let color = 'grey'
    if (value != null && colorConfigs != null) {
      
      let ix = colorConfigs.findIndex((config: any) => config.id == value)
      if (ix != -1) color = colorConfigs[ix].color
    }
    return color 
  }

}
