<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 }">

    <!-- toolbar -->
    <!-- <div class="ff-toolbar pb-3" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">

      <span fxFlex fxShow="false" fxShow.gt-sm></span> -->

    <!-- aggregation -->
    <!-- <div class="select-box" fxLayout="row" fxLayoutAlign="start center" style="margin-right: 1rem;">
        <mat-icon class="icon">local_offer</mat-icon>
        <mat-form-field appearance="fill">
          <mat-label>{{'INTERVAL.OFFSET'|translate}}</mat-label>
          <mat-select [(value)]="aggrId">
            <mat-option *ngFor="let aggr of aggrList" [value]="aggr.id" (click)="updateAggrId(aggr.id)">
              {{aggr.label|translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

    <!-- </div> -->

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < 6" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 6" fxLayout="column" class="w100 h100">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
        class="h100 w100" fxLayoutGap="8px">

        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="w100 h100" fxLayoutGap="8px">
          <ff-widget fxFlex="100"
            [widget]="{type: 'ff-plotly-chart-table', data: breakdownsData, machineProfile: machine.profile, config: breakdownsData.dataConfig }">
          </ff-widget>
        </div>

        <div fxFlex.gt-sm="{{dashboardConfig.secondColumnFlex}}" fxLayout="column" fxLayoutAlign="start stretch"
          class="w100 h100" fxLayoutGap="8px">

          <ff-widget *ngFor="let w of dashboardConfig.widgets" fxFlex.gt-sm="{{w.flex}}"
            [widget]="{ type: w.type, data: breakdownsData, machineProfile: machine.profile, config: w.config, title: w.title }">
          </ff-widget>

        </div>

      </div>

    </div>

  </div>

</div>
