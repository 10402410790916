import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

@Component({
  selector: 'ff-filters-dialog',
  templateUrl: './filters-dialog.component.html',
  styleUrls: ['./filters-dialog.component.scss']
})
export class FiltersDialogComponent implements OnInit {

  public dialog: any;
  public maxItemsSelectable: any;
  availableOptions: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogUnparsed: any,
    public apiService: ApiService,
    public translate: FfTranslateService,
    public clonerService: ClonerService,
  ) {
    this.dialog = this.clonerService.deepClone(this.dialogUnparsed);
    this.maxItemsSelectable = this.dialog?.config?.maxSel;
    this.updateAllSelected();
  }

  allSelected: boolean = true;

  updateAllSelected(item?: any) {

    // If no parameter maxSel is configured
    if (this.maxItemsSelectable == null) {
      if (item != null) item.selected = !item.selected;
      this.checkAllSelected();
      return;
    }

    // maxSel
    if (item == null) {
      this.updateSelectedList();
      return;
    }

    item.selected = !item.selected;
    this.updateSelectedList();

  }

  updateSelectedList() {

    let selectedItemsLength = this.dialog.options?.filter(t => t.selected)?.length;

    // console.log(selectedItemsLength);

    // If the default number of items selected is greater than the maximum limit, remove all the values in excess
    if (selectedItemsLength >= this.maxItemsSelectable) {

      this.dialog.options = this.dialog.options.reduce((acc, val) => {
        // If the value is not selected, add the value as unselected and disabled
        if (!val.selected) acc.push({ ...val, ...{ disabled: true } });

        // If the value is selected and the number of selected items is lower than the maximum limit, add the value as selected and not disabled
        else if (acc.filter(x => x.selected).length < this.dialog.config.maxSel) acc.push({ ...val, ...{ disabled: false } });

        // If the value is selected and the number of selected items is HIGHER than the maximum limit, add the value as not selected and disabled
        else acc.push({ ...val, ...{ selected: false, disabled: true } });

        return acc;
      }, []);
    }
    else {
      this.dialog.options.forEach(t => t.disabled = false);
    }

    this.checkAllSelected();

  }

  checkAllSelected() {
    if (this.maxItemsSelectable == null) {
      this.allSelected = this.dialog.options?.every((t: any) => t.selected);
      return;
    }
    this.allSelected = this.dialog.options.filter((t: any) => t.selected).length == this.maxItemsSelectable;
  }

  atLeastOneSelected(): boolean {
    if (this.dialog.options == null) {
      return false;
    }

    return this.dialog.forceSelection || this.dialog.options.filter((t: any) => t.selected).length > 0;
  }

  setAll(selected: boolean) {
    this.allSelected = !selected;
    if (this.dialog.options == null) {
      return;
    }
    this.dialog.options.forEach((t: any) => t.selected = !selected);
    this.updateAllSelected();
  }

  searchFromList(searchEvent) {
    this.availableOptions = searchEvent.target.value;
  }

  ngOnInit() { }

}
