import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-copy-week-dialog',
  templateUrl: './copy-week-dialog.component.html',
  styleUrls: ['./copy-week-dialog.component.scss']
})
export class CopyWeekDialogComponent implements OnInit, OnDestroy {

  public totalWeeks: any = null;
  public maxNumberOfWeeksToCopy: any;
  appConfig: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<CopyWeekDialogComponent>,
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public clonerService: ClonerService,
  ) {

    this.appConfig = this.appConfigService.getAppConfig;

    this.maxNumberOfWeeksToCopy = this.appConfig.calendar?.maxNumberOfWeeksToCopy ?? 104;

  }

  closeDialog() {
    this.dialogRef.close(this.dialog.totalWeeks);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

}
