<div mat-dialog-title fxLayout="row" fxLayoutAlign="start center" class="w100">
  <mat-icon class="icon">add</mat-icon>
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>

<mat-dialog-content>

  <div fxLayout="column">

    <div fxLayout="row" fxLayoutAlign="start center">

      <span fxFlex class="minor-title">{{ 'RBA.EVENT_TYPE' | translate }}</span>
      <span fxFlex></span>

      <div fxFlex class="ff-input-select radius-2 higher">
        <mat-select [(value)]="newEvent.alertType" (selectionChange)="onAlertTypeChange(newEvent.alertType)">
          <mat-option *ngFor="let alertType of dialog.alertsConfig" [value]="alertType.alertType">
            <span>{{ alertType.label | translate }}</span>
          </mat-option>
        </mat-select>
      </div>
    </div>

    <div class="divider"></div>

    <div fxLayout="row" fxLayoutAlign="start center" style="padding-bottom: 12px;">

      <mat-icon class="side left {{newEvent.typeIcon.class}}"
        style="padding-right: 10px; background-color: transparent !important">
        {{newEvent.typeIcon.icon}}
      </mat-icon>
      <span>
        <strong [translate]="newEvent.name" [translateParams]="{
                          xVarBool: (newEvent.parameters[0]?.valueLabel ?? newEvent.parameters[0].value ?? '----') | replace:['_{assetId}_', '_'],
                          xVarNum: (newEvent.parameters[0]?.valueLabel ?? newEvent.parameters[0].value ?? '----') | replace:['_{assetId}_', '_'],
                          xVarKPI: newEvent.parameters[0]?.kpiLabel ?? '-'
                      }">
        </strong>
      </span>

    </div>

    <div fxLayout="row" fxLayoutAlign="start center">

      <span class="subtitle" [translate]="newEvent.description" [translateParams]="{
          xVarBool: (newEvent.parameters[0]?.valueLabel ?? newEvent.parameters[0].value ?? '----') | replace:['_{assetId}_', '_'],
          xBool: newEvent.parameters[2]?.value ?? '-',
          xMinBool: newEvent.parameters[3]?.value ?? '-',
          xVarNum: (newEvent.parameters[0]?.valueLabel ?? newEvent.parameters[0].value ?? '----') | replace:['_{assetId}_', '_'],
          xUnitNum: newEvent.parameters[2]?.unit ?? '',
          xOpNum: newEvent.parameters[1]?.value ?? '-',
          xNum: newEvent.parameters[2]?.value ?? '-',
          xMinNum: newEvent.parameters[3]?.value ?? '-',
          xVarKPI: newEvent.parameters[0]?.kpiLabel ?? '-',
          xUnitKPI: newEvent.parameters[2] != null ? ( filterService.convertUnit(newEvent.parameters[2].unit).unit | translate ): '-', 
          xOpKPI: newEvent.parameters[1]?.value ?? '-',
          xKPI: newEvent.parameters[2]?.value ?? '-',
          xMinKPI: newEvent.parameters[3]?.value ?? '-',
          xAggrType: newEvent.parameters[5]?.valueLabel ?? '-',
          xTimeAggr: newEvent.parameters[4] != null ? ( ('GLOBAL.' + newEvent.parameters[4].value) | checkTranslation:'-':false): '-',
          nCycles: newEvent.parameters[1]?.value ?? '-',
          sapCode: (newEvent.parameters[2] != null && newEvent.parameters[2].value != undefined && newEvent.parameters[2].value !== '') ? (('ALERTS.WITH_SAP_CODE' | translate) + ' ' + newEvent.parameters[2].value) : '' 
      }">
      </span>

    </div>

    <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 12px;" class="clickable"
      (click)="newEvent.checked = !newEvent.checked;">

      <mat-icon *ngIf="newEvent.checked" svgIcon="radio_button" class="icon small left clickable radio_button">
      </mat-icon>
      <mat-icon *ngIf="!newEvent.checked" svgIcon="radio_button_disabled"
        class="icon small left clickable radio_button_disabled">
      </mat-icon>

      <span>{{ 'RBA.ALERT_ACTIVATION_NOTIFY' | translate }}</span>

    </div>


    <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 12px;" class="clickable"
      (click)="newEvent.deactivationAlert = !newEvent.deactivationAlert;">

      <mat-icon *ngIf="newEvent.deactivationAlert" svgIcon="radio_button" class="icon small left radio_button">
      </mat-icon>
      <mat-icon *ngIf="!newEvent.deactivationAlert" svgIcon="radio_button_disabled"
        class="icon small left radio_button_disabled">
      </mat-icon>

      <span>{{ 'RBA.ALERT_DEACTIVATION_NOTIFY' | translate }}</span>

    </div>

    <div *ngIf="isCalendarPresent && newEvent.alertType == 'kpiVar'" fxLayout="row" fxLayoutAlign="start center"
      style="margin-top: 12px;" [class.ff-disabled]="newEvent.shiftAggrSelected"
      [class.clickable]="!newEvent.shiftAggrSelected" class="completely" [disabled]="newEvent.shiftAggrSelected"
      (click)="newEvent.useCalendar = !newEvent.useCalendar;onCalendarChange()">

      <mat-icon *ngIf="newEvent.useCalendar" svgIcon="radio_button" class="icon small left radio_button">
      </mat-icon>
      <mat-icon *ngIf="!newEvent.useCalendar" svgIcon="radio_button_disabled"
        class="icon small left radio_button_disabled">
      </mat-icon>

      <span>{{ 'RBA.ALERT_USE_CALENDAR' | translate }}</span>

    </div>

    <div *ngIf="dialog.availableMachines != null && dialog.availableMachines.list != null && dialog.availableMachines.list.length > 1 && 
        newEvent.addMachineSelectionParameter && newEvent.alertType == 'kpiVar'" style="margin-top: 12px;">

      <span><strong>{{ 'RBA.SELECT_MACHINES' | translate }}</strong></span>
      <ul class="ff-ul" style="overflow: auto; max-height: 96px;">
        <li *ngFor="let item of dialog.availableMachines.list" fxLayout="row" fxLayoutAlign="start center"
          class="clickable" (click)="toggle(item, newEvent.machines)" style="margin: 6px 0;">
          <mat-icon *ngIf="exists(item, newEvent.machines)" svgIcon="radio_button" class="icon left radio_button"
            [ngClass]="item.class">
          </mat-icon>
          <mat-icon *ngIf="!exists(item, newEvent.machines)" svgIcon="radio_button_disabled"
            class="icon left radio_button_disabled">
          </mat-icon>
          <span fxFlex>{{item}}</span>
          <mat-icon class="icon" *ngIf="item.color" [ngStyle]="{ 'color': item.color }">circle</mat-icon>
        </li>
      </ul>

    </div>

    <table *ngIf="newEvent.parameters.length > 0" class="w100" style="margin-top: 12px;">
      <thead>
        <tr>
          <th class="col-icon-lg">
          <th>
            <span>{{ 'RBA.ALERTS.NAME' | translate }}</span>
          </th>
          <th>
            <span>{{ 'RBA.ALERTS.DESCRIPTION' | translate }}</span>
          </th>
          <th>
            <span>{{ 'RBA.ALERTS.VALUE' | translate }}</span>
          </th>
          <th>
            <span>{{ 'RBA.ALERTS.UNIT' | translate }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let parameter of newEvent.parameters">
          <td class="col-icon-lg">
            <mat-icon class="icon">equalizer</mat-icon>
          </td>
          <td md-cell class="primary">
            <span class="break-word">{{parameter.label | placeholder:'-'}}</span>
          </td>
          <td md-cell>
            <span class="break-word">{{parameter.description | placeholder:'-'}}</span>
          </td>
          <td md-cell>
            <div *ngIf="parameter.type == 'dialog'" class="ff-input-select"
              [ngClass]="{'clickable': isAllowedUserWrite}" (click)="openEditVariable(newEvent, parameter)">

              <span [ngClass]="{'md-red-i': parameter.value == null}">
                {{ parameter.valueLabel != null ? parameter.valueLabel : (parameter.value != null ?
                parameter.value : 'GLOBAL.REQUIRED') | translate | replace:['_{assetId}_', '_'] }}
              </span>
              <span fxFlex></span>
              <span style="margin-left: 8px;">
                <mat-icon svgIcon="touch"></mat-icon>
              </span>

            </div>
            <!-- <div *ngIf="parameter.type == 'dialog'" class="ff-input-select" #tooltip="matTooltip"
              matTooltip="{{ 'RBA.INFO_PARAMETER' | translate }}" matTooltipPosition="left">

              <mat-icon class="icon">info</mat-icon>

            </div> -->

            <md-input-container class="padding0 margin0 height100" style="margin-top: 12px !important;">

              <input *ngIf="parameter.type == null && (parameter.inputType == 'text')" class="ff-input"
                [(ngModel)]="parameter.value" type="text" [required]="!parameter.notRequired"
                name="{{newEvent.id}}-{{parameter.label}}" [disabled]="!isAllowedUserWrite"
                aria-label="{{parameter.label}}">

              <input *ngIf="parameter.type == null && (parameter.inputType == null || parameter.inputType == 'number')"
                class="ff-input" [(ngModel)]="parameter.value" type="number" [required]="!parameter.notRequired"
                [min]="parameter.min" [max]="parameter.max" name="{{newEvent.id}}-{{parameter.label}}"
                [disabled]="!isAllowedUserWrite" aria-label="{{parameter.label}}">

              <div *ngIf="parameter.type == 'dropdown'" class="ff-input-select">
                <mat-select [(value)]="parameter.value" (selectionChange)="onVariableChange(newEvent, parameter)"
                  name="{{newEvent.id}}-{{parameter.label}}"
                  (openedChange)="parameter.searchText = null;parameter.availableOptions = null;"
                  [disabled]="(newEvent.alertType == 'boolVar' && parameter.paramType == 'OPERATOR') || (newEvent.alertType == 'cloths' && parameter.paramType == 'OPERATOR') || !isAllowedUserWrite">

                  <div *ngIf="parameter?.addSearchFilter" class="search-input" fxLayout="row"
                    fxLayoutAlign="start center">
                    <mat-icon class="icon">search</mat-icon>
                    <input type="text" class="w100" (keyup)="searchFromList($event, parameter)"
                      placeholder="{{'GLOBAL.SEARCH'|translate}}" [(ngModel)]="parameter.searchText">
                  </div>

                  <mat-option
                    *ngFor="let variable of parameter.list | filterByProperty:['disabledIf', '!=', newEvent.alertType] | customSearchFilter: parameter.availableOptions:['kpi', 'label', 'translLabel']"
                    [value]="variable.kpi != null ? variable.kpi : variable" [disabled]="variable.disabled">
                    <span *ngIf="parameter.paramType == 'OPERATOR'">{{ variable }}</span>
                    <span *ngIf="parameter.paramType != 'OPERATOR'">
                      {{ variable.label | translate }}
                    </span>
                  </mat-option>

                  <div
                    *ngIf="!(parameter.list | filterByProperty:['disabledIf', '!=', newEvent.alertType] | customSearchFilter: parameter.availableOptions:['kpi', 'label', 'translLabel']).length"
                    style="padding: 8px 16px;">
                    <span>
                      {{ (parameter.noDataLabel ?? 'RBA.NO_VARIABLE_AVAILABLE') | translate }}
                    </span>
                  </div>

                </mat-select>

              </div>


            </md-input-container>

          </td>
          <td><span class="break-word">{{ filterService.convertUnit(parameter.unit).unit | translate | placeholder:'-'
              }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button class="filter-button md-primary" (click)="closeDialog()">
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</mat-dialog-actions>