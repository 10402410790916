import { Component, Input, OnInit } from '@angular/core';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'ff-url-path',
  templateUrl: './ff-url-path.component.html',
  styleUrls: ['./ff-url-path.component.scss']
})
export class FfUrlPathComponent implements OnInit {

  @Input() widget: any;

  urlPath: any = [{}];

  constructor(public internalDataService: InternalDataService) { }

  navigateToPath(path, index) {

    if (!this.urlPath?.length || this.urlPath?.length <= 1) return;
    this.urlPath.splice(index + 1);

    this.internalDataService.setCustomSubscription({
      clickFunction: "handleResponse",
      data: path,
    });

  }

  navigateToPreviousPath() {

    if (!this.urlPath?.length || this.urlPath?.length <= 1) return;
    this.urlPath.splice(this.urlPath.length - 1, 1);

    let path = null;
    try { path = this.urlPath.at(-1) }
    catch (error) { path = null }

    this.internalDataService.setCustomSubscription({
      clickFunction: "handleResponse",
      data: path,
    });
  }

  ngOnInit(): void {
    this.urlPath = this.widget?.referenceComponent?.currentUrlPath ?? this.urlPath;
  }

}
