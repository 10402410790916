import { Component, Inject, OnDestroy, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-single-input-dialog',
  templateUrl: './single-input-dialog.component.html',
  styleUrls: ['./single-input-dialog.component.scss']
})
export class SingleInputDialogComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<SingleInputDialogComponent>,
  ) { }

  closeDialog() { this.dialogRef.close(this.dialog?.row) }

  ngOnInit() { }

  ngOnDestroy() { }

}
