import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

import { IntervalService } from 'src/app/services/interval.service';

import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import {
  ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexTitleSubtitle, ApexXAxis,
  ApexYAxis
} from "ng-apexcharts";
import { ClonerService } from 'src/app/services/clone.service';
import { CycleComparisonDialogComponent } from '../cycle-comparison-dialog/cycle-comparison-dialog.component';

export type ChartOptions = {
  annotations: ApexAnnotations,
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  grid: ApexGrid,
  tooltip: any // to be simple I made it as any, can be replaced with the proper className
};

@Component({
  selector: 'app-cycle-detail-timeline',
  templateUrl: './cycle-detail-timeline.component.html',
  styleUrls: ['./cycle-detail-timeline.component.scss']
})
export class CycleDetailTimelineComponent implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public backButton: any;
  public tabs: any;

  public cycleType: any = 0;
  public cycleId: any;
  public cycleDay: any;
  public cycleStart: any;
  public cycleEnd: any;
  public cycleSelectedSub: Subscription;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public pollingTime: any;
  public pollingMachines: any;

  public currentSynopticId: any;
  public synopticConfig: any;
  public synopticConfigDefault: any;
  public monitoringData: any;
  public monitoringDataUnparsed: any;
  public dashboardConfig: any;

  public cycleInfoConfig: any = {
    "gap": "8px",
    "widgets": [
      {
        "type": "ff-value",
        "flex": 18,
        "config": [
          {
            "variable": "cycleId",
            "label": "CYCLE_TIMELINE.CYCLE_ID",
            "iconClass": "md-primary",
            "icon": {
              "icon": "receipt",
              "type": "icon"
            }
          }
        ]
      },
      {
        "type": "ff-value",
        "flex": 18,
        "config": [
          {
            "variable": "cycleStart",
            "label": "CYCLE_TIMELINE.CYCLE_START",
            "iconClass": "md-primary",
            "icon": {
              "icon": "play_arrow",
              "type": "icon"
            }
          }
        ]
      },
      {
        "type": "ff-value",
        "flex": 18,
        "config": [
          {
            "variable": "cycleEnd",
            "label": "CYCLE_TIMELINE.CYCLE_END",
            "iconClass": "md-primary",
            "icon": {
              "icon": "stop",
              "type": "icon"
            }
          }
        ]
      },
      {
        "type": "ff-value",
        "flex": 18,
        "config": [
          {
            "variable": "cycleDuration",
            "label": "CYCLE_TIMELINE.CYCLE_DURATION",
            "format": "time",
            "iconClass": "md-primary",
            "icon": {
              "icon": "timer_quarter",
              "type": "svg"
            }
          }
        ]
      },
      {
        "type": "ff-bar",
        "flex": 18,
        "config": [
          {
            "variable": "gca",
            "label": "CYCLES.MEAN_GCA_EVALUATION",
            "iconClass": "md-blue",
            "icon": {
              "icon": "health",
              "type": "svg"
            }
          }
        ]
      },
      {
        "type": "ff-led",
        "flex": 10,
        "config": {
          "variable": "trainSet",
          "label": "CYCLE_TIMELINE.TRAINING_CYCLE",
          "trueColor": '#4BDD7D',
          "falseColor": '#5f6de8',
        }
      },
      {
        "type": "ff-value",
        "flex": 16.6,
        "config": [
          {
            "variable": "alarmTime",
            "label": "CYCLE_TIMELINE.ALARM_TIME",
            "format": "time",
            "iconClass": "md-red-i",
            "icon": {
              "icon": "warning",
              "type": "icon"
            }
          }
        ]
      },
      {
        "type": "ff-value",
        "flex": 16.6,
        "config": [
          {
            "variable": "signalationTime",
            "label": "CYCLE_TIMELINE.SIGNALATION_TIME",
            "format": "time",
            "iconClass": "md-orange-i",
            "icon": {
              "icon": "warning",
              "type": "icon"
            }
          }
        ]
      },
      {
        "type": "ff-value",
        "flex": 16.6,
        "config": [
          {
            "variable": "waitingTime",
            "label": "CYCLE_TIMELINE.WAITING_TIME",
            "format": "time",
            "iconClass": "md-primary",
            "icon": {
              "icon": "waiting_time",
              "type": "svg"
            }
          }
        ]
      },
      {
        "type": "ff-value",
        "flex": 16.6,
        "config": [
          {
            "variable": "techTime",
            "label": "CYCLE_TIMELINE.TECH_TIME",
            "format": "time",
            "iconClass": "md-primary",
            "icon": {
              "icon": "waiting_time",
              "type": "svg"
            }
          }
        ]
      },
      {
        "type": "ff-value",
        "flex": 16.6,
        "config": [
          {
            "variable": "processTime",
            "label": "CYCLE_TIMELINE.PROCESS_TIME",
            "format": "time",
            "iconClass": "md-primary",
            "icon": {
              "icon": "waiting_time",
              "type": "svg"
            }
          }
        ]
      },
      {
        "type": "ff-value",
        "flex": 16.6,
        "config": [
          {
            "variable": "maintTime",
            "label": "CYCLE_TIMELINE.MAINT_TIME",
            "format": "time",
            "iconClass": "md-primary",
            "icon": {
              "icon": "waiting_time",
              "type": "svg"
            }
          }
        ]
      }
    ]
  };
  public cycleData: any;

  public dialogData: any;
  public showDialog: boolean = false;
  public chartOptions: any;

  public interval: any;
  public intervalConfig: any;

  public alarmsInfo: any;
  public alarmsColumns: string[] = [];
  public alarms: any;
  public alarmsData: any;

  public signalationsInfo: any;
  public signalationsColumns: string[] = [];
  public signalations: any;
  public signalationsData: any;

  public eventsInfo: any;
  public eventsColumns: string[] = [];
  public events: any;
  public eventsData: any;

  public paramsRInfo: any;
  public paramsRColumns: string[] = [];
  public paramsR: any;
  public paramsRData: any;

  public phaseSelected: boolean = false;
  public phaseVariables: any;
  public actualParamsR: any;

  public comparedCycleDataConfig: any;
  public comparedCycleData: any;
  public comparedCycleInfos: any;
  public comparedCycleId: any;
  public isComparedCycle: boolean = false;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getCycleTimelineWidgets, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getCycleDetailPolling, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    // TODO AGGIUNGERE AGGREGAZIONI E FILTRI
    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.monitoringData = null;


    this.breadcrumb = ['CYCLE_TRACEABILITY.TITLE', 'CYCLE_TIMELINE.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs('cycleDetails');

    this.alarmsInfo = this.appConfig.machineMonitoring.alarmsInfo;

    this.alarmsColumns.push('icon');
    this.alarmsInfo.forEach((element: any) => {
      this.alarmsColumns.push(element.variable);
    });

    this.signalationsInfo = this.appConfig.machineMonitoring.alarmsInfo;

    this.signalationsColumns.push('icon');
    this.signalationsInfo.forEach((element: any) => {
      this.signalationsColumns.push(element.variable);
    });

    this.eventsInfo = this.appConfig.cycleDetails.cycleTimeline.eventInfo;

    this.eventsColumns.push('icon');
    this.eventsInfo.forEach((element: any) => {
      this.eventsColumns.push(element.variable);
    });

    this.paramsRInfo = this.clonerService.deepClone(this.appConfig.cycleDetails.cycleTimeline.paramsInfo);

    this.paramsRColumns = ['icon'].concat(this.paramsRInfo.filter((x: any) => this.isComparedCycle ? true : !x.compared).map((x: any) => x.variable))
    // .push('icon');
    // this.paramsRInfo.forEach((element: any) => {
    //   this.paramsRColumns.push(element.variable);
    // });

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        this.breadcrumb[2] = value.machineName;
        this.internalDataService.setBreadcrumb(this.breadcrumb);
      }
    });

    this.cycleSelectedSub = this.internalDataService.cycleSelected.subscribe(value => {
      if (value != null && !Array.isArray(value)) {
        this.breadcrumb[3] = (value.split("$$")[0]);
        this.internalDataService.setBreadcrumb(this.breadcrumb);
      }
    });

    this.pollingTime = 0;
    this.pollingMachines = Subscription;

    this.alarms = {
      list: [],
      selected: null,
      expanded: true,
    };

    this.alarmsData = new MatTableDataSource<any[]>(this.alarms.list);

    this.signalations = {
      list: [],
      selected: null,
      expanded: true,
    };

    this.signalationsData = new MatTableDataSource<any[]>(this.signalations.list);

    this.events = {
      list: [],
      selected: null,
      expanded: true,
    };

    this.eventsData = new MatTableDataSource<any[]>(this.events.list);

    this.paramsR = {
      list: [],
      selected: null,
      expanded: false,
    };

    this.paramsRData = new MatTableDataSource<any[]>(this.paramsR.list);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'cycleDetails');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getCycleTimelineWidgets(_this: any) {

    try {

      const sources40F = _this.appInfo.sources40F != null ? _this.appInfo.sources40F : 'assets/config/';

      _this.apiService.sendGetRequest(sources40F + 'dashboard-cycle-timeline.json').pipe(
        retryWhen(_this.apiService.genericRetryStrategy()),
        catchError(error => {

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError(
            'Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.dashboardConfig = data.body;

            _this.cycleInfoConfig = _this.dashboardConfig?.cycleWidgets != null ? _this.clonerService.deepClone({
              widgets: _this.dashboardConfig?.cycleWidgets,
              gap: _this.dashboardConfig?.gap
            }) : _this.cycleInfoConfig;

            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SYNOPTIC CONFIG

  // polling
  getCycleDetailPolling(_this: any) {
    try {

      if (_this.pollingTime > 0) {
        _this.pollingMachines = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getCycleDetail(_this, count);
        });
      } else {
        _this.getCycleDetail(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getCycleDetail(_this: any, count?: any) {
    try {

      // let payload = {filters: {}} //_this.internalDataService.buildMachinePayload(machine);
      let url = '/apif/cycle-detail/cycle-timeline/' + _this.machineId;

      let query: any = {
        from: _this.cycleStart,
        to: _this.cycleEnd,
        cycleType: _this.cycleType,
        cycleId: _this.cycleId,
        tz: _this.machine.timezone
      };

      _this.apiService.sendGetRequest(url, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError(
            'Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            let height = 275;
            try {
              height = window.innerHeight - 530;
            } catch (error) {
              console.log(error);
            }

            _this.chartOptions = {
              chart: {
                height: height,
                type: 'rangeBar',
                animations: {
                  enabled: false
                },
                zoom: {
                  enabled: false
                },
                events: {
                  click: function (event: any, chartContext: any, config: any) {
                    // console.log(config);

                    if (config != null && config.dataPointIndex != -1 &&
                      _this.chartOptions.series[0].data[config.dataPointIndex] != null &&
                      _this.chartOptions.series[0].data[config.dataPointIndex].phaseStart != null
                    ) {
                      try {
                        _this.phaseSelected = true;
                        _this.monitoringData = _this.chartOptions.series[0].data[config.dataPointIndex];
                      } catch (error) {
                        console.log(error);
                      }
                    } else {
                      _this.phaseSelected = false;
                      _this.monitoringData = _this.monitoringDataUnparsed;
                    }
                  }
                },
                toolbar: {
                  show: false
                }
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  distributed: true,
                  dataLabels: {
                    hideOverflowingLabels: false
                  }
                }
              },
              dataLabels: {
                enabled: false,
                style: {
                  fontSize: '14px',
                },
                formatter: function (val: any, opts: any) {
                  // console.log (val, opts);
                  var label = opts.w.globals.labels[opts.dataPointIndex];
                  var a = moment(val[0]);
                  var b = moment(val[1]);
                  var diff = b.diff(a, 'seconds');
                  var diffHM = _this.filterService.parseTime(b.diff(a, 'seconds'), 's', 'HH:mm');
                  if (diff > 0) {
                    return diffHM;
                  }
                  // return label;
                },
                // style: {
                //   colors: ['#f3f4f5', '#fff']
                // }
              },
              xaxis: {
                type: 'datetime',
                labels: {
                  datetimeUTC: false,
                  show: true,
                }
              },
              tooltip: {
                enabled: true,
                custom: function (totalSeries: any) {

                  let series = totalSeries.series;
                  let seriesIndex = totalSeries.seriesIndex;
                  let dataPointIndex = totalSeries.dataPointIndex;
                  let w = totalSeries.w;

                  let startText = w.config.series[seriesIndex].data[dataPointIndex].y != null ?
                    '<p> <strong>' + _this.translate.instant("CYCLE_TIMELINE.START") + ': </strong>' + moment(w.config.series[seriesIndex].data[dataPointIndex].y[0]).format('MMM DD, YYYY HH:mm:ss') + '</p>' : '';

                  let endText = w.config.series[seriesIndex].data[dataPointIndex].y != null && w.config.series[seriesIndex].data[dataPointIndex].phaseEnd != '-' ?
                    '<p> <strong>' + _this.translate.instant("CYCLE_TIMELINE.END") + ': </strong>' + moment(w.config.series[seriesIndex].data[dataPointIndex].y[1]).format('MMM DD, YYYY HH:mm:ss') + '</p>' : '';

                  let durationText = w.config.series[seriesIndex].data[dataPointIndex].duration != null ?
                    '<p> <strong>' + _this.translate.instant("CYCLE_TIMELINE.DURATION") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].duration + '</p>' : '';

                  let phaseText = w.config.series[seriesIndex].data[dataPointIndex].label != null ?
                    '<p> <strong>' + _this.translate.instant("CYCLE_TIMELINE.PHASE") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].label + '</p>' : '';

                  let cycleText = w.config.series[seriesIndex].data[dataPointIndex].cycleId != null ?
                    '<p> <strong>' + _this.translate.instant("CYCLE_TIMELINE.CYCLE") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].cycleId + '</p>' : '';

                  let alarmText = w.config.series[seriesIndex].data[dataPointIndex].alarm != null ?
                    '<p> <strong>' + _this.translate.instant("CYCLE_TIMELINE.ALARM") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].alarm.code +
                    ' - ' + w.config.series[seriesIndex].data[dataPointIndex].alarm.message + '</p>' : '';

                  let alarmCodes = '';
                  if (w.config.series[seriesIndex].data[dataPointIndex].ids != null && w.config.series[seriesIndex].data[dataPointIndex].ids.length > 0) {
                    alarmCodes +=
                      '<p><strong>Codes: </strong></p><ul style="margin:0;">';
                    w.config.series[seriesIndex].data[dataPointIndex].ids.forEach((alarm: any) => {
                      alarmCodes += '<li>' +
                        '<p><strong>Code:</strong> ' + alarm.code + '</p>' +
                        '<p><strong>Message:</strong> ' + alarm.message + '</p>' +
                        '<p><strong>Start:</strong> ' + moment(alarm.start).format('MMM DD, YYYY HH:mm:ss') + '</p>' +
                        '<p><strong>End:</strong> ' + moment(alarm.end).format('MMM DD, YYYY HH:mm:ss') + '</p>' +
                        '</li>';
                    });
                    alarmCodes += '</ul>';
                    // endText = '';
                  }

                  let gcaText = w.config.series[seriesIndex].data[dataPointIndex].gca != null ?
                    '<p> <strong>' + _this.translate.instant("GCA") + ': </strong>' + _this.filterService.parseGaugeValue(w.config.series[seriesIndex].data[dataPointIndex].gca, 0, 100) + '%</p>' : '';
                  // console.log(alarmCodes);

                  return '<div class="arrow_box" style="background-color: ' + w.config.series[seriesIndex].data[dataPointIndex].fillColor + '80;">' +
                    startText +
                    endText +
                    durationText +
                    phaseText +
                    cycleText +
                    alarmText +
                    alarmCodes +
                    gcaText +
                    '</div>'
                },
              },
              yaxis: {
                show: true,
                labels: {
                  style: {
                    fontSize: '12px'
                  }
                }
              },
              grid: {
                row: {
                  colors: ['#f3f4f5', '#fff'],
                  opacity: 1
                }
              },
            }

            let series: any = [];

            if (data.body.hasOwnProperty('phases') && data.body.phases != null && data.body.phases.length > 0) {
              data.body.phases.sort(_this.filterService.sortByProperty('phaseId', null, true)).forEach((state: any, index: any) => {

                // state.gca = Math.random();
                let duration = state.end != null ? _this.filterService.parseTime(moment(state.end).diff(moment(state.start), 's'), 's', 'HH:mm:ss') :
                  _this.filterService.parseTime(moment().diff(moment(state.start), 's'), 's', 'HH:mm:ss');

                let stateConfig = null;
                let color = null;
                let label = null;
                let phaseId: any = null;
                try {
                  stateConfig = _this.machine.profile.phases.find((x: any) => x.inputPhases.includes(state.phaseId));
                  if (stateConfig != null) {
                    color = stateConfig.color;
                    label = _this.translate.instant(stateConfig.label);
                    phaseId = stateConfig.outputPhaseId;
                  }
                } catch (error) {
                  console.log(error);
                }

                // console.log(index, data.body.phases.length - 1);
                // let phaseEnd = (state.end == null || index == (data.body.phases.length - 1)) ? '-' : moment(state.end).format("DD MMM YYYY HH:mm:ss");
                // console.log(phaseEnd);

                let currentSeriesObj: any = {
                  x: label,
                  y: [
                    moment(state.start).utc(false).valueOf() - 0,
                    (state.end != null) ? moment(state.end).utc(false).valueOf() : moment().utc(false).valueOf(),
                  ],
                  cycleId: state.cycleId,
                  phaseStart: moment(state.start).format("DD MMM YYYY HH:mm:ss"),
                  phaseEnd: state.end != null ? moment(state.end).format("DD MMM YYYY HH:mm:ss") : '-',
                  phaseId: phaseId,
                  phaseVariables: _this.machine.profile.phaseVariables[phaseId].map((pc: any) => {
                    try {
                      let pvConf: any = {
                        type: 'ff-value',
                        // flex: 100 / _this.machine.profile.phaseVariables[phaseId].length,
                        config: [{
                          variable: pc.variable.split(".")[1],
                          // label: _this.translate.instant("DATAPOINTS." + _this.machineId + '.' + pc.variable.split(".")[0] + phaseId + "." + pc.variable.split(".")[1]),
                          label: _this.internalDataService.parseDatapointLabel(pc.variable.split(".")[0] + phaseId, pc.variable.split(".")[1], _this.machineId),
                          suffix: pc.unit != null ? pc.unit : "",
                          iconClass: "md-blue",
                          icon: {
                            icon: "info",
                            type: "icon"
                          }
                        }]
                      };

                      if (pc.decimals != null) pvConf.config[0].decimals = pc.decimals;
                      return pvConf;
                    } catch (error) {
                      console.log(error);
                      return null;
                    }
                  }).concat([{
                    type: 'ff-bar',
                    // flex: 100 / _this.machine.profile.phaseVariables[phaseId].length,
                    config: [
                      {
                        "variable": "gca",
                        "label": "CYCLES.MEAN_GCA_EVALUATION",
                        "iconClass": "md-blue",
                        "icon": {
                          "icon": "health",
                          "type": "svg"
                        }
                      }]
                  }]),
                  duration: duration,
                  label: label,
                  fillColor: color,
                  alarmTime: state.alarmTime,
                  signalationTime: state.signalationTime,
                  waitingTime: state.waitingTime,
                  maintTime: state.maintTime,
                  techTime: state.techTime,
                  processTime: state.processTime,
                  gca: state.gca,
                };

                Object.entries(state)?.forEach(([k, v]: any) => {
                  if (currentSeriesObj?.[k] == null) currentSeriesObj[k] = v;
                })

                try { currentSeriesObj.phaseVariables.forEach((pc: any) => currentSeriesObj[pc.config[0].variable] = state[pc.config[0].variable]) }
                catch (error) { console.log(error) }

                series.push(currentSeriesObj);

              });
            }

            // var max = 0;
            // data.body.phases.forEach((x: any) => {
            //   if (new Date(x.end).getTime() > max) {
            //     max = new Date(x.end).getTime();
            //   }
            // });

            // var min = Infinity;
            // data.body.phases.forEach((x: any) => {
            //   if (new Date(x.start).getTime() < min) {
            //     min = new Date(x.start).getTime();
            //   }
            // });

            // var alarmDuration = (max - min) / 200;

            if (data.body.alarms != null && data.body.alarms.length > 0) {
              data.body.alarms.forEach((alarm: any) => {

                if (alarm.start == null) {
                  return;
                }

                series.push({
                  x: _this.translate.instant("ALARMS.TITLE"),
                  y: [
                    new Date(alarm.start).getTime(),
                    new Date(alarm.end).getTime()
                  ],
                  phaseEnd: alarm.end != null ? moment(alarm.end).format("DD MMM YYYY HH:mm:ss") : '-',
                  ids: alarm.codes != null ? alarm.codes.map((x: any) => {
                    return {
                      code: _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'code', _this.machineId),
                      message: _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'message', _this.machineId),
                      start: x.start,
                      end: x.end
                    }
                  }) : null,
                  fillColor: "#f44336",
                });

              });
            }

            if (data.body.signalations != null && data.body.signalations.length > 0) {
              data.body.signalations.forEach((sign: any) => {

                if (sign.start == null) {
                  return;
                }

                series.push({
                  x: _this.translate.instant("SIGNALATIONS.TITLE"),
                  y: [
                    new Date(sign.start).getTime(),
                    new Date(sign.end).getTime()
                  ],
                  phaseEnd: sign.end != null ? moment(sign.end).format("DD MMM YYYY HH:mm:ss") : '-',
                  ids: sign.codes != null ? sign.codes.map((x: any) => {
                    return {
                      code: _this.internalDataService.parseWarningsLabel(x.alarmCode, 'code', _this.machineId),
                      message: _this.internalDataService.parseWarningsLabel(x.alarmCode, 'message', _this.machineId),
                      start: x.start,
                      end: x.end
                    }
                  }) : null,
                  fillColor: "#FCA10D",
                });

              });
            }

            // console.log(series);

            _this.chartOptions.series = [{ data: series }];

            _this.chartOptions.xaxis.min = new Date(_this.filterService.getMin(_this.chartOptions.series[0].data, 'y', 0)).getTime();
            _this.chartOptions.xaxis.max = new Date(_this.filterService.getMax(_this.chartOptions.series[0].data, 'y', 1)).getTime();

            _this.monitoringDataUnparsed = {
              phaseStart: "-",
              phaseEnd: "-",
              duration: "-",
              signalationTime: 0,
              waitingTime: 0,
              alarmTime: 0,
              maintTime: 0,
              techTime: 0,
              processTime: 0,
            };

            _this.monitoringDataUnparsed = { ..._this.monitoringDataUnparsed, ...data.body.total };

            _this.cycleData = { ..._this.cycleData, ..._this.monitoringDataUnparsed };
            _this.cycleData.type = data.body.phases.findIndex((phase: any) => phase.outputPhaseId == 13) != -1 ? 1 : 0;

            if (!_this.phaseSelected) _this.monitoringData = _this.monitoringDataUnparsed;

            _this.alarms.list = _this.parseAlarmsTable(data.body.alarmsTable);

            setTimeout(() => {
              _this.alarmsData = new MatTableDataSource<Object>(_this.alarms.list);
            }, 100);

            _this.signalations.list = _this.parseSignalationsTable(data.body.signalationsTable);

            setTimeout(() => {
              _this.signalationsData = new MatTableDataSource<Object>(_this.signalations.list);
            }, 100);

            _this.events.list = _this.parseEventsTable(data.body.eventsTable, _this.machine.profile.events, _this.machine.profile.phases);

            setTimeout(() => {
              _this.eventsData = new MatTableDataSource<Object>(_this.events.list);
            }, 100);

            _this.actualParamsR = _this.clonerService.deepClone(data.body.params);
            _this.paramsR.list = _this.parseParamsRTable(data.body.params, _this.machine.profile.paramsR);

            setTimeout(() => {
              _this.paramsRData = new MatTableDataSource<Object>(_this.paramsR.list);
            }, 100);


            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      console.log(error);
    }

  }

  getAlarmStr(code: any, attribute: string) {
    let codeTrKey = 'alarms.' + this.machineId + '.' + code + '.' + attribute;
    let codeTr = this.translate.instant(codeTrKey);
    if (codeTr === codeTrKey) {
      // custom attribute not found
      codeTrKey = 'alarms.default.' + code + '.' + attribute;
      codeTr = this.translate.instant(codeTrKey);
      if (codeTr === codeTrKey) {
        // default attribute not found]
        codeTr = code;
      }
    }
    return codeTr;
  }

  parseAlarmsTable(data: any) {
    let copyData: any = this.clonerService.deepClone(data ?? []);

    copyData.forEach((element: any) => {

      // element.isActive = element.timeEnd == null;
      // element.timeStartHours = moment(element.timeStart).diff(moment(element.timeStart).startOf("day"), 's');
      // element.timeEndHours = element.timeEnd != null ? moment(element.timeEnd).diff(moment(element.timeEnd).startOf("day"), 's') : 0;

      element.duration = element.duration ?? null;

      element.durationP = (element.duration != null) ?
        this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss') :
        // this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss', element.timeStart, element.timeEnd);
        '-';
      element.code = this.internalDataService.parseAlarmsLabel(element.alarmCode, 'code', this.machineId);
      if (!element.message) element.message = this.internalDataService.parseAlarmsLabel(element.alarmCode, 'message', this.machineId);

      if (element.machine != null) element.machineP = this.internalDataService.parseMachinesLabel(element.machine, null, this.machineId);

      element.timeStartP = this.filterService.parseMoment(element.start, 'default', this.machine.timezone);
      element.timeEndP = this.filterService.parseMoment(element.end, 'default', this.machine.timezone);

    });

    return copyData;
  }

  parseSignalationsTable(data: any) {

    let copyData: any = this.clonerService.deepClone(data ?? []);

    copyData.forEach((element: any) => {

      element.duration = element.duration ?? null;
      element.code = this.internalDataService.parseWarningsLabel(element.alarmCode, 'code', this.machineId);
      if (!element.message) element.message = this.internalDataService.parseWarningsLabel(element.alarmCode, 'message', this.machineId);

      if (element.machine != null) element.machineP = this.internalDataService.parseMachinesLabel(element.machine, null, this.machineId);

      element.timeStartP = this.filterService.parseMoment(element.start, 'default', this.machine.timezone);
      element.timeEndP = this.filterService.parseMoment(element.end, 'default', this.machine.timezone);

      element.durationP = (element.duration != null) ?
        this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss') :
        // this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss', element.timeStart, element.timeEnd);
        '-';

    });

    return copyData;
  }

  parseEventsTable(data: any, eventsConfig: any, phaseConfig: any) {
    if (data != null && data.length > 0) {

      data.sort(this.filterService.sortByProperty('start', null, true)).forEach((element: any) => {

        let evConf = eventsConfig.find((x: any) => x.eventId == element.eventId);

        element.timestampP = this.filterService.parseMoment(element.start, 'default');
        element.durationP = (element.duration != null && element.duration > 0) ?
          this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss') :
          // this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss', element.timeStart, element.timeEnd);
          '-';
        if (evConf != null) {
          element.eventName = this.translate.instant(evConf.label);
          if (evConf.icon1 != null) element.icon1 = evConf.icon1;
          if (evConf.icon2 != null) element.icon2 = evConf.icon2;

          // Phase info
          if (evConf.phaseIcon) {
            let phaseConf = phaseConfig.find((x: any) => x.inputPhases.includes(element.phaseId));
            if (phaseConf != null) {
              element.icon1 = phaseConf.icon;
              element.description = this.translate.instant(phaseConf.label);
              element.phaseId = phaseConf.outputPhaseId;
            }

          }
        }

      });
      return data;
    }
    return []
  }

  parseParamsRTable(data: any, paramsRConfig: any, comparedData?: any) {

    if (data != null && Object.keys(data) != null && Object.keys(data).length > 0) {

      let finalTable: any = [];
      Object.entries(data).forEach(([paramName, paramValue], paramIndex: any) => {

        let paramRConfIdx = paramsRConfig.findIndex((x: any) => x.variable == paramName);

        let paramRConf = paramRConfIdx != -1 ? paramsRConfig[paramRConfIdx] : null;

        if (paramName.startsWith("param") && paramRConf != null && !paramRConf.hide) {
          let comparedValue = null;
          let isChanged = null;
          if (comparedData != null) comparedValue = this.filterService.parseGaugeValue(comparedData[paramName], paramRConf.decimals != null ? paramRConf.decimals : 0, 1);
          if (comparedData != null) isChanged = comparedData[paramName] != paramValue;
          finalTable.push({
            // label: this.translate.instant("PARAMS_R." + this.machineId + "." + paramName),
            label: this.internalDataService.parseParamsRLabel(paramName, null, this.machineId),
            variable: paramName,
            value: this.filterService.parseGaugeValue(paramValue, paramRConf.decimals != null ? paramRConf.decimals : 0, 1),
            comparedValue: comparedValue,
            index: Number(paramName.split("param")[1]),
            isChanged: isChanged,
            unit: paramRConf != null ? paramRConf.unit : '-',
          });
        }

      });
      finalTable.sort(this.filterService.sortByProperty("index", 'asc', true));

      console.log(finalTable);

      return finalTable;
    }
    return []
  }

  openCycleComparison() {

    const cycleComparisonDialog = this.dialog.open(CycleComparisonDialogComponent,
      {
        panelClass: 'ff-dialog',
        width: '90%',
        height: '90%',
        data: {
          title: this.translate.instant("PROCESS_LOG.CYCLE_SELECTION"),
          machine: this.machine,
          appConfig: this.appConfig,
          actualCycleInfos: this.cycleData,
          comparedCycleInfos: this.comparedCycleInfos
        },
      });

    cycleComparisonDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          console.log(result);

          this.isComparedCycle = true;
          this.comparedCycleId = result.cycle.id;

          this.comparedCycleInfos = {
            cycleId: result.cycle.id != null ? result.cycle.id.toString() : 'unknown',
            cycleDay: moment(result.cycle.timeEnd).format("YYYY-MM-DD"),
            cycleStart: result.cycle.timeStartP,
            cycleEnd: result.cycle.timeEndP,
            cycleDuration: moment(result.cycle.timeEnd).diff(result.cycle.timeStart, 's'),
          };

          let addInfo: any = {
            cycleId: this.cycleId,
            comparedCycleId: this.comparedCycleId,
          }
          try {
            this.paramsRInfo.forEach((info: any) => {
              info.label = info.comparedLabel != null ? this.translate.instant(info.comparedLabel) + (info.addVariable != null ? (' (' + addInfo[info.addVariable] + ')') : '') : info.label
            });
          } catch (error) {
            console.log(error);
          }
          this.paramsRColumns = ['icon'].concat(this.paramsRInfo.filter((x: any) => this.isComparedCycle ? true : !x.compared).map((x: any) => x.variable));
          this.paramsR.list = this.parseParamsRTable(this.actualParamsR, this.machine.profile.paramsR, result.cycle.params);

          setTimeout(() => {
            this.paramsRData = new MatTableDataSource<Object>(this.paramsR.list);
          }, 100);

        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  removeComparedCycle() {
    this.isComparedCycle = false;
    this.comparedCycleId = null;
    this.comparedCycleInfos = null;
    this.paramsRInfo = this.clonerService.deepClone(this.appConfig.cycleDetails.cycleTimeline.paramsInfo);
    this.paramsRColumns = ['icon'].concat(this.paramsRInfo.filter((x: any) => this.isComparedCycle ? true : !x.compared).map((x: any) => x.variable));
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    let cycleUrl = this.route.snapshot.params['cycleId'];

    this.backButton = [this.machineId, "cycle-traceability", "cycles"];
    this.internalDataService.setBackButton(this.backButton);

    try {
      let splittedCycleUrl = cycleUrl.split("$$");
      this.cycleId = splittedCycleUrl[0];
      this.cycleStart = splittedCycleUrl[1];
      this.cycleEnd = splittedCycleUrl[2];
      this.cycleType = splittedCycleUrl[3];
      this.cycleDay = moment(this.cycleEnd).format("YYYY-MM-DD");
    } catch (error) {
      console.log(error);
    }
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId'];
        let cycleUrl = params['cycleId'];

        try {
          let splittedCycleUrl = cycleUrl.split("$$");
          this.cycleId = splittedCycleUrl[0];
          this.cycleStart = splittedCycleUrl[1];
          this.cycleEnd = splittedCycleUrl[2];
          this.cycleType = splittedCycleUrl[3];
          this.cycleDay = moment(this.cycleEnd).format("YYYY-MM-DD");

          this.cycleData = {
            cycleId: this.cycleId,
            cycleDay: this.cycleDay,
            cycleType: this.cycleType,
            cycleStart: this.filterService.parseMoment(this.cycleStart, 'default'),
            cycleEnd: this.filterService.parseMoment(this.cycleEnd, 'default'),
            cycleDuration: moment(this.cycleEnd).diff(this.cycleStart, 's'),
          };

        } catch (error) {
          console.log(error);
        }

        this.internalDataService.setCycleSelected(cycleUrl);

      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() {
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pollingMachines.unsubscribe() } catch (error) { }
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.cycleSelectedSub.unsubscribe() } catch (error) { }
    try { this.internalDataService.setBackButton([]) } catch (error) { }
  }

}