<!-- dashboard -->
<div *ngIf="dashboardType == null" class="w100" fxLayout.lt-sm="column"
  fxLayout="{{ asColumn ? 'column' : 'row' }} wrap" fxLayoutAlign="start stretch" fxLayoutGap="8px grid"
  fxLayoutGap.gt-sm="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
  <ff-widget *ngFor="let widget of dashboardConfig.widgets"
    [fxFlex.gt-sm]="'calc(' + widget.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
    [fxShow.lt-md]="!widget.hideMobile" [fxFlex.sm]="'calc(' + widget.mobileFlex + '% - 8px )'"
    [fxFlexOrder.sm]="widget.mobileOrder" [widget]="widget" (clicked)="tableAction($event)"
    (inputTag)="tableInputTagChange($event)">
  </ff-widget>
</div>

<div fxLayout="column" class="w100 h100" *ngIf="dashboardType == 'centralPlotLateralWidgets'"
  [ngStyle]="{'min-height': isSmThanTablet ? '0px' : 'calc(100vh - 270px)'}">

  <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
    class="h100 w100" fxLayoutGap="8px">

    <div fxFlex [fxShow.lt-md]="!dashboardConfig.mainWidget?.hideMobile" fxLayout="column" fxLayoutAlign="start stretch"
      fxFlexOrder.lt-md="2" class="w100" [class.h100]="!isSmThanTablet" fxLayoutGap="8px">
      <ff-widget fxFlex="100" [widget]="dashboardConfig.mainWidget">
      </ff-widget>
    </div>

    <div [fxFlex.gt-sm]="dashboardConfig.secondColumnFlex != null ? dashboardConfig.secondColumnFlex : 20"
      fxLayout="column" fxLayoutAlign="start stretch" fxLayout.gt-sm="column" fxLayoutAlign.gt-sm="start stretch"
      fxFlexOrder.lt-md="1" class="w100" [class.h100]="!isSmThanTablet" fxLayoutGap="8px">

      <ff-widget *ngFor="let widget of dashboardConfig.widgets" fxFlex="100"
        [fxFlex.sm]="'0 1 calc(' + (widget.mobileFlex != null ? widget.mobileFlex : 100) + '% - 12px)'"
        [fxFlex.gt-sm]="widget.flex" [widget]="widget" (clicked)="tableAction($event)"
        (inputTag)="tableInputTagChange($event)">
      </ff-widget>

    </div>

  </div>

</div>