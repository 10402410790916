import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';
import { CycleSelectionDialogNewComponent } from '../../machine-recorder/cycle-selection-dialog-new/cycle-selection-dialog-new.component';
import { CycleSelectionDialogComponent } from '../../machine-recorder/cycle-selection-dialog/cycle-selection-dialog.component';


@Component({
  selector: 'app-health-traceability',
  templateUrl: './health-traceability.component.html',
  styleUrls: ['./health-traceability.component.scss']
})
export class HealthTraceabilityComponent implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public backButton: any;
  public tabs: any;

  public HMComponentSelectedSub: Subscription;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public pollingTime: any;
  public pollingEvents: any;

  public currentSynopticId: any;
  public synopticConfig: any;
  public synopticConfigDefault: any;
  public monitoringData: any;
  public monitoringDataUnparsed: any;
  public dashboardConfig: any;

  public cycleInfoConfig: any;
  public cycleData: any;

  public dialogData: any;
  public showDialog: boolean = false;
  public chartOptions: any;

  public defaultInterval: any;

  public interval: any;
  public intervalConfig: any;

  public aggregations: any;
  public aggregationsPayload: any;

  public componentTableInfos: any = [];

  public tableInfos: any = [];
  public tableColumns: string[] = [];
  public table: any;
  public tableData: any;

  public componentConfig: any = {};
  public componentId: any;
  public machineRefId: any;

  public sectionName: any = "healthMonitoringDetail";
  public dashboardName: any = "health-traceability";

  public dashboardData: any = {};

  public availableMachines: any;

  public showResetInterval: boolean = false;
  public zoomedIntervalVariablesSub: Subscription;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  private sort!: MatSort;
  @ViewChild(MatSort) set MatSort(ms: MatSort) {
    this.sort = ms;
    this.setSort();
  }

  setSort() {
    this.tableData.sort = this.sort;
  }

  public cachedIntervalId: any = "intervalHM";

  public componentsHealthConfig: any = [
    {
      id: 0,
      label: "HEALTH_MONITORING.NOT_ANOMALOUS",
      class: "md-green-i",
      anomalyClass: "md-green-i",
      icon: {
        type: "icon",
        icon: "check"
      }
    },
    {
      id: 1,
      label: "HEALTH_MONITORING.ANOMALOUS",
      class: "md-red-i",
      anomalyClass: "md-red-i",
      icon: {
        type: "icon",
        icon: "close"
      }
    },
    {
      id: 2,
      label: "HEALTH_MONITORING.NOT_EVALUATED",
      class: "md-light-gray",
      anomalyClass: "md-light-gray",
      icon: {
        type: "icon",
        icon: "remove"
      }
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getComponentsConfig, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getHealthTraceabilityPolling, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  public isMobile: any;
  public isSmThanTablet: any;
  public mobileListener: Subscription;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public router: Router,
    public mobile: MobileService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));
    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile;
      this.isSmThanTablet = value.isSmThanTablet;
    })

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['HEALTH_MONITORING.TITLE', 'HEALTH_TRACEABILITY.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    try {
      this.tableInfos = this.appConfig[this.sectionName].tableInfos;
    } catch (error) {
      console.log(error);
    }

    try {
      this.componentTableInfos = this.appConfig.machineRecorder.cycleExploration.tableInfos;
    } catch (error) {
      console.log(error);
    }

    // this.tableColumns.push('icon');
    this.tableInfos.forEach((element: any) => this.tableColumns.push(element.variable));

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb[2] = value.machineName;
        this.breadcrumb = newBreadcrumb;
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.HMComponentSelectedSub = this.internalDataService.HMComponentSelected.subscribe(value => {
      if (value != null) {
        this.breadcrumb = this.internalDataService.getHMBreadcrumb(this, value);
        this.internalDataService.setBreadcrumb(this.clonerService.deepClone(this.breadcrumb));
      }
    });

    this.pollingTime = 0;
    this.pollingEvents = Subscription;

    this.table = {
      list: [],
      filtered: [],
      pageOptions: [7, 15, 25, 50, 100],
      pageSize: 7,
    };

    this.tableData = new MatTableDataSource<any[]>(this.table.list);

    this.zoomedIntervalVariablesSub = this.internalDataService.zoomedIntervalVariables.subscribe(value => {
      if (value != null) {

        this.showResetInterval = false;
        this.pageState.next(6);
        this.getHealthTraceabilityPolling(this, value);

      }
    });

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
    } catch (error) {
      console.log(error);
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET COMPONENTS CONFIG

  getComponentsConfig(_this: any) {

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getComponentsConfig(_this, _this.machineId, _this.machineRefId, _this.componentId, true);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getHealthTraceabilityPolling, this.getHealthTraceability, this.machine.timezone, 6, 7) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SYNOPTIC CONFIG

  // polling
  getHealthTraceabilityPolling(_this: any, range: any = null) {
    try {

      if (range == null) {
        if (_this.cacheService.get(_this.cachedIntervalId) == null) {

          _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

          _this.intervalConfig = {
            list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
            selected: _this.interval
          };

          _this.cacheService.set(_this.cachedIntervalId, _this.clonerService.deepClone(_this.intervalConfig));

        } else {
          _this.intervalConfig = _this.clonerService.deepClone(_this.cacheService.get(_this.cachedIntervalId));
          _this.interval = _this.intervalConfig.selected;
        }
      }

      if (_this.defaultInterval == null) _this.defaultInterval = _this.clonerService.deepClone(_this.interval);

      if (_this.pollingTime > 0) _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => _this.getHealthTraceability(_this, count, range));
      else _this.getHealthTraceability(_this, 0, range);

    } catch (error) {
      console.log(error);
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getHealthTraceability(_this: any, count?: any, range?: any) {
    try {

      let url = 'apif/condition-monitoring/analysis/' + _this.machineId + '/' + _this.componentId;

      if (range != null) {
        if (range.hasOwnProperty('from') && range.from != null) {// && range.hasOwnProperty('to') && range.to != null) {
          console.log('range', range);

          _this.showResetInterval = true;
          _this.interval.start = range.from;
          if (range.to != null) _this.interval.end = range.to;
          _this.intervalService.formatTimezone(_this.interval, _this.machine.timezone);

          // _this.interval.startDate = moment.tz(range.from, _this.machine.timezone).toDate(); //.add(100, 'ms').toDate();
          // _this.interval.endDate = moment.tz(range.to, _this.machine.timezone).toDate(); //.add(100, 'ms').toDate();
          // _this.interval.startP = moment.tz(range.from, _this.machine.timezone).format('MMM DD, YYYY - HH:mm:ss')
          // _this.interval.endP = moment.tz(range.to, _this.machine.timezone).format('MMM DD, YYYY - HH:mm:ss')
          // _this.interval.start = JSON.parse(JSON.stringify(_this.interval.startDate));

          // let lastEnd = _this.interval.end;
          // _this.interval.end = JSON.parse(JSON.stringify(_this.interval.endDate));

          // if (lastEnd != _this.interval.end) _this.interval.enabledPolling = false;

        } else {
          _this.showResetInterval = false;
          _this.interval = _this.clonerService.deepClone(_this.defaultInterval);
        }
      }


      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone,
      };

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      _this.internalDataService.buildAggregationsPayload(_this);
      // payload.filters = _this.aggregationsPayload;

      let payload = {
        filters: _this.aggregationsPayload
      }

      _this.apiService.sendPostRequest(url, payload, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            console.log(_this.componentConfig);

            _this.dashboardData = Object.assign(_this.clonerService.deepClone(_this.componentConfig), _this.parseData(data.body));
            _this.table.list = _this.parseTableData(_this.dashboardData.timeserie);

            try {
              _this.dashboardData.dataConfig = {};
              // set plot data and configs
              _this.dashboardData.plotData = _this.buildPlotConfig(_this, _this.dashboardData);
            } catch (error) { }

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
            _this.filterTableData();
          }
        );

    } catch (error) {
      console.log(error);
    }

  }

  parseData(data: any) {

    // COMPONENTS HEALTH CONFIG
    let componentsHealthConfig = this.machine.profile.componentsHealth ?? this.componentsHealthConfig;

    data.componentsHealthConfig = this.clonerService.deepClone(componentsHealthConfig);

    return data;

  }

  // PARSE TABLE
  parseTableData(data: any) {
    if (data != null && data.length > 0) {
      data.forEach((element: any) => {
        if (element.timeStart) {
          element.clickable = element.totalData > 0;
          element.timeStartP = this.filterService.parseMoment(element.timeStart, 'default');
          element.timeEndP = this.filterService.parseMoment(element.timeEnd, 'default');
          element.durationP = (element.duration != null) ?
            this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss') :
            // this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss', element.timeStart, element.timeEnd);
            '-';
        } else {
          element.clickable = false;
          element.timestampP = this.filterService.parseMoment(element.timestamp, 'default');
        }

        if (element.totalData) element.anomalyPerc = element.anomalousData / element.totalData;

      });
      return data;
    }
    return [];
  }

  filterTableData() {

    let filterVariables = this.appConfig[this.sectionName]?.filterVariables != null ? this.appConfig[this.sectionName].filterVariables : [];

    let filtered: any = this.clonerService.deepClone(this.table.list);

    // try {
    //   filtered = this.alarms.list.filter((x: any) => this.alarms.switch.checked ? x.isActive : true);
    // } catch (error) {
    //   console.log(error);
    // }

    // try {
    //   // filtered = filtered.filter((data: any) => filterVariables.some((x: any) => this.searchAlarms == null || this.searchAlarms == '' ? true : data[x].toLowerCase().includes(this.searchAlarms.toLowerCase())));
    //   filtered = filtered.filter((data: any) => filterVariables.some((x: any) => this.searchAlarms == null || this.searchAlarms == '' ? true : String(data[x]).toLowerCase().includes(this.searchAlarms.toLowerCase())));
    // } catch (error) {
    //   console.log(error);
    // }

    // try {
    //   if (this.filterButtons != null && this.filterButtons.length > 0) {
    //     this.filterButtons.forEach((filterButton: any) => {
    //       filtered = filtered.filter((data: any) => filterButton.options.filter((opt: any) => opt.selected).some((opt: any) => String(data[filterButton.variable]).includes(opt.id)));
    //     });
    //   }
    // } catch (error) {
    //   console.log(error);
    // }

    // if (this.sliderConf.min != null && this.sliderConf.max != null) {
    //   try {
    //     filtered = filtered.filter((x: any) => x.timeStartHours >= this.sliderConf.min && x.timeEndHours <= this.sliderConf.max);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    // try {
    //   console.log(this.sort);
    // } catch (error) {
    //   console.log(error);
    // }

    // if (this.isMobile) {

    //   let filteredMobile: any = this.clonerService.deepClone(filtered)

    //   filteredMobile.sort(this.filterService.sortByProperty(this.mobileData.sort.currentSortingProperty.orderBy, this.mobileData.sort.direction, true))

    //   this.mobileData.filtered = filteredMobile
    //   this.mobileData.collapsed = []

    //   this.mobileData.filtered.forEach((x: any) => {
    //     this.collapsed.some((y: any) => JSON.stringify(x) == JSON.stringify(y)) ? this.mobileData.collapsed.push(false) : this.mobileData.collapsed.push(true)
    //   });
    // }

    filtered.sort(
      this.filterService.sortByProperty(
        (this.sort != null && this.sort.active != null) ? this.sort.active : "timeStartP",
        'desc',
        // (this.sort != null && this.sort._direction != null) ? this.sort._direction : "desc",
        true
      )
    );

    this.table.filtered = filtered;

    const cd = new MatTableDataSource<any[]>();

    cd.sort = this.sort;
    cd.paginator = this.paginator;
    cd.data = filtered;

    this.tableData = cd;

  }

  // BUILD PLOT
  buildPlotConfig(_this: any, data: any) {

    if (data == null || !data.hasOwnProperty('timeserie') || data.timeserie == null || data.timeserie.length == 0) {
      return {
        layout: {},
        traces: []
      };
    }

    let traces: any = [];
    let dataSet: any = this.clonerService.deepClone(data.timeserie);

    let hoverInfo: any = dataSet.map((x: any) => {
      let hover: any = "";
      hover += "<b>" + this.translate.instant("HEALTH_MONITORING.HEALTH") + ':</b> ' + this.filterService.parseGaugeValue(x.value, 1, 100) + '%<br>';
      hover += "<b>" + this.translate.instant("HEALTH_MONITORING.THRESHOLD") + ':</b> ' + this.filterService.parseGaugeValue(data.threshold, 0, 100) + '%<br>';
      hover += "<b>" + this.translate.instant("HEALTH_MONITORING.DATE") + ':</b> ' + this.filterService.parseMoment(x.timestamp, 'default') + '<br>';
      return hover;
    });

    let timestamps = dataSet.map((x: any) => x.timestamp);
    let thresholds = dataSet.map(() => data.threshold);
    let values = dataSet.map((x: any) => x.value);

    traces.push({
      x: timestamps,
      y: values,
      name: this.translate.instant("HEALTH_MONITORING.HEALTH"),
      hoverinfo: 'text',
      hovertext: hoverInfo,
      type: "scatter",
      mode: values?.length > 1 ? 'lines' : 'lines+markers',
      line: {
        shape: "spline"
      },
      // marker: {
      //   size: 4,
      // }
    });

    traces.push({
      x: timestamps,
      y: thresholds,
      name: this.translate.instant("HEALTH_MONITORING.THRESHOLD"),
      hoverinfo: 'skip',
      type: "scatter",
      mode: thresholds?.length > 1 ? 'lines' : 'lines+markers',
      line: {
        color: "#FF5757",
        dash: "dash"
      }
    });

    let plotLayout: any = {
      showlegend: false,
      xaxis: {
        automargin: true,
        // showticklabels: false,
        showgrid: false,
        zeroline: false,
      },
      yaxis: {
        range: [-0.05, 1.05],
        // showticklabels: false,
        fixedrange: true,
        automargin: true,
        tickformat: '%',
        zeroline: false,
        showgrid: false
      },
      margin: {
        t: 0,
        r: 30,
        b: 0,
        l: 40,
        pad: 5
      }
    };
    return {
      layout: plotLayout,
      traces: traces,
      params: {
        displayModeBar: false,
        relayoutVariables: true,
      }
    };
  }

  openCycleSelection(row?: any) {

    console.log(row);

    let title = 'CYCLE_EXPLORATION.ACTUAL_CYCLE_SELECTION';

    let data = {
      title: this.translate.instant(title),
      isEnabledAD: true,
      machine: this.machine,
      machineId: this.machineId,
      componentId: this.componentId,
      tableInfos: this.componentTableInfos,
      availableMachines: this.availableMachines,
      aggregations: this.aggregations,
      isDaySelectable: false,
      customInterval: {
        start: moment(row.timeStart).format("YYYY-MM-DDTHH:mm:ss.SSS"),
        end: moment(row.timeEnd).format("YYYY-MM-DDTHH:mm:ss.SSS"),
      }
    };

    const selectionDialog: any = this.appConfig?.newCycleSelectionDialog ? CycleSelectionDialogNewComponent : CycleSelectionDialogComponent;
    const cycleSelectionDialog = this.dialog.open(selectionDialog,
      {
        panelClass: 'ff-dialog',
        width: '90%',
        height: '90%',
        data: data,
      });

    cycleSelectionDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {

          this.navigateToSelectedCycle(result.cycle);

        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  navigateToSelectedCycle(cycle: any) {

    let a = moment();
    let b = moment().subtract(365, 'days');

    let days: any = [];
    for (let m = moment(a); b.diff(m, 'days') < 0; m.subtract(1, 'days')) {
      days.push(moment(m).format("YYYY-MM-DD"));
    }

    // console.log(cycleParsed);

    this.cacheService.set("selectedCycle", cycle);
    this.cacheService.set("cycleDays", {
      list: days,
      selected: cycle.day,
    });

    let url: any = [this.machineId, 'health-monitoring', 'cycle-exploration'];
    this.router.navigate(url, { queryParams: { machineRefId: this.machineRefId, componentId: this.componentId } })

  }

  resetDefaultInterval() {
    this.interval = this.clonerService.deepClone(this.defaultInterval);
    this.showResetInterval = false;
    this.pageState.next(6);
    this.getHealthTraceability(this, 0);
  }


  openAggrDialog(aggr: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval)),
        measurement: "anomalyDetectionAggr",
        componentId: this.componentId
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        // console.log('afterClosed', result);
        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
        this.pageState.next(5);

        // if (this.appConfig?.[this.sectionName] != null && this.appConfig[this.sectionName].hasOwnProperty("sessionFilters")) {
        //   let sessionFilters: any = this.clonerService.deepClone(this.aggregations)
        //   this.cacheService.set('sessionFilters', sessionFilters)
        // }

        this.getHealthTraceabilityPolling(this);
      }

    });
  };

  async resetModelConfirm() {

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant('HEALTH_MONITORING.CONFIRM_RESET_MODEL'),
        description: this.translate.instant('HEALTH_MONITORING.CONFIRM_RESET_MODEL_WARNING'),
      },
    });

    confirmationDialog.afterClosed().subscribe(async (result: any) => {
      if (result != null && result != '') await this.resetModel();
    });
  }

  async resetModel() {

    let resp = null;
    let responseCode = 200;

    let url = `/apif/reset-model/${this.machineId}/${this.componentId}`;
    let payload = {};

    try {
      resp = await this.apiService.sendPostRequestAwait(url, payload);
      responseCode = resp.status;
      this.internalDataService.openSnackBar(this.translate.instant('HEALTH_MONITORING.MODEL_RESET_SUCCESS'), 'right', 'bottom', 4000, '', ["success"]);
    } catch (error) {
      console.log(error);
      responseCode = error.status;
      this.internalDataService.openSnackBar(this.translate.instant('ERRORS.SOMETHING_WENT_WRONG'), 'right', 'bottom', 4000, '', ["fail"]);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    let queryParams = this.route.snapshot.queryParams;

    this.backButton = [this.machineId, "health-monitoring"];
    this.internalDataService.setBackButton(this.backButton);

    this.componentId = queryParams.componentId;
    this.machineRefId = queryParams.machineRefId;

    this.route.params.subscribe((params: Params) => {

      this.machineId = params['machineId'];

      try {
        let queryParams = this.route.snapshot.queryParams;
        this.componentId = queryParams.componentId;
        this.machineRefId = queryParams.machineRefId;
      } catch (error) { console.log("Wrong query params") }

      this.internalDataService.setHMComponentSelected({
        machineRefId: this.machineRefId,
        componentId: this.componentId,
      });

    });

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pollingEvents.unsubscribe() } catch (error) { }
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.HMComponentSelectedSub.unsubscribe() } catch (error) { }
    try {
      this.internalDataService.setBackButton([]);
    } catch (error) { }
    try {
      this.internalDataService.setZoomedIntervalVariables(null);
      this.zoomedIntervalVariablesSub.unsubscribe();
    } catch (error) { }
  }

}