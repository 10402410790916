<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>

<!-- content -->
<div fxLayout="column" *ngIf="pageState.value >= 6" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>
  </div>

  <!-- toolbar -->
  <div class="ff-toolbar mb-3" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">
    <button class="mat-button rounded-button md-primary" (click)="onAlternate()"
      style="margin-right: 8px;" fxLayoutAlign="space-evenly center">
      <span *ngIf="!alternate" style="margin-right: 4px;">{{ 'PROGRAM_TRACEABILITY.SEE_PARENT_COMP' | translate }}</span>
      <span *ngIf="alternate" style="margin-right: 4px;">{{ 'PROGRAM_TRACEABILITY.SEE_PROGRAM' | translate }}</span>
      <mat-icon *ngIf="!alternate" class="icon">zoom_out_map</mat-icon>
      <mat-icon *ngIf="alternate" svgIcon="zoom_in_map" class="icon"></mat-icon>
    </button>
    
    <span *ngIf="!enabledIntervalAggr" fxFlex></span>

    <button *ngIf="!alternate" class="mat-button rounded-button md-light-gray" (click)="navigateToMachineRecorder()"
      style="margin-right: 8px;" #tooltip="matTooltip"
      matTooltip="{{ 'PROGRAM_TRACEABILITY.NAVIGATE_TO_MACHINEREC' | translate }}" matTooltipPosition="above"
      fxLayoutAlign="space-evenly center">
      <span>{{ 'CONTINUOUS_EXPLORATION.TITLE' | translate }}</span>
      <mat-icon class="icon">open_in_new</mat-icon>
    </button>

    <span *ngIf="enabledIntervalAggr" fxFlex></span>

    <!-- intervals -->
    <ff-interval-selector *ngIf="enabledIntervalAggr" [config]="intervalConfig" (selected)="selectInterval($event)"
      [intAggrs]="intervalAggregations" [hideIntervalSelector]="true" (selectedAggr)="selectAggregation($event)"></ff-interval-selector>
  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content without-tabs">

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < pageStateReady" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div fxLayout="column" class="w100" >

      <!-- dashboard -->
      <div *ngIf="prodsDataDash != null" class="w100 h100" fxLayout="row wrap" fxLayoutAlign="start stretch"
        fxLayoutGap="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
        <ff-widget *ngFor="let w of dashboardConfig.widgets"
          [fxFlex]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
          [widget]="{ type: w.type, subtype: w.subtype, 
                      customHeight: w.customHeight, customMinHeight: w.customMinHeight,
                      data: prodsDataDash, machineProfile: machine.profile, config: w.config, title: w.title }">
        </ff-widget>
      </div>

    </div>

  </div>

</div>
