<div mat-dialog-title fxLayout="row" class="w100">

  <!-- icon -->
  <span *ngIf="dialog.icon != null" style="margin-right: 8px;" fxLayout="row" fxLayoutAlign="start center">

    <!-- standard mat icon -->
    <ng-container *ngIf="dialog.icon?.type == null">
      <mat-icon class="icon">{{ dialog.icon }}</mat-icon>
    </ng-container>

    <!-- icon.type -->
    <ng-container *ngIf="dialog.icon?.type != null">
      <mat-icon *ngIf="dialog.icon.type == 'icon'" class="icon">
        {{dialog.icon.icon }}
      </mat-icon>
      <mat-icon *ngIf="dialog.icon.type == 'svg'" svgIcon="{{ dialog.icon.icon }}" class="icon"></mat-icon>
      <i *ngIf="dialog.icon.type == 'fontawesome' || dialog.icon.type == 'phosphor'"
        class="icon {{ dialog.icon.icon }}"></i>
    </ng-container>
  </span>

  <span fxLayout="column" fxLayoutAlign="center center">{{ dialog.title | translate }}</span>

  <span fxFlex></span>

  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>

</div>

<mat-dialog-content class="mat-typography" [class.alternative-color]="dialog.alternativeColor">

  <ff-loading-card *ngIf="loading" fxLayoutAlign="center center" fxLayout="column" class="w100 h100"
    [loadingData]="loadingData">
  </ff-loading-card>

  <ff-dashboard *ngIf="completeDashboardConfig != null" [completeDashboardConfig]="completeDashboardConfig">
  </ff-dashboard>

</mat-dialog-content>