<div [ngStyle]="{
  'padding.px': widget != null ? '16' : '0', 
  'height': widget != null ? 'calc(100% - 40px)' : '100%', 
  'width': widget != null ? 'calc(100% - 32px)' : '100%'
}">
  <span class="widget-gauge title" *ngIf="widget != null">{{ widget.title | translate }}</span>
  <div class="h100 w100" fxLayout="column" fxLayoutAlign="center center">
    <svg *ngIf="gaugeData != null && gaugeData.value != null" [attr.viewbox]="viewbox" [attr.width]="width"
      [attr.height]="height" xmlns="http://www.w3.org/2000/svg">

      <defs>
        <filter id="shadow">
          <feDropShadow dx="0" dy="0" stdDeviation="8" flood-color="rgba(119, 126, 136, 0.2)" />
        </filter>
      </defs>

      <circle class="widget-gauge background gauge-rotation" [attr.cx]="cxBack" [attr.cy]="cyBack" [attr.r]="rBack"
        [attr.filter]="filterBackground" />

      <circle class="widget-gauge circle-back gauge-rotation" [attr.stroke-width]="strokeWidth"
        [attr.stroke-linecap]="linecap" [attr.stroke-dasharray]="strokeDasharrayBack" [attr.cx]="cx" [attr.cy]="cy"
        [attr.r]="r" />

      <circle class="widget-gauge circle-main gauge-rotation" [attr.stroke]="color" [attr.stroke-width]="strokeWidth"
        [attr.stroke-linecap]="linecap" [attr.stroke-dasharray]="strokeDasharray" [attr.cx]="cx" [attr.cy]="cy"
        [attr.r]="r" />

      <text class="widget-gauge value" [attr.x]="width/2" [attr.y]="height/(gaugeData.label == null ? 2.14 : 2.5)"
        alignment-baseline="central" text-anchor="middle">
        {{ gaugeData.valueStr }}
      </text>

      <text *ngIf="gaugeData.label != null" class="widget-gauge label" [attr.x]="width/2" [attr.y]="height*0.6"
        alignment-baseline="central" text-anchor="middle">
        {{ gaugeData.label | translate }}
      </text>

    </svg>
  </div>
</div>