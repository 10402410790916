import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { MobileService } from 'src/app/services/mobile.service';

declare var Plotly: any;

@Component({
  selector: 'ff-plotly-chart-table',
  templateUrl: './plotly-chart-table.component.html',
  styleUrls: ['./plotly-chart-table.component.scss']
})
export class PlotlyChartTableComponent implements OnInit {

  @Input() widget: any;
  mobileListener: Subscription;

  randomId: any;
  plotState: any;
  realPlotPosition: any;
  sidenavWidth: any;
  flagsConfig: any;
  flagsNumber: any;

  tabs: any = [
    {
      "id": "chart",
      "title": "BREAKDOWNS.CHART",
      "icon": {
        "icon": "assessment",
        "type": "icon"
      },
      "selected": true
    },
    {
      "id": "table",
      "title": "BREAKDOWNS.TABLE",
      "icon": {
        "icon": "list_alt",
        "type": "icon"
      },
      "right": true
    }
  ];

  tableInfo: any;
  tableColumns: string[] = [];
  table: any;
  tableData = new MatTableDataSource<any[]>();

  exportTableConfig: any = {};

  isMobile: any;
  appConfig: any;
  appInfo: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(
    public appConfigService: AppConfigService,
    public translate: FfTranslateService,
    public cacheService: CacheService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public clonerService: ClonerService,
    public mobile: MobileService
  ) {

    this.appInfo = this.appConfigService.getAppInfo;
    this.appConfig = this.appConfigService.getAppConfig;
    this.table = {
      hideNoData: true,
      tableInfos: [],             // array of columns
      filterVariables: [],        // variables (properties of each row) filtables
      rows: [],                   // array of rows
      rowsFiltered: [],
      paginator: {
        options: [25, 50, 100],   // options of rows for page
        size: 50,                 // number of rows for page
        position: 'bottom right'  // position of paginator row
      },
      sort: {
        sortVariables: [],        // variables (properties of each row) sortables  
      },
      export: {
        filename: this.widget?.config?.title,
      }
    }

    this.randomId = generateRandomString();
    this.plotState = 0;

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile
    })
  }

  ngOnInit(): void {
    this.buildTable();
    this.buildPlot();
  }

  ngOnChanges(): void {
    this.buildTable();
    this.buildPlot(false);
  }

  buildTable() {
    // console.log('buildTable', this.widget);

    // TABLE

    if (!this.isMobile) this.tabs = this.widget?.config?.tabs ?? this.tabs;

    this.tableInfo = this.widget.data.tableInfo;
    this.tableColumns = this.widget.data.tableColumns;
    let columnToOrder = 'perc';

    try {
      if (this.widget.config.orderAttribute != null) columnToOrder = this.widget.config.orderAttribute;
      else columnToOrder = this.tableInfo.filter((x: any) => x.orderBy != null)[0]?.orderBy;
    } catch (error) { console.log(error) }

    let table = {

      tableInfos: this.tableInfo,             // array of columns
      filterVariables: [],        // variables (properties of each row) filtables
      rows: this.widget.data[this.widget.config.tableDataAttribute] ?? [],                   // array of rows
      rowsFiltered: this.widget.data[this.widget.config.tableDataAttribute] ?? [],
      paginator: {
        options: [25, 50, 100],   // options of rows for page
        size: 50,                 // number of rows for page
        position: 'bottom right'  // position of paginator row
      },
      sort: {
        sortVariables: this.tableInfo?.filter((info) => info.orderBy),        // variables (properties of each row) sortables
        variable: columnToOrder,    // variable to sort
        order: 'desc'
      },
      export: {
        filename: this.widget.config.title,
      },
      profile: this.widget?.machineProfile ?? this.widget?.profile,
      cardFrame: this.widget.config?.cardFrame != null ? this.widget.config?.cardFrame : true,
      completeCardFrame: this.widget.config?.completeCardFrame != null ? this.widget.config?.completeCardFrame : true,
      fixedDesktopView: this.widget.config?.fixedDesktopView
    }

    if (this.widget.additionalTableConfig != null && Object.keys(this.widget.additionalTableConfig)?.length > 0) {
      table = { ...table, ...this.widget.additionalTableConfig };
    }
    // console.log('buildTable', table)

    this.table = this.clonerService.deepClone(table);

    // TABLE


    // try {
    //   if (this.widget.config.orderAttribute != null) {
    //     columnToOrder = this.widget.config.orderAttribute;
    //   } else {
    //     columnToOrder = this.tableInfo.filter((x: any) => x.orderBy != null)[0].orderBy;
    //   }
    // } catch (error) {
    //   console.log(error);
    // }

    // this.table = {
    //   list: this.widget.data[this.widget.config.tableDataAttribute],
    //   pageOptions: [25, 50, 100],
    //   pageSize: 50,
    //   orderVariable: columnToOrder,
    // };

    // this.tableData = new MatTableDataSource<any[]>(this.table.list);

    // setTimeout(() => {
    //   this.tableData.sort = this.sort;
    //   this.tableData.paginator = this.paginator;
    // }, 50);

    // export table

    this.exportTableConfig = {
      title: this.widget.config.title,
      payload: {
        translations: {},
        rows: []
      }
    };

    try {
      let trnsl: any = {};
      this.tableInfo.forEach((info: any) => {
        if (info.label) trnsl[info.variable] = this.translate.instant(info.label) + (info.suffix != null ? (' [' + info.suffix + ']') : '')
      });
      this.exportTableConfig.payload.translations = trnsl;

      this.table.rows.sort(this.filterService.sortByProperty(columnToOrder, 'desc', true)).forEach((elem: any) => {
        let row: any = {};
        this.tableInfo.forEach((info: any) => {
          if (info.label) row[info.variable] = this.filterService.parseObjFromConfig(elem, info)
        });
        this.exportTableConfig.payload.rows.push(row);
      });
    } catch (error) {
      console.log(error);
    }
  }

  buildPlot(type?: any) {
    // console.log('buildPlot');

    // if (this.table == null || this.table.list == null || this.table.list.length <= 0) {
    //   this.plotState = 2;
    //   return;
    // }

    let plotData: any = {};

    let plotConfig: any = {
      responsive: true,
      displaylogo: false,
      modeBarButtonsToRemove: [
        // 2D plot
        "pan2d", "select2d", "lasso2d", "zoomIn2d", "zoomOut2d", "autoScale2d",
        // 3D plot
        "zoom3d", "pan3d", "orbitRotation", "tableRotation", "handleDrag3d", "resetCameraDefault3d", "resetCameraLastSave3d", "hoverClosest3d",
        // Cartesian
        "hoverClosestCartesian", "hoverCompareCartesian",
        // Geo
        "zoomInGeo", "zoomOutGeo", "resetGeo", "hoverClosestGeo",
        // Other
        "hoverClosestGl2d", "hoverClosestPie", "toggleHover", "resetViews", "toggleSpikelines", "resetViewMapbox", "sendDataToCloud"
      ],
    };

    this.plotState = 1;
    try {
      plotData = this.widget.data[this.widget.config.plotDataAttribute];
      if (plotData.layout?.toImageButtonOptions != null) plotConfig.toImageButtonOptions = plotData.layout.toImageButtonOptions
      else plotConfig.modeBarButtonsToRemove.push('toImage')
    } catch (error) {
      console.log(error);
    }

    let _this = this;
    if (plotData != null && plotData.hasOwnProperty('traces') && plotData.traces != null && plotData.traces.length > 0) {

      if (plotData?.layout != null) {

        if (plotData?.layout.colorway == null) plotData.layout.colorway = this.clonerService.deepClone(this?.appInfo?.darkTheme ? this.internalDataService.defaultPlotlyColorsDark : this.internalDataService.defaultPlotlyColors);

        let mainFontColor = this.appConfig?.plotlyDefaultColors?.font ?? '#000000';

        let dark = {
          plot_bgcolor: '#00000000',
          paper_bgcolor: '#00000000',
          modebar: {
            bgcolor: '#00000000',
            color: "#64697E",
            activecolor: "#64697E",
          }
        }

        plotData.layout = { ...plotData.layout, ...dark };

        // Merge legend
        let legendDark = { font: { color: mainFontColor } };
        plotData.layout.legend = plotData.layout.legend != null ? { ...plotData.layout.legend, ...legendDark } : legendDark;

        // Merge x axis
        let xAxisDark = { color: mainFontColor };
        if (plotData.layout.xaxis1 == null) {
          plotData.layout.xaxis = plotData.layout.xaxis != null ? { ...plotData.layout.xaxis, ...xAxisDark } : xAxisDark;
        }

        for (let i = 1; i < 20; i++) {
          if (plotData.layout["xaxis" + i] != null) plotData.layout["xaxis" + i] = plotData.layout["xaxis" + i] != null ? { ...plotData.layout["xaxis" + i], ...xAxisDark } : xAxisDark;
        }

        // Merge y axis
        let yAxisDark = { color: mainFontColor };
        if (plotData.layout.yaxis1 == null) {
          plotData.layout.yaxis = plotData.layout.yaxis != null ? { ...plotData.layout.yaxis, ...yAxisDark } : yAxisDark;
        }

        for (let i = 1; i < 20; i++) {
          if (plotData.layout["yaxis" + i] != null) plotData.layout["yaxis" + i] = plotData.layout["yaxis" + i] != null ? { ...plotData.layout["yaxis" + i], ...yAxisDark } : yAxisDark;
        }

      }

      if (plotData.hasOwnProperty('params') && plotData.params != null) {
        plotConfig = Object.assign(plotConfig, plotData.params);
      }

      setTimeout(() => {
        try {
          let plotDiv: any = document.getElementById(this.randomId);
          Plotly.react(plotDiv, plotData.traces, plotData.layout, plotConfig);

          if (plotConfig.hasOwnProperty('actions')) {

            for (let action of Object.keys(plotConfig.actions)) {
              let callback = plotConfig.actions[action];
              plotDiv.on(action, callback);
            }

          }

          if (plotConfig.hasOwnProperty('showFlagAnnotations') && plotConfig.showFlagAnnotations) {
            try {
              let plotCont: any = document.getElementsByClassName("nsewdrag")[0];
              let sidenav: any = document.getElementsByClassName("sidenav-container")[0];

              this.sidenavWidth = sidenav.getBoundingClientRect().width + 20;
              this.realPlotPosition = plotCont.getBoundingClientRect();

              this.flagsConfig = this.widget.data[this.widget.config.flagEventsDataAttribute];

              if (this.flagsConfig != null && Array.isArray(this.flagsConfig) && this.flagsConfig.length > 0) this.flagsNumber = this.flagsConfig.length;

            } catch (error) {
              console.log(error);
            }
          }

          if (type == null) {
            if (plotConfig.hasOwnProperty('plotType') && plotConfig.plotType) {
              plotDiv.on('plotly_update', function (data: any) {
                try {
                  if (data != null && data.hasOwnProperty('data') && data.data != null &&
                    Array.isArray(data.data) && data.data.length > 0 && data.data[0] != null && data.data[0].hasOwnProperty('type') &&
                    Array.isArray(data.data[0].type) && data.data[0].type.length > 1 && data.data[0].type[1] != null) {

                    _this.cacheService.set("typePlot", data.data[0].type[data.data[0].type.length - 1]);
                  }
                } catch (error) { }
              });
            }

            if (plotConfig.hasOwnProperty('boxDetail') && plotConfig.boxDetail) {
              let dragLayer: any = document.getElementsByClassName('nsewdrag')[0];
              let dragLayer2: any = document.getElementsByClassName('nsewdrag')[3];
              plotDiv.on('plotly_click', function (data: any) {
                try {

                  if (data.points[0].data.x.length > 0 &&
                    data.points[0].data.x.every((element: any) => (element == null || typeof element == 'number'))) {

                    let traceConfig = data.points[0];
                    let unit = traceConfig?.data?.unit ?? data.points[0].xaxis.title.text.split("[")[1].split("]")[0];

                    let boxData = {
                      data: traceConfig.data.boxPlotValues ?? traceConfig.data.x,
                      name: traceConfig.data.name,
                      unit: unit,
                      multiplier: traceConfig.data.multiplier,
                      statisticalData: traceConfig.data.boxPlotData,
                      color: traceConfig.fullData?.line?.color ?? traceConfig.fullData?.marker?.color,
                    };

                    _this.internalDataService.setBoxPlotData(boxData);

                  } else if (data.points[0].data != null && data.points[0].data.hasOwnProperty('boxPlotInfo') &&
                    data.points[0].data.hasOwnProperty('boxPlotInfo') != null) {

                    let bp = data.points[0];

                    let bpInfo = bp.data.boxPlotInfo[bp.pointIndex[0]][bp.pointIndex[1]].filter((x: any) => x != null);
                    if (bpInfo.length == 0) return;
                    let boxData = {
                      data: bpInfo,
                      name: bp.y + ' - ' + bp.x,
                      unit: bp.data.unit,
                      color: null,
                    };

                    _this.internalDataService.setBoxPlotData(boxData);
                  }
                } catch (error) { }
              });
              plotDiv.on('plotly_hover', function (data: any) {
                if (data) {
                  dragLayer.style.cursor = 'pointer';
                  try { dragLayer2.style.cursor = 'pointer' }
                  catch (error) { }
                }
              });
              plotDiv.on('plotly_unhover', function (data: any) {
                if (data) {
                  dragLayer.style.cursor = '';
                  try { dragLayer2.style.cursor = '' }
                  catch (error) { }
                }
              });
            }

            if (plotConfig.hasOwnProperty('stateTimelineAdvanced') && plotConfig.stateTimelineAdvanced) {
              let dragLayers: any = document.getElementsByClassName('nsewdrag');
              plotDiv.on('plotly_click', function (data: any) {
                try {

                  if (data.points[0].data != null) {

                    var zoomedIntervalStateTimelineAdvanced = {
                      start: moment(data.points[0].x),
                      end: moment(data.points[0].x).add(1, data.points[0].data.timeAggrUnit.unit),
                    };

                    _this.internalDataService.setZoomedInterval(zoomedIntervalStateTimelineAdvanced);

                  }
                } catch (error) { }
              });
              plotDiv.on('plotly_hover', function (data: any) {
                try {
                  if (data) {
                    dragLayers.forEach((dragLayer: any) => dragLayer.style.cursor = 'pointer');
                  }
                } catch (error) {
                  console.log(error);
                }
              });
              plotDiv.on('plotly_unhover', function (data: any) {
                try {
                  if (data) {
                    dragLayers.forEach((dragLayer: any) => dragLayer.style.cursor = '');
                  }
                } catch (error) {
                  console.log(error);
                }
              });
            }

            if (plotConfig.hasOwnProperty('relayoutVariables') && plotConfig.relayoutVariables) {
              plotDiv.on('plotly_relayout', function (eventData: any) {
                try {
                  let zoomVariables = {
                    from: eventData['xaxis.range[0]'],
                    to: eventData['xaxis.range[1]'],
                  };

                  _this.internalDataService.setZoomedIntervalVariables(zoomVariables);
                } catch (error) { }
              });
            }
          }

        } catch (error) {
          console.log(error);
        }
      }, 50);
    } else if (this.widget.config.noDataDash) {
      this.plotState = 3;
    } else {
      this.plotState = 2;
    }
  }

  // ngOnInit(): void {
  //   // console.log('ngOnInit PlotlyChartTableComponent');

  //   // TABLE
  //   this.tableInfo = this.widget.data.tableInfo;
  //   this.tableColumns = this.widget.data.tableColumns;
  //   let columnToOrder = 'perc';

  //   try {
  //     if (this.widget.config.orderAttribute != null) {
  //       columnToOrder = this.widget.config.orderAttribute;
  //     } else {
  //       columnToOrder = this.tableInfo.filter((x: any) => x.orderBy != null)[0].orderBy;
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   this.table = {
  //     list: this.widget.data[this.widget.config.tableDataAttribute],
  //     pageOptions: [25, 50, 100],
  //     pageSize: 50,
  //     orderVariable: columnToOrder,
  //   };

  //   this.tableData = new MatTableDataSource<any[]>(this.table.list);

  //   setTimeout(() => {
  //     this.tableData.sort = this.sort;
  //     this.tableData.paginator = this.paginator;
  //   }, 50);

  //   // PLOT
  //   let plotData: any = {};
  //   let plotConfig: any = {
  //     responsive: true,
  //     displaylogo: false
  //   };

  //   try {
  //     if (this.widget.config.plotConfigAttribute
  //       && this.widget.data[this.widget.config.plotConfigAttribute]
  //       && this.widget.data[this.widget.config.plotConfigAttribute]) {
  //       plotConfig = this.widget.data[this.widget.config.plotConfigAttribute]
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   try {
  //     plotData = this.widget.data[this.widget.config.plotDataAttribute];
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   setTimeout(() => {
  //     try {
  //       if (this.table != null && this.table.list != null && this.table.list.length > 0) Plotly.react(this.randomId, plotData.traces, plotData.layout, plotConfig);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }, 50);

  // }

}

function generateRandomString() {
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}