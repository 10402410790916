// import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'embedded-link-dialog',
  templateUrl: './embedded-link-dialog.component.html',
  styleUrls: ['./embedded-link-dialog.component.scss']
})
export class EmbeddedLinkDialogComponent implements OnInit {

  url: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    private domSanitizer: DomSanitizer,
    // private http: HttpClient,
  ) {

  }

  ngOnInit(): void {
    // window.open(this.dialog?.widget?.link, "_blank")
    this.url = this.domSanitizer.bypassSecurityTrustResourceUrl(this.dialog?.widget?.link);
  }

  // submitForm(event: Event) {
  //   event.preventDefault();

  //   let username = "interno";
  //   let password = "interno402023!";
  //   // Make a POST request to the server with the username and password as form data
  //   this.http.post('https://omet.desys.it/servlet/ValidateLogin', { username: username, password: password }).subscribe((response: any) => {
  //     console.log({ response });

  //     // Set the src attribute of the iframe to the URL of the page to display, passing the session ID or token returned by the server in the response
  //     // this.iframeSrc = `http://example.com/page.html?token=${response.token}`;
  //   });
  // }

}
