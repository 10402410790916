<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

    <!-- toolbar tabs -->
    <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="start center">

        <!-- tabs -->
        <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

        <span fxFlex fxShow="false" fxShow.gt-sm></span>

    </div>

    <!-- tab content -->
    <div class="tab-content h100 w100" fxLayout="column"
        fxLayoutAlign="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start stretch' }}"
        [ngClass]="{ 'without-tabs': tabs.length <= 1}">

        <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
        <!-- loading -->
        <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData"
            style="margin: auto;">
        </ff-loading-card>

        <div *ngIf="timeMachineMode && !noTimeMachineData" class="ff-streaming-container w100"
            [ngStyle.lt-md]="{'margin': '12px 0'}">
            <div fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" fxLayout="column"
                fxLayoutAlign="center stretch" fxLayoutGap="18px"
                style="background-color: transparent; margin: 12px 16px;">

                <!-- <span fxFlex></span> -->

                <div fxLayout="column" fxLayoutAlign="center start" class="column-title-value"
                    style="margin-right: 48px;">
                    <span class="title">{{ 'VIDEO_STREAMING.STREAM_START' | translate }}</span>
                    <span class="value">{{ start | placeholder }}</span>
                </div>

                <div fxLayout="column" fxLayoutAlign="center start" class="column-title-value"
                    style="margin-right: 48px;">
                    <span class="title">{{ 'VIDEO_STREAMING.CURRENT_TIME' | translate }}</span>
                    <span class="value">{{ currentTime | placeholder }}</span>
                </div>

                <div fxLayout="column" fxLayoutAlign="center start" class="column-title-value"
                    style="margin-right: 4px;">
                    <span class="title">{{ 'VIDEO_STREAMING.STREAM_END' | translate }}</span>
                    <span class="value">{{ end | placeholder }}</span>
                </div>

            </div>

            <div fxLayout="column" fxLayoutAlign="center center"
                style="margin: 12px 16px; min-height: 60px; background-color: transparent;">

                <div fxLayout="row" fxLayoutAlign="start center" class="w100">

                    <!-- slider -->
                    <div *ngIf="slider != null" fxLayout="row" fxLayoutAlign="start center"
                        class="ngx-slider-container">
                        <ngx-slider [(value)]="slider.min" [options]="slider.options" class="no-max-width"
                            (userChangeStart)="slider.userChangeStart($event)"
                            (userChangeEnd)="slider.userChangeEnd($event)" style="margin-right: 24px;"
                            (valueChange)="slider.valueChange($event)">
                        </ngx-slider>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="center center">

                        <div style="background: #F6F8F8; border-radius: 20px; padding: 8px;" left>
                            <span style="color: #777E88;">{{ "GLOBAL.CURRENT_SPEED" | translate }}:&nbsp;</span>
                            <span style="color: #777E88; font-weight: 800; font-size: 16px; line-height: 22px;">
                                x{{speed}}
                            </span>
                        </div>

                        <button class="rounded-button md-primary" (click)="decreaseSpeed()" left
                            [disabled]="pollingSliderTime > 999 || pageState.value < pageStateReady">
                            <ff-icon icon="fast_rewind"></ff-icon>
                        </button>

                        <button class="rounded-button md-primary" (click)="play()" left
                            [disabled]="playerState == 1 || pageState.value < pageStateReady">
                            <ff-icon icon="play_arrow"></ff-icon>
                        </button>

                        <button class="rounded-button md-primary" (click)="pause()" left
                            [disabled]="playerState == 0 || pageState.value < pageStateReady">
                            <ff-icon icon="pause"></ff-icon>
                        </button>

                        <button class="rounded-button md-primary" (click)="resetSlider()" left
                            [disabled]="playerState == 0 || pageState.value < pageStateReady">
                            <ff-icon icon="stop"></ff-icon>
                        </button>

                        <button class="rounded-button md-primary" (click)="increaseSpeed()" left
                            [disabled]="pollingSliderTime < 499 || pageState.value < pageStateReady">
                            <ff-icon icon="fast_forward"></ff-icon>
                        </button>

                    </div>

                </div>

            </div>
        </div>

        <div *ngIf="!isMobile && !repeatedCards && !noSynoptic && pageState.value >= pageStateReady" fxLayout="row"
            fxLayoutAlign="start start" class="h100 w100" fxLayoutGap="8px">
            <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="h100" fxLayoutGap="8px">

                <div fxFlex="5" fxLayout="row" fxLayoutAlign="start center">

                    <button *ngIf="!timeMachineMode" (click)="openTimeMachineSelectionDialog()" class="filter-button"
                        fxLayout="row" fxLayoutAlign="center center">
                        <ff-icon icon="restore"></ff-icon>
                        <span>{{ 'GLOBAL.TIME_MACHINE' | translate }}</span>
                    </button>

                    <button *ngIf="timeMachineMode" (click)="exitTimeMachineMode()" class="filter-button" fxLayout="row"
                        fxLayoutAlign="center center">
                        <ff-icon icon="arrow_back"></ff-icon>
                        <span>{{ 'GLOBAL.EXIT_TIME_MACHINE_MODE' | translate }}</span>
                    </button>

                    <span fxFlex></span>

                    <div fxLayout="row" fxLayoutAlign="start center"
                        class="last-update {{monitoringData?.notConnected ? 'md-orange' : (monitoringData?.neverConnected ? 'md-gray' :'md-green') }}">

                        <mat-icon class="icon left">
                            {{ monitoringData?.notConnected ?
                            'warning' :
                            (monitoringData?.neverConnected ? 'remove' : 'check') }}
                        </mat-icon>
                        <span>
                            {{ 'REMOTE_MONITORING.LAST_UPDATE' | translate }}: {{ monitoringData?.lastUpdateP }}
                        </span>
                    </div>
                </div>

                <div *ngIf="noTimeMachineData" class="no-data">
                    <span>{{ 'GLOBAL.TIME_MACHINE_NO_DATA' | translate }}</span>
                </div>

                <ff-widget fxFlex="95" *ngIf="!noTimeMachineData"
                    [widget]="{type: 'ff-synoptic-time-machine', data: monitoringData, synopticConfig: synopticConfig}">
                </ff-widget>

            </div>
            <div fxFlex="{{ !isSmThanTablet ? dashboardConfig.secondColumnFlex : dashboardConfig.secondColumnFlex + 10 }}"
                fxLayout="column" fxLayoutAlign="start stretch" class="h100" fxLayoutGap="8px">
                <ff-widget *ngFor="let w of dashboardConfig.widgets" fxFlex="{{w.flex}}"
                    [widget]="{ type: w.type, data: monitoringData, machineProfile: machine.profile, config: w.config, title: w.title, units: w.units }">
                </ff-widget>
            </div>

        </div>

        <div *ngIf="isMobile && pageState.value >= pageStateReady" fxLayout="row wrap" fxLayoutAlign="start stretch"
            class="h100 w100" fxLayoutGap="8px grid">
            <span fxFlex="100">
                <button *ngIf="!hideSynopticSelectionButton" mat-button (click)="openMatDialog()" class="primary-button"
                    fxLayout="row" fxLayoutAlign="center center">
                    {{ 'SYNOPTICS.' + currentSynopticId | translate }}
                    <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
                        style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
                    </mat-icon>
                </button>
            </span>

            <span fxFlex="100">
                <div fxLayout="row" fxLayoutAlign="start center"
                    class="last-update {{monitoringData?.notConnected ? 'md-orange' : (monitoringData?.neverConnected ? 'md-gray' :'md-green') }}">

                    <mat-icon class="icon left">
                        {{monitoringData?.notConnected ? 'warning' : (monitoringData?.neverConnected ? 'remove' :
                        'check')}}
                    </mat-icon>
                    <span>{{ 'REMOTE_MONITORING.LAST_UPDATE' | translate }}: {{ monitoringData?.lastUpdateP }}</span>
                </div>
            </span>

            <ff-widget fxFlex="100"
                [widget]="{type: 'ff-synoptic', data: monitoringData, synopticConfig: synopticConfig, mobile: true }">
            </ff-widget>
            <ff-widget *ngFor="let w of dashboardConfig?.widgets" fxFlex="100" [widget]="{ 
            type: w.type,
            data: monitoringData,
            machineProfile: machine?.profile,
            config: w.config,
            title: w.title,
            unit: w.unit,
            productionUnit: w.productionUnit  }">
            </ff-widget>

        </div>
    </div>

</div>