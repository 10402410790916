import { Component, Input, OnInit } from '@angular/core';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'ff-synoptic-widget',
  templateUrl: './synoptic-widget.component.html',
  styleUrls: ['./synoptic-widget.component.scss']
})
export class SynopticWidgetComponent implements OnInit {

  @Input() datapoint: any;

  public innerWidth: any;

  constructor(public internalDataService: InternalDataService) { }

  dpClick(dp: any, tableHeaderDialog?: boolean) {
    // console.log(dp)
    if (dp != null) {
      if (dp.action != null) this.internalDataService.setDatapoint(dp)
      else if (tableHeaderDialog && dp.type == 'table' && dp.datapointList != null) this.internalDataService.setDatapoint(dp);
    }
  }

  ngOnInit() { }

  ngOnChanges() { }

  ngOnDestroy() { }

}
