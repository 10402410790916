import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

@Component({
  selector: 'app-custom-interval-dialog',
  templateUrl: './custom-interval-dialog.component.html',
  styleUrls: ['./custom-interval-dialog.component.scss']
})
export class CustomIntervalDialogComponent implements OnInit {

  range: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public apiService: ApiService,
    public translate: FfTranslateService,
    public formBuilder: FormBuilder
  ) {

    this.range = this.formBuilder.group({
      start: new FormControl(),
      end: new FormControl()
    });

    this.dialog.interval = this.range;

  }

  // myFilter(d: Date | null) {
  //   const day = (d || new Date()).getDay();
  //   // Prevent Saturday and Sunday from being selected.
  //   return day !== 0 && day !== 6;
  // }

  ngOnInit(): void { }

}
