import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClonerService } from 'src/app/services/clone.service';

@Component({
  selector: 'app-assign-operators-dialog',
  templateUrl: './assign-operators-dialog.component.html',
  styleUrls: ['./assign-operators-dialog.component.scss']
})
export class AssignOperatorsDialogComponent implements OnInit {

  public shiftTemplateInfos: any = [{
    variable: "shiftId",
    label: "CALENDAR.SHIFT"
  },
  {
    variable: "from",
    label: "CALENDAR.START"
  },
  {
    variable: "to",
    label: "CALENDAR.END"
  }];
  public currentTemplate: any;
  public selectedDaysCopy: any;
  public operatorsList: any;
  public isOperators: any = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<AssignOperatorsDialogComponent>,
    public clonerService: ClonerService,
  ) { }

  addOperator(shift) {
    let ix = this.currentTemplate.shifts.findIndex(x => x.shiftId == shift.shiftId);
    try {
      this.currentTemplate.shifts[ix].operatorIds.push(null);
    } catch (error) {
      this.currentTemplate.shifts[ix].operatorIds = [null];
    }
  }

  deleteOperator(shift, operatorIdx) {
    shift.operatorIds.splice(operatorIdx, 1)
  };

  ngOnInit(): void {
    try {
      this.currentTemplate = this.clonerService.deepClone(this.dialog.currentTemplate);
    } catch (error) { console.log(error) }
    try {
      this.selectedDaysCopy = this.clonerService.deepClone(this.dialog.selectedDaysCopy);
    } catch (error) { console.log(error) }
    try {
      this.operatorsList = this.clonerService.deepClone(this.dialog.operatorsList);
    } catch (error) { console.log(error) }
    try {
      this.isOperators = this.dialog.isOperators;
    } catch (error) { console.log(error) }
  }

  checkDisabledDialog() {
    try {
      return this.currentTemplate.shifts.some(shift => {
        return shift.operatorIds == null || shift.operatorIds.length == 0 ? false : shift.operatorIds.some(x => x == null || x == undefined || x == '')
      });
    } catch (error) {
      console.log(error);
      return true;
    }
  }

  closeDialog() {
    this.dialogRef.close(this.currentTemplate);
  }
}
