import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache.service';

@Component({
  selector: 'ff-switch',
  templateUrl: './ff-switch.component.html',
  styleUrls: ['./ff-switch.component.scss']
})
export class FfSwitchComponent implements OnInit {

  @Input() widget: any;
  switch: any;

  constructor(
    public cacheService: CacheService,
    public router: Router,
  ) { }

  onSwitchChange(value) {
    if (this.switch?.saveInCache) this.switch.checked = this.cacheService.set(this.switch?.variable, value);
    if (this.switch?.reloadPageOnSwitchToggle) this.router.navigateByUrl(this.router.url);
  }

  ngOnInit() {
    this.switch = this.widget?.config ?? {};
    if (this.switch?.saveInCache) this.switch.checked = this.cacheService.get(this.switch?.variable) ?? this.switch.checked;
  }

}
