<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title | translate }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <input matInput class="ff-input number w100" type="number" [min]="0" [(ngModel)]="dialog.row[dialog.key]">

</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button class="filter-button" (click)="closeDialog()"
    [disabled]="dialog.row == null || dialog.key == null || dialog.row[dialog.key] == null">
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</mat-dialog-actions>
