import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { ComponentVariablesSelectionComponent } from '../../machine-recorder/component-variables-selection/component-variables-selection.component';

declare let videojs: any

@Component({
  selector: 'app-session-detail',
  templateUrl: './session-detail.component.html',
  styleUrls: ['./session-detail.component.scss']
})
export class SessionDetailComponent implements OnInit, OnDestroy {

  public isAllowedUser: boolean = true;
  public notLoadedVideos: boolean = true;
  public defaultEnableFilters: any = true;

  public pastVideosStreamings: any;
  public slider: any;
  public currentTime: any;
  public playerState: any = 0;
  public streamEnd: any;
  public streamStart: any;
  public streamStartHHmmSS: any;
  public streamEndUnparsed: any;

  public videoIds: any;
  public timeStart: any;
  public timeEnd: any;
  public videoType: any;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public isMobile: any;
  public machineProfiles: any;

  public backButton: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public pollingTime: any;
  public pollingEvents: any;

  public maxStringsLength: any;
  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;

  public excludeLine: boolean = true;

  public interval: any;
  public intervalConfig: any;

  public dashboardConfig: any;

  public dashboardData: any;

  public mobileData: any;
  public currentSortingProperty: any;
  public sortDirection: any;
  public collapsed: any;

  public tabName: any = "videoStreamingSessionDetail";
  public sectionName: any = "sessionDetail";
  public dashboardName: any = "session-detail";
  public pollingPropName: any = "pollingTime";
  public dashboardNameComplete: any;

  public componentId: any;
  public plotsConfig: any;
  public plotsConfigComponents: any;
  public variablesData: any;
  public variables: any = {
    list: [],
    selected: []
  };
  public events: any = {
    list: [],
    filtered: [],
    pageOptions: [25, 50, 100],
    pageSize: 50,
  };

  public edgeVideoStreaming: any = false;
  public hideMonitoringData: any = false;

  public switch: any = {
    checked: !this.hideMonitoringData,
    checkedLabel: 'on',
    uncheckedLabel: 'off',
  };

  public eventConfig: any;
  public skipTelemetryInVideoStreaming: any = false;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 8;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getVariablesConfig, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getVideos, nextState: 6, loadingMsg: 'LOADING.VIDEOS' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.startStreaming, nextState: 7, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
  ) {

    this.isMobile = window.innerWidth <= 959;

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.edgeVideoStreaming = this.appInfo?.edgeVideoStreaming;

    this.breadcrumb = ['VIDEO_STREAMING.TITLE', 'SESSION_DETAIL.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig?.[this.tabName]?.[this.pollingPropName] ?? 5000;
    this.pollingEvents = Subscription;

    this.dashboardData = null;

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) { this.isMobile = window.innerWidth <= 959 }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-vs-session");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getMonitoringDataPolling, this.getMonitoringData, this.machine.timezone) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET VARIABLES CONFIG

  getVariablesConfig(_this: any) {

    _this.skipTelemetryInVideoStreaming = _this.appConfig.skipTelemetryInVideoStreaming || _this.machine.profile.skipTelemetryInVideoStreaming;

    if (_this.skipTelemetryInVideoStreaming) {
      _this.hideMonitoringData = true;
      _this.switch.checked = false;
      _this.dispatcherService.getDispatch(_this, 300);
      return;
    }

    try {

      _this.componentsList = _this.machine.components?.map((x: any) => {
        return {
          label: _this.internalDataService.parseComponentsLabel(x, null, _this.machineId),
          id: x,
        };
      });

      if (_this.componentId == null) _this.componentId = _this.cacheService.get("selectedComponent")?.assetId == _this.machineId ? _this.cacheService.get("selectedComponent")?.value : null;
      if (_this.componentId == null) _this.componentId = _this.componentsList?.[0]?.id;

      let machineReference = _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference);

      _this.internalDataService.getVariablesConfig(_this, _this.machineId, machineReference, null, false);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }


  openVariablesSelectionDialog() {

    let wasPlayingVideo = false;
    if (this.playerState == 1) {
      this.pause();
      wasPlayingVideo = true;
    }

    const variablesDialog = this.dialog.open(ComponentVariablesSelectionComponent,
      {
        panelClass: 'ff-dialog',
        width: '40%',
        // height: '90%',
        data: {
          title: this.translate.instant("CONTINUOUS_EXPLORATION.VARIABLES_SELECTION"),
          componentId: this.componentId,
          groups: this.clonerService.deepClone(this.plotsConfigComponents)
        },
      }
    );

    variablesDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          // console.log(this.plotsConfig);

          this.componentId = result.componentId;

          this.plotsConfigComponents = this.clonerService.deepClone(result.groups);
          this.plotsConfig = this.clonerService.deepClone(result.groups[this.componentId].plotsConfig);

        } catch (error) {
          console.log(error);
        }
      }

      if (wasPlayingVideo) this.play();

    });
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET VIDEOS

  getVideos(_this: any) {
    try {

      let url = '/apif/video-streaming/video-history/' + _this.machineId;
      // let url = '/apif/video-streaming/video-history/test';

      let query: any = {
        from: _this.timeStart,
        tz: _this.machine.timezone,
      };

      if (_this.videoType != null) query.videoType = _this.videoType;

      let payload = _this.clonerService.deepClone(_this.videoIds);

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.pastVideos = _this.clonerService.deepClone(data.body);

            try {
              _this.timeStart = _this.filterService.parseMoment(_this.pastVideos.map(x => x.timeStart).sort()[0], 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone);
              _this.streamStart = _this.filterService.parseMoment(_this.pastVideos.map(x => x.timeStart).sort()[0], 'DD/MM/YYYY HH:mm:ss', _this.machine.timezone);
              _this.streamStartHHmmSS = _this.filterService.parseMoment(_this.pastVideos.map(x => x.timeStart).sort()[0], 'HH:mm:ss', _this.machine.timezone);

              let eventId = _this.pastVideos[0].eventId;
              _this.eventConfig = _this.machine.profile?.videoEventTypes?.find(x => x.id == eventId) ?? {
                label: eventId
              };

            } catch (error) {
              console.log(error);
              _this.streamStart = null;
            }

            _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // START STREAMING

  play() {
    this.playerState = 1;
    this.stopPollingData();
    this.getMonitoringDataPolling(this);
    this.playStopVideos();
  };

  pause() {
    this.playerState = 0;
    try {
      this.pastVideosStreamings.forEach(video => video.player.pause());
    } catch (error) { console.log(error) }
    this.stopPollingData();
  };

  setSliderValues() {

    let _this = this;

    this.slider = {
      value: 0,
      min: _this.slider != null && _this.slider.min != null && !isNaN(_this.slider.min) ? _this.slider.min : 0,
      options: {
        showSelectionBar: true,
        floor: 0,
        ceil: moment(this.streamEndUnparsed).diff(_this.timeStart, 'seconds'),
        translate: function (value) {
          return (value != null && !isNaN(value)) ? _this.filterService.parseMoment(moment(_this.timeStart).add(value, 'seconds'), 'HH:mm:ss', _this.machine.timezone) : _this.streamStartHHmmSS;
          return (value != null && !isNaN(value)) ? _this.filterService.parseTime(value, 's', 'HH:mm:ss') : '00:00:00';
        },
      },
      userChangeEnd: function (slider) {
        _this.slider.min = slider?.value;
        _this.currentTime = _this.filterService.parseMoment(moment(_this.timeStart).add(slider?.value, 'seconds'), 'DD/MM/YYYY HH:mm:ss', _this.machine.timezone);
        _this.playStopVideos();
      },
      valueChange: function (slider) {
        _this.playStopVideos(true);
      }
    }

  }

  playStopVideos(onlyPaused: any = false) {

    this.currentTime = this.filterService.parseMoment(moment(this.timeStart).add(this.slider.min, 'seconds'), 'DD/MM/YYYY HH:mm:ss', this.machine.timezone);
    if (this.playerState == 1) {
      this.pastVideosStreamings.filter(x => !onlyPaused ? true : x.player.paused()).forEach(video => {
        try {
          if (moment(moment(this.timeStart).add(this.slider.min, 'seconds')).diff(video.timeStart, 'seconds') >= 0) {
            video.player.play();
            video.player.currentTime(moment(moment(this.timeStart).add(this.slider.min, 'seconds')).diff(video.timeStart, 'seconds'));
          } else {
            if (!onlyPaused) {
              video.player.currentTime(0);
              video.player.pause();
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
  }

  startStreaming(_this: any) {

    _this.pastVideosStreamings = _this.clonerService.deepClone(_this.pastVideos);
    setTimeout(() => {
      (_this.setVideos(_this), 50);
      _this.dispatcherService.getDispatch(_this, 300);
    });

  }

  setVideos(_this: any) {
    if (_this.pastVideosStreamings != null && _this.pastVideosStreamings.length > 0) {

      let loadingVideos = 0;
      let finishedVideos = 0;

      // AZURE
      if (!_this.edgeVideoStreaming) {
        _this.pastVideosStreamings.forEach(video => {
          var myOptions = {
            nativeControlsForTouch: false,
            // controls: _this.pastVideosStreamings.length == 1,
            controls: false,
            autoplay: false,
            // muted: true,
            // enableFullscreen: true,
          };

          video.player = amp(video.id, myOptions, function () {

            this.addEventListener('play', function () {
              // console.log('Play clicked!');
              finishedVideos = 0;
            });

            this.addEventListener('ended', function () {
              finishedVideos++;
              if (finishedVideos >= _this.pastVideosStreamings.length) _this.slider.min = _this.slider.options.ceil;
            });

            this.addEventListener('loadeddata', function () {
              // console.log('Loaded data!');
              if (_this.streamEndUnparsed == null) {
                _this.streamEndUnparsed = _this.filterService.parseMoment(moment(video.timeStart).add(video.player.duration(), 'seconds'), 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone);
              } else {
                if (moment(_this.filterService.parseMoment(moment(video.timeStart).add(video.player.duration(), 'seconds'), 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone))
                  .diff(_this.streamEndUnparsed, 'seconds') > 0) {
                  _this.streamEndUnparsed = _this.filterService.parseMoment(moment(video.timeStart).add(video.player.duration(), 'seconds'), 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone);
                }
              }
              _this.streamEnd = _this.filterService.parseMoment(_this.streamEndUnparsed, 'DD/MM/YYYY HH:mm:ss', _this.machine.timezone);
              _this.setSliderValues();
              loadingVideos++;
              if (loadingVideos >= _this.pastVideosStreamings.length) _this.notLoadedVideos = false;
            });

            // console.log('Good to go!');
            // this.addEventListener('ended', function () {
            //     console.log('Finished!');
            // })

          }).src([{
            src: video.url,
            type: "application/vnd.ms-sstr+xml",
            protectionInfo: [{
              type: "AES",
              authenticationToken: video.bearerToken
            }]
          }]);
        });
      }

      // EDGE
      else {
        _this.pastVideosStreamings.forEach(video => {

          console.log({ video });

          if (video.player == null) {
            video.player = videojs(video.id, {}, () => { });

            if (_this.streamEndUnparsed == null) {
              _this.streamEndUnparsed = _this.filterService.parseMoment(moment(video.timeStart).add(video.duration, 'seconds'), 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone);
            } else {
              if (moment(_this.filterService.parseMoment(moment(video.timeStart).add(video.duration, 'seconds'), 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone))
                .diff(_this.streamEndUnparsed, 'seconds') > 0) {
                _this.streamEndUnparsed = _this.filterService.parseMoment(moment(video.timeStart).add(video.duration, 'seconds'), 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone);
              }
            }
          }
          else {

            video.player.pause();

            setTimeout(() => {
              video.player.src({ type: 'application/x-mpegURL', src: 'streaming/' + video.name + '/stream.m3u8' });
              video.player.play();
            }, 500);
          }
        });

        _this.streamEnd = _this.filterService.parseMoment(_this.streamEndUnparsed, 'DD/MM/YYYY HH:mm:ss', _this.machine.timezone);
        _this.setSliderValues();
        _this.notLoadedVideos = false;
      }
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // events

  // polling
  getMonitoringDataPolling(_this: any) {
    try {

      if (_this.cacheService.get("intervalLong") == null) {

        _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.cacheService.set("intervalLong", _this.intervalConfig);

      } else {
        _this.intervalConfig = _this.cacheService.get("intervalLong");
        _this.interval = _this.cacheService.get("intervalLong").selected;
      }

      if (_this.pollingTime > 0) {
        _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => _this.getMonitoringData(_this, count));
      } else {
        _this.getMonitoringData(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  stopPollingData() {
    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
  }

  // get events
  getMonitoringData(_this: any, count?: any) {
    try {

      try {
        _this.slider.min = isNaN(_this.slider.min) ? 0 : _this.slider.min;

        // Stop the data polling
        if ((_this.slider.min + _this.pollingTime / 1000) > _this.slider.options.ceil) {
          _this.stopPollingData();
          _this.playerState = 0;
          _this.slider.min = _this.slider.options.ceil;
          return;
        }

        if (count > 0) _this.slider.min += _this.pollingTime / 1000;

        let diffFromStart = _this.slider.min;
        _this.timeEnd = _this.filterService.parseMoment(moment(_this.timeStart).add(diffFromStart, 'seconds'), 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone);
        _this.currentTime = _this.filterService.parseMoment(moment(_this.timeStart).add(diffFromStart, 'seconds'), 'YYYY/MM/DD HH:mm:ss', _this.machine.timezone);
      } catch (error) {
        console.log(error);
      }

      if (this.hideMonitoringData) return;

      let url = '/apif/variables-exploration/' + _this.machineId + '/' + _this.componentId;

      let query: any = {
        // from: _this.timeStart,
        from: _this.intervalService.formatStringDate(_this.timeStart, _this.machine.timezone),
        to: _this.intervalService.formatStringDate(_this.timeEnd, _this.machine.timezone),
        // to: "2021-10-21T10:27:17.000Z",
        // from: _this.filterService.parseMoment(_this.timeStart, 'YYYY-MM-DDTHH:mm:ss.SSS', _this.machine.timezone) + "Z",
        // to: _this.filterService.parseMoment(_this.timeEnd, 'YYYY-MM-DDTHH:mm:ss.SSS', _this.machine.timezone) + "Z",
        tz: _this.machine.timezone,
        returnStates: 1,
      };

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      let seconds = 3600;
      _this.parsedTablesCopy = [];

      try {
        seconds = moment(_this.interval.end).diff(moment(_this.interval.start), 's');
        _this.intervalSeconds = moment(_this.interval.end).diff(moment(_this.interval.start), 's');
        _this.intervalMilliSeconds = moment(_this.interval.end).diff(moment(_this.interval.start));

        let selectedEventIds: any = null;

        let c_eventTypes = _this.cacheService.get("eventTypes");
        if (c_eventTypes == null) {
          _this.eventTypes = _this.clonerService.deepClone(_this.machine.profile.tables?.map((x: any) => {
            return {
              selected: _this.defaultEnableFilters,
              label: _this.translate.instant(x.label),
              enabled: true,
              id: x.eventId,
              value: x.label,
            }
          }));
        } else {
          _this.eventTypes = _this.clonerService.deepClone(c_eventTypes);
        }

        if (_this.eventTypes != null) {
          selectedEventIds = _this.eventTypes.filter((ev: any) => ev.selected).map((ev: any) => ev.id);
        }

        if (_this.machine.profile.tables != null) {
          _this.parsedTablesCopy = _this.clonerService.deepClone(_this.machine.profile.tables.filter((table: any) => {

            if (selectedEventIds != null && !selectedEventIds.includes(table.eventId)) return false;

            if (table.timeRange) {

              let unit = table.timeRange.slice(-1)
              let value = parseFloat(table.timeRange.slice(0, -1))

              if (unit == 'm') return value >= seconds / 60;
              else if (unit == 'h') return value >= seconds / 3600;
              else if (unit == 'd') return value >= seconds / (24 * 3600);

            }

            return !table.hasOwnProperty('daysRange') || table.daysRange === null || table.daysRange >= seconds / (24 * 3600);

          }));
        }
      } catch (error) {
        console.log(error);
      }

      try {
        _this.parsedTablesCopy.forEach((table: any) => {
          if (table.hasOwnProperty('filters')) {

            // for (const [param, condition] of Object.entries(table.filters)) {
            //   for (const [operator, setpoint] of Object.entries(condition)) {
            //     if (typeof (setpoint) === "number") {
            //       if (seconds > 3600) {
            //         // console.log(setpoint);
            //         table.filters[param][operator] = String(Math.min(setpoint * seconds / 3600, 1800));
            //       } else {
            //         delete table.filters[param];
            //       }
            //     }
            //   }
            // }

            Object.entries(table.filters).forEach((filt: any) => {

              let param = filt[0];
              let condition = filt[1];

              Object.entries(condition).forEach((cond: any) => {
                let operator = cond[0];
                let setpoint = cond[1];

                if (typeof (setpoint) === "number") {
                  if (seconds > 3600) {
                    table.filters[param][operator] = String(Math.min(setpoint * seconds / 3600, 1800));
                  } else {
                    delete table.filters[param];
                  }
                }
              });
            });

          }
        });
      } catch (error) {
        console.log(error);
      }

      let payload: any = {
        variables: _this.variables.list,
        tables: _this.parsedTablesCopy,
      };

      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            // _this.dashboardData = _this.parsedashboardData(_this.clonerService.deepClone(data.body));
            _this.dashboardData = _this.clonerService.deepClone(data.body);
            _this.dashboardData.dataConfig = {};
            _this.dashboardData.dataConfig.plotDataAttribute = 'plotData';
            _this.dashboardData.dataConfig.flagEventsDataAttribute = 'flagData';

            _this.dashboardData.dataConfig.noDataPolicy = 'plot-axis';
            _this.dashboardData.dataConfig.noDataLabel = _this.translate.instant('GLOBAL.NO_DATA_AVAILABLE');

            // set table data and config
            _this.dashboardData.dataConfig.tableDataAttribute = 'faults';
            _this.dashboardData.dataConfig.orderAttribute = _this.appConfig?.[_this.tabName]?.orderAttribute != null ? _this.appConfig?.[_this.tabName]?.orderAttribute : 'perc';

            // _this.tableInfos = _this.appConfig['machineRecorder'].tableInfos;

            // _this.machine.profile.aggregations.forEach((aggr: any) => {
            //   let idx = _this.tableInfos.findIndex((x: any) => x.variable == aggr.id);
            //   if (idx == -1) {
            //     _this.tableInfos.push({
            //       variable: aggr.id,
            //       label: _this.translate.instant(aggr.label),
            //       suffix: null
            //     });
            //   }
            // });

            // _this.eventsColumns.push('icon');
            // _this.tableInfos.forEach((element: any) => {
            //   _this.eventsColumns.push(element.variable);
            // });

            try {
              _this.dashboardData.tableInfo = _this.clonerService.deepClone(_this.appConfig['machineRecorder'].tableInfos);
            } catch (error) { console.log(error) }

            _this.dashboardData.tableColumns = [];
            _this.dashboardData.tableInfo?.forEach((element: any) => {
              _this.dashboardData.tableColumns.push(element.variable);
            });

            try {

              let lastUpdate: any = Object.values(_this.dashboardData.aspects)?.reduce((acc, aspect: any) => {
                let timestamp: any = aspect?.timestamps?.at(-1);
                if (moment(timestamp).diff(moment(acc)) > 0) acc = timestamp;
                return acc;
              }, "1970-01-01T00:00:00.000");

              _this.dashboardData.lastUpdate = lastUpdate == '1970-01-01T00:00:00.000' ? '-' : _this.filterService.parseMoment(moment(lastUpdate), "HH:mm:ss", _this.machine.timezone);

            } catch (error) { console.log(error) }

            try {
              _this.dashboardData.lastState = _this.dashboardData?.states?.[_this.dashboardData?.states.length - 1]?.value;
            } catch (error) { console.log(error) }

            _this.parseData(_this, data.body);

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseData(_this: any, data: any) {

    if (data.hasOwnProperty('states') && data.states != null &&
      Array.isArray(data.states) && data.states.length > 0) {
      try {
        let statesCopy = _this.clonerService.deepClone(data.states);
        data.states.forEach((state: any, stateIdx: any) => {
          let previousIdx = stateIdx - 1;
          if (previousIdx > -1 && state.timeStart != data.states[previousIdx].timeEnd) {
            statesCopy.push({
              timeEnd: state.timeStart,
              timeStart: data.states[previousIdx].timeEnd,
              value: 'NC'
            });
          }
          if (stateIdx == 0 &&
            // All events between the interval start and the first value of the states are filled with state NC
            moment(state.timeStart).diff(moment(_this.interval.start)) > 0) {
            statesCopy.push({
              timeEnd: state.timeStart,
              timeStart: moment(_this.interval.start).format("YYYY-MM-DDTHH:mm:ss.SSS") + 'Z',
              value: 'NC'
            });
          }
          if (stateIdx === data.states.length - 1 &&
            // All events between the last value of the states and the interval end are filled with state NC
            moment(state.timeEnd).diff(moment(_this.interval.end)) < 0) {
            statesCopy.push({
              timeEnd: moment(_this.interval.end).format("YYYY-MM-DDTHH:mm:ss.SSS") + 'Z',
              timeStart: state.timeEnd,
              value: 'NC'
            });
          }
        });
        data.states = _this.clonerService.deepClone(statesCopy)
      } catch (error) {
        console.log(error);
      }
    }

    _this.hiddenEventsMsg = false;
    if (data.hasOwnProperty('table') && data.table != null &&
      Array.isArray(data.table) && data.table.length > 0) {

      let parsedTable: any = [];

      // TODO
      if (data.hiddenEvents) {
        _this.hiddenEventsMsg = _this.translate.instant('CYCLE_TIMELINE.HIDDEN_EVENTS_WARNING', { shown: data.table.length, tot: data.hiddenEvents })
      }

      data.table.filter((x: any, index: any) => index < 100000).forEach((event: any) => {
        // data.table.forEach((event: any) => {
        // console.log(event);
        event = _this.internalDataService.getEventInfo(_this, event);
        event.timestampP = moment(event.timestamp).format("DD MMM YYYY HH:mm:ss.SSS");
        event.durationP = event.duration != null ? _this.filterService.parseTime(event.duration, 's', 'HH:mm:ss') : null;
        // event.eventName = event.eventId;
        // event.description = "Descrizione casuale";
        parsedTable.push(event);
      });

      // console.log(_this.parsedTablesCopy);
      _this.filtersInfo = _this.machine.profile.tables.map((x: any) => {
        if (x.daysRange != null) {
          return _this.translate.instant("CONTINUOUS_EXPLORATION.TIME_INTERVAL", {
            name: _this.translate.instant(x.label),
            duration: _this.filterService.parseTime(x.daysRange * 24 * 3600, 's', 'HH:mm:ss'),
          });
        }
        return _this.translate.instant("CONTINUOUS_EXPLORATION.NOT_SHOWN", {
          name: _this.translate.instant(x.label),
        });
      });
      _this.parsedTablesCopy.forEach((table: any) => {

        let ix = _this.machine.profile.tables.findIndex((x: any) => x.eventId == table.eventId);
        if (ix != -1) {
          let descr = _this.translate.instant(table.label);
          if (table.hasOwnProperty('filters') && table.filters != null && table.filters.hasOwnProperty("\"duration\"") &&
            table.filters["\"duration\""] != null && table.filters["\"duration\""][">"] != null) {
            try {
              descr = _this.translate.instant("CONTINUOUS_EXPLORATION.FILTER_DURATION", {
                name: _this.translate.instant(table.label),
                duration: _this.filterService.parseTime(table.filters["\"duration\""][">"], 's', 'HH:mm:ss'),
              });
            } catch (error) {
              console.log(error);
            }
          } else {
            descr = null;
          }
          _this.filtersInfo[ix] = descr;
        }

      });

      try {
        _this.filtersDontExist = _this.filtersInfo.every((x: any) => x == null);
      } catch (error) {
        console.log(error);
      }

      _this.events.list = _this.clonerService.deepClone(parsedTable);

      _this.dashboardData.faults = _this.clonerService.deepClone(parsedTable);
      // console.log(_this.dashboardData.faults);


    } else {
      try {
        _this.events.list = [];
      } catch (error) {
        console.log(error);
      }
    }

    _this.variablesData = data;
    // _this.filterEvents();

    _this.drawPlot(_this, _this.variablesData);

  }

  drawPlot(_this: any, data: any, skipGetData?: any) {

    _this.traces = [];
    // Chart height 
    // var chartHeight = 500;

    var filteredConfigsLength = _this.plotsConfig.list.filter((c: any) => c.show).length;

    var domain = _this.getDomain(filteredConfigsLength);

    // console.log([moment(_this.interval.start).valueOf(), moment(_this.interval.end).valueOf()]);
    // console.log([_this.interval.start, _this.interval.end]);
    // Default layout
    var layout: any = {
      colorway: _this.colors,
      margin: {
        t: 40,
        b: 50,
        r: 25,
        l: 25,
        pad: 0
      },
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: -0.15
      },
      xaxis: {
        type: 'date',
        range: [moment(_this.timeStart).valueOf(), moment(_this.timeEnd).valueOf()],
        domain: domain,
        zeroline: false,
        showgrid: false,
      },
      // height: chartHeight,
    };

    var configIdx = 0;

    _this.plotsConfig.list.filter((c: any) => c.show).forEach((config: any) => {

      configIdx += 1;
      layout['yaxis' + configIdx] = {
        fixedrange: true,
        zeroline: false,
        showgrid: false,
        automargin: true,
        anchor: 'free',
        // anchor: configIdx != 2 ? 'free' : 'x',
        position: configIdx > 1 ? (configIdx % 2 != 0 ? 0.05 * (configIdx - 1) / 2 : (1 - (0.05 * (configIdx - 2) / 2))) : null,
        overlaying: configIdx > 1 ? 'y' : null,
        side: configIdx % 2 == 0 ? 'right' : 'left',
        title: {
          // standoff: 50,
          text: this.filterService.convertUnit(config.unit).unit
        }
      };

      let noData = true;
      Object.entries(data.aspects).forEach((aspectValues: any) => {

        const aspect = aspectValues[0];
        const values = aspectValues[1];

        if (config?.traces?.length > 0 && Object.keys(values).length > 0 && values?.variables != null) {

          config.traces.filter((x: any) => values.variables.hasOwnProperty(x.id) && aspect == x.aspect).forEach((x: any, idx: any) => {

            if (!values.timestamps || values.timestamps.length == 0) return;

            let traceName = x.label;
            let multiplier = x.multiplier != null ? x.multiplier : (config.multiplier != null ? config.multiplier : 1);
            let hovertext = values.variables[x.id].map((v: any, xIdx: any) => {
              let hover = "<b>" + _this.translate.instant("CONTINUOUS_EXPLORATION.VARIABLE") + ": </b>" + (x.paramRFlag ? x.id + ' - ' : '') + traceName + '<br>' +
                "<b>" + _this.translate.instant("CONTINUOUS_EXPLORATION.VALUE") + ": </b>" + _this.filterService.parseGaugeValue(this.filterService.convertUnit(config.unit, v * multiplier).value, 2, 1) +
                " " + layout['yaxis' + configIdx].title.text + '<br>' +
                "<b>" + _this.translate.instant("CONTINUOUS_EXPLORATION.TIME") + ": </b>" + moment(values.timestamps[xIdx]).format("MMM DD, YYYY HH:mm:ss.SSS") + '<br>';
              if (_this.showTraceName) hover += "<b>" + _this.translate.instant("CONTINUOUS_EXPLORATION.VARIABLE_NAME") + ": </b>" + (x.paramRFlag ? x.id + ' - ' : '') + x.name + '<br>';
              return hover;
            });

            if (noData) noData = values.timestamps.length > 0;

            _this.traces.push({
              showlegend: true,
              yaxis: 'y' + configIdx,
              name: traceName,
              id: x.id,
              legendgroup: x.id,
              visible: x.show,
              // mode: 'lines',
              mode: x.traceType == 'setpoint' ? 'lines' : 'lines+markers',
              type: 'scatter',
              text: hovertext,
              line: {
                dash: x.traceType == 'setpoint' ? 'dot' : 'solid',
              },
              hoverinfo: "text",
              x: values.timestamps,//.map((x: any) => x.substring(0, x.length - 6)),
              y: values.variables[x.id].map((x: any) => x != null ? this.filterService.convertUnit(config.unit, x * multiplier).value : null),
            });
          });
        }

      });
    });

    try {
      Object.entries(data.aspects).forEach((aspectValues: any) => {

        const aspect = aspectValues[0];
        const values = aspectValues[1];

        _this.aggregations?.forEach((aggr: any) => {
          if (values != null && values.hasOwnProperty('variables') && values.variables != null &&
            values.variables.hasOwnProperty(aggr.id) && values.variables[aggr.id] != null && values.variables[aggr.id].length > 0) {

            let yValuesIdx = _this.traces.findIndex((x: any) => x.yaxis == 'y1');
            let yVals = (yValuesIdx != -1) ? _this.clonerService.deepClone(_this.traces[yValuesIdx].y) : [];

            _this.traces.push({
              name: aggr.label,
              id: aggr.id,
              showlegend: false,
              mode: 'lines',
              type: 'scatter',
              line: {
                width: 0
              },
              hovertext: values.variables[aggr.id].map((x: any) => '<b>' + aggr.label.capitalize() + '</b>: ' + x),
              hoverinfo: "text",
              x: values.timestamps,
              y: yVals,
            });
          }
        });

      });
    } catch (error) {
      console.log(error);
    }

    layout.shapes = [];
    layout.annotations = [];
    if ((_this.backgroundType == null || _this.backgroundType == 0) && data.hasOwnProperty('states') && data.states != null && data.states.length > 0) {

      data.states.forEach((bar: any) => {
        let color = null;
        try {

          let c_timeStates = _this.cacheService.get("timeStates");

          let stateIdx = (c_timeStates != null ? c_timeStates : _this.machine.profile.timeStates).filter((x: any) => x.enabled).findIndex((x: any) => x.state == bar.value);
          if (stateIdx != -1) {
            color = (c_timeStates != null ? c_timeStates : _this.machine.profile.timeStates).filter((x: any) => x.enabled)[stateIdx].color;
          }
        } catch (error) {
          console.log(error);
        }
        layout.shapes.push({
          type: 'rect',
          xref: 'x',
          yref: 'paper',
          x0: bar.timeStart,
          y0: 0,
          x1: bar.timeEnd,
          y1: 1,
          fillcolor: color,
          opacity: 0.2,
          line: {
            width: 0
          }
        });
      });
    }

    _this.dashboardData.plotData = {
      layout: layout,
      traces: _this.traces,
      params: {
        displayModeBar: false
      }
    };

    if (_this.traces.every((trace: any) => trace.x.length == 0) && layout.shapes.length == 0) {
      _this.plotState = 2;
    } else {
      _this.plotState = 1;
    }

    _this.dispatcherService.getDispatch(_this, skipGetData ? 302 : 300);

  }

  getDomain(range: any) {
    if (range != null && range > 0) {
      // solo due assi
      if (range < 3) {
        return [0, 1];
        // lunghezza pari
      } else if (range % 2 == 0) {
        return [0.05 * (range - 1) / 2, 1 - (0.05 * (range - 1) / 2)];
        // lunghezza dispari
      } else if (range % 2 != 0) {
        return [0.05 * (range - 1) / 2, 1 - (0.05 * (range - 3) / 2)];
      }
    }
    return [0, 1];
  }

  calculateHeight(videoId) {
    try {
      let element = document.getElementById(videoId);
      if (element != null) return (element.offsetWidth * 9 / 16 - 16);
    } catch (error) { console.log(error) }
  }

  toggleHideData() {
    this.hideMonitoringData = !this.hideMonitoringData;

    let maxWidth = this.hideMonitoringData ? 48 : 96;

    this.pastVideosStreamings?.forEach(video => {
      console.log({ video });
      document.getElementById(video.id).style.maxWidth = maxWidth + '%';
    });

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    let queryParams = this.route.snapshot.queryParams;

    this.backButton = [this.machineId, "video-streaming", "past-videos"];
    this.internalDataService.setBackButton(this.backButton);

    this.videoIds = queryParams.videoIds?.split(",");
    this.timeStart = queryParams.timeStart;

    this.route.params.subscribe((params: Params) => {

      this.machineId = params['machineId'];
      let queryParams = this.route.snapshot.queryParams;

      try {
        this.componentId = queryParams.componentId;
        this.videoIds = queryParams.videoIds?.split(",");
        this.timeStart = queryParams.timeStart;
      } catch (error) { console.log("Wrong query params") }

      try { this.videoType = queryParams.type }
      catch (error) { }

      // this.internalDataService.setHMComponentSelected({
      //   videoIds: this.videoIds,
      //   timeStart: this.timeStart,
      // });

    });

    this.dispatcherService.getDispatch(this, 300);


  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingEvents.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.internalDataService.setBackButton([]) } catch (error) { }

    this.cacheService.set('sessionDetail', null);
    try {
      this.pastVideosStreamings.forEach(video => {
        try { video.player.dispose() } catch (error) { }
      });
    } catch (error) { }
  }

}