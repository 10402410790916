import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';


@Component({
  selector: 'hm-features-scatter-matrix',
  templateUrl: './hm-features-scatter-matrix.component.html',
  styleUrls: ['./hm-features-scatter-matrix.component.scss']
})
export class HmFeaturesScatterMatrix implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public backButton: any;
  public tabs: any;

  public HMComponentSelectedSub: Subscription;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public pollingTime: any;
  public pollingEvents: any;

  public currentSynopticId: any;
  public synopticConfig: any;
  public synopticConfigDefault: any;
  public monitoringData: any;
  public monitoringDataUnparsed: any;
  public dashboardConfig: any;

  public completeDashboardConfig: any;
  public dashboardType: any = null;

  public cycleInfoConfig: any;
  public cycleData: any;

  public dialogData: any;
  public showDialog: boolean = false;
  public chartOptions: any;

  public defaultInterval: any;

  public interval: any;
  public intervalConfig: any;

  public componentTableInfos: any = [];

  public tableInfos: any = [];
  public tableColumns: string[] = [];
  public table: any;
  public tableData: any;

  public componentConfig: any = {};
  public componentId: any;
  public machineRefId: any;

  public sectionName: any = "healthMonitoringDetail";
  public dashboardName: any = "features-scatter-matrix";

  public dashboardData: any = {};

  public availableMachines: any;

  public cachedIntervalId: any = "intervalHM";

  public mobileListener: Subscription;
  public isMobile: boolean = false;
  public isSmThanTablet: boolean;
  public errorDataMobile: any;

  features: any = [];

  colorArray: any = [
    {
      min: 0,
      max: 10,
      color: '#FF5757'
    },
    {
      min: 10,
      max: 20,
      color: '#FF7F57'
    },
    {
      min: 20,
      max: 30,
      color: '#FAA63E'
    },
    {
      min: 30,
      max: 40,
      color: '#FAC63E'
    },
    {
      min: 40,
      max: 50,
      color: '#FADC3E'
    },
    {
      min: 50,
      max: 60,
      color: '#FAF43E'
    },
    {
      min: 60,
      max: 70,
      color: '#D2FA3E'
    },
    {
      min: 70,
      max: 80,
      color: '#C5EB5C'
    },
    {
      min: 80,
      max: 90,
      color: '#8BE362'
    },
    {
      min: 90,
      max: 100,
      color: '#62E39D'
    },
    {
      min: 100,
      max: 110,
      color: '#40A4F4'
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getComponentsConfig, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getScatterMatrixPolling, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public mobile: MobileService,
    public router: Router,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));
    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile;
      this.isSmThanTablet = value.isSmThanTablet;
    })

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['HEALTH_MONITORING.TITLE', 'FEATURES_EXPLORATION.SCATTER_MATRIX'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb[2] = value.machineName;
        this.breadcrumb = newBreadcrumb;
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.HMComponentSelectedSub = this.internalDataService.HMComponentSelected.subscribe(value => {
      if (value != null) {
        this.breadcrumb = this.internalDataService.getHMBreadcrumb(this, value);
        this.internalDataService.setBreadcrumb(this.clonerService.deepClone(this.breadcrumb));
      }
    });

    this.pollingTime = 0;
    this.pollingEvents = Subscription;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
    } catch (error) {
      console.log(error);
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET COMPONENTS CONFIG

  getComponentsConfig(_this: any) {

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getComponentsConfig(_this, _this.machineId, _this.machineRefId, _this.componentId, true);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getScatterMatrixPolling, this.getScatterMatrix, this.machine.timezone, 6, 7) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {

    let c_customVariables = _this.cacheService.get("customVariables");

    _this.features = [];
    if (_this.appConfig?.addCustomVariables && c_customVariables?.machineId == _this.machineId && c_customVariables?.value != null) {
      _this.features = c_customVariables.value.features;
    }

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SYNOPTIC CONFIG

  // polling
  getScatterMatrixPolling(_this: any, range: any = null) {
    try {

      if (range == null) {
        if (_this.cacheService.get(_this.cachedIntervalId) == null) {

          _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

          _this.intervalConfig = {
            list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
            selected: _this.interval
          };

          _this.cacheService.set(_this.cachedIntervalId, _this.clonerService.deepClone(_this.intervalConfig));

        } else {
          _this.intervalConfig = _this.clonerService.deepClone(_this.cacheService.get(_this.cachedIntervalId));
          _this.interval = _this.intervalConfig.selected;
        }
      }

      if (_this.defaultInterval == null) _this.defaultInterval = _this.clonerService.deepClone(_this.interval);

      if (_this.pollingTime > 0) _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => _this.getScatterMatrix(_this, count, range));
      else _this.getScatterMatrix(_this, 0, range);

    } catch (error) {
      console.log(error);
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getScatterMatrix(_this: any, count?: any, range?: any) {
    try {

      let url = '/apif/condition-monitoring/cycles-detail/' + _this.machineId + '/' + _this.componentId;

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        anomalyDetection: true,
        tz: _this.machine.timezone
      };

      if (_this.machineRefId != null) {
        query.machineId = _this.machineRefId;
        try {
          query.processes = _this.machine.machines[_this.machineRefId].processes.join(";");
        } catch (error) { }
      }

      let payload: any = {
        additionalCycleVariables: _this.features?.map(x => x.id) ?? [],
      };

      _this.apiService.sendPostRequest(url, payload, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            // console.log(_this.componentConfig);

            // _this.dashboardData = Object.assign(_this.clonerService.deepClone(_this.componentConfig), _this.parseData(data.body));
            _this.dashboardData = data.body;
            // _this.table.list = _this.parseTableData(_this.dashboardData.timeserie);

            try {
              _this.dashboardData.dataConfig = {};
              // set plot data and configs
              _this.dashboardData.plotData = _this.buildPlotConfig(_this, _this.dashboardData);
            } catch (error) { }


            _this.completeDashboardConfig = {
              dashboardData: _this.clonerService.deepClone(_this.dashboardData),
              machineProfile: _this.machine.profile,
              dashboardConfig: _this.dashboardConfig,
              aggregations: _this.aggregations,
              dashboardType: _this.dashboardType,
              aggregatedTable: _this.aggregatedTable,
            };

            _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      console.log(error);
    }

  }

  // BUILD PLOT
  buildPlotConfig(_this: any, data: any) {

    let traces: any = [];
    let dataSet: any = this.clonerService.deepClone(data);

    let i = 0;
    let features = _this.features?.reduce((acc, val, index) => {

      if (val.component != this.componentId) return acc;
      // if (val.components == null || val.components.length == 0 || !val.components.includes(this.componentId)) {
      //   return acc;
      // }

      let obj = {
        index: i + 1,
        id: val.id,
        unit: val.unit,
        label: val.translations?.[this.translate.currentLang] ?? val.id,
        show: val.selected,
        values: dataSet.map(x => x[val.id]),
      };

      i++;

      acc.push(obj)
      return acc;
    }, []);

    let plotLayout: any = {
      showlegend: false,
      hovermode: 'closest',
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: -0.15
      },
      grid: {
        subplots: []
      },
      margin: {
        t: 0,
        r: 80,
        b: 80,
        l: 80,
        pad: 5
      }
    };

    let subplots = []
    for (let y = 0; y < features.length; y++) {

      let yaxisIndex = `${y > 0 ? (y + 1) : ''}`;
      let subplot = [];

      plotLayout[`yaxis${yaxisIndex}`] = {
        zeroline: false,
        // showgrid: false,
        title: `${features[y].label} [${features[y].unit}]`
      }
      plotLayout[`xaxis${yaxisIndex}`] = {
        zeroline: false,
        // showgrid: false,
        title: `${features[y].label} [${features[y].unit}]`
      }

      for (let x = 0; x < features.length; x++) {
        subplot.push(`x${x > 0 ? (x + 1) : ''}y${yaxisIndex}`)
      }
      subplots.push(subplot);
    }

    plotLayout.grid.subplots = subplots;

    let health = dataSet.map(x => x.health != null ? x.health * 100 : null);
    let cycleIds = dataSet.map(x => x.cycleId);
    let cycleTimes = dataSet.map(x => x.cycleTime);
    let cycleTimestamps = dataSet.map(x => x.timestamp);

    let healthColor = health.reduce((acc, val) => {
      let color = "black";
      if (val == null) {
        acc.push(color);
        return acc;
      }

      color = this.colorArray?.find(x => val >= x.min && val < x.max)?.color;
      acc.push(color);
      return acc;
    }, [])

    features.forEach(f1 => {
      features.forEach(f2 => {

        let cycleInfos = f1.values.reduce((acc, val, index) => {
          let conf = dataSet.find(x => x.cycleId == cycleIds[index]);
          acc.push(conf);
          return acc;
        }, []);

        let hovertext = f1.values.reduce((acc, val, index) => {

          // console.log(val, f2.values[index]);

          let hover = "";
          hover += `<b> ${f1.label}:</b> ${this.filterService.parseGaugeValue(val, f1.decimals ?? 2, f1.multiplier ?? 1)} ${f1.unit}<br>`;
          hover += `<b> ${f2.label}:</b> ${this.filterService.parseGaugeValue(f2.values[index], f2.decimals ?? 2, f2.multiplier ?? 1)} ${f2.unit}<br>`;
          hover += `<b> ${this.translate.instant("HEALTH_MONITORING.HEALTH")}:</b> ${this.filterService.parseGaugeValue(health[index], 1, 1)} %<br>`;
          hover += `<b> ${this.translate.instant("GLOBAL.CYCLE_ID")}:</b> ${cycleIds[index]}<br>`;
          hover += `<b> ${this.translate.instant("GLOBAL.DATE")}:</b> ${cycleTimestamps[index]}<br>`;
          hover += `<b> ${this.translate.instant("GLOBAL.DURATION")}:</b> ${this.filterService.parseGaugeValue(cycleTimes[index], 2, 1)} s<br>`;
          acc.push(hover);
          return acc;
        }, []);

        traces.push({
          x: f1.values,
          y: f2.values,
          text: hovertext,
          cycleInfos: cycleInfos,
          hoverinfo: 'text',
          mode: 'markers',
          type: 'scattergl',
          marker: {
            color: healthColor
          },
          xaxis: `x${f1.index}`,
          yaxis: `y${f2.index}`
        })
      })
    })

    let plotConfig = {
      layout: plotLayout,
      traces: traces,
      params: {
        displayModeBar: false,
        linkToHMCycles: true,
      }
    };

    console.log({ plotConfig });

    return plotConfig;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    let queryParams = this.route.snapshot.queryParams;

    this.backButton = [this.machineId, "health-monitoring"];
    this.internalDataService.setBackButton(this.backButton);

    this.componentId = queryParams.componentId;
    this.machineRefId = queryParams.machineRefId;

    this.route.params.subscribe((params: Params) => {

      this.machineId = params['machineId'];

      try {
        let queryParams = this.route.snapshot.queryParams;
        this.componentId = queryParams.componentId;
        this.machineRefId = queryParams.machineRefId;
      } catch (error) { console.log("Wrong query params") }

      this.internalDataService.setHMComponentSelected({
        machineRefId: this.machineRefId,
        componentId: this.componentId,
      });

    });

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pollingEvents.unsubscribe() } catch (error) { }
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.HMComponentSelectedSub.unsubscribe() } catch (error) { }
    try { this.internalDataService.setBackButton([]) } catch (error) { }
  }

}