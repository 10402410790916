<mat-card fxLayout="row" fxLayoutAlign="center center" [ngClass]="loadingData.class" class="loading-card">

  <table *ngIf="!newLoadingData">
    <tr>
      <td class="icon-container">
        <i class="fad fa-spinner fa-pulse icon"></i>
      </td>
      <td class="message-container">
        <span>{{loadingData.message}}</span>
        <kpi-bar *ngIf="loadingData.progressBarConfig != null"
          [kpiProps]="{ 'value': loadingData.progressBarConfig.value, 'hideLabel': true , style: 'margin-top:12px;'}">
        </kpi-bar>
      </td>
    </tr>
  </table>

  <div fxLayout="row" fxLayoutAlign="space-evenly center" *ngIf="newLoadingData">
    <span><i class="fad fa-spinner fa-pulse icon"></i></span>
    <div *ngIf="loadingData.message" style="margin-left: 12px !important;">
      <span>{{loadingData.message}}</span>
      <kpi-bar *ngIf="loadingData.progressBarConfig != null"
        [kpiProps]="{ 'value': loadingData.progressBarConfig.value, 'hideLabel': true , style: 'margin-top:12px;'}">
      </kpi-bar>
    </div>
  </div>

</mat-card>