import { Component, HostListener, Input, OnInit } from '@angular/core';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

@Component({
  selector: 'ff-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})

export class TabsComponent implements OnInit {

  @Input() tabs: any;
  public isMobile: any;

  constructor(
    public translate: FfTranslateService
  ) {
    this.isMobile = window.innerWidth <= 959;
  }

  selectTab(sel: any) {
    // reset position of tabs content
    this.tabs.forEach((t: any) => {
      t.selected = false;
      delete t.left;
      delete t.right;
    });
    // set as selected
    sel.selected = true;
    // loop on tabs to set on left previous and right nexts
    let selPassed = false;
    this.tabs.forEach((t: any) => {
      if (t.id != sel.id) {
        if (!selPassed) t.left = true;
        else t.right = true;
      } else {
        selPassed = true;
      }
    });
  }

  ngOnInit(): void {
    this.tabs = this.tabs || [];
    if (this.isMobile && this.tabs.filter((tab: any) => !tab.hideMobile).length <= 1) this.tabs[0].urlArray = [];

  }

  ngOnDestroy(): void { }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = window.innerWidth <= 959;
  }

}
