<div mat-dialog-title fxLayout="row" class="w100">
    <span>{{ dialog.title }}</span>
    <span fxFlex></span>

    <button mat-dialog-close class="close">
        <mat-icon class="icon" aria-label="close">close</mat-icon>
    </button>
</div>

<mat-dialog-content class="mat-typography">

    <ff-loading-card *ngIf="state == 0" [loadingData]="loadingData">
    </ff-loading-card>

    <div *ngIf="state == 2" class="not-found w100" fxLayout="row" fxLayoutAlign="center center">
        <span>{{ 'CYCLE_EXPLORATION.NO_CYCLE_IMAGE_AVAILABLE' | translate }}</span>
    </div>

    <ng-container *ngIf="state == 1">
        <button class="filter-button md-primary" (click)="downloadImages()" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="icon" aria-label="close">download</mat-icon>
            <span>{{ 'GLOBAL.DOWNLOAD_IMAGES' | translate }}</span>
        </button>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" style="margin-top: 12px;">
            <div *ngFor="let image of images | keyvalue; let index = index" fxLayout="column">

                <!-- <span>{{ index }}) {{ image.key }}</span> -->
                <span>{{ index + 1 }}.</span>
                <img onerror="this.src=''" [src]="image.value">
            </div>
        </div>
    </ng-container>

</mat-dialog-content>