<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null " class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center"
    [ngStyle]="{'margin-top': isMobile ? '12px' : '0'}">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < 5 || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar -->
    <div *ngIf="pageState.value >= 5 && filterButtons?.length > 0" class="ff-toolbar py-3" fxLayout="column"
      fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <!-- filter buttons -->
      <ff-filter-buttons [filterButtons]="filterButtons" (selected)="onFiltersDialogSelect($event)"
        [ngStyle.gt-sm]="{'margin-left': aggregations?.length > 0 ? '8px' : '0'}"
        [ngStyle.lt-md]="{'margin-top': aggregations?.length > 0 ? '8px' : '0'}">
      </ff-filter-buttons>

    </div>

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 5" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 5" fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start start" class="w100 h100">

      <ff-table-sortable [tableConfig]="tableConfig" (clicked)="onButtonClick($event)"></ff-table-sortable>

    </div>

  </div>

</div>