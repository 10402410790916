import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'connection-timeline-dialog',
  templateUrl: './connection-timeline-dialog.component.html',
  styleUrls: ['./connection-timeline-dialog.component.scss']
})
export class ConnectionTimelineDialogComponent implements OnInit, OnDestroy {

  aggregationsPayload: any;
  chartOptions: any;
  appConfig: any;
  useStatesInsteadOfCategoriesInStateTimeline: any;
  ganttState: number;
  exportCSVConfig: any;
  ganttData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<ConnectionTimelineDialogComponent>,
    public appConfigService: AppConfigService,
    public translate: FfTranslateService,
    public http: ApiService,
    public clonerService: ClonerService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
  ) {
    this.appConfig = this.appConfigService.getAppConfig;
  }

  ngOnInit(): void {

    (async () => {
      let endpointUrl = (this?.dialog?.endpointUrl ?? '/apif/machine-monitoring/connection-timeline/{machineId}')?.replaceAll("{machineId}", this.dialog.machineId);

      let machine: any = this.clonerService.deepClone(this.dialog?.row);
      let payload = this.internalDataService.buildMachinePayload(this.dialog?.row);
      this.internalDataService.buildAggregationsPayload(this);
      payload.filters = this.aggregationsPayload;

      // if (this.interval != null && this.interval.enabledPolling) {
      //   this.interval = this.intervalService.getIntervalById(this.intervalConfig.selected.id, machine.timezone);
      //   this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));
      // }

      let connectionInterval = this.appConfig?.homepage?.connectedMachinesList?.requestedHours ?? 24;
      // max 30 days
      if (connectionInterval > 24 * 30) connectionInterval = 24 * 30;

      let query: any = {
        from: moment().utc().subtract(connectionInterval, "hours").format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z",
        tz: machine.timezone,
      };

      // if (this.availableMachines != null) query.machineId = this.availableMachines?.list?.join(';');
      // else if (machine.machineReference) query.machineId = machine.machineReference;

      try {

        let method = 'POST';
        if (endpointUrl.includes(':')) {
          endpointUrl = endpointUrl.split(':');
          method = endpointUrl[0];
          endpointUrl = endpointUrl[1];
        }

        let requestSent = null;
        if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
        else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
        else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

        const data: any = await requestSent.toPromise();

        //------------------------------------------------------------------------//
        // RESPONSE PARSING

        let copyData: any = this.clonerService.deepClone(data?.body ?? []);
        this.ganttData = copyData;

        console.log({ copyData });
        this.createCSV();
        this.parseGantt(copyData);


        // this.dialog.activitiesSelectionWidget.data = copyData;
      } catch (error) {
      }

    })();

  }

  parseGantt(data: any) {

    let _this = this;

    let height = 400;
    try { height = window.innerHeight - 300 }
    catch (error) { console.log(error) }

    this.chartOptions = {
      chart: {
        height: height,
        type: 'rangeBar',
        animations: {
          enabled: false
        },
        zoom: {
          enabled: true
        },
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: true,
            customIcons: []
          },
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          dataLabels: {
            hideOverflowingLabels: false
          }
        }
      },
      dataLabels: {
        enabled: false,
        style: {
          fontSize: '14px',
        },
        formatter: function (val: any, opts: any) {
          // console.log (val, opts);
          var label = opts.w.globals.labels[opts.dataPointIndex];
          var a = moment(val[0]);
          var b = moment(val[1]);
          var diff = b.diff(a, 'seconds');
          var diffHM = this.filterService.parseTime(b.diff(a, 'seconds'), 's', 'HH:mm');
          if (diff > 0) {
            return diffHM;
          }
          // return label;
        },
        // style: {
        //   colors: ['#f3f4f5', '#fff']
        // }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
          show: true,
        }
      },
      tooltip: {
        enabled: true,
        custom: function (totalSeries: any) {

          let series = totalSeries.series;
          let seriesIndex = totalSeries.seriesIndex;
          let dataPointIndex = totalSeries.dataPointIndex;
          let w = totalSeries.w;

          if (w.config.series[seriesIndex].data[dataPointIndex].hideTooltip) return '';
          let startText = w.config.series[seriesIndex].data[dataPointIndex].y != null ?
            '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.START") + ': </strong>' + moment(w.config.series[seriesIndex].data[dataPointIndex].y[0]).format('MMM DD, YYYY HH:mm:ss') + '</p>' : '';

          let endText = w.config.series[seriesIndex].data[dataPointIndex].y != null ?
            '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.END") + ': </strong>' + moment(w.config.series[seriesIndex].data[dataPointIndex].y[1]).format('MMM DD, YYYY HH:mm:ss') + '</p>' : '';

          let durationText = w.config.series[seriesIndex].data[dataPointIndex].duration != null ?
            '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.DURATION") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].duration + '</p>' : '';

          let stateText = w.config.series[seriesIndex].data[dataPointIndex].label != null ?
            '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.STATE") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].label + '</p>' : '';

          let modeText = w.config.series[seriesIndex].data[dataPointIndex].mode != null ?
            '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.MODE") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].mode + '</p>' : '';

          let codeText = w.config.series[seriesIndex].data[dataPointIndex].id != null ?
            '<p> <strong>' + _this.translate.instant("MACHINE_STATUS_LOG.CODE") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].id + '</p>' : '';

          // let alarmText = w.config.series[seriesIndex].data[dataPointIndex].alarmText != null ?
          //   '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.ALARM") + ': </strong>' + _this.internalDataService.parseAlarmsLabel(w.config.series[seriesIndex].data[dataPointIndex].alarmText, 'code', machineCopy?.machineId) + '</p>' : '';

          // let alarmTextTransl = w.config.series[seriesIndex].data[dataPointIndex].alarmText != null ?
          //   '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.ALARM_MESSAGE") + ': </strong>' + _this.internalDataService.parseAlarmsLabel(w.config.series[seriesIndex].data[dataPointIndex].alarmText, 'message', machineCopy?.machineId) + '</p>' : '';

          let alarmCodes = '';
          if (w.config.series[seriesIndex].data[dataPointIndex].ids != null && w.config.series[seriesIndex].data[dataPointIndex].ids.length > 0) {
            // let maxAlarmsPerColumn = 5;
            // let alarmsUlColumnsNum = 1;

            // try {
            //   alarmsUlColumnsNum = Math.ceil(w.config.series[seriesIndex].data[dataPointIndex].ids.length / maxAlarmsPerColumn);
            // } catch (error) { }

            alarmCodes +=
              '<p><strong>' + _this.translate.instant("STATE_TIMELINE.ALARMS") + `: </strong></p><ul style="margin:0;">`;
            // '<p><strong>' + _this.translate.instant("STATE_TIMELINE.ALARMS") + `: </strong></p><ul style="margin:0;columns:${alarmsUlColumnsNum};column-span:2">`;
            w.config.series[seriesIndex].data[dataPointIndex].ids.forEach((alarm: any, idx: any) => {
              if (idx < 4) {
                alarmCodes += '<li>' +
                  '<p><strong>' + _this.translate.instant("MACHINE_STATUS_LOG.CODE") + ':</strong> ' + alarm.code + '</p>' +
                  '<p><strong>' + _this.translate.instant("STATE_TIMELINE.ALARM_MESSAGE") + ':</strong> ' + alarm.message + '</p>' +
                  (alarm.category != null ? ('<p><strong>Category:</strong> ' + alarm.category + '</p>') : '') +
                  '<p><strong>' + _this.translate.instant("STATE_TIMELINE.START") + ':</strong> ' + moment(alarm.start).format('MMM DD, YYYY HH:mm:ss') + '</p>' +
                  '<p><strong>' + _this.translate.instant("STATE_TIMELINE.END") + ':</strong> ' + moment(alarm.end).format('MMM DD, YYYY HH:mm:ss') + '</p>' +
                  '</li>';
              }
            });
            if (w.config.series[seriesIndex].data[dataPointIndex].ids?.length > 4) alarmCodes += '<li>' + this.translate.instant("GLOBAL.OTHER_ALARMS", { n: w.config.series[seriesIndex].data[dataPointIndex].ids?.length - 4 }) + '</li>';
            alarmCodes += '</ul>';
          }

          let detectedStates = '';
          if (w.config.series[seriesIndex].data[dataPointIndex].states != null && w.config.series[seriesIndex].data[dataPointIndex].states.length > 0) {
            detectedStates +=
              '<p><strong>Alarm Codes: </strong></p><ul style="margin:0;">';
            w.config.series[seriesIndex].data[dataPointIndex].states.forEach((alarm: any) => {
              detectedStates += '<li>' + alarm.message + '</li>';
            });
            detectedStates += '</ul>';
            // phaseText = '';
          }

          // var detectedStates = '';
          // if (w.config.series[seriesIndex].data[dataPointIndex].states != null && w.config.series[seriesIndex].data[dataPointIndex].states.length > 0) {
          //   detectedStates +=
          //     '<p> <strong>Start: </strong>' + moment(w.config.series[seriesIndex].data[dataPointIndex].y[0]).utc(0).format('MMM DD, YYYY HH:mm:ss') + '</p>' +
          //     '<p> <strong>End: </strong>' + moment(w.config.series[seriesIndex].data[dataPointIndex].y[1]).utc(0).format('MMM DD, YYYY HH:mm:ss') + '</p>' +
          //     '<p><strong>Alarm Codes: </strong></p><ul style="margin:0;">';
          //   w.config.series[seriesIndex].data[dataPointIndex].states.forEach(alarm => {
          //     detectedStates += '<li>' + alarm.message + '</li>';
          //   });
          //   detectedStates += '</ul>';
          //   phaseText = '';
          // }

          // if (w.config.series[seriesIndex].data[dataPointIndex].alertDescription != null) {
          //   alarmCodes += '<p><strong>Alert description: </strong>' + w.config.series[seriesIndex].data[dataPointIndex].alertDescription + '</p>';
          // }
          return '<div class="arrow_box" style="background-color: ' + w.config.series[seriesIndex].data[dataPointIndex].fillColor + '80; font-size: 0.8rem;">' +
            startText +
            endText +
            codeText +
            // machineText +
            durationText +
            stateText +
            modeText +
            // alarmText +
            // alarmTextTransl +
            alarmCodes +
            detectedStates +
            '</div>'
        },
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            fontSize: '12px'
          }
        }
      },
      grid: {
        row: {
          colors: ['#f3f4f5', '#fff'],
          opacity: 1
        }
      },
    }

    let series: any = [];

    data?.forEach(state => {

      let duration = state.end != null ? this.filterService.parseTime(moment(state.end).diff(moment(state.start), 's'), 's', 'HH:mm:ss') :
        this.filterService.parseTime(moment().diff(moment(state.start), 's'), 's', 'HH:mm:ss');

      series.push({
        x: this.translate.instant("GLOBAL.CONNECTION_STATE"),
        y: [
          moment(state.start).utc(false).valueOf() - 0,
          (state.end != null) ? moment(state.end).utc(false).valueOf() : moment().utc(false).valueOf(),
        ],
        duration: duration,
        label: state?.connected ? this.translate.instant("LINE_STATES.CONNECTED") : this.translate.instant("LINE_STATES.NOT_CONNECTED"),
        fillColor: state?.connected ? "#4BDD7D" : "#FC5249",
      });

    });

    if (series.length == 0 || series.every((x: any) => x.hideTooltip)) {
      this.ganttState = 2;
    } else {
      this.ganttState = 1;
      this.chartOptions.series = [{ data: series }];

      // Limit to real data
      this.chartOptions.xaxis.min = new Date(this.filterService.getMin(this.chartOptions.series[0].data, 'y', 0)).getTime();
      this.chartOptions.xaxis.max = new Date(this.filterService.getMax(this.chartOptions.series[0].data, 'y', 1)).getTime();

      // Limit to interval
      // this.chartOptions.xaxis.min = new Date(this.interval.start).getTime();
      // this.chartOptions.xaxis.max = new Date(this.interval.end).getTime();
    }
  }

  createCSV() {

    // export file name
    let filename = this.dialog.title;

    // Add export date
    // filename = filename + ' - ' + moment().format("YYYY/MM/DD_HH:mm:ss");

    // Add machine name
    // if (this.tableConfig?.machine?.machineName) filename = filename + ' - ' + this.tableConfig?.machine?.machineName;

    // Export table config
    this.exportCSVConfig = {
      title: filename,
      // type: 'pdf',
      payload: {
        translations: {},
        rows: []
      }
    };

    let exportCSVColumnsInfos: any = this.appConfig?.homepage?.connectedMachinesList?.exportCSVColumnsInfos ?? [
      {
        variable: "start",
        label: "INTERVAL.START",
        type: "date"
      },
      {
        variable: "end",
        label: "INTERVAL.END",
        type: "date"
      },
      {
        variable: "connected",
        label: "LINE_STATES.CONNECTED"
      },
    ]

    try {
      let translation = {};
      exportCSVColumnsInfos?.filter(x => !x.excludeDownload)?.forEach(info => {
        let unit = info?.suffix ?? info?.unit;
        translation[info.variable] = this.translate.instant(info.label ?? '-') + (unit != null ? (' [' + this.translate.instant(unit) + ']') : '')
      });
      this.exportCSVConfig.payload.translations = translation;
      this.ganttData?.sort(this.filterService.sortByProperty('start', 'desc', true)).forEach((prod) => {
        let row = {};
        exportCSVColumnsInfos.filter(x => !x.excludeDownload)?.forEach(info => row[info.variable] = this.filterService.parseObjFromConfig(prod, info));
        this.exportCSVConfig.payload.rows.push(row);
      });
    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy() { }

  confirm() { this.dialogRef.close(true) }

  confirmAdditionalAction() { if (this.dialog?.additionalButton) this.dialogRef.close({ additionalAction: true }) }

}
