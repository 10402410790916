<div mat-dialog-title fxLayout="row" class="w100">

  <span fxLayout="column" fxLayoutAlign="center center">{{ dialog.title | translate }}</span>

  <span fxFlex></span>

  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>

</div>
<mat-dialog-content class="mat-typography alternative-color" [style.min-height]="'calc(100vh - 200px)'">

  <div [style.min-height]="'calc(100vh - 200px)'" fxLayout="row" fxLayoutAlign="center center" class="h100 w100"
    fxLayoutGap="8px" *ngIf="state == 0">

    <ff-loading-card></ff-loading-card>
  </div>

  <div [style.min-height]="'calc(100vh - 200px)'" fxLayout="row" fxLayoutAlign="start stretch" class="h100 w100"
    fxLayoutGap="8px" *ngIf="state == 1">

    <div fxFlex="50" class="w100 h100 mh100" fxLayout="column" fxLayoutAlign="start stretch">

      <div fxFlex="50" fxLayout="row" fxLayoutAlign="center center">

        <button class="rounded-button" (click)="previousImage()" fxLayout="row"
          matTooltip="{{ 'IMAGES_LABELLING.PREVIOUS_IMAGE' | translate }}" fxLayoutAlign="center center">
          <ff-icon [icon]="'skip_previous'"></ff-icon>
        </button>

        <div id="imageContainter" style="position: relative; margin: auto;">

          <img *ngIf="!noImage" [src]="image" (error)="noImage = true" id="image" style="border: 1px solid #d4d4d4;"
            [style.width.px]="imageWidth != null ? imageWidth : 600"
            [style.height.px]="imageHeight != null ? imageHeight : 300">

          <span *ngIf="noImage" style="text-align: center;">{{ 'GLOBAL.NO_IMAGE_AVAILABLE' | translate }}</span>

        </div>

        <button class="rounded-button" (click)="nextImage()" fxLayout="row"
          matTooltip="{{ 'IMAGES_LABELLING.NEXT_IMAGE' | translate }}" fxLayoutAlign="center center">
          <ff-icon [icon]="'skip_next'"></ff-icon>
        </button>

      </div>

      <div fxFlex="40" *ngFor="let info of dialog?.tableInfos | filterByProperty:['editableInDialog', '==', true]">

        <div fxLayout="row" fxLayoutAlign="center center" style="margin: 8px 0;" *ngIf="dialog.row != null">

          <span fxFlex="{{ dialog?.labelFlex ? dialog.labelFlex : 30}}" style="font-weight: 800;">
            <span>{{ (info?.label != null ? info.label : '-') | translate }}</span>
            <span *ngIf="info.unit && info.unitInTitle">&nbsp;[{{ info.unit | translate }}]</span>
          </span>

          <div fxFlex="{{ dialog?.labelFlex ? (100 - dialog.labelFlex) : 70}}">
            <ng-container [ngTemplateOutlet]="InfoValue" [ngTemplateOutletContext]="{ info:info, item:dialog?.row }">
            </ng-container>
          </div>
        </div>
      </div>

      <div fxFlex="10" fxLayout="row" fxLayoutAlign="start center">
        <span fxFlex></span>

        <button style="margin-left: 8px;" class="filter-button" (click)="confirmUpdateImages()" fxLayout="row"
          [disabled]="checkDisability() || changedUserLabels?.length == 0" fxLayoutAlign="center center">
          <ff-icon [icon]="'save'"></ff-icon>
          <span>{{ 'IMAGES_LABELLING.UPDATE_IMAGES' | translate }}</span>
          <span *ngIf="changedUserLabels?.length > 0">&nbsp;({{changedUserLabels?.length}})</span>
        </button>

      </div>

    </div>

    <span class="vertical-divider"></span>

    <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center">

      <div id="myresult" class="img-zoom-result" style="width: 600px; height: 600px; border: 1px solid #d4d4d4;"></div>
    </div>

  </div>
  <!-- </div> -->

</mat-dialog-content>

<!-- TEMPLATE InfoValue -->
<ng-template #InfoValue let-info="info" let-item="item">

  <div class="input-box" *ngIf="info.dialogEditType == 'inputText'"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <input *ngIf="info.type != 'advancedTranslate' && info.type != 'configFromProfile'" matInput
      class="ff-input text w100 completely" type="text" [matAutocomplete]="auto"
      [class.ff-disabled]="info.disabledInDialog" [(ngModel)]="item[info.variable]">
    <!-- <input *ngIf="info.type =='advancedTranslate'" matInput class="ff-input text w100" type="text"
      [matAutocomplete]="auto" [disabled]="info.disabledInDialog"
      [(ngModel)]="advancedTranslate(info?.advancedTranslateConfig, item)"> -->
    <mat-autocomplete #auto="matAutocomplete">
      <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let option of info.autocomplete; let i = index"
        [attr.data-index]="i">
        <mat-option style="padding-right: 0px" fxFlex [value]="option">
          <div>{{ option | translate }}</div>
        </mat-option>
      </div>
    </mat-autocomplete>
  </div>

  <div class="input-box" *ngIf="info.dialogEditType == 'inputText' && info.type == 'advancedTranslate'"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <div class="ff-input w100 ff-disabled">{{ advancedTranslate(info?.advancedTranslateConfig, item) }}</div>
  </div>

  <div class="input-box" *ngIf="info.dialogEditType == 'inputText' && info.type == 'configFromProfile'"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <div class="ff-input w100 ff-disabled">{{ mapFromProfile(info, item) }}</div>
  </div>

  <div *ngIf="info.dialogEditType == 'inputNum'" class="input-box"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <input matInput class="ff-input number w100" type="number" [min]="0" [(ngModel)]="item[info.variable]"
      [disabled]="info.disabledInDialog">
  </div>

  <div *ngIf="info.dialogEditType == 'closedList'" class="ff-input-select w100" fxLayout="row"
    fxLayoutAlign="start center">
    <mat-select [(value)]="item[info.variable]" [disabled]="info.disabledInDialog || info.listOptions?.length == 0">
      <mat-option *ngIf="info.addNullValue" [value]="null">---</mat-option>
      <ng-container *ngIf="info.closedListType == null">
        <mat-option *ngFor="let opt of info.listOptions" [value]="opt" (click)="selectionChange(opt)">
          <span *ngIf="info?.advancedTranslateConfig != null">
            {{ advancedTranslate(info?.advancedTranslateConfig, opt) }}
          </span>
          <span *ngIf="info?.type == 'configFromProfile'">
            {{ mapFromProfile(info, opt, true) }}
          </span>
          <span *ngIf="info.type != 'advancedTranslate' && info.type != 'configFromProfile'">
            {{ opt }}
          </span>
        </mat-option>
      </ng-container>
      <ng-container *ngIf="info.closedListType == 'arrayOfObjects'">
        <mat-option *ngFor="let opt of info.listOptions" [value]="opt.id">
          {{ filterService.translateElementNewFormat(opt) }}
        </mat-option>
      </ng-container>
    </mat-select>
  </div>

  <div *ngIf="info.dialogEditType == 'inputDateTime'">
    <input matInput class="ff-input datetime w100" type="datetime-local" [max]="nowTime"
      [(ngModel)]="item[info.variable]">
  </div>

  <div *ngIf="info.dialogEditType == 'inputDate'" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center" style="margin-right: 8px;">

    <div class="w100" fxLayout="row" fxLayoutAlign="start center">
      <!-- <mat-icon class="icon">event_available</mat-icon> -->

      <mat-form-field appearance="fill" class="w100">
        <!-- <mat-label>{{ 'CONSUMABLES_SETTINGS.TRAINING_END' | translate }}</mat-label> -->
        <input matInput [matDatepicker]="pickerEnd" [value]="item[info.variable]" disabled
          (dateChange)="onDaySelection($event, info.variable)">
        <mat-datepicker-toggle matSuffix [for]="pickerEnd">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #pickerEnd disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <textarea *ngIf="info.dialogEditType == 'textarea'" rows="1" style="max-height: 160px; min-height: 160px;"
    maxlength="250" class="ff-textarea" [(ngModel)]="item[info.variable]"
    oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' name="textarea" id="textarea">
      </textarea>
  <span *ngIf="info.dialogEditType == 'textarea'">{{item[info.variable] != null ? item[info.variable]?.length : 0}} /
    250</span>

  <div *ngIf="info.dialogEditType == 'inputDuration'">
    <ff-duration-input format="HH:mm" unit="s" [(value)]="item[info.variable]"></ff-duration-input>
  </div>

  <div *ngIf="info.requiredInDialog && (item[info.variable] == null || item[info.variable] == '')" class="required">
    <span>{{ 'GLOBAL.REQUIRED' | translate }}</span>
  </div>

</ng-template>