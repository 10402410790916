import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { MachineComponentSelectionDialogComponent } from '../components/machine-component-selection-dialog/machine-component-selection-dialog.component';
import { ZoomableImageDialogComponent } from '../components/zoomable-image-dialog/zoomable-image-dialog.component';
import { ApiService } from './api.service';
import { AppConfigService } from './app-config.service';
import { CacheService } from './cache.service';
import { ClonerService } from './clone.service';



class FilterButton {

  useCustomVariables: boolean;
  label: string;
  options: Array<any>;
  selection: undefined | Array<any>;
  variable: string;
  config: any;

  constructor(
    variable: any,
    label: any,
    useCustomVariables: any,
    options: any,
    selection: any,
    config: any,
  ) {

    this.variable = variable;
    this.useCustomVariables = useCustomVariables;
    this.selection = selection;
    this.options = options;
    this.label = label;
    this.config = config;

  }

  filter(data: any) {
    let listToCheck = this.config.useCustomVariables ? this.selection : this.options;
    return data.filter((data: any) => listToCheck.filter((opt: any) => opt.selected).some((opt: any) => String(data[this.variable]) == (String(opt.id))))
  }

  updateSelection() {
    if (this.useCustomVariables) {
      this.selection.forEach((sel: any) => {
        let opt = this.options.find((opt: any) => opt.id == sel.label);
        if (opt == null) {
          sel.selected = true;
        } else {
          sel.selected = opt.selected;
        }
      })
    }
  }
}


@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  constructor(
    private translate: FfTranslateService,
    public appConfigService: AppConfigService,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public http: ApiService,
    private router: Router,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
  ) { }

  filterBySearch(array: any, query: any, properties: any) {
    if (array == null || array.length == 0 || array == undefined) return array;

    if (query != '' && query != undefined && query != null) {
      return array.filter(obj => this.filterObjBySearch(obj, query, properties));
    }

    return array;

  };

  filterObjBySearch(obj: any, query: any, properties: any) {

    return properties.some(function (prop: any) {
      switch (typeof obj[prop]) {
        case 'number':
          return String(obj[prop]).includes(query.toLowerCase());
        case 'string':
          return obj[prop].toLowerCase().includes(query.toLowerCase());
        case 'object':
          if (Array.isArray(obj[prop]) && obj[prop].length > 0) {
            return obj[prop].some((x: any) => x.toLowerCase().includes(query.toLowerCase()));
          }
          break;
      }
    });
  }

  sortByProperty(prop: any, type: any, discardNull: any) {
    return function (a: any, b: any) {
      if (prop != null) {
        if (discardNull) {
          if (a[prop] === b[prop]) return 0;
          if (a[prop] == null) return 1;
          if (b[prop] == null) return -1;
          if (a[prop] < b[prop]) return type == 'desc' ? 1 : -1;
          if (a[prop] > b[prop]) return type == 'desc' ? -1 : 1;
          return 0;
        } else {
          if (a[prop] < b[prop]) return type == 'desc' ? 1 : -1;
          if (a[prop] > b[prop]) return type == 'desc' ? -1 : 1;
          return 0;
        }
      } else {
        if (discardNull) {
          if (a === b) return 0;
          if (a == null) return 1;
          if (b == null) return -1;
          if (a < b) return type == 'desc' ? 1 : -1;
          if (a > b) return type == 'desc' ? -1 : 1;
          return 0;
        } else {
          if (a < b) return type == 'desc' ? 1 : -1;
          if (a > b) return type == 'desc' ? -1 : 1;
          return 0;
        }
      }
    }
  };

  sum(data: any, prop?: any) {
    if (data != null && Array.isArray(data) && data.length > 0) {
      let sum = 0;
      data.filter(elem => prop != null ? elem?.[prop] != null : elem != null).forEach(elem => sum = sum + (prop != null ? elem[prop] : elem));
      return isNaN(sum) ? null : sum;
    }
    return null;
    // return data.reduce(function (prev, cur) {
    //     return (prev != null && cur != null) ? prev + cur : 0;
    // }, 0);
  }

  std(data: any, prop?: any) {
    if (data != null && Array.isArray(data) && data.length > 0) {
      let avg = this.average(data, prop);
      let sum = 0;
      data.filter(elem => prop != null ? elem?.[prop] != null : elem != null).forEach(elem => {
        let val = prop != null ? elem[prop] : elem;
        sum = sum + ((avg - val) * (avg - val));
      });

      sum = isNaN(sum) ? null : sum;
      return sum != null ? Math.sqrt(sum / data?.length) : null;
    }
    return null;
  }

  average(data: any, prop?: any) {
    let sum: any = this.sum(data, (prop != null ? prop : null));
    let avg = sum != null ? sum / data.filter(elem => prop != null ? elem?.[prop] != null : elem != null).length : null;
    return isNaN(avg) ? null : avg;
  }

  groupBy(xs: any, key: any) {
    return xs.reduce((rv: any, x: any) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  groupByAsArray(data: any) {
    return Object.entries(data)?.reduce((acc, [k, v]: any) => {
      let obj: any = { id: k };
      v.forEach(vv => {
        (Object.entries(vv) as any).forEach(([key, val]) => {
          try { obj[key].push(val) }
          catch (error) { obj[key] = [val] }
        })
      })
      acc.push(obj);
      return acc;
    }, []);
  }


  parseGaugeValue(value: any, decimals: number, multiplier: number, showSign?: boolean) {
    try {
      // console.log(value, decimals, multiplier)
      if (value != null) {
        if (typeof value == 'string') return value;
        if (multiplier) {
          value = parseFloat(value) * multiplier;
        }

        let parseThousandsAndDecimals = true;

        try {
          parseThousandsAndDecimals = this.appConfigService.getAppConfig.parseThousandsAndDecimals != null ? this.appConfigService.getAppConfig.parseThousandsAndDecimals : true;
        } catch (error) {
          console.log(error);
        }

        value = isNaN(value) ? null : (this.translate.currentLang != 'zh_cn' && parseThousandsAndDecimals) ? parseFloat(value).toLocaleString(this.translate.currentLang, {
          minimumFractionDigits: decimals,
          maximumFractionDigits: decimals,
        }) : (value != null ? parseFloat(value).toFixed(decimals) : null);
      } else {
        value = null;
      }
      return value != null ? (showSign ? ((value >= 0 ? '+' : '') + value + '%') : value) : '';
    } catch (error) {
      console.log(error);
    }
  }

  parseMoment(time: any, format?: any, timezone?: any) {
    if (time != null) {
      if (format == 'default') {
        if (timezone != null) {
          return moment.tz(time, timezone).format("MMM DD, YYYY - HH:mm:ss");
        } else {
          return moment(time).format("MMM DD, YYYY - HH:mm:ss");
        }
      } else if (format != null) {
        if (timezone != null) {
          return moment.tz(time, timezone).format(format);
        } else {
          return moment(time).format(format);
        }
      } else {
        if (timezone != null) {
          return moment.tz(time, timezone).format();
        } else {
          return moment(time).format();
        }
      }
    } else {
      return null;
    }
  }

  parseTime(time: any, formatIn: any, formatOut: any) {
    try {
      formatIn = formatIn.toLowerCase();
    } catch (error) {
      console.log("Format Input is not correct, should be string");
    }
    try {
      formatOut = formatOut.toLowerCase();
    } catch (error) {
      console.log("Format Output is not correct, should be string");
    }
    if (time == null) return formatOut === 'hh:mm:ss' ? '--:--:--' : '--:--';
    if (time != null) {
      if (formatIn === formatOut) {
        return time;
      }

      if (formatIn === 'ms') time = time / 1000;

      // time >= 0
      if (time >= 0) {
        // formatOut = hh:mm
        if (formatOut === 'hh:mm') {
          let hrs = Math.floor(time / 3600);
          let min = Math.floor((time - (hrs * 3600)) / 60);
          return (hrs < 10 ? "0" + hrs : hrs) + ":" + (min < 10 ? "0" + min : min);
        }
        // formatOut = durobj
        else if (formatOut === 'durobj') {
          let hrs = Math.floor(time / 3600);
          let min = Math.floor((time - (hrs * 3600)) / 60);
          let sec = time - (hrs * 3600) - (min * 60);
          sec = Math.ceil(Math.round(sec * 100) / 100);
          let durObj = {
            h: hrs,
            m: min,
            s: sec
          }
          return durObj;
        }
        // formatOut = hh:mm:ss or DEFAULT
        else {
          let hrs = Math.floor(time / 3600);
          let min = Math.floor((time - (hrs * 3600)) / 60);
          let sec = time - (hrs * 3600) - (min * 60);
          sec = Math.ceil(Math.round(sec * 100) / 100);
          if (sec == 60) {
            sec = 0;
            min += 1;
          }
          return (hrs < 10 ? "0" + hrs : hrs) + ":" + (min < 10 ? "0" + min : min) + ":" + (sec < 10 ? "0" + sec : sec);
        }
      }
      // time < 0
      else {
        // formatOut = hh:mm:ss
        if (formatOut === 'hh:mm:ss') {
          let hrs = Math.ceil(time / 3600);
          let min = Math.ceil((time - (hrs * 3600)) / 60);
          let sec = time - (hrs * 3600) - (min * 60);
          sec = Math.ceil(Math.round(sec * 100) / 100);
          return (hrs > -10 ? "-0" + -hrs : hrs) + ":" + (min > -10 ? "0" + -min : -min) + ":" + (sec > -10 ? "0" + -sec : -sec);
        }
        // formatOut = hh:mm
        if (formatOut === 'hh:mm') {
          let hrs = Math.ceil(time / 3600);
          let min = Math.ceil((time - (hrs * 3600)) / 60);
          return (hrs > -10 ? "-0" + -hrs : hrs) + ":" + (min > -10 ? "0" + -min : -min);
        }
      }
    }
    return null;
  }

  parseTime24H(_this: any, duration: any, formatIn: any, formatOut: any) {
    var hrs = null;
    var min = null;
    var sec = null;
    if (duration < 0) {
      return '00:00';
    }
    if (formatIn === formatOut) {
      return duration;
    } else if (formatIn === 's' && formatOut === 'HH:mm:ss') {
      if (duration >= 24 * 3600) return _this.parseTime24H(_this, duration - 24 * 3600, formatIn, formatOut);
      hrs = Math.floor(duration / 3600);
      min = Math.floor((duration - (hrs * 3600)) / 60);
      sec = duration - (hrs * 3600) - (min * 60);
      sec = Math.round(Math.round(sec * 100) / 100);
      if (duration == null) return '--:--:--';
      return (hrs < 10 ? "0" + hrs : hrs) + ":" + (min < 10 ? "0" + min : min) + ":" + (sec < 10 ? "0" + sec : sec);
    } else if (formatIn === 's' && formatOut === 'HH:mm') {
      if (duration >= 24 * 3600) return _this.parseTime24H(_this, duration - 24 * 3600, formatIn, formatOut);
      hrs = Math.floor(duration / 3600);
      min = Math.floor((duration - (hrs * 3600)) / 60);
      if (duration == null) return '--:--';
      return (hrs < 10 ? "0" + hrs : hrs) + ":" + (min < 10 ? "0" + min : min);
    }
    return null;
  };

  parseSecondsFromAggrUnit(timeAggrUnit: any, value?: any) {
    switch (timeAggrUnit) {
      case 'hour':
        return {
          value: value != null ? value / 60 : null,
          mult: 1,
          unit: 'min'
        };
      case 'day':
        return {
          value: value != null ? value / 3600 : null,
          mult: 24,
          unit: 'hr'
        };
      case 'week':
      default:
        return {
          value: value != null ? value / 3600 : null,
          mult: 24 * 7,
          unit: 'hr'
        };
      case 'month':
        return {
          value: value != null ? value / (3600 * 24) : null,
          mult: 24 * 30,
          unit: 'd'
        };
    }
  }

  parseTableDate(timestamp: any, aggr: any) {
    switch (aggr) {
      case 'day':
      case 'week':
        return moment(timestamp).format('DD MMM YYYY');
      case 'month':
        return moment(timestamp).format('MMM YYYY');
      case 'year':
        return moment(timestamp).format('YYYY');
      default:
        return moment(timestamp).format('DD MMM YYYY HH:mm:ss');
    }
  };

  parseDuration(duration: any, formatIn: any, formatOut: any, start?: any, end?: any) {
    if (duration == null) {
      if (end) duration = moment(end).diff(moment(start));
      else duration = moment().diff(moment(start));
      formatIn = 'ms';
    }

    if (formatOut.endsWith('(trimmed)')) {
      let newFormatOut = formatOut.split('(')[0].toLowerCase()
      let durObj = this.parseTime(duration, formatIn, 'durobj');
      if (newFormatOut == 'hh:mm') delete durObj.s
      let durArray = []
      Object.keys(durObj).forEach((key: any) => {
        if (durObj[key] > 0) durArray.push(durObj[key] + key)
      });
      return durArray.length == 0 ? '0' : durArray.length == 1 ? durArray[0] : durArray.join(' ')
    }

    return this.parseTime(duration, formatIn, formatOut);
  }

  filterDate = function (date, toCompare, typeCompare) {
    let diff = null;
    try {
      diff = moment.parseZone(date).diff(toCompare);
    } catch (error) {
      return false;
    }
    if (diff != null) {
      typeCompare = typeCompare.toLowerCase();
      if (typeCompare === 'before' && diff < 0) return true;
      else if (typeCompare === 'after' && diff > 0) return true;
      else if (typeCompare === 'equal' && diff == 0) return true;
      else if (typeCompare === 'equal-before' && diff <= 0) return true;
      else if (typeCompare === 'equal-after' && diff >= 0) return true;
      else return false;
    } else {
      return false;
    }
  }

  getMin(array: any, variable: any, index: any) {
    try {
      if (array.length > 0) {
        return array.reduce((min: any, p: any) => p[variable][index] < min ? p[variable][index] : min, array[0][variable][index]);
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  getMax(array: any, variable: any, index: any) {
    try {
      if (array.length > 0) {
        return array.reduce((max: any, p: any) => p[variable][index] > max ? p[variable][index] : max, array[0][variable][index]);
      } else {
        return null;
      }
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  getMaxFromArray(data, prop?) {
    return (data ?? [])?.reduce((acc, val) => {
      let valToCheck = prop != null ? val?.[prop] : val;
      if (valToCheck > acc) acc = valToCheck;
      return acc;
    }, -Infinity);
  }

  onlyUnique(value: any, index: any, self: any) { return self.indexOf(value) === index };

  parseObjFromConfig(data: any, info: any, isVariableCalculated?: any, typeKey: any = 'type', addUnit: any = false, removeUnitBrackets: any = false, machineProfile?) {

    let value: any = "-";

    if (info?.productionUnit != null && machineProfile != null) info.unit = this.getProductionUnit(info?.productionUnit, machineProfile?.productionConfig);

    let transformedUnit = this.convertUnit(info.unit ?? info.suffix).unit;

    let translatedUnit = this.translate.instant((transformedUnit != null && transformedUnit != '') ? transformedUnit : '-');

    if (transformedUnit == null && removeUnitBrackets) translatedUnit = '';

    let unit = addUnit ? ((removeUnitBrackets ? ' ' : ' [') + translatedUnit + (removeUnitBrackets ? '' : ']')) : '';

    let variable: any = isVariableCalculated ? data : data[info.variable];

    if (info?.[typeKey] == 'interval') value = (data?.timeStartP ?? this.parseMoment(data?.timeStart, 'default') ?? '') + ' - ' + (data?.timeEndP ?? this.parseMoment(data?.timeEnd, 'default') ?? '');

    if (variable != null) {

      let configType: any = info?.[typeKey];

      // TYPE STRING
      if (typeof variable == 'string') {

        // Date
        if (configType == 'date') value = this.parseMoment(variable, info?.format ?? 'default');

        // Translate
        else if (configType == 'translate') value = this.translate.instant(variable);

        // Default (replace "<br>" with ",")
        else value = variable.split("<br>").join(", ");
      }

      // TYPE BOOL
      else if (typeof variable == 'boolean') {
        value = variable ? 1 : 0;
      }

      // TYPE NOT STRING (SUPPOSEDLY VALUE)
      else {

        // Time format
        if (configType == 'time') {

          if (info.hasOwnProperty('multiplier') && variable != null) variable = variable * info.multiplier;
          value = this.parseTime(variable, 's', info.suffix ?? info?.format ?? 'HH:mm:ss') + unit;

        }

        // Progress bar
        else if (configType == 'progressBar') value = this.parseGaugeValue(variable, 0, 100) + unit;

        // Size in bytes
        else if (configType == 'sizeInBytes') value = this.parseFileSize(variable);

        // Default
        else value = this.parseGaugeValue(this.convertUnit(info.unit ?? info.suffix, variable).value, info.decimals, info.multiplier) + unit;

      }
    }

    return value;
  }

  convertUnit(unit: any, value?: any, revert: any = false) {

    try {
      // Unit System ID = USID
      let USID: any = null;
      // let USID = 'imperial';

      // Conversions config
      let convConfig: any = null;

      // Current conversion config
      let currentConfig = null;

      try { USID = localStorage.getItem("USID") } catch (error) { }

      if (USID == null || USID == 'SI') return {
        unit: unit,
        value: value,
      };

      convConfig = this.appConfigService.getConvConfig;

      if (convConfig != null) {
        let SIconfigIdx = convConfig?.unitSystems.findIndex((x: any) => x.id == USID);
        if (SIconfigIdx != -1) currentConfig = convConfig.unitSystems[SIconfigIdx];

        if (currentConfig != null && convConfig?.units?.[unit]?.[currentConfig.id] != null) {
          try {

            let newConfig = convConfig.units[unit][currentConfig.id];

            let newUnit = newConfig.unit;
            let newValue = value != null ? (revert ? (value - newConfig.offset) / newConfig.multiplier : newConfig.offset + (value * newConfig.multiplier)) : null;

            return {
              unit: newUnit,
              value: newValue,
            };

          } catch (error) {
            console.log(error);
          }
        }
      }

      return {
        unit: unit,
        value: value,
      };

      // Fail
    } catch (error) {
      console.log(error);
      return {
        unit: unit,
        value: value
      }
    }

  }

  checkUnitParenthesis(unit) {
    try {
      let isBetweenBrackets = unit?.startsWith("[");
      let newUnit = isBetweenBrackets ? unit.substring(1, unit.length - 1) : unit;
      newUnit = this.convertUnit(newUnit).unit;
      return isBetweenBrackets ? ('[' + this.translate.instant(newUnit ?? '-') + ']') : newUnit;
    } catch (error) {
      console.log(error);
      return unit;
    }
  }

  parseNumber(value: any, locales?: any, decimals?: number, multiplier?: number) {
    // console.log('parseNumber')
    if (locales == null) locales = this.translate.currentLang
    if (multiplier == null) multiplier = 1
    if (typeof value == 'number') {
      if (decimals == null) decimals = this.decimalsCounter(value)
      if (multiplier != 1) value = value * multiplier
      let multiplierDecimals = this.decimalsCounter(multiplier)
      if (multiplierDecimals > 0 && multiplierDecimals > decimals) decimals += multiplierDecimals
      value = Math.round(value * (10 ** decimals)) / (10 ** decimals)
    }
    if (typeof value != 'string') return value
    let cleaned = ''
    let example = Intl.NumberFormat(locales).format(1.1);
    try {
      let cleanPattern = new RegExp(`[^-+0-9${example.charAt(1)}]`, 'g');
      let matched = value.match(cleanPattern);
      if (matched == null || matched.length < 3) cleaned = value.replace(cleanPattern, '')
    } catch (error) {
      return value
    }
    let normalized = cleaned.replace(example.charAt(1), '.');

    if (isNaN(parseFloat(normalized))) return value
    else {
      let normalizedNum: number = parseFloat(normalized)
      if (decimals == null) decimals = this.decimalsCounter(normalizedNum)
      if (multiplier != 1) normalizedNum = normalizedNum * multiplier
      let multiplierDecimals = this.decimalsCounter(multiplier)
      if (multiplierDecimals > 0 && multiplierDecimals > decimals) decimals += multiplierDecimals
      if (decimals > 0) normalizedNum = Math.round(normalizedNum * (10 ** decimals)) / (10 ** decimals)
      return normalizedNum
    }
  }

  decimalsCounter(value) {
    if (Math.floor(value.valueOf()) === value.valueOf()) return 0;
    let floater = value.toString().split(".")[1]
    return floater != null ? floater.length : 0
  }

  removeKeysWithCustomRule(obj: any, rule: any) {
    return Object.fromEntries(new Map(Object.entries(obj).filter((x: any) => rule(x))));
  }

  calculateRelativeUnit(relConsUnit, productionUnit, defaultValue = null) {
    if (relConsUnit == null || productionUnit == null) return defaultValue;
    return `${relConsUnit}/${productionUnit}`;
  }

  parseDisabilityCondition(referenceComponent, disabledCondition) {

    let firstCondition = referenceComponent?.[disabledCondition?.[0]];
    let secondCondition = disabledCondition?.[2];

    if (disabledCondition?.[0]?.split(".")?.length > 1) {
      firstCondition = referenceComponent;
      disabledCondition?.[0]?.split(".")?.forEach(cond => firstCondition = firstCondition?.[cond]);
    }

    switch (disabledCondition?.[1]) {
      case '==': return firstCondition == secondCondition;
      case '!=': return firstCondition != secondCondition;
    }
  }

  stringToObjParam(data: any, str: string, index?: number) {
    if (str == null || str == '') return data
    let isNeg = str.startsWith('!')
    let isTrue = str.startsWith('!!')
    if (data == null) return isNeg ? true : null
    if (isNeg) str = str.substring(1);
    if (isTrue) str = str.substring(1);
    let strArr = str.split('.')
    let dataTemp = this.clonerService.deepClone(data)
    for (let i = 0; i < strArr.length; i++) {
      let squareBrktsArr = strArr[i].split(/[[\]]/)
      let hasIndex = squareBrktsArr.length > 1
      let ix;
      if (hasIndex) ix = squareBrktsArr[1] == 'index' ? index : this.parseNumber(squareBrktsArr[1])
      if (hasIndex && ix != null && Array.isArray(dataTemp[squareBrktsArr[0]])) dataTemp = dataTemp[squareBrktsArr[0]][ix]
      else if (dataTemp != null && dataTemp.hasOwnProperty(strArr[i])) dataTemp = dataTemp[strArr[i]]
      else return isNeg ? (isTrue ? false : true) : null
    }
    if (isTrue) return dataTemp != null
    return isNeg ? dataTemp != null ? !dataTemp : true : dataTemp
  }

  objValueAssignator(object, path, value) {
    var result = object
    var arr = path.split('.');
    for (var i = 0; i < arr.length - 1; i++) {
      object = object[arr[i]] = object[arr[i]] != null ? object[arr[i]] : {};
    }
    object[arr[arr.length - 1]] = value;
    if (value == '$$$delete$$$') delete object[arr[arr.length - 1]]
    return result;
  }

  parseBool(value) {
    if (typeof value == 'boolean') return value
    if (value == null || value == '') return false
    value = this.parseNumber(value)
    return +value
  }

  isFloat(n) {
    return parseFloat(n.match(/^-?\d*(\.\d+)?$/)) > 0;
  }

  getAggrBars(data: any, timeAggrUnit: any, timeAggrVal: any, prop?: any, timestampProp: any = 'timestamps', orderByTimestamp: any = false) {

    let copyData: any = this.clonerService.deepClone(data);

    // Get days from time aggregation
    let aggrTime = this.getDaysFromAggrUnit(timeAggrUnit, timeAggrVal)

    let barProps: any = {
      offset: [],
      width: [],
    };

    barProps.timestamps = this.clonerService.deepClone(copyData.map((aggr: any) => aggr?.[timestampProp]));
    barProps.values = this.clonerService.deepClone(copyData.map((aggr: any) => aggr[prop ?? 'values']));
    barProps.width = this.clonerService.deepClone(copyData.map((aggr: any) => aggr[prop ?? 'values']));
    barProps.offset = this.clonerService.deepClone(copyData.map((aggr: any) => aggr[prop ?? 'values']));
    barProps.flatTimestamps = barProps.timestamps.flat();
    barProps.flatValues = this.clonerService.deepClone(copyData.map((aggr: any) => aggr[prop ?? 'values']).flat());

    let occurencies: any = {};
    // console.log(barProps, copyData, aggrTime);

    barProps.timestamps.forEach((aggr: any, ix: any) => {
      aggr.forEach((time: any, timeIdx: any) => {

        if (!occurencies.hasOwnProperty(time)) {
          occurencies[time] = 0;
        }
        let occur = barProps.flatTimestamps.filter((x: any, idx: any) => (x == time && barProps.flatValues[idx] != null)).length;
        let occurFract = occur == 0 ? 0 : 1 / occur;

        if (timeAggrUnit == 'shift') {
          try { aggrTime = moment(copyData[ix].shiftSpan[timeIdx][1]).diff(moment(copyData[ix].shiftSpan[timeIdx][0]), 's') / (3600 * 24) }
          catch (error) { console.log(error) }
        }

        barProps.width[ix][timeIdx] = occurFract * 1000 * 3600 * 24 * aggrTime * 0.75;
        barProps.offset[ix][timeIdx] = occurFract * (occurencies[time] != null ? occurencies[time] : 0) * 1000 * 3600 * 24 * aggrTime;
        if (barProps.values[ix][timeIdx] != null) {
          if (occurencies.hasOwnProperty(time)) occurencies[time] += 1
          else occurencies[time] = 0;
        }
      });
    });

    if (!orderByTimestamp) return barProps;

    const flattenedTimestamps = barProps?.timestamps.reduce((acc, val) => acc.concat(val), []);
    const flattenedOffsets = barProps?.offset.reduce((acc, val) => acc.concat(val), []);
    const flattenedWidths = barProps?.width.reduce((acc, val) => acc.concat(val), []);

    const combinedData = flattenedTimestamps.map((timestamp, index) => ({
      timestamp,
      offset: flattenedOffsets[index],
      width: flattenedWidths[index],
    }));

    // Sort the combined data by timestamp in ascending order
    combinedData.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

    return combinedData;
  }

  getDaysFromAggrUnit(aggrUnit: any, aggrVal?: any) {
    let val = null;
    switch (aggrUnit) {
      case 'minute':
        val = 1 / 24 / 60;
        break;
      case 'day':
      default:
        val = 1;
        break;
      case 'hour':
        val = 1 / 24;
        break;
      case 'shift':
        val = 1 / 3;
        break;
      case 'week':
        val = 7;
        break;
      case 'month':
        val = 28;
        break;
    }
    return val * (aggrVal ?? 1);
  }

  getFormatFromAggrTime(aggrUnit: any, removeSeconds?) {

    if (aggrUnit?.startsWith('aggr')) return `MMM DD, YYYY HH:mm${!removeSeconds ? ':ss' : ''}`;
    switch (aggrUnit) {
      case 'day':
      case 'week':
      default:
        return 'MMM DD, YYYY';
      case 'shift':
      case 'hour':
      case 'minute':
        return `MMM DD, YYYY HH:mm${!removeSeconds ? ':ss' : ''}`;
      case 'month':
        return 'MMM YYYY';
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // REQUEST FROM ENDPOINT URL

  getRequestFromEndpointUrl(endpointUrl: any, machineId?, query?, payload?) {

    let requestSent = null;

    try {

      if (machineId != null) endpointUrl = endpointUrl?.replaceAll("{machineId}", machineId);

      let method = 'POST';
      if (endpointUrl.includes(':')) {
        endpointUrl = endpointUrl.split(':');
        method = endpointUrl[0];
        endpointUrl = endpointUrl[1];
      }

      if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
      else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
      else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

    } catch (error) {
      console.log(error);
    }

    return requestSent;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // BUILD FILTER BUTTONS

  buildFilterButtons(_this: any, filterButtons?, list?, toSort = true, checkSelectedVariable = null, addSearchFilter = false) {

    let buttons: any[] = [];
    let listToParse: any = list ?? _this.dashboardData?.list ?? [];
    let additionalFilterButtons: any = this.clonerService.deepClone(filterButtons ?? _this?.additionalFilterButtons ?? []);

    try {

      if (additionalFilterButtons?.length > 0) {

        additionalFilterButtons?.forEach((attribute: any) => {

          attribute = _this.clonerService.deepClone(attribute);

          let listToFilter: any = []

          let useCustomVariables = (attribute?.type == 'advancedTranslate' &&
            attribute?.advancedTranslateConfig?.enableCustomVariables)

          if (useCustomVariables) {
            let keyToFilter = attribute?.advancedTranslateConfig?.aspect;
            listToFilter = _this.clonerService.deepClone(listToParse?.map((x: any) => x?.[keyToFilter])?.filter(_this.filterService.onlyUnique));
          } else {
            listToFilter = _this.clonerService.deepClone(listToParse?.map((x: any) => x?.[attribute.variable])?.filter(_this.filterService.onlyUnique));
            if (toSort) listToFilter?.sort();
          }

          if (addSearchFilter) {
            if (attribute.config == null) attribute.config = {};
            attribute.config.addSearchFilter = true;
          }

          let optKey = useCustomVariables ? 'selection' : 'options';
          attribute[optKey] = listToFilter?.map((x: any) => {

            let transl = (x != null && x != '' && typeof x == 'string') ? _this.translate.instant(x) : x;
            let selected = true;

            if (attribute?.type == 'configFromProfile' && attribute?.mappingConfig != null) {
              let v = _this.machine?.profile?.[attribute?.mappingConfig?.key ?? 'timeStates']?.find(y => y?.[attribute?.mappingConfig?.idKey ?? 'id'] == x)?.[attribute?.mappingConfig?.outputKey ?? 'label'];
              transl = attribute?.mappingConfig?.translate ? this.translate.instant(v ?? '-') : v;
            }

            if (attribute?.type == 'advancedTranslate') {

              let conf = attribute?.advancedTranslateConfig;

              if (conf.enableCustomVariables) {

                let name: any = "";
                let c_customVariables = this.cacheService.get("customVariables");

                // Cached custom translations
                if (_this.appConfig?.addCustomVariables && c_customVariables?.machineId == _this.machineId && c_customVariables?.value != null) {

                  // let aspectName = item?.[conf.aspect];
                  let aspectName = x;
                  aspectName = (aspectName != null && typeof aspectName == 'string') ? aspectName.replace(/\$\$\$/g, ' - ') : aspectName;
                  let varName = aspectName;
                  if (!conf?.excludeVariableKeyUsingVariables && conf.variable != null) {
                    varName += `.${conf.variable}`;
                  }

                  let codeConfig = _this.internalDataService.buildTranslationsFromVariablesFile(conf.key, varName, c_customVariables?.value, this.appConfigService.getDefaultCustomVariables, conf.idVariable ?? 'id');

                  // if (conf.parseObjFromConfig) name = this.filterService.parseObjFromConfig(item?.[conf.variable], { ...codeConfig }, true, 'type', true);
                  // else 
                  name = codeConfig?.[conf.outputKey ?? 'label'];

                  if (conf.translateValue && typeof name == 'object') name = name?.[this.translate.currentLang] ?? name;

                  if (conf.addUnit && codeConfig?.unit != null) name += ' [' + this.translate.instant(codeConfig?.unit ?? '-') + ']';

                  transl = name;
                  // x = transl;
                }
              }

              // console.log('Filters', listToFilter, listToParse)

              // console.log('attribute', attribute, attribute?.advancedTranslateConfig?.key, x, null, _this.machineId)
              else if (attribute?.advancedTranslationType == null) {
                try { transl = _this.internalDataService.parseLabelWithAssetId(attribute?.advancedTranslateConfig?.key, x, null, _this.machineId) }
                catch (error) { console.log(error) }
              }
              else if (attribute?.advancedTranslationType == 'alarm') {
                try { transl = _this.internalDataService.parseAlarmsLabel(x, attribute?.advancedTranslateConfig?.key ?? 'message', _this.machineId) }
                catch (error) { console.log(error) }
              }

            }

            if (attribute?.type == 'columnSelection') {
              let infoConfig = _this.clonerService.deepClone(listToParse?.find(v => v?.variable == x));
              selected = !infoConfig?.hideInTable;
              try { transl = this.translate.instant(infoConfig?.[attribute?.labelColumn ?? 'label'] ?? x ?? '-') }
              catch (error) { console.log(error) }
            }

            if (checkSelectedVariable) {
              let infoConfig = _this.clonerService.deepClone(listToParse?.find(v => v?.[attribute.variable] == x));
              selected = infoConfig?.[checkSelectedVariable];
            }

            return {
              id: x,
              label: transl,
              selected: selected
            }
          });

          if (useCustomVariables) {
            // console.log(attribute.selection)

            let labels = new Set();
            let selection: any = this.clonerService.deepClone(attribute.selection);
            attribute.options = selection.map((elem) => {
              elem = this.clonerService.deepClone(elem);
              return {
                id: elem.label,
                label: elem.label,
                selected: elem.selected,
              }
            }).filter((elem: any) => {

              if (labels.has(elem.label)) {
                return false;
              }

              labels.add(elem.label);
              return true;
            });

            if (toSort) {
              attribute.options.sort(this.sortByProperty("id", "asc", true));
            }
          }

          if (attribute.config == null) {
            attribute.config = {};
          }

          attribute.config.useCustomVariables = useCustomVariables;

          let button = new FilterButton(
            attribute.variable,
            attribute.label,
            useCustomVariables,
            attribute.options,
            attribute.selection,
            attribute.config
          );

          buttons.push(button);

          // buttons.push(attribute);

        });

      }

      return buttons;
    } catch (error) {
      console.log(error);
      return [];

    }

  }

  openImage(data) {

    let title = data?.image;

    try { title = data?.image.split("/").at(-1) } catch (error) { }

    if (data?.timestamp != null && data?.timestampP == null) data.timestampP = moment(data.timestamp).format("DD MMM YYYY HH:mm:ss.SSS");
    if (data?.timestampP) title += (' - ' + data?.timestampP);

    this.dialog.open(ZoomableImageDialogComponent, {
      panelClass: 'ff-dialog',
      minWidth: "70%",
      data: {
        title: title,
        imageUrl: data?.image
      }
    });

  }

  getSpecificPermission(allowedRole: any, keyToSearch: any = 'roles') {
    let currentUser: any = localStorage.getItem('user');
    if (currentUser != null && currentUser != "") {

      try { currentUser = JSON.parse(currentUser) }
      catch (error) { console.log(error) }

      if (currentUser?.oauth != null && currentUser?.oauth != false && (keyToSearch != 'roles' || !currentUser?.checkOnlyScopes)) {
        return ![-1, null].includes(currentUser?.[keyToSearch]?.indexOf(allowedRole));
      }
    }
    return true;
  }


  getSpecificPermissions(allowedRoles, operator, keyToSearch = 'roles') {
    let currentUser: any = localStorage.getItem('user');
    if (currentUser != null && currentUser != "") {
      try {
        currentUser = JSON.parse(currentUser);
      } catch (error) { console.log(error) }

      if (currentUser?.oauth != null && currentUser?.oauth != false && (keyToSearch != 'roles' || !currentUser?.checkOnlyScopes)) {
        switch (operator) {
          case 'or':
          default:
            return allowedRoles?.some(x => currentUser?.[keyToSearch]?.includes(x));
          case 'and':
            return allowedRoles?.every(x => currentUser?.[keyToSearch]?.includes(x));
        }
      }
    }
    return true;
  }

  filterArrayWithPermission(arr: any[], permissionKey: any = "permission") {
    return arr?.reduce((acc, val) => {
      // If attribute "[permissionKey]" is not present, keep the button
      if (val?.[permissionKey] == null) {
        acc.push(val);
        return acc;
      }

      let isAllowedUser = false;

      // Single [permissionKey] as string (es. "mat-ms-alarms")
      if (typeof val[permissionKey] == 'string') {
        try { isAllowedUser = this.getSpecificPermission(val[permissionKey]) }
        catch (error) { console.log(error) }
      }

      // Multiple [permissionKey]s as object
      // {
      //   "operator": "or"                                   - string:           "or" / "and"
      //   "roles": ["mat-ms-alarms", "mat-ms-signalations"]  - list of strings:  ["...", "---"]
      // }
      else if (typeof val[permissionKey] == 'object' && Object.keys(val[permissionKey])?.length > 0) {
        try { isAllowedUser = this.getSpecificPermissions(val[permissionKey]?.roles ?? [], val[permissionKey]?.operator) }
        catch (error) { console.log(error) }
      }

      if (isAllowedUser) {
        acc.push(val);
        return acc;
      }

      return acc;

    }, []);
  }

  weekOfMonth(date) {

    const weekInYear = date.clone().isoWeek();
    const result = weekInYear - date.clone().startOf('month').isoWeek();

    return (result < 0 ? weekInYear : result) + 1;
  }

  checkWeek(date, monthWeek) {

    // Month week as number
    if (monthWeek != 'last') return monthWeek == Math.ceil(date.date() / 7);

    // Last month week
    let nextOccurrence = this.weekOfMonth(date.clone().add(7, 'days'));
    let lastWeekOfMonth = this.weekOfMonth(date.clone().endOf("month"));

    return this.weekOfMonth(date.clone()) == lastWeekOfMonth || nextOccurrence == 1;

  }

  downloadFromBrowser(path, fileName?) {
    let link = document.createElement('a');
    link.href = path;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  getProductionUnit(prodId: any, config: any, convertUnit?: any) {

    if (config.singleUnitTranslations) config.singleUnit = config.singleUnitTranslations?.[this.translate.currentLang] ?? config.singleUnit;
    if (config.unitTranslations) config.unit = config.unitTranslations?.[this.translate.currentLang] ?? config.unit;

    let multipleUnit = convertUnit ? this.convertUnit(config.unit).unit : config.unit;
    let singleUnit = convertUnit ? this.convertUnit(config.singleUnit).unit : config.singleUnit;

    switch (prodId) {
      case 'avgProdAct':
      case 'avgProdSet':
        if (config.invert) {
          return this.translate.instant(multipleUnit ?? '-') + '/' + config.timeUnit.label;
        }
        return config.timeUnit.label + '/' + this.translate.instant(singleUnit ?? '-');
      case 'singleUnit':
        return this.translate.instant(singleUnit ?? '-');
      case 'totProdAct':
      case 'totProdSet':
      case 'totGood':
      case 'totBad':
      default:
        return this.translate.instant(multipleUnit ?? '-');
    }

  };

  getProductionLabel(prodId: any, invert: any) {
    switch (prodId) {
      case 'prodAct':
        switch (invert) {
          case true:
          default:
            return this.translate.instant("GLOBAL.PRODUCTION_RATE");
          case false:
            return this.translate.instant("GLOBAL.DURATION");
        }
        break;
      case 'avgProdAct':
        switch (invert) {
          case true:
          default:
            return this.translate.instant("GLOBAL.AVERAGE_PRODUCTION_RATE");
          case false:
            return this.translate.instant("GLOBAL.AVERAGE_DURATION");
        }
        break;
      case 'avgProdSet':
        switch (invert) {
          case true:
          default:
            return this.translate.instant("GLOBAL.IDEAL_PRODUCTION_RATE");
          case false:
            return this.translate.instant("GLOBAL.IDEAL_DURATION");
        }
        break;
      case 'totProdAct':
      default:
        return this.translate.instant("GLOBAL.TOTAL_PRODUCTION");
      case 'totProdSet':
        return this.translate.instant("GLOBAL.IDEAL_PRODUCTION");
      case 'totGood':
        return this.translate.instant("GLOBAL.TOTAL_GOOD");
      case 'totBad':
        return this.translate.instant("GLOBAL.TOTAL_BAD");
    }



  }

  parseFileSize(size: any) {
    if (size == 0) return 'File is empty';
    else if (size > 1e9) return Math.round(size * 1e-9) + ' GB';
    if (size > 1e6) return Math.round(size * 1e-6) + ' MB';
    else if (size > 1000) return Math.round(size / 1000) + ' kB';
    else return size + ' B';
  }


  filterAggregationsByMachine(_this, machine) {

    if (_this.aggregationDropdown?.list?.length > 0) {
      if (machine == null) {
        _this.aggregationDropdown.listFiltered = this.clonerService.deepClone(_this.aggregationDropdown?.list);
        return;
      }
      _this.aggregationDropdown.listFiltered = _this.aggregationDropdown?.list?.filter(aggr => !aggr.hideWithMachines || !aggr.hideWithMachines?.includes(machine));

      if (_this.aggregationDropdown.listFiltered?.length == 0 && _this.aggregationDropdown.selected != '---') _this.aggregationDropdown.selected = '---';
    }

    if (machine == null) return;

    _this.aggregations = _this.aggregationsUnparsed?.filter(aggr => !aggr.hideWithMachines || !aggr.hideWithMachines?.includes(machine));
    _this.aggregationsToShowInDropdown = _this.aggregationsToShowInDropdownUnparsed?.filter(aggr => !aggr.hideWithMachines || !aggr.hideWithMachines?.includes(machine));

    if (_this.aggregationsToShowInDropdown?.length == 0 && _this.aggrDropdown != null) _this.aggrDropdown = null;

  }

  addStandardInformations(data?: any) {
    let obj: any = {
      user: this.cacheService.get("user")?.user
    };

    let assets = this.cacheService.get("assets") ?? [];
    let machineId = this.cacheService.get("machineId");

    if (machineId != null && assets?.length > 0) {
      let currentAsset = assets?.find(x => x.machineId == machineId);
      if (currentAsset != null) {
        obj.machineName = currentAsset?.machineName;
        obj.tz = currentAsset?.timezone;
        obj.orderNumber = currentAsset?.orderNumber;
      }
    }

    if (data != null) {
      obj = { ...obj, ...data };
    }

    return obj;

  }

  base64ToBlob(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
      bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: 'application/pdf' });
  };

  arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  arrayBufferToBlob(buffer) {

    let base64 = this.arrayBufferToBase64(buffer);
    return this.base64ToBlob(base64);
  };

  calculateNumberOfComments(data, listKey: any = "elements", nameKey: any = "numberOfAnnotations") {

    let parsedValue = data?.[listKey]?.reduce((acc, file) => {

      // File element 
      if (!file?.elements?.length) {
        if (file?.[nameKey] > 0) acc += file?.[nameKey];
      }

      // Folder element 
      else acc += this.calculateNumberOfComments(file, listKey, nameKey);

      return acc;
    }, 0);

    return parsedValue

  }

  calculateNumberOfNestedRows(data, listKey: any = "elements", searchComments: any = false) {

    let parsedValue = data?.[listKey]?.reduce((acc, file) => {

      if (file?.name == 'empty.html') return acc;

      // File element 
      if (!file?.elements?.length) {
        if (searchComments) {
          if (file?.numberOfAnnotations) acc += 1
        }
        else acc += 1
      }

      // Folder element 
      else acc += this.calculateNumberOfNestedRows(file, listKey, searchComments);

      return acc;
    }, 0);

    return parsedValue

  }

  getCommentedFilesList(data, listKey: any = "elements", searchComments: any = false) {

    let parsedValue = data?.[listKey]?.reduce((acc, file) => {

      if (file?.name == 'empty.html') return acc;

      // File element 
      if (!file?.elements?.length) {
        if (searchComments) {
          if (file?.numberOfAnnotations) acc.push(file?.name)
        }
        else acc.push(file?.name)
      }

      // Folder element 
      else acc = acc.concat(this.getCommentedFilesList(file, listKey, searchComments));

      return acc;
    }, []);

    return parsedValue

  }

  // GANTT FOR STATE TIMELINE AND PROD TRACE DETAIL
  parseGantt(_this: any, data: any, customHeight?: any) {

    let appConfig = this.appConfigService.getAppConfig;
    let appInfo = this.appConfigService.getAppInfo;

    let height = 400;
    if (customHeight != null) height = customHeight;
    else {
      try { height = window.innerHeight - 300 }
      catch (error) { console.log(error) }
    }

    let gantt: any = {
      chart: {
        background: appInfo?.darkTheme ? "#000000" : "#FFFFFF",
        foreColor: appConfig?.plotlyDefaultColors?.font ?? '#373d3f',
        height: height,
        type: 'rangeBar',
        animations: {
          enabled: false
        },
        zoom: {
          enabled: true
        },
        events: {
          zoomed: function (config: any, axes: any) {
            try {
              if (_this.pollingEvents != null) {
                if (axes.xaxis.min != null && axes.xaxis.max != null) {
                  try { _this.pollingEvents.unsubscribe() } catch (error) { }
                  // do nothing
                } else {
                  if (!_this.interval?.enabledPolling) {
                    _this.getStatesTimeline(_this, 0);
                  } else {
                    _this.pageState.next(5);
                    _this.getStateTimelinePolling(_this);
                  }
                }
              }
            } catch (error) {
              console.log(error);
            }
          },
          // click: function (a: any, b: any, c: any) {
          //   console.log(a, b, c);
          // }
        },
        toolbar: {
          show: true,
          tools: {
            download: false,
            selection: false,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: true,
            customIcons: []
          },
          // export: {
          //   csv: {
          //     filename: undefined,
          //     columnDelimiter: ';',
          //     headerCategory: 'category',
          //     headerValue: 'value',
          //     dateFormatter(timestamp:any) {
          //       console.log(timestamp);

          //       return new Date(timestamp).toDateString()
          //     }
          //   },
          //   svg: {
          //     filename: undefined,
          //   },
          //   png: {
          //     filename: undefined,
          //   }
          // },
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
          dataLabels: {
            hideOverflowingLabels: false
          }
        }
      },
      dataLabels: {
        enabled: false,
        style: {
          fontSize: '14px',
        },
        formatter: function (val: any, opts: any) {
          // console.log (val, opts);
          var label = opts.w.globals.labels[opts.dataPointIndex];
          var a = moment(val[0]);
          var b = moment(val[1]);
          var diff = b.diff(a, 'seconds');
          var diffHM = _this.filterService.parseTime(b.diff(a, 'seconds'), 's', 'HH:mm');
          if (diff > 0) return diffHM;
          // return label;
        },
        // style: {
        //   colors: ['#f3f4f5', '#fff']
        // }
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
          show: true,
        }
      },
      tooltip: {
        enabled: true,
        custom: function (totalSeries: any) {

          let series = totalSeries.series;
          let seriesIndex = totalSeries.seriesIndex;
          let dataPointIndex = totalSeries.dataPointIndex;
          let w = totalSeries.w;

          let traceConfig = w.config.series[seriesIndex].data[dataPointIndex];

          if (traceConfig.hideTooltip) return '';

          let startText = traceConfig.y != null ?
            '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.START") + ': </strong>' + moment(traceConfig.y[0]).format('MMM DD, YYYY HH:mm:ss') + '</p>' : '';

          let endText = traceConfig.y != null ?
            '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.END") + ': </strong>' + moment(traceConfig.y[1]).format('MMM DD, YYYY HH:mm:ss') + '</p>' : '';

          let durationText = traceConfig.duration != null ?
            '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.DURATION") + ': </strong>' + traceConfig.duration + '</p>' : '';

          let stateText = traceConfig.label != null ?
            '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.STATE") + ': </strong>' + traceConfig.label + '</p>' : '';

          let modeText = traceConfig.mode != null ?
            '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.MODE") + ': </strong>' + traceConfig.mode + '</p>' : '';

          let codeText = traceConfig.id != null ?
            '<p> <strong>' + _this.translate.instant("MACHINE_STATUS_LOG.CODE") + ': </strong>' + traceConfig.id + '</p>' : '';

          let alarmText = traceConfig.alarmText != null ?
            '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.ALARM") + ': </strong>' + _this.internalDataService.parseAlarmsLabel(traceConfig.alarmText, 'code', _this.machineId) + '</p>' : '';

          let alarmTextTransl = traceConfig.alarmText != null ?
            '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.ALARM_MESSAGE") + ': </strong>' + _this.internalDataService.parseAlarmsLabel(traceConfig.alarmText, 'message', _this.machineId) + '</p>' : '';

          let alarmCodes = '';
          if (traceConfig.ids != null && traceConfig.ids.length > 0) {
            // let maxAlarmsPerColumn = 5;
            // let alarmsUlColumnsNum = 1;

            // try {
            //   alarmsUlColumnsNum = Math.ceil(config.ids.length / maxAlarmsPerColumn);
            // } catch (error) { }

            let idsLabel = _this.translate.instant(traceConfig?.idsLabel ?? "STATE_TIMELINE.ALARMS");
            let codeLabel = _this.translate.instant(traceConfig?.codeLabel ?? "MACHINE_STATUS_LOG.CODE");
            let messageLabel = _this.translate.instant(traceConfig?.messageLabel ?? "STATE_TIMELINE.ALARM_MESSAGE");

            alarmCodes +=
              '<p><strong>' + idsLabel + `: </strong></p><ul style="margin:0;">`;
            // '<p><strong>' + _this.translate.instant("STATE_TIMELINE.ALARMS") + `: </strong></p><ul style="margin:0;columns:${alarmsUlColumnsNum};column-span:2">`;
            traceConfig.ids.forEach((alarm: any, idx: any) => {
              if (idx < 4) {
                alarmCodes += '<li>';
                if (alarm.code != null) alarmCodes += '<p><strong>' + codeLabel + ':</strong> ' + alarm.code + '</p>'
                if (alarm.message != null) alarmCodes += '<p><strong>' + messageLabel + ':</strong> ' + alarm.message + '</p>'
                if (alarm.category != null) alarmCodes += '<p><strong>' + _this.translate.instant("MACHINE_STATUS_LOG.CATEGORY") + ':</strong> ' + alarm.category + '</p>'
                if (alarm.start != null) alarmCodes += '<p><strong>' + _this.translate.instant("STATE_TIMELINE.START") + ':</strong> ' + moment(alarm.start).format('MMM DD, YYYY HH:mm:ss') + '</p>'
                if (alarm.end != null) alarmCodes += '<p><strong>' + _this.translate.instant("STATE_TIMELINE.END") + ':</strong> ' + moment(alarm.end).format('MMM DD, YYYY HH:mm:ss') + '</p>'
                alarmCodes += '</li>';
              }
            });
            if (traceConfig.ids?.length > 4) alarmCodes += '<li>' + _this.translate.instant("GLOBAL.OTHER_ALARMS", { n: traceConfig.ids?.length - 4 }) + '</li>';
            alarmCodes += '</ul>';
          }

          let detectedStates = '';
          if (traceConfig.states != null && traceConfig.states.length > 0) {
            detectedStates +=
              '<p><strong>Alarm Codes: </strong></p><ul style="margin:0;">';
            traceConfig.states.forEach((alarm: any) => {
              detectedStates += '<li>' + alarm.message + '</li>';
            });
            detectedStates += '</ul>';
            // phaseText = '';
          }

          // var detectedStates = '';
          // if (config.states != null && config.states.length > 0) {
          //   detectedStates +=
          //     '<p> <strong>Start: </strong>' + moment(config.y[0]).utc(0).format('MMM DD, YYYY HH:mm:ss') + '</p>' +
          //     '<p> <strong>End: </strong>' + moment(config.y[1]).utc(0).format('MMM DD, YYYY HH:mm:ss') + '</p>' +
          //     '<p><strong>Alarm Codes: </strong></p><ul style="margin:0;">';
          //   config.states.forEach(alarm => {
          //     detectedStates += '<li>' + alarm.message + '</li>';
          //   });
          //   detectedStates += '</ul>';
          //   phaseText = '';
          // }

          // if (config.alertDescription != null) {
          //   alarmCodes += '<p><strong>Alert description: </strong>' + config.alertDescription + '</p>';
          // }
          return '<div class="arrow_box" style="background-color: ' + traceConfig.fillColor + '80; font-size: 0.8rem;">' +
            startText +
            endText +
            codeText +
            // machineText +
            durationText +
            stateText +
            modeText +
            alarmText +
            alarmTextTransl +
            alarmCodes +
            detectedStates +
            '</div>'
        },
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            fontSize: '12px'
          }
        }
      },
      grid: {
        row: {
          colors: appInfo?.darkTheme ? ['#182529', '#181b1e'] : ['#f3f4f5', '#fff'],
          opacity: 1
        }
      },
    }

    let series: any = [];
    let seriesExpanded: any = [];

    // Check if at least one machine is present
    if (!Object.keys(data).length) return gantt;

    for (const machine of Object.keys(data)) {

      if (data[machine].states != null && data[machine].states.length > 0) {
        data[machine].states.forEach((state: any) => {

          let duration = state.end != null ? _this.filterService.parseTime(moment(state.end).diff(moment(state.start), 's'), 's', 'HH:mm:ss') :
            _this.filterService.parseTime(moment().diff(moment(state.start), 's'), 's', 'HH:mm:ss');

          let stateConfig = null;
          let color = null;
          let label = null;
          let mode = null;

          let category = -1;
          let catLabel = "unknown";
          let catColor = "#bfbfbf";

          try {
            stateConfig = _this.machine.profile.timeStates.find((x: any) => x.state == state.id);
            if (stateConfig != null) {
              category = stateConfig.category;
              color = stateConfig.color;
              mode = stateConfig.mode != null ? _this.translate.instant(stateConfig.mode) : null;
              label = stateConfig.value != null ? this.translate.instant(stateConfig.value) : null;

              try { color = color.startsWith("#") && color.endsWith("80") ? color.substring(0, color.length - 2) : color }
              catch (error) { console.log(error) }
            }

          } catch (error) {
            console.log(error);
          }

          try {
            if (category != null) {
              let catConfig = _this.machine.profile.categories.find((x: any) => x.id == category);
              if (catConfig != null) {
                catLabel = catConfig.label != null ? _this.translate.instant(catConfig.label ?? '-') : null;
                catColor = catConfig.color;
              }
            }
          } catch (error) {
            console.log(error);
          }

          series.push({
            x: machine != "null" ? _this.internalDataService.parseLabelWithAssetId("MACHINES", machine, null, _this.machineId) : _this.translate.instant('GLOBAL.LINE'),
            y: [
              moment(state.start).utc(false).valueOf() - 0,
              (state.end != null) ? moment(state.end).utc(false).valueOf() : moment().utc(false).valueOf(),
            ],
            alarmText: state.alarm,
            id: state.id,
            duration: duration,
            label: _this.useStatesInsteadOfCategoriesInStateTimeline ? label : catLabel,
            fillColor: _this.useStatesInsteadOfCategoriesInStateTimeline ? color : catColor,
            mode: mode,
          });

        });
      }
      else {
        if (_this.interval != null) {
          series.push({
            x: machine != "null" ? _this.internalDataService.parseLabelWithAssetId("MACHINES", machine, null, _this.machineId) : _this.translate.instant('GLOBAL.LINE'),
            y: [new Date(_this.interval.start).getTime(), new Date(_this.interval.end).getTime()],
            hideTooltip: true,
            fillColor: '#249efb00'
          });
        }
      }

      if (Object.keys(data).length == 1) {

        data[machine].states?.forEach((state: any) => {

          let duration = state.end != null ? _this.filterService.parseTime(moment(state.end).diff(moment(state.start), 's'), 's', 'HH:mm:ss') :
            _this.filterService.parseTime(moment().diff(moment(state.start), 's'), 's', 'HH:mm:ss');

          let stateConfig = null;
          let color = null;
          let label = null;
          let mode = null;

          try {
            stateConfig = _this.machine.profile.timeStates.find((x: any) => x.state == state.id);
            if (stateConfig != null) {
              color = stateConfig.color;
              mode = stateConfig.mode != null ? _this.translate.instant(stateConfig.mode) : null;
              label = stateConfig.value != null ? _this.translate.instant(stateConfig.value) : null;

              try { color = color.startsWith("#") && color.endsWith("80") ? color.substring(0, color.length - 2) : color }
              catch (error) { console.log(error) }
            }

          } catch (error) {
            console.log(error);
          }

          seriesExpanded.push({
            x: label,
            y: [
              moment(state.start).utc(false).valueOf() - 0,
              (state.end != null) ? moment(state.end).utc(false).valueOf() : moment().utc(false).valueOf(),
            ],
            alarmText: state.alarm,
            id: state.id,
            duration: duration,
            label: label,
            fillColor: color,
            mode: mode,
          });

        });

        seriesExpanded.sort(_this.filterService.sortByProperty("id", "asc", true));

        // Alarms
        data[machine].alarms?.forEach((alarm: any) => {

          if (alarm.start == null) return;

          let alarmSerie: any = {
            x: _this.translate.instant("ALARMS.TITLE"),
            y: [
              new Date(alarm.start).getTime(),
              new Date(alarm.end).getTime()
            ],
            phaseEnd: alarm.end != null ? moment(alarm.end).format("DD MMM YYYY HH:mm:ss") : '-',
            ids: alarm.codes != null ? alarm.codes.map((x: any) => {
              return Object.assign(x, {
                code: _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'code', _this.machineId),
                message: _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'message', _this.machineId),
                start: x.start,
                end: x.end
              });
            }) : null,
            fillColor: _this.machine.profile?.alarmCategories?.length > 0 ? alarm.codes.reduce((acc, alarm) => {
              let alarmCategory = _this.machine.profile?.alarmCategories?.find(x => x.id == alarm.category);
              if (alarmCategory != null) {
                if (alarmCategory?.priority < acc.priority) {
                  acc = {
                    color: alarmCategory.color,
                    priority: alarmCategory.priority,
                  }
                }
              }
              return acc;
            }, { color: "#F44336", priority: Infinity }).color : '#F44336',
          };

          series.push(alarmSerie);
          seriesExpanded.push(alarmSerie);

        });

        // Warnings
        data[machine].signalations?.forEach((alarm: any) => {

          if (alarm.start == null) return;

          let warningSerie: any = {
            x: _this.translate.instant("SIGNALATIONS.TITLE"),
            y: [
              new Date(alarm.start).getTime(),
              new Date(alarm.end).getTime()
            ],
            phaseEnd: alarm.end != null ? moment(alarm.end).format("DD MMM YYYY HH:mm:ss") : '-',
            ids: alarm.codes != null ? alarm.codes.map((x: any) => {
              return {
                code: _this.internalDataService.parseWarningsLabel(x.alarmCode, 'code', _this.machineId),
                message: _this.internalDataService.parseWarningsLabel(x.alarmCode, 'message', _this.machineId),
                start: x.start,
                end: x.end
              }
            }) : null,
            fillColor: "#FCA10D",
          };

          series.push(warningSerie);
          seriesExpanded.push(warningSerie);

        });

        // NEW detected states
        data[machine].detectedStates?.forEach((cause: any) => {

          if (cause.start == null) return;

          let causeCodes: any = this.removeKeysWithCustomRule(this.clonerService.deepClone(cause), (x => x?.[0]?.startsWith("cause_")));

          causeCodes = (Object.entries(causeCodes) ?? [])?.reduce((acc, [key, val]) => {
            if (val == 1) acc.push({
              code: _this.internalDataService.parseCausesLabel(key, null, _this.machineId),
              start: cause.start,
              end: cause.end
            })

            return acc;
          }, []);

          // console.log({ causeCodes });

          let causeSerie: any = {
            x: _this.translate.instant("STATE_TIMELINE.DETECTED_CAUSES"),
            y: [
              new Date(cause.start).getTime(),
              new Date(cause.end).getTime()
            ],
            phaseEnd: cause.end != null ? moment(cause.end).format("DD MMM YYYY HH:mm:ss") : '-',
            idsLabel: "STATE_TIMELINE.CAUSES",
            codeLabel: "STATE_TIMELINE.CAUSE",
            ids: causeCodes,
            // ids: cause.codes?.map((x: any) => {
            //   let conf = _this.machine.profile.causes?.find(y => y.id == x.causeId);
            //   return {
            //     code: _this.translate.instant(conf?.label ?? '-'),
            //     start: x.start,
            //     end: x.end
            //   }
            // }),
            fillColor: "#2196f3",
          }

          series.push(causeSerie);
          seriesExpanded.push(causeSerie);

        });

        // OLD Detected states
        // data[machine].detectedStates?.forEach((detectedState: any) => {

        //   if (detectedState.start == null) return;

        //   let detStateSerie: any = {
        //     x: _this.translate.instant("GLOBAL.DETECTED_STATES"),
        //     y: [
        //       new Date(_this.filterService.parseMoment(detectedState.start, "MMM DD, YYYY HH:mm:ss", _this.machine.timezone)).getTime(),
        //       new Date(_this.filterService.parseMoment(detectedState.end, "MMM DD, YYYY HH:mm:ss", _this.machine.timezone)).getTime(),
        //     ],
        //     states: detectedState.state == null ? [] : [...detectedState.state].map((x, idx) => {
        //       let conf = _this.machine.profile.detectedStates?.find((y: any) => y.state == idx);
        //       if (conf != null) {
        //         return {
        //           message: _this.translate.instant(conf?.value ?? '-'),
        //           code: idx,
        //           flag: x,
        //         }
        //       } else {
        //         return {
        //           code: 'Undefined',
        //           message: 'undefined',
        //           flag: x,
        //         };
        //       }
        //     }).filter(x => x.flag != 0),
        //     fillColor: "#2196f3",
        //   };

        //   series.push(detStateSerie);
        //   seriesExpanded.push(detStateSerie);

        // });
      }
    }

    // console.log({ series });
    // console.log({ seriesExpanded });

    if (series.length == 0 || series.every((x: any) => x.hideTooltip)) {
      _this.ganttState = 2;
      return {};
    } else {
      _this.ganttState = 1;
      gantt.series = [{ data: series }];
      gantt.seriesExpanded = [{ data: seriesExpanded }];

      // Limit to real data
      gantt.xaxis.min = new Date(_this.filterService.getMin(gantt.series[0].data, 'y', 0)).getTime();
      gantt.xaxis.max = new Date(_this.filterService.getMax(gantt.series[0].data, 'y', 1)).getTime();

      // Limit to interval
      // gantt.xaxis.min = new Date(_this.interval.start).getTime();
      // gantt.xaxis.max = new Date(_this.interval.end).getTime();
      return gantt;
    }

  }


  checkShowCondition(info, row, keyName = "showCondition") {

    if (info?.[keyName] == null) return true;

    // console.log({ info }, { row });

    let condition = info[keyName];
    let typeCheck = info.conditionOperatorCheck ?? 'some';

    let boolCond = true;

    try {

      if (typeCheck == 'every') {
        if (condition?.every(cond => Array.isArray(cond) && cond?.length == 3)) {
          boolCond = condition.every(c => this.parseDisabilityCondition(row, c));
        }
        else if (condition?.length == 3) boolCond = this.parseDisabilityCondition(row, condition);
      }

      else if (typeCheck == 'some') {
        if (condition?.some(cond => Array.isArray(cond) && cond?.length == 3)) {
          boolCond = condition.some(c => this.parseDisabilityCondition(row, c));
        }
        else if (condition?.length == 3) boolCond = this.parseDisabilityCondition(row, condition);
      }

    } catch (error) { console.log(error) }

    // If the condition(s) is(are) not satisfied, delete the value associated with the variable
    if (!boolCond) row[info.variable] = null;

    return boolCond;

  }

  createCompleteDashboardConfig(_this: any) {

    _this.completeDashboardConfig = {
      dashboardData: _this.clonerService.deepClone(_this.dashboardData),
      machineProfile: _this.machine.profile,
      dashboardConfig: _this.dashboardConfig,
      aggregations: _this.aggregations,
    };

    if (_this.aggregatedTable != null) _this.completeDashboardConfig.aggregatedTable = _this.aggregatedTable;

    // let keysToExclude: any = Object?.keys(_this.completeDashboardConfig).concat("completeDashboardConfig").concat([
    let keysToExclude: any = Object?.keys({}).concat("completeDashboardConfig").concat([
      'route',
      'router',
      'translate',
      'dialog',
      '__ngContext__'
    ]);
    let refComp: any = Object?.entries(_this)?.filter(kv => !keysToExclude.includes(kv[0]) && !kv[0].toLocaleLowerCase().endsWith("service"))?.reduce((acc, val) => {
      try { acc[val[0]] = val[1] } catch (error) { }
      return acc;
    }, {})

    _this.completeDashboardConfig.referenceComponent = refComp;
  }

  generateRandomString() {
    return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  mapFromProfile(info, item, profile, valAlreadyCalculated = false) {
    // Destructure the mapping key, idKey and outputKey from the info object
    const { key = 'timeStates', idKey = 'id', outputKey = 'label' } = info?.mappingConfig ?? {};

    // Split the mapping key into an array of keys if it's a string with dots
    let keys = typeof key === 'string' && key.includes('.') ? key.split('.') : [key];

    // Extract the container from the profile using the keys array
    let container = this.getNestedValue(keys, profile);

    // Determine the value based on whether it's already been calculated or not
    let value = valAlreadyCalculated ? item : item?.[info?.variable];

    // Element configuration of the container that matches the id value
    let elemConfig = container?.find(x => x?.[idKey] == value);

    // "outputKey" of the element
    let finalValue = elemConfig?.[outputKey];

    // If the outputkey is label, before returning the value, check that "translations" or "desc" object is present (cmms config options)
    if (outputKey == 'label') {
      if (elemConfig?.translations != null) finalValue = elemConfig?.translations?.[this.translate.currentLang];
      else if (elemConfig?.desc != null) finalValue = elemConfig?.desc?.[this.translate.currentLang];
    }

    return finalValue;

  }

  translateElementNewFormat(elemConfig: any) {

    let finalValue = elemConfig.label;

    if (elemConfig?.translations != null) return elemConfig?.translations?.[this.translate.currentLang];
    else if (elemConfig?.desc != null) return elemConfig?.desc?.[this.translate.currentLang];

    return this.translate.instant(finalValue);

  }

  getNestedValue(propertiesArray = [], object = {}) {

    // Check if propertiesArray or object is falsy (null, undefined, etc.),
    // and return undefined if either of them is, since we can't perform any lookups.
    if (!propertiesArray || !object) {
      console.log("Error: propertiesArray or object is falsy.");
      return undefined;
    }

    // Use the reduce function to traverse the propertiesArray and look up
    // the corresponding values in the object. If a key in propertiesArray
    // doesn't exist in the current object, the reduce function will return undefined,
    // and we will return undefined.
    return propertiesArray.reduce((acc, key) => acc?.[key], object);

  }

  openComponentSelectionDialog(_this, pageName) {

    let componentsConfig = this.clonerService.deepClone(_this.componentsConfig);

    const dialog = _this.dialog.open(MachineComponentSelectionDialogComponent,
      {
        panelClass: 'ff-dialog',
        width: '40%',
        data: {
          title: _this.translate.instant("HEALTH_MONITORING.CHANGE_COMPONENT"),
          list: componentsConfig,
          lineMode: _this.machineRefId != null,
          singleSelectionMode: true,
          selectedComponent: {
            machineRefId: _this.machineRefId,
            componentId: _this.componentId,
          }
        },
      });

    dialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {

        _this.internalDataService.setHMComponentSelected({
          machineRefId: result.machineRefId,
          componentId: result.componentId,
        });

        let url: any = [_this.machineId, 'health-monitoring', pageName];
        this.router.navigate(url, { queryParams: { machineRefId: result.machineRefId, componentId: result.componentId } })

      }
    });
  }


  getDomain(range: any) {
    if (range != null && range > 0) {
      // solo due assi
      if (range < 3) {
        return [0, 1];
        // lunghezza pari
      } else if (range % 2 == 0) {
        return [0.05 * (range - 1) / 2, 1 - (0.05 * (range - 1) / 2)];
        // lunghezza dispari
      } else if (range % 2 != 0) {
        return [0.05 * (range - 1) / 2, 1 - (0.05 * (range - 3) / 2)];
      }
    }
    return [0, 1];
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  isActiveCalendar(machineId?) {
    if (machineId == null) machineId = this.cacheService.get('machineId');
    let enabledCalendarAsset = localStorage.getItem(machineId + '_calendar');
    return enabledCalendarAsset == 'true';
  }

  getAssetsFromHierarchy(elements) {
    let selectedAssets = [];
    this.iterateThroughHierarchy(elements, selectedAssets);
    return selectedAssets;
  }

  iterateThroughHierarchy(elements, selectedAssets) {
    for (const element of elements) {
      if (element.elements) {
        this.iterateThroughHierarchy(element.elements, selectedAssets);
      }
      else {
        let existsElement = selectedAssets.find(x => x.id == element.id) != null;
        if (!existsElement) {
          selectedAssets.push({
            id: element.id,
            label: element.label
          });
        }
      }
    }

  }

}

