import { Component, Input, OnInit } from '@angular/core';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'machine-card',
  templateUrl: './machine-card.component.html',
  styleUrls: ['./machine-card.component.scss']
})
export class MachineCardComponent implements OnInit {

  @Input() machineData: any;
  @Input() machineInfos: any;
  @Input() dashboardConfig: any;
  @Input() imageFlex: any;

  image: any;
  noImage: any;

  constructor(
    public internalDataService: InternalDataService,
  ) { }

  ngOnInit(): void {

    this.image = 'assets/images/' + this.machineData?.machineId + '.png';
    // console.log(this.machineData);
    // console.log(this.machineInfos);

  }

}
