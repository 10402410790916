import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';
import { ComponentVariablesSelectionComponent } from '../../machine-recorder/component-variables-selection/component-variables-selection.component';

@Component({
  selector: 'hm-features-trend',
  templateUrl: './hm-features-trend.component.html',
  styleUrls: ['./hm-features-trend.component.scss']
})
export class HmFeaturesTrendComponent implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public backButton: any;
  public tabs: any;

  public HMComponentSelectedSub: Subscription;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public pollingTime: any;
  public pollingEvents: any;

  public currentSynopticId: any;
  public synopticConfig: any;
  public synopticConfigDefault: any;
  public monitoringData: any;
  public monitoringDataUnparsed: any;
  public dashboardConfig: any;

  public completeDashboardConfig: any;
  public dashboardType: any = null;

  public cycleInfoConfig: any;
  public cycleData: any;

  public dialogData: any;
  public showDialog: boolean = false;
  public chartOptions: any;

  public defaultInterval: any;

  public interval: any;
  public intervalConfig: any;

  public componentTableInfos: any = [];

  public tableInfos: any = [];
  public tableColumns: string[] = [];
  public table: any;
  public tableData: any;

  public componentConfig: any = {};
  public componentId: any;
  public machineRefId: any;

  public sectionName: any = "healthMonitoringDetail";
  public dashboardName: any = "features-trend";

  public dashboardData: any = {};

  public availableMachines: any;

  public cachedIntervalId: any = "intervalHM";

  public mobileListener: Subscription;
  public isMobile: boolean = false;
  public isSmThanTablet: boolean;
  public errorDataMobile: any;

  features: any = [];
  groups: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getComponentsConfig, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getHealthTraceabilityPolling, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public mobile: MobileService,
    public router: Router,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));
    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile;
      this.isSmThanTablet = value.isSmThanTablet;
    })

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['HEALTH_MONITORING.TITLE', 'FEATURES_EXPLORATION.FEATURES_TREND'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb[2] = value.machineName;
        this.breadcrumb = newBreadcrumb;
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.HMComponentSelectedSub = this.internalDataService.HMComponentSelected.subscribe(value => {
      if (value != null) {
        this.breadcrumb = this.internalDataService.getHMBreadcrumb(this, value);
        this.internalDataService.setBreadcrumb(this.clonerService.deepClone(this.breadcrumb));
      }
    });

    this.pollingTime = 0;
    this.pollingEvents = Subscription;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
    } catch (error) {
      console.log(error);
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET COMPONENTS CONFIG

  getComponentsConfig(_this: any) {

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getComponentsConfig(_this, _this.machineId, _this.machineRefId, _this.componentId, true);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getHealthTraceabilityPolling, this.getHealthTraceability, this.machine.timezone, 6, 7) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {

    let c_customVariables = _this.cacheService.get("customVariables");

    _this.features = []
    if (_this.appConfig?.addCustomVariables && c_customVariables?.machineId == _this.machineId && c_customVariables?.value != null) {
      _this.features = c_customVariables.value.features;
    }

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SYNOPTIC CONFIG

  // polling
  getHealthTraceabilityPolling(_this: any, range: any = null) {
    try {

      if (range == null) {
        if (_this.cacheService.get(_this.cachedIntervalId) == null) {

          _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

          _this.intervalConfig = {
            list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
            selected: _this.interval
          };

          _this.cacheService.set(_this.cachedIntervalId, _this.clonerService.deepClone(_this.intervalConfig));

        } else {
          _this.intervalConfig = _this.clonerService.deepClone(_this.cacheService.get(_this.cachedIntervalId));
          _this.interval = _this.intervalConfig.selected;
        }
      }

      if (_this.defaultInterval == null) _this.defaultInterval = _this.clonerService.deepClone(_this.interval);

      if (_this.pollingTime > 0) _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => _this.getHealthTraceability(_this, count, range));
      else _this.getHealthTraceability(_this, 0, range);

    } catch (error) {
      console.log(error);
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getHealthTraceability(_this: any, count?: any, range?: any) {
    try {

      let url = '/apif/condition-monitoring/cycles-detail/' + _this.machineId + '/' + _this.componentId;

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        anomalyDetection: true,
        tz: _this.machine.timezone
      };

      if (_this.machineRefId != null) {
        query.machineId = _this.machineRefId;
        try {
          query.processes = _this.machine.machines[_this.machineRefId].processes.join(";");
        } catch (error) { }
      }

      let payload: any = {
        additionalCycleVariables: this.features?.map(x => x.id) ?? [],
      };

      _this.apiService.sendPostRequest(url, payload, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            // console.log(_this.componentConfig);

            // _this.dashboardData = Object.assign(_this.clonerService.deepClone(_this.componentConfig), _this.parseData(data.body));
            _this.dashboardData = data.body;
            // _this.table.list = _this.parseTableData(_this.dashboardData.timeserie);

            try {
              _this.dashboardData.dataConfig = {};
              // set plot data and configs
              _this.dashboardData.plotData = _this.buildPlotConfig(_this, _this.dashboardData);
            } catch (error) { }

            _this.completeDashboardConfig = {
              dashboardData: _this.clonerService.deepClone(_this.dashboardData),
              machineProfile: _this.machine.profile,
              dashboardConfig: _this.dashboardConfig,
              aggregations: _this.aggregations,
              dashboardType: _this.dashboardType,
              aggregatedTable: _this.aggregatedTable,
            };

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      console.log(error);
    }

  }

  // BUILD PLOT
  buildPlotConfig(_this: any, data: any) {

    let traces: any = [];
    let dataSet: any = this.clonerService.deepClone(data);

    dataSet.sort(this.filterService.sortByProperty("timestamp", "asc", true));

    let c_groups = this.cacheService.get("groupsOfFeatures");

    console.log(c_groups);

    this.groups = c_groups ?? _this.features?.reduce((acc, val) => {

      if (val.component != this.componentId) return acc;
      // if (val.components == null || val.components.length == 0 || !val.components.includes(this.componentId)) {
      //   return acc;
      // }

      let obj = {
        id: val.id,
        unit: val.unit,
        label: val.translations?.[this.translate.currentLang] ?? val.id,
        show: val.selected,
        values: dataSet.map(x => x[val.id]),
      };

      let groupIndex = acc.findIndex(x => x.id == val.unit);

      if (groupIndex == -1) {
        acc.push({
          id: val.unit,
          show: true,
          label: val.unit,
          unit: val.unit,
          traces: [obj]
        })
      }
      else {
        acc[groupIndex].traces.push(obj);
      }
      return acc;
    }, []);

    let domain = this.filterService.getDomain(this.groups.length);

    let plotLayout: any = {
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: -0.15
      },
      xaxis: {
        domain: domain,
        anchor: 'y3',
        showgrid: false,
        zeroline: false,
      },
      yaxis: {
        domain: [0.55, 1],
        fixedrange: true,
        range: [-0.5, 105],
        automargin: true,
        zeroline: false,
        showgrid: false,
        title: `${this.translate.instant("HEALTH_MONITORING.HEALTH")} [%]`
      },
      yaxis2: {
        domain: [0.55, 1],
        fixedrange: true,
        automargin: true,
        zeroline: false,
        showgrid: false,
        overlaying: "y",
        side: "right",
        title: `${this.translate.instant("CONDITION_ANALYSIS.CYCLE_TIME")} [s]`
      },
      shapes: [{
        type: 'line',
        xref: 'paper',
        yref: 'paper',
        line: {
          color: "black",
          width: 1
        },
        x0: 0,
        x1: 1,
        y0: 0.5,
        y1: 0.5
      }],
      margin: {
        t: 0,
        r: 80,
        b: 80,
        l: 80,
        pad: 5
      }
    };

    let threshold = 0.6;

    let timestamps = dataSet.map((x: any) => x.timestamp);
    let cycleIds = dataSet.map((x: any) => x.cycleId);
    let thresholds = dataSet.map(() => threshold * 100);
    let health = dataSet.map((x: any) => x.health != null ? x.health * 100 : null);
    let cycleTimes = dataSet.map((x: any) => x.cycleTime);

    let hoverInfo: any = dataSet.map((x: any, xIdx) => {
      let hover: any = "";
      hover += "<b>" + this.translate.instant("HEALTH_MONITORING.HEALTH") + ':</b> ' + this.filterService.parseGaugeValue(x.health, 1, 100) + '%<br>';
      hover += "<b>" + this.translate.instant("HEALTH_MONITORING.THRESHOLD") + ':</b> ' + this.filterService.parseGaugeValue(threshold, 0, 100) + '%<br>';
      hover += "<b>" + this.translate.instant("HEALTH_MONITORING.DATE") + ':</b> ' + this.filterService.parseMoment(x.timestamp, 'default') + '<br>';
      hover += "<b>" + _this.translate.instant("GLOBAL.CYCLE_ID") + ": </b>" + cycleIds[xIdx] + '<br>';
      return hover;
    });

    let cycleTimeHoverInfo: any = dataSet.map((x: any, xIdx) => {
      let hover: any = "";
      hover += "<b>" + this.translate.instant("CONDITION_ANALYSIS.CYCLE_TIME") + ':</b> ' + this.filterService.parseGaugeValue(x.cycleTime, 1, 1) + 's<br>';
      hover += "<b>" + this.translate.instant("HEALTH_MONITORING.DATE") + ':</b> ' + this.filterService.parseMoment(x.timestamp, 'default') + '<br>';
      hover += "<b>" + _this.translate.instant("GLOBAL.CYCLE_ID") + ": </b>" + cycleIds[xIdx] + '<br>';
      return hover;
    });

    let domains = ["time", "space"];

    let activeDomain = "time";

    domains.forEach(domain => {

      let xVals = domain == 'time' ? timestamps : dataSet.map((_, i) => i);

      traces.push({
        x: xVals,
        y: health,
        name: this.translate.instant("HEALTH_MONITORING.HEALTH"),
        hoverinfo: 'text',
        hovertext: hoverInfo,
        type: "scatter",
        mode: 'lines',
        visible: domain == activeDomain,
        traceType: domain,
        xaxis: 'x',
        // line: {
        //   shape: "spline"
        // },
        // marker: {
        //   size: 4,
        // }
      });

      traces.push({
        x: xVals,
        y: thresholds,
        name: this.translate.instant("HEALTH_MONITORING.THRESHOLD"),
        hoverinfo: 'skip',
        type: "scatter",
        mode: 'lines',
        visible: domain == activeDomain,
        traceType: domain,
        xaxis: 'x',
        line: {
          color: "#FF5757",
          // shape: "spline",
          dash: "dot"
        }
      });

      traces.push({
        x: xVals,
        y: cycleTimes,
        name: _this.translate.instant('CONDITION_ANALYSIS.CYCLE_TIME'),
        type: "scatter",
        hoverinfo: "text",
        hovertext: cycleTimeHoverInfo,
        mode: 'lines',
        visible: domain == activeDomain,
        traceType: domain,
        xaxis: 'x',
        yaxis: 'y2',
        // line: {
        //   shape: "spline"
        // }
      });

      this.groups.forEach((group, _i) => {

        let yaxisIndex = _i + 3;

        if (plotLayout[`yaxis${yaxisIndex}`] == null) {

          let provaIndex = yaxisIndex - 2;
          plotLayout[`yaxis${yaxisIndex}`] = {
            fixedrange: true,
            domain: [0, 0.45],
            zeroline: false,
            showgrid: false,
            automargin: true,
            anchor: 'free',
            position: provaIndex > 1 ? (provaIndex % 2 != 0 ? 0.05 * (provaIndex - 1) / 2 : (1 - (0.05 * (provaIndex - 2) / 2))) : null,
            overlaying: provaIndex > 1 ? 'y3' : null,
            side: provaIndex % 2 == 0 ? 'right' : 'left',
            title: {
              // standoff: 50,
              text: this.filterService.convertUnit(group.unit).unit
            }
          };

        }
        group.traces.filter(x => x.show)?.forEach(f => {

          let hoverText = f.values?.reduce((acc, val, xIdx) => {

            let hover = "<b>" + _this.translate.instant("FEATURES_EXPLORATION.FEATURE") + ": </b>" + f.label + '<br>';
            hover += "<b>" + _this.translate.instant("CONTINUOUS_EXPLORATION.VALUE") + ": </b>" + (val != null ? _this.filterService.parseGaugeValue(this.filterService.convertUnit(f.unit, val != null ? val * (f.multiplier ?? 1) : null).value, f.decimals, 1) : '-');
            hover += " " + this.filterService.convertUnit(group.unit).unit + "<br>";
            hover += "<b>" + _this.translate.instant("CONTINUOUS_EXPLORATION.TIME") + ": </b>" + moment(timestamps[xIdx]).format("MMM DD, YYYY HH:mm:ss.SSS") + '<br>';
            hover += "<b>" + _this.translate.instant("GLOBAL.CYCLE_ID") + ": </b>" + cycleIds[xIdx] + '<br>';
            acc.push(hover);
            return acc;
          }, []);

          traces.push({
            x: xVals,
            y: f.values,
            name: f.label,
            text: hoverText,
            hoverinfo: "text",
            type: "scatter",
            mode: 'lines',
            visible: domain == activeDomain,
            traceType: domain,
            // line: {
            //   shape: "spline"
            // },
            xaxis: 'x',
            yaxis: `y${yaxisIndex}`
          });

        })
      })

    });

    // console.log({ traces });

    let updatemenus = [
      {
        buttons: [
          {
            args: [
              {
                'visible': traces.map(x => x.traceType == null || x.traceType == 'time')
              },
              {
                'xaxis.type': "date"
              }
            ],
            label: _this.translate.instant("FEATURES_EXPLORATION.TIME_DOMAIN"),
            method: 'update'
          },
          {
            args: [
              {
                'visible': traces.map(x => x.traceType == null || x.traceType == 'space')
              },
              {
                'xaxis.type': null
              }
            ],
            label: _this.translate.instant("FEATURES_EXPLORATION.SPACE_DOMAIN"),
            method: 'update'
          },
        ],
        direction: 'left',
        pad: {
          'r': 10,
        },
        showactive: true,
        type: 'buttons',
        x: 0,
        y: 1.15,
        xanchor: 'left',
        yanchor: 'top'
      }
    ];

    plotLayout.updatemenus = updatemenus;

    // console.log({ plotLayout });

    return {
      layout: plotLayout,
      traces: traces,
      params: {
        displayModeBar: false,
        // relayoutVariables: true,
      }
    };
  }

  openFeaturesSelectionDialog() {

    let groups = {};

    groups[this.componentId] = { plotsConfig: { list: this.groups } };

    const variablesDialog = this.dialog.open(ComponentVariablesSelectionComponent,
      {
        panelClass: 'ff-dialog',
        width: '40%',
        // height: '90%',
        data: {
          title: this.translate.instant("CONTINUOUS_EXPLORATION.VARIABLES_SELECTION"),
          hideComponents: true,
          componentId: this.componentId,
          groups: groups
        },
      });

    variablesDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {

          this.cacheService.set("groupsOfFeatures", this.clonerService.deepClone(result.groups[this.componentId].plotsConfig.list));

          this.pageState.next(6);
          setTimeout(() => {

            this.dashboardData.plotData = this.buildPlotConfig(this, this.dashboardData);
            this.completeDashboardConfig.dashboardData = this.dashboardData;
            this.pageState.next(7);
          }, 500);


        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    let queryParams = this.route.snapshot.queryParams;

    this.backButton = [this.machineId, "health-monitoring"];
    this.internalDataService.setBackButton(this.backButton);

    this.componentId = queryParams.componentId;
    this.machineRefId = queryParams.machineRefId;

    this.route.params.subscribe((params: Params) => {

      this.machineId = params['machineId'];

      try {
        let queryParams = this.route.snapshot.queryParams;
        this.componentId = queryParams.componentId;
        this.machineRefId = queryParams.machineRefId;
      } catch (error) { console.log("Wrong query params") }

      this.internalDataService.setHMComponentSelected({
        machineRefId: this.machineRefId,
        componentId: this.componentId,
      });

    });

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    this.cacheService.set("groupsOfFeatures", null);
    try { this.pollingEvents.unsubscribe() } catch (error) { }
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.HMComponentSelectedSub.unsubscribe() } catch (error) { }
    try { this.internalDataService.setBackButton([]) } catch (error) { }
  }

}