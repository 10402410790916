<div fxLayout="row" fxLayoutAlign="start center" class="clickable" (click)="onCheckboxClick(checkBoxConfig)">

  <mat-icon *ngIf="checkBoxConfig?.checked" svgIcon="radio_button" class="icon small left radio_button">
  </mat-icon>
  <mat-icon *ngIf="!checkBoxConfig?.checked" svgIcon="radio_button_disabled"
    class="icon small left radio_button_disabled">
  </mat-icon>

  <span *ngIf="checkBoxConfig?.label != null">{{ checkBoxConfig.label | translate }}</span>

  <div *ngIf="checkBoxConfig?.recurrence != null" class="input-box" style="margin-left: 8px;">
    <input matInput class="ff-input number w100" style="max-width: 90px;" type="number" [min]="1"
      [(ngModel)]="checkBoxConfig.recurrence" [disabled]="false" (ngModelChange)="onRecurrencyChange(checkBoxConfig)">
  </div>

  <span *ngIf="checkBoxConfig?.label2 != null" style="margin-left: 8px;">{{ checkBoxConfig.label2 | translate }}</span>

</div>
