import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'app-consumption-supply-settings',
  templateUrl: './consumption-supply-settings.component.html',
  styleUrls: ['./consumption-supply-settings.component.scss']
})
export class ConsumptionSupplySettingsComponent implements OnInit, OnChanges {

  public isAllowedUser: any = true;
  public isAllowedUserWrite: any = true;
  public isSupplier: boolean = true;
  public isClient: boolean = true;
  public isSuperClient: boolean = false;
  public isPageAdmin: boolean = false;

  loadingData: any;
  errorData: any;
  public errorDataMobile: any;

  appConfig: any;
  appInfo: any;
  machineProfiles: any;

  public customPlantId: any;
  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  breadcrumb: any;
  public tabs: any;

  public settings: any;
  public settingsUnparsed: any;
  public settingsList: any;
  public settingsData: any;
  public settingsVariables: any;
  inputInfos: any = [];

  public tabName: any = "supplySettings";
  public sectionName: any = "consumptionAnalytics";

  public mobileListener: Subscription;
  public isMobile: any;
  public mailRegex: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getSupplySettings, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public route: ActivatedRoute,
    public translate: FfTranslateService,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public mobile: MobileService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })

    this.breadcrumb = ['CONSUMPTION_ANALYTICS.TITLE', 'CONSUMPTION_SUPPLY_SETTINGS.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.inputInfos = this.appConfig?.[this.sectionName]?.[this.tabName]?.inputInfos ?? [];
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try { _this.isAllowedUserWrite = _this.internalDataService.getSpecificPermission("mat-cm-css-rw") }
    catch (error) { console.log(error) }

    try { _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(["mat-cm-css-r", "mat-cm-css-rw"], 'or') }
    catch (error) { console.log(error) }

    try { _this.isSupplier = _this.internalDataService.getSpecificPermission("base-supplier", 'scopes') }
    catch (error) { console.log(error) }

    try { _this.isClient = _this.internalDataService.getSpecificPermissions(["base-customer", "super-customer"], 'or', 'scopes') }
    catch (error) { console.log(error) }

    try { _this.isSuperClient = _this.internalDataService.getSpecificPermission("super-customer", 'scopes') }
    catch (error) { console.log(error) }

    try { _this.isPageAdmin = _this.internalDataService.getSpecificPermission("super-supplier", 'scopes') }
    catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SUPPLY SETTINGS

  public getSupplySettings(_this: any) {
    try {

      _this.customPlantId = `${(_this.machine?.parentId ?? 'unknown')}/${(_this.machine?.plant ?? 'unknown')}`;

      let query: any = {};

      let url = `/apif/consumption-supply/settings/${_this.customPlantId}/${(_this.machine?.customer ?? 'unknown')}`;

      _this.apiService.sendGetRequest(url, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy()),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe((data: any) => {
          // console.log(data);

          _this.settingsUnparsed = _this.clonerService.deepClone(data.body);
          _this.settings = _this.parseSettingsVariables(_this.clonerService.deepClone(data.body));

          _this.dispatcherService.getDispatch(_this, 300);

        },
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseSettingsVariables(data: any) {
    if (
      (data?.clientMailingList != null && data.clientMailingList.length > 0) ||
      (data?.superClientMailingList != null && data.superClientMailingList.length > 0)
    ) {
      data.clientMailingList = data.clientMailingList.concat(data?.superClientMailingList ?? []);
      data.clientMailingList = data.clientMailingList.map((mail: any, index: any) => {
        let superClientIdx = data?.superClientMailingList?.indexOf(mail);
        let isSuperClient = superClientIdx != null && superClientIdx > -1;
        return {
          value: mail,
          id: index,
          superClient: isSuperClient
        }
      })
    }
    if (data.hasOwnProperty('supplierMailingList') && data.supplierMailingList != null && data.supplierMailingList.length > 0) {
      data.supplierMailingList = data.supplierMailingList.map((mail: any, index: any) => {
        return {
          value: mail,
          id: index
        }
      })
    }
    return data;
  }

  parsePayload(data: any) {

    let newData: any = Object.assign({}, {
      clientMailingList: data?.clientMailingList?.filter(x => !x.superClient)?.map((mail: any) => mail.value) ?? [],
      superClientMailingList: data?.clientMailingList?.filter(x => x.superClient)?.map((mail: any) => mail.value) ?? [],
      supplierMailingList: data.supplierMailingList != null && data.supplierMailingList.length > 0 ? data.supplierMailingList.map((mail: any) => mail.value) : [],
      materials: data.materials,
    });

    return newData;
  }

  updateSettings() {

    this.pageState.next(4);

    let payload = this.parsePayload(this.clonerService.deepClone(this.settings));

    console.log(payload);

    try {


      // let url = `/apif/consumption-supply/settings/${_this.customPlantId}/${(_this.machine?.customer ?? 'unknown')}`;
      let url = `/apif/consumption-supply/settings/${this.customPlantId}/${(this.machine?.customer ?? 'unknown')}`;
      // let url = '/apif/consumption-supply/settings/' + this.customPlantId;

      let _this: any = this;

      _this.apiService.sendPostRequest(url, payload, null)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy()),
          catchError(error => {
            _this.internalDataService.openSnackBar('Update failed', 'right', 'bottom', 4000, '', ["fail"]);
            return _this.internalDataService.parseStandardHTTPError(_this, error);
          }))
        .subscribe(
          (data: any) => {
            console.log(data.body);

            _this.internalDataService.openSnackBar('Update successful', 'right', 'bottom', 4000, '', ["success"]);

            _this.settingsUnparsed = _this.clonerService.deepClone(data.body);
            _this.settings = _this.parseSettingsVariables(_this.clonerService.deepClone(data.body));

            _this.pageState.next(6);

          },
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      this.dispatcherService.getDispatch(this, 301, errorData);
    }
  }

  // ADD MAIL
  addMail(type: any) {

    switch (type) {
      case 'client':
      default:
        this.settings.clientMailingList.push({
          id: this.settings.clientMailingList.length,
          value: ""
        });
        break;
      case 'supplier':
        this.settings.supplierMailingList.push({
          id: this.settings.supplierMailingList.length,
          value: ""
        });
        break;
    }
  }

  // DELETE MAIL
  deleteMail(type: any, index: any) {

    switch (type) {
      case 'client':
      default:
        this.settings.clientMailingList.splice(index, 1);
        break;
      case 'supplier':
        this.settings.supplierMailingList.splice(index, 1);
        break;
    }

  };

  checkUpdates(checkExit?: any) {
    try {
      // console.log('checkUpdates');

      // Check alert settings
      // let isChangedMaterials = null;
      // let isInvalidMaterials = !this.settings.materials.every((al: any) => al.parameters.every((p: any) => p.notRequired || (p.value != null && p.value !== '' && p.value != undefined)));
      // if (!isInvalidMaterials || checkExit) {
      //   if (this.settingsUnparsed.materials.length == this.settings.materials.length) {
      //     isChangedMaterials = !this.settings.materials.every((al: any, alIdx: any) =>
      //       al.parameters.every((p: any, pIdx: any) =>
      //         this.settingsUnparsed.materials[alIdx].parameters[pIdx].value == (p.multiplier != null ? p.value / p.multiplier : p.value)) &&
      //       (al.checked == this.settingsUnparsed.materials[alIdx].checked || al.checked == !!+this.settingsUnparsed.materials[alIdx].checked) &&
      //       (al.deactivationAlert == this.settingsUnparsed.materials[alIdx].deactivationAlert || al.deactivationAlert == !!+this.settingsUnparsed.materials[alIdx].deactivationAlert) &&
      //       (al.machines != null && al.machines.length > 0 ?
      //         al.machines.length == this.settingsUnparsed.materials[alIdx].machines.length &&
      //         al.machines.every((m: any) => (this.settingsUnparsed.materials[alIdx].machines != null && this.settingsUnparsed.materials[alIdx].machines.length > 0) ?
      //           this.settingsUnparsed.materials[alIdx].machines.includes(m) : true) :
      //         true)
      //     )
      //   } else {
      //     isChangedMaterials = true;
      //   }
      // } else {
      //   isChangedMaterials = false;
      // }

      // Check Client Mailing List
      let isChangedClientMail = null;
      let isInvalidClientMail = !this.settings.clientMailingList.every((clientMail: any) => clientMail.value != null && clientMail.value != '' && RegExp(this.mailRegex, 'g').exec(clientMail.value));
      if (!isInvalidClientMail || checkExit) {

        if (
          (this.settingsUnparsed.clientMailingList.length != this.settings.clientMailingList?.filter(mail => !mail.superClient)?.length) ||
          (this.settingsUnparsed.superClientMailingList?.length != this.settings.clientMailingList?.filter(mail => mail.superClient)?.length)
        ) {
          isChangedClientMail = true;
        }

        else {
          isChangedClientMail = !this.settings.clientMailingList.every((clientMail: any, clientMailIdx: any) => {

            let isChangedSupplierRole = clientMail.superClient ? this.settingsUnparsed.superClientMailingList?.some(x => x == clientMail.value) : this.settingsUnparsed.clientMailingList?.some(x => x == clientMail.value);
            return isChangedSupplierRole;

          });
        }
      } else {
        isChangedClientMail = false;
      }

      // Check Supplier Mailing List
      let isChangedSupplierMail = null;
      let isInvalidSupplierMail = !this.settings.supplierMailingList.every((supplierMail: any) => supplierMail.value != null && supplierMail.value != '' && RegExp(this.mailRegex, 'g').exec(supplierMail.value));
      if (!isInvalidSupplierMail || checkExit) {
        if (this.settingsUnparsed.supplierMailingList.length == this.settings.supplierMailingList.length) {
          isChangedSupplierMail = !this.settings.supplierMailingList.every((supplierMail: any, supplierMailIdx: any) => supplierMail.value == this.settingsUnparsed.supplierMailingList[supplierMailIdx]);
        }
        else {
          isChangedSupplierMail = true;
        }
      } else {
        isChangedSupplierMail = false;
      }

      // return isChangedAlerts == false && isChangedMail == false;
      return isChangedClientMail == false && isChangedSupplierMail == false;

    } catch (error) {
      return true;
    }
  }

  canDeactivate() {
    return this.checkUpdates(true);
  }

  exists(item: any, list: any) {
    return list.filter((x: any) => x == item).length > 0;
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit(): void {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);
  }

  // CHANGE
  ngOnChanges() { }

  // DESTROY
  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
  }

}
