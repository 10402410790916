<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
  <!-- utils -->
  <!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->
</div>

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar -->
  <div fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">
    <!-- tabs -->
    <ff-tabs [tabs]="tabs"></ff-tabs>
  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start center" class="tab-content">

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < pageStateReady" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 5" style="border-radius: 25px; background-color: #fff; margin: 12px 0 12px 0;"
      class="w100">
      <div class="phase-data-title">
        <span>{{ 'CYCLE_TIMELINE.CYCLE_DATA' | translate }}</span>
      </div>
      <div class="w100 ff-widget-container" fxLayout="row wrap" fxLayoutAlign="start stretch"
        fxLayoutGap="{{ cycleInfoConfig.gap != null ? (cycleInfoConfig.gap + ' grid') : '8px grid' }}">
        <ff-widget *ngFor="let w of cycleInfoConfig.widgets"
          [fxFlex]="'calc(' + w.flex + '% - ' + (cycleInfoConfig.gap != null ? cycleInfoConfig.gap : '8px') + ')'"
          [widget]="{ type: w.type, data: cycleData, machineProfile: machine.profile, config: w.config, title: w.title }">
        </ff-widget>
      </div>
    </div>

    <!-- chart -->
    <div *ngIf="pageState.value >= 5" style="border-radius: 25px; background-color: #fff;" class="w100">
      <apx-chart class="w100" [chart]="chartOptions.chart" [plotOptions]="chartOptions.plotOptions"
        [dataLabels]="chartOptions.dataLabels" [xaxis]="chartOptions.xaxis" [tooltip]="chartOptions.tooltip"
        [yaxis]="chartOptions.yaxis" [grid]="chartOptions.grid" [series]="chartOptions.series"
        [annotations]="chartOptions.annotations"></apx-chart>
    </div>

    <div *ngIf="pageState.value >= 5" style="border-radius: 25px; background-color: #fff; margin-top: 12px;"
      class="w100">
      <div class="phase-data-title">
        <span>{{ 'CYCLE_TIMELINE.PHASE_DATA' | translate }}</span>
        <span *ngIf="monitoringData.label == null" class="select-phase">{{ 'CYCLE_TIMELINE.SELECT_PHASE' | translate
          }}</span>
        <span *ngIf="monitoringData.label != null"> - {{ monitoringData.label }}</span>
      </div>
      <div *ngIf="monitoringData.label != null" class="w100 ff-widget-container" fxLayout="row wrap"
        fxLayoutAlign="start stretch"
        fxLayoutGap="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
        <ff-widget *ngFor="let w of dashboardConfig.widgets"
          [fxFlex]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
          [widget]="{ type: w.type, data: monitoringData, machineProfile: machine.profile, config: w.config, title: w.title }">
        </ff-widget>
      </div>
    </div>

    <div *ngIf="pageState.value >= 5 && monitoringData.phaseVariables != null"
      style="border-radius: 25px; background-color: #fff; margin-top: 12px;" class="w100">
      <div class="phase-data-title">
        <span>{{ 'CYCLE_TIMELINE.PHASE_VARIABLES' | translate }}</span>
        <span *ngIf="monitoringData.label != null"> - {{ monitoringData.label }}</span>
      </div>
      <div class="w100 ff-widget-container" fxLayout="row wrap" fxLayoutAlign="start stretch"
        fxLayoutGap="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
        <ff-widget *ngFor="let p of monitoringData.phaseVariables"
          [fxFlex]="'calc(' + p.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
          [widget]="{ type: p.type, data: monitoringData, machineProfile: machine.profile, config: p.config, title: p.title }">
        </ff-widget>
      </div>
    </div>

    <div fxLayout="column" *ngIf="pageState.value >= 5 && events.list.length > 0" class="w100"
      style="border-radius: 25px; background-color: #fff; margin-top: 12px; padding-bottom: 16px;">

      <div class="phase-data-title" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon *ngIf="!events.expanded" svgIcon="curtain_down" class="icon left clickable"
          (click)="events.expanded = !events.expanded">
        </mat-icon>
        <mat-icon *ngIf="events.expanded" svgIcon="curtain_up" class="icon left clickable"
          (click)="events.expanded = !events.expanded"></mat-icon>
        <span>{{ 'CYCLE_TIMELINE.EVENTS_LOG' | translate }}</span>
      </div>

      <table *ngIf="events.expanded" mat-table [dataSource]="eventsData" matSort>

        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let event" class="td-icon-large">
            <!-- <span>{{ event | json }}</span> -->
            <!-- <div class="box-icon md-red-i">
              <mat-icon class="icon" aria-label="event">cancel</mat-icon>
            </div> -->
            <div fxLayout="row" fxLayoutAlign="center center">
              <div *ngIf="event.icon1">
                <mat-icon *ngIf="event.icon1.type == 'icon'"
                  class="icon {{ event.icon1.class != null ? event.icon1.class : '' }}"
                  translate-attr="{'aria-label': infoCy.label}">
                  {{event.icon1.icon}}</mat-icon>
                <mat-icon *ngIf="event.icon1.type == 'svg'" svgIcon="{{ event.icon1.icon }}"
                  class="icon {{ event.icon1.class != null ? event.icon1.class : '' }}"
                  translate-attr="{'aria-label': infoCy.label}"></mat-icon>
                <i *ngIf="event.icon1.type == 'fontawesome' || event.icon1.type == 'phosphor'"
                  class="icon {{ event.icon1.class != null ? event.icon1.class : '' }} {{ event.icon1.icon }}"
                  translate-attr="{'aria-label': infoCy.label}"></i>
              </div>
              <div *ngIf="event.icon1 == null">

              </div>

              <div *ngIf="event.icon2">
                <mat-icon *ngIf="event.icon2.type == 'icon'"
                  class="icon {{ event.icon2.class != null ? event.icon2.class : '' }}"
                  translate-attr="{'aria-label': infoCy.label}">
                  {{event.icon2.icon}}</mat-icon>
                <mat-icon *ngIf="event.icon2.type == 'svg'" svgIcon="{{ event.icon2.icon }}"
                  class="icon {{ event.icon2.class != null ? event.icon2.class : '' }}"
                  translate-attr="{'aria-label': infoCy.label}"></mat-icon>
                <i *ngIf="event.icon2.type == 'fontawesome' || event.icon2.type == 'phosphor'"
                  class="icon {{ event.icon2.class != null ? event.icon2.class : '' }} {{ event.icon2.icon }}"
                  translate-attr="{'aria-label': infoCy.label}"></i>
              </div>
              <div *ngIf="event.icon2 == null">

              </div>
            </div>



          </td>
        </ng-container>

        <ng-container *ngFor="let info of eventsInfo" matColumnDef="{{info.variable}}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{info.label|translate}}
          </th>
          <td mat-cell *matCellDef="let event">
            <span *ngIf="info.prefix != null">{{info.prefix}}&nbsp;</span>
            <span>{{(event[info.variable] != null ? event[info.variable] : '-')}}</span>
            <span *ngIf="info.suffix != null">&nbsp;{{info.suffix}}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="eventsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: eventsColumns;"
          [ngClass]="{'highlight': row.phaseId == monitoringData.phaseId }">
        </tr>
      </table>
      <!-- <mat-paginator [length]="events.list.length" [pageSizeOptions]="events.pageOptions" [pageSize]="events.pageSize"
        showFirstLastButtons></mat-paginator> -->

    </div>

    <div fxLayout="column" *ngIf="pageState.value >= 5 && alarms.list.length > 0" class="w100"
      style="border-radius: 25px; background-color: #fff; margin-top: 12px; padding-bottom: 16px;">

      <div class="phase-data-title" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon *ngIf="!alarms.expanded" svgIcon="curtain_down" class="icon left clickable"
          (click)="alarms.expanded = !alarms.expanded">
        </mat-icon>
        <mat-icon *ngIf="alarms.expanded" svgIcon="curtain_up" class="icon left clickable"
          (click)="alarms.expanded = !alarms.expanded"></mat-icon>
        <span>{{ 'ALARMS.TITLE' | translate }}</span>
      </div>

      <table mat-table *ngIf="alarms.expanded" [dataSource]="alarmsData" matSort>

        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let alarm" class="td-icon">
            <div class="box-icon md-red-i">
              <!-- [ngClass]="{ 'md-gray-i-2': alarm.timeEnd != null, 'md-red-i': alarm.timeEnd == null }"> -->
              <mat-icon class="icon" aria-label="alarm">cancel</mat-icon>
              <!-- <mat-icon class="icon" svgIcon="{{.icon}}" aria-label="alarm"></mat-icon> -->
              <!-- <i class="icon {{.icon}}" aria-label="alarm"></i> -->
            </div>
          </td>
        </ng-container>

        <ng-container *ngFor="let info of alarmsInfo" matColumnDef="{{info.variable}}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{info.label|translate}}
          </th>
          <td mat-cell *matCellDef="let alarm">
            <span *ngIf="info.prefix != null">{{info.prefix}}&nbsp;</span>
            <span>{{(alarm[info.variable] != null ? alarm[info.variable] : '-')}}</span>
            <span *ngIf="info.suffix != null">&nbsp;{{info.suffix}}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="alarmsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: alarmsColumns;"></tr>
      </table>
      <!-- <mat-paginator [length]="alarms.list.length" [pageSizeOptions]="alarms.pageOptions" [pageSize]="alarms.pageSize"
        showFirstLastButtons></mat-paginator> -->

    </div>

    <div fxLayout="column" *ngIf="pageState.value >= 5 && signalations.list.length > 0" class="w100"
      style="border-radius: 25px; background-color: #fff; margin-top: 12px; padding-bottom: 16px;">

      <div class="phase-data-title" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon *ngIf="!signalations.expanded" svgIcon="curtain_down" class="icon left clickable"
          (click)="signalations.expanded = !signalations.expanded">
        </mat-icon>
        <mat-icon *ngIf="signalations.expanded" svgIcon="curtain_up" class="icon left clickable"
          (click)="signalations.expanded = !signalations.expanded"></mat-icon>
        <span>{{ 'SIGNALATIONS.TITLE' | translate }}</span>
      </div>

      <table mat-table *ngIf="signalations.expanded" [dataSource]="signalationsData" matSort>

        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let signalation" class="td-icon">
            <div class="box-icon md-orange-i">
              <mat-icon class="icon" aria-label="signalation">warning</mat-icon>
              <!-- <mat-icon class="icon" svgIcon="{{.icon}}" aria-label="signalation"></mat-icon> -->
              <!-- <i class="icon {{.icon}}" aria-label="signalation"></i> -->
            </div>
          </td>
        </ng-container>

        <ng-container *ngFor="let info of signalationsInfo" matColumnDef="{{info.variable}}">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{info.label|translate}}
          </th>
          <td mat-cell *matCellDef="let signalation">
            <span *ngIf="info.prefix != null">{{info.prefix}}&nbsp;</span>
            <span>{{(signalation[info.variable] != null ? signalation[info.variable] : '-')}}</span>
            <span *ngIf="info.suffix != null">&nbsp;{{info.suffix}}</span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="signalationsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: signalationsColumns;"></tr>
      </table>
      <!-- <mat-paginator [length]="signalations.list.length" [pageSizeOptions]="signalations.pageOptions" [pageSize]="signalations.pageSize"
        showFirstLastButtons></mat-paginator> -->

    </div>

  </div>

  <div fxLayout="column" *ngIf="pageState.value >= 5 && paramsR.list.length > 0" class="w100"
    style="border-radius: 25px; background-color: #fff; margin-top: 12px; padding-bottom: 16px;">

    <div class="phase-data-title" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon *ngIf="!paramsR.expanded" svgIcon="curtain_down" class="icon left clickable"
        (click)="paramsR.expanded = !paramsR.expanded">
      </mat-icon>
      <mat-icon *ngIf="paramsR.expanded" svgIcon="curtain_up" class="icon left clickable"
        (click)="paramsR.expanded = !paramsR.expanded"></mat-icon>
      <span>{{ 'PARAMS_R.TITLE' | translate }}</span>

      <span fxFlex></span>

      <button *ngIf="isComparedCycle" mat-button (click)="removeComparedCycle()" class="filter-button md-red-i"
        fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="icon">cancel</mat-icon>
        {{ 'PROCESS_LOG.REMOVE_COMPARED_CYCLE' | translate }}
      </button>

      <button mat-button (click)="openCycleComparison()" class="filter-button" fxLayout="row"
        fxLayoutAlign="center center">
        {{ 'PROCESS_LOG.CYCLE_COMPARISON' | translate }}
        <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': 'PROCESS_LOG.CYCLE_COMPARISON'}"
          style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
        </mat-icon>
      </button>

    </div>

    <table *ngIf="paramsR.expanded" mat-table [dataSource]="paramsRData" matSort>

      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let paramR" class="td-icon">
          <div class="box-icon md-gray-i">
            <mat-icon class="icon" aria-label="paramR">edit</mat-icon>
          </div>
        </td>
      </ng-container>

      <ng-container *ngFor="let info of paramsRInfo" matColumnDef="{{info.variable}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{info.label|translate}}
        </th>
        <td mat-cell *matCellDef="let paramR">
          <span *ngIf="info.prefix != null">{{info.prefix}}&nbsp;</span>
          <span>{{(paramR[info.variable] != null ? paramR[info.variable] : '-')}}</span>
          <span *ngIf="info.suffix != null">&nbsp;{{info.suffix}}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="paramsRColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: paramsRColumns;"></tr>
    </table>
    <!-- <mat-paginator [length]="paramsR.list.length" [pageSizeOptions]="paramsR.pageOptions" [pageSize]="paramsR.pageSize"
        showFirstLastButtons></mat-paginator> -->

  </div>

</div>