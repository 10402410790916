import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'ff-single-cell',
  templateUrl: './ff-single-cell.component.html',
  styleUrls: ['./ff-single-cell.component.scss']
})
export class FfSingleCellComponent implements OnInit {

  public appConfig: any;

  @Input() info: any;
  @Input() item: any;
  @Input() nestedTableIndex?: any = 0;
  @Input() tableConfig?: any;
  @Input() profile?: any;
  @Input() machineId?: any;
  @Output() clicked = new EventEmitter<any>();

  constructor(
    public filterService: FiltersService,
    public clonerService: ClonerService,
    public translate: FfTranslateService,
    public appConfigService: AppConfigService,
    public internalDataService: InternalDataService,
  ) {
    this.appConfig = this
  }

  // ADDITIONAL PARSING
  // Parse icon class
  getDynamicIconAttribute(col: any, row: any, attr: any = 'class') {
    if (col[attr] != null) return col[attr];
    else {

      // Icon config from a particular attribute in profile (default timeStates)
      if (col?.configFromProfile != null && col.iconConfig == null) col.iconConfig = this.clonerService.deepClone(this.profile?.[col.configFromProfile ?? 'timeStates']);

      // Find value in iconConfig
      if (col != null && col.iconConfig != null && Array.isArray(col.iconConfig) && col.iconConfig.length > 0) {
        if (col.attribute != attr && !col.attribute.includes(attr)) return null;
        let attribute = Array.isArray(col.attribute) ? col.attribute.find((x: any) => x == attr) : col.attribute
        let ix = col.iconConfig.findIndex(x => x[col.referenceIdKey ?? 'id'] == row[col.attrVariable]);
        if (ix != -1) return col.iconConfig[ix][attribute ?? 'class'];
      }

    }
    return attr == 'class' ? 'md-gray-i-2' : (attr == 'label' ? '' : 'inherit');
  }

  mapFromProfile(info: any, item: any) {
    let v = this.filterService.mapFromProfile(info, item, this.profile);
    // let mappingKey = info?.mappingConfig?.key ?? 'timeStates';
    // if (typeof mappingKey === 'string' && mappingKey.includes('.')) {
    //   mappingKey = mappingKey.split('.');
    // }

    // let container = [];
    // if (Array.isArray(mappingKey)) {
    //   container = this.profile;
    //   for (let m_key of mappingKey) {
    //     container = container?.[m_key];
    //   }
    // }
    // else {
    //   container = this.profile?.[mappingKey];
    // }

    // let v = container?.find(x => x?.[info?.mappingConfig?.idKey ?? 'id'] == item?.[info?.variable])?.[info?.mappingConfig?.outputKey ?? 'label'];

    // let v = this.profile?.[info?.mappingConfig?.key ?? 'timeStates']?.find(x => x?.[info?.mappingConfig?.idKey ?? 'id'] == item?.[info?.variable])?.[info?.mappingConfig?.outputKey ?? 'label'];
    return info?.mappingConfig?.translate ? this.translate.instant(v ?? '-') : v;
  }

  advancedTranslate(config, item, machineId) {
    try {
      let conf = config ?? {};
      return this.internalDataService.parseLabelWithAssetId(conf?.key, item?.[conf?.aspect], conf?.variable, machineId);
    } catch (error) {
      return config?.key ?? '-';
    }
  }

  toggleExpandRow(item) {
    let action = !item.expandedNesting;
    item.expandedNesting = action;
    if (this.tableConfig?.nestedTableConfig?.isNestedTable) {
      try { item?.[this.tableConfig?.nestedTableConfig?.variable]?.forEach(x => x.expandedNesting = action) }
      catch (error) { console.log(error) }
    }
  }

  getLastConnectionAttribute(info, item, attribute = 'class') {
    try {

      // console.log({ item });
      let variable = item[info.variable];
      let neverConnected = variable == null;
      let notConnected = moment().diff(moment(variable), 'm') > (this.appConfig?.machineMonitoring?.warningMinutes ?? 15);

      switch (attribute) {

        case 'class':
        default:
          if (neverConnected) return 'md-gray';
          if (notConnected) return 'md-red';
          return "md-green"
        case 'icon':
          if (neverConnected) return 'remove';
          if (notConnected) return 'warning';
          return "check"
      }
    } catch (error) { console.log(error) }

  }

  ngOnInit(): void {
  }

}
