import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { StandardParsingService } from 'src/app/services/standard-parsing.service';

@Component({
  selector: 'app-signalations-table',
  templateUrl: './signalations.component.html',
  styleUrls: ['./signalations.component.scss'],
})
export class SignalationsTableComponent implements OnInit, OnDestroy {

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // COMPONENT CONFIGURATION
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  

  // // // // // // // //
  // PAGE CONFIG
  // // // // // // // //

  // Section name: "appConfig[sectionName]"
  public sectionName: any = "machineMonitoring";

  // Tab name: "appConfig[sectionName][tabName]"
  public tabName: any = "signalations";

  // Permission to load the page (in case authentication is provided)
  public permission: any = "mat-ms-signalations";

  // // // // // // // //
  // DASHBOARD CONFIG
  // // // // // // // //

  // Dashboard name: "dashboard-[dashboardName].json"
  public dashboardName: any = "signalations";

  // Expected widgets
  public expectedWidgets: any = [
    {
      id: "ff-table-sortable",
      label: "Table",
      number: 1
    }
  ];

  // // // // // // // //
  // MAIN FUNCTION
  // // // // // // // //

  // Loading message
  public loadingMessageMainFunction: any = "LOADING.SIGNALATIONS";

  // Default Polling Time [ms]
  public defaultPollingTime: any = 30000;

  // Endpoint url: "[endpointUrl] + [machineId]"
  public endpointUrl: any = "/apif/machine-monitoring/signalations/";

  // Parsing Function (inside standardParsingService)
  public parsingFunction: any = "parseWarningsTable";

  // Filter variables
  public defaultFilterVariables: any = ["code", "message"];

  // Switch
  public switch: any = {
    label: "SIGNALATIONS.ACTIVE_ALARMS",
    checked: false,
    checkedLabel: 'on',
    uncheckedLabel: 'off',
  };

  // // // // // // // //
  // OTHER
  // // // // // // // //

  // Breadcrumb
  public breadcrumb: any = ['MACHINE_MONITORING.TITLE', 'SIGNALATIONS.TITLE'];

  // interval
  public cachedIntervalId: any = "intervalMedium";
  public defaultIntervalListId: any = 2;
  public defaultIntervalSelected: any = "today";

  // true if the  machine selector is not needed for the current page
  public excludeLine: boolean = true;

  // true if average and sum are calculated for every parameter of the table
  public addStandardStatisticsToDashboard: boolean = true;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  

  public isAllowedUser: boolean = true;

  public configurationErrors: any = [];

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public tabs: any;

  public aggregations: any;
  public aggregationsPayload: any;

  public machineId: any;
  public machine: any;
  public machineSelectedSub: Subscription;

  public availableMachines: any;

  public pollingTime: any;
  public pollingSubscription: Subscription;

  public interval: any;
  public intervalConfig: any;

  public filterButtons: any;

  public searchElements: any;

  public sliderConf: any = {};

  public dashboardData: any;
  public dashboardNameComplete: any;
  public dashboardConfig: any;
  public completeDashboardConfig: any;


  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.mainPollingFunction, nextState: 5, loadingMsg: this.loadingMessageMainFunction },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public standardParsingService: StandardParsingService,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    // init standard page
    this.internalDataService.initStandardPage(this, this.defaultPollingTime);

    // init slider
    if (this.sliderConf != null) this.initSlider(this);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try { _this.isAllowedUser = _this.internalDataService.getSpecificPermission(_this.permission) }
    catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {

      _this.expectedWidgets?.forEach(widget => {
        if (_this.dashboardConfig?.widgets?.filter(x => x.type == widget.id)?.length != widget.number) {
          let label = "Expected " + widget.number + ' \"' + _this.translate.instant(widget.label) + "\" widget" + (widget.number > 1 ? 's' : '') + " (type \"" + widget.id + "\"), but " + _this.dashboardConfig?.widgets?.filter(x => x.type == widget.id)?.length + " " + (widget.number == 1 ? 'was' : 'were') + " found in the file \"" + _this.dashboardNameComplete + "\""
          _this.configurationErrors.push({ label: label })
        }
      })

      if (_this.configurationErrors?.length > 0) _this.internalDataService.openSnackBar(_this.configurationErrors.map(x => "- " + x.label + ".").join("\n"), 'right', 'bottom', 60000, 'x', ["warning"]);

      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingSubscription, this.mainPollingFunction, this.mainRequest, this.machine.timezone, 5, 6) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // Open aggr dialog
  openAggrDialog(aggr: any) { this.internalDataService.openAggrDialog(this, aggr, this.pollingSubscription, this.mainPollingFunction, this.mainRequest, 5) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // Main polling function
  mainPollingFunction(_this: any) {
    try {

      try {

        // Calculate interval with machine timezone
        if (_this.interval == null) {
          if (_this.cacheService.get(_this.cachedIntervalId) == null) {

            _this.interval = _this.intervalService.getIntervalById(_this.defaultIntervalSelected, _this.machine.timezone);

            _this.intervalConfig = {
              list: _this.intervalService.getDefaultIntervals(_this.defaultIntervalListId, _this.machine.timezone),
              selected: _this.interval
            };

            if (_this.cachedIntervalId != null) _this.cacheService.set(_this.cachedIntervalId, _this.intervalConfig);

          } else {
            _this.intervalConfig = _this.cacheService.get(_this.cachedIntervalId);
            _this.interval = _this.cacheService.get(_this.cachedIntervalId).selected;
          }
        }

      } catch (error) { console.log(error) }

      if (_this.pollingTime > 0) _this.pollingSubscription = timer(0, _this.pollingTime).subscribe((count) => _this.mainRequest(_this, count));
      else _this.mainRequest(_this, 0);

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // Main request
  mainRequest(_this: any, count?: any) {
    try {

      let url = _this.endpointUrl + _this.machineId;

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      // if (_this.interval != null && _this.interval.enabledPolling) {
      //   _this.interval = _this.intervalService.getIntervalById(_this.intervalConfig.selected.id, _this.machine.timezone);
      //   _this.intervalConfig.selected = JSON.parse(JSON.stringify(_this.interval));
      // }

      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone,
      };

      if (_this.interval != null && !_this.interval.enabledPolling) query.to = _this.interval.end;

      if (_this.availableMachines != null) payload.machineList = _this.availableMachines?.list;
      else if (_this.machine.machineReference) query.machineId = _this.machine.machineReference;

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingSubscription)))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.dashboardData = {
              removeAnimations: count > 0,
              list: _this.standardParsingService?.[_this.parsingFunction](data.body, _this.machineId, _this.machine, _this.additionalParseTableConfig),
            };

            _this.dispatcherService.getDispatch(_this, 300);

            if (count == 0) _this.filterButtons = _this.filterService.buildFilterButtons(_this,_this.appConfig?.[_this.sectionName]?.[_this.tabName]?.additionalFilterButtons);

            _this.filterElements(_this);

          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  onSwitchChange(value: boolean) {
    this.switch.checked = value;
    this.filterElements();
  }

  filterElements() {

    let filterVariables = this.appConfig?.[this.sectionName]?.[this.tabName]?.filterVariables ?? this.defaultFilterVariables;

    let filtered: any = this.clonerService.deepClone(this.dashboardData.list);

    if (this.switch?.checked) {
      try { filtered = this.dashboardData.list.filter((x: any) => x.isActive) }
      catch (error) { console.log(error) }
    }

    if (this.searchElements != null && this.searchElements != '') {
      try { filtered = this.filterService.filterBySearch(filtered, this.searchElements, filterVariables); }
      catch (error) { console.log(error) }
    }

    if (this.filterButtons?.length > 0) {
      try { this.filterButtons.forEach((filterButton: any) => filtered = filtered.filter((data: any) => filterButton.options.filter((opt: any) => opt.selected).some((opt: any) => String(data[filterButton.variable]) == (String(opt.id))))) }
      catch (error) { console.log(error) }
    }

    if (this.sliderConf?.min != null && this.sliderConf?.max != null) {
      try { filtered = filtered.filter((x: any) => x.timeStartHours >= this.sliderConf.min && x.timeEndHours <= this.sliderConf.max) }
      catch (error) { console.log(error) }
    }

    this.dashboardData.table = filtered;

    let standardStatistics = this.addStandardStatisticsToDashboard ? this.internalDataService.addStandardStatistics(filtered, this.interval?.numberOfDays) ?? {} : {};

    this.completeDashboardConfig = {
      dashboardData: Object.assign(this.clonerService.deepClone(this.dashboardData), standardStatistics),
      machineProfile: this.machine.profile,
      dashboardConfig: this.dashboardConfig,
      aggregations: this.aggregations,
    };

  }

  initSlider(_this: any) {
    _this.sliderConf = {
      show: true,
      max: _this.sliderConf.max != null ? _this.sliderConf.max : 24 * 3600,
      min: _this.sliderConf.min != null && !isNaN(_this.sliderConf.min) ? _this.sliderConf.min : 0,
      options: {
        floor: 0,
        ceil: 24 * 3600,
        step: 300,
        translate: function (index: any) {
          try {
            return _this.filterService.parseTime(index, 's', 'HH:mm');
          } catch (error) {
            return index;
          }
        },
      },
      userChangeEnd: function () {
        _this.filterElements();
      }

    };

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // OPEN FILTER DIALOG

  openFilterDialog(button: any) { this.internalDataService.openFilterDialog(this, button) }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CHANGES

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY

  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingSubscription.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
  }

}