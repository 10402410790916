<div mat-dialog-title fxLayout="row" fxLayoutAlign="start center" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>

<mat-dialog-content>
  <div fxLayout="row wrap" fxLayoutAlign="center center" class="w100" style="padding: 16px;">
    <div *ngFor="let info of annealerData" [fxFlex]="'calc('+100/annealerData.length+'% - 6px)'"
      class="label-square-one" style="height: 20%" fxLayout="column" fxLayoutAlign="center center">
      <div>
        <p class="label" style="font-size: calc(0.5em + 1vh)">{{info.label | translate}}</p>
        <p class="value" style="font-size: calc(1em + 1vh)">
          <!-- <span><b>{{monitoringData[info.variable].toFixed(info.decimals != null ? info.decimals : 0) | placeholder: "-"}}</b></span> -->
          <span><b>{{ filterService.parseNumber(monitoringData[info.variable], null, info.decimals, info.multiplier) | placeholder: "-" }}</b></span>
          <span *ngIf="info.unit != null" style="margin-left: 0.25rem;"><b>{{info.unit}}</b></span>
        </p>
      </div>
    </div>
    <div *ngIf="plotState == 1" id="annealer" style="width: 100%; height: 80%;"></div>

    <div *ngIf="plotState == 2" class="w100" fxLayoutAlign="center center"  style="height: 80%">
      <div class="not-found w100 h100" fxLayout="column" fxLayoutAlign="center center">
        <span>{{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}</span>
      </div>
    </div>

    <div *ngIf="plotState == 0" class="w100" style="height: 80%">
      <div fxLayout="row" fxLayoutAlign="center center" class="w100 h100" >
        <ff-loading-card></ff-loading-card>
      </div>
    </div>
  </div>
</mat-dialog-content>