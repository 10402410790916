<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < 5 || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar -->
    <div *ngIf="pageState.value >= 5" class="ff-toolbar pb-3" fxLayout="column" fxLayoutAlign="center start"
      fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <!-- slider -->
      <div *ngIf="sliderConf?.show" fxLayout="row" fxLayoutAlign="start center" class="ngx-slider-container">
        <ngx-slider [(value)]="sliderConf.min" [(highValue)]="sliderConf.max" [options]="sliderConf.options"
          (userChangeEnd)="sliderConf.userChangeEnd($event)"></ngx-slider>
      </div>

      <span fxFlex></span>

      <!-- intervals -->
      <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

    </div>

    <!-- toolbar -->
    <div *ngIf="pageState.value >= 5" class="ff-toolbar py-3" fxLayout="column" fxLayoutAlign="center start"
      fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <!-- switch -->
      <div *ngIf="switch != null" fxLayout="row" fxLayoutAlign="center center" class="switch-container">
        <span marginRight>{{ switch?.label | translate }}</span>
        <ui-switch [checked]="switch?.checked" (change)="onSwitchChange($event)"
          [disabled]="dashboardData?.list?.length == 0" checkedLabel="{{switch?.checkedLabel}}"
          uncheckedLabel="{{switch?.uncheckedLabel}}">
        </ui-switch>
      </div>

      <span *ngIf="switch != null" fxFlex></span>

      <!-- aggregations -->
      <ff-aggregations-buttons [referenceComponent]="this" [aggregationButtons]="aggregations">
      </ff-aggregations-buttons>

      <!-- filter buttons -->
      <ff-filter-buttons [filterButtons]="filterButtons" (selected)="filterElements($event)"
        [ngStyle.gt-sm]="{'margin-left': aggregations?.length > 0 ? '8px' : '0'}"
        [ngStyle.lt-md]="{'margin-top': aggregations?.length > 0 ? '8px' : '0'}">
      </ff-filter-buttons>

      <span *ngIf="switch == null" fxFlex></span>

      <!-- search -->
      <span style="width: 100px;"></span>
      <div class="search-input h-auto" fxLayout="row" fxLayoutAlign="start center" style="min-width: 300px;">
        <mat-icon class="icon">search</mat-icon>
        <input type="text" [(ngModel)]="searchElements" (keyup)="filterElements()"
          placeholder="{{'GLOBAL.SEARCH'|translate}}">
      </div>

    </div>

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 6" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <ff-dashboard *ngIf="dashboardData != null && pageState.value >= 6"
      [completeDashboardConfig]="completeDashboardConfig">
    </ff-dashboard>

  </div>

</div>