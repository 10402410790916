import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { MachineComponentSelectionDialogComponent } from 'src/app/components/machine-component-selection-dialog/machine-component-selection-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';


@Component({
  selector: 'app-hm-grid',
  templateUrl: './hm-grid.component.html',
  styleUrls: ['./hm-grid.component.scss']
})
export class HmGridComponent implements OnInit, OnDestroy {

  public cachedIntervalId: any = "intervalHM";

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public isMobile: any;
  public isSmThanTablet: any;

  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public pollingTime: any;
  public pollingEvents: any;

  public maxStringsLength: any;

  public aggrDropdown: any = null;

  public aggregations: any;
  public aggregationsPayload: any;
  public aggregationsCustom: any;

  public excludeLine: boolean = false;

  public interval: any;
  public intervalConfig: any;

  public sectionName: any = 'healthMonitoring';

  public healthData: any = {
    list: [],
    filtered: []
  };
  public cardGap: any = '8px';
  public flexWidth: any = 50;

  public componentsHealthConfig: any = [
    {
      id: 0,
      label: "HEALTH_MONITORING.NOT_ANOMALOUS",
      class: "md-green-i",
      anomalyClass: "md-green-i",
      icon: {
        type: "icon",
        icon: "check"
      }
    },
    {
      id: 1,
      label: "HEALTH_MONITORING.ANOMALOUS",
      class: "md-red-i",
      anomalyClass: "md-red-i",
      icon: {
        type: "icon",
        icon: "close"
      }
    },
    {
      id: 2,
      label: "HEALTH_MONITORING.NOT_EVALUATED",
      class: "md-gray-i",
      anomalyClass: "md-gray-i",
      icon: {
        type: "icon",
        icon: "remove"
      }
    }
  ];
  public componentsConfig: any;
  public componentsConfigFilter: any;

  dashboardName: any = "health-card";
  public dashboardConfig: any = {
    "gap": "8px",
    "widgets": [
      {
        "type": "ff-gauge",
        "flex": 40,
        "config": {
          "label": "HEALTH_MONITORING.HEALTH",
          "variable": "health",
          "height": 120,
          "strokeWidth": 12,
          "strokeFill": "white",
          "linecap": "round",
          "percentageFillCircle": 0.75,
        }
      },
      {
        "type": "ff-plotly-chart",
        "flex": 60,
        "customHeight": "120px",
        "customMinHeight": "120px",
        "hideMobile": true,
        "config": {
          "plotDataAttribute": "plotData"
        }
      },
      {
        "type": "ff-value",
        "subtype": "row-icon-label-value",
        "class": "md-light-gray",
        "flex": 50,
        "config": [
          {
            "variable": "totalData",
            "label": "HEALTH_MONITORING.CYCLES",
          }
        ]
      },
      {
        "type": "ff-value",
        "subtype": "row-icon-label-value",
        "changeableClass": true,
        "class": "md-light-gray",
        "flex": 50,
        "config": [
          {
            "variable": "anomalousData",
            "label": "HEALTH_MONITORING.ANOMALIES",
          }
        ]
      },
    ]
  };

  public lineMode: any = true;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getComponentsConfig, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getHealthMonitoringPolling, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public mobile: MobileService,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    private router: Router,
  ) {

    this.mobile.mobileListener.subscribe((value) => {
      this.isMobile = value.isMobile
      this.isSmThanTablet = value.isSmThanTablet
    })
    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.flexWidth = this.appConfig?.healthMonitoring?.flexWidth ?? this.flexWidth;

    this.breadcrumb = ['HEALTH_MONITORING.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig?.[this.sectionName]?.polling != null ? this.appConfig[this.sectionName].polling : 0;
    this.pollingEvents = Subscription;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO
  getAssetInfo(_this: any) {

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET COMPONENTS CONFIG
  getComponentsConfig(_this: any) {

    _this.lineMode = Object.keys(_this.machine.machines ?? {})?.length > 0;

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getComponentsConfig(_this, _this.machineId);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getHealthMonitoringPolling, this.getHealthMonitoring, this.machine.timezone) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET HEALTH MONITORING POLLING
  getHealthMonitoringPolling(_this: any) {

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {

      if (_this.cacheService.get(_this.cachedIntervalId) == null) {

        _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.cacheService.set(_this.cachedIntervalId, _this.intervalConfig);

      } else {
        _this.intervalConfig = _this.cacheService.get(_this.cachedIntervalId);
        _this.interval = _this.cacheService.get(_this.cachedIntervalId).selected;
      }

      let c_componentsConfig = _this.cacheService.get("HMComponentsConfig");
      if (c_componentsConfig?.machineId == _this.machineId) _this.componentsConfig = c_componentsConfig.value;


      if (_this.machine.profile?.additionalHMConfig?.hideInHealthMonitoringGrid != null) {
        _this.componentsConfig = _this.hideComponentsFromMachineProfile(_this.componentsConfig, _this.machine.profile.additionalHMConfig.hideInHealthMonitoringGrid);
      }

      if (_this.componentsConfigFilter == null) _this.componentsConfigFilter = _this.createComponentsConfigFilter(_this.componentsConfig);

      if (_this.pollingTime > 0) _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => _this.getHealthMonitoring(_this, count));
      else _this.getHealthMonitoring(_this, 0);

    } catch (error) {
      let errorData = {
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getHealthMonitoring(_this: any, count?: any) {

    try {

      let url = '/apif/health-monitoring/' + _this.machineId;

      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone,
      };

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      let payload = {};

      if (_this.lineMode) payload = _this.machine.machines;
      else payload = _this.machine.componentsAD ?? _this.machine.components;

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.healthData.list = _this.parseData(_this.clonerService.deepClone(data.body));
            _this.filterData();

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        origin: "Front End",
        module: _this.sectionName,
        function: "getHealthMonitoring",
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  hideComponentsFromMachineProfile(componentsConfig, hideList) {

    let cc: any = this.clonerService.deepClone(componentsConfig);
    let lineMode = Object.keys(this.machine.machines ?? {})?.length > 0;

    if (lineMode) {
      cc = cc.reduce((machines, machine) => {

        machine.components = (machine.components ?? []).reduce((comps, comp) => {
          if (!hideList.includes(comp?.componentId)) comps.push(comp);
          return comps;
        }, []);

        if (machine.components.length > 0) machines.push(machine);
        return machines;
      }, []);
    }
    else {
      cc = cc.reduce((acc, comp) => {
        if (!hideList.includes(comp?.componentId)) acc.push(comp);
        return acc;
      }, []);
    }

    return cc;
  }

  // PARSE DATA
  parseData(data: any) {
    if (data != null && Array.isArray(data) && data.length > 0) {

      // COMPONENTS HEALTH CONFIG
      let componentsHealthConfig = this.machine.profile.componentsHealth ?? this.componentsHealthConfig;

      data.forEach((comp: any) => {

        // let numbers = [0, 1, 2];
        // comp.anomalyType = numbers[Math.floor(Math.random() * numbers.length)];
        comp.anomalyType = comp.anomalyType ?? (!comp.anomalyDetection ? 2 : (comp.health > comp.threshold ? 0 : 1));

        // if (comp.componentId == 'comp2') {
        //   let i = 0;
        //   comp.anomalyType = 1;
        //   comp.anomalousData = 5102;
        //   comp.health = 0.42;
        //   comp.values = comp.values.map((x: any) => {
        //     i++;
        //     return Math.random() * 0.8;
        //   });
        // }

        comp.anomalyIndex = comp.anomalyPerc != null ? (comp.anomalyPerc > 0.1 ? 1 : 0) : 2;

        let compAnomalyIdx = componentsHealthConfig.findIndex((x: any) => x.id == comp.anomalyIndex);
        if (compAnomalyIdx != -1) comp.anomalyClass = componentsHealthConfig[compAnomalyIdx].anomalyClass;

        let compTypeIdx = componentsHealthConfig.findIndex((x: any) => x.id == comp.anomalyType);
        if (compTypeIdx != -1) comp.icon = this.clonerService.deepClone(componentsHealthConfig[compTypeIdx]);

        comp = Object.assign(comp, this.internalDataService.getComponentConfig(this.componentsConfig, comp.machineId, comp.componentId, this.machineId));

        comp.clickable = false;
        comp.urlQueryParams = { componentId: comp.componentId, machineRefId: comp.machineId };

        if (comp.anomalyDetection && !this.isMobile) {
          if (this.cacheService.get("user")?.oauth) {

            let permissions = [
              "mat-hm-condition",
              "mat-hm-continuous",
              "mat-hm-exploration",
              "mat-hm-traceability",
              "mat-hm-trend",
            ];

            if (permissions?.some(role => this.cacheService.get("user").roles?.includes(role))) {
              comp.clickable = true
            }

          }
          else comp.clickable = true;
        }

        // console.log({ comp });

      });
      return data;
    }
    return [];
  }

  navigateToHMDetail(comp) {
    if (!comp.clickable) return;

    if (comp.anomalyDetection && !this.isMobile) {

      this.internalDataService.setHMComponentSelected({
        machineRefId: comp.machineId,
        componentId: comp.componentId,
      });

      this.router.navigate([this.machineId + '/health-monitoring/health-traceability'], {
        queryParams: comp.urlQueryParams
      });
    }
  }

  // FILTER DATA
  filterData() {

    let filtered: any = [];

    if (this.healthData.list != null && Array.isArray(this.healthData.list) && this.healthData.list.length > 0) {

      filtered = this.clonerService.deepClone(this.healthData.list);

      filtered = filtered.filter((comp: any) => {
        if (this.componentsConfigFilter.length > 0) {
          return this.componentsConfigFilter.findIndex((x: any) => x.machineId == comp.machineId && x.componentId == comp.componentId) != -1;
        }
        return true;
      });
    }

    this.healthData.filtered = filtered;

  }

  // COMPONENTS SELECTION DIALOG
  openComponentSelectionDialog() {

    let componentsConfig = this.clonerService.deepClone(this.componentsConfig);

    const variablesDialog = this.dialog.open(MachineComponentSelectionDialogComponent,
      {
        panelClass: 'ff-dialog',
        width: '40%',
        data: {
          title: this.translate.instant(this.lineMode ? "HEALTH_MONITORING.MACHINE_COMPONENT_SELECTION" : "HEALTH_MONITORING.COMPONENT_SELECTION"),
          list: componentsConfig,
          lineMode: this.lineMode,
        },
      });

    variablesDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {

        this.componentsConfig = this.clonerService.deepClone(result.list);

        this.cacheService.set("HMComponentsConfig", {
          machineId: this.machineId,
          value: this.componentsConfig
        });

        try {

          this.componentsConfigFilter = this.createComponentsConfigFilter(this.componentsConfig);
          this.filterData();

        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  createComponentsConfigFilter(elem: any) {

    if (this.lineMode) {
      if (elem?.length > 0) {

        let componentsConfigFilter: any = this.clonerService.deepClone(elem);
        let finalConfig: any = [];
        componentsConfigFilter.filter((x: any) => x.show).forEach((machine: any) => {
          if (machine.hasOwnProperty('components') && machine.components.length > 0) {
            machine.components.filter((x: any) => x.show).forEach((comp: any) => {
              finalConfig.push({
                machineId: machine.machineId,
                componentId: comp.componentId,
              });
            });
          }
        });
        return finalConfig;
      }
      return []
    }

    else {
      let finalConfig = (elem ?? []).reduce((acc, comp) => {
        if (comp.show) acc.push({
          componentId: comp.componentId
        })
        return acc;
      }, []);

      return finalConfig;
    }
  }

  async getDashboard() {
    try {

      let url = '/apif/get-custom-file';
      let dashboardName = 'dashboard-' + this.dashboardName + '.json';

      let query: any = {
        machineId: this.machineId,
        fileName: dashboardName,
        fromWeb: this.appInfo?.sources40F != null ? 1 : 0,
        source: this.appInfo?.sources40F ?? '/assets/config/',
      };

      try {
        let resp = await this.apiService.sendGetRequestAwait(url, query);
        this.dashboardConfig = resp?.body ?? this.dashboardConfig;
      } catch (error) {
        console.log(error);
      }

    } catch (error) { }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  async ngOnInit() {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);

    await this.getDashboard();

    this.dispatcherService.getDispatch(this, 300);
  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pageState.unsubscribe();
    } catch (error) { }
    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) { }
  }

}