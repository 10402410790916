<ng-container [ngTemplateOutlet]="InfoValue"
  [ngTemplateOutletContext]="{ info:info, item:item, nestedTableIndex:nestedTableIndex }">
</ng-container>

<!-- TEMPLATE InfoValue -->
<ng-template #InfoValue let-info="info" let-item="item" let-nestedTableIndex="nestedTableIndex">

  <div *ngIf="!info.type && (info.variable != 'description' || item.eventType == null)" fxLayout="row"
    fxLayoutAlign="start center">

    <div *ngIf="info.expander != null && info.expander == nestedTableIndex" fxLayout="row" fxLayoutAlign="start center"
      style="margin-right: 8px; margin-left: 2px;">
      <button *ngIf="item != null" class="rounded-button md-light-gray" (click)="toggleExpandRow(item)">
        <mat-icon class="icon transition-01" [ngClass]="{'arrow_upward-up': item.expandedNesting}">
          expand_more
        </mat-icon>
      </button>
    </div>

    <img *ngIf="info.imageVariable != null && item[info.imageVariable] != null"
      [src]="'assets/images/' + item[info.imageVariable]" style="height: 50px; border-radius: 12px;">

    <span>{{item[info.variable] | shorten: (isMobile ? 30 : 4000) | placeholder:(info.placeholder != null ?
      info.placeholder : '-') }}</span>
    <span *ngIf="info.unit != null && !info.unitInTitle">&nbsp;{{ info.unit | translate }}</span>
    <mat-icon *ngIf="info.icon != null && info.showLed" class="icon {{item.iconClass}}" style="margin-left: 8px;">lens
    </mat-icon>
  </div>

  <div *ngIf="info.type == 'advancedTranslate'" fxLayout="row" fxLayoutAlign="start center">

    <span>{{ advancedTranslate(info?.advancedTranslateConfig, item, machineId) }}</span>
    <span *ngIf="info.unit != null && !info.unitInTitle">&nbsp;{{ info.unit | translate }}</span>
    <mat-icon *ngIf="info.icon != null" class="icon {{item.iconClass}}" style="margin-left: 8px;">lens</mat-icon>
  </div>

  <div *ngIf="info.type == 'interval'">
    <span>{{ item.timeStartP != null ? item.timeStartP : filterService.parseMoment(item.timeStart, 'default') |
      placeholder }}</span><br>
    <span>{{ item.timeEndP != null ? item.timeEndP : filterService.parseMoment(item.timeEnd, 'default') | placeholder
      }}</span>
  </div>

  <div *ngIf="info.type == 'date'">
    <span>{{ item[info.variable] != null ? filterService.parseMoment(item[info.variable], (info.format != null ?
      info.format : 'default')) : null | placeholder }}</span><br>
  </div>

  <div *ngIf="info.type == 'lastConnection'">
    <div fxLayout="row" fxLayoutAlign="start center" class="last-update"
      [ngClass]="getLastConnectionAttribute(info, item, 'class')">

      <mat-icon class="icon left">{{ getLastConnectionAttribute(info, item, 'icon') }}</mat-icon>
      <span>{{ item[info.variable] != null ? filterService.parseMoment(item[info.variable], (info.format != null ?
        info.format : 'default')) : ('MACHINE_STATES.NEVER_CONNECTED' | translate) | placeholder }}</span>

    </div>
  </div>

  <div *ngIf="info.type == 'configFromProfile'">
    <span>{{ mapFromProfile(info, item) }}</span>
    <span *ngIf="info.unit != null && !info.unitInTitle">&nbsp;{{ info.unit | translate }}</span>
  </div>

  <div *ngIf="info.type == 'value'">
    <span>{{ item[info.variable] != null ? filterService.parseGaugeValue(item[info.variable], info.decimals,
      info.multiplier) : '-' }}</span>
    <span *ngIf="info.unit != null && !info.unitInTitle">&nbsp;{{ info.unit | translate }}</span>
  </div>

  <div *ngIf="info.type == 'time'">
    <span>{{ item[info.variable] != null ? filterService.parseTime(item[info.variable], 's', (info.format != null ?
      info.format : 'HH:mm:ss')) : '-' }}</span>
    <span *ngIf="info.unit != null && !info.unitInTitle">&nbsp;{{ info.unit | translate }}</span>
  </div>

  <div *ngIf="info.type == 'progressBar'">

    <kpi-bar
      *ngIf="info.fixedColor == null && (info.placeholder == null || (info.placeholder != null && item[info.variable] != null))"
      [kpiProps]="{ 'value': item[info.variable], 'hideLabel': true }">
    </kpi-bar>

    <kpi-bar
      *ngIf="info.fixedColor != null && (info.placeholder == null || (info.placeholder != null && item[info.variable] != null))"
      [kpiProps]="{ 'value': item[info.variable], 'hideLabel': true, fixedColor: info.fixedColor }">
    </kpi-bar>

  </div>

  <div *ngIf="info.type == 'icon' && info.icon != null && info.icon.icon != null">
    <div class="{{info.class}} fixed32" [ngClass]="getDynamicIconAttribute(info, item, 'class')"
      matTooltip="{{ getDynamicIconAttribute(info, item, 'label') | translate }}">

      <ff-icon [icon]="info.icon" [ngStyle]="{'color': getDynamicIconAttribute(info, item, 'color')}"></ff-icon>

    </div>
  </div>

  <div *ngIf="info.type == 'link'" matTooltip="{{ 'CYCLES.NAVIGATE_TO_item_DETAIL' | translate }}" class="clickable"
    [routerLink]="item[info.variable] !=null ? item[info.variable] : item.urlArray">
    <mat-icon class="icon left">open_in_new</mat-icon>
  </div>

  <div *ngIf="info.type == 'checkbox'" fxLayout="row" fxLayoutAlign="start center" class="clickable"
    (click)="item[info.variable] = !item[info.variable];">

    <mat-icon *ngIf="item[info.variable]" svgIcon="radio_button" class="icon small left radio_button">
    </mat-icon>
    <mat-icon *ngIf="!item[info.variable]" svgIcon="radio_button_disabled"
      class="icon small left radio_button_disabled">
    </mat-icon>

  </div>

  <div *ngIf="info.type == 'image'" fxLayout="row" fxLayoutAlign="start center">
    <img *ngIf="item[info.variable] != null" [src]="'assets/images/' + item[info.variable]"
      style="height: 50px; border-radius: 12px;">
  </div>

  <div *ngIf="info.type == 'table' && item[info.variable] !=null && item[info.variable].length > 0" class="td-table">
    <table class="table-in-td">
      <tr *ngFor="let item of item[info.variable]; let i=index">
        <td *ngIf="item !=null" style="padding: 0 0.25rem !important;">
          <span>{{item.label | translate}}</span>
        </td>
        <td *ngIf="item !=null" style="padding: 0 0.25rem !important;">
          <span>{{item.value}}</span>
          <span *ngIf="item.unit !=null">&nbsp;{{item.unit}}</span>
        </td>
      </tr>
    </table>
  </div>

  <!-- LIST COLUMN -->
  <span *ngIf="(item.eventType == 'colorList' || item.eventType == 'list') && info.variable == 'description'"
    ng-class="{'minWidthListLarge': mobile()}" class="{{info.classes}}">
    <ul class="ff-ul">
      <div *ngIf="item.eventType == 'colorList'">
        <li *ngFor="let state of item.description" style="padding: 4px 0;">
          <span class="color-square" [style.background-color]="state.color"
            style="margin: 0 8px 0 0; padding: 0 9px;"></span>
          <span>{{ state.value | translate }}</span>
        </li>
      </div>
      <div *ngIf="item.eventType == 'list'">
        <li *ngFor="let state of item.description">
          <span>{{ state }}</span>
        </li>
      </div>
    </ul>
  </span>

  <span *ngIf="info.type == 'timeState'">
    <div *ngIf="item[info.variable] != null" fxLayout="row" fxLayoutAlign="center center"
      [style.background-color]="item[info.variable].bgColor!= null ? item[info.variable].bgColor : '#FFFFFF'"
      [style.color]="item[info.variable].fontColor != null ? item[info.variable].fontColor : '#373A3F'"
      class="state-container">
      <span>{{item[info.variable]?.value | translate }}</span>
    </div>
  </span>

  <span *ngIf="info.type == 'stateUI'">
    <div *ngIf="item[info.variable] != null" fxLayout="row" fxLayoutAlign="center center"
      class="state-container {{item[info.variable]?.class}}">
      <ff-icon *ngIf="item[info.variable]?.icon != null" [icon]="item[info.variable]?.icon" left></ff-icon>
      <span>{{item[info.variable]?.title | translate }}</span>
    </div>
  </span>

  <div class="td-list"
    *ngIf="info.type == 'listToSplit' && item[info.variable] != null && item[info.variable]?.split(info.variableToSplit)?.length > 0">
    <ul style="list-style: none; padding: 0;">
      <li *ngFor="let li of item[info.variable]?.split(info.variableToSplit)">
        <span>{{ li }}</span>
      </li>
    </ul>
  </div>

  <div *ngIf="info.type == 'list' && item[info.variable] !=null && item[info.variable].length > 0" class="td-list">
    <ul style="list-style: none; padding: 0;">
      <li *ngFor="let li of item[info.variable]; let i=index">
        <!-- <div *ngIf="item[info.variable + '_max'] && i < item[info.variable + '_max']"> -->
        <span *ngIf="li != null && li.label != null"><strong>{{li.label | translate}}: </strong></span>
        <span *ngIf="li != null && li.value !=null">{{li.value}}</span>
        <span *ngIf="li != null && li.label == null && li.value == null">{{ li }}</span>
        <span *ngIf="li != null && li.unit !=null">&nbsp;{{li.unit}}</span>
        <!-- </div> -->
      </li>
    </ul>
  </div>

  <div class="input-box" *ngIf="info.type == 'inputText'"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <input matInput class="ff-input text" type="text" [matAutocomplete]="auto" [(ngModel)]="item[info.variable]">
    <!-- <span *ngIf="info.hasOwnProperty('unit')" matSuffix>{{ info.unit }}</span> -->
    <mat-autocomplete #auto="matAutocomplete">
      <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let option of info.autocomplete; let i = index"
        [attr.data-index]="i">
        <mat-option style="padding-right: 0px" fxFlex [value]="option">
          <div>{{ option | translate }}</div>
        </mat-option>
        <!-- <span fxFlex></span> -->
      </div>
    </mat-autocomplete>
  </div>

  <div *ngIf="info.type == 'led'">
    <mat-icon class="icon"
      [ngStyle]="{'color': item?.[info.variable] ? (info.trueColor ?? '#62E39D') : (info.falseColor ?? '#FF5757') }">
      lens
    </mat-icon>
  </div>

  <div *ngIf="info.type == 'inputNum'" class="input-box"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <input matInput class="ff-input number" type="number" [min]="0" [(ngModel)]="item[info.variable]"
      (keyup)="onInputTagChange(tableConfig.rowsFiltered)">
  </div>
  <div *ngIf="info.type == 'inputDateTime'">
    <input matInput class="ff-input datetime" type="datetime-local" [(ngModel)]="item[info.variable]">
  </div>
  <div *ngIf="info.type == 'inputDuration'">
    <ff-duration-input format="HH:mm" unit="s" [(value)]="item[info.variable]"></ff-duration-input>
  </div>

</ng-template>