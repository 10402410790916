import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { MobileService } from 'src/app/services/mobile.service';
import { MachinesFiltersPipe } from '../machines.filters';

@Component({
  selector: 'homepage-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})

export class HomepageListComponent implements OnInit, OnChanges, OnDestroy {

  public appConfig: any;

  cardStyle: any;
  cardConfig: any = { config: {} };
  machineProfiles: any = [];
  sidenavItemPC: any;

  showCalendarSwitch: Boolean = false;

  machineInfos: any = [];
  machineDatas: any = [];
  otherData: any = [];
  worstComponentsInfos: any = [];
  anomalyInfos: any = [];
  oeeInfos: any = [];
  productivity: any = {};

  machineFiltersPipe: any = new MachinesFiltersPipe();
  public homepageDataSub: any;
  public mFilters: any;

  public machinesListSub: Subscription;
  public machines: any;

  public mobileListener: Subscription;
  public isMobile: any;
  public errorDataMobile: any;
  avoidLoopProfiles: any = false;

  constructor(
    private internalDataService: InternalDataService,
    public appConfigService: AppConfigService,
    public filterService: FiltersService,
    public mobile: MobileService,
    public cacheService: CacheService,
    private router: Router,
    public translate: FfTranslateService
  ) {

    this.appConfig = this.appConfigService.getAppConfig;
    this.cardStyle = this.appConfig.homepage.gridCards.cardStyle != null ? this.appConfig.homepage.gridCards.cardStyle : 0;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })

    this.sidenavItemPC = this.appConfigService.getSidenavItems.find(sItem => (sItem.id == 'planning-calendar'));
    this.showCalendarSwitch = this.appConfig?.homepage?.showCalendarSwitch && this.sidenavItemPC != null && !this.sidenavItemPC.disabled;
    // card-style != 0
    this.machineInfos = this.appConfig.homepage.machineInfos;
    this.machineDatas = this.appConfig.homepage?.productionData ?? this.appConfig.homepage.machineDatas;
    this.worstComponentsInfos = this.appConfig.homepage.worstComponentsInfos;
    this.anomalyInfos = this.appConfig.homepage.anomalyInfos;
    this.oeeInfos = this.appConfig.homepage.oeeInfos;

    this.homepageDataSub = this.internalDataService.homepageData.subscribe(value => {
      this.mFilters = value.filters;
    });

    this.machinesListSub = this.internalDataService.machinesList.subscribe(value => {
      this.machines = value;
    });

  }

  trackById = (index, machine: any): string => {
    return machine.machineId;
  };

  selectMachine(machine: any) {
    this.internalDataService.setMachineSelected(machine);
  }

  differentMachineProfile(profile: any, machine: any) {
    let profileDef = this.machineProfiles.profiles.find((x: any) => x.id === profile.id);
    if (profileDef) return profileDef?.id == 'default' ? false : !profileDef?.machineIdList?.includes(machine.machineId);
    return true;
  }

  enableCalendarSwitch(machineId) {

    try {
      // this.enableCalendarSwitch = sidenavItems.find(sItem => (sItem.id == 'planning-calendar' && !sItem.disabled)) != null
      // console.log(this.sidenavItemPC.enabledForAssets, this.showCalendarSwitch)
      if (this.showCalendarSwitch) {

        // console.log(this.sidenavItemPC.enabledForAssets, machineId)
        if (Array.isArray(this.sidenavItemPC.enabledForAssets) && !this.sidenavItemPC.enabledForAssets.includes(machineId)) {
          return false;
        } else if (Array.isArray(this.sidenavItemPC.disabledForAssets) && this.sidenavItemPC.disabledForAssets.includes(machineId)) {
          return false;
        }
        // console.log('enabled')
        return true;
      }
      return false;
    } catch (error) {
      console.log(error);
    }
  }

  isEnabledMachineCalendar(machineId) {
    try {
      return this.filterService.isActiveCalendar(machineId);
    } catch (error) {
      console.log(error);
    }
  }

  public toggleEnableCalendarAsset(machine, value) {

    try { localStorage.setItem(machine.machineId + '_calendar', value) }
    catch (error) { console.log(error) }


    if (value == false) {
      try { if (this.cacheService.get("intervalAggregation")?.id == 'shift') this.cacheService.set("intervalAggregation", null) }
      catch (error) { console.log(error) }
    }

    this.router.navigateByUrl(this.router.url);
  }

  emptyProfileCard(profile: any) {
    let profileDef = this.machineProfiles.profiles.find((x: any) => x.id === profile.id);
    if (profileDef) {
      for (let machine of this.machineFiltersPipe.transform(this.machines, this.mFilters)) {
        if (profileDef?.machineIdList?.includes(machine.machineId) ?? (profileDef?.id == 'default' && Object.keys(profileDef?.value ?? {}).length)) return false;
      }
    }
    return true;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
  }

  ngOnInit(): void {
    this.avoidLoopProfiles = this.appConfig?.homepage?.avoidLoopProfiles;
  }

  ngOnChanges() { }

  ngOnDestroy() {
    try { this.homepageDataSub.unsubscribe() } catch (error) { }
    try { this.machinesListSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) {

    }
  }
}