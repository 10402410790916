import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert-invalid',
  templateUrl: './alert-invalid.component.html',
  styleUrls: ['./alert-invalid.component.scss']
})
export class AlertInvalidComponent {
  @Input() date: any;
  @Input() trafila: any;
  @Input() shift: any;
  @Input() lotChangeTime: any;
  @Input() product: any;
  @Input() lot: any;
  @Input() bigBag: any;
  @Input() dayTime: any;
  @Input() outcome: any;
  @Output() closeInvalid = new EventEmitter<void>()
  @Output() confirmInvalid = new EventEmitter<void>()

  onClose() {
    this.closeInvalid.emit();
  }

  onConfirm() {
    this.confirmInvalid.emit();
  }
}
