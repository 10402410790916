import { Injectable } from "@angular/core";
import { BehaviorSubject, fromEvent, Observable } from "rxjs";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MobileService {
  public mobileListener: Observable<any>

  constructor() {
    let innerWidth$ = new BehaviorSubject(getInnerWidth())
    this.mobileListener = (innerWidth$ as Observable<any>).pipe(distinctUntilChanged())

    fromEvent(window, 'resize').pipe(map(getInnerWidth)).subscribe(innerWidth$)
  }
}

function getInnerWidth() {
  console.log('resize detected')
  return {
    isMobile: window.innerWidth <= 959,
    isTablet: window.innerWidth < 1440 && window.innerWidth >= 1024,
    isSmThanTablet: window.innerWidth < 1440
  }
}