<mat-card class="rba-container" style="margin-bottom: 20px;">

  <div class="title">
    <span>{{ mailingListTitle | translate}}</span>
    <span class="number" *ngIf="mailingList != null && mailingList.length > 0">
      &nbsp;({{ mailingList.length }})
    </span>

    <span fxFlex></span>

    <button mat-button *ngIf="mailingList != null && mailingList.length < 6" class="md-gray rounded-button elliptic"
      (click)="addMail()" [disabled]="!isAllowedUserWrite" fxLayout="row" fxLayoutAlign="space-evenly center"
      #tooltip="matTooltip" matTooltip="{{ 'RBA.ADD_MAIL' | translate }}" matTooltipPosition="above">
      <mat-icon class="icon side">add</mat-icon>
    </button>

    <!-- <button mat-button *ngIf="isAllowedUser" class="filter-button md-primary rounded-button" -->
    <button style="margin-left: 8px;" mat-button *ngIf="isAllowedUser" class="md-primary rounded-button elliptic"
      (click)="sendUpdatedMailingList()" [disabled]="checkUpdates() || !isAllowedUserWrite" fxLayout="row"
      matTooltip="{{ 'GLOBAL.SAVE' | translate }}" fxLayoutAlign="center center">
      <mat-icon class="icon" aria-label="save">save</mat-icon>
      <!-- <span>{{'GLOBAL.SAVE'|translate}}</span> -->
    </button>

  </div>

  <div class="subtitle">
    <span *ngIf="mailingList != null && mailingList.length > 0">
      {{ mailingListDescription | translate}}.
    </span>
    <span *ngIf="mailingList == null || mailingList.length == 0">
      {{ noMailConfiguredText | translate}}!</span>
  </div>

  <mat-card-content>

    <div fxLayout="row wrap" *ngIf="mailingList != null && mailingList.length > 0" fxLayoutGap="16px">
      <div *ngFor="let mail of mailingList; let i = index" [fxFlex]="'calc(33.3% - 16px)'"
        class="single-event-container three" style="background-color: transparent !important;" fxLayout="row"
        fxLayoutAlign="start center">

        <input class="ff-input mail" [(ngModel)]="mail.value" type="email" required name="mail"
          [class.ff-disabled]="!isAllowedUserWrite || mail.immutable"
          [disabled]="!isAllowedUserWrite || mail.immutable">

        <button *ngIf="isAllowedUserWrite && !mail.immutable" mat-button class="md-red rounded-button elliptic"
          (click)="deleteMail(i)" fxLayout="row" fxLayoutAlign="space-evenly center" #tooltip="matTooltip"
          matTooltip="{{ 'RBA.DELETE_MAIL' | translate }}" matTooltipPosition="above"
          style="margin-left: 12px !important;">
          <mat-icon class="icon side">delete</mat-icon>
        </button>

      </div>
    </div>
  </mat-card-content>

</mat-card>