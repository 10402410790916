<div mat-dialog-title fxLayout="row" fxLayoutAlign="center center" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" aria-label="close">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography">


  <div fxLayout="column" style="padding: 0 8px;">

    <div fxLayout="row">
      <div fxLayout="column" style="padding: 12px 0;">

        <ff-icon *ngIf="isOperators" icon="warning" iconClass="md-orange"
          matTooltip="{{ 'CALENDAR.OVERWRITE_OPERATOR_LIST' | translate }}">
        </ff-icon>

        <div fxLayout="row" style="padding: 4px 0;">
          <strong><span>{{ 'CALENDAR.FROM' | translate }}:&nbsp;</span></strong>
          <span>{{selectedDaysCopy[0]}}</span>
        </div>

        <div fxLayout="row" style="padding: 4px 0;">
          <strong><span>{{ 'CALENDAR.TO' | translate }}:&nbsp;</span></strong>
          <span>{{selectedDaysCopy[selectedDaysCopy.length -1]}}</span>
        </div>

      </div>

    </div>

    <div fxLayout="row" style="padding: 8px 0;">
      <span class="md-headline-invert"><strong>{{ 'SHIFT_TEMPLATES.SHIFT_TEMPLATE_NAME' | translate }}:</strong></span>

      <span class="break-all" style="margin-left: 7px; color: #212121;">{{ currentTemplate.name }}
      </span>
      <span class="color-square" [ngStyle]="{'background-color': currentTemplate.color}" style="margin-left: 12px;">
      </span>
    </div>

    <table class="standard-table">
      <thead>
        <tr>
          <th *ngFor="let info of shiftTemplateInfos">
            <span>{{ info.label | translate }}</span>
          </th>
          <th>
            <span>{{ 'OPERATOR_IDS.TITLE' | translate }}</span>
          </th>
      </thead>
      <tbody>
        <tr *ngFor="let shift of currentTemplate.shifts">
          <td *ngFor="let info of shiftTemplateInfos">

            <!-- STANDARD COLUMN -->
            <span *ngIf="info.type == null" class="{{info.classes}}">{{ shift[info.variable] | placeholder:'-'}}
              <span *ngIf="info.suffix != null">{{info.suffix}}</span>
            </span>

          </td>
          <td class="input-reduced" fxLayout="column">

            <button *ngIf="shift.operatorIds == null || shift.operatorIds.length == 0" class="md-gray filter-button"
              (click)="addOperator(shift)" matTooltip="{{ 'OPERATOR_IDS.ADD_OPERATOR' | translate }}">
              <mat-icon class="icon side">add</mat-icon>
            </button>

            <div fxLayout="row" fxLayoutAlign="start center"
              *ngFor="let shiftOperator of shift.operatorIds; let i = index; let first = first; let last = last"
              [attr.data-index]="i">

              <div class="ff-input-select" style="margin: 4px; width: auto;">
                <mat-select [(value)]="shift.operatorIds[i]">
                  <mat-option *ngFor="let operator of operatorsList" [value]="operator"
                    [ngStyle]="{'display': shift.operatorIds.includes(operator) ? 'none' : 'block'}">
                    {{ operator }}
                  </mat-option>
                </mat-select>
              </div>

              <button class="md-red rounded-button" (click)="deleteOperator(shift, i)"
                matTooltip="{{ 'OPERATOR_IDS.REMOVE_OPERATOR' | translate }}">
                <mat-icon class="icon">delete</mat-icon>
              </button>

              <!-- <button style="margin-left: 4px;" [ngStyle]="{'opacity': last && shift.operatorIds.length < operatorsList.length ? 1 : 0, 
                  'pointer-events': last && shift.operatorIds.length < operatorsList.length ? 'auto' : 'none'}"
                class="md-gray rounded-button" (click)="addOperator(shift)"
                matTooltip="{{ 'OPERATOR_IDS.ADD_OPERATOR' | translate }}">
                <mat-icon class="icon side">add</mat-icon>
              </button> -->

            </div>

          </td>
        </tr>
      </tbody>
    </table>
  </div>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button mat-button (click)="closeDialog()" cdkFocusInitial class="filter-button" [disabled]="checkDisabledDialog()">{{
    'GLOBAL.SAVE' | translate }}</button>
</mat-dialog-actions>