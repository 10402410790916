<span *ngIf="widget.title" class="default-widget-title">{{ widget.title | translate }}</span>
<div class="w100 h100" fxLayout="column" fxLayoutAlign="space-around start">

  <div class="w100" fxLayout="row" fxLayoutAlign="start center">

    <div class="w100" fxLayout="row" fxLayoutAlign="space-around center">
      <div *ngIf="led.label != null" class="value">
        <span *ngIf="!led.value" style="text-transform: capitalize;" class="title fw-500">
          {{ 'GLOBAL.NOT' | checkTranslation:'NOT':false }}&nbsp;
        </span>
        <span class="title fw-500">{{ led.label | translate }}</span>
      </div>
    </div>

  </div>

</div>