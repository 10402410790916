import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'app-performance-settings',
  templateUrl: './performance-settings.component.html',
  styleUrls: ['./performance-settings.component.scss']
})
export class PerformanceSettingsComponent implements OnInit {

  public isAllowedUser: any = true;
  public isAllowedUserWrite: any = true;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public recipes: any;

  public tableConfig: any;
  public tableConfigDefault: any;

  public saveState: any;

  public mobileListener: Subscription;
  public errorDataMobile: any;
  public isMobile: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 4;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getMachineInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAggrList, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    private _snackbar: MatSnackBar,
    public mobile: MobileService
  ) {

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['PERFORMANCE_SETTINGS.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);
    this.tabs = [];

    this.recipes = {
      list: [],
      unchanged: []
    };

    this.tableConfig = {
      tableInfos: [],
      rows: [],
      rowsFiltered: [],
      // title: "PERFORMANCE_SETTINGS.DEFAULT_VALUE",
      cardFrame: true,
      completeCardFrame: true,
      paginator: null,
      sort: null
    };

    this.tableConfig.tableInfos = this.appConfig?.performanceSettings?.tableInfo ?? [];

    this.tableConfigDefault = {
      tableInfos: [],
      rows: [],
      rowsFiltered: [],
      // title: "PERFORMANCE_SETTINGS.DEFAULT_VALUE",
      cardFrame: true,
      completeCardFrame: true,
      paginator: null,
      sort: null
    };

    this.tableConfigDefault.tableInfos = this.appConfig?.performanceSettings?.tableInfo ?? [];

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile;
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })

    this.pageState.subscribe()
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // GET MACHINE INFO

  getMachineInfo(_this: any) {

    try {
      _this.isAllowedUserWrite = _this.internalDataService.getSpecificPermission("mat-performance-settings-write");
    } catch (error) { console.log(error) }

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(["mat-performance-settings-read", "mat-performance-settings-write"], 'or');
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedmachineId = _this.cacheService.get("machineId");
      if (isCachedmachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(null);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, null);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // GET AGGR LIST

  getAggrList(_this: any) {
    try {

      let selectedAggr = _this.machine?.profile?.settings?.production?.set?.coef;

      if (selectedAggr == null) {
        _this.internalDataService.openSnackBar(_this.translate.instant('PERFORMANCE_SETTINGS.NO_AGGR_CONFIGURED'), 'right', 'bottom', 60000, 'x', ["warning"]);
        _this.pageState.next(0);
        return;
      }

      let url = '/apif/list-aggr/' + _this.machineId + '/' + selectedAggr;

      let query = {
        tz: _this.machine.timezone
      }

      _this.apiService.sendGetRequest(url, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
        )
        .subscribe(
          (data: any) => {
            _this.recipes.list = _this.parseRecipes(data.body);

            _this.tableConfig.rows = _this.clonerService.deepClone(_this.recipes.list);
            _this.tableConfig = _this.clonerService.deepClone(_this.tableConfig);

            _this.tableConfigDefault.rows = _this.clonerService.deepClone(_this.parseDefault(data.body));
            _this.tableConfigDefault = _this.clonerService.deepClone(_this.tableConfigDefault);

            _this.dispatcherService.getDispatch(_this, 300)
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseRecipes(data) {

    let parsedData = data?.reduce((acc, val) => {
      let obj: any = { name: val };
      obj.value = this.filterService.convertUnit('m/s', this.machine?.settings?.production?.aggr?.[val]).value?.toFixed(2);
      acc.push(obj);
      return acc;
    }, []);

    try {
      Object.entries(this.machine?.settings?.production?.aggr ?? {})?.forEach(([k, v], index) => {
        if (parsedData?.find(x => x.name == k) == null) parsedData.push({
          name: k,
          value: this.filterService.convertUnit('m/s', v).value?.toFixed(2),
        });
      })
    } catch (error) { console.log(error) }

    console.log(parsedData);

    return parsedData;
  }

  parseDefault() {
    return [{
      name: this.translate.instant("GLOBAL.DEFAULT"),
      value: this.filterService.convertUnit('m/s', this.machine.settings?.production?.default).value?.toFixed(2),
    }];
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // UPDATE PERFORMANCE SETTINGS

  updatePerformanceSettings() {

    this.pageState.next(3);
    this.loadingData.message = this.translate.instant('PERFORMANCE_SETTINGS.UPDATING_SETTINGS');

    try {

      let variables = this.parsePerformanceSettingsPayload();

      let payload: any
      if (this.appConfig.MAT2) {
        payload = this.internalDataService.buildMachinePayload(this.machine);
        payload.settings.dynamics = variables;
      } else {
        payload = variables;
      }


      let url = '/apif/settings/' + this.machineId;

      console.log('payload', payload);
      let _this = this;

      _this.apiService.sendPutRequest(url, payload).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          _this.internalDataService.openSnackBar(_this.translate.instant('PERFORMANCE_SETTINGS.RESPONSE.FAIL'), 'right', 'bottom', 4000, '', ["fail"]);

          _this.pageState.next(5);
          return throwError('Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {

            _this.internalDataService.openSnackBar(_this.translate.instant('PERFORMANCE_SETTINGS.RESPONSE.SUCCESS'), 'right', 'bottom', 4000, '', ["success"]);

            window.location.reload();

          },
        );

    } catch (error) { console.log(error) }
  }

  parsePerformanceSettingsPayload() {

    let recipeDict = this.tableConfig?.rowsFiltered?.reduce((acc, val) => {
      if (val.value != null) acc[val.name] = this.filterService.convertUnit('m/s', parseFloat(val.value), true).value;
      return acc;
    }, {});

    return {
      production: {
        aggr: recipeDict,
        default: this.filterService.convertUnit('m/s', parseFloat(this.tableConfigDefault?.rowsFiltered?.[0]?.value), true).value
      },
    };
  }


  invalidFormCheck() {
    let rowsValues = []
    let rows: any = this.clonerService.deepClone(this.tableConfig.rows)
    rows.forEach((element: any) => {
      if (element.notCollapsed != null) delete element.notCollapsed
      Object.values(element).forEach((value) => { if (typeof value == 'number') rowsValues.push(value) })
      // console.log('rowsValues', rowsValues)
    });
    let min0 = rowsValues.some((value) => value < 0)
    if (this.saveState != 1 && !min0) return JSON.stringify(this.recipes.list) == JSON.stringify(rows)
    else return true
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // INIT

  ngOnInit() {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);
  }

  ngOnDestroy() {

    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
  }

}
