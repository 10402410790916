<div *ngIf="buttons?.length > 0" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="8px">
  <button *ngFor="let butt of buttons" (click)="buttonAction(butt)" [disabled]="butt.disabled" [ngClass]="butt.class"
    matTooltip="{{ butt.tooltip | translate }}" class="filter-button" matTooltipPosition="above" fxLayout="row"
    fxLayoutAlign="center center">

    <!-- icon -->
    <ff-icon *ngIf="butt?.icon" [icon]="butt.icon" left></ff-icon>

    <!-- Selection Label -->
    <span>{{ butt.label | translate }}</span>

  </button>
</div>