import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ff-health-bar',
  templateUrl: './health-bar.component.html',
  styleUrls: ['./health-bar.component.scss']
})
export class HealthBarComponent implements OnInit {

  @Input() widget: any;

  public bars: any;
  public borderRadius: any = 25;
  public marginAboveBelow: any = 16;
  public marginBetweenBars: any = 4;
  public barConfig: any = [
    {
      start: 0,
      end: 50,
      color: '#FF5757'
    },
    {
      start: 50,
      end: 75,
      color: '#FAA63E'
    },
    {
      start: 75,
      end: 100,
      color: '#62E39D'
    }
  ]

  constructor() { }

  ngOnInit(): void {

    // TODO: barConfig from widget

    let bars = [];
    this.barConfig.forEach((bar: any, index: any) => {
      bars.push({
        end: bar.end,
        start: bar.start,
        margin: index == 0 ? ('0 0 ' + this.marginAboveBelow + 'px 0') : ((index == this.barConfig.length - 1) ? (this.marginAboveBelow + 'px 0 0 0') : '0'),
        height: 'calc(' + (bar.end - bar.start) + "% - " + ((index == 0 || index == this.barConfig.length - 1) ? this.marginAboveBelow : this.marginBetweenBars * 2) + "px)",
        bottom: 'calc(' + bar.start + "% + " + ((index == 0 || index == this.barConfig.length - 1) ? 0 : this.marginBetweenBars) + "px)",
        color: bar.color,
      });
    });

    this.bars = bars;
  }

}
