<div class="card">

    <!-- <pre>{{ machineInfos.machineId }}</pre> -->
    <span class="title">
        {{ internalDataService.parseLabelWithAssetId("MACHINES", machineData.machineId, null, machineInfos.machineId) }}
    </span>

    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px" style="margin-top: 8px;">

        <div [fxFlex]="imageFlex">
            <img *ngIf="!noImage" [src]="image" (error)="noImage = true">
            <span *ngIf="noImage" style="text-align: center;">
                {{ 'GLOBAL.NO_IMAGE_AVAILABLE' | translate }}
            </span>
        </div>

        <div *ngIf="dashboardConfig && machineData" [fxFlex]="100-imageFlex" class="w100" fxLayout.lt-sm="column"
            fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap="8px grid"
            fxLayoutGap.gt-sm="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
            <ff-widget *ngFor="let w of dashboardConfig.widgets"
                [fxFlex.gt-sm]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
                [fxShow.lt-md]="!w.hideMobile" [fxFlex.sm]="'calc(' + w.mobileFlex + '% - 8px )'"
                [fxFlexOrder.sm]="w.mobileOrder" [widget]="{ 
                    type: w.type, 
                    subtype: w.subtype, 
                    data: machineData, 
                    machineProfile: machineInfos?.profile, 
                    config: w.config, 
                    title: w.title
                  }">
            </ff-widget>
        </div>
    </div>
</div>