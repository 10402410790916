<div fxLayout="row" fxLayoutAlign="start center" style="padding: 1rem;" class="w100" #loading>
    <span class="title">{{ dialog.title | translate }}</span>
    <span fxFlex></span>
    <button (click)="close()" class="button-1">
        <ff-icon [icon]="'close'"></ff-icon>
    </button>
</div>

<mat-dialog-content *ngIf="dialog.state != 1" class="mat-typography">
    <div *ngIf="dialog.state == 2" fxLayout="row" fxLayoutAlign="center center" class="w100 h100">
        <div class="not-found" fxLayout="row" fxLayoutAlign="center center">
            <span>{{ 'GLOBAL.PDF_NOT_AVAILABLE' | translate }}</span>
        </div>
    </div>

    <div *ngIf="dialog.state == 0" class="w100 h100">
        <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
            <ff-loading-card [loadingData]="loadingData"></ff-loading-card>
        </div>
    </div>
</mat-dialog-content>

<div #viewer class="viewer" style="min-height: 800px;" [style.display]="dialog.state != 1 ? 'none' : 'table'"></div>