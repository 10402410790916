import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';



@Component({
  selector: 'app-production-trend',
  templateUrl: './production-trend.component.html',
  styleUrls: ['./production-trend.component.scss']
})
export class LeanProductionTrendComponent implements OnInit, OnDestroy {

  public isAllowedUser: boolean = true;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public intervalAggrList: any = null;

  public productionColumns: string[] = [];

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public pollingTime: any;
  public pollingEvents: any;

  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;

  public interval: any;
  public intervalConfig: any;

  public intervalAggregations: any;
  public aggregationsTime: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public dashboardConfig: any;

  public leanAnalyticsData: any;

  public tabName: any = "productionTrend";
  public sectionName: any = "leanAnalytics";
  public dashboardName: any = "lean-production-trend";
  public dashboardNameComplete: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getDataPolling, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['LEAN_ANALYTICS.TITLE', 'PRODUCTION_TREND.TITLE'];
    this.internalDataService.setCalendarPage(true);
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);
    this.availableMachines = this.appConfig?.[this.tabName]?.availableMachines ?? [];

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig.leanAnalytics.pollingAlarms;
    this.pollingEvents = Subscription;

    this.leanAnalyticsData = null

  }

  openAggrDialog(aggr: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        // console.log('afterClosed', result);
        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
      }

      this.pageState.next(isClickedSelect ? 5 : 6);

      if (!this.interval.enabledPolling) {
        this.getData(this, 0);
      } else {
        this.getDataPolling(this);
      }

    });
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-la-production");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getDataPolling, this.getData, this.machine.timezone) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval aggr
  selectAggregation(aggr: any) {
    if (aggr != null) {
      try {
        this.pollingEvents.unsubscribe();
      } catch (error) { }

      this.intervalAggregations.selected = aggr;

      this.pageState.next(5);
      this.getDataPolling(this);
    }
  }

  machineSelectionChange(machine: any) {

    if (machine != null) {

      try {
        this.pollingEvents.unsubscribe();
      } catch (error) {
      }

      this.pageState.next(5);
      this.getDataPolling(this);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // aggregation id
  // updateAggrId(aggrId: any) {
  //   this.aggrId = aggrId;
  //   this.productionColumns = [];
  //   this.leanAnalyticsInfo.forEach((element: any) => {
  //     if (!element.offset || (element.offset && element.offset.includes(this.aggrId))) {
  //       this.productionColumns.push(element.variable);
  //     } 
  //   });
  // }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, 'lean-production-trend');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // events

  // polling
  getDataPolling(_this: any) {
    try {

      // try {
      //   _this.intervalAggregations = {
      //     list: _this.aggregationsTime,
      //     selected: _this.aggregationsTime[0]
      //   };
      // } catch (error) {
      //   console.log(error);
      // }

      if (_this.cacheService.get("intervalLong") == null) {

        _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.cacheService.set("intervalLong", _this.intervalConfig);

      } else {
        _this.intervalConfig = _this.cacheService.get("intervalLong");
        _this.interval = _this.cacheService.get("intervalLong").selected;
      }

      if (_this.pollingTime > 0) {
        _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getData(_this, count);
        });
      } else {
        _this.getData(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getData(_this: any, count?: any) {
    try {

      if (count == 0 && _this.intervalAggrList?.length > 0) _this.internalDataService.forceAggregationList(_this, _this.intervalAggrList);

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone,
        unit: _this.intervalAggregations.selected.unit != null ? _this.intervalAggregations.selected.unit : 'day',
        value: _this.intervalAggregations.selected.value != null ? _this.intervalAggregations.selected.value : 1,
      };

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      _this.filterService.filterAggregationsByMachine(_this, query?.machineId);

      _this.apiService.sendPostRequest('/apif/production-analytics/production-trend/' + _this.machineId, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.leanAnalyticsData = data.body;
            try {

              _this.leanAnalyticsData.dataConfig = {
                plotDataAttribute: 'productionTrendData'
              };

              // set plot data and configs

              _this.leanAnalyticsData.productionTrendData = _this.buildPlotConfig(_this, _this.leanAnalyticsData);

            } catch (error) {
              console.log(error);
            }

            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  buildPlotConfig(_this: any, data: any, zoomRange?: any) {

    if (!data.hasOwnProperty('timeserie') || data.timeserie == null || !Array.isArray(data.timeserie) || data.timeserie.length == 0) {
      return {
        traces: [],
        layout: {}
      };
    }

    let traces: any = [];

    let isQuality: boolean = false;
    if (_this.appConfig.MAT2) {
      isQuality = _this.machine.profile?.defaultKpis.findIndex((k: any) => k.reference == 'quality') != -1;
    }
    else {
      isQuality = _this.machine.profile.oeeKpis.quality == true;
    }

    traces.push({
      x: data.timeserie.filter((x: any) => (isQuality ? x.totGood : x.totProdAct) != null).map((x: any) => x.timestamp),
      // y: data.timeserie.filter((x: any) => (isQuality ? x.totGood : x.totProdAct) != null).map((x: any) => isQuality ? x.totGood : x.totProdAct),
      y: data.timeserie.filter((x: any) => (isQuality ? x.totGood : x.totProdAct) != null).map((x: any) => _this.filterService.convertUnit(_this.filterService.getProductionUnit('totProdAct', _this.machine.profile.productionConfig), isQuality ? x.totGood : x.totProdAct).value),
      name: _this.filterService.getProductionLabel((isQuality ? 'totGood' : 'totProdAct'), _this.machine.profile.productionConfig.invert),
      text: data.timeserie.filter((x: any) => (isQuality ? x.totGood : x.totProdAct) != null).map((x: any) => {
        return '<b>' + _this.filterService.getProductionLabel((isQuality ? 'totGood' : 'totProdAct'), _this.machine.profile.productionConfig.invert) + '</b>: '
          + _this.filterService.parseGaugeValue(_this.filterService.convertUnit(_this.filterService.getProductionUnit('totProdAct', _this.machine.profile.productionConfig), isQuality ? x.totGood : x.totProdAct).value, 0, 1)
      }),
      hoverinfo: "x+text",
      type: 'scatter',
      mode: 'lines+markers',
      marker: {
        color: "rgb(76, 175, 80)"
      },
      line: {
        width: 3,
        shape: 'spline'
      }
    });

    traces.push({
      x: data.timeserie.filter((x: any) => (isQuality ? x.totGood : x.totProdAct) != null).map((x: any) => x.timestamp),
      y: data.timeserie.filter((x: any) => (isQuality ? x.totGood : x.totProdAct) != null).map((x: any) => _this.filterService.convertUnit(_this.filterService.getProductionUnit('totProdAct', _this.machine.profile.productionConfig), x.totProdSet).value),
      // y: prodPunctData[tr.variable]?.map(x => _this.filterService.convertUnit(_this.filterService.getProductionUnit(tr.yaxis == 'y2' ? 'avgProdAct' : 'totProdAct', _this.machine.profile.productionConfig), x).value),
      fill: 'tonexty',
      yaxis: 'y1',
      name: _this.filterService.getProductionLabel('totProdSet', _this.machine.profile.productionConfig.invert),
      text: data.timeserie.filter((x: any) => (isQuality ? x.totGood : x.totProdAct) != null).map((x: any) => {
        return '<b>' + _this.filterService.getProductionLabel('totProdSet', _this.machine.profile.productionConfig.invert) + '</b>: '
          + _this.filterService.parseGaugeValue(_this.filterService.convertUnit(_this.filterService.getProductionUnit('totProdAct', _this.machine.profile.productionConfig), x.totProdSet).value, 0, 1)
      }),
      hoverinfo: "x+text",
      type: 'scatter',
      mode: 'lines+markers',
      fillcolor: 'rgba(0,0,0,0)',
      marker: {
        color: "rgba(76, 175, 80, 0.5)"
      },
      line: {
        width: 3,
        shape: 'spline',
        dash: 'dash'
      }
    });

    if (_this.machine.profile.aggregations != null && _this.machine.profile.aggregations.length > 0) {
      _this.machine.profile.aggregations.forEach((aggr: any) => {

        if (aggr.id == _this.intervalAggregations.selected.id) {
          traces.push({
            x: data.timeserie.filter((x: any) => (isQuality ? x.totGood : x.totProdAct) != null).map((x: any) => x.timestamp),
            y: data.timeserie.filter((x: any) => (isQuality ? x.totGood : x.totProdAct) != null).map((x: any) => _this.filterService.convertUnit(_this.filterService.getProductionUnit('totProdAct', _this.machine.profile.productionConfig), x.totProdSet).value),
            name: _this.translate.instant(aggr.label).capitalize(),
            type: "scatter",
            mode: 'lines+markers',
            traceType: 'actual',
            visible: true,
            showlegend: false,
            hovertext: data.timeserie.filter((x: any) => (isQuality ? x.totGood : x.totProdAct) != null).map((x: any) => '<b>' + (x[aggr.id] != null ? x[aggr.id] : '-') + '</b>'),
            hoverinfo: "x+text+name",
            line: {
              width: 0,
              shape: 'spline'
            },
            connectgaps: false,
          })
        }
      });
    }

    if (isQuality) {
      traces.push({
        x: data.timeserie.filter((x: any) => x.totBad != null).map((x: any) => x.timestamp),
        y: data.timeserie.filter((x: any) => x.totBad != null).map((x: any) => _this.filterService.convertUnit(_this.filterService.getProductionUnit('totBad', _this.machine.profile.productionConfig), x.totBad).value),
        yaxis: 'y',
        name: _this.filterService.getProductionLabel('totBad', _this.machine.profile.productionConfig.invert),
        text: data.timeserie.filter((x: any) => x.totBad != null).map((x: any) => {
          return '<b>' + _this.filterService.getProductionLabel('totBad', _this.machine.profile.productionConfig.invert) + '</b>: ' +
            _this.filterService.parseGaugeValue(_this.filterService.convertUnit(_this.filterService.getProductionUnit('totBad', _this.machine.profile.productionConfig), x.totBad).value, 0, 1)
        }),
        hoverinfo: "x+text",
        type: 'scatter',
        mode: 'lines+markers',
        marker: {
          color: "rgba(244, 67, 54, 1)"
        },
        line: {
          width: 3,
          shape: 'spline'
        }
      });
    }

    traces.push({
      x: data.timeserie.map((x: any) => x.timestamp),
      y: data.timeserie.map((x: any) => (x.productiveTime != null ? x.productiveTime / 60 : null)),
      xaxis: 'x',
      yaxis: 'y2',
      name: _this.translate.instant('PRODUCTION_TREND.PRODUCTIVE_TIME'),
      text: data.timeserie.map((x: any) => {
        return '<b>' + _this.translate.instant('PRODUCTION_TREND.PRODUCTIVE_TIME') + '</b>: ' +
          _this.filterService.parseTime(x.productiveTime, 's', 'HH:mm:ss')
      }),
      hoverinfo: "x+text",
      type: 'scatter',
      mode: 'lines+markers',
      marker: {
        color: "rgb(87, 156, 212)"
        // color: "rgb(30, 117, 180)"
      },
      line: {
        width: 3,
        shape: 'spline'
      }
    });

    traces.push({
      x: data.timeserie.map((x: any) => x.timestamp),
      y: data.timeserie.map((x: any) => (x.totTime != null ? x.totTime / 60 : null)),
      fill: 'tonexty',
      fillcolor: 'rgba(0,0,0,0)',
      xaxis: 'x',
      yaxis: 'y2',
      name: _this.translate.instant('PRODUCTION_TREND.PLANNED_TIME'),
      text: data.timeserie.map((x: any) => {
        return '<b>' + _this.translate.instant('PRODUCTION_TREND.PLANNED_TIME') + '</b>: ' +
          _this.filterService.parseTime(x.totTime, 's', 'HH:mm:ss')
      }),
      hoverinfo: "x+text",
      type: 'scatter',
      mode: 'lines+markers',
      marker: {
        color: "rgba(87, 156, 212, 0.5)"
        // color: "rgb(30, 117, 180)"
      },
      line: {
        width: 3,
        shape: 'spline',
        dash: 'dash'
      }
    });

    if (_this.machine.profile.aggregations != null && _this.machine.profile.aggregations.length > 0) {
      _this.machine.profile.aggregations.forEach((aggr: any) => {

        if (aggr.id == _this.intervalAggregations.selected.id) {
          traces.push({
            x: data.timeserie.map((x: any) => x.timestamp),
            y: data.timeserie.map((x: any) => (x.downtime != null ? x.downtime / 60 : null)),
            xaxis: 'x',
            yaxis: 'y2',
            name: _this.translate.instant(aggr.label).capitalize(),
            type: "scatter",
            mode: 'lines+markers',
            traceType: 'actual',
            visible: true,
            showlegend: false,
            hovertext: data.timeserie.map((x: any) => '<b>' + (x[aggr.id] != null ? x[aggr.id] : '-') + '</b>'),
            hoverinfo: "x+text+name",
            line: {
              width: 0,
              shape: 'spline'
            },
            connectgaps: false,
          })
        }
      });
    }

    traces.push({
      x: data.timeserie.filter((x: any) => x.avgProdAct != null).map((x: any) => x.timestamp),
      y: data.timeserie.filter((x: any) => x.avgProdAct != null).map((x: any) => _this.filterService.convertUnit(_this.filterService.getProductionUnit('avgProdAct', _this.machine.profile.productionConfig), x.avgProdAct).value),
      yaxis: 'y3',
      name: _this.filterService.getProductionLabel('avgProdAct', _this.machine.profile.productionConfig.invert),
      text: data.timeserie.filter((x: any) => x.avgProdAct != null).map((x: any) => {
        return '<b>' + _this.filterService.getProductionLabel('avgProdAct', _this.machine.profile.productionConfig.invert) + '</b>: ' +
          _this.filterService.parseGaugeValue(_this.filterService.convertUnit(_this.filterService.getProductionUnit('avgProdAct', _this.machine.profile.productionConfig), x.avgProdAct).value, 2, 1)
      }),
      hoverinfo: "x+text",
      type: 'scatter',
      mode: 'lines+markers',
      marker: {
        color: "rgb(30, 117, 180)"
      },
      line: {
        width: 3,
        shape: 'spline'
      }
    });

    traces.push({
      x: data.timeserie.filter((x: any) => x.avgProdAct != null).map((x: any) => x.timestamp),
      y: data.timeserie.filter((x: any) => x.avgProdAct != null).map((x: any) => _this.filterService.convertUnit(_this.filterService.getProductionUnit('avgProdSet', _this.machine.profile.productionConfig), x.avgProdSet).value),
      fill: 'tonexty',
      yaxis: 'y3',
      name: _this.filterService.getProductionLabel('avgProdSet', _this.machine.profile.productionConfig.invert),
      text: data.timeserie.filter((x: any) => x.avgProdAct != null).map((x: any) => {
        return '<b>' + _this.filterService.getProductionLabel('avgProdSet', _this.machine.profile.productionConfig.invert) + '</b>: ' +
          _this.filterService.parseGaugeValue(_this.filterService.convertUnit(_this.filterService.getProductionUnit('avgProdSet', _this.machine.profile.productionConfig), x.avgProdSet).value, 2, 1)
      }),
      hoverinfo: "x+text",
      type: 'scatter',
      mode: 'lines+markers',
      fillcolor: 'rgba(0,0,0,0)',
      marker: {
        color: "rgba(30, 117, 180, 0.5)"
      },
      line: {
        width: 3,
        shape: 'spline',
        dash: 'dash'
      }
    });

    if (_this.machine.profile.aggregations != null && _this.machine.profile.aggregations.length > 0) {
      _this.machine.profile.aggregations.forEach((aggr: any) => {

        if (aggr.id == _this.intervalAggregations.selected.id) {
          traces.push({
            x: data.timeserie.filter((x: any) => x.avgProdAct != null).map((x: any) => x.timestamp),
            y: data.timeserie.filter((x: any) => x.avgProdAct != null).map((x: any) => _this.filterService.convertUnit(_this.filterService.getProductionUnit('avgProdSet', _this.machine.profile.productionConfig), x.avgProdSet).value),
            xaxis: 'x',
            yaxis: 'y3',
            name: _this.translate.instant(aggr.label).capitalize(),
            type: "scatter",
            mode: 'lines+markers',
            traceType: 'actual',
            visible: true,
            showlegend: false,
            hovertext: data.timeserie.filter((x: any) => x.avgProdAct != null).map((x: any) => '<b>' + (x[aggr.id] != null ? x[aggr.id] : '-') + '</b>'),
            hoverinfo: "x+text+name",
            line: {
              width: 0,
              shape: 'spline'
            },
            connectgaps: false,
          })
        }
      });
    }

    let plotLayout = {
      grid: {
        rows: 3,
        columns: 1,
        // subplots: [['xy', 'xy2'], ['xy2', 'xy']],
        roworder: 'top to bottom'
      },
      margin: {
        t: 30,
        r: 110,
        b: 50,
        l: 100,
        pad: 15
      },
      xaxis: {
        zeroline: false,
        showgrid: false,
      },
      yaxis: {
        zeroline: false,
        showgrid: false,
        title: {
          text: _this.filterService.getProductionLabel('totProdAct', _this.machine.profile.productionConfig.invert) +
            ' [' + _this.filterService.convertUnit(_this.filterService.getProductionUnit('totProdAct', _this.machine.profile.productionConfig)).unit + ']',
        },
        // rangemode: 'tozero',
      },
      yaxis2: {
        zeroline: false,
        showgrid: false,
        title: {
          text: _this.translate.instant('PRODUCTION_TREND.TIME') + ' [min]',
        },
        // rangemode: 'tozero',
      },
      yaxis3: {
        zeroline: false,
        showgrid: false,
        title: {
          text: _this.filterService.getProductionLabel('prodAct', _this.machine.profile.productionConfig.invert) +
            ' [' + _this.filterService.convertUnit(_this.filterService.getProductionUnit('avgProdAct', _this.machine.profile.productionConfig)).unit + ']',
        },
        // rangemode: 'tozero',
        side: 'left'
      },
      yaxis4: {
        zeroline: false,
        showgrid: false,
        overlaying: 'y',
        title: {
          text: _this.filterService.getProductionLabel('totBad', _this.machine.profile.productionConfig.invert) +
            ' [' + _this.filterService.convertUnit(_this.filterService.getProductionUnit('totBad', _this.machine.profile.productionConfig)).unit + ']',
        },
        // rangemode: 'tozero',
        side: 'right'
      },
      showlegend: true,
      legend: {
        // orientation: 'h',
        traceorder: 'normal',
        // x: 0,
        // y: -0.2
      },
    };

    // widgetParams = {
    //   data: traces,
    //   layout: plotLayout,
    //   onClickNavigateToMachineRecorder: true,
    //   machineId: params != null ? params.machineId : null,
    // };
    return {
      layout: plotLayout,
      traces: traces
    };

  }

  filterEvents(searchEvent?: any) {
    // TODO
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    this.internalDataService.setCalendarPage(false);
    try {
      this.pageState.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
  }

}