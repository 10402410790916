import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-assign-template-dialog',
  templateUrl: './assign-template-dialog.component.html',
  styleUrls: ['./assign-template-dialog.component.scss']
})
export class AssignTemplateDialogComponent implements OnInit, OnDestroy {

  public currentTemplate: any;
  public frequency: any;
  public monthWeek: any = 1;

  public shiftTemplateInfos: any = [
    {
      variable: "templateName",
      label: "MAINTENANCE_CALENDAR.TEMPLATE_NAME"
    },
    {
      variable: "frequency",
      label: "MAINTENANCE_CALENDAR.FREQUENCY"
    },
  ];

  public recurrencyOptions: any = [
    {
      id: "day",
      label: "INTERVAL.DAY"
    },
    {
      id: "week",
      label: "INTERVAL.WEEK"
    },
    {
      id: "month",
      label: "INTERVAL.MONTH"
    },
    {
      id: "year",
      label: "INTERVAL.YEAR"
    }
  ];

  weekDays: any = [
    {
      id: 0,
      label: "CALENDAR.MONDAY"
    },
    {
      id: 1,
      label: "CALENDAR.TUESDAY"
    },
    {
      id: 2,
      label: "CALENDAR.WEDNESDAY"
    },
    {
      id: 3,
      label: "CALENDAR.THURSDAY"
    },
    {
      id: 4,
      label: "CALENDAR.FRIDAY"
    },
    {
      id: 5,
      label: "CALENDAR.SATURDAY"
    },
    {
      id: 6,
      label: "CALENDAR.SUNDAY"
    }
  ];

  monthWeeks: any = [
    {
      id: 1,
      label: "CALENDAR.FIRST"
    },
    {
      id: 2,
      label: "CALENDAR.SECOND"
    },
    {
      id: 3,
      label: "CALENDAR.THIRD"
    },
    {
      id: 4,
      label: "CALENDAR.FOURTH"
    },
    {
      id: "last",
      label: "CALENDAR.LAST"
    },
  ]

  everyDay: any = {
    id: 'everyDay',
    frequency: 'day',
    label: 'MAINTENANCE_CALENDAR.EVERY',
    recurrence: 1,
    label2: 'MAINTENANCE_CALENDAR.DAY(S)',
    disabledWhenTrue: true,
    checked: true
  };

  everyWeekDay: any = {
    id: 'everyWeekDay',
    frequency: 'day',
    label: 'MAINTENANCE_CALENDAR.EVERY_WEEK_DAY',
    disabledWhenTrue: true,
    checked: false
  };

  everyWeek: any = {
    id: 'everyWeek',
    frequency: 'week',
    label: 'MAINTENANCE_CALENDAR.EVERY_WEEK',
    recurrence: 1,
    disabledWhenTrue: true,
    checked: true
  };

  everyMonth: any = {
    id: 'everyMonth',
    frequency: 'month',
    label: 'MAINTENANCE_CALENDAR.EVERY_MONTH',
    recurrence: 1,
    disabledWhenTrue: true,
    checked: true
  };

  nowTime: any;
  nowDateP: any;
  nowDateWithoutYear: any;
  numberOfOccurrences: any = 30;
  maxNumberOfOccurences: any = 365;
  recurrencyPattern: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<AssignTemplateDialogComponent>,
    public apiService: ApiService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public clonerService: ClonerService,
  ) {
    // console.log(this.dialog);

    this.nowDateP = moment(this.dialog.selectedDay).format("MMM DD, YYYY");
    this.nowDateWithoutYear = moment(this.dialog.selectedDay).format("DD MMMM");

    this.weekDays = this.weekDays.reduce((acc, weekDay) => {

      // Automatically check the selected day of the week
      if (moment(this.dialog.selectedDay).weekday() == weekDay.id) weekDay.checked = true;

      // Add disabled when true flag
      weekDay = { ...weekDay, ...{ disabledWhenTrue: true, checked: weekDay.checked ?? false } };

      acc.push(weekDay);
      return acc;

    }, []);

  }

  onWeekDayChange(checkbox) {
    this.weekDays.forEach(day => day.checked = checkbox.id == day.id);
  }

  selectOption(option) {
    // option.selected = true;
    this.frequency = option.id;

    let frequencyIdx = this.recurrencyOptions.findIndex(x => x.id == option.id)
    this.recurrencyOptions.forEach((x, idx) => x.selected = idx == frequencyIdx);
  }

  changeCurrentTemplate(event, template) {
    // console.log(event, template);

    let frequencyIdx = this.recurrencyOptions.findIndex(x => x.id == template.frequency)

    if (frequencyIdx != -1) this.recurrencyOptions.forEach((x, idx) => x.selected = idx == frequencyIdx);

    this.frequency = this.recurrencyOptions[frequencyIdx].id;

    switch (this.frequency) {

      case 'year':

        this.numberOfOccurrences = 1;
        this.maxNumberOfOccurences = 2;
        break;

      case 'month':

        this.numberOfOccurrences = 6;
        this.maxNumberOfOccurences = 12;

        this.monthWeek = Math.ceil(moment(this.dialog.selectedDay).date() / 7);
        if (this.monthWeek >= 5) this.monthWeek = 'last';
        this.recurrencyPattern = this.clonerService.deepClone(this.everyMonth);
        break;

      case 'week':

        this.numberOfOccurrences = 12;
        this.maxNumberOfOccurences = 52;

        this.recurrencyPattern = this.clonerService.deepClone(this.everyWeek);
        break;

      case 'day':
      default:

        this.numberOfOccurrences = 30;
        this.maxNumberOfOccurences = 365;

        this.recurrencyPattern = this.clonerService.deepClone(this.everyDay);
        break;
    }
  }

  onCheckboxChange(checkbox) {
    // console.log(checkbox);
    this.recurrencyPattern = this.clonerService.deepClone(checkbox);
    if (checkbox?.frequency == 'day') {
      if (checkbox.id == 'everyDay') {
        this.everyWeekDay.checked = false;
      }
      else if (checkbox.id == 'everyWeekDay') {
        this.everyDay.checked = false;
      }
    }
  }

  checkDisability() {
    // Check that a template is selected
    let templateSelectedCheck = this.currentTemplate != null;

    // Check that the number of occurences is greater than 1 and less than 365
    let occurencesNumberCheck = this.numberOfOccurrences >= 1 && this.numberOfOccurrences <= this.maxNumberOfOccurences;

    return !templateSelectedCheck || !occurencesNumberCheck;
  }

  // // // // // // // // // // // // // // // // // // // // // // // //

  closeDialog() {

    let startingDay = this.dialog.selectedDay;
    if (this.frequency == 'week') {
      let weekDayIdStart = this.weekDays.find(x => x.checked)?.id;
      let weekDiff = weekDayIdStart - moment(startingDay).weekday();

      if (weekDiff > 0) {
        startingDay = moment(startingDay).add(weekDiff, 'days').format("YYYY-MM-DD");
      }

      else if (weekDiff < 0) {
        startingDay = moment(startingDay).add(7 + weekDiff, 'days').format("YYYY-MM-DD");
      }

    }

    else if (this.frequency == 'month') {
      let weekDayIdStart = this.weekDays.find(x => x.checked)?.id;
      let weekDiff = weekDayIdStart - moment(startingDay).weekday();

      let predictedMonth = moment(startingDay).clone().month();

      startingDay = moment(startingDay).add(weekDiff, 'days').format("YYYY-MM-DD");
      let m = moment(startingDay).clone();

      if (!this.filterService.checkWeek(m, this.monthWeek)) {
        m = m.subtract(28, 'days');
        let i = 0;
        while ((!this.filterService.checkWeek(m, this.monthWeek) || predictedMonth != m.month()) && i < 10) {
          m = m.add(7, 'days');
          i++;
        }
        startingDay = m.format("YYYY-MM-DD");
      }
    }

    let dialogData = {
      currentTemplate: this.currentTemplate,
      frequency: this.frequency,
      recurrencyPattern: this.recurrencyPattern?.id,
      recurrence: this.recurrencyPattern?.recurrence ?? 1,
      numberOfOccurrences: this.numberOfOccurrences,
      startingDay: startingDay,
      monthWeek: this.monthWeek,
    };

    this.dialogRef.close(dialogData);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

}
