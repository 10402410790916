<div mat-dialog-title fxLayout="row" fxLayoutAlign="center center" class="w100">
  <mat-icon class="icon left">playlist_add</mat-icon>
  <span>{{ (dialog.shiftTemplateDialog.shiftTemplate != null ? 'SHIFT_TEMPLATES.EDIT_SHIFT_TEMPLATE' : 'SHIFT_TEMPLATES.ADD_SHIFT_TEMPLATE') | translate }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" aria-label="close">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography" style="padding: 1.5rem 2rem 1rem;">

  <!-- name -->
  <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 1.5rem;">
    <span class="md-headline-invert"><b>{{ 'SHIFT_TEMPLATES.SHIFT_TEMPLATE_NAME' | translate }}</b></span>
    <span fxFlex></span>
    <div fxFlex="40" fxLayout="row" fxLayoutAlign="start center">
      <input type="text" [(ngModel)]="newShiftTemplate.name" required class="ff-input w100">
    </div>
  </div>

  <!-- color -->
  <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 1.5rem;">
    <span class="md-headline-invert"><b>{{ 'CALENDAR.BACKGROUND_COLOR' | translate }}</b></span>
    <span fxFlex></span>
    <div fxFlex="40" fxLayout="row" fxLayoutAlign="start center">
      <div class="color-square clickable" [style.background-color]="newShiftTemplate.color" [cpPosition]="'bottom'"
        [cpPositionOffset]="'50%'" [cpPositionRelativeToArrow]="true" [(colorPicker)]="newShiftTemplate.color"
        cpAlphaChannel="disabled"></div>
    </div>
  </div>

  <!-- day start -->
  <div fxLayout="row" fxLayoutAlign="start center">

    <div fxLayout="row" fxLayoutAlign="start center">
      <span class="md-headline-invert"><b>{{ 'SHIFT_TEMPLATES.DAY_START' | translate }}</b></span>
      <mat-icon class="icon right left" #tooltip="matTooltip"
        matTooltip="{{ 'SHIFT_TEMPLATES.DAY_START_TOOLTIP' | translate }}" matTooltipPosition="above">
        info
      </mat-icon>
    </div>
    <span fxFlex></span>

    <!-- hours -->
    <div class="select-box compact" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">

      <div class="ff-input-select">
        <mat-select [(value)]="dayStartHour" class="completely"
          [ngClass]="{'ff-disabled' : newShiftTemplate.shifts.length > 0}"
          placeholder="{{ 'MACHINE_SETTINGS.HOURS' | translate }}">
          <mat-option *ngFor="let h of shiftSelectionHours" [value]="h.value">
            {{ h.label }}
          </mat-option>
        </mat-select>
      </div>

    </div>

    <span style="margin: 0 8px;">:</span>

    <!-- minutes -->
    <div class="select-box compact" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">

      <div class="ff-input-select">
        <mat-select [(value)]="dayStartMinute" class="completely"
          [ngClass]="{'ff-disabled' : newShiftTemplate.shifts.length > 0}"
          placeholder="{{ 'MACHINE_SETTINGS.MINUTES' | translate }}">
          <mat-option *ngFor="let min of shiftSelectionMinutes" [value]="min.value">
            {{ min.label }}
          </mat-option>
        </mat-select>
      </div>

    </div>

  </div>

  <div style="margin: 1.5rem 0; background-color: rgba(0,0,0,0.12); height: 1px; width: 100%;"></div>

  <div fxLayout="row" *ngIf="newShiftTemplate.shifts.length > 0" class="w100">
    <span fxFlex="10" style="text-align: center; font-weight: 800; font-size: 1rem;">Shift</span>
    <span fxFlex="80" style="text-align: center; font-weight: 800; font-size: 1rem;">Period</span>
    <span fxFlex="10" style="text-align: center; font-weight: 800; font-size: 1rem;"></span>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" class="w100"
    *ngFor="let shift of newShiftTemplate.shifts; last as isLast">
    <span fxFlex="10" style="text-align: center;">{{ shift.shiftId }}</span>
    <span fxFlex="10"></span>
    <ngx-slider style="margin: 3rem 0 1.5rem 0;" [(value)]="shift.slider.min" [(highValue)]="shift.slider.max"
      [options]="shift.slider.options" (userChangeEnd)="shift.slider.userChangeEnd($event)">
    </ngx-slider>
    <span fxFlex="10"></span>
    <span fxFlex="10">

      <button *ngIf="isLast" mat-button class="circular-icon-button md-red" (click)="deleteShift(shift)"
        #tooltip="matTooltip" matTooltip="{{ 'SHIFT_TEMPLATES.DELETE_SHIFT' | translate }}" matTooltipPosition="left">
        <!-- <span>{{ 'SHIFT_TEMPLATES.DELETE_SHIFT' | translate }}</span> -->
        <mat-icon class="icon">delete</mat-icon>
      </button>
      <!-- <md-button class="md-red md-icon-button" (click)="deleteShift(shift)">
        [disabled]="!$last"
        <md-tooltip md-direction="left">
          <span class="break-word">{{ 'SHIFT_TEMPLATES.DELETE_SHIFT' | translate }}</span>
        </md-tooltip>
        <mat-icon class="icon right">delete</mat-icon>
      </md-button> -->
    </span>
  </div>

  <div fxLayout="row">
    <span fxFlex></span>

    <button mat-button class="filter-button-no-background" (click)="addShift()" [disabled]="checkAddShiftDisabled()">
      <span>{{ 'SHIFT_TEMPLATES.ADD_SHIFT' | translate }}</span>
      <mat-icon class="icon right">add</mat-icon>
    </button>

  </div>

  <div *ngIf="checkShiftTemplateName(newShiftTemplate.name)" fxLayout="row" fxLayoutAlign="start center"
    class="warning-shift-template-name md-red">
    <mat-icon class="icon left">warning</mat-icon>
    <span>{{ 'SHIFT_TEMPLATES.SHIFT_NAME_USED' | translate }}</span>
  </div>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 1rem;">
  <button class="filter-button md-primary" (click)="closeDialog()"
    [disabled]="newShiftTemplate.shifts.length == 0 || checkShiftTemplateName(newShiftTemplate.name)">
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</mat-dialog-actions>
