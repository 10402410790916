<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <!-- <div *ngIf="plotState == 1" id="plotDiv" style="width: 100%; height: 100%; min-height: calc(50vh - 48px);"></div>

  <div *ngIf="plotState == 2" fxLayout="row" fxLayoutAlign="center center">
    <div class="not-found" fxLayout="row" fxLayoutAlign="center center">
      <span>{{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}</span>
    </div>
  </div>

  <div *ngIf="plotState == 0" class="h100 w100">
    <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
      <ff-loading-card></ff-loading-card>
    </div>
  </div> -->

  <ff-plotly-chart [widget]="widget" fxLayout="column" fxLayoutAlign="space-between start"
    style="height: 100%; width: 100%;"></ff-plotly-chart>

  <table class="w100 standard-table">
    <thead>
      <tr>
        <th *ngFor="let info of tableInfos">
          <span>{{ info.label | translate }}</span>
        </th>
    </thead>
    <tbody>
      <tr>
        <td *ngFor="let info of tableInfos">

          <span>{{ info.value }}</span>
          <span *ngIf="info.unit != null">&nbsp;{{ info.unit }}</span>

        </td>
      </tr>
    </tbody>
  </table>


</mat-dialog-content>
