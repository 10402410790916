<div mat-dialog-title fxLayout="row" class="w100">

  <span fxLayout="column" fxLayoutAlign="center center">{{ dialog.title | translate }}</span>

  <span fxFlex></span>

  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>

</div>
<mat-dialog-content class="mat-typography alternative-color" [style.min-height]="'calc(100vh - 200px)'">

  <!-- <pre>{{ dialog }}</pre> -->
  <div id="imageContainter" style="position: relative; margin: auto;">

    <!-- <img [src]="dialog.imageUrl" (error)="noImage = true" [id]="id" style="border: 1px solid #d4d4d4;" -->
    <img *ngIf="!noImage" [src]="dialog.imageUrl" [id]="id" style="border: 1px solid #d4d4d4;"
      [style.width.px]="imageWidth ?? 600">

    <span *ngIf="noImage" style="text-align: center;">{{ 'GLOBAL.NO_IMAGE_AVAILABLE' | translate }}</span>

  </div>

  <span class="vertical-divider"></span>

  <div fxFlex="50" fxLayout="column" fxLayoutAlign="center center">

    <div id="myresult" class="img-zoom-result" style="width: 600px; height: 600px; border: 1px solid #d4d4d4;"></div>
  </div>
</mat-dialog-content>