import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

import { IntervalService } from 'src/app/services/interval.service';

import { MatDialog } from '@angular/material/dialog';
import {
  ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexTitleSubtitle, ApexXAxis,
  ApexYAxis
} from "ng-apexcharts";
import { CustomIntervalDialogComponent } from 'src/app/components/interval-selector/custom-interval-dialog/custom-interval-dialog.component';

export type ChartOptions = {
  annotations: ApexAnnotations,
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  grid: ApexGrid,
  tooltip: any // to be simple I made it as any, can be replaced with the proper className
};

@Component({
  selector: 'app-cycle-timeline',
  templateUrl: './cycle-timeline.component.html',
  styleUrls: ['./cycle-timeline.component.scss']
})
export class CycleTimelineComponent implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public pollingTime: any;
  public pollingMachines: any;

  public currentSynopticId: any;
  public synopticConfig: any;
  public synopticConfigDefault: any;
  public monitoringData: any;
  public monitoringDataUnparsed: any;
  public dashboardConfig: any;

  public dialogData: any;
  public showDialog: boolean = false;
  public chartOptions: any;

  public interval: any;
  public intervalConfig: any;

  public phaseSelected: boolean = false;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getCycleTimelineWidgets, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getStateTimelinePolling, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    // TODO AGGIUNGERE AGGREGAZIONI E FILTRI
    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.monitoringData = null;


    this.breadcrumb = ['MACHINE_MONITORING.TITLE', 'CYCLE_TIMELINE.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs('machineMonitoring');

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = 50000;
    this.pollingMachines = Subscription;

    this.interval = this.intervalService.getIntervalById('last30Minutes');

    this.intervalConfig = {
      list: this.intervalService.getDefaultIntervals(1),
      selected: this.interval
    };

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET USER DATA
  public getUserData(_this: any) {
    try {

      _this.apiService.sendGetRequest('/apif/user').pipe(
        retryWhen(_this.apiService.genericRetryStrategy()),
        catchError(error => {

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError(
            'Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.userInfo = data.body;
            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'machineMonitoring');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getCycleTimelineWidgets(_this: any) {

    let dashboardName = 'cycle-timeline';

    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingMachines, this.getStateTimelinePolling, this.getStatesTimeline, this.machine.timezone, 5, 6) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SYNOPTIC CONFIG

  // polling
  getStateTimelinePolling(_this: any) {
    try {

      if (_this.pollingTime > 0) {
        _this.pollingMachines = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getStatesTimeline(_this, count);
        });
      } else {
        _this.getStatesTimeline(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getStatesTimeline(_this: any, count?: any) {
    try {

      // let payload = {filters: {}} //_this.internalDataService.buildMachinePayload(machine);
      if (_this.interval != null && _this.interval.enabledPolling) {
        _this.interval = _this.intervalService.getIntervalById(_this.intervalConfig.selected.id);
        _this.intervalConfig.selected = JSON.parse(JSON.stringify(_this.interval));
      }

      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone
      };

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      _this.apiService.sendGetRequest('/apif/remote-monitoring/cycle-timeline/' + _this.machineId, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          if (count == 0) {
            _this.pollingMachines.unsubscribe();
          }

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError(
            'Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            let height = 275;
            try {
              height = window.innerHeight - 530;
            } catch (error) {
              console.log(error);
            }

            _this.chartOptions = {
              chart: {
                // redrawOnParentResize: true,
                height: height,
                type: 'rangeBar',
                animations: {
                  enabled: false
                },
                zoom: {
                  enabled: true
                },
                events: {
                  zoomed: function (config: any, axes: any) {
                    try {
                      if (axes.xaxis.min != null && axes.xaxis.max != null) {
                        try {
                          _this.pollingMachines.unsubscribe();
                        } catch (error) {
                          console.log(error);
                        }
                        // do nothing
                      } else {
                        if (!_this.interval.enabledPolling) {
                          _this.getStatesTimeline(_this, 0);
                        } else {
                          _this.pageState.next(6);
                          _this.getStateTimelinePolling(_this);
                        }
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  },
                  click: function (event: any, chartContext: any, config: any) {
                    // console.log({ config });

                    if (config != null && config.dataPointIndex != -1 &&
                      _this.chartOptions.series[0].data[config.dataPointIndex] != null &&
                      _this.chartOptions.series[0].data[config.dataPointIndex].phaseStart != null
                    ) {
                      try {
                        _this.phaseSelected = true;
                        _this.monitoringData = _this.chartOptions.series[0].data[config.dataPointIndex];
                      } catch (error) {
                        console.log(error);
                      }
                    } else {
                      _this.phaseSelected = false;
                      _this.monitoringData = _this.monitoringDataUnparsed;
                    }
                  }
                },
                toolbar: {
                  show: true,
                  tools: {
                    download: false,
                    selection: false,
                    zoom: true,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    customIcons: []
                  },
                  // export: {
                  //   csv: {
                  //     filename: undefined,
                  //     columnDelimiter: ';',
                  //     headerCategory: 'category',
                  //     headerValue: 'value',
                  //     dateFormatter(timestamp:any) {
                  //       console.log(timestamp);

                  //       return new Date(timestamp).toDateString()
                  //     }
                  //   },
                  //   svg: {
                  //     filename: undefined,
                  //   },
                  //   png: {
                  //     filename: undefined,
                  //   }
                  // },
                }
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  distributed: true,
                  dataLabels: {
                    hideOverflowingLabels: false
                  }
                }
              },
              dataLabels: {
                enabled: false,
                style: {
                  fontSize: '14px',
                },
                formatter: function (val: any, opts: any) {
                  // console.log (val, opts);
                  var label = opts.w.globals.labels[opts.dataPointIndex];
                  var a = moment(val[0]);
                  var b = moment(val[1]);
                  var diff = b.diff(a, 'seconds');
                  var diffHM = _this.filterService.parseTime(b.diff(a, 'seconds'), 's', 'HH:mm');
                  if (diff > 0) {
                    return diffHM;
                  }
                  // return label;
                },
                // style: {
                //   colors: ['#f3f4f5', '#fff']
                // }
              },
              xaxis: {
                type: 'datetime',
                labels: {
                  datetimeUTC: false,
                  show: true,
                }
              },
              tooltip: {
                enabled: true,
                custom: function (totalSeries: any) {

                  let series = totalSeries.series;
                  let seriesIndex = totalSeries.seriesIndex;
                  let dataPointIndex = totalSeries.dataPointIndex;
                  let w = totalSeries.w;

                  let startText = w.config.series[seriesIndex].data[dataPointIndex].y != null ?
                    '<p> <strong>' + _this.translate.instant("CYCLE_TIMELINE.START") + ': </strong>' + moment(w.config.series[seriesIndex].data[dataPointIndex].y[0]).format('MMM DD, YYYY HH:mm:ss') + '</p>' : '';

                  let endText = w.config.series[seriesIndex].data[dataPointIndex].y != null && w.config.series[seriesIndex].data[dataPointIndex].phaseEnd != '-' ?
                    '<p> <strong>' + _this.translate.instant("CYCLE_TIMELINE.END") + ': </strong>' + moment(w.config.series[seriesIndex].data[dataPointIndex].y[1]).format('MMM DD, YYYY HH:mm:ss') + '</p>' : '';

                  let durationText = w.config.series[seriesIndex].data[dataPointIndex].duration != null ?
                    '<p> <strong>' + _this.translate.instant("CYCLE_TIMELINE.DURATION") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].duration + '</p>' : '';

                  let phaseText = w.config.series[seriesIndex].data[dataPointIndex].label != null ?
                    '<p> <strong>' + _this.translate.instant("CYCLE_TIMELINE.PHASE") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].label + '</p>' : '';

                  let cycleText = w.config.series[seriesIndex].data[dataPointIndex].cycleId != null ?
                    '<p> <strong>' + _this.translate.instant("CYCLE_TIMELINE.CYCLE") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].cycleId + '</p>' : '';

                  let alarmText = w.config.series[seriesIndex].data[dataPointIndex].alarm != null ?
                    '<p> <strong>' + _this.translate.instant("CYCLE_TIMELINE.ALARM") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].alarm.code +
                    ' - ' + w.config.series[seriesIndex].data[dataPointIndex].alarm.message + '</p>' : '';

                  let alarmCodes = '';
                  if (w.config.series[seriesIndex].data[dataPointIndex].ids != null && w.config.series[seriesIndex].data[dataPointIndex].ids.length > 0) {
                    alarmCodes +=
                      '<p><strong>Codes: </strong></p><ul style="margin:0;">';
                    w.config.series[seriesIndex].data[dataPointIndex].ids.forEach((alarm: any) => {
                      alarmCodes += '<li>' +
                        '<p><strong>Code:</strong> ' + alarm.code + '</p>' +
                        '<p><strong>Message:</strong> ' + alarm.message + '</p>' +
                        '<p><strong>Start:</strong> ' + moment(alarm.start).format('MMM DD, YYYY HH:mm:ss') + '</p>' +
                        '<p><strong>End:</strong> ' + moment(alarm.end).format('MMM DD, YYYY HH:mm:ss') + '</p>' +
                        '</li>';
                    });
                    alarmCodes += '</ul>';
                    // endText = '';
                  }

                  // console.log(alarmCodes);

                  return '<div class="arrow_box" style="background-color: ' + w.config.series[seriesIndex].data[dataPointIndex].fillColor + '80;">' +
                    startText +
                    endText +
                    durationText +
                    phaseText +
                    cycleText +
                    alarmText +
                    alarmCodes +
                    '</div>'
                },
              },
              yaxis: {
                show: true,
                labels: {
                  style: {
                    fontSize: '12px'
                  }
                }
              },
              grid: {
                row: {
                  colors: ['#f3f4f5', '#fff'],
                  opacity: 1
                }
              },
            }

            let series: any = [];

            if (data.body.hasOwnProperty('phases') && data.body.phases != null && data.body.phases.length > 0) {

              data.body.phases.sort(_this.filterService.sortByProperty('phaseId', null, true)).forEach((state: any, index: any) => {

                let duration = state.end != null ? _this.filterService.parseTime(moment(state.end).diff(moment(state.start), 's'), 's', 'HH:mm:ss') :
                  _this.filterService.parseTime(moment().diff(moment(state.start), 's'), 's', 'HH:mm:ss');

                let stateConfig = null;
                let color = null;
                let label = null;
                try {
                  stateConfig = _this.machine.profile.phases.find((x: any) => x.inputPhases.includes(state.phaseId));
                  if (stateConfig != null) {
                    color = stateConfig.color;
                    label = _this.translate.instant(stateConfig.label);
                  }
                } catch (error) {
                  console.log(error);
                }

                let currentSeriesObj = {
                  x: label,
                  y: [
                    moment(state.start).utc(false).valueOf() - 0,
                    (state.end != null) ? moment(state.end).utc(false).valueOf() : moment().utc(false).valueOf(),
                  ],
                  cycleId: state.cycleId,
                  phaseStart: moment(state.start).format("DD MMM YYYY HH:mm:ss"),
                  phaseEnd: state.end != null ? moment(state.end).format("DD MMM YYYY HH:mm:ss") : '-',
                  duration: duration,
                  label: label,
                  fillColor: color,
                  alarmTime: state.alarmTime,
                  signalationTime: state.signalationTime,
                  waitingTime: state.waitingTime,
                  maintTime: state.maintTime,
                  techTime: state.techTime,
                  processTime: state.processTime,
                };

                Object.entries(state)?.forEach(([k, v]: any) => {
                  if (currentSeriesObj?.[k] == null) currentSeriesObj[k] = v;
                });

                series.push(currentSeriesObj);

              });

              try {
                Object.entries(_this.filterService.groupBy(data.body.phases, 'cycleId')).forEach((cycleInfo: any, cycleIdx) => {

                  let cycleId = cycleInfo[0];
                  let cycleData = cycleInfo[1];

                  let cycleConf = null;
                  try {
                    let cycleConfIdx = _this.machine.profile.cycles.findIndex((cycleConf: any) => cycleData.some((p: any) => cycleConf.havePhases.includes(p.phaseId)));
                    cycleConf = cycleConfIdx == -1 ? _this.machine.profile.cycles.find((cc: any) => cc.id == 0) : _this.machine.profile.cycles[cycleConfIdx];
                  } catch (error) {
                    console.log(error);
                  }

                  if (cycleData != null && cycleData.length > 0) {
                    cycleData.sort(_this.filterService.sortByProperty('start', null, true));

                    let start = cycleData[0].start;
                    let end = cycleData[cycleData.length - 1].end;
                    let durSec = (end != null) ? moment(end).diff(moment(start), 's') : moment().diff(moment(start), 's');

                    let duration = _this.filterService.parseTime(durSec != null ? durSec : 0, 's', 'HH:mm:ss');

                    let currentCycle = {
                      x: _this.translate.instant("CYCLE_TIMELINE.CYCLE"),
                      y: [
                        moment(start).utc(false).valueOf() - 0,
                        (end != null) ? moment(end).utc(false).valueOf() : moment().utc(false).valueOf(),
                      ],
                      phaseStart: moment(start).format("DD MMM YYYY HH:mm:ss"),
                      phaseEnd: end != null ? moment(end).format("DD MMM YYYY HH:mm:ss") : '-',
                      cycleId: cycleId,
                      duration: duration,
                      isCycle: true,
                      // label: label,
                      fillColor: cycleConf.colors.length == 1 ? cycleConf.colors[0] : cycleConf.colors[cycleIdx % 2 == 0 ? 0 : 1],
                      alarmTime: _this.filterService.sum(cycleData, 'alarmTime'),
                      signalationTime: _this.filterService.sum(cycleData, 'signalationTime'),
                      waitingTime: _this.filterService.sum(cycleData, 'waitingTime'),
                      maintTime: _this.filterService.sum(cycleData, 'maintTime'),
                      techTime: _this.filterService.sum(cycleData, 'techTime'),
                      processTime: _this.filterService.sum(cycleData, 'processTime'),
                    };

                    Object.entries(cycleData[0])?.forEach(([k, v]: any) => {
                      if (currentCycle?.[k] == null) currentCycle[k] = _this.filterService.sum(cycleData, k);
                    });

                    series.push(currentCycle);

                  }
                });
              } catch (error) {
                console.log(error);
              }
            }

            // var max = 0;
            // data.body.phases.forEach((x: any) => {
            //   if (new Date(x.end).getTime() > max) {
            //     max = new Date(x.end).getTime();
            //   }
            // });

            // var min = Infinity;
            // data.body.phases.forEach((x: any) => {
            //   if (new Date(x.start).getTime() < min) {
            //     min = new Date(x.start).getTime();
            //   }
            // });

            // var alarmDuration = (max - min) / 200;

            if (data.body.alarms != null && data.body.alarms.length > 0) {
              data.body.alarms.forEach((alarm: any) => {

                if (alarm.start == null) {
                  return;
                }

                series.push({
                  x: _this.translate.instant("ALARMS.TITLE"),
                  y: [
                    new Date(alarm.start).getTime(),
                    new Date(alarm.end).getTime()
                  ],
                  phaseEnd: alarm.end != null ? moment(alarm.end).format("DD MMM YYYY HH:mm:ss") : '-',
                  ids: alarm.codes != null ? alarm.codes.map((x: any) => {
                    return {
                      code: _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'code', _this.machineId),
                      message: _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'message', _this.machineId),
                      start: x.start,
                      end: x.end
                    }
                  }) : null,
                  fillColor: "#f44336",
                });

              });
            }

            if (data.body.signalations != null && data.body.signalations.length > 0) {
              data.body.signalations.forEach((sign: any) => {

                if (sign.start == null) {
                  return;
                }

                series.push({
                  x: _this.translate.instant("SIGNALATIONS.TITLE"),
                  y: [
                    new Date(sign.start).getTime(),
                    new Date(sign.end).getTime()
                  ],
                  phaseEnd: sign.end != null ? moment(sign.end).format("DD MMM YYYY HH:mm:ss") : '-',
                  ids: sign.codes != null ? sign.codes.map((x: any) => {
                    return {
                      code: _this.internalDataService.parseWarningsLabel(x.alarmCode, 'code', _this.machineId),
                      message: _this.internalDataService.parseWarningsLabel(x.alarmCode, 'message', _this.machineId),
                      start: x.start,
                      end: x.end
                    }
                  }) : null,
                  fillColor: "#FCA10D",
                });

              });
            }

            // console.log(series);

            _this.chartOptions.series = [{ data: series }];

            _this.chartOptions.xaxis.min = new Date(_this.filterService.getMin(_this.chartOptions.series[0].data, 'y', 0)).getTime();
            _this.chartOptions.xaxis.max = new Date(_this.filterService.getMax(_this.chartOptions.series[0].data, 'y', 1)).getTime();

            _this.monitoringDataUnparsed = {
              phaseStart: "-",
              phaseEnd: "-",
              duration: "-",
              signalationTime: 0,
              waitingTime: 0,
              alarmTime: 0,
              maintTime: 0,
              techTime: 0,
              processTime: 0,
            };

            _this.monitoringDataUnparsed = { ..._this.monitoringDataUnparsed, ...data.body.total };
            if (!_this.phaseSelected) _this.monitoringData = _this.monitoringDataUnparsed;

            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      console.log(error);
    }

  }

  getPreviousBucket() {

    try {
      this.pollingMachines.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start), 'm') / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).subtract(duration, 'm')));
    let end = JSON.parse(JSON.stringify(moment(this.interval.end).subtract(duration, 'm')));

    this.interval.start = start;
    this.interval.end = end;

    this.interval.enabledPolling = false;

    this.interval.startF = this.intervalService.strFormat(this.interval, start) + ' - ' + this.intervalService.strFormat(this.interval, end);
    this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

    this.pageState.next(5);
    this.getStatesTimeline(this, 0);
  }

  getNextBucket() {

    try {
      this.pollingMachines.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start), 'm') / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).add(duration, 'm')));
    let end = moment(this.interval.end).add(duration, 'm');

    if (moment().diff(end, 'm') < 5) {
      this.interval.start = start;
      this.interval.enabledPolling = true;
      this.interval.end = JSON.parse(JSON.stringify(moment()));
      this.interval.startF = this.intervalService.strFormat(this.interval, start);
      this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

      this.pageState.next(5);
      this.getStateTimelinePolling(this);
    }
    else {

      this.interval.start = start;
      this.interval.end = JSON.parse(JSON.stringify(end));

      this.interval.enabledPolling = false;
      this.interval.startF = this.intervalService.strFormat(this.interval, start) + ' - ' + this.intervalService.strFormat(this.interval, this.interval.end);
      this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

      this.pageState.next(5);
      this.getStatesTimeline(this, 0);
    }


  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // RESIZE
  timeOutFunctionId: any;

  @HostListener('window:resize', ['$event'])
  onResize() {

    clearTimeout(this.timeOutFunctionId);
    this.timeOutFunctionId = setTimeout(() => {

      this.pageState.next(3);
      let height = 275;
      try {
        height = window.innerHeight - 530;
      } catch (error) {
        console.log(error);
      }

      try {
        this.chartOptions.chart.height = height;
      } catch (error) {
        console.log(error);
      }

      setTimeout(() => {
        this.pageState.next(6);
      }, 50);


    }, 50);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() {
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pollingMachines.unsubscribe();
    } catch (error) { }
    try {
      this.pageState.unsubscribe();
    } catch (error) { }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) { }
  }

}