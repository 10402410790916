<div mat-dialog-title fxLayout="row" class="w100">
    <span>{{ 'GLOBAL.HELP' | translate }}</span>
    <span fxFlex></span>
    <button mat-dialog-close>
      <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
    </button>
  </div>
  <mat-dialog-content class="mat-typography">
      <span>help dialog content</span>
  </mat-dialog-content>
  <!-- <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{ 'GLOBAL.CANCEL' | translate }}</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Install</button>
  </mat-dialog-actions> -->
  