<!-- content -->
<div fxLayout="column" fxLayoutAlign="start start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <button class="filter-button md-primary rounded-button" (click)="updateSettings()" fxLayout="row"
      *ngIf="isAllowedUser" fxLayoutAlign="start center"
      [disabled]="pageState.value < pageStateReady || settings == null || checkUpdates() || !isAllowedUserWrite">
      <mat-icon class="icon" aria-label="save">save</mat-icon>
      <span>{{'GLOBAL.SAVE'|translate}}</span>
    </button>

  </div>

  <!-- content -->
  <div class="tab-content h100 w100" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-md="row wrap"
    fxLayoutAlign.gt-md="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start start' }}">

    <!-- error -->
    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
    </ff-loading-card>

    <mat-card *ngIf="pageState.value >= pageStateReady && !appConfig?.alerts?.flags?.removeGlobalMailingList"
      class="rba-container w100" style="margin-bottom: 20px;">

      <div class="title">
        <span>{{'RBA.MAILING_LIST' | translate}}</span>
        <span class="number"
          *ngIf="settings != null && settings.mailingList != null && settings.mailingList.length > 0">
          &nbsp;({{ settings.mailingList.length }})
        </span>

        <span fxFlex></span>

        <button mat-button *ngIf="settings != null && settings.mailingList != null && settings.mailingList.length < 6"
          class="md-gray-i rounded-button elliptic" (click)="addMail(settings.mailingList)"
          [disabled]="!isAllowedUserWrite" fxLayout="row" fxLayoutAlign="space-evenly center" #tooltip="matTooltip"
          matTooltip="{{ 'RBA.ADD_MAIL' | translate }}" matTooltipPosition="above">
          <mat-icon class="icon side">add</mat-icon>
        </button>

      </div>

      <div class="subtitle">
        <span *ngIf="settings != null && settings.mailingList != null && settings.mailingList.length > 0">
          {{'RBA.MAILING_LIST_DESCRIPTION' | translate}}.
        </span>
        <span *ngIf="settings == null || settings.mailingList == null || settings.mailingList.length == 0">
          {{ 'RBA.NO_MAILS_CONFIGURED' | translate}}!</span>
      </div>

      <mat-card-content>

        <div fxLayout="row wrap"
          *ngIf="settings != null && settings.mailingList != null && settings.mailingList.length > 0"
          fxLayoutGap="16px">
          <div *ngFor="let mail of settings.mailingList; let i = index" [fxFlex]="'calc(33.3% - 16px)'"
            class="single-event-container three" style="background-color: transparent !important;" fxLayout="row"
            fxLayoutAlign="start center">

            <input class="ff-input mail" [(ngModel)]="mail.value" type="email" required name="mail"
              [disabled]="!isAllowedUserWrite">

            <button *ngIf="isAllowedUserWrite" mat-button class="md-red-i rounded-button elliptic"
              (click)="deleteMail(settings.mailingList, i)" fxLayout="row" fxLayoutAlign="space-evenly center"
              #tooltip="matTooltip" matTooltip="{{ 'RBA.DELETE_MAIL' | translate }}" matTooltipPosition="above"
              style="margin-left: 12px !important;">
              <mat-icon class="icon side">delete</mat-icon>
            </button>

          </div>
        </div>
      </mat-card-content>

    </mat-card>

    <mat-card *ngIf="pageState.value >= pageStateReady" class="rba-container w100">

      <div class="title">
        <span>{{'RBA.ALERTS_TYPE_SELECTION' | translate}}</span>
        <span class="number" *ngIf="settings != null && settings.alerts != null && settings.alerts.length > 0">
          &nbsp;({{ settings.alerts.length }})
        </span>

        <span fxFlex></span>

        <button *ngIf="settings != null && settings.alerts != null && settings.alerts.length < 20" mat-button
          class="md-gray-i rounded-button elliptic" (click)="addEvent()" [disabled]="!isAllowedUserWrite" fxLayout="row"
          fxLayoutAlign="space-evenly center" #tooltip="matTooltip" matTooltip="{{ 'RBA.ADD_EVENT' | translate }}"
          matTooltipPosition="above">
          <mat-icon class="icon side">add</mat-icon>
        </button>

      </div>

      <div class="subtitle" style="margin-bottom: 12px;">
        <span *ngIf="settings != null && settings.alerts != null && settings.alerts.length > 0">
          {{'RBA.ALERTS_DESCRIPTION' | translate}}.
        </span>
        <span *ngIf="settings == null || settings.alerts == null || settings.alerts.length == 0">
          {{ 'RBA.NO_EVENTS_CONFIGURED' | translate}}!
        </span>
      </div>

      <mat-card-content>
        <div fxLayout="row wrap" *ngIf="settings != null && settings.alerts != null && settings.alerts.length > 0"
          fxLayoutGap="16px">
          <div *ngFor="let alert of settings.alerts; index as i" [fxFlex]="'calc(50% - 16px)'"
            class="single-event-container" [ngClass]="{'admin-alert': alert.isAdmin}">

            <div fxLayout="row" fxLayoutAlign="start center" style="padding-bottom: 12px;">

              <mat-icon class="icon left">notifications</mat-icon>
              <span style="font-size: 1.2rem;">
                <strong>{{ 'RBA.ALERT_TYPES.' + alert.type | translate }}&nbsp;{{ i+1 }}</strong>
              </span>

              <span fxFlex></span>

              <button mat-button *ngIf="isAllowedUserWrite" class="filter-button md-red rounded-button"
                (click)="removeAlert(alert.id)" fxLayout="row" fxLayoutAlign="space-evenly center">
                <mat-icon class="icon side">delete</mat-icon>
                <span>{{ 'GLOBAL.REMOVE' | translate }}</span>
              </button>

            </div>

            <div fxLayout="row" fxLayoutAlign="start center" style="padding-bottom: 12px;">

              <mat-icon class="side left {{alert.typeIcon.class}}"
                style="padding-right: 10px; background-color: transparent !important">
                {{alert.typeIcon.icon}}
              </mat-icon>
              <span>
                <strong [translate]="alert.name" [translateParams]="{
                                  xVarBool: (alert.parameters[0]?.valueLabel ?? alert.parameters[0].value ?? '----') | replace:['_{assetId}_', '_'],
                                  xVarNum: (alert.parameters[0]?.valueLabel ?? alert.parameters[0].value ?? '----') | replace:['_{assetId}_', '_'],
                                  xVarKPI: alert.parameters[0]?.kpiLabel ?? '-'
                              }">
                </strong>
              </span>

            </div>

            <div fxLayout="row" fxLayoutAlign="start center">

              <span class="subtitle" [translate]="alert.description" [translateParams]="{
                  xVarBool: (alert.parameters[0]?.valueLabel ?? alert.parameters[0].value ?? '----') | replace:['_{assetId}_', '_'],
                  xBool: alert.parameters[2]?.value ?? '-',
                  xMinBool: alert.parameters[3]?.value ?? '-',
                  xVarNum: (alert.parameters[0]?.valueLabel ?? alert.parameters[0].value ?? '----') | replace:['_{assetId}_', '_'],
                  xUnitNum: alert.parameters[2]?.unit ?? '',
                  xOpNum: alert.parameters[1]?.value ?? '-',
                  xNum: alert.parameters[2]?.value ?? '-',
                  xMinNum: alert.parameters[3]?.value ?? '-',
                  xVarKPI: alert.parameters[0]?.kpiLabel ?? '-',
                  xUnitKPI: alert.parameters[2]?.unit != null ? ( filterService.convertUnit(alert.parameters[2].unit).unit | translate ): '', 
                  xOpKPI: alert.parameters[1]?.value ?? '-',
                  xKPI: alert.parameters[2]?.value ?? '-',
                  xMinKPI: alert.parameters[3]?.value ?? '-',
                  xAggrType: alert.parameters[5]?.valueLabel ?? '-',
                  xTimeAggr: alert.parameters[4] != null ? ( ('GLOBAL.' + alert.parameters[4].value) | checkTranslation:'-':false): '-',
                  nCycles: alert.parameters[1]?.value ?? '-',
                  sapCode: (alert.parameters[2] != null && alert.parameters[2].value != undefined && alert.parameters[2].value !== '') ? (('ALERTS.WITH_SAP_CODE' | translate) + ' ' + alert.parameters[2].value) : '' 
              }">
              </span>

            </div>

            <div *ngIf="appConfig?.alerts?.flags?.customText" fxLayout="row" fxLayoutAlign="start center"
              style="margin-top: 12px;" class="clickable" (click)="alert.isCustomText = !alert.isCustomText;">

              <mat-icon *ngIf="alert.isCustomText" svgIcon="radio_button" class="icon small left radio_button">
              </mat-icon>
              <mat-icon *ngIf="!alert.isCustomText" svgIcon="radio_button_disabled"
                class="icon small left radio_button_disabled">
              </mat-icon>

              <span>{{ 'RBA.ENABLE_CUSTOM_TEXT' | translate }}</span>
              <!-- <span *ngIf="alert.isCustomText">{{ 'RBA.ENABLED_CUSTOM_TEXT' | translate }}</span>
              <span *ngIf="!alert.isCustomText">{{ 'RBA.DISABLED_CUSTOM_TEXT' | translate }}</span> -->

            </div>

            <div *ngIf="appConfig?.alerts?.flags?.customText && alert.isCustomText" fxLayout="row"
              fxLayoutAlign="start center">
              <textarea rows="1" style="max-height: 160px" class="ff-textarea" [(ngModel)]="alert.customText"
                oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                name="custom-alert-description" id="custom-alert-description"></textarea>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 12px;" class="clickable"
              (click)="alert.checked = !alert.checked;">

              <mat-icon *ngIf="alert.checked" svgIcon="radio_button" class="icon small left radio_button">
              </mat-icon>
              <mat-icon *ngIf="!alert.checked" svgIcon="radio_button_disabled"
                class="icon small left clickable radio_button_disabled">
              </mat-icon>

              <span>{{ 'RBA.ALERT_ACTIVATION_NOTIFY' | translate }}</span>

            </div>

            <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 12px;" class="clickable"
              (click)="alert.deactivationAlert = !alert.deactivationAlert;">

              <mat-icon *ngIf="alert.deactivationAlert" svgIcon="radio_button" class="icon small left radio_button">
              </mat-icon>
              <mat-icon *ngIf="!alert.deactivationAlert" svgIcon="radio_button_disabled"
                class="icon small left radio_button_disabled">
              </mat-icon>

              <span>{{ 'RBA.ALERT_DEACTIVATION_NOTIFY' | translate }}</span>

            </div>

            <div *ngIf="isCalendarPresent && alert.alertType == 'kpiVar'" fxLayout="row" fxLayoutAlign="start center"
              style="margin-top: 12px;" [class.ff-disabled]="alert.shiftAggrSelected"
              [class.clickable]="!alert.shiftAggrSelected" class="completely" [disabled]="alert.shiftAggrSelected"
              (click)="alert.useCalendar = !alert.useCalendar;onCalendarChange(alert)">

              <mat-icon *ngIf="alert.useCalendar" svgIcon="radio_button" class="icon small left radio_button">
              </mat-icon>
              <mat-icon *ngIf="!alert.useCalendar" svgIcon="radio_button_disabled"
                class="icon small left radio_button_disabled">
              </mat-icon>

              <span>{{ 'RBA.ALERT_USE_CALENDAR' | translate }}</span>

            </div>

            <div *ngIf="pageAdmin && appConfig?.alerts?.flags?.alertsWithPermissions" fxLayout="row"
              fxLayoutAlign="start center" style="margin-top: 12px;" class="clickable"
              (click)="alert.isAdmin = !alert.isAdmin;">

              <mat-icon *ngIf="alert.isAdmin" svgIcon="radio_button" class="icon small left radio_button">
              </mat-icon>
              <mat-icon *ngIf="!alert.isAdmin" svgIcon="radio_button_disabled"
                class="icon small left radio_button_disabled">
              </mat-icon>

              <span>{{ 'RBA.ADMIN_ALERT' | translate }}</span>

            </div>

            <div *ngIf="availableMachines != null && availableMachines.list != null && availableMachines.list.length > 1 && 
              alert.addMachineSelectionParameter && alert.alertType == 'kpiVar'" style="margin-top: 12px;">

              <span><strong>{{ 'RBA.SELECT_MACHINES' | translate }}</strong></span>
              <ul class="ff-ul" style="overflow: auto; max-height: 96px;">
                <li *ngFor="let item of availableMachines.list" fxLayout="row" fxLayoutAlign="start center"
                  class="clickable" (click)="toggle(item, alert.machines)" style="margin: 6px 0;">
                  <mat-icon *ngIf="exists(item, alert.machines)" svgIcon="radio_button" class="icon left radio_button"
                    [ngClass]="item.class">
                  </mat-icon>
                  <mat-icon *ngIf="!exists(item, alert.machines)" svgIcon="radio_button_disabled"
                    class="icon left radio_button_disabled">
                  </mat-icon>
                  <span fxFlex>{{item}}</span>
                  <mat-icon class="icon" *ngIf="item.color" [ngStyle]="{ 'color': item.color }">circle</mat-icon>
                </li>
              </ul>

            </div>

            <table *ngIf="alert.parameters.length > 0 && allowedUser" class="w100 rba-table" style="margin-top: 8px;">
              <thead>
                <tr>
                  <th class="col-icon-lg">
                  <th>
                    <span>{{ 'RBA.ALERTS.NAME' | translate }}</span>
                  </th>
                  <th>
                    <span>{{ 'RBA.ALERTS.DESCRIPTION' | translate }}</span>
                  </th>
                  <th>
                    <span>{{ 'RBA.ALERTS.VALUE' | translate }}</span>
                  </th>
                  <th>
                    <span>{{ 'RBA.ALERTS.UNIT' | translate }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let parameter of alert.parameters">
                  <td class="col-icon-lg">
                    <mat-icon class="icon">equalizer</mat-icon>
                  </td>
                  <td md-cell class="primary">
                    <span class="break-word">{{parameter.label | placeholder:'-'}}</span>
                  </td>
                  <td md-cell>
                    <span class="break-word">{{parameter.description | placeholder:'-'}}</span>
                  </td>
                  <td md-cell>

                    <div *ngIf="parameter.type == 'dialog'" class="ff-input-select"
                      [ngClass]="{'clickable': isAllowedUserWrite}" (click)="openEditVariable(alert, parameter)">

                      <span [ngClass]="{'md-red-i': parameter.value == null}">
                        {{ parameter.valueLabel ?? (parameter.value ?? 'GLOBAL.REQUIRED') | translate |
                        replace:['_{assetId}_', '_'] }}
                      </span>
                      <span fxFlex></span>
                      <span style="margin-left: 8px;">
                        <mat-icon svgIcon="touch"></mat-icon>
                      </span>

                    </div>

                    <md-input-container class="padding0 margin0 height100" style="margin-top: 12px !important;">

                      <input *ngIf="parameter.type == null && (parameter.inputType == 'text')" class="ff-input"
                        [(ngModel)]="parameter.value" type="text" [required]="!parameter.notRequired"
                        name="{{alert.id}}-{{parameter.label}}" [disabled]="!isAllowedUserWrite"
                        aria-label="{{parameter.label}}">

                      <input
                        *ngIf="parameter.type == null && (parameter.inputType == null || parameter.inputType == 'number')"
                        class="ff-input" [(ngModel)]="parameter.value" type="number" [required]="!parameter.notRequired"
                        [min]="parameter.min" [max]="parameter.max" name="{{alert.id}}-{{parameter.label}}"
                        [disabled]="!isAllowedUserWrite" aria-label="{{parameter.label}}">

                      <div *ngIf="parameter.type == 'dropdown'" class="ff-input-select">
                        <mat-select [(value)]="parameter.value" (selectionChange)="onVariableChange(alert, parameter)"
                          name="{{alert.id}}-{{parameter.label}}"
                          (openedChange)="parameter.searchText = null;parameter.availableOptions = null;"
                          [disabled]="(alert.alertType == 'boolVar' && parameter.paramType == 'OPERATOR') || (alert.alertType == 'cloths' && parameter.paramType == 'OPERATOR') || !isAllowedUserWrite">

                          <div *ngIf="parameter?.addSearchFilter" class="search-input" fxLayout="row"
                            fxLayoutAlign="start center">
                            <mat-icon class="icon">search</mat-icon>
                            <input type="text" class="w100" (keyup)="searchFromList($event, parameter)"
                              placeholder="{{'GLOBAL.SEARCH'|translate}}" [(ngModel)]="parameter.searchText">
                          </div>

                          <mat-option
                            *ngFor="let variable of parameter.list | filterByProperty:['disabledIf', '!=', alert.alertType] | customSearchFilter: parameter.availableOptions:['kpi', 'label', 'translLabel']"
                            [value]="variable.kpi != null ? variable.kpi : variable" [disabled]="variable.disabled">
                            <span *ngIf="parameter.paramType == 'OPERATOR'">{{ variable }}</span>
                            <span *ngIf="parameter.paramType != 'OPERATOR'">
                              {{ variable.label | translate }}
                            </span>
                          </mat-option>

                          <div
                            *ngIf="!(parameter.list | filterByProperty:['disabledIf', '!=', alert.alertType] | customSearchFilter: parameter.availableOptions:['kpi', 'label', 'translLabel']).length"
                            style="padding: 8px 16px;">
                            <span>
                              {{ (parameter.noDataLabel ?? 'RBA.NO_VARIABLE_AVAILABLE') | translate }}
                            </span>
                          </div>

                        </mat-select>
                      </div>

                    </md-input-container>
                  </td>
                  <td>
                    <span class="break-word">
                      {{ filterService.convertUnit(parameter.unit).unit | translate | placeholder:'-' }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>

            <div
              *ngIf="appConfig?.alerts?.flags?.customMailingList && !appConfig?.alerts?.flags?.removeGlobalMailingList"
              fxLayout="row" fxLayoutAlign="start center" style="margin-top: 12px;" class="clickable"
              (click)="toggleCustomMailingList(alert);">

              <mat-icon *ngIf="alert.isCustomMailingList" svgIcon="radio_button" class="icon small left radio_button">
              </mat-icon>
              <mat-icon *ngIf="!alert.isCustomMailingList" svgIcon="radio_button_disabled"
                class="icon small left radio_button_disabled">
              </mat-icon>

              <span *ngIf="alert.isCustomMailingList">{{ 'RBA.ENABLED_CUSTOM_MAILING_LIST' | translate }}</span>
              <span *ngIf="!alert.isCustomMailingList">{{ 'RBA.DISABLED_CUSTOM_MAILING_LIST' | translate }}</span>

            </div>

            <mat-card *ngIf="appConfig?.alerts?.flags?.customMailingList && alert?.isCustomMailingList == true"
              class="custom-mail-container" style="margin-top: 20px;">

              <div class="title">
                <span>{{'RBA.CUSTOM_MAILING_LIST' | translate}}</span>
                <span class="number"
                  *ngIf="settings != null && alert?.mailingList != null && alert?.mailingList.length > 0">
                  &nbsp;({{ alert?.mailingList.length }})
                </span>

                <span fxFlex></span>

                <button mat-button
                  *ngIf="settings != null && alert?.mailingList != null && alert?.mailingList.length < 6"
                  class="md-gray-i rounded-button elliptic" (click)="addMail(alert?.mailingList)"
                  [disabled]="!isAllowedUserWrite" fxLayout="row" fxLayoutAlign="space-evenly center"
                  #tooltip="matTooltip" matTooltip="{{ 'RBA.ADD_MAIL' | translate }}" matTooltipPosition="above">
                  <mat-icon class="icon side">add</mat-icon>
                </button>

              </div>

              <div class="subtitle">
                <span *ngIf="settings != null && alert?.mailingList != null && alert?.mailingList.length > 0">
                  {{'RBA.MAILING_LIST_DESCRIPTION' | translate}}.
                </span>
                <span *ngIf="settings == null || alert?.mailingList == null || alert?.mailingList.length == 0">
                  {{ 'RBA.NO_MAILS_CONFIGURED' | translate}}!</span>
              </div>

              <mat-card-content>

                <div fxLayout="row wrap"
                  *ngIf="settings != null && alert?.mailingList != null && alert?.mailingList.length > 0"
                  fxLayoutGap="16px">
                  <div *ngFor="let mail of alert?.mailingList; let i = index" [fxFlex]="'calc(50% - 16px)'"
                    class="single-event-container three" style="background-color: transparent !important;"
                    fxLayout="row" fxLayoutAlign="start center">

                    <input class="ff-input mail" [(ngModel)]="mail.value" type="email" required name="mail"
                      [disabled]="!isAllowedUserWrite">

                    <button *ngIf="isAllowedUserWrite" mat-button class="md-red-i rounded-button elliptic"
                      (click)="deleteMail(alert?.mailingList, i)" fxLayout="row" fxLayoutAlign="space-evenly center"
                      #tooltip="matTooltip" matTooltip="{{ 'RBA.DELETE_MAIL' | translate }}" matTooltipPosition="above"
                      style="margin-left: 12px !important;">
                      <mat-icon class="icon side">delete</mat-icon>
                    </button>

                  </div>
                </div>
              </mat-card-content>

            </mat-card>

          </div>
        </div>

      </mat-card-content>
    </mat-card>

  </div>

</div>