<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title | translate }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <div *ngFor="let machine of list" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start center"
    fxLayoutAlign.gt-sm="space-between center" class="machine-block">
    <span>{{ machine.id }}</span>
    <div fxLayout="row" fxLayoutAlign="start center"
      class="last-update {{machine.notConnected ? 'md-orange' : (machine.neverConnected ? 'md-gray' :'md-green') }}">

      <mat-icon class="icon left">
        {{machine.notConnected ? 'warning' : (machine.neverConnected ? 'remove' : 'check')}}
      </mat-icon>
      <span>{{ 'REMOTE_MONITORING.LAST_UPDATE' | translate }}: {{ machine.lastUpdateP }}</span>
    </div>
  </div>
</mat-dialog-content>
