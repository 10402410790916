import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-cycle-selection-dialog',
  templateUrl: './cycle-selection-dialog.component.html',
  styleUrls: ['./cycle-selection-dialog.component.scss']
})
export class CycleSelectionDialogComponent implements OnInit, OnDestroy {

  public plotState: number = 0;
  public switch: any;

  public cycles: any;
  public cyclesData: any;
  public cyclesInfo: any;
  public cyclesColumns: any = [];
  public cycleStart: any;
  public cycleOrder: any;
  public searchCycles: any;

  // public availableDays: any = [];
  public selectedDay: any = new Date();

  public isCycleSelected: boolean = false;
  public selectedCycle: any;

  public isDaySelectable: boolean = true;
  public customInterval: any;

  public sliderConf: any = {};

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  // @ViewChild(MatSort) sort!: MatSort;

  private sort!: MatSort;
  @ViewChild(MatSort) set MatSort(ms: MatSort) {
    this.sort = ms;
    this.setSort();
  }

  setSort() {
    this.cyclesData.sort = this.sort;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<CycleSelectionDialogComponent>,
    public apiService: ApiService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public clonerService: ClonerService,
    public internalDataService: InternalDataService,
  ) {

    this.cycles = {
      list: [],
      filtered: [],
      selected: null,
      pageOptions: [10, 25, 50, 100],
      pageSize: 10,
      search: ""
    };

    this.cyclesData = new MatTableDataSource<any[]>([]);

    console.log(this.dialog.tableInfos);

    this.isDaySelectable = this.dialog.isDaySelectable == null || this.dialog.isDaySelectable;

    if (this.dialog.customInterval != null) this.customInterval = this.dialog.customInterval;

    this.cyclesInfo = this.dialog.tableInfos;
    // if (this.dialog.isEnabledAD) this.cyclesColumns.push('icon');
    this.cyclesInfo.filter((x: any) => this.dialog.isEnabledAD ? true : x.showNotAD).forEach((element: any) => this.cyclesColumns.push(element.variable));

    this.cycleOrder = {
      variable: "timestamp",
      direction: "desc"
    };

    if (this.isDaySelectable) {
      try {
        this.selectedDay = this.dialog.comparedCycleInfos != null && this.dialog.dialogType == 'comparison' ? this.dialog.comparedCycleInfos.day : this.dialog.actualCycleInfos.day;
      } catch (error) { console.log(error) }

      this.selectedDay = this.selectedDay != null ? new Date(this.selectedDay) : new Date();
    }

    this.initSlider(this);
    this.switch = {
      checked: false,
      checkedLabel: 'on',
      uncheckedLabel: 'off',
    };
  }

  getCycleList() {

    this.plotState = 0;
    try {

      let interval: any = null;
      let query: any = {};

      if (!this.isDaySelectable) {
        if (this.customInterval != null) {
          interval = this.clonerService.deepClone(this.customInterval);

          query = Object.assign(query, {
            from: interval.start,
            to: interval.end,
            tz: this.dialog.machine.timezone,
          });
        }
      } else {
        if (this.selectedDay != null) {
          interval = {
            start: JSON.parse(JSON.stringify(moment(this.selectedDay).startOf("day"))),
            end: JSON.parse(JSON.stringify(moment(this.selectedDay).endOf("day"))),
          };
        }
      }

      query = Object.assign(query, {
        anomalyDetection: this.dialog.isEnabledAD,
        tags: this.dialog.aggregations != null ? this.dialog.aggregations.map((x: any) => x.id).join(',') : "",
        tz: this.dialog.machine.timezone,
      });

      if (this.isDaySelectable) query.day = moment(this.selectedDay).format("YYYY-MM-DD");

      let machineReference = null;
      if (this.dialog.availableMachines != null && this.dialog.availableMachines.selected != null) {
        machineReference = this.dialog.availableMachines.selected === 'Line' ? this.dialog.machine.machineReference : this.dialog.availableMachines.selected;
      } else if (this.dialog.machine.machineReference) {
        machineReference = this.dialog.machine.machineReference;
      }

      if (machineReference != null) {
        query.machineId = machineReference;
        try {
          query.processes = this.dialog.machine.machines[machineReference].processes.join(";");
          //query.tools = this.dialog.machine.machines[machineReference].tools.join(";");
        } catch (error) { }
      }

      // if (this.interval != null && this.interval.id == 'custom') {
      //   query.to = this.interval.end;
      // }
      let url = '/apif/condition-monitoring/cycles-detail/' + this.dialog.machineId + '/' + this.dialog.componentId;

      let payload: any = {};

      if (this.dialog.machine.profile.additionalCycleVariables?.length > 0) payload.additionalCycleVariables = this.clonerService.deepClone(this.dialog.machine.profile.additionalCycleVariables);

      this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {

            this.plotState = 2;

            if (error.error instanceof ErrorEvent) {
              console.log(`Error: ${error.error.message}`);
            } else {
              console.log(`Error: ${error.message}`);
            }

            return throwError(
              'Something bad happened; please try again later.');
          }))
        .subscribe(
          (data: any) => {

            this.cycles.list = this.internalDataService.parseCycleInfos(data.body, true, this.dialog?.tableInfos);

            let selectedKey = this.dialog.dialogType == 'comparison' ? 'comparison' : 'selected';
            let comparedKey = this.dialog.dialogType == 'comparison' ? 'selected' : 'comparison';

            if (this.dialog.comparedCycleInfos != null && this.dialog.comparedCycleInfos.cycleId != null) {
              let comparedCycleIdx = this.cycles.list.findIndex((cycle: any) => cycle.cycleId == this.dialog.comparedCycleInfos.cycleId);
              if (comparedCycleIdx != -1) {
                console.log(this.cycles.list[comparedCycleIdx]);
                this.cycles.list[comparedCycleIdx][comparedKey] = true;
              }
            }

            if (this.dialog.actualCycleInfos != null && this.dialog.actualCycleInfos.cycleId != null) {
              let selectedCycleIdx = this.cycles.list.findIndex((cycle: any) => cycle.cycleId == this.dialog.actualCycleInfos.cycleId);
              if (selectedCycleIdx != -1) {
                console.log(this.cycles.list[selectedCycleIdx]);
                this.cycles.list[selectedCycleIdx][selectedKey] = true;
              }
            }
            this.filterCycles();
          },
        );

    } catch (error) {
      console.log(error);

    }
  }

  filterCycles() {

    let filterVariables = ["cycleId"];

    // try {
    //   if (this.dialog.appConfig.cycleTraceability.cycles.filterVariables)
    //   this.dialog.appConfig.cycleTraceability.cycles.filterVariables != null ? this.dialog.appConfig.cycleTraceability.cycles.filterVariables : ["id"];
    // } catch (error) {

    // }

    let filtered: any = [];

    filtered = this.cycles.list;

    if (this.dialog.isEnabledAD) {
      try {
        filtered = filtered.filter((x: any) => this.switch.checked ? x.isAnomalous : true);
      } catch (error) {
        console.log(error);
      }
    }

    // filter on searchEvent
    try {
      filtered = filtered.filter((data: any) => filterVariables.some((x: any) => this.searchCycles == null || this.searchCycles == '' ? true : String(data[x]).toLowerCase().includes(this.searchCycles.toLowerCase())));
    } catch (error) {
      console.log(error);
    }

    // filter on slider
    if (this.sliderConf.min != null && this.sliderConf.max != null) {
      try {
        filtered = filtered.filter((x: any) => x.timeStartHours >= this.sliderConf.min && x.timeEndHours <= this.sliderConf.max);
      } catch (error) {
        console.log(error);
      }
    }

    this.cycles.filtered = filtered;

    this.plotState = filtered.length > 0 ? 1 : 2;
    let cd = new MatTableDataSource<any>();

    if (cd.paginator) cd.paginator.firstPage();

    cd.paginator = this.paginator;
    cd.sort = this.sort;
    cd.data = filtered;

    // console.log(filtered);

    this.cyclesData = cd;
  }

  initSlider(_this: any) {
    _this.sliderConf = {
      show: true,
      max: _this.sliderConf.max != null ? _this.sliderConf.max : 24 * 3600,
      min: _this.sliderConf.min != null && !isNaN(_this.sliderConf.min) ? _this.sliderConf.min : 0,
      options: {
        floor: 0,
        ceil: 24 * 3600,
        step: 300,
        translate: function (index: any) {
          try {
            return _this.filterService.parseTime(index, 's', 'HH:mm');
          } catch (error) {
            return index;
          }
        },
      },
      userChangeEnd: function (event: any) {
        // console.log('slider change', event, _this.sliderConf.min, _this.sliderConf.max);
        _this.filterCycles();
      }

    };

  }

  selectCycle(cycle: any) {
    this.cycles.filtered.forEach((c: any) => c.selected = false);
    cycle.selected = true;
    this.isCycleSelected = true;
    this.selectedCycle = this.clonerService.deepClone(cycle);
  }


  onDaySelection(date: any) {

    this.selectedDay = date.value;
    this.getCycleList();
  }

  switchChange(value: boolean) {
    this.switch.checked = value;
    this.filterCycles();
  }

  closeDialog() {
    this.dialogRef.close({ cycle: this.selectedCycle, list: this.cycles.list });
  }



  ngOnInit(): void {
    this.getCycleList();
  }

  ngOnDestroy() {
  }

}
