import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-notes-dialog',
  templateUrl: './edit-notes-dialog.component.html',
  styleUrls: ['./edit-notes-dialog.component.scss']
})
export class EditNotesDialogComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<EditNotesDialogComponent>,
  ) {

  }

  closeDialog() {
    this.dialogRef.close(this.dialog.value != null && this.dialog.value != '' ? this.dialog.value : '-');
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

}
