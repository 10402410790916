import { Component, Input, OnInit } from '@angular/core';
import { catchError, retryWhen, throwError } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CacheService } from 'src/app/services/cache.service';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'ff-image-from-be',
  templateUrl: './image-from-be.component.html',
  styleUrls: ['./image-from-be.component.scss']
})
export class ImageFromBeComponent implements OnInit {

  @Input() config: any;

  imageSrc: any;
  state: any = 0;

  constructor(
    public apiService: ApiService,
    public cacheService: CacheService,
    public filterService: FiltersService,
  ) { }

  ngOnInit() {
    this.getImageWithAuthentication();
  }

  openImage() {
    if (this.config == null) return;
    this.config.image = this.config.filename;
    this.config.title = this.config.filename;
    this.config.fileFromBE = true; this.filterService.openImage(this.config);
  }

  getImageWithAuthentication() {

    // console.log(this.config);

    const url = `/apif/get-iot-file/${this.cacheService.get("machineId")}`;

    let query = {};

    let payload = {
      filepath: this.config.filenameFav
    }

    this.apiService.getArrayBuffer(url, query, payload).pipe(
      retryWhen(this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
      catchError(error => {
        this.state = 2;

        return throwError('Something bad happened; please try again later.');
      }))
      .subscribe(
        (response: any) => {

          let imageStr = this.filterService.arrayBufferToBase64(response.body);

          if (imageStr == "") {
            this.state = 2;
            return;
          }

          try {
            this.imageSrc = this.filterService.sanitize('data:image/png;base64, ' + imageStr)
          } catch (error) {
            console.log(error);
            this.state = 2;
          }

          this.state = 1;

        }
      );
  }

}
