import { Pipe, PipeTransform } from '@angular/core';
import { FiltersService } from '../services/filters.service';

@Pipe({
  name: 'customSearchFilter'
})
export class CustomSearchFilterPipe implements PipeTransform {
  constructor(private filterService: FiltersService) { }

  transform(items: any[], searchString: any, list?: any): any {

    if (searchString == null || searchString == '') return items;

    let filteredItems: any = items?.filter((item) => {
      if (typeof item == 'object' && Object.keys(item)?.length > 0) return this.filterService.filterObjBySearch(item, searchString, list ?? ["id", "label"]);
      return item?.toLowerCase().includes(searchString.toLowerCase())
    });
    return filteredItems;

  }
}