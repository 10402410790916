<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
  <!-- utils -->
  <!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->
</div>

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxFlex fxLayout="column" class="w100">

  <!-- toolbar -->
  <div fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">
    <!-- tabs -->
    <ff-tabs [tabs]="tabs"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>
    <!-- <span fxHide.gt-sm style="height: 16px;"></span> -->

  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start center" class="tab-content">

    <div fxLayout="row" fxLayoutAlign="center center">
      <mat-icon class="icon md-orange" style="font-size: 24px;">warning</mat-icon>
      <span style="margin-left: 12px; font-weight: 800; font-size: 20px;">{{ 'GLOBAL.DEMO_MODE' | translate }}</span>
    </div>

    <!-- content -->
    <form (ngSubmit)="onSubmit()" #f="ngForm" class="w100">
      <div class="form-group row" fxLayout="row" fxLayoutAlign="start center">
        <label class="col-sm-3 side-label">{{'PROD_SHEET.DATE' | translate}}:</label>
        <div class="col-sm-3">
          <input type="date" id="date" name="date" class="datepicker form-input" [(ngModel)]="defaultDate">
        </div>
      </div>
      <div class="form-group row" fxLayout="row" fxLayoutAlign="start center">
        <label class="col-sm-3 side-label">{{'PROD_SHEET.TRAFILA' | translate}}:</label>
        <div class="col-sm-3">
          <div class="btn-group" role="group">
            <label class="custom-btn" *ngFor="let trafila of trafile; let i = index"
              [ngClass]="trafilaSelected === i ? 'selected' : 'custom-btn-secondary'" (click)="onSelectTrafila(i)">
              <input class="hide" type="radio" name="trafila" [value]="trafila" [ngModel]="defaultTrafila">
              {{ trafila }}
            </label>
          </div>
        </div>
        <label class="col-sm-1 side-label">{{'PROD_SHEET.SHIFT' | translate}}:</label>
        <div class="col-sm-2">
          <div class="btn-group" role="group" fxLayout="row" fxLayoutAlign="end center">
            <label class="custom-btn" *ngFor="let shift of shifts; let i = index"
              [ngClass]="shiftSelected === i ? 'selected' : 'custom-btn-secondary'" (click)="onSelectShift(i)">
              <input class="hide" type="radio" name="shift" [value]="shift" [ngModel]="defaultShift">
              {{ shift }}
            </label>
          </div>
        </div>
      </div>
      <div class="form-group row" fxLayout="row" fxLayoutAlign="start center">
        <div class="col-sm-3 form-check">
          <input class="form-check-input" type="checkbox" value="true" name="lotChangeCheck"
            [(ngModel)]="lotChangeChecked" style="margin: 6px;">
          <label class="form-check-label side-label" [style.color]="!lotChangeChecked ? 'rgba(153,153,153,0.6)' : ''"
            style="margin-left: 8px;">
            {{'PROD_SHEET.LOT_CHANGE' | translate}}
          </label>
        </div>
        <label class="col-sm-3 side-label" [style.color]="!lotChangeChecked ? 'rgba(153,153,153,0.6)' : ''">
          {{'PROD_SHEET.LOT_CHANGE_TIME' | translate}}:
        </label>
        <div class="col-sm-3">
          <input type="time" class="form-input" name="lotChangeTime" ngModel [disabled]="!lotChangeChecked"
            [required]="lotChangeChecked">
        </div>
      </div>
      <div class="form-group row" fxLayout="row" fxLayoutAlign="start center">
        <label class="col-sm-3 side-label">{{'PROD_SHEET.PRODUCT_TYPE' | translate}}:</label>
        <div class="col-sm-9">
          <select class="selectpicker form-input" id="products" name="products" [ngModel]="defaultProduct"
            data-width="50%">
            <optgroup *ngFor="let product of products; let i = index">
              <option [value]="product">
                {{ product }}
              </option>
            </optgroup>
          </select>
        </div>
      </div>
      <div class="form-group row" fxLayout="row" fxLayoutAlign="start center">
        <label class="col-sm-3 side-label">{{'PROD_SHEET.LOT' | translate}}:</label>
        <div class="col-sm-9">
          <input type="text" class="form-input" id="lot" name="lot"
            placeholder="{{'PROD_SHEET.LOT_CODE' | translate}}..." ngModel #lot="ngModel"
            style="width: calc(100% - 24px);">
          <!-- <span class="help-block" *ngIf="!lot.valid">Inserire codice lotto</span> -->
        </div>
      </div>
      <div class="form-group row" fxLayout="row" fxLayoutAlign="start center">
        <label class="col-sm-3 side-label">{{'PROD_SHEET.BIG_BAGS_AMOUNT' | translate}}:</label>
        <div class="col-sm-9">
          <div class="btn-group">
            <label class="custom-btn" *ngFor="let bigBag of bigBags; let i = index"
              [ngClass]="bigBagSelected === i ? 'selected' : 'custom-btn-secondary'" (click)="onSelectBigBag(i)">
              <input class="hide" type="radio" name="bigBag" [value]="bigBag" [ngModel]="defaultBigBag">
              {{ bigBag }}
            </label>
          </div>
        </div>
      </div>
      <h4 style="margin-top: 4vh;" class="title-label"><strong>{{'PROD_SHEET.IN_PROD_CHECKS' | translate}}:</strong>
      </h4>
      <hr>
      <div class="form-group row" fxLayout="row" fxLayoutAlign="start center">
        <label class="col-sm-3 side-label">{{'PROD_SHEET.TIME' | translate}}:</label>
        <div class="col-sm-3">
          <input type="time" class="form-input" name="dayTime" ngModel required>
        </div>
      </div>
      <div class="form-group row" fxLayout="row" fxLayoutAlign="start center">
        <label for="" class="col-sm-3 side-label">{{'PROD_SHEET.OUTCOME' | translate}}:</label>
        <div class="col-sm-9">
          <div class="btn-group">
            <input class="form-check-input" type="radio" name="outcome" [value]="true" [(ngModel)]="defaultOutcome">
            <label style="margin: 0 25px; font-size: large;" class="form-check-label">OK</label>
            <input class="form-check-input" type="radio" name="outcome" [value]="false" [(ngModel)]="defaultOutcome">
            <label style="margin-left: 25px; font-size: large;" class="form-check-label">
              {{'PROD_SHEET.NOT' | translate}} OK</label>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="start center">
        <span fxFlex></span>
        <!-- <button class="custom-btn custom-btn-danger" type="submit" (click)="onInvalidate()">
          {{'PROD_SHEET.INVALIDATE' | translate}}
        </button> -->
        <button style="margin-left: 10px;" class="custom-btn custom-btn-primary" type="submit" [disabled]="!f.valid"
          (click)="onValidate()">{{'PROD_SHEET.SUBMIT' | translate}}
        </button>
      </div>
    </form>
    <app-alert *ngIf="submitted" (confirm)="onResetForm()" (close)="onBackToForm()" [date]="prodSheet.date"
      [trafila]="prodSheet.trafila" [shift]="prodSheet.shift" [lotChangeTime]="prodSheet.lotChangeTime"
      [product]="prodSheet.products" [lot]="prodSheet.lot" [bigBag]="prodSheet.bigBag" [dayTime]="prodSheet.dayTime"
      [outcome]="prodSheet.outcome ? 'OK' : (('PROD_SHEET.NOT' | translate) + ' OK')">
    </app-alert>

    <app-alert-invalid *ngIf="invalidated" (confirmInvalid)="onResetForm()" (closeInvalid)="onBackToForm()"
      [date]="prodSheet.date" [trafila]="prodSheet.trafila" [shift]="prodSheet.shift">
    </app-alert-invalid>

  </div>

</div>


<!-- <div *ngIf="machines.filtered.length == 0 && statePageReady" class="no-machines" fxLayout="row"
    fxLayoutAlign="center center">
    <span>{{ 'HOMEPAGE.NOT_FOUND' | translate }}</span>
  </div> -->

<!-- Riepilogo per test: dovrebbe essere inviato ad un dialog -->
<!-- <div class="row" *ngIf="submitted">
    <div class="col-xs-12">
      <h3>Riepilogo:</h3>
      <p>Data: {{ prodSheet.date }}</p>
      <p>Trafila: {{ prodSheet.trafila }}</p>
      <p>Turno: {{ prodSheet.shift }}</p>
      <p>Ora Cambio Lotto: {{ prodSheet.lotChangeTime }}</p>
      <p>Prodotto: {{ prodSheet.products }}</p>
      <p>Lotto: {{ prodSheet.lot }}</p>
      <p>N. Big Bags Prodotte: {{ prodSheet.bigBag }}</p>
      <p>Ore: {{ prodSheet.dayTime }}</p>
      <p>Esito: {{ prodSheet.outcome === "true" ? "OK" : "NON OK"}}</p>
    </div>
</div> -->
