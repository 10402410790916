import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError, timer } from 'rxjs';
import { catchError, mergeMap, retryWhen } from 'rxjs/operators';




@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private SERVER_URL = null; // BUILD
  // private SERVER_URL = "http://192.168.1.102:3074"; // BUILD
  // private SERVER_URL = "http://localhost:3037"; // SERVE
  // private SERVER_URL = "http://localhost:3038";
  // private SERVER_URL = "http://localhost:3057";
  // private SERVER_URL = "http://localhost:3072";

  constructor(private httpClient: HttpClient, public router: Router) { }

  public sendGetRequest(url: string, params?: any, responseType = null) {

    url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
    return this.httpClient.get(url, { params: params, observe: "response", responseType: responseType })
  }

  public sendPatchRequest(url: string, payload: any, params?: any) {

    url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
    return this.httpClient.patch(url, payload, { params: params, observe: "response" })
  }

  public sendPostRequest(url: string, payload: any, params?: any) {

    url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
    return this.httpClient.post(url, payload, { params: params, observe: "response" })
  }

  public sendPutRequest(url: string, payload: any, params?: any) {

    url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
    return this.httpClient.put(url, payload, { params: params, observe: "response" })
  }

  public sendDeleteRequest(url: string, params?: any) {

    url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
    return this.httpClient.delete(url, { params: params, observe: "response" })
  }

  public getArrayBuffer(url: string, params?: any, payload?: any) {

    if (!payload) {
      url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
      return this.httpClient.get(url, { params: params, observe: "response", responseType: 'arraybuffer' })
    }
    else {
      url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
      return this.httpClient.post(url, payload, { params: params, observe: "response", responseType: 'arraybuffer' })
    }
  }

  public download(url: string, payload?: any, params?: any) {

    let resp;

    if (payload) {
      url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
      // return this.httpClient.post(url, payload,  { params: params, observe: "response", responseType: 'arraybuffer' })
      resp = this.httpClient.post(url, payload, { params: params, observe: "response", responseType: 'blob' })
    } else {
      url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
      resp = this.httpClient.post(url, {}, { params: params, observe: "response", responseType: 'arraybuffer' })
    }

    resp.pipe(
      retryWhen(this.genericRetryStrategy({ maxRetryAttempts: 0 })),
      catchError(error => {
        console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);

        alert('Oops... something went wrong during file download');

        let errorData = {
          type: 0,
          status: error.status,
          message: error.statusText
        };
        // this.dispatcherService.getDispatch(this, 301, errorData);
        return throwError(errorData.status + ': ' + errorData.message);
      })
    )
      .subscribe(
        (data: any) => {

          let headers = data.headers;

          let filename = headers.get('content-disposition')?.split(';')?.[1]?.trim()?.split('=')?.[1];
          let contentType = headers.get('content-type');

          let linkElement = document.createElement('a');
          try {
            var blob = new Blob([data.body], {
              type: contentType
            });
            var url = window.URL.createObjectURL(blob);

            linkElement.setAttribute('href', url);
            linkElement.setAttribute("download", filename);

            var clickEvent = new MouseEvent("click", {
              "view": window,
              "bubbles": true,
              "cancelable": false
            });
            linkElement.dispatchEvent(clickEvent);

            return true
          } catch (ex) {
            console.log(ex);

            return false
          }
        }
      );
    return true
  }

  public async asyncDownload(url: string, payload?: any, params?: any, type?: any) {

    let req;

    // With payload
    if (payload) {
      url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
      // return this.httpClient.post(url, payload,  { params: params, observe: "response", responseType: 'arraybuffer' })
      req = await this.httpClient.post(url, payload, { params: params, observe: "response", responseType: type ?? 'blob' })
    }

    // Without payload
    else {
      url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
      req = await this.httpClient.post(url, {}, { params: params, observe: "response", responseType: type ?? 'arraybuffer' })
    }

    let resp = await req.toPromise();
    return resp;
  }

  handleDownload(data) {

    let headers = data.headers;

    let filename = headers.get('content-disposition')?.split(';')?.[1]?.trim()?.split('=')?.[1];
    let contentType = headers.get('content-type');

    let linkElement = document.createElement('a');
    try {
      var blob = new Blob([data.body], {
        type: contentType
      });
      var url = window.URL.createObjectURL(blob);

      linkElement.setAttribute('href', url);
      linkElement.setAttribute("download", filename);

      var clickEvent = new MouseEvent("click", {
        "view": window,
        "bubbles": true,
        "cancelable": false
      });
      linkElement.dispatchEvent(clickEvent);

      // return true
    } catch (ex) {
      console.log(ex);

      // return false
    }
    //     }
    //   );
    // return true
  }

  async sendGetRequestAwait(url: string, params?: any, responseType?: any) {

    url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
    let req: any = this.httpClient.get(url, { params: params, observe: "response", responseType: responseType })
    let resp = await req.toPromise();
    return resp;
  }

  async sendPostRequestAwait(url: string, payload: any, params?: any) {

    url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
    let req: any = this.httpClient.post(url, payload, { params: params, observe: "response" })
    let resp = await req.toPromise();
    return resp;
  }

  async sendPutRequestAwait(url: string, payload: any, params?: any) {

    url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
    let req: any = this.httpClient.put(url, payload, { params: params, observe: "response" })
    let resp = await req.toPromise();
    return resp;
  }

  public sendArrayBuffer(url: string, file: any) {

    url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;
    // console.log(url);
    return this.httpClient.post(url, file, { observe: "response" })
  }

  async sendCustomPayloadWithArrayBuffer(url: string, payload?: any, params?: any) {

    url = this.SERVER_URL != null && url.startsWith('/') ? this.SERVER_URL + url : url;

    let headersReq = new HttpHeaders();
    headersReq.append('Content-Type', 'multipart/form-data');
    headersReq.append('Accept', 'application/json');
    // return this.httpClient.post(url, payload,  { params: params, observe: "response", responseType: 'arraybuffer' })
    // let req = this.http.post(url, payload, {observe: "response", responseType: 'blob', headers: headersReq});
    let req = this.httpClient.post(url, payload, { observe: "response", responseType: 'arraybuffer', headers: headersReq, params: params });

    let resp = await req.toPromise();

    // catch (e) {}
  }

  // Generic retry: Funziona con input:
  // - maxRetryAttempts: Numero di tentativi prima del fallimento.
  // - scalingDuration:  Tempo di retry cumulato (ogni richiesta aumenta di questa quantità) [ms].
  // - excludedStatusCodes: Codici esclusi dal retry.
  public genericRetryStrategy = (
    {
      // maxRetryAttempts = 3,
      maxRetryAttempts = 0,
      scalingDuration = 1000,
      excludedStatusCodes = [-1]
    }: {
      maxRetryAttempts?: number;
      scalingDuration?: number;
      excludedStatusCodes?: number[];
    } = {}
  ) => (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (error.status == 302) {
          this.router.navigateByUrl('/login');
        }
        else if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find(e => e === error.status)) {
          return throwError(error);
        }
        console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration} ms`);
        // retry after 1s, 2s, etc...
        return timer(retryAttempt * scalingDuration);
      }),
      // finalize(() => console.log('We are done!'))
    );
  };
}
