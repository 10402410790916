<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector *ngIf="intervalConfig != null" [config]="intervalConfig" (selected)="selectInterval($event)"
      [intAggrs]="intervalAggregations" (selectedAggr)="selectAggregation($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    [fxLayoutAlign]="pageState.value >= 5 ? 'start stretch' : ((pageState.value == 0 || stringError != null) ? 'start center' : 'center center')"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar tabs -->
    <div class="ff-toolbar pt-1 pb-3" fxLayout="column" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">

      <!-- consumables dropdown -->
      <div *ngIf="consumables != null && consumables.length > 0" class="select-box" fxLayout="column"
        fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center" style="margin-right: 8px;">

        <div class="box" fxLayout="row" fxLayoutAlign="start center">

          <!-- icon -->
          <!-- Leave the containing div because of fontawesome problems -->
          <div *ngIf="pageState.value >= 6 && !multipleConsumablesMode">
            <ff-icon [icon]="consIcon" iconClass="md-primary"></ff-icon>
          </div>

          <mat-form-field appearance="fill">
            <mat-label>{{ 'GLOBAL.DROPDOWN_CONSUMABLE_SELECTION' | translate }}</mat-label>
            <mat-select [(ngModel)]="consDropdown" name="cons" style="text-transform: capitalize;">
              <!-- [disabled]="consumables.length == 1" -->
              <mat-option *ngFor="let cons of consumables" [value]="cons.id" (click)="changeConsumable(consDropdown)"
                class="completely" [class.ff-disabled]="cons.disabled">
                <span style="text-transform: capitalize;">{{ cons.label | translate }}</span>
              </mat-option>
              <mat-option *ngIf="appConfig?.addAllConsumableSelectionInConsumptionAnalytics && consumables?.length > 1"
                value="all" (click)="changeConsumable('all')" class="completely"
                [class.ff-disabled]="allConsumablesDisabled">
                <span>{{ "CONSUMABLES.ALL" | translate }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="switch-container" *ngIf="intervalConfig != null" fxLayoutAlign.lt-md="center center"
        fxLayout.gt-sm="row" marginRight>
        <button class="mat-button rounded-button" (click)="getPreviousBucket()" marginRight #tooltip="matTooltip"
          matTooltip="{{ 'CYCLE_TIMELINE.PREVIOUS_BUCKET' | translate }}" matTooltipPosition="above">
          <mat-icon svgIcon="arrow_left" class="icon"></mat-icon>
        </button>
        <button class="mat-button rounded-button" (click)="getNextBucket()" [disabled]="interval?.enabledPolling"
          #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.NEXT_BUCKET' | translate }}" matTooltipPosition="above">
          <mat-icon svgIcon="arrow_right" class="icon"></mat-icon>
        </button>
      </div>

      <!-- aggregations -->
      <div
        *ngIf="aggregations != null && aggregations.length > 0 && (aggregations | filterByProperty:['type', '!=', 'continuous'])?.length > 0"
        fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">
        <!-- <pre>{{ aggregations | json }}</pre> -->
        <button mat-button class="filter-button"
          *ngFor="let aggr of aggregations | filterByProperty:['type', '!=', 'continuous']"
          (click)="openAggrDialog(aggr)" [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
          <mat-icon *ngIf="aggr.icon != null && aggr.icon.type == 'icon'" class="icon"
            translate-attr="{'aria-label': aggr.label}">
            {{aggr.icon.icon }}</mat-icon>
          <mat-icon *ngIf="aggr.icon != null && aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
            translate-attr="{'aria-label': aggr.label}"></mat-icon>
          <i *ngIf="aggr.icon != null && (aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor')" class="icon {{ aggr.icon.icon }}"
            translate-attr="{'aria-label': aggr.label}"></i>
          <span>{{ aggr.selectionLabel }}</span>
          <div fxLayout="row" fxLayoutAlign="center center"
            *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
            {{ aggregationsPayload[aggr.id].length }}
          </div>
        </button>
      </div>

      <span fxFlex></span>

      <ng-container *ngIf="!multipleConsumablesMode">
        <!-- aggregations dropdown -->
        <div
          *ngIf="!isMobile && aggregationsToShowInDropdown?.length > 0 && (aggregationsToShowInDropdown | filterByProperty:['consumption', '!=', false] | filterByProperty:['type', '!=', 'continuous'])?.length > 0"
          class="select-box" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
          fxLayoutAlign.gt-sm="start center">

          <div class="box" fxLayout="row" fxLayoutAlign="start center">
            <!-- <mat-icon class="icon">event_available</mat-icon> -->
            <mat-form-field appearance="fill">
              <mat-label>{{ 'GLOBAL.DROPDOWN_AGGREGATION_SELECTION' | translate }}</mat-label>
              <mat-select [(ngModel)]="aggrDropdown" name="aggr" style="text-transform: capitalize;">
                <mat-option (click)="changePageAggregation(aggrDropdown)">---</mat-option>

                <mat-option
                  *ngFor="let aggr of aggregationsToShowInDropdown | filterByProperty:['consumption', '!=', false] | filterByProperty:['type', '!=', 'continuous']"
                  [value]="aggr.id" (click)="changePageAggregation(aggrDropdown)">

                  <span style="text-transform: capitalize;">{{ aggr.label }}</span>

                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <!-- custom aggregations dropdown // shown only if now aggregations -->
        <div
          *ngIf="(aggregationsToShowInDropdown == null || aggregationsToShowInDropdown.length == 0) && aggregationsCustom != null && aggregationsCustom.length > 0"
          class="aggregation-selector" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
          fxLayoutAlign.gt-sm="start center">

          <div class="box" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="icon">timeline</mat-icon>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'GLOBAL.DROPDOWN_AGGREGATION_SELECTION' | translate }}</mat-label>
              <mat-select [(ngModel)]="aggrDropdown" name="aggr" style="text-transform: capitalize;">
                <mat-option (click)="changePageAggregation(aggrDropdown)" [value]="null">---</mat-option>

                <mat-option *ngFor="let aggr of aggregationsCustom" [value]="aggr.id"
                  (click)="changePageAggregation(aggrDropdown)">

                  <!-- <mat-icon *ngIf="aggr.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': aggr.label}">
                    {{aggr.icon.icon }}</mat-icon>
                  <mat-icon *ngIf="aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
                    translate-attr="{'aria-label': aggr.label}"></mat-icon>
                  <i *ngIf="aggr.icon.type == 'fontawesome'" class="icon {{ aggr.icon.icon }}"
                    translate-attr="{'aria-label': aggr.label}"></i> -->

                  <span style="text-transform: capitalize;">{{ aggr.label | translate }}</span>

                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </ng-container>

      <ff-available-machines-selection [availableMachines]="availableMachines"
        (selected)="machineSelectionChange($event)"></ff-available-machines-selection>

    </div>

    <!-- error -->
    <ff-error-card *ngIf="stringError == null && pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="stringError == null && pageState.value > 0 && pageState.value < 6"
      [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="stringError != null" fxLayout="row" fxLayoutAlign="center center" class="w100"
      style="min-height: 100px;">
      <div class="not-found" fxLayout="row" fxLayoutAlign="center center">
        <span>{{ stringError | translate }}</span>
      </div>
    </div>

    <div *ngIf="pageState.value >= 6" fxLayout="column" class="w100 h100">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
        class="h100 w100" fxLayoutGap.gt-sm="8px">

        <div *ngIf="!isMobile" fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="w100 h100"
          fxLayoutGap="8px">
          <ff-widget fxFlex="100"
            [widget]="{type: 'ff-plotly-chart-table', data: consData, machineProfile: machine.profile, config: consData.dataConfig }">
          </ff-widget>
        </div>

        <div
          [fxFlex.gt-sm]="multipleConsumablesMode ? dashboardMultiConfig?.secondColumnFlex : dashboardConfig.secondColumnFlex"
          fxLayout="column" fxLayoutAlign="start stretch" class="w100 h100" fxLayoutGap.gt-sm="8px">

          <ff-widget
            *ngFor="let w of (multipleConsumablesMode ? dashboardMultiConfig?.widgets : dashboardConfig.widgets)"
            [fxFlex.gt-sm]="w.flex"
            [widget]="{ type: w.type, data: consData, machineProfile: machine.profile, config: w.config, title: w.title }">
          </ff-widget>

        </div>

      </div>

    </div>

  </div>

  <div fxFlex fxLayout="column" fxLayoutAlign="start start"
    *ngIf="!isMobile && pageState.value >= 6 && aggrDropdown != null" fxLayout="row" class="w100"
    style="margin-top: 8px;">

    <ff-widget fxFlex="100"
      [widget]="{type: 'ff-plotly-chart', data: consData, machineProfile: machine.profile, config: consData.aggrDataConfig }">
    </ff-widget>

  </div>

</div>