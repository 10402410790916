<!-- TITLE -->
<span class="plotly-chart-title" *ngIf="widget.title != null" fxLayout="row" fxLayoutAlign="start center">
  {{ widget.title | translate }}

  <span *ngIf="widget.tooltip != null" matTooltip="{{ widget.tooltip | translate }}" matTooltipPosition="above"
    style="margin-left: 6px;">
    <mat-icon class="icon">info</mat-icon>
  </span>

</span>

<!-- <pre>{{ widget | json }}</pre> -->

<!-- Loading -->
<div *ngIf="plotState == 0" fxLayout="row" class="w100" fxLayoutAlign="center center" style="min-height: 150px;"
  [ngStyle]="{'height': widget.title != null ? 'calc(100% - 150px)' : 'calc(100% - 100px)'}">

  <div fxLayout="row" fxLayoutAlign="space-evenly center">
    <span><i class="fad fa-spinner fa-pulse icon"></i></span>
    <div style="margin-left: 12px !important;">
      <span>{{ 'GLOBAL.LOADING' | translate }}</span>
    </div>
  </div>

</div>

<!-- Success -->
<div *ngIf="plotState == 1" [id]="plotId" class="w100" [ngStyle]="
  {'height': widget.customHeight != null ? widget.customHeight : (widget.title != null && !widget.excludeTitleHeight ? 'calc(50vh - 150px)' : '100%'), 
  'min-height': widget.customMinHeight != null ? widget.customMinHeight : 'calc(50vh - 150px)'}">
</div>
<!-- todo for sct -->
<!-- <div *ngIf="plotState == 1" #cbcb class="w100 chart-background-custom-before" [ngStyle]="
{'height': widget.customHeight != null ? widget.customHeight : (widget.title != null && !widget.excludeTitleHeight ? 'calc(50vh - 150px)' : '100%'), 
'min-height': widget.customMinHeight != null ? widget.customMinHeight : 'calc(50vh - 150px)'}">
  <div [id]="plotId" class="w100 h100"></div>
</div>
<div *ngIf="plotState == 1" #cbc id="chart-background-custom" style="position: absolute; background-color: gray"></div> -->
<!-- ng-style="{'background-color': chartBackgroundCustomColor }" -->

<!-- Fail -->
<div *ngIf="plotState == 2" class="data-not-found w100" fxLayout="row" fxLayoutAlign="center center"
  [ngStyle]="{'height': widget.customHeight != null ? widget.customHeight : (widget.title != null ? 'calc(50vh - 150px)' : ((widget.excludeTitleHeight) ? '100%': 'calc(50vh - 100px)'))}">
  <span>{{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}</span>
</div>

<!-- Fail DASH -->
<div *ngIf="plotState == 3" class="data-not-found dash w100" fxLayoutAlign="center center">
  <span>---</span>
</div>

<div *ngIf="plotState == 4" [id]="plotId" class="w100" [ngStyle]="
{'height': widget.customHeight != null ? widget.customHeight : (widget.title != null && !widget.excludeTitleHeight ? 'calc(50vh - 150px)' : '100%'), 
  'min-height': widget.customMinHeight != null ? widget.customMinHeight : 'calc(50vh - 150px)'}"></div>

<!-- Event flags -->
<div *ngIf="realPlotPosition != null && realPlotPosition.left != null && realPlotPosition.width != null">
  <div *ngFor="let flag of flagsConfig; index as i;" class="event-flag"
    [style.width]="realPlotPosition.width/flagsNumber + 'px'"
    [style.left]="realPlotPosition.left + realPlotPosition.width/flagsNumber*i - sidenavWidth + 'px'">

    <div class="tile-container" fxLayout="column" fxLayoutAlign="center center"
      *ngIf="flag.events != null && flag.events > 0">

      <!-- triangular indicator -->
      <div class="triangular-indicator" [ngStyle]="{'left': flag.events == 1 ? flag.percP : 'auto'}"></div>

      <!-- circular-indicator -->
      <div (click)="flag.hideFlag = !flag.hideFlag;" class="clickable circular-indicator"
        [style.left]="flag.singleEvent ? flag.percPplus : 'auto'">
        <div class="mini"></div>
      </div>

      <!-- Tile -->
      <div class="title" *ngIf="!flag.hideFlag" fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="!flag.singleEvent" fxLayout="row" fxLayoutAlign="start center" class="w100">
          <span>{{ flag.events }} {{ 'GLOBAL.EVENTS' | translate }}</span>
          <span fxFlex></span>
          <mat-icon class="icon clickable" *ngIf="!widget.maxZoomReached" (click)="navigateToSelectedInterval(flag)">
            open_in_new
          </mat-icon>
        </div>

        <div *ngIf="flag.singleEvent" fxLayout="row wrap" fxLayoutAlign="start center" class="w100">
          <span> 1 {{ 'GLOBAL.EVENT' | translate }}</span>
          <span fxFlex></span>
          <span>{{ flag.timestampP }}</span>
        </div>

      </div>

      <div *ngIf="!flag.hideFlag && !flag.singleEvent" class="content" fxLayout="row wrap" fxLayoutAlign="start center"
        fxLayoutGap="4px">
        <div *ngFor="let eventType of flag.eventTypesL" [fxFlex]="'calc(50% - 8px)'" [ngClass]="{'w504px': true}"
          class="w100" fxLayout="row" fxLayoutAlign="start start" style="position: relative;">

          <mat-icon *ngIf="eventType.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.title}"
            #tooltip="matTooltip" matTooltip="{{ eventType.label | translate }}" matTooltipPosition="left">
            {{ eventType.icon.icon }}</mat-icon>

          <mat-icon *ngIf="eventType.icon.type == 'svg'" svgIcon="{{ eventType.icon.icon }}" class="icon"
            translate-attr="{'aria-label': item.title}" #tooltip="matTooltip"
            matTooltip="{{ eventType.label | translate }}" matTooltipPosition="left"></mat-icon>

          <i *ngIf="eventType.icon.type == 'fontawesome' || eventType.icon.type == 'phosphor'"
            class="icon {{ eventType.icon.icon }}" translate-attr="{'aria-label': item.title}" #tooltip="matTooltip"
            matTooltip="{{ eventType.label | translate }}" matTooltipPosition="left"></i>

          <mat-icon *ngIf="eventType.icon2 != null" class="icon small-icon">{{eventType.icon2}}</mat-icon>

          <span style="padding-left: 12px;">
            ({{ eventType.occurrences != null ? eventType.occurrences : 0 }})
          </span>

        </div>
      </div>

      <div *ngIf="!flag.hideFlag && flag.singleEvent" class="content" fxLayout="row wrap" fxLayoutAlign="start center"
        fxLayoutGap="4px">
        <div fxFlex="100" [ngClass]="{'w504px': false}" class="w100" fxLayout="column" fxLayoutAlign="start start"
          style="position: relative;">

          <div fxLayout="row" fxLayoutAlign="start center">

            <img *ngIf="flag.value?.image" [src]="flag.value.image" alt="" class="clickable"
              style="height: 60px; width: 60px; border-radius: 5px;" (click)="filterService.openImage(flag.value)">

            <ng-container *ngIf="flag.value?.image == null">
              <mat-icon *ngIf="flag.icon.type == 'icon'" class="icon">
                {{ flag.icon.icon }}
              </mat-icon>

              <mat-icon *ngIf="flag.icon.type == 'svg'" svgIcon="{{ flag.icon.icon }}" class="icon"></mat-icon>

              <i *ngIf="flag.icon.type == 'fontawesome' || flag.icon.type == 'phosphor'"
                class="icon {{ flag.icon.icon }}"></i>

              <mat-icon *ngIf="flag.icon2 && flag.icon2.type == 'icon'" class="icon">
                {{ flag.icon2.icon }}
              </mat-icon>

              <mat-icon *ngIf="flag.icon2 && flag.icon2.type == 'svg'" svgIcon="{{ flag.icon2.icon }}" class="icon">
              </mat-icon>

              <i *ngIf="flag.icon2 && (flag.icon2.type == 'fontawesome' || flag.icon2.type == 'phosphor')"
                class="icon {{ flag.icon2.icon }}"></i>
            </ng-container>

            <span style="margin-left: 8px;">{{ flag.name }}</span>
          </div>

          <!-- NORMAL COLUMN -->
          <div *ngIf="flag.value.eventType == null">
            <span>{{ flag.value.description }}</span>
          </div>

          <!-- LIST COLUMN -->
          <div *ngIf="flag.value.eventType == 'colorList' || flag.value.eventType == 'list'">
            <ul class="ff-ul">
              <div *ngIf="flag.value.eventType == 'colorList'">
                <li *ngFor="let state of flag.value.description" style="padding: 4px 0;">
                  <span class="color-square" [style.background-color]="state.color"
                    style="margin: 0 8px 0 0; padding: 0 9px;"></span>
                  <span>{{state.value | translate}}</span>
                </li>
              </div>
              <div *ngIf="flag.value.eventType == 'list'">
                <li *ngFor="let state of flag.value.description">
                  <span>{{state}}</span>
                </li>
              </div>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>