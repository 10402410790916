<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null " class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center"
    [ngStyle]="{'margin-top': isMobile ? '12px' : '0'}">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <!-- variables and components selection -->
    <button mat-button (click)="openComponentSelectionDialog()" class="filter-button" fxLayout="row"
      fxLayoutAlign="center center">
      {{ (lineMode ? 'HEALTH_MONITORING.MACHINE_COMPONENT_SELECTION' : 'HEALTH_MONITORING.COMPONENT_SELECTION') |
      translate }}
    </button>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < 5" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 5 && healthData.filtered.length > 0" fxLayout="column" class="w100"
      [class.h100]="!isMobile">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-xs="row wrap" fxLayoutAlign.gt-xs="start stretch"
        [fxLayoutGap]="cardGap" [fxLayoutGap.gt-xs]="cardGap">

        <!-- <ff-hm-card (click)="navigateToHMDetail(comp)" [ngClass]="{'clickable': comp.anomalyDetection && !isMobile}" -->
        <ff-hm-card (click)="navigateToHMDetail(comp)" [ngClass]="{'clickable': comp.clickable && !isMobile}"
          [ngStyle]="{'max-width': 'calc(' + flexWidth + '% - ' + (cardGap != null ? cardGap : '8px') + ')', 'min-width': 'calc(' + flexWidth + '% - ' + (cardGap != null ? cardGap : '8px') + ')'}"
          *ngFor="let comp of healthData.filtered; trackBy trackById" [compData]="comp" [lineMode]="lineMode"
          [dashboardConfig]="dashboardConfig">
        </ff-hm-card>

      </div>

    </div>

    <div *ngIf="pageState.value >= 5 && healthData.filtered.length == 0" class="not-found w100" fxLayout="row"
      fxLayoutAlign="center center" style="height: 100px;">
      <span>{{ 'HEALTH_MONITORING.COMPONENTS_NOT_FOUND' | translate }}</span>
    </div>

  </div>

</div>