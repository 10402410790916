<!----------------------------------------------------------->
<!--                    DESKTOP                            -->
<!----------------------------------------------------------->
<!-- Datapoints -->
<div [id]="datapoint.id">
    <div class="datapoint {{datapoint.class}}"
        [ngClass]="{ 'clickable' : datapoint.action != null , 'linkToPreviousSynoptic' : datapoint.type == 'linkToPreviousSynoptic', 'hidePaginator': datapoint.hide, 'limit-height': datapoint['limit-height'] }"
        (click)="dpClick(datapoint)" matTooltip="{{ datapoint.tooltip | translate }}">

        <!-- Title -->
        <div class="title-container" fxLayout="row" fxLayoutAlign="start center">

            <!-- title alarm -->
            <div *ngIf="datapoint.alarm != null" fxLayout="row" fxLayoutAlign="start center">
                <mat-icon svgIcon="circle" class="icon synoptic"
                    [ngStyle]="{'fill': datapoint.alarmColor != null ? datapoint.alarmColor : 'gray'}">
                </mat-icon>
            </div>

            <!-- icon (before title) -->
            <div *ngIf="datapoint.icon != null" fxLayout="row"
                [fxLayoutAlign]="datapoint.type == 'linkToPreviousSynoptic' ? 'center center' : 'start center'">
                <mat-icon>{{ datapoint.icon }}</mat-icon>
            </div>

            <!-- title (if not undefined) -->
            <span *ngIf="datapoint.title != 'undefined'" class="title synoptic">{{ datapoint.title | translate }}</span>

            <!-- title unit -->
            <span *ngIf="datapoint.titleUnit != null" class="title synoptic">{{ datapoint.titleUnit | translate
                }}</span>

            <!-- icon2 (after title) -->
            <div *ngIf="datapoint.icon2 != null" fxLayout="row" fxLayoutAlign="center">
                <mat-icon style="margin-left: .5rem;">{{ datapoint.icon2 }}</mat-icon>
            </div>

            <!-- additional space if the title is type 'alarm' -->
            <span fxFlex *ngIf="datapoint.titleType == 'alarm'"></span>

            <!--------------------------------------------->
            <!-- automatic icon based on action and type -->
            <!--------------------------------------------->
            <div fxLayout="row" fxLayoutAlign="center center"
                [ngStyle.gt-sm]="{'margin-left': datapoint.title != 'undefined' ? '8px' : '0'}">

                <!-- alarm: circle svg -->
                <mat-icon *ngIf="datapoint.type == 'alarm' || datapoint.titleType == 'alarm'" svgIcon="circle"
                    class="icon synoptic"
                    [ngStyle]="{'fill': datapoint.alarmColor != null ? datapoint.alarmColor : 'gray'}">
                </mat-icon>

                <!-- chart / annealerCurves: timeline -->
                <mat-icon
                    *ngIf="datapoint.action == 'chart' || datapoint.action == 'annealerCurves'">timeline</mat-icon>

                <!-- synopticLink: open_in_new -->
                <mat-icon
                    *ngIf="datapoint.action == 'synopticLink' && datapoint.type != 'linkToPreviousSynoptic'">open_in_new
                </mat-icon>

                <!-- externalLink: open_in_new -->
                <mat-icon *ngIf="datapoint.action == 'externalLink'">open_in_new</mat-icon>

                <!-- imageDialog: web -->
                <mat-icon *ngIf="datapoint.action == 'imageDialog'">web</mat-icon>

                <!-- linkToPreviousSynoptic: arrow_left -->
                <mat-icon *ngIf="datapoint.type == 'linkToPreviousSynoptic'" svgIcon="arrow_left"></mat-icon>

                <!-- synopticList: list -->
                <mat-icon *ngIf="datapoint.action == 'synopticList'" class="clickable">list</mat-icon>

                <!-- table with coloration: circle -->
                <mat-icon *ngIf="datapoint.type == 'table' && datapoint.coloration != null" svgIcon="circle"
                    class="icon synoptic"
                    [ngStyle]="{'fill': datapoint.bgColor != null ? datapoint.bgColor : 'gray'}"></mat-icon>

                <!-- table with datapointList: curtain_right -->
                <mat-icon *ngIf="datapoint.type == 'table' && datapoint.datapointList != null" svgIcon="curtain_right"
                    class="icon synoptic clickable" (click)="dpClick(datapoint, true)" [id]="datapoint.id + '-table'">
                </mat-icon>

                <!-- customIcon -->
                <ff-icon *ngIf="datapoint.showCustomIcon && datapoint?.customIcon?.icon != null"
                    [icon]="datapoint.customIcon.icon"></ff-icon>

            </div>
        </div>

        <!-- Divider if 'tablehr' -->
        <div *ngIf="datapoint.tablehr" class="divider"></div>

        <!-- Value Datapoint -->
        <div fxLayout="column" [class.text-center]="datapoint.align == 'center'"
            *ngIf="!datapoint.hide && datapoint.type != 'linkToPreviousSynoptic'">
            <span class="value synoptic"
                *ngIf="datapoint.value != null && datapoint.type != 'table' && ( datapoint.type != 'alarm' || datapoint.showArticle ) "
                style="margin-top: 4px;" [ngStyle]="{ 'margin-left': datapoint.alarm != null ? '28px' : '4px' }">
                {{ datapoint.value }}
            </span>
            <span class=" synoptic"
                *ngIf="datapoint.value2 != null && datapoint.type != 'table' && ( datapoint.type != 'alarm' || datapoint.showArticle )"
                style="margin: 4px 0 0 4px;">{{ datapoint.value2 }}
            </span>
        </div>

        <!-- Table Datapoint -->
        <div *ngIf="datapoint.type == 'table' && datapoint.rows != null && !datapoint.hide && !datapoint.noTablehr"
            class="divider"></div>

        <div *ngIf="datapoint.type == 'table' && !datapoint.hide" class="datapoint-table">

            <table class="w100">

                <tr *ngIf="datapoint.headers != null">
                    <th *ngFor="let h of datapoint.headers; let headerIdx = index"
                        [class.text-center]="h.align == 'center'" [class.clickable]="h.action != null"
                        [ngClass]="h.class" (click)="dpClick(h)" [id]="datapoint.id + '-header-' + headerIdx">

                        <span fxLayout="row" fxLayoutAlign="{{ h?.align == 'center' ? 'center' : 'start' }} center">

                            {{ (h?.label ?? h) | translate }}

                            <mat-icon *ngIf="h.action == 'synopticLink' && h.type != 'linkToPreviousSynoptic'"
                                class="icon synoptic">
                                open_in_new
                            </mat-icon>

                        </span>
                    </th>
                </tr>

                <tr *ngFor="let r of datapoint.rows; let rowIndex = index">

                    <div *ngIf="!r.hide" style="display: contents;">
                        <td *ngFor="let c of r; let colIndex = index"
                            [id]="datapoint.id + '-col-' + rowIndex + '-' + colIndex" (click)="dpClick(c)"
                            [class.clickable]="c.action != null" [ngClass]="c.class"
                            [class.text-center]="c.align == 'center'">
                            <div fxLayout="row" fxLayoutAlign="{{ c.align == 'center' ? 'center' : 'start' }} center">

                                <span *ngIf="c.type == null && c.action != 'embeddedLink'" class="value-table synoptic">
                                    {{ c.value != null ? c.value : '-' }}
                                </span>

                                <span *ngIf="c.type == 'alarm'">
                                    <mat-icon svgIcon="circle" class="icon synoptic small"
                                        [ngStyle]="{'fill': c.alarmColor != null ? c.alarmColor : 'gray'}">
                                    </mat-icon>
                                </span>

                                <span *ngIf="c.action == 'synopticLink' && c.type != 'linkToPreviousSynoptic'">
                                    <mat-icon class="icon synoptic">open_in_new</mat-icon>
                                </span>

                                <span *ngIf="c.action == 'embeddedLink'">
                                    <ff-icon [icon]="c.icon ?? 'open_in_new'" class="synoptic" big="true"></ff-icon>
                                </span>

                                <ff-icon *ngIf="c.iconWarning != null" [icon]="c.iconWarning" right matTooltip="{{ 
                                    (c.iconWarningType == 'upper' ? 'REMOTE_MONITORING.REDUCTION_EXCEEDED' : 'REMOTE_MONITORING.REDUCTION_ZERO') 
                                    | translate:{ x: (c.iconWarningType == 'upper' ? datapoint.maxWireReduction : datapoint.minWireReduction)*100 }
                                    }}">
                                </ff-icon>
                            </div>
                        </td>
                    </div>

                </tr>

            </table>

        </div>

    </div>
</div>