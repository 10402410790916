import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { FiltersDialogComponent } from 'src/app/components/filters-dialog/filters-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit, OnDestroy {

  public isAllowedUser: boolean = true;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;
  public alarmsInfo: any;
  public alarmsColumns: string[] = [];

  public breadcrumb: any;
  public tabs: any;

  public excludeLine: boolean = true;

  public parameters: any;

  public aggregations: any;
  public aggregationsPayload: any;

  public machineId: any;
  public machine: any;
  public machineSelectedSub: Subscription;

  public availableMachines: any;

  public pollingTime: any;
  public pollingAlarms: any;

  public interval: any;
  public intervalConfig: any;

  public filterButtons: any;
  public sectionName: any = 'alerts';

  public searchAlarms: any;
  public alarms: any;
  public alarmsData = new MatTableDataSource<any[]>();

  public sliderConf: any = {};

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  private sort!: MatSort;
  @ViewChild(MatSort) set MatSort(ms: MatSort) {
    this.sort = ms;
    this.setSort();
  }

  setSort() {
    this.alarmsData.sort = this.sort;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getKPIConfigs, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getAlarmsPolling, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public cacheService: CacheService,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.alarmsInfo = this.appConfig.alerts != null ? this.appConfig.alerts.tableInfo : [];

    this.alarmsColumns.push('icon');
    this.alarmsInfo.forEach((element: any) => this.alarmsColumns.push(element.variable));

    this.breadcrumb = ['RBA.TITLE', "ALERTS.TITLE"];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = 60000;
    this.pollingAlarms = Subscription;

    // this.interval = this.intervalService.getIntervalById('today');

    // this.intervalConfig = {
    //   list: this.intervalService.getDefaultIntervals(2),
    //   selected: this.interval
    // };

    this.alarms = {
      list: [],
      filtered: [],
      selected: null,
      pageOptions: [25, 50, 100],
      pageSize: 50,
      search: "",
      switch: {
        checked: false,
        checkedLabel: 'on',
        uncheckedLabel: 'off',
      },
    };

    // this.alarmsData = new MatTableDataSource<any[]>([]);

    // init slider
    this.initSlider(this);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-sn-alerts");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET KPI CONFIGS

  public getKPIConfigs(_this: any) {
    try {
      _this.internalDataService.getKPIConfigs(_this, _this.machineId);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingAlarms, this.getAlarmsPolling, this.getAlarms, this.machine.timezone, 5, 6) };

  openAggrDialog(aggr: any) {

    try {
      this.pollingAlarms.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        machineReference: this.machine.machineReference,
        machineSelected: (this.availableMachines && this.availableMachines.list) ? this.availableMachines.list.join(';') : null,
        aggregations: this.aggregations,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        // console.log('afterClosed', result);
        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
      }

      this.pageState.next(isClickedSelect ? 4 : 5);

      if (!this.interval.enabledPolling) {
        this.getAlarms(this, 0);
      } else {
        this.getAlarmsPolling(this);
      }

    });
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // alarms

  // polling
  getAlarmsPolling(_this: any) {
    try {

      // Calculate interval with machine timezone
      if (_this.interval == null) {
        if (_this.cacheService.get("intervalLong") == null) {

          _this.interval = _this.intervalService.getIntervalById('today', _this.machine.timezone);

          _this.intervalConfig = {
            list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
            selected: _this.interval
          };

          _this.cacheService.set("intervalLong", _this.intervalConfig);

        } else {
          _this.intervalConfig = _this.cacheService.get("intervalLong");
          _this.interval = _this.cacheService.get("intervalLong").selected;
        }
      }

      if (_this.pollingTime > 0) {
        _this.pollingAlarms = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getAlarms(_this, count);
        });
      } else {
        _this.getAlarms(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get alarms
  getAlarms(_this: any, count?: any) {
    try {

      // console.log('getAlarms', _this.interval)
      let url = "/apif/alerts/history/" + _this.machineId;

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;
      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone,
      };

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      if (_this.availableMachines != null) {
        payload.machineList = _this.availableMachines?.list;
      } else if (_this.machine.machineReference) {
        query.machineId = _this.machine.machineReference;
      }

      // if (_this.machine.machineReference) { query.machineId = _this.machine.machineReference; }

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingAlarms))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.dispatcherService.getDispatch(_this, 300);
            _this.alarms.list = _this.parseAlarmsTable(data.body);

            if (count == 0) {
              _this.filterButtons = _this.filterService.buildFilterButtons(_this, _this.appConfig.machineMonitoring.machineInfos, _this.alarms.list);
            }
            // setTimeout(() => {
            _this.filterAlarms();
            // }, 50);

          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseAlarmsTable(data: any) {
    if (data != null && data.length > 0) {
      data.forEach((element: any) => {
        element.isActive = element.timeEnd == null;
        element.timeStartHours = moment(element.timeStart).diff(moment(element.timeStart).startOf("day"), 's');
        element.timeEndHours = element.timeEnd != null ? moment(element.timeEnd).diff(moment(element.timeEnd).startOf("day"), 's') : 0;
        // element.code = this.getAlarmStr(element, 'code');
        // element.message = this.getAlarmStr(element, 'message');
        let parameters = null;
        try {
          parameters = this.parameters.kpiParameters.list;
        } catch (error) { }

        if (element.type == 'cloths' && element.param5 != null && element.param5 != '-') element.forcedType = element.param5;

        element.message = this.internalDataService.getAlertTranslation(element.type, this.filterService.removeKeysWithCustomRule(element, (x: any) => x[0].startsWith('param')), parameters);
        element.isCloth = element.type == 'cloths' && element.param1 != null && Array.isArray(element.param1) && element.param1.length > 0;
        if (element.isCloth) element.clothsSplitted = element.param1.map((cloth: any) => this.translate.instant("CLOTH_MONITORING.CLOTH") + ': ' + cloth.id + ', ' + this.translate.instant("CLOTH_MONITORING.SAP_CODE") + ': ' + cloth.sapCode + ";").join("\n");
        element.type = this.internalDataService.getAlertType(element.type, 'type');
        element.typeIcon = this.internalDataService.getAlertIcon(element.forcedType != null ? element.forcedType : element.type);

        // element.message = "alerts." + element.type + ".description";
        // element.name = "alerts." + element.type + ".name";

        element.timeStartP = this.filterService.parseMoment(element.timeStart, 'default');
        element.timeEndP = this.filterService.parseMoment(element.timeEnd, 'default');
        element.aggr0P = this.translate.instant('MACHINES.' + element.aggr0) != 'MACHINES.' + element.aggr0 ? this.translate.instant('MACHINES.' + element.aggr0) : element.aggr0;
        element.machineP = this.translate.instant('MACHINES.' + element.machine) != 'MACHINES.' + element.machine ? this.translate.instant('MACHINES.' + element.machine) : element.machine;
        element.durationP = (element.duration != null) ?
          this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss') :
          // this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss', element.timeStart, element.timeEnd);
          '-';

      });
      return data;
    }
    return []
  }

  getAlarmStr(element: any, attribute: string) {
    let codeTrKey = 'alarms.' + this.machineId + '.' + element.alarmCode + '.' + attribute;
    let codeTr = this.translate.instant(codeTrKey);
    if (codeTr === codeTrKey) {
      // custom attribute not found
      codeTrKey = 'alarms.default.' + element.alarmCode + '.' + attribute;
      codeTr = this.translate.instant(codeTrKey);
      if (codeTr === codeTrKey) {
        // default attribute not found
        codeTr = element.alarmCode;
      }
    }
    return codeTr;
  }

  alarmSwitchChange(value: boolean) {
    this.alarms.switch.checked = value;
    this.filterAlarms();
  }

  filterAlarms() {

    let filterVariables = this.appConfig.machineMonitoring.filterVariables != null ? this.appConfig.machineMonitoring.filterVariables : ["code", "message"];

    let filtered: any = [];

    try {
      filtered = this.alarms.list.filter((x: any) => this.alarms.switch.checked ? x.isActive : true);
    } catch (error) {
      console.log(error);
    }

    try {
      // filtered = filtered.filter((data: any) => filterVariables.some((x: any) => this.searchAlarms == null || this.searchAlarms == '' ? true : data[x].toLowerCase().includes(this.searchAlarms.toLowerCase())));
      filtered = filtered.filter((data: any) => filterVariables.some((x: any) => this.searchAlarms == null || this.searchAlarms == '' ? true : String(data[x]).toLowerCase().includes(this.searchAlarms.toLowerCase())));
    } catch (error) {
      console.log(error);
    }

    try {
      if (this.filterButtons != null && this.filterButtons.length > 0) {
        this.filterButtons.forEach((filterButton: any) => {
          filtered = filtered.filter((data: any) => filterButton.options.filter((opt: any) => opt.selected).some((opt: any) => String(data[filterButton.variable]).includes(opt.id)));
        });
      }
    } catch (error) {
      console.log(error);
    }

    if (this.sliderConf.min != null && this.sliderConf.max != null) {
      try {
        filtered = filtered.filter((x: any) => x.timeStartHours >= this.sliderConf.min && x.timeEndHours <= this.sliderConf.max);
      } catch (error) {
        console.log(error);
      }
    }

    // try {
    //   console.log(this.sort);
    // } catch (error) {
    //   console.log(error);
    // }

    filtered.sort(
      this.filterService.sortByProperty(
        (this.sort != null && this.sort.active != null) ? this.sort.active : "timeStartP",
        'desc',
        // (this.sort != null && this.sort._direction != null) ? this.sort._direction : "desc",
        true
      )
    );

    this.alarms.filtered = filtered;
    // this.alarmsData = new MatTableDataSource<any>(filtered);

    // // this.alarmsData.filterPredicate = (data: any, filters: any) => {
    // //   let show = false;
    // //   if (filters.all) show = true;
    // //   else if (!data.timeEnd) show = true;
    // //   return show;
    // // }

    // // let alarmsFilters: any = {
    // //   all: !this.alarms.switch.checked,
    // //   search: null
    // // };

    // // this.alarmsData.filter = alarmsFilters;

    // if (this.alarmsData.paginator) this.alarmsData.paginator.firstPage();

    // setTimeout(() => {
    //   this.alarmsData.paginator = this.paginator;
    //   this.alarmsData.sort = this.sort;
    // }, 100);

    const cd = new MatTableDataSource<any[]>();

    // if (cd.paginator) cd.paginator.firstPage();



    cd.sort = this.sort;
    cd.paginator = this.paginator;
    cd.data = filtered;




    // setTimeout(() => {
    this.alarmsData = cd;
    // }, 2500);

    // console.log(this.alarmsData);

  }

  initSlider(_this: any) {
    _this.sliderConf = {
      show: true,
      max: _this.sliderConf.max != null ? _this.sliderConf.max : 24 * 3600,
      min: _this.sliderConf.min != null && !isNaN(_this.sliderConf.min) ? _this.sliderConf.min : 0,
      options: {
        floor: 0,
        ceil: 24 * 3600,
        step: 300,
        translate: function (index: any) {
          try {
            return _this.filterService.parseTime(index, 's', 'HH:mm');
          } catch (error) {
            return index;
          }
        },
      },
      userChangeEnd: function (event: any) {
        // console.log('slider change', event, _this.sliderConf.min, _this.sliderConf.max);
        _this.filterAlarms();
      }

    };

  }

  openFilterDialog(button: any) {
    // console.log(button)
    let filtersDialog = this.dialog.open(FiltersDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(button.label),
        variable: button.variable,
        options: button.options
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') {
        // console.log('afterClosed', result);

        button.options = this.clonerService.deepClone(result?.options);
        this.filterAlarms();

      }
    });
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pageState.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.pollingAlarms.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
  }

}