import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

import { IntervalService } from 'src/app/services/interval.service';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ClonerService } from 'src/app/services/clone.service';

@Component({
  selector: 'app-gca-variables-config',
  templateUrl: './gca-variables-config.component.html',
  styleUrls: ['./gca-variables-config.component.scss'],
})
export class GcaVariablesConfigComponent implements OnInit {

  public isAllowedUser: boolean = true;
  public isAllowedUserWrite: boolean = true;

  public unparsedConfig: any;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public pollingTime: any;
  public pollingMachines: any;

  public monitoringData: any;

  public cycleInfoConfig: any;
  public cycleData: any;

  public dialogData: any;

  public allPhases: any;
  public allPhasesCopy: any;

  public phaseSelected: boolean = false;
  public phaseVariables: any;

  public comparedCycleDataConfig: any;
  public comparedCycleData: any;
  public comparedCycleInfos: any;
  public comparedCycleId: any;
  public isComparedCycle: boolean = false;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getStateTimelinePolling, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public snackBar: MatSnackBar,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    // TODO AGGIUNGERE AGGREGAZIONI E FILTRI
    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.monitoringData = null;


    this.breadcrumb = ['CYCLE_TRACEABILITY.TITLE', 'GCA_VARIABLES_CONFIG.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs('cycleTraceability');

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        this.breadcrumb[2] = value.machineName;
        this.internalDataService.setBreadcrumb(this.breadcrumb);
      }
    });

    this.pollingTime = 0;
    this.pollingMachines = Subscription;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getAssetInfo(_this: any) {

    try {
      _this.isAllowedUserWrite = _this.internalDataService.getSpecificPermission("mat-gca-vc-rw");
    } catch (error) { console.log(error) }

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(["mat-gca-vc-r", "mat-gca-vc-rw"], 'or');
    } catch (error) { console.log(error) }


    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'cycleTraceability');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getCycleTimelineWidgets(_this: any) {

    try {

      const sources40F = _this.appInfo.sources40F != null ? _this.appInfo.sources40F : 'assets/config/';

      _this.apiService.sendGetRequest(sources40F + 'dashboard-cycle-timeline.json').pipe(
        retryWhen(_this.apiService.genericRetryStrategy()),
        catchError(error => {

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError(
            'Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.dashboardConfig = data.body;

            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SYNOPTIC CONFIG

  // polling
  getStateTimelinePolling(_this: any) {
    try {

      if (_this.pollingTime > 0) {
        _this.pollingMachines = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getStatesTimeline(_this, count);
        });
      } else {
        _this.getStatesTimeline(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getStatesTimeline(_this: any, count?: any) {
    try {

      let url = '/apif/gca-variables-config/' + _this.machineId;

      let query: any = null;

      _this.apiService.sendGetRequest(url, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
      ).subscribe(
        (data: any) => {
          // console.log(data.body);

          _this.unparsedConfig = _this.clonerService.deepClone(data.body);

          _this.parsePhasesResponse(_this, data.body != null ? data.body : {});

          if (count == 0) {
            _this.dispatcherService.getDispatch(_this, 300);
          }
        }
      );

    } catch (error) {
      console.log(error);
    }

  }

  parsePhasesResponse(_this: any, phases: any) {

    _this.allPhasesCopy = _this.clonerService.deepClone(phases);
    // _this.cycleData.type = phases.findIndex((phase: any) => phase.outputPhaseId == 13) != -1 ? 2 : 0;
    _this.cycleType = 0;

    let allPhases: any = [];
    if (_this.machine.profile.phaseVariables != null) {
      let phaseColumns: any = [];
      Object.entries(_this.machine.profile.phaseVariables)
        // .filter((pvc: any) => {
        //   console.log(pvc);

        //   return phases.findIndex((p: any) => {
        //     let phaseConfig = null;
        //     let phaseId: any = null;
        //     try {
        //       phaseConfig = _this.machine.profile.phases.find((x: any) => x.inputPhases.includes(p.phaseId));
        //       if (phaseConfig != null) phaseId = phaseConfig.outputPhaseId;
        //     } catch (error) {
        //       console.log(error);
        //     }
        //     return phaseId == pvc[0];
        //   }) != -1
        // })
        .forEach((props: any) => {

          if (_this.isAllowedUserWrite) phaseColumns.push('icon');

          let phaseId = props[0];

          let phaseConfig = null;
          let phaseName = null;
          let phaseColor = null;

          try {
            phaseConfig = _this.machine.profile.phases.find((x: any) => x.outputPhaseId == phaseId);
            if (phaseConfig != null) {
              phaseName = _this.translate.instant(phaseConfig.label);
              phaseColor = phaseConfig.color;
            }

          } catch (error) {
            console.log(error);
          }

          try {
            _this.appConfig.cycleDetails.phaseVariables.phasesInfo.forEach((x: any) => phaseColumns.push(x.variable));
          } catch (error) {
            console.log(error);
          }

          allPhases.push({
            phaseColumns: phaseColumns,
            phaseInfos: _this.appConfig.cycleDetails.phaseVariables.phasesInfo,
            phases: {
              list: [],
              expanded: true,
            },
            phaseId: phaseId,
            phaseName: phaseName,
            phaseColor: phaseColor,
            phaseData: new MatTableDataSource<any[]>([]),
          });

          phaseColumns = [];

        });
    }

    _this.allPhases = allPhases;

    _this.allPhases.forEach((p: any) => {
      let actualPhaseId = p.phaseId;
      let actualPhase = phases[p.phaseId];

      let stateConfig = null;
      let color = null;
      let label = null;
      let phaseId: any = null;
      try {
        stateConfig = _this.machine.profile.phases.find((x: any) => x.inputPhases.map(x => String(x)).includes(actualPhaseId));
        if (stateConfig != null) {
          color = stateConfig.color;
          label = _this.translate.instant(stateConfig.label);
          phaseId = stateConfig.outputPhaseId;
        }
      } catch (error) {
        console.log(error);
      }

      let phaseList: any = [];
      let phaseVars: any = _this.machine.profile.phaseVariables[phaseId];

      let phaseColumns: any = ['icon'];

      try {
        _this.appConfig.cycleDetails.phaseVariables.phasesInfo.forEach((x: any) => phaseColumns.push(x.variable));
      } catch (error) {
        console.log(error);
      }

      phaseVars.forEach((pc: any) => {

        let vIdx = actualPhase != null ? actualPhase.findIndex(x => x.id == pc.variable) : -1;
        phaseList.push({
          id: pc.variable,
          enabled: vIdx != -1,
          value: vIdx != -1 ? (actualPhase[vIdx].weight != null ? Number(_this.filterService.parseGaugeValue(actualPhase[vIdx].weight, 0, 100)) : null) : null,
          // label: _this.translate.instant("DATAPOINTS." + this.machineId + '.' + pc.variable.split(".")[0] + phaseId + "." + pc.variable.split(".")[1]),
          label: _this.internalDataService.parseDatapointLabel(pc.variable.split(".")[0] + phaseId, pc.variable.split(".")[1], _this.machineId),
          unit: "%",
        });
      });

      let idx = _this.allPhases.findIndex((x: any) => x.phaseId == phaseId);

      if (idx != -1) {
        _this.allPhases[idx].phases.list = phaseList;
        _this.allPhases[idx].phaseData = new MatTableDataSource<Object>(phaseList);
      }

    });
  }

  updateGCASettings() {

    let _this = this;
    let phasesFinal: any = {};

    try {
      this.allPhases.filter(x => x.phases.list.filter(x => x.enabled).length > 0).forEach((phase: any) => {
        phasesFinal[phase.phaseId] = phase.phases.list.filter(x => x.enabled).map(x => {
          return {
            id: x.id,
            weight: x.value != null ? x.value / 100 : null,
          };
        });
      });
    } catch (error) {
      console.log(error);
    }

    let payload = phasesFinal;
    // let payload = {
    //   phases: phasesFinal,
    //   downcast: this.unparsedConfig != null ? this.unparsedConfig.downcast : null,
    // };

    console.log(payload);

    try {

      let url = '/apif/gca-variables-config/' + this.machineId;

      let query: any = null;

      _this.apiService.sendPostRequest(url, payload, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          _this.snackBar.open(_this.translate.instant('GCA_VARIABLES_CONFIG.RESPONSE.' + (error.status == 409 ? '409' : '500')), '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['snackbar', 'fail']
          });
          return _this.internalDataService.parseStandardHTTPError(_this, error);
        })
      ).subscribe(
        (data: any) => {
          // console.log(data.body);

          _this.snackBar.open(_this.translate.instant('GCA_VARIABLES_CONFIG.RESPONSE.204'), '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['snackbar', 'success']
          });

          _this.unparsedConfig = _this.clonerService.deepClone(data.body);

          if (data.body != null) { _this.parsePhasesResponse(_this, data.body) };

        }
      );

    } catch (error) {
      console.log(error);
    }

  }

  checkUpdates(exitCheck: boolean = false) {
    try {

      let isAtLeastOneDifferentValueAllPhases = true;
      try {
        if (this.unparsedConfig != null && Object.keys(this.unparsedConfig).length > 0) {

          let isDifferentLengthAllPhases = this.allPhases.filter(x => x.phases.list.filter(x => x.enabled).length > 0) != Object.keys(this.unparsedConfig).length;

          isAtLeastOneDifferentValueAllPhases = (isDifferentLengthAllPhases || !Object.entries(this.unparsedConfig).every(p => {

            let phaseId = p[0];
            let phaseVars: any = p[1];

            let currentPhase = this.allPhases.find(x => x.phaseId == phaseId);
            if (currentPhase != null) {

              let isDifferentLength = currentPhase.phases.list.filter(x => x.enabled).length != phaseVars.length;

              let isAtLeastOneDifferentValue = (currentPhase.phases.list.filter(x => x.enabled).some(pv => {
                let pIdx = phaseVars.findIndex(x => x.id == pv.id);
                if (pIdx != -1) return pv.value != phaseVars[pIdx].weight * 100;
                return true;
              }) || isDifferentLength);
              return !isAtLeastOneDifferentValue;
            }
            return false;
          }));
        }
      } catch (error) {
        console.log(error);
      }

      let isAtLeastOneWrongValue = this.allPhases.filter(x => x.phases.list.length > 0).some(phase => phase.phases.list.filter(x => x.enabled).some(x => x.value == null || x.value < 1 || x.value > 100));

      let isEmptyArray = this.allPhases.filter(x => x.phases.list.filter(x => x.enabled).length > 0).length == 0;

      return exitCheck ? !isAtLeastOneDifferentValueAllPhases : (isAtLeastOneWrongValue || !isAtLeastOneDifferentValueAllPhases || isEmptyArray);
    } catch (error) {
      return true;
    }

  }

  canDeactivate() {
    return this.checkUpdates(true);
  }

  toggleAll(phase) {
    try {
      let action = !phase.phases.list.every(x => x.enabled);
      phase.phases.list.forEach(p => p.enabled = action);
    } catch (error) {
      console.log(error);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pageState.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.pollingMachines.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
  }

}