import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'ff-export-table',
  templateUrl: './export-table.component.html',
  styleUrls: ['./export-table.component.scss']
})

export class ExportTableComponent implements OnInit {

  @Input() config: any;

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService
  ) { }

  ngOnInit(): void { }

  ngOnDestroy(): void { }

  exportTable(): void {
    try {
      // this.csvState = 1;

      let url = '/apif/export-table';
      let query: any = {
        type: this.config.type ?? 'csv',
        title: this.config.title
      };
      let payload = this.config.payload;

      this.apiService.download(url, payload, query)
      // this.csvState = 0;

    } catch (error) {
      console.log(error)
      // this.csvState = 2;
      // let errorData = {
      //   type: 0,
      //   status: 500,
      //   message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      // };
      // this.dispatcherService.getDispatch(this, 301, errorData);
    }
  }

}
