import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {
  public saveState: any;
  public message: any;

  constructor(
    @Optional() @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private _snackRef: MatSnackBarRef<SnackbarComponent>
  ) { 
    if(this.data.saveState) this.saveState = this.data.saveState
    console.log("sb", this.data)
    if(this.data.message) this.message = this.data.message
  }

  ngOnInit(): void {
  }

}
