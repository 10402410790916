import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfLoggingService } from 'src/app/services/ff-logging.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-add-maintenance-component-dialog',
  templateUrl: './add-maintenance-component-dialog.component.html',
  styleUrls: ['./add-maintenance-component-dialog.component.scss']
})
export class AddMaintenanceComponentDialogComponent implements OnInit, OnDestroy {

  public state: any = 0;
  public statesConfig: any = {
    data: 1,
  };

  groups: any = [];
  subgroups: any = [];
  components: any = [];

  newComponent: any = {};

  srcResult: any;
  fileInfos: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<AddMaintenanceComponentDialogComponent>,
    public http: ApiService,
    public intervalService: IntervalService,
    public clonerService: ClonerService,
    public translate: FfTranslateService,
    public apiService: ApiService,
    public filterService: FiltersService,
    public internalDataService: InternalDataService,
    public additionalDialog: MatDialog,
    public logger: FfLoggingService,
  ) { }

  ngOnInit() {

    this.state = 0;

    // Populate all the lists that have a closed list from the machine profiles, with the following config:
    // - editableInDialog:  true
    // - dialogEditType:    'closedList'
    // - listFromBE:        false || none
    // - type:              'configFromProfile'
    try {
      let closedLists = this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'closedList' && !x.listFromBE);
      closedLists = closedLists?.reduce((acc, info) => {
        if (info?.type != 'configFromProfile' || this.dialog.profile == null) {
          acc.push(info);
          return acc;
        }

        let mappingKey = info?.mappingConfig?.key;
        if (typeof mappingKey === 'string' && mappingKey.includes('.')) {
          mappingKey = mappingKey.split('.');
        }

        let list = [];
        if (Array.isArray(mappingKey)) {
          list = this.clonerService.deepClone(this.dialog.profile);
          for (let m_key of mappingKey) {
            list = list?.[m_key];
          }
        } else {
          list = this.clonerService.deepClone(this.dialog.profile?.[mappingKey ?? 'timeStates']);
        }

        if (info?.onSelect != null) this.onSelectFromClosedList(info, list.find(x => x?.id == this.dialog.row[info?.variable]));

        this.parseObjectTypeClosedList(info, list);
        acc.push(info);

        return acc;
      }, []);
      closedLists?.filter(x => x?.listOptions?.length == 1).forEach(list => this.dialog.row[list.variable] = list.listOptions[0]);
    } catch (error) { console.log(error) }

    // // // // // // // // // // // // // // // // // // // // // // 
    // COMPONENTS 
    // // // // // // // // // // // // // // // // // // // // // // 

    this.logger.log(this.dialog);

    this.statesConfig.data = 0;

    (async () => {
      let endpointUrl = this?.dialog?.endpointUrl?.replaceAll("{machineId}", this.dialog.machineId);

      let query: any = {
        tz: this.dialog?.machine?.timezone,
        lang: this.translate?.currentLang ?? 'en',
      };

      let payload: any = {}

      try {

        let method = 'POST';
        if (endpointUrl.includes(':')) {
          endpointUrl = endpointUrl.split(':');
          method = endpointUrl[0];
          endpointUrl = endpointUrl[1];
        }

        let requestSent = null;
        if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
        else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
        else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

        const data: any = await requestSent.toPromise();

        //------------------------------------------------------------------------//
        // RESPONSE PARSING

        let copyData: any = this.clonerService.deepClone(data?.body ?? {});

        this.populateDropdowns(copyData);

        this.statesConfig.data = 1;
        this.checkState();

      } catch (error) {
        this.statesConfig.data = 1;
        this.checkState();
      }

    })();

  }

  populateDropdowns(data) {
    this.groups = this.clonerService.deepClone(data?.hierarchy);
  }

  selectGroup(groupId) {

    try { delete this.newComponent.subgroupId } catch (error) { console.log(error) }

    let group = this.groups.find(x => x.id == groupId);

    this.subgroups = group?.subgroups.reduce((subgroups, subgroup) => {

      let subgroupObj = {
        label: subgroup?.subgroup,
        subgroupId: subgroup?.id,
        groupId: group?.id,
      };

      subgroups.push(subgroupObj);
      return subgroups;
    }, []);

  }

  onSelectFromClosedList(info: any, selectedOption: any) {

    if (info?.onSelect == null) return;

    let onSelect = info.onSelect;

    let val = selectedOption?.[onSelect.key] ?? '-';
    this.newComponent[onSelect.as] = onSelect.translate ? this.translate.instant(val) : val;
    // return selectedOption.id;
  }

  getUnit(info: any) {
    if (info.unitKey == null) return this.translate.instant(info.unit);
    else return this.translate.instant(this.newComponent?.[info.unitKey]);
  }

  checkState() {
    try { this.state = Object.values(this.statesConfig).every(x => x == 1) ? 1 : 0 }
    catch (error) {
      console.log(error);
      return 0;
    }
  }

  onDaySelection(date: any, key: string) { try { this.newComponent[key] = JSON.parse(JSON.stringify(moment(date.value))) } catch (error) { console.log(error) } }

  parseObjectTypeClosedList(info: any, data: any) {

    info.listOptions = data?.filter(x => {

      // If element is not object, don't filter
      if (typeof x != 'object') return true;

      // If object doesn't have key "filterForSpecificVariableAndValue", don't filter
      if (info?.filterForSpecificVariableAndValue == null) return true;

      // Return only elements that have value associated to the specified "variable" equal to the specified "value"
      return x?.[info?.filterForSpecificVariableAndValue?.variable] == info?.filterForSpecificVariableAndValue?.value;
    })
      // Map on nestedKey if present
      .map(x => info?.nestedKeyToMap ? this.translate.instant(x?.[info?.nestedKeyToMap] ?? '-') : x) ?? [];

    if (info?.nestedKeysToKeep?.length > 0) {
      info.listOptionsDict = data?.reduce((acc, val) => {

        if (typeof val == 'object') {
          let obj: any = {}

          info?.nestedKeysToKeep?.forEach(k => {
            if (typeof k == 'object') {
              // If "primaryKey", substitute the id in the incoming object with the variable from info
              if (k?.primaryKey) obj[info.variable] = this.translate.instant(val[k.id] ?? '-');
              // Else map the newId (if present) with the correct value
              else obj[k?.newId ?? k?.id] = val[k.id];
            }
            else obj[k] = val[k];
          });
          acc.push(obj);
        }

        return acc;
      }, []);
    }
  }

  ngOnDestroy() { }

  checkShowCondition(info, row) {

    if (info?.showCondition == null) return true;

    let condition = info.showCondition;

    let boolCond = true;

    try {

      if (condition?.every(cond => Array.isArray(cond) && cond?.length == 3)) boolCond = condition.some(c => this.filterService.parseDisabilityCondition(row, c));
      else if (condition?.length == 3) boolCond = this.filterService.parseDisabilityCondition(row, condition);

    } catch (error) { console.log(error) }

    // If the condition(s) is(are) not satisfied, delete the value associated with the variable
    if (!boolCond) row[info.variable] = null;

    return boolCond;

  }

  addNewGroup() {
    this.newComponent.isNewGroup = true;
    try {
      delete this.newComponent.groupId;
      delete this.newComponent.subgroupId;
    } catch (error) {

    }
  }

  addNewSubgroup() {
    this.newComponent.isNewSubgroup = true;
    try {
      delete this.newComponent.subgroupId;
    } catch (error) { }
  }

  removeNewGroup() {
    try {
      delete this.newComponent.isNewGroup;
      delete this.newComponent.newGroupLabel;
      delete this.newComponent.newSubgroupLabel;

    } catch (error) {
      console.log(error);

    }
  }

  removeNewSubgroup() {
    try {
      delete this.newComponent.isNewSubgroup;
      delete this.newComponent.newSubgroupLabel;
    } catch (error) {
      console.log(error);

    }
  }

  checkDisability() {

    let checkGroup = ["groupId", "newGroupLabel"].some(x => this.newComponent?.[x] != null && this.newComponent?.[x] != "");
    let checkSubgroup = ["subgroupId", "newSubgroupLabel"].some(x => this.newComponent?.[x] != null && this.newComponent?.[x] != "");

    let tableInfosCheck = !this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x.requiredInDialog)?.map(x => x.variable)?.every(x => (this.newComponent?.[x] != null && this.newComponent?.[x] != ''));
    return !checkGroup || !checkSubgroup || tableInfosCheck;
  }

  async addComponent() {
    console.log(this.newComponent);

    if (this.newComponent?.imageName == null) {
      this.newComponent.imageName = `${(this.newComponent?.groupId ?? this.newComponent?.newGroupLabel)}.${(this.newComponent?.subgroupId ?? this.newComponent?.newSubgroupLabel)}.${(this.newComponent?.componentCode ?? this.newComponent?.description)}.png`;
    } else {
      let isAlreadyPresentExtension = this.newComponent.imageName?.split(".")?.length > 1;
      if (!isAlreadyPresentExtension) {
        let extension = "png";
        try { extension = this.fileInfos[0].name.split(".").at(-1) } catch (error) { }
        this.newComponent.imageName = `${this.newComponent.imageName}.${extension}`;
      }
    }

    this.state = 0;

    let resp = null;
    let responseCode = 200;

    let url = this.dialog.buttonInfos?.addComponentEndpointUrl ?? '/apif/cmms/add-row/';

    try {
      resp = await this.apiService.sendPostRequestAwait(url + (this.dialog.rowType ?? 'component') + '/' + this.dialog?.machineId, this.newComponent);
      responseCode = resp.status;
    } catch (error) {
      console.log(error);
      responseCode = error.status;
    }

    // console.log(this.fileInfos);

    if (this.fileInfos?.length > 0) {

      let url = this.dialog.buttonInfos?.uploadAttachmentsEndpointUrl ?? '/apif/cmms/update-attachments/';

      let payload = new FormData();

      if (this.dialog.rowType == 'activity' && this.dialog?.buttonInfos?.attachmentsConfig?.multiple) {
        for (let file of this.fileInfos) {
          if (file.arrayBuffer != null) payload.append(file.name, new Blob([file.arrayBuffer]));
        }
      } else {
        // payload.append(this.newComponent?.[this.dialog.rowType ?? 'component'], new Blob([this.srcResult]));
        // payload.append((this.newComponent?.imageName ?? this.newComponent?.component ?? '-'), new Blob([this.fileInfos[0].arrayBuffer]));
        // let extension = "png";
        // try { extension = this.fileInfos[0].name.split(".").at(-1) } catch (error) { }
        // payload.append(`${(this.newComponent?.imageName ?? this.newComponent?.component ?? this.fileInfos[0].name.split(".")[0])}.${extension}`, new Blob([this.fileInfos[0].arrayBuffer]));
        payload.append(`${(this.newComponent?.imageName ?? this.newComponent?.component ?? this.fileInfos[0].name.split(".")[0])}`, new Blob([this.fileInfos[0].arrayBuffer]));
      }
      console.log(payload);

      try {
        await this.apiService.sendCustomPayloadWithArrayBuffer(url + (this.dialog.rowType ?? 'component') + '/' + this.dialog?.machineId + '/' + resp?.body?.id, payload);
      } catch (error) {
        console.log(error);
      }
    }

    this.internalDataService.openSnackBar(
      this.translate.instant('MAINTENANCE_STATE.ADD_COMPONENT_RESPONSE.' + (responseCode < 300 ? 'SUCCESS' : 'FAIL')),
      'right',
      'bottom',
      4000,
      '',
      [responseCode < 300 ? 'success' : 'fail']
    );

    this.dialogRef.close({});
  }


  onFileSelected() {

    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      let promises = [];
      for (let file of inputNode.files) {
        let filePromise = new Promise(resolve => {
          let reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = () => resolve({ arrayBuffer: reader.result, infos: file });
        });
        promises.push(filePromise);
      }
      Promise.all(promises).then((fileContents: any) => {
        // console.log({ fileContents });

        let fileInfos: any = [];

        for (let file of fileContents) {

          if (fileInfos.findIndex(x => x.name == file.infos.name) == -1) {
            let infos = {
              name: file.infos.name,
              type: file.infos.type,
              size: file.infos.size,
              arrayBuffer: file.arrayBuffer,
              sizeP: this.parseFileSize(file.infos.size),
            }
            fileInfos.push(infos);
          }
        }

        this.fileInfos = fileInfos;

      });

    }

  }

  parseFileSize(size: any) {
    if (size > 0) {

      if (size > 1000000)
        return Math.round(size / 1000000) + ' MB';
      else if (size > 1000)
        return Math.round(size / 1000) + ' kB';
      else
        return size + ' B';
    }
    return 'File is empty';
  }

  async closeDialog() {
    this.dialogRef.close(this.dialog?.row);
  }

}