<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <!-- toolbar tabs -->
  <div *ngIf="(materials?.length > 0 || availableMachines?.length > 0) && !isMobile" class="ff-toolbar pt-1"
    fxLayout="column" fxLayoutAlign="start stretch" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- consumables dropdown -->
    <div *ngIf="materials?.length > 0" class="select-box" fxLayout="column" fxLayoutAlign="center start"
      fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center" style="margin-right: 8px;">

      <div class="box" fxLayout="row" fxLayoutAlign="start center">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'GLOBAL.DROPDOWN_CONSUMABLE_SELECTION' | translate }}</mat-label>
          <mat-select [(ngModel)]="materialsDropdown" name="cons" style="text-transform: capitalize;">
            <!-- [disabled]="materials.length == 1" -->
            <mat-option *ngFor="let cons of materials" [value]="cons.id" (click)="changeMaterial(materialsDropdown)">
              <span style="text-transform: capitalize;">{{ cons.label | translate }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div *ngIf="warningList?.length > 0" fxLayout="column" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center" fxLayoutGap="8px">
      <button class="rounded-button md-orange not-clickable" *ngFor="let warning of warningList"
        matTooltip="{{ warning }}">
        <mat-icon class="icon">warning</mat-icon>
      </button>
    </div>

    <span fxFlex></span>

    <ff-available-machines-selection [availableMachines]="availableMachines"
      (selected)="machineSelectionChange($event)"></ff-available-machines-selection>

  </div>

  <div *ngIf="isMobile" fxFlex fxLayout="column" class="tab-content h100 w100"
    [ngClass]="{ 'without-tabs': tabs.length <= 1}" fxLayoutAlign="center center">
    <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
  </div>

  <!-- tab content -->
  <div *ngIf="!isMobile" class="tab-content h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < (pageStateReady + 1)" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div fxLayout="column" class="w100">

      <div *ngIf="pageState.value >= (pageStateReady + 1) && !materials.length" fxLayout="row"
        fxLayoutAlign="center center" class="w100" style="min-height: 100px;">
        <div class="not-found w100" fxLayout="row" fxLayoutAlign="center center">
          <span>{{ 'CONSUMPTION_SUPPLY.NO_MATERIAL' | translate }}</span>
        </div>
      </div>

      <!-- dashboard -->
      <div *ngIf="prodsDataDash && pageState.value >= (pageStateReady + 1) && materials?.length" class="w100"
        fxLayout.lt-sm="column" fxLayout="row wrap" fxLayoutAlign="start stretch" fxLayoutGap="8px grid"
        fxLayoutGap.gt-sm="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
        <ff-widget *ngFor="let w of dashboardConfig.widgets"
          [fxFlex.gt-sm]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
          [fxShow.lt-md]="!w.hideMobile" [fxFlex.sm]="'calc(' + w.mobileFlex + '% - 8px )'"
          (clicked)="onButtonClick($event)" [fxFlexOrder.sm]="w.mobileOrder" [widget]="{ 
                type: w.type, 
                subtype: w.subtype, 
                data: prodsDataDash, 
                machineProfile: machine.profile, 
                config: w.config, 
                title: w.title,
                innerFlex: w.innerFlex,
                interval: interval,
                intervalAggregations: intervalAggregations,
                referenceComponent: this
              }">
        </ff-widget>
      </div>

    </div>

  </div>

</div>