import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FfTranslateService {

  currentLang = this.translate.currentLang;
  defaultLang = this.translate.defaultLang;

  constructor(
    public translate: TranslateService,
  ) {
    this.currentLang = this.translate.currentLang;
    this.defaultLang = this.translate.defaultLang;
  }

  use(lang) {
    this.currentLang = lang;
    return this.translate.use(lang);
  }

  instant(val: any, params?: any, _default?: any) {

    if (val == null) return _default;

    if (typeof val == 'number') val = val.toString();

    if (params != null) {
      try { return this.translate.instant(val, params) }
      catch (error) {
        // console.log("Error in smart translation", error);
        return _default ?? val;
      }
    }

    try { return this.translate.instant(val) }
    catch (error) {
      // console.log("Error in smart translation", error);
      return _default ?? val;
    }

  }

}
