<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" aria-label="close">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography">
  <div *ngFor="let group of dialog.groups.plotsConfig.list" fxLayout="column" fxLayoutAlign="center start"
    style="padding: 4px 0;">
    <div fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 0;" class="w100">

      <mat-icon *ngIf="group.show" svgIcon="radio_button" class="icon left clickable radio_button"
        (click)="toggleGroup(group)">
      </mat-icon>

      <mat-icon *ngIf="!group.show" svgIcon="radio_button_disabled" class="icon left clickable radio_button_disabled"
        (click)="toggleGroup(group)">
      </mat-icon>

      <span style="font-weight: 800;">{{ group.label }}</span>
      <span *ngIf="!group.doubleAxis">&nbsp;[{{ group.unit }}]</span>

      <mat-icon *ngIf="!group.expanded" svgIcon="curtain_down" class="icon left clickable"
        (click)="toggleGroupExpansion(group)">
      </mat-icon>

      <mat-icon *ngIf="group.expanded" svgIcon="curtain_up" class="icon left clickable"
        (click)="toggleGroupExpansion(group)">
      </mat-icon>

      <span fxFlex></span>

      <span>{{ group.shownTraces }}/{{ group.allTraces }}</span>

    </div>
    <ng-container *ngIf="group.expanded">
      <div *ngFor="let trace of group.traces" fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 8px;">
        <mat-icon *ngIf="trace.show" svgIcon="radio_button" class="icon small left clickable radio_button"
          (click)="toggleTrace(group, trace)"></mat-icon>
        <mat-icon *ngIf="!trace.show" svgIcon="radio_button_disabled"
          class="icon small left clickable radio_button_disabled" (click)="toggleTrace(group, trace)"></mat-icon>
        <span style="margin-left: 12px;">{{ trace.label }}</span>
        <span *ngIf="group.doubleAxis">&nbsp;[{{ trace.unit }}]</span>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button class="filter-button" [mat-dialog-close]="dialog">{{ 'GLOBAL.CONFIRM' | translate }}</button>
</mat-dialog-actions>