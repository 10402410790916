<div mat-dialog-title fxLayout="row" fxLayoutAlign="start center" class="w100">

  <!-- icon -->
  <ff-icon *ngIf="dialog?.icon != null" [icon]="dialog.icon" style="margin-right: 8px;"></ff-icon>

  <span fxLayout="column" fxLayoutAlign="center center">{{ dialog.title | translate }}</span>

  <span fxFlex></span>

  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>

</div>

<mat-dialog-content class="mat-typography alternative-color" [class.mh100vh-300px]="!deleteMode">

  <ng-container *ngIf="state == 0">
    <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
      <ff-loading-card></ff-loading-card>
    </div>
  </ng-container>

  <ng-container *ngIf="state == 1">

    <div fxLayout="column" fxLayoutAlign="start start" [class.mh100vh-300px]="!deleteMode">

      <div *ngIf="!deleteMode" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px" class="h100 w100">

        <ff-widget *ngFor="let w of dialog.dashboardConfig?.templateDetailConfig?.widgets" fxFlex="100"
          class="h100 w100" [widget]="w" (clicked)="onButtonClick($event)">
        </ff-widget>

      </div>

      <div *ngIf="deleteMode" fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px" class="h100 w100">

        <div style="margin-bottom: 12px;">
          <button class="filter-button" (click)="disableDeleteMode()" fxLayout="row" fxLayoutAlign="center center">
            <ff-icon [icon]="'keyboard_backspace'"></ff-icon>
            <span>{{ 'MAINTENANCE_CALENDAR.VIEW_EVENT' | translate }}</span>
          </button>
        </div>

        <ff-checkbox *ngFor="let opt of deleteOptions" [checkBoxConfig]="opt" (changedValue)="onCheckboxChange($event)">
        </ff-checkbox>

        <div fxLayout="row" style="margin-top: 16px;">
          <span fxFlex></span>
          <button class="filter-button md-red" (click)="confirmCloseDialogDelete()" fxLayout="row"
            fxLayoutAlign="center center">
            <ff-icon [icon]="'delete'"></ff-icon>
            <span>{{ 'GLOBAL.CONFIRM_DELETE' | translate }}</span>
          </button>
        </div>

      </div>

    </div>

  </ng-container>

</mat-dialog-content>

<mat-dialog-actions *ngIf="state == 1 && !deleteMode && permission" fxLayout="row" fxLayoutAlign="end center"
  style="margin-right: 12px;">

  <button *ngIf="eventInThePastOrPresent" style="margin-left: 8px;" class="filter-button"
    (click)="addMaintenanceIntervention()" fxLayout="row" fxLayoutAlign="center center">
    <ff-icon [icon]="'add'"></ff-icon>
    <span>{{ 'MAINTENANCE_CALENDAR.ADD_MAINTENANCE_INTERVENTION' | translate }}</span>
  </button>

  <button class="filter-button md-red" (click)="enableDeleteMode()" fxLayout="row" fxLayoutAlign="center center">
    <ff-icon [icon]="'delete'"></ff-icon>
    <span>{{ 'MAINTENANCE_CALENDAR.GO_TO_DELETE_MODE' | translate }}</span>
  </button>

</mat-dialog-actions>