import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import {
  ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexTitleSubtitle, ApexXAxis,
  ApexYAxis
} from "ng-apexcharts";
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FiltersDialogComponent } from 'src/app/components/filters-dialog/filters-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';

export type ChartOptions = {
  annotations: ApexAnnotations,
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  grid: ApexGrid,
  tooltip: any // to be simple I made it as any, can be replaced with the proper className
};

@Component({
  selector: 'app-state-timeline-advanced',
  templateUrl: './state-timeline-advanced.component.html',
  styleUrls: ['./state-timeline-advanced.component.scss']
})
export class StateTimelineAdvancedComponent implements OnInit {

  public isAllowedUser: boolean = true;

  public loadingData: any;
  public errorData: any;
  public errorDataMobile: any;

  public leftPlot: any;
  public rightPlot: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public excludeLine: boolean = true;
  public detailMode: boolean = false;

  public pollingTime: any;
  public pollingEvents: any;

  public currentSynopticId: any;
  public synopticConfig: any;
  public synopticConfigDefault: any;

  public monitoringData: any;
  public unparsedMonitoringData: any;

  public dialogData: any;
  public showDialog: boolean = false;
  public chartOptions: any;

  public ganttState: any = 0;

  public filterButtons: any;

  public availableMachines: any;

  public oneMachineSelected: boolean = false;
  public alarmCategories: any;

  public interval: any;
  public intervalConfig: any;
  public intervalAggregations: any;

  public defaultIntervalId: any;

  public zoomedIntervalSub: Subscription;
  public zoomedInterval: any;
  public zoomIntervalHistory: any = [];

  public breakdownsLink: boolean = false;

  public mobileListener: any;
  public isMobile: any;

  public machineSelectionChanged: any = false;
  public selectableMachinesLimit: any;

  public addOeeTrend: any = false;

  public useStatesInsteadOfCategoriesInStateTimeline: any = false;
  public excludeAvailableMachines: any = false;

  public expandedMode: any = false;

  public maxMachineNameLength: any = 15;

  public expandedModeSwitch: any = {
    title: "GLOBAL.GANTT_EXPANDED_VIEW",
    tooltip: "GLOBAL.GANTT_EXPANDED_VIEW_TOOLTIP",
    checked: false,
    checkedLabel: "on",
    uncheckedLabel: "off"
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.dispatchBuildButtons, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getStateTimelinePolling, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public cacheService: CacheService,
    public clonerService: ClonerService,
    public router: Router,
    public mobile: MobileService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));
    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })

    // TODO AGGIUNGERE AGGREGAZIONI E FILTRI
    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.monitoringData = null;


    this.breadcrumb = ['MACHINE_MONITORING.TITLE', 'STATE_TIMELINE.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs('machineMonitoring');

    this.selectableMachinesLimit = this.appConfig?.machineMonitoring?.stateTimelineAdvanced?.selectableMachinesLimit;
    this.availableMachines = this.appConfig?.machineMonitoring?.stateTimelineAdvanced?.availableMachines;
    this.excludeAvailableMachines = this.appConfig?.machineMonitoring?.stateTimelineAdvanced?.excludeAvailableMachines;
    this.maxMachineNameLength = this.appConfig?.machineMonitoring?.stateTimelineAdvanced?.maxMachineNameLength ?? this.maxMachineNameLength;

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.internalDataService.setCalendarPage(true);

    this.pollingTime = 5000;
    this.pollingEvents = Subscription;

    this.zoomedIntervalSub = this.internalDataService.zoomedInterval.subscribe(value => {
      if (value != null) {

        // console.log("%c Zoomed Interval", "color: green; font-weight: bold; background-color: pink;");
        this.zoomIntervalHistory.push(this.clonerService.deepClone(this.interval));
        let interval = this.interval;

        interval.start = value.start;
        interval.end = moment(value.end);

        this.intervalService.formatTimezone(interval, this.machine.timezone);

        interval.enabledPolling = moment().diff(moment(interval.end), 'm') < 0;
        if (moment().diff(moment(interval.end), 'm') < 0) interval.end = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        // interval.end = JSON.parse(JSON.stringify(interval.end));
        interval.startF = this.intervalService.strFormat(interval, interval.start, this.machine.timezone) + ' - ' + this.intervalService.strFormat(interval, interval.end, this.machine.timezone);

        this.interval = interval;
        this.internalDataService.getValidIntervalAggregations(this, true);

        try {
          this.interval.id = this.intervalAggregations.selected.unit == null && interval.enabledPolling ? 'lastHour' : interval.id;
          this.detailMode = this.intervalAggregations.selected.unit == null;

          this.selectLines();
          if (this.detailMode) {
            let formatHours = "MMM DD YYYY, HH:mm";
            this.interval.startF = this.intervalService.strFormat(interval, interval.start, this.machine.timezone, formatHours) + ' - ' + this.intervalService.strFormat(interval, interval.end, this.machine.timezone, "HH:mm");
          }
        } catch (error) {
          console.log(error);
        }

        let clonedInterval = this.clonerService.deepClone(this.interval);
        this.intervalConfig.selected = JSON.parse(JSON.stringify(Object.assign(clonedInterval, { id: 'custom' })));

        this.pageState.next(5);
        this.getStatesTimeline(this, 0);

      }
    });
  }

  selectLines() {

    // If no limit is configured, do nothing
    if (!this.selectableMachinesLimit) return;

    // Otherwise check if detail mode is active
    if (this.detailMode && !this.machineSelectionChanged) {
      this.filterButtons = this.buildFilterButtons(this);
    }
    else if (!this.detailMode && !this.machineSelectionChanged) {
      this.filterButtons = this.buildFilterButtons(this, this.selectableMachinesLimit);
    }
    else if (!this.detailMode && this.filterButtons.find((x: any) => x.variable == 'machineP')?.options?.filter(x => x.selected)?.length >= this.selectableMachinesLimit) {
      this.filterButtons = this.buildFilterButtons(this, this.selectableMachinesLimit);
    }
    // else {
    //   console.log(this.filterButtons.find((x: any) => x.variable == 'machineP')?.options?.filter(x => x.selected)?.length, this.selectableMachinesLimit);

    // }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-ms-timeline");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs('machineMonitoring');
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'machineMonitoring');
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getStateTimelinePolling, this.getStateTimelinePolling, this.machine.timezone, 5, 5, true) };

  selectAggregation(aggr: any) {

    if (aggr != null) {

      try {
        this.pollingEvents.unsubscribe();
      } catch (error) {
        // console.log(error)
      }

      this.intervalAggregations.selected = aggr;

      this.pageState.next(5);
      this.getStateTimelinePolling(this);

    }
  }

  setPreviousInterval() {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    this.detailMode = false;

    let lastZoomedInterval = this.zoomIntervalHistory[this.zoomIntervalHistory.length - 1];

    let interval = this.interval;

    interval.start = JSON.parse(JSON.stringify(lastZoomedInterval.start));
    interval.end = moment(lastZoomedInterval.end);

    this.zoomIntervalHistory.splice(this.zoomIntervalHistory.length - 1, 1);

    interval.enabledPolling = moment().diff(interval.end, 'm') < 5;
    if (moment().diff(interval.end) < 0) interval.end = moment();

    interval.end = JSON.parse(JSON.stringify(interval.end));
    interval.startF = this.intervalService.strFormat(interval, interval.star, this.machine.timezone) + ' - ' + this.intervalService.strFormat(interval, interval.end, this.machine.timezone);

    interval.id = 'custom';
    if (this.defaultIntervalId != null && this.zoomIntervalHistory.length == 0) interval.id = this.defaultIntervalId;

    this.interval = interval;
    this.internalDataService.getValidIntervalAggregations(this, true);

    this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

    this.pageState.next(5);
    this.getStatesTimeline(this, 0);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET STATES TIMELINE

  // polling
  getStateTimelinePolling(_this: any) {

    try {
      _this.cacheService.set("intervalLong", _this.clonerService.deepClone(_this.intervalConfig));

      if (_this.pollingTime > 0 && _this.detailMode) {
        _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getStatesTimeline(_this, count);
        });
      } else {
        _this.getStatesTimeline(_this, 0);
      }

      _this.addOeeTrend = _this.machine?.profile?.flags?.addOeeTrendInStateTimelineAdvanced;

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getStatesTimeline(_this: any, count?: any) {

    try {

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      if (_this.interval != null && _this.interval.enabledPolling && _this.detailMode && _this.machine.profile?.showDayAsNotAggregatedInStateTimeline == null) {
        _this.interval = _this.intervalService.getIntervalById('lastHour', this.machine.timezone);
        _this.intervalConfig.selected = JSON.parse(JSON.stringify(_this.interval));
      }

      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone
      };

      if (_this.interval != null && (!_this.interval.enabledPolling || !_this.detailMode)) query.to = _this.interval.end;
      if (!_this.detailMode && _this.addOeeTrend) query.addOeeTrend = 1;

      if (_this.intervalAggregations && _this.intervalAggregations.selected && _this.intervalAggregations.selected.unit) {
        query.unit = _this.intervalAggregations.selected.unit;
        query.value = _this.intervalAggregations.selected.value;
      }

      if (_this.availableMachines != null) {
        payload.machineList = _this.availableMachines?.list;
      } else if (_this.machine.machineReference) {
        query.machineId = _this.machine.machineReference;
      }

      _this.apiService.sendPostRequest('/apif/machine-monitoring/states-timeline/' + _this.machineId, payload, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents)))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.detailMode = !_this.intervalAggregations.selected.unit;

            _this.selectLines();

            try {
              if (Object.keys(data.body).length == 1) _this.oneMachineSelected = true;
            } catch (error) { console.log(error) }

            _this.unparsedMonitoringData = _this.clonerService.deepClone(data.body);
            let parsedData = _this.parseData(_this.unparsedMonitoringData);

            if (_this.detailMode) _this.parseGantt(_this, parsedData);
            else {

              _this.leftPlot = _this.buildLeftPlot(_this, parsedData);
              _this.rightPlot = _this.buildRightPlot(_this, parsedData);

              if (_this.addOeeTrend) _this.oeeTrendPlot = _this.buildOeeTrendPlot(_this, parsedData);
            }

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      console.log(error);
    }

  }

  parseData(data: any) {

    let copyData: any = data != null ? this.clonerService.deepClone(data) : null;

    try {

      // Check if machines exist
      if (Object.keys(data).length > 0) {

        let categories: any = this.clonerService.deepClone(this.machine.profile.categories);

        if (this.filterButtons != null && this.filterButtons.length > 0) {

          // Filter selected machines
          let filterMachinesIdx = this.filterButtons.findIndex((x: any) => x.variable == 'machineP');
          if (filterMachinesIdx != -1) {
            let toRemove = this.filterButtons[filterMachinesIdx].options.filter((opt: any) => !opt.selected).map((x: any) => x.id);
            if (toRemove.length > 0) toRemove.forEach((tr: any) => delete copyData[tr]);
          }

          // Filter selected alarm categories
          let alCatIx = this.filterButtons.findIndex((x: any) => x.variable == 'alarmCategories');
          if (alCatIx != -1) {

            Object.entries(copyData).forEach((conf: any, machineIdx: any) => {

              const machine = conf[0];
              const values = conf[1];

              if (values.alarms != null && values.alarms.length > 0) {
                let toRemain = this.filterButtons[alCatIx].options.filter((opt: any) => opt.selected).map((x: any) => x.id);
                if (toRemain.length > 0) {
                  let newAlarms: any = [];
                  values.alarms.forEach((al: any) => {
                    if (al.codes != null && al.codes.length > 0) {
                      al.codes = al.codes.filter((code: any) => toRemain.includes(code.category));
                      if (al.codes.length > 0) newAlarms.push(al);
                    }
                  });
                  values.alarms = this.clonerService.deepClone(newAlarms);
                }
              }

              if (values.signalations != null && values.signalations.length > 0) {
                let toRemain = this.filterButtons[alCatIx].options.filter((opt: any) => opt.selected).map((x: any) => x.id);
                if (toRemain.length > 0) {
                  let newSignalations: any = [];
                  values.signalations.forEach((al: any) => {
                    if (al.codes != null && al.codes.length > 0) {
                      al.codes = al.codes.filter((code: any) => toRemain.includes(code.category));
                      if (al.codes.length > 0) newSignalations.push(al);
                    }
                  });
                  values.signalations = this.clonerService.deepClone(newSignalations);
                }
              }

            });

          }
        }

        Object.entries(copyData).forEach((conf: any, machineIdx: any) => {

          const machine = conf[0];
          const values = conf[1];

          if (copyData.addPlannedTime) {
            categories.push(
              {
                backendValue: "plannedTime",
                backendValueStateTimeline: "plannedTime",
                color: "#2196f380",
                opacity: 0.3,
                label: "HOMEPAGE.PLANNED_TIME"
              });
          }

          if (typeof copyData[machine] == 'object') {
            copyData[machine].totalTimes = {};
            categories.forEach((cat: any) => copyData[machine].totalTimes[cat.backendValueStateTimeline] = this.filterService.sum(values.states, cat.backendValueStateTimeline));
            copyData[machine].totalTimes.tot = this.filterService.sum(Object.values(copyData[machine].totalTimes))
          }

        });


      }
    } catch (error) {
      console.log(error);
    }

    return copyData;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // BUILD PLOTS

  // Apex gantt chart
  parseGantt(_this: any, data: any) {
    this.chartOptions = this.filterService.parseGantt(_this, data);
    if (this.expandedModeSwitch.checked) this.chartOptions.series = this.chartOptions.seriesExpanded;
  }

  switchChange(flag) {
    this.expandedModeSwitch.checked = flag;

    let parsedData = this.parseData(this.unparsedMonitoringData);
    this.oneMachineSelected = Object.keys(parsedData).length == 1;

    if (this.detailMode) this.parseGantt(this, parsedData);
  }

  // Left: aggregated times
  buildLeftPlot(_this: any, data: any) {

    // console.log(data);

    let traces: any = [];

    let categories = _this.clonerService.deepClone(_this.machine.profile.categories);

    if (data.addPlannedTime) {
      categories.push(
        {
          backendValue: "plannedTime",
          backendValueStateTimeline: "plannedTime",
          color: "#2196f380",
          opacity: 0.3,
          label: "HOMEPAGE.PLANNED_TIME"
        });
      // delete data.addPlannedTime;
    }


    let plotLayout: any = {
      barmode: 'stack',
      annotations: [],
      font: {
        size: 12,
      },
      margin: {
        t: 0,
        r: 60,
        b: 60,
        l: 60,
        pad: 5
      },
      xaxis: {
        showgrid: false,
        zeroline: false,
      },
    };

    let machinesObjectToIterate: any = _this.filterService.removeKeysWithCustomRule(data, ([k, v]) => k != 'addPlannedTime');

    let frac: any = [];
    for (let j = 0; j <= Object.keys(machinesObjectToIterate).length; j++) {
      frac.push(j / Object.keys(machinesObjectToIterate).length);
    }

    // Check if machines exist
    if (Object.keys(machinesObjectToIterate).length > 0) {

      Object.entries(machinesObjectToIterate).forEach((conf: any, machineIdx: any) => {

        let machine = conf[0];
        let values = conf[1];

        let name = Object.entries(machinesObjectToIterate)[Object.keys(machinesObjectToIterate).length - machineIdx - 1][0];
        let invertedMachineName: any = (name != "Line" && name != 'None') ? name : _this.translate.instant("GLOBAL.LINE");
        invertedMachineName = invertedMachineName.length > _this.maxMachineNameLength ? (invertedMachineName.substring(0, _this.maxMachineNameLength) + '...') : invertedMachineName;

        plotLayout['yaxis' + parseInt(machineIdx + 1)] = {
          title: {
            text: this.internalDataService.parseLabelWithAssetId("MACHINES", invertedMachineName, null, this.machineId) + ' [' + _this.filterService.parseSecondsFromAggrUnit(_this.intervalAggregations.selected.unit).unit + ']',
            font: {
              size: 12
            }
          },
          zeroline: true,
          showgrid: false,
          domain: [frac[machineIdx], frac[machineIdx + 1] - 0.05],
        };

        categories.forEach((cat: any) => {

          let opacity = cat.opacity ?? 0.9;

          let states = values.states ?? [];

          traces.push({
            machine: machine,
            x: states.map((x: any) => x.timestamp),
            y: states.map((x: any) => _this.filterService.parseSecondsFromAggrUnit(_this.intervalAggregations.selected.unit, x[cat.backendValueStateTimeline]).value),
            // width: states.length < 3 ? 1000 * 3600 * 24 : null,
            name: _this.translate.instant(cat.label),
            yaxis: 'y' + (Object.keys(machinesObjectToIterate).length - machineIdx),
            type: "bar",
            width: 3600 * 1000 * _this.filterService.parseSecondsFromAggrUnit(_this.intervalAggregations.selected.unit, 1).mult * 0.9,
            // visible: machineIdx == 0 ? true : 'legendonly',
            showlegend: machineIdx == 0,
            legendgroup: cat.id,
            timeAggrUnit: _this.intervalAggregations.selected,
            hovertext: states.map((x: any) => {

              // Calculate total time
              let totalTime = categories.reduce((acc, val) => {
                acc += x[val.backendValueStateTimeline];
                return acc;
              }, 0);

              return _this.translate.instant(cat.label) + ': ' + _this.filterService.parseDuration(x[cat.backendValueStateTimeline], "s", "HH:mm:ss") + ' <br>' +
                _this.translate.instant("GLOBAL.PERCENTAGE") + ': ' + _this.filterService.parseGaugeValue((x[cat.backendValueStateTimeline] / totalTime), 2, 100) + '% <br>' +
                _this.filterService.parseMoment(x.timestamp, _this.filterService.getFormatFromAggrTime(_this.intervalAggregations.selected.unit, true))
            }),
            hoverinfo: "text",
            marker: {
              color: cat.color,
              opacity: opacity,
              line: {
                color: 'rgba(0,0,0,0.38)',
                width: 1
              }
            },
            connectgaps: true,
          });
        });
      })

    };

    console.log(traces, plotLayout);

    let plotConfig = {
      leftPlotData: {
        traces: traces,
        layout: plotLayout,
        params: {
          displayModeBar: false,
          stateTimelineAdvanced: true
        }
      }
    };

    return {
      title: 'STATE_TIMELINE.AGGREGATED_TIMES',
      profile: this.machine.profile,
      excludeTitleHeight: true,
      type: 'ff-plotly-chart',
      config: { plotDataAttribute: 'leftPlotData' },
      data: plotConfig
    };
  }

  // Right: cumulative times
  buildRightPlot(_this: any, data: any) {

    // console.log(data);

    let traces: any = [];

    let categories = _this.clonerService.deepClone(_this.machine.profile.categories);

    if (data.addPlannedTime) {
      categories.push(
        {
          backendValue: "plannedTime",
          backendValueStateTimeline: "plannedTime",
          color: "#2196f380",
          opacity: 0.3,
          label: "HOMEPAGE.PLANNED_TIME"
        });
      // delete data.addPlannedTime;
    }


    let plotLayout: any = {
      barmode: 'stack',
      hovermode: 'closest',
      font: {
        size: 12,
      },
      margin: {
        t: 0,
        r: 30,
        b: 60,
        l: 30,
        pad: 5
      },
      showlegend: false,
      xaxis: {
        title: {
          text: _this.filterService.parseSecondsFromAggrUnit(_this.intervalAggregations.selected.unit).unit
        },
        showgrid: false,
        zeroline: false,
      },
    };

    let parsedData = this.filterService.removeKeysWithCustomRule(this.clonerService.deepClone(data), (x) => typeof x[1] == 'object');
    let parsedDataKeys = Object.keys(parsedData);

    let frac: any = [];
    for (let j = 0; j <= parsedDataKeys.length; j++) {
      frac.push(j / parsedDataKeys.length);
    }

    // Check if machines exist
    if (parsedDataKeys.length > 0) {

      Object.entries(parsedData).forEach((conf: any, machineIdx: any) => {

        let machine = conf[0];
        let values = conf[1];

        let prevMach = Object.keys(parsedData ?? {})?.[parsedDataKeys.length - machineIdx - 1];

        plotLayout['yaxis' + (machineIdx == 0 ? '' : parseInt(machineIdx + 1))] = {
          title: {
            text: prevMach,
            font: {
              size: 5
            }
          },
          zeroline: true,
          showgrid: false,
          showticklabels: false,
          visible: false,
          domain: [frac[machineIdx], frac[machineIdx + 1] - 0.05],
        };

        categories.forEach((cat: any) => {

          let opacity = cat.opacity ?? 0.9;
          let perc = values.totalTimes[cat.backendValueStateTimeline] / values.totalTimes.tot;
          traces.push({
            x: [_this.filterService.parseSecondsFromAggrUnit(_this.intervalAggregations.selected.unit, values.totalTimes[cat.backendValueStateTimeline]).value],
            y: [machine],
            // width: values.totalTimes.length < 3 ? 1000 * 3600 * 24 : null,
            name: _this.translate.instant(cat.label),
            yaxis: 'y' + (parsedDataKeys.length - machineIdx),
            type: "bar",
            orientation: 'h',
            // visible: machineIdx == 0 ? true : 'legendonly',
            showlegend: machineIdx == 0,
            legendgroup: cat.id,
            // timeAggrUnit: _this.intervalAggregations.selected,
            hovertext: '<b>' + _this.translate.instant(cat.label) + '</b>: ' + _this.filterService.parseDuration(values.totalTimes[cat.backendValueStateTimeline], "s", "HH:mm:ss") +
              '<br><b>' + _this.translate.instant("STATE_TIMELINE.PERCENTAGE") + '</b>: ' + _this.filterService.parseGaugeValue(perc, 2, 100) + '%',
            hoverinfo: "text",
            marker: {
              color: cat.color,
              opacity: opacity,
              line: {
                color: 'rgba(0,0,0,0.38)',
                width: 1
              }
            },
            // connectgaps: true,
          });
        });
      });

    };
    // console.log(traces, plotLayout);

    let plotConfig = {
      rightPlotData: {
        traces: traces,
        layout: plotLayout,
        params: {
          displayModeBar: false,
        }
      }
    };

    return {
      title: 'STATE_TIMELINE.CUMULATIVE_TIMES',
      excludeTitleHeight: true,
      type: 'ff-plotly-chart',
      config: { plotDataAttribute: 'rightPlotData' },
      data: plotConfig,
    };
  }

  // Left: aggregated times
  buildOeeTrendPlot(_this: any, data: any) {

    // console.log(data);

    let traces: any = [];

    let plotLayout: any = {
      margin: {
        t: 0,
        r: 60,
        b: 60,
        l: 60,
        pad: 5
      },
      xaxis: {
        showgrid: false,
        zeroline: false,
      },
      yaxis: {
        showgrid: false,
        zeroline: false,
        range: [0, 105],
        title: "OEE [%]",
      }
    }

    // Check if machines exist
    if (Object.keys(data).length > 0) {

      Object.entries(data).forEach((conf: any, machineIdx: any) => {

        let machine = conf[0];
        let values = conf[1];

        let xValues = values?.states?.map(x => x.timestamp);
        let yValues = values?.states?.map(x => x.oee != null ? x.oee * 100 : null);
        let hovertext = values?.states?.map(x => {
          let text = '<b>' + this.translate.instant('GLOBAL.MACHINE') + '</b>: ' + machine + '<br>';
          text += "<b>" + this.translate.instant("GLOBAL.OEE") + '</b>: ' + (x.oee != null ? this.filterService.parseGaugeValue(x.oee, 2, 100) : '-') + ' [%]<br>';
          text += '<b>' + this.translate.instant('GLOBAL.DATE') + '</b>: ' + this.filterService.parseMoment(x.timestamp, 'default', this.machine.timezone);
          return text;
        });

        traces.push({
          x: xValues,
          y: yValues,
          type: "scatter",
          mode: "lines",
          hovertext: hovertext,
          hoverinfo: "text",
          connectgaps: false,
          name: machine
        });

      })

    };

    // console.log(traces, plotLayout);

    let plotConfig = {
      oeeTrendPlotData: {
        traces: traces,
        layout: plotLayout,
        params: {
          displayModeBar: false
        }
      }
    };

    return {
      title: 'OEE_TREND.TITLE',
      profile: this.machine.profile,
      excludeTitleHeight: true,
      type: 'ff-plotly-chart',
      config: { plotDataAttribute: 'oeeTrendPlotData' },
      data: plotConfig
    };
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // FILTER BUTTONS

  dispatchBuildButtons(_this: any) {

    _this.useStatesInsteadOfCategoriesInStateTimeline = _this.machine.profile?.useStatesInsteadOfCategoriesInStateTimeline ?? _this.appConfig?.useStatesInsteadOfCategoriesInStateTimeline ?? false;

    if (_this.cacheService.get("intervalBreakdowns") != null) {
      _this.interval = _this.cacheService.get("intervalBreakdowns");
    } else {

      let copyInterval = _this.clonerService.deepClone(_this.cacheService.get("intervalLong"));

      if (copyInterval == null) {

        _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.cacheService.set("intervalLong", _this.clonerService.deepClone(_this.intervalConfig));

      } else {
        _this.intervalConfig = _this.clonerService.deepClone(copyInterval);
        _this.interval = _this.clonerService.deepClone(_this.intervalConfig.selected);
      }

    }

    _this.intervalConfig = {
      list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
      selected: _this.interval
    };

    _this.defaultIntervalId = _this.clonerService.deepClone(_this.intervalConfig.selected.id);
    _this.internalDataService.filterIntervals(_this, ['hour', 'day', 'week'], true);

    _this.filterButtons = _this.buildFilterButtons(_this, (!_this.detailMode && _this.selectableMachinesLimit != null) ? _this.selectableMachinesLimit : null);
    _this.dispatcherService.getDispatch(_this, 300);
  }

  buildFilterButtons(_this: any, limitMachines?: any) {

    let buttons: any[] = [];

    if (_this.availableMachines != null && _this.availableMachines.list != null && _this.availableMachines.list.length > 0) {

      let attribute: any = {
        variable: "machineP",
        label: "GLOBAL.MACHINE",
      };

      attribute.options = _this.clonerService.deepClone(_this.availableMachines.list.map((x: any, i) => {
        return {
          id: x,
          label: this.internalDataService.parseLabelWithAssetId("MACHINES", x, null, this.machineId),
          // selected: true
          selected: limitMachines ? i < limitMachines : true
        }
      }));

      buttons.push(attribute);

    }

    if (_this.machine.profile.alarmCategories != null && _this.machine.profile.alarmCategories.length > 0) {

      let attribute: any = {
        variable: "alarmCategories",
        label: "STATE_TIMELINE.CATEGORIES",
      };

      attribute.options = _this.clonerService.deepClone(_this.machine.profile.alarmCategories.map((x: any) => {
        return {
          id: x.id,
          label: x.id,
          // label: x.value != null && x.value != '' ? this.translate.instant(x.value) : null,
          selected: true
        }
      }));

      buttons.push(attribute);

    }


    return buttons;
  }

  openFilterDialog(button: any) {

    // console.log(button)

    let maxSel = {};

    if (button?.variable == 'machineP' && !this.detailMode && this.selectableMachinesLimit != null) {
      maxSel = {
        config: {
          maxSel: this.selectableMachinesLimit
        }
      };
    }

    let filtersDialog = this.dialog.open(FiltersDialogComponent, {
      panelClass: 'ff-dialog',
      data: Object.assign(
        {
          title: this.translate.instant(button.label),
          variable: button.variable,
          options: button.options,
        },
        maxSel
      ),
    });

    filtersDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') {

        if (button?.variable == 'machineP') this.machineSelectionChanged = true;
        // console.log('afterClosed', result);
        button.options = this.clonerService.deepClone(result?.options);

        let parsedData = this.parseData(this.unparsedMonitoringData);
        this.oneMachineSelected = Object.keys(parsedData).length == 1;

        if (this.detailMode) this.parseGantt(this, parsedData);
        else {
          this.leftPlot = this.buildLeftPlot(this, parsedData);
          this.rightPlot = this.buildRightPlot(this, parsedData);
        }

      }
    });
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // BUCKET BUTTONS (NOT-AGGREGATED MODE)

  // Navigate to previous bucket
  getPreviousBucket() {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start), 'm') / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).subtract(duration, 'm')));
    let end = JSON.parse(JSON.stringify(moment(this.interval.end).subtract(duration, 'm')));

    this.interval.start = start;
    this.interval.end = end;

    this.interval.enabledPolling = false;
    this.selectLines();

    if (this.detailMode) {
      let formatHours = "MMM DD YYYY, HH:mm";
      this.interval.startF = this.intervalService.strFormat(this.interval, start, this.machine.timezone, formatHours) + ' - ' + this.intervalService.strFormat(this.interval, end, this.machine.timezone, "HH:mm");
    } else {
      this.interval.startF = this.intervalService.strFormat(this.interval, start) + ' - ' + this.intervalService.strFormat(this.interval, end);
    }
    this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

    this.pageState.next(5);
    this.getStatesTimeline(this, 0);
  }

  // Navigate to next bucket
  getNextBucket() {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start), 'm') / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).add(duration, 'm')));
    let end = moment(this.interval.end).add(duration, 'm');

    if (moment().diff(end, 'm') < 5) {
      this.interval.start = start;
      this.interval.enabledPolling = true;
      this.interval.end = JSON.parse(JSON.stringify(moment()));
      this.interval.startF = this.intervalService.strFormat(this.interval, start);
      this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

      this.pageState.next(5);
      this.getStateTimelinePolling(this);
    }
    else {

      this.interval.start = start;
      this.interval.end = JSON.parse(JSON.stringify(end));

      this.interval.enabledPolling = false;
      this.selectLines();

      if (this.detailMode) {
        let formatHours = "MMM DD YYYY, HH:mm";
        this.interval.startF = this.intervalService.strFormat(this.interval, start, this.machine.timezone, formatHours) + ' - ' + this.intervalService.strFormat(this.interval, end, this.machine.timezone, "HH:mm");
      } else {
        this.interval.startF = this.intervalService.strFormat(this.interval, start, this.machine.timezone) + ' - ' + this.intervalService.strFormat(this.interval, end, this.machine.timezone);
      }
      this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

      this.pageState.next(5);
      this.getStatesTimeline(this, 0);
    }

  }

  // NAVIGATE TO BREAKDOWNS
  navigateToBreakdowns() {
    this.cacheService.set("intervalBreakdowns", this.interval);
    this.breakdownsLink = true;
    let url: any = [this.machineId, 'lean-analytics', 'breakdowns'].map((x: any) => "/" + x).join("");
    this.router.navigateByUrl(url);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);
  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    this.internalDataService.setCalendarPage(false);
    try { this.pollingEvents.unsubscribe() } catch (error) { }
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }

    try {
      this.internalDataService.setZoomedInterval(null);
      this.zoomedIntervalSub.unsubscribe();
    } catch (error) { }

    if (!this.breakdownsLink) try { this.internalDataService.setBackButton([]) } catch (error) { };
  }

}