import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ff-icon',
  templateUrl: './ff-icon.component.html',
  styleUrls: ['./ff-icon.component.scss']
})
export class FfIconComponent implements OnInit {

  @Input() icon: any;
  @Input() iconClass?: any;
  @Input() iconColor?: any;
  @Input() left?: any;
  @Input() right?: any;
  @Input() big?: any;

  constructor() { }

  ngOnInit() { }

}
