import { Component, Input, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/services/app-config.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

@Component({
  selector: 'ff-loading-card',
  templateUrl: './loading-card.component.html',
  styleUrls: ['./loading-card.component.scss']
})
export class LoadingCardComponent implements OnInit {

  @Input() loadingData: any;

  newLoadingData: any;

  constructor(
    public translate: FfTranslateService,
    public appConfigService: AppConfigService,
  ) {
    this.newLoadingData = this.appConfigService.getAppConfig.newLoadingData;
  }

  ngOnInit(): void {

    if (this.loadingData == null) { this.loadingData = { message: this.translate.instant('GLOBAL.LOADING') }; }
    else if (this.loadingData.hasOwnProperty('class') && !this.loadingData.hasOwnProperty('message')) {
      this.loadingData = Object.assign(this.loadingData, { message: this.translate.instant('GLOBAL.LOADING') })
    }
    // this.loadingData = this.loadingData || {
    //   message: this.translate.instant('GLOBAL.LOADING')
    // };
  }

  ngOnDestroy(): void { }

}
