<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <button *ngIf="showResetInterval" mat-button class="filter-button md-gray-dark" aria-label="remove filters"
      (click)="resetDefaultInterval()">
      <mat-icon class="icon">refresh</mat-icon>
      <span style="padding-left: 8px;">{{'CONTINUOUS_EXPLORATION.RESET_INTERVAL' | translate}}</span>
    </button>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <div *ngIf="isMobile" fxFlex fxLayout="column" class="tab-content h100 w100"
    [ngClass]="{ 'without-tabs': tabs.length <= 1}" fxLayoutAlign="center center">
    <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
  </div>

  <!-- tab content -->
  <div *ngIf="!isMobile" class="tab-content h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < 6 || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar -->
    <div class="ff-toolbar pb-3 pt-1" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center" *ngIf="availableMachines != null">

      <span fxFlex fxShow="false" fxShow.gt-sm></span>

      <ff-available-machines-selection [availableMachines]="availableMachines"
        (selected)="machineSelectionChange($event)">
      </ff-available-machines-selection>

    </div>

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 7" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div fxLayout="column" class="w100">

      <!-- dashboard -->
      <!-- <div *ngIf="dashboardData && pageState.value >= 7" class="w100" fxLayout.lt-sm="column" fxLayout="row wrap"
        fxLayoutAlign="start stretch" fxLayoutGap="8px grid"
        fxLayoutGap.gt-sm="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
        <ff-widget *ngFor="let w of dashboardConfig.widgets"
          [fxFlex.gt-sm]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
          [fxShow.lt-md]="!w.hideMobile" [fxFlex.sm]="'calc(' + w.mobileFlex + '% - 8px )'"
          (clicked)="onButtonClick($event)" [fxFlexOrder.sm]="w.mobileOrder" [widget]="{ 
              type: w.type, 
              aggregations: aggregations, 
              subtype: w.subtype, 
              data: dashboardData, 
              machineProfile: machine.profile, 
              config: w.config, 
              title: w.title,
              interval: interval,
              intervalAggregations: intervalAggregations,
              aggregatedTable: aggregatedTable,
              referenceComponent: this
            }">
        </ff-widget>
      </div> -->

      <!-- dashboard -->
      <ff-dashboard *ngIf="dashboardData != null && pageState.value >= 7" (clicked)="onButtonClick($event)"
        [completeDashboardConfig]="completeDashboardConfig">
      </ff-dashboard>

    </div>

  </div>

</div>