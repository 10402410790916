import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';

@Component({
  selector: 'ff-error-card',
  templateUrl: './error-card.component.html',
  styleUrls: ['./error-card.component.scss']
})
export class ErrorCardComponent implements OnInit {

  @Input() errorData: any;

  statusCode: any;
  statusMessage: any;
  hideAdditionalInfos: any;

  constructor(
    public translate: FfTranslateService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.errorData = this.errorData ?? {
      type: 0,
      status: 500,
      message: this.translate.instant('GLOBAL.NO_DATA_AVAILABLE')
    };

    this.hideAdditionalInfos = this.errorData?.message?.hideAdditionalInfos ?? this.errorData?.hideAdditionalInfos ?? false;
    this.statusCode = (this.errorData?.message?.customCode ?? this.errorData.status ?? '500').toString();
    this.statusMessage = this.errorData?.message?.customMessage ?? 'ERRORS.SOMETHING_WENT_WRONG';

  }

  ngOnDestroy(): void { }

  reloadPage() {
    window.location.reload();
  }

  openInfoDialog() {

    let errorDetail = {
      url: this.errorData?.url,
      statusText: this.errorData?.statusText,
      status: this.errorData?.status,
      message: this.errorData?.message?.rawError ?? this.errorData?.message?.message ?? this.errorData?.message
    };

    this.dialog.open(ErrorDialogComponent, {
      panelClass: 'ff-dialog',
      maxWidth: "500px",
      data: {
        title: this.translate.instant("ERRORS.ERROR_DETAILS"),
        description: errorDetail
      },
    });
  }

}
