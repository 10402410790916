<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null " class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"
      [intAggrs]="intervalAggregations" (selectedAggr)="selectAggregation($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar tabs -->
    <div class="ff-toolbar pt-1 pb-3" fxLayout="column" fxLayoutAlign="start stretch" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">

      <!-- consumables dropdown -->
      <div *ngIf="consumables != null && consumables.length > 1" class="select-box" fxLayout="column"
        fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center" style="margin-right: 8px;">

        <div class="box" fxLayout="row" fxLayoutAlign="start center">
          <!-- icon -->
          <mat-icon *ngIf="consIcon != null && consIcon.type == 'icon'" class="icon">
            {{ consIcon.icon }}
          </mat-icon>
          <mat-icon *ngIf="consIcon != null && consIcon.type == 'svg'" svgIcon="{{ consIcon.icon }}" class="icon">
          </mat-icon>
          <i *ngIf="consIcon != null && (consIcon.type == 'fontawesome' || consIcon.type == 'phosphor')"
            class="icon {{ consIcon.icon }}"></i>

          <mat-form-field appearance="fill">
            <mat-label>{{ 'GLOBAL.DROPDOWN_CONSUMABLE_SELECTION' | translate }}</mat-label>
            <mat-select [(ngModel)]="consDropdown" name="cons" style="text-transform: capitalize;">
              <!-- [disabled]="consumables.length == 1" -->
              <mat-option *ngFor="let cons of consumables" [value]="cons.id" (click)="changeConsumable(consDropdown)">
                <span style="text-transform: capitalize;">{{ cons.label | translate }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <ff-filter-buttons [filterButtons]="[brandSelectionButton]" (selected)="onBrandDialogSelect($event)"
        [ngStyle.gt-sm]="{'margin-left': aggregations?.length > 0 ? '8px' : '0'}"
        [ngStyle.lt-md]="{'margin-top': aggregations?.length > 0 ? '8px' : '0'}">
      </ff-filter-buttons>

      <div class="arrows" fxLayoutAlign.lt-md="center center" fxLayout.gt-sm="row" style="margin-right: 8px;">
        <button class="mat-button rounded-button" (click)="getPreviousBucket()" style="margin-right: 8px;"
          #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.PREVIOUS_BUCKET' | translate }}"
          matTooltipPosition="above">
          <mat-icon svgIcon="arrow_left" class="icon"></mat-icon>
        </button>
        <button class="mat-button rounded-button" (click)="getNextBucket()" [disabled]="interval.enabledPolling"
          #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.NEXT_BUCKET' | translate }}" matTooltipPosition="above">
          <mat-icon svgIcon="arrow_right" class="icon"></mat-icon>
        </button>
      </div>

      <!-- aggregations -->
      <div *ngIf="aggregations != null && aggregations.length > 0" fxLayout="column" fxLayoutAlign="start start"
        fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">
        <button mat-button class="filter-button" *ngFor="let aggr of aggregations" (click)="openAggrDialog(aggr)"
          [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
          <mat-icon *ngIf="aggr.icon != null && aggr.icon.type == 'icon'" class="icon"
            translate-attr="{'aria-label': aggr.label}">
            {{aggr.icon.icon }}</mat-icon>
          <mat-icon *ngIf="aggr.icon != null && aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
            translate-attr="{'aria-label': aggr.label}"></mat-icon>
          <i *ngIf="aggr.icon != null && (aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor')"
            class="icon {{ aggr.icon.icon }}" translate-attr="{'aria-label': aggr.label}"></i>
          <span>{{ aggr.selectionLabel }}</span>
          <div fxLayout="row" fxLayoutAlign="center center"
            *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
            {{ aggregationsPayload[aggr.id].length }}
          </div>
        </button>
      </div>

      <span fxFlex></span>

      <ff-available-machines-selection [availableMachines]="availableMachines"
        (selected)="machineSelectionChange($event)"></ff-available-machines-selection>

    </div>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < 7" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 7" fxLayout="column" class="w100 h100">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
        class="h100 w100" fxLayoutGap.gt-sm="8px">

        <div *ngIf="!isMobile" fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="w100 h100"
          fxLayoutGap="8px">
          <ff-widget fxFlex="100"
            [widget]="{type: 'ff-plotly-chart-table', data: consData, machineProfile: machine.profile, config: consData.dataConfig }">
          </ff-widget>
        </div>

        <div fxFlex.gt-sm="{{dashboardConfig.secondColumnFlex}}" fxLayout="column" fxLayoutAlign="start stretch"
          class="w100 h100" fxLayoutGap.gt-sm="8px">

          <ff-widget *ngFor="let w of dashboardConfig.widgets" fxFlex.gt-sm="{{w.flex}}"
            [widget]="{ type: w.type, data: consData, machineProfile: machine.profile, config: w.config, title: w.title }">
          </ff-widget>

        </div>

      </div>

    </div>

  </div>

  <div fxFlex fxLayout="column" fxLayoutAlign="start start" *ngIf="!isMobile && pageState.value >= 7" fxLayout="row"
    class="w100" style="margin-top: 8px;">

    <ff-widget fxFlex="100"
      [widget]="{type: 'ff-plotly-chart', data: consData, machineProfile: machine.profile, config: consData.aggrDataConfig }">
    </ff-widget>

  </div>

</div>