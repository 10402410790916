import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'ff-column-array',
  templateUrl: './ff-column-array.component.html',
  styleUrls: ['./ff-column-array.component.scss']
})
export class FfColumnArrayComponent implements OnInit, OnChanges {

  @Input() widget: any;
  @HostBinding('class') classes: any;

  public machineId: any = null;

  constructor(
    public filterService: FiltersService,
    public internalDataService: InternalDataService,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public translate: FfTranslateService,
  ) { }

  parseWidgetData() {

    if (this.cacheService.get("machineId") != null) this.machineId = this.cacheService.get("machineId");

    try {

      let conf = this.widget.config ?? {};

      let finalArray = [];
      if (conf?.arrayFromProfile) {
        finalArray = this.widget?.machineProfile?.[conf?.arrayFromProfile] ?? [];
      }

      this.widget.rows = [];
      if (conf?.rowConfig?.length > 0) {

        let copyRowsConfig: any = this.clonerService.deepClone(conf?.rowConfig);
        finalArray.forEach((elem, idx) => {

          this.widget.rows.push([]);
          copyRowsConfig.forEach(col => {

            let id = elem?.[col?.valueVariable ?? 'id'];
            if (id != null) {
              col.variable = id;
              if (col?.valueRegex != null) col.variable = col?.valueRegex?.replace('{{id}}', id);
            }

            let label = elem?.[col?.labelVariable ?? 'label'];
            if (label != null) {
              col.label = this.translate.instant(label);
              if (col?.labelRegex != null) col.label = this.translate.instant(col?.labelRegex?.replace('{{label}}', label));
            }

            if (col?.unitVariable != null) {
              let unit = elem?.[col?.unitVariable ?? 'unit'];
              if (unit == null) unit = elem?.unit;
              if (unit != null) {
                col.unit = this.translate.instant(unit);
                if (col?.unitRegex != null) col.unit = this.translate.instant(col?.unitRegex?.replace('{{unit}}', this.translate.instant(unit ?? '-')));
              }
            }

            col.multiplier = col?.multiplier ?? 1;
            if (col?.multiplierVariable != null) {
              let multiplier = elem?.[col?.multiplierVariable ?? 'multiplier'];
              if (multiplier == null) multiplier = elem?.multiplier;
              if (multiplier != null) col.multiplier = multiplier;
            }

            let icon = elem?.[col?.iconVariable ?? 'icon'];
            if (icon != null) col.icon = icon;

            if (col?.decimalsVariable != null) {
              let decimals = elem?.[col?.decimalsVariable ?? 'decimals'];
              if (decimals == null) decimals = elem?.decimals;
              if (decimals != null) col.decimals = decimals;
            }

            this.parseInfos(col);

            this.widget.rows[idx].push(this.clonerService.deepClone(col));

          });
        })
      }

      // console.log(this.widget.rows);

      if (this.widget.class != null) this.classes = this.widget.class;
    } catch (error) { console.log(error) }

  }

  parseInfos(v: any) {

    if (v.label != null) {
      try { v.label = this.internalDataService.parseDatapointLabel(v.label.replaceAll('{{machineId}}', this.machineId), null, this.machineId) }
      catch (error) { console.log(error) }
    }

    let val = this.widget.data?.[v.variable];

    if (val != null) {
      if (typeof val == 'string') {
        v.value = val;
        if (v.format == 'date') {
          v.value = val != null && val != '-' ? this.filterService.parseMoment(val, 'default') : "-";
        }
      } else {
        if (v.format == 'time') {
          v.value = this.filterService.parseTime(this.widget.data[v.variable], 's', 'hh:mm:ss');
        } else if (v.type == 'progressBar') {
          v.value = this.widget.data[v.variable];
        } else {
          v.value = this.filterService.parseGaugeValue(val, v.decimals, v.multiplier);
        }
      }
    } else {
      v.value = null;
    }

    if (v.dynamicClass != null) {

      try {
        let dynamicVar = v.dynamicClass.variable;
        let dynamicConfig = v.dynamicClass.config;
        let dynamicClassName = v.dynamicClass.className;

        let compTypeIdx = this.widget.data[dynamicConfig].findIndex((x: any) => x.id == this.widget.data[dynamicVar]);
        if (compTypeIdx != -1) v.class = this.widget.data[dynamicConfig][compTypeIdx][dynamicClassName != null ? dynamicClassName : 'class'];
      } catch (error) { console.log(error) }

    }
  }

  ngOnInit(): void {
    this.parseWidgetData();
  }

  ngOnChanges(): void {
    this.parseWidgetData();
  }

}
