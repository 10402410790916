<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <div fxLayout="row" fxLayoutAlign="start start">

    <div style="margin: 0 12px;" fxLayout="column" fxLayoutAlign="center start" class="interval-selector">

      <span style="opacity: 0.8; font-size: 0.8rem;">{{ 'CALENDAR.WEEK_TO_COPY' | translate }}</span>
      <div class="ff-input-select" style="margin: 4px 0;" fxLayout="row" fxLayoutAlign="start center">
        <mat-select [(value)]="dialog.totalWeeks.selected">
          <mat-option *ngFor="let week of dialog.totalWeeks.list" [value]="week">
            {{ week.id }}
          </mat-option>
        </mat-select>
      </div>

      <div fxLayout="column" *ngIf="dialog.totalWeeks.selected != null">
        <span style="padding: 24px 0 8px 0;"><strong>{{ 'CALENDAR.START' | translate }}</strong>:
          {{dialog.totalWeeks.selected.start}}</span>
        <span style="padding: 8px 0 24px 0"><strong>{{ 'CALENDAR.END' | translate }}</strong>:
          {{dialog.totalWeeks.selected.end}}</span>
      </div>

    </div>

    <div style="margin: 0 12px;" fxLayout="column" fxLayoutAlign="center start" class="interval-selector">

      <span style="opacity: 0.8; font-size: 0.8rem;">{{ 'CALENDAR.WEEK_TO_COPY_TO' | translate }}</span>
      <div class="ff-input-select" style="margin: 4px 0;" fxLayout="row" fxLayoutAlign="start center">
        <mat-select [(value)]="dialog.totalWeeks.selectedTo">
          <mat-option *ngFor="let week of dialog.totalWeeks.list" [value]="week">
            {{ week.id }}
          </mat-option>
        </mat-select>
      </div>

      <div fxLayout="column" *ngIf="dialog.totalWeeks.selectedTo != null">
        <span style="padding: 24px 0 8px 0;"><strong>{{ 'CALENDAR.START' | translate }}</strong>:
          {{dialog.totalWeeks.selectedTo.start}}</span>
        <span style="padding: 8px 0 24px 0"><strong>{{ 'CALENDAR.END' | translate }}</strong>:
          {{dialog.totalWeeks.selectedTo.end}}</span>
      </div>

    </div>

    <div style="margin: 4px 0;" fxLayout="column">
      <span style="opacity: 0.8; font-size: 0.8rem;">{{ 'CALENDAR.NUMBER_OF_WEEKS' | translate }}</span>
      <input class="ff-input" type="number" id="numberOfWeeks" name="numberOfWeeks" placeholder="-"
        [(ngModel)]="dialog.totalWeeks.numberOfWeeks" min="1" [max]="maxNumberOfWeeksToCopy">
    </div>

  </div>

  <div fxLayout="column" *ngIf="dialog.totalWeeks?.selected != null" style="margin-top: 12px;">

    <div fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 12px;">
      <mat-checkbox [(ngModel)]="dialog.totalWeeks.selected.copyDowntime"></mat-checkbox>
      <span right>{{ 'CALENDAR.COPY_DOWNTIME' | translate }}</span>
      <ff-icon icon="info" right matTooltip="{{ 'CALENDAR.COPY_DOWNTIME_INFO' | translate }}"></ff-icon>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 12px;">
      <mat-checkbox [(ngModel)]="dialog.totalWeeks.selected.overwriteDowntime"></mat-checkbox>
      <span right>{{ 'CALENDAR.OVERWRITE_DOWNTIME' | translate }}</span>
      <ff-icon icon="info" right matTooltip="{{ 'CALENDAR.OVERWRITE_DOWNTIME_INFO' | translate }}"></ff-icon>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 12px;" *ngIf="dialog?.isOperators">
      <mat-checkbox [(ngModel)]="dialog.totalWeeks.selected.copyOperators"></mat-checkbox>
      <span right>{{ 'CALENDAR.COPY_OPERATORS' | translate }}</span>
      <ff-icon icon="info" right matTooltip="{{ 'CALENDAR.COPY_OPERATORS_INFO' | translate }}"></ff-icon>
    </div>

  </div>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button class="filter-button md-primary" (click)="closeDialog()" cdkFocusInitial [disabled]="dialog == null || dialog.totalWeeks == null || dialog.totalWeeks.numberOfWeeks <= 0 || dialog.totalWeeks.numberOfWeeks > maxNumberOfWeeksToCopy || dialog.totalWeeks.numberOfWeeks == null || 
  dialog.totalWeeks.selected == null || dialog.totalWeeks.selectedTo == null">
    {{ 'GLOBAL.SELECT' | translate }}
  </button>
</mat-dialog-actions>