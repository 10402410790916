import { trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable, SortDirection } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { FiltersDialogComponent } from 'src/app/components/filters-dialog/filters-dialog.component';
import { GcaInputDialogComponent } from 'src/app/components/gca-input-dialog/gca-input-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-cycles-table',
  templateUrl: './cycles.component.html',
  styleUrls: ['./cycles.component.scss'],
  animations: [
    trigger('detailExpand', []),
  ]
})
export class CycleTraceabilityComponent implements OnInit, OnDestroy {

  public isAllowedUser: boolean = true;
  public isAllowedUserWrite: boolean = true;
  public trainingAction: any;
  public groupByStandardStatistics: any = ["aggr0"];

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;
  public cyclesInfo: any;
  public cyclesSort: {
    variable: String,
    direction: SortDirection
  };
  public cyclesColumns: string[] = [];
  public phasesInfo: any;
  public phasesSort: any;
  public phasesColumns: string[] = [];

  public breadcrumb: any;
  public tabs: any;
  public searchCycles: any;

  public machine: any;
  public machineId: any;
  public machineSelectedSub: Subscription;

  public pollingTime: any;
  public pollingCycles: any;

  public interval: any;
  public intervalConfig: any;

  public cycles: any;
  public cyclesSelected: any = [];
  public cyclesData = new MatTableDataSource<any[]>();
  public cycleExpanded: any | null;

  public dashboardConfig: any;
  public monitoringData: any;

  public cyclesFilters: any;
  public filterButtons: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  private sort!: MatSort;
  @ViewChild(MatSort) set MatSort(ms: MatSort) {
    this.sort = ms;
    this.setSort();
  }

  setSort() {
    this.cyclesData.sort = this.sort;
  }

  @ViewChildren('innerTables') innerTables: QueryList<MatTable<any>> | undefined;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getCyclesWidgets, nextState: 4, loadingMsg: 'LOADING.CYCLES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getCyclesPolling, nextState: 5, loadingMsg: 'LOADING.CYCLES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public cacheService: CacheService,
    public clonerService: ClonerService,
    private router: Router,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.cyclesInfo = this.appConfig.cycleTraceability?.cycles?.cycleInfo ?? [];
    this.cyclesColumns.push('icon');
    this.cyclesInfo.forEach((element: any) => {
      if (!element.hideDefault) this.cyclesColumns.push(element.variable);
    });

    try {
      this.cyclesSort = {
        variable: this.appConfig.cycleTraceability.cycles.sortDefaultCycles.variable,
        direction: this.appConfig.cycleTraceability.cycles.sortDefaultCycles.direction
      };
    } catch (error) {
      console.log(error);
    }

    this.phasesInfo = this.appConfig.cycleTraceability.cycles.phaseInfo;
    this.phasesInfo.forEach((element: any) => {
      if (!element.hideDefault) this.phasesColumns.push(element.variable);
    });

    try {
      this.phasesSort = {
        variable: this.appConfig.cycleTraceability.cycles.sortDefaultPhases.variable,
        direction: this.appConfig.cycleTraceability.cycles.sortDefaultPhases.direction
      };
    } catch (error) {
      console.log(error);
    }

    this.breadcrumb = ['CYCLE_TRACEABILITY.TITLE', 'CYCLES.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.tabs = this.internalDataService.getPageTabs('cycleTraceability');

    this.pollingTime = 0;
    this.pollingCycles = Subscription;

    this.cycles = {
      list: [],
      filtered: [],
      selected: null,
      pageOptions: [25, 50, 100],
      pageSize: 25,
      search: ""
    };

    this.cyclesData = new MatTableDataSource<any[]>([]);

    this.cyclesFilters = {
      search: null,
      cyclesMax: null,
      cyclesMin: null,
      sapCodes: [],
      types: [],
      params: []
    };

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUserWrite = _this.internalDataService.getSpecificPermission("mat-ct-rw");
    } catch (error) { console.log(error) }

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(["mat-ct-r", "mat-ct-rw"], 'or');
    } catch (error) { console.log(error) }


    _this.cyclesColumns = [];
    _this.cyclesInfo = _this.cyclesInfo.filter((x: any) => x.permission == null || _this.isAllowedUserWrite);
    _this.cyclesColumns.push('icon');
    _this.cyclesInfo.forEach((element: any) => { if (!element.hideDefault) _this.cyclesColumns.push(element.variable) });

    _this.phasesColumns = [];
    _this.phasesInfo = _this.phasesInfo.filter((x: any) => x.permission == null || _this.isAllowedUserWrite);
    _this.phasesInfo.forEach((element: any) => { if (!element.hideDefault) _this.phasesColumns.push(element.variable) });

    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'cycleTraceability');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingCycles, this.getCyclesPolling, this.getCycles, this.machine.timezone) };


  public getCyclesWidgets(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, "cycle-traceability");
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // cycles

  // polling
  getCyclesPolling(_this: any) {
    try {

      if (_this.cacheService.get("intervalCycles") == null) {

        _this.interval = _this.intervalService.getIntervalById('last7Days', _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.cacheService.set("intervalCycles", _this.intervalConfig);

      } else {
        _this.intervalConfig = _this.cacheService.get("intervalCycles");
        _this.interval = _this.cacheService.get("intervalCycles").selected;
      }

      if (_this.pollingTime > 0) {
        _this.pollingCycles = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getCycles(_this, count);
        });
      } else {
        _this.getCycles(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get cycles
  getCycles(_this: any, count?: any) {
    try {

      let payload = { filters: {} } //_this.internalDataService.buildMachinePayload(machine);
      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone,
      };

      if (_this.interval != null && _this.interval.id == 'custom') {
        query.to = _this.interval.end;
      }

      _this.apiService.sendGetRequest('/apif/traceability/cycles/' + _this.machineId, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingCycles))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            // if (count == 0) {
            _this.dispatcherService.getDispatch(_this, 300);
            // }

            _this.cycles.list = _this.parseCyclesTable(_this, data.body);

            _this.monitoringData = _this.parseMonitoringData(_this.cycles.list);

            _this.filterButtons = _this.buildFilterButtons(_this);

            // setTimeout(() => {
            _this.filterCycles(null, count);
            // }, 100);

          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseMonitoringData(cycles: any) {
    try {
      let parsedCycles = this.internalDataService.addStandardStatistics(cycles, this.interval?.numberOfDays, this.groupByStandardStatistics)
      // console.log({ parsedCycles });
      return parsedCycles;

    } catch (error) {}
  };

  parseCyclesTable(_this: any, data: any) {
    if (data != null && data.cycles != null && data.cycles.length > 0) {
      data.cycles.forEach((element: any, elemIndex: any) => {
        // cycle attributes
        // element.gca = Math.random();
        // if (Math.random() > 0.5) element.trainSet = true;
        element.expanded = false;
        element.timeStartP = _this.filterService.parseMoment(element.timeStart, 'default');
        element.timeEndP = _this.filterService.parseMoment(element.timeEnd, 'default');
        element.intervalP = element.timeStartP + "\n" + element.timeEndP;
        element.durationP = (element.duration != null) ?
          _this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss') :
          // _this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss', element.timeStart, element.timeEnd);
          '-';
        element.alarmTimeP = _this.filterService.parseDuration(element.alarmTime, 's', 'hh:mm:ss');
        element.signalationTimeP = _this.filterService.parseDuration(element.signalationTime, 's', 'hh:mm:ss');
        element.waitingTimeP = _this.filterService.parseDuration(element.waitingTime, 's', 'hh:mm:ss');
        element.techTimeP = _this.filterService.parseDuration(element.techTime, 's', 'hh:mm:ss');
        element.processTimeP = _this.filterService.parseDuration(element.processTime, 's', 'hh:mm:ss');
        element.maintTimeP = _this.filterService.parseDuration(element.maintTime, 's', 'hh:mm:ss');

        // if (elemIndex == 2) element.cycleType = 2;
        _this.parseCycleType(_this, element);

        _this.parseParams(_this, element);

        element.compositeCycleId = element.id + '$$' + element.timeStart + '$$' + element.timeEnd + '$$' + element.cycleType;

        element.urlArray = ["/" + _this.machineId, 'cycle-traceability', element.compositeCycleId, 'cycle-timeline'];
        element.phases.forEach((element: any) => {
          // phase attributes
          try {
            let phaseConfig = _this.machine.profile.phases.find((x: any) => x.inputPhases.includes(parseInt(element.phaseId)));
            if (phaseConfig != null) {
              element.icon = phaseConfig.icon;
              element.phaseNameP = this.translate.instant(phaseConfig.label);
              // let color = phaseConfig.color;
            }
          } catch (error) {
            console.log(error);
          }

          element.timeStartP = _this.filterService.parseMoment(element.timeStart, 'default');
          element.timeEndP = _this.filterService.parseMoment(element.timeEnd, 'default');
          element.intervalP = element.timeStartP + "\n" + element.timeEndP;
          element.durationP = (element.duration != null) ?
            _this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss') :
            // _this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss', element.timeStart, element.timeEnd);
            '-';
          element.alarmTimeP = _this.filterService.parseDuration(element.alarmTime, 's', 'hh:mm:ss');
          element.signalationTimeP = _this.filterService.parseDuration(element.signalationTime, 's', 'hh:mm:ss');
          element.waitingTimeP = _this.filterService.parseDuration(element.waitingTime, 's', 'hh:mm:ss');
          element.techTimeP = _this.filterService.parseDuration(element.techTime, 's', 'hh:mm:ss');
          element.processTimeP = _this.filterService.parseDuration(element.processTime, 's', 'hh:mm:ss');
          element.maintTimeP = _this.filterService.parseDuration(element.maintTime, 's', 'hh:mm:ss');
        });
      });

      data.cycles.sort(_this.filterService.sortByProperty(this.appConfig.cycleTraceability.cycles.sortDefaultCycles.variable, this.appConfig.cycleTraceability.cycles.sortDefaultCycles.direction));

      return data.cycles;
    }
    return []
  }


  navigateToCycleDetail(cycle) {
    this.internalDataService.setCycleSelected(cycle.compositeCycleId);
    this.router.navigate([this.machineId + '/cycle-traceability/' + cycle.compositeCycleId + '/cycle-timeline']);
  }

  parseCycleType(_this: any, element: any) {
    try {
      let cycleType = null;
      if (_this.machine.profile.cycles != null &&
        _this.machine.profile.cycles.length > 0) {
        if (element.hasOwnProperty('cycleType') && element.cycleType != null) {
          let ix = _this.machine.profile.cycles.findIndex(x => x.id == element.cycleType);
          if (ix != -1) cycleType = _this.machine.profile.cycles[ix];
        } else {
          for (let phase of element.phases) {
            for (let cycle of _this.machine.profile.cycles) {
              if (cycle.havePhases.length == 0) {
                cycleType = cycle;
              }
              let ix = cycle.havePhases.findIndex((x: any) => { return x == parseInt(phase.phaseId); })
              if (ix != -1) {
                cycleType = cycle;
                break;
              }
            }
            // if (cycleType != null) break;
          }
        }
        element.typeP = cycleType != null ? cycleType : _this.machine.profile.cycles.find((x: any) => x.id == 0);
        try { element.cycleType = element.typeP.id } catch (error) { }
      }
    } catch (error) {
      console.log('Failed to parse params')
    }
  }

  parseParams(_this: any, element: any) {
    let paramsP_all = [];
    try {
      if (element.params != null) {
        for (let param in element.params) {
          let ix = _this.machine.profile.paramsR.findIndex((x: any) => { return x.variable.toLowerCase() == param.toLocaleLowerCase(); })
          if (ix != -1) {
            let p = _this.clonerService.deepClone(_this.machine.profile.paramsR[ix]);
            p.label = _this.internalDataService.parseParamsRLabel(param, null, _this.machineId);
            p.value = _this.clonerService.deepClone(element.params[param]);
            paramsP_all.push(p);
          }
        }
      }
    } catch (error) {
      console.log('Failed to parse params')
    }
    element.paramsP_all = paramsP_all;
    element.paramsP = [];

    try {
      element.paramsP_max = _this.machine.profile.paramsRMax;
    } catch (error) { }
  }

  filterCycles(searchEvent?: any, count?: any) {

    // try {
    //   console.log(this.cyclesFilters.params.filter(x => x.selected));
    // } catch (error) { console.log(error) }

    let filterVariables = this.appConfig.cycleTraceability.cycles.filterVariables != null ? this.appConfig.cycleTraceability.cycles.filterVariables : ["id"];

    let filtered: any = [];

    filtered = this.cycles.list;

    // filter on searchEvent
    try {
      filtered = filtered.filter((data: any) => filterVariables.some((x: any) => this.searchCycles == null || this.searchCycles == '' ? true : String(data[x]).toLowerCase().includes(this.searchCycles.toLowerCase())));
    } catch (error) {
      console.log(error);
    }

    // filter on types (typeP)
    try {
      if (this.cyclesFilters.types.length > 0) {
        filtered = filtered.filter((data: any) => this.cyclesFilters.types.some((x: any) => x.selected && data.typeP.id === x.id));
      }
    } catch (error) {
      console.log(error);
    }

    // init default params
    if (count == 0 && this.cacheService.get("cycleParamsR") == null) {
      try {
        for (let param of this.cyclesFilters.params) {
          if (this.machine.profile.paramsRDefault &&
            this.machine.profile.paramsRDefault.includes(param.id)) {
            param.selected = true;
            for (let item of filtered) {
              item.paramsP.push(item.paramsP_all.find((x: any) => { return x.variable == param.id; }));
            }
          }
        }
      } catch (error) { }
    }
    // set params
    else {
      for (let flt of filtered) {
        flt.paramsP = flt.paramsP_all.filter((x: any) => this.cyclesFilters.params.some((y: any) => y.selected && y.id == x.variable));
      }
    }

    // sort table
    if (count == 0) {
      filtered.sort(
        this.filterService.sortByProperty(
          // (this.sort != null && this.sort.active != null) ? this.sort.active : 'timeStartP',
          (this.sort != null && this.sort.active != null) ? this.sort.active : this.appConfig.cycleTraceability.cycles.sortDefaultCycles.variable,
          this.appConfig.cycleTraceability.cycles.sortDefaultCycles.direction,
          true
        )
      );

      if (this.sort != null) {
        this.sort.sort(({
          id: this.appConfig.cycleTraceability.cycles.sortDefaultCycles.variable,
          start: this.appConfig.cycleTraceability.cycles.sortDefaultCycles.direction
        }) as MatSortable);
      }
    }

    this.cycles.filtered = filtered;

    this.monitoringData = this.parseMonitoringData(this.cycles.filtered);

    let cd = new MatTableDataSource<any>();

    if (cd.paginator) cd.paginator.firstPage();

    cd.paginator = this.paginator;
    cd.sort = this.sort;
    cd.data = filtered;

    this.cyclesData = cd;
  }

  getPhasesColSpan() {
    if (this.cyclesColumns.length <= this.phasesColumns.length) return this.phasesColumns.length;
    return this.cyclesColumns.length;
  }

  toggleRow(element: any) {
    element.expanded = !element.expanded;
  }

  buildFilterButtons(_this: any) {
    let buttons: any[] = [];
    try {

      for (let attribute of _this.appConfig.cycleTraceability.cyclesFilters) {
        attribute.config = {};

        if (attribute.filtrable) {
          attribute = JSON.parse(JSON.stringify(attribute));
          let options = [];
          for (let item of _this.cycles.list) {
            if (item.hasOwnProperty(attribute.variable) && item[attribute.variable] != null) {
              if (!attribute.type) {
                let x = (item[attribute.variable]).toString();
                options.push({
                  id: x,
                  label: this.translate.instant(x),
                  selected: true
                });
              }
              else if (attribute.type == 'object') {
                let opt = {
                  id: item[attribute.variable].id,
                  label: this.translate.instant(item[attribute.variable].label),
                  selected: true,
                  icon: null
                };
                if (item[attribute.variable].icon != null) {
                  opt.icon = item[attribute.variable].icon;
                }
                options.push(opt);
              }
              else if (attribute.type == 'objlist') {
                for (let attr in item[attribute.variable]) {

                  let paramRConfIdx = this.machine.profile.paramsR.findIndex((x: any) => x.variable == attr);

                  let paramRConf = paramRConfIdx != -1 ? this.machine.profile.paramsR[paramRConfIdx] : null;

                  if (attr.startsWith("param") && paramRConf != null && !paramRConf.hide) {
                    let opt = {
                      id: attr,
                      index: 0,
                      // label: this.translate.instant('PARAMS_R.' + _this.machine.assetId + '.' + attr),
                      label: this.internalDataService.parseParamsRLabel(attr, null, this.machineId),
                      selected: false
                    };

                    if (attribute.variable == 'params') opt.index = Number(opt.id.split('param')[1]);

                    if (attribute.hasOwnProperty('maxSel') && attribute.maxSel > 0) {
                      attribute.config.maxSel = attribute.maxSel;
                      attribute.config.hideSelAll = true;
                    }

                    options.push(opt);
                  }
                }
                options.sort(this.filterService.sortByProperty('index', 'asc', true));
              }
            }
          }
          // remove duplicates
          let optionsClear: any = [];
          for (let opt of options) {
            if (optionsClear.findIndex((x: any) => { return x.id == opt.id }) == -1) optionsClear.push(opt);
          }
          attribute.options = optionsClear;
          buttons.push(attribute);
        }

        if (attribute.variable == 'typeP') {
          this.cyclesFilters.types = attribute.options;
        }
        if (attribute.variable == 'params') {
          // this.cyclesFilters.params = attribute.options;
          let cachedParams = this.clonerService.deepClone(this.cacheService.get("cycleParamsR"));
          if (cachedParams == null || !Array.isArray(cachedParams) || cachedParams.length == 0) {
            // this.cyclesFilters.params = attribute.options;
          } else {
            attribute.options = cachedParams;
          }
          this.cyclesFilters.params = attribute.options;
        }
      }

    } catch (error) {
      console.log('Failed to build filter buttons: ' + error)
    }
    return buttons;
  }

  openFilterDialog(button: any) {
    // console.log(button)
    let filtersDialog = this.dialog.open(FiltersDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(button.label),
        variable: button.variable,
        options: button.options,
        config: button.config
      }
    });

    filtersDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') {
        // console.log('afterClosed', result)
        result = JSON.parse(JSON.stringify(result));
        button.options = result.options;

        if (result.variable == 'typeP') this.cyclesFilters.types = result.options;
        if (result.variable == 'params') {
          this.cyclesFilters.params = result.options;
          this.cacheService.set("cycleParamsR", this.cyclesFilters.params);
          // console.log(result.options.filter(x => x.selected));

        }

        this.filterCycles();

      }
    });
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GCA

  // SELECT / UNSELECT CYCLE
  toggleCycleForGCA(cycle: any) {
    // console.log("%c Cycle " + cycle.id, 'color: red;');
    // console.log(cycle);

    if (this.cyclesSelected != null && Array.isArray(this.cyclesSelected)) {

      if (this.cyclesSelected.length == 0) {
        this.cyclesSelected.push(cycle);
        cycle.gcaSelected = true;
        this.trainingAction = !cycle.trainSet ? 'add' : 'remove';
        return;
      }
      let ix = this.cyclesSelected.findIndex((x: any) => x.id == cycle.id && x.cycleType == cycle.cycleType);
      if (ix == -1) {
        this.cyclesSelected.push(cycle);
        cycle.gcaSelected = true;
      }
      else {
        this.cyclesSelected.splice(ix, 1);
        cycle.gcaSelected = false;
      }
      if (this.cyclesSelected.length == 0) this.trainingAction = null;
    }

  }

  checkCycleCheckboxClasses(cycle) {
    if (this.trainingAction == null) return "clickable";
    else if (this.trainingAction == 'add') return !cycle.trainSet ? "clickable" : "ff-disabled completely";
    else return cycle.trainSet ? "clickable" : "ff-disabled completely";
  }

  //  OPEN GCA DIALOG
  openGCADialog(type: any = 'train', action: any = 'add') {

    let dialogTitle = "";
    let confirmTitle = "";

    if (action == 'apocalypse') {

      dialogTitle = "CYCLES.CLEAN_TRAINING_SET";
      let description = "CYCLES.CLEAN_TRAINING_SET_DESCRIPTION";

      let confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        panelClass: 'ff-dialog',
        data: {
          title: dialogTitle,
          description: description
        }
      });

      confirmationDialog.afterClosed().subscribe((result: any) => {

        if (result != null && result != '') {

          console.log('Remove training set');

          this.updateGCA(this, [], type, action);

          this.cyclesSelected = [];
          this.trainingAction = null;
          this.cycles.filtered.forEach((cycle: any) => cycle.gcaSelected = false);

        }

      });

    } else {

      dialogTitle = type == 'train' ? (action == 'add' ? "CYCLES.CYCLES_TO_TRAIN" : "CYCLES.REMOVE_FROM_TRAIN_SET") : "CYCLES.CYCLES_TO_RE_EVALUATE";
      confirmTitle = type == 'train' ? (action == 'add' ? "CYCLES.CONFIRM_TRAINING" : "CYCLES.CONFIRM_DELETION") : "CYCLES.CONFIRM_RE_EVALUATION";

      let filtersDialog = this.dialog.open(GcaInputDialogComponent, {
        panelClass: 'ff-dialog',
        data: {
          title: dialogTitle,
          confirmTitle: confirmTitle,
          list: this.cyclesSelected
        }
      });

      filtersDialog.afterClosed().subscribe((result: any) => {

        if (result != null && result != '') {

          console.log(result);

          let propsToKeep = ["id", "cycleType", "duration", "timeStart", "timeEnd"];
          result = result.map((cycle: any) => this.filterService.removeKeysWithCustomRule(cycle, (x: any) => propsToKeep.includes(x[0])));

          if (type == 'train') this.updateGCA(this, result, type, action);
          else this.updateGCA(this, result, 'evaluation');

          this.cyclesSelected = [];
          this.trainingAction = null;
          this.cycles.filtered.forEach((cycle: any) => cycle.gcaSelected = false);

        }

      });
    }

  }

  //  UPDATE GCA SET
  updateGCA(_this: any, cycles: any, type: any = "train", action: any = null) {

    let url = "/apif/" + (type == 'train' ? 'gca-config' : 'gca-push') + "/" + _this.machineId;

    let query = action != null ? { action: action } : null;
    let payload = cycles;

    _this.apiService.sendPostRequest(url, payload, query)
      .pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {
          console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);

          this.snackBar.open(this.translate.instant('CYCLES.GCA_RESPONSE.' + (error.status == 409 ? '409' : '500')), '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['snackbar', 'fail']
          });

          return throwError(error.status + ': ' + error.statusText);
        })
      )
      .subscribe(
        (data: any) => {
          console.log(data.body);

          this.snackBar.open(this.translate.instant('CYCLES.GCA_RESPONSE.204'), '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['snackbar', 'success']
          });

        }
      );

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pageState.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.pollingCycles.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
  }

}