<ff-tabs [tabs]="tabs"></ff-tabs>

<div fxFlex fxLayout="row" fxLayoutAlign="start start" class="w100 h100 tab-content-animated">
  <div *ngFor="let tab of tabs" fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content transition-01"
    [ngClass]="{'z-index-m1': !tab.selected, 'translate-l100': tab.left, 'translate-r100': tab.right }">

    <div class="w100 h100">
      <ff-widget (clicked)="onButtonClick($event)" [fxFlex.gt-sm]="'calc(' + widget.flex + '% - 8px)'"
        [fxShow.lt-md]="!widget?.hideMobile" [fxFlex.sm]="'calc(' + widget.mobileFlex + '% - 8px )'"
        [fxFlexOrder.sm]="widget?.mobileOrder" [widget]="{ 
          type: tab?.type != null ? tab.type : 'ff-table-sortable', 
          data: widget?.data, 
          machineProfile: widget?.machineProfile, 
          config: tab?.config, 
          title: tab?.title,
          interval: widget?.interval, 
          intervalAggregations: widget?.intervalAggregations,
          referenceComponent: widget?.referenceComponent
        }">
      </ff-widget>
    </div>

  </div>
</div>
