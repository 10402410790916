import { Component, OnInit } from '@angular/core';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {

  public breadcrumb: any;
  public errorData = {
    type: 0,
    status: 404,
    message: this.translate.instant('GLOBAL.PAGE_NOT_AVAILABLE'),
    origin: "Front End",
    module: "Error page",
    function: "Unknown function"
  };

  constructor(
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public translate: FfTranslateService
  ) {

    this.breadcrumb = ['GLOBAL.ERROR'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

  }

  ngOnInit(): void { }

}
