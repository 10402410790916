import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-maintenance-component-dialog',
  templateUrl: './maintenance-component-dialog.component.html',
  styleUrls: ['./maintenance-component-dialog.component.scss']
})
export class MaintenanceComponentDialogComponent implements OnInit {

  public state: any = 0;
  public statesConfig: any = {
    data: 1,
  };

  public editMode: any = false;
  public showWidget: any = false;
  public nowTime: any;
  public completeDashboardConfig: any;
  public errors: any = [];

  public srcResult: any;
  public fileInfos: any;
  public additionalAttachmentsInfos: any;

  public permission: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<MaintenanceComponentDialogComponent>,
    public http: ApiService,
    public intervalService: IntervalService,
    public clonerService: ClonerService,
    public translate: FfTranslateService,
    public apiService: ApiService,
    public filterService: FiltersService,
    public internalDataService: InternalDataService,
    public additionalDialog: MatDialog,
  ) { }

  ngOnInit() {

    this.permission = this.internalDataService.getSpecificPermission(this.dialog.buttonInfos?.editPermission ?? "mat-maintenance-edit");

    this.nowTime = new Date().toISOString().substring(0, 16);

    this.state = 0;

    // Populate all the lists that have a closed list from the machine profiles, with the following config:
    // - editableInDialog:  true
    // - dialogEditType:    'closedList'
    // - listFromBE:        false || none
    // - type:              'configFromProfile'
    try {
      let closedLists = this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'closedList' && !x.listFromBE);
      closedLists = closedLists?.reduce((acc, info) => {
        if (info?.type != 'configFromProfile' || this.dialog?.machine?.profile == null) {
          acc.push(info);
          return acc;
        }

        let mappingKey = info?.mappingConfig?.key;
        if (typeof mappingKey === 'string' && mappingKey.includes('.')) {
          mappingKey = mappingKey.split('.');
        }

        let list = [];
        if (Array.isArray(mappingKey)) {
          list = this.clonerService.deepClone(this.dialog?.machine?.profile);
          for (let m_key of mappingKey) {
            list = list?.[m_key];
          }
        } else {
          list = this.clonerService.deepClone(this.dialog?.machine?.profile?.[mappingKey ?? 'timeStates']);
        }

        if (info?.onSelect != null) this.onSelectFromClosedList(info, list.find(x => x?.id == this.dialog.row[info?.variable]));

        this.parseObjectTypeClosedList(info, list);
        acc.push(info);

        return acc;
      }, []);
      closedLists?.filter(x => x?.listOptions?.length == 1).forEach(list => this.dialog.row[list.variable] = list.listOptions[0]);
    } catch (error) { console.log(error) }

    // // // // // // // // // // // // // // // // // // // // // // 
    // COMPONENTS 
    // // // // // // // // // // // // // // // // // // // // // // 

    console.log('DIALOG', this.dialog);

    if (this?.dialog?.endpointUrl != null) {

      this.statesConfig.data = 0;

      (async () => {
        let endpointUrl = this?.dialog?.endpointUrl?.replaceAll("{machineId}", this.dialog.machineId).replace("{componentId}", this.dialog.row.id);

        let query: any = {
          tz: this.dialog?.machine?.timezone,
          lang: this.translate?.currentLang ?? 'en',
        };

        let payload = this.internalDataService.buildMachinePayload(this.dialog?.machine);

        try {

          let method = 'POST';
          if (endpointUrl.includes(':')) {
            endpointUrl = endpointUrl.split(':');
            method = endpointUrl[0];
            endpointUrl = endpointUrl[1];
          }

          let requestSent = null;
          if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
          else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
          else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

          const data: any = await requestSent.toPromise();

          //------------------------------------------------------------------------//
          // RESPONSE PARSING

          let copyData: any = this.clonerService.deepClone(data?.body ?? {});

          this.completeDashboardConfig = {
            dashboardData: this.clonerService.deepClone({ ...copyData, ...this.dialog.row }),
            machineProfile: this.dialog?.machine?.profile,
            dashboardConfig: { widgets: this.dialog?.widgets }
          };

          console.log(this.completeDashboardConfig);

          this.statesConfig.data = 1;
          this.checkState();

        } catch (error) {
          this.statesConfig.data = 1;
          this.checkState();
        }

      })();
    } else {
      this.completeDashboardConfig = {
        dashboardData: this.clonerService.deepClone(this.dialog.row),
        machineProfile: this.dialog?.machine?.profile,
        dashboardConfig: { widgets: this.dialog?.widgets }
      };

      this.statesConfig.data = 1;
      this.checkState();
    }

  }

  checkState() {
    try { this.state = Object.values(this.statesConfig).every(x => x == 1) ? 1 : 0 }
    catch (error) {
      console.log(error);
      return 0;
    }
  }

  onSelectFromClosedList(info: any, selectedOption: any) {

    if (info?.onSelect == null) return;

    let onSelect = info.onSelect;

    let val = selectedOption?.[onSelect.key];
    this.dialog.row[onSelect.as] = onSelect.translate ? this.translate.instant(val) : val;

  }

  getUnit(info: any) {
    if (info.unitKey == null) return this.translate.instant(info.unit);
    else return this.translate.instant(this.dialog.row?.[info.unitKey]);
  }

  checkDisability() {
    let tableInfosCheck = !this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x.requiredInDialog).every(x => (this?.dialog?.row?.[x?.variable] != null && this?.dialog?.row?.[x?.variable] != ''));
    return tableInfosCheck;
  }

  parseObjectTypeClosedList(info: any, data: any) {

    info.listOptions = data?.filter(x => {

      // If element is not object, don't filter
      if (typeof x != 'object') return true;

      // If object doesn't have key "filterForSpecificVariableAndValue", don't filter
      if (info?.filterForSpecificVariableAndValue == null) return true;

      // Return only elements that have value associated to the specified "variable" equal to the specified "value"
      return x?.[info?.filterForSpecificVariableAndValue?.variable] == info?.filterForSpecificVariableAndValue?.value;
    })
      // Map on nestedKey if present
      .map(x => info?.nestedKeyToMap ? this.translate.instant(x?.[info?.nestedKeyToMap] ?? '-') : x) ?? [];

    if (info?.nestedKeysToKeep?.length > 0) {
      info.listOptionsDict = data?.reduce((acc, val) => {

        if (typeof val == 'object') {
          let obj: any = {}

          info?.nestedKeysToKeep?.forEach(k => {
            if (typeof k == 'object') {
              // If "primaryKey", substitute the id in the incoming object with the variable from info
              if (k?.primaryKey) obj[info.variable] = this.translate.instant(val[k.id] ?? '-');
              // Else map the newId (if present) with the correct value
              else obj[k?.newId ?? k?.id] = val[k.id];
            }
            else obj[k] = val[k];
          });
          acc.push(obj);
        }

        return acc;
      }, []);
      console.log(info.listOptionsDict);
    }
  }

  onDaySelection(date: any, key: string) {

    this.dialog.row[key] = JSON.parse(JSON.stringify(moment(date.value)));

  }

  checkShowCondition(info, row) {

    if (info?.showCondition == null) return true;

    let condition = info.showCondition;

    let boolCond = true;

    try {

      if (condition?.every(cond => Array.isArray(cond) && cond?.length == 3)) boolCond = condition.some(c => this.filterService.parseDisabilityCondition(row, c));
      else if (condition?.length == 3) boolCond = this.filterService.parseDisabilityCondition(row, condition);

    } catch (error) { console.log(error) }

    // If the condition(s) is(are) not satisfied, delete the value associated with the variable
    if (!boolCond) row[info.variable] = null;

    return boolCond;

  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.dialogRef.updateSize('auto');
    }
  }

  async updateComponent() {
    console.log(this.dialog.row);

    this.state = 0;

    let resp = null;
    let responseCode = 200;

    if (this.fileInfos?.length > 0) {

      let payload = new FormData();

      let url = this.dialog.buttonInfos?.uploadAttachmentsEndpointUrl ?? '/apif/cmms/update-attachments/';

      if (this.dialog.rowType == 'activity' && this.dialog?.buttonInfos?.attachmentsConfig?.multiple) {
        for (let file of this.fileInfos) {
          if (file.arrayBuffer != null) payload.append(file.name, new Blob([file.arrayBuffer]));
        }
      }

      else payload.append((this.dialog.row.imageName ?? this.dialog.row.componentCode ?? this.dialog.row.component), new Blob([this.fileInfos[0].arrayBuffer]));

      try {
        await this.apiService.sendCustomPayloadWithArrayBuffer(url + (this.dialog.rowType ?? 'component') + '/' + this.dialog?.machineId + '/' + this.dialog.row.id, payload);
      } catch (error) {
        console.log(error);
      }
    }

    if (this.additionalAttachmentsInfos?.length > 0) {

      let payload = new FormData();

      let url = this.dialog.buttonInfos?.uploadAdditionalAttachmentsEndpointUrl ?? '/apif/cmms/upload-additional-attachments/';

      for (let file of this.additionalAttachmentsInfos) {
        if (file.arrayBuffer != null) payload.append(file.name, new Blob([file.arrayBuffer]));
      }

      try { await this.apiService.sendCustomPayloadWithArrayBuffer(url + (this.dialog.rowType ?? 'component') + '/' + this.dialog?.machineId + '/' + this.dialog.row.id, payload) }
      catch (error) { console.log(error) }
    }

    let url = this.dialog.buttonInfos?.updateComponentEndpointUrl ?? '/apif/cmms/update-row/';

    try {
      resp = await this.apiService.sendPostRequestAwait(url + (this.dialog.rowType ?? 'component') + '/' + this.dialog?.machineId + '/' + this.dialog.row.id, this.dialog.row);
      responseCode = resp.status;
    } catch (error) {
      console.log(error);
      responseCode = error.status;
    }

    this.internalDataService.openSnackBar(
      this.translate.instant('MAINTENANCE_STATE.UPDATE_COMPONENT_RESPONSE.' + (responseCode == 200 ? 'SUCCESS' : 'FAIL')),
      'right',
      'bottom',
      4000,
      '',
      [responseCode == 200 ? 'success' : 'fail']
    );

    this.dialogRef.close({});
  }

  onFileSelected(htmlId?: any, listType?: any) {

    const inputNode: any = document.querySelector('#' + (htmlId ?? 'file'));

    if (typeof (FileReader) !== 'undefined') {
      let promises = [];
      for (let file of inputNode.files) {
        let filePromise = new Promise(resolve => {
          let reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = () => resolve({ arrayBuffer: reader.result, infos: file });
        });
        promises.push(filePromise);
      }
      Promise.all(promises).then((fileContents: any) => {
        // console.log({ fileContents });

        let fileInfos: any = [];

        for (let file of fileContents) {

          if (fileInfos.findIndex(x => x.name == file.infos.name) == -1) {
            let infos = {
              name: file.infos.name,
              type: file.infos.type,
              size: file.infos.size,
              arrayBuffer: file.arrayBuffer,
              sizeP: this.parseFileSize(file.infos.size),
            }
            fileInfos.push(infos);
          }
        }

        if (listType == 'attachments') this.additionalAttachmentsInfos = fileInfos;
        else this.fileInfos = fileInfos;

      });

    }

  }

  parseFileSize(size: any) {
    if (size > 0) {

      if (size > 1000000)
        return Math.round(size / 1000000) + ' MB';
      else if (size > 1000)
        return Math.round(size / 1000) + ' kB';
      else
        return size + ' B';
    }
    return 'File is empty';
  }

  confirmDeleteComponent() {

    const confirmationDialog = this.additionalDialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        cancelClass: 'md-red-i',
        title: this.translate.instant((this.dialog.buttonInfos?.removeLabelConfirm ?? 'MAINTENANCE_STATE.CONFIRM_REMOVE_COMPONENT'), {
          componentName: this.dialog.row?.[this.dialog.rowType ?? 'component']
        }),
      },
    });
    confirmationDialog.afterClosed().subscribe(async (result: any) => {
      console.log(result);

      if (result != null && result != '') {

        let resp = null;
        let responseCode = 200;

        this.state = 0;

        let url = this.dialog.buttonInfos?.deleteComponentEndpointUrl ?? '/apif/cmms/delete-row/';

        try {
          resp = await this.apiService.sendPostRequestAwait(url + (this.dialog.rowType ?? 'component') + '/' + this.dialog?.machineId + '/' + this.dialog.row.id, this.dialog.row);
          responseCode = resp.status;
        } catch (error) {
          console.log(error);
          responseCode = error.status;
        }

        this.internalDataService.openSnackBar(
          this.translate.instant('MAINTENANCE_STATE.REMOVE_COMPONENT_RESPONSE.' + (responseCode == 200 ? 'SUCCESS' : 'FAIL')),
          'right',
          'bottom',
          4000,
          '',
          [responseCode == 200 ? 'success' : 'fail']
        );

        this.dialogRef.close({});
      }
    });
  }

  buildConfigPayload(files) {

    let payload = new FormData();

    for (let file of files) {
      if (file.file != null) payload.append(file.fileName, new Blob([file.file]));
    }

    return payload
  }

  async closeDialog() {
    this.dialogRef.close(this.dialog?.row);
  }

}