import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-assign-shift-template-dialog',
  templateUrl: './assign-shift-template-dialog.component.html',
  styleUrls: ['./assign-shift-template-dialog.component.scss']
})
export class AssignShiftTemplateDialogComponent implements OnInit, OnDestroy {

  public currentTemplate: any = null;
  public shiftTemplateInfos: any = [
    {
      variable: "shiftId",
      label: "CALENDAR.SHIFT"
    },
    {
      variable: "from",
      label: "CALENDAR.START"
    },
    {
      variable: "to",
      label: "CALENDAR.END"
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<AssignShiftTemplateDialogComponent>,
    public apiService: ApiService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public clonerService: ClonerService,
  ) {

  }

  closeDialog() {
    this.dialogRef.close(this.currentTemplate);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

}
