<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
  <!-- utils -->
  <!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->
</div>

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar -->
  <div class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">
    <!-- tabs -->
    <ff-tabs [tabs]="tabs"></ff-tabs>
  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content">

    <!-- toolbar -->
    <div class="ff-toolbar py-3" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">

      <button mat-button class="filter-button" *ngFor="let item of filterButtons" (click)="openFilterDialog(item)"
        [disabled]="item.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
        <mat-icon class="icon">filter_alt</mat-icon>
        <span>{{ item.label | translate }}</span>
      </button>

      <div fxLayout="row" fxLayoutAlign="start center" class="ngx-slider-container ms-3">
        <span class="me-3">{{'CLOTH_MONITORING.CYCLES'|translate}}</span>
        <ngx-slider [(value)]="sliderConf.value" [(highValue)]="sliderConf.highValue" [options]="sliderConf.options"
          (userChangeEnd)="sliderConf.userChangeEnd()"></ngx-slider>
      </div>

      <button mat-button class="filter-button" (click)="navigateToMachineSettings()" fxLayout="row"
        fxLayoutAlign="space-evenly center">
        <mat-icon class="icon">open_in_new</mat-icon>
        <span>{{ 'MACHINE_SETTINGS.TITLE' | translate }}</span>
      </button>

      <span fxFlex></span>

      <!-- search -->
      <div class="search-input" fxLayout="row" fxLayoutAlign="center center" style="margin-right: 8px;">
        <mat-icon class="icon">search</mat-icon>
        <input type="text" placeholder="{{'GLOBAL.SEARCH'|translate}}" (keyup)="searchCloths($event)">
      </div>

    </div>

    <!-- loading -->
    <!-- <ff-loading-card *ngIf="pageState.value <= pageStateReady" [loadingData]="loadingData" style="margin: auto;"></ff-loading-card> -->

    <!-- cloth svg -->
    <div class="w100">
      <mat-card class="cloth-monitoring" style="height: 400px;">
        <div #clothSvg id="clothSvg" class="w100 h100"></div>
      </mat-card>
    </div>

    <!-- cloth card list -->
    <div #clothList *ngIf="cloths.filtered.length > 0" fxLayout="row wrap" class="w100" style="margin-top: 0.5rem;">

      <div *ngFor="let cloth of cloths.filtered" id="{{cloth.id}}" fxFlex.xs="50" fxFlex.sm="33" fxFlex.md="20"
        fxFlex.lg="14" fxFlex.gt-lg="10" style="padding: 4px;">
        <mat-card class="cloth-monitoring cloth-card clickable" fxLayout="column"
          [ngClass]="{'selected': cloth.selected }" (click)="selectCloth(cloth)">
          <div class="id" fxLayout="row" fxLayoutAlign="space-between">
            <span>{{ 'CLOTH_MONITORING.CLOTH' | translate }} {{ cloth.idP }}</span>
            <mat-icon class="icon" [ngStyle]="{ 'color': cloth.color }">circle</mat-icon>
          </div>
          <div class="labels" fxLayout="row">
            <span fxFlex="50">{{'CLOTH_MONITORING.SAP_CODE'|translate}}</span>
            <span fxFlex="50">{{'CLOTH_MONITORING.CYCLES'|translate}}</span>
          </div>
          <div class="values" fxLayout="row" fxLayoutGap="8px">
            <span fxFlex="50" class="truncate" [matTooltip]="cloth.sapCode">{{cloth.sapCode}}</span>
            <span fxFlex="50" class="truncate">{{cloth.nCycles}}</span>
          </div>
        </mat-card>
      </div>

    </div>

    <!-- no results -->
    <mat-card class="not-found" *ngIf="cloths.filtered.length == 0">
      <span>{{ 'CLOTH_MONITORING.NOT_FOUND' | translate }}</span>
    </mat-card>

  </div>

</div>