import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClonerService } from 'src/app/services/clone.service';
import { MobileService } from 'src/app/services/mobile.service';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'ff-dashboard',
  templateUrl: './ff-dashboard.component.html',
  styleUrls: ['./ff-dashboard.component.scss']
})
export class FfDashboardComponent implements OnInit, OnChanges {

  @Input() completeDashboardConfig: any;
  @Output() clicked = new EventEmitter<any>();
  @Output() inputTag = new EventEmitter<any>();

  public dashboardData: any;
  public dashboardConfig: any;
  public dashboardConfigUnparsed: any;
  public machineProfile: any;
  public aggregations: any;
  public intervalAggregations: any;
  public referenceComponent: any;
  public aggregatedTable: any;
  public dashboardType: any;
  public additionalParams: any;
  public asColumn: boolean = false;

  public mobileListener: Subscription;
  public isSmThanTablet: boolean;
  public isMobile: boolean;

  constructor(
    public clonerService: ClonerService,
    public filterService: FiltersService,
    public mobile: MobileService,
  ) {

    this.mobileListener = this.mobile.mobileListener.subscribe((value) => {
      this.isMobile = value.isMobile;
      this.isSmThanTablet = value.isSmThanTablet
    })

  }

  buildCompleteWidgetConfig() {
    this.dashboardType = this.completeDashboardConfig?.dashboardType;
    this.asColumn = this.completeDashboardConfig?.asColumn;
    this.dashboardData = this.completeDashboardConfig?.dashboardData;
    this.dashboardConfig = this.completeDashboardConfig?.dashboardConfig;
    this.machineProfile = this.completeDashboardConfig?.machineProfile;
    this.aggregations = this.completeDashboardConfig?.aggregations;
    this.intervalAggregations = this.completeDashboardConfig?.intervalAggregations;
    this.referenceComponent = this.completeDashboardConfig?.referenceComponent;
    this.aggregatedTable = this.completeDashboardConfig?.aggregatedTable;
    this.additionalParams = this.completeDashboardConfig?.additionalParams;

    this.dashboardConfigUnparsed = (this.referenceComponent?.useUnparsedDashboard ? this.referenceComponent?.dashboardConfigUnparsed : this.dashboardConfig) ?? this.dashboardConfig;

    try {
      let widgets: any = this.clonerService.deepClone(this.dashboardConfigUnparsed?.widgets);
      this.dashboardConfig.widgets = widgets?.reduce((acc, widget) => {

        if (widget?.showCondition != null) {

          widget.conditionOperatorCheck = 'every';
          let boolCond = this.filterService.checkShowCondition(widget, this.referenceComponent);

          // If the condition(s) is(are) not satisfied, delete the value associated with the variable
          if (!boolCond) return acc;
        }

        let parsedWidget: any = Object.assign(widget, {
          data: this.dashboardData,
          machineProfile: this.machineProfile,
          aggregations: this.aggregations,
          intervalAggregations: this.intervalAggregations,
          dashboardType: this.dashboardType,
          referenceComponent: this.referenceComponent,
        });

        if (this.aggregatedTable != null) parsedWidget.aggregatedTable = this.aggregatedTable;
        if (this.additionalParams != null) parsedWidget.additionalParams = this.additionalParams;

        acc.push(parsedWidget);
        return acc;
      }, []);
    } catch (error) { console.log(error) }

    try {
      if (this.dashboardConfig?.mainWidget != null) {
        let mainWidget: any = this.clonerService.deepClone(this.dashboardConfig?.mainWidget);
        this.dashboardConfig.mainWidget = Object.assign(mainWidget, {
          data: this.dashboardData,
          machineProfile: this.machineProfile,
          aggregations: this.aggregations,
          intervalAggregations: this.intervalAggregations,
          dashboardType: this.dashboardType,
          referenceComponent: this.referenceComponent,
        });
      }
    } catch (error) { console.log(error) }

    try {
      if (this.dashboardConfig?.aggrWidget != null) {
        let aggrWidget: any = this.clonerService.deepClone(this.dashboardConfig?.aggrWidget);
        this.dashboardConfig.aggrWidget = Object.assign(aggrWidget, {
          data: this.dashboardData,
          machineProfile: this.machineProfile,
          aggregations: this.aggregations,
          intervalAggregations: this.intervalAggregations,
          dashboardType: this.dashboardType,
          referenceComponent: this.referenceComponent,
        });
      }
    } catch (error) { console.log(error) }
  }

  ngOnInit(): void {
    // console.log('init', this.dashboardConfig?.widgets);
    this.buildCompleteWidgetConfig();
  }

  ngOnChanges() {
    // console.log('build', this.dashboardConfig?.widgets);
    this.buildCompleteWidgetConfig();
  }

  // EVENT EMITTERS
  tableAction(item) { this.clicked.emit(item) }

  // EVENT EMITTERS
  tableInputTagChange(item) { this.inputTag.emit(item) }
}
