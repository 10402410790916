<div class="interval-selector" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
  [class.alternative-color]="config?.alternativeColor" fxLayoutAlign.gt-sm="start center">

  <!-- start -->
  <div class="box" fxLayout="row" fxLayoutAlign="start center"
    *ngIf="!hideStart && config != null && config.selected != null && config.selected.startF != null"
    (click)="selectCustomInterval()" [ngClass]="{'clickable': clickableDate}">
    <mat-icon class="icon">event_available</mat-icon>
    <mat-form-field [style.min-width.px]="fullWidthStart ? 340 : 200">
      <mat-label>{{ intervalLabel | translate }}</mat-label>
      <input matInput disabled value="{{config.selected.startF}}">
    </mat-form-field>
  </div>

  <!-- <ff-selector [element]="{'dropdown': config, 'label': intervalLabel }" (clicked)="selectionChange($event)"></ff-selector> -->

  <!-- select -->
  <div class="box" fxLayout="row" fxLayoutAlign="start center"
    *ngIf="!hideIntervalSelector && config != null && config.list != null && config.list.length > 0">
    <mat-icon class="icon">date_range</mat-icon>
    <mat-form-field appearance="fill" class="w140">
      <mat-label>{{'INTERVAL.OFFSET'|translate}}</mat-label>
      <mat-select [(value)]="selectedId">
        <mat-option *ngFor="let int of config.list" [value]="int.id" [disabled]="int.hide"
          (click)="selectionChange(selectedId)">
          {{ int.label | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- aggregation -->
  <div *ngIf="intAggrs != null" class="box" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="icon">assignment</mat-icon>
    <mat-form-field appearance="fill" class="w140">
      <mat-label>{{'INTERVAL.AGGREGATION_TYPE'|translate}}</mat-label>
      <mat-select [(value)]="selectedAggrId">
        <mat-option *ngFor="let aggr of intAggrs.list" [disabled]="aggr.hide" [value]="aggr.id"
          (click)="aggrSelectionChange(aggr)">{{ aggr.label | translate }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

</div>