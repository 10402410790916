<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

  </div>

  <!-- tab content -->
  <div class="tab-content pad-x-2em pad-y-1em widpad-x-2em h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1}">

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="!isMobile && !repeatedCards && !noSynoptic && pageState.value >= pageStateReady" fxLayout="row"
      fxLayoutAlign="start start" class="h100 w100" fxLayoutGap="8px">
      <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="h100" fxLayoutGap="8px">
        <div fxFlex="5" fxLayout="row" fxLayoutAlign="start center">
          <button *ngIf="!hideSynopticSelectionButton" mat-button (click)="openMatDialog()" class="filter-button"
            fxLayout="row" fxLayoutAlign="center center">
            {{ internalDataService.parseLabelFromCustomTranslations("SYNOPTICS." + currentSynopticId) }}
            <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
              style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
            </mat-icon>
          </button>
          <span fxFlex></span>
          <div fxLayout="row" fxLayoutAlign="start center"
            class="last-update {{monitoringData?.notConnected ? 'md-orange' : (monitoringData?.neverConnected ? 'md-gray' :'md-green') }}">

            <mat-icon class="icon left">
              {{monitoringData?.notConnected ? 'warning' : (monitoringData?.neverConnected ? 'remove' : 'check')}}
            </mat-icon>
            <span>{{ 'REMOTE_MONITORING.LAST_UPDATE' | translate }}: {{ monitoringData?.lastUpdateP }}</span>
          </div>
        </div>
        <ff-widget fxFlex="95" [widget]="{type: 'ff-synoptic', data: monitoringData, synopticConfig: synopticConfig}">
        </ff-widget>
      </div>
      <div fxFlex="{{ !isSmThanTablet ? dashboardConfig.secondColumnFlex : dashboardConfig.secondColumnFlex + 10 }}"
        fxLayout="column" fxLayoutAlign="start stretch" class="h100" fxLayoutGap="8px">
        <ff-widget *ngFor="let w of dashboardConfig.widgets" fxFlex="{{w.flex}}"
          [widget]="{ type: w.type, data: monitoringData, machineProfile: machine.profile, config: w.config, title: w.title, units: w.units,phaseIdVariable: w.phaseIdVariable }">
        </ff-widget>
      </div>

    </div>

    <div *ngIf="repeatedCards && !noSynoptic && pageState.value >= pageStateReady" fxLayout="column"
      fxLayoutAlign="start stretch" fxLayout.gt-sm="row wrap" fxLayoutAlign.gt-sm="start start" class="h100 w100"
      [fxLayoutGap]="dashboardConfig?.cardConfig?.gap != null ? dashboardConfig?.cardConfig?.gap : '8px'">

      <div *ngFor="let card of monitoringData[dashboardConfig?.cardConfig?.key]" class="repeated-card"
        [fxFlex.gt-sm]="'calc(' + dashboardConfig?.cardConfig?.innerFlex + '% - ' + (dashboardConfig?.cardConfig?.gap != null ? dashboardConfig?.cardConfig?.gap : '8px') + ')'"
        [ngStyle.gt-sm]="{'max-width': 'calc(' + dashboardConfig?.cardConfig?.innerFlex + '% - ' + (dashboardConfig?.cardConfig?.gap != null ? dashboardConfig?.cardConfig?.gap : '8px') + ')'}">

        <div class="header" fxLayout="row">

          <div class="title">
            {{ card[dashboardConfig?.cardConfig?.labelVariable != null ? dashboardConfig?.cardConfig?.labelVariable :
            'id'] }}
          </div>

          <span fxFlex></span>

          <ff-single-cell *ngIf="dashboardConfig?.cardConfig?.stateIconIndicator" [item]="card"
            [info]="dashboardConfig?.cardConfig?.stateIconIndicator" [profile]="machine.profile">
          </ff-single-cell>

        </div>
        <table>
          <tbody>
            <tr *ngFor="let info of dashboardConfig?.cardConfig?.tableInfos">


              <td md-cell [ngClass]="{'primary' : info.primary}">

                <span>{{ info.label | translate }}</span>
                <span *ngIf="info.unit != null && info.unitInTitle" style="text-transform: none;">
                  [{{ info.unit }}]
                </span>

              </td>

              <td md-cell [ngClass]="{'primary' : info.primary}">
                <ff-single-cell [item]="card" [info]="info" [profile]="machine.profile"></ff-single-cell>
              </td>

            </tr>
          </tbody>
        </table>

      </div>

    </div>

    <div *ngIf="noSynoptic && pageState.value >= pageStateReady" fxLayout="row wrap" fxLayout.gt-md="row"
      fxLayoutAlign="start stretch" fxLayoutAlign.gt-sm="start start" class="h100 w100" fxLayoutGap.gt-md="8px">
      <div [class.mobile-card]="isMobile" *ngFor="let col of dashboardConfig" [fxFlex]="'calc(100% - 8px)'"
        [fxFlex.sm]="'calc(50% - 8px)'" fxFlex.gt-md="{{col.flex}}" fxLayout="column" fxLayoutAlign="start stretch"
        [class.h100]="!isMobile" [ngStyle]="{'margin-bottom': isMobile ? '8px' : 0}" fxLayoutGap.gt-md="8px">
        <ff-widget *ngFor="let w of col.widgets" fxFlex="{{w.flex}}"
          [widget]="{ type: w.type, data: monitoringData, machineProfile: machine.profile, config: w.config, title: w.title }">
        </ff-widget>
      </div>

    </div>

    <div *ngIf="isMobile && !noSynoptic && !repeatedCards && pageState.value >= pageStateReady" fxLayout="row wrap"
      fxLayoutAlign="start stretch" class="h100 w100" fxLayoutGap="8px grid">
      <span fxFlex="100">
        <button *ngIf="!hideSynopticSelectionButton" mat-button (click)="openMatDialog()" class="filter-button"
          fxLayout="row" fxLayoutAlign="center center">
          {{ 'SYNOPTICS.' + currentSynopticId | translate }}
          <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
            style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
          </mat-icon>
        </button>
      </span>

      <span fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="start center"
          class="last-update {{monitoringData?.notConnected ? 'md-orange' : (monitoringData?.neverConnected ? 'md-gray' :'md-green') }}">

          <mat-icon class="icon left">
            {{monitoringData?.notConnected ? 'warning' : (monitoringData?.neverConnected ? 'remove' : 'check')}}
          </mat-icon>
          <span>{{ 'REMOTE_MONITORING.LAST_UPDATE' | translate }}: {{ monitoringData?.lastUpdateP }}</span>
        </div>
      </span>

      <ff-widget fxFlex="100"
        [widget]="{type: 'ff-synoptic', data: monitoringData, synopticConfig: synopticConfig, mobile: true }">
      </ff-widget>
      <ff-widget *ngFor="let w of dashboardConfig?.widgets" fxFlex="100" [widget]="{ 
          type: w.type,
          data: monitoringData,
          machineProfile: machine?.profile,
          config: w.config,
          title: w.title,
          unit: w.unit,
          phaseIdVariable: w.phaseIdVariable,
          productionUnit: w.productionUnit  }">
      </ff-widget>

    </div>
  </div>

</div>