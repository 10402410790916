import { Component, HostListener, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { StopService } from './stop.service';
import { Stop } from './stops-story/stop.module';

import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';


@Component({
  selector: 'app-stops',
  templateUrl: './stops.component.html',
  styleUrls: ['./stops.component.scss'],
  providers: [StopService]
})

export class StopsComponent implements OnInit, OnChanges, OnDestroy {

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // VARIABLES

  @ViewChild('f') prodForm!: NgForm;
  intervalId: any;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;
  public breadcrumb: any;

  public tabs: any;

  public homepageData: any;
  public interval: any;

  public pollingTime: number = 0;
  public pollingMachines: any;

  public pollingTime2: number = 0;
  public pollingMachinesData: any;

  public machineSelected: any;

  public machines: any;

  public productionInfo: any;
  @ViewChild('f2') cardForm!: NgForm;
  causes = [
    'Causa 1',
    'Causa 2',
    'Causa 3',
    'Causa 4',
    'Causa 5',
    'Causa 6',
    'Causa 7',
    'Causa 8',
    'Causa 9',
    'Causa 10',
    'Causa 11',
    'Causa 12'
  ];
  defaultCause = this.causes[0];

  trafile = ['1', '2', '3'];
  defaultTrafila = this.trafile[0];
  trafilaSelected = 0;
  onSelectTrafila(num: number) {
    this.trafilaSelected = num;
  }

  // STATIC DATETIME
  now: Date = new Date();
  defaultDate = (this.now.toISOString().substring(0, 10));
  hours = ("0" + this.now.getHours()).slice(-2);
  minutes = ("0" + this.now.getMinutes()).slice(-2);
  endTime = this.hours + ':' + this.minutes;

  // UP TO DATE DATETIME
  // now = new Date();
  // rxNow = new Date();
  // timeSubscription!: Subscription;

  // defaultDate!: string;
  // hours!: string;
  // minutes!: string;
  // endTime!: string;

  stops: any[];
  subscription: Subscription;

  onCreateCard() {
    this.stopService.addStop(this.cardForm.value);

    this.cardForm.reset({ 
      cause: this.defaultCause,
      trafila: this.defaultTrafila,
      startDate: this.defaultDate,
      endDate: this.defaultDate,
      startTime: '',
      endTime: this.endTime,
    });
    this.trafilaSelected = 0;
  }
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getMachinesPolling, nextState: 3, loadingMsg: 'LOADING.MACHINES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getProductionInfo, nextState: 4 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public dialog: MatDialog,
    private stopService: StopService
     
  ) {

    // this.pageState.subscribe((value) => { console.log('pageState.subscribe', value); });

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.pollingTime = 0;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['MANUAL_INPUT.TITLE','STOPS_SHEET.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    try {
      this.tabs = this.internalDataService.getPageTabs('manualInput');
    } catch (error) {
      console.error(error);
    }
    this.machines = {
      list: []
    };

    this.stops = [];
    this.subscription = this.stopService.stopsChanged.subscribe(
      (stops: Stop[]) => {
        this.stops = stops;
      }
    );

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // machines

  // polling
  getMachinesPolling(_this: any) {
    try {

      if (_this.pollingTime > 0) {
        _this.pollingMachines = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getMachines(_this, count);
        });
      } else {
        _this.getMachines(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get machines
  getMachines(_this: any, count: any) {
    try {

      _this.apiService.sendGetRequest('/apif/machines', {})
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingMachines))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            // TODO
            // if not first request (polling), merge machine data with actuals, to avoid to reset them

            // set default image
            if (data.body != null && data.body.length > 0) {
              data.body.forEach((machine: any, idx: number) => {
                machine.profile = _this.internalDataService.getMachineProfile(_this, machine.machineId);
                // TODO
                // if (sources40f) { }
                // else {

                let sources40F = _this.appInfo.sources40F != null ? _this.appInfo.sources40F : 'assets/images/';
                machine.machineName = machine.machineName != null ? machine.machineName : machine.name;
                machine.image = sources40F + 'machine-default.png';
                // machine.profile = {
                //   'settings': {},
                //   ''
                // }
                // }
              });
            }

            // _this.machines.list = _this.parseMachines(_this, data.body);
            _this.machines.list = data.body;
            _this.machines.filtered = _this.machines.list;

            _this.internalDataService.setMachinesList(_this.machines.filtered);

            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // Production Info

  // get production info
  getProductionInfo(_this: any) {

    try {

      const sources40F = _this.appInfo.sources40F != null ? _this.appInfo.sources40F : 'assets/config/';

      _this.apiService.sendGetRequest(sources40F + 'productionInfo.json').pipe(
        retryWhen(_this.apiService.genericRetryStrategy()),
        catchError(error => {

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError(
            'Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.productionInfo = data.body;

            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.stops = this.stopService.getStops();
    this.dispatcherService.getDispatch(this, 300);

    // UP TO DATE DATETIME INIT
    // this.timeSubscription = timer(0, 30000)
    // .pipe(
    //   map(() => new Date()),
    //   share()
    // ).subscribe(now => {
    //   this.rxNow = now;
    //   this.defaultDate = (this.rxNow.toISOString().substring(0, 10));
    //   this.hours = ("0" + this.rxNow.getHours()).slice(-2);
    //   this.minutes = ("0" + this.rxNow.getMinutes()).slice(-2);
    //   this.endTime = this.hours + ':' + this.minutes;
    // });

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pageState.unsubscribe();
    } catch (error) { }
    try {
      this.pollingMachines.unsubscribe();
    } catch (error) { }
  

    // UP TO DATE DATETIME DESTROY
    
    // if (this.timeSubscription) {
    //   this.timeSubscription.unsubscribe();
    // }
  }

  @HostListener('window:resize', ['$event'])
  onResize() { }

}