import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'ff-led',
  templateUrl: './led.component.html',
  styleUrls: ['./led.component.scss']
})
export class LedComponent implements OnInit, OnChanges {

  @Input() widget: any;
  public led: any = {};
  @HostBinding('style.background-color') led_bg_color: any = 'red';

  constructor(
    public filterService: FiltersService,
    public internalDataService: InternalDataService,
  ) { }

  parseWidgetData() {


    let conf = this.widget.config != null ? this.widget.config : {};

    let v = this.widget.data?.[conf.variable];

    if (v != null) {
      if (typeof v == 'string') {
        conf.value = v;
      }
      else if (typeof v == 'boolean') {
        conf.value = v;
      } else {
        if (conf.format == 'time') {
          conf.value = this.filterService.parseTime(v, 's', 'hh:mm:ss');
        } else {
          conf.value = this.filterService.parseGaugeValue(v, conf.decimals, conf.multiplier);
        }
      }
    } else {
      conf.value = null;
    }

    this.led = conf;
    this.led_bg_color = conf.value ? (conf.trueColor ? conf.trueColor : 'green') : (conf.falseColor ? conf.falseColor : 'red');

  }

  ngOnInit(): void {
    this.parseWidgetData();
  }

  ngOnChanges(): void {
    this.parseWidgetData();
  }

}
