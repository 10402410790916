<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= 6" fxLayout="column" fxLayoutAlign="start start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <button mat-button class="md-green" (click)="updateSettings()" [disabled]="!isAllowedUserWrite || checkUpdates()">
      <mat-icon class="icon" aria-label="save">save</mat-icon>
      <span>{{'GLOBAL.SAVE'|translate}}</span>
    </button>

  </div>

  <!-- content -->
  <div class="tab-content" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-md="row wrap"
    fxLayoutAlign.gt-md="start stretch">

    <div *ngIf="tabs.length == 1" fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 12px" class="w100">

      <span class="suggestion">{{ 'CONSUMABLES_SETTINGS.SUGGESTION' | translate }}</span>

      <span fxFlex></span>

      <button mat-button class="filter-button md-primary" (click)="updateSettings()"
        [disabled]="!isAllowedUserWrite || checkUpdates()">
        <mat-icon class="icon" aria-label="save">save</mat-icon>
        <span>{{'GLOBAL.SAVE'|translate}}</span>
      </button>

    </div>

    <div *ngFor="let consumable of machine?.profile?.consumables" fxFlex="100" fxFlex.gt-md="33" class="w100"
      style="padding: 0.5rem;">
      <mat-card class="card impact-consumables-settings">
        <div class="title" fxLayout="row">
          <ff-icon *ngIf="consumable.icon" [icon]="consumable.icon" style="margin-right: 12px;"></ff-icon>
          <span>{{consumable.label | translate}}</span>
        </div>
        <div class="w100 content" fxLayout="row" fxLayoutAlign="space-evenly center">
          <input fxFlex="80" type="number" id="test" name="test" placeholder="" class="ff-input"
            [(ngModel)]="settingsVariables[consumable.id + 'Cost']" [disabled]="!isAllowedUserWrite">
          <span fxFlex="20" *ngIf="consumable.unit != null || consumable.settingsUnit != null" style="margin-left: 8px;"
            class="title">
            $/{{ (consumable.settingsUnit != null ? consumable.settingsUnit : (consumable.unit != null ? consumable.unit : '-')) | translate }}
          </span>
        </div>
      </mat-card>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center" style="margin: 12px 0;" class="w100">

      <span class="suggestion impact-consumables-settings">{{ 'CONSUMABLES_SETTINGS.SPECIFY_TRAINING_PERIOD' | translate }}</span>

    </div>

    <div fxFlex="100" class="w100" style="padding: 0.5rem;" fxLayout="column" fxLayout.gt-sm="row wrap"
      fxLayoutGap="8px" fxLayoutAlign.gt-sm="center stretch">
      <mat-card fxFlex.gt-sm="33" fxFlex="100" class="card">
        <div class="title" fxLayout="column" fxLayout.gt-sm="row">
          <span>{{ 'CONSUMABLES_SETTINGS.TRAINING_START' | translate }}</span>
        </div>

        <div class="interval-selector" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
          fxLayoutAlign.gt-sm="start center" style="margin-right: 8px;">

          <div class="box" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="icon">event_available</mat-icon>

            <mat-form-field appearance="fill" class="w100">
              <mat-label>{{ 'CONSUMABLES_SETTINGS.TRAINING_START' | translate }}</mat-label>
              <input matInput [matDatepicker]="pickerStart" [value]="settingsVariables.trainingStart" disabled
                (dateChange)="onDaySelection($event,'trainingStart')">
              <mat-datepicker-toggle matSuffix [for]="pickerStart">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #pickerStart disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

      </mat-card>

      <mat-card class="card" fxFlex.gt-sm="33" fxFlex="100">
        <div class="title" fxLayout="column" fxLayout.gt-sm="row">
          <span>{{ 'CONSUMABLES_SETTINGS.TRAINING_END' | translate }}</span>
        </div>

        <div class="interval-selector" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
          fxLayoutAlign.gt-sm="start center" style="margin-right: 8px;">

          <div class="box" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon class="icon">event_available</mat-icon>

            <mat-form-field appearance="fill" class="w100">
              <mat-label>{{ 'CONSUMABLES_SETTINGS.TRAINING_END' | translate }}</mat-label>
              <input matInput [matDatepicker]="pickerEnd" [value]="settingsVariables.trainingEnd" disabled
                (dateChange)="onDaySelection($event,'trainingEnd')">
              <mat-datepicker-toggle matSuffix [for]="pickerEnd">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #pickerEnd disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>
        </div>

      </mat-card>

      <mat-card class="card" fxFlex.gt-sm="33" fxFlex="100">
        <div class="title" fxLayout="column" fxLayout.gt-sm="row">
          <span>{{ 'CONSUMABLES_SETTINGS.N_SIGMA' | translate }}</span>
        </div>
        <div class="w100 content" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start center">
          <input fxFlex.gt-sm="80" type="number" id="test" name="test" placeholder="" class="ff-input" min="1" max="6"
            [(ngModel)]="settingsVariables['nSigma']" [disabled]="!isAllowedUserWrite">
        </div>
      </mat-card>
    </div>


  </div>

</div>
