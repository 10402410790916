<div mat-dialog-title fxLayout="row" fxLayoutAlign="center center" class="w100">
  <mat-icon class="icon left">dehaze</mat-icon>
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" aria-label="close">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <div class="ff-input-select w100" style="margin-bottom: 16px;">
      <mat-select [(value)]="currentTemplate" placeholder="{{ 'CALENDAR.SHIFT_TEMPLATE_SELECTION' | translate }}">
        <mat-option *ngFor="let template of dialog.shiftTemplates" [value]="template">
          {{ template.name }}
        </mat-option>
      </mat-select>
    </div>

  </div>

  <div *ngIf="currentTemplate != null">

    <div class="width100" fxLayout="row">
      <span class="md-headline-invert">{{ 'CALENDAR.BACKGROUND_COLOR' | translate }}</span>
      <span flex></span>
      <span class="color-square" [ngStyle]="{'background-color': currentTemplate.color}">
      </span>
    </div>

    <table class="standard-table">
      <thead>
        <tr>
          <th *ngFor="let info of shiftTemplateInfos">
            <span>{{ info.label | translate }}</span>
          </th>
      </thead>
      <tbody>
        <tr *ngFor="let shift of currentTemplate.shifts">
          <td *ngFor="let info of shiftTemplateInfos">

            <span>{{ shift[info.variable] }}</span>
            <!-- <span ng-if="info.type == null" class="{{info.classes}}"
              ng-class="{'minWidthListLarge': mobile()}">{{ shift[info.variable] | placeholder:'-'}}
              <span ng-if="info.suffix != null">{{info.suffix}}</span>
            </span> -->

          </td>
        </tr>
      </tbody>
    </table>

  </div>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button class="filter-button md-primary" (click)="closeDialog()">{{ 'GLOBAL.SELECT' | translate }}</button>
</mat-dialog-actions>