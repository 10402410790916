<div mat-dialog-title fxLayout="row" class="w100">
    <span>{{ dialog.title }}</span>
    <span fxFlex></span>
    <button mat-dialog-close class="close">
        <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography bigger">

    <div style="height: calc(90vh - 60px);" class="w100">
        <iframe [src]="url" width="100%" height="100%"></iframe>
    </div>
    <!-- <form (submit)="submitForm($event)">
        <input type="text" name="username" [(ngModel)]="username">
        <input type="password" name="password" [(ngModel)]="password">
        <button type="submit">Submit</button>
    </form>
    <iframe [src]="iframeSrc"></iframe> -->

    <!-- <pre>{{ dialog.widget?.link | json }}</pre> -->
    <!-- <iframe [src]="url" width="100%" height="500"></iframe> -->
    <!-- <iframe src="https://omet.desys.it/servlet/ValidateLogin" width="100%" height="500"></iframe> -->
    <!-- <form action="https://omet.desys.it/servlet/ValidateLogin" method="POST" target="myiframe">
        <input type="hidden" name="login" value="interno">
        <input type="hidden" name="password" value="interno402023!">
        <input type="hidden" name="submit" value="Login">
        <input type="submit" value="Submit">
    </form>
    <iframe name="myiframe" width="100%" height="500"></iframe> -->

</mat-dialog-content>