import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'ff-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, OnChanges {

  public image: string;
  @Input() widget: any;
  constructor() { }

  parseWidgetData() {

    let conf = this.widget.config ?? {};

    try {

      // Image name directly in config
      if (conf?.imageRegex == null) this.image = this.widget.data[conf.variable];
      // Image with additional regexes
      else this.image = conf.imageRegex?.replace("{image}", this.widget.data[conf.variable]);

    } catch (error) { console.log(error) }

  }

  ngOnInit(): void {
    this.parseWidgetData();
  }

  ngOnChanges() {
    this.parseWidgetData();
  }

}
