import { CommonModule, LOCATION_INITIALIZED } from '@angular/common';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

// HTTP
import { HttpClient, HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';

// Angular material components
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

// Flex layout
import { FlexLayoutModule } from '@angular/flex-layout';

// Translate
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

// Custom services
import { AppConfigService } from './services/app-config.service';

// Custom components
import { AggregationsDialogComponent } from './components/aggregations-dialog/aggregations-dialog.component';
import { ErrorCardComponent } from './components/error-card/error-card.component';
import { ExportTableComponent } from './components/export-table/export-table.component';
import { HelpDialogComponent } from './components/help-dialog/help-dialog.component';
import { CustomIntervalDialogComponent } from './components/interval-selector/custom-interval-dialog/custom-interval-dialog.component';
import { IntervalSelectorComponent } from './components/interval-selector/interval.component';
import { LoadingCardComponent } from './components/loading-card/loading-card.component';
import { MainToolbarComponent } from './components/main-toolbar/toolbar.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { TabsComponent } from './components/tabs/tabs.component';
import './string.extensions';

// Widgets
import { ApexChartComponent } from './components/widgets/apex-chart/apex-chart.component';
import { BarComponent } from './components/widgets/bar/bar.component';
import { GaugeComponent } from './components/widgets/gauge/gauge.component';
import { HtmlBarComponent } from './components/widgets/html-bar/html-bar.component';
import { PlotlyChartTableComponent } from './components/widgets/plotly-chart-table/plotly-chart-table.component';
import { PlotlyChartComponent } from './components/widgets/plotly-chart/plotly-chart.component';
import { StatusComponent } from './components/widgets/status/status.component';
import { SynopticComponent } from './components/widgets/synoptic/synoptic.component';
import { WidgetCardComponent } from './components/widgets/widget-card/widget-card.component';

// Pages
import { ErrorPageComponent } from './pages/error-page/error-page.component';

// import { TestPageComponent } from './pages/test-page/test-page.component';

import { FiltersDialogComponent } from './components/filters-dialog/filters-dialog.component';
import { HomepageGridCardComponent } from './pages/homepage/grid/card/card.component';
import { HomepageGridComponent } from './pages/homepage/grid/grid.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { HomepageListComponent } from './pages/homepage/list/list.component';
import { MachinesFiltersPipe } from './pages/homepage/machines.filters';
import { HomepageMapComponent } from './pages/homepage/map/map.component';
import { CheckTranslationPipe } from './pipes/checkTranslation.pipe';
import { FilterByPropertyPipe } from './pipes/filterByProperty.pipe';
import { PlaceholderPipe } from './pipes/placeholder.pipe';
import { ReplacePipe } from './pipes/replace.pipe';
import { ShortenPipe } from './pipes/shorten.pipe';

import { AlarmsTableComponent } from './pages/machine-supervision/alarms/alarms.component';
import { ClothMonitoringComponent } from './pages/machine-supervision/cloth-monitoring/cloth-monitoring.component';
import { CycleTimelineComponent } from './pages/machine-supervision/cycle-timeline/cycle-timeline.component';
import { MachineSupervisionComponent } from './pages/machine-supervision/machine-supervision.component';
import { DatapointDialogComponent } from './pages/machine-supervision/remote-monitoring/datapoint-dialog/datapoint-dialog.component';
import { RemoteMonitoringComponent } from './pages/machine-supervision/remote-monitoring/remote-monitoring.component';
import { SynopticSelectionDialogComponent } from './pages/machine-supervision/remote-monitoring/synoptic-selection-dialog/synoptic-selection-dialog.component';
import { SignalationsTableComponent } from './pages/machine-supervision/signalations/signalations.component';
import { StatesTimelineComponent } from './pages/machine-supervision/states/states.component';

import { AlertInvalidComponent } from './pages/manual-input/prod-sheet/alert-invalid/alert-invalid.component';
import { AlertComponent } from './pages/manual-input/prod-sheet/alert/alert.component';
import { ProdSheetComponent } from './pages/manual-input/prod-sheet/prod-sheet.component';
import { StopsStoryComponent } from './pages/manual-input/stops/stops-story/stops-story.component';
import { StopsComponent } from './pages/manual-input/stops/stops.component';

import { CycleDashboardComponent } from './pages/cycle-traceability/cycle-detail/cycle-dashboard/cycle-dashboard.component';
import { CycleDetailTimelineComponent } from './pages/cycle-traceability/cycle-detail/cycle-detail-timeline/cycle-detail-timeline.component';
import { CycleDetailComponent } from './pages/cycle-traceability/cycle-detail/cycle-detail.component';
import { PhaseVariablesComponent } from './pages/cycle-traceability/cycle-detail/phase-variables/phase-variables.component';
import { ProcessLogComponent } from './pages/cycle-traceability/cycle-detail/process-log/process-log.component';
import { VariablesSelectionDialogComponent } from './pages/cycle-traceability/cycle-detail/process-log/variables-selection-dialog/variables-selection-dialog.component';
import { CycleTraceabilityComponent } from './pages/cycle-traceability/cycles/cycles.component';

import { ProductionTraceabilityDetailComponent } from './pages/lean-analytics/production-traceability-detail/production-traceability-detail.component';
import { ProductionTraceabilityComponent } from './pages/lean-analytics/production-traceability/production-traceability.component';

import { BreakdownsAlarmsComponent } from './pages/breakdowns/alarms/alarms.component';
import { BreakdownsSignalationsComponent } from './pages/breakdowns/signalations/signalations.component';

import { LeanBreakdownsSignalationsComponent } from './pages/lean-analytics/breakdowns-signalations/breakdowns-signalations.component';
import { LeanBreakdownsComponent } from './pages/lean-analytics/breakdowns/breakdowns.component';
import { LeanFaultAnalysisComponent } from './pages/lean-analytics/fault-analysis/fault-analysis.component';
import { LeanOeeTrendComponent } from './pages/lean-analytics/oee-trend/oee-trend.component';
import { LeanProductionTrendComponent } from './pages/lean-analytics/production-trend/production-trend.component';
import { LeanScrapAnalyticsComponent } from './pages/lean-analytics/scrap-analytics/scrap-analytics.component';

import { MachineSettingsComponent } from './pages/machine-settings/machine-settings.component';
import { OptionsComponent } from './pages/options/options.component';

import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AssignShiftTemplateDialogComponent } from './pages/planning-calendar/calendar/assign-shift-template-dialog/assign-shift-template-dialog.component';
import { EditShiftTemplateComponent } from './pages/planning-calendar/shift-templates/edit-shift-template/edit-shift-template.component';
import { ShiftTemplatesComponent } from './pages/planning-calendar/shift-templates/shift-templates.component';

import { SctDatapointDialogComponent } from './pages/machine-supervision/sct-heatmap/sct-datapoint-dialog/sct-datapoint-dialog.component';
import { SctSetZoomDialogComponent } from './pages/machine-supervision/sct-heatmap/sct-set-zoom-dialog/sct-set-zoom-dialog.component';
import { SctHeatmapComponent } from './pages/machine-supervision/sct-heatmap/sct-heatmap.component';


import { CustomDateAdapter } from './services/custom-date-adapter';

// Angular Google Maps
import { AgmCoreModule } from '@agm/core';
import { ValueComponent } from './components/widgets/value/value.component';

// Apex charts
import { NgApexchartsModule } from 'ng-apexcharts';

// Angular UI Switch
import { UiSwitchModule } from 'ngx-ui-switch';

// ngx-slider 
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CycleComparisonDialogComponent } from './pages/cycle-traceability/cycle-detail/cycle-comparison-dialog/cycle-comparison-dialog.component';
import { LeanAnalyticsComponent } from './pages/lean-analytics/lean-analytics.component';
import { MachineStatusLogComponent } from './pages/machine-supervision/machine-status-log/machine-status-log.component';
import { CalendarComponent } from './pages/planning-calendar/calendar/calendar.component';
import { PlanningCalendarComponent } from './pages/planning-calendar/planning-calendar.component';

// Calendar
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid'; // a plugin

import { LoginPageComponent } from './pages/login-page/login-page.component';
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin,
  timeGridPlugin
]);

// ngx-color
import * as moment from 'moment';
import { ColorPickerModule } from 'ngx-color-picker';
import { ColorSketchModule } from 'ngx-color/sketch';
import { DoubleKpiAnalyticsComponent } from './pages/kpi-analytics/double-kpi-analytics/double-kpi-analytics.component';
import { KpiAnalyticsComponent } from './pages/kpi-analytics/kpi-analytics.component';
import { SingleKpiAnalyticsComponent } from './pages/kpi-analytics/single-kpi-analytics/single-kpi-analytics.component';
import { AddDowntimeDialogComponent } from './pages/planning-calendar/calendar/add-downtime-dialog/add-downtime-dialog.component';
import { CopyWeekDialogComponent } from './pages/planning-calendar/calendar/copy-week-dialog/copy-week-dialog.component';

// Components
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddMaintenanceComponentDialogComponent } from './components/add-maintenance-component-dialog/add-maintenance-component-dialog.component';
import { AggregationsButtonsComponent } from './components/aggregations-buttons/aggregations-buttons.component';
import { AvailableMachinesSelectionComponent } from './components/available-machines-selection/available-machines-selection.component';
import { BarillaShiftSelectionDialogComponent } from './components/barilla-shift-selection-dialog/barilla-shift-selection-dialog.component';
import { ConnectionTimelineDialogComponent } from './components/connection-timeline-dialog/connection-timeline-dialog.component';
import { CreateFolderDialogComponent } from './components/create-folder-dialog/create-folder-dialog.component';
import { CustomActionButtonsComponent } from './components/custom-action-buttons/custom-action-buttons.component';
import { DashboardDialogComponent } from './components/dashboard-dialog/dashboard-dialog.component';
import { DisplayImageDialogComponent } from './components/display-image-dialog/display-image-dialog.component';
import { EditPdfDialogComponent } from './components/edit-pdf-dialog/edit-pdf-dialog.component';
import { ErrorDialogComponent } from './components/error-card/error-dialog/error-dialog.component';
import { FfBotComponent } from './components/ff-bot/ff-bot.component';
import { FfCheckboxComponent } from './components/ff-checkbox/ff-checkbox.component';
import { FfColumnArrayComponent } from './components/ff-column-array/ff-column-array.component';
import { FfDashboardDialogComponent } from './components/ff-dashboard-dialog/ff-dashboard-dialog.component';
import { FfDashboardComponent } from './components/ff-dashboard/ff-dashboard.component';
import { FfIconComponent } from './components/ff-icon/ff-icon.component';
import { FfSelectorComponent } from './components/ff-selector/ff-selector.component';
import { FfSingleCellComponent } from './components/ff-single-cell/ff-single-cell.component';
import { FfTableSortableComponent } from './components/ff-table-sortable/ff-table-sortable.component';
import { FfTableComponent } from './components/ff-table/ff-table.component';
import { FfWilsonComponent } from './components/ff-wilson/ff-wilson.component';
import { FilterButtonsComponent } from './components/filter-buttons/filter-buttons.component';
import { GcaInputDialogComponent } from './components/gca-input-dialog/gca-input-dialog.component';
import { ImportExcelDialogComponent } from './components/import-excel-dialog/import-excel-dialog.component';
import { ImportFileDialogComponent } from './components/import-file-dialog/import-file-dialog.component';
import { IngenyaImageDialogComponent } from './components/ingenya-image-dialog/ingenya-image-dialog.component';
import { LastUpdatesDetailDialogComponent } from './components/last-updates-detail-dialog/last-updates-detail-dialog.component';
import { MachineComponentSelectionDialogComponent } from './components/machine-component-selection-dialog/machine-component-selection-dialog.component';
import { MaintenanceBomGeneratorDialogComponent } from './components/maintenance-bom-generator-dialog/maintenance-bom-generator-dialog.component';
import { MaintenanceCalendarTemplatesDialogComponent } from './components/maintenance-calendar-templates-dialog/maintenance-calendar-templates-dialog.component';
import { MaintenanceComponentDialogComponent } from './components/maintenance-component-dialog/maintenance-component-dialog.component';
import { MaintenanceInterventionDialogComponent } from './components/maintenance-intervention-dialog/maintenance-intervention-dialog.component';
import { MobileCardComponent } from './components/mobile-card/mobile-card.component';
import { MultipleInputDialogComponent } from './components/multiple-input-dialog/multiple-input-dialog.component';
import { ParameterChangeTableComponent } from './components/parameter-change-table/parameter-change-table.component';
import { RoundedIconButtonComponent } from './components/rounded-icon-button/rounded-icon-button.component';
import { SingleInputDialogComponent } from './components/single-input-dialog/single-input-dialog.component';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { ApexChartWithSwitchComponent } from './components/widgets/apex-chart-with-switch/apex-chart-with-switch.component';
import { AssignTemplateDialogComponent } from './components/widgets/custom-calendar/assign-template-dialog/assign-template-dialog.component';
import { CustomCalendarComponent } from './components/widgets/custom-calendar/custom-calendar.component';
import { MaintenanceEventDialogComponent } from './components/widgets/custom-calendar/maintenance-event-dialog/maintenance-event-dialog.component';
import { DurationInputComponent } from './components/widgets/duration-input/duration-input.component';
import { FfMailingListComponent } from './components/widgets/ff-mailing-list/ff-mailing-list.component';
import { FfSwitchComponent } from './components/widgets/ff-switch/ff-switch.component';
import { FfUrlPathComponent } from './components/widgets/ff-url-path/ff-url-path.component';
import { HealthBarComponent } from './components/widgets/health-bar/health-bar.component';
import { ImageComponent } from './components/widgets/image/image.component';
import { InfoGaugeComponent } from './components/widgets/info-gauge/info-gauge.component';
import { LastUdpateComponent } from './components/widgets/last-udpate/last-udpate.component';
import { LedComponent } from './components/widgets/led/led.component';
import { MonitorComponent } from './components/widgets/monitor/monitor.component';
import { MultipleTablesComponent } from './components/widgets/multiple-tables/multiple-tables.component';
import { SynopticTimeMachineComponent } from './components/widgets/synoptic-time-machine/synoptic-time-machine.component';
import { SynopticWidgetComponent } from './components/widgets/synoptic/synoptic-widget/synoptic-widget.component';
import { ZoomableImageDialogComponent } from './components/zoomable-image-dialog/zoomable-image-dialog.component';
import { LeftDirective } from './directives/left.directive';
import { MarginLeftDirective } from './directives/margin-left.directive';
import { MarginRightDirective } from './directives/margin-right.directive';
import { RightDirective } from './directives/right.directive';
import { AlertsComponent } from './pages/alerts/alerts.component';
import { BrandConsumptionComponent } from './pages/consumption-analytics/brand-consumption/brand-consumption.component';
import { CompleteConsumptionComponent } from './pages/consumption-analytics/complete-consumption/complete-consumption.component';
import { ConsumptionAnalyticsComponent } from './pages/consumption-analytics/consumption-analytics.component';
import { ConsumptionSupplySettingsComponent } from './pages/consumption-analytics/consumption-supply-settings/consumption-supply-settings.component';
import { ConsumptionSupplyComponent } from './pages/consumption-analytics/consumption-supply/consumption-supply.component';
import { MultiplePeriodsComponent } from './pages/consumption-analytics/multiple-periods/multiple-periods.component';
import { GcaVariablesComponent } from './pages/cycle-traceability/cycle-detail/gca-variables/gca-variables.component';
import { GcaSettingsComponent } from './pages/cycle-traceability/gca-settings/gca-settings.component';
import { GcaVariablesConfigComponent } from './pages/cycle-traceability/gca-variables-config/gca-variables-config.component';
import { DataMappingComponent } from './pages/data-mapping/data-mapping.component';
import { HealthTraceabilityComponent } from './pages/health-monitoring/health-traceability/health-traceability.component';
import { HealthTrendComponent } from './pages/health-monitoring/health-trend/health-trend.component';
import { HmContinuousExplorationComponent } from './pages/health-monitoring/hm-continuous-exploration/hm-continuous-exploration.component';
import { HmCycleExplorationComponent } from './pages/health-monitoring/hm-cycle-exploration/hm-cycle-exploration.component';
import { HmCardComponent } from './pages/health-monitoring/hm-grid/hm-card/hm-card.component';
import { HmGridComponent } from './pages/health-monitoring/hm-grid/hm-grid.component';
import { ConnectedMachinesListComponent } from './pages/homepage/connected-machines-list/connected-machines-list.component';
import { MapCardComponent } from './pages/homepage/map/map-card/map-card.component';
import { BoxPlotDialogComponent } from './pages/kpi-analytics/box-plot-dialog/box-plot-dialog.component';
import { ChangeoverComponent } from './pages/lean-analytics/changeover/changeover.component';
import { EfficiencyLossComponent } from './pages/lean-analytics/efficiency-loss/efficiency-loss.component';
import { MachineKpisComponent } from './pages/lean-analytics/machine-kpis/machine-kpis.component';
import { ComponentVariablesSelectionComponent } from './pages/machine-recorder/component-variables-selection/component-variables-selection.component';
import { ContinuousExplorationComponent } from './pages/machine-recorder/continuous-exploration/continuous-exploration.component';
import { EventTypesFilterDialogComponent } from './pages/machine-recorder/continuous-exploration/event-types-filter-dialog/event-types-filter-dialog.component';
import { CycleExplorationComponent } from './pages/machine-recorder/cycle-exploration/cycle-exploration.component';
import { CycleListComponent } from './pages/machine-recorder/cycle-list/cycle-list.component';
import { CycleSelectionDialogNewComponent } from './pages/machine-recorder/cycle-selection-dialog-new/cycle-selection-dialog-new.component';
import { CycleSelectionDialogComponent } from './pages/machine-recorder/cycle-selection-dialog/cycle-selection-dialog.component';
import { MachineRecorderComponent } from './pages/machine-recorder/machine-recorder.component';
import { TimeStatesDialogComponent } from './pages/machine-recorder/time-states-dialog/time-states-dialog.component';
import { ConsumablesSettingsComponent } from './pages/machine-settings/consumables-settings/consumables-settings.component';
import { AddEventDialogComponent } from './pages/machine-settings/rba-settings/add-event-dialog/add-event-dialog.component';
import { EditVariableDialogComponent } from './pages/machine-settings/rba-settings/edit-variable-dialog/edit-variable-dialog.component';
import { RbaSettingsComponent } from './pages/machine-settings/rba-settings/rba-settings.component';
import { MachineCardComponent } from './pages/machine-supervision/multi-machines-grid/machine-card/machine-card.component';
import { MultiMachinesGridComponent } from './pages/machine-supervision/multi-machines-grid/multi-machines-grid.component';
import { RemoteMonitoringArComponent } from './pages/machine-supervision/remote-monitoring-ar/remote-monitoring-ar.component';
import { AnnealerDialogComponent } from './pages/machine-supervision/remote-monitoring/annealer-dialog/annealer-dialog.component';
import { EmbeddedLinkDialogComponent } from './pages/machine-supervision/remote-monitoring/embedded-link-dialog/embedded-link-dialog.component';
import { ImageDialogComponent } from './pages/machine-supervision/remote-monitoring/image-dialog/image-dialog.component';
import { OnlyDatapointLinkComponent } from './pages/machine-supervision/remote-monitoring/only-datapoint-link/only-datapoint-link.component';
import { StateTimelineAdvancedComponent } from './pages/machine-supervision/state-timeline-advanced/state-timeline-advanced.component';
import { PerformanceSettingsComponent } from './pages/performance-settings/performance-settings.component';
import { AssignOperatorsDialogComponent } from './pages/planning-calendar/calendar/assign-operators-dialog/assign-operators-dialog.component';
import { ExportCalendarDialogComponent } from './pages/planning-calendar/calendar/export-calendar-dialog/export-calendar-dialog.component';
import { OperatorsComponent } from './pages/planning-calendar/operators/operators.component';
import { PlannerDialogComponent } from './pages/planning-calendar/planner/planner-dialog/planner-dialog.component';
import { PlannerComponent } from './pages/planning-calendar/planner/planner.component';
import { ProgramTraceabilityDetailComponent } from './pages/program-traceability/program-traceability-detail/program-traceability-detail.component';
import { StandardDashboardTablePageComponent } from './pages/standard-dashboard-table-page/standard-dashboard-table-page.component';
import { TechnicalSupportComponent } from './pages/technical-support/technical-support.component';
import { LastVideoStreamingComponent } from './pages/video-streaming/last-video-streaming/last-video-streaming.component';
import { LiveStreamingComponent } from './pages/video-streaming/live-streaming/live-streaming.component';
import { EditNotesDialogComponent } from './pages/video-streaming/past-videos/edit-notes-dialog/edit-notes-dialog.component';
import { PastVideosComponent } from './pages/video-streaming/past-videos/past-videos.component';
import { SessionDetailComponent } from './pages/video-streaming/session-detail/session-detail.component';
import { VideoStreamingComponent } from './pages/video-streaming/video-streaming.component';
import { CustomSearchFilterPipe } from './pipes/custom-search-filter.pipe';

// Time machine (fiera drinktec)
import { ImageFromBeComponent } from './components/image-from-be/image-from-be.component';
import { MultiAssetSelectionDialogComponent } from './components/multi-asset-selection-dialog/multi-asset-selection-dialog.component';
import { RecursiveListComponent } from './components/multi-asset-selection-dialog/recursive-list/recursive-list.component';
import { SmileyFacesComponent } from './components/smiley-faces/smiley-faces.component';
import { HmFeaturesScatterMatrix } from './pages/health-monitoring/hm-features-scatter-matrix/hm-features-scatter-matrix.component';
import { HmFeaturesTrendComponent } from './pages/health-monitoring/hm-features-trend/hm-features-trend.component';
import { CrossKpiAnalyticsComponent } from './pages/kpi-analytics/cross-kpi-analytics/cross-kpi-analytics.component';
import { CyclePhotosDialogComponent } from './pages/machine-recorder/cycle-exploration/cycle-photos-dialog/cycle-photos-dialog.component';
import { TimeMachineComponent } from './pages/machine-supervision/time-machine/time-machine.component';
import { ShiftInvalidationComponent } from './pages/lean-analytics/shift-invalidation/shift-invalidation.component';
// Time machine (DXF)
// import { TimeMachineComponent } from './pages/machine-supervision/time-machine/time-machine_dxf.component';

// required for Translate (AOT) compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new MultiTranslateHttpLoader(http, [
    { prefix: "./i18n_global/", suffix: ".json" },
    { prefix: "./assets/i18n/", suffix: ".json" },
    // { prefix: 'https://mindappstatic.blob.core.windows.net/aqseptence/', suffix: ".json" },
    // { prefix: 'https://ocmeiotstorage.blob.core.windows.net/ocmewebappstatic/', suffix: ".json" },
  ]);
}

moment.updateLocale('it', {
  monthsShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 1 // The week that contains Jan 4th is the first week of the year.
  }
});

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 1 // The week that contains Jan 4th is the first week of the year.
  }
});
// // Create custom Injection Token
// const ConfigDeps = new InjectionToken<(() => Function)[]>('configDeps');

@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    MainToolbarComponent,
    ErrorCardComponent,
    LoadingCardComponent,
    TabsComponent,
    IntervalSelectorComponent,

    RemoteMonitoringComponent,
    SynopticComponent,
    WidgetCardComponent,
    StatusComponent,
    GaugeComponent,
    HtmlBarComponent,
    BarComponent,

    AlarmsTableComponent,
    SignalationsTableComponent,
    StatesTimelineComponent,
    CycleTimelineComponent,

    CycleTraceabilityComponent,

    ProductionTraceabilityComponent,
    ProductionTraceabilityDetailComponent,

    BreakdownsAlarmsComponent,
    BreakdownsSignalationsComponent,

    LeanAnalyticsComponent,
    LeanOeeTrendComponent,
    LeanProductionTrendComponent,
    LeanBreakdownsComponent,
    LeanFaultAnalysisComponent,
    LeanBreakdownsSignalationsComponent,
    LeanScrapAnalyticsComponent,

    ErrorPageComponent,
    // TestPageComponent,

    HomepageComponent,
    HomepageGridComponent,
    HomepageGridCardComponent,
    HomepageListComponent,
    HomepageMapComponent,
    MachinesFiltersPipe,
    PlaceholderPipe,
    CheckTranslationPipe,
    ShortenPipe,
    ReplacePipe,
    FilterByPropertyPipe,
    FiltersDialogComponent,

    MachineSupervisionComponent,
    DatapointDialogComponent,
    ValueComponent,
    OptionsComponent,
    MachineSettingsComponent,

    SynopticSelectionDialogComponent,
    ClothMonitoringComponent,

    CustomIntervalDialogComponent,

    ProdSheetComponent,
    StopsComponent,
    StopsStoryComponent,
    AlertComponent,
    AlertInvalidComponent,
    CycleDetailComponent,
    CycleDetailTimelineComponent,
    ProcessLogComponent,
    VariablesSelectionDialogComponent,
    PhaseVariablesComponent,
    CycleDashboardComponent,
    PlotlyChartComponent,
    PlotlyChartTableComponent,
    ApexChartComponent,
    HelpDialogComponent,
    AggregationsDialogComponent,
    ExportTableComponent,
    MachineStatusLogComponent,
    CycleComparisonDialogComponent,
    LeanProductionTrendComponent,
    PlanningCalendarComponent,
    CalendarComponent,
    AssignShiftTemplateDialogComponent,
    ConfirmationDialogComponent,
    ShiftTemplatesComponent,
    EditShiftTemplateComponent,
    AddDowntimeDialogComponent,
    CopyWeekDialogComponent,
    KpiAnalyticsComponent,
    SingleKpiAnalyticsComponent,
    DoubleKpiAnalyticsComponent,
    BoxPlotDialogComponent,
    StateTimelineAdvancedComponent,
    ConsumptionAnalyticsComponent,
    CompleteConsumptionComponent,
    MachineRecorderComponent,
    ContinuousExplorationComponent,
    EventTypesFilterDialogComponent,
    ComponentVariablesSelectionComponent,
    TimeStatesDialogComponent,
    CycleExplorationComponent,
    CycleSelectionDialogComponent,
    RbaSettingsComponent,
    EditVariableDialogComponent,
    AddEventDialogComponent,
    AlertsComponent,
    MobileCardComponent,
    MultiplePeriodsComponent,
    LastUpdatesDetailDialogComponent,
    GcaInputDialogComponent,
    FfTableComponent,
    GcaVariablesComponent,
    LedComponent,
    HmGridComponent,
    HmCardComponent,
    RoundedIconButtonComponent,
    MachineComponentSelectionDialogComponent,
    HealthTraceabilityComponent,
    ImageComponent,
    FfTableSortableComponent,
    HealthTrendComponent,
    ExportCalendarDialogComponent,
    HmContinuousExplorationComponent,
    HmCycleExplorationComponent,
    HealthBarComponent,
    GcaVariablesConfigComponent,
    AvailableMachinesSelectionComponent,
    DataMappingComponent,
    FfSelectorComponent,
    VideoStreamingComponent,
    LiveStreamingComponent,
    PastVideosComponent,
    PlannerComponent,
    PlannerDialogComponent,
    ProgramTraceabilityDetailComponent,
    SessionDetailComponent,
    InfoGaugeComponent,
    EfficiencyLossComponent,
    MapCardComponent,
    EditNotesDialogComponent,
    MonitorComponent,
    CycleListComponent,
    FfDashboardComponent,
    FilterButtonsComponent,
    AggregationsButtonsComponent,
    DurationInputComponent,
    StandardDashboardTablePageComponent,
    SingleInputDialogComponent,
    MultipleInputDialogComponent,
    ConsumptionSupplyComponent,
    MultipleTablesComponent,
    CustomActionButtonsComponent,
    FfBotComponent,
    FfWilsonComponent,
    ConsumptionSupplySettingsComponent,
    FfIconComponent,
    FfDashboardDialogComponent,
    BrandConsumptionComponent,
    LastUdpateComponent,
    FfColumnArrayComponent,
    ConsumablesSettingsComponent,
    FfSingleCellComponent,
    ChangeoverComponent,
    MaintenanceInterventionDialogComponent,
    DisplayImageDialogComponent,
    MaintenanceComponentDialogComponent,
    MachineKpisComponent,
    GcaSettingsComponent,
    MaintenanceBomGeneratorDialogComponent,
    PerformanceSettingsComponent,
    MaintenanceCalendarTemplatesDialogComponent,
    CustomCalendarComponent,
    AssignTemplateDialogComponent,
    FfCheckboxComponent,
    MaintenanceEventDialogComponent,
    ParameterChangeTableComponent,
    AddMaintenanceComponentDialogComponent,
    ImportExcelDialogComponent,
    ImageDialogComponent,
    AnnealerDialogComponent,
    TechnicalSupportComponent,
    SnackbarComponent,
    DashboardDialogComponent,
    IngenyaImageDialogComponent,
    OperatorsComponent,
    AssignOperatorsDialogComponent,
    FfMailingListComponent,
    ZoomableImageDialogComponent,
    LastVideoStreamingComponent,
    FfUrlPathComponent,
    ErrorDialogComponent,
    MultiMachinesGridComponent,
    MachineCardComponent,
    EditPdfDialogComponent,
    ImportFileDialogComponent,
    CreateFolderDialogComponent,
    LeftDirective,
    RightDirective,
    TimeMachineComponent,
    MarginLeftDirective,
    MarginRightDirective,
    SynopticTimeMachineComponent,
    ConnectedMachinesListComponent,
    ConnectionTimelineDialogComponent,
    CustomSearchFilterPipe,
    BarillaShiftSelectionDialogComponent,
    ApexChartWithSwitchComponent,
    LoginPageComponent,
    FfSwitchComponent,
    OnlyDatapointLinkComponent,
    SynopticWidgetComponent,
    RemoteMonitoringArComponent,
    SctHeatmapComponent,
    SctDatapointDialogComponent,
    SctSetZoomDialogComponent,
    EmbeddedLinkDialogComponent,
    CycleSelectionDialogNewComponent,
    HmFeaturesTrendComponent,
    HmFeaturesScatterMatrix,
    CyclePhotosDialogComponent,
    CrossKpiAnalyticsComponent,
    MultiAssetSelectionDialogComponent,
    RecursiveListComponent,
    ImageFromBeComponent,
    SmileyFacesComponent,
    ShiftInvalidationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,

    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    DragDropModule,

    MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,

    FlexLayoutModule,
    BrowserAnimationsModule,
    NgApexchartsModule,

    UiSwitchModule,
    NgxSliderModule,

    FullCalendarModule,

    ColorSketchModule,
    ColorPickerModule,


    AgmCoreModule.forRoot({
      // apiKey: 'AIzaSyDvd_gKCUDNM7SY7RBWEyBsBGPKzawkBXU' /* m.curatitoli@gmail.com */
      apiKey: 'AIzaSyC5AO_ML-sM_GlDSbbqc8tR2MqpZqj_XWA' /* 40factory */
    }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [

    // init AppConfigService
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          //Make sure to return a promise!
          return appConfigService.loadAppConfigurations();
        };
      }
    },

    // init TranslateService
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    },
    // // init AppConfigService
    // {
    //   provide: APP_INITIALIZER,
    //   multi: true,
    //   // ConfigDeps is now a dependency for configurationFactory
    //   deps: [HttpClient, NewAppConfigurationService, ConfigDeps],
    //   useFactory: configurationFactory,
    // },

    // {
    //   provide: ConfigDeps,
    //   // multi: true,
    //   // Use a factory that return an array of dependant functions to be executed
    //   useFactory: (
    //     http: HttpClient,
    //     config: NewAppConfigurationService
    //   ) => {
    //     // Easy to add or remove dependencies
    //     return [
    //       // bFactory(http, config, configB),
    //       // cFactory(http, config, configC)
    //       appInitializerFactory
    //     ];
    //   },
    //   deps: [HttpClient, NewAppConfigurationService, TranslateService, Injector]
    // }
    // // init TranslateService
    // // {
    // //   provide: APP_INITIALIZER,
    // //   useFactory: appInitializerFactory,
    // //   deps: [TranslateService, Injector],
    // //   multi: true
    // // }

    {
      provide: DateAdapter,
      useClass: CustomDateAdapter
    },
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }

// required for Translate (init translations)
export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {

    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      let localLang = localStorage.getItem('language');

      if (localLang == null) {
        translate.setDefaultLang('en');
        localStorage.setItem('language', 'en');
      }

      localLang = localLang != null ? localLang.toString() : 'en';

      try {
        moment.locale(localLang);
      } catch (error) {
        console.log(error);
      }

      translate.use(localLang).subscribe(() => {
        // console.info(`Successfully initialized '${localLang}' language.'`);
      }, err => {
        console.error(`Problem with '${localLang}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}