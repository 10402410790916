import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

import * as moment from 'moment';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { FiltersDialogComponent } from 'src/app/components/filters-dialog/filters-dialog.component';
import { CacheService } from 'src/app/services/cache.service';
import { MobileService } from 'src/app/services/mobile.service';
import { StandardDialogServiceService } from 'src/app/services/standard-dialog.service';

@Component({
  selector: 'shift-invalidation',
  templateUrl: './shift-invalidation.component.html',
  styleUrls: ['./shift-invalidation.component.scss']
})
export class ShiftInvalidationComponent implements OnInit, OnDestroy {

  public isAllowedUser: boolean = true;

  public configurationErrors: any = [];

  public loadingData: any;
  public errorData: any;
  public errorDataMobile: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public isSection: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public calendarShifts: any = [];

  public pollingTime: any;
  public pollingProds: any;

  public intervalAggrList: any;
  public intervalAggrDefaultId: any;

  public interval: any;
  public intervalConfig: any;

  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;

  public intervalAggregations: any;
  public aggregationsTime: any;

  public intervalSelectorType: any;
  public noAggrButtons: any;
  public filterButtons: any;
  public customFilters: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public dashboardConfig: any;

  public prodsDataDash: any;

  public tabName: any = "shiftInvalidation";
  public sectionName: any = "leanAnalytics";
  public dashboardName: any = "shift-invalidation";
  public dashboardNameComplete: any;

  public mobileListener: any;
  public isMobile: any;
  public isSmThanTablet: any

  public defaultSelectedSubAggregation: any = null;
  public isAggregationSelected: boolean = false;


  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getCalendar, nextState: 5, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.mainPollingFunction, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    private router: Router,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    public standardDialogServiceService: StandardDialogServiceService,
    public mobile: MobileService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile
      this.isSmThanTablet = value.isSmThanTablet
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })

    let tabConfig = this.appConfig?.[this.tabName] ?? {};

    this.noAggrButtons = tabConfig?.noAggrButtons;
    this.filterButtons = tabConfig?.additionalFilterButtons;
    this.availableMachines = tabConfig?.availableMachines ?? [];

    this.breadcrumb = tabConfig?.breadcrumb ?? ['LEAN_ANALYTICS.TITLE', 'SHIFT_INVALIDATION.TITLE'];

    this.internalDataService.setCalendarPage(true);
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.isSection = !this.router.routerState.snapshot.url?.split('/')?.includes('lean-analytics');

    this.tabs = this.internalDataService.getPageTabs(this.isSection ? this.tabName : this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig?.[this.tabName]?.pollingTime ?? 0;
    this.pollingProds = Subscription;

    this.intervalSelectorType = this.appConfig?.[this.tabName]?.intervalSelectorType;

    // this.intervalAggrList = this.appConfig?.[this.tabName]?.intervalAggrList;
    // this.intervalAggrDefaultId = this.appConfig?.[this.tabName]?.intervalAggrDefault;

    // if (this.intervalAggrDefaultId != null && this.intervalAggrList?.length > 0) this.cacheService.set("intervalAggregation", this.intervalAggrList.find(x => x.id == this.intervalAggrDefaultId) ?? this.intervalAggrList.find(x => x.id == "day") ?? this.intervalAggrList[1])

    // this.pageState.subscribe((value: any) => console.log(value))

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  openAggrDialog(aggr: any) {

    try {
      this.pollingProds.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {

        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
      }

      if (this.appConfig?.[this.tabName] != null && this.appConfig[this.tabName].hasOwnProperty("sessionFilters")) {
        let sessionFilters: any = this.clonerService.deepClone(this.aggregations)
        this.cacheService.set('sessionFilters', sessionFilters)
      }

      this.pageState.next(isClickedSelect ? 6 : 7);

      if (!this.interval.enabledPolling) {
        this.getData(this, 0);
      } else {
        this.mainPollingFunction(this);
      }

    });
  };

  openFilterDialog(button: any) {
    // console.log(button)
    let filtersDialog = this.dialog.open(FiltersDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(button.label),
        variable: button.variable,
        options: button.options
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') {
        // console.log('afterClosed', result);
        button.options = this.clonerService.deepClone(result?.options);
        this.customFilters[button.variable] = button.options.filter((opt: any) => opt.selected).map((opt: any) => opt.id)

        setTimeout(() => {
          this.mainPollingFunction(this)
          this.pageState.next(6)
        }, 250)


      }
    });
  }

  changePageAggregation(aggrDropdown: any) {
    this.aggrDropdown = this.clonerService.deepClone(aggrDropdown);
    this.isAggregationSelected = true;
    this.pageState.next(6);
    if (!this.interval.enabledPolling) this.getData(this, 0);
    else this.getData(this, 0);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-la-traceability");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.isSection ? _this.internalDataService.getPageTabs(_this.tabName) : _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {

      let tableType = "ff-table-sortable";
      if (_this.dashboardConfig?.widgets?.findIndex(x => x.type == tableType) == -1) {
        _this.configurationErrors.push({ label: "The table is not present because in the file \"" + _this.dashboardNameComplete + "\" there is no widget of type \"" + tableType + "\"" })

      }
      if (_this.configurationErrors.length > 0) {
        _this.internalDataService.openSnackBar(_this.configurationErrors.map(x => "- " + x.label + ".").join("\n"), 'right', 'bottom', 60000, 'x', ["warning"]);
      }
      try {
        let tabsParent = _this.isSection ? _this.tabName : _this.sectionName
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, tabsParent);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingProds, this.mainPollingFunction, this.getData, this.machine.timezone, 5) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // interval aggr
  selectAggregation(aggr: any) {
    if (aggr != null) {
      try {
        this.pollingProds.unsubscribe();
      } catch (error) { }

      this.intervalAggregations.selected = aggr;

      this.pageState.next(5);
      this.mainPollingFunction(this);
    }
  }

  machineSelectionChange(machine: any) {

    if (machine != null) {

      try {
        this.pollingProds.unsubscribe();
      } catch (error) {
      }

      this.pageState.next(5);
      this.mainPollingFunction(this);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // CALENDAR

  // get calendar shifts
  getCalendar(_this: any) {
    try {

      let url = '/apif/calendar/' + _this.machineId;

      let query: any = {
        minimumCalendarUnit: _this.machine.profile?.settings?.minimumCalendarUnit ?? '30m',
      };

      _this.apiService.sendGetRequest(url, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.calendarShifts = _this.clonerService.deepClone(data.body);

            _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // prods

  // polling
  mainPollingFunction(_this: any) {
    try {

      if (_this.cacheService.get("intervalLong") == null) {

        _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.cacheService.set("intervalLong", _this.intervalConfig);

      } else {
        _this.intervalConfig = _this.cacheService.get("intervalLong");
        _this.interval = _this.cacheService.get("intervalLong").selected;
      }

      if (_this.pollingTime > 0) {
        _this.pollingProds = timer(0, _this.pollingTime).subscribe(count => _this.getData(_this, count));
      } else { _this.getData(_this, 0) }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get prods
  getData(_this: any, count?: any) {
    try {

      // if (count == 0 && _this.intervalAggrList?.length > 0) _this.internalDataService.forceAggregationList(_this, _this.intervalAggrList);

      let url = '/apif/production-traceability/' + _this.machineId;

      // if (_this.appConfig?.[_this.tabName] != null && _this.appConfig[_this.tabName].hasOwnProperty("sessionFilters")) {
      //   let sessionFilters = _this.cacheService.get('sessionFilters')
      //   if (sessionFilters != null) _this.aggregations = sessionFilters
      // }

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      payload.ignorePlannedDowntime = true;
      payload.enabledCalendar = true;
      // payload.customFilters = _this.customFilters

      if (_this.machine.profile?.prodTraceVariables?.length > 0) payload.prodTraceVariables = _this.clonerService.deepClone(_this.machine.profile.prodTraceVariables);
      if (_this.appConfig?.showCalendarOperatorsInProductionTraceability) payload.showCalendarOperatorsInProductionTraceability = true;

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone,
        unit: "shift",
        value: 1,
      };

      // if (_this.interval != null && !_this.interval.enabledPolling) {
      //   query.to = _this.interval.end;
      // }

      // Default sub aggregation (MFL - prysmian)
      if (_this.aggrDropdown == null && _this.defaultSelectedSubAggregation != null && !_this.isAggregationSelected) _this.aggrDropdown = _this.defaultSelectedSubAggregation;

      if (_this.machine.profile.subAggregations != null && _this.machine.profile.subAggregations.length > 0 && _this.aggrDropdown != null) {
        query.subAggregations = _this.aggrDropdown;
      }

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      _this.filterService.filterAggregationsByMachine(_this, query?.machineId);

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log('data',data);

            _this.prodsDataDash = _this.clonerService.deepClone(data.body);

            _this.prodsDataDash.table = [];
            if (_this.prodsDataDash != null && _this.prodsDataDash.timeserie != null) {
              _this.prodsDataDash.table = _this.parseProdsTable(_this.prodsDataDash.timeserie);
            }

            if (_this.appConfig?.[_this.tabName]?.filterButtons != null) {
              _this.filterButtons = _this.buildCustomFilterButtons(_this.prodsDataDash.activeTable.concat(_this.prodsDataDash.table))
            }

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseProdsTable(data: any) {

    let iconConfig = [
      {
        id: true,
        icon: {
          icon: "check_circle_outline",
          type: "icon",
          class: "md-green"
        }
      },
      {
        id: false,
        icon: {
          icon: "cancel",
          type: "icon",
          class: "md-red"
        }
      },
      {
        id: null,
        icon: {
          icon: "cancel",
          type: "icon",
          class: "md-red"
        }
      }
    ]

    let parsedData = (data ?? [])?.reduce((acc, val) => {
      acc.push(val);

      let shiftConfigs = this.calendarShifts?.filter(shiftConf => {

        let isValidShift = false;
        try { isValidShift = shiftConf.workDay == val.day && shiftConf.from == val.timeStart.substring(0, 16) && shiftConf.to == val.timeEnd.substring(0, 16) }
        catch (error) { console.log(error) }

        // let isValidShift = x.shiftId == val.shiftId && x.workDay == val.day;
        return isValidShift;
      });

      val.shiftConfig = shiftConfigs?.find(x => x.validity != 0) ?? shiftConfigs?.[0];

      val.validity = shiftConfigs?.every(x => x.validity == 0);

      val.icon = iconConfig?.find(x => x.id == val.validity);

      return acc;
    }, []);

    let tableInfos = this.dashboardConfig?.widgets?.find(x => x.type == 'ff-table-sortable')?.config?.tableInfos ?? [];
    let singleCellTableInfos = tableInfos?.filter(x => x.type == 'singleCellList');

    if (singleCellTableInfos?.length) {

      parsedData = parsedData?.reduce((acc, val) => {

        let idx = acc.findIndex(x => x.timeStart == val.timeStart);

        singleCellTableInfos?.forEach(info => val[info.variable] = [val[info.variable]]);
        if (idx == -1) return acc.concat(val);

        singleCellTableInfos?.forEach(info => {
          try { acc[idx][info.variable] = acc[idx][info.variable].concat(val[info.variable]) }
          catch (error) { console.log(error) }
        }
        );

        return acc;
      }, []);

      // console.log({ parsedData });

      return parsedData;
    }

    return parsedData ?? [];
  }

  buildCustomFilterButtons(data: any) {

    let buttons: any[] = [];
    let filterButtonsConfig = this.appConfig?.[this.tabName]?.filterButtons

    if (filterButtonsConfig != null && filterButtonsConfig.length > 0) {

      filterButtonsConfig.forEach((attribute: any) => {

        attribute = this.clonerService.deepClone(attribute);

        let newOptions: [] = this.clonerService.deepClone(data.map((x: any) => x[attribute.variable]).filter(this.filterService.onlyUnique))
        let closedListOptions: [] = this.clonerService.deepClone(attribute.options.map((x: any) => x[attribute.variable]).filter(this.filterService.onlyUnique))
        let concatOptions = closedListOptions.concat(newOptions)

        attribute.options = this.clonerService.deepClone(concatOptions.filter(this.filterService.onlyUnique).map((x: any) => {
          if (x == '' || x == null) x = ''
          return {
            id: x,
            label: x != null && x != '' ? this.translate.instant(x) : this.translate.instant('PLANNER.TO_COMPILE'),
            selected: this.customFilters[attribute.variable].includes(x)
          }
        }));

        buttons.push(attribute);

      });

    }


    return buttons;
  }

  // TABLE ACTION
  tableAction(item) { this.openStandardDialog(item) }

  openStandardDialog(item) {

    // console.log({ item });

    if (this.standardDialogServiceService?.[item?.buttonInfos?.clickFunction] == null) {
      this.internalDataService.openSnackBar(this.translate.instant('GLOBAL.NO_CLICK_FUNCTION_CONFIGURED', {
        clickFunction: item?.buttonInfos?.clickFunction ?? '-'
      }), 'right', 'bottom', 4000, '', ["warning"]);
      return;
    }
    this.standardDialogServiceService?.[item?.buttonInfos?.clickFunction]?.(this, item);
  }

  editShiftValidity(_this, row) {

    this.pageState.next(5);

    console.log("ciao");

    // Shift to validate --> remove downtime from calendar
    if (row.shiftConfig?.validity != 0) {

      let shift = row.shiftConfig;

      let shiftIndex = _this.calendarShifts?.findIndex(shiftConf => {

        let isValidShift = shiftConf.workDay == shift.workDay && shiftConf.from == shift.from && shiftConf.to == shift.to && shiftConf.validity == shift.validity;
        // let isValidShift = false;
        // try { isValidShift = shiftConf.workDay == shift.day && shiftConf.from == shift.from && shiftConf.to == shift.to && shiftConf.validity == shift.validity }
        // catch (error) { console.log(error) }

        // let isValidShift = x.shiftId == val.shiftId && x.workDay == val.day;
        return isValidShift;
      });

      if (shiftIndex != -1) {
        console.log(_this.calendarShifts[shiftIndex], shiftIndex);
        _this.calendarShifts.splice(shiftIndex, 1);
        _this.updateCalendar(_this);
      }

    }

    // Shift to invalidate --> add downtime to calendar
    else {

      let shift = row.shiftConfig;

      shift.validity = 3;

      _this.calendarShifts.push(shift)
      _this.updateCalendar(_this);

    }

  }

  updateCalendar(_this) {

    try {

      let query: any = {
        minimumCalendarUnit: _this.machine.profile?.settings?.minimumCalendarUnit ?? '30m',
      };

      let payload: any = _this.clonerService.deepClone(_this.calendarShifts);

      let url = '/apif/calendar/' + _this.machineId;

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // INIT

  ngOnInit() {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);

    this.dispatcherService.getDispatch(this, 300);
  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // DESTROY
  ngOnDestroy() {
    this.internalDataService.setCalendarPage(false);
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingProds.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
  }

}