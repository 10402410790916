import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject, lastValueFrom, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { AggregationsDialogComponent } from '../components/aggregations-dialog/aggregations-dialog.component';
import { FiltersDialogComponent } from '../components/filters-dialog/filters-dialog.component';
import { CheckTranslationPipe } from '../pipes/checkTranslation.pipe';
import { ApiService } from './api.service';
import { AppConfigService } from './app-config.service';
import { CacheService } from './cache.service';
import { ClonerService } from './clone.service';
import { FiltersService } from './filters.service';

@Injectable({
  providedIn: 'root'
})
export class InternalDataService {
  public appConfig: any;
  public appInfo: any;
  public progressBarThresholds: any;

  constructor(
    public appConfigService: AppConfigService,
    public activatedRoute: ActivatedRoute,
    public filterService: FiltersService,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    private MatSnackBar: MatSnackBar,
    private apiService: ApiService,
    private router: Router,
    private translate: FfTranslateService,
  ) {
    this.appConfig = this.appConfigService.getAppConfig
    this.appInfo = this.appConfigService.getAppInfo
    this.progressBarThresholds = this.appConfig.progressBarThresholds
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // breadcrumb
  private breadcrumbSource: BehaviorSubject<any> = new BehaviorSubject([]);
  breadcrumb = this.breadcrumbSource.asObservable();
  setBreadcrumb(value: []) {

    let translated: any[] = [];
    if (value != null && Array.isArray(value) && value.length > 0) {
      value.forEach((v: string, i: number) => translated.push((i == 0 ? "" : "/ ") + this.translate.instant(v, null, "")))
    }
    this.breadcrumbSource.next(translated);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // onMultiAssetSelection
  public onMultiAssetSelectionSource: BehaviorSubject<any> = new BehaviorSubject(null);
  onMultiAssetSelection = this.onMultiAssetSelectionSource.asObservable();
  setOnMultiAssetSelection(value: any = null) { this.onMultiAssetSelectionSource.next(value) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // selectedIntervalId
  private selectedIntervalIdSource: BehaviorSubject<any> = new BehaviorSubject(null);
  selectedIntervalId = this.selectedIntervalIdSource.asObservable();
  setSelectedIntervalId(value: any = null) { this.selectedIntervalIdSource.next(value) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // calendar
  private calendarPageSource: BehaviorSubject<any> = new BehaviorSubject(false);
  calendarPage = this.calendarPageSource.asObservable();
  setCalendarPage(value: Boolean = false) {

    this.calendarPageSource.next(value);
  }

  private calendarSource: BehaviorSubject<any> = new BehaviorSubject(null);
  calendar = this.calendarSource.asObservable();
  setCalendar(value: any) { this.calendarSource.next(value) }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // selectedAssetsList
  private selectedAssetsListSource: BehaviorSubject<any> = new BehaviorSubject([]);
  selectedAssetsList = this.selectedAssetsListSource.asObservable();
  setSelectedAssetsList(value: any = null) {
    if (Array.isArray(value)) this.cacheService.set("assets", this.clonerService.deepClone(value));
    this.selectedAssetsListSource.next(value);
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // langs
  private langSource: BehaviorSubject<any> = new BehaviorSubject(null);
  lang = this.langSource.asObservable();
  setLang(value: any = null) { this.langSource.next(value) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // monitoring data
  private monitoringDataSource: BehaviorSubject<any> = new BehaviorSubject(null);
  monitoringData = this.monitoringDataSource.asObservable();
  setMonitoringData(value: any = null) { this.monitoringDataSource.next(value) };


  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // langs
  public customSubscriptionSource: BehaviorSubject<any> = new BehaviorSubject(null);
  customSubscription = this.customSubscriptionSource.asObservable();
  setCustomSubscription(value: any = null) { this.customSubscriptionSource.next(this.clonerService.deepClone(value)) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // backButton
  private backButtonSource: BehaviorSubject<any> = new BehaviorSubject([]);
  backButton = this.backButtonSource.asObservable();
  setBackButton(value: [], navigateInBrowserHistory: any = false) {
    if ((value == null || value.length == 0) && this.cacheService.get("backButtonClicked") == false) {
      this.cacheService.set("intervalBreakdowns", null);
    }
    this.cacheService.set("backButtonClicked", false);
    let url = this.parseBackButtonValue(value);

    if (navigateInBrowserHistory) this.backButtonSource.next({ navigateInBrowserHistory: navigateInBrowserHistory });
    else this.backButtonSource.next(url);
  }

  parseBackButtonValue(value: []) {
    let url = value?.filter((x: any) => typeof x != 'object')?.map((x: any) => "/" + x)?.join("");
    let queryParams: any = value?.filter((x: any) => typeof x == 'object');
    let queryParamsUrl = '';
    if (queryParams?.length == 1) {
      let params = new URLSearchParams();
      for (let key in queryParams[0]) {
        params.set(key, queryParams[0][key]);
      }
      queryParamsUrl = '?' + params;
    }
    url = url + queryParamsUrl;
    return url;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // boxPlotData
  private boxPlotDataSource: BehaviorSubject<any> = new BehaviorSubject(null);
  boxPlotData = this.boxPlotDataSource.asObservable();
  setBoxPlotData(value: any) {
    this.boxPlotDataSource.next(value);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // resizePlot
  private resizePlotSource: BehaviorSubject<any> = new BehaviorSubject(null);
  resizePlot = this.resizePlotSource.asObservable();
  setResizePlot(value: any) {
    this.resizePlotSource.next(value);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // states timeline zoom
  private zoomedIntervalSource: BehaviorSubject<any> = new BehaviorSubject(null);
  zoomedInterval = this.zoomedIntervalSource.asObservable();
  setZoomedInterval(value: any) {
    this.zoomedIntervalSource.next(value);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // machine recorder zoom
  private zoomedIntervalVariablesSource: BehaviorSubject<any> = new BehaviorSubject(null);
  zoomedIntervalVariables = this.zoomedIntervalVariablesSource.asObservable();
  setZoomedIntervalVariables(value: any) {
    this.zoomedIntervalVariablesSource.next(value);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // HM trend bar click
  private clickCycleDetailSource: BehaviorSubject<any> = new BehaviorSubject(null);
  clickCycleDetail = this.clickCycleDetailSource.asObservable();
  setClickCycleDetail(value: any) {
    this.clickCycleDetailSource.next(value);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // hideSidenavs
  private hideSidenavsSource: BehaviorSubject<any> = new BehaviorSubject(false);
  hideSidenavs = this.hideSidenavsSource.asObservable();
  public _hideSidenavs: any = false;
  setHideSidenav(value: any) {
    this.hideSidenavsSource.next(value);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // hideToolbar
  private hideToolbarSource: BehaviorSubject<any> = new BehaviorSubject(false);
  hideToolbar = this.hideToolbarSource.asObservable();
  public _hideToolbar: any = false;
  setHideToolbar(value: any) {
    this.hideToolbarSource.next(value);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // sidenavItems
  private sidenavItemsSource: BehaviorSubject<any> = new BehaviorSubject([]);
  sidenavItems = this.sidenavItemsSource.asObservable();
  updateSidenavItems(keyToSearch = 'roles') {
    let _sidenavItems = this.appConfigService.getSidenavItems;

    let currentUser: any = localStorage.getItem('user');
    if (currentUser != null && currentUser != "") {

      try { currentUser = JSON.parse(currentUser) }
      catch (error) { console.log(error) }

      if (currentUser?.oauth != null && currentUser?.oauth != false && (keyToSearch != 'roles' || !currentUser?.checkOnlyScopes)) {
        _sidenavItems = _sidenavItems.filter(x => x.groups == null || currentUser?.[keyToSearch]?.some(role => x.groups?.includes(role)));
      }
    }

    _sidenavItems.forEach((item: any, idx: number) => {
      // update enabled/disabled items

      // item.disabled = ( Array.isArray(item.disabledFor)  && 
      //                   this.machineSelectedSource.value && 
      //                   item.disabledFor.includes(this.machineSelectedSource.value.machineId)) ||
      //                 ( item.hasOwnProperty('enableVariable') && 
      //                   item.enableVariable != null           && 
      //                   this._enabledVariables.findIndex((x: any) => x.id == item.enableVariable) == -1);

      if (item.disabled) {
        item._disabled = true;
      } else if (Array.isArray(item.enabledForAssets) && this.machineSelectedSource.value && !item.enabledForAssets.includes(this.machineSelectedSource.value.machineId)) {
        item._disabled = true;
      } else if (Array.isArray(item.disabledForAssets) && this.machineSelectedSource.value && item.disabledForAssets.includes(this.machineSelectedSource.value.machineId)) {
        item._disabled = true;
      } else if (item.hasOwnProperty('enableVariable') && item.enableVariable != null) {
        item._disabled = this._enabledVariables.findIndex((x: any) => x.id == item.enableVariable) == -1;
      } else {
        item._disabled = false;
      }
      // update urls
      if (item.hasOwnProperty('url')) {

        try {
          item.urlArray = item.url.split("/");
          item.urlArray.splice(0, 1);
          item.urlArray.forEach((element: any, idx: number) => {
            if (element.startsWith(':')) {
              element = element.substring(1);
              let enabledVar = this._enabledVariables.find((x: any) => x.id == element);
              // update url variable if enabled one
              if (enabledVar != null) item.urlArray[idx] = enabledVar.value;
            }
          });
          item.urlArray[0] = '/' + item.urlArray[0];
        } catch (error) {
          console.error(error);
        }
      }
    })

    this.sidenavItemsSource.next(_sidenavItems);
  }


  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // enabledVariables
  private enabledVariablesSource: BehaviorSubject<any> = new BehaviorSubject([]);
  enabledVariables = this.enabledVariablesSource.asObservable();
  public _enabledVariables: any[] = [];
  private enabledVariablesSub = this.enabledVariables.subscribe(value => {
    this._enabledVariables = value;
  });
  setEnabledVariable(value: any) {
    let enabledVarIndex = this._enabledVariables.findIndex((x: any) => {
      return x.id == value.id;
    });
    if (enabledVarIndex == -1) {
      this._enabledVariables.push(value);
    } else {
      this._enabledVariables[enabledVarIndex] = value;
    }

    this.enabledVariablesSource.next(this._enabledVariables);
    this.updateSidenavItems();
  }


  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // tabs
  getPageTabs(page: string) {
    let tabs: any = [];
    let _appConfig = this.appConfigService.getAppConfig;

    if (_appConfig && _appConfig[page] && _appConfig[page].tabs) {
      tabs = _appConfig[page].tabs;
      tabs.forEach((tab: any) => {

        if (tab.hasOwnProperty('url') && tab.url != null) {
          tab.urlArray = tab.url.split("/");
          tab.urlArray.splice(0, 1);
          tab.urlArray.forEach((element: any, idx: number) => {
            if (element.startsWith(':')) {
              let enabledVar = this._enabledVariables.find(x => x.id == element.substring(1));
              if (enabledVar != null) tab.urlArray[idx] = enabledVar.value;
            }
          });
          tab.urlArray[0] = '/' + tab.urlArray[0];

          if (tab.hasOwnProperty('queryParams') && tab.queryParams != null) {
            let queryParamsEnableVariable = this._enabledVariables.find((x: any) => x.id == tab.queryParams);
            if (queryParamsEnableVariable != null && queryParamsEnableVariable.value != null) tab.urlQueryParams = this.clonerService.deepClone(queryParamsEnableVariable.value);
          }

        }
      });
    }

    return tabs;
  }

  getPageTabsFromObject(page: string, tabName?: any) {

    let tabs: any = [];
    let _appConfig = this.appConfigService.getAppConfig;

    let pageConfig = tabName != null ? _appConfig?.[page]?.[tabName]?.detailTabs : _appConfig?.[page];

    if (pageConfig != null && Object.keys(pageConfig)?.length > 0) {

      tabs = Object.entries(pageConfig)?.map(kv => {
        let tab: any = kv[1];

        let urlQueryParams = tabName == null
          ? {
            sectionName: page,
            tabName: kv[0]
          }
          : {
            sectionName: page,
            tabName: tabName,
            detailTabName: kv[0],
          };

        return Object.assign({}, {
          url: tab.tabUrl,
          icon: tab.tabIcon,
          title: tab.title,
          hideMobile: tab.hideMobile,
          urlQueryParams: urlQueryParams,
        });
      });

      tabs.forEach((tab: any) => {

        if (tab.hasOwnProperty('url') && tab.url != null) {
          tab.urlArray = tab.url.split("/");
          tab.urlArray.splice(0, 1);
          tab.urlArray.forEach((element: any, idx: number) => {
            if (element.startsWith(':')) {
              let enabledVar = this._enabledVariables.find(x => x.id == element.substring(1));
              if (enabledVar != null) tab.urlArray[idx] = enabledVar.value;
            }
          });
          tab.urlArray[0] = '/' + tab.urlArray[0];
        }

      });

    }

    return tabs;
  }

  getOperatorSymbol(_op: any) {
    if (_op == 'le') {
      _op = '≤';

    } else if (_op == 'ge') {
      _op = '≥';

    } else if (_op == 'gt') {
      _op = '>';

    } else if (_op == 'lt') {
      _op = '<';

    } else if (_op == 'eq') {
      _op = "=";

    } else if (_op == 'neq') {
      _op = "!=";

    }

    return _op
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // get user data
  getUserData(_this: any) {
    try {
      _this.apiService.sendGetRequest('/apif/user')
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy()),
          catchError(error => {
            try {
              return _this.internalDataService.parseStandardHTTPError(_this, error)
            } catch (e) {
              console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);
              let errorData = {
                type: 0,
                status: error.status,
                message: error.statusText
              };
              _this.dispatcherService.getDispatch(_this, 301, errorData);
              return throwError(errorData.status + ': ' + errorData.message);
            }
          })
        )
        .subscribe(
          async (data: any) => {
            // console.log('getUserData', data.body);

            if (data.body != null && typeof data.body == 'string' && data.body.startsWith('login')) {
              window.location.reload();
            } else {

              try {
                let userInfo: any = _this.clonerService.deepClone(data.body);

                try { _this.cacheService.set("user", userInfo) } catch (error) { }

                localStorage.setItem('user', JSON.stringify(data.body));
                _this.isAllowedUserWrite = true;
                if (userInfo != null && userInfo.hasOwnProperty('roles') && userInfo.roles != null && Array.isArray(userInfo.roles)) {
                  _this.isAllowedUserWrite = !userInfo.roles.includes("mdsp_customrole:readOnly");
                  _this.internalDataService.updateSidenavItems();
                }
              } catch (error) {
                console.log(error);

              }
              _this.userData = data.body;

              if (_this.userData.reload) {

                if (_this.appInfo?.internalAuth) {
                  let currentUser = localStorage.getItem('basicAuthHeader');

                  if (currentUser != null) {

                    let credentials = currentUser.split(':', 2);
                    let payload: any = {};
                    if (credentials.length == 2) {
                      payload.user = credentials[0];
                      payload.password = credentials[1];
                    }
                    let url = "/apif/check-user";

                    try {
                      console.log('Checking user token from local storage');
                      await _this.apiService.sendPostRequest(url, payload, {}).toPromise();

                      document.cookie = `basicAuthHeader=${currentUser};path=/`;
                      _this.internalDataService.router.navigateByUrl(_this.internalDataService.router.url);
                    } catch (e) {
                      console.log(e);
                      // _this.internalDataService.router.navigateByUrl('/login-page');
                      window.location.href = '/login-page';

                    }
                  } else { window.location.href = '/login-page'; }
                }
                else { window.location.href = '/'; }
              }

              _this.dispatcherService.getDispatch(_this, 300);
            }
          },
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }


  getSpecificPermission(allowedRole: any, keyToSearch: any = 'roles') {
    let currentUser: any = localStorage.getItem('user');
    if (currentUser != null && currentUser != "") {

      try { currentUser = JSON.parse(currentUser) }
      catch (error) { console.log(error) }

      if (currentUser?.oauth != null && currentUser?.oauth != false && (keyToSearch != 'roles' || !currentUser?.checkOnlyScopes)) {
        return ![-1, null].includes(currentUser?.[keyToSearch]?.indexOf(allowedRole));
      }
    }
    return true;
  }


  getSpecificPermissions(allowedRoles, operator, keyToSearch = 'roles') {
    let currentUser: any = localStorage.getItem('user');
    if (currentUser != null && currentUser != "") {
      try {
        currentUser = JSON.parse(currentUser);
      } catch (error) { console.log(error) }

      if (currentUser?.oauth != null && currentUser?.oauth != false && (keyToSearch != 'roles' || !currentUser?.checkOnlyScopes)) {
        switch (operator) {
          case 'or':
          default:
            return allowedRoles?.some(x => currentUser?.[keyToSearch]?.includes(x));
          case 'and':
            return allowedRoles?.every(x => currentUser?.[keyToSearch]?.includes(x));
        }
      }
    }
    return true;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // machinesList
  private machinesListSource: BehaviorSubject<any> = new BehaviorSubject([]);
  machinesList = this.machinesListSource.asObservable();
  setMachinesList(value: []) {
    this.machinesListSource.next(value);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // machineSelected
  private machineSelectedSource: BehaviorSubject<any> = new BehaviorSubject([]);
  machineSelected = this.machineSelectedSource.asObservable();
  async setMachineSelected(value: any) {
    // reset selected machine
    this.machinesListSource.value.forEach(machine => { try { machine.selected = false } catch (error) { } });
    // set selected machine
    value.selected = true;
    this.machineSelectedSource.next(value);

    if (value.machineId != null) this.cacheService.set("machineId", value.machineId);

    // update enabledVariables
    let enabledVariable = { id: 'machineId', value: value.machineId };
    this.setEnabledVariable(enabledVariable);

    if (this.appConfig?.addCustomTranslations && value.machineId != null) await this.getCustomTranslations(value.machineId);
    if (this.appConfig?.addCustomVariables && value.machineId != null) await this.getCustomVariables(value.machineId);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // hierarchyElementSelected
  private hierarchyElementSelectedSource: BehaviorSubject<any> = new BehaviorSubject(null);
  hierarchyElementSelected = this.hierarchyElementSelectedSource.asObservable();
  async setHierarchyElementSelected(value: any) {
    this.hierarchyElementSelectedSource.next(value);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // hierarchyHistory
  private hierarchyHistorySource: BehaviorSubject<any> = new BehaviorSubject(null);
  hierarchyHistory = this.hierarchyHistorySource.asObservable();
  async setHierarchyHistory(value: any) {
    this.hierarchyHistorySource.next(value);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // cycleList
  private cyclesListSource: BehaviorSubject<any> = new BehaviorSubject([]);
  cyclesList = this.cyclesListSource.asObservable();
  setCyclesList(value: []) {
    this.cyclesListSource.next(value);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // cycleSelected
  private cycleSelectedSource: BehaviorSubject<any> = new BehaviorSubject([]);
  cycleSelected = this.cycleSelectedSource.asObservable();
  setCycleSelected(value: any) {
    // reset selected machine
    // this.cyclesListSource.value.forEach((cycle: any) => {
    //   cycle.selected = false;
    // });
    // // set selected cycle
    // value.selected = true;
    this.cycleSelectedSource.next(value);
    // update enabledVariables
    let enabledVariable = { id: 'cycleId', value: value };
    this.setEnabledVariable(enabledVariable);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // HMComponentSelected
  private HMComponentSelectedSource: BehaviorSubject<any> = new BehaviorSubject(null);
  HMComponentSelected = this.HMComponentSelectedSource.asObservable();
  setHMComponentSelected(value: any) {
    // set value
    this.HMComponentSelectedSource.next(value);
    // update enabledVariables
    let enabledVariable = { id: 'HMComponentId', value: value };
    this.setEnabledVariable(enabledVariable);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // get machine infos
  getMachineInfo(_this: any, machineId: any, machineProfiles: any, sources40F: any, tabsParent?: string, tabsAsObject?: any) {
    try {

      if (this.cacheService.get("assets")?.length > 0 && this.cacheService.get("machineId") != null) {
        _this.internalDataService.parseMachineInfos(_this, this.cacheService.get("assets"), machineId, tabsParent, tabsAsObject, true);
        return;
      }

      let url = '/apif/machines';
      if (_this.appConfig.requests != null && _this.appConfig.requests.machines != null) url = _this.appConfig.requests.machines;

      _this.apiService.sendGetRequest(url)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy()),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            try { this.setSelectedAssetsList(data?.body ?? []) }
            catch (error) { console.log(error) }

            _this.internalDataService.parseMachineInfos(_this, data.body, machineId, tabsParent, tabsAsObject);

          },
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  async parseMachineInfos(_this: any, assets: any, machineId: any, tabsParent: any, tabsAsObject: any = false, cached = false) {

    try {

      let ix = assets.findIndex((x: any) => x.machineId == machineId);
      if (ix != -1) {
        assets[ix].profile = await this.getMachineProfile(_this, machineId);
        if (assets[ix].profile == null || !Object.keys(assets[ix].profile).length) {
          let testError = {
            type: 0,
            status: 500,
            message: "No profile associated to assetId " + machineId,
          };
          _this.dispatcherService.getDispatch(_this, 301, testError);
          return;

        } else {

          _this.machine = await this.getMachineData(_this, assets[ix], assets[ix].profile, null);

          if (!cached) {
            await this.setMachineSelected(_this.machine);

            if (tabsParent != null) {
              try {
                if (_this.detailTabName != null) _this.tabs = this[tabsAsObject ? 'getPageTabsFromObject' : 'getPageTabs'](tabsParent, _this.tabName);
                else _this.tabs = this[tabsAsObject ? 'getPageTabsFromObject' : 'getPageTabs'](tabsParent);
              } catch (error) {
                console.log(error);
              }

              // Keep additional queryParams
              try {
                let standardQueryParams = ['sectionName', 'tabName', 'detailTabName'];
                let queryParamsToKeep = _this.filterService.removeKeysWithCustomRule(_this.route.snapshot.queryParams, (x) => !standardQueryParams.includes(x[0]));
                if (Object.keys(queryParamsToKeep)?.length > 0) _this.tabs?.forEach(tab => tab.urlQueryParams = { ...tab.urlQueryParams, ...queryParamsToKeep });
              } catch (error) { console.log(error) }
            }
          }

          _this.dispatcherService.getDispatch(_this, 300);
        }
      } else {
        let testError = {
          type: 0,
          status: 500,
          message: "No profile associated to assetId " + machineId,
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
        return;
      }
    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  filterIntervals(_this: any, validIntervals: any, hideElements: any = false) {
    let noAggr = [
      {
        id: "no_aggr",
        unit: null,
        label: _this.translate.instant("AGGREGATIONS.NO_AGGR"),
        value: null,
        show: true
      }
    ];
    _this.intervalAggregations.list = noAggr.concat(_this.intervalAggregations.list.filter((aggr: any) => validIntervals.includes(aggr.id)));
    this.getValidIntervalAggregations(_this, hideElements);
  }

  getValidIntervalAggregations(_this: any, hideElements: boolean = false, maxHoursRaw: any = 2, recommendedPoints: any = 50, maxPoints: any = 200) {

    try {

      if (_this.intervalAggregations != null && _this.intervalAggregations.selected != null) {

        let showDayAsNotAggregatedInStateTimeline = _this.machine?.profile?.showDayAsNotAggregatedInStateTimeline ?? _this.appConfig?.showDayAsNotAggregatedInStateTimeline;

        let deltaMilliseconds = moment(_this.interval?.end).diff(moment(_this.interval.start));
        let deltaHours = deltaMilliseconds / 1000 / 3600;
        let deltaDays = deltaHours / 24;

        let selectables: any = [];

        // if delta < 2 hours, select 'no_aggr' as default
        if (deltaHours < maxHoursRaw) {
          selectables.push('no_aggr');
          _this.intervalAggregations.selected = _this.intervalAggregations.list.find((aggr: any) => aggr.id === 'no_aggr');
        }
        // if delta < 2 days (50 hours), select 'hour' as default
        else if (deltaHours < recommendedPoints) {
          _this.intervalAggregations.selected = _this.intervalAggregations.list.find((aggr: any) => aggr.id === 'hour')
        }
        // if delta < 50 days, select 'day' as default
        else if (deltaDays < recommendedPoints) {
          _this.intervalAggregations.selected = _this.intervalAggregations.list.find((aggr: any) => aggr.id === 'day');
        }
        // if delta > 50 days, select 'week' as default
        else {
          _this.intervalAggregations.selected = _this.intervalAggregations.list.find((aggr: any) => aggr.id === 'week');
        }

        // if (deltaDays >= 1) {
        if (deltaDays <= 1.3 && showDayAsNotAggregatedInStateTimeline) {
          if (selectables.findIndex(x => x == 'no_aggr') == -1) selectables.push('no_aggr');
          if (selectables.findIndex(x => x == 'hour') == -1) selectables.push('hour');
          // if (deltaDays > 2 && maxNumberOfDaysToShowAsNotAggregatedInStateTimeline > 2) selectables.push('day');
          // if (deltaDays > 50 && maxNumberOfDaysToShowAsNotAggregatedInStateTimeline > 50) selectables.push('week');
        }
        // }

        if (!selectables.includes('no_aggr')) {

          if (deltaHours < maxPoints) selectables.push('hour');

          if ((deltaDays < maxPoints) && (deltaHours > 1)) selectables.push('day');

          if ((deltaDays > 1)) selectables.push('week');

        }

        // try {
        //   this.cacheService.set("intervalAggregation", _this.intervalAggregations.list.find((aggr: any) => aggr.id === _this.intervalAggregations.selected.id));
        // } catch (error) { 
        //   console.log(error);
        // }

        if (hideElements) _this.intervalAggregations.list.forEach((aggr: any) => aggr.hide = !selectables.includes(aggr.id));
      }
    } catch (error) {
      console.log(error);
    }

  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // get machine profile
  async getMachineProfile(_this: any, machineId: any) {
    try {

      let configProfile = _this.appConfigService.machineProfiles;
      let machineProfile: any = {};
      let machineProfileTotal: any = {};

      if (machineId != null && configProfile?.profiles?.length && configProfile?.profileList?.length) {

        // get profile id of machine
        let profile = configProfile.profiles.find((x: any) => x.machineIdList?.includes(machineId));

        // NEW STANDARD: Get profile from separate file (/apif/custom-file syntax)
        if (profile == null) {
          profile = await this.getMachineProfileFromFile(machineId);
          configProfile = _this.appConfigService.machineProfiles;
        }

        if (profile == null) profile = configProfile.profiles.find((x: any) => x.id == 'default');

        if (profile != null && profile.hasOwnProperty('id')) {

          machineProfileTotal = this.clonerService.deepClone(configProfile.profileList.find((x: any) => x.id == profile.id));
          machineProfile = machineProfileTotal.value;

          if (machineProfileTotal != null && machineProfileTotal.hasOwnProperty('referenceProfileId') && machineProfileTotal.referenceProfileId != null) {
            let referenceProfileIdx = configProfile.profileList.findIndex((x: any) => x.id == machineProfileTotal.referenceProfileId);
            if (referenceProfileIdx != -1) {
              machineProfile = Object.assign(configProfile.profileList[referenceProfileIdx].value, machineProfile);
            }
          }

          _this.aggregationsTime = [{
            id: "hour",
            unit: "hour",
            label: _this.translate.instant("INTERVAL.HOUR"),
            value: 1,
            show: true
          },
          {
            id: "day",
            unit: "day",
            label: _this.translate.instant("INTERVAL.DAY"),
            value: 1,
            show: true
          },
          {
            id: "week",
            unit: "week",
            label: _this.translate.instant("INTERVAL.WEEK"),
            value: 1,
            show: true
          },
          {
            id: "month",
            unit: "month",
            label: _this.translate.instant("INTERVAL.MONTH"),
            value: 1,
            show: true
          }
          ];

          let calendarPage = _this.appConfigService.sidenavItems.find((page: any) => page.id === 'planning-calendar');

          let isEnabledCalendar = this.filterService.isActiveCalendar(machineId);

          if (calendarPage && isEnabledCalendar) {
            _this.aggregationsTime.push({
              id: 'shift',
              unit: 'shift',
              label: _this.translate.instant("INTERVAL.SHIFT"),
              value: 1,
              show: true,
            });

          }

          if (machineProfile.aggregations != null) machineProfile.aggregations = this.addLabelFromTranslations(machineProfile.aggregations);
          if (machineProfile.subAggregations != null) machineProfile.subAggregations = this.addLabelFromTranslations(machineProfile.subAggregations);
          if (machineProfile.consumables != null) machineProfile.consumables = this.addLabelFromTranslations(machineProfile.consumables);
          if (machineProfile.kpis?.length) {
            machineProfile.kpis = this.addLabelFromTranslations(machineProfile.kpis);
            this.buildKPIConfig(machineProfile.kpis, machineId);
          }

          let aggregations = machineProfile.aggregations;
          if (_this.dashboardConfig && _this.dashboardConfig.aggregations) aggregations = _this.dashboardConfig.aggregations;

          if (aggregations != null && aggregations.length > 0) {
            // scope.aggrDropdown = machineProfile.aggregations[0];
            this.generateAggregationDialogs(_this, aggregations);
            // this.addAggregationsToOffsets(_this, machineProfile.aggregations);
            if (_this.aggregations != null && _this.aggregations.length > 0) {
              _this.aggregations.filter((x: any) => x.timeAggr).forEach((aggr: any) => {
                _this.aggregationsTime.push({
                  id: aggr.id,
                  unit: aggr.id,
                  label: aggr.label.capitalize(),
                  value: 1,
                  show: true,
                });
              });
            }

            _this.aggregationsUnparsed = this.clonerService.deepClone(_this.aggregations);
            _this.aggregationsToShowInDropdownUnparsed = this.clonerService.deepClone(_this.aggregationsToShowInDropdown);
          }

          let cachedIntervalAggr = this.cacheService.get("intervalAggregation");
          if (cachedIntervalAggr == null) this.cacheService.set("intervalAggregation", _this.aggregationsTime[1]);

          _this.intervalAggregations = {
            list: _this.aggregationsTime,
            selected: this.cacheService.get("intervalAggregation"),
          };

        }

      }

      return machineProfile;

    } catch (error) {
      console.log(error);
      return {};
    }
  }

  addLabelFromTranslations(data) {

    try {
      let parsedList = (data ?? [])?.reduce((acc, val) => {
        val.label = val?.translations?.[this.translate.currentLang] ?? val.label;
        acc.push(val);
        return acc;
      }, []);

      return parsedList;
    } catch (error) {
      console.log(error);
      return data;
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // build machine payload
  buildMachinePayload(machine: any) {
    try {
      let payload: any = {};
      if (machine != null &&
        machine.hasOwnProperty('profile') &&
        machine.profile != null) {

        let timeStates: any = {
          other: []
        };
        let timeStatesTimeline: any = {
          other: []
        };
        // categories
        if (machine.profile.hasOwnProperty('categories') &&
          machine.profile.categories != null &&
          machine.profile.categories.length > 0) {
          machine.profile.categories.forEach((cat: any) => timeStates[cat.backendValue] = []);
          machine.profile.categories.forEach((cat: any) => timeStatesTimeline[cat.backendValueStateTimeline] = []);
          // time states
          if (machine.profile.hasOwnProperty('timeStates') &&
            machine.profile.timeStates != null &&
            machine.profile.timeStates.length > 0) {
            machine.profile.timeStates.forEach((timestate: any) => {
              let cat = machine.profile.categories.find((x: any) => x.id == timestate.category);
              if (cat != null) {

                let _statesPrefix = null;

                if (this.appConfig.MAT2) _statesPrefix = machine.profile.timeStatesPrefix;
                else _statesPrefix = machine.profile.timeStatesSuffix;

                let statesPrefix: string = '';
                if (typeof _statesPrefix == 'string') statesPrefix = _statesPrefix;

                let state = `${statesPrefix}${timestate.state}`;

                timeStates[cat.backendValue].push(state);
                timeStatesTimeline[cat.backendValueStateTimeline].push(state);
              }
            });
          }
        }

        payload = {
          states: timeStates,
          statesTimeline: timeStatesTimeline,
          consData: machine.profile.consParams,
          cmmsConfig: machine.profile.cmmsConfig ?? {},
          settings: {},
          aggregations: {}
        };

        if (this.appConfig.MAT2) {

          let customKpis = Object.assign({}, machine.profile.customKpis ?? {});

          if (!customKpis.remoteMonitoring) {
            customKpis.remoteMonitoring = {};
          }
          // "customKpis.remoteMonitoring" dovrebbe essere un oggetto con due chiavi:
          // - variables
          // - lastValuesVariables
          else if (Array.isArray(customKpis.remoteMonitoring)) {
            let _variables = customKpis.remoteMonitoring;
            customKpis.remoteMonitoring = {
              variables: _variables,
              lastValuesVariables: []
            };
          }
          // "customKpis.remoteMonitoring" come oggetto, controllare che ci siano le chiavi "variables" e "lastValuesVariables"
          else {
            if (customKpis.remoteMonitoring.variables == null) customKpis.remoteMonitoring.variables = [];
            if (customKpis.remoteMonitoring.lastValuesVariables == null) customKpis.remoteMonitoring.lastValuesVariables = [];
          }

          payload.definitions = machine.profile.definitions ?? {};
          payload.constantVariables = machine.profile.constantVariables ?? {};
          payload.customKpis = customKpis;
          payload.defaultKpis = machine.profile.defaultKpis ?? [];
          payload.scrapColumns = machine.profile.scrapColumns ?? {};
          payload.mappings = machine.profile.mappings;
        }
        else {
          payload.stateTypes = timeStates;
          payload.oeeKpis = Object.assign({}, machine.profile.oeeKpis);
          payload.actualStatus = machine.profile.actualStatus;

          if (machine.profile.hasOwnProperty('homepageVariables') && machine.profile.homepageVariables != null &&
            Array.isArray(machine.profile.homepageVariables)) {
            payload.homepageVariables = Object.assign([], machine.profile.homepageVariables);
          }

        }

        if (machine.profile.hasOwnProperty('aggregations') && machine.profile.aggregations != null &&
          Array.isArray(machine.profile.aggregations) && machine.profile.aggregations.length > 0) {
          // payload.aggregations = machine.profile.aggregations.map(aggr => aggr.id);
          if (this.appConfig.MAT2) {

            machine.profile.aggregations.forEach(aggr =>
              payload.aggregations[aggr.id] = {
                type: aggr?.type ?? 'discrete',
                source: aggr.calendar ? 'calendar' : null
              }
            );

          }
          else {
            machine.profile.aggregations.forEach(aggr => payload.aggregations[aggr.id] = aggr?.type ?? 'discrete');
          }

        }

        if (machine.profile.hasOwnProperty('productionConfig') && machine.profile.productionConfig != null) {
          payload.performanceData = {
            invert: machine.profile.productionConfig.invert,
            multiplier: machine.profile.productionConfig.timeUnit.value,
          };
        }

        if (machine.settings != null && Object.keys(machine.settings).length) payload.settings.dynamics = machine.settings;
        if (machine.profile?.settings != null) payload.settings.statics = machine.profile.settings;

        if (this.appConfig?.customKeysFromProfileToAdd?.length > 0) this.appConfig?.customKeysFromProfileToAdd.forEach(conf => payload[conf] = this.clonerService.deepClone(machine.profile[conf]));

        // Add custom translations
        let c_customTranslations = this.cacheService.get("customTranslations");
        if (c_customTranslations != null && c_customTranslations?.machineId == machine.machineId) payload.enabledParameters = this.buildCustomTranslationsPayload(c_customTranslations?.value);

        if (this.appConfig?.addEnableParametersInMachinePayload) {
          // Add custom variables enabled
          let c_customVariables = this.cacheService.get("customVariables");
          if (c_customVariables != null && c_customVariables?.machineId == machine.machineId) payload.enabledParameters = this.buildCustomVariablesPayload(c_customVariables?.value);
        }

        // Add enabled calendar flag
        payload.enabledCalendar = this.filterService.isActiveCalendar(machine.machineId);

      }
      return payload;

    } catch (error) {
      console.log(error);
      return {};
    }
  }

  getCurrentAggrTranslation = function (_this: any, aggr: any, aggregations: any) {
    try {
      if (aggregations != null && aggregations.length > 0) {
        let ix = aggregations.findIndex((x: any) => x.id == aggr);
        if (ix != -1) {
          if (_this.translate.instant(aggregations[ix].label) != (aggregations[ix].label)) {
            return _this.translate.instant(aggregations[ix].label);
          } else {
            return aggr;
          }
        }
      }
    } catch (error) {
      console.log(error);
      return aggr;
    }
  };

  generateAggregationDialogs = (_this: any, aggregations: any) => {

    _this.aggregations = [];
    if (aggregations != null && aggregations.length > 0) {
      aggregations.forEach((aggr: any) => {

        let label = null;
        if (aggr.translations != null) label = aggr.translations?.[this.translate.currentLang];
        if (label == null) label = _this.translate.instant(aggr.label);

        let aggrDefault = {
          label: label,
          selectionLabel: _this.translate.instant("GLOBAL.AGGREGATION_SELECTION", {
            aggrLabel: label
          }).capitalize(),
          notFoundLabel: _this.translate.instant("GLOBAL.AGGREGATION_NOT_FOUND", {
            aggrLabel: label
          }).capitalize(),
          list: [],
          selected: []
        };

        _this.aggregations.push({ ...aggr, ...aggrDefault });

      });

      if (_this.internalDataService?.appConfig?.MAT2) {

        let isEnabledCalendar = this.filterService.isActiveCalendar(_this.machineId);
        let isCalendarPage = this.calendarPageSource.getValue();

        if (isEnabledCalendar && isCalendarPage) {
          _this.aggregations.forEach((aggr: any) => {

            if (aggr.calendar && !aggr.id.startsWith('calendar.')) {
              aggr.id = `calendar.${aggr.id}`;
            }
          })
        } else {
          _this.aggregations = _this.aggregations.filter(x => !x.calendar);

        }
      }

      _this.aggregationsToShowInDropdown = this.clonerService.deepClone(_this.aggregations);
      _this.aggregations = _this.aggregations.filter(x => !x.hideEverywhereExceptDropdown);
      _this.aggregationChangeovers = _this.aggregations.filter((aggr: any) => aggr.changeover);
      this.buildAggregationsPayload(_this);
    }
  };

  forceAggregationList = function (_this: any, aggregations: any, id?: any) {
    _this.intervalAggregations.list = aggregations;
    let cachedIntervalAggr = _this.cacheService.get("intervalAggregation");
    if (cachedIntervalAggr != null) {
      let idx: any = aggregations.findIndex((x: any) => x.id == cachedIntervalAggr.id);
      if (idx == -1) {
        console.log("No int aggr set in cache");
        _this.cacheService.set("intervalAggregation", aggregations.find(x => x.id == 'day') ?? aggregations[1]);
        _this.intervalAggregations.selected = aggregations.find(x => x.id == 'day') ?? aggregations[1];
      }
      if (id != null) {
        _this.cacheService.set("intervalAggregation", aggregations.find(x => x.id == id))
        _this.intervalAggregations.selected = aggregations.find(x => x.id == id)
      }
    }
    if (!_this.intervalAggregations.selected) _this.intervalAggregations.selected = _this.intervalAggregations.list.find(x => x.id == 'day') ?? _this.intervalAggregations.list[1] ?? _this.intervalAggregations.list[0];
  }

  buildAggregationsPayload = function (_this: any) {
    _this.aggregationsPayload = {};
    if (_this.aggregations != null && _this.aggregations.length > 0) {
      _this.aggregations.forEach((aggr: any) =>
        _this.aggregationsPayload[aggr.id] = (aggr.selected.length > 0) && (aggr.selected.length != aggr.list.length) ? aggr.selected : null);
    }
  };

  // addAggregationsToOffsets = function (_this: any, aggregations: any) {
  //   if (_this.offset != null && _this.offset.hasOwnProperty('list') && _this.offset.list != null && _this.offset.list.length > 0 &&
  //     aggregations.filter((x: any) => x.timeAggr).length > 0) {
  //     aggregations.filter((x: any) => x.timeAggr).forEach((aggr: any) => {
  //       console.log(aggr);

  //       _this.offset.list.push({
  //         id: aggr.id,
  //         label: _this.translate.instant("GLOBAL.CURRENT_AGGREGATION", {
  //           aggrLabel: _this.translate.instant(aggr.label)
  //         }).toUpperCase(),
  //       });
  //     });
  //   }
  // };

  createAggrList(_this: any, data: any, aggrId: any) {
    try {

      if (data != null) {

        let ix = _this.aggregations.findIndex((x: any) => x.id == aggrId);
        if (ix != -1) {
          let copyList: any = this.clonerService.deepClone(data);
          _this.aggregations[ix].list = copyList?.sort();
          _this.aggregations[ix].selected = _this.aggregations[ix].selected.length == 0 ? this.clonerService.deepClone(data) : _this.aggregations[ix].selected;
          _this.paramBox = this.clonerService.deepClone(_this.aggregations[ix]);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // get machine data
  async getMachineData(_this: any, machineData: any, machineProfile?: any, sources40f?: any) {
    try {

      // get machine profile
      if (!machineProfile || !Object.keys(machineProfile).length) {
        if (machineData.hasOwnProperty('machineId') && machineData.machineId != null) {
          machineProfile = await this.getMachineProfile(_this, machineData.machineId);
        }
      }

      try {

        if (_this.availableMachines != null && Array.isArray(_this.availableMachines) && _this.availableMachines?.length) {
          let list = _this.availableMachines?.map(x => x?.id ?? x);
          let selected = _this.availableMachines.find(x => x?.selected)?.id ?? list[0];
          let disabledList = (_this.availableMachines.filter(x => x?.disabled) ?? [])?.map(x => x?.id);

          _this.availableMachines = {
            list: list,
            selected: selected,
            disabledList: disabledList,
          };

        } else {

          // Delete available machines (used in state timeline advanced)
          if (_this.excludeAvailableMachines) _this.availableMachines = null;

          // Create automatic available machine list
          else {
            if (!_this.excludeLine && !machineData.excludeLine) {
              _this.availableMachines = {
                list: ['Line'].concat(Object.keys(machineData.machines)),
                selected: 'Line' //machineData.machineReference
              };
            }
            else if (Object.keys(machineData.machines).length > 0) {
              let availableMachines = Object.keys(machineData.machines);
              _this.availableMachines = {
                list: availableMachines,
                selected: availableMachines[0]//machineData.machineReference
              }
            }
          }
        }

      } catch (error) {
      }

      // set data based on profile
      if (!Object.keys(machineProfile).length) {
        machineData.noProfile = true;
      } else {
        // profile
        machineData.profile = machineProfile;

        if (this.appConfig.MAT2) {
          if (machineData.profile.productionConfig?.type) {
            let ptype = machineData.profile.productionConfig?.type;
            if (ptype == 'duration') {
              machineData.profile.productionConfig.invert = false;
            } else {
              machineData.profile.productionConfig.invert = true;
            }
          }
        }

        // name
        machineData.machineName = machineData.machineName ?? machineData.name ?? machineData.label;

        let sources40F = _this.appInfo.sources40F ?? 'assets/images/';

        // image
        machineData.image = (_this.appInfo.sources40F ?? 'assets/config/') + machineData.machineId + "/homepage.png";
        machineData.imageProfile = sources40F + (machineProfile.machineImage ?? 'machine-default.png');

        // stateUI
        if (machineProfile.actualStatus) this.parseStateUI(machineData);
        if (machineProfile?.homepage?.config?.image?.location) this.parseLocation(machineData);

      }

      return machineData;

    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  handleMissingImage(event: Event, machineData: any) {
    if (!machineData.loading && (machineData.imageRetry == null || machineData.imageRetry < 40)) {
      if (machineData.imageRetry) machineData.imageRetry++;
      else machineData.imageRetry = 1;
      (event.target as HTMLImageElement).src = machineData.imageProfile
    }
  }

  // parse state ui of machine
  parseStateUI(machine: any, returnValue: boolean = false) {
    try {

      let connectionString = "neverConnected";
      let stateConf = machine.profile.lineStates != null ? 'lineStates' : 'timeStates';
      if (machine?.profile?.[stateConf] == null) {
        machine.stateUI = null;
        machine.connectionString = connectionString;
        return;
      }

      let stateUI = {
        class: "md-gray",
        title: this.translate.instant('MACHINE_STATES.UNDEFINED'),
        icon: {
          "icon": "remove",
          "type": "icon"
        }
      };

      if (machine.profile) {
        let state = machine.profile[stateConf]?.find((x: any) => x.state == machine.state);
        connectionString = "connected";
        if (state != null) {
          stateUI.class = state.class;
          stateUI.title = this.translate.instant(state.value);
          stateUI.icon = state.icon;
        }
      }

      if (machine.notConnected) {
        connectionString = "notConnected";
        stateUI = {
          class: "md-red",
          title: this.translate.instant('MACHINE_STATES.NOT_CONNECTED'),
          icon: {
            icon: "warning",
            type: "icon"
          }
        };
      }

      if (machine.neverConnected) {
        connectionString = "neverConnected";
        stateUI = {
          class: "md-gray",
          title: this.translate.instant('MACHINE_STATES.NEVER_CONNECTED'),
          icon: {
            icon: "remove",
            type: "icon"
          }
        };
      }

      if (returnValue) return stateUI;
      else {
        machine.stateUI = stateUI;
        machine.connectionString = connectionString;
      }
      return;

    } catch (error) {
      console.log(error);
      return;
    }

  }

  getBarColor(value: number, multiplier: any = 1, revert: boolean = false, thresholds?: any): string {
    if (value == -1) {
      return 'transparent';
    }
    let parsedValue: any = value != null ? (revert ? (100 / multiplier) - value : value) : null;
    parsedValue = parsedValue != null ? parsedValue * multiplier : null;
    let progressBarThresholds = thresholds != null ? thresholds : this.progressBarThresholds
    if (progressBarThresholds != null) {
      for (let i = 0; i <= progressBarThresholds.length; i++) {
        let start = i == 0 ? 0 : progressBarThresholds[i - 1].threshold
        if (i < progressBarThresholds.length && parsedValue >= start && parsedValue < progressBarThresholds[i].threshold) return progressBarThresholds[i].color
        else if (i == progressBarThresholds.length) return progressBarThresholds[i - 1].color
      }
    } else {
      if (parsedValue >= 0 && parsedValue < 10) {
        return '#FF5757';
      } else if (parsedValue >= 10 && parsedValue < 20) {
        return '#FF7F57';
      } else if (parsedValue >= 20 && parsedValue < 30) {
        return '#FAA63E';
      } else if (parsedValue >= 30 && parsedValue < 40) {
        return '#FAC63E';
      } else if (parsedValue >= 40 && parsedValue < 50) {
        return '#FADC3E';
      } else if (parsedValue >= 50 && parsedValue < 60) {
        return '#FAF43E';
      } else if (parsedValue >= 60 && parsedValue < 70) {
        return '#D2FA3E';
      } else if (parsedValue >= 70 && parsedValue < 80) {
        return '#C5EB5C';
      } else if (parsedValue >= 80 && parsedValue < 90) {
        return '#8BE362';
      } else if (parsedValue >= 90 && parsedValue <= 100) {
        return '#62E39D';
      } else {
        return '#40A4F4';
      }
    }
  }

  getKPIBarColor(value: any) {
    switch (value) {
      case 'globalOee':
      case 'globalOeeMA':
      case 'globalOeeP':
      case 'G':
        return '#929292';
      case 'oee':
      case 'oeeMA':
      case 'oeeP':
      case 'O':
        return '#444444';
      case 'availability':
      case 'availabilityMA':
      case 'availabilityP':
      case 'A':
      default:
        return '#a900ff';
      case 'performance':
      case 'performanceMA':
      case 'performanceP':
      case 'P':
        return '#00f0fb';
      case 'quality':
      case 'qualityMA':
      case 'qualityP':
      case 'Q':
        return '#0074ff';
    }
  }

  getHealthColor(value: any) {
    if (value >= 0 && value < 50) {
      return '#FF5757';
    } else if (value >= 50 && value < 75) {
      return '#FAA63E';
    } else if (value >= 75) {
      return '#62E39D';
    } else {
      return '#FFFFFF';
    }
  };

  public defaultPlotlyColors = [
    '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#008FFB',
    "#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D", "#FF9800",
    "#33B2DF", "#546E7A", "#D4526E", "#13D8AA", "#A5978B",
    "#4ECDC4", "#C7F464", "#81D4FA", "#546E7A", "#FD6A6A",
    "#2B908F", "#F9A3A4", "#90EE7E", "#FA4443", "#69D2E7",
    "#449DD1", "#F86624", "#EA3546", "#662E9B", "#C5D86D",
    "#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044",
    "#662E9B", "#F86624", "#F9C80E", "#EA3546", "#43BCCD",
    "#5C4742", "#A5978B", "#8D5B4C", "#5A2A27", "#C4BBAF",
    "#A300D6", "#7D02EB", "#5653FE", "#2983FF", "#00B1F2",
    '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd',
    '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf',
  ];

  public defaultPlotlyColorsDark = [
    '#BB86FC', '#3700B3', '#03DAC6', '#6200EE', '#89C8FD',
    '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#008FFB',
    "#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D", "#FF9800",
    "#33B2DF", "#546E7A", "#D4526E", "#13D8AA", "#A5978B",
    "#4ECDC4", "#C7F464", "#81D4FA", "#546E7A", "#FD6A6A",
    "#2B908F", "#F9A3A4", "#90EE7E", "#FA4443", "#69D2E7",
    "#449DD1", "#F86624", "#EA3546", "#662E9B", "#C5D86D",
    "#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044",
    "#662E9B", "#F86624", "#F9C80E", "#EA3546", "#43BCCD",
    "#5C4742", "#A5978B", "#8D5B4C", "#5A2A27", "#C4BBAF",
    "#A300D6", "#7D02EB", "#5653FE", "#2983FF", "#00B1F2",
  ];

  public defaultBreakdownsColors = [
    "#FF5757", "#FAC63E", "#FAF43E", "#8BE362", "#1C0221",
    "#7B5E7B", "#938274", "#E9EB87", "#B9F18C", "#2176AE",
    "#57B8FF", "#B66D0D", "#FBB13C", "#FE6847", "#89023E",
    "#CC7178", "#FFD9DA", "#F3E1DD", "#C7D9B7", "#00B1F2",
    '#BB86FC', '#3700B3', '#03DAC6', '#6200EE', '#89C8FD',
    '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#008FFB',
    "#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D", "#FF9800",
    "#33B2DF", "#546E7A", "#D4526E", "#13D8AA", "#A5978B",
    "#4ECDC4", "#C7F464", "#81D4FA", "#546E7A", "#FD6A6A",
    "#2B908F", "#F9A3A4", "#90EE7E", "#FA4443", "#69D2E7",
    "#449DD1", "#F86624", "#EA3546", "#662E9B", "#C5D86D",
    "#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044",
    "#662E9B", "#F86624", "#F9C80E", "#EA3546", "#43BCCD",
    "#5C4742", "#A5978B", "#8D5B4C", "#5A2A27", "#C4BBAF",
    "#A300D6", "#7D02EB", "#5653FE", "#2983FF", "#00B1F2",
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // homepageData
  private homepageDataSource: BehaviorSubject<any> = new BehaviorSubject([]);
  homepageData = this.homepageDataSource.asObservable();
  setHomepageData(value: {}) {
    this.homepageDataSource.next(value);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // homepageCardReload
  private homepageCardReloadSource: BehaviorSubject<any> = new BehaviorSubject(null);
  homepageCardReload = this.homepageCardReloadSource.asObservable();
  setHomepageCardReload(value: {}) {
    this.homepageCardReloadSource.next(value);
  }


  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // get dashboard
  async getDashboard(_this: any, machineId: any, pageName: any, skipError: any = false, dashboardConfigName: any = 'dashboardConfig') {

    try {

      let url = '/apif/get-custom-file';
      let dashboardName = 'dashboard-' + pageName + '.json';

      let query: any = {
        fromWeb: _this.appInfo?.sources40F != null ? 1 : 0,
        source: _this.appInfo?.sources40F ?? '/assets/config/',
      };

      let profileId = null;
      try { profileId = _this.machineProfiles.profiles.find(x => x?.machineIdList?.includes(machineId)).id }
      catch (error) { }

      if (machineId != null) query.machineId = machineId;
      if (dashboardName != null) query.fileName = dashboardName;
      if (profileId != null) query.profileId = profileId;

      _this.apiService.sendGetRequest(url, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          if (!skipError) {
            error = {
              ...error, ...{
                error: {
                  "customMessage": "Missing file: " + dashboardName,
                  "customCode": "404",
                }
              }
            }
            return _this.internalDataService.parseStandardHTTPError(_this, error);
          } else {
            _this.dispatcherService.getDispatch(_this, 300);
            return [];
          }
        }))
        .subscribe(
          (data: any) => {

            _this[dashboardConfigName ?? 'dashboardConfig'] = data.body;
            _this.isDashboard = true;

            // if(_this.dashboardConfig.hasOwnProperty('widgets') && _this.reelLabel != null && _this.reelId != null) {

            //   _this.dashboardConfig.widgets.forEach(widget => {
            //     if(widget.type == 'ff-info-gauge') {
            //       if (widget.hasOwnProperty('config') && widget.config.hasOwnProperty('gaugeConfig')) {
            //         widget.config.gaugeConfig.valueLabel = _this.reelLabel
            //       }

            //       if (widget.hasOwnProperty('config') && widget.config.hasOwnProperty('infoConfig')) {
            //         let objKeys = Object.keys(widget.config.infoConfig)
            //         objKeys.forEach((key: any) => {
            //           if (widget.config != null && widget.config.infoConfig != null && widget.config.infoConfig[key] != null) {
            //             widget.config.infoConfig[key].forEach((element: any) => {
            //               if (Array.isArray(element.variable)) element.variable = element.variable.join(_this.reelId, "")
            //             });
            //           }
            //         })
            //       }
            //     }
            //   })
            // }

            try { _this[(dashboardConfigName ?? 'dashboardConfig') + 'Unparsed'] = _this.internalDataService.parseDashboardData(_this, data.body, dashboardName) }
            catch (error) { console.log(error) }

            _this.dispatcherService.getDispatch(_this, 300);

          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  async getMachineProfileFromFile(machineId: any) {

    try {

      let url = '/apif/get-custom-file';
      let fileName = `machine-profile.json`;

      let query: any = {
        fromWeb: this.appInfo?.sources40F != null ? 1 : 0,
        source: this.appInfo?.sources40F ?? '/assets/config/',
        fileName: fileName
      };

      if (machineId != null) query.machineId = machineId;

      try {
        let data = await this.apiService.sendGetRequest(url, query).toPromise();

        let profileIdConfig = { machineIdList: [machineId], id: machineId };
        if (this.appConfigService.machineProfiles.profiles != null && this.appConfigService.machineProfiles.profiles.find(x => x.id == machineId) == null) {
          this.appConfigService.machineProfiles.profiles.push(profileIdConfig);
        }
        if (this.appConfigService.machineProfiles.profileList != null && this.appConfigService.machineProfiles.profileList.find(x => x.id == machineId) == null) {
          this.appConfigService.machineProfiles.profileList.push({ value: data.body, id: machineId });
        }

        return profileIdConfig;
      } catch (error) {
        return null;
      }

    } catch (error) { console.log(error) }
  }

  parseDashboardData(_this: any, data: any, dashboardName: any = null) {
    if (dashboardName != null) _this.dashboardNameComplete = dashboardName;
    try {
      if (data != null && Object.keys(data).length > 0) {

        // Override default properties with dashboardData keys
        try { Object.entries(data).filter(kv => _this.hasOwnProperty(kv[0])).forEach(kv => _this[kv[0]] = kv[1]) } catch (error) { }

        return _this.clonerService.deepClone(data);
      }
      return {};
    } catch (error) {
      console.log(error);
      return {};
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET KPI CONFIGS
  async getKPIConfigs(_this: any, machineId: any) {

    try {

      let url = '/apif/get-custom-file';
      let fileName = 'parameters.json';

      let profileId = null;
      try { profileId = _this.machineProfiles.profiles.find(x => x?.machineIdList?.includes(machineId)).id }
      catch (error) { console.log(error) }

      let profile = null;
      try { profile = _this.machineProfiles.profileList.find(x => x.id == profileId)?.value }
      catch (error) { console.log(error) }

      if (_this.internalDataService?.appConfig?.MAT2) {
        if (true) {
          _this.internalDataService.parseKpiParameters(_this, profile, { selected: null, kpiParameters: { list: profile.kpiAnalytics } });
          _this.dispatcherService.getDispatch(_this, 300);
          return;
        }
      }
      else {
        let c_params = this.cacheService.get(`${machineId}-${fileName}`);
        if (c_params != null) {
          _this.internalDataService.parseKpiParameters(_this, profile, c_params);
          _this.dispatcherService.getDispatch(_this, 300);
          return;
        }
      }

      // KPI config from file
      let query: any = {
        fromWeb: _this.appInfo?.sources40F != null ? 1 : 0,
        source: _this.appInfo?.sources40F ?? '/assets/config/',
      };

      if (machineId != null) query.machineId = machineId;
      if (fileName != null) query.fileName = fileName;
      if (profileId != null) query.profileId = profileId;

      _this.apiService.sendGetRequest(url, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {
          error = {
            ...error, ...{
              error: {
                "customMessage": "Missing file: default-" + fileName,
                "customCode": "404",
              }
            }
          }
          return _this.internalDataService.parseStandardHTTPError(_this, error);
        }))
        .subscribe(
          (data: any) => {

            // console.log(data.body);

            if (_this.internalDataService?.appConfig?.MAT2) {
              if (profile != null) {
                let kpiList: any = (data.body?.kpiParameters?.list ?? [])?.reduce((acc, val) => {
                  if (val?.productionUnit != null) val.unit = _this.filterService.getProductionUnit(val?.productionUnit, profile?.productionConfig);
                  acc.push(val);
                  return acc;
                }, []);

                try { data.body.kpiParameters.list = kpiList }
                catch (error) { console.log(error) }
              }

              _this.parameters = data.body;
              _this.parametersUnparsed = _this.clonerService.deepClone(data.body);

            } else {
              _this.internalDataService.parseKpiParameters(_this, profile, data.body);
            }
            _this.dispatcherService.getDispatch(_this, 300);

          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  parseKpiParameters(_this, profile, data) {

    if (profile != null) {
      let kpiList: any = (data?.kpiParameters?.list ?? [])?.reduce((acc, val) => {
        if (val?.productionUnit != null) val.unit = _this.filterService.getProductionUnit(val?.productionUnit, profile?.productionConfig);
        if (val?.translations?.[this.translate.currentLang] != null) val.label = val?.translations?.[this.translate.currentLang];
        acc.push(val);
        return acc;
      }, []);

      try { data.kpiParameters.list = kpiList }
      catch (error) { console.log(error) }
    }

    _this.parameters = data;
    _this.parametersUnparsed = _this.clonerService.deepClone(data);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET COMPONENTS CONFIG
  getComponentsConfig(_this: any, machineId: any, machineRefId: any, componentId: any, setBreadcrumb: any = false) {
    try {

      let url = '/apif/get-custom-file';
      let fileName = 'componentsConfig.json';

      let profileId = null;
      try { profileId = _this.machineProfiles.profiles.find(x => x?.machineIdList?.includes(machineId)).id }
      catch (error) { console.log(error) }

      let profile = null;
      try { profile = _this.machineProfiles.profileList.find(x => x.id == profileId)?.value }
      catch (error) { console.log(error) }

      let query: any = {
        fromWeb: _this.appInfo?.sources40F != null ? 1 : 0,
        source: _this.appInfo?.sources40F ?? '/assets/config/',
      };

      if (machineId != null) query.machineId = machineId;
      if (fileName != null) query.fileName = fileName;
      if (profileId != null) query.profileId = profileId;

      _this.apiService.sendGetRequest(url, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          error = {
            ...error, ...{
              error: {
                "customMessage": "Missing file: " + fileName,
                "customCode": "404",
              }
            }
          }
          return _this.internalDataService.parseStandardHTTPError(_this, error);

        }))
        .subscribe(
          (data: any) => {

            let componentsConfig: any = _this.clonerService.deepClone(data.body);

            let lineMode = Object.keys(_this.machine.machines ?? {})?.length > 0;
            if (lineMode) {
              if (componentsConfig != null && Array.isArray(componentsConfig) && componentsConfig.length > 0) {
                componentsConfig.filter((machine: any) => !machine.show).forEach((machine: any) => machine.components.forEach((comp: any) => comp.show = false));
              }
            }
            else {
              componentsConfig = componentsConfig.reduce((acc, comp) => {
                comp.componentName = this.parseComponentsLabel(comp.componentId, null, machineId ?? this.cacheService.get("machineId"));
                acc.push(comp);
                return acc;
              }, []);
            }

            _this.componentsConfig = componentsConfig;

            if (componentId != null) _this.componentConfig = this.getComponentConfig(componentsConfig, machineRefId, componentId);

            if (_this.componentConfig != null && setBreadcrumb) {
              try {
                let newBreadcrumb: any = _this.clonerService.deepClone(_this.breadcrumb);

                if (lineMode) {
                  newBreadcrumb[3] = _this.componentConfig.machineName;
                  newBreadcrumb[4] = _this.componentConfig.componentName;
                }
                else {
                  newBreadcrumb[3] = _this.componentConfig.componentName;
                }
                _this.breadcrumb = newBreadcrumb;
                _this.internalDataService.setBreadcrumb(newBreadcrumb);
              } catch (error) { console.log(error) }
            }

            _this.dispatcherService.getDispatch(_this, 300);

          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  getHMBreadcrumb(_this, value) {

    let newBreadcrumb: any = _this.clonerService.deepClone(_this.breadcrumb);
    if (value.machineRefId != null) {
      newBreadcrumb[3] = value.machineRefId;
      newBreadcrumb[4] = value.componentId;
    }
    else {
      newBreadcrumb[3] = value.componentId;
    }

    return newBreadcrumb;

  }

  getComponentConfig(componentsConfig: any, machineRefId: any, componentId: any, machineId?: any) {

    let _appInfo = this.appConfigService.getAppInfo;
    let imagesSource = _appInfo.sources40F ?? 'assets/images/';

    let multiMachineMode = machineRefId != null;

    let returnConfig: any = {
      componentId: componentId,
      machineRefId: machineRefId,
      imagePath: imagesSource + 'components/default.png',
    };

    // Handling Line --> multi machine mode
    if (multiMachineMode) {

      if (machineRefId != null && componentId != null) {
        const checkTranslPipe = new CheckTranslationPipe(this.translate);

        let compConfig: any = null;
        let machineConfig: any = null;

        let machineIdx = componentsConfig.findIndex((machine: any) => machine.machineId == machineRefId);
        if (machineIdx != -1 && componentsConfig[machineIdx] != null && componentsConfig[machineIdx].components != null) {

          machineConfig = componentsConfig[machineIdx];
          returnConfig.machineName = checkTranslPipe.transform((machineConfig.label), machineConfig.machineId, false);

          let componentIdx = componentsConfig[machineIdx].components.findIndex((component: any) => component.componentId == componentId);
          if (componentIdx != -1) {

            compConfig = componentsConfig[machineIdx].components[componentIdx];

            if (compConfig != null) {
              if (compConfig.image != null) returnConfig.imagePath = imagesSource + 'components/' + compConfig.image;
              returnConfig.componentName = checkTranslPipe.transform((compConfig.label), compConfig.componentId, false);
            }
          }
        }

      }
    }

    else {

      let compConfig = componentsConfig.find((comp: any) => comp.componentId == componentId);

      // Component is configured inside componentsConfig
      if (compConfig != null) {
        if (compConfig.image != null) returnConfig.imagePath = imagesSource + 'components/' + compConfig.image;
      }

      returnConfig.componentName = this.parseComponentsLabel(componentId, null, machineId ?? this.cacheService.get("machineId"));

    }

    return this.clonerService.deepClone(returnConfig);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET KPI CONFIGS
  getVariablesConfig(_this: any, machineId: any, machineReference: any, componentId?: any, showTraces?: boolean, processes?: any, hm?: any, timeSeries?: any, setBreadcrumb?: any, isLiveStreaming?: any) {
    try {

      // console.log("getVariablesConfig");

      const sources40F = _this.appInfo.sources40F ?? 'assets/config/';

      let url = sources40F + machineId + '/';

      let fileName = '';
      if (machineReference != null) fileName += machineReference + '-';
      fileName += 'variablesConfig';
      if (hm != null) fileName += 'HM';
      fileName += '.json';

      url += fileName;
      let correctFileName = `${machineId}/${fileName}`;

      // Variables config generated automatically with variables.json file
      if (this.cacheService.get(fileName)?.value != null &&
        this.cacheService.get(fileName)?.assetId == machineId) {

        _this.internalDataService.parseVariablesConfig(_this, machineId, this.cacheService.get(fileName)?.value, fileName, componentId, showTraces, processes, timeSeries, setBreadcrumb, isLiveStreaming);
      }

      // Variables config from file
      else {
        // url types:
        // 1) machineReference == null, hm == null:
        //    '<machineId>/variablesConfig.json'
        // 2) machineReference != null, hm == null:
        //    '<machineId>/<machineReference>-variablesConfig.json'
        // 3) machineReference == null, hm != null:
        //    '<machineId>/variablesConfigHM.json'
        // 4) machineReference != null, hm != null:
        //    '<machineId>/<machineReference>-variablesConfigHM.json'

        _this.apiService.sendGetRequest(url).pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {
            error = {
              ...error, ...{
                error: {
                  "customMessage": "Missing file: " + correctFileName,
                  "customCode": "404",
                }
              }
            }
            return _this.internalDataService.parseStandardHTTPError(_this, error);
          }))
          .subscribe(
            (data: any) => {

              _this.internalDataService.parseVariablesConfig(_this, machineId, data?.body, fileName, componentId, showTraces, processes, timeSeries, setBreadcrumb, isLiveStreaming);
            },
          );

      }

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  parseVariablesConfig(_this: any, machineId: any, data: any, fileName?: any, componentId?: any, showTraces?: boolean, processes?: any, timeSeries?: any, setBreadcrumb?: any, isLiveStreaming?: any) {

    // Initialize plots config from data
    let plotsConfigComponents: any = _this.clonerService.deepClone(data);

    // Destructure cached plots config, if available
    let { value: c_value, assetId: c_assetId, selectedMachine: c_selectedMachine } = this.cacheService.get("plotsConfigComponents") ?? {};

    // Initialized cached plots config
    if (c_value != null && c_assetId == machineId && c_selectedMachine == _this.availableMachines?.selected) {

      let check = this.checkPlotsConfigLength(plotsConfigComponents, c_value);

      // When the cached value has missing items, probably due to the flags "cycleTraceabilityOnly", "continuousExplorationOnly", ecc...
      // The plots config object must be reinitialized from the starting value
      if (check) plotsConfigComponents = this.clonerService.deepClone(c_value)

    }

    // Maximum number of axes to show in the plot
    _this.maxAxes = 4;
    let maxAxes: any = _this.maxAxes - 1;

    if (plotsConfigComponents != null && Object.keys(plotsConfigComponents)?.length > 0) {

      Object.entries(plotsConfigComponents).forEach((pc: any) => {

        let compName = pc[0];
        let compConfig = pc[1];

        // se true cycle traceability altrimenti continuous exploration
        let isCycleExploration = _this.variables.hasOwnProperty('dict');

        if (compConfig.plotsConfig.list.filter((x: any) => x.show).length > _this.maxAxes) {
          compConfig.plotsConfig.list.forEach((conf: any, idx: any) => {
            if (conf.show && idx > parseInt(maxAxes)) {
              conf.show = !conf.show;
              if (conf?.traces?.length) conf.traces.forEach((trace: any) => trace.show = false);
            }
          });
        }

        // Filter variables that are NOT in cycle exploration
        if (!isCycleExploration) compConfig.plotsConfig.list = this.filterPlotsConfigWithSpecificProperty(compConfig.plotsConfig.list, 'cycleTraceabilityOnly', "!=");

        // Filter variables that are NOT in continuous exploration
        else compConfig.plotsConfig.list = this.filterPlotsConfigWithSpecificProperty(compConfig.plotsConfig.list, 'continuousExplorationOnly', "!=");

        // Filter variables that are NOT in live streaming
        if (isLiveStreaming) compConfig.plotsConfig.list = this.filterPlotsConfigWithSpecificProperty(compConfig.plotsConfig.list, 'hideInLiveStreaming', "!=");

        // Handling specific permission for every group of variables
        let currentUser: any = localStorage.getItem('user');
        if (currentUser != null && currentUser != "") {

          try { currentUser = JSON.parse(currentUser) }
          catch (error) { console.log(error) }

          try {
            // getSpecificPermission
            let permissionKey = currentUser?.checkOnlyScopes ? 'scopes' : 'roles';

            if (currentUser?.oauth != null && currentUser?.oauth != false) {

              // Filter permission
              compConfig.plotsConfig.list = compConfig.plotsConfig.list?.reduce((acc, val) => {

                // Filter permission - group of variables
                if (val.permission != null && !currentUser?.[permissionKey]?.some(role => val.permission == role)) return acc;

                // Filter permission - single traces
                val.traces = val?.traces.filter(trace => trace.permission == null || currentUser?.[permissionKey]?.some(role => trace.permission == role));
                if (val.traces?.length > 0) acc.push(val);
                return acc;
              }, []);
            }
          } catch (error) { console.log(error) }

        }

      })

    }

    // Remove empty plots config
    _this.plotsConfigComponents = this.filterService.removeKeysWithCustomRule(plotsConfigComponents, (x: any) => x[1]?.plotsConfig?.list?.length > 0);

    // Cached plotsConfig
    let compId = componentId ?? Object.keys(plotsConfigComponents)[0];
    if (
      this.cacheService.get("plotsConfig")?.value != null &&
      this.cacheService.get("plotsConfig")?.assetId == machineId &&
      this.cacheService.get("plotsConfig")?.selectedMachine == _this.availableMachines?.selected
    ) {
      _this.plotsConfig = this.clonerService.deepClone(this.cacheService.get("plotsConfig")?.value);
      _this.buffers = _this.plotsConfigComponents?.[_this.cacheService.get("selectedComponent")?.value]?.bufferData ?? {};
    }
    else {

      if (setBreadcrumb) {
        _this.componentId = compId;
        let componentName = this.parseComponentsLabel(compId, null, machineId);

        if (componentName != null) {
          let newBreadcrumb = _this.clonerService.deepClone(_this.breadcrumb);
          newBreadcrumb.push(componentName);
          _this.internalDataService.setBreadcrumb(newBreadcrumb);
        }

      }
      try {
        _this.plotsConfig = plotsConfigComponents[compId].plotsConfig;
        _this.buffers = plotsConfigComponents?.[compId]?.bufferData ?? {};
      } catch (error) {

        let errorData = {
          message: {
            customMessage: "Missing property \"" + compId + "\" in " + fileName,
            customCode: "404",
          }
        }
        console.log({ errorData });

        _this.dispatcherService.getDispatch(_this, 301, errorData);
        return;
      }
    }

    Object.entries(_this.plotsConfigComponents)?.forEach(([key, value]) => {
      let plotConf: any = value;
      let isCurrentComponent = key == (_this.cacheService.get("selectedComponent")?.value ?? compId);
      this.setTracesSelected(_this, machineId, plotConf?.plotsConfig, showTraces, processes, timeSeries, !isCurrentComponent);
    });
    _this.dispatcherService.getDispatch(_this, 300);
  }

  filterPlotsConfigWithSpecificProperty(plotsConfigList, property, checkOperator = "==") {
    try {
      // Reduce the list of plot configurations to a filtered list
      let filteredList = plotsConfigList?.reduce((groupList, group) => {

        // Check the value of the specified property in the group using the provided operator
        // If the operator is "==", check if the property is truthy
        // If the operator is "!=", check if the property is falsy
        let checkedGroup = (checkOperator === "==") ? group?.[property] : !group?.[property];

        // If the group doesn't meet the specified condition, return the groupList without adding the group
        if (!checkedGroup) return groupList;

        // Reduce the group's traces to a filtered list of traces
        group.traces = group.traces.reduce((traceList, trace) => {

          // Check the value of the specified property in the trace using the provided operator
          // If the operator is "==", check if the property is truthy
          // If the operator is "!=", check if the property is falsy
          let checkedTrace = (checkOperator === "==") ? trace?.[property] : !trace?.[property];

          // If the trace meets the specified condition, add it to the traceList
          if (checkedTrace) traceList.push(trace);

          // Return the traceList
          return traceList;
        }, []);

        // Add the group to the groupList
        groupList.push(group);

        // Return the groupList
        return groupList;

      }, []);

      // console.log({ filteredList });

      // Return the filtered list
      return filteredList;
    } catch (error) {
      // Log any errors that occur
      console.log(error);
      // Return the original plotsConfigList if there is an error
      return plotsConfigList;
    }
  }

  checkPlotsConfigLength(plotsConfig = {}, c_plotsConfig = {}) {
    try {
      // Check the number of properties in both objects
      if (Object.keys(plotsConfig).length !== Object.keys(c_plotsConfig).length) {
        return false;
      }

      // Iterate over each property in the plotsConfig object
      for (const k in plotsConfig) {
        // Check if the list arrays have the same length
        if (plotsConfig[k].plotsConfig.list.length !== c_plotsConfig[k].plotsConfig.list.length) {
          return false;
        }

        // Iterate over each element in the list array
        for (let val of plotsConfig[k].plotsConfig.list) {
          // Check if the traces arrays have the same length
          if (val.traces.length !== c_plotsConfig[k].plotsConfig.list.find(x => x.id === val.id).traces.length) {
            return false;
          }
        }
      }

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }


  setTracesSelected(_this, machineId, plotsConfig, showTraces?: boolean, processes?: any, timeSeries?: any, justParsing: any = false) {

    let isCycleExploration = _this.variables.hasOwnProperty('dict');

    // if (emptySelectedVariables) _this.variables.selected = [];
    if (!justParsing) _this.variables.selected = [];

    plotsConfig.list.forEach((group: any) => {

      if (group.hasOwnProperty('traces') && group.traces != null && group.traces.length > 0) {

        group.traces = group.traces.filter((trace: any) => {
          if (processes) return processes.includes(trace.process);
          return true;
        });

        group.shownTraces = group.traces.filter((x: any) => x.show).length;
        group.allTraces = group.traces.length;

        if (!this.appConfig?.addCustomVariables) {
          group.name = this.translate.instant(this.parseDatapointLabel(group.label ?? group.name, null, machineId));
        }

        else {

          let name = this.getCustomVariableConfig(group.label, null, 'functionalGroups', machineId)?.label;

          if (name == group.label) group.name = this.getCustomVariableConfig(group.name, null, 'functionalGroups', machineId)?.label;
          else group.name = name;

        }

        group.traces.forEach((trace: any) => {

          let traceNameDefault = timeSeries ? (trace.timeSeriesName ?? trace.name) : trace.name;

          trace.label = traceNameDefault;
          trace.aspect = traceNameDefault;
          trace.id = traceNameDefault;
          trace.show = !showTraces ? trace.show : true;
          trace.unit = trace.unit ?? group?.unit;
          trace.group = group.name;

          try {
            let splittedTraceName = traceNameDefault.split(".");
            // RawData1000.RawData1000.abcd
            if (splittedTraceName.length > 2 && !traceNameDefault.startsWith("retention")) {
              trace.aspect = splittedTraceName[0];
              trace.id = splittedTraceName.slice(1, 3).join(".");
            }
            // retention_prod.RawData1000.abcd
            else {
              trace.aspect = splittedTraceName.filter((_, idx) => idx < splittedTraceName.length - 1).join(".");
              trace.id = splittedTraceName[splittedTraceName.length - 1];
            }
          } catch (error) { console.log(error) }

          try {
            trace.label = timeSeries
              ? _this.internalDataService.parseDatapointLabel(trace.aspect, trace.id, machineId)
              : _this.internalDataService.parseVariablesLabel(trace.id, null, machineId, false, false, trace.aspect);
          } catch (error) { console.log(error) }

          if (!justParsing) {

            if (isCycleExploration) {

              if (trace.show) {
                if (!_this.variables.dict.hasOwnProperty(traceNameDefault)) _this.variables.dict[traceNameDefault] = {};

                if (trace.process) _this.variables.dict[traceNameDefault].process = trace.process;
                if (trace.station) _this.variables.dict[traceNameDefault].station = trace.station;
              }

            } else if (!_this.variables.list.includes(traceNameDefault)) _this.variables.list.push(traceNameDefault);

            if (!isCycleExploration && Array.isArray(_this.variables.completeList)) _this.variables.completeList.push(_this.clonerService.deepClone(trace));

            if (trace.show && !_this.variables.selected.includes(traceNameDefault)) _this.variables.selected.push(traceNameDefault);

          }
        });
      }
    });
  }

  setCachedMachineRecorderTraces(_this) {

    this.cacheService.set("selectedComponent", {
      value: _this.componentId,
      selectedMachine: _this.availableMachines?.selected,
      assetId: _this.machineId
    });

    this.cacheService.set("plotsConfigComponents", {
      value: this.clonerService.deepClone(_this.plotsConfigComponents),
      selectedMachine: _this.availableMachines?.selected,
      assetId: _this.machineId,
    });

    this.cacheService.set("plotsConfig", {
      value: this.clonerService.deepClone(_this.plotsConfig),
      selectedMachine: _this.availableMachines?.selected,
      assetId: _this.machineId,
    });

  }

  parseKPIParameters(asset: any, data: any, doubleAggr: any) {
    // if (asset != null && data != null && data.kpiParameters != null && data.kpiParameters.list != null &&
    //   data.kpiParameters.list.length > 0) {

    //   data.kpiParameters.list = self.filterConfigFlag(data.kpiParameters.list, asset).filter(kpi => {
    //     if (userInfo != null) {

    //       if (userInfo.scope.some(x => x.includes("mdsp:core:Admin3rdPartyTechUser"))) {
    //         return true;
    //       } else
    //       if (userInfo.hasOwnProperty('roles') && userInfo.roles != null && Array.isArray(userInfo.roles)) {
    //         return kpi.advancedKPI != null ? userInfo.roles.includes("mdsp_customrole:mflxradvkpi") : true;
    //       }
    //     }
    //     return true;

    //   }).filter(x => doubleAggr ? x.doubleAggr == null : true);
    // }

    return data;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // datapoint
  private datapoint = new Subject<any>();
  setDatapoint(value: any = []) {
    this.datapoint.next(value);
  }
  resetDatapoint() {
    this.datapoint.next(null);
  }
  onDatapointUpdate(): Observable<any> {
    return this.datapoint.asObservable();
  }


  parseCycleInfos(data: any, findDay?: any, tableInfos?: any) {

    let _this = this;

    let datesFormatStart = "HH:mm:ss";
    let datesFormatEnd = "HH:mm:ss";

    if (tableInfos?.find(x => x.variable == 'timeStartP')?.format != null) datesFormatStart = tableInfos?.find(x => x.variable == 'timeStartP')?.format;
    if (tableInfos?.find(x => x.variable == 'timeEndP')?.format != null) datesFormatEnd = tableInfos?.find(x => x.variable == 'timeEndP')?.format;

    if (data != null && Array.isArray(data) && data.length > 0) {
      data.sort(this.filterService.sortByProperty('timestamp', null, true));
      data.forEach((cycle: any) => _this.parseSingleCycleInfos(cycle, findDay));
      return data;
    }
    return [];
  }

  parseSingleCycleInfos(cycle, findDay) {

    let _this = this;

    let datesFormatStart = "HH:mm:ss";
    let datesFormatEnd = "HH:mm:ss";

    if (findDay) cycle.day = cycle.timestamp != null ? moment(cycle.timestamp).format("YYYY-MM-DD") : null;

    // Cycle start
    cycle.timeStart = moment(cycle.timestamp).subtract(cycle.cycleTime, 's');
    cycle.timeStartP = cycle.timeStart.format(datesFormatStart);

    // Cycle end
    cycle.timeEndP = _this.filterService.parseMoment(cycle.timestamp, datesFormatEnd);
    cycle.timestampP = cycle.timestamp != null ? _this.filterService.parseMoment(cycle.timestamp, 'default') : null;

    // Cycle duration
    cycle.cycleTimeP = cycle.cycleTime != null ? _this.filterService.parseGaugeValue(cycle.cycleTime, 3, 1) : null;

    // Cycle start/end in seconds (0 - 86400)
    cycle.timeStartHours = moment(cycle.timestamp).subtract(cycle.cycleTime, 's').diff(moment(cycle.timestamp).startOf("day"), 's');
    cycle.timeEndHours = moment(cycle.timestamp).diff(moment(cycle.timestamp).startOf("day"), 's');

    // Anomaly detection (probably very old)
    cycle.healthP = cycle.health != null ? _this.filterService.parseGaugeValue(cycle.health, 0, 100) : null;
    cycle.classP = cycle.className ?? cycle.classId;
    if (cycle.probFirstName != null || cycle.probFirstId != null || cycle.probFirstVal != null) {
      cycle.probFirstP = (cycle.probFirstName != null ? cycle.probFirstName : cycle.probFirstId) + ': ' + _this.filterService.parseGaugeValue(cycle.probFirstVal, 0, 100) + '%';
    }
    cycle.icon = cycle.isAnomalous != null ? cycle.isAnomalous == 0 ? 'check_circle' : 'warning' : 'question_mark';
    cycle.iconClass = cycle.isAnomalous != null ? cycle.isAnomalous == 0 ? 'md-green' : 'md-red' : null;
    cycle.iconTooltip = cycle.isAnomalous != null ? cycle.isAnomalous == 0 ? 'Non anomalous' : 'Anomalous' : 'Not evaluated';
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // Alarms translations
  getAlarmStr(machineId: any, code: any, attribute: string) {
    let codeTrKey = 'alarms.' + machineId + '.' + code + '.' + attribute;
    let codeTr = this.translate.instant(codeTrKey);
    if (codeTr === codeTrKey) {
      // custom attribute not found
      codeTrKey = 'alarms.default.' + code + '.' + attribute;
      codeTr = this.translate.instant(codeTrKey);
      if (codeTr === codeTrKey) {
        // default attribute not found]
        codeTr = code;
      }
    }
    return codeTr;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // Alerts translations
  getAlertType(alertId: any, parameter: any) {

    if (('alerts.' + alertId + '.' + parameter) == this.translate.instant('alerts.' + alertId + '.' + parameter)) {
      return alertId;
    }
    return this.translate.instant('alerts.' + alertId + '.' + parameter);
  };

  getAlertIcon(type: any) {
    if (type === 'alarm') {
      return {
        icon: "cancel",
        class: "md-red-i"
      };
    } else if (type === 'warning') {
      return {
        icon: "warning",
        class: "md-orange-i"
      };
    } else if (type === 'info') {
      return {
        icon: "info",
        class: "md-blue-i"
      };
    } else {
      return {
        icon: "warning",
        class: "md-orange-i"
      };
    }
  };

  getAlertTranslation(alertId: any, parameters?: any, kpisFile?: any) {

    let paramsConfig: any = {};
    try {
      if (parameters != null && Object.keys(parameters).length > 0) {

        // Translate the selected time period.
        // if the period is "1" use the single label (minute, hour, day)
        // else the plural (minutes, hours, days)
        if (parameters?.param5 != null) {
          let timeAggrs = [
            {
              id: "minutes",
              label: this.translate.instant("INTERVAL.MINUTES"),
              singleLabel: this.translate.instant("INTERVAL.MINUTE"),
            },
            {
              id: "hours",
              label: this.translate.instant("INTERVAL.HOURS"),
              singleLabel: this.translate.instant("INTERVAL.HOUR"),
            },
            {
              id: "days",
              label: this.translate.instant("INTERVAL.DAYS"),
              singleLabel: this.translate.instant("INTERVAL.DAY"),
            }
          ];

          let labelKey = 'label';
          if (parameters.param4 == '1') labelKey = 'singleLabel';

          parameters.param5 = timeAggrs.find(x => x.id == parameters?.param5)?.[labelKey]?.toLowerCase() ?? parameters?.param5;
        }

        // Try converting the value to float
        try { parameters.param3 = parseFloat(parameters.param3) } catch (error) { }

        switch (alertId) {
          case 'numericVar':
          default:
            paramsConfig = {
              xVarNum: this.parseDatapointLabel(parameters.param1, null, this.cacheService.get("machineId")),
              xOpNum: this.getOperatorSymbol(parameters.param2),
              xNum: parameters.param3,
              xMinNum: parameters.param4,
              xUnitNum: this.parseDatapointUnit(parameters.param1, null, this.cacheService.get("machineId")),
              xTimeAggr: parameters.param5,
            }
            break;
          case 'boolVar':
            paramsConfig = {
              xVarBool: this.parseDatapointLabel(parameters.param1, null, this.cacheService.get("machineId")),
              xOpBool: "=",
              xBool: parameters.param3,
              xMinBool: parameters.param4,
              xTimeAggr: parameters.param5,
            }
            break;
          case 'cloths':
            paramsConfig = {
              nCycles: parameters.param3,
              sapCode: parameters.param4 != null && parameters.param4 != '-' ? (this.translate.instant('ALERTS.WITH_SAP_CODE') + ' ' + parameters.param4) : ''
            }
            break;
          case 'kpiVar':

            let label = parameters.param1;
            let value = parameters.param3;

            if (kpisFile != null) {
              let kpiConfigIdx = kpisFile.findIndex((kpi: any) => kpi.kpi == parameters.param1 || kpi.id == parameters.param1);
              let kpiConfig = kpiConfigIdx != -1 ? kpisFile[kpiConfigIdx] : null;

              if (kpiConfig != null) {
                label = this.translate.instant(kpiConfig.label ?? '-');
                value = this.filterService.parseGaugeValue(this.filterService.convertUnit(kpiConfig.unit, value).value, kpiConfig.decimals, kpiConfig.multiplier)
                  + (kpiConfig.unit != null ? (' ' + this.translate.instant(this.filterService.convertUnit(kpiConfig.unit).unit ?? '-')) : '');
              }
            }

            paramsConfig = {
              xVarKPI: label,
              xOpKPI: this.getOperatorSymbol(parameters.param2),
              xKPI: value,
              xUnitKPI: "",
              xMinKPI: parameters.param4,
              xTimeAggr: parameters.param5,
            }
            break;
        }
      }
    } catch (error) { console.log(error) }

    return this.translate.instant('alerts.' + alertId + '.descriptionShort', paramsConfig);
  }

  //////////////////////////////////////////
  // machine selection on change
  machineSelectionChange(_this: any, machine: any, nextState: number = 5) {

    if (machine != null) {

      try {
        _this.pollingEvents.unsubscribe();
      } catch (error) {
      }

      _this.pageState.next(nextState);
      _this.getEventsBreakdowns(_this);

    }
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // export file (.csv ecc.) title builder
  buildExportFileTitle(breadcrumb: any, interval?: any, format?: any) {
    let titleBreadcrumb = this.translate.instant(breadcrumb[breadcrumb.length - 1])

    let titleArray = [titleBreadcrumb]

    if (interval) {
      if (!format) format = "DD/MM/YYYY"
      let titleIntervalStart = this.filterService.parseMoment(interval.start, format)
      let titleIntervalEnd = this.filterService.parseMoment(interval.end, format)
      titleArray.push(titleIntervalStart, titleIntervalEnd)
    }

    if (this.machineSelectedSource.value.machineName) {
      let titleMachine = this.machineSelectedSource.value.machineName
      titleArray.unshift(titleMachine)
    }

    let title = titleArray.join(' - ')
    return title
  }

  setMachineDropdownSelected(availableMachines: any, machineReference: any, query: any = null, joinAvailableMachines: any = false) {

    if (availableMachines != null && availableMachines.list != null && availableMachines.list.length > 0) {

      if (joinAvailableMachines) {
        if (query != null) {
          query.machineId = availableMachines.list.join(';');
          return;
        } else return availableMachines.list.join(';');
      }

      if (this.cacheService.get("selectedMachine") == null && availableMachines.selected != null) this.cacheService.set("selectedMachine", availableMachines.selected);

      let selectedMachine = this.cacheService.get("selectedMachine");

      let avMachIdx = availableMachines.list.indexOf(selectedMachine);
      if (avMachIdx == -1) {
        selectedMachine = availableMachines.list[0];
        this.cacheService.set("selectedMachine", selectedMachine);

        try {
          this.cacheService.reset("selectedComponent");
          this.cacheService.reset("plotsConfigComponents");
          this.cacheService.reset("plotsConfig");
        } catch (error) { }
      }

      availableMachines.selected = selectedMachine;

      let machRef = selectedMachine === 'Line' ? machineReference : selectedMachine;
      if (query != null) {
        if (machRef != null) query.machineId = machRef;
        return;
      } else return machRef;

    } else if (machineReference) {
      if (query != null) {
        query.machineId = machineReference;
        return;
      } else return machineReference;
    }
    if (query == null) return null;

  }

  getDomain(range: any) {
    if (range != null && range > 0) {
      // solo due assi
      if (range < 3) {
        return [0, 1];
        // lunghezza pari
      } else if (range % 2 == 0) {
        return [0.05 * (range - 1) / 2, 1 - (0.05 * (range - 1) / 2)];
        // lunghezza dispari
      } else if (range % 2 != 0) {
        return [0.05 * (range - 1) / 2, 1 - (0.05 * (range - 3) / 2)];
      }
    }
    return [0, 1];
  }

  parseStandardHTTPError(_this: any, error: any, pollingFunction: any = null, snackBarOptions: any = null, avoid301 = false) {

    console.log({ error });

    let message = error.error;
    if (message != null && typeof message == 'object') {

      // Message with old format
      if (message.hasOwnProperty('error') && message.error != null) {
        message = message.error;
        if (message != null && typeof message == 'object' && message.hasOwnProperty('message') && message.message != null) {
          message = message.message;
        }
      }
    }

    let errorData = {
      type: 0,
      url: error.url,
      status: error.status,
      statusText: error.statusText,
      message: message,
    };

    if (snackBarOptions) {

      let text = snackBarOptions?.text;

      if (snackBarOptions?.textFromError) {
        if (error?.status != null) snackBarOptions.text = error?.status
        if (error?.statusText != null) snackBarOptions.text += error?.statusText
      }

      let horizontalPosition = snackBarOptions?.horizontalPosition;
      let verticalPosition = snackBarOptions?.verticalPosition;
      let duration = snackBarOptions?.duration;
      let closeButton = snackBarOptions?.closeButton;
      let classes = snackBarOptions?.classes;

      this.openSnackBar(text, horizontalPosition, verticalPosition, duration, closeButton, classes);
    }

    if (message?.redirectToLogin) window.location.href = '/';
    else if (!avoid301) _this.dispatcherService.getDispatch(_this, 301, errorData);

    if (pollingFunction != null) try { pollingFunction.unsubscribe() } catch (e) { console.log(e) };

    return throwError(() => errorData.status + ': ' + errorData.message);

  }

  openSnackBar(text: any, horizontalPosition: any = 'right', verticalPosition: any = 'bottom', duration: any = 4000, closeButton: any = '', classes: Array<any> = ["success"]) {
    this.MatSnackBar.open(text, closeButton, {
      horizontalPosition: horizontalPosition,
      verticalPosition: verticalPosition,
      panelClass: ['snackbar'].concat(classes),
      duration: duration
    });
  }

  /** Import:
   * - appInfo.json
   * - appConfig.json
   * - machineProfiles.json
   * 
   * Set breadcrumb
   * 
   * Set tabs
   * 
   * Override default properties with appConfig.<sectionName>.<tabName>
   * 
   * Subscribe to machine selected
   */
  initStandardPage(_this: any, defaultPollingTime: any = 0, tabsAsObject: any = false) {

    // Import appInfo
    _this.appInfo = _this.appConfigService.getAppInfo;
    // Import appConfig
    _this.appConfig = _this.appConfigService.getAppConfig;
    // Import machineProfiles
    _this.machineProfiles = _this.appConfigService.getMachineProfiles;

    // Override default properties with appConfig.<sectionName>.<tabName>
    let pageConfig = _this.appConfig?.[_this.sectionName]?.[_this.tabName];
    // or appConfig.<sectionName>.<tabName>.detailTabs.<detailTabName> if present
    if (_this.detailTabName != null) pageConfig = _this.appConfig?.[_this.sectionName]?.[_this.tabName].detailTabs?.[_this.detailTabName];

    // Override default properties with appConfig.<sectionName>.<tabName>
    try { Object.entries(pageConfig).filter(kv => _this.hasOwnProperty(kv[0])).forEach(kv => _this[kv[0]] = kv[1]) } catch (error) { }

    // Set breadcrumb
    if (_this.breadcrumb == null) _this.breadcrumb = [pageConfig?.title];
    _this.internalDataService.setBreadcrumb(_this.breadcrumb);

    // Set tabs
    if (_this.detailTabName != null) _this.tabs = _this.internalDataService[tabsAsObject ? 'getPageTabsFromObject' : 'getPageTabs'](_this.sectionName, _this.tabName);
    else _this.tabs = _this.internalDataService[tabsAsObject ? 'getPageTabsFromObject' : 'getPageTabs'](_this.sectionName);

    // Keep additional queryParams
    try {
      let standardQueryParams = ['sectionName', 'tabName', 'detailTabName'];
      let queryParamsToKeep = _this.filterService.removeKeysWithCustomRule(_this.route.snapshot.queryParams, (x) => !standardQueryParams.includes(x[0]));
      if (Object.keys(queryParamsToKeep)?.length > 0) _this.tabs?.forEach(tab => tab.urlQueryParams = { ...tab.urlQueryParams, ...queryParamsToKeep });
    } catch (error) { console.log(error) }

    // Set machine selected subscription
    _this.machineSelectedSub = _this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0 && _this.breadcrumb?.length >= 0) {
        let newBreadcrumb: any = _this.clonerService.deepClone(_this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        _this.breadcrumb = newBreadcrumb;
        _this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    // Set pollingTime if present
    _this.pollingTime = _this.appConfig[_this.sectionName]?.[_this.tabName]?.pollingTime ?? defaultPollingTime ?? 0;

    if (pageConfig?.setCalendarPage) _this.internalDataService.setCalendarPage(true);
  }

  addStandardStatistics(list: any, numberOfDays?: any, groupBy?: any) {

    let statObj: any = {};
    if (list != null && Array.isArray(list) && list.length > 0) {

      statObj.totElements = list.length;
      statObj.avgElementsPerDay = numberOfDays != null && numberOfDays > 0 ? list.length / numberOfDays : null;

      let columns = Object.keys(list[0]);

      columns.forEach(col => {
        statObj['avg' + col.capitalize()] = this.filterService.average(list, col);
        statObj['sum' + col.capitalize()] = this.filterService.sum(list, col);
        statObj['std' + col.capitalize()] = this.filterService.std(list, col);

        if (numberOfDays != null && numberOfDays > 0) {
          statObj['avg' + col.capitalize() + 'PerDay'] = statObj['avg' + col.capitalize()] / numberOfDays;
          statObj['sum' + col.capitalize() + 'PerDay'] = statObj['sum' + col.capitalize()] / numberOfDays;
        }
      });

      if (groupBy != null) {

        if (Array.isArray(groupBy) && groupBy?.length > 0) groupBy?.forEach(x => group(this, x));
        else group(this, groupBy);
      }

      function group(_this, prop) {
        try {
          Object.entries(_this.filterService.groupBy(list, prop)).forEach(([k, v], index) => {
            let values: any = v;
            columns.forEach(col => {
              statObj["totElementsPer" + k.capitalize()] = values.length;
              statObj['avg' + col.capitalize() + 'Per' + k.capitalize()] = _this.filterService.average(v, col);
              statObj['sum' + col.capitalize() + 'Per' + k.capitalize()] = _this.filterService.sum(v, col);
            });
          });
        } catch (error) { console.log(error) }
      }

    }

    // console.log({ statObj });

    return statObj;
  }


  /** openAggrDialog */
  openAggrDialog(_this: any,
    aggr: any,
    unsubscribePolling: any,
    pollingFunction: any,
    notPollingFunction: any,
    pageStatePolling: any = 5,
  ) {

    let aggrDialog = _this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: _this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: _this.clonerService.deepClone(_this.machine),
        machineId: _this.machineId,
        machineReference: _this.machine?.machineReference,
        machineSelected: _this.availableMachines?.list?.join(';') ?? null,
        aggregations: _this.aggregations,
        interval: JSON.parse(JSON.stringify(_this.interval))
      },
    });

    aggrDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        result = this.clonerService.deepClone(result);
        aggr.selected = this.clonerService.deepClone(result.selected);

        try { unsubscribePolling.unsubscribe() } catch (error) { }

        _this.pageState.next(pageStatePolling);

        if (!_this.interval.enabledPolling) notPollingFunction(_this, 0);
        else pollingFunction(_this);
      }

    });
  };

  /** Parsa gli eventi */
  getEventInfo(_this: any, event: any) {
    if (event != null && event.hasOwnProperty('eventId') && event.eventId != null) {

      let eventInfo: any = {
        description: '',
        eventName: '',
      };

      let c_timeStates = _this.cacheService.get("timeStates");
      let currentStateConfig = null;

      let currentStateIdx = (c_timeStates ?? _this.machine.profile.timeStates).findIndex((x: any) => x.state == event.state);
      if (currentStateIdx != -1) currentStateConfig = _this.clonerService.deepClone((c_timeStates ?? _this.machine.profile.timeStates)[currentStateIdx]);

      let eventConfig: any = {};

      let eventId = String(event?.eventId);

      let unparseableEventIds = ["1", "2", "1bis", "2bis"];
      if (!unparseableEventIds.includes(eventId)) {
        try {
          eventConfig = _this.machine.profile.tables.find((x: any) => {
            // ID as string
            if (!Array.isArray(x.id)) return String(x.id) == eventId;
            // ID as array
            else if (Array.isArray(x.id) && x.id?.length > 0) {
              let stringifiedIds = x.id?.map(id => String(id));
              if (stringifiedIds?.includes(eventId)) {
                eventId = stringifiedIds?.indexOf(eventId) == 0 ? 'customStart' : 'customEnd';
                return true;
              }
            }
          }) ?? {}
        }
        catch (error) { console.log(error) }
      }

      event.alarmCodeSplitted = event.alarmCode;
      try { event.alarmCodeSplitted = event.alarmCode.split(_this.machine.profile.alarmsConfig.split.char)[0] } catch (error) { }

      let alarmMsg: any = event.alarmCode;

      let keyToFind = null;
      let description = null;

      switch (eventId) {
        case '1':
          alarmMsg = event.message ?? _this.internalDataService.parseAlarmsLabel(event.alarmCode, 'message', _this.machineId);

          eventInfo = {
            icon1: {
              type: 'icon',
              icon: 'cancel',
              class: 'md-red'
            },
            icon2: {
              type: 'icon',
              icon: 'play_arrow',
              class: 'md-red'
            },
            state: event.state,
            description: alarmMsg,
            eventName: _this.translate.instant("CONTINUOUS_EXPLORATION.FILTERS.ALARM_START"),
            filterId: "alarms",
            flagConfig: {
              color: 'red',
              width: 2,
            },
            stateP: {
              value: currentStateConfig != null ? _this.translate.instant(currentStateConfig.value) : null,
              bgColor: currentStateConfig != null ? currentStateConfig.color : null,
              color: currentStateConfig != null ? currentStateConfig.color : null,
            }
          };
          break;
        case '2':

          alarmMsg = event.message ?? _this.internalDataService.parseAlarmsLabel(event.alarmCode, 'message', _this.machineId);

          eventInfo = {
            icon1: {
              type: 'icon',
              icon: 'cancel',
              class: 'md-blue-gray'
            },
            icon2: {
              type: 'icon',
              icon: 'stop',
              class: 'md-blue-gray'
            },
            state: event.state,
            description: alarmMsg,
            eventName: _this.translate.instant("CONTINUOUS_EXPLORATION.FILTERS.ALARM_END"),
            filterId: "alarms",
            flagConfig: {
              color: 'green',
              width: 2,
            },
            stateP: {
              value: currentStateConfig != null ? _this.translate.instant(currentStateConfig.value) : null,
              bgColor: currentStateConfig != null ? currentStateConfig.color : null,
              color: currentStateConfig != null ? currentStateConfig.color : null,
            }
          };
          break;
        case "1bis":
          // if (event.alarmCode.startsWith('AL_')) 
          alarmMsg = event.message ?? _this.internalDataService.parseWarningsLabel(event.alarmCode, 'message', _this.machineId);

          eventInfo = {
            icon1: {
              type: 'icon',
              icon: 'warning',
              class: 'md-orange'
            },
            icon2: {
              type: 'icon',
              icon: 'play_arrow',
              class: 'md-orange'
            },
            state: event.state,
            description: alarmMsg,
            eventName: _this.translate.instant("CONTINUOUS_EXPLORATION.FILTERS.SIGNALATION_START"),
            filterId: "signalations",
            flagConfig: {
              color: 'orange',
              width: 2,
            },
            stateP: {
              value: currentStateConfig != null ? _this.translate.instant(currentStateConfig.value) : null,
              bgColor: currentStateConfig != null ? currentStateConfig.color : null,
              color: currentStateConfig != null ? currentStateConfig.color : null,
            }
          };
          break;
        case "2bis":

          // if (event.alarmCode.startsWith('AL_')) 
          alarmMsg = event.message ?? _this.internalDataService.parseWarningsLabel(event.alarmCode, 'message', _this.machineId);

          eventInfo = {
            icon1: {
              type: 'icon',
              icon: 'warning',
              class: 'md-blue-gray'
            },
            icon2: {
              type: 'icon',
              icon: 'stop',
              class: 'md-blue-gray'
            },
            state: event.state,
            description: alarmMsg,
            eventName: _this.translate.instant("CONTINUOUS_EXPLORATION.FILTERS.SIGNALATION_END"),
            filterId: "signalations",
            flagConfig: {
              color: 'green',
              width: 2,
            },
            stateP: {
              value: currentStateConfig != null ? _this.translate.instant(currentStateConfig.value) : null,
              bgColor: currentStateConfig != null ? currentStateConfig.color : null,
              color: currentStateConfig != null ? currentStateConfig.color : null,
            }
          };
          break;
        case '3':

          let stateToConfig = null;
          let stateFromConfig = null;

          let stateFromIdx = (c_timeStates != null ? c_timeStates : _this.machine.profile.timeStates).findIndex((x: any) => x.state == event.stateFrom);
          if (stateFromIdx != -1) {
            stateFromConfig = _this.clonerService.deepClone((c_timeStates != null ? c_timeStates : _this.machine.profile.timeStates)[stateFromIdx]);
            try {
              // stateFromConfig.color = stateFromConfig.color + '50';
            } catch (error) {
              console.log("NOT HEX COLOR", error);
            }
          }

          let stateToIdx = (c_timeStates != null ? c_timeStates : _this.machine.profile.timeStates).findIndex((x: any) => x.state == event.stateTo);
          if (stateToIdx != -1) {
            stateToConfig = _this.clonerService.deepClone((c_timeStates != null ? c_timeStates : _this.machine.profile.timeStates)[stateToIdx]);
            try {
              // stateToConfig.color = stateToConfig.color + '50';
            } catch (error) {
              console.log("NOT HEX COLOR", error);
            }
          }

          eventInfo = {
            icon1: {
              type: 'icon',
              icon: 'settings_backup_restore',
            },
            state: event.stateTo,
            description: [{
              value: _this.translate.instant("CONTINUOUS_EXPLORATION.FROM") + ' ' + (stateFromConfig != null ? _this.translate.instant(stateFromConfig.value) : null),
              color: (stateFromConfig != null ? stateFromConfig.color : null),
            },
            {
              value: _this.translate.instant("CONTINUOUS_EXPLORATION.TO") + ' ' + (stateToConfig != null ? _this.translate.instant(stateToConfig.value) : null),
              color: (stateToConfig != null ? stateToConfig.color : null),
            },
            ],
            eventName: _this.translate.instant(eventConfig.eventLabel ?? '-'),
            filterId: eventConfig.eventId,
            eventType: 'colorList',
            flagConfig: eventConfig.flagConfig,
            stateP: {
              value: stateToConfig != null ? _this.translate.instant(stateToConfig.value) : null,
              bgColor: stateToConfig != null ? stateToConfig.color : null,
              color: stateToConfig != null ? stateToConfig.color : null,
            }
          };
          break;
        case '4':
          // AGGR0 change
          eventInfo = {
            icon1: {
              type: 'icon',
              icon: 'update',
            },
            state: event.state,
            description: [
              _this.translate.instant("CONTINUOUS_EXPLORATION.FROM") + ' ' + event.aggrFrom,
              _this.translate.instant("CONTINUOUS_EXPLORATION.TO") + ' ' + event.aggrTo,
            ],
            eventName: _this.translate.instant(eventConfig.eventLabel),
            filterId: eventConfig.eventId,
            flagConfig: eventConfig.flagConfig,
            eventType: 'list',
            aggr0: event.aggrTo,
            stateP: {
              value: currentStateConfig != null ? _this.translate.instant(currentStateConfig.value) : null,
              bgColor: currentStateConfig != null ? currentStateConfig.color : null,
              color: currentStateConfig != null ? currentStateConfig.color : null,
            }
          };
          break;
        case '5':
          // AGGR1 change
          eventInfo = {
            icon1: {
              type: 'icon',
              icon: 'update',
            },
            state: event.state,
            description: [
              _this.translate.instant("CONTINUOUS_EXPLORATION.FROM") + ' ' + event.aggrFrom,
              _this.translate.instant("CONTINUOUS_EXPLORATION.TO") + ' ' + event.aggrTo,
            ],
            eventName: _this.translate.instant(eventConfig.eventLabel),
            filterId: eventConfig.eventId,
            flagConfig: eventConfig.flagConfig,
            eventType: 'list',
            aggr1: event.aggrTo,
            stateP: {
              value: currentStateConfig != null ? _this.translate.instant(currentStateConfig.value) : null,
              bgColor: currentStateConfig != null ? currentStateConfig.color : null,
              color: currentStateConfig != null ? currentStateConfig.color : null,
            }
          };
          break;
        case '6':
          // AGGR1 change
          eventInfo = {
            icon1: {
              type: 'icon',
              icon: 'update',
            },
            state: event.state,
            description: [],
            eventName: _this.translate.instant(eventConfig.eventLabel),
            filterId: eventConfig.eventId,
            eventType: 'list',
            flagConfig: eventConfig.flagConfig,
            stateP: {
              value: currentStateConfig != null ? _this.translate.instant(currentStateConfig.value) : null,
              bgColor: currentStateConfig != null ? currentStateConfig.color : null,
              color: currentStateConfig != null ? currentStateConfig.color : null,
            }
          };
          break;
        case '7':
          // buttons pressing
          eventInfo = {
            icon1: {
              type: 'svg',
              icon: 'touch',
            },
            state: event.state,
            // description: event.buttons != null ? event.buttons.split("$$").map((x: any) => _this.checkTranslPipe.transform(("CONTINUOUS_EXPLORATION.BUTTONS." + x), x, false)) : [],
            description: event.buttons != null ? event.buttons.split("$$").map((x: any) => this.parseButtonsLabel(x, null, _this.machineId)) : [],
            eventName: _this.translate.instant(eventConfig.eventLabel),
            filterId: eventConfig.eventId,
            flagConfig: eventConfig.flagConfig,
            // flagConfig: _this.machine.profile.tables.find((x: any) => x.id == event.eventId).flagConfig,
            eventType: 'list',
            stateP: {
              value: currentStateConfig != null ? _this.translate.instant(currentStateConfig.value) : null,
              bgColor: currentStateConfig != null ? currentStateConfig.color : null,
              color: currentStateConfig != null ? currentStateConfig.color : null,
            }
          };
          break;

        // Default handling
        default:

          description = this.parseDescription(eventConfig?.description, event, keyToFind, _this.machineId, _this.machine?.profile);

          if (!Array.isArray(description)) description = [description];

          // For history params, the real timestamp is associated to the key "variationTime"
          if (event?.variationTime != null) event.timestamp = event.variationTime;

          // default event
          eventInfo = {
            icon1: eventConfig?.icon ?? {
              icon: 'update',
              type: 'icon',
            },
            state: event.state,
            description: description,
            eventName: _this.translate.instant(eventConfig.eventLabel ?? '-'),
            filterId: eventConfig.eventId,
            flagConfig: eventConfig.flagConfig,
            eventType: 'list',
            stateP: {
              value: _this.translate.instant(currentStateConfig?.value ?? '-'),
              bgColor: currentStateConfig?.color,
              color: currentStateConfig?.color,
            }
          };

          if (eventConfig?.imageRegex != null) event.image = eventConfig.imageRegex?.replace("{filename}", event?.filename)?.replace("{machineId}", this.cacheService.get("machineId"));
          if (eventConfig?.addImage != null) event.addImage = eventConfig?.addImage;

          break;
        case "customStart":
        case "customEnd":
          // custom Start/End

          let index = eventId == 'customStart' ? 0 : 1;
          keyToFind = this.parseKeyToFindFromEventConfigParams(eventConfig?.params ?? []);
          description = this.parseDescription(eventConfig?.description, event, keyToFind, _this.machineId);

          eventInfo = {
            icon1: eventConfig?.icons?.[index]?.icon1 ?? {
              icon: 'update',
              type: 'icon',
              class: 'md-blue-gray'
            },
            icon2: eventConfig?.icons?.[index]?.icon2 ?? {
              icon: eventId == 'customStart' ? 'play_arrow' : 'stop',
              type: 'icon',
              class: 'md-blue-gray'
            },
            state: event.state,
            description: description,
            eventName: _this.translate.instant(eventConfig?.eventNames?.[index] ?? (eventId == 'customStart' ? 'GLOBAL.START' : 'GLOBAL.END')),
            filterId: eventConfig.eventId,
            stateP: {
              value: _this.translate.instant(currentStateConfig?.value ?? '-'),
              bgColor: currentStateConfig?.color ?? null,
              color: currentStateConfig?.color ?? null,
            }
          };
          break;
      }

      // console.log(filterService.mergeObjects(event, eventInfo));
      return Object.assign(event, eventInfo);
    }
  }

  parseDescription(description, event, keyToFind, machineId, profile?) {
    if (description == null) return keyToFind;

    let conf: any = {};

    if (typeof description == 'object') {
      switch (description?.type) {
        case 'fromVariable':
          return event?.[description?.variable];
        case 'fromVariableArray':
          return description?.variableArray?.reduce((acc, val) => {

            // Calculate prefix
            let prefix = val?.prefix != null ? (this.translate.instant(val?.prefix ?? '-') + ' ') : '';

            // If "prefixes" exist, override prefix
            if (val?.prefixes?.length > 0) {
              prefix = val?.prefixes?.reduce((pref, elem) => pref + (this.translate.instant(elem ?? '-')) + ' ', "");
            }

            let name: any = "";

            switch (val?.type) {
              case 'advancedTranslate':

                conf = val?.advancedTranslateConfig ?? {};

                let c_customVariables = this.cacheService.get("customVariables");

                // Cached custom translations
                if (this.appConfig?.addCustomVariables && c_customVariables?.machineId == machineId && c_customVariables?.value != null) {

                  let aspectName = event?.[conf?.aspect];
                  aspectName = (aspectName != null && typeof aspectName == 'string') ? aspectName.replace(/\$\$\$/g, ' - ') : aspectName;
                  let varName = aspectName;
                  if (!conf?.excludeVariableKeyUsingVariables && conf.variable != null) {
                    varName += `.${conf.variable}`;
                  }

                  let codeConfig = this.buildTranslationsFromVariablesFile(conf?.key, varName, c_customVariables?.value, this.appConfigService.getDefaultCustomVariables, conf?.idVariable ?? 'id');

                  if (conf?.parseObjFromConfig) name = this.filterService.parseObjFromConfig(event?.[val?.variable], { ...codeConfig }, true, 'type', true);
                  else name = codeConfig?.[conf?.outputKey ?? 'label'];

                  if (conf.translateValue && typeof name == 'object') name = name?.[this.translate.currentLang] ?? name;

                  if (val?.addUnit && codeConfig?.unit != null) name += ' [' + this.translate.instant(codeConfig?.unit ?? '-') + ']';

                  break;
                }

                let c_customTranslations = this.cacheService.get("customTranslations");

                // Cached custom translations
                if (c_customTranslations != null && c_customTranslations?.machineId == machineId && c_customTranslations?.value != null && this.appConfig?.addCustomTranslations) {

                  let aspectName = event?.[conf?.aspect];
                  aspectName = (aspectName != null && typeof aspectName == 'string') ? aspectName.replace(/\$\$\$/g, ' - ') : aspectName;
                  let varName = aspectName + (conf?.variable != null ? ('.' + conf?.variable) : '');

                  let codeConfig = this.buildTranslationsFromFile(conf?.key, varName, c_customTranslations?.value, this.appConfigService.getDefaultCustomTranslations);
                  name = codeConfig?.label;

                  if (val?.addUnit && codeConfig?.unit != null) name += ' [' + this.translate.instant(codeConfig?.unit ?? '-') + ']';

                  break;
                }

                name = this.parseLabelWithAssetId(conf?.key, event?.[conf?.aspect], conf?.variable, machineId);

                break;

              case 'configFromProfile':

                let profileConfig = profile?.[val?.mappingConfig?.key ?? 'timeStates']?.find(x => x?.[val?.mappingConfig?.idKey ?? 'id'] == event?.[val?.variable]);

                let v = profileConfig?.[val?.mappingConfig?.outputKey ?? 'label'];

                // translation
                try { name = val?.mappingConfig?.translate ? this.translate.instant(v ?? event?.[val?.variable] ?? '-') : (v ?? event?.[val?.variable]) }
                catch (error) { name = v ?? event?.[val?.variable] }

                // additional unit
                if (val?.addUnit && profileConfig?.unit != null) name += ' [' + this.translate.instant(profileConfig?.unit ?? '-') + ']';
                break;
              default:
                name = event?.[val?.variable];
                break;
            }

            // Calculate suffix
            let suffix = val?.suffix != null ? (this.translate.instant(val?.suffix ?? '-') + ' ') : '';

            // If "suffixes" exist, override suffix
            if (val?.suffixes?.length > 0) {
              suffix = val?.suffixes?.reduce((pref, elem) => pref + (this.translate.instant(elem ?? '-')) + ' ', "");
            }

            let obj = prefix + name + suffix;

            acc.push(obj);
            return acc;
          }, []);
        case 'advancedTranslate':
        default:
          conf = description?.advancedTranslateConfig ?? {};
          return this.parseLabelWithAssetId(conf?.key, event?.[conf?.aspect], conf?.variable, machineId);
      }
    }

    return keyToFind;

  }

  parseKeyToFindFromEventConfigParams(params) {

    let param = params?.[0];

    if (typeof param == 'string') return param;
    if (Array.isArray(param) && param?.length == 2) return param[1];
    return 'code';
  }

  parseLocation(machine: any) {
    if (machine?.location != null) {
      machine.locationP = '';
      if (machine.location?.streetAddress != null) machine.locationP += machine.location.streetAddress + ", ";
      if (machine.location?.locality != null) machine.locationP += machine.location.locality + ", ";
      if (machine.location?.postalCode != null) machine.locationP += machine.location.postalCode + ", ";
      if (machine.location?.region != null) machine.locationP += machine.location.region + ", ";
      if (machine.location?.country != null) machine.locationP += machine.location.country;
    }
  }

  // // // // // // // // // // // // // //
  // FILTER BUTTONS

  checkFilterButtons(item: any) {
    return item?.options?.length > item?.options?.filter(x => x.selected)?.length;
  }

  filteredItems(item: any) {
    return item?.options?.filter(x => x.selected)?.length;
  }

  openFilterDialog(_this: any, button: any) {
    // console.log(button)
    let filtersDialog = _this.dialog.open(FiltersDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: _this.translate.instant(button.label),
        variable: button.variable,
        options: button.options
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') {
        button.options = _this.clonerService.deepClone(result?.options);
        _this.filterElements();
      }
    });
  }

  filterConfigFlag(initialArray, asset) {
    return initialArray.filter(elem => this.checkConfigFlag(elem, asset));
  }

  checkConfigFlag(elem, asset) {
    try {
      if (asset.hasOwnProperty('configuration') && asset.configuration != null &&
        elem.hasOwnProperty("configFlag") && elem.configFlag != null &&
        elem.configFlag.hasOwnProperty('id') && elem.configFlag.id != null &&
        elem.configFlag.hasOwnProperty('index') && elem.configFlag.index != null &&
        elem.configFlag.hasOwnProperty('values') && elem.configFlag.values != null &&
        Array.isArray(elem.configFlag.values) && elem.configFlag.values.length > 0) {

        let splittedConfig = asset.configuration.split(";").map(x => {
          return {
            id: x.split("=")[0],
            value: x.split("=")[1]
          }
        });

        let ixCheck = splittedConfig.findIndex(x => x.id == elem.configFlag.id);
        if (ixCheck != -1) {
          return elem.configFlag.values.includes(parseInt(splittedConfig[ixCheck].value[elem.configFlag.index]));
        }
      }
      return true;
    } catch (error) {
      console.log(error);
      return true;
    }
  }

  // // // // // // // // // // // // // //
  //  CUSTOM VARIABLES (variables.json)  
  // // // // // // // // // // // // // //

  async getCustomVariables(machineId) {

    let url = '/apif/get-custom-file';
    let fileName = 'variables.json';

    let query: any = {
      fromWeb: this.appInfo?.sources40F != null ? 1 : 0,
      source: this.appInfo?.sources40F ?? '/assets/config/',
      machineId: machineId,
      fileName: fileName,
    };

    // Import specific custom translations
    try {

      let c_customVariables = this.cacheService.get("customVariables");

      // Cached custom translations
      if (c_customVariables == null || c_customVariables?.machineId != machineId) {
        let request: any = await lastValueFrom(this.apiService.sendGetRequest(url, query));

        // SET custom translations in cache
        this.cacheService.set("customVariables", {
          machineId: machineId,
          value: request.body,
        });

        let variables: any = request.body ?? {};

        this.buildVariablesConfig(variables.telemetry ?? [], machineId, variables.components ?? []);
        this.buildRbaConfig(variables.telemetry ?? [], machineId);

        // if (variables?.kpis?.length) this.buildKPIConfig(variables.kpis, machineId);

      }
    } catch (error) {
      console.log(error);
    }

  }

  buildVariablesConfig(data, machineId, components = []) {

    // Find the complete list of machines
    let listOfMachines = data?.reduce((acc, val) => {
      if (val?.machines?.length) {
        val?.machines?.forEach(mach => {
          if (!acc?.includes(mach)) acc.push(mach);
        })
      }
      return acc;
    }, []);

    // // // // // // // // // // // // //
    // variablesConfig.json
    let variablesConfig = this.transformVariablesToVariablesConfig(data, components);

    this.cacheService.set("variablesConfig.json", {
      value: variablesConfig,
      assetId: machineId
    });

    // console.log(`variablesConfig.json`, this.cacheService.get(`variablesConfig.json`).value);

    // // // // // // // // // // // // //
    // variablesConfigHM.json
    let variablesConfigHM = this.transformVariablesToVariablesConfig(data, components, true);

    this.cacheService.set("variablesConfigHM.json", {
      value: variablesConfigHM,
      assetId: machineId
    });

    // console.log(`variablesConfigHM.json`, this.cacheService.get(`variablesConfigHM.json`).value);

    // // // // // // // // // // // // //
    // MACHINES
    // // // // // // // // // // // // //

    if (listOfMachines.length > 0) {

      listOfMachines?.forEach(mach => {

        // // // // // // // // // // // // //
        // <machineId>-variablesConfig.json
        let variablesConfig = this.transformVariablesToVariablesConfig(data, components, false, mach);

        this.cacheService.set(`${mach}-variablesConfig.json`, {
          value: variablesConfig,
          assetId: machineId
        });

        // console.log(`${mach}-variablesConfig.json`, this.cacheService.get(`${mach}-variablesConfig.json`).value);

        // // // // // // // // // // // // //
        // <machineId>-variablesConfigHM.json
        let variablesConfigHM = this.transformVariablesToVariablesConfig(data, components, true, mach);

        this.cacheService.set(`${mach}-variablesConfigHM.json`, {
          value: variablesConfigHM,
          assetId: machineId
        });

        // console.log(`${mach}-variablesConfigHM.json`, this.cacheService.get(`${mach}-variablesConfigHM.json`).value);

      });

    }

  }

  transformVariablesToVariablesConfig(data, componentsConfig = [], hm = false, machine = null) {

    return data?.reduce((acc, val) => {

      // Single machine
      if (machine == null) {
        if (val?.machines?.length) return acc;
      }
      // Multi machine
      else {
        if (!val?.machines?.length || !val.machines.includes(machine)) return acc;
      }

      let componentsArray = [];

      if (hm) {
        if (val.adComponent) componentsArray.push(val.adComponent);
        if (Array.isArray(val.adComponents) && val.adComponents?.length) val.adComponents?.forEach(comp => componentsArray.push(comp));
      }
      else {
        if (val.inGlobal == null) val.inGlobal = true;
        if (val.inGlobal) componentsArray.push("GLOBAL");
        if (val.component) componentsArray.push(val.component);
        if (Array.isArray(val.components) && val.components?.length) val.components?.forEach(comp => componentsArray.push(comp));
      }

      componentsArray.forEach(comp => {

        if (acc?.[comp] == null) {
          acc[comp] = {
            plotsConfig: {
              list: []
            }
          }
          let compConfig = componentsConfig?.find(x => x.id == comp);
          if (compConfig?.order != null) acc[comp].order = compConfig?.order;
        }

        if (val.inRecorder && val.functionalGroup != null) {
          let funcGroupIdx = acc[comp].plotsConfig.list.findIndex(x => x.id == val.functionalGroup);

          // New functional group
          if (funcGroupIdx == -1) {

            let variable: any = {
              id: val.functionalGroup,
              show: val.showInRecorder,
              name: val.functionalGroup,
              label: `functionalGroups.${val.functionalGroup}`,
              unit: val.unit ?? '-',
              traces: [{
                name: val.id,
                show: val.showInRecorder,
                mean: val.mean,
                multiplier: val.multiplier,
                decimals: val.decimals,
                timeSeriesName: val.liveStreamingId,
                station: val.station,
                cycleTraceabilityOnly: val.cycleTraceabilityOnly,
                continuousExplorationOnly: val.continuousExplorationOnly,
                hideInLiveStreaming: val.hideInLiveStreaming,
              }]
            };

            acc[comp].plotsConfig.list.push(variable);

          }

          // Existing functional group
          else {
            if (val.unit == acc[comp].plotsConfig.list[funcGroupIdx].unit) {

              let variableIndex = acc[comp].plotsConfig.list[funcGroupIdx].traces?.findIndex(x => x.name == val.id);

              if (variableIndex == -1) {

                let trace: any = {
                  name: val.id,
                  show: val.showInRecorder,
                  mean: val.mean,
                  multiplier: val.multiplier,
                  decimals: val.decimals,
                  timeSeriesName: val.liveStreamingId,
                  station: val.station,
                  cycleTraceabilityOnly: val.cycleTraceabilityOnly,
                  continuousExplorationOnly: val.continuousExplorationOnly,
                  hideInLiveStreaming: val.hideInLiveStreaming,
                };

                acc[comp].plotsConfig.list[funcGroupIdx].traces.push(trace);
                acc[comp].plotsConfig.list[funcGroupIdx].show = acc[comp].plotsConfig.list[funcGroupIdx].traces.some(x => x.show);
              }

            }

            else {
              console.warn(
                `Different units of measure in same functional group "${val.functionalGroup}" in ${hm ? 'AD' : ''} component "${comp}" 
                "${val.id}" unit: ${val.unit} | Functional group unit: ${acc[comp].plotsConfig.list[funcGroupIdx].unit} | "${val.id}" not added`
              );
            }
          }
        }
      })

      // Show only the components that have at least one trace selectable
      acc = this.filterService.removeKeysWithCustomRule(acc, (x: any) => x[1]?.plotsConfig?.list?.length > 0);

      return acc;

    }, {});
  }

  buildRbaConfig(data, machineId) {

    let dataTypes = data?.reduce((acc, val) => {
      if (val.inRba == null) val.inRba = true;
      if (!val.inRba) return acc;
      if (val.dataType == null) val.dataType = 'number';
      if (acc.indexOf(val.dataType) == -1) acc.push(val.dataType);
      return acc;
    }, []);

    let alertsConfig: any = this.clonerService.deepClone(this.appConfigService.getDefaultAlertsConfig);

    if (!dataTypes.includes("number")) alertsConfig.splice(alertsConfig.findIndex(x => x.alertType == 'numericVar'), 1);
    if (!dataTypes.includes("bool")) alertsConfig.splice(alertsConfig.findIndex(x => x.alertType == 'boolVar'), 1);

    // console.log({ alertsConfig });

    this.cacheService.set("alertsConfig.json", {
      value: alertsConfig,
      assetId: machineId
    });

    let rbaVariablesConfigDefault: any = {}
    if (dataTypes.includes("number")) rbaVariablesConfigDefault.numbers = []
    if (dataTypes.includes("bool")) rbaVariablesConfigDefault.booleans = []

    let rbaVariables = data?.reduce((acc, val) => {

      if (val.inRba == null) val.inRba = true;
      if (!val.inRba) return acc;
      if (val.dataType == null) val.dataType = 'number';

      let obj = {
        id: val.id,
        unit: val.unit,
        translations: val.translations,
        multiplier: val.multiplier
      };

      if (val.dataType == 'number') acc.numbers.push(obj);
      else if (val.dataType == 'bool') acc.booleans.push(obj);

      return acc;

    }, rbaVariablesConfigDefault)

    // console.log({ rbaVariables });

    this.cacheService.set("rba-variables.json", {
      value: rbaVariables,
      assetId: machineId
    });

  }

  buildKPIConfig(data, machineId) {

    let kpiConfig = {
      selected: null,
      kpiParameters: { list: data }
    };

    // console.log({ kpiConfig });

    this.cacheService.set(`${machineId}-parameters.json`, kpiConfig);

  }

  buildTranslationsFromVariablesFile(type: any, code: any, customFile: any, defaultCustomFile: any, idVariable: any = "id") {

    // No translation file
    if (defaultCustomFile == null && customFile == null) {
      return {
        label: code,
        message: code,
        code: code,
      };
    }

    let translInCustomFile = false;
    let fileParameter = null;

    // <assetId>/variables.json
    if (customFile != null) {
      try {

        // <assetId>/variables.json
        fileParameter = customFile?.[type]?.find(x => x?.[idVariable] == code);

        if (fileParameter != null) {
          translInCustomFile = fileParameter?.translations[this.translate.currentLang] != null;
        } else {

          // default-variables.json - No parameter config
          fileParameter = defaultCustomFile?.[type]?.find(x => x?.[idVariable] == code);

          if (fileParameter != null) {
            translInCustomFile = fileParameter?.translations[this.translate.currentLang] != null;
          }
        }

      } catch (error) { }
    }
    // No <assetId>/variables.json
    else {
      try {
        translInCustomFile = defaultCustomFile?.[type]?.find(x => x?.[idVariable] == code)?.translations[this.translate.currentLang] != null;
        fileParameter = defaultCustomFile?.[type]?.find(x => x?.[idVariable] == code);
      } catch (error) { }

    }

    if (translInCustomFile) {
      return {
        ...fileParameter,
        ...{
          label: fileParameter?.translations?.[this.translate.currentLang],
          message: fileParameter?.translations?.[this.translate.currentLang],
        }
      };
    }

    return {
      label: code,
      message: code,
      code: code,
    };

  }


  // // // // // // // // // // // // // //
  //  CUSTOM TRANSLATIONS  
  // // // // // // // // // // // // // //

  async getCustomTranslations(machineId) {

    let url = '/apif/get-custom-file';
    let fileName = 'customTranslations.json';

    let query: any = {
      fromWeb: this.appInfo?.sources40F != null ? 1 : 0,
      source: this.appInfo?.sources40F ?? '/assets/config/',
      machineId: machineId,
      fileName: fileName,
    };

    // Import specific custom translations
    try {

      let c_customTranslations = this.cacheService.get("customTranslations");

      // Cached custom translations
      if (c_customTranslations == null || c_customTranslations?.machineId != machineId) {
        let request = await this.apiService.sendGetRequest(url, query).toPromise();

        // SET custom translations in cache
        this.cacheService.set("customTranslations", {
          machineId: machineId,
          value: request.body,
        });

      }
    } catch (error) {
      console.log(error);
    }

  }

  buildCustomTranslationsPayload(customTranslations: any) {

    let payload: any = {};

    try {
      if (customTranslations != null && Object.keys(customTranslations).length > 0) {
        for (const [type, values] of Object.entries(customTranslations)) {
          payload[type] = [];
          let value: any = values;
          for (const [paramId, paramValue] of Object.entries(value)) {
            if ((paramValue as any).enabled) payload[type].push(paramId);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
    return payload;
  }

  buildCustomVariablesPayload(customVariables: any) {

    let payload: any = {};

    try {
      if (customVariables != null && Object.keys(customVariables).length > 0) {
        for (const [type, values] of Object.entries(customVariables)) {
          payload[type] = [];
          let value: any = values;
          value?.filter(x => x.enabled == null || x.enabled == true)?.forEach(x => payload[type].push(x.id))
        }
      }
    } catch (error) {
      console.log(error);
    }
    return payload;
  }

  getAlarmTranslation(code: any, customTranslations: any, defaultCustomTranslations: any) {
    return this.buildTranslationsFromFile('alarms', code, customTranslations, defaultCustomTranslations);
  }

  getWarningTranslation(code: any, customTranslations: any, defaultCustomTranslations: any) {
    return this.buildTranslationsFromFile('warnings', code, customTranslations, defaultCustomTranslations);
  }

  getParametersTranslation(code: any, customTranslations: any, defaultCustomTranslations: any) {
    return this.buildTranslationsFromFile('recipes', code, customTranslations, defaultCustomTranslations);
  }

  buildTranslationsFromFile(type: any, code: any, customFile: any, defaultCustomFile: any) {

    // No translation file
    if (defaultCustomFile == null && customFile == null) {
      return {
        label: code,
        message: code,
        code: code,
      };
    }

    let translInCustomFile = false;
    let fileParameter = null;

    // <assetId>/customTranslations.json
    if (customFile != null) {
      try {

        // <assetId>/customTranslations.json
        fileParameter = customFile?.[type]?.[code];

        if (fileParameter != null) {
          translInCustomFile = customFile?.[type]?.[code]?.translations[this.translate.currentLang] != null;
        } else {

          // default-customTranslations.json - No parameter config
          fileParameter = defaultCustomFile?.[type]?.[code];

          if (fileParameter != null) {
            translInCustomFile = defaultCustomFile?.[type]?.[code]?.translations[this.translate.currentLang] != null;
          }
        }

      } catch (error) { }
    }
    // No <assetId>/customTranslations.json
    else {
      try {
        translInCustomFile = defaultCustomFile[type][code].translations[this.translate.currentLang] != null;
        fileParameter = defaultCustomFile[type][code];
      } catch (error) { }

    }

    if (translInCustomFile) {
      return {
        ...fileParameter,
        ...{
          label: fileParameter?.translations?.[this.translate.currentLang],
          message: fileParameter?.translations?.[this.translate.currentLang],
        }
      };
    }

    return {
      label: code,
      message: code,
      code: code,
    };

  }


  // // // // // // //
  //  TRANSLATIONS  //
  // // // // // // //

  getCustomVariableConfig(aspect: any, variable: any, type: any = 'telemetry', machineId: any = null) {

    let c_customVariables = this.cacheService.get("customVariables");

    // Cached custom variables
    if (this.appConfig?.addCustomVariables && c_customVariables?.machineId == machineId && c_customVariables?.value != null) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;
      let varName = aspectName + (variable != null ? '.' + variable : '');

      return this.buildTranslationsFromVariablesFile(type, varName, c_customVariables?.value, this.appConfigService.getDefaultCustomTranslations);
    }

  }

  parseDatapointLabel(aspect: any, variable: any, machineId: any = null, showTicks: boolean = false, returnOnlyAspect: boolean = false) {

    // let c_customTranslations = this.cacheService.get("customTranslations");

    // // Cached custom translations
    // if (c_customTranslations != null && c_customTranslations?.machineId == machineId && c_customTranslations?.value != null && this.appConfig?.addCustomTranslations) {

    //   let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;
    //   let varName = aspectName + (variable != null ? '.' + variable : '');

    //   return this.buildTranslationsFromFile('datapoints', varName, c_customTranslations?.value, this.appConfigService.getDefaultCustomTranslations)?.label;
    // }

    let c_customVariables = this.cacheService.get("customVariables");

    // Cached custom translations
    if (this.appConfig?.addCustomVariables && c_customVariables?.machineId == machineId && c_customVariables?.value != null) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;
      let varName = aspectName + (variable != null ? '.' + variable : '');

      let label = this.buildTranslationsFromVariablesFile('telemetry', varName, c_customVariables?.value, this.appConfigService.getDefaultCustomVariables)?.label;

      if (label != varName) return label;

    }

    return this.parseLabelWithAssetId("DATAPOINTS", aspect, variable, machineId, showTicks, returnOnlyAspect);
  }

  parseDatapointUnit(aspect: any, variable: any, machineId: any = null) {

    let c_customVariables = this.cacheService.get("customVariables");

    // Cached custom variables
    if (this.appConfig?.addCustomVariables && c_customVariables?.machineId == machineId && c_customVariables?.value != null) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;
      let varName = aspectName + (variable != null ? '.' + variable : '');

      return this.buildTranslationsFromVariablesFile('telemetry', varName, c_customVariables?.value, this.appConfigService.getDefaultCustomVariables)?.unit;
    }

    return "";
  }

  /** Translate the given label using the variables.json.
   * 
   * Inside the object "customTranslations", if the "id" corresponding to the input label is found in the corresponding array, overwrite the translation with the custom one.
   */
  parseLabelFromCustomTranslations(label, machineId = null) {

    if (machineId == null) machineId = this.cacheService.get("machineId");

    let parsedLabel = this.parseDatapointLabel(label, null, machineId);

    // If the datapoint has already been translated, which means it is already present in the "telemetry" object in "variables.json", return the translation
    if (label != parsedLabel) return parsedLabel;

    if (!this.appConfig?.addCustomVariables) label = this.translate.instant(label);
    else {
      let customTranslation = this.getCustomVariableConfig(label, null, 'customTranslations', machineId)?.label;

      if (customTranslation != label) label = customTranslation;
      else label = this.translate.instant(label);
    }

    // console.log({ label });

    return label;
  }

  parseParamsRLabel(aspect: any, variable: any, machineId: any = null, showTicks: boolean = false, returnOnlyAspect: boolean = false) {
    return this.parseLabelWithAssetId("PARAMS_R", aspect, variable, machineId, showTicks, returnOnlyAspect);
  }

  parseAlarmsLabel(aspect: any, variable: any, machineId: any = null, showTicks: boolean = false, returnOnlyAspect: boolean = true) {

    let c_customVariables = this.cacheService.get("customVariables");

    // Cached custom translations
    if (this.appConfig?.addCustomVariables && c_customVariables?.machineId == machineId && c_customVariables?.value != null) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;

      return this.buildTranslationsFromVariablesFile('alarms', aspectName, c_customVariables?.value, this.appConfigService.getDefaultCustomVariables)?.[variable];
    }

    let c_customTranslations = this.cacheService.get("customTranslations");

    // Cached custom translations
    if (c_customTranslations != null && c_customTranslations?.machineId == machineId && c_customTranslations?.value != null && this.appConfig?.addCustomTranslations) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;

      return this.getAlarmTranslation(aspectName, c_customTranslations?.value, this.appConfigService.getDefaultCustomTranslations)?.[variable];
    }

    return this.parseLabelWithAssetId("alarms", aspect, variable, machineId, showTicks, returnOnlyAspect);
  }

  parseCausesLabel(aspect: any, variable: any, machineId: any = null, showTicks: boolean = false, returnOnlyAspect: boolean = true) {

    let c_customVariables = this.cacheService.get("customVariables");

    // Cached custom variables
    if (this.appConfig?.addCustomVariables && c_customVariables?.machineId == machineId && c_customVariables?.value != null) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;

      return this.buildTranslationsFromVariablesFile('causes', aspectName, c_customVariables?.value, this.appConfigService.getDefaultCustomVariables)?.label;
    }

    let c_customTranslations = this.cacheService.get("customTranslations");

    // Cached custom translations
    if (c_customTranslations != null && c_customTranslations?.machineId == machineId && c_customTranslations?.value != null && this.appConfig?.addCustomTranslations) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;

      return this.buildTranslationsFromFile("causes", aspectName, c_customTranslations?.value, this.appConfigService.getDefaultCustomTranslations)?.label;
    }

    return this.parseLabelWithAssetId("causes", aspect, variable, machineId, showTicks, returnOnlyAspect);
  }

  parseWarningsLabel(aspect: any, variable: any, machineId: any = null, showTicks: boolean = false, returnOnlyAspect: boolean = true) {

    let c_customVariables = this.cacheService.get("customVariables");

    // Cached custom translations
    if (this.appConfig?.addCustomVariables && c_customVariables?.machineId == machineId && c_customVariables?.value != null) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;

      return this.buildTranslationsFromVariablesFile('warnings', aspectName, c_customVariables?.value, this.appConfigService.getDefaultCustomVariables)?.[variable];
    }

    let c_customTranslations = this.cacheService.get("customTranslations");

    // Cached custom translations
    if (c_customTranslations != null && c_customTranslations?.machineId == machineId && c_customTranslations?.value != null && this.appConfig?.addCustomTranslations) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;

      return this.getWarningTranslation(aspectName, c_customTranslations?.value, this.appConfigService.getDefaultCustomTranslations)?.[variable];
    }

    return this.parseLabelWithAssetId("alarms", aspect, variable, machineId, showTicks, returnOnlyAspect);
  }

  parseRecipeLabel(aspect: any, variable: any, machineId: any = null, showTicks: boolean = false, returnOnlyAspect: boolean = true) {

    let c_customVariables = this.cacheService.get("customVariables");

    // Cached custom translations
    if (this.appConfig?.addCustomVariables && c_customVariables?.machineId == machineId && c_customVariables?.value != null) {

      return this.buildTranslationsFromVariablesFile('recipes', aspect, c_customVariables?.value, this.appConfigService.getDefaultCustomVariables);
    }

    let c_customTranslations = this.cacheService.get("customTranslations");

    // Cached custom translations
    if (c_customTranslations != null && c_customTranslations?.machineId == machineId && c_customTranslations?.value != null && this.appConfig?.addCustomTranslations) {

      return this.getParametersTranslation(aspect, c_customTranslations?.value, this.appConfigService.getDefaultCustomTranslations);
    }

    return this.parseLabelWithAssetId("recipes", aspect, variable, machineId, showTicks, returnOnlyAspect);
  }

  parseVariablesLabel(aspect: any, variable: any, machineId: any = null, showTicks: boolean = false, returnOnlyAspect: boolean = false, aspectForCustomTransl: any = null) {

    let c_customVariables = this.cacheService.get("customVariables");

    // Cached custom variables
    if (this.appConfig?.addCustomVariables && c_customVariables?.machineId == machineId && c_customVariables?.value != null) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;
      let varName = (aspectForCustomTransl != null ? (aspectForCustomTransl + '.') : '') + aspectName + (variable != null ? '.' + variable : '');

      return this.buildTranslationsFromVariablesFile('telemetry', varName, c_customVariables?.value, this.appConfigService.getDefaultCustomVariables)?.label;
    }

    let c_customTranslations = this.cacheService.get("customTranslations");

    // Cached custom translations
    if (c_customTranslations != null && c_customTranslations?.machineId == machineId && c_customTranslations?.value != null && this.appConfig?.addCustomTranslations) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;
      let varName = (aspectForCustomTransl != null ? (aspectForCustomTransl + '.') : '') + aspectName + (variable != null ? '.' + variable : '');

      return this.buildTranslationsFromFile('datapoints', varName, c_customTranslations?.value, this.appConfigService.getDefaultCustomTranslations)?.label;
    }

    return this.parseLabelWithAssetId("CONTINUOUS_EXPLORATION.TRACES", aspect, variable, machineId, showTicks, returnOnlyAspect);
  }

  parseButtonsLabel(aspect: any, variable: any, machineId: any = null, showTicks: boolean = false, returnOnlyAspect: boolean = false) {

    let c_customVariables = this.cacheService.get("customVariables");

    // Cached custom variables
    if (this.appConfig?.addCustomVariables && c_customVariables?.machineId == machineId && c_customVariables?.value != null) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;
      let varName = aspectName + (variable != null ? '.' + variable : '');

      return this.buildTranslationsFromVariablesFile('buttons', varName, c_customVariables?.value, this.appConfigService.getDefaultCustomVariables)?.label;
    }

    return this.parseLabelWithAssetId("CONTINUOUS_EXPLORATION.BUTTONS", aspect, variable, machineId, showTicks, returnOnlyAspect);
  }

  parseComponentsLabel(aspect: any, variable: any, machineId: any = null, showTicks: boolean = false, returnOnlyAspect: boolean = false) {

    let c_customVariables = this.cacheService.get("customVariables");

    // Cached custom variables
    if (this.appConfig?.addCustomVariables && c_customVariables?.machineId == machineId && c_customVariables?.value != null) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;
      let varName = aspectName + (variable != null ? '.' + variable : '');

      return this.buildTranslationsFromVariablesFile('components', varName, c_customVariables?.value, this.appConfigService.getDefaultCustomVariables)?.label;
    }

    let c_customTranslations = this.cacheService.get("customTranslations");

    // Cached custom translations
    if (c_customTranslations != null && c_customTranslations?.machineId == machineId && c_customTranslations?.value != null && this.appConfig?.addCustomTranslations) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;
      let varName = aspectName + (variable != null ? ('.' + variable) : '');

      return this.buildTranslationsFromFile('components', varName, c_customTranslations?.value, this.appConfigService.getDefaultCustomTranslations)?.label;
    }

    return this.parseLabelWithAssetId("components", aspect, variable, machineId, showTicks, returnOnlyAspect);

  }

  parseMachinesLabel(aspect: any, variable: any, machineId: any = null, showTicks: boolean = false, returnOnlyAspect: boolean = false) {

    let c_customVariables = this.cacheService.get("customVariables");

    // Cached custom variables
    if (this.appConfig?.addCustomVariables && c_customVariables?.machineId == machineId && c_customVariables?.value != null) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;
      let varName = aspectName + (variable != null ? '.' + variable : '');

      return this.buildTranslationsFromVariablesFile('machines', varName, c_customVariables?.value, this.appConfigService.getDefaultCustomVariables)?.label;
    }

    let c_customTranslations = this.cacheService.get("customTranslations");

    // Cached custom translations
    if (c_customTranslations != null && c_customTranslations?.machineId == machineId && c_customTranslations?.value != null && this.appConfig?.addCustomTranslations) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;
      let varName = aspectName + (variable != null ? ('.' + variable) : '');

      return this.buildTranslationsFromFile('machines', varName, c_customTranslations?.value, this.appConfigService.getDefaultCustomTranslations)?.label;
    }

    return this.parseLabelWithAssetId("machines", aspect, variable, machineId, showTicks, returnOnlyAspect);
  }

  parseConsSupplyAlertsLabel(aspect: any, variable: any, machineId: any = null, showTicks: boolean = false, returnOnlyAspect: boolean = false) {

    let c_customTranslations = this.cacheService.get("customTranslations");

    // Cached custom translations
    if (c_customTranslations != null && c_customTranslations?.machineId == machineId && c_customTranslations?.value != null && this.appConfig?.addCustomTranslations) {

      let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;
      let varName = aspectName + (variable != null ? ('.' + variable) : '');

      return this.buildTranslationsFromFile('consSupplyAlerts', varName, c_customTranslations?.value, this.appConfigService.getDefaultCustomTranslations)?.label;
    }

    return this.parseLabelWithAssetId("CONSUMPTION_SUPPLY_ALERTS", aspect, variable, machineId, showTicks, returnOnlyAspect);
  }

  parseLabelWithAssetId(type: any, aspect: any, variable: any, machineId: any = null, showTicks: boolean = false, returnOnlyAspect: boolean = false) {

    const checkTranslPipe = new CheckTranslationPipe(this.translate);

    let aspectName = (aspect != null && typeof aspect == 'string') ? aspect.replace(/\$\$\$/g, ' - ') : aspect;;
    let varName = aspectName + (variable != null ? '.' + variable : '');

    // First translation: <type>.<machineId>.<aspect.variable>
    let firstTranslation = checkTranslPipe.transform(
      type + "." + machineId + "." + varName,
      type + ".default." + varName,
      false,
      true
    );
    // console.log('firstTranslation', firstTranslation);
    if (firstTranslation.isTranslated) return firstTranslation.translation;

    // Second translation: <type>.default.<aspect.variable>
    let secondTranslation = checkTranslPipe.transform(
      firstTranslation.translation,
      type + "." + varName,
      false,
      true
    );
    if (secondTranslation.isTranslated) return secondTranslation.translation;

    // Third translation: <type>.<aspect.variable>
    let thirdTranslation = checkTranslPipe.transform(
      secondTranslation.translation,
      returnOnlyAspect ? aspectName : varName,
      false,
      true
    );
    // console.log('thirdTranslation', thirdTranslation);
    if (thirdTranslation.isTranslated) return thirdTranslation.translation;

    // Last translation: <aspect.variable>
    let lastTranslation = checkTranslPipe.transform(
      thirdTranslation.translation,
      returnOnlyAspect ? aspectName : varName,
      showTicks,
      true
    );
    // console.log('lastTranslation', lastTranslation);
    return lastTranslation.translation;
  }

}
