import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'ff-dashboard-dialog',
  templateUrl: './ff-dashboard-dialog.component.html',
  styleUrls: ['./ff-dashboard-dialog.component.scss']
})
export class FfDashboardDialogComponent implements OnInit, OnDestroy {

  public completeDashboardConfig: any;
  public loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<FfDashboardDialogComponent>,
    public http: ApiService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
  ) { }

  closeDialog() { this.dialogRef.close(this.dialog?.row) }

  ngOnInit() {
    if (this.dialog?.type == "await") {
      this.loading = true;
      this.asyncFunct(this.dialog?._this, this.dialog?.infos);
    } else {
      this.completeDashboardConfig = this.dialog?.completeDashboardConfig;
    }
  }

  async asyncFunct(_this, infos) {

    let url = '/apif/cons-detail/' + _this.machineId;

    let query = {
      aggr: infos.xVal.id,
      cons: infos.yVal.id,
      aggrType: infos.xVal.type,
      from: _this.interval.start,
      to: _this.interval.end,
      tz: _this.machine.timezone,
    }

    let resp = await this.http.sendGetRequest(url, query).toPromise();
    this.loading = false;
    let body: any = resp?.body;
    let dashboardConfig = null;

    if (infos.xVal.type != 'continuous') {

      let tracesConfig = body.map(trace => {
        return {
          "variableX": "id_" + trace.id,
          "variableY": "values_" + trace.id,
          "label": trace.id,
          "type": "box",
          "boxpoints": false,
          "multiplier": infos.yVal?.relativeMultiplier ?? 1,
          "unit": this.translate.instant(infos.xVal.label ?? '-')
        }
      });

      body = body?.reduce((acc, val) => {
        let id = "id_" + val.id;
        acc[id] = val.values.map(_ => val.id);
        acc["values_" + val.id] = val.values;
        return acc;
      }, {})

      dashboardConfig = {
        widgets: [
          {
            "type": "ff-plotly-chart",
            "flex": 100,
            "customMinHeight": "calc(80vh - 120px)",
            "customHeight": "calc(80vh - 120px)",
            "config": {
              "plotDataAttribute": "plot",
              // "plotFormatType": "arrayOfObjects",
              "plotConfig": {
                "traces": tracesConfig,
                "layout": {
                  "legend": {
                    "x": 0,
                    "y": -0.25,
                    "orientation": "h"
                  },
                  "hoverlabel": {
                    "align": "left"
                  },
                  "xaxis": {
                    "showgrid": false,
                    "zeroline": false,
                    "title": this.translate.instant(infos.xVal.label),
                  },
                  "yaxis": {
                    "showgrid": false,
                    "zeroline": false,
                    "title": this.translate.instant(infos.yVal.relativeUnit ?? '-') + '/' + this.filterService.getProductionUnit("singleUnit", _this.machine?.profile?.productionConfig)
                  },
                  "margin": {
                    "t": 30,
                    "r": 60,
                    "b": 60,
                    "l": 60,
                    "pad": 5
                  }
                },
                "params": {
                  "responsive": true
                }
              }
            }
          }
        ]
      };
    }
    else {

      let tracesConfig = [{
        "variableX": "x",
        "variableY": "y",
        "label": "GLOBAL.COST_PER_PIECE",
        "labelXAxis": infos.xVal.label,
        "unitXAxis": infos.xVal.unit,
        "multiplier": infos.yVal?.relativeMultiplier ?? 1,
        "type": "scattergl",
        "mode": "markers",
        "unit": this.translate.instant(infos.yVal.relativeUnit ?? '-') + '/' + this.filterService.getProductionUnit("singleUnit", _this.machine?.profile?.productionConfig)
      },
      {
        "variableX": "xFit",
        "variableY": "yFit",
        "label": "GLOBAL.COST_PER_PIECE_TRND",
        "multiplier": infos.yVal?.relativeMultiplier ?? 1,
        "type": "scatter",
        "mode": "lines",
        "unit": "GLOBAL.PRICE_PER_UNIT"
      },
      ];

      dashboardConfig = {
        widgets: [
          {
            "type": "ff-plotly-chart",
            "flex": 100,
            "customMinHeight": "calc(80vh - 120px)",
            "customHeight": "calc(80vh - 120px)",
            "config": {
              "plotDataAttribute": "plot",
              // "plotFormatType": "arrayOfObjects",
              "plotConfig": {
                "traces": tracesConfig,
                "layout": {
                  "showlegend": false,
                  "hoverlabel": {
                    "align": "left"
                  },
                  "xaxis": {
                    "showgrid": false,
                    "zeroline": false,
                    "title": this.translate.instant(infos.xVal.label) + (infos.xVal.unit != null ? (' [' + infos.xVal.unit + ']') : ''),
                  },
                  "yaxis": {
                    "showgrid": false,
                    "zeroline": false,
                    "title": this.translate.instant(infos.yVal.relativeUnit ?? '-') + '/' + this.filterService.getProductionUnit("singleUnit", _this.machine?.profile?.productionConfig)
                  },
                  "margin": {
                    "t": 30,
                    "r": 60,
                    "b": 60,
                    "l": 60,
                    "pad": 5
                  }
                },
                "params": {
                  "responsive": true
                }
              }
            }
          }
        ]
      };
    }


    this.completeDashboardConfig = {
      dashboardData: { "plot": body },
      dashboardConfig: dashboardConfig,
    };
  }

  ngOnDestroy() { }

}
