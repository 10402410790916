import { Pipe } from "@angular/core";
import { PipeTransform } from "@angular/core";

@Pipe({
    name: 'filterByProperty'
})
export class FilterByPropertyPipe implements PipeTransform {
    transform(list: any, property: any) {

        if (!list?.length) return [];
        if (property == null) return list;
        if (property.length == 3) {
            let propertyName = property[0];
            let operator = property[1];
            let propertyValue = property[2];

            let multipleCheckOperator = null;

            if (operator != null && operator.includes("$$")) {
                try { multipleCheckOperator = operator.split("$$")[1] } catch (error) { }
                try { operator = operator.split("$$")[0] } catch (error) { }
            }

            switch (operator) {
                case '==':
                default:
                    return list.filter((x: any) => x[propertyName] == propertyValue);
                case '!=':
                    return list.filter((x: any) => x[propertyName] != propertyValue);
                case 'in':

                    if (multipleCheckOperator != null) {
                        if (multipleCheckOperator == 'every') return list.filter((x: any) => x[propertyName].every(v => v == propertyValue));
                        else if (multipleCheckOperator == 'some') return list.filter((x: any) => x[propertyName].some(v => v == propertyValue));
                    }

                    return list.filter((x: any) => x[propertyName].includes(propertyValue));
                case 'not_in':

                    if (multipleCheckOperator != null) {
                        if (multipleCheckOperator == 'every') return list.filter((x: any) => !x[propertyName].every(v => v == propertyValue));
                        else if (multipleCheckOperator == 'some') return list.filter((x: any) => !x[propertyName].some(v => v == propertyValue));
                    }

                    return list.filter((x: any) => !x[propertyName].includes(propertyValue));
                case 'includes':
                    return list.filter((x: any) => propertyName.includes(x[propertyValue]));
                case '!includes':
                    return list.filter((x: any) => !propertyName.includes(x[propertyValue]));
            }
        }
        return list;
    }
}