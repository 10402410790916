import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject, OnChanges, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from "@angular/platform-browser";
import { Subscription } from 'rxjs';
import { AppConfigService } from './services/app-config.service';
import { InternalDataService } from './services/internal-data.service';
import { MobileService } from './services/mobile.service';

declare var _mdsp: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None // Use css globally
})

export class AppComponent implements OnInit, OnChanges {

  public appInfo: any;
  public appName: any;
  public mobileListener: Subscription;
  public isSmThanTablet: boolean;
  public isMobile: boolean;

  public hideToolbar: boolean = false;
  hideToolbarSub: Subscription;

  constructor(
    private titleService: Title,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public appConfigService: AppConfigService,
    public mobile: MobileService,
    public internalDataService: InternalDataService,
    @Inject(DOCUMENT) private _document: Document,
    private _renderer2: Renderer2,
  ) {

    this.mobileListener = this.mobile.mobileListener.subscribe((value) => {
      this.isMobile = value.isMobile;
      this.isSmThanTablet = value.isSmThanTablet
    })

    this.hideToolbarSub = this.internalDataService.hideToolbar.subscribe(value => this.hideToolbar = value);

    this.appInfo = this.appConfigService.getAppInfo;

    this.appName = 'MAT';
    if (this.appInfo.displayName != null) {
      this.appName = this.appInfo.displayName
    }
    this.setTitle(this.appName);

    this.matIconRegistry.addSvgIcon('health', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/health.svg'));
    this.matIconRegistry.addSvgIcon('eye', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/eye.svg'));
    this.matIconRegistry.addSvgIcon('factory', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/factory.svg'));
    this.matIconRegistry.addSvgIcon('timer_quarter', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/timer_quarter.svg'));
    this.matIconRegistry.addSvgIcon('waiting_time', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/waiting_time.svg'));
    this.matIconRegistry.addSvgIcon('circle', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/circle.svg'));
    this.matIconRegistry.addSvgIcon('curtain_right', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/curtain_right.svg'));
    this.matIconRegistry.addSvgIcon('arrow_left', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/arrow_left.svg'));
    this.matIconRegistry.addSvgIcon('arrow_right', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/arrow_right.svg'));
    this.matIconRegistry.addSvgIcon('arrow_down', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/arrow_down.svg'));
    this.matIconRegistry.addSvgIcon('arrow_up', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/arrow_up.svg'));
    this.matIconRegistry.addSvgIcon('energy', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/energy.svg'));
    this.matIconRegistry.addSvgIcon('radio_button', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/radio_button.svg'));
    this.matIconRegistry.addSvgIcon('radio_button_disabled', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/radio_button_disabled.svg'));
    this.matIconRegistry.addSvgIcon('curtain_down', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/curtain_down.svg'));
    this.matIconRegistry.addSvgIcon('curtain_up', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/curtain_up.svg'));
    this.matIconRegistry.addSvgIcon('faults', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/faults.svg'));
    this.matIconRegistry.addSvgIcon('question_mark', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/question_mark.svg'));
    this.matIconRegistry.addSvgIcon('machine_recorder', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/machine_recorder.svg'));
    this.matIconRegistry.addSvgIcon('continuous_exploration', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/continuous_exploration.svg'));
    this.matIconRegistry.addSvgIcon('state_change', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/state_change.svg'));
    this.matIconRegistry.addSvgIcon('scraps_analytics', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/scraps_analytics.svg'));
    this.matIconRegistry.addSvgIcon('total_prod', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/total_prod.svg'));
    this.matIconRegistry.addSvgIcon('rba', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/rba.svg'));
    this.matIconRegistry.addSvgIcon('touch', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/touch.svg'));
    this.matIconRegistry.addSvgIcon('sad_face', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/sad_face.svg'));
    this.matIconRegistry.addSvgIcon('customer-service', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/customer-service.svg'));
    this.matIconRegistry.addSvgIcon('video_streaming', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/video_streaming.svg'));
    this.matIconRegistry.addSvgIcon('image-recognition', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/image-recognition.svg'));
    this.matIconRegistry.addSvgIcon('webcam', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/webcam.svg'));
    this.matIconRegistry.addSvgIcon('beer', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/beer.svg'));
    this.matIconRegistry.addSvgIcon('machine_model', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/machine_model.svg'));
    this.matIconRegistry.addSvgIcon('thickness', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/thickness.svg'));
    this.matIconRegistry.addSvgIcon('dwg', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/dwg.svg'));
    this.matIconRegistry.addSvgIcon('bot_sad', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/bot_sad.svg'));
    this.matIconRegistry.addSvgIcon('delorean', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/delorean.svg'));
    this.matIconRegistry.addSvgIcon('fault_trend', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/fault_trend.svg'));
    this.matIconRegistry.addSvgIcon('pasta-rigatoni', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/pasta-rigatoni.svg'));
    this.matIconRegistry.addSvgIcon('pasta-fusilli', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/pasta-fusilli.svg'));
    this.matIconRegistry.addSvgIcon('pasta-farfalle', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/pasta-farfalle.svg'));
    this.matIconRegistry.addSvgIcon('cassette-tape', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/cassette-tape.svg'));
    this.matIconRegistry.addSvgIcon('barrel', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/barrel.svg'));
    this.matIconRegistry.addSvgIcon('pineapple', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/pineapple.svg'));
    this.matIconRegistry.addSvgIcon('orange', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/orange.svg'));
    this.matIconRegistry.addSvgIcon('orange-slice', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/orange-slice.svg'));
    this.matIconRegistry.addSvgIcon('gintonic', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/gintonic.svg'));
    this.matIconRegistry.addSvgIcon('aperol', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/aperol.svg'));
    this.matIconRegistry.addSvgIcon('cocacola', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/cocacola.svg'));
    this.matIconRegistry.addSvgIcon('wine', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/wine.svg'));

    this.matIconRegistry.addSvgIcon('box_label_1', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_1.svg'));
    this.matIconRegistry.addSvgIcon('box_label_2', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_2.svg'));
    this.matIconRegistry.addSvgIcon('box_label_3', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_3.svg'));
    this.matIconRegistry.addSvgIcon('box_label_4', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_4.svg'));
    this.matIconRegistry.addSvgIcon('box_label_5', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_5.svg'));
    this.matIconRegistry.addSvgIcon('box_label_6', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_6.svg'));
    this.matIconRegistry.addSvgIcon('box_label_7', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_7.svg'));
    this.matIconRegistry.addSvgIcon('box_label_8', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_8.svg'));
    this.matIconRegistry.addSvgIcon('box_label_9', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_9.svg'));
    this.matIconRegistry.addSvgIcon('box_label_10', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_10.svg'));
    this.matIconRegistry.addSvgIcon('box_label_11', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_11.svg'));
    this.matIconRegistry.addSvgIcon('box_label_12', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_12.svg'));
    this.matIconRegistry.addSvgIcon('box_label_13', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_13.svg'));
    this.matIconRegistry.addSvgIcon('box_label_14', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_14.svg'));
    this.matIconRegistry.addSvgIcon('box_label_15', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_15.svg'));
    this.matIconRegistry.addSvgIcon('box_label_16', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_16.svg'));
    this.matIconRegistry.addSvgIcon('box_label_17', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_17.svg'));
    this.matIconRegistry.addSvgIcon('box_label_18', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_18.svg'));
    this.matIconRegistry.addSvgIcon('box_label_19', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_19.svg'));
    this.matIconRegistry.addSvgIcon('box_label_20', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/box_label_20.svg'));

    this.matIconRegistry.addSvgIcon('face_sad', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/face_sad.svg'));
    this.matIconRegistry.addSvgIcon('face_dumb', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/face_dumb.svg'));
    this.matIconRegistry.addSvgIcon('face_happy', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/face_happy.svg'));

    try { this.matIconRegistry.addSvgIcon('logo-01', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/logo-01.svg')) } catch (error) { }
    // try { this.matIconRegistry.addSvgIcon('logo-02', this.domSanitizer.bypassSecurityTrustResourceUrl('./assets/images/logo-02.svg')) } catch (error) { }

    this.matIconRegistry.addSvgIcon('filter_cycle', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/filter_cycle.svg'));
    this.matIconRegistry.addSvgIcon('acid_wash', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/acid_wash.svg'));
    this.matIconRegistry.addSvgIcon('high_pressure_wash', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/high_pressure_wash.svg'));
    this.matIconRegistry.addSvgIcon('zoom_in_map', this.domSanitizer.bypassSecurityTrustResourceUrl('./statics/icons/zoom_in_map.svg'));
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  ngOnInit() {

    if (this.appInfo.mindsphere) {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.onload = function () {
        _mdsp.init({
          appId: "_mdspcontent",
          appInfoPath: "./assets/config/app-info.json",
          initialize: true
        });
      };
      script.src = 'https://static.eu1.mindsphere.io/osbar/v4/js/main.min.js';
      document.getElementsByTagName('head')[0].appendChild(script);
    }

    if (this.appInfo.bugherd?.apiKey) {
      let attrs = [["async", "true"]];
      this._createScriptTag(`https://www.bugherd.com/sidebarv2.js?apikey=${this.appInfo.bugherd?.apiKey}`, attrs);
    }

    // TODO: import azure media player and vjs only for apps with video streaming enabled
    // TODO: Import AMP and VJS not from the web

    // let attrs = [["defer", "true"]];
    // this._createScriptTag(`./statics/fontawesome-pro-5.15.3-web/js/all.min.js`, attrs, 'first');
    // this._createScriptTag(`https://amp.azure.net/libs/amp/2.3.8/azuremediaplayer.min.js`, attrs, 'first');

  }

  private _createScriptTag(src: string, attributes: any = [], position: any = "last") {
    const script = this._renderer2.createElement('script');
    script.type = 'text/javascript';

    attributes?.forEach(attr => script.setAttribute(attr[0], attr[1]))

    script.src = src;

    const head = this._document.getElementsByTagName('head')[0];
    head.insertBefore(script, position == 'first' ? head.firstChild : head.lastChild);
  }

  ngOnChanges() { }

  @HostListener('window:resize', ['$event'])
  onResize() { }

}
