<div fxLayout="row" fxLayoutAlign="center center" style="padding: 1rem;">
    <span class="title">{{dialog.title | translate }}</span>
</div>
<mat-dialog-content *ngIf="dialog.description" class="mat-typography">
    <div fxLayout="row" fxLayoutAlign="center center" style="max-width: 500px;">
        <div fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="8px">
            <div *ngFor="let variable of dialog.description | keyvalue" fxLayout="row" fxLayoutAlign="center center"
                class="w100">
                <span fxFlex="30" style="font-weight: 600;">{{ variable.key }}</span>
                <span fxFlex="70">{{ variable.value }}</span>
            </div>
        </div>
    </div>
</mat-dialog-content>