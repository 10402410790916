<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

    <!-- tab content -->
    <div class="tab-content pad-x-2em pad-y-1em widpad-x-2em h100 w100 without-tabs" fxLayout="column"
        fxLayoutAlign="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start stretch' }}">

        <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
        <!-- loading -->
        <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData"
            style="margin: auto;">
        </ff-loading-card>

        <div *ngIf="pageState.value >= pageStateReady" fxLayout="row" fxLayoutAlign="start start" class="h100 w100"
            fxLayoutGap="8px">
            <ff-synoptic-widget [datapoint]="datapoint"></ff-synoptic-widget>
        </div>

    </div>

</div>