<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- save button -->
    <div *ngIf="!isMobile && isAllowedUserWrite && pageState.value >= pageStateReady && operatorsList.length == 0"
      style="margin-right: 8px;">
      <button mat-button class="button button-1" (click)="addOp()"
        matTooltip="{{ 'OPERATOR_IDS.ADD_OPERATOR' | translate }}">
        <mat-icon class="side">add</mat-icon>
      </button>
    </div>

    <!-- save button -->
    <div *ngIf="!isMobile && isAllowedUserWrite && pageState.value >= pageStateReady">
      <button mat-button class="filter-button" (click)="saveOperatorsList()" [disabled]="invalidFormCheck()">
        <mat-icon class="side">save</mat-icon>
      </button>
    </div>

  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" class="tab-content" [ngClass]="{ 'without-tabs': tabs.length <= 1}"
    fxLayoutAlign="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start start' }}">

    <!-- error -->
    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
    </ff-loading-card>

    <div *ngIf="pageState.value >= pageStateReady && operatorsList.length == 0" fxLayout="row"
      fxLayoutAlign="center center" class="w100" style="min-height: 100px;">
      <div class="not-found" fxLayout="row" fxLayoutAlign="center center">
        <span>{{ 'OPERATOR_IDS.NOT_FOUND' | translate }}</span>
      </div>
    </div>

    <!-- tab content -->
    <div *ngIf="operatorsList?.length > 0" fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content">
      <div fxLayout="row wrap" fxLayoutAlign="start stretch" class="w100">

        <mat-card class="standard-mat-card" fxFlex="0 1 calc(50% - 8px)"
          *ngFor="let operator of operatorsList; let i = index" [data-attr.index]="i" style="margin: 8px 4px 0 4px">
          <div fxLayout="row" fxLayoutAlign="center center">
            <mat-form-field appearance="standard" fxFlex="90">
              <mat-label style="font-size: 1rem;">{{ i + 1 }}</mat-label>
              <input style="font-size: 1.2rem;" required matInput [(ngModel)]="operator.name"
                [disabled]="!isAllowedUserWrite">
              <mat-error>{{ 'GLOBAL.REQUIRED' | translate }}</mat-error>
            </mat-form-field>
            <div fxFlex="10" style="margin-left: 1rem">
              <button *ngIf="isAllowedUserWrite" class="button md-red" (click)="deleteOp(i)"
                style="padding: 1rem !important; border: none; border-radius: 50%;">
                <mat-icon class="icon">delete</mat-icon>
              </button>
            </div>
          </div>
        </mat-card>

        <mat-card *ngIf="isAllowedUserWrite" fxFlex="0 1 calc(10% - 8px)"
          style="margin: 8px 4px 0 4px; background-color: transparent;">
          <div fxLayout="row" fxLayoutAlign="start center">
            <button class="button" (click)="addOp()" matTooltip="{{ 'OPERATOR_IDS.ADD_OPERATOR' | translate }}"
              style="padding: 1.2rem !important; border: none; border-radius: 50%; margin-top: 0.8rem;">
              <mat-icon class="icon">add</mat-icon>
            </button>
          </div>
        </mat-card>

      </div>
    </div>

  </div>

</div>