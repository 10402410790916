import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment-timezone';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { CustomIntervalDialogComponent } from '../components/interval-selector/custom-interval-dialog/custom-interval-dialog.component';
import { ApiService } from './api.service';
import { CacheService } from './cache.service';
import { ClonerService } from './clone.service';
import { InternalDataService } from './internal-data.service';

@Injectable({
    providedIn: 'root'
})
export class IntervalService {

    constructor(
        public internalDataService: InternalDataService,
        public clonerService: ClonerService,
        public cacheService: CacheService,
        public http: ApiService,
        public snackBar: MatSnackBar,
        public translate: FfTranslateService,
    ) { }
    shortDateFormat = "MMM DD, YYYY";
    shortDateFormat2 = "MMM DD, YYYY, HH:mm";

    intervalArrayDefault = [
        {
            id: "last30Minutes",
            enabledPolling: true,
            label: "INTERVAL.LAST_30_MINUTES",
            // start: moment().subtract(30, 'minutes'),
            // end: moment(),
            startFunction: ['subtract', 30, 'minutes'],
            endFunction: [],
            intervalUnit: { unit: "minute", value: 1 },
            format: 'MMM DD, YYYY - HH:mm:ss'
        },
        {
            id: "lastHour",
            enabledPolling: true,
            label: "INTERVAL.LAST_HOUR",
            // start: moment().subtract(1, "hours"),
            // end: moment(),
            startFunction: ['subtract', 60, 'minutes'],
            endFunction: [],
            intervalUnit: { unit: "hour", value: 1 },
            format: 'MMM DD, YYYY - HH:mm:ss'
        },
        {
            id: "last2Hours",
            enabledPolling: true,
            label: "INTERVAL.LAST_2_HOURS",
            // start: moment().subtract(2, "hours"),
            // end: moment(),
            startFunction: ['subtract', 120, 'minutes'],
            endFunction: [],
            intervalUnit: { unit: "hour", value: 1 },
            format: 'MMM DD, YYYY - HH:mm:ss'
        },
        {
            id: "last4Hours",
            enabledPolling: true,
            label: "INTERVAL.LAST_4_HOURS",
            // start: moment().subtract(4, "hours"),
            // end: moment(),
            startFunction: ['subtract', 240, 'minutes'],
            endFunction: [],
            intervalUnit: { unit: "hour", value: 1 },
            format: 'MMM DD, YYYY - HH:mm:ss'
        },
        {
            id: "last8Hours",
            enabledPolling: true,
            label: "INTERVAL.LAST_8_HOURS",
            // start: moment().subtract(8, "hours"),
            // end: moment(),
            startFunction: ['subtract', 480, 'minutes'],
            endFunction: [],
            intervalUnit: { unit: "hour", value: 1 },
            format: 'MMM DD, YYYY - HH:mm:ss'
        },
        {
            id: "last12Hours",
            enabledPolling: true,
            label: "INTERVAL.LAST_12_HOURS",
            // start: moment().subtract(12, "hours"),
            // end: moment(),
            startFunction: ['subtract', 12, 'hours'],
            endFunction: [],
            intervalUnit: { unit: "hour", value: 1 },
            format: 'MMM DD, YYYY - HH:mm:ss'
        },
        {
            id: "last24Hours",
            enabledPolling: true,
            label: "INTERVAL.LAST_24_HOURS",
            // start: moment().subtract(24, "hours"),
            // end: moment(),
            startFunction: ['subtract', 24, 'hours'],
            endFunction: [],
            intervalUnit: { unit: "hour", value: 1 },
            format: 'MMM DD, YYYY - HH:mm:ss'
        },
        {
            id: "today",
            enabledPolling: true,
            label: "INTERVAL.TODAY",
            // start: moment().startOf("day"),
            // end: moment(),
            startFunction: ['startOf', 'day'],
            endFunction: [],
            intervalUnit: { unit: "hour", value: 1 },
            format: 'MMM DD, YYYY'
        },
        {
            id: "yesterday",
            enabledPolling: false,
            label: "INTERVAL.YESTERDAY",
            // start: moment().subtract(1, 'days').startOf("day"),
            // end: moment().subtract(1, 'days').endOf("day"),
            startFunction: ['subtract', 24, 'hours', 'startOf', 'day'],
            endFunction: ['subtract', 24, 'hours', 'endOf', 'day'],
            intervalUnit: { unit: "hour", value: 1 },
            format: 'MMM DD, YYYY'
        },
        {
            id: "last7Days",
            enabledPolling: true,
            numberOfDays: 7,
            label: "INTERVAL.LAST_7_DAYS",
            // start: moment().subtract(7, 'days').startOf('day'),
            // end: moment(),
            startFunction: ['subtract', 6, 'days', 'startOf', 'day'],
            endFunction: [],
            intervalUnit: { unit: "day", value: 1 },
            format: 'MMM DD, YYYY'
        },
        {
            id: "last30Days",
            enabledPolling: true,
            numberOfDays: 30,
            label: "INTERVAL.LAST_30_DAYS",
            // start: moment().subtract(30, 'days').startOf('day'),
            // end: moment(),
            startFunction: ['subtract', 29, 'days', 'startOf', 'day'],
            endFunction: [],
            intervalUnit: { unit: "day", value: 1 },
            format: 'MMM DD, YYYY'
        },
        {
            id: "last90Days",
            enabledPolling: true,
            label: "INTERVAL.LAST_90_DAYS",
            // start: moment().subtract(90, 'days').startOf('day'),
            // end: moment(),
            startFunction: ['subtract', 89, 'days', 'startOf', 'day'],
            endFunction: [],
            intervalUnit: { unit: "day", value: 1 },
            format: 'MMM DD, YYYY'
        },
        {
            id: "lastWeek",
            enabledPolling: false,
            label: "INTERVAL.LAST_WEEK",
            // start: moment().subtract(1, 'week').startOf('week'),
            // end: moment().subtract(1, 'week').endOf('week'),
            startFunction: ['subtract', 1, 'week', 'startOf', 'week'],
            endFunction: ['subtract', 1, 'week', 'endOf', 'week'],
            intervalUnit: { unit: "day", value: 1 },
            format: 'MMM DD, YYYY'
        },
        {
            id: "lastMonth",
            enabledPolling: false,
            label: "INTERVAL.LAST_MONTH",
            // start: moment().subtract(1, 'month').startOf('month'),
            // end: moment().subtract(1, 'month').endOf('month'),
            startFunction: ['subtract', 1, 'month', 'startOf', 'month'],
            endFunction: ['subtract', 1, 'month', 'endOf', 'month'],
            intervalUnit: { unit: "day", value: 1 },
            format: 'MMM DD, YYYY'
        },
        {
            id: "last3Months",
            enabledPolling: false,
            label: "INTERVAL.LAST_3_MONTHS",
            // start: moment().subtract(3, 'month').startOf('month'),
            // end: moment().subtract(1, 'month').endOf('month'),
            startFunction: ['subtract', 3, 'month', 'startOf', 'month'],
            endFunction: ['subtract', 1, 'month', 'endOf', 'month'],
            intervalUnit: { unit: "day", value: 1 },
            format: 'MMM DD, YYYY'
        },
        {
            id: "last6Months",
            enabledPolling: false,
            label: "INTERVAL.LAST_6_MONTHS",
            // start: moment().subtract(6, 'month').startOf('month'),
            // end: moment().subtract(1, 'month').endOf('month'),
            startFunction: ['subtract', 6, 'month', 'startOf', 'month'],
            endFunction: ['subtract', 1, 'month', 'endOf', 'month'],
            intervalUnit: { unit: "day", value: 1 },
            format: 'MMM DD, YYYY'
        },
        {
            id: "last12Months",
            enabledPolling: true,
            label: "INTERVAL.LAST_12_MONTHS",
            // start: moment().subtract(12, 'month').startOf('month'),
            // end: moment().subtract(1, 'month').endOf('month'),
            startFunction: ['subtract', 12, 'month', 'startOf', 'month'],
            endFunction: ['subtract', 1, 'month', 'endOf', 'month'],
            intervalUnit: { unit: "day", value: 1 },
            format: 'MMM DD, YYYY'
        },
        {
            id: "thisWeek",
            enabledPolling: true,
            label: "INTERVAL.THIS_WEEK",
            // start: moment().startOf('week'),
            // end: moment(),
            startFunction: ['startOf', 'week'],
            endFunction: [],
            intervalUnit: { unit: "day", value: 1 },
            format: 'MMM DD, YYYY'
        },
        {
            id: "thisMonth",
            enabledPolling: true,
            label: "INTERVAL.THIS_MONTH",
            // start: moment().startOf('month'),
            // end: moment(),
            startFunction: ['startOf', 'month'],
            endFunction: [],
            intervalUnit: { unit: "day", value: 1 },
            format: 'MMM, YYYY'
        },
        {
            id: "thisYear",
            enabledPolling: true,
            label: "INTERVAL.THIS_YEAR",
            // start: moment().startOf('year'),
            // end: moment(),
            startFunction: ['startOf', 'year'],
            endFunction: [],
            intervalUnit: { unit: "day", value: 1 },
            format: 'MMM, YYYY'
        },
        {
            id: "lastYear",
            enabledPolling: false,
            label: "INTERVAL.LAST_YEAR",
            // start: moment().subtract(1, 'year').startOf('year'),
            // end: moment().subtract(1, 'year').endOf('year'),
            startFunction: ['subtract', 1, 'year', 'startOf', 'year'],
            endFunction: ['subtract', 1, 'year', 'endOf', 'year'],
            intervalUnit: { unit: "day", value: 1 },
            format: 'MMM DD, YYYY'
        },
        {
            id: "custom",
            enabledPolling: false,
            label: "INTERVAL.CUSTOM",
            start: moment().subtract(1, 's'),
            end: moment().subtract(1, 's'),
            intervalUnit: { unit: "day", value: 1 },
            format: 'MMM DD, YYYY'
        },
        // {
        //     id: "aggr",
        //     enabledPolling: true,
        //     label: "INTERVAL.AGGR_0",
        //     intervalUnit: { unit: "day", value: 1 },
        //     format: 'MMM DD, YYYY - HH:mm:ss'
        // },
    ];

    intervalArrayDefault_5 = [
        "custom",
        "lastHour",
        "today",
        "yesterday",
        "last7Days",
        "last30Days",
        "last90Days",
        "lastWeek",
        "lastMonth",
        "last3Months",
        "last6Months",
        "last12Months",
        "thisWeek",
        "thisMonth",
        "thisYear",
        "lastYear"
    ];

    intervalArrayDefault_1 = [
        'custom',
        'last30Minutes',
        'lastHour',
        'last2Hours',
        'last4Hours',
        'last8Hours',
        'last24Hours',
        'yesterday',
    ];

    intervalArrayDefault_2 = [
        'custom',
        'today',
        'yesterday',
        'last7Days',
        'last30Days',
        'last90Days',
        'thisWeek',
        'thisMonth',
        'thisYear',
        'last12Months',
    ];

    intervalArrayHomepage = [
        'custom',
        'today',
        'yesterday',
        'last7Days',
        'last30Days',
        'last90Days',
        'thisWeek',
        'thisMonth',
        'thisYear',
    ];

    intervalArrayTraceability = [
        'custom',
        'today',
        'yesterday',
        'last7Days',
        'last30Days',
        'thisWeek',
        'thisMonth',
    ];

    intervalArrayPeroni = [
        'custom',
        'today',
        'yesterday',
        'thisWeek',
        'thisMonth',
        'thisYear',
        'lastMonth',
        'lastYear',
        'last7Days',
        'last30Days',
        'last90Days',
    ];

    intervalArrayOnlyPolling = [
        'last30Minutes',
        'lastHour',
        'last2Hours',
        'last4Hours',
        'last8Hours',
        'last24Hours',
    ];

    intervalArray: any;

    getDefaultIntervals(id?: any, tz?: string, customArray?: any) {
        if (id != null) {
            switch (id) {
                case 0: return this.createIntervals(this.intervalArrayDefault, tz);
                case 1: return this.createIntervals(this.intervalArrayDefault_1.map((x: any) => this.intervalArrayDefault.find((c: any) => c.id == x)), tz);
                case 2: return this.createIntervals(this.intervalArrayDefault_2.map((x: any) => this.intervalArrayDefault.find((c: any) => c.id == x)), tz);
                case 3: return this.createIntervals(this.intervalArrayHomepage.map((x: any) => this.intervalArrayDefault.find((c: any) => c.id == x)), tz);
                case 4: return this.createIntervals(this.intervalArrayTraceability.map((x: any) => this.intervalArrayDefault.find((c: any) => c.id == x)), tz);
                case 5: return this.createIntervals(this.intervalArrayDefault_5.map((x: any) => this.intervalArrayDefault.find((c: any) => c.id == x)), tz);
                case "peroni": return this.createIntervals(this.intervalArrayPeroni.map((x: any) => this.intervalArrayDefault.find((c: any) => c.id == x)), tz);
                case "edge": return this.createIntervals(this.intervalArrayOnlyPolling.map((x: any) => this.intervalArrayDefault.find((c: any) => c.id == x)), tz);
                case "custom": return this.createIntervals((customArray ?? []).map((x: any) => this.intervalArrayDefault.find((c: any) => c.id == x)), tz);
            }
        }
        return this.createIntervals(this.intervalArrayDefault, tz);
    }

    getIntervalList(list: any, tz?: string) {
        try {
            return this.createIntervals(list.map((x: any) => this.intervalArrayDefault.find((c: any) => c.id == x)), tz)
        } catch (error) {
            console.log(error);
        }
    }

    getIntervalById(intervalId: string, tz?: any) {
        if (!tz) tz = null;
        let intervalArray = this.createIntervals(this.intervalArrayDefault, tz);
        if (intervalArray.findIndex((x: any) => x.id == intervalId) != -1) {
            return intervalArray.find((x: any) => x.id == intervalId);
        } else {
            console.error('Nessun intervallo giusto');
            return {};
        }
    }

    strFormat(element: any, time: any, tz?: string, format?: any) {
        if (tz != null) {
            return moment.tz(time, tz).format(format ?? element.format ?? 'MMM DD, YYYY');
        } else {
            return moment(time).format(format ?? element.format ?? 'MMM DD, YYYY');
        }
    }

    createIntervals(intervalArrayInput: any, tz: string = "Europe/Rome") {

        tz = tz == null ? "Europe/Rome" : tz;

        let intervalArray = JSON.parse(JSON.stringify(intervalArrayInput));

        intervalArray.forEach((element: any) => this.parseSingleInterval(element, tz));

        return intervalArray;
    }

    parseSingleInterval(element: any, tz: any) {

        if (element.startFunction != null && element.startFunction.length > 0) {
            if (element.startFunction[0] == 'subtract') {
                if (element.startFunction[3] == 'startOf') {
                    element.start = moment().tz(tz).subtract(element.startFunction[1], element.startFunction[2]).startOf(element.startFunction[4]).utc();
                }
                else if (element.startFunction[3] == 'endOf') {
                    element.start = moment().tz(tz).subtract(element.startFunction[1], element.startFunction[2]).endOf(element.startFunction[4]).utc();
                } else {
                    element.start = moment().utc().subtract(element.startFunction[1], element.startFunction[2]);
                }
            }
            if (element.startFunction[0] == 'startOf') {
                element.start = moment().tz(tz).startOf(element.startFunction[1]).utc();
            }
        } else {
            element.start = moment();
        }

        if (element.endFunction != null && element.endFunction.length > 0) {
            if (element.endFunction[0] == 'subtract') {
                if (element.endFunction[3] == 'startOf') {
                    element.end = moment().tz(tz).subtract(element.endFunction[1], element.endFunction[2]).startOf(element.endFunction[4]).utc();
                }
                else if (element.endFunction[3] == 'endOf') {
                    element.end = moment().tz(tz).subtract(element.endFunction[1], element.endFunction[2]).endOf(element.endFunction[4]).utc();
                } else {
                    element.end = moment().tz(tz).subtract(element.endFunction[1], element.endFunction[2]).utc();
                }
            }
            if (element.endFunction[0] == 'startOf') {
                element.end = moment().startOf(element.endFunction[1]);
            }
        } else {
            element.end = moment().utc();
        }

        try { element.numberOfDays = Math.ceil(element.end.diff(element.start) / 1000 / 3600 / 24) }
        catch (error) { console.log('Could not calculate number of days') }

        element.start = element.start.format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
        element.end = element.end.format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

        // element.startF = this.strFormat(element, element.start, tz);
        // element.endF = this.strFormat(element, element.end, tz);

        element.startH = this.strFormat(element, element.start, tz, 'HH:mm:ss');
        element.endH = this.strFormat(element, element.end, tz, 'HH:mm:ss');

        element.endF = this.strFormat(element, element.end, tz);
        element.startF = this.strFormat(element, element.start, tz) + " - " + (element.enabledPolling ? this.translate.instant("GLOBAL.NOW") : element.endF);

        // this.formatTimezone(element, tz);
    }

    formatTimezone(element: any, tz: any = null) {

        try { element.numberOfDays = Math.ceil(element.end.diff(element.start) / 1000 / 3600 / 24) }
        catch (error) { console.log('Could not calculate number of days') }

        element.start = this.formatStringDate(element.start, tz);
        element.end = this.formatStringDate(element.end, tz);

        element.endF = this.strFormat(element, element.end, tz);
        element.startF = this.strFormat(element, element.start, tz) + " - " + (element.enabledPolling ? this.translate.instant("GLOBAL.NOW") : element.endF);

        element.startH = this.strFormat(element, element.start, tz, 'HH:mm:ss');
        element.endH = this.strFormat(element, element.end, tz, 'HH:mm:ss');
    }

    formatStringDate(stringDate: any, tz: any = null) {

        let currentTimezone = 0;
        if (tz != null) {
            try { currentTimezone = moment(stringDate).tz(tz).utcOffset() }
            catch (error) { }
        }

        return moment.isMoment(stringDate) ?
            (stringDate.subtract(currentTimezone, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z") :
            (moment(stringDate).subtract(currentTimezone, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z");
    }

    unformatStringDate(stringDate: any, tz: any = null) {

        let currentTimezone = 0;
        if (tz != null) {
            try { currentTimezone = moment(stringDate).tz(tz).utcOffset() }
            catch (error) { }
        }

        return moment.isMoment(stringDate) ?
            (stringDate.add(currentTimezone, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSS")) :
            (moment(stringDate?.substring(0, stringDate.length - 1)).add(currentTimezone, 'minutes').format("YYYY-MM-DDTHH:mm:ss.SSS"));
    }

    async selectInterval(
        _this: any,
        interval: any,
        unsubscribePolling: any,
        pollingFunction: any,
        notPollingFunction: any,
        tz: any,
        pageStatePolling: any = 5,
        pageStateCustomDialogClosedWithoutChoosing: any = 6,
        autoAggregationCalc: boolean = false,
        homepage: boolean = false,
    ) {

        _this.detailMode = false;
        _this.zoomIntervalHistory = [];

        try { unsubscribePolling.unsubscribe() } catch (error) { }

        try { _this.aggregations.forEach(aggr => aggr.selected = []) } catch (error) { }

        if (interval != null && Object.keys(interval).length > 0) {

            _this.defaultIntervalId = this.clonerService.deepClone(interval.id);

            // Aggr interval (not implemented yet)
            if (interval.id.startsWith("aggr")) {

                let url = '/apif/current-aggr/' + + interval.id;

                let resp: any = null;
                try {

                    _this.pageState.next(pageStatePolling);
                    resp = await this.http.sendGetRequest(url).toPromise();

                    let newInterval: any = resp.body;

                    interval.start = JSON.parse(JSON.stringify(newInterval.start));
                    interval.end = moment().endOf("day");

                    this.formatTimezone(interval, tz);

                    if (moment().diff(moment(interval.end), 'm') < 0) interval.end = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
                    interval.startF = this.strFormat(interval, interval.start, tz) + ' - ' + this.strFormat(interval, interval.end, tz);

                    _this.interval = interval;
                    if (autoAggregationCalc) this.internalDataService.getValidIntervalAggregations(_this, autoAggregationCalc);
                    this.internalDataService.setSelectedIntervalId(_this.interval);
                    _this.defaultInterval = this.clonerService.deepClone(_this.interval);
                    _this.showResetInterval = false;

                    if (_this.cachedIntervalId != null) {
                        let cachedInterval: any = this.clonerService.deepClone(this.cacheService.get(_this.cachedIntervalId));
                        if (cachedInterval != null) {
                            let selected = this.clonerService.deepClone(_this.interval);
                            cachedInterval.selected = selected;
                            this.cacheService.set(_this.cachedIntervalId, this.clonerService.deepClone(cachedInterval));
                        }
                    }

                    if (homepage) {
                        try { this.internalDataService.setHomepageData(this.clonerService.deepClone({ interval: interval })) }
                        catch (error) { console.log(error) }
                    }

                    if (!interval.enabledPolling) notPollingFunction(_this, 0);
                    else pollingFunction(_this);

                } catch (e) {
                    console.error(e);

                    _this.pageState.next(pageStateCustomDialogClosedWithoutChoosing);
                    if (!_this.interval.enabledPolling) notPollingFunction(_this, 0);
                    else pollingFunction(_this);

                    this.internalDataService.setSelectedIntervalId(_this.interval);
                    _this.defaultInterval = this.clonerService.deepClone(_this.interval);

                    if (_this.cachedIntervalId != null) {
                        let cachedInterval: any = this.clonerService.deepClone(this.cacheService.get(_this.cachedIntervalId));
                        if (cachedInterval != null) {
                            let selected = this.clonerService.deepClone(_this.interval);
                            cachedInterval.selected = selected;
                            this.cacheService.set(_this.cachedIntervalId, this.clonerService.deepClone(cachedInterval));
                        }
                    }

                    if (homepage) {
                        try { this.internalDataService.setHomepageData(this.clonerService.deepClone({ interval: _this.interval })) }
                        catch (error) { console.log(error) }
                    }

                    this.snackBar.open(_this.translate.instant('Request failed! Reverting to previously selected interval'),
                        '',
                        {
                            horizontalPosition: 'right',
                            verticalPosition: 'bottom',
                            panelClass: ['snackbar', 'fail'],
                            duration: 4000
                        }
                    );
                    console.log('Request failed! Reverting to previously selected interval');
                }

            }

            // Custom interval
            else if (interval.id == 'custom') {

                const customIntervalDialog = _this.dialog.open(CustomIntervalDialogComponent, {
                    panelClass: 'ff-dialog',
                    disableClose: true,
                    data: {
                        title: _this.translate.instant('INTERVAL.CUSTOM')
                    },
                });

                customIntervalDialog.afterClosed().subscribe((result: any) => {
                    // console.log('afterClosed', result);
                    if (result != null && result != '') {

                        interval.start = JSON.parse(JSON.stringify(result.interval.value.start));
                        interval.end = moment(result.interval.value.end).endOf("day");

                        this.formatTimezone(interval, tz);
                        if (moment().diff(moment(interval.end), 'm') < 0) interval.end = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
                        interval.startF = this.strFormat(interval, interval.start, tz) + ' - ' + this.strFormat(interval, interval.end, tz);

                        _this.interval = interval;
                        if (autoAggregationCalc) this.internalDataService.getValidIntervalAggregations(_this, autoAggregationCalc);
                        this.internalDataService.setSelectedIntervalId(_this.interval);
                        _this.defaultInterval = this.clonerService.deepClone(_this.interval);
                        _this.showResetInterval = false;

                        if (_this.cachedIntervalId != null) {
                            let cachedInterval: any = this.clonerService.deepClone(this.cacheService.get(_this.cachedIntervalId));
                            if (cachedInterval != null) {
                                let selected = this.clonerService.deepClone(_this.interval);
                                cachedInterval.selected = selected;
                                this.cacheService.set(_this.cachedIntervalId, this.clonerService.deepClone(cachedInterval));
                            }
                        }

                        if (homepage) {
                            try { this.internalDataService.setHomepageData(this.clonerService.deepClone({ interval: interval })) }
                            catch (error) { console.log(error) }
                        }

                        _this.pageState.next(pageStatePolling);
                        notPollingFunction(_this, 0);

                    }

                    // Custom dialog opened but then closed without selecting time range
                    else {

                        _this.pageState.next(pageStateCustomDialogClosedWithoutChoosing);
                        if (!_this.interval.enabledPolling) notPollingFunction(_this, 0);
                        else pollingFunction(_this);

                        this.internalDataService.setSelectedIntervalId(_this.interval);
                        _this.defaultInterval = this.clonerService.deepClone(_this.interval);

                        if (_this.cachedIntervalId != null) {
                            let cachedInterval: any = this.clonerService.deepClone(this.cacheService.get(_this.cachedIntervalId));
                            if (cachedInterval != null) {
                                let selected = this.clonerService.deepClone(_this.interval);
                                cachedInterval.selected = selected;
                                this.cacheService.set(_this.cachedIntervalId, this.clonerService.deepClone(cachedInterval));
                            }
                        }

                        if (homepage) {
                            try { this.internalDataService.setHomepageData(this.clonerService.deepClone({ interval: _this.interval })) }
                            catch (error) { console.log(error) }
                        }

                        console.log('Custom interval dialog closed without choosing date');
                    }
                });

            }

            // Standard interval
            else {

                this.parseSingleInterval(interval, tz);
                _this.interval = interval;
                _this.defaultInterval = this.clonerService.deepClone(_this.interval);

                if (_this.cachedIntervalId != null) {
                    let cachedInterval: any = this.clonerService.deepClone(this.cacheService.get(_this.cachedIntervalId));
                    if (cachedInterval != null) {
                        let selected = this.clonerService.deepClone(_this.interval);
                        cachedInterval.selected = selected;
                        this.cacheService.set(_this.cachedIntervalId, this.clonerService.deepClone(cachedInterval));
                    }
                }

                _this.showResetInterval = false;

                if (autoAggregationCalc) this.internalDataService.getValidIntervalAggregations(_this, autoAggregationCalc);
                // if (autoAggregationCalc) this.internalDataService.getValidIntervalAggregations(_this);

                if (homepage) {
                    try { this.internalDataService.setHomepageData(this.clonerService.deepClone({ interval: interval })) }
                    catch (error) { console.log(error) }
                }

                _this.pageState.next(pageStatePolling);
                if (!interval.enabledPolling) notPollingFunction(_this, 0);
                else pollingFunction(_this);

            }
        }
    }

    async selectIntervalAggregation(
        _this: any,
        aggr: any,
        unsubscribePolling: any,
        pollingFunction: any,
        notPollingFunction: any,
        pageStatePolling: any = 5
    ) {

        if (aggr != null && _this.intervalAggregations != null) {

            try { unsubscribePolling.unsubscribe() } catch (error) { }

            _this.intervalAggregations.selected = aggr;

            _this.pageState.next(pageStatePolling);
            if (!_this.interval?.enabledPolling) notPollingFunction(_this, 0);
            else pollingFunction(_this);
        }
    }
}