import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
@Component({
  selector: 'ff-hm-card',
  templateUrl: './hm-card.component.html',
  styleUrls: ['./hm-card.component.scss']
})
export class HmCardComponent implements OnInit, OnChanges {

  imageFlex: any = 30;
  cardAlign: any = "row";

  @Input() compData: any;
  @Input() dashboardConfig: any;
  @Input() lineMode: any = true;

  constructor(
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public clonerService: ClonerService,
  ) { }

  buildPlot(data: any) {

    if (data == null || !data.hasOwnProperty('timestamps') || data.timestamps == null || data.timestamps.length == 0 ||
      !data.hasOwnProperty('values') || data.values == null || data.values.length == 0) {
      return {
        layout: {},
        traces: []
      };
    }
    let traces: any = [];

    let hoverInfo: any = data.timestamps.map((x: any, idx: any) => {
      let hover: any = "";
      hover += "<b>" + this.translate.instant("HEALTH_MONITORING.HEALTH") + ':</b> ' + this.filterService.parseGaugeValue(data.values[idx], 1, 100) + '%<br>';
      hover += "<b>" + this.translate.instant("HEALTH_MONITORING.THRESHOLD") + ':</b> ' + this.filterService.parseGaugeValue(data.threshold, 0, 100) + '%<br>';
      hover += "<b>" + this.translate.instant("HEALTH_MONITORING.DATE") + ':</b> ' + this.filterService.parseMoment(x, 'default') + '<br>';
      return hover;
    });

    traces.push({
      x: data.timestamps,
      y: data.values,
      name: this.translate.instant("HEALTH_MONITORING.HEALTH"),
      hoverinfo: 'text',
      hovertext: hoverInfo,
      type: "scatter",
      mode: data.values?.length > 1 ? 'lines' : 'lines+markers',
      line: {
        shape: "spline"
      },
      // marker: {
      //   size: 4,
      // }
    });

    traces.push({
      x: data.timestamps,
      y: data.timestamps.map(() => data.threshold),
      name: this.translate.instant("HEALTH_MONITORING.THRESHOLD"),
      hoverinfo: 'skip',
      type: "scatter",
      mode: data.values?.length > 1 ? 'lines' : 'lines+markers',
      line: {
        color: "#FF5757",
        dash: "dash"
      }
    });

    let plotLayout: any = {
      showlegend: false,
      xaxis: {
        showticklabels: false,
        showgrid: false,
        zeroline: false,
      },
      yaxis: {
        range: [-0.05, 1.05],
        // showticklabels: false,
        automargin: true,
        tickformat: '%',
        zeroline: false,
        showgrid: false
      },
      margin: {
        t: 0,
        r: 0,
        b: 0,
        l: 40,
        pad: 5
      }
    };
    return {
      layout: plotLayout,
      traces: traces,
      params: {
        displayModeBar: false,
      }
    };

  }

  recalculateDashboardConfig() {
    try {
      let changeableClassIdx = this.dashboardConfig.widgets.findIndex((x: any) => x.changeableClass);
      if (changeableClassIdx != -1) this.dashboardConfig.widgets[changeableClassIdx].class = this.compData != null ? this.compData.anomalyClass : this.dashboardConfig.widgets[changeableClassIdx].class;
    } catch (error) {
      console.log(error);
    }
  }

  parseData() {
    this.compData.plotData = this.buildPlot(this.compData);
    this.recalculateDashboardConfig();
  }

  // // // // // // // // // // // // // // // // // // // // // // // //
  // START

  async ngOnInit() {
    this.imageFlex = this.dashboardConfig?.imageFlex ?? this.imageFlex;
    this.cardAlign = this.dashboardConfig?.cardAlign ?? this.cardAlign;
    this.parseData()
  }

  ngOnChanges() { }

  ngOnDestroy() { }

}
