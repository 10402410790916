<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <div class="ff-streaming-container w100" [ngStyle.lt-md]="{'margin': '12px 0'}">
    <div fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" fxLayout="column"
      fxLayoutAlign="center stretch" fxLayoutGap="18px" style="background-color: transparent; margin: 12px 16px;">

      <!-- switch -->
      <ng-container *ngIf="!skipTelemetryInVideoStreaming">
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!isMobile">
          <span style="margin-right: 8px;">
            {{ 'VIDEO_STREAMING.SHOW_DATA' | translate }}
          </span>
          <ui-switch [checked]="switch.checked" (change)="toggleHideData($event)" checkedLabel="{{switch.checkedLabel}}"
            [disabled]="pageState.value < pageStateReady" uncheckedLabel="{{switch.uncheckedLabel}}">
          </ui-switch>
        </div>
      </ng-container>

      <!-- variables and components selection -->
      <button *ngIf="!isMobile && !hideMonitoringData" mat-button (click)="openVariablesSelectionDialog()"
        class="filter-button" fxLayout="row" fxLayoutAlign="center center"
        [disabled]="pageState.value < pageStateReady">
        {{ 'CONTINUOUS_EXPLORATION.VARIABLES_SELECTION' | translate }}
        <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
          style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
        </mat-icon>
      </button>

      <span fxFlex></span>

      <div fxLayout="column" fxLayoutAlign="center start" class="column-title-value">
        <span class="title">{{ 'LAST_VIDEO.EVENT_TYPE' | translate }}</span>

        <div *ngIf="eventConfig != null" fxLayout="row" fxLayoutAlign="center center"
          [style.background-color]="eventConfig.bgColor!= null ? eventConfig.bgColor : '#FFFFFF'"
          [style.color]="eventConfig.fontColor != null ? eventConfig.fontColor : '#373A3F'" class="state-container">
          <span>{{eventConfig?.label | translate }}</span>
        </div>
      </div>

      <span fxFlex></span>
      <!-- <span style="font-weight: 800; font-size: 21px; line-height: 30px; color: #1A1919; margin: 16px 8px 8px 8px;">
        {{ 'VIDEO_STREAMING.TITLE' | translate }}
      </span> -->
      <!-- <span flex></span> -->

      <div fxLayout="column" fxLayoutAlign="center start" class="column-title-value" style="margin-right: 48px;">
        <span class="title">{{ 'VIDEO_STREAMING.STREAM_START' | translate }}</span>
        <span class="value">{{ streamStart | placeholder }}</span>
      </div>

      <!-- <span flex></span>

      <div fxLayout="column" fxLayoutAlign="center start" class="column-title-value">
        <span class="title">{{ 'VIDEO_STREAMING.CURRENT_TIME' | translate }}</span>
        <span class="value">{{ currentTime }}</span>
      </div>

      <span flex></span> -->

      <div fxLayout="column" fxLayoutAlign="center start" class="column-title-value" style="margin-right: 4px;">
        <span class="title">{{ 'VIDEO_STREAMING.STREAM_END' | translate }}</span>
        <span class="value">{{ streamEnd | placeholder }}</span>
      </div>

    </div>

    <div fxLayout="column" fxLayoutAlign="center center"
      style="margin: 12px 16px; min-height: 60px; background-color: transparent;">

      <div fxLayout="row" fxLayoutAlign="start center" class="w100">

        <!-- slider -->
        <div *ngIf="slider != null" fxLayout="row" fxLayoutAlign="start center" class="ngx-slider-container">
          <ngx-slider [(value)]="slider.min" [options]="slider.options" (userChangeEnd)="slider.userChangeEnd($event)"
            (valueChange)="slider.valueChange($event)" class="no-max-width" style="margin-right: 24px;">
          </ngx-slider>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">

          <button class="rounded-button md-primary" (click)="play()" aria-label="Play" style="margin-right: 8px;"
            [disabled]="playerState == 1 || pageState.value < pageStateReady || notLoadedVideos">
            <mat-icon class="icon">play_arrow</mat-icon>
          </button>

          <button class="rounded-button md-primary" (click)="pause()" aria-label="Pause" style="margin-right: 8px;"
            [disabled]="playerState == 0 || pageState.value < pageStateReady || notLoadedVideos">
            <mat-icon class="icon">pause</mat-icon>
          </button>

        </div>

      </div>

    </div>
  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < 7 || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 8" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 7" fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="center center" class="w100 h100">

      <div fxFlex.gt-sm fxFlexOrder.gt-sm="1" fxFlexOrder="2" class="w100 h100" fxLayout="row wrap"
        fxLayoutAlign="{{ pastVideosStreamings?.length == 1 ? 'center start' : 'center start' }}"
        *ngIf="pastVideosStreamings?.length > 0" style="margin-top: 12px;"
        [ngStyle.gt-sm]="{'overflow': 'auto', 'height': 'calc(100vh - 280px)'}">

        <ng-container *ngFor="let video of pastVideosStreamings; let i = index">

          <!-- AZURE -->
          <ng-container *ngIf="!edgeVideoStreaming">
            <video [fxFlex]="!skipTelemetryInVideoStreaming ? 'calc(100% - 16px)' : 'calc(50% - 16px)'"
              [id]="video.id" [height]="calculateHeight(video.id)" width="100%"
              class="video-container azuremediaplayer amp-default-skin amp-big-play-centered" tabindex="0">
            </video>
          </ng-container>

          <!-- EDGE -->
          <ng-container *ngIf="edgeVideoStreaming">
            <video [fxFlex]="!skipTelemetryInVideoStreaming ? 'calc(100% - 16px)' : 'calc(50% - 16px)'"
              [id]="video.id" class="video-container video-js" [height]="calculateHeight(video.id)" width="100%"
              controls>
              <source [src]="video.completeName" type='video/mp4' />
            </video>
          </ng-container>

        </ng-container>

      </div>

      <div *ngIf="!hideMonitoringData" fxFlex.gt-sm fxFlexOrder.gt-sm="1" fxFlexOrder="2" fxLayout="column"
        fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start" class="w100 h100"
        fxLayoutGap="8px">
        <!-- style="height: calc((100vh - 250px) * 0.45); margin-top: 8px;" -->

        <!-- dashboard -->
        <div *ngIf="dashboardData" class="w100 h100" fxLayout.lt-sm="column" fxLayout="row wrap"
          fxLayoutAlign="start stretch" fxLayoutGap="8px grid"
          fxLayoutGap.gt-sm="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
          <ff-widget *ngFor="let w of dashboardConfig.widgets" [ngStyle]="{'height': w.customHeight}"
            [fxFlex.gt-sm]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
            [fxShow.lt-md]="!w.hideMobile" [fxFlex.sm]="'calc(' + w.mobileFlex + '% - 8px )'"
            [fxFlexOrder.sm]="w.mobileOrder" [widget]="{ 
            type: w.type, 
            subtype: w.subtype, 
            data: dashboardData, 
            machineProfile: machine.profile, 
            config: w.config, 
            title: w.title
          }">
          </ff-widget>
        </div>

        <div *ngIf="dashboardData == null && notLoadedVideos" class="w100 ff-streaming-container mt-3"
          fxLayout="row wrap" fxLayoutAlign="center center">
          <ff-loading-card [loadingData]="loadingData" style="margin: auto;">
          </ff-loading-card>
        </div>

        <div *ngIf="dashboardData == null && !notLoadedVideos" class="w100 ff-streaming-container mt-3"
          fxLayout="row wrap" fxLayoutAlign="center center">
          <p>{{ 'SESSION_DETAIL.PRESS_PLAY_TO_START_SESSION' | translate }}</p>
        </div>

      </div>

    </div>

  </div>

</div>