<div [ngStyle]="{
    'padding.px': widget != null ? '16' : '0', 
    'height': widget != null ? 'calc(100% - 40px)' : '100%', 
    'width': widget != null ? 'calc(100% - 32px)' : '100%'
  }">
  <span class="widget-gauge title" *ngIf="widget != null">{{ widget.title | translate }}</span>
  <div fxLayout="row" class="h100 w-100" fxLayoutAlign="start center">
    <div *ngIf="widget != null && widget.units" fxFlex="10" fxLayout="column" fxLayoutAlign="center center">
      <span class="widget-gauge infovalue" [class.op-03]="unit != widget.units[1]" style="margin-bottom: .75rem;">{{
        widget.convertedUnits[1] }}</span>
      <div style="transform: rotate(-90deg);">
        <mat-slide-toggle [checked]="unitSliderConf.checked" (change)="toggleUnits()"></mat-slide-toggle>
      </div>
      <span class="widget-gauge infovalue" [class.op-03]="unit != widget.units[0]" style="margin-top: .75rem;">{{
        widget.convertedUnits[0] }}</span>
    </div>
    <div fxFlex fxLayout="column" fxLayoutAlign="center center">

      <svg *ngIf="gaugeData != null && gaugeData.value != null" [attr.viewbox]="viewbox" [attr.width]="width"
        [attr.height]="height" xmlns="http://www.w3.org/2000/svg">

        <defs>
          <filter id="shadow">
            <feDropShadow dx="0" dy="0" stdDeviation="8" flood-color="rgba(119, 126, 136, 0.2)" />
          </filter>
        </defs>

        <circle class="widget-gauge background" [attr.cx]="cxBack" [attr.cy]="cyBack" [attr.r]="rBack"
          [attr.filter]="filterBackground" />

        <circle class="widget-gauge circle-back" [attr.stroke-width]="strokeWidth" [attr.stroke-linecap]="linecap"
          [attr.stroke-dasharray]="strokeDasharrayBack" [attr.cx]="cx" [attr.cy]="cy" [attr.r]="r" />

        <circle class="widget-gauge circle-main" [attr.stroke]="color" [attr.stroke-width]="strokeWidth"
          [attr.stroke-linecap]="linecap" [attr.stroke-dasharray]="strokeDasharray" [attr.cx]="cx" [attr.cy]="cy"
          [attr.r]="r" />

        <text class="widget-gauge value" [attr.x]="width/2" [attr.y]="height/2.5" alignment-baseline="central"
          [attr.dy]="dy" text-anchor="middle">
          {{gaugeData.valueStr}}
        </text>

        <text class="widget-gauge label" [attr.x]="width/2" [attr.y]="height*0.6" alignment-baseline="central"
          text-anchor="middle" [matTooltip]="valueLabelTooltip" matTooltipPosition="above">
          {{ valueLabel | translate }}
        </text>

      </svg>
      <div style="margin-top: 1.2rem" *ngIf="showInfo">
        <p *ngFor="let info of infos" style="line-height: .75rem; text-align: center">
          <span class="widget-gauge label">{{ info.label | translate }}:</span>
          <span *ngIf="widget.data[info.variable]" class="widget-gauge infovalue" style="margin-left: 0.3rem;">
            {{ filtersService.parseObjFromConfig(widget.data, info, false, 'type', true, true) }}
          </span>
        </p>
      </div>
    </div>
  </div>