<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<div *ngIf="pageState.value >= pageStateReady && isMobile" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
</div>

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady && !isMobile" fxLayout="column" fxLayoutAlign="center start"
  class="w100 h100">

  <!-- toolbar tabs -->
  <div class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- save button -->
    <div *ngIf="isAllowedUserWrite">
      <!-- <button mat-button class="button button-1" [disabled]="invalidFormCheck()" (click)="updatePerformanceSettings()"> -->
      <button mat-button class="filter-button md-primary" (click)="updatePerformanceSettings()">
        <span style="margin-right: 1rem; font-size: 1.2rem; text-transform: uppercase;">
          {{ 'GLOBAL.SAVE' | translate }}
        </span>
        <mat-icon class="side">save</mat-icon>
      </button>
    </div>

  </div>

  <div class="w100 h100" fxLayout="column" fxLayoutAlign="center center"
    *ngIf="(pageState.value > 0 && pageState.value <= pageStateReady) || saveState == 1">
    <ff-loading-card [loadingData]="loadingData"></ff-loading-card>
  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content">

    <ff-table-sortable *ngIf="pageState.value > pageStateReady && saveState != 1" [(tableConfig)]="tableConfigDefault">
    </ff-table-sortable>

    <ff-table-sortable style="margin-top: 16px;" *ngIf="pageState.value > pageStateReady && saveState != 1"
      [(tableConfig)]="tableConfig">
    </ff-table-sortable>
  </div>
</div>