<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value > 0" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="(tabs.length > 1 || intervalConfig != null) && pageState.value >= 6" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <ng-container *ngIf="exportCsv">
      <button class="filter-button md-primary" matTooltipClass="ff-tooltip" (click)="generatePDF()" fxLayout="row"
        fxLayoutAlign="start center" matTooltip="{{ 'GLOBAL.GENERATE_PDF_TOOLTIP' | translate }}"
        matTooltipPosition="right">
        <ff-icon icon="download"></ff-icon>
        <span>{{ 'GLOBAL.GENERATE_PDF' | translate }}</span>
      </button>
    </ng-container>

    <button *ngIf="showResetInterval" mat-button class="filter-button md-gray-dark" aria-label="remove filters"
      (click)="resetDefaultInterval()">
      <mat-icon class="icon">refresh</mat-icon>
      <!-- <ng-md-icon icon="filter-icon" class="side" size="20"></ng-md-icon>
      <ng-md-icon icon="close" size="16" style="position: absolute; top: 6px; left: 18px;"></ng-md-icon> -->
      <span style="padding-left: 8px;">{{'CONTINUOUS_EXPLORATION.RESET_INTERVAL' | translate}}</span>
    </button>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <div *ngIf="isMobile" fxFlex fxLayout="column" class="tab-content h100 w100"
    [ngClass]="{ 'without-tabs': tabs.length <= 1}" fxLayoutAlign="center center">
    <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
  </div>

  <!-- tab content -->
  <div *ngIf="!isMobile" fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content">

    <!-- toolbar tabs -->
    <div *ngIf="pageState.value >= 6" class="ff-toolbar pt-1 pb-3" fxLayout="column" fxLayoutAlign="start start"
      fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <!-- variables and components selection -->
      <button mat-button (click)="openVariablesSelectionDialog()" class="filter-button" fxLayout="row"
        fxLayoutAlign="center center">
        {{ 'CONTINUOUS_EXPLORATION.VARIABLES_SELECTION' | translate }}
        <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
          style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
        </mat-icon>
      </button>

      <button *ngIf="eventTypes?.length" mat-button (click)="openEventsDialog()" class="filter-button" fxLayout="row"
        fxLayoutAlign="center center">
        {{ 'CONTINUOUS_EXPLORATION.EVENT_SELECTION' | translate }}
        <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
          style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
        </mat-icon>
      </button>

      <!-- aggregations -->
      <div *ngIf="aggregations != null && aggregations.length > 0" fxLayout="column" fxLayoutAlign="start start"
        fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">
        <button mat-button class="filter-button" *ngFor="let aggr of aggregations" (click)="openAggrDialog(aggr)"
          [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
          <mat-icon *ngIf="aggr.icon != null && aggr.icon.type == 'icon'" class="icon"
            translate-attr="{'aria-label': aggr.label}">
            {{aggr.icon.icon }}</mat-icon>
          <mat-icon *ngIf="aggr.icon != null && aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
            translate-attr="{'aria-label': aggr.label}"></mat-icon>
          <i *ngIf="aggr.icon != null && (aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor')"
            class="icon {{ aggr.icon.icon }}" translate-attr="{'aria-label': aggr.label}"></i>
          <span>{{ aggr.selectionLabel }}</span>
          <div fxLayout="row" fxLayoutAlign="center center"
            *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
            {{ aggregationsPayload[aggr.id].length }}
          </div>
        </button>
      </div>

      <!-- variables and components selection -->
      <button *ngIf="timeStates?.length && returnStates && pageStateReady" mat-button (click)="openTimeStatesDialog()"
        class="filter-button" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="icon">dehaze</mat-icon>
        {{ 'CONTINUOUS_EXPLORATION.STATE_SELECTION' | translate }}
      </button>

      <span fxFlex fxShow="false" fxShow.gt-sm></span>

      <ff-available-machines-selection [availableMachines]="availableMachines"
        (selected)="machineSelectionChange($event)">
      </ff-available-machines-selection>

    </div>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < 6" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 12px;" class="switch-container">

      <button class="mat-button rounded-button" (click)="getPreviousBucket()" style="margin-right: 8px;"
        #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.PREVIOUS_BUCKET' | translate }}"
        matTooltipPosition="above">
        <mat-icon svgIcon="arrow_left" class="icon"></mat-icon>
      </button>

      <button class="mat-button rounded-button" (click)="getUnzoomedBucket()" style="margin-right: 8px;"
        #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.UNZOOMED_BUCKET' | translate }}"
        matTooltipPosition="above">
        <mat-icon class="icon side">remove</mat-icon>
      </button>

      <button class="mat-button rounded-button" (click)="getZoomedBucket()" style="margin-right: 8px;"
        [disabled]="maxZoomReached" #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.ZOOMED_BUCKET' | translate }}"
        matTooltipPosition="above">
        <mat-icon class="icon side">add</mat-icon>
      </button>

      <button class="mat-button rounded-button" (click)="getNextBucket()" [disabled]="interval.enabledPolling"
        #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.NEXT_BUCKET' | translate }}" matTooltipPosition="above">
        <mat-icon svgIcon="arrow_right" class="icon"></mat-icon>
      </button>

    </div>

    <div *ngIf="pageState.value >= 6" fxLayout="column" class="w100 h100" style="min-height: calc(100vh - 250px);">

      <div *ngIf="pageState.value == 6" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="start start" class="h100 w100">
        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="w100 h100" fxFlex="100">
          <ff-widget fxFlex="100" [widget]="{type: 'ff-loading-card'}">
          </ff-widget>
        </div>
      </div>

      <div *ngIf="pageState.value == 7" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="start start" class="h100 w100" fxLayoutGap="8px">

        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="w100 h100" fxLayoutGap="8px">
          <ff-widget fxFlex="100"
            [widget]="{type: 'ff-plotly-chart', data: monitoringData, machineProfile: machine.profile, config: monitoringData.dataConfig, spaceForEvents: spaceForEvents, maxZoomReached: maxZoomReached}">
          </ff-widget>
        </div>

      </div>

    </div>

    <!-- table -->
    <!-- <mat-card fxLayout="column" [ngClass]="{'hidePaginator': (pageState.value < 5 || events.filtered.length == 0) }"> -->

    <mat-card fxLayout="column"
      [ngClass]="{'hidePaginator': (pageState.value < 7 || !machine.profile?.tables?.length) }" class="w100"
      style="padding-bottom: 16px;" [ngStyle]="{'margin-top': isFlags ? '125px' : '24px'}">

      <mat-card fxLayout="row" fxLayoutAlign="start center" style="margin-left: 16px;">
        <!-- <mat-icon *ngIf="!events.expanded" svgIcon="curtain_down" class="icon left clickable"
          (click)="events.expanded = !events.expanded">
        </mat-icon>
        <mat-icon *ngIf="events.expanded" svgIcon="curtain_up" class="icon left clickable"
          (click)="events.expanded = !events.expanded"></mat-icon> -->
        <span class="title">{{ 'CYCLE_TIMELINE.EVENTS_LOG' | translate }}</span>

        <div *ngIf="hiddenEventsMsg" style="margin-left: 8px;">
          <mat-icon class="icon md-orange" #tooltip="matTooltip" matTooltip="{{ hiddenEventsMsg }}">warning</mat-icon>
        </div>

        <span fxFlex></span>

        <button *ngIf="filterTabs?.length" mat-button class="filter-button" (click)="openEventFilter()" fxLayout="row"
          fxLayoutAlign="space-evenly center">
          <mat-icon class="icon">filter_alt</mat-icon>
          <span>{{ 'CONTINUOUS_EXPLORATION.ALL_FILTERS' | translate }}</span>
        </button>

        <button *ngIf="atLeastOneFilter" mat-button class="filter-button md-red" aria-label="remove filters"
          (click)="removeAllFilters()">
          <mat-icon class="icon">filter_alt</mat-icon>
          <mat-icon class="icon">close</mat-icon>
          <!-- <ng-md-icon icon="filter-icon" class="side" size="20"></ng-md-icon>
            <ng-md-icon icon="close" size="16" style="position: absolute; top: 6px; left: 18px;"></ng-md-icon> -->
          <span style="padding-left: 8px;">{{'CONTINUOUS_EXPLORATION.REMOVE_FILTERS' | translate}}</span>
        </button>

        <div flex style="position: relative;">
          <!-- <ng-md-icon *ngIf="!filtersDontExist" icon="info" class="side" size="32" ng-mouseenter="showInfos = true"
              ng-mouseleave="showInfos = false"></ng-md-icon> -->

          <!-- <div *ngIf="showInfos && !filtersDontExist" class="ff-tooltip">
              <div *ngFor="let info of filtersInfo track by $index" ng-if="info != null" style="margin: 2px 0;">
                <p *ngFor="let infoPart of info.split('$$')">
                  {{infoPart}}
                </p>
              </div>
            </div> -->
        </div>

        <div class="search-input" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">search</mat-icon>
          <input type="text" [(ngModel)]="searchEvents" (keyup)="filterSearchEvents(searchEvents)"
            placeholder="{{'CONTINUOUS_EXPLORATION.SEARCH_DESCRIPTION' | translate}}" style="min-width: 160px;">
        </div>


      </mat-card>

      <table mat-table [dataSource]="eventsData" matSort matSortActive="timestamp" matSortDirection="desc"
        *ngIf="pageState.value >= 7 && events.filtered.length > 0" class="standard-table">

        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let event" class="td-icon-large">
            <div fxLayout="row" fxLayoutAlign="center center">

              <ng-container *ngIf="event?.addImage || event?.image">
                <div>
                  <ff-image-from-be *ngIf="event?.addImage" [config]="event">
                  </ff-image-from-be>
                  <img *ngIf="event?.image" [src]="event.image" alt="" class="clickable"
                    style="height: 50px; border-radius: 5px;" (click)="filterService.openImage(event)">
                </div>
              </ng-container>

              <ng-container *ngIf="event?.image == null && event?.addImage == null">
                <div>
                  <ff-icon *ngIf="event.icon1" [icon]="event.icon1"></ff-icon>
                </div>

                <div>
                  <ff-icon *ngIf="event.icon2" [icon]="event.icon2"></ff-icon>
                </div>
              </ng-container>

            </div>

          </td>
        </ng-container>

        <ng-container *ngFor="let info of tableInfos" matColumnDef="{{info.variable}}">
          <th mat-header-cell *matHeaderCellDef [mat-sort-header]="info.orderBy != null ? info.orderBy : null"
            [disabled]="info.orderBy == null">
            {{info.label|translate}}
          </th>
          <td mat-cell *matCellDef="let event" [ngClass]="{ 'primary': info.primary }">

            <!-- NORMAL COLUMN -->
            <span *ngIf="info.type == null && (info.variable != 'description' || event.eventType == null)"
              class="break-word {{info.class}} {{event[info.variable] != null ? event[info.variable].class : ''}}"
              style="font-size: calc(0.4em + 1vh) !important;">
              {{event[info.variable] | placeholder}}
            </span>

            <!-- STATUS COLUMN -->
            <!-- <ng-md-icon *ngIf="info.type == 'status'" class="{{info.class}} {{event[info.variable].class}}"
              icon="{{info.icon}}">
            </ng-md-icon> -->

            <!-- LIST COLUMN -->
            <span
              *ngIf="(event.eventType == 'colorList' || event.eventType == 'list') && info.variable == 'description'"
              ng-class="{'minWidthListLarge': mobile()}" class="{{info.classes}}">
              <ul class="ff-ul">
                <div *ngIf="event.eventType == 'colorList'">
                  <li *ngFor="let state of event.description" style="padding: 4px 0;">
                    <span class="color-square" [style.background-color]="state.color"
                      style="margin: 0 8px 0 0; padding: 0 9px;"></span>
                    <span>{{ state.value | translate }}</span>
                  </li>
                </div>
                <div *ngIf="event.eventType == 'list'">
                  <li *ngFor="let state of event.description">
                    <span [innerHTML]="state"></span>
                  </li>
                </div>
              </ul>
            </span>

            <span *ngIf="info.type == 'timeState'">
              <div *ngIf="event.stateP != null" fxLayout="row" fxLayoutAlign="center center"
                [style.background-color]="event.stateP.color" class="state-container full-width">
                <span>{{event.stateP.value | translate }}</span>
              </div>
              <!-- <span *ngIf="event.stateP != null" class="color-square" [style.background-color]="event.stateP.color"
                style="padding: 0 9px;"></span> -->
            </span>
            <!-- <span *ngIf="info.prefix != null">{{info.prefix}}&nbsp;</span>
            <span>{{(event[info.variable] != null ? event[info.variable] : '-')}}</span>
            <span *ngIf="info.suffix != null">&nbsp;{{info.suffix}}</span> -->
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="eventsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: eventsColumns;"></tr>
      </table>
      <mat-paginator [ngClass]="{'hidePaginator': (pageState.value < 5 || events.filtered.length == 0) }"
        [length]="events.filtered.length" [pageSizeOptions]="events.pageOptions" [pageSize]="events.pageSize"
        showFirstLastButtons></mat-paginator>

    </mat-card>
    <!-- </mat-card> -->

  </div>

</div>

<div style="display:none">
  <div id="single-plots"></div>
  <ng-container #container></ng-container>
</div>