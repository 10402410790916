<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout.gt-sm="row grid" fxLayoutAlign.gt-sm="start stretch"
  fxLayout="column" fxLayoutAlign="start stretch" fxLayoutGap="8px" fxLayoutGap.gt-sm="8px" class="w100">

  <!-- column (1) -->
  <div fxFlex.gt-sm="33" fxFlex="100" fxLayout="column" fxFlexOffset="8px" fxFlexOffset.gt-sm="0">

    <!-- languages -->
    <div class="option-box ff-widget-box">
      <span class="title">{{'OPTIONS.LANGUAGE'|translate}}</span>
      <div>
        <div class="title-image" fxLayout="column" fxLayoutAlign="center center">
          <mat-icon class="icon" aria-label="languages">language</mat-icon>
        </div>
        <div class="options_content">
          <div fxLayout="column" fxLayoutAlign="start stretch" style="margin-top: 12px;">
            <button *ngFor="let lang of langs" (click)="useLanguage(lang.id)" class="" fxLayout="row"
              fxLayoutAlign="start center" [ngStyle]="{'opacity': lang.current ? 1 : 0.5 }">
              <div class="check transition-01" [ngClass]="{'current': lang.current}" fxLayout="row"
                fxLayoutAlign="center center">
                <mat-icon class="icon" aria-label="current">done</mat-icon>
              </div>
              <div fxFlex fxLayout="row" fxLayoutAlign="center center">
                <span>{{lang.name | translate}} - [{{lang.id}}]</span>
              </div>
              <div class="flag" fxLayout="row" fxLayoutAlign="center center">
                <img [src]="'./statics/flags/' + lang.flag" alt="{{lang.id}}">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>

  <!-- column (2) -->
  <div fxFlex.gt-sm="33" fxFlex="100" fxLayout="column" fxLayoutAlign="space-between stretch">

    <!-- Unit Systems -->
    <div class="option-box ff-widget-box" *ngIf="convConfig != null">
      <span class="title">{{'OPTIONS.UNIT_SYSTEM'|translate}}</span>
      <div>
        <div class="title-image" fxLayout="column" fxLayoutAlign="center center">
          <mat-icon class="icon" aria-label="Unit system">account_balance</mat-icon>
        </div>
        <div class="options_content">
          <div fxLayout="column" fxLayoutAlign="start stretch" style="margin-top: 12px;">
            <button *ngFor="let unitSystem of unitSystems" (click)="useUnitSystem(unitSystem.id)" class=""
              fxLayout="row" fxLayoutAlign="start center" [ngStyle]="{'opacity': unitSystem.current ? 1 : 0.5 }">
              <div class="check transition-01" [ngClass]="{'current': unitSystem.current}" fxLayout="row"
                fxLayoutAlign="center center">
                <mat-icon class="icon" aria-label="current">done</mat-icon>
              </div>
              <div fxFlex fxLayout="row" fxLayoutAlign="center center">
                <span>{{unitSystem.label | translate}} - [{{unitSystem.id}}]</span>
              </div>
              <!-- <div class="flag" fxLayout="row" fxLayoutAlign="center center">
                <img [src]="'./statics/flags/' + unitSystem.flag" alt="{{unitSystem.id}}">
              </div> -->
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- column (3) -->
  <div fxFlex.gt-sm="33" fxFlex="100" fxLayout="column" fxLayoutAlign="space-between stretch">

    <!-- Unit Systems -->
    <!-- <div class="option-box ff-widget-box">
      <span class="title">{{'OPTIONS.UNIT_SYSTEM'|translate}}</span>
      <div>
        <div class="title-image" fxLayout="column" fxLayoutAlign="center center">
          <mat-icon class="icon" aria-label="Unit system">account_balance</mat-icon>
        </div>
        <div class="options_content">
          <div fxLayout="column" fxLayoutAlign="start stretch" style="margin-top: 12px;">

            <button *ngFor="let style of styles" (click)="changeCSS(style + '/static.css')" class="" fxLayout="row"
              fxLayoutAlign="start center">
              <div class="check transition-01" fxLayout="row" fxLayoutAlign="center center">
                <mat-icon class="icon" aria-label="current">done</mat-icon>
              </div>
              <div fxFlex fxLayout="row" fxLayoutAlign="center center">
                <span>{{ style }}</span>
              </div>
            </button>

          </div>
        </div>
      </div>
    </div> -->
  </div>

</div>