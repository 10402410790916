<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

    <!-- toolbar tabs -->
    <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="start center">

        <!-- tabs -->
        <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

        <span fxFlex fxShow="false" fxShow.gt-sm></span>

    </div>

    <!-- tab content -->
    <div class="tab-content pad-x-2em pad-y-1em widpad-x-2em h100 w100" fxLayout="column"
        fxLayoutAlign="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start stretch' }}"
        [ngClass]="{ 'without-tabs': tabs.length <= 1}">

        <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
        <!-- loading -->
        <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData"
            style="margin: auto;">
        </ff-loading-card>

        <div *ngIf="!repeatedCards && !noSynoptic && pageState.value >= pageStateReady" fxLayout="column"
            fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start" class="h100 w100"
            fxLayoutGap="8px">

            <!-- <div *ngFor="let datapoint of synopticConfig.datapoints" style="display:none">
                <ff-synoptic-widget [datapoint]="datapoint"></ff-synoptic-widget>
            </div> -->

            <div fxFlex="80" fxLayout="column" fxLayoutAlign="start stretch" class="h100" fxLayoutGap="8px">
                <div fxFlex="5" fxLayout="row" fxLayoutAlign="start center">
                    <span fxFlex></span>
                    <div fxLayout="row" fxLayoutAlign="start center"
                        class="last-update {{monitoringData?.notConnected ? 'md-orange' : (monitoringData?.neverConnected ? 'md-gray' :'md-green') }}">

                        <mat-icon class="icon left">
                            {{monitoringData?.notConnected ? 'warning' : (monitoringData?.neverConnected ? 'remove'
                            : 'check')}}
                        </mat-icon>
                        <span>{{ 'REMOTE_MONITORING.LAST_UPDATE' | translate }}: {{ monitoringData?.lastUpdateP
                            }}</span>
                    </div>
                </div>

                <div id="annotationViewer" fxFlex="95" class="widget-card h100 w100"></div>

                <!-- <ff-widget fxFlex="95"
                    [widget]="{type: 'ff-synoptic', data: monitoringData, synopticConfig: synopticConfig}">
                </ff-widget> -->
            </div>
            <div fxFlex="{{ !isSmThanTablet ? dashboardConfig.secondColumnFlex : dashboardConfig.secondColumnFlex + 10 }}"
                fxLayout="column" fxLayoutAlign="start stretch" class="h100" fxLayoutGap="8px">
                <ff-widget *ngFor="let w of dashboardConfig.widgets" fxFlex="{{w.flex}}"
                    [widget]="{ type: w.type, data: monitoringData, machineProfile: machine.profile, config: w.config, title: w.title, units: w.units }">
                </ff-widget>
            </div>

        </div>

    </div>

</div>


<!-- <div> -->
<div style="display:none">
    <ng-container #container></ng-container>
</div>