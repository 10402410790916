<div class="backdrop" (click)="onClose()"></div>
<div class="alert-box">
  <h2>Riepilogo</h2>
  <p><strong>Data:</strong> {{ date }}</p>
  <p><strong>Trafila:</strong> {{ trafila }}</p>
  <p><strong>Turno:</strong> {{ shift }}</p>
  <div class="alert-box-actions pull-right" fxLayout="row" fxLayout="start center">
    <span fxFlex></span>
    <button style="margin-right: 10px" class="custom-btn custom-btn-danger" type="button"
      (click)="onClose()">Annulla</button>
    <button class="custom-btn custom-btn-primary" type="button" (click)="onConfirm()">Conferma</button>
  </div>
</div>
