import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
  selector: 'ff-table',
  templateUrl: './ff-table.component.html',
  styleUrls: ['./ff-table.component.scss']
})
export class FfTableComponent implements OnInit, OnChanges {

  @Input() tableConfig: any;

  public tableInfos: any = [];
  public elemList: any = [];

  constructor() { }

  ngOnInit(): void {
    // console.log(this.tableConfig);
    try { this.tableInfos = this.tableConfig.tableInfos } catch (error) { }
    try { this.elemList = this.tableConfig.elemList } catch (error) { }
  }

  ngOnChanges(): void {
    // console.log(this.tableConfig);
    try { this.tableInfos = this.tableConfig.tableInfos } catch (error) { }
    try { this.elemList = this.tableConfig.elemList } catch (error) { }
  }

}
