import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { loadAsync } from 'jszip';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'cycle-photos-dialog',
  templateUrl: './cycle-photos-dialog.component.html',
  styleUrls: ['./cycle-photos-dialog.component.scss']
})
export class CyclePhotosDialogComponent implements OnInit, OnDestroy {

  public confirmClass: any;
  public cancelClass: any;
  public confirmAdditionalActionClass: any;
  public state: any;
  public images: any = {};
  public response: any;

  public loadingData: any = {}

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<CyclePhotosDialogComponent>,
    public apiService: ApiService,
    public clonerService: ClonerService,
    public filterService: FiltersService,
    public translate: TranslateService,
  ) { }

  async ngOnInit() {

    this.loadingData = {
      message: this.translate.instant("LOADING.CYCLE_IMAGES")
    }
    await this.getPhotos();
  }

  async getPhotos() {

    this.state = 0;

    try {

      let url: any = '/apif/cycle-traceability/get-cycle-photos/' + this.dialog.machineId;

      if (this.dialog.config?.endpointUrl) url = this.dialog.config.endpointUrl.replaceAll("{machineId}", this.dialog.machineId);

      let payload: any = {
        additionalConfig: this.clonerService.deepClone(this.dialog.config ?? {}),
        cycleInfos: this.clonerService.deepClone(this.dialog.cycleInfos ?? {})
      };

      let query = {
        tz: this.dialog.machine.timezone,
      }

      let resp: any = null;

      try { resp = await this.apiService.asyncDownload(url, payload, query, "arraybuffer") }
      catch (error) {
        this.state = 2;
        console.log(error);
        return;
      }

      this.response = resp;

      // load response
      await loadAsync(resp.body).then((zip) => {
        // console.log('loadAsync')

        // if there are zero images in response
        if (Object.keys(zip.files)?.length == 0) this.state = 2

        // if there is at least image in response
        else {

          // for each image in zip
          Object.keys(zip.files).forEach((filename) => {

            // unzip image
            zip.files[filename].async("arraybuffer")
              .then((fileData) => {

                this.images[filename.split("/").at(-1)] = this.filterService.sanitize('data:image/jpg;base64, ' + this.filterService.arrayBufferToBase64(fileData));

                if (Object.keys(this.images)?.length == Object.keys(zip.files)?.length) {
                  this.state = 1;
                }

              });
          });
        }

      });


    } catch (error) {
      console.log(error);
      this.state = 2;
    }

  }

  downloadImages() {
    this.apiService.handleDownload(this.response);
  }

  ngOnDestroy() { }

  confirm() { this.dialogRef.close() }

}
