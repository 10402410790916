import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { CacheService } from 'src/app/services/cache.service';
import { MobileService } from 'src/app/services/mobile.service';
import { AddDowntimeDialogComponent } from './add-downtime-dialog/add-downtime-dialog.component';
import { AssignOperatorsDialogComponent } from './assign-operators-dialog/assign-operators-dialog.component';
import { AssignShiftTemplateDialogComponent } from './assign-shift-template-dialog/assign-shift-template-dialog.component';
import { CopyWeekDialogComponent } from './copy-week-dialog/copy-week-dialog.component';
import { ExportCalendarDialogComponent } from './export-calendar-dialog/export-calendar-dialog.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy, AfterViewInit {

  public isOperators: any = true;
  public disableOperatorsList: any = false;

  public isAllowedUser: any = true;
  public isAllowedUserWrite: any = true;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public productionColumns: string[] = [];

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public pollingTime: any;
  public pollingEvents: any;

  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;

  public interval: any;
  public intervalConfig: any;

  public intervalAggregations: any;
  public aggregationsTime: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public dashboardConfig: any;

  public showCalendarSwitch: boolean = false;

  public calendarData: any;
  public calendarStates: any;
  public calendarShifts: any;
  public calendarShiftsUnparsed: any;

  public calendarOptions: any = {};
  public selectedDays: any = [];
  public selectedDaysCopy: any = [];

  public shiftTemplates: any = [];
  public operatorsList: any = [];

  public mobileListener: Subscription;
  public errorDataMobile: any;
  public isMobile: any;

  public isEnabledCalendarAsset: any = true;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 8;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getCalendarStates, nextState: 4, loadingMsg: 'LOADING.CALENDAR_STATES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getShiftTemplates, nextState: 5, loadingMsg: 'LOADING.SHIFT_TEMPLATES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getOperatorsList, nextState: 6, loadingMsg: 'LOADING.OPERATORS_LIST' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.getDataPolling, nextState: 7, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    public cacheService: CacheService,
    public snackBar: MatSnackBar,
    public mobile: MobileService,
    public router: Router,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    let sidenavItems = appConfigService.getSidenavItems;

    try {
      // this.enableCalendarSwitch = sidenavItems.find(sItem => (sItem.id == 'planning-calendar' && !sItem.disabled)) != null
      this.showCalendarSwitch = sidenavItems.find(sItem => (sItem.id == 'planning-calendar')) != null;
    } catch (error) {
      console.log(error);
    }

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['CALENDAR.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs('calendar');

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig.calendar.pollingCalendar;
    this.pollingEvents = Subscription;

    this.calendarData = null;

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile;
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })
  }

  toggleEnableCalendarAsset(value) {

    try { localStorage.setItem(this.machineId + '_calendar', value) }
    catch (error) { console.log(error) }

    if (value == false) {
      try { if (this.cacheService.get("intervalAggregation")?.id == 'shift') this.cacheService.set("intervalAggregation", null) }
      catch (error) { console.log(error) }
    }

    this.internalDataService.setCalendar(value);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUserWrite = _this.internalDataService.getSpecificPermission("mat-pc-calendar-rw");
    } catch (error) { console.log(error) }

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(["mat-pc-calendar-r", "mat-pc-calendar-rw"], 'or');
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs('calendar');
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'calendar');
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET CALENDAR STATES

  public getCalendarStates(_this: any) {

    try {

      const sources40F = _this.appInfo.sources40F != null ? _this.appInfo.sources40F : 'assets/config/';

      _this.apiService.sendGetRequest(sources40F + 'calendarStates.json').pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {
          error = {
            ...error, ...{
              error: {
                "customMessage": "Missing file: calendarStates.json",
                "customCode": "404",
              }
            }
          }
          return _this.internalDataService.parseStandardHTTPError(_this, error);
        }))
        .subscribe(
          (data: any) => {

            _this.calendarStates = data.body;
            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SHIFT TEMPLATES

  public getShiftTemplates(_this: any) {

    try {

      _this.apiService.sendGetRequest('/apif/calendar-shifts/' + _this.machineId).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {

            _this.shiftTemplates = _this.clonerService.deepClone(data.body);
            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET OPERATORS LIST

  public getOperatorsList(_this: any) {

    try {

      _this.apiService.sendGetRequest('/apif/calendar-operators/' + _this.machineId).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {

            _this.operatorsList = _this.clonerService.deepClone((data.body ?? [])?.map(x => x?.name));
            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // events

  // polling
  getDataPolling(_this: any) {
    try {

      // try {
      //   _this.intervalAggregations = {
      //     list: _this.aggregationsTime,
      //     selected: _this.aggregationsTime[0]
      //   };
      // } catch (error) {
      //   console.log(error);
      // }

      if (_this.pollingTime > 0) {
        _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getData(_this, count);
        });
      } else {
        _this.getData(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getData(_this: any, count?: any) {
    try {

      let query: any = {
        minimumCalendarUnit: _this.machine.profile?.settings?.minimumCalendarUnit ?? '30m',
      };

      _this.apiService.sendGetRequest('/apif/calendar/' + _this.machineId, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            if (_this.calendarShifts == null) {
              _this.calendarShifts = _this.clonerService.deepClone(data.body);
              _this.calendarShiftsUnparsed = _this.clonerService.deepClone(data.body);
            }
            try {

              // set plot data and configs
              _this.parseCalendarDays(_this);

            } catch (error) {
              console.log(error);
            }

            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseCalendarDays(_this: any) {

    _this.totalDays = _this.parseTotalDays(_this);

    let monthsToShowBeforeAndAfter = this.appConfig.calendar?.monthsToShowBeforeAndAfter ?? 12;

    // console.log(_this.totalDays);

    _this.calendarOptions = {
      events: _this.totalDays,
      initialView: 'dayGridMonth',
      allDaySlot: false,
      height: "100%",
      nextDayThreshold: '14:00:00',
      // views: {
      //   dayGrid: {
      //     // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
      //   },
      //   timeGrid: {
      //     // options apply to timeGridWeek and timeGridDay views
      //   },
      //   week: {
      //     titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' }
      //   },
      //   day: {
      //     // options apply to dayGridDay and timeGridDay views
      //   }
      // },
      // dayHeaderFormat: function(date:any) {
      //   console.log(date);
      //   return moment(date).format("dddd");
      // },
      // { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
      // eventColor: 'red',
      navLinks: true,
      // defaultDate: c_month != null ? c_month : new Date(),
      // defaultView: c_view != null ? c_view : 'month',
      // navLinkDayClick: function (date, jsEvent) {
      //     // console.log('day', date.toISOString());
      // },
      navLinkWeekClick: function (weekStart: any) {
        // console.log(weekStart);

        _this.copyWeek(weekStart);
      },
      // height: window.innerHeight - 300,
      weekNumbers: true,
      selectOverlap: true,
      // eventLimit: true,
      dayMaxEvents: 3,
      headerToolbar: {
        start: 'title',
        center: 'timeGridWeek dayGridMonth',
        end: 'today prev,next',
      },
      validRange: {
        start: moment().subtract(monthsToShowBeforeAndAfter, 'months').format("YYYY-MM-DD"), //start date here
        end: moment().add(monthsToShowBeforeAndAfter, 'months').format("YYYY-MM-DD") //end date here
      },
      selectable: true,
      select: function (event: any) {

        let start = event.start;
        let end = event.end;

        _this.selectedDays = [];
        for (var m = moment(start); m.diff(end, 'days') < 0; m.add(1, 'days')) {

          let day = m.format("YYYY-MM-DD");
          _this.selectedDays.push(day);
        }

        let copyCalendarShifts = _this.clonerService.deepClone(_this.calendarShifts);

        let firstTemplate: any = null;

        try {
          firstTemplate = copyCalendarShifts.filter((x: any) => x.validity == 0).find((x: any) => x.workDay == _this.selectedDays[0]).template;
        } catch (error) {
          _this.disableOperatorsList = true;
        }

        if (firstTemplate != null) {
          _this.disableOperatorsList = !_this.selectedDays.every((day: any) => copyCalendarShifts.findIndex((x: any) => x.workDay == day) != -1 && copyCalendarShifts.filter((x: any) => x.validity == 0 && x.workDay == day).every((x: any) => x.template == firstTemplate));
        }

      },
      unselectAuto: false,
      // unselect: function () {
      //   _this.selectedDays = [];
      // },
      // eventRender: function (timeRange: any, el: any, view: any) {
      //   // console.log(timeRange, view);
      //   if (view != null) {
      //     try {
      //       if (moment(timeRange.day).diff(moment(view.start)) < 0 || moment(timeRange.day).diff(moment(view.end)) > 0) {
      //         el.css("display", 'none');
      //       } else {
      //         el.css("display", 'block');
      //       }
      //     } catch (error) {
      //       console.log(error);
      //     }
      //   }
      // },
      // viewRender: function (view, element) {
      //     try {
      //         cacheService.setItem("currentMonth", new Date(view.start));
      //         cacheService.setItem("currentView", view.name);
      //     } catch (error) {
      //         console.log(error);
      //     }
      //     // console.log('new date range start : ', view.start, 'new date range end : ', view.end);
      // },
      // eventClick: _this.alertOnEventClick,
      // dateClick: function (info) {
      //     alert('Clicked on: ' + info.dateStr);
      //     alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
      //     alert('Current view: ' + info.view.type);
      //     // change the day's background color just for fun
      //     // info.dayEl.style.backgroundColor = 'red';
      // },
      // dayClick: _this.dayClick,
      // showNonCurrentDates: false,
      displayEventEnd: false,
      displayEventTime: false,
      firstDay: 1,
      buttonText: {
        today: this.translate.instant('CALENDAR.TODAY'),
        week: this.translate.instant('CALENDAR.WEEK'),
        month: this.translate.instant('CALENDAR.MONTH'),
      },
      datesSet: function () {
        _this.setDayShiftColor()
      }
    };

  }

  unselectDays() {
    this.selectedDays = [];
  }

  parseTotalDays(_this: any) {

    _this.selectedDays = [];
    let list: any = [];

    let workConfig = _this.calendarStates.find((x: any) => x.id == 0);
    let stateConfig: any = null;

    // console.log(_this.calendarShifts);

    if (workConfig != null && _this.calendarShifts != null && _this.calendarShifts.length > 0) {

      _this.calendarShifts.forEach((timeRange: any) => {

        // State config
        if (timeRange.hasOwnProperty('validity') && timeRange.validity != null) {
          stateConfig = _this.calendarStates.find((x: any) => x.id == timeRange.validity);
        }

        // // Add background color assigned to shift template
        // if (list.findIndex((x: any) => x.allDay && x.start == timeRange.workDay) == -1) {
        //   let ix = _this.shiftTemplates.findIndex((st: any) => st.id == timeRange.template);
        //   if (ix != -1) {
        //     list.push({
        //       start: timeRange.workDay,
        //       allDay: true,
        //       display: 'background',
        //       color: _this.shiftTemplates[ix].color,
        //       // color: 'rgba(0,0,0,0)',
        //       // className: 'shiftTemplate'
        //     });
        //   }
        // }

        // Add shift
        list.push({
          // display: 'block',
          className: [stateConfig != null ? stateConfig.class : workConfig.class],
          stick: false,
          editable: false,
          isValid: stateConfig != null ? stateConfig.isValid : workConfig.isValid,
          shiftId: timeRange.shiftId,
          day: timeRange.workDay,
          title: moment(timeRange.from).format("HH:mm") + ' - ' + moment(timeRange.to).format("HH:mm") + ' - '
            + (timeRange.shiftId != null && timeRange.shiftId != 0 ? _this.translate.instant("CALENDAR.SHIFT") + ' ' + timeRange.shiftId : _this.translate.instant(stateConfig.label)),
          stateId: stateConfig != null ? stateConfig.id : workConfig.id,
          start: timeRange.from,
          end: timeRange.to,
        });

      });
    }

    _this.totalDaysUnfiltered = list;
    // console.log(_this.clonerService.deepClone(list.filter(x => x.allDayFlag)));
    // console.log(_this.clonerService.deepClone(list.filter(x => x.stateId != 0)));
    return _this.clonerService.deepClone(list);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // OPEN SHIFT TEMPLATES

  openShiftTemplate(selectedDays: any) {

    this.selectedDaysCopy = this.clonerService.deepClone(selectedDays);

    const assignShiftSelectionDialog = this.dialog.open(AssignShiftTemplateDialogComponent,
      {
        panelClass: 'ff-dialog',
        // width: '40%',
        height: 'auto',
        data: {
          title: this.translate.instant("CALENDAR.SHIFT_TEMPLATE_SELECTION"),
          machine: this.machine,
          appConfig: this.appConfig,
          shiftTemplates: this.shiftTemplates,
          selectedDaysCopy: this.selectedDaysCopy
        },
      });

    assignShiftSelectionDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          // console.log(result);

          let copyCalendarShifts: any = this.clonerService.deepClone(this.calendarShifts);
          let idxsList: any = [];

          this.selectedDaysCopy.forEach((day: any) => {
            copyCalendarShifts.filter((cs: any) => cs.workDay == day && cs.validity == 0).forEach((cs: any, idx: any) => {
              idxsList.push(copyCalendarShifts.findIndex((x: any) => x.from == cs.from && x.to == cs.to))
            });
            if (idxsList.length > 0) {
              idxsList.forEach((idx: any) => copyCalendarShifts[idx] = null);
              idxsList = [];
            }
            copyCalendarShifts = copyCalendarShifts.filter((x: any) => x != null);

            result.shifts.forEach((shift: any) => {

              copyCalendarShifts.push({
                validity: 0,
                shiftId: shift.shiftId,
                workDay: day,
                template: result.id,
                operatorIds: null,
                from: day + 'T' + shift.from + ':00.000',
                to: (moment(shift.to, 'HH:mm').isAfter(moment(shift.from, 'HH:mm')) ? day : moment(day).add(1, 'days').format("YYYY-MM-DD")) + 'T' + shift.to + ':00.000',
              });
            });
          });

          this.calendarShifts = this.clonerService.deepClone(copyCalendarShifts);
          this.updateCalendar(this);

        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // OPEN SHIFT TEMPLATES

  copyWeek(week: any = null) {

    let monthsToShowBeforeAndAfter = this.appConfig.calendar?.monthsToShowBeforeAndAfter ?? 12;

    if (this.isAllowedUserWrite) {
      let allWeeks = [];

      var a = moment().subtract(monthsToShowBeforeAndAfter, 'months');
      var b = moment().add(monthsToShowBeforeAndAfter, 'months');
      for (var m = moment(a); m.diff(b, 'days') <= 0; m.add(1, 'days')) {
        if (allWeeks.findIndex(x => x.id == m.endOf("week").week() + '-' + m.endOf("week").year()) == -1) {
          allWeeks.push({
            id: m.endOf("week").week() + '-' + m.endOf("week").year(),
            week: m.endOf("week").week(),
            year: m.endOf("week").year(),
            startUnparsed: m.startOf("week").toDate(),
            endUnparsed: m.endOf("week").toDate(),
            start: m.startOf("week").format("DD MMM, YYYY"),
            end: m.endOf("week").format("DD MMM, YYYY"),
          });
        }
      }

      let selectedToIdx = week != null ?
        allWeeks.findIndex(x => x.start == moment(week).format("DD MMM, YYYY")) :
        allWeeks.findIndex(x => x.start == moment().startOf('week').format("DD MMM, YYYY"));

      let selectedTo = null;
      try { selectedTo = allWeeks[selectedToIdx + 1] != null ? allWeeks[selectedToIdx + 1] : allWeeks[selectedToIdx] }
      catch (error) { console.log(error) }

      let totalWeeks = {
        list: allWeeks,
        selected: week != null ? allWeeks.find(x => x.start == moment(week).format("DD MMM, YYYY")) : allWeeks.find(x => x.start == moment().startOf('week').format("DD MMM, YYYY")),
        selectedTo: selectedTo,
      };

      const copyWeekDialog = this.dialog.open(CopyWeekDialogComponent,
        {
          panelClass: 'ff-dialog',
          // width: '40%',
          height: 'auto',
          data: {
            title: this.translate.instant("CALENDAR.COPY_WEEK"),
            machine: this.machine,
            appConfig: this.appConfig,
            totalWeeks: totalWeeks,
            isOperators: this.operatorsList?.length > 0
          },
        });

      copyWeekDialog.afterClosed().subscribe((result: any) => {

        if (result != null && result != '') {
          try {

            let newWeeks: any = this.clonerService.deepClone(result);

            // console.log(newWeeks);


            if (newWeeks && newWeeks.selected != null && newWeeks.numberOfWeeks != null && newWeeks.selectedTo != null) {

              // loadingService.setLoadingMessage(this.translate.instant('LOADING.UPDATE_CALENDAR'));
              // setState(3);

              let diffWeeks = moment(newWeeks.selected.startUnparsed).diff(newWeeks.selectedTo.startUnparsed, 'weeks') + 1;
              let copyCalendarShifts: any = this.clonerService.deepClone(this.calendarShifts);

              let selectedDaysInitial: any = [];
              for (let m = moment(newWeeks.selected.startUnparsed); m.diff(newWeeks.selected.endUnparsed, 'days') <= 0; m.add(1, 'days')) {
                if (selectedDaysInitial.length < 7) {
                  selectedDaysInitial.push(m.format("YYYY-MM-DD"));
                }
              }

              let copyWeek = copyCalendarShifts.filter((x: any) => selectedDaysInitial.includes(x.workDay) && (newWeeks.selected.copyDowntime ? true : x.validity == 0));

              // console.log(copyWeek);

              if (copyWeek != null) {

                let idxsList: any = [];
                for (var i = 1; i <= newWeeks.numberOfWeeks; i++) {
                  selectedDaysInitial.forEach((day: any) => {

                    let newDay = moment(day).subtract(diffWeeks, 'weeks').add(i, 'weeks').format("YYYY-MM-DD");
                    try {
                      copyCalendarShifts.filter((x: any) => x.workDay == newDay && (newWeeks.selected.overwriteDowntime ? true : x.validity == 0)).forEach((timeRange: any) => {
                        idxsList.push(copyCalendarShifts.findIndex((x: any) => x.workDay == newDay && timeRange.shiftId == x.shiftId));
                      });
                    } catch (error) {
                      console.log(error);
                    }
                  });
                }

                if (idxsList.length > 0) {
                  idxsList.forEach((idx: any) => copyCalendarShifts[idx] = null);
                  idxsList = [];
                }
                copyCalendarShifts = copyCalendarShifts.filter((x: any) => x != null);

                for (var i = 1; i <= newWeeks.numberOfWeeks; i++) {
                  copyWeek.forEach((timeRange: any) => {

                    let newWorkDay = moment(timeRange.workDay).subtract(diffWeeks, 'weeks').add(i, 'weeks').format("YYYY-MM-DD");
                    let newFrom = moment(timeRange.from).subtract(diffWeeks, 'weeks').add(i, 'weeks').format("YYYY-MM-DDTHH:mm:ss.SSS");
                    let newTo = moment(timeRange.to).subtract(diffWeeks, 'weeks').add(i, 'weeks').format("YYYY-MM-DDTHH:mm:ss.SSS");
                    let newOperatorList = newWeeks.selected.copyOperators ? timeRange.operatorIds : null;

                    let newShifts = this.clonerService.deepClone(timeRange);
                    copyCalendarShifts.push(Object.assign(newShifts, {
                      workDay: newWorkDay,
                      from: newFrom,
                      to: newTo,
                      operatorIds: newOperatorList,
                    }));
                  });
                }
              }

              // console.log(copyCalendarShifts);

              this.calendarShifts = this.clonerService.deepClone(copyCalendarShifts);
              this.updateCalendar(this);

            }

          } catch (error) {
            console.log(error);
          }
        }
      });
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // REMOVE SHIFT TEMPLATES

  removeShiftTemplate(daysList: any, invalidityRemove: any) {

    this.selectedDaysCopy = this.clonerService.deepClone(daysList);

    let title: any = null;
    let regex: any = null;
    switch (invalidityRemove) {
      case 0:
      case "0":
        title = 'CALENDAR.CONFIRM_REMOVE_SHIFTS';
        regex = (shift: any) => shift.validity == 0;
        break;
      case 1:
      case "1":
        title = 'CALENDAR.CONFIRM_REMOVE_PLANNED_DOWNTIME';
        regex = (shift: any) => shift.validity != 0;
        break;
      case 2:
      case "2":
      default:
        title = 'CALENDAR.CONFIRM_CLEAR_ALL';
        regex = () => true;
        break;
    }

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent,
      {
        panelClass: 'ff-dialog',
        // width: '40%',
        height: 'auto',
        data: {
          title: this.translate.instant(title),
        },
      });

    confirmationDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          // console.log(result);

          let copyCalendarShifts: any = this.clonerService.deepClone(this.calendarShifts);
          let idxsList: any = [];

          this.selectedDaysCopy.forEach((day: any) => {
            copyCalendarShifts.filter((cs: any) => cs.workDay == day && regex(cs)).forEach((cs: any, idx: any) => {
              idxsList.push(copyCalendarShifts.findIndex((x: any) => x.from == cs.from && x.to == cs.to && x.validity == cs.validity));
            });
            if (idxsList.length > 0) {
              idxsList.forEach((idx: any) => copyCalendarShifts[idx] = null);
              idxsList = [];
            }
            copyCalendarShifts = copyCalendarShifts.filter((x: any) => x != null);
          });

          this.calendarShifts = this.clonerService.deepClone(copyCalendarShifts);
          this.updateCalendar(this);
          this.setDayShiftColor();

        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // OPEN DOWNTIME PLANNING

  openDowntimePlanning(daysList: any) {

    this.selectedDaysCopy = this.clonerService.deepClone(daysList);

    const addDowntimePlanningDialog = this.dialog.open(AddDowntimeDialogComponent,
      {
        panelClass: 'ff-dialog',
        // width: '40%',
        height: 'auto',
        data: {
          title: this.translate.instant("CALENDAR.EVENT_CREATION"),
          machine: this.machine,
          appConfig: this.appConfig,
          selectedDaysCopy: this.selectedDaysCopy,
          calendarStates: this.calendarStates
        },
      });

    addDowntimePlanningDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          // console.log(result);

          let copyCalendarShifts: any = this.clonerService.deepClone(this.calendarShifts);

          this.selectedDaysCopy.forEach((day: any) => {
            // Push invalid event
            copyCalendarShifts.push({
              validity: result.invalidity,
              shiftId: null,
              workDay: day,
              template: null,
              operatorIds: null,
              from: moment(day).add(result.slider.min, 's').format("YYYY-MM-DDTHH:mm:ss.SSS"),
              to: moment(day).add(result.slider.max, 's').format("YYYY-MM-DDTHH:mm:ss.SSS"),
            });

          });

          this.calendarShifts = this.clonerService.deepClone(copyCalendarShifts);
          this.updateCalendar(this);

        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // ASSIGN OPERATORS

  openOperatorAssign(daysList: any) {

    this.selectedDaysCopy = this.clonerService.deepClone(daysList);

    let copyCalendarShifts: any = this.clonerService.deepClone(this.calendarShifts);

    this.isOperators = null;
    let firstTemplate = null;
    try {
      firstTemplate = copyCalendarShifts.filter(x => x.validity == 0).find(x => x.workDay == this.selectedDays[0]).template;
    } catch (error) { }

    if (firstTemplate == null) {
      console.log('Non dovrebbe succedere');
    }

    let currentTemplate: any = this.clonerService.deepClone(this.shiftTemplates.find(x => x.id == firstTemplate));

    if (this.selectedDaysCopy.length == 1) {
      currentTemplate.shifts.forEach(shift => {
        let ix = copyCalendarShifts.findIndex(x => x.workDay == this.selectedDaysCopy[0] && x.shiftId == shift.shiftId);
        if (ix != -1 && copyCalendarShifts[ix].operatorIds != null) {
          shift.operatorIds = [];
          copyCalendarShifts[ix].operatorIds.split("$$").forEach(op => {
            shift.operatorIds.push(op);
          });
        }
      });
    } else {
      this.isOperators = this.selectedDaysCopy.some(day => {
        return currentTemplate.shifts.some(shift => {
          let ix = copyCalendarShifts.findIndex(x => x.workDay == day && x.shiftId == shift.shiftId);
          return ix != -1 && copyCalendarShifts[ix].operatorIds != null;
        });
      });
      currentTemplate.shifts.forEach(shift => shift.operatorIds = []);
    }

    // console.log(currentTemplate);

    const assignOperatorsDialog = this.dialog.open(AssignOperatorsDialogComponent,
      {
        panelClass: 'ff-dialog',
        height: 'auto',
        data: {
          title: this.translate.instant("CALENDAR.OPERATOR_ID"),
          machine: this.machine,
          machineId: this.machineId,
          appConfig: this.appConfig,
          currentTemplate: currentTemplate,
          selectedDaysCopy: this.selectedDaysCopy,
          isOperators: this.isOperators,
          operatorsList: this.operatorsList,
        },
      });

    assignOperatorsDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {

        let copyCalendarShifts: any = this.clonerService.deepClone(this.calendarShifts);

        this.selectedDaysCopy.forEach(day => {

          result.shifts.filter(x => x.operatorIds != null).forEach(shift => {
            let ix = copyCalendarShifts.findIndex(x => x.workDay == day && x.shiftId == shift.shiftId);
            if (ix != -1) {
              shift.operatorIds = shift.operatorIds.filter(x => x != null);
              copyCalendarShifts[ix].operatorIds = shift.operatorIds.length > 0 ? shift.operatorIds.join("$$") : null;
            }
          });
        });

        this.calendarShifts = this.clonerService.deepClone(copyCalendarShifts);
        this.updateCalendar(this);
      }

    });
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // UPDATE CALENDAR

  updateCalendar(_this: any, machineId: any = null) {
    try {

      let query: any = {
        minimumCalendarUnit: _this.machine.profile?.settings?.minimumCalendarUnit ?? '30m',
      };
      let payload: any = _this.clonerService.deepClone(_this.calendarShifts);
      let url = '/apif/calendar/' + (machineId != null ? machineId : _this.machineId);

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            if (machineId == null) {
              if (_this.calendarShifts == null) {
                _this.calendarShifts = _this.clonerService.deepClone(data.body);
                _this.calendarShiftsUnparsed = _this.clonerService.deepClone(data.body);
              }
              try {

                // set plot data and configs
                _this.parseCalendarDays(_this);

              } catch (error) {
                console.log(error);
              }
              _this.dispatcherService.getDispatch(_this, 300);
            } else {

              this.snackBar.open(this.translate.instant('CALENDAR.ASSETS_EXPORTED_SUCCESSFULLY'), '', {
                horizontalPosition: 'right',
                verticalPosition: 'bottom',
                duration: 2000,
                panelClass: ['snackbar', 'success']
              });
            }

          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // UPDATE SHIFT TEMPLATES

  updateShiftTemplates(_this: any, machineId: any = null) {
    try {

      let query: any = null;
      let payload: any = _this.clonerService.deepClone(_this.shiftTemplates);
      let url = '/apif/calendar-shifts/' + (machineId != null ? machineId : _this.machineId);

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => { }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.isEnabledCalendarAsset = this.filterService.isActiveCalendar(this.machineId);

    this.dispatcherService.getDispatch(this, 300);

  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setDayShiftColor();
    }, 1000);
  }

  setDayShiftColor() {
    // console.log('setDayShiftColor')

    this.setDayBackground();

    try {
      let list: any = [];
      let workConfig = this.calendarStates.find((x: any) => x.id == 0);
      let stateConfig: any = null;
      if (workConfig != null && this.calendarShifts != null && this.calendarShifts.length > 0) {
        this.calendarShifts.forEach((timeRange: any) => {
          // State config
          if (timeRange.hasOwnProperty('validity') && timeRange.validity != null) {
            stateConfig = this.calendarStates.find((x: any) => x.id == timeRange.validity);
          }
          // color assigned to shift template
          if (list.findIndex((x: any) => x.allDay && x.start == timeRange.workDay) == -1) {
            let ix = this.shiftTemplates.findIndex((st: any) => st.id == timeRange.template);
            if (ix != -1) {
              list.push({
                start: timeRange.workDay,
                allDay: true,
                display: 'background',
                color: this.shiftTemplates[ix].color
              });
            }
          }
        });
      }

      this.setDayBackground(list);

    } catch (error) {
    }
  }

  setDayBackground(list: any = []) {
    try {

      let fullcalendarObj: any = document.getElementById('fullcalendar');

      // let dates = fullcalendarObj.querySelectorAll('a.fc-daygrid-day-number');
      let dates = fullcalendarObj.querySelectorAll('.fc-daygrid-day.fc-day');
      for (let i = 0; i < dates.length; i++) {
        let day = dates[i];
        if (day && day.dataset && day.dataset.date) {
          // let navdate = JSON.parse(day.dataset.date);
          let navdate = day.dataset.date;
          let elem = document.getElementById(day.getAttribute("aria-labelledby"))

          // First set all the labels to transparent
          setDayBG(elem, navdate, "#FFFFFF00");

          // Then color only the ones in the list
          setDayBG(elem, navdate);
        }
      }
      // let colHT = fullcalendarObj.querySelectorAll('.fc-col-header-cell.fc-day.fc-day-today');
      // for (let i = 0; i < colHT.length; i++) {
      //   let day = colHT[i];
      //   if (day && day.dataset && day.dataset) {
      //     let navdate = day.dataset;
      //     setDayBG(day, navdate);
      //   }
      // }
      // let colHF = fullcalendarObj.querySelectorAll('.fc-col-header-cell.fc-day.fc-day-future');
      // for (let i = 0; i < colHF.length; i++) {
      //   let day = colHF[i];
      //   if (day && day.dataset && day.dataset) {
      //     let navdate = day.dataset;
      //     setDayBG(day, navdate);
      //   }
      // }
      // let colHP = fullcalendarObj.querySelectorAll('.fc-col-header-cell.fc-day.fc-day-past');
      // for (let i = 0; i < colHP.length; i++) {
      //   let day = colHP[i];
      //   if (day && day.dataset && day.dataset) {
      //     let navdate = day.dataset;
      //     setDayBG(day, navdate);
      //   }
      // }

      function setDayBG(day: any, navdate: any, color?) {
        // day.style.background = "#FFFFFF00";
        if (color != null) day.style.background = color;
        else if (list != null && list.length > 0) {

          let color = list.find((x: any) => x.start.toString() === navdate)?.color;

          if (color != null) {

            // RBG to 40% opacity
            if (color.startsWith('rgb')) color = color.replace('rgb', 'rgba').replace(')', ', 0.4 )')

            // HEX to 40% opacity
            else if (color.startsWith('#')) color = color + '66';

            day.style.background = color;
          }
        }
      }
    } catch (error) {
    }
  }

  openExportCalendar() {
    const exportCalendarDialog = this.dialog.open(ExportCalendarDialogComponent,
      {
        panelClass: 'ff-dialog',
        height: 'auto',
        data: {
          title: this.translate.instant("CALENDAR.EXPORT_CALENDAR"),
          machine: this.machine,
          machineId: this.machineId,
          appConfig: this.appConfig,
        },
      });

    exportCalendarDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '' && result.length > 0) {
        result.forEach((asset: any) => {
          this.updateShiftTemplates(this, asset.machineId);
          this.updateCalendar(this, asset.machineId);
        });
      }

    });

  }

  ngOnChanges() {
    this.setDayShiftColor();
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingEvents.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
  }

}