import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { CheckTranslationPipe } from 'src/app/pipes/checkTranslation.pipe';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';
import { ComponentVariablesSelectionComponent } from '../../machine-recorder/component-variables-selection/component-variables-selection.component';
import { CycleSelectionDialogNewComponent } from '../../machine-recorder/cycle-selection-dialog-new/cycle-selection-dialog-new.component';
import { CycleSelectionDialogComponent } from '../../machine-recorder/cycle-selection-dialog/cycle-selection-dialog.component';

@Component({
  selector: 'app-hm-cycle-exploration',
  templateUrl: './hm-cycle-exploration.component.html',
  styleUrls: ['./hm-cycle-exploration.component.scss']
})
export class HmCycleExplorationComponent implements OnInit {

  public isEnabledAD: boolean = true;

  public loadingData: any;
  public errorData: any;
  public errorDataMobile: any;


  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public backButton: any;
  public tabs: any;

  public HMComponentSelectedSub: Subscription;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public aggregations: any;
  public availableMachines: any;

  public dashboardConfig: any;

  public defaultInterval: any;
  public interval: any;
  public intervalConfig: any;

  public componentConfig: any = {};
  public componentId: any;
  public machineRefId: any;

  public sectionName: any = "healthMonitoringDetail";
  public dashboardName: any = "health-cycle-exploration";

  public monitoringData: any = {};
  public isMobile: boolean = false;

  public barColors: any;

  public showResetInterval: boolean = false;
  public zoomedIntervalVariablesSub: Subscription;

  public buffers: any = {};
  public plotsConfig: any;
  public plotsConfigComponents: any;

  public process: any = false;
  public variables: any = {
    dict: {},
    list: [],
    selected: []
  };

  public variablesData: any = {
    actual: null,
    comparison: null,
  };

  public cycleInfos: any = {
    actual: null,
    comparison: null,
  };

  public srsActual: any;
  public srsComparison: any;

  public cycleDays: any = {
    list: [],
    selected: null
  };

  public cycleDaysComparison: any = {
    list: [],
    selected: null
  };

  public cycles: any = {
    list: [],
    selected: null
  };

  public cyclesComparison: any = {
    list: [],
    selected: null
  };

  public checkTranslPipe: any = new CheckTranslationPipe(this.translate);
  public returnStates: any = 0;
  public returnPhases: any = 0;
  public enabledCSVDownload: any = 0;

  public dataTimestamps: any = {};
  public traces: any;

  public colors: any = [
    '#66DA26', '#546E7A', '#E91E63', '#FF9800',
    "#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D",
    "#33B2DF", "#D4526E", "#13D8AA", "#A5978B",
    "#4ECDC4", "#C7F464", "#81D4FA", "#FD6A6A",
    "#2B908F", "#F9A3A4", "#90EE7E", "#FA4443",
    "#449DD1", "#F86624", "#EA3546", "#662E9B",
    "#D7263D", "#1B998B", "#2E294E", "#F46036",
    "#F9C80E", "#43BCCD", "#69D2E7", '#008FFB',
    "#5C4742", "#8D5B4C", "#5A2A27", "#C4BBAF",
    "#A300D6", "#7D02EB", "#5653FE", "#2983FF",
    "#C5D86D", "#E2C044", "#00B1F2",
  ];

  public tableInfos: any;
  public bTableInfos: any;

  public isComparedCycle: boolean = false;
  public isChangedComparedCycle: boolean = false;
  public comparedCycleId: any;

  public isOpenAverageValues: boolean = false;
  public isDashboard: any = false;

  public features: any;
  public useFeaturesInsteadOfSrs: any = false;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 10;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 'dash', loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 'dash',
      codes: [
        { code: 300, function: this.getDashboard, nextState: 2, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getComponentsConfig, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getCycleDays, nextState: 5, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getCycles, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.getCycleDetail, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.getVariablesConfig, nextState: 8, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 },
      ]
    },
    {
      state: 8,
      codes: [
        { code: 300, function: this.getVariablesData, nextState: 9 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 },
        { code: 302, function: this.dispatcherService.completeDispatch, nextState: 10 },
      ]
    },
    {
      state: 9,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 10 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];
  processes: any;
  public mobileListener: Subscription;


  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public snackBar: MatSnackBar,
    public mobile: MobileService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));
    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['HEALTH_MONITORING.TITLE', 'CYCLE_EXPLORATION.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb[2] = value.machineName;
        this.breadcrumb = newBreadcrumb;
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.HMComponentSelectedSub = this.internalDataService.HMComponentSelected.subscribe(value => {
      if (value != null) {
        this.breadcrumb = this.internalDataService.getHMBreadcrumb(this, value);
        this.internalDataService.setBreadcrumb(this.clonerService.deepClone(this.breadcrumb));
      }
    });

    this.barColors = this.internalDataService.defaultPlotlyColors;

    this.monitoringData = {
      dataConfig: {
        plotDataAttribute: 'plotData',
        flagEventsDataAttribute: 'flagData',
      }
    };

    try {
      this.tableInfos = this.appConfig.machineRecorder.cycleExploration.tableInfos;
    } catch (error) {
      console.log(error);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName, true);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
    } catch (error) {
      console.log(error);
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET COMPONENTS CONFIG

  getComponentsConfig(_this: any) {

    let c_customVariables = _this.cacheService.get("customVariables");

    _this.features = []
    if (_this.appConfig?.addCustomVariables && c_customVariables?.machineId == _this.machineId && c_customVariables?.value != null) {
      _this.useFeaturesInsteadOfSrs = true;
      _this.features = c_customVariables.value.features;
    }

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getComponentsConfig(_this, _this.machineId, _this.machineRefId, _this.componentId, true);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET VARIABLES CONFIG

  getVariablesConfig(_this: any) {
    try {

      _this.componentsList = _this.machine.components.map((x: any) => {
        return {
          label: _this.translate.instant("components." + x),
          id: x
        }
      });

      try {
        _this.processes = _this.machine.machines[_this.machineRefId].processes;
      } catch (error) { _this.processes = false }

      _this.variables = {
        dict: {},
        list: [],
        selected: []
      };

      _this.internalDataService.getVariablesConfig(_this, _this.machineId, _this.machineRefId, _this.componentId, false, _this.processes, true);

    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  machineSelectionChange(machine: any) {

    if (machine != null) {

      this.cacheService.reset("selectedComponent");
      this.cacheService.reset("plotsConfigComponents");
      this.cacheService.reset("plotsConfig");

      if (this.machine.profile.flags?.clearCyclesOnMachineChange) {
        this.removeComparedCycle();
        this.cacheService.set("totalCycles", null);
        this.cacheService.set("selectedCycle", null);
      }

      this.pageState.next(5);
      this.getCycleDays(this);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, null, this.getVariablesData, this.getVariablesData, this.machine.timezone, 6, 7) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DAYS

  getCycleDays(_this: any) {

    let c_comparedDayCycle = _this.cacheService.get('comparedDayCycle');

    let a = moment();
    let b = moment().subtract(365, 'days');

    let data: any = [];
    for (let m = moment(a); b.diff(m, 'days') < 0; m.subtract(1, 'days')) {
      data.push(moment(m).format("YYYY-MM-DD"));
    }

    let c_days = _this.cacheService.get('cycleDays');
    if (c_days == null) {
      _this.cycleDays.list = data;
      _this.cycleDays.selected = data[0];
      _this.cycleDaysComparison.list = _this.clonerService.deepClone(data);
      _this.cycleDaysComparison.selected = c_comparedDayCycle != null ? c_comparedDayCycle : data[0];
    } else {
      _this.cycleDays = c_days;
      _this.cycleDaysComparison = _this.clonerService.deepClone(c_days);
      _this.cycleDaysComparison.selected = c_comparedDayCycle != null ? c_comparedDayCycle : c_days.selected;
    }

    if (_this.cycleDays.list.length > 0) {

      _this.cacheService.set('comparedDayCycle', _this.cycleDaysComparison.selected);

      _this.cacheService.set('cycleDays', {
        list: _this.cycleDays.list,
        selected: _this.cycleDays.selected
      });

      _this.dispatcherService.getDispatch(_this, 300);

    } else {

      let errorData = {
        type: 0,
        status: 500,
        message: 'No days available for the current component'
      };

      _this.dispatcherService.getDispatch(_this, 301, errorData);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET CYCLES

  getCycles(_this: any, comparison: any) {

    _this.componentsList = _this.machine.components.map((x: any) => {
      return {
        label: _this.translate.instant("components." + x),
        id: x
      }
    });

    let url = '/apif/condition-monitoring/cycles-detail/' + _this.machineId + '/' + _this.componentId;

    let query: any = {
      tags: _this.aggregations != null ? _this.aggregations.map((x: any) => x.id).join(',') : "",
      day: _this.cycleDays.selected,
      // anomalyDetection: _this.isEnabledAD,
      anomalyDetection: true,
      tz: _this.machine.timezone
    };

    if (_this.machineRefId != null) {
      query.machineId = _this.machineRefId;
      try {
        query.processes = _this.machine.machines[_this.machineRefId].processes.join(";");
      } catch (error) { }
    }

    let payload: any = {};

    if (_this.machine.profile.additionalCycleVariables?.length > 0) payload.additionalCycleVariables = _this.clonerService.deepClone(_this.machine.profile.additionalCycleVariables);

    _this.apiService.sendPostRequest(url, payload, query)
      .pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
      )
      .subscribe(
        (data: any) => {
          // con
          let parsedData = _this.internalDataService.parseCycleInfos(data.body, false, _this.tableInfos);
          // let parsedData = data.body;

          var c_comparedCycle = _this.cacheService.get('comparedCycle');
          var c_selectedCycle = _this.cacheService.get('selectedCycle');

          if (!comparison) {
            _this.cycles.list = parsedData;
            _this.cycles.selected = c_selectedCycle != null ? c_selectedCycle : parsedData[parsedData.length - 1];
            console.log(_this.cycles.selected);

            _this.cyclesComparison.list = parsedData;
            _this.cyclesComparison.selected = c_comparedCycle != null ? c_comparedCycle : null;
            _this.cyclesComparison.indexList = _this.cyclesComparison.selected != null ?
              parsedData.findIndex((x: any) => x.cycleId == _this.cyclesComparison.selected.cycleId) : null;
          } else {
            _this.cyclesComparison.list = parsedData;
            _this.cyclesComparison.selected = c_comparedCycle != null ? c_comparedCycle : null;
            _this.cyclesComparison.indexList = _this.cyclesComparison.selected != null ?
              parsedData.findIndex((x: any) => x.cycleId == _this.cyclesComparison.selected.cycleId) : null;
            _this.cacheService.set('comparedCycle', _this.cyclesComparison.selected);
          }

          _this.cacheService.set('totalCycles', {
            list: _this.cycles.list,
            selected: _this.cycles.selected
          });

          if (_this.cycles.selected != null) {
            _this.dispatcherService.getDispatch(_this, 300);
          } else {
            _this.pageState.next(8);
            _this.getVariablesData(_this, comparison, 0);
          }

        }
      );
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET CYCLE DETAIL

  getCycleDetail(_this: any, comparison: any) {

    let name = !comparison ? 'actual' : 'comparison';

    _this.cycleInfos[name] = !comparison ?
      _this.cycles.selected :
      _this.cyclesComparison.selected;

    if (!comparison) {
      _this.getCycleDetail(_this, true);
    } else {
      _this.dispatcherService.getDispatch(_this, 300);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET VARIABLES DATA

  getVariablesData(_this: any, comparison?: any, range?: any) {
    try {

      if (_this.cycles.selected == null) {
        _this.pageState.next(9);
        return;
      }

      let name = !comparison ? 'actual' : 'comparison';
      let dayId = !comparison ? _this.cycleDays.selected : _this.cycleDaysComparison.selected;
      let cycleIdId = !comparison ? _this.cycles.selected.cycleId : (_this.cyclesComparison.selected != null ? _this.cyclesComparison.selected.cycleId : null);

      let url = '/apif/cycle-traceability/timeserie/' + _this.machineId + '/' + _this.componentId + '/' + dayId + '/' + cycleIdId;

      if (comparison && (_this.cyclesComparison.selected == null || _this.cyclesComparison.selected.cycleId == null || _this.cyclesComparison.selected.cycleId == _this.cycles.selected.cycleId)) {
        _this.cyclesComparison.selected = null;
        _this.dispatcherService.getDispatch(_this, 300);
        return;
      }

      if (!_this.isChangedComparedCycle && comparison && (_this.variablesData[name] != null || _this.isBaricentalComparedCycle)) {
        // if (_this.isBaricentalComparedCycle) calculateBarycentralMeans();
        _this.drawPlot(_this, _this.variablesData[name], range, comparison);
        _this.dispatcherService.getDispatch(_this, 300);
        return;
      }

      let query: any = {
        anomalyDetection: _this.isEnabledAD,
        tz: _this.machine.timezone,
        timeEnd: !comparison ? _this.cycles.selected.timestamp : _this.cyclesComparison.selected.timestamp,
        // duration: 600,
        duration: !comparison ? _this.cycles.selected.cycleTime : _this.cyclesComparison.selected.cycleTime,
      };


      let machineReference = null;
      if (_this.availableMachines != null && _this.availableMachines.selected != null) {
        machineReference = _this.availableMachines.selected === 'Line' ? _this.machine.machineReference : _this.availableMachines.selected;
      } else if (_this.machine.machineReference) {
        machineReference = _this.machine.machineReference;
      }

      // if (machineReference != null) {
      //   query.machineId = machineReference;
      //   try {
      //     query.processes = _this.machine.machines[machineReference].processes.join(";");
      //     if (_this.returnPhases) query.returnPhases = 1;
      //   } catch (error) { }
      // }

      if (_this.returnStates == 1) query.returnStates = 1;

      if (_this.machineRefId != null) {
        try {
          query.machineId = _this.machineRefId;
          try {
            query.processes = _this.machine.machines[_this.machineRefId].processes.join(";");
          } catch (error) { }
        } catch (error) { console.log(error) }
      }

      // if (_this.availableMachines != null && _this.availableMachines.selected != null) {
      //   query.machineId = _this.availableMachines.selected === 'Line' ? _this.machine.machineReference : _this.availableMachines.selected;
      // } else if (_this.machine.machineReference) {
      //   query.machineId = _this.machine.machineReference;
      // }

      let payload: any = {
        variables: _this.variables.dict,
        buffers: _this.buffers ? _this.buffers.list : [],
      };

      if (_this.features?.length > 0) payload.features = _this.features?.map(x => x.id)

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.variablesData[name] = _this.clonerService.deepClone(data.body);

            if (!comparison) {
              _this.srsActual = [];
            } else {
              _this.isChangedComparedCycle = false;
              _this.srsComparison = [];
            }

            if (!_this.isComparedCycle) {
              _this.srsComparison = [];
            }

            if (data.body?.dataAD != null) {

              if (data.body.dataAD.features?.length > 0 && _this.features.length > 0) {

                _this.features.forEach((f: any) => {

                  console.log({ f });
                  let fData = data.body.dataAD.features.find(x => x.id == f.id) ?? {};

                  try {
                    if (!comparison) {
                      _this.srsActual.push({
                        id: f.id,
                        label: f.translations?.[_this.translate.currentLang] ?? f.id,
                        valueUnparsed: fData.value != null ? fData.value * f.multiplier ?? 1 : null,
                        value: fData.value != null ? _this.filterService.parseGaugeValue(fData.value, f.decimals ?? 2, f.multiplier ?? 1) : '-',
                        unit: f.unit,
                      });

                    }

                    if (_this.isComparedCycle) {
                      if (comparison) {
                        let ix = _this.srsActual.findIndex((x: any) => x.id == f.id);
                        if (ix != -1) {
                          _this.srsActual[ix].valueComparedUnparsed = fData.value != null ? fData.value * f.multiplier ?? 1 : null;
                          _this.srsActual[ix].valueCompared = fData.value != null ? _this.filterService.parseGaugeValue(fData.value, f.decimals ?? 2, f.multiplier ?? 1) : '-';
                          _this.srsComparison.push(_this.srsActual[ix]);
                        }
                      } else {
                        let ix = _this.srsComparison.findIndex((x: any) => x.id == f.id);
                        if (ix != -1) {
                          let actualIx = _this.srsActual.findIndex((x: any) => x.id == f.id);
                          if (actualIx != -1) {
                            _this.srsActual[actualIx].valueComparedUnparsed = _this.srsComparison[ix].valueComparedUnparsed;
                            _this.srsActual[actualIx].valueCompared = _this.srsComparison[ix].valueCompared;
                          }
                        }
                      }
                    }
                  } catch (error) {
                    console.log(error);
                  }
                })
              }

              else if (data.body.dataAD.variables?.length > 0) {

                data.body.dataAD.variables.forEach((srsVar: any) => {

                  try {
                    let currentPlotConfig = _this.plotsConfig.list.filter((x: any) => {
                      return x.traces.findIndex((y: any) => y.srsName == srsVar.id) != -1;
                    })[0];
                    if (currentPlotConfig != null) {
                      let ix = currentPlotConfig.traces.findIndex((y: any) => y.srsName == srsVar.id);
                      if (ix != -1 && currentPlotConfig.traces[ix].srs) {
                        if (!comparison) {
                          _this.srsActual.push({
                            id: srsVar.id,
                            label: _this.checkTranslPipe.transform(("CONTINUOUS_EXPLORATION.TRACES." + currentPlotConfig.traces[ix].name.split(".")[1]), currentPlotConfig.traces[ix].name.split(".")[1], false),
                            valueUnparsed: srsVar.value,
                            value: srsVar.value != null ? _this.filterService.parseGaugeValue(srsVar.value, 2, currentPlotConfig.multiplier != null ? currentPlotConfig.multiplier : 1) : '-',
                            unit: currentPlotConfig.unit,
                          });
                        }

                        if (_this.isComparedCycle) {
                          if (comparison) {
                            let ix = _this.srsActual.findIndex((x: any) => x.id == srsVar.id);
                            if (ix != -1) {
                              _this.srsActual[ix].valueComparedUnparsed = srsVar.value;
                              _this.srsActual[ix].valueCompared = srsVar.value != null ? _this.filterService.parseGaugeValue(srsVar.value, 2, currentPlotConfig.multiplier != null ? currentPlotConfig.multiplier : 1) : '-';
                              _this.srsComparison.push(_this.srsActual[ix]);
                            }
                          } else {
                            let ix = _this.srsComparison.findIndex((x: any) => x.id == srsVar.id);
                            if (ix != -1) {
                              let actualIx = _this.srsActual.findIndex((x: any) => x.id == srsVar.id);
                              if (actualIx != -1) {
                                _this.srsActual[actualIx].valueComparedUnparsed = _this.srsComparison[ix].valueComparedUnparsed;
                                _this.srsActual[actualIx].valueCompared = _this.srsComparison[ix].valueCompared;
                              }
                            }
                          }
                        }

                      }
                    }
                  } catch (error) {
                    console.log(error);
                  }
                })
              }
            }

            _this.drawPlot(_this, _this.variablesData[name], range, comparison);
            if (!comparison) {
              _this.getVariablesData(_this, true, range);
            } else {
              _this.dispatcherService.getDispatch(_this, 300);
            }


          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      console.log(error)
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // DRAW PLOT

  drawPlot(_this: any, data: any, range?: any, comparison?: any, skipGetData?: any) {

    if (comparison && !_this.isComparedCycle) {
      _this.dispatcherService.getDispatch(_this, skipGetData ? 302 : 300);
      return;
    }

    if (!comparison) {
      _this.traces = [];
    }
    // Chart height 
    // var chartHeight = 500;

    var filteredConfigsLength = _this.plotsConfig.list.filter((c: any) => c.show).length;

    var domain = _this.getDomain(filteredConfigsLength);

    // console.log([moment(_this.interval.start).valueOf(), moment(_this.interval.end).valueOf()]);
    // console.log([_this.interval.start, _this.interval.end]);
    // Default layout
    var layout: any = {
      colorway: _this.colors,
      margin: {
        t: 40,
        b: 50,
        r: 25,
        l: 25,
        pad: 0
      },
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: -0.15
      },
      xaxis: {
        type: 'date',
        tickformat: "%H:%M:%S.%f",
        // range: [moment(_this.interval.start).valueOf(), moment(_this.interval.end).valueOf()],
        domain: domain,
        zeroline: false,
        showgrid: false,
      },
      // height: chartHeight,
    };

    let configIdx = 0;
    let colorIndex = 0;

    _this.plotsConfig.list.filter((c: any) => c.show).forEach((config: any) => {

      configIdx += 1;

      layout['yaxis' + configIdx] = {
        fixedrange: true,
        zeroline: false,
        showgrid: false,
        automargin: true,
        anchor: 'free',
        // anchor: configIdx != 2 ? 'free' : '',
        position: configIdx > 1 ? (configIdx % 2 != 0 ? 0.05 * (configIdx - 1) / 2 : (1 - (0.05 * (configIdx - 2) / 2))) : null,
        overlaying: configIdx > 1 ? 'y' : null,
        side: configIdx % 2 == 0 ? 'right' : 'left',
        title: {
          // standoff: 50,
          text: config.unit
        }
      };

      let name = !comparison ? 'actual' : 'comparison';

      Object.entries(data.aspects).forEach((aspectValues: any) => {

        const aspect = aspectValues[0];
        const values = aspectValues[1];

        if (!comparison) {
          _this.dataTimestamps[aspect] = values.timestamps;
        }

        if (config.hasOwnProperty('traces') && config.traces != null && config.traces.length > 0 &&
          Object.keys(values).length > 0 && values.hasOwnProperty('variables') && values.variables != null) {
          config.traces.filter((x: any) => values.variables.hasOwnProperty(x.id)).forEach((x: any, idx: any) => {

            colorIndex += 1;

            let delta = 0;
            if (comparison) {
              if (values.hasOwnProperty('timestamps')) {
                if (!values.timestampsUnparsed) values.timestampsUnparsed = _this.clonerService.deepClone(values.timestamps);
                delta = moment(_this.dataTimestamps[aspect][0]).diff(values.timestamps[0]);
                // console.log(values.timestamps);
                values.timestamps.forEach((x: any, idx: any) => values.timestamps[idx] = moment(x).add(delta).format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
                // } else if (values.hasOwnProperty('seconds')) {
                //     values.timestamps = [];
                //     let start = moment(_this.dataTimestamps[aspect][0]);
                //     values.seconds.forEach((delta) => values.timestamps.push(start.add(delta, 'milliseconds').format("YYYY-MM-DDTHH:mm:ss.SSSZ")));
              } else {

                values.timestamps = [];
                let unit = _this.samplingsMilli != null && _this.samplingsMilli[aspect] != null ? _this.samplingsMilli[aspect] : 50;
                let samples = values.variables[x.id].length * unit;
                let start = moment(_this.dataTimestamps[aspect][0]);

                for (let i = 0; i <= samples; i += unit) {
                  values.timestamps.push(start.add(unit, 'milliseconds').format("YYYY-MM-DDTHH:mm:ss.SSSZ"));
                }
              }
            }

            let traceName = x.traceType == 'setpoint' ? (_this.translate.instant("CYCLE_EXPLORATION.SETPOINT") + ' - ') : '';
            if (comparison && _this.isBaricentalComparedCycle) {
              traceName += _this.translate.instant("CYCLE_EXPLORATION.BARYCENTRAL_CYCLE") + ' - ' + x.label;
            } else {
              traceName += !comparison ? x.label : _this.translate.instant("CYCLE_EXPLORATION.COMPARISON") + ' - ' + x.label;
            }
            let multiplier = x.multiplier != null ? x.multiplier : (config.multiplier != null ? config.multiplier : 1);

            let timestamps = comparison ? values.timestampsUnparsed : values.timestamps;
            let dateFormat = comparison ? "YYYY-MM-DD HH:mm:ss.SSS" : "HH:mm:ss.SSS";

            let hovertext = values.variables[x.id].map((v: any, xIdx: any) => {

              let hover = "<b>" + _this.translate.instant("CYCLE_EXPLORATION.VARIABLE") + ": </b>" + (x.paramRFlag ? x.id + ' - ' : '') + traceName + '<br>' +
                "<b>" + _this.translate.instant("CYCLE_EXPLORATION.VALUE") + ": </b>" + (v != null ? _this.filterService.parseGaugeValue(v, 2, multiplier) : '-') +
                " " + layout['yaxis' + configIdx].title.text + '<br>' +
                "<b>" + _this.translate.instant("CYCLE_EXPLORATION.TIME") + ": </b>" + moment(timestamps[xIdx]).format(dateFormat) + '<br>';
              return hover;
            });

            _this.traces.push({
              yaxis: 'y' + configIdx,
              name: traceName,
              id: x.id + '-' + name,
              opacity: !comparison ? 1 : 0.5,
              legendgroup: x.id,
              visible: x.show,
              showlegend: !comparison ? true : false,
              // mode: 'lines',
              mode: x.traceType == 'setpoint' ? 'lines' : 'lines+markers',
              type: 'scattergl',
              text: hovertext,
              hoverinfo: "text",
              line: {
                // shape: 'spline',
                dash: !comparison ? (x.traceType == 'setpoint' ? 'dot' : 'solid') : (x.traceType == 'setpoint' ? 'dashdot' : 'dot'),
                color: _this.colors[configIdx + colorIndex]
              },
              x: values.timestamps,//.map((x: any) => x.substring(0, x.length - 6)),
              y: values.variables[x.id].map((x: any) => x != null ? x * multiplier : null),
            });
          });
        }

      });
    });

    try {
      if (_this.aggregations != null && _this.aggregations.length > 0) {
        Object.entries(data).forEach((aspectValues: any) => {

          const aspect = aspectValues[0];
          const values = aspectValues[1];

          if (aspect != 'dataAD' && aspect != 'states' && aspect != 'table' && aspect != 'aggr2') {
            _this.aggregations.forEach((aggr: any) => {
              if (values != null && values.hasOwnProperty('variables') && values.variables != null &&
                values.variables.hasOwnProperty(aggr.id) && values.variables[aggr.id] != null && values.variables[aggr.id].length > 0) {

                let yValuesIdx = _this.traces.findIndex((x: any) => x.yaxis == 'y1');
                let yVals = (yValuesIdx != -1) ? _this.clonerService.deepClone(_this.traces[yValuesIdx].y) : [];

                _this.traces.push({
                  name: aggr.label,
                  id: aggr.id,
                  showlegend: false,
                  mode: 'lines',
                  type: 'scattergl',
                  line: {
                    width: 0
                  },
                  hovertext: values.variables[aggr.id].map((x: any) => '<b>' + aggr.label.capitalize() + '</b>: ' + x),
                  hoverinfo: "text",
                  x: values.timestamps,
                  y: yVals,
                });
              }
            });
          }
        });
      }
    } catch (error) {
      console.log(error);
    }

    // layout.annotations = [];
    if (_this.returnPhases && !comparison &&
      data.hasOwnProperty('phases') && data.phases != null && data.phases.length > 0) {

      layout.shapes = [];

      data.phases.forEach((bar: any) => {
        let color = null;
        try {

          let c_cyclePhases = _this.cacheService.get("cyclePhases");

          let phaseIdx = (c_cyclePhases != null ? c_cyclePhases : _this.machine.profile.cyclePhases).filter((x: any) => x.enabled).findIndex((x: any) => x.id == bar.value);
          if (phaseIdx != -1) {
            color = (c_cyclePhases != null ? c_cyclePhases : _this.machine.profile.cyclePhases).filter((x: any) => x.enabled)[phaseIdx].color;
          }
        } catch (error) {
          console.log(error);
        }
        layout.shapes.push({
          type: 'rect',
          xref: '',
          yref: 'paper',
          x0: bar.timeStart,
          y0: 0,
          x1: bar.timeEnd,
          y1: 1,
          fillcolor: color,
          opacity: 0.2,
          line: {
            width: 0
          }
        });
      });

      _this.phasesCopy = _this.clonerService.deepClone(layout.shapes);
    }

    if (_this.returnPhases && comparison) {
      layout.shapes = _this.phasesCopy;
    }

    colorIndex = 0;

    if (!comparison) {
      _this.bufferTraces = [];
    }

    if (data.buffers != null && Object.keys(data.buffers).length > 0) {

      let layoutBuffer = {
        margin: {
          t: 40,
          b: 50,
          r: 25,
          l: 25,
          pad: 0
        },
        legend: {
          orientation: 'h',
          traceorder: 'normal',
          x: 0,
          y: -0.25
        },
        xaxis: {
          title: _this.buffers.xUnit,
          zeroline: false,
          showgrid: false,
          automargin: true,
        },
        yaxis: {
          title: _this.buffers.yUnit,
          zeroline: false,
          showgrid: false,
          automargin: true,
        }
      };

      Object.entries(data.buffers).forEach((aspectValues: any) => {

        const aspect = aspectValues[0];
        const variables = aspectValues[1];

        Object.entries(variables).forEach((varVals: any) => {

          const variable = varVals[0];
          const values = varVals[1];

          colorIndex += 1;

          // console.log(_this.buffers.list, variable);

          let bufferConfig = _this.buffers.list.find((x: any) => x.id == variable);

          let bufferName = '';
          if (bufferConfig != null) {
            bufferName += bufferConfig.traceType == 'setpoint' ? (_this.translate.instant("CYCLE_EXPLORATION.SETPOINT") + ' - ') : '';
            bufferName += !comparison ? variable : _this.translate.instant("CYCLE_EXPLORATION.COMPARISON") + ' - ' + variable;
            let hovertext = values.yAxis.map((x: any, xIdx: any) => {
              return "<b>" + _this.translate.instant("CYCLE_EXPLORATION.VARIABLE") + ": </b>" + bufferName + '<br>' +
                "<b>" + _this.translate.instant("CYCLE_EXPLORATION.VALUE") + ": </b>" + _this.filterService.parseGaugeValue(x, 2, 1) +
                " " + layoutBuffer.yaxis.title + '<br>' +
                "<b>" + _this.translate.instant("CYCLE_EXPLORATION.BUFFER_DATA") + ": </b>" + values.xAxis[xIdx] +
                " " + layoutBuffer.xaxis.title + '<br>';
            });

            _this.bufferTraces.push({
              name: bufferName,
              id: variable,
              opacity: !comparison ? 1 : 0.5,
              legendgroup: variable,
              showlegend: !comparison ? true : false,
              mode: bufferConfig.traceType == 'setpoint' ? 'lines' : 'lines+markers',
              type: 'scattergl',
              text: hovertext,
              hoverinfo: "text",
              x: values.xAxis,
              y: values.yAxis,
              line: {
                dash: !comparison ? (bufferConfig.traceType == 'setpoint' ? 'dot' : 'solid') : (bufferConfig.traceType == 'setpoint' ? 'dashdot' : 'dot'),
                color: _this.colors[colorIndex]
              },
            });
          }
        });

      });

      // console.log(this.bufferTraces);

      _this.monitoringData.bufferPlotData = {
        layout: layoutBuffer,
        traces: _this.bufferTraces
      };


    }

    _this.monitoringData.plotData = {
      layout: layout,
      traces: _this.traces
    };

    _this.completeDashboardConfig = {
      dashboardData: _this.clonerService.deepClone(_this.monitoringData),
      machineProfile: _this.machine.profile,
      dashboardConfig: _this.dashboardConfig,
      aggregations: _this.aggregations,
    };

    // if (_this.traces.every((trace: any) => trace.x.length == 0) && layout.shapes.length == 0) {
    //   _this.plotState = 2;
    // } else {
    //   _this.plotState = 1;
    // }

    if (!comparison && _this.isComparedCycle) {
      // Wait for the compared cycle
    } else {
      _this.dispatcherService.getDispatch(_this, skipGetData ? 302 : 300);
    }

  }

  resizePlot() {
    this.internalDataService.setResizePlot(true);
  }

  getDomain(range: any) {
    if (range != null && range > 0) {
      // solo due assi
      if (range < 3) {
        return [0, 1];
        // lunghezza pari
      } else if (range % 2 == 0) {
        return [0.05 * (range - 1) / 2, 1 - (0.05 * (range - 1) / 2)];
        // lunghezza dispari
      } else if (range % 2 != 0) {
        return [0.05 * (range - 1) / 2, 1 - (0.05 * (range - 3) / 2)];
      }
    }
    return [0, 1];
  }

  openVariablesSelectionDialog() {

    const variablesDialog = this.dialog.open(ComponentVariablesSelectionComponent,
      {
        panelClass: 'ff-dialog',
        width: '40%',
        // height: '90%',
        data: {
          title: this.translate.instant("HEALTH_MONITORING.VARIABLES_SELECTION"),
          componentId: this.componentId,
          groups: this.clonerService.deepClone(this.plotsConfigComponents),
          hideComponents: true,
        },
      });

    variablesDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          this.plotsConfigComponents = this.clonerService.deepClone(result.groups);
          this.plotsConfig = this.clonerService.deepClone(result.groups[this.componentId].plotsConfig);

          this.internalDataService.setTracesSelected(this, this.machineId, this.plotsConfig, false, this.processes, false);

          this.pageState.next(8);
          this.getCycleDetail(this, false);

        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  openCycleComparison(type?: any) {

    let title = type == 'comparison' ? 'CYCLE_EXPLORATION.COMPARED_CYCLE_SELECTION' : 'CYCLE_EXPLORATION.ACTUAL_CYCLE_SELECTION';

    const selectionDialog: any = this.appConfig?.newCycleSelectionDialog ? CycleSelectionDialogNewComponent : CycleSelectionDialogComponent;
    const cycleComparisonDialog = this.dialog.open(selectionDialog,
      {
        panelClass: 'ff-dialog',
        width: '90%',
        height: '90%',
        data: {
          title: this.translate.instant(title),
          isEnabledAD: this.isEnabledAD,
          machine: this.machine,
          machineId: this.machineId,
          componentId: this.componentId,
          aggregations: this.aggregations,
          table: this.appConfig,
          tableInfos: this.tableInfos,
          actualCycleInfos: this.cycleInfos.actual,
          comparedCycleInfos: this.cycleInfos.comparison,
          dialogType: type,
          availableMachines: this.availableMachines
        },
      });

    cycleComparisonDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          console.log(result);

          if (type == 'comparison') {
            this.isComparedCycle = true;
            this.isChangedComparedCycle = true;
            this.comparedCycleId = result.cycle.id;
            this.cyclesComparison.selected = result.cycle;

            // this.cycleInfos.comparison = {
            //   cycleId: result.cycle.id != null ? result.cycle.id.toString() : 'unknown',
            //   cycleDay: moment(result.cycle.timeEnd).format("YYYY-MM-DD"),
            //   cycleStart: result.cycle.timeStartP,
            //   cycleEnd: result.cycle.timeEndP,
            //   cycleDuration: moment(result.cycle.timeEnd).diff(result.cycle.timeStart, 's'),
            // };

            this.cacheService.set('comparedCycle', this.cyclesComparison.selected.cycleId);
            this.cacheService.set('comparedDayCycle', this.cyclesComparison.selected.cycleDay);

            this.pageState.next(8);
            this.getCycleDetail(this, false);
          } else {
            this.cycles.selected = result.cycle;
            this.cycles.list = result.list.sort(this.filterService.sortByProperty('timestamp', 'asc', true));
            this.navigateToCycleVariables();
          }

        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  navigateToCycleVariables() {
    this.cacheService.set('cycleDays', {
      list: this.cycleDays.list,
      selected: this.cycleDays.selected
    });

    this.cacheService.set('totalCycles', {
      list: this.cycles.list,
      selected: this.cycles.selected
    });
    this.cacheService.set('selectedCycle', this.cycles.selected);

    this.pageState.next(7);
    this.getCycleDetail(this, false);
  }

  removeComparedCycle() {
    this.isComparedCycle = false;

    this.pageState.next(8);
    this.getCycleDetail(this, true);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // NAVIGATE TO CYCLES

  navigateToPreviousCycle() {
    try {

      // console.log('previous cycle');
      var previousCycle = this.cycles.list[this.cycles.list.findIndex((x: any) => x.cycleId == this.cycles.selected.cycleId) - 1];
      if (this.cyclesComparison.selected != null && previousCycle != null && previousCycle.cycleId == this.cyclesComparison.selected.cycleId) {
        this.cycles.selected = previousCycle;

        this.snackBar.open(this.translate.instant('CYCLE_EXPLORATION.SKIPPED_COMPARED_CYCLE'), '', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 2000
        });

        this.navigateToPreviousCycle();
        return;
      } else {
        if (previousCycle != null) {
          this.cycles.selected = previousCycle;
        }
      }

      this.cacheService.set('totalCycles', {
        list: this.cycles.list,
        selected: this.cycles.selected
      });

      if (previousCycle != null) {

        this.cacheService.set('selectedCycle', this.cycles.selected);

        this.pageState.next(8);
        this.getCycleDetail(this, false);
      } else {
        this.snackBar.open(this.translate.instant('CYCLE_EXPLORATION.FIRST_CYCLE'), '', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 2000
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  navigateToNextCycle() {
    try {

      var nextCycle = this.cycles.list[this.cycles.list.findIndex((x: any) => x.cycleId == this.cycles.selected.cycleId) + 1];

      if (this.cyclesComparison.selected != null && nextCycle != null && nextCycle.cycleId == this.cyclesComparison.selected.cycleId) {
        this.cycles.selected = nextCycle;

        this.snackBar.open(this.translate.instant('CYCLE_EXPLORATION.SKIPPED_COMPARED_CYCLE'), '', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 2000
        });

        this.navigateToNextCycle();
        return;
      } else {
        if (nextCycle != null) {
          this.cycles.selected = nextCycle;
        }
      }

      this.cacheService.set('totalCycles', {
        list: this.cycles.list,
        selected: this.cycles.selected
      });

      if (nextCycle != null) {

        this.cacheService.set('selectedCycle', this.cycles.selected);

        this.pageState.next(8);
        this.getCycleDetail(this, false);
      } else {

        this.snackBar.open(this.translate.instant('CYCLE_EXPLORATION.LAST_CYCLE'), '', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          duration: 2000
        });

      }

    } catch (e) {
      console.log(e);
    }
  };

  compareValues(value: any, valueCompared: any, tolerance: any = 0.01) {
    if (value != null && valueCompared != null) {
      if (Math.abs((valueCompared - value) / value) <= tolerance) return "equal";
      else if (valueCompared > value) return "greater";
      else return "smaller";
    }
    return "equal";
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    let queryParams = this.route.snapshot.queryParams;

    this.backButton = [this.machineId, "health-monitoring"];
    this.internalDataService.setBackButton(this.backButton);

    this.componentId = queryParams.componentId;
    this.machineRefId = queryParams.machineRefId;

    this.route.params.subscribe((params: Params) => {

      this.machineId = params['machineId'];

      try {
        let queryParams = this.route.snapshot.queryParams;
        this.componentId = queryParams.componentId;
        this.machineRefId = queryParams.machineRefId;
      } catch (error) { console.log("Wrong query params") }

      this.internalDataService.setHMComponentSelected({
        machineRefId: this.machineRefId,
        componentId: this.componentId,
      });

    });

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.HMComponentSelectedSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }

    try { this.internalDataService.setBackButton([]) } catch (error) { }
    try {
      this.internalDataService.setZoomedIntervalVariables(null);
      this.zoomedIntervalVariablesSub.unsubscribe();
    } catch (error) { }

    try { this.cacheService.set("cycleDays", null) } catch (error) { }
    try { this.cacheService.set("selectedCycle", null) } catch (error) { }
    try { this.cacheService.set("comparedCycle", null) } catch (error) { }
    try { this.cacheService.set("comparedDayCycle", null) } catch (error) { }
  }

}