import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'barilla-shift-selection-dialog',
  templateUrl: './barilla-shift-selection-dialog.component.html',
  styleUrls: ['./barilla-shift-selection-dialog.component.scss']
})
export class BarillaShiftSelectionDialogComponent implements OnInit, OnDestroy {

  public confirmClass: any;
  public cancelClass: any;
  public confirmAdditionalActionClass: any;
  interval: any;
  defaultIntervalSelected: string = 'last30Days';
  intervalConfig: any;
  defaultIntervalListId: any = 2;
  state: any = 0;
  completeDashboardConfig: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<BarillaShiftSelectionDialogComponent>,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public clonerService: ClonerService,
    public http: ApiService,
  ) { }

  async ngOnInit() {

    this.interval = this.intervalService.getIntervalById(this.defaultIntervalSelected, this.dialog.machine?.timezone);

    this.intervalConfig = {
      list: this.intervalService.getDefaultIntervals(this.defaultIntervalListId, this.dialog.machine?.timezone),
      selected: this.interval
    };

    await this.getShifts();

  }

  async selectInterval(interval: any) {
    this.interval = interval;
    await this.getShifts();

    // this.intervalService.selectInterval(this, interval, this.pollingSubscription, this.mainPollingFunction, this.mainRequest, this.dialog.machine?.timezone, 5, 6)
  };

  async getShifts() {

    this.state = 0;

    let shifts = this.dialog.completePayload?.settings?.statics?.shifts ?? [];

    let completeShifts = []
    for (let m = moment(this.interval.start); m.diff(moment()) < 0; m.add(1, 'days')) {

      let day = m.format("YYYY-MM-DD");

      shifts?.forEach(shift => {

        let shiftStart = `${day}T${shift?.from}.000`;
        let shiftEnd = `${day}T${shift?.to}.000`;

        if (moment(shiftStart).diff(shiftEnd) > 0) {
          shiftEnd = moment(shiftEnd).add(1, "d").format("YYYY-MM-DDTHH:mm:ss.SSS")
        }

        if (moment(shiftStart).diff(moment()) < 0) {
          completeShifts.push({
            id: `${day}_${shift?.id}`,
            label: `${shift?.id} - ${this.translate.instant("INTERVAL.DAY")}: ${day}`,
            day: day,
            shiftId: `${shift.id}`,
            shift: `${shift.from}-${shift.to}`,
            shiftStart: shiftStart,
            shiftEnd: shiftEnd,
          })
        }

      })

    }
    // console.log({ completeShifts });

    await setTimeout(() => {

      this.completeDashboardConfig = {
        dashboardData: this.clonerService.deepClone({ completeShifts: completeShifts }),
        machineProfile: this.dialog?.profile,
        dashboardConfig: { widgets: this.dialog?.widgets }
      };

      this.state = 1
    }, 250);

  }

  tableAction(item) {

    console.log({ item });

    let buttonInfos: any = this.clonerService.deepClone(item?.buttonInfos ?? {})
    let row: any = this.clonerService.deepClone(item?.row ?? {})
    let tableInfos: any = this.clonerService.deepClone(item?.tableInfos ?? [])

    if (buttonInfos?.clickFunction == 'selectShift') {

      this.dialogRef.close(row)

    }

  }

  ngOnDestroy() { }

}
