<div mat-dialog-title fxLayout="row" class="w100">

  <span fxLayout="column" fxLayoutAlign="center center">{{ dialog.title | translate }}</span>

  <span fxFlex></span>

  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>

</div>
<mat-dialog-content class="mat-typography cmms-new-component">

  <!-- <pre>{{ state | json }}</pre> -->

  <ng-container *ngIf="state == 0">
    <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
      <ff-loading-card></ff-loading-card>
    </div>
  </ng-container>

  <ng-container *ngIf="state == 1">

    <!-- GROUPS -->

    <div style="margin-bottom: 16px;">
      <span class="headline">{{ 'MAINTENANCE.GROUP' | translate }}</span>

      <div *ngIf="!newComponent.isNewGroup" fxLayout="row" fxLayoutAlign="start center" class="w100"
        style="margin-top: 8px;">

        <div style="margin-right: 8px;" class="ff-input-select" fxLayout="row" fxLayoutAlign="start center">

          <mat-select [(value)]="newComponent.groupId" (selectionChange)="selectGroup(newComponent.groupId)">
            <mat-option *ngFor="let group of groups" [value]="group.id">
              {{ group.group | translate }}
            </mat-option>
          </mat-select>

        </div>

        <button *ngIf="!dialog?.buttonInfos?.blockAddButtons" class="filter-button no-label" (click)="addNewGroup()"
          fxLayout="row" fxLayoutAlign="center center">
          <ff-icon [icon]="'add'"></ff-icon>
        </button>

      </div>

      <div *ngIf="newComponent.isNewGroup" fxLayout="row" fxLayoutAlign="start center" style="margin-top: 12px;"
        class="w100">

        <div class="input-box" style="width: 90%;">
          <input matInput class="ff-input text w100" type="text" [(ngModel)]="newComponent.newGroupLabel">
        </div>

        <button *ngIf="!dialog?.buttonInfos?.blockAddButtons" style="margin-left: 8px !important;"
          class="filter-button no-label md-red" (click)="removeNewGroup()" fxLayout="row" fxLayoutAlign="center center">
          <ff-icon [icon]="'delete'"></ff-icon>
        </button>

      </div>

    </div>

    <!-- SUBGROUPS -->

    <div style="margin-bottom: 16px;" *ngIf="newComponent?.groupId != null || newComponent?.newGroupLabel != null">

      <span class="headline">{{ 'MAINTENANCE.SUBGROUP' | translate }}</span>

      <div *ngIf="!newComponent.isNewGroup && !newComponent.isNewSubgroup" fxLayout="row" fxLayoutAlign="start center"
        class="w100" style="margin-top: 8px;">

        <div style="margin-right: 8px;" class="ff-input-select" fxLayout="row" fxLayoutAlign="start center">

          <mat-select [(value)]="newComponent.subgroupId">
            <mat-option *ngFor="let subgroup of subgroups" [value]="subgroup.subgroupId">
              {{ subgroup.label | translate }}
            </mat-option>
          </mat-select>

        </div>

        <button *ngIf="!dialog?.buttonInfos?.blockAddButtons" class="filter-button no-label" (click)="addNewSubgroup()"
          fxLayout="row" fxLayoutAlign="center center">
          <ff-icon [icon]="'add'"></ff-icon>
        </button>

      </div>

      <!-- <div *ngIf="newComponent.isNewGroup || newComponent.isNewSubgroup" fxLayout="row" fxLayoutAlign="start center"
        style="margin-top: 12px;" class="w100">

        <div class="input-box w100">
          <input matInput class="ff-input text w100" type="text" [(ngModel)]="newComponent.newSubgroupLabel">
        </div>
      </div> -->

      <div *ngIf="newComponent.isNewGroup || newComponent.isNewSubgroup" fxLayout="row" fxLayoutAlign="start center"
        style="margin-top: 12px;" class="w100">

        <div class="input-box" style="width: 90%;">
          <input matInput class="ff-input text w100" type="text" [(ngModel)]="newComponent.newSubgroupLabel">
        </div>

        <button *ngIf="newComponent.isNewSubgroup && !dialog?.buttonInfos?.blockAddButtons"
          style="margin-left: 8px !important;" class="filter-button no-label md-red" (click)="removeNewSubgroup()"
          fxLayout="row" fxLayoutAlign="center center">
          <ff-icon [icon]="'delete'"></ff-icon>
        </button>

      </div>

    </div>

    <div
      *ngIf="(newComponent?.groupId != null || newComponent?.newGroupLabel != null) && (newComponent?.subgroupId != null || newComponent?.newSubgroupLabel != null)"
      class="w100 h100 new-component-container" fxLayout="column" fxLayoutAlign="start stretch">

      <span class="headline title-divider">
        {{ (dialog.newComponentLabel ?? 'MAINTENANCE.NEW_COMPONENT') | translate }}
      </span>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!dialog?.buttonInfos?.attachmentsConfig?.hide">

        <div fxFlex="50" fxLayout="row" class="attachment-button clickable" (click)="fileInput.click()">
          <div class="tip"><i class="fas fa-upload"></i></div>
          <div class="label">
            {{ (dialog?.buttonInfos?.attachmentsConfig?.label ?? 'MAINTENANCE.ATTACH_IMAGE') | translate }}
          </div>
        </div>

        <input hidden (change)="onFileSelected()" #fileInput type="file" id="file" [(ngModel)]="fileCopy"
          [multiple]="dialog?.buttonInfos?.attachmentsConfig?.multiple"
          [accept]="dialog?.buttonInfos?.attachmentsConfig?.accept != null ? dialog?.buttonInfos?.attachmentsConfig?.accept : '.jpg, .png'">

        <div *ngIf="fileInfos != null">
          <div *ngFor="let info of fileInfos" style="margin-bottom: 4px;">
            <div fxLayout="row" fxLayoutAlign="start center">
              <span style="font-weight: 600;">{{ 'MAINTENANCE.FILE_NAME' | translate }}</span>:&nbsp;<span>{{ info.name
                }}</span>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <span style="font-weight: 600;">{{ 'MAINTENANCE.FILE_SIZE' | translate }}</span>:&nbsp;<span>{{ info.sizeP
                }}</span>
            </div>
          </div>
        </div>

      </div>

      <div *ngFor="let info of dialog?.tableInfos | filterByProperty:['editableInDialog', '==', true]">

        <div fxLayout="row" fxLayoutAlign="center center" style="margin: 8px 0;"
          *ngIf="dialog.row != null && checkShowCondition(info, dialog?.row)">

          <span fxFlex="{{ dialog?.labelFlex ? dialog.labelFlex : 50}}" style="font-weight: 800;">
            <span>{{ (info?.label != null ? info.label : '-') | translate }}</span>
            <span *ngIf="(info.unit || info.unitKey) && info.unitInTitle">&nbsp;[{{ getUnit(info) }}]</span>
          </span>

          <div fxFlex="{{ dialog?.labelFlex ? (100 - dialog.labelFlex) : 50}}">
            <!-- TEMPLATE InfoValue -->
            <ng-container [ngTemplateOutlet]="InfoValue" [ngTemplateOutletContext]="{ info:info, item:newComponent }">
            </ng-container>
          </div>
        </div>
      </div>

    </div>

    <!-- <pre>{{ newComponent | json }}</pre> -->

  </ng-container>

</mat-dialog-content>

<mat-dialog-actions *ngIf="state == 1" fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">

  <button style="margin-left: 8px;" class="filter-button" (click)="addComponent()" fxLayout="row"
    [disabled]="checkDisability()" fxLayoutAlign="center center">
    <ff-icon [icon]="'save'"></ff-icon>
    <span>{{ (dialog?.buttonInfos?.labels?.add != null ? dialog?.buttonInfos?.labels?.add :
      'MAINTENANCE_STATE.ADD_COMPONENT') | translate }}</span>
  </button>

</mat-dialog-actions>




<!-- TEMPLATE InfoValue -->
<ng-template #InfoValue let-info="info" let-item="item">

  <div class="input-box" *ngIf="info.dialogEditType == 'inputText'"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <input matInput class="ff-input text w100" type="text" [matAutocomplete]="auto" [disabled]="info.disabledInDialog"
      [(ngModel)]="item[info.variable]">
    <mat-autocomplete #auto="matAutocomplete">
      <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let option of info.autocomplete; let i = index"
        [attr.data-index]="i">
        <mat-option style="padding-right: 0px" fxFlex [value]="option">
          <div>{{ option | translate }}</div>
        </mat-option>
      </div>
    </mat-autocomplete>
  </div>

  <div class="input-box" *ngIf="info.dialogEditType == 'inputTextDisabled'"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <input matInput class="ff-input text w100" type="text" [matAutocomplete]="auto" disabled
      [(ngModel)]="item[info.variable]">
  </div>

  <div *ngIf="info.dialogEditType == 'checkbox'">
    <input type="checkbox" style="transform: scale(1.2)" [(ngModel)]="item[info.variable]" />
  </div>

  <div *ngIf="info.dialogEditType == 'inputNum'" class="input-box"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <input matInput class="ff-input number w100" type="number" [min]="0" [(ngModel)]="item[info.variable]"
      [disabled]="info.disabledInDialog">
  </div>

  <div *ngIf="info.dialogEditType == 'closedList'" class="ff-input-select w100" fxLayout="row"
    fxLayoutAlign="start center">
    <mat-select [(value)]="item[info.variable]" [disabled]="info.disabledInDialog || info.listOptions?.length == 0">
      <mat-option *ngIf="info.addNullValue" [value]="null">---</mat-option>
      <ng-container *ngIf="info.closedListType == null">
        <mat-option *ngFor="let opt of info.listOptions" [value]="opt">
          {{ opt }}
        </mat-option>
      </ng-container>
      <ng-container *ngIf="info.closedListType == 'arrayOfObjects'">
        <mat-option *ngFor="let opt of info.listOptions" [value]="opt.id" fxLayout="row" fxLayoutAlign="start center"
          (click)="onSelectFromClosedList(info, opt)">
          <span fxLayout="row" fxLayoutAlign="start center">
            {{ filterService.translateElementNewFormat(opt) }}
            <ff-icon *ngIf="opt.class != null" [icon]="{'icon': 'circle', 'type': 'svg'}" [iconClass]="opt.class"
              style="margin-left: 8px;">
            </ff-icon>
          </span>
        </mat-option>
      </ng-container>
    </mat-select>
  </div>

  <div *ngIf="info.dialogEditType == 'inputDateTime'">
    <input matInput class="ff-input datetime w100" type="datetime-local" [max]="nowTime"
      [(ngModel)]="item[info.variable]">
  </div>

  <div *ngIf="info.dialogEditType == 'inputDate'" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center" style="margin-right: 8px;">

    <div class="w100" fxLayout="row" fxLayoutAlign="start center">
      <!-- <mat-icon class="icon">event_available</mat-icon> -->

      <mat-form-field appearance="fill" class="w100">
        <!-- <mat-label>{{ 'CONSUMABLES_SETTINGS.TRAINING_END' | translate }}</mat-label> -->
        <input matInput [matDatepicker]="pickerEnd" [value]="item[info.variable]" disabled
          (dateChange)="onDaySelection($event, info.variable)">
        <mat-datepicker-toggle matSuffix [for]="pickerEnd">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #pickerEnd disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <textarea *ngIf="info.dialogEditType == 'textarea'" rows="1" style="max-height: 160px; min-height: 160px;"
    maxlength="250" class="ff-textarea" [(ngModel)]="item[info.variable]"
    oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' name="textarea" id="textarea">
      </textarea>
  <span *ngIf="info.dialogEditType == 'textarea'">{{item[info.variable] != null ? item[info.variable]?.length : 0}} /
    250</span>

  <div *ngIf="info.dialogEditType == 'inputDuration'">
    <ff-duration-input format="HH:mm" unit="s" [(value)]="item[info.variable]"></ff-duration-input>
  </div>

  <div *ngIf="info.requiredInDialog && (item[info.variable] == null || item[info.variable] == '')" class="required">
    <span>{{ 'GLOBAL.REQUIRED' | translate }}</span>
  </div>

</ng-template>