import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'app-zoomable-image-dialog',
  templateUrl: './zoomable-image-dialog.component.html',
  styleUrls: ['./zoomable-image-dialog.component.scss']
})
export class ZoomableImageDialogComponent implements OnInit {

  id: any = null;
  noImage: any = false;
  imageWidth: any = 600;
  imageHeight: any = 300;
  state: any;

  appConfig: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<ZoomableImageDialogComponent>,
    public appConfigService: AppConfigService,
    public cacheService: CacheService,
    public apiService: ApiService,
    public filterService: FiltersService,
  ) { }

  async ngOnInit() {

    this.id = this.filterService.generateRandomString();
    this.appConfig = this.appConfigService.getAppConfig;

    this.imageWidth = this.dialog?.profile?.ingenya?.dimensions?.width ?? this.appConfig?.ingenya?.imagesLabelling?.dimensions?.width ?? this.imageWidth;
    this.imageHeight = this.dialog?.profile?.ingenya?.dimensions?.height ?? this.appConfig?.ingenya?.imagesLabelling?.dimensions?.height ?? this.imageHeight;

    if (this.dialog.fileFromBE) await this.getImage();

    try { setTimeout(() => imageZoom(this.id, "myresult"), 100) }
    catch (error) { console.log(error) }

  }

  async getImage() {

    try {

      let url = `/apif/get-iot-file/${this.cacheService.get("machineId")}`;

      if (this.dialog.config?.endpointUrl) url = this.dialog.config.endpointUrl.replaceAll("{machineId}", this.dialog.machineId);

      let query = {}

      let payload: any = {
        filepath: this.dialog.imageUrl
      };

      let resp: any = null;

      try { resp = await this.apiService.asyncDownload(url, payload, query, "arraybuffer") }
      catch (error) {
        this.noImage = true;
        console.log(error);
        return;
      }

      let imageStr = this.filterService.arrayBufferToBase64(resp.body);

      if (imageStr == "") {
        this.noImage = true;
        return;
      }

      try {
        this.dialog.imageUrl = this.filterService.sanitize('data:image/png;base64, ' + imageStr)
      } catch (error) {
        console.log(error);
        this.noImage = true;
        this.state = 2;
      }

      this.state = 1;

    } catch (error) {
      console.log(error);
      this.state = 2;
    }

  }

}



function imageZoom(imgID, resultID) {
  var img, lens, result, cx, cy;
  img = document.getElementById(imgID);
  result = document.getElementById(resultID);
  /*create lens:*/
  lens = document.createElement("DIV");
  lens.setAttribute("class", "img-zoom-lens");
  /*insert lens:*/
  img.parentElement.insertBefore(lens, img);
  /*calculate the ratio between result DIV and lens:*/
  cx = result.offsetWidth / lens.offsetWidth;
  cy = result.offsetHeight / lens.offsetHeight;
  /*set background properties for the result DIV:*/
  result.style.backgroundImage = "url('" + img.src + "')";
  result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
  /*execute a function when someone moves the cursor over the image, or the lens:*/
  lens.addEventListener("mousemove", moveLens);
  img.addEventListener("mousemove", moveLens);
  /*and also for touch screens:*/
  lens.addEventListener("touchmove", moveLens);
  img.addEventListener("touchmove", moveLens);
  function moveLens(e) {
    var pos, x, y;
    /*prevent any other actions that may occur when moving over the image:*/
    e.preventDefault();
    /*get the cursor's x and y positions:*/
    pos = getCursorPos(e);
    /*calculate the position of the lens:*/
    x = pos.x - (lens.offsetWidth / 2);
    y = pos.y - (lens.offsetHeight / 2);
    /*prevent the lens from being positioned outside the image:*/
    if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
    if (x < 0) { x = 0; }
    if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
    if (y < 0) { y = 0; }
    /*set the position of the lens:*/
    lens.style.left = x + "px";
    lens.style.top = y + "px";
    /*display what the lens "sees":*/
    result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
  }
  function getCursorPos(e) {
    var a, x = 0, y = 0;
    e = e || window.event;
    /*get the x and y positions of the image:*/
    a = img.getBoundingClientRect();
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x: x, y: y };
  }
}