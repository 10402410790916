<div class="backdrop" (click)="onClose()"></div>
<div class="alert-box">
  <h2>Riepilogo</h2>
  <p><strong>{{ 'PROD_SHEET.DATE' | translate }}:</strong> {{ date }}</p>
  <p><strong>{{ 'PROD_SHEET.TRAFILA' | translate }}:</strong> {{ trafila }}</p>
  <p><strong>{{ 'PROD_SHEET.SHIFT' | translate }}:</strong> {{ shift }}</p>
  <p *ngIf="lotChangeTime"><strong>{{ 'PROD_SHEET.LOT_CHANGE_TIME' | translate }}:</strong> {{ lotChangeTime }}</p>
  <p><strong>{{ 'PROD_SHEET.PRODUCT_TYPE' | translate }}:</strong> {{ product }}</p>
  <p><strong>{{ 'PROD_SHEET.LOT' | translate }}:</strong> {{ lot }} 
    <span *ngIf="!lot" style="color: red;"><strong>{{ 'PROD_SHEET.LOT_CODE' | translate | uppercase }}</strong></span>
</p>
  <p><strong>{{ 'PROD_SHEET.BIG_BAGS_AMOUNT' | translate }}:</strong> {{ bigBag }}</p>
  <p><strong>{{ 'PROD_SHEET.TIME' | translate }}:</strong> {{ dayTime }}</p>
  <p><strong>{{ 'PROD_SHEET.OUTCOME' | translate }}:</strong> {{ outcome }}</p>
  <div class="alert-box-actions pull-right" fxLayout="row" fxLayout="start center">
    <span fxFlex></span>
    <button style="margin-right: 10px" class="custom-btn custom-btn-danger" type="button"
      (click)="onClose()">{{'PROD_SHEET.CLOSE' | translate}}</button>
    <button class="custom-btn custom-btn-primary" type="button" (click)="onConfirm()">{{'PROD_SHEET.CONFIRM' | translate}}</button>
  </div>
</div>
