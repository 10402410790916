import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'cycle-selection-dialog-new',
  templateUrl: './cycle-selection-dialog-new.component.html',
  styleUrls: ['./cycle-selection-dialog-new.component.scss']
})
export class CycleSelectionDialogNewComponent implements OnInit, OnDestroy {

  public appInfo: any;
  public appConfig: any;

  public machine: any;
  public machineId: any;
  public machineProfile: any;

  public loadingData: any;

  public cycles: any;
  public cyclesData: any;
  public cyclesInfo: any;
  public cyclesColumns: any = [];
  public cycleStart: any;
  public cycleOrder: any;
  public searchCycles: any;

  public dashboardData: any;
  public dashboardConfig: any;
  public completeDashboardConfig: any;
  public dashboardName: any = "cycle-selection-dialog";

  public intervalAggrList: any;

  public showResetInterval: any;
  public interval: any;
  public intervalConfig: any;
  public defaultIntervalId: any = 'today';
  public intervalListId: any = 2;
  public customIntervalArray: any = null;

  public customInterval: any;

  public aggregatedTable: any = false;
  public maxTableLengthBeforeAutomaticAggregation: any = 1000;
  public numberOfRowsAfterAutomaticAggregation: any = 50;

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 3;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.getCycleList, nextState: 2, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 3 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    public dialog: MatDialog,
    public appConfigService: AppConfigService,
    public dialogRef: MatDialogRef<CycleSelectionDialogNewComponent>,
    public apiService: ApiService,
    public cacheService: CacheService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public clonerService: ClonerService,
    public internalDataService: InternalDataService,
    public dispatcherService: DispatcherService,
  ) {

    this.cycles = {
      list: [],
      filtered: [],
      selected: null,
      pageOptions: [10, 25, 50, 100],
      pageSize: 10,
      search: ""
    };

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machine = this.dialogData.machine;
    this.machineProfile = this.machine?.profile;
    this.machineId = this.dialogData.machineId;

    if (this.dialogData.customInterval != null) this.customInterval = this.dialogData.customInterval;

    this.cyclesInfo = this.clonerService.deepClone(this.dialogData.tableInfos);

    if (this.dialogData.defaultIntervalId) this.defaultIntervalId = this.dialogData.defaultIntervalId;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  async getDashboard() {
    try {

      let url = '/apif/get-custom-file';
      let dashboardName = 'dashboard-' + this.dashboardName + '.json';

      let query: any = {
        machineId: this.machineId,
        fileName: dashboardName,
        fromWeb: this.appInfo?.sources40F != null ? 1 : 0,
        source: this.appInfo?.sources40F ?? '/assets/config/',
      };

      try {
        let resp = await this.apiService.sendGetRequestAwait(url, query);
        this.dashboardConfig = this.clonerService.deepClone(resp?.body ?? {});
      } catch (error) {
        console.log(`Dashboard ${dashboardName} not present`);
        this.dashboardConfig = {};
      }

    } catch (error) {
      console.log(error);
    }
  }

  getCurrentInterval() {

    if (this.dialogData.customInterval && !this.showResetInterval) return this.clonerService.deepClone(this.dialogData.customInterval);

    let interval = null;

    if (this.cacheService.get("intervalCycleSelection") == null) {

      interval = this.intervalService.getIntervalById(this.defaultIntervalId, this.machine.timezone);

      this.intervalConfig = {
        list: this.intervalService.getDefaultIntervals(this.intervalListId, this.machine.timezone, this.customIntervalArray),
        selected: interval
      };

      this.cacheService.set("intervalCycleSelection", this.intervalConfig);

    } else {
      this.intervalConfig = this.cacheService.get("intervalCycleSelection");
      interval = this.cacheService.get("intervalCycleSelection").selected;
    }

    return interval;

  }

  async getCycleList(_this) {

    _this.interval = _this.getCurrentInterval();

    try {

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone,
        anomalyDetection: false,
        tags: ""
      };

      query = Object.assign(query, {
        anomalyDetection: _this.dialogData.isEnabledAD,
        tags: _this.dialogData.aggregations != null ? _this.dialogData.aggregations.map((x: any) => x.id).join(',') : "",
        tz: _this.machine.timezone,
      });

      let machineReference = null;
      if (_this.dialogData.availableMachines != null && _this.dialogData.availableMachines.selected != null) {
        machineReference = _this.dialogData.availableMachines.selected === 'Line' ? _this.machine.machineReference : _this.dialogData.availableMachines.selected;
      } else if (_this.machine.machineReference) {
        machineReference = _this.machine.machineReference;
      }

      if (machineReference != null) {
        query.machineId = machineReference;
        try {
          query.processes = _this.machine.machines[machineReference].processes.join(";");
          //query.tools = _this.machine.machines[machineReference].tools.join(";");
        } catch (error) { }
      }

      let url = '/apif/condition-monitoring/cycles-detail/' + _this.dialogData.machineId + '/' + _this.dialogData.componentId;

      let payload: any = {};

      if (_this.machine.profile.additionalCycleVariables?.length > 0) payload.additionalCycleVariables = _this.clonerService.deepClone(_this.machine.profile.additionalCycleVariables);

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {
            _this.dispatcherService.getDispatch(_this, 301, error);
            _this.createDashboard([]);

            if (error.error instanceof ErrorEvent) console.log(`Error: ${error.error.message}`);
            else console.log(`Error: ${error.message}`);

            return [];
          }))
        .subscribe(
          (data: any) => {

            _this.dispatcherService.getDispatch(_this, 300);

            _this.createDashboard(data?.body ?? []);

          },
        );

    } catch (error) {
      console.log(error);
    }

  }

  createDashboard(data) {

    this.dashboardData = {
      table: this.clonerService.deepClone(this.parseTable(data)),
      totCycles: data.length,
      avgDuration: this.filterService.average(data, 'cycleTime'),
      totDuration: this.filterService.sum(data, 'cycleTime'),
      cyclesHour: data.length / this.filterService.sum(data, 'cycleTime') * 3600,
    };

    this.completeDashboardConfig = {
      aggregatedTable: this.aggregatedTable,
      dashboardData: this.clonerService.deepClone(this.dashboardData),
      machineProfile: this.machine.profile,
      dashboardConfig: this.dashboardConfig,
      aggregations: this.dialogData.aggregations,
      additionalParams: { isEnabledAD: this.dialogData.isEnabledAD }
    }
  }

  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, null, this.getCycleList, this.getCycleList, this.machine.timezone, 2, 3) };

  async onButtonClick(button) {
    if (button != null && button.buttonInfos != null && button.buttonInfos.clickFunction != null) {
      switch (button.buttonInfos.clickFunction) {
        case "cycleDetail":

          // Aggregated table
          if (!this.aggregatedTable) {
            try {

              let a = moment();
              let b = moment().subtract(365, 'days');

              let daysList: any = [];
              for (let m = moment(a); b.diff(m, 'days') < 0; m.subtract(1, 'days')) {
                daysList.push(moment(m).format("YYYY-MM-DD"));
              }

              this.cacheService.set('cycleDays', {
                list: daysList,
                selected: button.row.day
              });

              this.cacheService.set('totalCycles', {
                list: this.clonerService.deepClone(this.dashboardData.table),
                selected: button.row
              });

              this.cacheService.set('selectedCycle', button.row);

              this.dialogRef.close({ cycle: button.row, list: this.clonerService.deepClone(this.dashboardData.table) });
            } catch (error) { console.log(error) }
          }

          // NOT aggregated table
          else {

            let range: any = {
              from: button?.row?.timeStart,
              to: button?.row?.timeEnd,
            };

            if (range != null && range.from != null && range.to != null) {

              this.showResetInterval = true;

              this.interval.id = 'custom';
              this.interval.startDate = moment.tz(range.from, this.machine.timezone).toDate(); //.add(100, 'ms').toDate();
              this.interval.endDate = moment.tz(range.to, this.machine.timezone).toDate(); //.add(100, 'ms').toDate();

              this.interval.start_shortDate = moment.tz(range.from, this.machine.timezone).format('MMM DD, YYYY - HH:mm:ss')
              this.interval.end_shortDate = moment.tz(range.to, this.machine.timezone).format('MMM DD, YYYY - HH:mm:ss')
              this.interval.startF = this.interval.start_shortDate + ' - ' + this.interval.end_shortDate;

              this.interval.start = JSON.parse(JSON.stringify(this.interval.startDate));
              this.interval.end = JSON.parse(JSON.stringify(this.interval.endDate));

              this.intervalConfig = {
                list: this.intervalService.getDefaultIntervals(2, this.machine.timezone),
                selected: this.interval
              };

              this.cacheService.set("intervalCycleSelection", this.intervalConfig);

              this.pageState.next(2);
              this.getCycleList(this);

            }
          }

          break;
        default:
          console.log(button);
          break;
      }
    }
  }


  resetDefaultInterval() {

    this.showResetInterval = false;
    this.cacheService.set("intervalCycleSelection", null);

    this.pageState.next(2);
    this.getCycleList(this);

  };

  parseTable(data: any) {

    let newData: any = this.clonerService.deepClone(data ?? []);

    let selectedKey = this.dialogData.dialogType == 'comparison' ? 'unselectable' : 'selected';
    let comparedKey = this.dialogData.dialogType == 'comparison' ? 'selected' : 'unselectable';

    if (this.dialogData.comparedCycleInfos != null && this.dialogData.comparedCycleInfos.cycleId != null) {
      let comparedCycleIdx = newData.findIndex((cycle: any) => cycle.cycleId == this.dialogData.comparedCycleInfos.cycleId);
      if (comparedCycleIdx != -1) newData[comparedCycleIdx][comparedKey] = true;
    }

    if (this.dialogData.actualCycleInfos != null && this.dialogData.actualCycleInfos.cycleId != null) {
      let selectedCycleIdx = newData.findIndex((cycle: any) => cycle.cycleId == this.dialogData.actualCycleInfos.cycleId);
      if (selectedCycleIdx != -1) newData[selectedCycleIdx][selectedKey] = true;
    }

    newData?.forEach((cycle: any) => {

      cycle.day = cycle.timestamp != null ? moment(cycle.timestamp).format("YYYY-MM-DD") : null;
      cycle.timeStart = cycle.timeStart ?? moment(cycle.timestamp).subtract(cycle.cycleTime, 's');
      cycle.cycleTimeP = this.filterService.parseTime(cycle.cycleTime, 's', "HH:mm:ss");
      cycle.timeStartP = this.filterService.parseMoment(cycle.timeStart, "HH:mm:ss");
      cycle.timeEndP = this.filterService.parseMoment(cycle.timestamp, "HH:mm:ss");

    });

    this.aggregatedTable = false;

    this.maxTableLengthBeforeAutomaticAggregation =
      this.dashboardConfig.maxTableLengthBeforeAutomaticAggregation ??
      this.machine.profile?.cycleTraceability?.maxTableLengthBeforeAutomaticAggregation ??
      this.appConfig.machineRecorder?.maxTableLengthBeforeAutomaticAggregation ??
      this.maxTableLengthBeforeAutomaticAggregation;

    this.maxTableLengthBeforeAutomaticAggregation = this.maxTableLengthBeforeAutomaticAggregation > 40000 ? 40000 : this.maxTableLengthBeforeAutomaticAggregation;

    this.numberOfRowsAfterAutomaticAggregation =
      this.dashboardConfig.numberOfRowsAfterAutomaticAggregation ??
      this.machine.profile?.cycleTraceability?.numberOfRowsAfterAutomaticAggregation ??
      this.appConfig.machineRecorder?.numberOfRowsAfterAutomaticAggregation ??
      this.numberOfRowsAfterAutomaticAggregation;

    this.numberOfRowsAfterAutomaticAggregation = this.numberOfRowsAfterAutomaticAggregation > 200 ? 200 : this.numberOfRowsAfterAutomaticAggregation;

    if (newData?.length > this.maxTableLengthBeforeAutomaticAggregation) {

      this.aggregatedTable = true;

      let bucketNumber = 1;
      let aggrData = newData.sort(this.filterService.sortByProperty('timestamp', 'asc', true)).reduce((acc, val, index) => {

        let aggrObj = {
          timeStart: val?.timeStart,
          timeEnd: val?.timestamp,
          cycles: 1,
          duration: [val.cycleTime],
        };

        if (index < newData?.length / this.numberOfRowsAfterAutomaticAggregation * bucketNumber) {
          try {
            acc[bucketNumber].timeEnd = aggrObj?.timeEnd;
            acc[bucketNumber].cycles++;
            acc[bucketNumber].duration.push(val.cycleTime);
          } catch (error) {
            acc[bucketNumber] = aggrObj;
          }
        } else {
          bucketNumber++;
          acc[bucketNumber] = aggrObj;
        }

        return acc;

      }, {});

      aggrData = (Object.entries(aggrData) as any)?.reduce((acc, val) => {

        let aggrVal = val?.[1];
        if (aggrVal?.duration?.length > 0) aggrVal.avgDuration = this.filterService.average(aggrVal.duration);
        if (aggrVal?.health?.length > 0) aggrVal.avgHealth = this.filterService.average(aggrVal.health);
        acc.push(aggrVal);
        return acc;
      }, []);

      return aggrData;

    }

    return newData;
  }

  async ngOnInit() {
    await this.getDashboard();
    this.dispatcherService.getDispatch(this, 300);
  }

  ngOnDestroy() {
    // this.cacheService.set("intervalCycleSelection", null);
  }

}
