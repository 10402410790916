import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { AppConfigService } from 'src/app/services/app-config.service';

@Component({
  selector: 'homepage-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class HomepageGridComponent implements OnInit, OnChanges, OnDestroy {

  public appConfig: any;
  public flexWidth: any;
  public cardGap: any;

  public homepageDataSub: any;
  public mFilters: any;

  public machinesListSub: Subscription;
  public machines: any;

  constructor(
    private internalDataService: InternalDataService,
    public appConfigService: AppConfigService
  ) {

    this.appConfig = this.appConfigService.getAppConfig;
    this.flexWidth = this.appConfig.homepage.gridCards.flexWidth;
    this.cardGap = this.appConfig.homepage.gridCards.cardGap != null ? this.appConfig.homepage.gridCards.cardGap : '8px';

    this.homepageDataSub = this.internalDataService.homepageData.subscribe(value => {
      this.mFilters = value.filters;
    });

    this.machinesListSub = this.internalDataService.machinesList.subscribe(value => {
      // console.log('machinesListSub', { value });

      this.machines = value;
    });

  }

  trackById = (index: any, machine) => {
    return machine.machineId;
  };

  ngOnInit(): void { }

  ngOnChanges(): void { }

  ngOnDestroy(): void {
    try {
      this.homepageDataSub.unsubscribe();
    } catch (error) { }
    try {
      this.machinesListSub.unsubscribe();
    } catch (error) { }
  }

}
