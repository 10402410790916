<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div [ngClass]="{'hidePaginator': pageState.value >= 0 && pageState.value < pageStateReady }" fxLayout="column"
  fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">
    <ff-tabs [tabs]="tabs"></ff-tabs>
  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 }">

    <!-- toolbar -->
    <div class="ff-toolbar py-3" [ngClass]="{ 'pt-0': tabs.length <= 1 }" fxLayout="column" fxLayoutAlign="center start"
      fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <button mat-button class="filter-button" *ngFor="let item of filterButtons" (click)="openFilterDialog(item)"
        [disabled]="item.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
        <mat-icon class="icon">filter_alt</mat-icon>
        <span>{{ item.label | translate }}</span>
      </button>

      <span fxFlex></span>

      <!-- search -->
      <div class="search-input me-3" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="icon">search</mat-icon>
        <input type="text" [(ngModel)]="searchCycles" (keyup)="filterCycles()"
          placeholder="{{'GLOBAL.SEARCH'|translate}}">
      </div>

      <!-- intervals -->
      <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

    </div>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < 5" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 6 && cycles.filtered.length > 0"
      style="border-radius: 25px; background-color: #fff; margin-bottom: 12px;" class="w100">
      <div class="phase-data-title">
        <span>{{ 'CYCLES.STATISTICAL_DATA' | translate }}</span>
      </div>
      <div class="w100 ff-widget-container" fxLayout="row wrap" fxLayoutAlign="start stretch"
        fxLayoutGap="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
        <ff-widget *ngFor="let w of dashboardConfig.widgets"
          [fxFlex]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
          [widget]="{ type: w.type, subtype: w.subtype, data: monitoringData, machineProfile: machine.profile, config: w.config, title: w.title }">
        </ff-widget>
      </div>
    </div>

    <!-- gca evaluation toolbar -->
    <div *ngIf="cyclesSelected != null && cyclesSelected.length > 0 && isAllowedUser" class="ff-toolbar pt-1 pb-3"
      fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <button mat-button class="filter-button md-red-i" (click)="openGCADialog('train', 'apocalypse')" fxLayout="row"
        fxLayoutAlign="space-evenly center">
        <mat-icon class="icon">delete_forever</mat-icon>
        <span>{{ 'CYCLES.CLEAN_TRAINING_SET' | translate }}</span>
      </button>

      <span fxFlex></span>

      <button *ngIf="trainingAction == 'remove'" mat-button class="filter-button md-red-i"
        (click)="openGCADialog('train', 'remove')" fxLayout="row" fxLayoutAlign="space-evenly center">
        <mat-icon class="icon">delete</mat-icon>
        <span>{{ 'CYCLES.REMOVE_FROM_TRAIN_SET' | translate }}</span>
        <span>&nbsp;({{ cyclesSelected.length }})</span>
      </button>

      <button *ngIf="trainingAction == 'add'" mat-button class="filter-button" (click)="openGCADialog('train', 'add')"
        fxLayout="row" fxLayoutAlign="space-evenly center">
        <mat-icon class="icon" svgIcon="health"></mat-icon>
        <span>{{ 'CYCLES.TRAIN' | translate }}</span>
        <span>&nbsp;({{ cyclesSelected.length }})</span>
      </button>

      <button mat-button class="filter-button" (click)="openGCADialog('evaluate')" fxLayout="row"
        fxLayoutAlign="space-evenly center">
        <mat-icon class="icon">edit</mat-icon>
        <span>{{ 'CYCLES.RE_EVALUATE' | translate }}</span>
        <span>&nbsp;({{ cyclesSelected.length }})</span>
      </button>

    </div>

    <!-- <mat-card>
      <div>{{ trainingAction }}</div>
    </mat-card> -->
    <!-- table -->
    <mat-card fxLayout="column" class="cycle-traceability"
      [ngClass]="{'hidePaginator': (pageState.value < 6 || cycles.filtered.length == 0) }">
      <!-- main table -->
      <table mat-table [dataSource]="cyclesData" multiTemplateDataRows matSort #outerSort="matSort"
        *ngIf="pageState.value >= 6 && cycles.filtered.length > 0">

        <!-- toggle icon column -->
        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let cycle" class="td-icon clickable" (click)="toggleRow(cycle)">
            <button mat-button class="box-icon" *ngIf="cycle.phases?.length > 0">
              <mat-icon *ngIf="!cycle.expanded" class="icon" aria-label="unfold_more">unfold_more</mat-icon>
              <mat-icon *ngIf="cycle.expanded" class="icon" aria-label="unfold_less">unfold_less</mat-icon>
            </button>
          </td>
        </ng-container>

        <!-- cycles rows definition -->
        <ng-container *ngFor="let infoCy of cyclesInfo" matColumnDef="{{infoCy.variable}}">

          <!-- column header -->
          <th mat-header-cell *matHeaderCellDef [mat-sort-header]="infoCy.orderBy != null ? infoCy.orderBy : null"
            [disabled]="infoCy.orderBy == null">
            <div fxLayout="row">
              <!-- icon -->
              <div *ngIf="infoCy.iconTH">
                <mat-icon *ngIf="infoCy.iconTH.type == 'icon'" class="icon left"
                  translate-attr="{'aria-label': infoCy.label}">
                  {{infoCy.iconTH.icon}}</mat-icon>
                <mat-icon *ngIf="infoCy.iconTH.type == 'svg'" svgIcon="{{ infoCy.iconTH.icon }}" class="icon left"
                  translate-attr="{'aria-label': infoCy.label}"></mat-icon>
                <i *ngIf="infoCy.iconTH.type == 'fontawesome' || infoCy.iconTH.type == 'phosphor'"
                  class="icon left {{ infoCy.iconTH.icon }}" translate-attr="{'aria-label': infoCy.label}"></i>
              </div>
              <!-- prefix, label, suffix -->
              <span *ngIf="infoCy.prefix != null">{{infoCy.prefix}}&nbsp;</span>
              <span>{{infoCy.label|translate}}</span>
              <span *ngIf="infoCy.suffix != null">&nbsp;[{{infoCy.suffix}}]</span>
            </div>
          </th>

          <!-- column body != list -->
          <td mat-cell *matCellDef="let cycle" [ngClass]="{ 'primary': infoCy.primary }">

            <div *ngIf="!infoCy.type">
              <!-- <span>{{cycle.trainSet}}</span> -->
              <span>{{(cycle[infoCy.variable] != null ? cycle[infoCy.variable] : '-')}}</span>
              <span *ngIf="infoCy.unit != null">&nbsp;{{infoCy.unit}}</span>
            </div>

            <div *ngIf="infoCy.type == 'interval'">
              <span>{{(cycle.timeStartP != null ? cycle.timeStartP : '-')}}</span><br>
              <span>{{(cycle.timeEndP != null ? cycle.timeEndP : '-')}}</span>
              <!-- <span>{{cycle.paramsP|json}}</span> -->
            </div>

            <div *ngIf="infoCy.type == 'progressBar'">
              <kpi-bar [kpiProps]="{ 'value': cycle[infoCy.variable], 'hideLabel': true }"></kpi-bar>
            </div>

            <div *ngIf="infoCy.type == 'icon' && cycle[infoCy.variable] != null && cycle[infoCy.variable].icon != null"
              matTooltip="{{ cycle[infoCy.variable].label |translate}}">
              <mat-icon *ngIf="cycle[infoCy.variable].icon.type == 'icon'" class="icon left"
                translate-attr="{'aria-label': infoCy.label}">
                {{cycle[infoCy.variable].icon.icon}}</mat-icon>
              <mat-icon *ngIf="cycle[infoCy.variable].icon.type == 'svg'"
                svgIcon="{{ cycle[infoCy.variable].icon.icon }}" class="icon left"
                translate-attr="{'aria-label': infoCy.label}"></mat-icon>
              <i *ngIf="cycle[infoCy.variable].icon.type == 'fontawesome' || cycle[infoCy.variable].icon.type == 'phosphor'"
                class="icon left {{ cycle[infoCy.variable].icon.icon }}"
                translate-attr="{'aria-label': infoCy.label}"></i>
            </div>

            <div *ngIf="infoCy.type == 'link'" matTooltip="{{ 'CYCLES.NAVIGATE_TO_CYCLE_DETAIL' | translate }}"
              class="clickable" (click)="navigateToCycleDetail(cycle)">
              <!-- class="clickable" [routerLink]="cycle[infoCy.variable] != null ? cycle[infoCy.variable] : cycle.urlArray"> -->
              <mat-icon class="icon left">open_in_new</mat-icon>
            </div>

            <div *ngIf="infoCy.type == 'checkbox'" fxLayout="row" fxLayoutAlign="start center"
              [ngClass]="checkCycleCheckboxClasses(cycle)" (click)="toggleCycleForGCA(cycle)"
              matTooltip="{{ 'CYCLES.SELECT_CYCLE_FOR_GCA' | translate }}">

              <mat-icon *ngIf="cycle[infoCy.variable]" svgIcon="radio_button" class="icon small left radio_button"
                style="height: 30px; width: 30px;">
              </mat-icon>
              <mat-icon *ngIf="!cycle[infoCy.variable]" svgIcon="radio_button_disabled"
                class="icon small left radio_button_disabled" style="height: 30px; width: 30px;">
              </mat-icon>

            </div>

            <div *ngIf="infoCy.type == 'table' && cycle[infoCy.variable] != null && cycle[infoCy.variable].length > 0"
              class="td-table" (click)="toggleRow(cycle)">
              <table class="table-in-td">
                <tr *ngFor="let item of cycle[infoCy.variable]; let i=index">
                  <td *ngIf="item != null" style="padding: 0 0.25rem !important;">
                    <span>{{item.label | translate}}</span>
                  </td>
                  <td *ngIf="item != null" style="padding: 0 0.25rem !important;">
                    <span>{{item.value}}</span>
                    <span *ngIf="item.unit != null">&nbsp;{{item.unit}}</span>
                  </td>
                </tr>
              </table>
            </div>

            <div *ngIf="infoCy.type == 'list' && cycle[infoCy.variable] != null && cycle[infoCy.variable].length > 0"
              class="td-list" (click)="toggleRow(cycle)">
              <ul>
                <li *ngFor="let li of cycle[infoCy.variable]; let i=index">
                  <div *ngIf="cycle[infoCy.variable + '_max'] != null && i < cycle[infoCy.variable + '_max']">
                    <span>{{li.label | translate}}: </span>
                    <span>{{li.value}}</span>
                    <span *ngIf="li.unit != null">&nbsp;{{li.unit}}</span>
                  </div>
                </li>
              </ul>
            </div>

          </td>

        </ng-container>

        <!-- phases rows definition -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let cycle" [attr.colspan]="getPhasesColSpan()">
            <div *ngIf="cycle.phases.length > 0" class="transition-01"
              [@detailExpand]="cycle.expanded ? 'expanded' : 'collapsed'">
              <div *ngIf="cycle.expanded" class="table-phase-container">
                <!-- *ngIf="cycle == cycleExpanded" -->
                <table #innerTables mat-table [dataSource]="cycle.phases" matSort #innerSort="matSort">

                  <!-- rows definition -->
                  <ng-container *ngFor="let infoPh of phasesInfo" [matColumnDef]="infoPh.variable">

                    <!-- column header -->
                    <th mat-header-cell *matHeaderCellDef>
                      <!-- mat-sort-header -->
                      <div fxLayout="row">
                        <!-- icon -->
                        <div *ngIf="infoPh.iconTH">
                          <mat-icon *ngIf="infoPh.iconTH.type == 'icon'" class="icon left"
                            translate-attr="{'aria-label': infoPh.label}">
                            {{infoPh.iconTH.icon}}</mat-icon>
                          <mat-icon *ngIf="infoPh.iconTH.type == 'svg'" svgIcon="{{ infoPh.iconTH.icon }}"
                            class="icon left" translate-attr="{'aria-label': infoPh.label}"></mat-icon>
                          <i *ngIf="infoPh.iconTH.type == 'fontawesome' || infoPh.iconTH.type == 'phosphor'"
                            class="icon left {{ infoPh.iconTH.icon }}"
                            translate-attr="{'aria-label': infoPh.label}"></i>
                        </div>
                        <!-- prefix, label, suffix -->
                        <span *ngIf="infoPh.prefix != null">{{infoPh.prefix}}&nbsp;</span>
                        <span>{{infoPh.label|translate}}</span>
                        <span *ngIf="infoPh.suffix != null">&nbsp;[{{infoPh.suffix}}]</span>
                      </div>
                    </th>

                    <!-- column body -->
                    <td mat-cell *matCellDef="let phase" [ngClass]="{ 'primary': infoPh.primary }">

                      <div *ngIf="!infoPh.type">
                        <span>{{(phase[infoPh.variable] != null ? phase[infoPh.variable] : '-')}}</span>
                        <span *ngIf="infoPh.unit != null">&nbsp;{{infoPh.unit}}</span>
                      </div>

                      <div *ngIf="infoPh.type == 'interval'">
                        <span>{{(phase.timeStartP != null ? phase.timeStartP : '-')}}</span><br>
                        <span>{{(phase.timeEndP != null ? phase.timeEndP : '-')}}</span>
                      </div>

                      <div *ngIf="infoPh.type == 'progressBar'">
                        <kpi-bar [kpiProps]="{ 'value': phase[infoPh.variable], 'hideLabel': true }"></kpi-bar>
                      </div>

                      <div *ngIf="infoPh.type == 'icon'">
                        <mat-icon *ngIf="phase.icon.type == 'icon'" class="icon left"
                          translate-attr="{'aria-label': infoPh.label}">
                          {{phase.icon.icon}}</mat-icon>
                        <mat-icon *ngIf="phase.icon.type == 'svg'" svgIcon="{{ phase.icon.icon }}" class="icon left"
                          translate-attr="{'aria-label': infoPh.label}"></mat-icon>
                        <i *ngIf="phase.icon.type == 'fontawesome' || phase.icon.type == 'phosphor'"
                          class="icon left {{ phase.icon.icon }}" translate-attr="{'aria-label': infoPh.label}"></i>
                      </div>

                    </td>

                  </ng-container>

                  <!-- header row -->
                  <tr mat-header-row *matHeaderRowDef="phasesColumns"></tr>
                  <!-- phases rows -->
                  <tr mat-row *matRowDef="let row; columns: phasesColumns;"></tr>
                </table>
              </div>
            </div>
          </td>
        </ng-container>

        <!-- header row -->
        <tr mat-header-row *matHeaderRowDef="cyclesColumns"></tr>
        <!-- cycles rows -->
        <tr mat-row *matRowDef="let cycle; columns: cyclesColumns;" [ngClass]="{ 'trained': cycle.trainSet }"></tr>
        <!-- phases rows -->
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expand-row"></tr>

      </table>

      <mat-paginator [ngClass]="{'hidePaginator': (pageState.value < 6 || cycles.filtered.length == 0) }"
        [length]="cycles.filtered.length" [pageSizeOptions]="cycles.pageOptions" [pageSize]="cycles.pageSize"
        showFirstLastButtons></mat-paginator>

    </mat-card>

    <!-- no results -->
    <div *ngIf="pageState.value >= 6 && cycles.list.length == 0" fxLayout="row" fxLayoutAlign="center center"
      class="w100" style="min-height: 100px;">
      <div class="not-found" fxLayout="row" fxLayoutAlign="center center">
        <span>{{ 'CYCLES.NOT_FOUND' | translate }}</span>
      </div>
    </div>

  </div>

</div>