<div *ngIf="widget.title" fxLayout="row" fxLayoutAlign="start center">
  <span class="title">{{ widget.title | translate }}</span>
</div>

<div [class]="compressed ? 'compressed' : 'label'" [ngClass]="widget.class" fxLayout="row" class="last-update"
  fxLayoutAlign="center center">

  <ff-icon [icon]="widget.icon" style="margin-right: 8px;"></ff-icon>

  <span *ngIf="compressed">{{ (widget?.config?.label != null ? widget?.config?.label : '-') | translate }}:&nbsp;</span>
  <span>{{ widget.label }}</span>

</div>