<div mat-dialog-title fxLayout="row" class="w100">
    <span>{{ dialog.title | translate }}</span>
    <span fxFlex></span>
    <button mat-dialog-close class="close">
        <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
    </button>
</div>

<mat-dialog-content class="mat-typography">

    <div *ngIf="ganttState == 1" style="border-radius: 20px; background-color: #fff;" class="w100">

        <!-- export -->
        <ff-export-table [config]="exportCSVConfig" matTooltip="{{ 'GLOBAL.EXPORT_TABLE_CSV' | translate }}"
            class="inToolbar">
        </ff-export-table>

        <apx-chart class="w100" [chart]="chartOptions.chart" [plotOptions]="chartOptions.plotOptions"
            [dataLabels]="chartOptions.dataLabels" [xaxis]="chartOptions.xaxis" [tooltip]="chartOptions.tooltip"
            [yaxis]="chartOptions.yaxis" [grid]="chartOptions.grid" [series]="chartOptions.series"
            [annotations]="chartOptions.annotations"></apx-chart>
    </div>

    <div *ngIf="ganttState == 2" fxLayout="row" fxLayoutAlign="center center" class="w100" style="margin-top: 12px;">
        <div class="not-found" fxLayout="row" fxLayoutAlign="center center">
            <span>{{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}</span>
        </div>
    </div>

    <div *ngIf="!ganttState" fxLayout="row" fxLayoutAlign="center center" class="w100" style="margin-top: 12px;">
        <ff-loading-card [loadingData]="loadingData">
        </ff-loading-card>
    </div>

</mat-dialog-content>