
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
@Component({
  selector: 'app-machine-component-selection-dialog',
  templateUrl: './machine-component-selection-dialog.component.html',
  styleUrls: ['./machine-component-selection-dialog.component.scss']
})
export class MachineComponentSelectionDialogComponent implements OnInit {

  public dialogList: any = [];
  public disableButton: boolean = true;
  public maxItemsSelectable: any;
  public availableOptions: any;
  public allSelected: any = true;
  public singleSelectionMode: any = false;
  public selectedComponent: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public translate: FfTranslateService,
  ) { }

  // // // // // // // // // // // // // // // // // //
  // MULTIPLE SELECTION MODE

  // // // // // // // // // // // // // // // // // //
  // MULTIPLE MACHINES
  toggleGroupExpansion(group: any) { group.expanded = group.expanded != null ? !group.expanded : true }

  toggleTrace(group: any, trace: any) {
    trace.show = trace.show != null ? !trace.show : true;
    group.show = group.components.some((x: any) => x.show);
    this.recalc(group);
  }

  toggleGroup(group: any) {
    group.show = group.show != null ? !group.show : true;
    group.components.forEach((trace: any) => trace.show = group.show);
    this.recalc(group);
  }

  recalc(group: any) {
    group.shownTraces = group.components.filter((x: any) => x.show).length;
    this.disableButton = this.dialogList.every((group: any) => !group.show);
  }

  // // // // // // // // // // // // // // // // // //
  // SINGLE MACHINE
  toggleComponent(comp) {
    comp.show = comp.show != null ? !comp.show : true;
  }

  searchFromList(searchEvent) {
    this.availableOptions = searchEvent.target.value;
  }

  updateAllSelected(comp?: any) {
    if (comp != null) comp.show = comp.show != null ? !comp.show : true;
    this.checkAllSelected();
  }

  checkAllSelected() {
    this.allSelected = this.dialogList?.every((t: any) => t.show);
  }

  atLeastOneSelected(): boolean {
    if (this.dialogList == null) return false;
    return this.dialog.forceSelection || this.dialogList.filter((t: any) => t.show).length > 0;
  }

  setAll(show: boolean) {
    this.allSelected = !show;
    if (this.dialogList == null) return;
    this.dialogList.forEach((t: any) => t.show = !show);
    this.updateAllSelected();
  }

  // // // // // // // // // // // // // // // // // //
  // SINGLE SELECTION MODE
  selectComponent(comp, machine?) {
    this.dialogList.forEach((g: any) => {
      g.selected = false
      if (g.components != null) g.components.forEach((c: any) => c.selected = false);
    });
    comp.selected = true;

    this.selectedComponent = {
      componentId: comp.componentId,
      machineRefId: machine?.machineId
    }
  }

  onDialogConfirm() {
    if (this.singleSelectionMode) return this.selectedComponent;
    return this.dialog;
  }

  checkDisability() {
    if (this.singleSelectionMode) return this.selectedComponent == null;
    return this.dialog.lineMode ? this.disableButton : !this.atLeastOneSelected();
  }
  // // // // // // // // // // // // // // // // // //
  // INIT
  ngOnInit(): void {
    if (this.dialog != null && this.dialog.list != null && this.dialog.list.length > 0) {
      this.dialogList = this.dialog.list;

      if (this.dialog.lineMode) this.dialogList.forEach((group: any) => group.expanded = true);
      else this.checkAllSelected();

      this.disableButton = false;

      this.singleSelectionMode = this.dialog.singleSelectionMode;

      if (this.singleSelectionMode && this.dialog.selectedComponent) {

        this.selectedComponent = this.dialog.selectedComponent;

        let scCompId = this.dialog.selectedComponent.componentId;
        let scMachRefId = this.dialog.selectedComponent.machineRefId;

        if (this.dialog.lineMode) {
          this.dialogList = this.dialogList.reduce((machines, machine) => {

            if (machine.machineId == scMachRefId) {
              machine.components = (machine.components ?? []).reduce((comps, comp) => {
                comp.selected = comp.componentId == scCompId;
                comps.push(comp);
                return comps;
              }, []);
            }

            machines.push(machine);
            return machines;
          }, []);
        }

        else {

          this.dialogList = this.dialogList.reduce((comps, comp) => {
            comp.selected = comp.componentId == scCompId;
            comps.push(comp);
            return comps;
          }, []);

        }
      }

    }
  }

  ngOnDestroy() { }

}
