<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < 9" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 9" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="(tabs.length > 1 || intervalConfig != null) && pageState.value >= 7" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- change component -->
    <button mat-button class="filter-button"
      (click)="filterService.openComponentSelectionDialog(this, 'cycle-exploration')">
      <span>{{ 'HEALTH_MONITORING.CHANGE_COMPONENT' | translate }}</span>
    </button>

  </div>

  <div *ngIf="isMobile" fxFlex fxLayout="column" class="tab-content h100 w100"
    [ngClass]="{ 'without-tabs': tabs.length <= 1}" fxLayoutAlign="center center">
    <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
  </div>

  <!-- tab content -->
  <div *ngIf="!isMobile" fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content">

    <!-- toolbar tabs -->
    <div *ngIf="pageState.value >= 7" class="ff-toolbar pt-1 pb-3" fxLayout="column" fxLayoutAlign="start start"
      fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <!-- variables and components selection -->
      <button mat-button [disabled]="plotsConfigComponents == null" (click)="openVariablesSelectionDialog()"
        class="filter-button" fxLayout="row" fxLayoutAlign="center center">
        {{ 'HEALTH_MONITORING.VARIABLES_SELECTION' | translate }}
        <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
          style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
        </mat-icon>
      </button>

      <button mat-button (click)="openTimeStatesDialog()" class="filter-button" fxLayout="row"
        fxLayoutAlign="center center" *ngIf="returnStates && pageStateReady">
        <mat-icon class="icon">dehaze</mat-icon>
        {{ 'CONTINUOUS_EXPLORATION.STATE_SELECTION' | translate }}
      </button>

      <button mat-button (click)="openCyclePhasesDialog()" class="filter-button" fxLayout="row"
        fxLayoutAlign="center center" *ngIf="returnPhases && pageStateReady">
        <mat-icon class="icon">update</mat-icon>
        {{ 'CYCLE_EXPLORATION.PHASE_SELECTION' | translate }}
      </button>

      <span fxFlex fxShow="false" fxShow.gt-sm></span>

      <button [disabled]="cycles == null || cycles.list == null || cycles.list.length == 0"
        (click)="navigateToPreviousCycle()" class="circular-icon-button md-blue left">
        <mat-icon class="icon">skip_previous</mat-icon>
      </button>

      <button mat-button (click)="openCycleComparison('actual')" class="filter-button" fxLayout="row"
        fxLayoutAlign="center center">
        <mat-icon class="icon">dehaze</mat-icon>
        {{ 'CYCLE_EXPLORATION.ACTUAL_CYCLE_SELECTION' | translate }}
      </button>

      <button [disabled]="cycles == null || cycles.list == null || cycles.list.length == 0"
        (click)="navigateToNextCycle()" class="circular-icon-button md-blue">
        <mat-icon class="icon">skip_next</mat-icon>
      </button>

      <span fxFlex fxShow="false" fxShow.gt-sm></span>

      <button *ngIf="isComparedCycle" mat-button (click)="removeComparedCycle()" class="filter-button md-red"
        fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="icon">cancel</mat-icon>
        {{ 'CYCLE_EXPLORATION.REMOVE_COMPARISON' | translate }}
      </button>

      <button [disabled]="cycles == null || cycles.list == null || cycles.list.length == 0" mat-button
        (click)="openCycleComparison('comparison')" class="filter-button" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="icon">dehaze</mat-icon>
        {{ 'CYCLE_EXPLORATION.COMPARED_CYCLE_SELECTION' | translate }}
      </button>

    </div>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < 8 || (pageState.value < 10 && cycles.selected != null)"
      [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 8 && cycles.selected == null" fxLayout="row" fxLayoutAlign="center center"
      class="not-found w100 h100" style="height: 100px;">
      <span>{{ 'CYCLE_EXPLORATION.NO_CYCLES_IN_DAY' | translate }}</span>
    </div>

    <div *ngIf="pageState.value >= 10 && cycles.selected != null" fxLayout="column" class="w100 h100" [ngStyle]="{'max-height': isComparedCycle ? 'calc(100vh - ' + (appInfo.mindsphere ? '418' : '372') + 'px)' : 
      'calc(100vh - ' + (appInfo.mindsphere ? '348' : '332') + 'px)'}">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
        class="h100 w100" fxLayoutGap="8px">

        <div fxFlex fxLayout="row" fxLayoutAlign="start stretch" class="w100 h100" fxLayoutGap="8px"
          style="position: relative;">

          <ff-dashboard fxFlex *ngIf="monitoringData != null && isDashboard"
            [completeDashboardConfig]="completeDashboardConfig">
          </ff-dashboard>

          <ff-widget fxFlex *ngIf="!isDashboard"
            [widget]="{type: 'ff-plotly-chart', data: monitoringData, machineProfile: machine.profile, config: monitoringData.dataConfig }">
          </ff-widget>

          <div (click)="isOpenAverageValues = !isOpenAverageValues;resizePlot()"
            *ngIf="srsActual != null && srsActual.length > 0"
            class="clickable transition-01 toggle-average-values-table" fxLayout="column" fxLayoutAlign="center center">
            <mat-icon *ngIf="!isOpenAverageValues" class="icon md-white" aria-label="expand">chevron_left</mat-icon>
            <mat-icon *ngIf="isOpenAverageValues" class="icon md-white" aria-label="collaps">chevron_right</mat-icon>
          </div>

          <div [fxFlex]="isOpenAverageValues ? '30' : '1e-9'" class="average-values-table"
            [ngClass]="{'open': isOpenAverageValues}">

            <div class="phase-data-title">
              <span>{{ (!useFeaturesInsteadOfSrs ? 'HEALTH_MONITORING.SINGLE_VARIABLE_SCORE' :
                'FEATURES_EXPLORATION.SINGLE_FEATURE_AVG') | translate }}</span>
              <span *ngIf="isComparedCycle" class="select-phase">
                ({{ 'CYCLE_EXPLORATION.COMPARISON' | translate }})
              </span>
            </div>

            <div *ngIf="srsActual != null && srsActual.length > 0" class="table-container">
              <table class="standard-table">
                <thead>
                  <tr>

                    <ng-container *ngIf="!useFeaturesInsteadOfSrs">
                      <th><strong>{{ 'CYCLE_EXPLORATION.VARIABLE' | translate }}</strong></th>
                      <th><strong>{{ 'HEALTH_MONITORING.HEALTH' | translate }}</strong></th>

                      <th *ngIf="isComparedCycle">
                        <strong>{{ 'CYCLE_EXPLORATION.VALUE_COMPARED' | translate }}</strong>
                      </th>

                    </ng-container>

                    <ng-container *ngIf="useFeaturesInsteadOfSrs">
                      <th><strong>{{ 'FEATURES_EXPLORATION.FEATURE' | translate }}</strong></th>
                      <th><strong>{{ 'GLOBAL.VALUE' | translate }}</strong></th>

                      <th *ngIf="isComparedCycle">
                        <strong>{{ 'CYCLE_EXPLORATION.VALUE_COMPARED' | translate }}</strong>
                      </th>

                    </ng-container>

                    <th *ngIf="isComparedCycle"></th>
                </thead>
                <tbody>
                  <tr *ngFor="let srs of srsActual">

                    <td>{{ srs.label }}</td>

                    <td *ngIf="!useFeaturesInsteadOfSrs">
                      <kpi-bar [kpiProps]="{ 'value': srs.valueUnparsed, 'hideLabel': true }">
                      </kpi-bar>
                    </td>

                    <td *ngIf="useFeaturesInsteadOfSrs">{{ srs.value }} [{{ srs.unit | placeholder:'-' }}]</td>

                    <td *ngIf="isComparedCycle">

                      <kpi-bar *ngIf="!useFeaturesInsteadOfSrs"
                        [kpiProps]="{ 'value': srs.valueComparedUnparsed, 'hideLabel': true }">
                      </kpi-bar>

                      <span *ngIf="useFeaturesInsteadOfSrs">
                        {{ srs.valueCompared }} [{{ srs.unit | placeholder:'-' }}]
                      </span>
                      <!-- <strong>{{ srs.valueCompared | placeholder:'-' }}&nbsp;{{ srs.unit }}</strong> -->
                    </td>
                    <td *ngIf="isComparedCycle">
                      <mat-icon *ngIf="compareValues(srs.valueUnparsed, srs.valueComparedUnparsed) == 'greater'"
                        svgIcon="arrow_up" class="icon md-green">
                      </mat-icon>
                      <mat-icon *ngIf="compareValues(srs.valueUnparsed, srs.valueComparedUnparsed) == 'smaller'"
                        svgIcon="arrow_down" class="icon md-red-i">
                      </mat-icon>
                      <mat-icon *ngIf="compareValues(srs.valueUnparsed, srs.valueComparedUnparsed) == 'equal'"
                        class="icon">remove
                      </mat-icon>
                    </td>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

      </div>

    </div>

    <mat-card *ngIf="pageState.value >= 10 && cycles.selected != null" class="cycle-table-container">

      <table class="standard-table">
        <thead>
          <tr>
            <th></th>
            <th *ngFor="let info of tableInfos">
              <span>{{ info.label | translate }}</span>
              <span *ngIf="info.suffix != null">&nbsp;[{{ info.suffix }}]</span>
            </th>
        </thead>
        <tbody>
          <!-- <ng-content *ngFor="let type of ['actual', 'comparison']"> -->
          <tr *ngFor="let type of ['actual', 'comparison']"
            [ngStyle]="{'display': (type == 'comparison' ? (isComparedCycle && cycleInfos.comparison != null && cycleInfos.comparison.cycleId != null && cycleInfos.actual.cycleId != cycleInfos.comparison.cycleId ? 'table-row' : 'none') : 'table-row')}">
            <td>
              <span>{{ 'CYCLE_EXPLORATION.' + type.toUpperCase() | translate }}</span>
            </td>
            <td *ngFor="let info of tableInfos">

              <ff-single-cell [item]="cycleInfos?.[type] ?? {}" [info]="info" [profile]="machine?.profile"
                [machineId]="machineId">
              </ff-single-cell>

            </td>
          </tr>
        </tbody>
      </table>
    </mat-card>

  </div>

</div>