<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div [ngClass]="{'hidePaginator': pageState.value >= 0 && pageState.value < pageStateReady }" fxLayout="column"
  fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">
    <ff-tabs [tabs]="tabs"></ff-tabs>

    <span fxFlex.gt-sm></span>

    <button class="filter-button md-primary rounded-button" (click)="updateGcaSettings()" fxLayout="row"
      fxLayoutAlign="start center" [disabled]="!pageStateReady || checkUpdates() || !isAllowedUserWrite">
      <mat-icon class="icon" aria-label="save">save</mat-icon>
      <span>{{ 'GCA_SETTINGS.UPDATE_SETTINGS' | translate }}</span>
    </button>

  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column"
    fxLayoutAlign="{{ (pageState.value < 8 || pageState.value == 0 || trainingState == 1) ? 'center center' : 'start stretch' }}"
    class="tab-content h100 w100" [ngClass]="{ 'without-tabs': tabs.length <= 1 }">

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < 8" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="trainingState == 1">
      <!-- <pre>{{ loadingTrainingData | json }}</pre> -->
      <ff-loading-card [loadingData]="loadingTrainingData" style="margin: auto;">
      </ff-loading-card>
    </div>

    <mat-card *ngIf="pageState.value >= 8 && trainingState != 1" class="gca-settings completely"
      [class.ff-disabled]="cyclesSelected != null && cyclesSelected.length > 0">

      <div class="title" fxLayout="column">

        <span class="error" *ngIf="cyclesSelected != null && cyclesSelected.length > 0" style="margin-bottom: 24px;">
          {{ 'GCA_SETTINGS.CYCLE_SELECTION_SETTINGS_DISABLED_WARNING' | translate }}
        </span>

        <span>{{ 'GCA_SETTINGS.CYCLE_SELECTION_SETTINGS' | translate }}</span>
      </div>

      <div fxLayout="row" fxLayoutAlign="start center" class="setting-row">
        <span fxFlex="20">
          {{ "GCA_SETTINGS.AUTOMATIC_SELECTION" | translate }}
        </span>
        <ui-switch fxFlex="10" [checked]="autoSwitch.checked" (change)="switchChange($event, autoSwitch, 'auto')"
          checkedLabel="{{autoSwitch.checkedLabel}}" uncheckedLabel="{{autoSwitch.uncheckedLabel}}"
          [disabled]="!isAllowedUserWrite">
        </ui-switch>
        <span fxFlex="70" style="margin-left: 8px;">
          {{ autoSwitch.description | translate }}
        </span>
      </div>

      <div *ngIf="autoSwitch.checked" fxLayout="row" fxLayoutAlign="start center" class="setting-row">

        <span fxFlex="20">
          {{ "GCA_SETTINGS.NUMBER_OF_CYCLES" | translate }}
        </span>

        <div fxFlex="10">
          <input matInput class="ff-input number" type="number" [min]="0" [max]="200"
            [(ngModel)]="gcaSettingsConfig.numOfCycles" [disabled]="!isAllowedUserWrite">
        </div>

        <span fxFlex="70" style="margin-left: 8px;">
          {{ "GCA_SETTINGS.NUMBER_OF_CYCLES_DESCRIPTION" | translate }}
        </span>

      </div>

      <div *ngIf="autoSwitch.checked" fxLayout="row" fxLayoutAlign="start center" class="setting-row">
        <span fxFlex="20">
          {{ "GCA_SETTINGS.TIME_RANGE_SWITCH_SELECTION" | translate }}
        </span>
        <ui-switch fxFlex="10" [checked]="timeRangeSwitch.checked" [disabled]="!isAllowedUserWrite"
          (change)="switchChange($event, timeRangeSwitch, 'timeRange')" checkedLabel="{{timeRangeSwitch.checkedLabel}}"
          uncheckedLabel="{{timeRangeSwitch.uncheckedLabel}}">
        </ui-switch>
        <span fxFlex="70" style="margin-left: 8px;">
          {{ timeRangeSwitch.description | translate }}
        </span>
      </div>

      <div *ngIf="autoSwitch.checked && timeRangeSwitch.checked" fxLayout="row" fxLayoutAlign="start center"
        class="setting-row">
        <span fxFlex="20">
          {{ "GCA_SETTINGS.TIME_RANGE_SELECTION" | translate }}
        </span>
        <ff-interval-selector [config]="intervalConfig" (selected)="openCustomTimeRangeSelection($event)"
          [class.ff-disabled]="!isAllowedUserWrite" class="completely">
        </ff-interval-selector>
      </div>

      <div class="title" *ngIf="autoSwitch.checked">
        <span>{{ 'GCA_SETTINGS.CUSTOM_RULE' | translate }}</span>
      </div>

      <div class="subtitle" *ngIf="autoSwitch.checked">
        <span translate="GCA_SETTINGS.CUSTOM_RULE_DESCRIPTION"
          [translateParams]="{numOfCycles: gcaSettingsConfig?.numOfCycles != null ? gcaSettingsConfig?.numOfCycles : '-'}">
        </span>
        <span *ngIf="!customRule?.list?.length">
          {{ 'GCA_SETTINGS.INIT_CUSTOM_RULE' | translate }}
        </span>
      </div>

      <div *ngIf="autoSwitch.checked" fxLayout="row" style="margin: 16px 0px 0px 8px;">

        <div *ngFor="let rule of ruleButtons">
          <ng-container [ngTemplateOutlet]="InfoValue" [ngTemplateOutletContext]="{ info:rule }">
          </ng-container>
        </div>

        <div fxLayout="column" fxLayoutAlign="center center">
          <span class="rule-title">{{ 'GCA_SETTINGS.CANCEL' | translate }}</span>
          <button class="rounded-button md-red-i" [disabled]="!customRule?.list?.length || !isAllowedUserWrite"
            (click)="customRule.list.pop(); customRule.toBE.pop(); parseCustomRuleStringFromList()">
            <ff-icon [icon]="'keyboard_backspace'"></ff-icon>
          </button>
        </div>

      </div>

      <div *ngIf="customRule.string != null && customRule.string != '' && autoSwitch.checked"
        class="custom-rule-container">
        <span>{{ customRule.string }}</span>
      </div>

      <div *ngIf="customRule?.errors?.length && autoSwitch.checked" fxLayout="column" class="error-container">
        <span *ngFor="let error of customRule.errors" class="error">
          {{ (error != null ? error : '-') | translate }}
        </span>
      </div>

    </mat-card>

    <!-- gca evaluation toolbar -->
    <div *ngIf="cyclesSelected != null && cyclesSelected.length > 0 && isAllowedUserWrite && trainingState != 1"
      class="ff-toolbar pt-1 pb-3" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">

      <span fxFlex></span>

      <button *ngIf="trainingAction == 'remove'" mat-button class="filter-button md-red"
        (click)="openGCADialog('train', 'remove')" fxLayout="row" fxLayoutAlign="space-evenly center">
        <mat-icon class="icon">delete</mat-icon>
        <span>{{ 'CYCLES.REMOVE_FROM_TRAIN_SET' | translate }}</span>
        <span>&nbsp;({{ cyclesSelected.length }})</span>
      </button>

    </div>

    <mat-card class="gca-settings" *ngIf="trainingState != 1" class="completely" [class.ff-disabled]="dirtySettings">

      <div class="title" fxLayout="column">

        <span class="error" *ngIf="dirtySettings" style="margin-bottom: 24px;">
          {{ 'GCA_SETTINGS.TRAINING_CYCLES_DISABLED_WARNING' | translate }}
        </span>

        <span>{{ 'GCA_SETTINGS.TRAINING_CYCLES' | translate }}</span>
      </div>

      <ff-dashboard *ngIf="completeDashboardConfig != null" [completeDashboardConfig]="completeDashboardConfig"
        (clicked)="onButtonClick($event)">
      </ff-dashboard>
    </mat-card>


  </div>

</div>


<!-- TEMPLATE InfoValue -->
<ng-template #InfoValue let-info="info">

  <div fxLayout="column" fxLayoutAlign="center center" style="margin: 0 20px;">
    <span class="rule-title" [class.opacity0]="info?.label == null">
      {{ (info?.label != null ? (info?.label | translate) : '-' ) }}</span>

    <div *ngIf="info.type == null">
      <button class="calculator-button rounded-button md-gray" (click)="addValueToCustomRule(info)"
        [disabled]="checkRuleDisability(info) || !isAllowedUserWrite">{{ info.value }}</button>
    </div>

    <div *ngIf="info.type == 'operators'" fxLayout="row">
      <button *ngFor="let operator of info.buttons" class="calculator-button rounded-button md-gray"
        (click)="addValueToCustomRule(info, operator)" [disabled]="checkRuleDisability(info) || !isAllowedUserWrite">
        {{ operator.value }}
      </button>
    </div>

    <div *ngIf="info.type == 'number'" class="input-box" fxLayout="row">
      <input matInput class="ff-input number completely" type="number" [(ngModel)]="info.value"
        (keydown)="onKeydown($event, info)" [class.ff-disabled]="checkRuleDisability(info) || !isAllowedUserWrite">
      <button class="calculator-button rounded-button md-gray" (click)="onKeydown({key: 'Enter'}, info)"
        [disabled]="checkRuleDisability(info) || info.value == null || info.value == '' || !isAllowedUserWrite">
        <ff-icon [icon]="'subdirectory_arrow_left'"></ff-icon>
      </button>
    </div>

    <div *ngIf="info.type == 'dropdown'" class="ff-input-select w100" fxLayout="row" fxLayoutAlign="start center">
      <mat-select [(value)]="info.value"
        [disabled]="!info.list?.length || checkRuleDisability(info) || !isAllowedUserWrite">
        <ng-container *ngIf="info.closedListType == null">
          <mat-option *ngFor="let opt of info.list" [value]="opt">
            {{ opt }}
          </mat-option>
        </ng-container>
        <ng-container *ngIf="info.closedListType == 'arrayOfObjects'">
          <mat-option *ngFor="let opt of info.list" [value]="opt.id" (click)="addValueToCustomRule(info, opt)">
            {{ filterService.translateElementNewFormat(opt) }}
          </mat-option>
        </ng-container>
      </mat-select>
    </div>
  </div>

</ng-template>