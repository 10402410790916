import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { EditVariableDialogComponent } from '../edit-variable-dialog/edit-variable-dialog.component';

@Component({
  selector: 'app-add-event-dialog',
  templateUrl: './add-event-dialog.component.html',
  styleUrls: ['./add-event-dialog.component.scss']
})
export class AddEventDialogComponent implements OnInit, OnDestroy {

  public newEvent: any;
  public appConfig: any;
  public isCalendarPresent: boolean;
  public isAllowedUser: any = true;
  public isAllowedUserWrite: any = true;
  public machine: any;
  public machineId: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<AddEventDialogComponent>,
    public clonerService: ClonerService,
    public internalDataService: InternalDataService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public appConfigService: AppConfigService,
    public subdialog: MatDialog,

  ) {
    this.appConfig = appConfigService.getAppConfig;
  }

  closeDialog() {
    this.dialogRef.close(this.newEvent);
  }

  ngOnInit(): void {
    // console.log('this.dialog.alertsConfig', this.dialog.alertsConfig);

    this.machine = this.dialog.machine;
    this.machineId = this.machine.machineId;
    this.isCalendarPresent = this.dialog.isCalendarPresent;
    this.dialog.alertsConfig.forEach((alert: any) => {

      alert.checked = !!+alert.checked;
      alert.deactivationAlert = !!+alert.deactivationAlert;
      alert.useCalendar = !!+alert.useCalendar;

      alert.shiftAggrSelected = false;

      if (alert.addMachineSelectionParameter != null && this.dialog.availableMachines != null && this.dialog.availableMachines.list != null && this.dialog.availableMachines.list.length > 0) {
        alert.machines = [this.dialog.availableMachines.selected];
      }

      alert.type = this.internalDataService.getAlertType(alert.alertType, 'type');
      alert.typeIcon = this.internalDataService.getAlertIcon(alert.type);

      alert.description = "alerts." + alert.alertType + ".description";
      alert.name = "alerts." + alert.alertType + ".name";
      alert.nameShort = "alerts." + alert.alertType + ".nameShort";

      alert.parameters.forEach((p: any, pIdx: any) => {

        if ((p.type == 'dropdown' || p.type == 'dialog') && p.hasOwnProperty("dropdownType") &&
          p.dropdownType != null && this.dialog.dropdowns != null &&
          this.dialog.dropdowns.hasOwnProperty(p.dropdownType)) {
          p = Object.assign(p, {
            list: this.dialog.dropdowns[p.dropdownType]
          });

          if (p.dropdownType == 'timeAggrs') {
            let shiftAggr = p.list.find(elem => elem.kpi == 'shifts');
            if (shiftAggr) shiftAggr.disabled = !alert.useCalendar;
            if (p.value == 'shifts') alert.shiftAggrSelected = true;
          }
        }

        p.label = this.translate.instant("alerts." + alert.alertType + ".parameters." + p.id + ".label");
        p.description = this.translate.instant("alerts." + alert.alertType + ".parameters." + p.id + ".description");

        alert.parameters[pIdx] = this.clonerService.deepClone(p);
      });

      // CUSTOM MAILING LIST
      if (this.appConfig?.alerts?.flags?.customMailingList && this.appConfig?.alerts?.flags?.removeGlobalMailingList) {
        alert.isCustomMailingList = true;
        alert.mailingList = alert?.mailingList ?? [];
      };
      if (alert.isCustomMailingList && alert?.mailingList?.length > 0) {
        alert.mailingList = alert?.mailingList?.reduce((acc, val, index) => {
          acc.push({ id: index, value: val });
          return acc;
        }, []);
      }
    })

    this.newEvent = this.clonerService.deepClone(Object.assign(this.dialog.alertsConfig[0], {
      id: (new Date()).getTime() + Math.random().toString(16).slice(2),
    }));
    try {
      let opParamIndex = this.newEvent.parameters.findIndex((x: any) => x.id == 2);
      if (opParamIndex != -1) {
        this.newEvent.parameters[opParamIndex].value = "=";
      }
    } catch (error) {
      console.log(error);

    }

  }

  onCalendarChange() {

    this.newEvent.parameters.forEach((p: any, pIdx: any) => {

      if ((p.type == 'dropdown' || p.type == 'dialog') && p.hasOwnProperty("dropdownType") &&
        p.dropdownType != null && this.dialog.dropdowns != null &&
        this.dialog.dropdowns.hasOwnProperty(p.dropdownType)) {
        if (p.dropdownType == 'timeAggrs') {
          let shiftAggr = p.list.find(elem => elem.kpi == 'shifts');
          if (shiftAggr) shiftAggr.disabled = !this.newEvent.useCalendar;
        }
      }
    });

  }

  onAlertTypeChange(type: any) {
    let idx = this.dialog.alertsConfig.findIndex((x: any) => x.alertType == type);
    if (idx != -1) {
      this.newEvent = this.clonerService.deepClone(Object.assign(this.dialog.alertsConfig[idx], {
        id: (new Date()).getTime() + Math.random().toString(16).slice(2),
      }));
    }
    if (type == 'boolVar' || type == 'cloths') {
      try {
        let opParamIndex = this.newEvent.parameters.findIndex((x: any) => x.id == 2);
        if (opParamIndex != -1) {
          this.newEvent.parameters[opParamIndex].value = type == 'boolVar' ? "=" : "\u2265";
        }
      } catch (error) {
        console.log(error);

      }
    }
  }

  onVariableChange(alert: any, param: any) {

    if (param?.paramType == 'KPI' || (param?.paramType == 'DYNAMIC-VAR' && param?.type == 'dropdown')) {
      try {

        let currentKPI = param.list.find((x: any) => (x.kpi ?? x.id) == param.value);

        if (currentKPI != null) {
          if (param?.paramType == 'KPI') param.kpiLabel = this.translate.instant(currentKPI.label ?? '-');
          else if (param?.paramType == 'DYNAMIC-VAR') param.valueLabel = currentKPI.label;
          alert.parameters[2].unit = currentKPI.unit;
          alert.parameters[2].multiplier = currentKPI.multiplier;
        }

      } catch (error) {
        console.log(error);
      }
    }
    else if (param.paramType == 'TIME-AGGR') {
      alert.shiftAggrSelected = alert.parameters[4].value == 'shifts';
    }
    else if (param.paramType == 'AGGR-TYPE') {
      if (alert.parameters[5].value == 'all') alert.parameters[5].valueLabel = this.translate.instant('RBA.ALL_VALUES_SHORT').toLowerCase();
      else if (alert.parameters[5].value == 'mean') alert.parameters[5].valueLabel = this.translate.instant('RBA.MEAN_VALUE_SHORT').toLowerCase();
    }
  }

  openEditVariable(alert: any, parameter: any) {

    if (this.isAllowedUser && this.isAllowedUserWrite) {
      let currentAlert = this.clonerService.deepClone(alert);
      let currentParameter = this.clonerService.deepClone(parameter);

      let addMachineSelection = false;
      try {
        addMachineSelection = this.machine.profile.flags.addMachineSelectionToVariables;
      } catch (error) { }

      const editVariableDialog = this.subdialog.open(EditVariableDialogComponent, {
        panelClass: 'ff-dialog',
        width: '40%',
        data: {
          title: this.translate.instant("RBA.EDIT_VARIABLE"),
          addMachineSelection: addMachineSelection,
          machineId: this.machineId,
          currentAlert: currentAlert,
          currentParameter: currentParameter,
        },
      });

      editVariableDialog.afterClosed().subscribe((result: any) => {

        let isClickedSelect = result != null && result != '';
        if (isClickedSelect) {
          parameter.value = result.aspect + '.' + result.variable;
          parameter.valueLabel = this.internalDataService.parseDatapointLabel(result.aspect, result.variable, this.machineId);
          // parameter.valueLabel = ('DATAPOINTS.' + this.machineId + '.' + result.aspect + '.' + result.variable) == this.translate.instant('DATAPOINTS.' + this.machineId + '.' + result.aspect + '.' + result.variable) ?
          //   null : this.translate.instant('DATAPOINTS.' + this.machineId + '.' + result.aspect + '.' + result.variable);
        }

      });
    }

  };

  exists(item: any, list: any) {
    return list.filter((x: any) => x == item).length > 0;
  };

  toggle(item: any, list: any) {

    var idx = list.findIndex((x: any) => x == item);
    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }
    // this.checkAllSelected();
  };

  searchFromList(searchEvent, parameter) {
    parameter.availableOptions = searchEvent.target.value;
  }

  ngOnDestroy() {
  }

}
