import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[marginLeft]'
})
export class MarginLeftDirective {

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.marginLeft = '8px';
  }

}
