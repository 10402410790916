<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title | translate }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <textarea class="ff-textarea" rows="10" cols="60" [(ngModel)]="dialog.value" name="text"></textarea>

</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button class="filter-button md-primary" (click)="closeDialog()">
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</mat-dialog-actions>