import { Pipe, PipeTransform } from '@angular/core';

// Replaces input strings with custom strings.
// The input can be a list ["keyToChange", "changedKey"]
// or a list of lists [["keyToChange1", "changedKey1"], ["keyToChange2", "changedKey"2] ... ]

@Pipe({ name: 'replace' })
export class ReplacePipe implements PipeTransform {
    constructor() { }

    transform(value: any, replaceArray: any): any {

        if (value == null) return null;
        if (replaceArray != null && Array.isArray(replaceArray) && replaceArray.length > 0) {

            // Check if the input is a list of lists
            if (replaceArray.every((elem: any) => elem != null && Array.isArray(elem) && elem.length > 0)) {
                try {
                    replaceArray.forEach((elem: any) => value = value.replace(elem[0], elem[1]));
                } catch (error) {
                    console.log(error);
                }
            }

            // Check if the input is a single array
            else {
                try {
                    value = value.replace(replaceArray[0], replaceArray[1]);
                } catch (error) {
                    console.log(error);
                }
            }
        }

        return value;
    }
}