<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"
      [intAggrs]="intervalAggregations" (selectedAggr)="selectAggregation($event)">
    </ff-interval-selector>

  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    [fxLayoutAlign]="pageState.value >= 5 ? 'start stretch' : (pageState.value == 0 ? 'start center' : 'center center')"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar -->
    <div *ngIf="aggregations?.length > 0 || availableMachines?.list?.length > 0" class="ff-toolbar pb-3 pt-1"
      fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <!-- aggregations -->
      <button mat-button class="filter-button" *ngFor="let aggr of aggregations" (click)="openAggrDialog(aggr)"
        [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
        <mat-icon *ngIf="aggr.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': aggr.label}">
          {{aggr.icon.icon }}</mat-icon>
        <mat-icon *ngIf="aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
          translate-attr="{'aria-label': aggr.label}"></mat-icon>
        <i *ngIf="aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor'" class="icon {{ aggr.icon.icon }}"
          translate-attr="{'aria-label': aggr.label}"></i>
        <span>{{ aggr.selectionLabel }}</span>
        <div fxLayout="row" fxLayoutAlign="center center"
          *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
          {{ aggregationsPayload[aggr.id].length }}
        </div>
      </button>

      <span fxFlex fxShow="false" fxShow.gt-sm></span>

      <ff-available-machines-selection *ngIf="availableMachines?.list?.length > 0"
        [availableMachines]="availableMachines" (selected)="machineSelectionChange($event)">
      </ff-available-machines-selection>

    </div>

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 6" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 6" fxLayout="column" class="w100 h100"
      [ngStyle]="{'min-height': isSmThanTablet ? '0px' : 'calc(100vh - 260px)'}">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
        class="h100 w100" fxLayoutGap="8px">

        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" fxFlexOrder.lt-md="2" class="w100"
          [class.h100]="!isMobile" fxLayoutGap="8px">
          <ff-widget fxFlex.gt-sm="100"
            [widget]="{type: 'ff-plotly-chart-table', data: scrapAnalyticsData, machineProfile: machine.profile, config: scrapAnalyticsData.dataConfig }">
          </ff-widget>
        </div>

        <div fxFlex.gt-sm="{{dashboardConfig.secondColumnFlex}}" fxLayout="row wrap" fxLayoutAlign="stretch start"
          fxLayout.gt-sm="column" fxLayoutAlign.gt-sm="start stretch" fxFlexOrder.lt-md="1" class="w100"
          [class.h100]="!isSmThanTablet" fxLayoutGap="8px">

          <ff-widget *ngFor="let w of dashboardConfig.widgets"
            [fxFlex.gt-sm]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
            [fxShow.lt-md]="!w.hideMobile" [fxFlex.sm]="'calc(' + w.mobileFlex + '% - 8px )'"
            [fxFlexOrder.sm]="w.mobileOrder"
            [widget]="{ type: w.type, data: scrapAnalyticsData, machineProfile: machine.profile, config: w.config, title: w.title }">
          </ff-widget>

        </div>

      </div>

    </div>

    <mat-card fxLayout="column"
      [ngClass]="{'hidePaginator': (pageState.value < 6 || !(machine.profile?.tables | filterByProperty:['showInScrapAnalytics', '==', true])?.length ) }"
      class="w100" style="padding-bottom: 16px; margin-top: 24px">

      <mat-card fxLayout="row" fxLayoutAlign="start center" style="margin-left: 16px;">
        <span class="title">{{ 'CYCLE_TIMELINE.EVENTS_LOG' | translate }}</span>

        <span fxFlex></span>

        <!-- <div class="search-input" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">search</mat-icon>
          <input type="text" [(ngModel)]="searchEvents" (keyup)="filterSearchEvents(searchEvents)"
            placeholder="{{'CONTINUOUS_EXPLORATION.SEARCH_DESCRIPTION' | translate}}" style="min-width: 160px;">
        </div> -->


      </mat-card>

      <table mat-table [dataSource]="eventsData" matSort matSortActive="timestamp" matSortDirection="desc"
        *ngIf="pageState.value >= 6 && events.filtered.length > 0" class="standard-table">

        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let event" class="td-icon-large">
            <div fxLayout="row" fxLayoutAlign="center center">

              <ng-container *ngIf="event?.addImage || event?.image">
                <div>
                  <ff-image-from-be *ngIf="event?.addImage" [config]="event">
                  </ff-image-from-be>
                  <img *ngIf="event?.image" [src]="event.image" alt="" class="clickable"
                    style="height: 50px; border-radius: 5px;" (click)="filterService.openImage(event)">
                </div>
              </ng-container>

              <ng-container *ngIf="event?.image == null && event?.addImage == null">
                <div>
                  <ff-icon *ngIf="event.icon1" [icon]="event.icon1"></ff-icon>
                </div>

                <div>
                  <ff-icon *ngIf="event.icon2" [icon]="event.icon2"></ff-icon>
                </div>
              </ng-container>

            </div>

          </td>
        </ng-container>

        <ng-container *ngFor="let info of tableInfos" matColumnDef="{{info.variable}}">
          <th mat-header-cell *matHeaderCellDef [mat-sort-header]="info.orderBy != null ? info.orderBy : null"
            [disabled]="info.orderBy == null">
            {{info.label|translate}}
          </th>
          <td mat-cell *matCellDef="let event" [ngClass]="{ 'primary': info.primary }">

            <!-- NORMAL COLUMN -->
            <span *ngIf="info.type == null && (info.variable != 'description' || event.eventType == null)"
              class="break-word {{info.class}} {{event[info.variable] != null ? event[info.variable].class : ''}}"
              style="font-size: calc(0.4em + 1vh) !important;">
              {{event[info.variable] | placeholder}}
            </span>

            <!-- STATUS COLUMN -->
            <!-- <ng-md-icon *ngIf="info.type == 'status'" class="{{info.class}} {{event[info.variable].class}}"
              icon="{{info.icon}}">
            </ng-md-icon> -->

            <!-- LIST COLUMN -->
            <span
              *ngIf="(event.eventType == 'colorList' || event.eventType == 'list') && info.variable == 'description'"
              ng-class="{'minWidthListLarge': mobile()}" class="{{info.classes}}">
              <ul class="ff-ul">
                <div *ngIf="event.eventType == 'colorList'">
                  <li *ngFor="let state of event.description" style="padding: 4px 0;">
                    <span class="color-square" [style.background-color]="state.color"
                      style="margin: 0 8px 0 0; padding: 0 9px;"></span>
                    <span>{{ state.value | translate }}</span>
                  </li>
                </div>
                <div *ngIf="event.eventType == 'list'">
                  <li *ngFor="let state of event.description">
                    <span [innerHTML]="state"></span>
                  </li>
                </div>
              </ul>
            </span>

            <span *ngIf="info.type == 'timeState'">
              <div *ngIf="event.stateP != null" fxLayout="row" fxLayoutAlign="center center"
                [style.background-color]="event.stateP.color" class="state-container">
                <span>{{event.stateP.value | translate }}</span>
              </div>
              <!-- <span *ngIf="event.stateP != null" class="color-square" [style.background-color]="event.stateP.color"
                style="padding: 0 9px;"></span> -->
            </span>
            <!-- <span *ngIf="info.prefix != null">{{info.prefix}}&nbsp;</span>
            <span>{{(event[info.variable] != null ? event[info.variable] : '-')}}</span>
            <span *ngIf="info.suffix != null">&nbsp;{{info.suffix}}</span> -->
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="eventsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: eventsColumns;"></tr>
      </table>
      <mat-paginator [ngClass]="{'hidePaginator': (pageState.value < 5 || events.filtered.length == 0) }"
        [length]="events.filtered.length" [pageSizeOptions]="events.pageOptions" [pageSize]="events.pageSize"
        showFirstLastButtons></mat-paginator>

    </mat-card>

  </div>

</div>