<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" aria-label="close">close</mat-icon>
  </button>
</div>
<mat-dialog-content *ngIf="dialog.singleDate == null">

  <mat-form-field appearance="fill" class="interval-selector mb-8">
    <mat-label>{{'INTERVAL.SET_INTERVAL'|translate}}</mat-label>
    <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
      <input matStartDate required formControlName="start" placeholder="Start date">
      <input matEndDate required formControlName="end" placeholder="End date">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
  </mat-form-field>

  <div class="actions" fxLayout="row" fxLayoutAlign="end end">
    <button mat-button [disabled]="!range.value.start || !range.value.end" [mat-dialog-close]="dialog"
      class="filter-button" cdkFocusInitial>{{'GLOBAL.SELECT'|translate}}</button>
  </div>

</mat-dialog-content>
<mat-dialog-content *ngIf="dialog.singleDate">

  <mat-form-field appearance="fill" class="w100">
    <mat-label>{{'INTERVAL.SET_INTERVAL'|translate}}</mat-label>
    <input matInput [matDatepicker]="picker">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <!-- <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
    <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error> -->
  </mat-form-field>

  <!-- <pre>{{range}}</pre> -->

  <div fxLayout="row" fxLayoutAlign="end end">
    <button mat-button [disabled]="!range.value.start" [mat-dialog-close]="dialog" class="filter-button"
      cdkFocusInitial>{{'GLOBAL.SELECT'|translate}}</button>
  </div>

</mat-dialog-content>