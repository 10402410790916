import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CacheService } from 'src/app/services/cache.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'ff-interval-selector',
  templateUrl: './interval.component.html',
  styleUrls: ['./interval.component.scss']
})

export class IntervalSelectorComponent implements OnInit, OnChanges, OnDestroy {

  @Input() config: any = {};
  @Input() intAggrs?: any;
  @Input() clickableDate?: any = false;
  @Input() hideStart?: any = false;
  @Input() hideIntervalSelector?: any = false;
  @Input() intervalLabel?: any = "INTERVAL.START_END";
  @Input() customAggregations?: any;
  @Input() fullWidthStart?: any;
  @Output() selected = new EventEmitter<any>();
  @Output() selectedAggr = new EventEmitter<any>();

  public selectedIdSub: Subscription;
  public selectedId: string = "";
  public selectedAggrId: string = "";

  constructor(
    public translate: FfTranslateService,
    public intervalService: IntervalService,
    public cacheService: CacheService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
  ) {

    this.selectedIdSub = this.internalDataService.selectedIntervalId.subscribe(value => {
      if (value != null) {
        this.selectedId = value.id;
        this.config.selected = value;
        this.hideIntervalAggregationsAccordingToNumberOfDays();
      }
    });

  }

  selectionChange(selId: string) {
    if (selId == 'custom') this.parseCustomInterval();
    else {
      this.config.list = this.intervalService.createIntervals(this.config.list);
      let ix = this.config.list.findIndex((x: any) => x.id === selId);
      if (ix != -1) {
        let sel = this.config.list[ix];
        this.config.selected = sel;
        this.selected.emit(sel);
      }
    }

    this.hideIntervalAggregationsAccordingToNumberOfDays();
  }

  aggrSelectionChange(aggr) {

    let selId = aggr?.id;

    if (!aggr?.hide) {
      let ix = this.intAggrs.list.findIndex((x: any) => x.id === selId);
      if (ix != -1) {
        let sel = this.intAggrs.list[ix];
        this.intAggrs.selected = sel;
        this.cacheService.set("intervalAggregation", sel);
        this.selectedAggr.emit(sel);
      }
    }

    this.hideIntervalsAccordingToNumberOfDays();

  }

  selectCustomInterval() { if (this.clickableDate) this.parseCustomInterval() }

  parseCustomInterval() {
    this.config.list = this.intervalService.createIntervals(this.config.list);
    let ix = this.config.list.findIndex((x: any) => x.id === 'custom');
    if (ix != -1) this.selected.emit(this.config.list[ix]);
  }

  selectAll() {
    this.config = this.config ?? {};
    if (this.config?.selected?.id != null) this.selectedId = this.config.selected.id;
    if (this.intAggrs?.selected?.id != null) this.selectedAggrId = this.intAggrs.selected.id;

    this.hideIntervalAggregationsAccordingToNumberOfDays();
    this.hideIntervalAggregationsAccordingToCalendarEnabled();
    this.hideIntervalsAccordingToNumberOfDays();
  }

  hideIntervalAggregationsAccordingToCalendarEnabled() {
    if (this.customAggregations) return;
    try {

      let isEnabledCalendar = this.filterService.isActiveCalendar();

      this.intAggrs.list = this.intAggrs.list?.reduce((acc, val) => {

        if (val.id === 'shift' && !isEnabledCalendar) {
          val.hide = true;
        }

        acc.push(val);
        return acc;
      }, [])
    } catch (error) {

    }
  }

  hideIntervalAggregationsAccordingToNumberOfDays() {

    if (this.customAggregations) return;
    try {
      this.intAggrs.list = this.intAggrs.list?.reduce((acc, val) => {

        if (this.config.selected.numberOfDays != null && (val.maxDays != null && val.maxDays < this.config.selected.numberOfDays)) {
          val.hide = true;
        } else {
          val.hide = false;
        }
        acc.push(val);
        return acc;
      }, [])
    } catch (error) {

    }
  }

  hideIntervalsAccordingToNumberOfDays() {

    if (this.intAggrs?.selected == null || this.customAggregations) {
      if (this.intAggrs?.selected == null && this.config.list?.length > 0) {
        this.config.list = this.config.list?.reduce((acc, val) => {
          val.hide = false;
          acc.push(val);
          return acc;
        }, []);
      }
      return;
    }

    try {

      this.config.list = this.config.list?.reduce((acc, val) => {
        val.hide = this.intAggrs.selected.maxDays != null && val.numberOfDays > this.intAggrs.selected.maxDays;
        acc.push(val);
        return acc;
      }, [])
    } catch (error) {

    }
  }

  ngOnInit() { this.selectAll() }
  ngOnChanges() { this.selectAll() }
  ngOnDestroy(): void { }

}
