import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';


declare let videojs: any

@Component({
  selector: 'app-last-video-streaming',
  templateUrl: './last-video-streaming.component.html',
  styleUrls: ['./last-video-streaming.component.scss']
})
export class LastVideoStreamingComponent implements OnInit, OnDestroy {

  public isAllowedUser: boolean = true;
  public noVideoAvailable: boolean = false;

  public pastVideosStreamings: any;
  public slider: any;
  public currentTime: any;
  public playerState: any = 0;
  public streamEnd: any;
  public streamStart: any;
  public streamStartHHmmSS: any;
  public streamEndUnparsed: any;

  public videoIds: any;
  public timeStart: any;
  public timeEnd: any;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public isMobile: any;
  public machineProfiles: any;

  public backButton: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public pollingVideoState: any;

  public pollingTime: any;
  public pollingEvents: any;

  public maxStringsLength: any;
  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;

  public excludeLine: boolean = true;

  public interval: any;
  public intervalConfig: any;

  public dashboardConfig: any;

  public dashboardData: any;

  public mobileData: any;
  public currentSortingProperty: any;
  public sortDirection: any;
  public collapsed: any;

  public tabName: any = "lastVideoStreaming";
  public sectionName: any = "videoStreaming";
  public dashboardName: any = "session-detail";
  public pollingPropName: any = "pollingTime";
  public dashboardNameComplete: any;

  public componentId: any;
  public edgeVideoStreaming: any = false;
  public defaultEdgeIntervalId: any = 'last30Minutes';

  public pollingSubscription: Subscription;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getLastVideo, nextState: 4, loadingMsg: 'LOADING.VIDEOS' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.startStreaming, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
  ) {

    this.isMobile = window.innerWidth <= 959;

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.edgeVideoStreaming = this.appInfo?.edgeVideoStreaming;

    this.breadcrumb = ['VIDEO_STREAMING.TITLE', 'LAST_VIDEO.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig?.[this.tabName]?.[this.pollingPropName] ?? 5000;

    this.dashboardData = null;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-vs-last-video");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  getLastVideo(_this: any, count?: any) {
    try {

      let url = '/apif/video-streaming/last-video/' + _this.machineId;

      let query: any = {
        tz: _this.machine.timezone,
      };

      _this.apiService.sendGetRequest(url, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.pastVideos = _this.clonerService.deepClone(data.body);

            if (data?.body?.length == 0) {
              _this.noVideoAvailable = true;
              _this.dispatcherService.getDispatch(_this, 300);
              return;
            }

            // _this.pastVideos = pastVideos?.reduce((acc, val) => {
            //   val.id = val.videoId;
            //   acc.push(val);
            //   return acc;
            // }, [])

            try {
              let firstVideo = _this.pastVideos.map(x => x.timeStart).sort()[0];
              _this.timeStart = _this.filterService.parseMoment(firstVideo, 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone);
              _this.streamStart = _this.filterService.parseMoment(firstVideo, 'DD/MM/YYYY HH:mm:ss', _this.machine.timezone);
              _this.streamStartHHmmSS = _this.filterService.parseMoment(firstVideo, 'HH:mm:ss', _this.machine.timezone);

              let eventId = _this.pastVideos[0].eventId;
              _this.eventConfig = _this.machine.profile.videoEventTypes.find(x => x.id == eventId) ?? {
                label: eventId
              };

            } catch (error) {
              console.log(error);
              _this.streamStart = null;
            }

            _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // START STREAMING

  play() {
    this.playerState = 1;
    this.stopPollingData();
    this.playStopVideos();
    this.updateSliderPolling();
  };

  pause() {
    this.playerState = 0;
    try {
      this.pastVideosStreamings.forEach(video => video.player.pause());
    } catch (error) { console.log(error) }
    this.stopPollingData();
  };

  setSliderValues() {

    let _this = this;

    this.slider = {
      value: 0,
      min: _this.slider != null && _this.slider.min != null && !isNaN(_this.slider.min) ? _this.slider.min : 0,
      options: {
        showSelectionBar: true,
        floor: 0,
        ceil: moment(this.streamEndUnparsed).diff(_this.timeStart, 'seconds'),
        translate: function (value) {
          return (value != null && !isNaN(value)) ? _this.filterService.parseMoment(moment(_this.timeStart).add(value, 'seconds'), 'HH:mm:ss', _this.machine.timezone) : _this.streamStartHHmmSS;
          return (value != null && !isNaN(value)) ? _this.filterService.parseTime(value, 's', 'HH:mm:ss') : '00:00:00';
        },
      },
      userChangeEnd: function (slider) {
        _this.slider.min = slider?.value;
        _this.currentTime = _this.filterService.parseMoment(moment(_this.timeStart).add(slider?.value, 'seconds'), 'DD/MM/YYYY HH:mm:ss', _this.machine.timezone);
        _this.playStopVideos();
      },
      valueChange: function (slider) {
        _this.playStopVideos(true);
      }
    }

  }

  playStopVideos(onlyPaused: any = false) {

    this.currentTime = this.filterService.parseMoment(moment(this.timeStart).add(this.slider.min, 'seconds'), 'DD/MM/YYYY HH:mm:ss', this.machine.timezone);
    if (this.playerState == 1) {
      this.pastVideosStreamings.filter(x => !onlyPaused ? true : x.player.paused()).forEach(video => {
        try {
          if (moment(moment(this.timeStart).add(this.slider.min, 'seconds')).diff(video.timeStart, 'seconds') >= 0) {
            video.player.play();
            video.player.currentTime(moment(moment(this.timeStart).add(this.slider.min, 'seconds')).diff(video.timeStart, 'seconds'));
          } else {
            if (!onlyPaused) {
              video.player.currentTime(0);
              video.player.pause();
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
    }
  }
  startStreaming(_this: any) {

    _this.pastVideosStreamings = _this.clonerService.deepClone(_this.pastVideos);
    setTimeout(() => {
      (_this.setVideos(_this), 50);
      _this.dispatcherService.getDispatch(_this, 300);
    });

  }

  setVideos(_this: any) {
    if (_this.pastVideosStreamings != null && _this.pastVideosStreamings.length > 0) {

      let loadingVideos = 0;

      // AZURE
      if (!_this.edgeVideoStreaming) {
        _this.pastVideosStreamings.forEach(video => {
          var myOptions = {
            nativeControlsForTouch: false,
            // controls: _this.pastVideosStreamings.length == 1,
            controls: false,
            autoplay: false,
            // muted: true,
            // enableFullscreen: true,
          };
          video.player = amp(video.id, myOptions, function () {
            this.addEventListener('play', function () {
              // console.log('Play clicked!');
            });
            this.addEventListener('loadeddata', function () {
              // console.log('Loaded data!');
              if (_this.streamEndUnparsed == null) {
                _this.streamEndUnparsed = _this.filterService.parseMoment(moment(video.timeStart).add(video.player.duration(), 'seconds'), 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone);
              } else {
                if (moment(_this.filterService.parseMoment(moment(video.timeStart).add(video.player.duration(), 'seconds'), 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone))
                  .diff(_this.streamEndUnparsed, 'seconds') > 0) {
                  _this.streamEndUnparsed = _this.filterService.parseMoment(moment(video.timeStart).add(video.player.duration(), 'seconds'), 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone);
                }
              }
              _this.streamEnd = _this.filterService.parseMoment(_this.streamEndUnparsed, 'DD/MM/YYYY HH:mm:ss', _this.machine.timezone);
              _this.setSliderValues();
              loadingVideos++;
              if (loadingVideos >= _this.pastVideosStreamings.length) _this.notLoadedVideos = false;
            });
            // console.log('Good to go!');
            // this.addEventListener('ended', function () {
            //     console.log('Finished!');
            // })
          }).src([{
            src: video.url,
            type: "application/vnd.ms-sstr+xml",
            protectionInfo: [{
              type: "AES",
              authenticationToken: video.bearerToken
            }]
          }]);
        });
      }

      // EDGE
      else {
        _this.pastVideosStreamings.forEach(video => {

          console.log({ video });

          if (video.player == null) {
            video.player = videojs(video.id, {}, () => { });

            if (_this.streamEndUnparsed == null) {
              _this.streamEndUnparsed = _this.filterService.parseMoment(moment(video.timeStart).add(video.duration, 'seconds'), 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone);
            } else {
              if (moment(_this.filterService.parseMoment(moment(video.timeStart).add(video.duration, 'seconds'), 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone))
                .diff(_this.streamEndUnparsed, 'seconds') > 0) {
                _this.streamEndUnparsed = _this.filterService.parseMoment(moment(video.timeStart).add(video.duration, 'seconds'), 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone);
              }
            }
          }
          else {

            video.player.pause();

            setTimeout(() => {
              video.player.src({ type: 'application/x-mpegURL', src: 'streaming/' + video.name + '/stream.m3u8' });
              video.player.play();
            }, 500);
          }
        });

        _this.streamEnd = _this.filterService.parseMoment(_this.streamEndUnparsed, 'DD/MM/YYYY HH:mm:ss', _this.machine.timezone);
        _this.setSliderValues();
        _this.notLoadedVideos = false;
      }
    }
  }


  updateSliderPolling() {

    let _this = this;

    if (_this.pollingTime > 0) _this.pollingSubscription = timer(0, _this.pollingTime).subscribe((count) => {

      try {
        _this.slider.min = isNaN(_this.slider.min) ? 0 : _this.slider.min;
        if ((_this.slider.min + _this.pollingTime / 1000) > _this.slider.options.ceil) {
          _this.stopPollingData();
          _this.playerState = 0;
          _this.slider.min = _this.slider.options.ceil;
          return;
        }
        if (count > 0) _this.slider.min += _this.pollingTime / 1000;

        let diffFromStart = _this.slider.min;
        let secondsFromStart = moment(_this.timeStart).add(diffFromStart, 'seconds');

        _this.timeEnd = _this.filterService.parseMoment(secondsFromStart, 'YYYY-MM-DDTHH:mm:ssZ', _this.machine.timezone);
        _this.currentTime = _this.filterService.parseMoment(secondsFromStart, 'YYYY/MM/DD HH:mm:ss', _this.machine.timezone);

      } catch (error) {
        console.log(error);
      }
    });
  }

  stopPollingData() {
    try { this.pollingSubscription.unsubscribe() } catch (error) { }
  }

  calculateHeight(videoId) {
    try {
      let element = document.getElementById(videoId);
      if (element != null) return (element.offsetWidth * 9 / 16 - 16);
    } catch (error) { console.log(error) }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingSubscription.unsubscribe() } catch (error) { }
    try { this.pollingVideoState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try {
      this.pastVideosStreamings.forEach(video => {
        try { video.player.dispose() } catch (error) { }
      });
    } catch (error) { }
  }

}