import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ff-smiley-face',
  templateUrl: './smiley-faces.component.html',
  styleUrls: ['./smiley-faces.component.scss']
})
export class SmileyFacesComponent implements OnInit {

  @Input() widget: any;

  thresholds: any = [];
  config: any;
  defaultConfig: any = {
    "color": "#BFCACF",
    "icon": {
      "icon": "sad_face",
      "type": "svg"
    }
  };

  constructor() { }

  ngOnInit(): void {
    try {

      this.thresholds = this.widget?.config.thresholds ?? this.thresholds;

      let v = this.widget?.data?.[this.widget?.config?.variable];

      if (v == null) {
        this.config = this.widget?.config?.default ?? this.defaultConfig;
        return;
      }

      this.config = this.thresholds?.reduce((acc, val) => {
        if (acc != null) return acc;
        if (v < val.max && v >= val.min) acc = val;
        return acc;
      }, null);

      if (this.config == null) this.config = this.defaultConfig;
    } catch (error) {
      console.log(error);
      this.config = this.defaultConfig
    }

  }

}
