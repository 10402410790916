<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <ng-container *ngIf="!isSmThanTablet">

      <button *ngIf="showResetInterval" mat-button class="filter-button" aria-label="remove filters"
        (click)="resetDefaultInterval()">
        <mat-icon class="icon">refresh</mat-icon>
        <span style="padding-left: 2px;">{{'CONTINUOUS_EXPLORATION.RESET_INTERVAL' | translate}}</span>
      </button>

      <!-- change component -->
      <button mat-button class="filter-button"
        (click)="filterService.openComponentSelectionDialog(this, 'continuous-exploration')">
        <span>{{ 'HEALTH_MONITORING.CHANGE_COMPONENT' | translate }}</span>
      </button>

      <!-- intervals -->
      <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>
    </ng-container>

  </div>

  <!-- toolbar tabs -->
  <div *ngIf="isSmThanTablet" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <button *ngIf="showResetInterval" mat-button class="filter-button" aria-label="remove filters"
      (click)="resetDefaultInterval()">
      <mat-icon class="icon">refresh</mat-icon>
      <span style="padding-left: 2px;">{{'CONTINUOUS_EXPLORATION.RESET_INTERVAL' | translate}}</span>
    </button>

    <!-- change component -->
    <button mat-button class="filter-button"
      (click)="filterService.openComponentSelectionDialog(this, 'continuous-exploration')">
      <span>{{ 'HEALTH_MONITORING.CHANGE_COMPONENT' | translate }}</span>
    </button>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar tabs -->
    <div *ngIf="pageState.value >= 7" class="ff-toolbar pt-1 pb-3" fxLayout="column" fxLayoutAlign="start start"
      fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <!-- variables and components selection -->
      <button mat-button (click)="openVariablesSelectionDialog()" class="filter-button" fxLayout="row"
        fxLayoutAlign="center center">
        {{ 'HEALTH_MONITORING.VARIABLES_SELECTION' | translate }}
        <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
          style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
        </mat-icon>
      </button>

      <!-- aggregations -->
      <div *ngIf="aggregations != null && aggregations.length > 0" fxLayout="column" fxLayoutAlign="start start"
        fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">
        <button mat-button class="filter-button" *ngFor="let aggr of aggregations" (click)="openAggrDialog(aggr)"
          [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
          <mat-icon *ngIf="aggr.icon != null && aggr.icon.type == 'icon'" class="icon"
            translate-attr="{'aria-label': aggr.label}">
            {{aggr.icon.icon }}</mat-icon>
          <mat-icon *ngIf="aggr.icon != null && aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
            translate-attr="{'aria-label': aggr.label}"></mat-icon>
          <i *ngIf="aggr.icon != null && (aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor')"
            class="icon {{ aggr.icon.icon }}" translate-attr="{'aria-label': aggr.label}"></i>
          <span>{{ aggr.selectionLabel }}</span>
          <div fxLayout="row" fxLayoutAlign="center center"
            *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
            {{ aggregationsPayload[aggr.id].length }}
          </div>
        </button>
      </div>

      <span fxFlex></span>

      <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 12px;">

        <button class="mat-button rounded-button" (click)="getPreviousBucket()" style="margin-right: 8px;"
          #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.PREVIOUS_BUCKET' | translate }}"
          matTooltipPosition="above">
          <mat-icon svgIcon="arrow_left" class="icon"></mat-icon>
        </button>

        <button class="mat-button rounded-button" (click)="getUnzoomedBucket()" style="margin-right: 8px;"
          #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.UNZOOMED_BUCKET' | translate }}"
          matTooltipPosition="above">
          <mat-icon class="icon side">remove</mat-icon>
        </button>

        <button class="mat-button rounded-button" (click)="getZoomedBucket()" style="margin-right: 8px;"
          #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.ZOOMED_BUCKET' | translate }}"
          matTooltipPosition="above">
          <mat-icon class="icon side">add</mat-icon>
        </button>

        <button class="mat-button rounded-button" (click)="getNextBucket()" [disabled]="interval.enabledPolling"
          #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.NEXT_BUCKET' | translate }}" matTooltipPosition="above">
          <mat-icon svgIcon="arrow_right" class="icon"></mat-icon>
        </button>

      </div>

    </div>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < 8" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 8" fxLayout="column" class="w100 h100">

      <div fxLayout="column" fxLayoutAlign="center start" class="h100 w100" fxLayoutGap="8px">

        <div fxFlex="70" fxLayout="row" fxLayoutAlign="start stretch" class="w100 h100" fxLayoutGap="8px"
          style="position: relative;">
          <ff-widget fxFlex
            [widget]="{type: 'ff-plotly-chart', data: dashboardData, config: {'plotDataAttribute': 'plotData'}, customHeight: '100%', customMinHeight: '100%' }">
          </ff-widget>
          <div fxFlex="10" class="hm-container">

            <ff-health-bar [widget]="'hello'"></ff-health-bar>

          </div>
        </div>

        <div fxFlex="30" fxLayout="row" fxLayoutAlign="start stretch" class="h100 w100"
          style="background-color: white; border-radius: 25px;" fxLayoutGap="8px">
          <ff-widget *ngFor="let w of dashboardConfig.widgets" fxFlex="{{w.flex}}"
            [widget]="{ data: dashboardData, type: w.type, subtype: w.subtype, class: w.class, machineProfile: machine.profile, config: w.config, title: w.title }">
          </ff-widget>
        </div>

      </div>
    </div>

  </div>

</div>