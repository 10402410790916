interface String {
    capitalize(): string;
}

String.prototype.capitalize = function (): any {

    try {
        return this.replace(this[0], this[0].toUpperCase());
    } catch (error) {
        console.log(error);
    }

    return this;

}