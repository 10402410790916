<span class="widget-value widget-value-title" *ngIf="widget.title != null" fxLayout="row" fxLayoutAlign="start center"
  [class.w100]="widget.icon2 != null">

  <div *ngIf="widget.icon != null" class="icon-box" [ngClass]="widget.iconClass" fxLayout="row"
    fxLayoutAlign="center center">
    <ff-icon [icon]="widget.icon" [left]="true"></ff-icon>
  </div>

  <span>{{ widget.title | translate }}</span>

  <span *ngIf="widget.unit != null">&nbsp;[{{ filterService.convertUnit(widget.unit).unit }}]</span>

  <span *ngIf="widget.tooltip != null" matTooltip="{{ widget.tooltip | translate }}" matTooltipPosition="above"
    style="margin-left: 6px;">
    <mat-icon class="icon">info</mat-icon>
  </span>

  <span fxFlex></span>

  <div *ngIf="widget.icon2 != null" class="icon-box" [ngClass]="widget.icon2Class"
    [class.clickable]="widget.icon2ClickConfig" (click)="onIcon2Click(widget?.icon2ClickConfig)" fxLayout="row"
    fxLayoutAlign="center center">
    <ff-icon [icon]="widget.icon2" [right]="true"></ff-icon>
  </div>

</span>

<div *ngIf="compressed" fxLayout="row" fxLayoutGap="8px" class="w100">
  <div *ngFor="let elem of widget.infos" [ngClass]="elem.class" class="widget-value compressed" fxLayout="row"
    fxLayoutAlign="center center">
    <ff-icon *ngIf="elem.icon" [icon]="elem.icon" [left]="true" matTooltip="{{ elem.tooltip | translate }}"></ff-icon>
    <span *ngIf="elem.label">{{ (elem?.label != null ? elem.label : '-') | translate }}</span>
    <span *ngIf="elem.value">:&nbsp;<strong>{{ elem.value }}</strong></span>
    <span *ngIf="elem.unit">&nbsp;{{ elem.unit }}</span>
    <ff-icon *ngIf="elem.icon2" [icon]="elem.icon2" [right]="true"></ff-icon>

    <ng-container *ngIf="elem.buttons?.length">

      <div marginLeft>

        <button *ngFor="let info of elem.buttons" [ngClass]="info.class" (click)="buttonClick(elem, info)"
          [ngStyle]="{'display': info.defaultHide && elem.hideButtons == null ? 'none' : (elem.hideButtons != null ? (elem.hideButtons[info.clickFunction] ? 'none' : 'block') : 'block')}"
          [disabled]="info.defaultDisabled && elem.disabledButtons == null ? true : (elem.disabledButtons != null ? elem.disabledButtons[info.clickFunction] : false)"
          matTooltip="{{ info.tooltip | translate }}" matTooltipPosition="above">
          <mat-icon *ngIf="(elem.iconButtons != null && elem.iconButtons[info.clickFunction] != null) || info?.icon"
            class="icon">
            {{ elem.iconButtons?.[info.clickFunction] != null ? elem.iconButtons?.[info.clickFunction] :
            info?.icon }}
          </mat-icon>
          <span *ngIf="info.label">{{ info.label | translate }}</span>

        </button>
      </div>

    </ng-container>

  </div>
</div>

<div *ngIf="!compressed" class="w100 h100" [fxLayout]="fxLayoutBody()" [fxLayoutAlign]="fxLayoutAlignBody()"
  [fxLayoutGap]="fxLayoutGap()">

  <div *ngIf="widget.infos == null || widget.infos.length == 0" class="data-not-found" fxLayout="row"
    fxLayoutAlign="center center">{{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}
  </div>

  <div *ngFor="let elem of widget.infos" (click)="dpClick(elem)"
    [fxFlex.gt-sm]="widget.innerFlex != null ? widget.innerFlex : 50"
    class="label widget-value {{elem.action != null ? 'clickable' : '' }} sub-{{widget.subtype}}" [ngClass]="elem.class"
    fxLayout="row" fxLayoutAlign="center center">

    <div class="widget-value box2 w100" fxLayout="row" fxLayoutAlign="start center" [ngClass]="elem.boxClass">

      <!-- icon -->
      <div *ngIf="elem.icon != null" class="icon-box" [ngClass]="elem.iconClass" [ngStyle]="elem.style">
        <mat-icon *ngIf="elem.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': elem.label}"
          [ngStyle]="elem.style">
          {{elem.icon.icon }}</mat-icon>
        <mat-icon *ngIf="elem.icon.type == 'svg'" svgIcon="{{ elem.icon.icon }}" class="icon" [ngStyle]="elem.style"
          translate-attr="{'aria-label': elem.label}"></mat-icon>
        <i *ngIf="elem.icon.type == 'fontawesome' || elem.icon.type == 'phosphor'" class="icon {{ elem.icon.icon }}"
          [ngStyle]="elem.style" translate-attr="{'aria-label': elem.label}"></i>
      </div>

      <!-- content (default subtype: 'column-icon-label-value') -->
      <!-- content (subtype: 'horizontal') -->
      <div
        *ngIf="(!widget.subtype || widget.subtype == 'horizontal') && elem.type != 'progressBar' && elem.subtype != 'monitor'"
        class="content w100" fxLayout="column" fxLayoutAlign="center start">
        <div *ngIf="elem.label != null" class="title-box">
          {{ elem.label | translate }}
          <span *ngIf="elem.tooltip != null" matTooltip="{{ elem.tooltip | translate }}" matTooltipPosition="above"
            style="margin-left: 6px;">
            <mat-icon class="icon">info</mat-icon>
          </span>
        </div>
        <div class="value w100" fxLayout="row">
          <span>{{ elem.value != null ? elem.value : '-' }}</span>
          <span *ngIf="elem.unit != null">&nbsp;{{ elem.unit | translate }}</span>
          <span *ngIf="elem.suffix != null && elem.unit == null">&nbsp;{{ elem.suffix | translate }}</span>
          <span fxFlex></span>
          <span *ngIf="elem.value2 != null">{{ elem.value2 }}</span>
        </div>
      </div>

      <!-- content (subtype: 'row-icon-label-value') -->
      <div *ngIf="widget.subtype == 'row-icon-label-value'" class="content" fxLayout="row"
        fxLayoutAlign="space-between stretch">
        <div *ngIf="elem.label != null" class="widget-value title">{{ elem.label | translate }}</div>
        <div class="value">
          <span>{{ elem.value != null ? elem.value : '-' }}</span>
          <span *ngIf="elem.unit != null">&nbsp;{{ elem.unit | translate }}</span>
          <span *ngIf="elem.suffix != null && elem.unit == null">&nbsp;{{ elem.suffix | translate }}</span>
        </div>
      </div>

      <div *ngIf="elem.type == 'progressBar'" class="content w100">
        <div *ngIf="elem.label != null" class="widget-value title">{{ elem.label | translate }}</div>
        <div *ngIf="elem.fixedColor == null">
          <kpi-bar [kpiProps]="{ 'value': elem.value, 'hideLabel': true, 'revert': elem.revert }"></kpi-bar>
        </div>
        <div *ngIf="elem.fixedColor != null">
          <kpi-bar
            [kpiProps]="{ 'value': elem.value, 'hideLabel': true, fixedColor: elem.fixedColor, 'revert': elem?.revert }">
          </kpi-bar>
        </div>
      </div>

      <div *ngIf="elem.subtype == 'monitor'" class="content w100">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex *ngIf="elem.label != null" class="widget-value title">{{ elem.label | translate }}</div>
          <div fxFlex="10" *ngIf="elem.fixedColor == null">
            <ff-monitor [props]="{ 'value': elem.value, colorConfigs: elem.colorConfigs }"></ff-monitor>
          </div>
          <div fxFlex="10" *ngIf="elem.fixedColor != null">

          </div>
        </div>
      </div>

      <!-- icon2 -->
      <div *ngIf="elem.icon2 != null" class="icon-box right">
        <mat-icon *ngIf="elem.icon2.type == 'icon'" class="icon" [ngClass]="elem.icon2Class"
          translate-attr="{'aria-label': elem.label}">
          {{elem.icon2.icon }}</mat-icon>
        <mat-icon *ngIf="elem.icon2.type == 'svg'" svgIcon="{{ elem.icon2.icon }}" class="icon"
          [ngClass]="elem.icon2Class" translate-attr="{'aria-label': elem.label}"></mat-icon>
        <i *ngIf="elem.icon2.type == 'fontawesome' || elem.icon2.type == 'phosphor'" class="icon {{ elem.icon2.icon }}"
          [ngClass]="elem.icon2Class" translate-attr="{'aria-label': elem.label}"></i>
      </div>

    </div>

  </div>
</div>