<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" aria-label="close">close</mat-icon>
  </button>
</div>
<mat-dialog-content>

  <div *ngFor="let elem of dialog.timeStateList" fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 8px;">
    <mat-icon *ngIf="elem.enabled" svgIcon="radio_button" class="icon small left clickable radio_button"
      (click)="elem.enabled = !elem.enabled">
    </mat-icon>
    <mat-icon *ngIf="!elem.enabled" svgIcon="radio_button_disabled" (click)="elem.enabled = !elem.enabled"
      class="icon small left clickable radio_button_disabled"></mat-icon>
    <div class="state-container clickable" [style.background-color]="elem.color" fxLayout="row"
      (click)="elem.enabled = !elem.enabled" fxLayoutAlign="center center">
      <span style="text-align: center;">{{ elem.value | translate }}</span>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <!-- <button mat-button mat-dialog-close>
        {{'GLOBAL.CANCEL'|translate}}
      </button> -->
  <button mat-button [mat-dialog-close]="dialog" cdkFocusInitial class="filter-button">
    {{'GLOBAL.SELECT'|translate}}
  </button>
</mat-dialog-actions>
