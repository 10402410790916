import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { ComponentVariablesSelectionComponent } from '../../machine-recorder/component-variables-selection/component-variables-selection.component';
import { MobileService } from 'src/app/services/mobile.service';


@Component({
  selector: 'app-hm-continuous-exploration',
  templateUrl: './hm-continuous-exploration.component.html',
  styleUrls: ['./hm-continuous-exploration.component.scss']
})
export class HmContinuousExplorationComponent implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public backButton: any;
  public tabs: any;

  public HMComponentSelectedSub: Subscription;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public pollingTime: any;
  public pollingEvents: any;

  public aggregations: any;
  public availableMachines: any;

  public dashboardConfig: any;

  public defaultInterval: any;
  public interval: any;
  public intervalConfig: any;

  public componentConfig: any = {};
  public componentId: any;
  public machineRefId: any;

  public sectionName: any = "healthMonitoringDetail";
  public dashboardName: any = "health-continuous-exploration";

  public dashboardData: any = {};

  public barColors: any;

  public showResetInterval: boolean = false;
  public zoomedIntervalVariablesSub: Subscription;

  public plotsConfig: any;
  public plotsConfigComponents: any;
  public variables: any = {
    list: [],
    selected: []
  };

  public cachedIntervalId: any = "intervalHM";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getComponentsConfig, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getVariablesConfig, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 },
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.getHealthTraceabilityPolling, nextState: 7, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];
  
  public isMobile: any;
  public isSmThanTablet: any;
  public mobileListener: Subscription;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public mobile: MobileService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));
    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile;
      this.isSmThanTablet = value.isSmThanTablet;
    })

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['HEALTH_MONITORING.TITLE', 'HEALTH_TREND.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb[2] = value.machineName;
        this.breadcrumb = newBreadcrumb;
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.HMComponentSelectedSub = this.internalDataService.HMComponentSelected.subscribe(value => {
      if (value != null) {
        this.breadcrumb = this.internalDataService.getHMBreadcrumb(this, value);
        this.internalDataService.setBreadcrumb(this.clonerService.deepClone(this.breadcrumb));
      }
    });

    this.pollingTime = 0;
    this.pollingEvents = Subscription;

    this.barColors = this.internalDataService.defaultPlotlyColors;

    this.zoomedIntervalVariablesSub = this.internalDataService.zoomedIntervalVariables.subscribe(value => {
      if (value != null) {

        this.showResetInterval = false;

        this.pageState.next(7);
        this.getHealthTraceabilityPolling(this, value);

      }
    });
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
    } catch (error) {
      console.log(error);
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET COMPONENTS CONFIG

  getComponentsConfig(_this: any) {

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getComponentsConfig(_this, _this.machineId, _this.machineRefId, _this.componentId, true);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET VARIABLES CONFIG

  getVariablesConfig(_this: any) {
    try {

      _this.componentsList = _this.machine.components.map((x: any) => {
        return {
          label: _this.internalDataService.parseComponentsLabel(x, null, _this.machineId),
          id: x
        }
      });

      _this.internalDataService.getVariablesConfig(_this, _this.machineId, _this.machineRefId, _this.componentId, false, false, true);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getHealthTraceabilityPolling, this.getHealthTraceability, this.machine.timezone, 6, 7) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SYNOPTIC CONFIG

  // polling
  getHealthTraceabilityPolling(_this: any, range: any = null) {
    try {

      if (range == null) {
        if (_this.cacheService.get(_this.cachedIntervalId) == null) {

          _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

          _this.intervalConfig = {
            list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
            selected: _this.interval
          };

          _this.cacheService.set(_this.cachedIntervalId, _this.clonerService.deepClone(_this.intervalConfig));

        } else {
          _this.intervalConfig = _this.clonerService.deepClone(_this.cacheService.get(_this.cachedIntervalId));
          _this.interval = _this.intervalConfig.selected;
        }
      }

      if (_this.defaultInterval == null) _this.defaultInterval = _this.clonerService.deepClone(_this.interval);

      if (_this.pollingTime > 0) _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => _this.getHealthTraceability(_this, count, range));
      else _this.getHealthTraceability(_this, 0, range);

    } catch (error) {
      console.log(error);
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getHealthTraceability(_this: any, count?: any, range?: any) {
    try {

      let url = 'apif/variables-exploration/' + _this.machineId + '/' + _this.componentId;


      if (range != null) {
        if (range.hasOwnProperty('from') && range.from != null && range.hasOwnProperty('to') && range.to != null) {
          console.log('range', range);

          _this.showResetInterval = true;
          _this.interval.start = range.from;
          _this.interval.end = range.to;
          _this.intervalService.formatTimezone(_this.interval, _this.machine.timezone);
          console.log(_this.interval);


          // _this.interval.startDate = moment.tz(range.from, _this.machine.timezone).toDate(); //.add(100, 'ms').toDate();
          // _this.interval.endDate = moment.tz(range.to, _this.machine.timezone).toDate(); //.add(100, 'ms').toDate();
          // _this.interval.startP = moment.tz(range.from, _this.machine.timezone).format('MMM DD, YYYY - HH:mm:ss')
          // _this.interval.endP = moment.tz(range.to, _this.machine.timezone).format('MMM DD, YYYY - HH:mm:ss')
          // _this.interval.start = JSON.parse(JSON.stringify(_this.interval.startDate));

          // let lastEnd = _this.interval.end;
          // _this.interval.end = JSON.parse(JSON.stringify(_this.interval.endDate));

          // if (lastEnd != _this.interval.end) _this.interval.enabledPolling = false;

        } else {
          _this.showResetInterval = false;
          _this.interval = _this.clonerService.deepClone(_this.defaultInterval);
        }
      }

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone,
        anomalyDetection: true,
      };

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      let payload: any = {
        variables: _this.variables.selected,
      };

      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      _this.apiService.sendPostRequest(url, payload, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.dashboardData = Object.assign(_this.clonerService.deepClone(_this.componentConfig), data.body);
            if (data.body.hasOwnProperty('dataAD') && Object.keys(data.body.dataAD).length > 0) {
              _this.dashboardData = Object.assign(_this.dashboardData, _this.parseADData(data.body.dataAD));
            }

            _this.dashboardData.dataConfig = {
              noDataPolicy: 'plot-axis',
              noDataLabel: _this.translate.instant('GLOBAL.NO_DATA_AVAILABLE'),
            };

            _this.dashboardData.plotData = _this.buildPlotConfig(_this, _this.dashboardData);

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);

          }
        );

    } catch (error) {
      console.log(error);
    }

  }

  parseADData(data: any) {
    console.log({ data });
    data.timeStart = this.interval.start;
    data.timeEnd = this.interval.end;
    data.timeStartP = this.interval.startF;
    data.timeStartHour = this.interval.startH;
    data.timeEndP = this.interval.endF;
    data.timeEndHour = this.interval.endH;
    if (data.hasOwnProperty('totalData') && data.totalData != null &&
      data.hasOwnProperty('anomalousData') && data.anomalousData != null) {
      data.anomalousTimeRatio = data.anomalousData / data.totalData;
      data.anomalousTimeRatio = !isNaN(data.anomalousTimeRatio) ? data.anomalousTimeRatio : 0;
    }
    return data;
  }

  // BUILD PLOT
  buildPlotConfig(_this: any, data: any) {
    try {

      let traces = [];

      let filteredConfigsLength = _this.plotsConfig.list.filter((c: any) => c.show).length;

      let domain = _this.internalDataService.getDomain(filteredConfigsLength);

      // console.log([moment(_this.interval.start).valueOf(), moment(_this.interval.end).valueOf()]);
      // console.log([_this.interval.start, _this.interval.end]);
      // Default layout
      let plotLayout: any = {
        colorway: _this.colors,
        margin: {
          t: 40,
          b: 50,
          r: 25,
          l: 25,
          pad: 0
        },
        legend: {
          orientation: 'h',
          traceorder: 'normal',
          x: 0,
          y: -0.15
        },
        xaxis: {
          type: 'date',
          // range: [moment(_this.interval.start).valueOf(), moment(_this.interval.end).valueOf()],
          domain: domain,
          zeroline: false,
          showgrid: false,
        },
        // height: chartHeight,
      };

      var configIdx = 0;

      _this.plotsConfig.list.filter((c: any) => c.show).forEach((config: any) => {

        configIdx += 1;
        plotLayout['yaxis' + configIdx] = {
          fixedrange: true,
          zeroline: false,
          showgrid: false,
          automargin: true,
          anchor: 'free',
          // anchor: configIdx != 2 ? 'free' : 'x',
          position: configIdx > 1 ? (configIdx % 2 != 0 ? 0.05 * (configIdx - 1) / 2 : (1 - (0.05 * (configIdx - 2) / 2))) : null,
          overlaying: configIdx > 1 ? 'y' : null,
          side: configIdx % 2 == 0 ? 'right' : 'left',
          title: {
            // standoff: 50,
            text: config.unit
          }
        };

        let noData = true;
        Object.entries(data.aspects).forEach((aspectValues: any) => {

          const aspect = aspectValues[0];
          const values = aspectValues[1];

          if (config.hasOwnProperty('traces') && config.traces != null && config.traces.length > 0 &&
            Object.keys(values).length > 0 && values.hasOwnProperty('variables') && values.variables != null) {
            config.traces.filter((x: any) => values.variables.hasOwnProperty(x.id)).forEach((x: any, idx: any) => {

              if (!values.timestamps || values.timestamps.length == 0) return;

              let traceName = x.label;
              let multiplier = x.multiplier != null ? x.multiplier : (config.multiplier != null ? config.multiplier : 1);
              let hovertext = values.variables[x.id].map((v: any, xIdx: any) => {
                let hover = "<b>" + _this.translate.instant("CONTINUOUS_EXPLORATION.VARIABLE") + ": </b>" + (x.paramRFlag ? x.id + ' - ' : '') + traceName + '<br>' +
                  "<b>" + _this.translate.instant("CONTINUOUS_EXPLORATION.VALUE") + ": </b>" + _this.filterService.parseGaugeValue(v * multiplier, 2, 1) +
                  " " + plotLayout['yaxis' + configIdx].title.text + '<br>' +
                  "<b>" + _this.translate.instant("CONTINUOUS_EXPLORATION.TIME") + ": </b>" + moment(values.timestamps[xIdx]).format("MMM DD, YYYY HH:mm:ss.SSS") + '<br>';
                return hover;
              });

              if (noData) noData = values.timestamps.length > 0;

              traces.push({
                showlegend: true,
                yaxis: 'y' + configIdx,
                name: traceName,
                id: x.id,
                legendgroup: x.id,
                visible: x.show,
                // mode: 'lines',
                mode: x.traceType == 'setpoint' ? 'lines' : 'lines+markers',
                type: 'scatter',
                text: hovertext,
                line: {
                  dash: x.traceType == 'setpoint' ? 'dot' : 'solid',
                },
                hoverinfo: "text",
                x: values.timestamps,//.map((x: any) => x.substring(0, x.length - 6)),
                y: values.variables[x.id].map((x: any) => x != null ? x * multiplier : null),
              });
            });
          }

        });
      });

      try {
        Object.entries(data.aspects).forEach((aspectValues: any) => {

          const aspect = aspectValues[0];
          const values = aspectValues[1];

          _this.aggregations?.forEach((aggr: any) => {
            if (values != null && values.hasOwnProperty('variables') && values.variables != null &&
              values.variables.hasOwnProperty(aggr.id) && values.variables[aggr.id] != null && values.variables[aggr.id].length > 0) {

              let yValuesIdx = traces.findIndex((x: any) => x.yaxis == 'y1');
              let yVals = (yValuesIdx != -1) ? _this.clonerService.deepClone(traces[yValuesIdx].y) : [];

              traces.push({
                name: aggr.label,
                id: aggr.id,
                showlegend: false,
                mode: 'lines',
                type: 'scatter',
                line: {
                  width: 0
                },
                hovertext: values.variables[aggr.id].map((x: any) => '<b>' + aggr.label.capitalize() + '</b>: ' + x),
                hoverinfo: "text",
                x: values.timestamps,
                y: yVals,
              });
            }
          });

        });
      } catch (error) {
        console.log(error);
      }

      plotLayout.shapes = [];
      if (data.hasOwnProperty('dataAD') && data.dataAD != null &&
        data.dataAD.hasOwnProperty('timeserie') && data.dataAD.timeserie != null &&
        data.dataAD.timeserie.length > 0) {

        data.dataAD.timeserie.forEach(bar => {
          plotLayout.shapes.push({
            type: 'rect',
            xref: 'x',
            yref: 'paper',
            x0: bar.timeStart,
            y0: 0,
            x1: bar.timeEnd,
            y1: 1,
            fillcolor: _this.internalDataService.getHealthColor(bar.value != null ? bar.value * 100 : null),
            opacity: 0.2,
            line: {
              width: 0
            }
          });
        });
      }
      plotLayout.annotations = [];

      return {
        layout: plotLayout,
        traces: traces,
        params: {
          relayoutVariables: true,
          displayModeBar: false,
        }
      };
    }
    catch (e) {
      console.log(e);
      return {
        layout: {},
        traces: []
      };
    }

  }

  openAggrDialog(aggr: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: this.clonerService.deepClone(this.interval),
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {

        console.log('afterClosed', result);
        result = this.clonerService.deepClone(result);
        aggr.selected = this.clonerService.deepClone(result.selected);

        this.pageState.next(7);
        this.getHealthTraceability(this, 0);
      }

    });
  };

  openVariablesSelectionDialog() {

    const variablesDialog = this.dialog.open(ComponentVariablesSelectionComponent,
      {
        panelClass: 'ff-dialog',
        width: '40%',
        // height: '90%',
        data: {
          title: this.translate.instant("HEALTH_MONITORING.VARIABLES_SELECTION"),
          componentId: this.componentId,
          groups: this.clonerService.deepClone(this.plotsConfigComponents),
          hideComponents: true,
        },
      });

    variablesDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          this.plotsConfigComponents = this.clonerService.deepClone(result.groups);
          this.plotsConfig = this.clonerService.deepClone(result.groups[this.componentId].plotsConfig);

          this.internalDataService.setTracesSelected(this, this.machineId, this.plotsConfig, false, false, false);

          this.pageState.next(7);
          this.getHealthTraceabilityPolling(this);

          // this.pageState.next(7);
          // this.dashboardData.plotData = this.buildPlotConfig(this, this.dashboardData);
          // setTimeout(() => this.dispatcherService.getDispatch(this, 300), 200);

        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  getZoomedBucket() {

    this.showResetInterval = true;

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let delta = moment(this.interval.end).diff(moment(this.interval.start)) * 0.25;

    let start = JSON.parse(JSON.stringify(moment(this.interval.start).add(delta)));
    let end = JSON.parse(JSON.stringify(moment(this.interval.end).subtract(delta)));

    this.interval.start = start;
    this.interval.end = end;

    this.interval.enabledPolling = false;

    this.intervalService.formatTimezone(this.interval, this.machine.timezone);
    // let formatHours = "MMM DD YYYY, HH:mm";
    // this.interval.startF = this.intervalService.strFormat(this.interval, start, this.machine.timezone, formatHours) + ' - ' + this.intervalService.strFormat(this.interval, end, this.machine.timezone, "HH:mm");

    // this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

    this.pageState.next(7);
    this.getHealthTraceability(this, 0);
  }

  getUnzoomedBucket() {

    this.showResetInterval = true;

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let delta = moment(this.interval.end).diff(moment(this.interval.start)) * 0.5;

    let start = moment(this.interval.start).subtract(delta);
    let end = moment(this.interval.end).add(delta);
    let now = moment.utc();

    if (end > now) {

      // Shift the unzoomed interval of an offset = end - now

      start = start.subtract(end.diff(now));
      end = now;

      this.interval.enabledPolling = true;
    } else {
      this.interval.enabledPolling = false;
    }

    this.interval.start = JSON.parse(JSON.stringify(start));
    this.interval.end = JSON.parse(JSON.stringify(end));

    this.intervalService.formatTimezone(this.interval, this.machine.timezone);
    // let formatHours = "MMM DD YYYY, HH:mm";
    // this.interval.startF = this.intervalService.strFormat(this.interval, start, this.machine.timezone, formatHours) + ' - ' + this.intervalService.strFormat(this.interval, end, this.machine.timezone, "HH:mm");

    // this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

    this.pageState.next(7);
    this.getHealthTraceability(this, 0);
  }

  // Navigate to previous bucket
  getPreviousBucket() {

    this.showResetInterval = true;

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start)) / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).subtract(duration)));
    let end = JSON.parse(JSON.stringify(moment(this.interval.end).subtract(duration)));

    this.interval.start = start;
    this.interval.end = end;

    this.interval.enabledPolling = false;

    this.intervalService.formatTimezone(this.interval, this.machine.timezone);
    // let formatHours = "MMM DD YYYY, HH:mm";
    // this.interval.startF = this.intervalService.strFormat(this.interval, start, this.machine.timezone, formatHours) + ' - ' + this.intervalService.strFormat(this.interval, end, this.machine.timezone, "HH:mm");

    // this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

    this.pageState.next(7);
    this.getHealthTraceability(this, 0);
  }

  // Navigate to next bucket
  getNextBucket() {

    this.showResetInterval = true;

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start)) / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).add(duration)));
    let end = moment(this.interval.end).add(duration);

    if (end > moment.utc()) {
      end = moment.utc();
      this.interval.enabledPolling = true;
    } else {
      this.interval.enabledPolling = false;
    }

    this.interval.start = start;
    this.interval.end = JSON.parse(JSON.stringify(end));

    this.intervalService.formatTimezone(this.interval, this.machine.timezone);
    // let formatHours = "MMM DD YYYY, HH:mm";
    // this.interval.startF = this.intervalService.strFormat(this.interval, start, this.machine.timezone, formatHours) + ' - ' + this.intervalService.strFormat(this.interval, end, this.machine.timezone, "HH:mm");

    // this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

    this.pageState.next(7);
    this.getHealthTraceability(this, 0);

  }

  resetDefaultInterval() {
    this.interval = this.clonerService.deepClone(this.defaultInterval);
    this.showResetInterval = false;
    this.pageState.next(7);
    this.getHealthTraceability(this, 0);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    let queryParams = this.route.snapshot.queryParams;

    this.backButton = [this.machineId, "health-monitoring"];
    this.internalDataService.setBackButton(this.backButton);

    this.componentId = queryParams.componentId;
    this.machineRefId = queryParams.machineRefId;

    this.route.params.subscribe((params: Params) => {

      this.machineId = params['machineId'];

      try {
        let queryParams = this.route.snapshot.queryParams;
        this.componentId = queryParams.componentId;
        this.machineRefId = queryParams.machineRefId;
      } catch (error) { console.log("Wrong query params") }

      this.internalDataService.setHMComponentSelected({
        machineRefId: this.machineRefId,
        componentId: this.componentId,
      });

    });

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }
    try {
      this.pageState.unsubscribe();
    } catch (error) { }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) { }
    try {
      this.HMComponentSelectedSub.unsubscribe();
    } catch (error) { }
    try {
      this.internalDataService.setBackButton([]);
    } catch (error) { }
    try {
      this.internalDataService.setZoomedIntervalVariables(null);
      this.zoomedIntervalVariablesSub.unsubscribe();
    } catch (error) { }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) { this.isMobile = window.innerWidth <= 959 }
}