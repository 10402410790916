import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {
  @Input() date: any;
  @Input() trafila: any;
  @Input() shift: any;
  @Input() lotChangeTime: any;
  @Input() product: any;
  @Input() lot: any;
  @Input() bigBag: any;
  @Input() dayTime: any;
  @Input() outcome: any;
  @Output() close = new EventEmitter<void>()
  @Output() confirm = new EventEmitter<void>()

  onClose() {
    this.close.emit();
  }

  onConfirm() {
    this.confirm.emit();
  }
} 

