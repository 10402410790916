import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

import { IntervalService } from 'src/app/services/interval.service';

import { MatDialog } from '@angular/material/dialog';
import {
  ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexTitleSubtitle, ApexXAxis,
  ApexYAxis
} from "ng-apexcharts";

export type ChartOptions = {
  annotations: ApexAnnotations,
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  grid: ApexGrid,
  tooltip: any // to be simple I made it as any, can be replaced with the proper className
};

@Component({
  selector: 'app-states-table',
  templateUrl: './states.component.html',
  styleUrls: ['./states.component.scss']
})
export class StatesTimelineComponent implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public excludeLine: boolean = true;

  public pollingTime: any;
  public pollingMachines: any;

  public currentSynopticId: any;
  public synopticConfig: any;
  public synopticConfigDefault: any;
  public monitoringData: any;

  public dialogData: any;
  public showDialog: boolean = false;
  public chartOptions: any;

  public ganttState: any = 0;

  public interval: any;
  public intervalConfig: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getStateTimelinePolling, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    // TODO AGGIUNGERE AGGREGAZIONI E FILTRI
    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.monitoringData = null;


    this.breadcrumb = ['MACHINE_MONITORING.TITLE', 'STATE_TIMELINE.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs('machineMonitoring');

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = 60000;
    this.pollingMachines = Subscription;

    this.interval = this.intervalService.getIntervalById('last2Hours');

    this.intervalConfig = {
      list: this.intervalService.getDefaultIntervals(1),
      selected: this.interval
    };

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET USER DATA
  public getUserData(_this: any) {
    try {

      _this.apiService.sendGetRequest('/apif/user').pipe(
        retryWhen(_this.apiService.genericRetryStrategy()),
        catchError(error => {

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError(
            'Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.userInfo = data.body;
            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'machineMonitoring');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingMachines, this.getStateTimelinePolling, this.getStatesTimeline, this.machine.timezone, 4, 5) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SYNOPTIC CONFIG

  // polling
  getStateTimelinePolling(_this: any) {
    try {

      if (_this.pollingTime > 0) {
        _this.pollingMachines = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getStatesTimeline(_this, count);
        });
      } else {
        _this.getStatesTimeline(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getStatesTimeline(_this: any, count?: any) {
    try {

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      if (_this.interval != null && _this.interval.enabledPolling) {
        _this.interval = _this.intervalService.getIntervalById(_this.intervalConfig.selected.id);
        _this.intervalConfig.selected = JSON.parse(JSON.stringify(_this.interval));
      }

      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone
      };

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      if (_this.availableMachines != null) {
        payload.machineList = _this.availableMachines?.list;
      } else if (_this.machine.machineReference) {
        query.machineId = _this.machine.machineReference;
      }

      _this.apiService.sendPostRequest('/apif/machine-monitoring/states-timeline/' + _this.machineId, payload, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          if (count == 0) {
            _this.pollingMachines.unsubscribe();
          }

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError(
            'Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            let height = 400;
            try {
              height = window.innerHeight - 300;
            } catch (error) {
              console.log(error);
            }

            _this.chartOptions = {
              chart: {
                height: height,
                type: 'rangeBar',
                animations: {
                  enabled: false
                },
                zoom: {
                  enabled: true
                },
                events: {
                  zoomed: function (config: any, axes: any) {
                    try {
                      if (axes.xaxis.min != null && axes.xaxis.max != null) {
                        try {
                          _this.pollingMachines.unsubscribe();
                        } catch (error) {
                          console.log(error);
                        }
                        // do nothing
                      } else {
                        if (!_this.interval.enabledPolling) {
                          _this.getStatesTimeline(_this, 0);
                        } else {
                          _this.pageState.next(4);
                          _this.getStateTimelinePolling(_this);
                        }
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }
                },
                toolbar: {
                  show: true,
                  tools: {
                    download: false,
                    selection: false,
                    zoom: true,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    customIcons: []
                  },
                  // export: {
                  //   csv: {
                  //     filename: undefined,
                  //     columnDelimiter: ';',
                  //     headerCategory: 'category',
                  //     headerValue: 'value',
                  //     dateFormatter(timestamp:any) {
                  //       console.log(timestamp);

                  //       return new Date(timestamp).toDateString()
                  //     }
                  //   },
                  //   svg: {
                  //     filename: undefined,
                  //   },
                  //   png: {
                  //     filename: undefined,
                  //   }
                  // },
                }
              },
              plotOptions: {
                bar: {
                  horizontal: true,
                  distributed: true,
                  dataLabels: {
                    hideOverflowingLabels: false
                  }
                }
              },
              dataLabels: {
                enabled: false,
                style: {
                  fontSize: '14px',
                },
                formatter: function (val: any, opts: any) {
                  // console.log (val, opts);
                  var label = opts.w.globals.labels[opts.dataPointIndex];
                  var a = moment(val[0]);
                  var b = moment(val[1]);
                  var diff = b.diff(a, 'seconds');
                  var diffHM = _this.filterService.parseTime(b.diff(a, 'seconds'), 's', 'HH:mm');
                  if (diff > 0) {
                    return diffHM;
                  }
                  // return label;
                },
                // style: {
                //   colors: ['#f3f4f5', '#fff']
                // }
              },
              xaxis: {
                type: 'datetime',
                labels: {
                  datetimeUTC: false,
                  show: true,
                }
              },
              tooltip: {
                enabled: true,
                custom: function (totalSeries: any) {

                  let series = totalSeries.series;
                  let seriesIndex = totalSeries.seriesIndex;
                  let dataPointIndex = totalSeries.dataPointIndex;
                  let w = totalSeries.w;

                  let startText = w.config.series[seriesIndex].data[dataPointIndex].y != null ?
                    '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.START") + ': </strong>' + moment(w.config.series[seriesIndex].data[dataPointIndex].y[0]).format('MMM DD, YYYY HH:mm:ss') + '</p>' : '';

                  let endText = w.config.series[seriesIndex].data[dataPointIndex].y != null ?
                    '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.END") + ': </strong>' + moment(w.config.series[seriesIndex].data[dataPointIndex].y[1]).format('MMM DD, YYYY HH:mm:ss') + '</p>' : '';

                  // let machineText = w.config.series[seriesIndex].data[dataPointIndex].x != null ?
                  //   '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.MACHINE") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].x + '</p>' : '';

                  let durationText = w.config.series[seriesIndex].data[dataPointIndex].duration != null ?
                    '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.DURATION") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].duration + '</p>' : '';

                  let stateText = w.config.series[seriesIndex].data[dataPointIndex].label != null ?
                    '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.STATE") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].label + '</p>' : '';

                  let modeText = w.config.series[seriesIndex].data[dataPointIndex].mode != null ?
                    '<p> <strong>' + _this.translate.instant("STATE_TIMELINE.MODE") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].mode + '</p>' : '';

                  let codeText = w.config.series[seriesIndex].data[dataPointIndex].id != null ?
                    '<p> <strong>' + _this.translate.instant("MACHINE_STATUS_LOG.CODE") + ': </strong>' + w.config.series[seriesIndex].data[dataPointIndex].id + '</p>' : '';

                  // let state = '';
                  // if (w.config.series[seriesIndex].data[dataPointIndex].ids != null && w.config.series[seriesIndex].data[dataPointIndex].ids.length > 0) {
                  //   state += '<p> <strong>Start: </strong>' + moment(w.config.series[seriesIndex].data[dataPointIndex].y[0]).utc(0).format('MMM DD, YYYY HH:mm:ss') + '</p>' +
                  //     '<p><strong>Alarm Codes: </strong></p><ul style="margin:0;">';
                  //   w.config.series[seriesIndex].data[dataPointIndex].ids.forEach(alarm => {
                  //     state += '<li><p><strong>Code:</strong> ' + alarm.code + ', <strong>Message:</strong> ' + alarm.message + '</p></li>';
                  //   });
                  //   state += '</ul>';
                  // }

                  // var detectedStates = '';
                  // if (w.config.series[seriesIndex].data[dataPointIndex].states != null && w.config.series[seriesIndex].data[dataPointIndex].states.length > 0) {
                  //   detectedStates +=
                  //     '<p> <strong>Start: </strong>' + moment(w.config.series[seriesIndex].data[dataPointIndex].y[0]).utc(0).format('MMM DD, YYYY HH:mm:ss') + '</p>' +
                  //     '<p> <strong>End: </strong>' + moment(w.config.series[seriesIndex].data[dataPointIndex].y[1]).utc(0).format('MMM DD, YYYY HH:mm:ss') + '</p>' +
                  //     '<p><strong>Alarm Codes: </strong></p><ul style="margin:0;">';
                  //   w.config.series[seriesIndex].data[dataPointIndex].states.forEach(alarm => {
                  //     detectedStates += '<li>' + alarm.message + '</li>';
                  //   });
                  //   detectedStates += '</ul>';
                  //   phaseText = '';
                  // }

                  // if (w.config.series[seriesIndex].data[dataPointIndex].alertDescription != null) {
                  //   alarmCodes += '<p><strong>Alert description: </strong>' + w.config.series[seriesIndex].data[dataPointIndex].alertDescription + '</p>';
                  // }
                  return '<div class="arrow_box" style="background-color: ' + w.config.series[seriesIndex].data[dataPointIndex].fillColor + '80; font-size: 0.8rem;">' +
                    startText +
                    endText +
                    codeText +
                    // machineText +
                    durationText +
                    stateText +
                    modeText +
                    // detectedStates +
                    '</div>'
                },
              },
              yaxis: {
                show: true,
                labels: {
                  style: {
                    fontSize: '12px'
                  }
                }
              },
              grid: {
                row: {
                  colors: ['#f3f4f5', '#fff'],
                  opacity: 1
                }
              },
            }

            let series: any = [];

            // Check if machines exist
            if (Object.keys(data.body).length > 0) {

              // console.log(Object.values(data.body).filter((x: any) => x.states.length > 0).length == 1);

              // Only one machine: y axis with categories
              if (Object.keys(data.body).length == 1) {
                for (const machine of Object.keys(data.body)) {

                  data.body[machine].states.forEach((state: any) => {

                    let duration = state.end != null ? _this.filterService.parseTime(moment(state.end).diff(moment(state.start), 's'), 's', 'HH:mm:ss') :
                      _this.filterService.parseTime(moment().diff(moment(state.start), 's'), 's', 'HH:mm:ss');

                    let stateConfig = null;
                    let color = null;
                    let label = null;
                    let mode = null;

                    let category = -1;
                    let catLabel = "unknown";
                    let catColor = "#bfbfbf";

                    try {
                      // SIRE
                      // stateConfig = _this.machine.profile.timeStates.find((x: any) => x.state == 'timeState' + machine + state.id);
                      // OCME
                      // stateConfig = _this.machine.profile.timeStates.find((x: any) => x.state == 'timeState' + state.id);
                      stateConfig = _this.machine.profile.timeStates.find((x: any) => x.state == state.id);
                      if (stateConfig != null) {
                        category = stateConfig.category;
                        color = stateConfig.color;
                        mode = stateConfig.mode != null ? _this.translate.instant(stateConfig.mode) : null;
                        label = stateConfig.value != null ? this.translate.instant(stateConfig.value) : null;
                      }
                    } catch (error) {
                      console.log(error);
                    }

                    try {
                      if (category != null) {
                        let catConfig = _this.machine.profile.categories.find((x: any) => x.id == category);
                        if (catConfig != null) {
                          catLabel = catConfig.label != null ? _this.translate.instant(catConfig.label) : null;
                          catColor = catConfig.color;
                        }
                      }
                    } catch (error) {
                      console.log(error);
                    }

                    series.push({
                      x: catLabel,
                      y: [
                        moment(state.start).utc(false).valueOf() - 0,
                        (state.end != null) ? moment(state.end).utc(false).valueOf() : moment().utc(false).valueOf(),
                      ],
                      id: state.id,
                      duration: duration,
                      label: label,
                      fillColor: catColor,
                      mode: mode,
                    });

                  });

                  if (data.body[machine].alarms != null && data.body[machine].alarms.length > 0) {
                    data.body[machine].alarms.forEach((alarm: any) => {

                      if (alarm.start == null) {
                        return;
                      }

                      series.push({
                        x: _this.translate.instant("ALARMS.TITLE"),
                        y: [
                          new Date(alarm.start).getTime(),
                          new Date(alarm.end).getTime()
                        ],
                        phaseEnd: alarm.end != null ? moment(alarm.end).format("DD MMM YYYY HH:mm:ss") : '-',
                        ids: alarm.codes != null ? alarm.codes.map((x: any) => {
                          return {
                            code: _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'code', _this.machineId),
                            message: _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'message', _this.machineId),
                            start: x.start,
                            end: x.end
                          }
                        }) : null,
                        fillColor: "#f44336",
                      });

                    });
                  }

                  if (data.body[machine].signalations != null && data.body[machine].signalations.length > 0) {
                    data.body[machine].signalations.forEach((alarm: any) => {

                      if (alarm.start == null) {
                        return;
                      }

                      series.push({
                        x: _this.translate.instant("SIGNALATIONS.TITLE"),
                        y: [
                          new Date(alarm.start).getTime(),
                          new Date(alarm.end).getTime()
                        ],
                        phaseEnd: alarm.end != null ? moment(alarm.end).format("DD MMM YYYY HH:mm:ss") : '-',
                        ids: alarm.codes != null ? alarm.codes.map((x: any) => {
                          return {
                            code: _this.internalDataService.parseWarningsLabel(x.alarmCode, 'code', _this.machineId),
                            message: _this.internalDataService.parseWarningsLabel(x.alarmCode, 'message', _this.machineId),
                            start: x.start,
                            end: x.end
                          }
                        }) : null,
                        fillColor: "#FCA10D",
                      });

                    });
                  }
                }

              }

              // Multiple machines: y axis with machines
              else {
                for (const machine of Object.keys(data.body)) {

                  data.body[machine].states.forEach((state: any) => {

                    let duration = state.end != null ? _this.filterService.parseTime(moment(state.end).diff(moment(state.start), 's'), 's', 'HH:mm:ss') :
                      _this.filterService.parseTime(moment().diff(moment(state.start), 's'), 's', 'HH:mm:ss');

                    let stateConfig = null;
                    let color = null;
                    let label = null;
                    let mode = null;

                    let category = -1;
                    let catLabel = "unknown";
                    let catColor = "#bfbfbf";

                    try {
                      // SIRE
                      // stateConfig = _this.machine.profile.timeStates.find((x: any) => x.state == 'timeState' + machine + state.id);
                      // OCME
                      // stateConfig = _this.machine.profile.timeStates.find((x: any) => x.state == 'timeState' + state.id);
                      stateConfig = _this.machine.profile.timeStates.find((x: any) => x.state == state.id);
                      if (stateConfig != null) {
                        category = stateConfig.category;
                        color = stateConfig.color;
                        mode = stateConfig.mode != null ? _this.translate.instant(stateConfig.mode) : null;
                        label = stateConfig.value != null ? this.translate.instant(stateConfig.value) : null;
                      }

                    } catch (error) {
                      console.log(error);
                    }

                    try {
                      if (category != null) {
                        let catConfig = _this.machine.profile.categories.find((x: any) => x.id == category);
                        if (catConfig != null) {
                          catLabel = catConfig.label != null ? _this.translate.instant(catConfig.label) : null;
                          catColor = catConfig.color;
                        }
                      }
                    } catch (error) {
                      console.log(error);
                    }

                    series.push({
                      x: _this.translate.instant('MACHINES.' + machine) != 'MACHINES.' + machine ? _this.translate.instant('MACHINES.' + machine) : machine,
                      y: [
                        moment(state.start).utc(false).valueOf() - 0,
                        (state.end != null) ? moment(state.end).utc(false).valueOf() : moment().utc(false).valueOf(),
                      ],
                      id: state.id,
                      duration: duration,
                      label: label,
                      fillColor: catColor,
                      mode: mode,
                    });

                  });

                  if (data.body[machine].alarms != null && data.body[machine].alarms.length > 0) {
                    data.body[machine].alarms.forEach((alarm: any) => {

                      if (alarm.start == null) {
                        return;
                      }

                      series.push({
                        x: _this.translate.instant("ALARMS.TITLE"),
                        y: [
                          new Date(alarm.start).getTime(),
                          new Date(alarm.end).getTime()
                        ],
                        phaseEnd: alarm.end != null ? moment(alarm.end).format("DD MMM YYYY HH:mm:ss") : '-',
                        ids: alarm.codes != null ? alarm.codes.map((x: any) => {
                          return {
                            code: _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'code', _this.machineId),
                            message: _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'message', _this.machineId),
                            start: x.start,
                            end: x.end
                          }
                        }) : null,
                        fillColor: "#f44336",
                      });

                    });
                  }

                  if (data.body[machine].signalations != null && data.body[machine].signalations.length > 0) {
                    data.body[machine].signalations.forEach((alarm: any) => {

                      if (alarm.start == null) {
                        return;
                      }

                      series.push({
                        x: _this.translate.instant("Signalations"),
                        y: [
                          new Date(alarm.start).getTime(),
                          new Date(alarm.end).getTime()
                        ],
                        phaseEnd: alarm.end != null ? moment(alarm.end).format("DD MMM YYYY HH:mm:ss") : '-',
                        ids: alarm.codes != null ? alarm.codes.map((x: any) => {
                          return {
                            code: _this.internalDataService.parseWarningsLabel(x.alarmCode, 'code', _this.machineId),
                            message: _this.internalDataService.parseWarningsLabel(x.alarmCode, 'message', _this.machineId),
                            start: x.start,
                            end: x.end
                          }
                        }) : null,
                        fillColor: "#FCA10D",
                      });

                    });
                  }
                }
              }
            }

            // console.log(series);

            if (series.length == 0) {
              _this.ganttState = 2;
            } else {
              _this.ganttState = 1;
              _this.chartOptions.series = [{ data: series }];

              _this.chartOptions.xaxis.min = new Date(_this.filterService.getMin(_this.chartOptions.series[0].data, 'y', 0)).getTime();
              _this.chartOptions.xaxis.max = new Date(_this.filterService.getMax(_this.chartOptions.series[0].data, 'y', 1)).getTime();
            }


            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      console.log(error);
    }

  }

  getPreviousBucket() {

    try {
      this.pollingMachines.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start), 'm') / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).subtract(duration, 'm')));
    let end = JSON.parse(JSON.stringify(moment(this.interval.end).subtract(duration, 'm')));

    this.interval.start = start;
    this.interval.end = end;

    this.interval.enabledPolling = false;

    this.interval.startF = this.intervalService.strFormat(this.interval, start) + ' - ' + this.intervalService.strFormat(this.interval, end);
    this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

    this.pageState.next(4);
    this.getStatesTimeline(this, 0);
  }

  getNextBucket() {

    try {
      this.pollingMachines.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start), 'm') / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).add(duration, 'm')));
    let end = moment(this.interval.end).add(duration, 'm');

    if (moment().diff(end, 'm') < 5) {
      this.interval.start = start;
      this.interval.enabledPolling = true;
      this.interval.end = JSON.parse(JSON.stringify(moment()));
      this.interval.startF = this.intervalService.strFormat(this.interval, start);
      this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

      this.pageState.next(4);
      this.getStateTimelinePolling(this);
    }
    else {

      this.interval.start = start;
      this.interval.end = JSON.parse(JSON.stringify(end));

      this.interval.enabledPolling = false;
      this.interval.startF = this.intervalService.strFormat(this.interval, start) + ' - ' + this.intervalService.strFormat(this.interval, this.interval.end);
      this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

      this.pageState.next(4);
      this.getStatesTimeline(this, 0);
    }


  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() {
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pollingMachines.unsubscribe();
    } catch (error) { }
    try {
      this.pageState.unsubscribe();
    } catch (error) { }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) { }
  }

}