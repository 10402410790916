<span class="default-widget-title">{{ widget.title | translate }}</span>
<div class="bar-container-label {{elem.action != null ? 'clickable' : '' }}" [ngClass]="elem.class" fxLayout="row"
  fxLayoutAlign="center center" *ngFor="let elem of widget.infos" (click)="dpClick(elem)">

  <div class="bar-box2" fxLayout="row" fxLayoutAlign="start center">

    <div *ngIf="elem.icon != null" class="icon-box" [ngClass]="elem.iconClass">
      <mat-icon *ngIf="elem.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': elem.label}">
        {{elem.icon.icon }}</mat-icon>
      <mat-icon *ngIf="elem.icon.type == 'svg'" svgIcon="{{ elem.icon.icon }}" class="icon"
        translate-attr="{'aria-label': elem.label}"></mat-icon>
      <i *ngIf="elem.icon.type == 'fontawesome' || elem.icon.type == 'phosphor'" class="icon {{ elem.icon.icon }}"
        translate-attr="{'aria-label': elem.label}"></i>
    </div>

    <div fxFlex fxLayout="column" fxLayoutAlign="center space-around">
      <div *ngIf="elem.label != null" class="title">{{ elem.label | translate }}</div>
      <kpi-bar [kpiProps]="{ 'value': elem.value, 'hideLabel': true }" fxFlex></kpi-bar>
    </div>

  </div>

</div>