import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { StandardDialogServiceService } from 'src/app/services/standard-dialog.service';

declare var Plotly: any;

@Component({
  selector: 'ff-plotly-chart',
  templateUrl: './plotly-chart.component.html',
  styleUrls: ['./plotly-chart.component.scss']
})
export class PlotlyChartComponent implements OnInit, OnChanges, OnDestroy {

  @Input() widget: any;
  public randomId: any;
  public plotId: any;
  public plotState: any;
  public realPlotPosition: any;
  public sidenavWidth: any;
  public flagsConfig: any;
  public flagsNumber: any;
  public plotDiv: any;

  public resizePlotSub: Subscription;

  public appConfig: any;
  public appInfo: any;

  // @ViewChild('cbcb') chartBackgroundCustomBefore: ElementRef;
  // @ViewChild('cbc') chartBackgroundCustom: ElementRef;

  constructor(
    public cacheService: CacheService,
    public internalDataService: InternalDataService,
    public router: Router,
    public route: ActivatedRoute,
    public clonerService: ClonerService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public appConfigService: AppConfigService,
    public standardDialogServiceService: StandardDialogServiceService,
    public dialog: MatDialog,
  ) {

    this.appInfo = this.appConfigService.getAppInfo;
    this.appConfig = this.appConfigService.getAppConfig;

    this.randomId = generateRandomString();
    this.plotId = this.randomId;
    this.plotState = 0;

    this.resizePlotSub = this.internalDataService.resizePlot.subscribe(value => {
      if (value != null && value) {
        setTimeout(() => {
          try {
            Plotly.relayout(this.plotDiv, { autosize: true });
          } catch (error) { }
        }, 150);
      }
    });
  }

  ngOnInit(): void {
    this.buildPlot();
  }

  ngOnChanges(): void {
    this.buildPlot(false);
  }

  ngOnDestroy(): void {
    try {
      this.resizePlotSub.unsubscribe();
      this.internalDataService.setResizePlot(false);
    } catch (error) {
      // console.log(error)
    }
  }

  navigateToSelectedInterval(interval: any) {
    let zoomVariables = {
      from: moment(interval.start).format("YYYY-MM-DD HH:mm:ss.SSS"),
      to: moment(interval.end).format("YYYY-MM-DD HH:mm:ss.SSS"),
    };

    this.internalDataService.setZoomedIntervalVariables(zoomVariables);
  }

  buildPlot(type?: any) {
    // console.log("buildPlot", this.widget);

    let _this = this;

    let plotData: any = {};

    let plotConfig: any = {
      responsive: true,
      displaylogo: false,
      // variablesTimeseries: true,
      modeBarButtonsToRemove: [
        // Zoom 2D plot
        // "zoomIn2d", "zoomOut2d",
        // 2D plot
        "pan2d", "select2d", "lasso2d", "autoScale2d",
        // 3D plot
        "zoom3d", "pan3d", "orbitRotation", "tableRotation", "handleDrag3d", "resetCameraDefault3d", "resetCameraLastSave3d", "hoverClosest3d",
        // Cartesian
        "hoverClosestCartesian", "hoverCompareCartesian",
        // Geo
        "zoomInGeo", "zoomOutGeo", "resetGeo", "hoverClosestGeo",
        // Other
        "hoverClosestGl2d", "hoverClosestPie", "toggleHover", "resetViews", "toggleSpikelines", "resetViewMapbox", "sendDataToCloud"
      ],
    };

    this.widget.customHeight = this.widget?.config?.customHeight ?? this.widget?.customHeight;

    // // // // // // // // // // // // // // // // // // // // // // // // // // // //
    // START CUSTOM PLOT
    // // // // // // // // // // // // // // // // // // // // // // // // // // // //

    if (this.widget.config.hasOwnProperty('plotConfig')) {

      let data = this.widget?.config?.plotDataAttribute != null ? this.widget.data?.[this.widget?.config?.plotDataAttribute] : this.widget.data;
      let unparsedData: any = this.clonerService.deepClone(data);
      let newData: any = this.clonerService.deepClone(data);

      let traces: any = [];
      let plotLayout: any = this.widget.config?.plotConfig?.layout ?? {};
      let tracesConfigs = this.widget.config?.plotConfig?.traces ?? [];
      let updatemenus = this.widget?.config?.plotConfig?.updatemenus ?? [];

      let xVals = null;
      let yVals = null;
      let zVals = null;

      let xValsUnparsed = null;
      let yValsUnparsed = null;

      let xValsComplete = null;
      let yValsComplete = null;

      let textHeatmap = null;

      if (plotLayout.colorway == null) plotLayout.colorway = this.clonerService.deepClone(this?.appInfo?.darkTheme ? this.internalDataService.defaultPlotlyColorsDark : this.internalDataService.defaultPlotlyColors);

      let formatType = this.widget?.config?.plotFormatType ?? 'objectOfArrays';
      // Default is "objectOfArrays"
      // 
      // let a = {
      //   timestamps: [...],
      //   variable1: [...],
      //   ...
      // }
      // 
      // If "arrayOfObjects":
      // 
      // let a = [
      //   {
      //     timestamp: ...,
      //     variable1: ...,
      //     ...
      //   }
      // ]
      // 
      // 

      // GROUPBY
      if (this.widget.config?.plotConfig?.additionalConfigParams?.groupBy != null && this.widget?.config?.plotFormatType == 'arrayOfObjects') {

        try {

          // Group by the data (works ONLY if array)
          let groupedData = this.filterService.groupBy(data, this.widget.config?.plotConfig?.additionalConfigParams?.groupBy);

          // Assign for every trace config all the the detected groups
          tracesConfigs = tracesConfigs.reduce((acc, traceConfig) => {
            Object.keys(groupedData)?.forEach(key => {
              let newObj: any = Object.assign(this.clonerService.deepClone(traceConfig), {
                variableY: traceConfig.variableY + key.capitalize(),
                variableX: traceConfig.variableX + key.capitalize(),
                label: tracesConfigs?.length > 1 ? this.translate.instant(traceConfig?.label ?? '-') + ' - ' + key.capitalize() : key.capitalize(),
                // label: this.translate.instant(traceConfig?.label ?? '-') + ' - ' + key.capitalize(),
                hoverFormat: Object.assign(this.clonerService.deepClone(traceConfig?.hoverFormat ?? {}), {
                  label: tracesConfigs?.length > 1 ? this.translate.instant(traceConfig?.label ?? '-') + ' - ' + key.capitalize() : key.capitalize(),
                  // label: this.translate.instant(traceConfig?.hoverFormat?.label ?? traceConfig?.label ?? '-') + ' - ' + key.capitalize()
                })
              })
              acc.push(newObj);
            });
            return acc;
          }, []);

          // Recalculate new array
          newData = Object.entries(groupedData)?.reduce((acc, groupArray: any) => {
            groupArray?.[1].forEach(element => {
              let newObj: any = {};
              let copyElem = this.clonerService.deepClone(element);
              Object.entries(copyElem)?.forEach(kv => newObj[kv[0] + groupArray?.[0]?.capitalize()] = copyElem?.[kv[0]]);
              acc.push(newObj);
            });
            return acc;
          }, []);

        } catch (error) { console.log(error) }
      }

      // LOOP ON MACHINE PROFILE ARRAY
      if (this.widget.config?.plotConfig?.additionalConfigParams?.loopOnProfileArray != null && this.widget?.config?.plotFormatType == 'arrayOfObjects') {

        try {

          let loopProfileConfig: any = this.clonerService.deepClone(this.widget.config?.plotConfig?.additionalConfigParams?.loopOnProfileArray);

          let key = loopProfileConfig?.key ?? 'consumables';
          let profileArray: any = this.clonerService.deepClone(this.widget.machineProfile?.[key]) ?? [];

          if (profileArray?.length > 0) {

            tracesConfigs = tracesConfigs.reduce((acc, traceConfig) => {

              if (loopProfileConfig?.onlyTracesWithParseFromProfileConfigAttribute && !traceConfig?.parseFromProfileConfig) {
                acc.push(traceConfig);
                return acc;
              }

              profileArray.forEach(key => {

                let id = key?.[loopProfileConfig?.idVariable ?? 'id'];
                let label = key?.[loopProfileConfig?.labelVariable ?? 'label'];
                let color = key?.[loopProfileConfig?.colorVariable ?? 'color'];

                let newObj: any = Object.assign(this.clonerService.deepClone(traceConfig), {
                  variableY: traceConfig.variableY?.replace("{{id}}", id),
                  label: traceConfig.label?.replace("{{label}}", label),
                  color: traceConfig.color?.replace("{{color}}", color),
                });
                acc.push(newObj);
              });
              return acc;
            }, []);
          }

        } catch (error) { console.log(error) }

      }

      // Conversion from array of objects to objects of array
      if (formatType == 'arrayOfObjects' && Array.isArray(newData) && newData.length > 0) {

        // Find unique keys
        let uniqueKeys = newData?.reduce((acc, val) => {
          Object.keys(val)?.forEach(k => { if (acc.indexOf(k) == -1) acc.push(k) });
          return acc;
        }, []);

        // Transform to array
        newData = newData?.reduce((acc, val) => {
          uniqueKeys?.forEach(key => {
            try { acc[key].push(val[key]) }
            catch (error) { acc[key] = [val[key]] }
          });
          return acc;
        }, {});
      }

      let aggrBars = null;
      if (this.widget.config?.plotConfig?.additionalConfigParams?.aggrBarsAccordingToTimestamps) {

        let config: any = this.clonerService.deepClone(this.widget.config?.plotConfig?.additionalConfigParams?.aggrBarsAccordingToTimestamps);

        let aggrBarsData = this.clonerService.deepClone(data);

        let dataGroupedById = this.filterService.groupBy(aggrBarsData, config?.variableToUseAsKey ?? "id");

        // Transform the data according to the id
        aggrBarsData = this.filterService.groupByAsArray(dataGroupedById);

        let intervalAggregation = this.cacheService.get("intervalAggregation");

        aggrBars = this.filterService.getAggrBars(aggrBarsData, intervalAggregation?.unit ?? 'day', intervalAggregation?.value ?? 1, config?.variableY ?? "OKformula", config?.variableX ?? "_time", true);

      }

      // // // // // // // // // // // // // // // // // // // // // // // // // // // //
      // START TRACES CONFIG
      // // // // // // // // // // // // // // // // // // // // // // // // // // // //

      tracesConfigs.forEach((element: any) => {
        if (element.hasOwnProperty('calc')) {
          let a: any = this.clonerService.deepClone(newData?.[element?.calc?.[0]]) ?? [];
          let b: any = this.clonerService.deepClone(newData?.[element?.calc?.[2]]) ?? [];
          let index = 0;
          let c = a.map((a: any) => {
            if (element.calc[1] == '+') a = a + b[index];
            else if (element.calc[1] == '-') a = a - b[index];
            else if (element.calc[1] == '*') a = a * b[index];
            else if (element.calc[1] == '/') a = a / b[index];
            index++;
            return a;
          });

          try { newData[element.variableY] = c }
          catch (error) { console.log(error) }

        }
      });

      let annotations = [];

      tracesConfigs.forEach((element: any) => {

        if (element?.variableY == null && element?.valueY != null) {
          element.variableY = "variableY";
        }

        if (element?.loopOnProfileArray != null) {

          if (element?.type == 'pie') {

            let key = element?.loopOnProfileArray?.key ?? 'consumables';
            let profileArray = this.widget.machineProfile?.[key] ?? [];

            if (profileArray?.length > 0) {
              element.variableValues = key + '_values';
              element.variableLabels = key + '_labels';

              if (element?.loopOnProfileArray?.colorVariable) element.variableColor = key + '_colors';

              newData[element.variableValues] = profileArray.map(cons => {
                let id = cons[element?.loopOnProfileArray?.idVariable ?? 'id'];
                if (id != null) {
                  if (element?.loopOnProfileArray?.valuesRegex != null) {
                    let newVal = newData?.[element?.loopOnProfileArray?.valuesRegex?.replace('{{id}}', id)];
                    if (element?.loopOnProfileArray?.multiplierVariable != null && cons[element?.loopOnProfileArray?.multiplierVariable] != null && newVal != null) newVal = newVal * cons[element?.loopOnProfileArray?.multiplierVariable];
                    return newVal;
                  }
                }
              });

              newData[element.variableLabels] = profileArray.map(cons => {
                let label = cons[element?.loopOnProfileArray?.labelVariable ?? 'label'];
                return this.translate.instant(label ?? '-');
              });

              if (element?.loopOnProfileArray?.colorVariable) {
                newData[element.variableColor] = profileArray.map(cons => {
                  let color = cons[element?.loopOnProfileArray?.colorVariable];
                  return color;
                });
              }

            }
          }

        }

        if (element?.variableXConfig != null && element?.variableXConfig?.type == 'configFromProfile') {
          try {
            newData[element.variableX + '_parsed'] = [];
            newData[element.variableX + '_complete'] = [];
            newData?.[element?.variableX]?.forEach((item, i) => {
              let mappingConfig = element?.variableXConfig;
              let profileConf = this.widget.machineProfile?.[mappingConfig?.key ?? 'consumables']?.find(x => x?.[mappingConfig?.idKey ?? 'id'] == item);
              let v = profileConf?.[mappingConfig?.outputKey ?? 'label'];
              newData[element.variableX + '_parsed'].push(mappingConfig?.translate ? this.translate.instant(v ?? '-') : v);
              newData[element.variableX + '_complete'].push(profileConf);
            });
          } catch (error) { console.log(error) }
        }

        if (element?.variableYConfig != null && element?.variableYConfig?.type == 'configFromProfile') {
          try {
            newData[element.variableY + '_parsed'] = [];
            newData[element.variableY + '_complete'] = [];
            newData?.[element?.variableY]?.forEach((item, i) => {
              let mappingConfig = element?.variableYConfig;
              let profileConf = this.widget.machineProfile?.[mappingConfig?.key ?? 'consumables']?.find(x => x?.[mappingConfig?.idKey ?? 'id'] == item);
              let v = profileConf?.[mappingConfig?.outputKey ?? 'label'];
              newData[element.variableY + '_parsed'].push(mappingConfig?.translate ? this.translate.instant(v ?? '-') : v);
              newData[element.variableY + '_complete'].push(profileConf);
            });
          } catch (error) { console.log(error) }
        }

        // // // // // // // // // // //
        // HEATMAP
        // // // // // // // // // // //
        if (element?.type == 'heatmap') {

          try {

            xVals = [];
            yVals = [];
            zVals = [];

            xValsUnparsed = [];
            yValsUnparsed = [];

            xValsComplete = [];
            yValsComplete = [];

            textHeatmap = [];

            let heatmap = [];

            let varX = element?.variableXConfig != null ? (element.variableX + '_parsed') : element.variableX;
            let varY = element?.variableYConfig != null ? (element.variableY + '_parsed') : element.variableY;

            for (let i = 0; i < newData[varX].length; i++) {

              let xVal = element?.maxStringLengthX ? (newData[varX][i]?.length > element.maxStringLengthX ? (newData[varX][i]?.substring(0, element.maxStringLengthX) + '...') : newData[varX][i]) : newData[varX][i];

              if (!xVals.includes(xVal)) {

                // If the x values are translated, add the correct translations
                xVals.push(xVal);

                // Keep the unparsedValues in case of need
                xValsUnparsed.push(newData[element.variableX][i]);

                // Keep the complete config (es. aggregations/consumables from profile) if needed
                if (element?.variableXConfig != null) xValsComplete.push(newData[element.variableX + '_complete'][i]);
              }

              let yVal = element?.maxStringLengthY ? (newData[varY][i]?.length > element.maxStringLengthY ? (newData[varY][i]?.substring(0, element.maxStringLengthY) + '...') : newData[varY][i]) : newData[varY][i];

              if (!yVals.includes(yVal)) {
                // If the x value or the y values are translated, add the correct translations
                yVals.push(yVal);

                // Keep the unparsedValues in case of need
                yValsUnparsed.push(newData[element.variableY][i]);

                // Keep the complete config (es. aggregations/consumables from profile) if needed
                if (element?.variableYConfig != null) yValsComplete.push(newData[element.variableY + '_complete'][i]);
              }

              heatmap.push({
                x: xVal,
                y: yVal,
                value: newData[element.variableZ][i],
              });

            }
            yVals.forEach(y => {

              zVals.push(xVals.map(x => {
                let ix = heatmap.findIndex(hm => hm.y == y && hm.x == x);
                if (ix != -1) return heatmap[ix].value != null ? heatmap[ix].value * (element?.multiplier ?? 1) : null;
                return null;
              }));

              textHeatmap.push(xVals.map(x => {
                let ix = heatmap.findIndex(hm => hm.y == y && hm.x == x);
                if (ix != -1) {
                  let currentVal = heatmap[ix].value;
                  currentVal = currentVal != null ? (this.filterService.parseObjFromConfig(currentVal,
                    element?.zInfos ?? {
                      type: "value",
                      decimals: element?.decimals ?? 2,
                      multiplier: element?.multiplier ?? 1
                    }, true, 'type', true)
                  ) : '-';

                  let addClick = this.widget?.config?.plotConfig?.params?.clickable ? ("<b>" + this.translate.instant("GLOBAL.CLICK_TO_EXPAND") + "</b>") : '';
                  return (
                    "<b>" + this.translate.instant(element?.labelX ?? "GLOBAL.AGGREGATE") + "</b>: " + heatmap[ix].x + "<br>" +
                    "<b>" + this.translate.instant(element?.labelY ?? "GLOBAL.CONSUMABLE") + "</b>: " + heatmap[ix].y + "<br>" +
                    "<b>" + this.translate.instant(element?.labelZ ?? "GLOBAL.CORRELATION_COEFFICIENT") + "</b>: " + currentVal + "<br>"
                    + addClick
                  );
                }
                return null;
              }));
            });

          } catch (error) { console.log(error) }
        }

        if (element?.variableY == "variableY" && element?.valueY != null && !element?.nestedObject) {
          newData["variableY"] = newData?.[element?.variableX]?.map(x => element?.valueY);
        }

        let arrayToUseForHoverText = newData?.[element?.variableY];

        if (element?.nestedObject) {
          let splittedVarX = element?.variableX?.split(element?.variableToSplit ?? '.');
          let xVars = this.clonerService.deepClone(newData);
          splittedVarX?.forEach(sv => xVars = xVars?.[sv]);
          newData[element.variableX] = xVars;

          if (element?.variableY == "variableY" && element?.valueY != null && element?.nestedObject) {
            newData["variableY"] = newData?.[element?.variableX]?.map(x => element?.valueY);
          }

          let splittedVarY = element?.variableY?.split(element?.variableToSplit ?? '.');
          arrayToUseForHoverText = this.clonerService.deepClone(newData);
          splittedVarY?.forEach(sv => arrayToUseForHoverText = arrayToUseForHoverText?.[sv]);
          newData[element.variableY] = arrayToUseForHoverText;

        }

        if (element?.type == 'pie') arrayToUseForHoverText = newData?.[element?.variableValues];

        let hovertext = arrayToUseForHoverText?.map((x: any, i: any) => {

          let hoverFormat: any = {
            pieLabel: element.pieLabel ?? '-',
            label: element.label ?? '-',
            labelXAxis: element.labelXAxis,
            unitXAxis: element.unitXAxis,
            type: 'value',
            multiplier: element?.multiplier ?? 1,
            unit: this.translate.instant(element?.unit ?? "-"),
            decimals: element?.decimals ?? 2,
          };

          if (element?.hoverFormat != null && Object.keys(element?.hoverFormat)?.length > 0) hoverFormat = { ...hoverFormat, ...this.clonerService.deepClone(element?.hoverFormat) };

          let label = hoverFormat.label;
          if (typeof hoverFormat?.label == 'object') {
            let translObj: any = {};
            translObj[hoverFormat.label?.secondParamKey ?? 'kpi'] = this.translate.instant(hoverFormat.label?.secondParam ?? "-");
            label = this.translate.instant(hoverFormat.label?.firstParam, translObj);
          } else {
            label = this.translate.instant(hoverFormat.label ?? '-');
          }

          let date = null;
          if (newData?.[element.variableX]?.length > 0 && typeof newData?.[element.variableX]?.[i] == 'string' && moment(newData?.[element.variableX]?.[i]).isValid() && !element?.hideDateInHover) {
            let valToCheck = newData?.[element.variableX]?.[i];
            // Check that the date is not a float (es: "1" or "0"). The moment.isValid() does not handle these cases
            if (!this.filterService.isFloat(valToCheck) && valToCheck != 0 && valToCheck != -1) {
              date = '<b>' + this.translate.instant('GLOBAL.DATE') + '</b>: ' + this.filterService.parseMoment(newData?.[element.variableX]?.[i], plotLayout?.[element?.xaxis?.replace("x", "xaxis") ?? 'xaxis']?.ticktext?.dateFormat ?? 'default', this.widget?.data?.timezone);
            }
          }

          let xVal = null;
          if (hoverFormat?.labelXAxis != null && newData?.[element.variableX]?.length > 0 && typeof newData?.[element.variableX]?.[i] == 'number') xVal = '<b>' + this.translate.instant(hoverFormat?.labelXAxis) + '</b>: '
            + this.filterService.parseGaugeValue(newData?.[element.variableX]?.[i], 2, 1) + (hoverFormat?.unitXAxis != null ? (' [' + hoverFormat?.unitXAxis + ']') : '');

          let pieLabel = null;
          if (newData?.[element.variableLabels]?.length > 0) pieLabel = '<b>' + this.translate.instant(hoverFormat?.pieLabel ?? 'GLOBAL.LABEL') + '</b>: ' + this.translate.instant(newData?.[element.variableLabels]?.[i] ?? '-');

          let customHover = null;
          let customHoverFormat = element?.customHoverFormat;
          if (customHoverFormat != null) {
            customHover = '';
            let profileArray: any = null;
            let loopProfileConfig: any = null;
            if (element?.customHoverFormatConfig?.additionalConfigParams?.loopOnProfileArray) {

              loopProfileConfig = this.clonerService.deepClone(element?.customHoverFormatConfig?.additionalConfigParams?.loopOnProfileArray);

              let key = loopProfileConfig?.key ?? 'aggregations';
              profileArray = this.clonerService.deepClone(this.widget.machineProfile?.[key]) ?? [];

            }

            customHoverFormat?.forEach(conf => {
              if (conf?.parseFromProfileConfig && loopProfileConfig != null && profileArray?.length > 0) {
                profileArray?.forEach(elem => {

                  let id = elem?.[loopProfileConfig?.idVariable ?? 'id'];
                  let label = elem?.[loopProfileConfig?.labelVariable ?? 'label'];
                  let icon = elem?.[loopProfileConfig?.iconVariable ?? 'icon'];

                  let unit = elem.unit;
                  if (loopProfileConfig?.unitVariable != null) unit = elem?.[loopProfileConfig?.unitVariable ?? 'unit'];

                  let newConf: any = Object.assign(this.clonerService.deepClone(conf), {
                    variable: conf.variable?.replace("{{id}}", id),
                    orderBy: conf.orderBy?.replace("{{id}}", id),
                    label: conf.label?.replace("{{label}}", label ?? '-'),
                    unit: conf.unit?.replace("{{unit}}", unit ?? '-'),
                    icon: icon,
                  });

                  customHover += '<b>' + this.translate.instant(newConf?.label ?? '-') + '</b>: ';
                  customHover += this.filterService.parseObjFromConfig(newData?.[newConf.variable]?.[i], newConf, true, "type", newConf?.unit != null) + '<br>';
                });
              } else {
                customHover += '<b>' + this.translate.instant(conf?.label ?? '-') + '</b>: ';
                customHover += this.filterService.parseObjFromConfig(newData?.[conf.variable]?.[i], conf, true, "type", conf?.unit != null) + '<br>';
              }
            });
          }

          return (element?.onlyCustomHover ? '' : ('<b>' + this.translate.instant(label) + '</b>: ' + this.filterService.parseObjFromConfig(x, hoverFormat, true, 'type', true) + '<br>')) + (date ?? '') + (pieLabel ?? '') + (xVal ?? '') + (customHover ?? '');
        });

        // if (element.hasOwnProperty('multiplier')) {
        //   let a: [] = this.clonerService.deepClone(newData?.[element.variableY]) ?? [];
        //   let c = a?.map((a: any) => this.filterService.parseDuration(a, 's', 'HH:mm(trimmed)'));
        //   try { newData[element.variableY] = c }
        //   catch (error) { console.log(error) }
        // }

        let multiplier = 1;
        if (element.hasOwnProperty('multiplier')) multiplier = typeof element.multiplier == 'number' ? element.multiplier : this.filterService.parseNumber(element.multiplier);

        let arraysToCheck = [newData?.[element.variableX], newData?.[element.variableY]];
        if (element?.type == 'pie') arraysToCheck = [newData?.[element.variableValues], newData?.[element.variableLabels]];

        let colorArray = null;
        if (this.widget.config?.plotConfig?.additionalConfigParams?.traceColorsFromProfile != null && element.color == null) {
          try {
            let conf = this.widget.config?.plotConfig?.additionalConfigParams?.traceColorsFromProfile;
            colorArray = newData[conf.variable].map((x: any) => this.widget.machineProfile[conf.key].find((y: any) => y.id == x).color);
          } catch (error) { }
        }

        if (element.colorationOptions) {
          let co = element.colorationOptions;

          if (co.function == 'greaterLower') {

            if (element.marker == null) element.marker = {}

            element.marker.color = unparsedData.reduce((acc, val) => {
              let color = co.outOfThresholdColor ?? "#FF5757";
              if (val[element.variableY] != null && val[element.variableY] > co.lowerThreshold && val[element.variableY] < co.upperThreshold) {
                color = co.normalColor ?? "#66DA26";
              }
              acc.push(color);
              return acc;
            }, []);

            // let max = 18;
            // let min = 8;
            // let variable = "nir4_Ceneri_cpk";

            // let xMax = Math.max(...unparsedData.map(x => x?.[variable]));
            // let xMin = Math.min(...unparsedData.map(x => x?.[variable]));

            // console.log({ xMax }, { xMin });

            // element.marker.size = unparsedData.reduce((acc, val) => {
            //   // Linear
            //   let color = min + ((val?.[variable] - minVal) / (maxVal - minVal)) * (max - min);

            //   // Quadratic
            //   const t = (val?.[variable] - xMin) / (xMax - xMin);
            //   const tSquared = t * t;
            //   let color = min + (max - min) * tSquared;

            //   acc.push(color);
            //   return acc;
            // }, []);

            // console.log(element.marker.size);

          }
        }

        if (arraysToCheck?.every(x => x?.length > 0)) {

          let label = element.label;
          if (typeof element?.label == 'object') {
            let translObj: any = {};
            translObj[element.label?.secondParamKey ?? 'kpi'] = this.translate.instant(element.label?.secondParam ?? "-");
            label = this.translate.instant(element.label?.firstParam, translObj);
          } else {
            label = this.translate.instant(element.label ?? '-');
          }

          let xValues = xVals ?? newData?.[element.variableX]?.map((a: any) => {
            if (a == null) return null;
            if (typeof a != 'number') return a;
            return a * (element.multiplierX ?? 1);
          });

          let yValues = yVals ?? newData?.[element.variableY]?.map((a: any) => {
            if (a == null) return null;
            if (typeof a != 'number') return a;
            return this.filterService.convertUnit(element.unit, a * multiplier).value;
          });

          let zValues = zVals ?? newData?.[element.variableZ];
          let finalHovertext = textHeatmap ?? hovertext;

          if (!xVals && element.sortingKey) {

            let listToSort = newData?.[element.sortingKey].map((elem, idx) => {
              let row: any = {
                index: elem,
                x: xValues[idx],
                y: yValues[idx],
                z: zValues,
                ht: finalHovertext[idx]
              };
              if (colorArray) row.color = colorArray[idx];
              return row;
            });

            listToSort.sort((a, b) => a.index - b.index);

            xValues = listToSort.map(elem => elem.x);
            yValues = listToSort.map(elem => elem.y);
            finalHovertext = listToSort.map(elem => elem.ht);
            if (colorArray) colorArray = listToSort.map(elem => elem.color);
          }

          colorArray = colorArray ?? newData?.[element?.variableColor];

          let hoverInfoType = element?.hoverInfoType ?? 'text';

          let x: any = {
            x: xValues,
            y: yValues,
            xUnparsed: xValsUnparsed,
            yUnparsed: yValsUnparsed,
            xComplete: xValsComplete,
            yComplete: yValsComplete,
            unparsedData: unparsedData,
            yVariable: element.variableY,
            xVariable: element.variableX,
            z: zValues,
            labels: newData?.[element.variableLabels]?.map(x => this.translate.instant(x ?? '-')),
            values: newData?.[element.variableValues]?.map((a: any) => a != null ? this.filterService.convertUnit(element.unit, a * multiplier).value : null),
            type: element.type,
            offsetgroup: element.offsetgroup,
            hoverinfo: hoverInfoType,
            hovertext: finalHovertext,
            yaxis: element.yaxis,
            xaxis: element.xaxis ?? 'x',
            text: element.hoverText,
            name: label,
            marker: element.marker ?? {
              color: element.color
            },
            line: {
              dash: element?.dash ?? 'solid',
              color: element?.lineColor,
              shape: element?.shape
            },
            showlegend: element.showlegend ?? true
          }

          if (aggrBars != null && element?.addAggrBars) {
            x.width = aggrBars.map(x => x.width);
            x.offset = aggrBars.map(x => x.offset);
          }

          if (element.hovertemplate) x.hovertemplate = element.hovertemplate?.reduce((acc, val) => {
            acc += this.translate.instant(val) + " ";
            return acc;
          }, "");

          // TODO: add the commented function below, remove all the statements afterwards and check that it works
          // element = { ...x, ...element };
          // for (let asf of element) {
          //   if (!x.hasOwnProperty(asf)) {
          //     x[asf] = element[asf]
          //   }
          // }

          if (element.whiskers != null) x.error_y = {
            type: 'data',
            array: newData?.[element.whiskers],
            visible: true,
            color: "black"
          };
          if (element.mode != null) x.mode = element.mode;
          if (element.legendgroup != null) x.legendgroup = element.legendgroup;
          if (element.connectgaps != null) x.connectgaps = element.connectgaps;
          if (element.visible != null) x.visible = element.visible;
          if (element.traceType != null) x.traceType = element.traceType;
          if (element.automargin != null) x.automargin = element.automargin;
          if (element.hole != null) x.hole = element.hole;
          if (element.transforms != null) x.transforms = element.transforms;
          if (element.colorbar != null) x.colorbar = element.colorbar;
          if (element.zmin != null) x.zmin = element.zmin;
          if (element.zmax != null) x.zmax = element.zmax;
          if (element.colorscale != null) x.colorscale = element.colorscale;
          if (element.boxpoints != null) x.boxpoints = element.boxpoints;
          if (element.outsidetextfont != null) x.outsidetextfont = element.outsidetextfont;
          if (element.opacity != null) x.opacity = element.opacity;

          if (colorArray) {
            if (element.type == 'pie') x.marker.colors = colorArray;
            else x.marker.color = colorArray;
          }

          traces.push(x);

        }

        if (element?.type == 'pie' && element?.total) {

          let valuesToParse = newData?.[element.variableValues];

          if (valuesToParse?.length > 0 && valuesToParse?.filter(x => Array.isArray(x) && x?.length > 0)?.length > 0) {
            valuesToParse = valuesToParse?.reduce((acc, val) => {
              let obj = val;
              if (Array.isArray(val) && val?.length > 0) obj = val[0];
              acc.push(obj);
              return acc;
            }, []);
          }

          let val = this.filterService.sum(valuesToParse);

          let strVal = this.filterService.parseGaugeValue(this.filterService.convertUnit(element.unit, val).value, element.decimals ?? 0, element.multiplier ?? 1);

          let unit = element.unit != null ? (' ' + this.translate.instant(this.filterService.convertUnit(element.unit).unit ?? '-')) : '';

          annotations.push(
            {
              font: {
                size: 18,
                color: this.appConfig?.plotlyDefaultColors?.font ?? '#000000'
              },
              showarrow: false,
              // text: "50",
              text: strVal + unit,
              x: 0.5,
              y: 0.5
            }
          );
        }
      });

      // // // // // // // // // // // // // // // // // // // // // // // // // // // //
      // END TRACES CONFIG
      // // // // // // // // // // // // // // // // // // // // // // // // // // // //

      plotLayout.annotations = annotations;

      if (newData != null && typeof plotLayout?.xaxis?.ticktext == 'object') {
        if (plotLayout?.xaxis.ticktext?.format === 'date') {
          plotLayout.xaxis.tickvals = newData?.[plotLayout?.xaxis.ticktext?.variable];
          plotLayout.xaxis.ticktext = newData?.[plotLayout?.xaxis.ticktext?.variable]?.map((x: any) => this.filterService.parseMoment(x, plotLayout?.xaxis.ticktext?.dateFormat ?? 'default', this.widget?.data?.timezone))
        }
      }

      // Parse update menus (if present)
      if (updatemenus?.length > 0) {
        plotLayout.updatemenus = updatemenus.reduce((acc, menu) => {
          menu?.buttons?.forEach(button => {
            button.label = this.translate.instant(button?.label ?? '-');

            if (button?.args?.filter(arg => arg.type == 'toggleTraces')?.length > 0) {
              button.args.forEach((arg, argIdx) => {
                if (arg.type == 'toggleTraces') button.args[argIdx] = { visible: traces.map((x: any) => x?.[arg?.config?.variable ?? "traceType"] == arg?.config?.value) };
              });
            }
          });
          acc.push(menu);
          return acc;
        }, []);
      }

      if (this.widget.config?.customShapesOptions) {

        let cso = this.widget.config?.customShapesOptions;
        if (cso.function == 'batches') {

          let customColors = cso.colors ?? ["#546E7A", "#b0b0b0"];

          unparsedData = unparsedData.sort(this.filterService.sortByProperty("timestamp", "asc", true));
          let batches = unparsedData?.reduce((acc, val) => {

            if (acc.length == 0) {
              acc.push({
                batch: val.parent_batch_id,
                timeStart: val.timestamp
              });
              return acc;
            }

            let batchIdx = acc.findIndex(x => x.batch == val.parent_batch_id);

            // console.log({ batchIdx });

            if (batchIdx != -1) {
              acc[batchIdx].timeEnd = val.timestamp;
              return acc;
            }

            acc.push({
              batch: val.parent_batch_id,
              timeStart: val.timestamp
            });
            return acc;
          }, []);

          // console.log({ batches });

          plotLayout.shapes = (plotLayout.shapes ?? []).concat(batches.map((x, i) => {
            return {
              type: 'rect',
              xref: 'x',
              yref: 'paper',
              x0: x.timeStart,
              y0: 0,
              x1: x.timeEnd,
              y1: 1,
              fillcolor: customColors[i % 2],
              opacity: 0.1,
              line: {
                width: 0
              }
            }
          }));
        }

      }

      // Translate titles of yaxes (if present)
      if (Object.keys(plotLayout)?.filter(x => x.startsWith("yaxis"))?.length > 0) {
        Object.entries(plotLayout)?.filter((x: any) => x[0].startsWith("yaxis"))?.forEach((kv: any) => {

          if (kv?.[1]?.title != null) {

            let yaxisTitle = '';

            try { yaxisTitle = this.translate.instant(kv?.[1]?.title?.text ?? kv?.[1]?.title ?? '-') }
            catch (error) { console.log(error) }

            // Add unit to title (if not already present)
            if (kv?.[1]?.unit != null && !yaxisTitle.endsWith((' ' + this.translate.instant(kv?.[1]?.unit ?? '-')))) {
              try { yaxisTitle += (' ' + this.translate.instant(kv?.[1]?.unit ?? '-')) }
              catch (error) { console.log(error) }
            }

            plotLayout[kv[0]].title = yaxisTitle;
          }

          let conf = kv[1];
          try {
            if (conf.tickvalsConfig != null) plotLayout[kv[0]].tickvals = newData?.[conf.tickvalsConfig?.variable];
            if (conf.ticktextConfig != null) {
              if (conf.ticktextConfig?.type == null) {
                plotLayout[kv[0]].ticktext = newData?.[conf.ticktextConfig?.variable];
              }
              else if (conf.ticktextConfig?.type == 'configFromProfile') {

                plotLayout[kv[0]].ticktext = newData?.[conf.ticktextConfig?.variable]?.map((item, i) => {
                  let mappingConfig = conf.ticktextConfig;
                  let v = this.widget.machineProfile?.[mappingConfig?.key ?? 'consumables']?.find(x => x?.[mappingConfig?.idKey ?? 'id'] == item)?.[mappingConfig?.outputKey ?? 'label'];
                  return mappingConfig?.translate ? this.translate.instant(v ?? '-') : v;
                });
              }
            }
          }
          catch (error) { console.log(error) }
        });
      }

      // Translate titles of xaxes (if present)
      if (Object.keys(plotLayout)?.filter(x => x.startsWith("xaxis"))?.length > 0) {
        Object.entries(plotLayout)?.filter((x: any) => x[0].startsWith("xaxis"))?.forEach((kv: any) => {

          if (kv?.[1]?.title != null) {

            let xaxisTitle = '';

            try { xaxisTitle = this.translate.instant(kv?.[1]?.title?.text ?? kv?.[1]?.title ?? '-') }
            catch (error) { console.log(error) }

            // Add unit to title (if not already present)
            if (kv?.[1]?.unit != null && !xaxisTitle.endsWith((' ' + this.translate.instant(kv?.[1]?.unit ?? '-')))) {
              try { xaxisTitle += (' ' + this.translate.instant(kv?.[1]?.unit ?? '-')) }
              catch (error) { console.log(error) }
            }

            plotLayout[kv[0]].title = xaxisTitle;

          }

          let conf = kv[1];
          try {
            if (conf.tickvalsConfig != null) plotLayout[kv[0]].tickvals = newData?.[conf.tickvalsConfig?.variable];
            if (conf.ticktextConfig != null) {
              if (conf.ticktextConfig?.type == null) {
                plotLayout[kv[0]].ticktext = newData?.[conf.ticktextConfig?.variable];
              }
              else if (conf.ticktextConfig?.type == 'configFromProfile') {

                plotLayout[kv[0]].ticktext = newData?.[conf.ticktextConfig?.variable]?.map((item, i) => {
                  let mappingConfig = conf.ticktextConfig;
                  let v = this.widget.machineProfile?.[mappingConfig?.key ?? 'consumables']?.find(x => x?.[mappingConfig?.idKey ?? 'id'] == item)?.[mappingConfig?.outputKey ?? 'label'];
                  return mappingConfig?.translate ? this.translate.instant(v ?? '-') : v;
                });
              }
            }
          }
          catch (error) { console.log(error) }
          // try { plotLayout[kv[0]].title = this.translate.instant(kv?.[1]?.title?.text ?? kv?.[1]?.title ?? '-') }
        });
      }

      let params: any = this.widget.config?.plotConfig?.params;
      if (params != null) {
        Object.keys(params).forEach((key: any) => {
          let newVar;
          if (typeof params[key] == 'string' && params.hasOwnProperty(key) && params[key] != null && params[key].startsWith('$$$')) newVar = params[key].replace('$$$', '');
          params[key] = newVar != null ? this.widget.data[newVar] : this.clonerService.deepClone(params[key]);
        });
      }
      if (params != null && params.hasOwnProperty('queryParams') && typeof params.queryParams == 'object') {
        Object.keys(params.queryParams)?.forEach((key: any) => {
          let newVar;
          if (params.queryParams[key]?.startsWith('$$$')) newVar = params.queryParams[key]?.replace('$$$', '')
          params.queryParams[key] = newVar != null ? this.widget.data[newVar] : this.clonerService.deepClone(params.queryParams[key])
        })
        if (params.hasOwnProperty('toImageButtonOptions') && Array.isArray(params.toImageButtonOptions?.filename)) {
          let filename = []
          params.toImageButtonOptions.filename.forEach((chunk: any) => {
            if (chunk.startsWith('$$$')) chunk = this.widget.data[chunk.replace('$$$', '')]
            filename.push(chunk)
          });
          params.toImageButtonOptions.filename = filename.join(' ')
        } else plotConfig.modeBarButtonsToRemove.push('toImageButton')
      }

      plotData = {
        layout: plotLayout,
        traces: traces,
        params: params
      }

    }
    // // // // // // // // // // // // // // // // // // // // // // // // // // // //
    // END CUSTOM PLOT
    // // // // // // // // // // // // // // // // // // // // // // // // // // // //

    else {
      try { plotData = this.widget?.data?.[this.widget?.config?.plotDataAttribute] }
      catch (error) { console.log(error) }
    }

    if (plotData != null && plotData.hasOwnProperty('traces') && plotData.traces != null && plotData.traces.length > 0) {

      if (plotData?.layout != null) {
        if (plotData?.layout.colorway == null) plotData.layout.colorway = this.clonerService.deepClone(this?.appInfo?.darkTheme ? this.internalDataService.defaultPlotlyColorsDark : this.internalDataService.defaultPlotlyColors);

        let mainFontColor = this.appConfig?.plotlyDefaultColors?.font ?? '#000000';

        let dark = {
          plot_bgcolor: '#00000000',
          paper_bgcolor: '#00000000',
          modebar: {
            bgcolor: '#00000000',
            color: "#64697E",
            activecolor: "#64697E",
          }
        }

        plotData.layout = { ...plotData.layout, ...dark };

        // Merge updatemenus
        // let updatemenus = { bgColor: "red" };
        // plotData.layout.updatemenus = plotData.layout.updatemenus != null ? { ...plotData.layout.updatemenus, ...updatemenus } : updatemenus;

        // Merge legend
        let legendDark = { font: { color: mainFontColor } };
        plotData.layout.legend = plotData.layout.legend != null ? { ...plotData.layout.legend, ...legendDark } : legendDark;

        // Merge x axis
        let xAxisDark = { color: mainFontColor };
        if (plotData.layout.xaxis1 == null) {
          plotData.layout.xaxis = plotData.layout.xaxis != null ? { ...plotData.layout.xaxis, ...xAxisDark } : xAxisDark;
        }

        for (let i = 1; i < 20; i++) {
          if (plotData.layout["xaxis" + i] != null) plotData.layout["xaxis" + i] = plotData.layout["xaxis" + i] != null ? { ...plotData.layout["xaxis" + i], ...xAxisDark } : xAxisDark;
        }

        // Merge y axis
        let yAxisDark = { color: mainFontColor };
        if (plotData.layout.yaxis1 == null) {
          plotData.layout.yaxis = plotData.layout.yaxis != null ? { ...plotData.layout.yaxis, ...yAxisDark } : yAxisDark;
        }

        for (let i = 1; i < 20; i++) {
          if (plotData.layout["yaxis" + i] != null) plotData.layout["yaxis" + i] = plotData.layout["yaxis" + i] != null ? { ...plotData.layout["yaxis" + i], ...yAxisDark } : yAxisDark;
        }

      }

      let cursor = null;
      if (plotData.hasOwnProperty('params') && plotData.params != null) {
        plotConfig = Object.assign(plotConfig, plotData.params);
        if (plotConfig.plotId) this.plotId = plotConfig.plotId;
        if (plotConfig.cursor) cursor = plotConfig.cursor;

      }

      let _this = this;

      setTimeout(() => {
        try {

          let plotDiv: any = document.getElementById(this.plotId);
          this.plotDiv = plotDiv;

          this.plotState = 1;

          if (plotDiv != null) {
            Plotly.react(plotDiv, plotData.traces, plotData.layout, plotConfig);
            if (cursor != null) {
              let bgLayers: any = plotDiv.getElementsByClassName('nsewdrag');

              for (let bgLayer of bgLayers) {
                bgLayer.style.cursor = cursor;
              }

            }

            if (plotConfig?.exportImage) this.exportImageData(plotDiv, plotData.plotName);

          }

          this.plotDiv = plotDiv;

          if (plotConfig.hasOwnProperty('variablesTimeseries') && plotConfig.variablesTimeseries) {
            let dragLayer: any = document.getElementsByClassName('nsewdrag')[0];

            plotDiv.on('plotly_hover', function (data: any) {
              dragLayer.style.cursor = 'pointer'
            });

            // plotDiv.on('plotly_unhover', function (data: any) {
            //   if (data) {
            //     dragLayer.style.cursor = '';
            //   }
            // });

            plotDiv.on('plotly_click', function (data: any) {
              let machineId = plotConfig.machineId != null ? plotConfig.machineId : _this.widget.data?.machineId
              plotConfig.queryParams.x = moment(data.points[0].x).toISOString()
              plotConfig.queryParams.variablesTimeseriesMode = 1
              plotConfig.queryParams.id = _this.widget.data?.aggr0
              plotConfig.queryParams.start = moment(_this.widget.data?.urlStart).toISOString()
              plotConfig.queryParams.end = moment(_this.widget.data?.urlEnd).toISOString()
              let timeAggrUnit = _this.widget.data.timeAggrUnit
              let condAnalObj = {
                from: plotConfig.queryParams.x,
                to: moment(plotConfig.queryParams.x).add(timeAggrUnit[0], timeAggrUnit[1]).toISOString(),
                timeAggrUnit: timeAggrUnit
              }

              _this.cacheService.set('condAnalFromTo', condAnalObj)
              _this.router.navigate(['/' + machineId, 'program-traceability', 'variables-timeseries'], {
                queryParams: plotConfig.queryParams
              });
            })

          }

          if (plotConfig.hasOwnProperty('showFlagAnnotations') && plotConfig.showFlagAnnotations) {
            try {

              // Width of the sidenav + margin
              this.sidenavWidth = document.getElementsByClassName("sidenav-container")[0]?.getBoundingClientRect()?.width + 20;

              // Exact plot start
              this.realPlotPosition = document.getElementsByClassName("layer-above")[0]?.getBoundingClientRect();

              this.flagsConfig = this.widget.data?.[this.widget.config?.flagEventsDataAttribute];
              this.widget.isFlags = this.flagsConfig != null && Array.isArray(this.flagsConfig) && this.flagsConfig.length > 0;

              if (this.widget.isFlags) this.flagsNumber = this.flagsConfig.length;

            } catch (error) {
              console.log(error);
            }
          }

          if (type == null) {

            // // // // // // // // // // // // // // // // // // // // // // // // // // // //
            // CUSTOM PLOT PARAM
            // // // // // // // // // // // // // // // // // // // // // // // // // // // //

            if (plotConfig?.customDialogFunction != null) {
              let dragLayers: any = document.getElementsByClassName('nsewdrag');

              let showClick = plotConfig?.clickable ?? true;

              if (showClick) {
                plotDiv.on('plotly_click', (eventData: any) => {
                  try {

                    if (_this.standardDialogServiceService[plotConfig.customDialogFunction] == null) {
                      _this.internalDataService.openSnackBar(_this.translate.instant('GLOBAL.NO_CLICK_FUNCTION_CONFIGURED', {
                        clickFunction: plotConfig.customDialogFunction ?? '-'
                      }), 'right', 'bottom', 4000, '', ["warning"]);
                      return;
                    }

                    let obj: any = {};

                    if (!plotConfig?.notHeatmap) {
                      let bp = eventData.points[0];
                      let xIndex = bp.pointIndex[1];
                      let yIndex = bp.pointIndex[0];

                      let xVal = bp.data.xComplete[xIndex];
                      let yVal = bp.data.yComplete[yIndex];
                      let currentVal = bp.data.z[yIndex][xIndex];

                      if (xVal != null && yVal != null && currentVal != null) {

                        obj = {
                          xVal: xVal,
                          yVal: yVal,
                          currentVal: currentVal,
                        };

                        _this.standardDialogServiceService[plotConfig.customDialogFunction](_this.widget.referenceComponent, obj);
                      }
                    }
                    else {

                      let bp = eventData.points[0];

                      obj = {
                        value: bp.data.unparsedData[bp.pointIndex],
                        yVariable: bp.data.yVariable,
                        xVariable: bp.data.xVariable,
                      };

                      // console.log({ obj });

                      _this.standardDialogServiceService[plotConfig.customDialogFunction](_this.widget.referenceComponent, obj);

                    }

                    // else {
                    //   _this.internalDataService.openSnackBar(_this.translate.instant("no fucking value! you dumb bitch?"), 'right', 'bottom', 4000, '', ["warning"]);
                    // }

                  } catch (error) { console.log(error) }
                });
                plotDiv.on('plotly_hover', function (data: any) {
                  for (let i = 0; i < dragLayers?.length; i++) {
                    try { dragLayers[i].style.cursor = 'pointer' } catch (error) { }
                  }
                });
                plotDiv.on('plotly_unhover', function (data: any) {
                  for (let i = 0; i < dragLayers?.length; i++) {
                    try { dragLayers[i].style.cursor = '' } catch (error) { }
                  }
                });
              }

            }

            if (plotConfig.hasOwnProperty('plotType') && plotConfig.plotType) {
              plotDiv.on('plotly_update', function (data: any) {
                try {
                  if (data != null && data.hasOwnProperty('data') && data.data != null &&
                    Array.isArray(data.data) && data.data.length > 0 && data.data[0] != null && data.data[0].hasOwnProperty('type') &&
                    Array.isArray(data.data[0].type) && data.data[0].type.length > 1 && data.data[0].type[1] != null) {

                    _this.cacheService.set("typePlot", data.data[0].type[data.data[0].type.length - 1]);
                  }
                } catch (error) { }
              });
            }

            if (plotConfig.hasOwnProperty('boxDetail') && plotConfig.boxDetail) {
              let dragLayer: any = document.getElementsByClassName('nsewdrag')[0];
              plotDiv.on('plotly_click', function (data: any) {
                try {

                  if (data.points[0].data.x.length > 0 &&
                    data.points[0].data.x.every((element: any) => (element == null || typeof element == 'number'))) {

                    var unit0 = data.points[0].xaxis.title.text.split("[")[1];
                    var unit = unit0.split("]")[0];

                    var boxData = {
                      data: data.points[0].data.x,
                      name: data.points[0].data.name,
                      unit: unit,
                      multiplier: data.points[0].data.multiplier,
                      statisticalData: data.points[0].data.boxPlotData,
                      color: data.points[0].fullData.line.color,
                    };

                    _this.internalDataService.setBoxPlotData(boxData);

                  } else if (data.points[0].data != null && data.points[0].data.hasOwnProperty('boxPlotInfo') &&
                    data.points[0].data.hasOwnProperty('boxPlotInfo') != null) {

                    let bp = data.points[0];

                    let bpInfo = bp.data.boxPlotInfo[bp.pointIndex[0]][bp.pointIndex[1]].filter((x: any) => x != null);
                    if (bpInfo.length == 0) return;
                    let boxData = {
                      data: bpInfo,
                      name: bp.y + ' - ' + bp.x,
                      unit: bp.data.unit,
                      color: null,
                    };

                    _this.internalDataService.setBoxPlotData(boxData);
                  }
                } catch (error) { }
              });
              plotDiv.on('plotly_hover', function (data: any) {
                if (data) {
                  dragLayer.style.cursor = 'pointer';
                }
              });
              plotDiv.on('plotly_unhover', function (data: any) {
                if (data) {
                  dragLayer.style.cursor = '';
                }
              });
            }

            if (plotConfig?.linkToHMCycles) {
              let dragLayers: any = document.getElementsByClassName('nsewdrag');
              plotDiv.on('plotly_click', function (data: any) {
                try {

                  let cycle = data.points[0].data.cycleInfos[data.points[0].pointIndex];

                  _this.internalDataService.parseSingleCycleInfos(cycle, true);

                  let a = moment();
                  let b = moment().subtract(365, 'days');

                  let days: any = [];
                  for (let m = moment(a); b.diff(m, 'days') < 0; m.subtract(1, 'days')) {
                    days.push(moment(m).format("YYYY-MM-DD"));
                  }

                  _this.cacheService.set("selectedCycle", cycle);
                  _this.cacheService.set("cycleDays", {
                    list: days,
                    selected: moment(cycle.timestamp).format("YYYY-MM-DD"),
                  });

                  let url: any = [_this.cacheService.get("machineId"), 'health-monitoring', 'cycle-exploration'];
                  _this.router.navigate(url, { queryParams: { machineRefId: _this.route.snapshot.queryParams.machineRefId, componentId: _this.route.snapshot.queryParams.componentId } })

                } catch (error) {
                  console.log(error);
                }
              });
              plotDiv.on('plotly_hover', function (data: any) {
                for (let i = 0; i < dragLayers?.length; i++) {
                  try { dragLayers[i].style.cursor = 'pointer' } catch (error) { }
                }
              });
              plotDiv.on('plotly_unhover', function (data: any) {
                for (let i = 0; i < dragLayers?.length; i++) {
                  try { dragLayers[i].style.cursor = '' } catch (error) { }
                }
              });
            }

            if (plotConfig.hasOwnProperty('stateTimelineAdvanced') && plotConfig.stateTimelineAdvanced) {
              let dragLayers: any = document.getElementsByClassName('nsewdrag');

              let showClick = true;

              try {
                showClick = !_this.widget.profile.flags.hideStateTimelineDetail;
              } catch (error) { }

              if (showClick) {
                plotDiv.on('plotly_click', function (data: any) {
                  try {

                    if (data.points[0].data != null) {

                      let end = moment(data.points[0].x).add(1, data.points[0].data.timeAggrUnit.unit);

                      if (_this.appConfig?.MAT2) end.subtract(1, "s");

                      var zoomedIntervalStateTimelineAdvanced = {
                        start: moment(data.points[0].x),
                        end: end,
                      };

                      _this.internalDataService.setZoomedInterval(zoomedIntervalStateTimelineAdvanced);

                    }
                  } catch (error) { console.log(error) }
                });
                plotDiv.on('plotly_hover', function (data: any) {
                  for (let i = 0; i < dragLayers?.length; i++) {
                    try { dragLayers[i].style.cursor = 'pointer' } catch (error) { }
                  }
                });
                plotDiv.on('plotly_unhover', function (data: any) {
                  for (let i = 0; i < dragLayers?.length; i++) {
                    try { dragLayers[i].style.cursor = '' } catch (error) { }
                  }
                });
              }

            }

            if (plotConfig.hasOwnProperty('actions')) {
              // let dragLayers: any = document.getElementsByClassName('nsewdrag');

              for (let action of Object.keys(plotConfig.actions)) {
                let callback = plotConfig.actions[action];
                plotDiv.on(action, callback);
              }

            }

            if (plotConfig.hasOwnProperty('onClickCycleDetail') && plotConfig.onClickCycleDetail) {
              let dragLayers: any = document.getElementsByClassName('nsewdrag');

              plotDiv.on('plotly_click', function (data: any) {
                try {
                  if (data.points[0].data.timeRange != null && data.points[0].pointIndex != null) {
                    var from = data.points[0].data.timeRange[data.points[0].pointIndex][0];
                    var to = data.points[0].data.timeRange[data.points[0].pointIndex][1];

                    var cycleDetail = {
                      from: from,
                      to: to
                    };
                    _this.internalDataService.setClickCycleDetail(cycleDetail);
                  }

                } catch (error) { }
              });
              plotDiv.on('plotly_hover', function (data: any) {
                try {
                  if (data) dragLayers[1].style.cursor = 'pointer';
                } catch (error) {
                  console.log(error);
                }
              });
              plotDiv.on('plotly_unhover', function (data: any) {
                try {
                  if (data) dragLayers[1].style.cursor = '';
                } catch (error) {
                  console.log(error);
                }
              });

            }

            if (plotConfig.hasOwnProperty('relayoutVariables') && plotConfig.relayoutVariables) {
              plotDiv.on('plotly_relayout', function (eventData: any) {
                try {

                  let zoomVariables = {
                    from: eventData['xaxis.range[0]'],
                    to: eventData['xaxis.range[1]'],
                  };

                  try {
                    if (plotData.traces[0].x.slice(-1)[0] == moment(eventData['xaxis.range[1]']).format('YYYY-MM-DDTHH:mm:ss.SSSSSS'))
                      zoomVariables.to = null;
                  } catch (error) { }

                  _this.internalDataService.setZoomedIntervalVariables(zoomVariables);
                } catch (error) { }
              });
            }

            if (plotConfig.hasOwnProperty('chartBackgroundCustom') && plotConfig.chartBackgroundCustom) {
              updateChartBackgroundCustom(_this)
            }

            if (plotConfig.hasOwnProperty('plotId') && plotConfig.plotId == 'sct-chart') {
              plotDiv.on('plotly_click', function (data: any) {
                try {
                  let dp = {
                    action: "sctDatapoint",
                    x: data.points[0].x,
                    y: data.points[0].y
                  }
                  _this.internalDataService.setDatapoint(dp);
                } catch (error) { }
              });
            }

          }

        } catch (error) {
          console.log(error);
        }
      }, 10);

    } else if (!this.widget.config.noDataPolicy) {
      this.plotState = 2;
    } else {

      switch (this.widget.config.noDataPolicy) {
        case 'dash':

          this.plotState = 3;
          break;

        case 'plot-axis':

          if (plotData.hasOwnProperty('params') && plotData.params != null) {
            plotConfig = Object.assign(plotConfig, plotData.params);
            if (plotConfig.plotId) this.plotId = plotConfig.plotId;
          }

          setTimeout(() => {

            let plotDiv: any = document.getElementById(this.plotId);
            this.plotDiv = plotDiv;

            if (plotData.layout.defaultRange) plotData.layout.xaxis.range = plotData.layout.defaultRange;

            let mainFontColor = this.appConfig?.plotlyDefaultColors?.font ?? '#000000';

            plotData.layout.shapes = [];
            plotData.layout.annotations = [{
              text: this.widget.config.noDataLabel, //this.translate.instant('GLOBAL.NO_DATA_AVAILABLE');
              xanchor: 'center',
              yanchor: 'center',
              showarrow: false,
              font: {
                color: mainFontColor,
                size: 16
              },
            }];
            this.plotState = 4; // render the html div before render the chart !!!

            try { Plotly.react(plotDiv, [], plotData.layout, plotConfig); }
            catch (error) { console.log(error); }

          }, 10);
          break;

        default:
          this.plotState = 2;
      }
    }
  }

  async exportImageData(plotDiv, plotName?) {

    let maxImages = this.cacheService.get("maxPlotImages");

    let id = `plot-continuous-exploration-image`;

    let cachedPlotImages = this.cacheService.get(id);

    if (maxImages != null && cachedPlotImages?.length >= maxImages) {
      this.cacheService.set(id, []);
    }

    // let options = { format: 'png' };
    // let options = { format: 'png', width: plotDiv.offsetWidth, height: plotDiv.offsetHeight, scale: 1 };
    let options = { format: 'png', width: 800, height: 400, scale: 1 };

    let image = await Plotly.toImage(plotDiv, options);
    // console.log({ image });

    cachedPlotImages = this.cacheService.get(id);

    if (cachedPlotImages == null) this.cacheService.set(id, [{ image: image, id: plotName }]);
    else this.cacheService.set(id, cachedPlotImages.concat({ image: image, id: plotName }));

  }
}

function generateRandomString() {
  return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

function updateChartBackgroundCustom(_this) {
  /*

  // console.log('updateChartBackgroundCustom')
  // console.log(_this.chartBackgroundCustomBefore)
  // _this.chartBackgroundCustomBefore?.nativeElement?.setAttribute('background-color', 'red');

  setTimeout(function () {
    // _this.chartBackgroundCustomBefore?.nativeElement.setAttribute('background-color', 'blue');

    // let cbc_before = $('#chart-background-custom-before').height();
    let cbc_before = _this.chartBackgroundCustomBefore.nativeElement.scrollHeight;
    let cbc_width = 0;
    let cbc_height = 0;
    let cbc_left = 0;
    let cbc_top = 0;

    // $(".clips").children().each((index, elem) => {
    //     let rect = $(elem).children();
    //     if (rect.length > 0) {
    //         rect = $(rect[0]);
    //         if ($(elem).hasClass('plotclip')) {
    //             cbc_width = rect.attr('width');
    //             cbc_height = rect.attr('height');
    //         }
    //         if ($(elem).hasClass('axesclip')) {
    //             let x = rect.attr('x');
    //             let y = rect.attr('y');
    //             if (x != 0 && y != 0) {
    //                 cbc_left = x + 'px';
    //                 cbc_top = (parseInt(y) + parseInt(cbc_before)) + 'px';
    //             }
    //         }
    //     }
    // });
    // $('#chart-background-custom').css("width", cbc_width);
    // $('#chart-background-custom').css("height", cbc_height);
    // $('#chart-background-custom').css("left", cbc_left);
    // $('#chart-background-custom').css("top", cbc_top);
    // // $('#chart-background-custom').css("background", "gray");
  }, 1000);

  */
}
