import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'app-breakdowns',
  templateUrl: './breakdowns.component.html',
  styleUrls: ['./breakdowns.component.scss'],
})
export class LeanBreakdownsComponent implements OnInit, OnDestroy {

  public isAllowedUser: boolean = true;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public isMobile: any;
  public isSmThanTablet: any;
  public mobileListener: Subscription;
  public machineProfiles: any;

  public backButton: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public pollingTime: any;
  public pollingEvents: any;

  public maxStringsLength: any;
  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;
  public aggrPlotTitle: any;

  public excludeLine: boolean = true;

  public interval: any;
  public intervalConfig: any;

  public dashboardConfig: any;

  public breakdownsData: any;
  public breakdownsColors: any;

  public mobileData: any;
  public currentSortingProperty: any;
  public sortDirection: any;
  public collapsed: any;

  public tabName: any = "breakdowns";
  public sectionName: any = "leanAnalytics";
  public dashboardName: any = "breakdowns-alarms";
  public colorsArrayName: any = "alarmsColors";
  public tablePropName: any = "alarmsInfo";
  public pollingPropName: any = "pollingAlarms";
  public dashboardNameComplete: any;

  // public referenceTablesToAggregateAutomatically: any = [
  //   {
  //     id: "historyBreakdowns",
  //     aggrUnit: "triggerState",
  //     parseTriggerStatesAsCategories: true,
  //   }
  // ]
  public referenceTablesToAggregateAutomatically: any = []

  public codeKeys: any = [];
  public codeKey: any = 'code';

  public referenceTables: any = [];
  public referenceTable: any = 'alarms';

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getEventsBreakdowns, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];
  aggregatedReferenceTableMode: boolean;
  filterButtons: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    public mobile: MobileService,
    public router: Router,
  ) {

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile;
      this.isSmThanTablet = value.isSmThanTablet;
    })

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['LEAN_ANALYTICS.TITLE', 'BREAKDOWNS.TITLE'];
    this.internalDataService.setCalendarPage(true);
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.availableMachines = this.appConfig?.[this.tabName]?.availableMachines ?? [];

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig?.[this.tabName]?.[this.pollingPropName] ?? 0;
    this.pollingEvents = Subscription;

    this.breakdownsData = null;
    this.breakdownsColors = this.appConfig?.[this.tabName]?.alarmsColors ?? ["#FF5757", "#FAC63E", "#FAF43E", "#8BE362", "#1c0221ff", "#7b5e7bff", "#938274ff", "#e9eb87ff", "#b9f18cff", "#2176aeff", "#57b8ffff", "#b66d0dff", "#fbb13cff", "#fe6847ff", "#89023eff", "#cc7178ff", "#ffd9daff", "#f3e1ddff", "#c7d9b7ff"];

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // OPEN AGGR DIALOG

  openAggrDialog(aggr: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        // console.log('afterClosed', result);
        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
      }

      this.pageState.next(isClickedSelect ? 5 : 6);

      if (!this.interval.enabledPolling) {
        this.getEvents(this, 0);
      } else {
        this.getEventsBreakdowns(this);
      }

    });
  };

  changePageAggregation(aggrDropdown: any) {
    this.aggrDropdown = this.clonerService.deepClone(aggrDropdown);

    let currentAggrConfig = this.aggregations?.find((x: any) => x.id == this.aggrDropdown);
    if (currentAggrConfig != null) this.aggrPlotTitle = this.translate.instant("GLOBAL.AGGREGATES_LIST") + ' - ' + currentAggrConfig?.label;

    this.pageState.next(5);
    if (!this.interval.enabledPolling) {
      this.getEvents(this, 0);
    } else {
      this.getEventsBreakdowns(this);
    }
  }

  onChangeCodeKey() {
    this.pageState.next(5);
    if (!this.interval.enabledPolling) this.getEvents(this, 0)
    else this.getEventsBreakdowns(this);
  }

  changeReferenceTable() {
    this.pageState.next(5);
    if (!this.interval.enabledPolling) this.getEvents(this, 0)
    else this.getEventsBreakdowns(this);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-la-breakdowns");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getEventsBreakdowns, this.getEvents, this.machine.timezone) };

  machineSelectionChange(machine: any) {

    this.filterService.filterAggregationsByMachine(this, machine);

    if (machine != null) {

      try {
        this.pollingEvents.unsubscribe();
      } catch (error) {
      }

      this.pageState.next(5);
      this.getEventsBreakdowns(this);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // events

  // polling
  getEventsBreakdowns(_this: any) {
    try {

      if (_this.cacheService.get("intervalBreakdowns") != null) {

        _this.interval = _this.cacheService.get("intervalBreakdowns");

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.backButton = [_this.machineId, "machine-supervision", "state-timeline-advanced"];
        _this.internalDataService.setBackButton(_this.backButton);

      } else {
        if (_this.cacheService.get("intervalLong") == null) {

          _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

          _this.intervalConfig = {
            list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
            selected: _this.interval
          };

          _this.cacheService.set("intervalLong", _this.intervalConfig);

        } else {
          _this.intervalConfig = _this.cacheService.get("intervalLong");
          _this.interval = _this.cacheService.get("intervalLong").selected;
        }
      }

      if (_this.pollingTime > 0) {
        _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getEvents(_this, count);
        });
      } else {
        _this.getEvents(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getEvents(_this: any, count?: any) {
    try {

      let url = '/apif/production-analytics/breakdowns/' + _this.machineId;

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone,
        codeKey: _this.codeKey ?? 'code',
        referenceTable: _this.referenceTable,
        // aggrUnit: _this.aggrDropdown
      };

      _this.aggregatedReferenceTableMode = _this.referenceTablesToAggregateAutomatically?.find(x => x?.id == _this.referenceTable) != null && _this.aggrDropdown == null;

      if (_this.aggregatedReferenceTableMode) {
        let aggrRefTable = _this.referenceTablesToAggregateAutomatically.find(x => x.id == _this.referenceTable);
        query.aggrUnit = aggrRefTable?.aggrUnit ?? 'triggerState';
        query.parseTriggerStatesAsCategories = aggrRefTable?.parseTriggerStatesAsCategories ? 1 : 0;
      }
      if (_this.filterButtons?.length) {
        payload.categoryFilters = _this.filterButtons[0]?.options?.filter(x => x.selected)?.map(x => x.id);
      }
      if (_this.aggrDropdown != null) query.aggrUnit = _this.aggrDropdown;
      if (_this.machine.profile?.additionalTags?.["lean-analytics/breakdowns"] != null) query.tag = _this.machine.profile?.additionalTags?.["lean-analytics/breakdowns"];

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      _this.filterService.filterAggregationsByMachine(_this, query.machineId);

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.breakdownsData = _this.parseBreakdownsData(data.body);

            try {

              if (_this.appConfig?.[_this.tabName]?.[_this.colorsArrayName]?.length > 0) _this.breakdownsColors = _this.appConfig?.[_this.tabName]?.[_this.colorsArrayName];

              _this.colorArray = [];

              try {

                let customColors = null;
                if (_this.aggrDropdown != null) customColors = _this.aggregationsToShowInDropdown?.find((x: any) => x.id == _this.aggrDropdown)?.colors;

                if (customColors != null) {
                  _this.colorArray = (Object.entries(customColors) as any)?.reduce((acc, [aggrName, color]) => {
                    acc.push({
                      id: aggrName,
                      color: color
                    });
                    return acc;
                  }, []);
                }

                else {
                  _this.colorArray = _this.aggrDropdown != null ? _this.breakdownsData[_this.aggrDropdown].map((x: any) => x.id).filter(_this.filterService.onlyUnique).map((x: any, idx: any) => {
                    return {
                      id: x,
                      color: _this.breakdownsColors[idx]
                    }
                  }) : [];
                }
              } catch (error) { console.log(error) }

              _this.breakdownsData.dataConfig = {};
              _this.breakdownsData.aggrDataConfig = {};

              // set plot data and configs
              _this.breakdownsData.dataConfig.title = _this.internalDataService.buildExportFileTitle(_this.breadcrumb, _this.interval, 'DD/MM/YYYY HH:mm');
              _this.breakdownsData.dataConfig.plotDataAttribute = 'plotData';
              _this.breakdownsData.aggrDataConfig.plotDataAttribute = 'aggrPlotData';

              _this.breakdownsData.plotData = _this.buildPlotConfig(_this, _this.breakdownsData);
              _this.breakdownsData.aggrPlotData = _this.buildAggrPlotConfig(_this, _this.breakdownsData);

              // set table data and config
              _this.breakdownsData.dataConfig.tableDataAttribute = 'faults';
              _this.breakdownsData.dataConfig.orderAttribute = _this.appConfig?.[_this.tabName]?.orderAttribute != null ? _this.appConfig?.[_this.tabName]?.orderAttribute : 'perc';
              _this.breakdownsData.dataConfig.cardFrame = true
              _this.breakdownsData.dataConfig.completeCardFrame = true

              _this.breakdownsData.tableInfo = _this.clonerService.deepClone(_this.appConfig?.[_this.tabName]?.[_this.tablePropName] ?? []);

              if (_this.aggregatedReferenceTableMode) {
                _this.breakdownsData.tableInfo.push({
                  variable: "category",
                  label: "ALARMS.CATEGORY",
                  type: "configFromProfile",
                  mappingConfig: {
                    key: "categories",
                    idKey: "backendValueStateTimeline",
                    outputKey: "label",
                    translate: true
                  }
                })
                // if (!_this.breakdownsData.tableColumns.includes("category")) _this.breakdownsData.tableColumns.push("category");
              }

              _this.breakdownsData.tableColumns = [];
              _this.breakdownsData.tableInfo.forEach((element: any) => {
                _this.breakdownsData.tableColumns.push(element.variable);
              });

              try {
                if (_this.aggrDropdown != null) {
                  if (_this.breakdownsData.tableInfo.findIndex((x: any) => x.variable == _this.aggrDropdown) == -1) {
                    _this.breakdownsData.tableInfo.push({
                      variable: _this.aggrDropdown,
                      orderBy: _this.aggrDropdown,
                      label: _this.translate.instant(_this.aggregationsToShowInDropdown.find((x: any) => x.id == _this.aggrDropdown).label).capitalize(),
                    });
                  }
                  if (!_this.breakdownsData.tableColumns.includes(_this.aggrDropdown)) _this.breakdownsData.tableColumns.push(_this.aggrDropdown);
                }
              } catch (error) {
                console.log(error);
              }

            } catch (error) {
              console.log(error);
            }

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseBreakdownsData(data: any) {

    try {

      if (data != null && Object.keys(data).length > 0) {

        data.faults = data.faults?.reduce((acc, val) => {

          val.codeP = this.internalDataService.parseAlarmsLabel(val.alarmCode, 'code', this.machineId);
          val.msgP = val.message ?? this.internalDataService.parseAlarmsLabel(val.alarmCode, 'message', this.machineId);
          val.durationP = this.filterService.parseTime(val.duration, 's', 'HH:mm:ss');

          // simulate trigger state (to comment)
          // val.triggerState = this.machine.profile.timeStates[Math.round(Math.random() * this.machine.profile.timeStates.length)]?.state;

          // acc.push({ ...val, ...{ triggerState: 1 } })

          acc.push(val);
          return acc;
        }, []) ?? [];

        if (this.aggregatedReferenceTableMode && !this.filterButtons?.length) {
          let filterButtons = [
            {
              variable: "category",
              label: "ALARMS.CATEGORY",
              type: "configFromProfile",
              mappingConfig: {
                key: "categories",
                idKey: "backendValueStateTimeline",
                outputKey: "label",
                translate: true
              }
            }
          ];
          this.filterButtons = this.filterService.buildFilterButtons(this, filterButtons, data.faults);

        }

        return data;
      }
      return {};

    } catch (error) {
      console.log(error);
      return {};
    }
  }

  buildPlotConfig(_this: any, data: any) {

    let aggrId: any = _this.aggrDropdown;

    // let colorArray = aggrId != null ? data.faults.map((x: any) => x[aggrId]).filter((val: any, id: any, array: any) => array.indexOf(val) == id) : [];

    let traces: any = [];

    if (!data.hasOwnProperty('faults') || data.faults == null || data.faults.length == 0) {
      return {
        layout: {},
        traces: []
      };
    }

    let range = null;

    let durationProp = 'duration';
    let sortingPropertyList = [durationProp, 'count'];
    // for each property
    sortingPropertyList.forEach((prop) => {

      // sort events
      // data.faults = data.faults?.sort(_this.filterService.sortByProperty(prop, 'desc', true)).filter((x: any) => x[prop] != null && x[prop] != 0);

      // sort events
      data.faults = data.faults?.reduce((acc, val) => {
        if (val?.[prop] != null && val?.[prop] != 0) {

          // Initialize first value
          if (acc?.length == 0) {
            acc.push(val);
            return acc;
          }

          // // // // // // // // // // // //
          // NOT historyBreakdowns
          // // // // // // // // // // // //

          // Sort by [prop] value, descending
          if (!this.aggregatedReferenceTableMode) {
            for (let i = 0; i < acc.length; i++) {
              if (val?.[prop] > acc[i][prop]) {
                acc.splice(i, 0, val);
                return acc;
              }
            }
            acc.push(val);
            return acc;
          }

          // // // // // // // // // // // //
          // historyBreakdowns
          // // // // // // // // // // // //

          // Check if the same code already exists
          let existingCodes = acc?.filter(x => x.alarmCode == val?.alarmCode);

          if (existingCodes?.length > 0) {

            // Delete all previous occurences
            existingCodes?.forEach(x => {
              let idx = acc.findIndex(xx => xx.alarmCode == x?.alarmCode && xx.category == x?.category);
              acc.splice(idx, 1);
            })

            // Calculate the sum with the new value
            let sumCode = this.filterService.sum(existingCodes.concat([val]), prop);

            // Insert in the correct spot
            let isLast = true;
            for (let i = 0; i < (this.clonerService.deepClone(acc) as any).length; i++) {
              if (sumCode > acc[i][prop]) {
                acc.splice(i, 0, ...existingCodes);
                isLast = false;
                break;
              }
            }

            // Insert in the correct spot (last value)
            if (isLast) acc.push(...existingCodes)

            // Update position
            for (let i = 0; i < existingCodes.length; i++) {
              if (val?.[prop] > existingCodes[i][prop]) {
                acc.splice(acc.findIndex(xx => xx.alarmCode == existingCodes[i]?.alarmCode && xx.category == existingCodes[i]?.category), 0, val);
                return acc;
              }
            }

            // Update position
            acc.splice(acc.findIndex(xx => xx.alarmCode == existingCodes[0]?.alarmCode && xx.category == existingCodes[0]?.category) + 1, 0, val);
            return acc;
          }

          for (let i = 0; i < acc.length; i++) {
            if (val?.[prop] > acc[i][prop]) {
              acc.splice(i, 0, val);
              return acc;
            }
          }
          acc.push(val);
          return acc;

        }
        return acc;
      }, [])

      // count total of events property
      let totSum = 0;
      data.faults.forEach((x: any) => totSum += x[prop]);

      // calculate cumulative percentage
      data.faults.forEach((x: any, idx: any) => {
        try { x.cumPerc = !isNaN(x[prop] / totSum + data.faults[idx - 1].cumPerc) ? x[prop] / totSum + data.faults[idx - 1].cumPerc : 0 }
        catch (error) { x.cumPerc = x[prop] / totSum }
      });

      // update hover text
      let hoverText = data.faults.map((x: any) => {
        let hover = '';
        x.mat = null;
        if (x.alarmCode != null) {
          hover += _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'code', _this.machineId) + '<br>';
          hover += "<b>" + _this.translate.instant('BREAKDOWNS.DESCRIPTION') + "</b>: ";
          hover += x.message ?? _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'message', _this.machineId);
          hover += '<br>';
        }
        if (x.count != null) {
          hover += "<b>" + _this.translate.instant('BREAKDOWNS.COUNT') + "</b>: ";
          hover += x.count + '<br>';
          x.mat = x.count;
        }
        if (x.duration != null) {
          hover += "<b>" + _this.translate.instant('BREAKDOWNS.DURATION') + "</b>: ";
          hover += _this.filterService.parseTime(x.duration, 's', 'HH:mm:ss') + '<br>';
          if (x.mat) {
            x.mat = _this.filterService.parseTime(x.duration / x.mat, 's', 'HH:mm:ss');
            hover += "<b>" + _this.translate.instant('BREAKDOWNS.MAT') + "</b>: ";
            hover += x.mat + '<br>';
          }
        }
        if (x.cumPerc != null) {
          hover += "<b>" + _this.translate.instant('BREAKDOWNS.CUMULATIVE_PERCENTAGE') + "</b>: ";
          hover += _this.filterService.parseGaugeValue(x.cumPerc, 1, 100) + '%<br>';
        }
        if (aggrId != null && x[aggrId] != null) {
          hover += "<b>" + _this.translate.instant(_this.aggregationsToShowInDropdown.find((x: any) => x.id == _this.aggrDropdown).label).capitalize() + "</b>: ";
          hover += x[aggrId];
        }
        if (_this.aggregatedReferenceTableMode && x.category != null) {
          hover += "<b>" + _this.translate.instant("ALARMS.CATEGORY") + "</b>: ";
          hover += _this.translate.instant(_this.machine.profile.categories.find((y: any) => y.backendValueStateTimeline == x.category)?.label ?? x.category.toString());
        }
        return hover;
      });

      let colorArray = _this.breakdownsColors[0];
      if (aggrId != null) colorArray = data.faults.map((x: any) => _this.colorArray.find((y: any) => y.id == x[aggrId])?.color ?? 'black');
      else if (_this.aggregatedReferenceTableMode) {
        colorArray = data.faults.map((x: any) => _this.machine.profile.categories.find((y: any) => y.backendValueStateTimeline == x.category)?.color ?? _this.breakdownsColors[0]);
      }

      // add trace (y)
      traces.push({
        x: data.faults.map((x: any) => aggrId != null ? x.alarmCode + '__' + x[aggrId] : x.alarmCode),
        y: data.faults.map((x: any) => prop == durationProp ? (x[prop] != null ? x[prop] / 3600 : null) : x[prop]),
        width: data.faults.length < 3 ? 0.4 : null,
        marker: {
          color: colorArray
        },
        type: 'bar',
        hoverinfo: 'text',
        text: hoverText,
        visible: prop == durationProp,
        showlegend: false,
        name: prop == durationProp ? _this.translate.instant("BREAKDOWNS.DURATION") : _this.translate.instant("BREAKDOWNS.COUNT")
      });

      // add trace (y2)
      traces.push({
        x: data.faults.map((x: any) => aggrId != null ? (x.alarmCode + '__' + x[aggrId]) : x.alarmCode),
        y: data.faults.map((x: any) => x.cumPerc),
        marker: {
          color: _this.appInfo?.darkTheme ? '#FFFFFF' : '#000000',
        },
        line: {
          color: _this.appInfo?.darkTheme ? '#FFFFFF' : '#000000',
        },
        type: 'scatter',
        hoverinfo: 'skip',
        yaxis: 'y2',
        visible: prop == durationProp,
        name: _this.translate.instant("BREAKDOWNS.CUMULATIVE_PERCENTAGE")
      });

      // set range
      if (data.faults.length > 0 && data.faults.length < 20) {
        range = [-0.5, data.faults.length - 0.5];
      } else if (data.faults.length >= 20) {
        range = [-0.5, 19.5];
      } else {
        range = [-0.5, 0.5];
      }

    });

    // set update menu buttons
    let updatemenus = [{
      buttons: [{
        args: [{
          'visible': [true, true, false, false]
        }, {
          'yaxis.ticksuffix': ' h',
        }],
        label: _this.translate.instant("BREAKDOWNS.DURATION"),
        method: 'update'
      }, {
        args: [{
          'visible': [false, false, true, true]
        }, {
          'yaxis.ticksuffix': null,
        }],
        label: _this.translate.instant("BREAKDOWNS.COUNT"),
        method: 'update'
      }],
      direction: 'left',
      pad: {
        'r': 10,
      },
      showactive: true,
      type: 'buttons',
      x: 0,
      y: 1.2,
      xanchor: 'left',
      yanchor: 'top'
    }];

    let plotLayout: any = {
      updatemenus: updatemenus,
      legend: {
        x: 0,
        y: -0.25,
        orientation: 'h',
        traceorder: 'normal'
      },
      hoverlabel: { align: 'left' },
      xaxis: {
        showgrid: false,
        zeroline: false,
        type: 'category',
        range: range,
        tickangle: 45,
        ticktext: data.faults.map((x: any) => _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'code', _this.machineId) != null ? _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'code', _this.machineId).toString().substring(0, (_this.maxStringsLength != null ? _this.maxStringsLength : 15)) : null),
        tickvals: data.faults.map((x: any) => aggrId != null ? x.alarmCode + '__' + x[aggrId] : x.alarmCode),
      },
      yaxis: {
        showgrid: false,
        zeroline: false,
        ticksuffix: ' h',
        rangemode: "tozero"
      },
      margin: {
        t: 0,
        r: 60,
        b: 0,
        l: 60,
        pad: 5
      },
      yaxis2: {
        showgrid: false,
        zeroline: false,
        tickformat: '%',
        range: [0, 1.05],
        overlaying: 'y',
        side: 'right'
      }
    };

    if (_this.aggregatedReferenceTableMode) plotLayout.hovermode = 'closest';

    let dragLayers: any = document.getElementsByClassName('nsewdrag');

    let plotData: any = {
      layout: plotLayout,
      traces: traces,
    };

    let faultAnalysisUrl = '/:machineId/lean-analytics/fault-analysis';
    let faultAnalysisPresent = _this.appConfigService.searchTabFromUrl(faultAnalysisUrl) != null;

    if (faultAnalysisPresent) {
      plotData.params = {
        actions: {
          plotly_click:
            function (data: any) {
              _this.cacheService.set("selectedFault", data?.points?.[0]?.x);
              _this.router.navigate([_this.machineId + '/lean-analytics/fault-analysis']);
            },
          plotly_hover:
            function (data: any) {
              for (let i = 0; i < dragLayers?.length; i++) {
                try { dragLayers[i].style.cursor = 'pointer' } catch (error) { }
              }
            },
          plotly_unhover:
            function (data: any) {
              for (let i = 0; i < dragLayers?.length; i++) {
                try { dragLayers[i].style.cursor = '' } catch (error) { }
              }
            }
        }
      }
    }

    return plotData;

  }

  buildAggrPlotConfig(_this: any, data: any, zoomRange?: any) {

    let aggrId: any = _this.aggrDropdown;

    if (aggrId == null || !data.hasOwnProperty(aggrId) || data[aggrId] == null || data[aggrId].length == 0) {
      return {};
    }

    let xValues: any = [];
    let yValues: any = [];
    let ids: any = [];
    let hoverText: any = [];

    data[aggrId].sort(_this.filterService.sortByProperty('downtime', 'desc', false)).forEach((aggr: any) => {
      ids.push(aggr.id);
      xValues.push(aggr.id);
      yValues.push(aggr.downtimeP * 100);// / 3600);
      hoverText.push(
        "<b>" + _this.translate.instant("BREAKDOWNS.RELATIVE_DOWNTIME") + "</b>: " + _this.filterService.parseGaugeValue(aggr.downtimeP, 1, 100) + '%' + '<br>' +
        "<b>" + _this.translate.instant("BREAKDOWNS.TOTAL_ALARM_COUNT") + "</b>: " + _this.filterService.parseGaugeValue(aggr.alarmCount, 0, 1) + '<br>' +
        "<b>" + _this.translate.instant("BREAKDOWNS.TOTAL_STOP_COUNT") + "</b>: " + _this.filterService.parseGaugeValue(aggr.stopCount, 0, 1) + '<br>' +
        "<b>" + _this.translate.instant("BREAKDOWNS.DOWNTIME") + "</b>: " + _this.filterService.parseTime(aggr.downtime, 's', 'HH:mm:ss') + '<br>' +
        "<b>" + _this.translate.instant("BREAKDOWNS.WORKING_TIME") + "</b>: " + _this.filterService.parseTime(aggr.totTime, 's', 'HH:mm:ss') + '<br>' +
        "<b>" + _this.translate.instant(_this.aggregationsToShowInDropdown.find((x: any) => x.id == _this.aggrDropdown).label).capitalize() + "</b>: " + aggr.id
      );
    });

    // var colorArray = params.colorArray;
    // let colorArray = aggrId != null ? data.faults.map((x: any) => x[aggrId]).filter((val: any, id: any, array: any) => array.indexOf(val) == id) : [];

    // console.log(colorArray);

    let traces: any = [{
      x: xValues,
      y: yValues,
      name: _this.translate.instant('BREAKDOWNS.INTERNAL'),
      text: hoverText,
      hoverinfo: 'text',
      width: xValues.length < 3 ? 0.4 : null,
      type: 'bar',
      marker: {
        color: ids.map((x: any) => (_this.colorArray.find((y: any) => y.id == x) != null) ? _this.colorArray.find((y: any) => y.id == x).color : 'black')
      }
    }];

    let plotLayout = {
      showlegend: false,
      legend: {
        x: 0,
        y: -0.2,
        orientation: 'h',
        traceorder: 'normal'
      },
      hoverlabel: { align: 'left' },
      margin: {
        t: 55,
        r: 55,
        b: 55,
        l: 55,
        pad: 5
      },
      xaxis: {
        showgrid: false,
        zeroline: false,
        type: 'category',
        ticktext: xValues.map((x: any) => x != null ? x.substring(0, (_this.maxStringsLength != null ? _this.maxStringsLength : 15)) : null),
        tickvals: xValues,
      },
      yaxis: {
        showgrid: false,
        zeroline: false,
        ticksuffix: '%',
        rangemode: "tozero"
      }
    };

    return {
      layout: plotLayout,
      traces: traces
    };

  }

  onFiltersDialogSelect() {

    // this.pageState.next(5);

    // this.breakdownsData = this.parseBreakdownsData(this.breakdownsData);
    // this.breakdownsData.plotData = this.buildPlotConfig(this, this.breakdownsData);
    // setTimeout(() => this.pageState.next(6), 10);

    this.pageState.next(5);
    if (!this.interval.enabledPolling) this.getEvents(this, 0);
    else this.getEventsBreakdowns(this);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);
  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    this.internalDataService.setCalendarPage(false);
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingEvents.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.internalDataService.setBackButton([]) } catch (error) { }
  }

}