import { Component, HostListener, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-prod-sheet',
  templateUrl: './prod-sheet.component.html',
  styleUrls: ['./prod-sheet.component.scss']
})
export class ProdSheetComponent implements OnInit, OnChanges, OnDestroy {

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // VARIABLES

  @ViewChild('f') prodForm!: NgForm;
  now = new Date();
  intervalId: any;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;
  public breadcrumb: any;

  public tabs: any;

  public homepageData: any;
  public interval: any;

  public pollingTime: number = 0;
  public pollingMachines: any;

  public pollingTime2: number = 0;
  public pollingMachinesData: any;

  public machineSelected: any;

  public machines: any;

  public productionInfo: any;

  defaultDate = (this.now.toISOString().substring(0, 10));
  hours = ("0" + this.now.getHours()).slice(-2);
  minutes = ("0" + this.now.getMinutes()).slice(-2);
  strTime = this.hours + ':' + this.minutes;

  trafile = ['1', '2', '3'];
  defaultTrafila = this.trafile[0];
  shifts = ['1', '2', '3'];
  defaultShift = this.shifts[0];

  lotChangeChecked: boolean = false;

  products = [
    'Prodotto 1',
    'Prodotto 2',
    'Prodotto 3',
    'Prodotto 4',
    'Prodotto 5',
    'Prodotto 6',
    'Prodotto 7',
    'Prodotto 8',
    'Prodotto 9',
    'Prodotto 10',
    'Prodotto 11',
    'Prodotto 12'
  ];
  defaultProduct = this.products[0];

  bigBags = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
  defaultBigBag = this.bigBags[0];

  prodSheet = {
    date: this.defaultDate,
    trafila: this.defaultTrafila,
    shift: this.defaultShift,
    lotChangeTime: '',
    products: this.defaultProduct,
    lot: '',
    bigBag: this.defaultBigBag,
    dayTime: this.strTime,
    outcome: true
  }

  defaultOutcome: boolean;
  trafilaSelected = 0;
  shiftSelected = 0;
  bigBagSelected = 0;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getMachinesPolling, nextState: 3, loadingMsg: 'LOADING.MACHINES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getProductionInfo, nextState: 4 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];


  onSelectTrafila(num: number) {
    this.trafilaSelected = num;
  }

  onSelectShift(num: number) {
    this.shiftSelected = num;
  }

  onSelectBigBag(num: number) {
    this.bigBagSelected = num;
  }

  submitted = false;
  invalidated = false;

  onSubmit() {

    if (this.submitted) {
      console.log(this.prodForm.value);
      this.prodSheet.date = this.prodForm.value.date;
      this.prodSheet.trafila = this.prodForm.value.trafila;
      this.prodSheet.shift = this.prodForm.value.shift;
      this.prodSheet.lotChangeTime = this.prodForm.value.lotChangeTime;
      this.prodSheet.products = this.prodForm.value.products;
      this.prodSheet.lot = this.prodForm.value.lot;
      this.prodSheet.bigBag = this.prodForm.value.bigBag;
      this.prodSheet.dayTime = this.prodForm.value.dayTime;
      this.prodSheet.outcome = this.prodForm.value.outcome;
    } else {
      this.prodSheet.date = this.prodForm.value.date;
      this.prodSheet.trafila = this.prodForm.value.trafila;
      this.prodSheet.shift = this.prodForm.value.shift;
    }
  }

  onBackToForm() {
    if (this.submitted) {
      this.submitted = false;
    } else {
      this.invalidated = false;
    }
  }

  onResetForm() {
    this.prodForm.reset({
      date: this.defaultDate,
      trafila: this.defaultTrafila,
      shift: this.defaultShift,
      lotChangeTime: '',
      products: this.defaultProduct,
      lot: '',
      bigBag: this.defaultBigBag,
      dayTime: '',
      outcome: true
    });
    this.trafilaSelected = 0;
    this.bigBagSelected = 0;
    this.shiftSelected = 0;
    this.defaultShift = this.shifts[this.shiftSelected];
    this.submitted = false;
    this.invalidated = false;
  }


  onValidate() {
    this.submitted = true;
  }

  onInvalidate() {
    this.invalidated = true;
  }
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public dialog: MatDialog
  ) {

    // this.pageState.subscribe((value) => { console.log('pageState.subscribe', value); });

    this.defaultOutcome = true;
    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.pollingTime = 0;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['MANUAL_INPUT.TITLE','PROD_SHEET.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    try {
      this.tabs = this.internalDataService.getPageTabs('manualInput');
    } catch (error) {
      console.error(error);
    }
    this.machines = {
      list: []
    };

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // machines

  // polling
  getMachinesPolling(_this: any) {
    try {

      if (_this.pollingTime > 0) {
        _this.pollingMachines = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getMachines(_this, count);
        });
      } else {
        _this.getMachines(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get machines
  getMachines(_this: any, count: any) {
    try {

      _this.apiService.sendGetRequest('/apif/machines', {})
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingMachines))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            // TODO
            // if not first request (polling), merge machine data with actuals, to avoid to reset them

            // set default image
            if (data.body != null && data.body.length > 0) {
              data.body.forEach((machine: any, idx: number) => {
                machine.profile = _this.internalDataService.getMachineProfile(_this, machine.machineId);
                // TODO
                // if (sources40f) { }
                // else {

                let sources40F = _this.appInfo.sources40F != null ? _this.appInfo.sources40F : 'assets/images/';
                machine.machineName = machine.machineName != null ? machine.machineName : machine.name;
                machine.image = sources40F + 'machine-default.png';
                // machine.profile = {
                //   'settings': {},
                //   ''
                // }
                // }
              });
            }

            // _this.machines.list = _this.parseMachines(_this, data.body);
            _this.machines.list = data.body;
            _this.machines.filtered = _this.machines.list;

            _this.internalDataService.setMachinesList(_this.machines.filtered);

            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // Production Info

  // get production info
  getProductionInfo(_this: any) {

    try {

      const sources40F = _this.appInfo.sources40F != null ? _this.appInfo.sources40F : 'assets/config/';

      _this.apiService.sendGetRequest(sources40F + 'productionInfo.json').pipe(
        retryWhen(_this.apiService.genericRetryStrategy()),
        catchError(error => {

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError(
            'Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.productionInfo = data.body;

            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pageState.unsubscribe();
    } catch (error) { }
    try {
      this.pollingMachines.unsubscribe();
    } catch (error) { }
  }

  @HostListener('window:resize', ['$event'])
  onResize() { }

}