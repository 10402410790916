import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { FiltersDialogComponent } from '../filters-dialog/filters-dialog.component';

@Component({
  selector: 'ff-parameter-change-table',
  templateUrl: './parameter-change-table.component.html',
  styleUrls: ['./parameter-change-table.component.scss']
})
export class ParameterChangeTableComponent implements OnInit {

  @Input() widget: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public tableInfos: any;
  public reelTableInfos: any;
  public reelDetailsColumns: any = [];

  public showOnlyChangedParameters: any = true;
  public reelTable: any;
  public reel: any;
  public recipeType: any;
  public groups: any;

  public removeBTN = false;

  public minRecipeChangeLength = 100;
  public deltaLength = 5;

  public customTranslations: any;
  public defaultCustomTranslations: any;

  public tableData = new MatTableDataSource<any[]>();
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public startPage: any;
  public endPage: any;

  constructor(
    public appConfigService: AppConfigService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public internalDataService: InternalDataService,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public dialog: MatDialog,
  ) {

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.defaultCustomTranslations = this.appConfigService.getDefaultCustomTranslations;

    this.reelTable = {
      search: "",
      recipeSwitch: {
        // checked: localStorage.getItem("recipeType") != null ? localStorage.getItem("recipeType") === 'true' : false,
        checked: false,
        checkedLabel: '',
        uncheckedLabel: '',
      },
      paramSwitch: {
        checked: true,
        checkedLabel: '',
        uncheckedLabel: '',
      }
    };

  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  // TABLE DATA

  parseTableData(data: any) {

    // try {
    //     if (data.recipeSet.length == 0 && data.recipeAutoSet == 0) return { rows: [] };

    // } catch (error) {
    //     console.log(error);
    // }

    this.showOnlyChangedParameters = this.reelTable.paramSwitch.checked;
    this.getRecipeType();

    let newTable: any = {
      headers: [
        this.translate.instant("REEL_DETAILS.PARAMETERS"),
        this.translate.instant("REEL_DETAILS.GROUP")
      ]
        .concat(data[this.recipeType.variable].concat(data.recipeAct).sort(this.filterService.sortByProperty('timeFrom', null, true))
          .map((x: any) => {
            let isBefore = moment(x._time == null ? x.timeFrom : x._time).diff(moment(this.widget.data.timeStart)) < 0 || (x["meterReel" + this.widget.data.takeUp] <= 0);
            return {
              label: (x._time == null ? this.recipeType.label + ' - ' : '') + this.filterService.parseMoment(x.timeFrom, "DD, MMM YYYY HH:mm:ss", this.widget.referenceComponent?.machine?.timezone),
              class: x._time == null ? 'md-red-i' : null,
              isBefore: isBefore,
              tooltip: x.recipeId != null ? x.recipeId : null,
            }
          })),
      rows: [],
      filteredRows: [],
      query: {
        limitStart: 100,
        limit: 100,
        limitOptions: [12, 25, 50, 100],
        page: 1
      }
    }

    for (const param of Object.keys(data.recipeSet.length > 0 ? data.recipeSet[0] : data.recipeAutoSet.length > 0 ? data.recipeAutoSet[0] : {})) {

      if (newTable.rows.length == 0) {

        let firstVal: any = [{
          id: "meters",
          value: this.translate.instant("REEL_DETAILS.METERS_TABLE") + " [" + this.filterService.convertUnit("m", 0).unit + "]",
          // group: "-",
          // groupP: "-",
          class: 'md-white'
        }];

        newTable.rows.push({
          show: true,
          values: firstVal.concat({
            // value: "-",
            class: 'md-white'
          })
            .concat(data[this.recipeType.variable].concat(data.recipeAct).sort(this.filterService.sortByProperty('timeFrom', null, true)).map((x, idx, list) => {
              let tdClass = null;
              let isBefore = false;
              try {
                isBefore = newTable.headers[idx + 2].isBefore;
              } catch (error) { }

              // if (isBefore) tdClass = 'opacity38';

              if (x._time == null) {
                return {
                  isBefore: isBefore,
                  value: isBefore ? this.translate.instant("REEL_DETAILS.BEFORE_REEL_START") : this.filterService.parseGaugeValue(this.filterService.convertUnit("m", x["meterReel" + this.widget.data.takeUp]).value, 0, 1),
                  class: 'md-gray',
                  tdClass: tdClass,
                };
              }
              let className = 'md-gray';

              return {
                isBefore: isBefore,
                value: isBefore ? this.translate.instant("REEL_DETAILS.BEFORE_REEL_START") : this.filterService.parseGaugeValue(this.filterService.convertUnit("m", x["meterReel" + this.widget.data.takeUp]).value, 0, 1),
                class: className,
                tdClass: tdClass
              }
            }))
        });
      }
      let paramConfig = this.internalDataService.parseRecipeLabel(param, 'label', this.cacheService.get("machineId"));

      // console.log({ paramConfig });

      if (!paramConfig.hide) {

        newTable.rows.push({
          values: [{
            id: param,
            value: paramConfig.label,
            unit: this.filterService.convertUnit(paramConfig.unit).unit,
            group: paramConfig.group,
            groupP: "groups." + paramConfig.group == this.translate.instant("groups." + paramConfig.group) ? paramConfig.group : this.translate.instant("groups." + paramConfig.group),
            class: ''
          }]
            .concat({
              id: '',
              unit: null,
              group: null,
              groupP: null,
              value: "groups." + paramConfig.group == this.translate.instant("groups." + paramConfig.group) ? paramConfig.group : this.translate.instant("groups." + paramConfig.group),
              class: ''
            })
            .concat(data[this.recipeType.variable].concat(data.recipeAct).sort(this.filterService.sortByProperty('timeFrom', null, true)).map((x: any, idx: any, list: any) => {
              let tdClass = null;
              if (typeof x[param] == 'boolean') try { x[param] = +!!x[param] } catch (error) { }
              if (x._time == null) {

                return {
                  value: this.filterService.parseGaugeValue(this.filterService.convertUnit(paramConfig.unit, x[param]).value, paramConfig.decimals ?? 3, paramConfig.multiplier ?? 1),
                  class: 'md-gray',
                  tdClass: tdClass,
                };
              }
              let className = 'md-gray';
              let changeId = 0;
              try {

                let compareTo = idx > 0 ? list[idx - 1][param] : data[this.recipeType.variable][0][param];

                // console.log(compareTo, idx, x[param], param);
                // 0 = not changed, 1 = greater, 2 = lower
                changeId = x[param] != compareTo ? (x[param] > compareTo ? 1 : 2) : 0;

                if (changeId == 2) {
                  className = 'md-blue';
                } else if (changeId == 1) {
                  className = 'md-orange';
                }
              } catch (error) {
                console.log(error);
              }
              return {
                value: this.filterService.parseGaugeValue(this.filterService.convertUnit(paramConfig.unit, x[param]).value, paramConfig.decimals ?? 3, paramConfig.multiplier ?? 1),
                class: className,
                tdClass: tdClass,
                isChanged: changeId != 0
              }
            }))
        });
        newTable.rows[newTable.rows.length - 1].show = newTable.rows[newTable.rows.length - 1].values.some((x: any) => x.isChanged);
      }

    }

    newTable.filteredRows = newTable.rows.filter((x: any) => this.showOnlyChangedParameters ? x.show : true);

    if (!this.groups) this.groups = this.clonerService.deepClone(
      newTable.rows.map((x: any) => x.values[0].groupP).filter(this.filterService.onlyUnique).map((x: any) => {
        return {
          enabled: true,
          label: x,
          id: x,
        }
      }).concat([{
        enabled: true,
        label: "-",
        id: null,
      }])
    );

    return newTable;
  }

  getRecipeType() {

    let recipeSet = {
      variable: "recipeSet",
      label: this.translate.instant("REEL_DETAILS.RECIPE_SET")
    };

    let recipeAutoSet = {
      variable: "recipeAutoSet",
      label: this.translate.instant("REEL_DETAILS.RECIPE_AUTO_SET")
    }

    let recipeType = Number(this.reelTable.recipeSwitch.checked) == 0 ? recipeSet : recipeAutoSet;
    this.recipeType = recipeType;

  }


  filterTableData() {
    let cols = ["value", "id", "group"];

    let filtered: any = this.clonerService.deepClone(this.reelTable.data.rows)
    filtered = filtered.filter((x: any) => this.showOnlyChangedParameters ? x.show : true);


    filtered = filtered.filter((x: any) => {
      try {
        return this.groups.filter((el: any) => el.enabled).some((group: any) => x.values[0].groupP == group.id);
      } catch (error) {
        return false;
      }
    });

    filtered = filtered.filter((x: any) => {
      try {
        return cols.some(col => String(x.values[0][col]).toLowerCase().includes(this.reelTable.search.toLowerCase()));
      } catch (error) {
        return false;
      }
    });


    this.reelTable.data.filteredRows = filtered;

    const cd = new MatTableDataSource<any[]>();

    cd.paginator = this.paginator;
    cd.data = filtered;

    this.tableData = cd;
  }

  reelSwitchChange(switchVar: string, value: boolean,) {
    this.reelTable[switchVar].checked = value;
    this.showOnlyChangedParameters = this.reelTable.paramSwitch.checked;
    // localStorage.setItem("recipeType", String(value));
    this.reelTable.data = this.parseTableData(this.widget.data);
    this.filterTableData();
  }

  openFilterDialog() {
    let groups: any = this.clonerService.deepClone(this.groups);
    groups.forEach((element: any) => element.selected = element.enabled);
    let filtersDialog = this.dialog.open(FiltersDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant('REEL_DETAILS.GROUP_SELECTION'),
        options: groups,
      }
    });

    filtersDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') {
        result.options.forEach((element: any) => {
          element.enabled = element.selected
          delete element.selected
        });

        this.removeBTN = result.options.some((element: any) => !element.selected);
        this.groups = this.clonerService.deepClone(result.options);
        this.filterTableData();
      }
    });
  }

  removeFilters() {
    this.groups = null;
    this.parseTableData(this.widget.data);
    this.filterTableData();
    this.removeBTN = this.groups.some((element: any) => !element.enabled);
  }

  ngOnInit(): void {
    let c_customTranslations = this.cacheService.get("customTranslations");
    if (c_customTranslations != null && c_customTranslations?.machineId == this.widget.referenceComponent?.machineId) this.customTranslations = c_customTranslations?.value;
    this.reelTable.data = this.parseTableData(this.widget?.data ?? {});
  }

  ngAfterViewChecked() {
    try {
      this.startPage = this.paginator.pageIndex * this.paginator.pageSize;
      this.endPage = (this.paginator.pageIndex + 1) * this.paginator.pageSize;
    }
    catch (error) { }
  }

}
