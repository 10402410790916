<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="close">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <div class="search-input" fxLayout="row" fxLayoutAlign="start center"
    style="border: 1px solid #eaecee; margin-bottom: 12px">
    <mat-icon class="icon">search</mat-icon>
    <input type="text" class="w100" (keyup)="searchFromList($event)" placeholder="{{'GLOBAL.SEARCH'|translate}}">
  </div>

  <div class="max-limit-warning" *ngIf="maxItemsSelectable != null">
    <span translate="GLOBAL.MAXIMUM_SELECTABLE_ITEMS" [translateParams]="{n: maxItemsSelectable}"></span>
  </div>

  <div *ngFor="let comp of dialog.groups | keyvalue:sortFunction">

    <div *ngIf="!showOnlyTraces" fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 0;">

      <mat-icon [svgIcon]="comp.key == dialog.componentId ? 'radio_button': 'radio_button_disabled'"
        class="icon left clickable {{ comp.key == dialog.componentId ? 'radio_button' : 'radio_button_disabled' }}"
        (click)="changeComponentSelected(comp)">
      </mat-icon>

      <span>{{ internalDataService.parseComponentsLabel(comp.key, null, machineId) | translate }}</span>

      <mat-icon *ngIf="comp.key == dialog.componentId" [svgIcon]="comp.value.expanded ? 'curtain_up' : 'curtain_down'"
        class="icon left clickable" (click)="comp.value.expanded = !comp.value.expanded">
      </mat-icon>

    </div>

    <div *ngIf="comp.key == dialog.componentId && comp.value.expanded">

      <ng-container *ngFor="let group of comp.value.plotsConfig.list">

        <div fxLayout="column" fxLayoutAlign="center start" style="padding: 4px 0 4px 20px;">

          <div fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 0;" class="w100">

            <mat-icon [svgIcon]="group.show ? 'radio_button' : 'radio_button_disabled'"
              class="icon left {{ group.show ? 'radio_button' : 'radio_button_disabled' }} completely"
              (click)="toggleGroup(group)" [class.ff-disabled]="checkGroupDisabled(group)"
              [class.clickable]="!checkGroupDisabled(group)">
            </mat-icon>

            <span style="font-weight: 800;">{{ group.name }}</span>

            <span *ngIf="!group.doubleAxis">
              &nbsp;[{{ filterService.convertUnit(group.unit).unit | translate }}]
            </span>

            <mat-icon [svgIcon]="group.expanded ? 'curtain_up' : 'curtain_down'" class="icon left clickable"
              (click)="toggleGroupExpansion(group)">
            </mat-icon>

            <span fxFlex></span>

            <span>{{ group.shownTraces }}/</span>
            <span>
              {{ (group.traces | customSearchFilter: availableOptions : availableOptionsVariablesConfig).length }}
            </span>
          </div>

          <ng-container *ngIf="group.expanded">

            <ng-container
              *ngIf="!(group.traces | customSearchFilter: availableOptions : availableOptionsVariablesConfig).length">
              <div fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 8px 4px 20px;">
                <span>{{ 'CONTINUOUS_EXPLORATION.NO_TRACE_AVAILABLE' | translate }}</span>
              </div>
            </ng-container>

            <ng-container
              *ngIf="(group.traces | customSearchFilter: availableOptions : availableOptionsVariablesConfig).length">

              <div
                *ngFor="let trace of (group.traces | customSearchFilter: availableOptions : availableOptionsVariablesConfig)"
                fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 8px 4px 20px;">

                <mat-icon [svgIcon]="trace.show ? 'radio_button': 'radio_button_disabled'"
                  class="icon small left {{ trace.show ? 'radio_button' : 'radio_button_disabled' }} completely"
                  (click)="toggleTrace(group, trace)" [class.ff-disabled]="checkTraceDisabled(group, trace)"
                  [class.clickable]="!checkTraceDisabled(group, trace)">
                </mat-icon>

                <span style="margin-left: 12px;">{{ trace.label }}</span>
                <span *ngIf="showTraceName" style="margin-left: 8px; font-size: 0.8rem;">/ {{ trace.name }}</span>
                <span *ngIf="group.doubleAxis">
                  &nbsp;[{{ filterService.convertUnit(trace.unit).unit | translate }}]
                </span>
              </div>

            </ng-container>
          </ng-container>

        </div>

      </ng-container>

    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">

  <button mat-button *ngIf="allShownTraces == null || allShownTraces <= 0"
    class="md-orange circular-icon-button not-clickable left" #tooltip="matTooltip"
    matTooltip="{{ 'CONTINUOUS_EXPLORATION.NOT_SELECTABLE' | translate }}" matTooltipPosition="left">
    <mat-icon class="icon">warning</mat-icon>
  </button>

  <button class="filter-button md-primary" [mat-dialog-close]="dialog"
    [disabled]="allShownTraces == null || allShownTraces <= 0">
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>

</mat-dialog-actions>