import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Stop } from "./stops-story/stop.module";

@Injectable()
export class StopService {
    stopsChanged = new Subject<Stop[]>();
    
    private stops: Stop[] = [
        new Stop(
            'Causa 3', 
            '1', 
            '2021-04-22',
            '2021-04-22', 
            '16:30',
            '16:50'),
        new Stop(
            'Causa 9', 
            '3', 
            '2021-04-22',
            '2021-04-22',
            '11:34',
            '12:12'),
        new Stop(
            'Causa 11', 
            '2', 
            '2021-04-21',
            '2021-04-21',
            '06:08',
            '06:22'
        )
    ];

    getStops() {
        return this.stops.slice();
    }

    deleteStop(index: number) {
        this.stops.splice(index, 1);
        this.stopsChanged.next(this.stops.slice());
    }

    addStop(stop: Stop) {
        this.stops.unshift(stop);
        this.stopsChanged.next(this.stops.slice());
    }
}