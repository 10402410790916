import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ffMachinesFilters',
  pure: false
})
export class MachinesFiltersPipe implements PipeTransform {

  transform(items: any[], filters: any): any {

    if (!items || !filters ||
      (filters.custom.length == 0 && filters.search == '')) {
      return items;
    }

    return items.filter((item) => {
      let showCustom = false;

      if (filters.custom.length > 0) {

        showCustom = filters.custom?.every((_filter) => {
          if (item?.[_filter?.variable] == null) return true;
          let exists = item?.[_filter?.variable] != null && _filter?.options?.filter(x => x?.selected)?.find(x => x?.id?.toLowerCase() == item?.[_filter?.variable]?.toLowerCase()) != null;
          return exists;

        });

      } else {
        showCustom = true;
      }

      let showSearch = false;

      if (filters.search != null && filters.search != "" && filters.filterVariables) {

        for (let variable of filters.filterVariables) {
          if (item.hasOwnProperty(variable) && item[variable] != null) {
            if (typeof item[variable] === 'string' &&
              item[variable].toLowerCase().includes(filters.search.toLowerCase())) {
              showSearch = true; break;
            }
            // TODO typeof number
            // if (typeof item[variable] === 'number' &&
            //   item[variable] == parseFloat(filters.search)) {
            //   showSearch = true; break;
            // }
            // TODO typeof array
          }
        }
      }
      else {
        showSearch = true;
      }

      return showCustom && showSearch;
    });
  }

}