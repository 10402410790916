import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-program-traceability-detail',
  templateUrl: './program-traceability-detail.component.html',
  styleUrls: ['./program-traceability-detail.component.scss']
})
export class ProgramTraceabilityDetailComponent implements OnInit {
  public appInfo: any;
  public appConfig: any;
  public dashboardConfig: any;
  public machineId: any;
  public breadcrumb: any;
  public backButton: any;
  public urlStart: any;
  public urlEnd: any;
  public urlMachineId: any;
  public detailData: any;
  public machineSelectedSub: any;
  public machineProfiles: any;
  public machine: any;

  public prodsDataDash: any;

  public dashboardName: any = "program-traceability-detail";
  public tabs: any;
  public queryParams: any;
  public programId: any;

  public routeSub: Subscription;

  public alternate: boolean = false;
  public parentComponentId: any;

  public intervalAggregations: any;
  public timeAggrUnit: any[];
  public forcedAggrList: any;
  public forcedAggrId: any;
  public enabledIntervalAggr: any;

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getDataPolling, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    public router: Router,
    private cacheService: CacheService

  ) {
    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.breadcrumb = ['PROGRAM_TRACEABILITY.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.machineId = this.route.snapshot.params['machineId'];
    this.queryParams = this.route.snapshot.queryParams;

    this.tabs = this.internalDataService.getPageTabs('programTraceabilityDetail');

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.programId = this.queryParams.id
        newBreadcrumb.push(this.programId);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.enabledIntervalAggr = this.appConfig.programTraceabilityDetail?.enabledIntervalAggr

    try {
      this.forcedAggrList = this.appConfig?.programTraceabilityDetail?.aggrList ?? []
    } catch (error) { }
    try {
      this.forcedAggrId = this.appConfig?.programTraceabilityDetail?.aggrListDefault ?? []
    } catch (error) { }

    this.pageState.subscribe()
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    // console.log('dashboard')
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {
    // console.log('asset')
    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-la-traceability");
    } catch (error) {
      console.log(error)
    }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({
          machineId: _this.machineId
        });
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'programTraceabilityDetail');
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // polling
  getDataPolling(_this: any) {
    try {
      _this.getData(_this, 0)
    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get prods
  getData(_this: any, count?: any) {
    try {

      if (count == 0) {
        _this.internalDataService.forceAggregationList(_this, _this.forcedAggrList, _this.forcedAggrId);
      }

      // console.log(_this.intervalAggregations)

      let url = '/apif/production-traceability/' + _this.machineId;

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = null
      payload.currentProgramDetail = _this.machine.profile.currentProgramDetail

      if (_this.machine.profile?.prodTraceVariables?.length > 0) payload.prodTraceVariables = _this.clonerService.deepClone(_this.machine.profile.prodTraceVariables);

      var c_condAnal = _this.cacheService.get("condAnalFromTo");

      let query: any = {
        from: _this.urlStart != null ? _this.urlStart : c_condAnal.from,
        to: _this.urlEnd != null ? _this.urlEnd : c_condAnal.to,
        tz: _this.machine.timezone,
        unit: _this.alternate ? 'aggr3' : 'aggr0',
        value: _this.alternate ? _this.parentComponentId : _this.queryParams.id
      };

      if (_this.urlMachineId) query.machineId = _this.urlMachineId;

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({
            maxRetryAttempts: 0
          })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.prodsDataDash = data.body;

            let isActive = _this.prodsDataDash?.active?.length > 0
            try {
              let progObj = _this.clonerService.deepClone(_this.prodsDataDash[isActive ? 'active' : 'timeserie']?.[0])
              Object.keys(progObj).forEach((key: any) => {
                _this.prodsDataDash[key] = progObj[key]
              })

              if (!_this.alternate) {
                _this.prodsDataDash.timeStart = _this.urlStart;
                _this.prodsDataDash.timeEnd = _this.urlEnd;
              }
              _this.prodsDataDash.urlStart = _this.urlStart;
              _this.prodsDataDash.urlEnd = _this.urlEnd;
              _this.prodsDataDash.aggr0 = _this.programId
              _this.prodsDataDash.machineId = _this.urlMachineId;
              _this.prodsDataDash.timezone = _this.machine.timezone;
              _this.timeAggrUnit = [_this.intervalAggregations.selected.value, _this.intervalAggregations.selected.unit]
              _this.prodsDataDash.timeAggrUnit = _this.timeAggrUnit
              _this.prodsDataDash.alternate = _this.alternate
              _this.prodsDataDash.active = isActive


            } catch (error) {

            }
            // _this.prodsDataDash.detailData = _this.buildPlotConfig(_this, data.body);
            // _this.parseProdsTable(_this, data.body);

            _this.parseDashboard(_this.prodsDataDash)

            // console.log(_this.prodsDataDash)
            // console.log(_this.dashboardConfig)

            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      this.dispatcherService.getDispatch(this, 301, errorData);
    }
  }

  navigateToMachineRecorder() {
    let queryParams: any = this.clonerService.deepClone(this.queryParams)
    queryParams.variablesTimeseriesMode = 1
    let condAnalObj = {
      from: this.urlStart,
      to: this.urlEnd,
      timeAggrUnit: this.timeAggrUnit
    }
    this.cacheService.set('condAnalFromTo', condAnalObj)
    this.router.navigate([this.machineId + '/program-traceability/variables-timeseries'], {
      queryParams: queryParams
    });
  }

  onAlternate() {
    this.alternate = !this.alternate
    this.parentComponentId = this.prodsDataDash.aggr3
    // this.prodsDataDash = {}
    // this.dashboardConfig = {}
    this.pageState.next(3)
    this.getDashboard(this)
  }

  parseProdsTable(_this: any, data: any) {
    if (data != null && data.hasOwnProperty('table') && data.table != null &&
      Array.isArray(data.table) && data.table.length > 0) {

      let parsedTable: any = [];
      data.table.filter((x: any, index: any) => index < 100000).forEach((event: any) => {
        // data.table.forEach((event: any) => {
        // console.log(event);
        event = _this.getEventInfo(_this, event);
        event.timestampP = moment(event.timestamp).format("DD MMM YYYY HH:mm:ss.SSS");
        event.durationP = event.duration != null ? _this.filterService.parseTime(event.duration, 's', 'HH:mm:ss') : null;
        // event.eventName = event.eventId;
        // event.description = "Descrizione casuale";
        parsedTable.push(event);
      });

      _this.events.list = _this.clonerService.deepClone(parsedTable);

      let c_eventTypes = _this.cacheService.get("eventTypes");
      if (c_eventTypes == null) {
        _this.eventTypes = _this.clonerService.deepClone(_this.machine.profile.tables.map((x: any) => {
          return {
            enabled: _this.defaultEnableFilters,
            id: x.eventId,
            value: x.label,
          }
        }));
      } else {
        _this.eventTypes = _this.clonerService.deepClone(c_eventTypes);
      }

    } else {
      try {
        _this.events.list = [];
      } catch (error) {
        console.log(error);
      }
    }

    _this.filterEvents();
  }

  parseDashboard(data: any) {
    this.dashboardConfig?.widgets?.forEach((widget: any) => {
      if (widget.hasOwnProperty('config') && Array.isArray(widget.config) && widget.config.some((conf: any) => conf.hasOwnProperty('configFlag'))) {
        widget.config = widget.config.filter((conf: any) => data[conf.configFlag?.variable] == conf.configFlag?.value)
      }
    });
  }

  selectAggregation(aggr: any) {

    if (aggr != null) {

      this.intervalAggregations.selected = aggr;
      this.timeAggrUnit = [this.intervalAggregations.selected.value, this.intervalAggregations.selected.unit]

      this.pageState.next(6);
      this.getData(this, 1);

    }
  }

  ngOnInit(): void {
    this.machineId = this.route.snapshot.params['machineId'];
    this.queryParams = this.route.snapshot.queryParams;

    // TODO
    this.backButton = [this.machineId, "program-traceability"];
    this.internalDataService.setBackButton(this.backButton);

    // let addZ = 'Z';
    let addZ = '';

    try {
      this.programId = this.queryParams.id
      this.urlStart = this.queryParams.start;
      this.urlEnd = this.queryParams.end;
    } catch (error) {
      console.log(error);
    }

    let newBreadcrumb = Object.assign([], this.breadcrumb);
    newBreadcrumb.push(this.programId);
    this.internalDataService.setBreadcrumb(newBreadcrumb);

    this.routeSub = this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
        this.queryParams = this.route.snapshot.queryParams;

        try {
          this.programId = this.queryParams.id
          this.urlStart = this.queryParams.start
          this.urlEnd = this.queryParams.end
          this.urlMachineId = this.machineId;

          // console.log(this.urlStart, this.urlEnd)

          this.detailData = {
            detailStart: this.filterService.parseMoment(this.urlStart, 'default'),
            detailEnd: this.filterService.parseMoment(this.urlEnd, 'default')
          };

          this.tabs = this.internalDataService.getPageTabs('programTraceabilityDetail');
          try {
            this.tabs.forEach((tab: any) => tab.urlQueryParams = this.queryParams)
          } catch (err) { }

        } catch (error) {
          console.log(error);
        }

      }

      // this.internalDataService.setCycleSelected(pageUrl);
    )

    this.dispatcherService.getDispatch(this, 300);
  }

  ngOnDestroy() {
    try {
      this.internalDataService.setBackButton([]);
    } catch (error) { }
    try {
      this.machineSelectedSub.unsubscribe()
    } catch { }
  }

}
