import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'app-complete-consumption',
  templateUrl: './complete-consumption.component.html',
  styleUrls: ['./complete-consumption.component.scss']
})
export class CompleteConsumptionComponent implements OnInit, OnDestroy {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public isMobile: any;
  public mobileListener: Subscription;

  public machineProfiles: any;

  public productionColumns: string[] = [];

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public pollingTime: any;
  public pollingEvents: any;

  public maxStringsLength: any;

  public aggrDropdown: any = null;

  public aggregations: any;
  public aggregationsPayload: any;
  public aggregationsCustom: any;

  public consDropdown: any = null;
  public consIcon: any = null;

  public excludeLine: boolean = true;

  public interval: any;
  public intervalConfig: any;
  public intervalAggregations: any;

  public dashboardConfig: any;
  public dashboardConfigUnparsed: any;

  public dashboardMultiConfig: any;
  public dashboardMultiConfigUnparsed: any;

  public aggrList: any;
  public aggrId: any;

  public allConsumablesDisabled: any = false;

  public consumables: any = [];

  public consData: any = null;
  public colors: any = [
    '#66DA26', '#546E7A', '#E91E63', '#FF9800',
    "#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D",
    "#33B2DF", "#D4526E", "#13D8AA", "#A5978B",
    "#4ECDC4", "#C7F464", "#81D4FA", "#FD6A6A",
    "#2B908F", "#F9A3A4", "#90EE7E", "#FA4443",
    "#449DD1", "#F86624", "#EA3546", "#662E9B",
    "#D7263D", "#1B998B", "#2E294E", "#F46036",
    "#F9C80E", "#43BCCD", "#69D2E7", '#008FFB',
    "#5C4742", "#8D5B4C", "#5A2A27", "#C4BBAF",
    "#A300D6", "#7D02EB", "#5653FE", "#2983FF",
    "#C5D86D", "#E2C044", "#00B1F2",
  ];

  public sectionName: any = 'consumptionAnalytics';
  public dashboardName: any = 'complete-consumption';
  public dashboardNameMult: any = 'complete-consumption-multiple';

  public plotType: any = "bar";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: "multi-dash", loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: "multi-dash",
      codes: [
        { code: 300, function: this.getDashboardMulti, nextState: 4, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getEventsBreakdowns, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];
  multipleConsumablesMode: boolean;
  consDataMultiple: any = [];
  stringError: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    public mobile: MobileService,
  ) {
    this.mobile.mobileListener.subscribe((value) => this.isMobile = value.isMobile)
    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    let isDarkTheme = this.appInfo?.darkTheme;

    this.colors = isDarkTheme ? this.internalDataService.defaultPlotlyColorsDark : this.internalDataService.defaultPlotlyColors;

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['CONSUMPTION_ANALYTICS.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);
    this.availableMachines = this.appConfig?.[this.sectionName]?.availableMachines ?? [];

    try {
      this.aggrList = this.appConfig[this.sectionName].aggrList;
    } catch (error) { }

    try {
      this.aggrId = this.appConfig[this.sectionName].aggrListDefault;
    } catch (error) { }

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig.consumptionAnalytics?.polling ?? 0;
    this.pollingEvents = Subscription;

    this.plotType = this.appConfig?.showConsumablesPlotAsLineChart ? 'scatter' : this.plotType;

  }

  openAggrDialog(aggr: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        // console.log('afterClosed', result);
        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
      }

      this.pageState.next(isClickedSelect ? 5 : 6);

      if (!this.interval.enabledPolling) {
        this.getEvents(this, 0);
      } else {
        this.getEventsBreakdowns(this);
      }

    });
  };

  changePageAggregation(aggrDropdown: any) {

    this.aggrDropdown = this.clonerService.deepClone(aggrDropdown);

    this.pageState.next(5);
    if (!this.interval.enabledPolling) {
      this.getEvents(this, 0);
    } else {
      this.getEventsBreakdowns(this);
    }
  }

  changeConsumable(consDropdown: any) {

    // MULTIPLE CONSUMABLES
    if (consDropdown == 'all') this.multipleConsumablesMode = true;

    // SINGLE CONSUMABLE
    else {
      this.multipleConsumablesMode = false;
      this.consDropdown = this.clonerService.deepClone(consDropdown);

      if (this.aggregations == null || this.aggregations.list == null || this.aggregations.list.length == 0) {
        try {
          let consIdx = this.consumables.findIndex((x: any) => x.id == this.consDropdown);
          if (consIdx != -1 && this.consumables[consIdx].icon != null) this.consIcon = this.consumables[consIdx].icon;
          if (consIdx != -1 && this.consumables[consIdx].hasOwnProperty('aggregations') &&
            this.consumables[consIdx].aggregations != null &&
            this.consumables[consIdx].aggregations.length > 0) {
            this.aggregationsCustom = this.consumables[consIdx].aggregations;
            this.aggrDropdown = this.aggregationsCustom.findIndex((x: any) => x.id == this.aggrDropdown) != -1 ? this.aggrDropdown : null;
          } else {
            this.aggregationsCustom = null;
            this.aggrDropdown = null;
          }
        } catch (error) {

        }
      }
    }

    this.pageState.next(5);
    if (!this.interval.enabledPolling) this.getEvents(this, 0);
    else this.getEventsBreakdowns(this);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
    } catch (error) {
      let testError = {
        module: 'complete-consumption',
        function: 'getAssetInfo',
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getEventsBreakdowns, this.getEvents, this.machine.timezone) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval aggr
  selectAggregation(aggr: any) {

    if (aggr != null) {

      try {
        this.pollingEvents.unsubscribe();
      } catch (error) {
        // console.log(error)
      }

      this.intervalAggregations.selected = aggr;

      this.pageState.next(5);
      this.getEventsBreakdowns(this);

    }
  }

  machineSelectionChange(machine: any) {

    this.filterService.filterAggregationsByMachine(this, machine);

    if (machine != null) {

      try {
        this.pollingEvents.unsubscribe();
      } catch (error) { }

      this.pageState.next(5);
      this.getEventsBreakdowns(this);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      console.log(error)
      let testError = {
        module: 'complete-consumption',
        function: 'getDashboard',
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboardMulti(_this: any) {

    try {
      _this.consumables = _this.clonerService.deepClone(_this.machine?.profile?.consumables?.filter(x => !x.brand) ?? []);
      _this.consDropdown = _this.clonerService.deepClone(_this.consumables)[0].id;
      _this.consIcon = _this.clonerService.deepClone(_this.consumables)[0].icon;
    } catch (error) {
      console.log(error);
      _this.stringError = "GLOBAL.NO_CONSUMABLE_CONFIGURED";
      return;
    }

    try { _this.allConsumablesDisabled = _this.consumables.every(x => x.disabled) } catch (error) { }

    if (_this.consumables?.length > 1 && _this.appConfig?.addAllConsumableSelectionInConsumptionAnalytics) {
      try {
        _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardNameMult, false, 'dashboardMultiConfig');
      } catch (error) {
        console.log(error)
        let testError = {
          module: 'complete-consumption',
          function: 'getDashboard',
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }
    else {
      _this.dispatcherService.getDispatch(_this, 300);
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // events

  // polling
  getEventsBreakdowns(_this: any) {
    try {


      if (_this.cacheService.get("intervalLong") == null) {

        _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.cacheService.set("intervalLong", _this.intervalConfig);

      } else {
        _this.intervalConfig = _this.cacheService.get("intervalLong");
        _this.interval = _this.cacheService.get("intervalLong").selected;
      }

      if (_this.pollingTime > 0) {
        _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getEvents(_this, count);
        });
      } else {
        _this.getEvents(_this, 0);
      }

    } catch (error) {
      let errorData = {
        module: 'complete-consumption',
        function: 'getEventsBreakdowns',
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getEvents(_this: any, count?: any) {
    try {

      if (count == 0 && _this.aggrList != null && _this.aggrList.length > 0) {
        _this.internalDataService.forceAggregationList(_this, _this.aggrList);
      }

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone,
        unit: _this.intervalAggregations.selected?.unit ?? 'hour',
        value: _this.intervalAggregations.selected?.value ?? 1,
        periods: _this.machine.profile?.lastPeriods ?? 3,
      };

      if (_this.interval != null && !_this.interval.enabledPolling) query.to = _this.interval.end;

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      _this.filterService.filterAggregationsByMachine(_this, query?.machineId);

      if (!_this.multipleConsumablesMode) _this.getSingleConsumable(count, query, payload);
      else _this.getAllConsumables(count, query, payload);

    } catch (error) {
      console.log(error);
      let errorData = {
        module: 'complete-consumption',
        function: 'getEvents',
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getAllConsumables(count, query, payload) {

    let _this = this;
    _this.consDataMultiple = [];
    _this.consData = {};

    let filteredConsumables = _this.consumables.filter(x => !x.disabled);

    filteredConsumables?.forEach((consSettings: any, consIdx) => {

      query.consumable = consSettings?.id;

      let url = '/apif/consumption-monitoring/complete-consumption/' + _this.machineId;

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => {

            _this.consDataMultiple.push({ ..._this.parseData(_this, data.body, consSettings?.id, false), consSettings, ...{ id: consSettings?.id } });

            if (count == 0 && _this.consDataMultiple?.length == filteredConsumables?.length) {

              _this.consDataMultiple = _this.consDataMultiple?.sort(_this.filterService.sortByProperty("id", 'asc', true));

              _this.consData = {}

              _this.consData.dataConfig = {
                title: _this.internalDataService.buildExportFileTitle(_this.breadcrumb, _this.interval),
                plotDataAttribute: 'plotData',
                tableDataAttribute: 'table'
              };
              _this.consData.aggrDataConfig = {
                plotDataAttribute: 'aggrPlotData'
              };

              _this.consData.plotData = _this.buildMultipleConsPlotConfig();

              let tableInfos: any = _this.clonerService.deepClone(_this.appConfig.consumptionAnalytics?.completeConsumption?.filter(x => !x.consUnit));
              let consTableInfos: any = _this.clonerService.deepClone(_this.appConfig.consumptionAnalytics?.completeConsumption?.filter(x => x.consUnit));

              _this.consumables?.forEach(cons => {
                consTableInfos.forEach(info => {

                  let consSettings: any = this.clonerService.deepClone(cons);

                  try {
                    consSettings.unit = _this.translate.instant(consSettings.unit ?? '-');
                    consSettings.relativeUnit = _this.translate.instant(consSettings.relativeUnit ?? consSettings.unit ?? '-');
                  } catch (error) { console.log(error) }

                  let consUnit = consSettings?.unit ?? '-';
                  let relConsUnit = consSettings?.relativeUnit ?? consSettings.unit ?? '-';
                  let productionUnit = _this.filterService.getProductionUnit("singleUnit", _this.machine.profile.productionConfig);

                  if (info.consUnit) consSettings.unit = consUnit;
                  if (info.relativeUnit) consSettings.unit = relConsUnit + '/' + productionUnit;

                  tableInfos.push({
                    ...info, ...{
                      variable: cons?.id + "-" + info?.variable,
                      orderBy: cons?.id + "-" + info?.variable,
                      label: this.translate.instant(info?.label) + ' - ' + this.translate.instant(cons?.label),
                      unit: consSettings.unit,
                      unitInTitle: true
                    }
                  })
                })
              })

              _this.consData.tableInfo = tableInfos;

              _this.dispatcherService.getDispatch(_this, 300);

            }
          }
        );
    })


  }

  getSingleConsumable(count, query, payload) {

    let _this = this;

    if (_this.consDropdown == null) {

      _this.consumables = _this.clonerService.deepClone(_this.machine?.profile?.consumables?.filter(x => !x.brand) ?? []);
      try {
        _this.consDropdown = _this.clonerService.deepClone(_this.consumables)[0].id;
        _this.consIcon = _this.clonerService.deepClone(_this.consumables)[0].icon;
      } catch (error) {
        console.log(error);
      }
    }

    if (_this.aggregations == null || _this.aggregations.length == 0) {
      try {
        let consIdx = _this.consumables.findIndex((x: any) => x.id == _this.consDropdown);
        if (consIdx != -1 && _this.consumables[consIdx].hasOwnProperty('aggregations') &&
          _this.consumables[consIdx].aggregations != null &&
          _this.consumables[consIdx].aggregations.length > 0) {
          _this.aggregationsCustom = _this.clonerService.deepClone(_this.consumables)[consIdx].aggregations;
        } else {
          _this.aggregationsCustom = null;
        }
      } catch (error) {
        console.log(error);
      }
    }

    query.consumable = _this.consDropdown;

    if (_this.aggrDropdown != null) query.aggrUnit = _this.aggrDropdown;

    _this.apiService.sendPostRequest('/apif/consumption-monitoring/complete-consumption/' + _this.machineId, payload, query)
      .pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
      )
      .subscribe(
        (data: any) => {
          // console.log(data);

          _this.consData = _this.parseData(_this, data.body, _this.consDropdown, true);

          if (count == 0) {
            _this.dispatcherService.getDispatch(_this, 300);
          }
        }
      );

  }

  parseData(_this: any, data: any, consId: any, singleMode: boolean) {

    let consSettings = _this.consumables.find((x: any) => x.id == consId);
    let dashboardCopy = _this.clonerService.deepClone(_this.dashboardConfigUnparsed);

    try {
      consSettings.unit = _this.translate.instant(consSettings.unit ?? '-');
      consSettings.relativeUnit = _this.translate.instant(consSettings.relativeUnit ?? consSettings.unit ?? '-');
    } catch (error) { console.log(error) }

    let consUnit = consSettings?.unit ?? '-';
    let relConsUnit = consSettings?.relativeUnit ?? consSettings.unit ?? '-';
    let productionUnit = _this.filterService.getProductionUnit("singleUnit", _this.machine.profile.productionConfig);

    try {

      let colorClasses = {
        red: dashboardCopy?.classes?.red ?? 'md-red-i',
        iconRed: dashboardCopy?.classes?.red ?? 'md-red',
        green: dashboardCopy?.classes?.green ?? 'md-green-i',
        iconGreen: dashboardCopy?.classes?.green ?? 'md-green',
        gray: dashboardCopy?.classes?.gray ?? 'md-gray-i',
      }

      dashboardCopy.widgets.filter((x: any) => x.objectVariable != null).forEach((widget: any) => {

        if (data.hasOwnProperty(widget.objectVariable) && data[widget.objectVariable] != null) {

          widget.config.forEach((conf: any) => {

            let newDataKey = !data.hasOwnProperty(widget.objectVariable + '$$' + conf.variable) ? (widget.objectVariable + '$$' + conf.variable) : (widget.objectVariable + '$$$$$$' + conf.variable);
            data[newDataKey] = data[widget.objectVariable][conf.variable] != null ? (data[widget.objectVariable][conf.variable] * (consSettings.multiplier != null ? consSettings.multiplier : 1)) : null;

            let customConsUnit = consUnit;

            if (widget.objectVariable == 'rel') {
              customConsUnit = relConsUnit;
              data[newDataKey] = data[widget.objectVariable][conf.variable] != null ? (data[widget.objectVariable][conf.variable] * (consSettings.relativeMultiplier ?? consSettings.multiplier ?? 1)) : null
              customConsUnit += ('/' + productionUnit);
            }
            if (widget.objectVariable == 'avg') customConsUnit += ('/' + (_this.intervalAggregations.selected.value != 1 ? _this.intervalAggregations.selected.value : '') + (_this.intervalAggregations.selected.unit ?? 'hour').substring(0, 1));

            let classes: any = {
              suffix: customConsUnit,
            };

            if (conf.variable == 'LTP') {

              classes = Object.assign(classes, {
                value2: _this.filterService.parseGaugeValue(data[widget.objectVariable].perc, 1, 100, true),
                label: _this.translate.instant("GLOBAL.LAST_N_PERIODS", {
                  n: _this.machine.profile.lastPeriods != null ? _this.machine.profile.lastPeriods : 3
                }),
              });

              if (data[widget.objectVariable].hasOwnProperty('perc')) {
                classes = Object.assign(classes, data[widget.objectVariable].perc >= 0 ? {
                  class: colorClasses.red
                } : {
                  class: colorClasses.green
                });
              }

            };

            if (conf.variable == 'actual') {

              classes = Object.assign(classes, {
                class: colorClasses.gray,
              });

              if (data[widget.objectVariable].hasOwnProperty('perc')) {
                classes = Object.assign(classes, data[widget.objectVariable].perc >= 0 ? {
                  // icon2Class: colorClasses.iconRed,
                  icon2: {
                    icon: "arrow_up",
                    type: "svg"
                  }
                } : {
                  // icon2Class: colorClasses.iconGreen,
                  icon2: {
                    icon: "arrow_down",
                    type: "svg"
                  }
                });
              }

            };

            conf = Object.assign(conf, classes);
            conf.variable = newDataKey;

          });
        }
      });

      _this.dashboardConfig = _this.clonerService.deepClone(dashboardCopy);

    } catch (error) { }

    try {

      _this.colorArray = [];
      if (data != null && data.hasOwnProperty('tracks') && data.tracks != null && data.tracks.length > 0) {

        data.faults = [];
        if (_this.aggrDropdown == null) {
          // Do nothing
        }
        else {
          try {

            let customColors = null;
            if (_this.aggrDropdown != null) customColors = _this.aggregations?.find((x: any) => x.id == _this.aggrDropdown)?.colors;

            if (customColors != null) {
              _this.colorArray = (Object.entries(customColors) as any)?.reduce((acc, [aggrName, color]) => {
                acc.push({
                  id: aggrName,
                  color: color
                });
                return acc;
              }, []);
            }

            else {
              _this.colorArray = _this.aggrDropdown != null ? data.tracks.map((x: any) => x.aggrUnitId).filter(_this.filterService.onlyUnique).map((x: any, idx: any) => {
                return {
                  id: x,
                  color: _this.colors[idx]
                }
              }) : [];
            }
          } catch (error) {
            console.log(error);
          }
        }

      }
    }
    catch (error) { console.log(error) }

    try {

      if (singleMode) {

        data.dataConfig = {
          title: _this.internalDataService.buildExportFileTitle(_this.breadcrumb, _this.interval),
          plotDataAttribute: 'plotData',
          tableDataAttribute: 'faults'
        };
        data.aggrDataConfig = {
          plotDataAttribute: 'aggrPlotData'
        };

        data.plotData = _this.buildPlotConfig(_this, data, consId);
        data.aggrPlotData = _this.buildAggrPlotConfig(_this, data, consId);

        data.tableInfo = _this.clonerService.deepClone(_this.appConfig.consumptionAnalytics?.completeConsumption ?? []);

        // Add consumable unit
        try {
          data.tableInfo.forEach((conf: any) => {
            if (conf.consUnit) conf.unit = consUnit;
            if (conf.relativeUnit) conf.unit = relConsUnit + '/' + productionUnit;
            conf.unitInTitle = true;
          });
        } catch (error) {
          console.log(error);
        }

        try {
          if (_this.aggrDropdown != null) {
            let currentAggregationList: any = _this.clonerService.deepClone(_this.aggregationsCustom != null ? _this.aggregationsCustom : _this.aggregations);
            if (data.tableInfo.findIndex((x: any) => x.variable == "aggregation") == -1) data.tableInfo.push({
              variable: "aggregation",
              orderBy: "aggregation",
              label: _this.translate.instant(currentAggregationList.find((x: any) => x.id == _this.aggrDropdown).label).capitalize(),
            });
            // if (!data.tableColumns.includes("aggregation")) data.tableColumns.push("aggregation");
          }
        } catch (error) {
          console.log(error);
        }
      }


    } catch (error) {
      console.log(error);
    }

    return data;

  }

  buildPlotConfig(_this: any, data: any, consId: any) {

    let aggrId: any = _this.aggrDropdown;
    let currentAggrConfig: any = null;

    try {
      currentAggrConfig = aggrId != null ?
        (_this.aggregations != null && _this.aggregations.length > 0
          ? _this.aggregations.find((x: any) => x.id == _this.aggrDropdown)
          : _this.aggregationsCustom.find((x: any) => x.id == _this.aggrDropdown))
        : null;
    } catch (error) { }

    let aggrTransl: any = null;

    try {
      if (currentAggrConfig != null && currentAggrConfig.hasOwnProperty('singleLabel')) {
        aggrTransl = currentAggrConfig.singleLabel;
      }
    } catch (error) { }

    // let colorArray = aggrId != null ? data.faults.map((x: any) => x[aggrId]).filter((val: any, id: any, array: any) => array.indexOf(val) == id) : [];

    let traces: any = [];

    const timeFormat = _this.filterService.getFormatFromAggrTime(_this.intervalAggregations.selected.unit);

    const aggrBarsTot = _this.filterService.getAggrBars(data.tracks, _this.intervalAggregations.selected.unit, _this.intervalAggregations.selected.value, "tot");
    const aggrBarsRel = _this.filterService.getAggrBars(data.tracks, _this.intervalAggregations.selected.unit, _this.intervalAggregations.selected.value, "rel");

    if (data == null || !data.hasOwnProperty('tracks') || data.tracks.length == 0) {
      return;
    }

    let consSettings = _this.consumables.find((x: any) => x.id == consId);

    try {
      consSettings.unit = _this.translate.instant(consSettings.unit ?? '-');
      consSettings.relativeUnit = _this.translate.instant(consSettings.relativeUnit ?? consSettings.unit ?? '-');
    } catch (error) { console.log(error) }

    const relConsUnit = consSettings?.relativeUnit ?? consSettings.unit;
    const productionUnit = _this.filterService.getProductionUnit("singleUnit", _this.machine?.profile?.productionConfig);

    data.tracks.forEach((track: any, trackIdx: any) => {

      track.aggrUnitLabel = aggrTransl != null ? _this.translate.instant(aggrTransl + '.' + track.aggrUnitId) : track.aggrUnitId;

      if (track.timestamps != null && track.timestamps.length > 0) {
        track.timestamps.forEach((ts: any, tsIdx: any) => {

          data.faults.push({
            totConsumptionP: (track.hasOwnProperty('tot') && track.tot[tsIdx] != null) ? _this.filterService.parseGaugeValue(track.tot[tsIdx], consSettings.decimals, consSettings.multiplier) : null,
            relConsumptionP: (track.hasOwnProperty('rel') && track.rel[tsIdx] != null) ? _this.filterService.parseGaugeValue(track.rel[tsIdx], consSettings.relativeDecimals ?? consSettings.decimals, consSettings.relativeMultiplier ?? consSettings.multiplier) : null,
            // prodTimeP: (track.hasOwnProperty('prodTime') && track.prodTime[tsIdx] != null) ? _this.filterService.parseTime(track.prodTime[tsIdx], 's', 'HH:mm:ss') : null,
            relConsumption: (track.hasOwnProperty('rel')) ? track.rel[tsIdx] : null,
            totConsumption: (track.hasOwnProperty('tot')) ? track.tot[tsIdx] : null,
            // delta: (track.hasOwnProperty('deltaTarget')) ? track.deltaTarget[tsIdx] : null,
            prodTime: (track.hasOwnProperty('prodTime')) ? track.prodTime[tsIdx] : null,
            timestamp: ts,
            timestampP: _this.filterService.parseTableDate(ts, _this.intervalAggregations.selected.unit),
            aggregation: track.aggrUnitLabel,
          });
        });
      }

      if (track.tot != null && track.tot.length > 0) {

        let hovertext = track.tot.map((val: any, idx: any) => {
          let hover = "";
          if (track.aggrUnitId != null) hover = "<b>" + track.aggrUnitLabel + '</b><br>';
          hover += "<b>" + _this.translate.instant("GLOBAL.VALUE") + ": </b>" + (val != null ? _this.filterService.parseGaugeValue(val, consSettings.decimals, consSettings.multiplier) : '-');
          hover += ` [${consSettings.unit ?? '-'}]<br>`;
          hover += "<b>" + _this.translate.instant("GLOBAL.DATE") + ": </b>" + moment(track.timestamps[idx]).format(timeFormat) + '<br>';
          return hover;
        });

        traces.push({
          x: track.timestamps,
          y: track.tot.map((x: any) => x != null ? (x * (consSettings.multiplier != null ? consSettings.multiplier : 1)) : null),
          traceType: 'tot',
          xaxis: 'x',
          yaxis: 'y',
          visible: true,
          width: aggrBarsTot.width[trackIdx],
          offset: aggrBarsTot.offset[trackIdx],
          name: track.aggrUnitId != null ? track.aggrUnitLabel : _this.translate.instant('COMPLETE_CONSUMPTION.TOTAL_CONSUMPTION'),
          type: _this.plotType,
          mode: 'lines+markers',
          hovertext: hovertext,
          hoverinfo: 'text',
          legendgroup: trackIdx,
          line: {
            color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId)?.color ?? 'black' : _this.colors[trackIdx],
            shape: 'spline',
          },
          marker: {
            color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId)?.color ?? 'black' : _this.colors[trackIdx]
          }
        });
      }

      if (track.rel != null && track.rel.length > 0) {

        let hovertext = track.rel.map((val: any, idx: any) => {
          let hover = "";
          if (track.aggrUnitId != null) hover = "<b>" + track.aggrUnitLabel + '</b><br>';
          hover += "<b>" + _this.translate.instant("GLOBAL.VALUE") + ": </b>" + _this.filterService.parseGaugeValue(val, consSettings.relativeDecimals ?? consSettings.decimals, consSettings.relativeMultiplier ?? consSettings.multiplier ?? 1);
          hover += ` [${_this.filterService.calculateRelativeUnit(relConsUnit, productionUnit, "-")}]<br>`;
          hover += "<b>" + _this.translate.instant("GLOBAL.DATE") + ": </b>" + moment(track.timestamps[idx]).format(timeFormat) + '<br>';
          return hover;
        });

        traces.push({
          x: track.timestamps,
          y: track.rel.map((x: any) => x != null ? (x * (consSettings.relativeMultiplier ?? consSettings.multiplier ?? 1)) : null),
          traceType: 'rel',
          xaxis: 'x',
          yaxis: 'y',
          visible: false,
          width: aggrBarsRel.width[trackIdx],
          offset: aggrBarsRel.offset[trackIdx],
          name: track.aggrUnitId != null ? track.aggrUnitLabel : _this.translate.instant('COMPLETE_CONSUMPTION.RELATIVE_CONSUMPTION'),
          type: _this.plotType,
          mode: 'lines+markers',
          hovertext: hovertext,
          hoverinfo: 'text',
          line: {
            color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId)?.color ?? 'black' : _this.colors[trackIdx],
            shape: 'spline',
          },
          marker: {
            color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId)?.color ?? 'black' : _this.colors[trackIdx]
          }
        });
      }

      // if (track.prodTime != null && track.prodTime.length > 0) {
      //   traces.push({
      //     x: track.timestamps,
      //     y: track.prodTime.map((x: any) => x != null ? x / 3600 : null),
      //     traceType: 'prodTime',
      //     xaxis: 'x',
      //     yaxis: 'y2',
      //     visible: true,
      //     name: track.aggrUnitId != null ? track.aggrUnitLabel : _this.translate.instant('COMPLETE_CONSUMPTION.PRODUCTIVE_TIME'),
      //     type: _this.plotType,
      //     mode: 'lines+markers',
      //     hovertext: track.prodTime.map((x: any) => _this.filterService.parseTime(x, 's', 'HH:mm:ss')),
      //     hoverinfo: 'text+x+name',
      //     legendgroup: trackIdx,
      //     showlegend: track.aggrUnitId == null,
      //     line: {
      //       color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId)?.color ?? 'black' : _this.colors[trackIdx],
      //       shape: 'spline',
      //       dash: 'dot',
      //       width: 2
      //     },
      //     marker: {
      //       color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId)?.color ?? 'black' : _this.colors[trackIdx]
      //     }
      //   });
      // }

    });

    let updatemenus: any = [{
      buttons: [{
        args: [{
          // 'visible': traces.map((x: any) => x.traceType == 'prodTime' || x.traceType == 'tot')
          'visible': traces.map((x: any) => x.traceType == 'tot')
        },
        {
          // 'yaxis2.visible': true,
          'yaxis.title.text': _this.translate.instant(consSettings.label) + ` [${consSettings.unit ?? '-'}]`,
        },
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.TOTAL"),
        method: 'update'
      },
      {
        args: [{
          'visible': traces.map((x: any) => x.traceType == 'rel')
        },
        {
          // 'yaxis2.visible': false,
          'yaxis.title.text': _this.translate.instant(consSettings.label) + (` [${_this.filterService.calculateRelativeUnit(relConsUnit, productionUnit, "-")}]`)
        },
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.RELATIVE"),
        method: 'update'
      }
      ],
      direction: 'left',
      pad: {
        'r': 10,
        't': 10
      },
      showactive: true,
      type: 'buttons',
      x: 0.0,
      y: 1.3,
      xanchor: 'left',
      yanchor: 'top'
    }];

    let plotLayout: any = {
      updatemenus: updatemenus,
      legend: {
        orientation: 'h',
        x: 0,
        y: -0.2
      },
      margin: {
        t: 30,
        r: 110,
        b: 50,
        l: 100,
        pad: 15
      },
      xaxis: {
        zeroline: false,
        showgrid: false,
        automargin: true,
        title: {
          standoff: 20
        }
      },
      yaxis: {
        zeroline: true,
        showgrid: false,
        automargin: true,
        title: {
          standoff: 20,
          text: _this.translate.instant(consSettings.label) + ` [${consSettings.unit ?? '-'}]`
        },
        rangemode: 'tozero',
      },
      // yaxis2: {
      //   zeroline: true,
      //   showgrid: false,
      //   overlaying: 'y',
      //   automargin: true,
      //   title: {
      //     standoff: 20,
      //     text: _this.translate.instant("COMPLETE_CONSUMPTION.PRODUCTIVE_TIME") + ' [hr]',
      //   },
      //   side: 'right',
      //   rangemode: 'tozero',
      // },
    };

    return {
      layout: plotLayout,
      traces: traces
    };

  }

  buildAggrPlotConfig(_this: any, data: any, consId: any) {

    if (_this.aggrDropdown == null) return;

    let currentAggrConfig: any = null;

    try {
      currentAggrConfig = _this.aggrDropdown != null ?
        (_this.aggregations != null && _this.aggregations.length > 0
          ? _this.aggregations.find((x: any) => x.id == _this.aggrDropdown)
          : _this.aggregationsCustom.find((x: any) => x.id == _this.aggrDropdown))
        : null;
    } catch (error) { }

    let aggrTransl: any = null;

    try {
      if (currentAggrConfig != null && currentAggrConfig.hasOwnProperty('singleLabel')) {
        aggrTransl = currentAggrConfig.singleLabel;
      }
    } catch (error) { }

    let consSettings = _this.consumables.find((x: any) => x.id == consId);

    try {
      consSettings.unit = _this.translate.instant(consSettings.unit ?? '-');
      consSettings.relativeUnit = _this.translate.instant(consSettings.relativeUnit ?? consSettings.unit ?? '-');
    } catch (error) { console.log(error) }

    // let productionUnit = _this.translate.instant("PRODUCTION_UNITS.CYCLE");
    let relConsUnit = consSettings?.relativeUnit ?? consSettings.unit ?? '-';
    let productionUnit = _this.filterService.getProductionUnit("singleUnit", _this.machine?.profile?.productionConfig);

    let traces: any = [];

    let orderByTot = _this.clonerService.deepClone(data.tracks.sort(_this.filterService.sortByProperty("sumTot", 'desc', true)));
    let orderByRel = _this.clonerService.deepClone(data.tracks.sort(_this.filterService.sortByProperty("sumRel", 'desc', true)));

    traces.push({
      x: orderByTot.map((x: any) => aggrTransl != null ? _this.translate.instant(aggrTransl + '.' + x.aggrUnitId) : x.aggrUnitId),
      y: orderByTot.map((x: any) => x.sumTot != null ? (x.sumTot * (consSettings.multiplier != null ? consSettings.multiplier : 1)) : null),
      traceType: 'tot',
      visible: true,
      type: 'bar',
      hovertext: orderByTot.map((x: any) => {
        let hovertext = '';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.TOTAL_CONSUMPTION") + '</b>: ' + (x.sumTot != null ? _this.filterService.parseGaugeValue(x.sumTot, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.RELATIVE_CONSUMPTION") + '</b>: ' + (x.sumRel != null ? _this.filterService.parseGaugeValue(x.sumRel, consSettings.relativeDecimals ?? consSettings.decimals, consSettings.relativeMultiplier ?? consSettings.multiplier) : '-') + ' ' + relConsUnit + (productionUnit != null ? '/' + productionUnit : '') + '<br>';
        if (!this.appConfig?.consumptionAnalyticsOptions?.hideAvgInAggrPlot) hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.AVG_CONSUMPTION") + '</b>: ' + (x.sumAvg != null ? _this.filterService.parseGaugeValue(x.sumAvg, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + (_this.intervalAggregations.selected.unit != null ? '/' + _this.intervalAggregations.selected.unit : '') + '<br>';
        if (!this.appConfig?.consumptionAnalyticsOptions?.hideProdTimeInAggrPlot) hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.PRODUCTIVE_TIME") + '</b>: ' + _this.filterService.parseTime(x.sumProdTime, 's', 'HH:mm:ss') + '<br>';
        return hovertext;
      }),
      hoverinfo: 'text',
      marker: {
        color: orderByTot.map((y: any) => _this.colorArray.find((x: any) => x.id == y.aggrUnitId)?.color ?? 'black')
      }
    });

    traces.push({
      x: orderByRel.map((x: any) => aggrTransl != null ? _this.translate.instant(aggrTransl + '.' + x.aggrUnitId) : x.aggrUnitId),
      y: orderByRel.map((x: any) => x.sumRel != null ? (x.sumRel * (consSettings.relativeMultiplier ?? consSettings.multiplier ?? 1)) : null),
      traceType: 'rel',
      visible: false,
      type: 'bar',
      hovertext: orderByRel.map((x: any) => {
        let hovertext = '';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.RELATIVE_CONSUMPTION") + '</b>: ' + (x.sumRel != null ? _this.filterService.parseGaugeValue(x.sumRel, consSettings.relativeDecimals ?? consSettings.decimals, consSettings.relativeMultiplier ?? consSettings.multiplier) : '-') + ' ' + relConsUnit + (productionUnit != null ? '/' + productionUnit : '') + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.TOTAL_CONSUMPTION") + '</b>: ' + (x.sumTot != null ? _this.filterService.parseGaugeValue(x.sumTot, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + '<br>';
        if (!this.appConfig?.consumptionAnalyticsOptions?.hideAvgInAggrPlot) hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.AVG_CONSUMPTION") + '</b>: ' + (x.sumAvg != null ? _this.filterService.parseGaugeValue(x.sumAvg, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + (_this.intervalAggregations.selected.unit != null ? '/' + _this.intervalAggregations.selected.unit : '') + '<br>';
        if (!this.appConfig?.consumptionAnalyticsOptions?.hideProdTimeInAggrPlot) hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.PRODUCTIVE_TIME") + '</b>: ' + _this.filterService.parseTime(x.sumProdTime, 's', 'HH:mm:ss') + '<br>';
        return hovertext;
      }),
      hoverinfo: 'text',
      marker: {
        color: orderByRel.map((y: any) => _this.colorArray.find((x: any) => x.id == y.aggrUnitId)?.color ?? 'black')
      }
    });

    let updatemenus: any = [{
      buttons: [{
        args: [{
          'visible': traces.map((x: any) => x.traceType == 'tot')
        },
        {
          'yaxis.title.text': _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + ']' : '[-]')
        },
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.TOTAL"),
        method: 'update'
      },
      {
        args: [{
          'visible': traces.map((x: any) => x.traceType == 'rel')
        },
        {
          'yaxis.title.text': _this.translate.instant(consSettings.label) + ' ' + ((relConsUnit != null) ? '[' + relConsUnit + (productionUnit != null ? '/' + productionUnit : '') + ']' : '[-]')
        }
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.RELATIVE"),
        method: 'update'
      }
      ],
      direction: 'left',
      pad: {
        'r': 10,
        't': 10
      },
      showactive: true,
      type: 'buttons',
      x: 0.0,
      y: 1.3,
      xanchor: 'left',
      yanchor: 'top'
    }];

    let plotLayout: any = {
      updatemenus: updatemenus,
      legend: {
        orientation: 'h',
        x: 0,
        y: -0.2
      },
      margin: {
        t: 30,
        r: 110,
        b: 50,
        l: 100,
        pad: 15
      },
      xaxis: {
        zeroline: false,
        showgrid: false,
        // automargin: true,
        type: 'category',
        title: {
          standoff: 20
        }
      },
      yaxis: {
        zeroline: true,
        showgrid: false,
        // automargin: true,
        title: {
          standoff: 20,
          // text: _this.translate.instant('ENERGY_CONSUMPTION.CONSUMPTION')
          text: _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + ']' : '[-]')
        },
      },
    };

    return {
      layout: plotLayout,
      traces: traces
    };

  }

  buildMultipleConsPlotConfig() {

    let _this = this;

    let traces: any = [];
    _this.consData.table = [];

    let plotLayout: any = {
      legend: {
        orientation: 'h',
        x: 0,
        y: -0.2
      },
      margin: {
        t: 30,
        r: 110,
        b: 50,
        l: 100,
        pad: 15
      },
      xaxis: {
        zeroline: false,
        showgrid: false,
        automargin: true,
        domain: _this.getDomain(_this.consDataMultiple?.length)
      },
    };

    _this.consData.table = _this.consDataMultiple?.[0]?.tracks?.[0]?.timestamps?.reduce((acc, timestamp, idx) => {

      let obj: any = {
        timestamp: timestamp,
        timestampP: _this.filterService.parseTableDate(timestamp, _this.intervalAggregations.selected?.unit),
      };

      _this.consDataMultiple?.forEach(cons => {
        let consSettings = cons?.consSettings;

        let track = cons.tracks?.[0];

        obj[consSettings?.id + '-relConsumptionP'] = _this.filterService.parseGaugeValue(track.rel?.[idx], consSettings.relativeDecimals ?? consSettings.decimals, consSettings.relativeMultiplier ?? consSettings.multiplier)
        obj[consSettings?.id + '-relConsumption'] = track.rel?.[idx]

        obj[consSettings?.id + '-totConsumptionP'] = _this.filterService.parseGaugeValue(track.tot?.[idx], consSettings.decimals, consSettings.multiplier)
        obj[consSettings?.id + '-totConsumption'] = track.tot?.[idx]

      });

      // console.log({ obj });

      acc.push(obj);
      return acc;
    }, []);

    ["tot", "avg", "rel"].forEach(prop => {
      _this.consData['table' + prop?.capitalize()] = _this.consDataMultiple?.reduce((acc, val) => {

        let consSettings = val?.consSettings;

        try {
          consSettings.unit = _this.translate.instant(consSettings.unit ?? '-');
          consSettings.relativeUnit = _this.translate.instant(consSettings.relativeUnit ?? consSettings.unit ?? '-');
        } catch (error) { console.log(error) }

        let consUnit = consSettings?.unit ?? '-';
        let relConsUnit = consSettings?.relativeUnit ?? consSettings.unit ?? '-';
        let productionUnit = _this.filterService.getProductionUnit("singleUnit", _this.machine.profile.productionConfig);

        let customConsUnit = consUnit;
        let value = val?.[prop]?.actual != null ? val?.[prop]?.actual * (consSettings.multiplier ?? 1) : null;

        if (prop == 'rel') {
          value = val?.[prop]?.actual != null ? val?.[prop]?.actual * (consSettings.relativeMultiplier ?? consSettings.multiplier ?? 1) : null;

          customConsUnit = relConsUnit;
          customConsUnit += ('/' + productionUnit);
        }
        else if (prop == 'avg') {
          value = val?.[prop]?.actual != null ? val?.[prop]?.actual * (consSettings.multiplier ?? 1) : null;
          customConsUnit += ('/' + (_this.intervalAggregations.selected.value != 1 ? _this.intervalAggregations.selected.value : '') + (_this.intervalAggregations.selected.unit ?? 'hour').substring(0, 1));
        }


        let obj = {
          id: consSettings?.id,
          label: this.translate.instant(consSettings?.label),
          value: value,
          icon: { icon: consSettings?.icon },
          customUnit: customConsUnit
        };

        acc.push(obj);
        return acc;
      }, []);

    })


    _this.consDataMultiple?.forEach((consumable, consIdx) => {

      let consSettings: any = consumable?.consSettings;
      let configIdx = consIdx + 1;

      plotLayout['yaxis' + configIdx] = {

        // fixedrange: true,
        zeroline: false,
        showgrid: false,
        automargin: true,
        rangemode: 'tozero',
        anchor: 'free',
        // anchor: configIdx != 2 ? 'free' : 'x',
        position: configIdx > 1 ? (configIdx % 2 != 0 ? 0.05 * (configIdx - 1) / 2 : (1 - (0.05 * (configIdx - 2) / 2))) : null,
        overlaying: configIdx > 1 ? 'y' : null,
        side: configIdx % 2 == 0 ? 'right' : 'left',
        title: {
          // standoff: 50,
          text: _this.translate.instant(consSettings?.label) + ' [' + this.filterService.convertUnit(consSettings?.unit)?.unit + ']',
        }
      };

      try {
        consSettings.unit = _this.translate.instant(consSettings.unit ?? '-');
        consSettings.relativeUnit = _this.translate.instant(consSettings.relativeUnit ?? consSettings.unit ?? '-');
      } catch (error) { console.log(error) }

      consumable.tracks.forEach((track: any, trackIdx: any) => {

        track.aggrUnitLabel = track.aggrUnitId;

        if (track.tot != null && track.tot.length > 0) {
          traces.push({
            x: track.timestamps,
            y: track.tot.map((x: any) => x != null ? (x * (consSettings.multiplier ?? 1)) : null),
            traceType: 'tot',
            xaxis: 'x',
            yaxis: 'y' + configIdx,
            visible: true,
            name: _this.translate.instant(consSettings?.label),
            // type: _this.plotType,
            type: 'scatter',
            mode: 'lines+markers',
            hovertext: track.tot.map((x: any) => _this.filterService.parseGaugeValue(x, consSettings.decimals, consSettings.multiplier)),
            hoverinfo: 'text+x+name',
            // legendgroup: trackIdx,
            line: {
              // color: _this.colors[trackIdx],
              shape: 'spline',
            },
            // marker: {
            //   color: _this.colors[trackIdx]
            // }
          });
        }

        if (track.rel != null && track.rel.length > 0) {
          traces.push({
            x: track.timestamps,
            y: track.rel.map((x: any) => x != null ? (x * (consSettings.relativeMultiplier ?? consSettings.multiplier ?? 1)) : null),
            traceType: 'rel',
            xaxis: 'x',
            yaxis: 'y' + configIdx,
            visible: false,
            // name: track.aggrUnitId != null ? track.aggrUnitLabel : _this.translate.instant('COMPLETE_CONSUMPTION.RELATIVE_CONSUMPTION'),
            name: _this.translate.instant(consSettings?.label),
            // type: _this.plotType,
            type: 'scatter',
            mode: 'lines+markers',
            hovertext: track.rel.map((x: any) => _this.filterService.parseGaugeValue(x, consSettings.relativeDecimals ?? consSettings.decimals, consSettings.relativeMultiplier ?? consSettings.multiplier ?? 1)),
            hoverinfo: 'text+x+name',
            showlegend: true,
            line: {
              // color: _this.colors[trackIdx],
              shape: 'spline',
            },
            // marker: {
            //   color: _this.colors[trackIdx]
            // }
          });
        }

      });
    })

    let updatemenus: any = [{
      buttons: [{
        args: [
          {
            // 'visible': traces.map((x: any) => x.traceType == 'prodTime' || x.traceType == 'tot')
            'visible': traces.map((x: any) => x.traceType == 'tot')
          }
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.TOTAL"),
        method: 'update'
      },
      {
        args: [
          {
            'visible': traces.map((x: any) => x.traceType == 'rel')
          },
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.RELATIVE"),
        method: 'update'
      }
      ],
      direction: 'left',
      pad: {
        'r': 10,
        't': 10
      },
      showactive: true,
      type: 'buttons',
      x: 0.0,
      y: 1.3,
      xanchor: 'left',
      yanchor: 'top'
    }];

    let idx = 0;
    _this.consDataMultiple?.forEach((cons, consIdx) => {
      idx = consIdx + 1;
      let consSettings: any = this.clonerService.deepClone(cons?.consSettings);
      let consUnit = consSettings?.unit ?? '-';
      let relConsUnit = consSettings?.relativeUnit ?? consSettings.unit ?? '-';
      let productionUnit = _this.filterService.getProductionUnit("singleUnit", _this.machine.profile.productionConfig);

      if (updatemenus[0].buttons[0].args[1] == null) updatemenus[0].buttons[0].args.push({})
      if (updatemenus[0].buttons[1].args[1] == null) updatemenus[0].buttons[1].args.push({})

      updatemenus[0].buttons[0].args[1]['yaxis' + (idx != 1 ? idx : '') + '.title.text'] = _this.translate.instant(consSettings.label) + ' ' + ((consUnit != null) ? '[' + consUnit + ']' : '[-]');
      updatemenus[0].buttons[1].args[1]['yaxis' + (idx != 1 ? idx : '') + '.title.text'] = _this.translate.instant(consSettings.label) + ' ' + ((relConsUnit != null) ? '[' + relConsUnit + (productionUnit != null ? '/' + productionUnit : '') + ']' : '[-]');

    });

    plotLayout.updatemenus = updatemenus;

    // console.log({ plotLayout });

    return {
      layout: plotLayout,
      traces: traces
    };



  }

  getPreviousBucket() {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start), 'm') / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).subtract(duration, 'm')));
    let end = JSON.parse(JSON.stringify(moment(this.interval.end).subtract(duration, 'm')));

    this.interval.start = start;
    this.interval.end = end;

    this.interval.enabledPolling = false;

    this.interval.startF = this.intervalService.strFormat(this.interval, start) + ' - ' + this.intervalService.strFormat(this.interval, end);
    this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

    this.pageState.next(5);
    this.getEvents(this, 0);
  }

  getNextBucket() {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start), 'm') / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).add(duration, 'm')));
    let end = moment(this.interval.end).add(duration, 'm');

    if (moment().diff(end, 'm') < 5) {
      this.interval.start = start;
      this.interval.enabledPolling = true;
      this.interval.end = JSON.parse(JSON.stringify(moment()));
      this.interval.startF = this.intervalService.strFormat(this.interval, start);
      this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

      this.pageState.next(5);
      this.getEventsBreakdowns(this);
    }
    else {

      this.interval.start = start;
      this.interval.end = JSON.parse(JSON.stringify(end));

      this.interval.enabledPolling = false;
      this.interval.startF = this.intervalService.strFormat(this.interval, start) + ' - ' + this.intervalService.strFormat(this.interval, this.interval.end);
      this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

      this.pageState.next(5);
      this.getEvents(this, 0);
    }

  }

  getDomain(range: any) {
    if (range != null && range > 0) {
      // solo due assi
      if (range < 3) {
        return [0, 1];
        // lunghezza pari
      } else if (range % 2 == 0) {
        return [0.05 * (range - 1) / 2, 1 - (0.05 * (range - 1) / 2)];
        // lunghezza dispari
      } else if (range % 2 != 0) {
        return [0.05 * (range - 1) / 2, 1 - (0.05 * (range - 3) / 2)];
      }
    }
    return [0, 1];
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    );

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingEvents.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
  }
}