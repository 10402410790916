import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})

export class AppConfigService {

  public appInfo: any;
  public appConfig: any;
  public sidenavItems: any;
  public machineProfiles: any;
  public convConfig: any;
  public defaultCustomTranslations: any;
  public defaultCustomVariables: any;
  public hierarchy: any;

  public defaultAlertsConfig: any = [
    {
      "alertType": "numericVar",
      "label": "RBA.ALERT_TYPES.NUMERIC_VAR",
      "parameters": [
        {
          "id": 1,
          "unit": "-",
          "paramType": "DYNAMIC-VAR",
          "type": "dropdown",
          "addSearchFilter": true,
          "dropdownType": "numbers"
        },
        {
          "id": 2,
          "unit": "-",
          "paramType": "OPERATOR",
          "type": "dropdown",
          "dropdownType": "operators"
        },
        {
          "id": 3,
          "unit": "-",
          "paramType": "STATIC-VAR"
        },
        {
          "id": 4,
          "unit": "-",
          "paramType": "STATIC-VAR",
          "min": 0,
          "max": 10000
        },
        {
          "id": 5,
          "unit": "-",
          "paramType": "TIME-AGGR",
          "type": "dropdown",
          "dropdownType": "timeAggrs"
        },
        {
          "id": 7,
          "unit": "-",
          "paramType": "AGGR-TYPE",
          "type": "dropdown",
          "dropdownType": "aggregationTypes"
        }
      ]
    },
    {
      "alertType": "boolVar",
      "label": "RBA.ALERT_TYPES.BOOLEAN_VAR",
      "parameters": [
        {
          "id": 1,
          "unit": "-",
          "paramType": "DYNAMIC-VAR",
          "type": "dropdown",
          "addSearchFilter": true,
          "dropdownType": "booleans"
        },
        {
          "id": 2,
          "unit": "-",
          "paramType": "OPERATOR",
          "type": "dropdown",
          "dropdownType": "operators"
        },
        {
          "id": 3,
          "unit": "-",
          "paramType": "STATIC-VAR",
          "min": 0,
          "max": 1
        },
        {
          "id": 4,
          "unit": "-",
          "paramType": "STATIC-VAR",
          "min": 0,
          "max": 10000
        },
        {
          "id": 5,
          "unit": "-",
          "paramType": "TIME-AGGR",
          "type": "dropdown",
          "dropdownType": "timeAggrs"
        }
      ]
    },
    {
      "alertType": "kpiVar",
      "label": "RBA.ALERT_TYPES.KPI_VAR",
      "parameters": [
        {
          "id": 1,
          "unit": "-",
          "paramType": "KPI",
          "type": "dropdown",
          "addSearchFilter": true,
          "dropdownType": "kpis"
        },
        {
          "id": 2,
          "unit": "-",
          "paramType": "OPERATOR",
          "type": "dropdown",
          "dropdownType": "operators"
        },
        {
          "id": 3,
          "unit": "-",
          "paramType": "STATIC-VAR"
        },
        {
          "id": 4,
          "unit": "-",
          "paramType": "STATIC-VAR",
          "min": 0,
          "max": 10
        },
        {
          "id": 5,
          "unit": "-",
          "paramType": "TIME-AGGR",
          "type": "dropdown",
          "dropdownType": "timeAggrs"
        }
      ]
    }
  ];

  constructor(
    public http: ApiService
  ) { }


  httpPromisePost(url, payload) {
    return new Promise((resolve, reject) => {

      this.http.sendPostRequest(url, payload).subscribe(
        (data: any) => { resolve(data.body); })

    });
  }

  searchTabFromUrl(urlToSearch: string) {

    let section = Object.keys(this.appConfig).find((_section: any) => {
      let tabs = this.appConfig[_section]?.tabs;
      if (tabs && Array.isArray(tabs) && tabs?.length > 0) {
        return tabs.find((_tab: any) => _tab.url == urlToSearch) != null;
      }
    })

    return section
  }


  async loadAppConfigurations() {

    // let tkn_cookie = getCookie('access_token')
    // if (!tkn_cookie) {
    //   let code = getQueryParam('code');
    //   console.log(code)
    //   if (code && code.length > 0) {

    //     let tokenUrl = "http://localhost:3009/oauth2/token?" +
    //       "client_id=3a4f25a0-a79a-48da-b0c5-7393a5a8b5aa&" +
    //       "client_secret=0R2Re2jWCRXrF0Kh-H43wO_Vrudua_IOydaETfP1pmM&" +
    //       "redirect_uri=http%3A%2F%2Flocalhost%3A4200&" +
    //       "code=" + code + "&" +
    //       "grant_type=authorization_code"

    //     let tokenData = {};

    //     let data: any;
    //     data = await this.httpPromisePost(tokenUrl, tokenData);
    //     console.log(data);
    //     setCookie('access_token', data.access_token, 1)
    //     // this.httpClient.post(tokenUrl, tokenData)
    //   }
    //   else {

    //     // "oauth": {
    //     //   "originUri": "http://localhost",
    //     //   "clientId": "3a4f25a0-a79a-48da-b0c5-7393a5a8b5aa",
    //     //   "clientSecret": "0R2Re2jWCRXrF0Kh-H43wO_Vrudua_IOydaETfP1pmM",
    //     //   "tenantId": "73f2ab10-6ba1-9f44-8163-9c3f77f91a4b",
    //     //   "secondsBeforeExpiration": 300,
    //     //   "useHttps": true,
    //     //   "port": 3009
    //     // // },
    //     window.location.href = "http://localhost:3009/oauth2/authorize?" +
    //       "client_id=3a4f25a0-a79a-48da-b0c5-7393a5a8b5aa&" +
    //       "redirect_uri=http%3A%2F%2Flocalhost%3A4200&" +
    //       "response_type=code&" +
    //       "response_mode=query&" +
    //       "scope=offline_access&" +
    //       "tenantId=73f2ab10-6ba1-9f44-8163-9c3f77f91a4b"

    //     console.log("http://localhost:3009/oauth2/authorize?" +
    //       "client_id=3a4f25a0-a79a-48da-b0c5-7393a5a8b5aa&" +
    //       "redirect_uri=http%3A%2F%2Flocalhost%3A4200&" +
    //       "response_type=code&" +
    //       "response_mode=query&" +
    //       "scope=offline_access&" +
    //       "tenantId=73f2ab10-6ba1-9f44-8163-9c3f77f91a4b")

    //   }
    // }

    // console.log('COOKIE', document.cookie)
    // console.log(document)

    // load app-info.json
    const appInfo = await this.http.sendGetRequest('assets/config/app-info.json').toPromise();
    this.appInfo = appInfo.body;

    const sources40F = this.appInfo.sources40F != null ? this.appInfo.sources40F : 'assets/config/';

    // load app-config.json
    const appConfig = await this.http.sendGetRequest(sources40F + 'app-config.json').toPromise();
    this.appConfig = appConfig.body;

    let faultAnalysisDefault = {
      "id": "fault-analysis",
      "disabled": false,
      "hide": true,
      "group": "mat-la-breakdowns",
      "title": "FAULT_ANALYSIS.TITLE",
      "icon": {
        "icon": "fault_trend",
        "type": "svg"
      },
      "url": "/:machineId/lean-analytics/fault-analysis"
    };

    let alarmsUrl = '/:machineId/machine-supervision/alarms';
    let breakdownsUrl = '/:machineId/lean-analytics/breakdowns';
    let faultAnalysisUrl = '/:machineId/lean-analytics/fault-analysis';

    let faultAnalysisPresent = this.searchTabFromUrl(faultAnalysisUrl);
    if (!faultAnalysisPresent && !this.appConfig?.ignoreFaultAnalysis) {
      let breakdownsPresent = Object.keys(this.appConfig).find((_section: any) => {
        let tabs = this.appConfig[_section]?.tabs;
        if (tabs != null && Array.isArray(tabs) && tabs?.length > 0) {
          let breakdownsTabIndex = tabs.findIndex((_tab: any) => _tab.url == breakdownsUrl);
          if (breakdownsTabIndex != -1) {
            let faultAnalysisTabIndex = breakdownsTabIndex + 1;
            tabs.splice(faultAnalysisTabIndex, 0, faultAnalysisDefault);
            return true;
          }
        }
      })

      if (!breakdownsPresent && this.searchTabFromUrl(alarmsUrl) != null) {

        if (this.appConfig.leanAnalytics == null) {
          // TODO add to sidenav-items
          this.appConfig.leanAnalytics = { tabs: [] };
        }
        this.appConfig.leanAnalytics.tabs.push(faultAnalysisDefault);
      }

    }

    try {
      // load machine-profiles.json
      const machineProfiles = await this.http.sendGetRequest(sources40F + 'machine-profiles.json').toPromise();
      this.machineProfiles = machineProfiles.body;

      for (let mp of this.machineProfiles?.profileList) {
        if (typeof mp?.value === 'string') {
          if (!mp.value.endsWith('.json')) mp.value += '.json';
          try {
            let val = await this.http.sendGetRequest(sources40F + mp.value).toPromise();
            mp.value = val.body;
          } catch (e) {
            console.log(e);
          }
        }
      }
    } catch (error) {
      this.machineProfiles = {
        "profiles": [
          {
            "id": "default"
          }
        ],
        "profileList": [
          {
            "id": "default",
            "value": {}
          }
        ]
      };
    }

    // load sidenav-items.json
    const sidenavItems = await this.http.sendGetRequest(sources40F + 'sidenav-items.json').toPromise();
    this.sidenavItems = sidenavItems.body;

    // load hierarchy.json
    try {
      const hierarchy = await this.http.sendGetRequest(sources40F + 'hierarchy.json').toPromise();
      this.hierarchy = hierarchy.body;
    } catch (error) {
      this.hierarchy = null;
    }

    try {
      // load conversions-config.json
      const convConfig = await this.http.sendGetRequest(sources40F + 'default_conversions.json').toPromise();
      this.convConfig = convConfig.body;
    } catch (error) {
      this.convConfig = null;
    }

    if (this.appConfig?.addCustomTranslations) {
      try {
        // load default-customTranslations.json
        const defaultCustomTranslations = await this.http.sendGetRequest(sources40F + 'default-customTranslations.json').toPromise();
        this.defaultCustomTranslations = defaultCustomTranslations.body;
      } catch (error) {
        this.defaultCustomTranslations = null;
      }
    }

    if (this.appConfig?.addCustomVariables) {
      try {
        // load default-customVariables.json
        const defaultCustomVariables = await this.http.sendGetRequest(sources40F + 'default-customVariables.json').toPromise();
        this.defaultCustomVariables = defaultCustomVariables.body;
      } catch (error) {
        this.defaultCustomVariables = null;
      }
    }

  }

  get getAppInfo() {
    if (!this.appInfo) throw Error('app-info.json not found');
    return this.appInfo;
  }

  get getAppConfig() {
    if (!this.appConfig) throw Error('app-config.json not found');
    return this.appConfig;
  }

  get getSidenavItems() {
    if (!this.sidenavItems) throw Error('sidenav-items.json not found');
    return this.sidenavItems;
  }

  get getMachineProfiles() {
    if (!this.machineProfiles) throw Error('machine-profiles.json not found');
    return this.machineProfiles;
  }

  get getHierarchy() {
    return this.hierarchy;
  }

  get getConvConfig() {
    return this.convConfig;
  }

  get getDefaultCustomTranslations() {
    return this.defaultCustomTranslations;
  }

  get getDefaultCustomVariables() {
    return this.defaultCustomVariables;
  }

  get getDefaultAlertsConfig() {
    return this.defaultAlertsConfig;
  }

}
