<!-- error & loading -->
<div *ngIf="pageState.value > 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
  <!-- utils -->
  <!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->
</div>

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady || pageState.value == 0" fxLayout="column" fxLayoutAlign="center start"
  class="w100 h100">

  <!-- toolbar -->
  <div fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">
    <!-- tabs -->
    <ff-tabs [tabs]="tabs"></ff-tabs>
  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start center" class="tab-content">

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value != 0 && pageState.value < pageStateReady" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div fxLayout="row" fxLayoutAlign="start center" class="w100">

      <div class="interval-selector" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="start center">

        <div *ngIf="availablePhases != null && availablePhases.length > 0" class="box" fxLayout="row"
          fxLayoutAlign="start center">
          <mat-icon class="icon">event_available</mat-icon>
          <mat-form-field appearance="fill">
            <mat-label>{{'PROCESS_LOG.PHASE_SELECTION'|translate}}</mat-label>
            <mat-select [(value)]="phaseSelectedId">
              <mat-option *ngFor="let phase of availablePhases" [value]="phase.outputPhaseId"
                (click)="phaseSelectionChange(phaseSelectedId)">{{phase.label|translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <span fxFlex></span>

      <button mat-button (click)="openVariablesSelectionDialog()" class="filter-button" fxLayout="row"
        fxLayoutAlign="center center" [disabled]="pageState.value == 0">
        {{ 'PROCESS_LOG.VARIABLES_SELECTION' | translate }}
        <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
          style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
        </mat-icon>
      </button>

    </div>

    <div *ngIf="pageStateReady" style="border-radius: 25px; background-color: white; margin: 12px 0 4px 4px;"
      class="w100">
      <div class="w100 ff-widget-container" fxLayout="row wrap" fxLayoutAlign="start stretch"
        fxLayoutGap="{{ phaseDataConfig.gap != null ? (phaseDataConfig.gap + ' grid') : '8px grid' }}">
        <ff-widget *ngFor="let w of phaseDataConfig.widgets" fxFlex.xl
          [fxFlex.lt-xl]="'calc(' + w.flex + '% - ' + (phaseDataConfig.gap != null ? phaseDataConfig.gap : '8px') + ')'"
          [widget]="{ type: w.type, data: phaseData, machineProfile: machine.profile, config: w.config, title: w.title }">
        </ff-widget>
      </div>
    </div>

    <!-- error -->
    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData" style="margin-top: 12px;"></ff-error-card>

    <div *ngFor="let group of dashboardConfig.plotsConfig.list" class="w100">
      <div *ngFor="let trace of group.traces" class="plot-container gca"
        [ngClass]="{'hidePlot': !group.show || !trace.show || pageState.value == 0}">
        <div class="phase-data-title-plots" fxLayout="row" fxLayoutAlign="start center">
          <span>{{ trace.label }}</span>
          <span fxFlex></span>
          <!-- <mat-icon *ngIf="!onlyOneGroupSelected" svgIcon="faults" class="icon left clickable"
            (click)="toggleGroup(group, dashboardConfig.plotsConfig.list)">
          </mat-icon> -->

        </div>
        <div fxLayout="row" fxLayoutAlign="start center" class="w100 h100">
          <div [id]="'prova_' + group.id + '_' + trace.label" class="variables-plot"></div>
          <div style="width: 200px; height: 100%;">
            <ff-gauge [gaugeData]="{ 'label': 'CYCLES.GCA_SHORT', 'value': trace.gca, 'skipOnChanges': true }"
              fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="center center"></ff-gauge>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>