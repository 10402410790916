<div [ngClass]="{
    'space-for-events': widget?.spaceForEvents && widget?.isFlags,
    'calc100vh-250px': widget['calc100vh-250px'],
    'no-background': widget.type == 'ff-divider' || !widget?.type,
    'ff-widget-box': !widget?.compressed,
    'overflow-auto': widget.autoOverflow
    }"
    [style.max-height]="widget.config?.overflowMaxHeight"
>

    <div *ngIf="widget.type == 'ff-loading-card'"  fxLayoutAlign="center center"
    [ngStyle]="{'height': 'calc(100%)'}">
        <ff-loading-card  *ngIf="widget.type == 'ff-loading-card'" [widget]="widget">
        </ff-loading-card>
    </div>
    <ff-synoptic *ngIf="widget.type == 'ff-synoptic'" [widget]="widget"></ff-synoptic>
    <ff-synoptic-time-machine *ngIf="widget.type == 'ff-synoptic-time-machine'" [widget]="widget"></ff-synoptic-time-machine>
    <ff-status *ngIf="widget.type == 'ff-status'" [widget]="widget" fxLayout="column" fxLayoutAlign="space-between start"></ff-status>
    <ff-value *ngIf="widget.type == 'ff-value'" [widget]="widget" fxLayout="column" fxLayoutAlign="space-between start"></ff-value>
    <ff-column-array *ngIf="widget.type == 'ff-column-array'" [widget]="widget" fxLayout="column" fxLayoutAlign="start stretch"></ff-column-array>
    <ff-last-update *ngIf="widget.type == 'ff-last-update'" [widget]="widget" fxLayout="column" fxLayoutAlign="space-between start"></ff-last-update>
    <ff-led *ngIf="widget.type == 'ff-led'" [widget]="widget" fxLayout="column" fxLayoutAlign="space-between start"></ff-led>
    <ff-bar *ngIf="widget.type == 'ff-bar'" [widget]="widget" fxLayout="column" fxLayoutAlign="space-between start"></ff-bar>
    <ff-gauge *ngIf="widget.type == 'ff-gauge'" [widget]="widget" fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="center center"></ff-gauge>
    <ff-info-gauge *ngIf="widget.type == 'ff-info-gauge'" [widget]="widget" fxLayout="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="center center"></ff-info-gauge>
    <ff-plotly-chart *ngIf="widget.type == 'ff-plotly-chart'" [widget]="widget" fxLayout="column" fxLayoutAlign="space-between start"></ff-plotly-chart>
    <ff-plotly-chart-table *ngIf="widget.type == 'ff-plotly-chart-table'" [widget]="widget" fxLayout="column" fxLayoutAlign.gt-sm="space-between start" fxLayoutAlign="space-between center"></ff-plotly-chart-table>    
    <ff-apex-chart *ngIf="widget.type == 'ff-apex-chart'" [widget]="widget" fxLayout="column" fxLayoutAlign="space-between start"></ff-apex-chart>
    <ff-apex-chart-with-switch *ngIf="widget.type == 'ff-apex-chart-with-switch'" [widget]="widget" fxLayout="column" fxLayoutAlign="space-between start"></ff-apex-chart-with-switch>
    <ff-image *ngIf="widget.type == 'ff-image'" [widget]="widget" fxLayout="column" fxLayoutAlign="center center"></ff-image>
    <ff-health-bar *ngIf="widget.type == 'ff-health-bar'" [widget]="widget" fxLayout="column" fxLayoutAlign="center center"></ff-health-bar>
    <ff-custom-calendar *ngIf="widget.type == 'ff-custom-calendar'" [widget]="widget"></ff-custom-calendar>
    <ff-parameter-change-table *ngIf="widget.type == 'ff-parameter-change-table'" [widget]="widget"></ff-parameter-change-table>
    <ff-mailing-list *ngIf="widget.type == 'ff-mailing-list'" [widget]="widget"></ff-mailing-list>
    <ff-url-path *ngIf="widget.type == 'ff-url-path'" [widget]="widget"></ff-url-path>
    <ff-switch *ngIf="widget.type == 'ff-switch'" [widget]="widget"></ff-switch>
    <ff-smiley-face *ngIf="widget.type == 'ff-smiley-face'" [widget]="widget"></ff-smiley-face>
    <ff-table-sortable *ngIf="widget.type == 'ff-table-sortable'" [(tableConfig)]="widget.config" (clicked)="tableAction($event)" 
        (inputTag)="tableInputTagChange($event)" fxLayout="column" fxLayoutAlign="center center"></ff-table-sortable>
    <ff-multiple-tables *ngIf="widget.type == 'ff-multiple-tables'" [widget]="widget" (clicked)="tableAction($event)" fxLayout="column" fxLayoutAlign="space-between start"></ff-multiple-tables>
    <hr *ngIf="widget.type == 'ff-divider'" 
    [ngStyle]="{'border-top': 
        (widget?.config?.height != null ? widget?.config?.height : '1px') + ' ' + 
        (widget?.config?.type != null ? widget?.config?.type : 'solid') + ' ' + 
        (widget?.config?.color != null ? widget?.config?.color : '#D3D3D3')
    }">
</div>
