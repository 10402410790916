<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <div class="w100" style="height: calc(100% - 64px);">
    <!-- aggregations -->
    <div fxLayout="row" fxLayoutAlign="start center" class="buttons-container">
      <button class="filter-button" *ngFor="let dInterval of dialogIntervals.list" (click)="regetData(dInterval.id)"
        [disabled]="plotState == 0" fxLayout="row" fxLayoutAlign="space-evenly center"
        [ngClass]="{'selected': dInterval.id == dialogIntervals.selected}">
        <span>{{ dInterval.label | translate }}</span>
      </button>
    </div>

    <div *ngIf="plotState == 1" style="padding: 16px 32px 0;">
      <div id="plot" class="w100 h100">
        
      </div>
    </div>

    <div *ngIf="plotState == 2" fxLayout="row" fxLayoutAlign="center center" class="w100 h100">
      <div class="not-found" fxLayout="row" fxLayoutAlign="center center">
        <span>{{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}</span>
      </div>
    </div>

    <div *ngIf="plotState == 0" class="w100 h100">
      <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
        <ff-loading-card></ff-loading-card>
      </div>
    </div>
  </div>

</mat-dialog-content>