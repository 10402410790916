<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1}">

    <!-- toolbar -->
    <div *ngIf="pageState.value >= 5" class="ff-toolbar pb-3" fxLayout="column" fxLayoutAlign="center start"
      fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center" style="border-bottom: 1px solid #D6DDE0;">

      <!-- slider -->
      <div fxLayout="row" fxLayoutAlign="start center" class="ngx-slider-container">
        <!-- <span style="white-space: pre; margin-right: 12px;">{{'ALARMS.HOUR_SELECTION'|translate}}</span> -->
        <ngx-slider [(value)]="sliderConf.min" [(highValue)]="sliderConf.max" [options]="sliderConf.options"
          (userChangeEnd)="sliderConf.userChangeEnd($event)"></ngx-slider>
      </div>

      <span fxFlex></span>

      <!-- intervals -->
      <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

    </div>

    <!-- toolbar -->
    <div *ngIf="pageState.value >= 5" class="ff-toolbar py-3" fxLayout="column" fxLayoutAlign="center start"
      fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <!-- switch -->
      <div fxLayout="row" fxLayoutAlign="center center" class="switch-container">
        <span style="margin-right: 8px;">
          {{'ALARMS.ACTIVE'|translate}}
        </span>
        <ui-switch [checked]="alarms.switch.checked" (change)="alarmSwitchChange($event)"
          [disabled]="alarms.list.length == 0" checkedLabel="{{alarms.switch.checkedLabel}}"
          uncheckedLabel="{{alarms.switch.uncheckedLabel}}">
        </ui-switch>
      </div>

      <span fxFlex></span>

      <!-- search -->
      <span style="width: 100px;"></span>
      <div class="search-input h-auto" fxLayout="row" fxLayoutAlign="start center" style="min-width: 300px;">
        <mat-icon class="icon">search</mat-icon>
        <input type="text" [(ngModel)]="searchAlarms" (keyup)="filterAlarms()"
          placeholder="{{'GLOBAL.SEARCH'|translate}}">
      </div>

    </div>

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value <= pageStateReady" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <!-- table -->
    <mat-card fxLayout="column" [ngClass]="{'hidePaginator': (pageState.value < 6 || alarms.filtered.length == 0) }">

      <table mat-table [dataSource]="alarmsData" matSort matSortActive="timeStart" matSortDirection="desc"
        *ngIf="pageState.value >= 6 && alarms.filtered.length > 0">

        <ng-container matColumnDef="icon">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let alarm" class="td-icon">
            <div class="box-icon {{ alarm.timeEnd == null ? alarm.typeIcon.class : 'md-gray-i-2'}}">
              <mat-icon *ngIf="alarm.typeIcon != null" class="icon" aria-label="alarm">{{alarm.typeIcon.icon}}
              </mat-icon>
            </div>
          </td>
        </ng-container>

        <ng-container *ngFor="let info of alarmsInfo" matColumnDef="{{info.variable}}">
          <th mat-header-cell *matHeaderCellDef [mat-sort-header]="info.orderBy != null ? info.orderBy : null"
            [disabled]="info.orderBy == null">
            {{info.label|translate}}
          </th>
          <td mat-cell *matCellDef="let alarm" [ngClass]="{ 'primary': info.primary }">
            <span *ngIf="info.prefix != null && info.type == null">{{info.prefix}}&nbsp;</span>
            <span *ngIf="info.type == null">{{ alarm[info.variable] | placeholder }}</span>
            <span *ngIf="info.suffix != null && info.type == null">&nbsp;{{info.suffix}}</span>

            <span *ngIf="alarm.isCloth && info.variable=='message' " matTooltip="{{alarm.clothsSplitted}}"
              matTooltipClass="my-tooltip">
              <mat-icon class="icon">info</mat-icon>
            </span>

            <!-- checkbox -->
            <div *ngIf="info.type == 'checkbox'" fxLayout="row" fxLayoutAlign="start center">

              <mat-icon *ngIf="alarm[info.variable]" svgIcon="radio_button" class="icon small left radio_button">
              </mat-icon>
              <mat-icon *ngIf="!alarm[info.variable]" svgIcon="radio_button_disabled"
                class="icon small left radio_button_disabled">
              </mat-icon>
              <span>{{ (alarm[info.variable] ? 'GLOBAL.YES' : 'GLOBAL.NO') | translate }}</span>

            </div>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="alarmsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: alarmsColumns;"></tr>
      </table>
      <mat-paginator [ngClass]="{'hidePaginator': (pageState.value < 6 || alarms.filtered.length == 0) }"
        [length]="alarms.filtered.length" [pageSizeOptions]="alarms.pageOptions" [pageSize]="alarms.pageSize"
        showFirstLastButtons></mat-paginator>

    </mat-card>

    <!-- no results -->
    <mat-card class="not-found" *ngIf="pageState.value >= 6 && alarms.filtered.length == 0">
      <span>{{ 'ALERTS.NOT_FOUND' | translate }}</span>
    </mat-card>

  </div>

</div>
