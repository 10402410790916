<div>

    <button *ngIf="urlPath?.length > 1" class="rounded-button" (click)="navigateToPreviousPath()"
        style="margin-right: 12px;">
        <ff-icon icon="arrow_back"></ff-icon>
    </button>

    <span *ngFor="let path of urlPath; let index = index" class="clickable url" (click)="navigateToPath(path, index)">
        {{ path.name | placeholder:widget?.referenceComponent?.machine?.machineName }}/
    </span>

</div>