<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <ng-container *ngIf="!isSmThanTablet">

      <button *ngIf="showResetInterval" mat-button class="filter-button" aria-label="remove filters"
        (click)="resetDefaultInterval()">
        <mat-icon class="icon">refresh</mat-icon>
        <span style="padding-left: 2px;">{{'CONTINUOUS_EXPLORATION.RESET_INTERVAL' | translate}}</span>
      </button>

      <!-- reset model -->
      <ng-container *ngIf="appConfig?.resetAnomalyDetectionModel">
        <button class="filter-button md-red" (click)="resetModelConfirm()" fxLayout="row"
          fxLayoutAlign="space-evenly center">
          <ff-icon icon="cancel"></ff-icon>
          <span>{{ 'HEALTH_MONITORING.RESET_MODEL' | translate }}</span>
        </button>
      </ng-container>

      <!-- change component -->
      <button mat-button class="filter-button"
        (click)="filterService.openComponentSelectionDialog(this, 'health-traceability')">
        <span>{{ 'HEALTH_MONITORING.CHANGE_COMPONENT' | translate }}</span>
      </button>

      <!-- aggregations -->
      <button mat-button class="filter-button"
        *ngFor="let aggr of aggregations | filterByProperty: ['showInHealthMonitoring', '==', true]"
        (click)="openAggrDialog(aggr)" [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
        <mat-icon *ngIf="aggr.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': aggr.label}">
          {{aggr.icon.icon }}</mat-icon>
        <mat-icon *ngIf="aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
          translate-attr="{'aria-label': aggr.label}"></mat-icon>
        <i *ngIf="aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor'" class="icon {{ aggr.icon.icon }}"
          translate-attr="{'aria-label': aggr.label}"></i>
        <span>{{ aggr.selectionLabel }}</span>
        <div fxLayout="row" fxLayoutAlign="center center"
          *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
          {{ aggregationsPayload[aggr.id].length }}
        </div>
      </button>

      <!-- intervals -->
      <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>
    </ng-container>

  </div>

  <!-- toolbar tabs -->
  <div *ngIf="isSmThanTablet" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <button *ngIf="showResetInterval" mat-button class="filter-button" aria-label="remove filters"
      (click)="resetDefaultInterval()">
      <mat-icon class="icon">refresh</mat-icon>
      <span style="padding-left: 2px;">{{'CONTINUOUS_EXPLORATION.RESET_INTERVAL' | translate}}</span>
    </button>

    <!-- aggregations -->
    <button mat-button class="filter-button" *ngFor="let aggr of aggregations" (click)="openAggrDialog(aggr)"
      [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
      <mat-icon *ngIf="aggr.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': aggr.label}">
        {{aggr.icon.icon }}</mat-icon>
      <mat-icon *ngIf="aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
        translate-attr="{'aria-label': aggr.label}"></mat-icon>
      <i *ngIf="aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor'" class="icon {{ aggr.icon.icon }}"
        translate-attr="{'aria-label': aggr.label}"></i>
      <span>{{ aggr.selectionLabel }}</span>
      <div fxLayout="row" fxLayoutAlign="center center"
        *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
        {{ aggregationsPayload[aggr.id].length }}
      </div>
    </button>

    <!-- change component -->
    <button mat-button class="filter-button"
      (click)="filterService.openComponentSelectionDialog(this, 'health-traceability')">
      <span>{{ 'HEALTH_MONITORING.CHANGE_COMPONENT' | translate }}</span>
    </button>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < 7" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div fxLayout="column" class="w100 h100">

      <div *ngIf="pageState.value >= 7" class="w100" fxLayout.lt-sm="column" fxLayout="row grid"
        fxLayoutAlign="start stretch"
        fxLayoutGap.gt-sm="{{ dashboardConfig.gap != null ? (dashboardConfig.gap) : '8px' }} grid">
        <ff-widget *ngFor="let w of dashboardConfig.widgets"
          [fxFlex.gt-sm]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
          [fxShow.lt-md]="!w.hideMobile" [fxFlex.sm]="'calc(' + w.mobileFlex + '% - 8px )'"
          [fxFlexOrder.sm]="w.mobileOrder"
          [widget]="{ data: dashboardData, type: w.type, subtype: w.subtype, class: w.class, machineProfile: machine.profile, config: w.config, title: w.title, customHeight: '100%', customMinHeight: '100%' }">
        </ff-widget>
      </div>

      <mat-card class="standard-mat-card" style="width: calc(100% - 48px) !important;"
        [ngClass]="{'hidePaginator': (pageState.value < 7 || table.filtered.length == 0 || isMobile) }">

        <!-- table -->
        <table mat-table [dataSource]="tableData" matSort matSortActive="timeStart" matSortDirection="desc"
          *ngIf="pageState.value >= 7 && table.filtered.length > 0" class="standard-table">

          <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let table" class="td-icon">
              <div
                class="box-icon {{table.timeEnd == null ? (machine.profile.flags != null && machine.profile.flags.inverttableClass ? 'md-red' : 'md-red-i') : 'md-gray-i-2'}}">
                <mat-icon class="icon" aria-label="table">cancel</mat-icon>
                <!-- <mat-icon class="icon" svgIcon="{{.icon}}" aria-label="table"></mat-icon> -->
                <!-- <i class="icon {{.icon}}" aria-label="table"></i> -->
              </div>
            </td>
          </ng-container>

          <ng-container *ngFor="let info of tableInfos" matColumnDef="{{info.variable}}">

            <th mat-header-cell *matHeaderCellDef [mat-sort-header]="info.orderBy != null ? info.orderBy : null"
              [disabled]="info.orderBy == null">
              <span *ngIf="info.prefix != null">[{{info.prefix}}]&nbsp;</span>
              <span>{{info.label|translate}}</span>
              <span *ngIf="info.suffix != null">&nbsp;[{{info.suffix}}]</span>
            </th>

            <td mat-cell *matCellDef="let table"
              [ngClass]="{ 'primary': info.primary, 'td-icon': info.type == 'icon' }">

              <div *ngIf="!info.type">
                <span>{{(table[info.variable] != null ? filterService.parseGaugeValue(table[info.variable],
                  (info.decimals != null ? info.decimals : 0), (info.multiplier != null ? info.multiplier : 1)) :
                  '-')}}</span>
                <span *ngIf="info.unit != null">&nbsp;{{info.unit}}</span>
              </div>

              <div *ngIf="info.type == 'icon'" class="box-icon {{info.class}}">
                <mat-icon *ngIf="info.icon.type == 'icon'" class="icon" aria-label="icon">{{info.icon.icon}}</mat-icon>
                <mat-icon *ngIf="info.icon.type == 'svg'" svgIcon="{{info.icon.icon}}" class="icon" aria-label="icon">
                </mat-icon>
                <i *ngIf="info.icon.type == 'fontawesome' || info.icon.type == 'phosphor'"
                  class="icon {{info.icon.icon}}" aria-label="icon"></i>
              </div>

              <div *ngIf="info.type == 'interval' && table.timeStartP">
                <span>{{(table.timeStartP != null ? table.timeStartP : '-')}}</span><br>
                <span>{{(table.timeEndP != null ? table.timeEndP : '-')}}</span>
              </div>

              <div *ngIf="info.type == 'interval' && table.timestampP">
                <span>{{table.timestampP}}</span>
              </div>

              <div *ngIf="info.type == 'progressBar' && info.fixedColor == null">
                <kpi-bar [kpiProps]="{ 'value': table[info.variable], 'hideLabel': true, 'revert': info.revert }">
                </kpi-bar>
              </div>

              <div *ngIf="info.type == 'progressBar' && info.fixedColor != null">
                <kpi-bar
                  [kpiProps]="{ 'value': table[info.variable], 'hideLabel': true, fixedColor: info.fixedColor, 'revert': info.revert }">
                </kpi-bar>
              </div>

              <div *ngIf="info.type == 'list'" class="td-list">
                <ul>
                  <li *ngFor="let li of table[info.variable]">
                    <span>{{li}}</span>
                    <span *ngIf="info.unit != null">&nbsp;{{info.unit}}</span>
                  </li>
                </ul>
              </div>

            </td>

          </ng-container>

          <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: tableColumns;" (click)="!row.clickable || openCycleSelection(row)"
            [ngClass]="{ 'clickable': row.clickable }"></tr>
        </table>

        <mat-paginator [ngClass]="{'hidePaginator': (pageState.value < 7 || table.filtered.length == 0 || isMobile) }"
          [length]="table.filtered.length" [pageSizeOptions]="table.pageOptions" [pageSize]="table.pageSize"
          showFirstLastButtons>
        </mat-paginator>

      </mat-card>

    </div>

  </div>

</div>