<div fxLayout="row" fxLayoutAlign="start center">
  <span class="widget-value widget-value-title">{{ widget.title | translate }}</span>
  <div *ngIf="widget.config.lastUpdatesDetail" class="ff-input-select just-icon smaller"
    [ngClass]="{'clickable': widget.config.lastUpdatesDetail }" (click)="openLastUpdatesDialog()" #tooltip="matTooltip"
    matTooltip="{{ 'HOMEPAGE.LAST_UPDATES_TOOLTIP' | translate }}" matTooltipPosition="above">
    <mat-icon svgIcon="touch"></mat-icon>
  </div>
</div>
<!-- [ngStyle]="{'background-color': widget.bgColor, 'color': widget.fontColor }" -->
<div class="status-label" [ngClass]="widget.class" fxLayout="row" fxLayoutAlign="center center">

  <div class="icon-box" style="margin-right: 8px;" *ngIf="widget.icon != null">
    <mat-icon *ngIf="widget.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': widget.label}">
      {{widget.icon.icon }}</mat-icon>
    <mat-icon *ngIf="widget.icon.type == 'svg'" svgIcon="{{ widget.icon.icon }}" class="icon"
      translate-attr="{'aria-label': widget.label}"></mat-icon>
    <i *ngIf="widget.icon.type == 'fontawesome' || widget.icon.type == 'phosphor'" class=" icon {{ widget.icon.icon }}"
      translate-attr="{'aria-label': widget.label}"></i>
  </div>

  {{ widget.label }}
</div>