import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { SnackbarComponent } from 'src/app/components/snackbar/snackbar.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-technical-support',
  templateUrl: './technical-support.component.html',
  styleUrls: ['./technical-support.component.scss']
})
export class TechnicalSupportComponent implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public asset: any;
  public customFields: any;
  public fieldItems: any;

  public gdprCheck: boolean = false;
  public message: string;

  public removePrivacyPolicy: any = false;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getMachineInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    // {
    //   state: 3,
    //   codes: [
    //     { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
    //     { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
    //   ]
    // },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getFieldsData, nextState: 5, loadingMsg: 'LOADING.TICKET_FIELDS' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private _snackbar: MatSnackBar
  ) {

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    if (this.appConfig?.technicalSupport?.removePrivacyPolicy != null) this.removePrivacyPolicy = this.appConfig.technicalSupport.removePrivacyPolicy;

    if (this.removePrivacyPolicy == true) this.gdprCheck = true;

    this.breadcrumb = ['TECHNICAL_SUPPORT.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    // this.tabs = this.internalDataService.getPageTabs('calendar');
    this.tabs = []

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    // this.pageState.subscribe(x => console.log(x))
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // GET MACHINE INFO

  getMachineInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, null);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {
    try {
      _this.apiService.sendGetRequest('apif/assets/info/' + _this.machineId).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {

            // console.log(data.body)

            _this.asset = data.body;
            _this.customFields = _this.getPreFilledData(data.body);

            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  getPreFilledData(fields, data: any) {
    fields.forEach(field => {
      let value = field.MSVariable != null ? this.filterService.stringToObjParam(data, field.MSVariable) : ''
      field.value = value
    })

    return fields
  }

  getFieldsData(_this: any) {
    try {
      let url = (_this.appInfo.sources40F ?? 'assets/config/') + 'ticket_fields_new.json';
      _this.apiService.sendGetRequest(url).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {
          error = {
            ...error, ...{
              error: {
                "customMessage": "Missing file: ticket_fields_new.json",
                "customCode": "404",
              }
            }
          }
          return _this.internalDataService.parseStandardHTTPError(_this, error);
        }))
        .subscribe(
          (data: any) => {

            // console.log(_this.machine)

            _this.fieldItems = _this.getPreFilledData(data.body, _this.machine);
            // _this.fieldItems = data.body

            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  touchedField(item: any) {
    let ix = this.fieldItems.findIndex((x: any) => x.id == item.id)
    this.fieldItems[ix].touched = true;
    if (item.value != '' && item.value != null && item.type == 'email') {
      this.fieldItems[ix].validEmail = this.validEmail(item.value)
    }
  }

  // isError(item: any) {
  //   let ix = this.fieldItems.findIndex((x: any) => x.id == item.id)
  //   if (this.fieldItems[ix].touched) {
  //     if (item.value == '' || item.value == null) return true
  //     if (item.type == 'email' && item.value) return !this.validEmail(item.value)
  //     if (item.type != 'email' && item.value != '' && item.value != null) return false
  //   } else {
  //     return false
  //   }
  // }

  validEmail(value: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
  }

  formValidityCheck() {
    if (this.gdprCheck) {
      return this.fieldItems.some((item: any) => item.value == null || item.value == '' || (item.value != null && item.value != '' && item.type == 'email' && item.validEmail == false))
    } else return true
  }

  openConfirmationDialog() {
    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      // width: '40%',
      height: 'auto',
      data: {
        title: this.translate.instant('TECHNICAL_SUPPORT.SEND_REQUEST'),
      },
    });

    confirmationDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          this.sendSubscriptionForm()
        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // SEND FORM

  sendSubscriptionForm() {

    try {
      let payload = this.buildPayload();
      let url = '/apif/techsupp/ticket';
      let snackbarData = {
        message: 'TECHNICAL_SUPPORT.RESPONSE.SUCCESS'
      }
      // this.openSnackbar('success-snackbar', snackbarData, 5000)
      // this.pageState.next(2)
      // this.gdprCheck = false;
      // this.message = '';
      // this.dispatcherService.getDispatch(this, 300)

      this.apiService.sendPostRequest(url, payload).pipe(
        retryWhen(this.apiService.genericRetryStrategy({
          maxRetryAttempts: 0
        })),
        catchError(error => {

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          let snackbarData = {
            message: 'TECHNICAL_SUPPORT.RESPONSE.FAIL'
          }
          this.openSnackbar('fail-snackbar', snackbarData, 5000)


          return throwError('Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            let snackbarData = {
              message: 'TECHNICAL_SUPPORT.RESPONSE.SUCCESS'
            }
            this.openSnackbar('success-snackbar', snackbarData, 5000)
            this.pageState.next(2)
            this.gdprCheck = false;
            this.message = '';
            this.dispatcherService.getDispatch(this, 300)
          },
        );
    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      let snackbarData = {
        message: 'TECHNICAL_SUPPORT.RESPONSE.FAIL'
      }
      this.openSnackbar('fail-snackbar', snackbarData, 5000)

      // this.dispatcherService.getDispatch(this, 301, testError);
    }
  }

  buildPayload() {
    let payload = {
      priority: "Normal",
      orgid: "00D5I000000lxSh",
      retURL: "http://",
      description: ''
    };
    this.fieldItems.forEach((field: any) => {
      payload[field.id] = field.value
      if (field.hasOwnProperty('options') && field.value == '$$$') payload[field.id] = ''
    });
    payload.description = this.message;

    return payload;
  }

  openSnackbar(theme: string, data: any, duration?: any) {
    this._snackbar.openFromComponent(SnackbarComponent, {
      panelClass: [theme],
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      data: data,
      duration: duration
    });
  }

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pageState.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
  }




}
