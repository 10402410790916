<!-- tabs WITH url link -->
<div *ngIf="tabs[0] && tabs[0]?.urlArray && tabs[0]?.urlArray.length > 0" fxLayout="row wrap"
  fxLayoutAlign="start center">

  <div *ngFor="let item of tabs">
    <button class="tab-button" *ngIf="!isMobile || (isMobile && !item.hideMobile)" [disabled]="item.disabled"
      fxLayout="row" fxLayoutAlign="space-evenly center" routerLinkActive="selected" [routerLink]="item?.urlArray"
      [queryParams]="item.urlQueryParams" [routerLinkActiveOptions]="{exact: false}" #tooltip="matTooltip"
      matTooltip="{{ item.title | translate }}" matTooltipPosition="above">
      <!-- icon -->
      <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.title}">
        {{ item.icon.icon }}
      </mat-icon>
      <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
        translate-attr="{'aria-label': item.title}"></mat-icon>
      <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'" class="icon {{ item.icon.icon }}"
        translate-attr="{'aria-label': item.title}"></i>
      <!-- label -->
      <span fxShow="false" fxShow.gt-sm>{{ (item.tabTitle ?? item.title) | translate }}</span>
    </button>
  </div>
</div>

<!-- tabs WITHOUT url link -->
<div *ngIf="!tabs[0]?.urlArray" fxLayout="row" fxLayoutAlign="start center">

  <div *ngFor="let item of tabs">
    <button class="tab-button" *ngIf="!isMobile || (isMobile && !item.hideMobile)" [disabled]="item.disabled"
      fxLayout="row" fxLayoutAlign="space-evenly center" [ngClass]="{ 'selected': item.selected }"
      (click)="selectTab(item)">
      <!-- icon -->
      <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.title}">
        {{ item.icon.icon }}
      </mat-icon>
      <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
        translate-attr="{'aria-label': item.title}"></mat-icon>
      <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'" class="icon {{ item.icon.icon }}"
        translate-attr="{'aria-label': item.title}"></i>
      <!-- label -->
      <span *ngIf="item.tabTitle != null || item.title != null" fxShow="false" fxShow.gt-sm>
        {{ (item.tabTitle ?? item.title) | translate }}
      </span>
    </button>
  </div>
</div>