import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

import { IntervalService } from 'src/app/services/interval.service';

import { MatDialog } from '@angular/material/dialog';
import {
  ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexFill, ApexGrid, ApexTitleSubtitle, ApexXAxis,
  ApexYAxis
} from "ng-apexcharts";
import { ClonerService } from 'src/app/services/clone.service';

export type ChartOptions = {
  annotations: ApexAnnotations,
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  dataLabels: ApexDataLabels;
  grid: ApexGrid,
  tooltip: any // to be simple I made it as any, can be replaced with the proper className
};

@Component({
  selector: 'app-cycle-dashboard',
  templateUrl: './cycle-dashboard.component.html',
  styleUrls: ['./cycle-dashboard.component.scss']
})
export class CycleDashboardComponent implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public backButton: any;
  public tabs: any;

  public cycleType: any = 0;
  public cycleDay: any;
  public cycleId: any;
  public cycleStart: any;
  public cycleEnd: any;
  public cycleSelectedSub: Subscription;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public pollingTime: any;
  public pollingMachines: any;

  public currentSynopticId: any;
  public synopticConfig: any;
  public synopticConfigDefault: any;
  public monitoringData: any;
  public monitoringDataUnparsed: any;
  public dashboardConfig: any;

  public cycleInfoConfig: any;
  public cycleData: any;
  public cycleConfig: any;

  public dialogData: any;
  public showDialog: boolean = false;
  public chartOptions: any;

  public interval: any;
  public intervalConfig: any;

  public alarmsInfo: any;
  public alarmsColumns: string[] = [];
  public alarms: any;
  public alarmsData: any;

  public signalationsInfo: any;
  public signalationsColumns: string[] = [];
  public signalations: any;
  public signalationsData: any;

  public eventsInfo: any;
  public eventsColumns: string[] = [];
  public events: any;
  public eventsData: any;

  public paramsRInfo: any;
  public paramsRColumns: string[] = [];
  public paramsR: any;
  public paramsRData: any;

  public phaseSelected: boolean = false;
  public phaseVariables: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 7;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getCycleTimelineWidgets, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getCycleDetail, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getCycleDashboardPolling, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    // TODO AGGIUNGERE AGGREGAZIONI E FILTRI
    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.monitoringData = null;

    this.breadcrumb = ['CYCLE_TRACEABILITY.TITLE', 'CYCLE_TIMELINE.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs('cycleDetails');

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        this.breadcrumb[2] = value.machineName;
        this.internalDataService.setBreadcrumb(this.breadcrumb);
      }
    });

    this.cycleSelectedSub = this.internalDataService.cycleSelected.subscribe(value => {
      if (value != null && !Array.isArray(value)) {
        this.breadcrumb[3] = (value.split("$$")[0]);
        this.internalDataService.setBreadcrumb(this.breadcrumb);
      }
    });

    this.pollingTime = 0;
    this.pollingMachines = Subscription;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'cycleDetails');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getCycleTimelineWidgets(_this: any) {

    let dashboardName = _this.cycleType == 1 ? 'cycle-dashboard_hpw' : 'cycle-dashboard';
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET CYCLE DETAIL

  getCycleDetail(_this: any, count?: any) {
    try {

      let url = '/apif/cycle-detail/cycle-timeline/' + _this.machineId;

      let query: any = {
        from: _this.cycleStart,
        to: _this.cycleEnd,
        cycleType: _this.cycleType,
        cycleId: _this.cycleId,
        tz: _this.machine.timezone
      };

      _this.apiService.sendGetRequest(url, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError(
            'Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            _this.cycleConfig = _this.clonerService.deepClone(data.body);
            _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      console.log(error);
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SYNOPTIC CONFIG

  // polling
  getCycleDashboardPolling(_this: any) {
    try {

      if (_this.pollingTime > 0) {
        _this.pollingMachines = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getCycleDashboard(_this, count);
        });
      } else {
        _this.getCycleDashboard(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getCycleDashboard(_this: any, count?: any) {
    try {

      // let payload = {filters: {}} //_this.internalDataService.buildMachinePayload(machine);

      let query: any = {
        from: _this.cycleStart,
        to: _this.cycleEnd,
        tz: _this.machine.timezone,
        idealFlow: _this.machine.idealFlow,
        cycleType: _this.cycleType,
        cycleId: _this.cycleId
      };

      _this.apiService.sendPostRequest('/apif/cycle-detail/cycle-dashboard/' + _this.machineId, {}, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError(
            'Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            if (count == 0) {
              _this.monitoringData = _this.clonerService.deepClone({ ...data.body, ..._this.cycleData });
              // _this.monitoringData = _this.clonerService.deepClone(data.body);

              _this.parseEnergyPlotData(_this.monitoringData, _this, "energy", "phasesPlotData");
              _this.parseGCAPlotData(_this.monitoringData, _this, "gca", "gcaPlotData");
              _this.monitoringData.cycleTimesPlotData = _this.parseCycleTimesPlotData(_this.monitoringData, _this);

              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      console.log(error);
    }

  }

  parseEnergyPlotData(plotData: any, _this: any, paramToMap: any, outputLabel: any) {

    plotData.valsPerPhase = plotData.valsPerPhase.sort(this.filterService.sortByProperty("phaseId", 'asc', true));

    let height = 300;
    try {
      height = (window.innerHeight - 425) / 2;
    } catch (error) {
      console.log(error);
    }

    plotData[outputLabel] = {
      series: [
        {
          name: this.translate.instant("CYCLE_DASHBOARD.ENERGY"),
          data: plotData.valsPerPhase.map((x: any) => x.energy)
        },
        {
          name: this.translate.instant("CYCLE_DASHBOARD.DURATION"),
          data: plotData.valsPerPhase.map((x: any) => x.duration)
        }
      ],
      chart: {
        height: height,
        type: "bar"
      },
      colors: plotData.valsPerPhase.map((x: any) => {
        let phaseConf = this.machine.profile.phases.find((pc: any) => pc.inputPhases.includes(x.phaseId));
        return phaseConf != null ? phaseConf.color : null;
      }),
      plotOptions: {
        bar: {
          columnWidth: '55%',
          distributed: true,
          borderRadius: 5,
        },
      },
      xaxis: {
        categories: plotData.valsPerPhase.map((x: any) => {
          let phaseConf = this.machine.profile.phases.find((pc: any) => pc.inputPhases.includes(x.phaseId));
          return phaseConf != null ? this.translate.instant(phaseConf.label) : x.phaseId;
        }),
        labels: {
          style: {
            colors: plotData.valsPerPhase.map((x: any) => {
              let phaseConf = this.machine.profile.phases.find((pc: any) => pc.inputPhases.includes(x.phaseId));
              return phaseConf != null ? phaseConf.color : null;
            }),
            fontSize: '12px'
          }
        }
      },
      yaxis: [
        {
          seriesName: this.translate.instant("CYCLE_DASHBOARD.ENERGY"),
          labels: {
            formatter: function (val: any) {
              return _this.filterService.parseGaugeValue(val, 3, 1);
            },
          },
          title: {
            text: this.translate.instant("CYCLE_DASHBOARD.ENERGY") + ' [kWh]',
          }
        },
        {
          opposite: true,
          seriesName: this.translate.instant("CYCLE_DASHBOARD.DURATION"),
          labels: {
            formatter: function (val: any) {
              return _this.filterService.parseTime(val, 's', 'HH:mm:ss');
            },
          },
          title: {
            text: this.translate.instant("CYCLE_DASHBOARD.DURATION")
          }
        }
      ],
      grid: {
        show: false
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val: any, config: any) => {
            return config.seriesIndex == 0 ? (_this.filterService.parseGaugeValue(val, 3, 1) + ' kWh') : (_this.filterService.parseTime(val, 's', 'HH:mm:ss'));
          },
          title: {
            formatter: (seriesName: any) => {
              return seriesName
            },
          },
        },
      },
    }
  }

  parseGCAPlotData(plotData: any, _this: any, paramToMap: any = "duration", outputLabel: any) {

    plotData.valsPerPhase = plotData.valsPerPhase.sort(this.filterService.sortByProperty("phaseId", 'asc', true));

    let height = 300;
    try {
      height = (window.innerHeight - 425) / 2;
    } catch (error) {
      console.log(error);
    }

    try {
      if (plotData.valsPerPhase.map((x: any) => x[paramToMap]).filter((x: any) => x != null).length == 0) {
        plotData[outputLabel] = {};
        return;
      }
    } catch (error) { console.log(error) }

    plotData[outputLabel] = {
      series: [
        {
          name: this.translate.instant("CYCLE_DASHBOARD.GCA"),
          data: plotData.valsPerPhase.map((x: any) => x[paramToMap])
        },
      ],
      chart: {
        height: height,
        type: "bar"
      },
      colors: plotData.valsPerPhase.map((x: any) => this.internalDataService.getBarColor(x[paramToMap], 100)),
      // colors: plotData.valsPerPhase.map((x: any) => {
      //   let phaseConf = this.machine.profile.phases.find((pc: any) => pc.inputPhases.includes(x.phaseId));
      //   return phaseConf != null ? phaseConf.color : null;
      // }),
      plotOptions: {
        bar: {
          // columnWidth: '55%',
          distributed: true,
          borderRadius: 5,
        }
      },
      xaxis: {
        categories: plotData.valsPerPhase.map((x: any) => {
          let phaseConf = this.machine.profile.phases.find((pc: any) => pc.inputPhases.includes(x.phaseId));
          return phaseConf != null ? this.translate.instant(phaseConf.label) : x.phaseId;
        }),
        labels: {
          style: {
            colors: plotData.valsPerPhase.map((x: any) => "#0a0a0a"),
            // colors: plotData.valsPerPhase.map((x: any) => this.internalDataService.getBarColor(x[paramToMap])),
            // colors: plotData.valsPerPhase.map((x: any) => {
            //   let phaseConf = this.machine.profile.phases.find((pc: any) => pc.inputPhases.includes(x.phaseId));
            //   return phaseConf != null ? phaseConf.color : null;
            // }),
            fontSize: '12px'
          }
        }
      },
      yaxis: [
        {
          seriesName: this.translate.instant("CYCLE_DASHBOARD.GCA"),
          labels: {
            formatter: function (val: any) {
              return val != null ? _this.filterService.parseGaugeValue(val, 0, 100) : null;
            },
          },
          title: {
            text: this.translate.instant("CYCLE_DASHBOARD.GCA") + ' [%]',
          }
        },
      ],
      grid: {
        show: false
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: (val: any, config: any) => {
            return _this.filterService.parseGaugeValue(val, 0, 100) + ' %';
          },
          title: {
            formatter: (seriesName: any) => {
              return seriesName
            },
          },
        },
      },
    }
  }

  parseCycleTimesPlotData(plotData: any, _this: any) {

    plotData.times = plotData.times.filter((x: any) => this.machine.profile.times.findIndex((pc: any) => pc.variable == x.id) > -1);

    let height = 300;
    try {
      height = (window.innerHeight - 425) / 2;
    } catch (error) {
      console.log(error);
    }

    return {
      series: plotData.times.map((x: any) => x.value),
      chart: {
        height: height,
        type: "donut"
      },
      labels: plotData.times.map((x: any) => {
        let timeConf = this.machine.profile.times.find((pc: any) => pc.variable == x.id);
        return timeConf != null ? this.translate.instant(timeConf.label) : null;
      }),
      colors: plotData.times.map((x: any) => {
        let timeConf = this.machine.profile.times.find((pc: any) => pc.variable == x.id);
        return timeConf != null ? timeConf.color : null;
      }),
      dataLabels: {
        enabled: true,
        formatter: function (val: any) {
          return _this.filterService.parseGaugeValue(val, 0, 1) + '%';
        },
        dropShadow: {
          enabled: false
        }
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '50%',
            labels: {
              show: false,
              total: {
                show: false,
                showAlways: false,
                label: _this.translate.instant("CYCLE_DASHBOARD.TOTAL_TIME"),
                fontSize: '16px',
                fontWeight: 600,
                color: '#373d3f',
                formatter: function (w: any) {
                  return _this.filterService.parseTime(w.globals.seriesTotals.reduce((a: any, b: any) => a + b, 0), 's', 'hh:mm:ss');
                }
              }
            }
          }
        }
      },
      tooltip: {
        enabled: true,
        custom: function (totalSeries: any) {

          let series = totalSeries.series;
          let seriesIndex = totalSeries.seriesIndex;

          let w = totalSeries.w;

          let startText = w.config.labels[seriesIndex] != null ? '<p> <strong>' + w.config.labels[seriesIndex] + ': </strong>' + _this.filterService.parseTime(series[seriesIndex], 's', 'hh:mm:ss') + '</p>' : '';

          return '<div class="arrow_box" style="background-color: ' + w.config.colors[seriesIndex] + ';">' +
            startText +
            '</div>'
        },
      },
      // responsive: [
      //   {
      //     breakpoint: 480,
      //     options: {
      //       chart: {
      //         width: 200
      //       },
      //       legend: {
      //         position: "bottom"
      //       }
      //     }
      //   }
      // ]
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    let cycleUrl = this.route.snapshot.params['cycleId'];

    this.backButton = [this.machineId, "cycle-traceability", "cycles"];
    this.internalDataService.setBackButton(this.backButton);

    try {
      let splittedCycleUrl = cycleUrl.split("$$");
      this.cycleId = splittedCycleUrl[0];
      this.cycleStart = splittedCycleUrl[1];
      this.cycleEnd = splittedCycleUrl[2];
      this.cycleType = splittedCycleUrl[3];
      this.cycleDay = moment(this.cycleEnd).format("YYYY-MM-DD");
    } catch (error) {
      console.log(error);
    }
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId'];
        let cycleUrl = params['cycleId'];

        try {
          let splittedCycleUrl = cycleUrl.split("$$");
          this.cycleId = splittedCycleUrl[0];
          this.cycleStart = splittedCycleUrl[1];
          this.cycleEnd = splittedCycleUrl[2];
          this.cycleType = splittedCycleUrl[3];
          this.cycleDay = moment(this.cycleEnd).format("YYYY-MM-DD");

          this.cycleData = {
            cycleId: this.cycleId,
            cycleDay: this.cycleDay,
            cycleType: this.cycleType,
            cycleStart: this.filterService.parseMoment(this.cycleStart, 'default'),
            cycleEnd: this.filterService.parseMoment(this.cycleEnd, 'default'),
            cycleDuration: moment(this.cycleEnd).diff(this.cycleStart, 's'),
          };

        } catch (error) {
          console.log(error);
        }

        this.internalDataService.setCycleSelected(cycleUrl);

      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }


  ngOnChanges() {
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // RESIZE
  timeOutFunctionId: any;

  @HostListener('window:resize', ['$event'])
  onResize() {

    clearTimeout(this.timeOutFunctionId);
    this.timeOutFunctionId = setTimeout(() => {

      this.pageState.next(4)

      this.parseEnergyPlotData(this.monitoringData, this, "energy", "phasesPlotData");
      this.parseGCAPlotData(this.monitoringData, this, "gca", "gcaPlotData");
      this.monitoringData.cycleTimesPlotData = this.parseCycleTimesPlotData(this.monitoringData, this);

      setTimeout(() => {
        this.pageState.next(7);
      }, 50);

    }, 50);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pollingMachines.unsubscribe();
    } catch (error) { }
    try {
      this.pageState.unsubscribe();
    } catch (error) { }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) { }
    try {
      this.cycleSelectedSub.unsubscribe();
    } catch (error) { }
    try {
      this.internalDataService.setBackButton([]);
    } catch (error) { }
  }

}