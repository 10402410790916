import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

declare let Plotly: any;

@Component({
  selector: 'ff-sct-datapoint-dialog',
  templateUrl: './sct-datapoint-dialog.component.html',
  styleUrls: ['./sct-datapoint-dialog.component.scss']
})
export class SctDatapointDialogComponent implements OnInit, OnDestroy {

  pollingDatapoint: any;
  pollingDatapointDialogTime: any = 30000;
  plotState: number = 0;
  public dialogIntervals: any = {
    selected: 'last4Hours',
    list: [
      "last24Hours",
      "last12Hours",
      "last8Hours",
      "last4Hours",
      // "last2Hours",
      // "lastHour",
      // "last30Minutes",
    ]
  };

  public appConfig: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public apiService: ApiService,
    public appConfigService: AppConfigService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
  ) {
    this.appConfig = this.appConfigService.getAppConfig;
    this.dialogIntervals.list = this.intervalService.getIntervalList(this.dialogIntervals.list);
  }

  getDatapointHistory() {
    // console.log('getDatapointHistory', this.dialog)
    try {
      let _this = this;

      let interval: any = this.intervalService.getIntervalById(this.dialogIntervals.selected);

      let url = '/apif/'

      // console.log(interval)

      let query = {
        from: interval.start,
        to: interval.end,
        tz: this.dialog.timezone,
      };

      let payload = {}
      // payload = this.dialog.datapointList.map((x: any) => typeof x == 'string' ? x : (Array.isArray(x) ? x : x.dp));

      if (this.dialog.completeDatapoint.action == "chartCrane") {
        url += this.dialog.machineId + '/sct-heatmap/crane-position-history'
        payload['assetConfig'] = {
          numCranes: parseInt(this.dialog.machine.numOfCranes),
          numCols: parseInt(this.dialog.machine.numOfColumns),
          numRows: parseInt(this.dialog.machine.numOfRows),
          xMax: parseInt(this.dialog.machine.xMax),
          yMax: parseInt(this.dialog.machine.yMax),
        }
      }

      if (this.dialog.completeDatapoint.action == "sctDatapoint") {
        url += this.dialog.machineId + '/sct-heatmap/tile-temp-history'
        payload['assetConfig'] = {
          numCranes: parseInt(this.dialog.machine.numOfCranes),
          numCols: parseInt(this.dialog.machine.numOfColumns),
          numRows: parseInt(this.dialog.machine.numOfRows),
          xMax: parseInt(this.dialog.machine.xMax),
          yMax: parseInt(this.dialog.machine.yMax),
          x: 0,
          y: 0,
        }
      }

      this.pollingDatapoint = timer(0, this.pollingDatapointDialogTime).subscribe((count => {
        this.apiService.sendPostRequest(url, payload, query).pipe(
          retryWhen(this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {
            this.plotState = 2;
            return _this.internalDataService.parseStandardHTTPError(_this, error, count == 0 ? _this.pollingDatapoint : null)
          }))
          .subscribe(
            (data: any) => {

              this.plotState = 1;

              let plotData: any = {};

              if (this.dialog.completeDatapoint.action == "chartCrane") {
                plotData = this.parseCranePlotData(_this, data.body);
              }

              if (this.dialog.completeDatapoint.action == "sctDatapoint") {
                plotData = this.parseDatapointPlotData(_this, data.body);
              }

              let plotConfig: any = {
                responsive: true,
                displaylogo: false
              };

              try {
                if (plotData.data.every((x: any) => x.x.length == 0)) {
                  this.plotState = 2;
                } else {
                  setTimeout(() => {
                    try {
                      Plotly.react("plot", plotData.data, plotData.layout, plotConfig);
                    } catch (error) {
                      console.log(error);
                    }
                  }, 50);
                }
              } catch (error) {
                console.log(error);
              }

            },
          );
      }));

    } catch (error) {
      console.log(error);
    }
  }

  parseCranePlotData(_this: any, plotData: any) {

    let traces = []

    plotData.forEach((position, posIdx) => {
      if (position.hasOwnProperty('history') && position.history != null && Array.isArray(position.history) && position.history.length > 0) {

        let x = [];
        let y = [];
        let hoverinfoText = [];
        let colors = [];

        let filtered = position.history.filter((pos) => {
          return (pos.hasOwnProperty('timestamp') && pos.timestamp != null);
        });
        filtered.sort((a, b) => {
          if (a.timestamp < b.timestamp) {
            return -1;
          }
          if (a.timestamp > b.timestamp) {
            return 1;
          }
          return 0;
        });

        filtered.forEach((pos) => {

          let hoverText = "";
          if (posIdx == 0) {
            colors.push('rgba(30, 117, 180, ' + (pos.opacity != null ? pos.opacity : 1) + ')');
          } else {
            colors.push('rgba(255, 152, 0, ' + (pos.opacity != null ? pos.opacity : 1) + ')');
          }
          if (pos.xRel != null) {
            hoverText += "<b>x: </b> " + parseInt(pos.xRel + 1) + '<br>';
          }
          if (pos.yRel != null) {
            hoverText += "<b>y: </b> " + parseInt(pos.yRel + 1) + '<br>';
          }
          if (pos.temp != null && pos.temp != 0) {
            hoverText += "<b>Temperature [°C]: </b> " + pos.temp.toFixed(2) + '<br>';
          }
          if (pos.timestamp != null) {
            hoverText += "<b>Last update: </b> " + _this.filterService.parseMoment(pos.timestamp, 'default') + '<br>';
          } else {
            hoverText += "<b>Last update: </b> Never passed! <br>";
          }

          hoverinfoText.push(hoverText);

          x.push(pos.xAct);
          y.push(pos.yAct);
        })

        traces.push({
          x: x,
          y: y,
          mode: 'lines+markers',
          type: 'scatter',
          name: (_this.translate.instant('cranes.' + position.id) != 'cranes.' + position.id) ? _this.translate.instant('cranes.' + position.id) : position.id,
          text: hoverinfoText,
          hoverInfo: "text",
          line: {
            color: posIdx == 0 ? 'rgba(30, 117, 180, 0.5)' : 'rgba(255, 152, 0, 0.5)'
            // color: 'rgba(30, 117, 180, 0.5)',
            // color: 'rgba(255, 152, 0, 0.5)',
          },
          marker: {
            color: colors,
            size: 6,
            width: 6,
          },
        });
      }
    });

    let plotLayout = {
      hovermode: 'closest',
      font: {
        size: 16,
      },
      yaxis: {
        zeroline: false,
        automargin: true,
        title: {
          text: "y [mm]",
          standoff: 20,
        },
        autorange: false,
        range: [0, parseInt(_this.dialog.machine.yMax)]
      },
      xaxis: {
        zeroline: false,
        title: "x [mm]",
        autorange: false,
        range: [0, parseInt(_this.dialog.machine.xMax)]
      },
      // showlegend: true,
      margin: {
        t: 30,
        r: 30,
        b: 100,
        l: 60,
        pad: 10
      },
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: -0.1
      },
    };

    return {
      layout: plotLayout,
      data: traces
    }

  }

  parseDatapointPlotData(_this: any, plotData: any) {

    let plotLayout: any = {
      uirevision: true,
      font: {
        size: 16,
      },
      xaxis: {
        domain: [0.02, 1]
      },
      showlegend: true,
      margin: {
        t: 30,
        r: 30,
        b: 30,
        l: 60,
        pad: 10
      },
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: -0.3
      },
    };

    let traces = []

    for (let v in plotData) {
      let yN = 0
      for (let val in plotData[v].variables) {
        yN += 1
        let trace: any = {
          id: val,
          x: plotData[v].timestamps,
          y: plotData[v].variables[val],
          type: 'scatter',
          name: _this.translate.instant("VARIABLES." + val)
        }
        // if (yN > 1) {
        //   trace.yaxis = 'y' + yN
        //   plotLayout['yaxis' + yN] = {
        //     anchor: 'free',
        //     overlaying: 'y',
        //     side: 'right',
        //     position: 0.85
        //   }
        // }
        traces.push(trace);
      }
    }

    return {
      layout: plotLayout,
      data: traces
    }

  }

  regetData(dialogInterval: any) {
    this.plotState = 0;
    this.dialogIntervals.selected = dialogInterval;
    try {
      this.pollingDatapoint.unsubscribe();
    } catch (error) {
      console.log(error);
    }
    this.getDatapointHistory();
  }

  ngOnInit(): void {
    this.getDatapointHistory();
  }

  ngOnDestroy() {
    try {
      this.pollingDatapoint.unsubscribe();
    } catch (error) {
      console.log(error);
    }
  }

}
