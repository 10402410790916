<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
    class="w100 h100">
    <!-- error -->
    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
    </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

    <!-- toolbar tabs -->
    <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
        fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

        <!-- tabs -->
        <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

        <span fxFlex></span>

        <ng-container *ngIf="!isSmThanTablet">

            <!-- change component -->
            <button mat-button class="filter-button"
                (click)="filterService.openComponentSelectionDialog(this, 'features-trend')">
                <span>{{ 'HEALTH_MONITORING.CHANGE_COMPONENT' | translate }}</span>
            </button>

            <!-- intervals -->
            <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>
        </ng-container>

    </div>

    <!-- toolbar tabs -->
    <div *ngIf="isSmThanTablet" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="start center">

        <!-- change component -->
        <button mat-button class="filter-button"
            (click)="filterService.openComponentSelectionDialog(this, 'features-trend')">
            <span>{{ 'HEALTH_MONITORING.CHANGE_COMPONENT' | translate }}</span>
        </button>

        <span fxFlex fxShow="false" fxShow.gt-sm></span>

        <!-- intervals -->
        <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

    </div>

    <!-- tab content -->
    <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content"
        [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

        <button mat-button (click)="openFeaturesSelectionDialog()" class="filter-button" fxLayout="row"
            fxLayoutAlign="center center" style="margin-bottom: 8px;">
            {{ 'CONTINUOUS_EXPLORATION.VARIABLES_SELECTION' | translate }}
            <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
                style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
            </mat-icon>
        </button>

        <!-- loading -->
        <ff-loading-card *ngIf="pageState.value < 7" [loadingData]="loadingData" style="margin: auto;">
        </ff-loading-card>

        <!-- dashboard -->
        <ff-dashboard *ngIf="dashboardData != null && pageState.value >= 7" class="w100 h100" style="margin-top: 8px;"
            [completeDashboardConfig]="completeDashboardConfig">
        </ff-dashboard>

    </div>

</div>