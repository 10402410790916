import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-maintenance-event-dialog',
  templateUrl: './maintenance-event-dialog.component.html',
  styleUrls: ['./maintenance-event-dialog.component.scss']
})
export class MaintenanceEventDialogComponent implements OnInit, OnDestroy {

  public unparsedActivities: any = [];
  public unparsedTemplates: any = [];

  eventInThePastOrPresent: any = false;
  public state: any = 0;
  public statesConfig: any = {
    activitiesSelectionWidget: 1,
    existingFiles: 1,
  };

  public nowTime: any;
  public srcResult: any;
  public fileInfos: any;
  public errors: any = [];

  public deleteMode: any = false;

  public permission: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<MaintenanceEventDialogComponent>,
    public http: ApiService,
    public intervalService: IntervalService,
    public additionalDialog: MatDialog,
    public clonerService: ClonerService,
    public translate: FfTranslateService,
    public apiService: ApiService,
    public filterService: FiltersService,
    public internalDataService: InternalDataService,
    public router: Router,
    public cacheService: CacheService,
  ) { }

  deleteOptions: any = [
    {
      id: 'deleteOccurrency',
      label: 'MAINTENANCE_CALENDAR.DELETE_SINGLE_EVENT',
      disabledWhenTrue: true,
      checked: true
    },
    {
      id: 'deleteOccurencesInFuture',
      label: 'MAINTENANCE_CALENDAR.DELETE_OCCURENCES_IN_FUTURE',
      disabledWhenTrue: true,
    },
    {
      id: 'deleteSeries',
      label: 'MAINTENANCE_CALENDAR.DELETE_ENTIRE_SERIES',
      disabledWhenTrue: true,
    },
  ];

  onCheckboxChange(checkbox) {
    let checkboxIdx = this.deleteOptions.findIndex(x => x.id == checkbox.id);
    this.deleteOptions.forEach((x, idx) => x.checked = idx == checkboxIdx);
  }

  enableDeleteMode() {
    this.deleteMode = true;
    this.dialogRef.updateSize('30%');
  }

  disableDeleteMode() {
    this.deleteMode = false;
    this.dialogRef.updateSize('90%');
  }

  ngOnInit() {

    this.permission = this.internalDataService.getSpecificPermission(this.dialog?.dashboardConfig?.templateDetailConfig?.editPermission ?? "mat-maintenance-edit");

    this.nowTime = new Date().toISOString().substring(0, 16);

    this.state = 0;

    try { this.eventInThePastOrPresent = moment().diff(moment(this.dialog.day)) >= 0 }
    catch (error) { console.log(error) }

    // // // // // // // // // // // // // // // // // // // // // // 
    // TEMPLATE LIST 
    // // // // // // // // // // // // // // // // // // // // // // 

    try {
      this.dialog.dashboardConfig.templateDetailConfig.widgets[0].config.profile = this.dialog.machine.profile;
    } catch (error) {
      console.log(error);
    }

    try { this.dialog.dashboardConfig.templateDetailConfig.widgets[0].data.templates = [this.dialog.currentTemplate] }
    catch (error) { this.dialog.dashboardConfig.templateDetailConfig.widgets[0].data = { templates: [this.dialog.currentTemplate] } }

    try { this.dialog.dashboardConfig.templateDetailConfig.widgets[0].config.additionalButtons[0].defaultDisabled = this.dialog.currentTemplate?.files == null || this.dialog.currentTemplate?.files.length == 0 }
    catch (error) { console.log(error) }

    this.unparsedTemplates = this.clonerService.deepClone(this.dialog.templates);

    // // // // // // // // // // // // // // // // // // // // // // 
    // ACTIVITIES 
    // // // // // // // // // // // // // // // // // // // // // // 

    this.statesConfig.activitiesSelectionWidget = 0;

    (async () => {
      let endpointUrl = this?.dialog?.dashboardConfig?.activitiesEndpointUrl?.replaceAll("{machineId}", this.dialog.machineId);

      let query: any = {
        tz: this.dialog?.machine?.timezone ?? "Europe/Rome",
        lang: this.translate?.currentLang ?? 'en',
      };

      let payload: any = {}

      try {

        let method = 'POST';
        if (endpointUrl.includes(':')) {
          endpointUrl = endpointUrl.split(':');
          method = endpointUrl[0];
          endpointUrl = endpointUrl[1];
        }

        let requestSent = null;
        if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
        else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
        else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

        const data: any = await requestSent.toPromise();

        //------------------------------------------------------------------------//
        // RESPONSE PARSING

        let copyData: any = this.clonerService.deepClone(data?.body ?? []);

        // this.dialog.activitiesSelectionWidget.data = copyData;
        this.unparsedActivities = copyData?.activities ?? [];

        let clonedActivities = [];
        try {
          this.unparsedActivities.forEach(group => {
            group.subgroups.forEach(subgroup => {
              subgroup.activities.forEach(activity => {
                if (this.dialog.currentTemplate?.selectedActivities.findIndex(act => activity.id == act) > -1) clonedActivities.push(activity);
              });
            });
          });

          this.dialog.dashboardConfig.templateDetailConfig.widgets[1].data = { selectedActivitiesTable: clonedActivities };
        } catch (error) {
          console.log(error);
        }

        this.statesConfig.activitiesSelectionWidget = 1;
        this.checkState();

      } catch (error) {
        this.statesConfig.activitiesSelectionWidget = 1;
        this.checkState();
      }

    })();

  }

  checkState() {
    try { this.state = Object.values(this.statesConfig).every(x => x == 1) ? 1 : 0 }
    catch (error) {
      console.log(error);
      return 0;
    }
  }

  addMaintenanceIntervention() {
    // console.log(this.dialog.currentTemplate, this.dialog);

    try {
      this.dialog.currentTemplate.timestamp = this.intervalService.formatStringDate(this.dialog.day);
      this.dialog.currentTemplate.interventionType = "ordinary";
      this.dialog.currentTemplate.activities = this.dialog.currentTemplate.selectedActivities;
      this.dialog.currentTemplate.id = null;
      this.cacheService.set("addMaintenanceIntervention", this.dialog?.currentTemplate);
    } catch (error) {
      console.log(error);
    }

    let url: any = [this.dialog.machineId, 'standard-dashboard-table-page?sectionName=maintenance&tabName=maintenanceHistory'].map((x: any) => "/" + x).join("");
    this.router.navigateByUrl(url);
    this.dialogRef.close();
  }

  async onButtonClick(item) {
    try {

      console.log(item);

      if (item?.buttonInfos?.clickFunction == 'downloadAttachments') {

        let payload = {
          fileName: item?.row?.day
        };

        let resp = await this.apiService.asyncDownload((item?.buttonInfos?.endpointUrl ?? '/apif/cmms/download-files/calendarTemplate/') + this.dialog.machineId + '/' + item?.row?.id, payload);
        this.apiService.handleDownload(resp);
        return;
      }

    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy() { }

  confirmCloseDialogDelete() {

    const confirmationDialog = this.additionalDialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        cancelClass: 'md-red',
        title: this.translate.instant('GLOBAL.CONFIRM_DELETE') + '?',
      },
    });
    confirmationDialog.afterClosed().subscribe(async (result: any) => {
      if (result != null && result != '') this.dialogRef.close(this.deleteOptions.find(x => x.checked));
    });
  }

}
