import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'homepage-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})

export class HomepageMapComponent implements OnInit, OnChanges, OnDestroy {

  public appConfig: any;

  public apiLoaded: Observable<boolean> | undefined;

  cardStyle: any;
  cardConfig: any = { config: {} };
  machineProfiles: any = [];

  machineInfos: any = [];
  machineDatas: any = [];
  otherData: any = [];
  worstComponentsInfos: any = [];
  anomalyInfos: any = [];
  oeeInfos: any = [];
  productivity: any = {};

  public homepageDataSub: any;
  public mFilters: any;

  public machinesListSub: Subscription;
  public machines: any;

  public mapOptions = {
    center: { lat: 45.879078, lng: 9 },
    zoom: 6
  };
  public mapSizes: any;
  public mobileListener: Subscription;
  public errorDataMobile: any;
  public isMobile: any;

  constructor(
    private internalDataService: InternalDataService,
    public appConfigService: AppConfigService,
    public mobile: MobileService,
    public translate: FfTranslateService
  ) {

    this.appConfig = this.appConfigService.getAppConfig;
    this.cardStyle = this.appConfig.homepage.gridCards.cardStyle != null ? this.appConfig.homepage.gridCards.cardStyle : 0;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.machineInfos = this.appConfig.homepage.machineInfos;
    this.machineDatas = this.appConfig.homepage.machineDatas;
    this.worstComponentsInfos = this.appConfig.homepage.worstComponentsInfos;
    this.anomalyInfos = this.appConfig.homepage.anomalyInfos;
    this.oeeInfos = this.appConfig.homepage.oeeInfos;

    this.homepageDataSub = this.internalDataService.homepageData.subscribe(value => {
      this.mFilters = value.filters;
    });

    this.machinesListSub = this.internalDataService.machinesList.subscribe(value => {
      this.machines = value;
    });

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })

  }

  trackById = (index, machine: any): string => {
    return machine.machineId;
  };

  clickMarker(machine: any) {
    // console.log(machine);
  }

  selectMachine(machine: any) {
    this.internalDataService.setMachineSelected(machine);
  }

  ngOnInit(): void {

    try {
      this.machines.forEach(mach => {
        if (mach.location == null) mach.location = {};
        if (mach.location.latitude != null) mach.location.latitude = parseFloat(mach.location.latitude);
        if (mach.location.longitude != null) mach.location.longitude = parseFloat(mach.location.longitude);
      })
    } catch (error) { console.log(error) }

    let mapOptions: any = {
      center: { lat: 45.879078, lng: 9 },
      zoom: 6
    };

    if (this.machines?.length >= 1) {
      let machDefault = this.machines.filter(x => x.location?.latitude != null && x.location?.longitude != null)?.[0]?.location;
      mapOptions = {
        center: {
          lat: machDefault?.latitude ?? 45.879078,
          lng: machDefault?.longitude ?? 9,
        },
        zoom: 6
      };
    }

    this.mapOptions = mapOptions;

    this.mapSizes = {
      width: '100%',
      height: 'auto'
    };

  }

  ngOnChanges() { }

  ngOnDestroy() {
    try {
      this.homepageDataSub.unsubscribe();
    } catch (error) { }
    try {
      this.machinesListSub.unsubscribe();
    } catch (error) { }
    try {
      this.mobileListener.unsubscribe()
    } catch (error) {

    }
  }


}