<agm-marker [latitude]="machineData.location.latitude" [longitude]="machineData.location.longitude">

  <agm-info-window>

    <div class="ff-agm-info-window-content" fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start start">

      <!-- image -->
      <div class="image-container">
        <img [src]="machineData.image" (error)="internalDataService.handleMissingImage($event, machineData)">
      </div>

      <div class="info-container" fxLayout="column">
        <p class="title">{{ machineData.machineName }}</p>

        <div *ngIf="enableCalendarSwitch" fxFlex fxLayout="row" fxLayoutAlign="start center"
          class="voice switch-container">

          <ui-switch [checked]="isEnabledMachineCalendar" (change)="toggleEnableCalendarAsset($event)" checkedLabel="ON"
            uncheckedLabel="OFF" size="small">
          </ui-switch>
          <span style="margin: 0 8px;">
            {{ (isEnabledMachineCalendar? "CALENDAR.DISABLE_CALENDAR_PER_ASSET" : "CALENDAR.ENABLE_CALENDAR_PER_ASSET")
            | translate }}
          </span>
        </div>

        <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="loading h100 w100">
          <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
            <ff-loading-card></ff-loading-card>
          </div>
        </div>

        <div class="content" [ngStyle]="{'display': machineData.loading ? 'none' : ''}">
          <!-- machineInfos -->
          <div *ngFor="let item of machineInfos" class="items-box">
            <div *ngIf="item.map" fxLayout="row" fxLayoutAlign="start center">
              <span class="label" fxFlex="45">{{item.label|translate}}</span>
              <div class="value" fxFlex>
                <span>{{(machineData[item.variable] != null ? machineData[item.variable] : '-')}}</span>
                <span *ngIf="item.suffix">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
              </div>
            </div>
          </div>

          <!-- machineDatas -->
          <div *ngIf="machineDatas" class="items-box">
            <ng-container *ngFor="let item of machineDatas">
              <div *ngIf="item.map" fxLayout="row" fxLayoutAlign="start center">
                <span class="label" fxFlex="45">{{item.label|translate}}</span>
                <div class="value" fxFlex>
                  <span>{{(machineData[item.variable] != null ? machineData[item.variable] : '-')}}</span>
                  <span *ngIf="item.suffix">&nbsp;{{filterService.convertUnit(item.suffix).unit | translate}}</span>
                </div>
              </div>
            </ng-container>
          </div>

          <!-- productionData -->
          <div *ngIf="productionData" class="items-box">
            <ng-container *ngFor="let item of productionData">
              <div *ngIf="item.map" fxLayout="row" fxLayoutAlign="start center">
                <span class="label" fxFlex="45">{{item.label|translate}}</span>
                <div class="value" fxFlex>
                  <span>{{(machineData[item.variable] != null ? machineData[item.variable] : '-')}}</span>
                  <!-- <span *ngIf="item.suffix">&nbsp;{{filterService.convertUnit(item.suffix).unit | translate}}</span> -->
                </div>
              </div>
            </ng-container>
          </div>

          <!-- productionOrder -->
          <div *ngIf="productionOrder" class="items-box">
            <ng-container *ngFor="let item of productionOrder">
              <div *ngIf="item.map" fxLayout="row" fxLayoutAlign="start center">
                <span class="label" fxFlex="45">{{item.label|translate}}</span>
                <div class="value" fxFlex>
                  <span>{{(machineData[item.variable] != null ? machineData[item.variable] : '-')}}</span>
                  <span *ngIf="item.suffix">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
                </div>
              </div>
            </ng-container>
          </div>

          <!-- materials -->
          <div *ngIf="materials" class="items-box">
            <ng-container *ngFor="let item of materials">
              <div *ngIf="item.map" fxLayout="row" fxLayoutAlign="start center">
                <span class="label" fxFlex="45">{{item.label|translate}}</span>
                <div class="value" fxFlex>
                  <span>{{(machineData[item.variable] != null ? machineData[item.variable] : '-')}}</span>
                  <span *ngIf="item.suffix">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
                </div>
              </div>
            </ng-container>
          </div>

          <!-- productivity -->
          <div *ngIf="productivity">
            <!-- gauges -->
            <ng-container *ngFor="let item of productivity.gauges">
              <div *ngIf="item.map && machineData[item.variable]" fxLayout="row" fxLayoutAlign="start center">
                <span class="label" fxFlex="20">{{item.label|translate}}</span>
                <kpi-bar [kpiProps]="{ 'value': machineData[item.variable], 'hideLabel': true }"></kpi-bar>
              </div>
              <div *ngIf="item.map && (!machineData[item.variable] || machineData[item.variable] == null)"
                fxLayout="row" fxLayoutAlign="start center">
                <span class="label" fxFlex="20">{{item.label|translate}}</span>
                <kpi-bar [kpiProps]="{ 'value': null, 'hideLabel': true }"></kpi-bar>
              </div>
            </ng-container>
            <!-- kpibars -->
            <ng-container *ngFor="let item of productivity.kpibars">
              <div *ngIf="item.map" fxLayout="row" fxLayoutAlign="start center">
                <span class="label" fxFlex="20">{{item.label|translate}}</span>
                <kpi-bar [kpiProps]="{ 'value': machineData[item.variable], 'hideLabel': true }"></kpi-bar>
              </div>
            </ng-container>
          </div>

        </div>

      </div>

    </div>


    <div class="select-button" fxLayout="row" fxLayoutAlign="center center" (click)="selectMachine()">
      <button mat-button class="filter-button mat-button mat-button-base">{{'GLOBAL.SELECT'|translate}}</button>
    </div>

  </agm-info-window>

</agm-marker>
