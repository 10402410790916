import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { HelpDialogComponent } from '../help-dialog/help-dialog.component';


import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'ff-main-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class MainToolbarComponent implements OnInit {

  public breadcrumbSub: Subscription;
  public breadcrumb: any;

  public backButtonSub: Subscription;
  public backButton: any;

  public enableCalendarSwitch: Boolean;
  public langSub: Subscription;
  public lang: any;

  public isMindSphere: any = false;
  public isAzure: any = false;
  public isDemo: any = false;
  public mobileListener: Subscription;
  public isMobile: any;
  public isSmThanTablet: any;

  public machineSelected: any;
  public machineSelectedSub: Subscription;

  public calendarPageSub: Subscription;
  public calendarSub: Subscription;

  public isEnabledCalendarAsset: any = true;
  public isCalendarPage: any = false;

  public currentLanguage: any;
  public langs: any = [];

  public showToolbar: boolean = false;
  public hide: boolean = false;

  public unitSystems: any = [
    {
      current: true,
      label: 'International System',
      id: "SI"
    }
  ];
  public unitSystem: any = this.unitSystems[0];

  public appInfo: any;

  public showUnitToolbar: boolean = false;
  public navigateInBrowserHistory: boolean = false;
  hideToolbarSub: Subscription;

  constructor(
    private internalDataService: InternalDataService,
    private router: Router,
    public dialog: MatDialog,
    public translate: FfTranslateService,
    public apiService: ApiService,
    public appConfigService: AppConfigService,
    public cacheService: CacheService,
    public mobile: MobileService,
    public filterService: FiltersService,
  ) {

    this.mobileListener = this.mobile.mobileListener.subscribe((value) => {
      this.isMobile = value.isMobile
      this.isSmThanTablet = value.isSmThanTablet
    })

    let appConfig = appConfigService.getAppConfig;
    this.appInfo = appConfigService.getAppInfo;
    let sidenavItems = appConfigService.getSidenavItems;

    try {
      // this.enableCalendarSwitch = sidenavItems.find(sItem => (sItem.id == 'planning-calendar' && !sItem.disabled)) != null
      this.enableCalendarSwitch = sidenavItems.find(sItem => (sItem.id == 'planning-calendar')) != null;
    } catch (error) {
      console.log(error);
    }


    try {
      this.isMindSphere = appConfigService.getAppInfo.mindsphere;
    } catch (error) {
      console.log(error);
    }

    try {
      this.isAzure = appConfigService.getAppInfo.azure;
    } catch (error) {
      console.log(error);
    }

    try {
      this.langs = appConfig.langs;
    } catch (error) {
      console.log(error);
    }

    try {
      this.isDemo = appConfigService.getAppInfo.demo;
    } catch (error) {
      console.log(error);
    }

    try {
      this.showToolbar = appConfig.languageSelectionInToolbar;
    } catch (error) {
      console.log(error);
    }

    try {
      this.showUnitToolbar = appConfig.unitSystemSelectionInToolbar && appConfigService.getConvConfig != null;
    } catch (error) {
      console.log(error);
    }

    if (this.showUnitToolbar) {
      try { this.unitSystems = this.unitSystems.concat(appConfigService.getConvConfig.unitSystems) }
      catch (error) { }
    }

    this.hideToolbarSub = this.internalDataService.hideToolbar.subscribe(value => this.hide = value);

    if (this.unitSystems?.length > 0) {
      let currentSystemId = localStorage.getItem('USID');
      let ix = this.unitSystems.findIndex((x: any) => x.id == currentSystemId);
      if (ix != -1) this.unitSystem = this.unitSystems[ix];
    }

    if (this.langs != null && this.langs.length > 0) {
      let currentLanguageId = localStorage.getItem('language');
      let ix = this.langs.findIndex((x: any) => x.id == currentLanguageId);
      if (ix != -1) this.currentLanguage = this.langs[ix];
    }
    // init breadcrumb subscription
    this.breadcrumbSub = this.internalDataService.breadcrumb.subscribe(value => {
      this.breadcrumb = value ?? [];
    });

    // init backButton subscription
    this.backButtonSub = this.internalDataService.backButton.subscribe(value => {
      this.navigateInBrowserHistory = false;
      if (value?.navigateInBrowserHistory != null) {
        this.navigateInBrowserHistory = value?.navigateInBrowserHistory;
        return;
      }

      this.backButton = value ?? [];
    });

    // init lang subscription
    this.langSub = this.internalDataService.lang.subscribe(value => {
      if (value != null) {
        this.useLanguage(value, false);
        if (this.langs != null && this.langs.length > 0) {
          let currentLanguageId = localStorage.getItem('language');
          let ix = this.langs.findIndex((x: any) => x.id == currentLanguageId);
          if (ix != -1) this.currentLanguage = this.langs[ix];
        }
      }
    });

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {

      this.machineSelected = value.machineId;
      this.isEnabledCalendarAsset = this.filterService.isActiveCalendar(this.machineSelected);
    });

    this.calendarPageSub = this.internalDataService.calendarPage.subscribe(value => {
      this.isCalendarPage = value;
    });

    this.calendarSub = this.internalDataService.calendar.subscribe(value => {
      if (value != null) this.isEnabledCalendarAsset = value;
    });

  }

  ngOnInit(): void { }

  public openSidenav() {
    let sidenavs = document.getElementsByTagName('ff-sidenav');
    if (sidenavs.length > 0) {
      sidenavs[0].classList.remove('closed');
    }
  }

  public toggleEnableCalendarAsset(value) {

    // console.log(this)

    try { localStorage.setItem(this.machineSelected + '_calendar', value) }
    catch (error) { console.log(error) }

    this.isEnabledCalendarAsset = value;
    if (value == false) {
      try { if (this.cacheService.get("intervalAggregation")?.id == 'shift') this.cacheService.set("intervalAggregation", null) }
      catch (error) { console.log(error); }
    }

    // window.location.href = window.location.href;
    this.router.navigateByUrl(this.router.url);
  }

  public useLanguage(langId: any, refresh: boolean = true) {
    try {
      localStorage.setItem('language', langId);
      this.translate.use(langId);
      try {
        moment.locale(langId);
      } catch (error) {
        console.log(error);
      }
    } catch (error) {
      console.log(error);
    }
    if (refresh) this.router.navigateByUrl(this.router.url); //window.location.reload();
  }

  public useSystem(unitSystem: any, refresh: boolean = true) {
    try { localStorage.setItem('USID', unitSystem) } catch (error) { }
    if (refresh) window.location.reload();
  }

  public openHelpDialog(event: any) {

    console.log(event);

    const helpDialog = this.dialog.open(HelpDialogComponent,
      {
        panelClass: 'ff-dialog',
        data: {
          title: this.translate.instant("REMOTE_MONITORING.SYNOPTIC_SELECTION"),
        },
      });

    helpDialog.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        console.log(result);
      }
    });
  }

  public navigateBack() {
    this.cacheService.set("backButtonClicked", true);

    if (this.navigateInBrowserHistory) {
      window.history.back();
      return;
    }

    this.router.navigateByUrl(this.backButton);
  }

  public logout() {

    console.log('logout');

    if (this.appInfo.internalAuth) {
      //remove the cookie
      document.cookie = "basicAuthHeader= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      //remove the local storage key
      localStorage.removeItem('basicAuthHeader');
      this.router.navigateByUrl('/login-page');

    } else {
      window.location.href = '/logout';
    }

  }

  public fusionauthAdminRedirect() {
    // Open fusion auth admin dashboard in new page
    window.open(window.location.protocol + '//' + window.location.hostname + ":" + window.location.port + "/fusionauth-admin", '_blank');
  }

  public checkUserToolbarPermission() {

    if (!this.appInfo.showToolbarButtonsWithPermission) return true;
    let c_user = this.cacheService.get('user');
    if (!c_user?.oauth) return true;
    if (c_user?.roles?.includes(this.appInfo.showToolbarButtonsWithPermission)) return true;

    return false;
  }

  ngOnDestroy(): void {
    try { this.breadcrumbSub.unsubscribe() } catch (error) { }
    try { this.backButton.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.calendarPageSub.unsubscribe() } catch (error) { }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    let innerWidth = window.innerWidth;
    this.isMobile = innerWidth <= 959;
  }

}
