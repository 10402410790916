import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { AssignTemplateDialogComponent } from 'src/app/components/widgets/custom-calendar/assign-template-dialog/assign-template-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';
import { MaintenanceEventDialogComponent } from './maintenance-event-dialog/maintenance-event-dialog.component';

@Component({
  selector: 'ff-custom-calendar',
  templateUrl: './custom-calendar.component.html',
  styleUrls: ['./custom-calendar.component.scss']
})
export class CustomCalendarComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() widget: any;

  tableMode: any = false;

  public isAllowedUser: any = true;
  public isAllowedUserWrite: any = true;

  public loadingData: any;
  public errorData: any;
  public errorDataMobile: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machine: Subscription;

  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;

  public dashboardConfig: any;

  public calendarData: any;
  public calendarStates: any = [];
  public maintenanceEvents: any;
  public maintenanceEventsUnparsed: any;

  public eventTableConfig: any;

  public calendarOptions: any = {};
  public templates: any = [];

  public mobileListener: Subscription;
  public isMobile: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 8;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 2, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getShiftTemplates, nextState: 6, loadingMsg: 'LOADING.SHIFT_TEMPLATES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.getData, nextState: 7, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    public cacheService: CacheService,
    public snackBar: MatSnackBar,
    public mobile: MobileService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile;
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    });

    this.calendarData = null;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUserWrite = _this.internalDataService.getSpecificPermission("mat-pc-calendar-rw");
    } catch (error) { console.log(error) }

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(["mat-pc-calendar-r", "mat-pc-calendar-rw"], 'or');
    } catch (error) { console.log(error) }

    _this.dispatcherService.getDispatch(_this, 300);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    _this.internalDataService.getDashboard(_this, _this.machineId, 'maintenance-calendar-actions');
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SHIFT TEMPLATES

  public getShiftTemplates(_this: any) {

    try {

      let url = '/apif/cmms/maintenance-templates/' + _this.machineId;

      _this.apiService.sendPostRequest(url, {}).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {

            _this.templates = _this.clonerService.deepClone(data.body?.templates);
            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // EVENTS

  // GET DATA
  getData(_this: any) {
    try {

      let url = '/apif/cmms/maintenance-calendar/' + _this.machineId;

      _this.apiService.sendPostRequest(url, {})
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data);

            if (_this.maintenanceEvents == null) {
              _this.maintenanceEvents = _this.clonerService.deepClone(data.body);
              _this.maintenanceEventsUnparsed = _this.clonerService.deepClone(data.body);
            }

            try { _this.parseCalendarDays(_this) }
            catch (error) { console.log(error) }


            try {
              _this.eventTableConfig = _this.dashboardConfig?.customActionButtons?.find(x => x?.clickFunction == 'eventsTable');
              _this.eventTableConfig.widget.config.profile = _this.machine.profile;
              _this.eventTableConfig.widget.data = { calendarEvents: _this.totalDays };
            } catch (error) {
              console.log(error);
            }

            _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseCalendarDays(_this: any) {

    _this.totalDays = _this.parseTotalDays(_this);

    // console.log(_this.totalDays);

    _this.calendarOptions = {

      customButtons: {
        myCustomButton: {
          text: _this.translate.instant("GLOBAL.TABLE_MODE"),
          click: () => _this.tableMode = true
        }
      },

      events: _this.totalDays,
      initialView: 'dayGridMonth',
      allDaySlot: false,
      height: "100%",
      nextDayThreshold: '14:00:00',
      dayMaxEvents: 3,
      headerToolbar: {
        start: 'title',
        end: 'today prev,next myCustomButton',
      },
      validRange: {
        start: moment().subtract(12, 'months').format("YYYY-MM-DD"), //start date here
        end: moment().add(12, 'months').format("YYYY-MM-DD") //end date here
      },
      // },
      eventClick: function (event) {
        _this.openMaintenanceEventDialog(event);
      },
      dateClick: function (event) {

        console.log(event);

        _this.openShiftTemplate(event.dateStr);

      },
      unselectAuto: false,
      displayEventEnd: false,
      displayEventTime: false,
      firstDay: 1,
      buttonText: {
        today: this.translate.instant('CALENDAR.TODAY'),
        week: this.translate.instant('CALENDAR.WEEK'),
        month: this.translate.instant('CALENDAR.MONTH'),
      },
      // datesSet: function () {
      //   _this.setDayShiftColor()
      // }
    };

  }

  parseTotalDays(_this: any) {

    _this.selectedDays = [];
    let list: any = [];

    if (_this.maintenanceEvents?.length > 0) {

      _this.maintenanceEvents.forEach((timeRange: any) => {

        let templateConfig = _this.templates?.find(template => template.id == timeRange.templateId);

        // Add shift
        list.push({
          // display: 'block',
          className: [templateConfig?.templateClass],
          templateClass: templateConfig?.templateClass,
          stick: false,
          editable: true,
          seriesId: timeRange.seriesId,
          templateId: timeRange.templateId,
          day: timeRange.workDay,
          title: templateConfig?.templateName,
          frequency: templateConfig?.frequency,
          activitiesNumber: templateConfig?.activitiesNumber,
          description: templateConfig?.description,
          start: timeRange.workDay,
          end: timeRange.workDay,
        });

      });
    }

    _this.totalDaysUnfiltered = list;

    return _this.clonerService.deepClone(list);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // OPEN SHIFT TEMPLATES

  openShiftTemplate(selectedDay: any) {

    const assignShiftSelectionDialog = this.dialog.open(AssignTemplateDialogComponent,
      {
        panelClass: 'ff-dialog',
        // width: '40%',
        height: 'auto',
        data: {
          title: this.translate.instant("MAINTENANCE_CALENDAR.ADD_RECURRENT_EVENT"),
          machine: this.machine,
          appConfig: this.appConfig,
          templates: this.templates,
          selectedDay: selectedDay
        },
      });

    assignShiftSelectionDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          // console.log(result);

          let currentTemplate = result?.currentTemplate;
          let frequency = result?.frequency ?? 'day';
          let recurrencyPattern = result?.recurrencyPattern ?? 'everyDay';
          let startingDay = result?.startingDay;
          let numberOfOccurrences = result?.numberOfOccurrences ?? 1;
          let recurrence = result?.recurrence ?? 1;

          let selectedDays: any = [];

          let i = 0;

          switch (frequency) {
            case 'year':

              // for (let m = moment(startingDay); m.diff(startingDay, 'years') < numberOfOccurrences; m.add(365, 'days')) {
              for (let m = moment(startingDay); i < numberOfOccurrences; m.add(365, 'days')) {

                let day = m.format("YYYY-MM-DD");
                selectedDays.push(day);
                i++;

              }
              break;
            case 'month':
              switch (recurrencyPattern) {
                case 'everyMonth':
                default:

                  let monthWeek = result?.monthWeek ?? 1;

                  for (let m = moment(startingDay); i < numberOfOccurrences; m.add(28 * recurrence, 'days')) {

                    let day = m.format("YYYY-MM-DD");

                    // console.log(day, m.diff(startingDay, 'months'), numberOfOccurrences);

                    let predictedMonth = null;

                    if (selectedDays?.length > 0) {
                      predictedMonth = moment(selectedDays.at(-1)).month() + 1;

                      if (recurrence > 1) predictedMonth = moment(selectedDays.at(-1)).add(recurrence, "months").month();

                    }

                    if (predictedMonth == null || (predictedMonth == m.month() && this.filterService.checkWeek(m, monthWeek))) {
                      selectedDays.push(day);
                    }

                    else {
                      let i = 0;
                      let dayDate = m.clone();
                      while ((predictedMonth != m.month() || !this.filterService.checkWeek(m, monthWeek)) && i < 10) {
                        dayDate = m.add(7, 'days');
                        i++;
                      }
                      day = dayDate.format("YYYY-MM-DD");
                      selectedDays.push(day);
                    }

                    i++;

                  }
                  break;
              }
              break;
            case 'week':
              switch (recurrencyPattern) {
                case 'everyWeek':
                default:

                  for (let m = moment(startingDay); i < numberOfOccurrences; m.add(7 * recurrence, 'days')) {

                    let day = m.format("YYYY-MM-DD");
                    selectedDays.push(day);
                    i++;
                  }
                  break;
              }
              break;
            case 'day':
            default:
              switch (recurrencyPattern) {
                case 'everyDay':
                default:

                  for (let m = moment(startingDay); i < numberOfOccurrences; m.add(recurrence, 'days')) {

                    let day = m.format("YYYY-MM-DD");
                    selectedDays.push(day);
                    i++;
                  }
                  break;

                case 'everyWeekDay':

                  for (let m = moment(startingDay); i < numberOfOccurrences; m.add(1, 'days')) {

                    if (m.weekday() != 5 && m.weekday() != 6) {
                      let day = m.format("YYYY-MM-DD");
                      selectedDays.push(day);
                      i++;
                    }

                  }
                  break;
              }
              break;

          }

          let copyMaintenanceEvents: any = this.clonerService.deepClone(this.maintenanceEvents);

          let seriesId = (new Date()).getTime() + Math.random().toString(16).slice(2);

          selectedDays.forEach((day: any) => {

            if (copyMaintenanceEvents.findIndex(x => x.workDay == day && x.templateId == currentTemplate?.id) == -1) {
              copyMaintenanceEvents.push({
                workDay: day,
                templateId: currentTemplate?.id,
                seriesId: seriesId,
              });
            } else {
              console.log("Template already existing for the selected day: " + day + ' and template: ' + currentTemplate?.templateName);
            }

          });

          this.maintenanceEvents = this.clonerService.deepClone(copyMaintenanceEvents);
          this.updateCalendar();

          console.log(copyMaintenanceEvents);

        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // OPEN SHIFT TEMPLATES

  tableAction(item) {
    console.log(item);

    if (item?.buttonInfos?.clickFunction == 'eventDetail') {
      let event = {
        event: {
          extendedProps: item.row
        }
      };
      this.openMaintenanceEventDialog(event);

    } else if (item?.buttonInfos?.clickFunction == 'calendarMode') {
      this.tableMode = false;
    }

  }

  openMaintenanceEventDialog(event: any) {

    // console.log(event);

    let eventProperties: any = event.event?.extendedProps ?? {};

    let currentTemplate = this.templates.find(x => x.id == eventProperties.templateId);
    let eventDetailConfig = this.dashboardConfig?.customActionButtons?.find(x => x?.clickFunction == 'eventDetail');

    let eventData: any = {
      ...eventProperties,
      ...{
        title: this.translate.instant("MAINTENANCE_CALENDAR.EVENT_INFORMATION") + ' - ' + moment(eventProperties?.day).format("MMM DD, YYYY"),
        machine: this.machine,
        appConfig: this.appConfig,
        templates: this.templates,
        machineId: this.machineId,
        currentTemplate: currentTemplate,
        dashboardConfig: eventDetailConfig,
      }
    };

    console.log({ eventData });

    const maintenanceEventDialog = this.dialog.open(
      MaintenanceEventDialogComponent,
      {
        panelClass: 'ff-dialog',
        // width: '40%',
        width: eventDetailConfig?.dialogWidth ?? "30%",
        // minWidth: eventDetailConfig?.dialogMinWidth ?? '30vw',
        height: 'auto',
        data: eventData,
      }
    );

    maintenanceEventDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {

        this.deleteEvents(result.id, eventData);
      }

    });
  }

  deleteEvents(deleteId, eventInfo) {

    // console.log({ deleteId });
    // console.log({ eventInfo });

    let copyMaintenanceEvents: any = this.clonerService.deepClone(this.maintenanceEvents);

    let finalMaintenanceEvents = copyMaintenanceEvents.reduce((acc, val) => {
      switch (deleteId) {
        case 'deleteOccurrency':
          if (!(val?.seriesId == eventInfo?.seriesId && val?.templateId == eventInfo?.templateId && val?.workDay == eventInfo?.day)) {
            acc.push(val);
          }
          break;
        case 'deleteSeries':
          if (val?.seriesId != eventInfo?.seriesId) acc.push(val);
          break;
        case 'deleteOccurencesInFuture':
          if (val?.seriesId != eventInfo?.seriesId || moment().diff(val.workDay) > 0) acc.push(val);
          break;
      }
      return acc;
    }, []);

    // console.log(finalMaintenanceEvents);

    this.maintenanceEvents = this.clonerService.deepClone(finalMaintenanceEvents);
    this.updateCalendar();
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // UPDATE CALENDAR

  updateCalendar() {
    try {

      let payload: any = this.clonerService.deepClone(this.maintenanceEvents);
      let url = '/apif/cmms/maintenance-calendar/' + this.machineId;

      let _this = this;

      this.apiService.sendPutRequest(url, payload)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, null,
            {
              text: _this.translate.instant('MAINTENANCE_CALENDAR.UPDATE_CALENDAR_RESPONSE.FAIL'),
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              duration: 4000,
              closeButton: '',
              classes: ['fail']
            }
          )))
        .subscribe(
          (data: any) => {
            // console.log(data);

            if (_this.maintenanceEvents == null) {
              _this.maintenanceEvents = _this.clonerService.deepClone(data.body);
              _this.maintenanceEventsUnparsed = _this.clonerService.deepClone(data.body);
            }
            try {

              // set plot data and configs
              _this.parseCalendarDays(_this);

            } catch (error) {
              console.log(error);
            }

            _this.internalDataService.openSnackBar(
              _this.translate.instant('MAINTENANCE_CALENDAR.UPDATE_CALENDAR_RESPONSE.SUCCESS'),
              'right',
              'bottom',
              4000,
              '',
              ['success']
            );

            _this.dispatcherService.getDispatch(_this, 300);

            window.location.reload();

          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      this.dispatcherService.getDispatch(this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {
    this.machineId = this.widget.referenceComponent.machineId;
    this.machine = this.clonerService.deepClone(this.widget.referenceComponent.machine ?? {});
    this.dispatcherService.getDispatch(this, 300);
  }

  ngAfterViewInit() { }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY

  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
  }

}