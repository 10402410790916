<div mat-dialog-title fxLayout="row" fxLayoutAlign="start center" class="w100">
  <mat-icon class="icon left">dehaze</mat-icon>
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close" fxLayout="row" fxLayoutAlign="center center">
    <mat-icon class="icon" aria-label="close">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <div *ngIf="dialog.templates == null || !dialog.templates?.length">
    <span class="error">{{ 'MAINTENANCE_CALENDAR.NO_TEMPLATE_CONFIGURED' | translate }}</span>
    <!-- <button class="filter-button" fxLayout="row" fxLayoutAlign="center center"
      (click)="openMaintenanceTemplatesDialog()">
      <ff-icon [icon]="'assignment'" style="margin-right: 8px;"></ff-icon>
      <span>{{ 'MAINTENANCE_CALENDAR.VIEW_MAINTENANCE_TEMPLATES' | translate }}</span>
    </button> -->
  </div>

  <div *ngIf="dialog.templates != null && dialog.templates?.length > 0" fxLayout="column" fxLayoutAlign="center start"
    fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <div class="ff-input-select w100" style="margin-bottom: 16px;">
      <mat-select [(value)]="currentTemplate" placeholder="{{ 'MAINTENANCE_CALENDAR.TEMPLATE_SELECTION' | translate }}"
        (selectionChange)="changeCurrentTemplate($event, currentTemplate)">
        <mat-option *ngFor=" let template of dialog.templates" [value]="template">
          {{ template.templateName }}
        </mat-option>
      </mat-select>
    </div>

  </div>

  <div *ngIf="currentTemplate != null">

    <!-- <div class="width100" fxLayout="row">
      <span class="md-headline-invert">{{ 'CALENDAR.BACKGROUND_COLOR' | translate }}</span>
      <span flex></span>
      <span class="color-square" [ngStyle]="{'background-color': currentTemplate.color}">
      </span>
    </div> -->

    <!-- <pre>{{ currentTemplate | json }}</pre> -->

    <div fxLayout="column">

      <div class="frequency-warning">
        <span class="md-orange">{{ 'MAINTENANCE_CALENDAR.FREQUENCY_CHANGE_WARNING' | translate }}</span>
      </div>

      <div fxLayout="row">

        <div class="recurrency-options" fxLayout="column">

          <div *ngFor="let opt of recurrencyOptions" fxLayout="row" fxLayoutAlign="start center">

            <mat-icon *ngIf="opt.selected" svgIcon="radio_button" class="icon small left radio_button">
            </mat-icon>

            <mat-icon *ngIf="!opt.selected" svgIcon="radio_button_disabled"
              class="icon small left radio_button_disabled">
            </mat-icon>

            <span>{{ filterService.translateElementNewFormat(opt) }}</span>

          </div>
        </div>

        <div class="recurrency" fxFlex="50">

          <div *ngIf="frequency == 'day'" fxLayout="column" fxLayoutGap="16px">

            <ff-checkbox [checkBoxConfig]="everyDay" (changedValue)="onCheckboxChange($event)"></ff-checkbox>
            <ff-checkbox [checkBoxConfig]="everyWeekDay" (changedValue)="onCheckboxChange($event)"></ff-checkbox>

          </div>

          <div *ngIf="frequency == 'week'">

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
              <span translate="MAINTENANCE_CALENDAR.RECUR_EVERY"></span>
              <div class="input-box">
                <input matInput class="ff-input number" style="max-width: 90px;" type="number" [min]="1"
                  [(ngModel)]="recurrencyPattern.recurrence" [disabled]="false">
              </div>
              <span translate="MAINTENANCE_CALENDAR.WEEK(S)_ON" style="text-transform: lowercase;"></span>
            </div>

            <div fxLayout="row grid" fxLayoutGap="16px grid" style="padding-top: 12px;">
              <span *ngFor="let day of weekDays">
                <ff-checkbox [checkBoxConfig]="day" (changedValue)="onWeekDayChange($event)"></ff-checkbox>
              </span>
            </div>

          </div>

          <div *ngIf="frequency == 'month'">

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
              <span translate="MAINTENANCE_CALENDAR.RECUR_EVERY"></span>
              <div class="input-box">
                <input matInput class="ff-input number" style="max-width: 90px;" type="number" [min]="1"
                  [(ngModel)]="recurrencyPattern.recurrence" [disabled]="false">
              </div>
              <span translate="MAINTENANCE_CALENDAR.MONTH(S)_ON" style="text-transform: lowercase;"></span>
            </div>


            <div class="ff-input-select" fxLayout="row" fxLayoutAlign="start center" style="margin-top: 8px;">
              <mat-select [(value)]="monthWeek">
                <mat-option *ngFor="let opt of monthWeeks" [value]="opt.id" fxLayout="row" fxLayoutAlign="start center">
                  <span fxLayout="row" fxLayoutAlign="start center">
                    {{ filterService.translateElementNewFormat(opt) }}
                  </span>
                </mat-option>
              </mat-select>
            </div>

            <div fxLayout="row grid" fxLayoutGap="16px grid" style="padding-top: 12px;">
              <span *ngFor="let weekDay of weekDays">
                <ff-checkbox [checkBoxConfig]="weekDay" (changedValue)="onWeekDayChange($event)"></ff-checkbox>
              </span>
            </div>


          </div>

          <div *ngIf="frequency == 'year'">

            <span style="font-weight: 500;">
              {{ 'MAINTENANCE_CALENDAR.RECUR_EVERY_YEAR_ON_THE' | translate }}
            </span>

            <input matInput class="ff-input text" type="text" disabled [(ngModel)]="nowDateWithoutYear">

          </div>

        </div>

        <div class="recurrency-end" fxFlex="50" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">

          <div class="interval-selector alternative-color" fxLayout="column" fxLayoutAlign="center start"
            fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

            <!-- start -->
            <div class="box" fxLayout="row" fxLayoutAlign="start center">
              <mat-icon class="icon">event_available</mat-icon>
              <mat-form-field style="min-width: 200px;">
                <mat-label>{{ 'INTERVAL.START' | translate }}</mat-label>
                <input matInput disabled [value]="nowDateP">
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
            <span translate="MAINTENANCE_CALENDAR.END_AFTER"></span>
            <div class="input-box">
              <input matInput class="ff-input number w100" type="number" [min]="1" [(ngModel)]="numberOfOccurrences"
                [disabled]="false">
            </div>
            <span translate="MAINTENANCE_CALENDAR.OCCURRENCES" style="text-transform: lowercase;"></span>
          </div>

          <div
            *ngIf="numberOfOccurrences == null || numberOfOccurrences == '' || numberOfOccurrences < 1 || numberOfOccurrences > maxNumberOfOccurences">
            <!-- <span>{{ numberOfOccurrences | json }}</span> -->
            <span class="error" *ngIf="numberOfOccurrences == null || numberOfOccurrences == ''">
              {{ 'GLOBAL.REQUIRED' | translate }}
            </span>
            <span class="error"
              *ngIf="numberOfOccurrences != null && numberOfOccurrences != '' && numberOfOccurrences < 1"
              translate="GLOBAL.MINIMUM_NUMBER" [translateParams]="{x: 1}">
            </span>
            <span class="error"
              *ngIf="numberOfOccurrences != null && numberOfOccurrences != '' && numberOfOccurrences > maxNumberOfOccurences"
              translate="GLOBAL.MAXIMUM_NUMBER" [translateParams]="{x: maxNumberOfOccurences}">
            </span>
          </div>

        </div>

      </div>
    </div>

    <!-- <table>
      <thead>
        <tr>
          <th *ngFor="let info of shiftTemplateInfos">
            <span>{{ info.label | translate }}</span>
          </th>
      </thead>
      <tbody>
        <tr *ngFor="let shift of currentTemplate">
          <td *ngFor="let info of shiftTemplateInfos">

            <span>{{ shift[info.variable] }}</span>
            <span ng-if="info.type == null" class="{{info.classes}}"
                ng-class="{'minWidthListLarge': mobile()}">{{ shift[info.variable] | placeholder:'-'}}
                <span ng-if="info.suffix != null">{{info.suffix}}</span>
              </span>

          </td>
        </tr>
      </tbody>
    </table> -->

  </div>

</mat-dialog-content>

<mat-dialog-actions *ngIf="dialog.templates != null && dialog.templates?.length > 0" fxLayout="row"
  fxLayoutAlign="end center" style="margin-right: 12px;">
  <button class="filter-button" (click)="closeDialog()" [disabled]="checkDisability()">
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</mat-dialog-actions>