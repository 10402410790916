import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-maintenance-calendar-templates-dialog',
  templateUrl: './maintenance-calendar-templates-dialog.component.html',
  styleUrls: ['./maintenance-calendar-templates-dialog.component.scss']
})
export class MaintenanceCalendarTemplatesDialogComponent implements OnInit, OnDestroy {

  public unparsedActivities: any = [];
  public unparsedTemplates: any = [];
  public frequency: any;
  public state: any = 0;
  public statesConfig: any = {
    activitiesSelectionWidget: 1,
    fileUpload: 1,
    existingFiles: 1,
    templateList: 0
  };

  public nowTime: any;
  public srcResult: any;
  public fileInfos: any;
  public errors: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<MaintenanceCalendarTemplatesDialogComponent>,
    public http: ApiService,
    public intervalService: IntervalService,
    public additionalDialog: MatDialog,
    public clonerService: ClonerService,
    public translate: FfTranslateService,
    public apiService: ApiService,
    public filterService: FiltersService,
    public internalDataService: InternalDataService,
  ) { }

  tabs: any = [
    {
      "id": "form",
      "title": "MAINTENANCE_HISTORY.FORM",
      "icon": {
        "icon": "assessment",
        "type": "icon"
      },
      "selected": true
    },
    {
      "id": "activities",
      "title": "MAINTENANCE_HISTORY.ACTIVITiES",
      "icon": {
        "icon": "list_alt",
        "type": "icon"
      },
      "right": true
    }
  ];

  ngOnInit() {

    this.nowTime = new Date().toISOString().substring(0, 16);

    this.state = 0;

    // Populate all the lists that have a closed list from the machine profiles, with the following config:
    // - editableInDialog:  true
    // - dialogEditType:    'closedList'
    // - listFromBE:        false || none
    // - type:              'configFromProfile'
    try {
      let closedLists = this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'closedList' && !x.listFromBE);
      closedLists = closedLists?.reduce((acc, info) => {
        if (info?.type != 'configFromProfile' || this.dialog.profile == null) {
          acc.push(info);
          return acc;
        }

        let list = this.clonerService.deepClone(this.dialog.profile?.[info?.mappingConfig?.key ?? 'timeStates']);

        this.parseObjectTypeClosedList(info, list);
        acc.push(info);

        return acc;
      }, []);
      closedLists?.filter(x => x?.listOptions?.length == 1).forEach(list => this.dialog.row[list.variable] = list.listOptions[0]);
    } catch (error) { console.log(error) }

    // // // // // // // // // // // // // // // // // // // // // // 
    // TEMPLATE LIST 
    // // // // // // // // // // // // // // // // // // // // // // 

    this.statesConfig.templateList = 0;

    (async () => {
      let endpointUrl = this?.dialog?.buttonInfos?.templatesConfig?.endpointUrl?.replaceAll("{machineId}", this.dialog.machineId);

      try {
        this.dialog.buttonInfos.templatesConfig.config.profile = this.dialog.profile;
      } catch (error) {
        console.log(error);
      }

      try {
        this.dialog.buttonInfos.templateDetailConfig.widgets[0].config.profile = this.dialog.profile;
      } catch (error) {
        console.log(error);
      }

      console.log(endpointUrl);

      let query: any = {
        tz: this.dialog.machine?.timezone ?? "Europe/Rome",
        lang: this.translate?.currentLang ?? 'en',
      };

      let payload: any = {}

      try {

        let method = 'POST';
        if (endpointUrl.includes(':')) {
          endpointUrl = endpointUrl.split(':');
          method = endpointUrl[0];
          endpointUrl = endpointUrl[1];
        }

        let requestSent = null;
        if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
        else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
        else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

        const data: any = await requestSent.toPromise();

        //------------------------------------------------------------------------//
        // RESPONSE PARSING

        let copyData: any = this.clonerService.deepClone(data?.body ?? []);

        this.unparsedTemplates = copyData;
        this.dialog.buttonInfos.templatesConfig.data = copyData;

        let permission = this.internalDataService.getSpecificPermission(this.dialog.buttonInfos?.templatesConfig?.editPermission ?? "mat-maintenance-edit");

        // Disable the EDIT and DELETE button for every template that is already in use in the calendar
        try {

          let calendarEvents: any = this.clonerService.deepClone(this.dialog.calendarEvents);

          this.dialog.buttonInfos.templatesConfig.data.templates.forEach(template => {

            let templateIdx = calendarEvents.findIndex(x => x.templateId == template.id);

            template.disabledButtons = {
              viewTemplate: false,
              editTemplate: templateIdx > -1 || !permission,
              removeTemplate: templateIdx > -1 || !permission,
            }
          });

        } catch (error) {
          console.log(error);
        }


        this.statesConfig.templateList = 1;
        this.checkState();

      } catch (error) {
        this.statesConfig.templateList = 1;
        this.checkState();
      }

    })();

    // // // // // // // // // // // // // // // // // // // // // // // 
    // // FILES 
    // // // // // // // // // // // // // // // // // // // // // // // 

    // if (this?.dialog?.dialogType == 'edit') {

    //   this.statesConfig.existingFiles = 0;

    //   (async () => {
    //     let endpointUrl = this?.dialog?.filesTableConfig?.endpointUrl?.replaceAll("{machineId}", this.dialog.machineId).replaceAll("{maintId}", this.dialog.row.id);

    //     console.log(endpointUrl);

    //     let query: any = {
    //       tz: this.dialog?.machine?.timezone ?? "Europe/Rome",
    //       lang: this.translate?.currentLang ?? 'en',
    //     };

    //     let payload: any = {}

    //     try {

    //       let method = 'POST';
    //       if (endpointUrl.includes(':')) {
    //         endpointUrl = endpointUrl.split(':');
    //         method = endpointUrl[0];
    //         endpointUrl = endpointUrl[1];
    //       }

    //       let requestSent = null;
    //       if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
    //       else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
    //       else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

    //       const data: any = await requestSent.toPromise();

    //       //------------------------------------------------------------------------//
    //       // RESPONSE PARSING

    //       let copyData: any = this.clonerService.deepClone(data?.body ?? []);

    //       // this.dialog.activitiesSelectionWidget.data = copyData;

    //       if (copyData?.length > 0) {

    //         copyData = copyData.reduce((acc, val) => {
    //           val.sizeP = this.parseFileSize(val.size);
    //           acc.push(val);
    //           return acc;
    //         }, []);
    //         this.dialog.buttonInfos.filesTableConfig.data = { selectedFiles: copyData };

    //         this.dialog.row.files = this.dialog.buttonInfos.filesTableConfig.data.selectedFiles.map(x => {
    //           return {
    //             name: x.name,
    //             file: x.arrayBuffer
    //           };
    //         });
    //       }
    //       console.log({ copyData });

    //       // if (this.dialog.row?.activities?.length) this.parseExistingActivities();

    //       // this.dialog.activitiesSelectionWidget = this.clonerService.deepClone(this.dialog.activitiesSelectionWidget);

    //       // console.log(this.dialog.activitiesSelectionWidget);

    //       this.statesConfig.existingFiles = 1;
    //       this.checkState();

    //     } catch (error) {
    //       this.statesConfig.existingFiles = 1;
    //       this.checkState();
    //     }

    //   })();
    // }

    // // // // // // // // // // // // // // // // // // // // // // 
    // ACTIVITIES 
    // // // // // // // // // // // // // // // // // // // // // // 

    // Initialize data as empty array (NEW MAINTENANCE) or pre-existing list of activities (EDIT MAINTENANCE)
    try {
      this.dialog.activitiesTableConfig.data = { selectedActivitiesTable: this.dialog.row?.activities ?? [] };
      this.dialog.activitiesTableConfig = this.clonerService.deepClone(this.dialog.activitiesTableConfig);
    } catch (error) {
      console.log(error);
    }

    if (this?.dialog?.activitiesSelectionWidget?.type != null) {

      this.statesConfig.activitiesSelectionWidget = 0;

      (async () => {
        let endpointUrl = this?.dialog?.activitiesSelectionWidget?.endpointUrl?.replaceAll("{machineId}", this.dialog.machineId);

        let query: any = {
          tz: this.dialog?.machine?.timezone ?? "Europe/Rome",
          lang: this.translate?.currentLang ?? 'en',
        };

        let payload: any = {}

        try {

          let method = 'POST';
          if (endpointUrl.includes(':')) {
            endpointUrl = endpointUrl.split(':');
            method = endpointUrl[0];
            endpointUrl = endpointUrl[1];
          }

          let requestSent = null;
          if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
          else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
          else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

          const data: any = await requestSent.toPromise();

          //------------------------------------------------------------------------//
          // RESPONSE PARSING

          let copyData: any = this.clonerService.deepClone(data?.body ?? []);

          this.dialog.activitiesSelectionWidget.data = copyData;
          this.unparsedActivities = copyData?.activities ?? [];

          if (this.dialog.row?.activities?.length) this.parseExistingActivities();

          this.dialog.activitiesSelectionWidget = this.clonerService.deepClone(this.dialog.activitiesSelectionWidget);

          this.statesConfig.activitiesSelectionWidget = 1;
          this.checkState();

        } catch (error) {
          this.statesConfig.activitiesSelectionWidget = 1;
          this.checkState();
        }

      })();
    }

  }

  parseExistingActivities() {

    let clonedActivities = [];
    try {
      this.dialog.activitiesSelectionWidget.data.activities.forEach(group => {
        group.subgroups.forEach(subgroup => {
          subgroup.activities.forEach(activity => {
            if (this.dialog.row?.selectedActivities.findIndex(act => activity.id == act) > -1) {
              activity.selected = true;
              clonedActivities.push(activity);
            }
          });
          if (subgroup.activities.every(x => x.selected)) subgroup.selected = true;
        });
        if (group.subgroups.every(x => x.selected)) group.selected = true;
      });

      this.dialog.activitiesTableConfig.data.selectedActivitiesTable = clonedActivities;

      this.dialog.row.selectedActivities = this.dialog.activitiesTableConfig.data.selectedActivitiesTable?.map(x => x.id);
      this.dialog.activitiesTableConfig = this.clonerService.deepClone(this.dialog.activitiesTableConfig);
    } catch (error) {
      console.log(error);
    }
  }

  checkState() {
    try { this.state = Object.values(this.statesConfig).every(x => x == 1) ? 1 : 0 }
    catch (error) {
      console.log(error);
      return 0;
    }
  }

  onFileSelected() {

    console.log('onFileSelected');

    this.statesConfig.fileUpload = 0;

    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      let promises = [];
      for (let file of inputNode.files) {
        let filePromise = new Promise(resolve => {
          let reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = () => resolve({ arrayBuffer: reader.result, infos: file });
        });
        promises.push(filePromise);
      }
      Promise.all(promises).then((fileContents: any) => {
        // console.log({ fileContents });

        let fileInfos: any = this.dialog.buttonInfos.filesTableConfig?.data?.selectedFiles ?? [];

        for (let file of fileContents) {

          if (fileInfos.findIndex(x => x.name == file.infos.name) == -1) {
            let infos = {
              name: file.infos.name,
              type: file.infos.type,
              size: file.infos.size,
              arrayBuffer: file.arrayBuffer,
              sizeP: this.parseFileSize(file.infos.size),
            }
            fileInfos.push(infos);
          }
        }

        // console.log(fileInfos);

        this.dialog.buttonInfos.filesTableConfig.data = { selectedFiles: [] };

        setTimeout(() => {

          this.dialog.buttonInfos.filesTableConfig.data = { selectedFiles: fileInfos };
          this.dialog.row.files = fileInfos.map(x => {
            return {
              name: x.name,
              size: x.size,
              file: x.arrayBuffer
            };
          });

          console.log(this.dialog.row.files);
        }, 250);


      });

    }

  }

  parseFileSize(size: any) {
    if (size > 0) {

      if (size > 1000000)
        return Math.round(size / 1000000) + ' MB';
      else if (size > 1000)
        return Math.round(size / 1000) + ' kB';
      else
        return size + ' B';
    }
    return 'File is empty';
  }

  checkShowCondition(info, row) {

    if (info?.showCondition == null) return true;

    let condition = info.showCondition;

    let boolCond = true;

    try {

      if (condition?.every(cond => Array.isArray(cond) && cond?.length == 3)) boolCond = condition.some(c => this.filterService.parseDisabilityCondition(row, c));
      else if (condition?.length == 3) boolCond = this.filterService.parseDisabilityCondition(row, condition);

    } catch (error) { console.log(error) }

    // If the condition(s) is(are) not satisfied, delete the value associated with the variable
    if (!boolCond) row[info.variable] = null;

    return boolCond;

  }

  async onButtonClick(item) {
    try {

      if (item?.buttonInfos?.clickFunction == 'downloadAttachments') {

        let payload = {
          fileName: item?.row?.day
        };

        let resp = await this.apiService.asyncDownload((item?.buttonInfos?.endpointUrl ?? '/apif/cmms/download-files/calendarTemplate/') + this.dialog.machineId + '/' + item?.row?.id, payload);
        this.apiService.handleDownload(resp);
        return;
      }

      if (item?.buttonInfos?.clickFunction == 'removeFile') {
        // console.log(item?.row);
        try {
          let idx = this.dialog.buttonInfos.filesTableConfig.data.selectedFiles.findIndex(x => x.name == item.row.name);
          if (idx > -1) {

            if (this.dialog.buttonInfos.filesTableConfig.data.selectedFiles[idx]?.arrayBuffer == null) {
              try { this.dialog.row.filesToRemove.push(this.dialog.buttonInfos.filesTableConfig.data.selectedFiles[idx].name) }
              catch (error) { this.dialog.row.filesToRemove = [this.dialog.buttonInfos.filesTableConfig.data.selectedFiles[idx].name] }
            }
            this.dialog.buttonInfos.filesTableConfig.data.selectedFiles.splice(idx, 1);
            let selectedFiles = this.dialog.buttonInfos.filesTableConfig.data.selectedFiles;
            this.dialog.buttonInfos.filesTableConfig.data.selectedFiles = [];
            setTimeout(() => {

              this.dialog.buttonInfos.filesTableConfig.data.selectedFiles = selectedFiles;
              this.dialog.row.files = selectedFiles.map(x => {
                return {
                  name: x.name,
                  size: x.size,
                  file: x.arrayBuffer
                };
              });

              // console.log(this.dialog.row.files);
            }, 100);
          }
        } catch (error) {

        }

        return;
      }

      else if (item?.buttonInfos?.clickFunction == 'addTemplate') {
        this.dialog.row.id = (new Date()).getTime() + Math.random().toString(16).slice(2);
        this.dialog.editMode = "add";
        return;
      }

      else if (item?.buttonInfos?.clickFunction == 'editTemplate') {
        console.log(item?.row, 'editTemplate');

        this.dialog.row = item?.row;
        this.frequency = this.dialog.row.frequency;

        if (this.dialog.row?.files?.length > 0) {

          let copyData: any = this.dialog.row?.files.reduce((acc, val) => {
            val.sizeP = this.parseFileSize(val.size);
            acc.push(val);
            return acc;
          }, []);

          this.dialog.buttonInfos.filesTableConfig.data = { selectedFiles: copyData };

          this.dialog.row.files = this.dialog.buttonInfos.filesTableConfig.data.selectedFiles.map(x => {
            return {
              name: x.name,
              size: x.size,
              file: x.arrayBuffer
            };
          });
        }

        this.recalcActivitiesBasedOnSelectedFrequency();
        if (this.dialog.row?.selectedActivities?.length) this.parseExistingActivities();
        this.dialog.editMode = "edit";
        return;
      }

      else if (item?.buttonInfos?.clickFunction == 'removeTemplate') {
        console.log(item?.row, 'removeTemplate');

        const confirmationDialog = this.additionalDialog.open(ConfirmationDialogComponent, {
          panelClass: 'ff-dialog',
          data: {
            cancelClass: 'md-red-i',
            title: this.translate.instant('MAINTENANCE_CALENDAR.CONFIRM_REMOVE_TEMPLATE', {
              templateName: item.row.templateName
            }),
          },
        });
        confirmationDialog.afterClosed().subscribe(async (result: any) => {
          console.log(result);

          if (result != null && result != '') {

            let templateIdx = this.unparsedTemplates.templates.findIndex(x => x.id == item.row?.id);
            if (templateIdx == -1) return;
            this.unparsedTemplates.templates[templateIdx].toDelete = true;

            let resp = null;
            let responseCode = 200;

            this.state = 0;

            try {
              resp = await this.apiService.sendPutRequestAwait('/apif/cmms/maintenance-templates/' + this.dialog?.machineId, this.unparsedTemplates);
              responseCode = resp.status;
            } catch (error) {
              console.log(error);
              responseCode = error.status;
            }

            this.internalDataService.openSnackBar(
              this.translate.instant('MAINTENANCE_CALENDAR.UPDATE_TEMPLATES_RESPONSE.' + (responseCode == 200 ? 'SUCCESS' : 'FAIL')),
              'right',
              'bottom',
              4000,
              '',
              [responseCode == 200 ? 'success' : 'fail']
            );
            this.state = 1;
            window.location.reload();
          }
        });
        return;
      }

      else if (item?.buttonInfos?.clickFunction == 'viewTemplate') {
        console.log(item?.row, 'viewTemplate');
        this.dialog.viewMode = true;

        let currentTemplate: any = this.filterService.removeKeysWithCustomRule(item.row, (x: any) => x[0] != 'disabledButtons');

        try { this.dialog.buttonInfos.templateDetailConfig.widgets[0].data.templates = [currentTemplate] }
        catch (error) { this.dialog.buttonInfos.templateDetailConfig.widgets[0].data = { templates: [currentTemplate] } }

        try { this.dialog.buttonInfos.templateDetailConfig.widgets[0].config.additionalButtons[0].defaultDisabled = currentTemplate.files == null || currentTemplate.files.length == 0 }
        catch (error) { console.log(error) }

        let clonedActivities = [];
        try {
          this.dialog.activitiesSelectionWidget.data.activities.forEach(group => {
            group.subgroups.forEach(subgroup => {
              subgroup.activities.forEach(activity => {
                if (item?.row?.selectedActivities.findIndex(act => activity.id == act) > -1) clonedActivities.push(activity);
              });
            });
          });

          this.dialog.buttonInfos.templateDetailConfig.widgets[1].data = { selectedActivitiesTable: clonedActivities };
        } catch (error) {
          console.log(error);
        }

        // console.log(this.dialog.buttonInfos.templateDetailConfig.widgets[1].data.selectedActivitiesTable);
        return;
      }

      let rv = item?.tableConfig?.rowsVariable;
      switch (rv) {
        case "activities":

          let settings: any = {
            tableConfigName: 'activitiesTableConfig',
            selectedTableName: 'selectedActivitiesTable',
            nestedKeyLabel: 'activities',
            finalElement: 'id',
          };

          let nestedTableIndex = item?.nestedTableIndex ?? 0;

          let compTable: any = this.dialog[settings.tableConfigName].data[settings.selectedTableName];

          if (nestedTableIndex == 2) {
            let compIdx = compTable.findIndex(comp => comp[settings.finalElement] == item.row[settings.finalElement]);
            if (compIdx == -1) {
              compTable.push(item.row);
            } else {
              compTable.splice(compIdx, 1);
            }
          } else if (nestedTableIndex == 1) {
            let toggle = item.row.selected;
            item.row[settings.nestedKeyLabel].forEach(component => {
              let compIdx = compTable.findIndex(comp => comp[settings.finalElement] == component[settings.finalElement]);
              if (compIdx == -1) {
                if (toggle) compTable.push(component);
              } else {
                if (!toggle) compTable.splice(compIdx, 1);
              }
            })
          } else if (nestedTableIndex == 0) {

            let toggle = item.row.selected;
            item.row.subgroups.forEach(subgroup => {
              subgroup[settings.nestedKeyLabel].forEach(component => {
                let compIdx = compTable.findIndex(comp => comp[settings.finalElement] == component[settings.finalElement]);
                if (compIdx == -1) {
                  if (toggle) compTable.push(component);
                } else {
                  if (!toggle) compTable.splice(compIdx, 1);
                }
              })
            })
          }

          if (rv == 'hierarchy') compTable?.forEach(comp => comp.interventionType = comp.interventionType ?? 1);

          this.dialog[settings.tableConfigName].data[settings.selectedTableName] = compTable;

          if (rv == 'hierarchy') {
            this.dialog.row.selectedComponents = compTable?.map(x => {
              return {
                id: x.id,
                interventionType: x.interventionType ?? 1
              };
            });
          } else {
            this.dialog.row.selectedActivities = compTable?.map(x => x.id);
          }
          this.dialog[settings.tableConfigName] = this.clonerService.deepClone(this.dialog[settings.tableConfigName]);
          break;

      }

    } catch (error) {
      console.log(error);
    }
  }

  selectSecondTab() {

    console.log('selectSecondTab');

    if (this.frequency != this.dialog.row.frequency) {
      this.frequency = this.dialog.row.frequency;

      this.dialog.row.selectedActivities = [];
      try { this.dialog.activitiesTableConfig.data = { selectedActivitiesTable: [] } }
      catch (error) { console.log(error) }

      this.recalcActivitiesBasedOnSelectedFrequency();

      console.log(this.dialog.activitiesSelectionWidget.data.activities);

      this.dialog.activitiesSelectionWidget = this.clonerService.deepClone(this.dialog.activitiesSelectionWidget);
      this.statesConfig.activitiesSelectionWidget = 0;

      setTimeout(() => {
        this.statesConfig.activitiesSelectionWidget = 1;
        this.selectTab(this.tabs[1]);
      }, 200);
    }
    else {

      this.selectTab(this.tabs[1]);
    }

  }

  recalcActivitiesBasedOnSelectedFrequency() {

    try {
      let clonedUnparsedActivities: any = this.clonerService.deepClone(this.unparsedActivities);
      this.dialog.activitiesSelectionWidget.data.activities = clonedUnparsedActivities?.reduce((accFirst, firstLevel) => {

        // FIRST LEVEL CHECK - if a searched value is present in the main object values, stop the loop
        let firstLevelCheck = this.filterService.filterObjBySearch(firstLevel, this.dialog.row.frequency, ["frequency"]);
        if (firstLevelCheck) {
          accFirst.push(firstLevel);
          return accFirst;
        }

        let secondLevelKey = this.dialog.activitiesSelectionWidget.config.nestedTableConfig.variable;
        if (firstLevel?.[secondLevelKey]?.length > 0) {

          firstLevel[secondLevelKey] = firstLevel[secondLevelKey].reduce((accSecond, secondLevel) => {

            // SECOND LEVEL CHECK - if a searched value is present in the values, stop the loop
            let secondLevelCheck = this.filterService.filterObjBySearch(secondLevel, this.dialog.row.frequency, ["frequency"]);
            if (secondLevelCheck) {
              accSecond.push(secondLevel);
              return accSecond;
            }

            if (this.dialog.activitiesSelectionWidget.config.nestedTableConfig?.isNestedTable && this.dialog.activitiesSelectionWidget.config.nestedTableConfig?.nestedTableConfig?.variable) {

              let thirdLevelKey = this.dialog.activitiesSelectionWidget.config.nestedTableConfig.nestedTableConfig.variable;
              if (secondLevel?.[thirdLevelKey]?.length > 0) {

                secondLevel[thirdLevelKey] = secondLevel[thirdLevelKey].reduce((accThird, thirdLevel) => {

                  // THIRD LEVEL CHECK - if a searched value is present in the third level, stop the loop
                  let thirdLevelCheck = this.filterService.filterObjBySearch(thirdLevel, this.dialog.row.frequency, ["frequency"]);
                  if (thirdLevelCheck) {
                    accThird.push(thirdLevel);
                    return accThird;
                  }

                  return accThird;

                }, []);

                if (secondLevel[thirdLevelKey].length > 0) {
                  accSecond.push(secondLevel);
                  return accSecond;
                }

              }
            }

            return accSecond;

          }, []);

          if (firstLevel[secondLevelKey].length > 0) {
            accFirst.push(firstLevel);
            return accFirst;
          }
        }

        return accFirst;
      }, []);

    } catch (error) { console.log(error) }
  }

  removeTemplateChanges() {

    // Remove existing activities
    try { this.dialog.activitiesSelectionWidget.data.activities = this.clonerService.deepClone(this.unparsedActivities) }
    catch (error) { console.log(error) }

    try { this.dialog.activitiesTableConfig.data.selectedActivitiesTable = [] }
    catch (error) { console.log(error) }

    // Remove existing files
    try { this.dialog.buttonInfos.filesTableConfig.data.selectedFiles = [] }
    catch (error) { console.log(error) }

    // Remove existing fields
    this.dialog.row = {};

    // Remove frequency
    this.frequency = null;

    // Not edit mode
    this.dialog.editMode = false;
  }


  ngOnDestroy() { }

  checkDisability() {
    let tableInfosCheck = !this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x.requiredInDialog).every(x => (this?.dialog?.row?.[x?.variable] != null && this?.dialog?.row?.[x?.variable] != ''));

    // let selectedComponentsCheck = this.dialog.row?.selectedActivities?.length != null && this.dialog.row?.selectedActivities?.length > 0
    let selectedComponentsCheck = true;
    let stateCheck = this.state == 0;
    return tableInfosCheck || !selectedComponentsCheck || stateCheck;
  }

  parseObjectTypeClosedList(info: any, data: any) {

    info.listOptions = data?.filter(x => {

      // If element is not object, don't filter
      if (typeof x != 'object') return true;

      // If object doesn't have key "filterForSpecificVariableAndValue", don't filter
      if (info?.filterForSpecificVariableAndValue == null) return true;

      // Return only elements that have value associated to the specified "variable" equal to the specified "value"
      return x?.[info?.filterForSpecificVariableAndValue?.variable] == info?.filterForSpecificVariableAndValue?.value;
    })
      // Map on nestedKey if present
      .map(x => info?.nestedKeyToMap ? this.translate.instant(x?.[info?.nestedKeyToMap] ?? '-') : x) ?? [];

    if (info?.nestedKeysToKeep?.length > 0) {
      info.listOptionsDict = data?.reduce((acc, val) => {

        if (typeof val == 'object') {
          let obj: any = {}

          info?.nestedKeysToKeep?.forEach(k => {
            if (typeof k == 'object') {
              // If "primaryKey", substitute the id in the incoming object with the variable from info
              if (k?.primaryKey) obj[info.variable] = this.translate.instant(val[k.id] ?? '-');
              // Else map the newId (if present) with the correct value
              else obj[k?.newId ?? k?.id] = val[k.id];
            }
            else obj[k] = val[k];
          });
          acc.push(obj);
        }

        return acc;
      }, []);
    }
  }

  onDaySelection(date: any, key: string) {
    this.dialog.row[key] = JSON.parse(JSON.stringify(moment(date.value)));
  }


  selectTab(sel: any) {
    // reset position of tabs content
    this.tabs.forEach((t: any) => {
      t.selected = false;
      delete t.left;
      delete t.right;
    });
    // set as selected
    sel.selected = true;
    // loop on tabs to set on left previous and right nexts
    let selPassed = false;
    this.tabs.forEach((t: any) => {
      if (t.id != sel.id) {
        if (!selPassed) t.left = true;
        else t.right = true;
      } else {
        selPassed = true;
      }
    });
  }

  buildConfigPayload(files) {

    let payload = new FormData();

    for (let file of files) {
      if (file.file != null) payload.append(file.name, new Blob([file.file]));
    }

    return payload
  }

  buildTemplatePayload(row, files?) {

    let payload: any = {};
    if (row != null) {
      payload = this.clonerService.deepClone(row);
      payload.activitiesNumber = row.selectedActivities.length;
      if (files?.length > 0) {
        payload.files = files.map(x => {
          return {
            name: x.name,
            size: x.size,
          }
        });
      }
    }
    return payload;
  }

  async closeDialog() {

    // let inputDateTime = this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'inputDateTime');

    // inputDateTime?.forEach(x => {
    //   try { this.dialog.row[x.variable] = this.intervalService.formatStringDate(this.dialog?.row?.[x.variable] + ':00.000', this.dialog?.machineId) }
    //   catch (error) { console.log(error) }
    // });


    let files = this.dialog?.row?.files;

    try {
      delete this.dialog.row.files;
    } catch (error) {
      console.log(error);
    }

    let templatePayload = this.buildTemplatePayload(this.dialog.row, files);

    try {
      let templateIdx = this.unparsedTemplates?.templates?.findIndex(x => x.id == this.dialog.row?.id);
      if (templateIdx == -1) this.unparsedTemplates.templates.push(templatePayload);
      else this.unparsedTemplates.templates[templateIdx] = templatePayload;
    } catch (error) {
      console.log(error);
    }

    this.state = 0;

    let resp = null;
    let responseCode = 200;

    try {
      resp = await this.apiService.sendPutRequestAwait('/apif/cmms/maintenance-templates/' + this.dialog?.machineId, this.unparsedTemplates);
      responseCode = resp.status;
    } catch (error) {
      console.log(error);
      responseCode = error.status;
    }

    if (files?.length && files?.filter(file => file.file != null)?.length > 0) {
      let filesPayload = this.buildConfigPayload(files);

      let filesResp = null;
      let filesResponseCode = 200;

      try {
        filesResp = await this.apiService.sendCustomPayloadWithArrayBuffer('/apif/cmms/update-files/calendarTemplate/' + this.dialog?.machineId + '/' + this.dialog.row.id, filesPayload);
        filesResponseCode = filesResp.status;
      } catch (error) {
        console.log(error);
        filesResponseCode = error.status;
      }

    }

    this.internalDataService.openSnackBar(
      this.translate.instant('MAINTENANCE_CALENDAR.UPDATE_TEMPLATES_RESPONSE.' + (responseCode == 200 ? 'SUCCESS' : 'FAIL')),
      'right',
      'bottom',
      4000,
      '',
      [responseCode == 200 ? 'success' : 'fail']
    );

    this.state = 1;

    this.dialogRef.close(this.dialog?.row);
  }

}
