<div *ngIf="isMobile" fxFlex fxLayout="column" class="tab-content h100 w100" fxLayoutAlign="center center">
  <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
</div>

<!-- ------------------------------
  cardStyle == 0 
------------------------------ -->
<ng-container *ngIf="cardStyle == 0 && machines.length > 0 && !isMobile && !avoidLoopProfiles">
  <ng-container *ngFor="let profile of machineProfiles.profileList">
    <mat-card *ngIf="!emptyProfileCard(profile)" fxLayout="column" class="homepage-list">
      <!-- <pre>{{profile.id}}</pre> -->

      <div class="table-title" *ngIf="profile.label != null">{{ profile.label | translate }}</div>

      <table>

        <!-- header -->
        <thead>
          <tr>

            <!-- image -->
            <th style="width: 64px;">&nbsp;</th>
            <!-- machineInfos -->
            <ng-container *ngFor="let item of profile.value.homepage.machineInfos">
              <th *ngIf="item.list == null || item.list">
                {{item.label | translate}}
              </th>
            </ng-container>
            <!-- productionData -->
            <ng-container *ngIf="profile.value.homepage.config.productionData">
              <ng-container *ngFor="let item of profile.value.homepage.productionData">
                <th *ngIf="item.list">
                  {{item.label | translate}}
                </th>
              </ng-container>
            </ng-container>
            <!-- productionOrder -->
            <ng-container *ngIf="profile.value.homepage.config.productionOrder">
              <ng-container *ngFor="let item of profile.value.homepage.productionOrder">
                <th *ngIf="item.list">
                  {{item.label | translate}}
                </th>
              </ng-container>
            </ng-container>
            <!-- materials -->
            <ng-container *ngIf="profile.value.homepage.config.materials">
              <ng-container *ngFor="let item of profile.value.homepage.materials">
                <th *ngIf="item.list">
                  {{item.label | translate}}
                </th>
              </ng-container>
            </ng-container>
            <!-- productivity -->
            <ng-container *ngIf="profile.value.homepage.config.productivity">
              <ng-container *ngFor="let item of profile.value.homepage.productivity.gauges">
                <th *ngIf="item.list">
                  {{item.label | translate}}
                </th>
              </ng-container>
              <ng-container *ngFor="let item of profile.value.homepage.productivity.kpibars">
                <th *ngIf="item.list">
                  {{item.label | translate}}
                </th>
              </ng-container>
            </ng-container>

            <th *ngIf="showCalendarSwitch">{{ 'CALENDAR.CALENDAR_ENABLED' | translate}}</th>

          </tr>
        </thead>

        <!-- body -->
        <tbody>
          <tr *ngFor="let machine of machines | ffMachinesFilters: mFilters; trackBy trackById"
            (click)="selectMachine(machine)" class="clickable" [ngClass]="{'selected': machine.selected}"
            [hidden]="differentMachineProfile(profile, machine)">

            <!-- image -->
            <td style="width: 64px; text-align: center; padding: 0.5rem 0.75rem 0.25rem 0.25rem;">
              <img [src]="machine.image" style="height: 50px; border-radius: 12px;"
                (error)="internalDataService.handleMissingImage($event, machine)">
            </td>
            <!-- machineInfos -->
            <ng-container *ngFor="let item of profile.value.homepage.machineInfos">
              <td *ngIf="item.list == null || item.list" [ngClass]="{ 'primary': item.primary }">
                <span>{{(machine[item.variable] != null ? machine[item.variable] : '-')}}</span>
                <span *ngIf="item.suffix">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
              </td>
            </ng-container>
            <!-- productionData -->
            <ng-container *ngIf="profile.value.homepage.config.productionData">
              <ng-container *ngFor="let item of profile.value.homepage.productionData">
                <td *ngIf="item.list">
                  <span>{{(machine[item.variable] != null ? machine[item.variable] : '-')}}</span>
                  <!-- <span *ngIf="item.suffix">&nbsp;{{ filterService.convertUnit(item.suffix).unit | translate }}</span> -->
                </td>
              </ng-container>
            </ng-container>
            <!-- productionOrder -->
            <ng-container *ngIf="profile.value.homepage.config.productionOrder">
              <ng-container *ngFor="let item of profile.value.homepage.productionOrder">
                <td *ngIf="item.list">
                  <span>{{(machine[item.variable] != null ? machine[item.variable] : '-')}}</span>
                  <span *ngIf="item.suffix">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
                </td>
              </ng-container>
            </ng-container>
            <!-- materials -->
            <ng-container *ngIf="profile.value.homepage.config.materials">
              <ng-container *ngFor="let item of profile.value.homepage.materials">
                <td *ngIf="item.list">
                  <span>{{(machine[item.variable] != null ? machine[item.variable] : '-')}}</span>
                  <span *ngIf="item.suffix">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
                </td>
              </ng-container>
            </ng-container>
            <!-- productivity -->
            <ng-container *ngIf="profile.value.homepage.config.productivity">
              <!-- gauges -->
              <ng-container *ngFor="let item of profile.value.homepage.productivity.gauges">
                <td *ngIf="item.list && machine[item.variable] && machine[item.variable].value">
                  <kpi-bar [kpiProps]="{ 'value': machine[item.variable].value, 'hideLabel': true }"></kpi-bar>
                </td>
                <td *ngIf="item.list && (!machine[item.variable] || machine[item.variable].value == null)">
                  <kpi-bar [kpiProps]="{ 'value': null, 'hideLabel': true }"></kpi-bar>
                </td>
              </ng-container>
              <!-- kpibars -->
              <ng-container *ngFor="let item of profile.value.homepage.productivity.kpibars">
                <td *ngIf="item.list">
                  <kpi-bar [kpiProps]="{ 'value': machine[item.variable], 'hideLabel': true }"></kpi-bar>
                </td>
              </ng-container>
            </ng-container>

            <td *ngIf="showCalendarSwitch" style="text-align:center">
              <ui-switch [checked]="isEnabledMachineCalendar(machine.machineId)"
                (change)="toggleEnableCalendarAsset(machine,$event)" checkedLabel="ON" uncheckedLabel="OFF"
                [disabled]="!enableCalendarSwitch(machine.machineId)" size="small">
              </ui-switch>
            </td>

          </tr>
        </tbody>

      </table>
    </mat-card>
  </ng-container>
</ng-container>


<!-- ------------------------------
  cardStyle != 0 
------------------------------ -->
<ng-container *ngIf="(cardStyle != 0 || avoidLoopProfiles) && machines.length > 0 && !isMobile">
  <mat-card fxLayout="column" style="margin-top: 12px;" class="homepage-list">
    <table>
      <!-- header row -->
      <tr>
        <th>&nbsp;</th>
        <th *ngFor="let info of machineInfos">
          <ng-container *ngIf="info.list == null || info.list">
            {{info.label | translate}}
          </ng-container>
        </th>

        <ng-container *ngFor="let info of machineDatas">
          <th *ngIf="info.list == null || info.list">
            {{info.label | translate}}
          </th>
        </ng-container>
        <th *ngIf="!appConfig?.homepage?.hideOeeGaugeInList">
          {{ 'HOMEPAGE.PRODUCTIVITY' | translate }}
        </th>
        <th *ngIf="showCalendarSwitch">{{ 'CALENDAR.CALENDAR_ENABLED' | translate}}</th>

        <!-- <th>
          <span>{{ 'HOMEPAGE.WORST_COMPONENT' | translate }}</span>
        </th> -->
        <th>&nbsp;</th>
      </tr>
      <!-- body rows -->
      <tr *ngFor="let machine of machines | ffMachinesFilters: mFilters; trackBy trackById"
        (click)="selectMachine(machine)" class="clickable" [ngClass]="{'selected': machine.selected}">

        <!-- <pre>{{ machine | json }}</pre> -->

        <td style="text-align: center; padding: 8px 0 4px 4px;"><img [src]="machine.image"
            (error)="internalDataService.handleMissingImage($event, machine)" style="height: 40px;"></td>

        <td *ngFor="let info of machineInfos" style="padding: 8px 0 4px 4px;">

          <ng-container *ngIf="info.list == null || info.list">
            <span *ngIf="!appConfig?.homepage?.useSingleCellInListView && info.type == null">
              {{(machine[info.variable] != null ? machine[info.variable] : '-')}}&nbsp;{{
              filterService.convertUnit(info.suffix).unit }}
            </span>

            <ff-single-cell [item]="machine" [info]="info" *ngIf="appConfig?.homepage?.useSingleCellInListView">
            </ff-single-cell>
          </ng-container>
        </td>

        <ng-container *ngFor="let info of machineDatas">
          <td *ngIf="info.list == null || info.list" style="padding: 8px 0 4px 4px;">
            <span *ngIf="!appConfig?.homepage?.useSingleCellInListView">
              {{(machine[info.variable] != null ? machine[info.variable] : '-')}}&nbsp;{{
              filterService.convertUnit(info.suffix).unit }}
            </span>

            <ff-single-cell [item]="machine" [info]="info" *ngIf="appConfig?.homepage?.useSingleCellInListView">
            </ff-single-cell>
          </td>
        </ng-container>

        <ng-container *ngIf="!appConfig?.homepage?.hideOeeGaugeInList">
          <td *ngIf="machine.oeeGaugeUI != null">
            <kpi-bar [kpiProps]="{ 'value': machine.oeeGaugeUI.value, 'hideLabel': true }">
            </kpi-bar>
          </td>
          <td *ngIf="machine.oeeGaugeUI == null">
            -
          </td>
        </ng-container>

        <td *ngIf="showCalendarSwitch" style="text-align:center">
          <ui-switch [checked]="isEnabledMachineCalendar(machine.machineId)"
            (change)="toggleEnableCalendarAsset(machine,$event)" checkedLabel="ON" uncheckedLabel="OFF"
            [disabled]="!enableCalendarSwitch(machine.machineId)" size="small">
          </ui-switch>
        </td>

        <!-- <td *ngIf="machine.worstComponent != null && machine.worstComponent.componentLabel != null"
        style="padding: 8px 0 4px 4px;">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <span fxFlex="20">
            {{(machine.worstComponent.componentLabel != null ? machine.worstComponent.componentLabel :
            '-')}}
          </span>
          <kpi-bar fxFlex="80" [kpiProps]="{ 'value': machine.worstComponent.health }"></kpi-bar>
        </div>
      </td> -->

        <!-- <td style="padding: 8px 0 4px 4px;">
        <mat-icon>location_on</mat-icon>
      </td> -->

      </tr>

    </table>
  </mat-card>
</ng-container>