import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss'],
})
export class HomepageComponent implements OnInit, OnChanges, OnDestroy {

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // VARIABLES

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;
  public breadcrumb: any;

  public tabs: any;

  public homepageData: any;
  public interval: any;
  public intervalConfig: any;

  public pollingTime: number = 0;
  public pollingMachines: any;

  public pollingTime2: number = 0;
  public pollingMachinesData: any;

  public homepageReloadSub: Subscription;

  public machineSelectedSub: Subscription;
  public machineSelected: any;

  public hierarchyElementSelectedSub: Subscription;
  public hierarchyElementSelected: any;

  public hierarchyHistorySub: Subscription;
  public hierarchyHistory: any = [];

  public machines: any;

  public filterButtons: any;

  public isSmThanTablet: any;

  public cachedIntervalId: any = "intervalHomepage";

  public hierarchy: any;
  public hierarchyUnparsed: any;

  public unparsedMachineList: any;
  public hierarchyMode: any = false;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 4;
  public pageStateComplete: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getMachinesPolling, nextState: 3, loadingMsg: 'LOADING.MACHINES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getMachinesDataPolling, nextState: 4 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public dialog: MatDialog,
    public filterService: FiltersService,
    public cacheService: CacheService,
    public clonerService: ClonerService,
    public mobile: MobileService,
    private router: Router,
  ) {

    // this.pageState.subscribe((value) => { console.log('pageState.subscribe', value); });

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.hierarchyUnparsed = this.appConfigService.getHierarchy;

    this.mobile.mobileListener.subscribe((value: any) => {
      this.isSmThanTablet = value.isSmThanTablet
    })

    this.pollingTime = this.appConfig.homepage.pollingMachineList;
    if (this.pollingTime == null) this.pollingTime = 0;

    this.pollingTime2 = this.appConfig.homepage.pollingMachineData;
    if (this.pollingTime2 == null) this.pollingTime2 = 60000;

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['HOMEPAGE.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    if (this.hierarchyUnparsed != null) {

      if (localStorage.getItem("hierarchyMode") == null) localStorage.setItem('hierarchyMode', 'true');

      this.hierarchyMode = JSON.parse(localStorage.getItem("hierarchyMode"));

      this.internalDataService.setBreadcrumb(this.breadcrumb);
      this.hierarchy = this.clonerService.deepClone(this.hierarchyUnparsed);

    }

    try { this.tabs = this.internalDataService.getPageTabs('homepage') }
    catch (error) { console.error(error) }

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      this.machineSelected = value;
    });

    this.hierarchyElementSelectedSub = this.internalDataService.hierarchyElementSelected.subscribe(value => {

      this.cacheService.set("hierarchy", null);

      if (value != null) {

        this.cacheService.set("hierarchy", value?.elements);

        this.breadcrumb.push(value.label);
        this.internalDataService.setBreadcrumb(this.breadcrumb);

        try { this.pollingMachinesData.unsubscribe() } catch (error) { }

        this.pageState.next(4);
        this.getMachinesDataPolling(this);
      }
    });

    this.hierarchyHistorySub = this.internalDataService.hierarchyHistory.subscribe(value => {
      if (value != null) this.hierarchyHistory.push(value);
    });

    this.machines = {
      list: []
    };


    if (this.cacheService.get(this.cachedIntervalId) == null) {

      this.interval = this.intervalService.getIntervalById(this.appConfig.homepage.intervalId ?? 'last30Days');

      this.intervalConfig = {
        list: this.intervalService.getDefaultIntervals(3),
        selected: this.interval
      };

      this.cacheService.set(this.cachedIntervalId, this.clonerService.deepClone(this.intervalConfig));

    } else {
      this.intervalConfig = this.clonerService.deepClone(this.cacheService.get(this.cachedIntervalId));
      this.interval = this.intervalConfig.selected;
    }


    this.homepageData = {
      filters: {
        filterVariables: this.appConfig.homepage.filterVariables,
        search: "",
        custom: []
      },
      pageOptions: [25, 50, 100],
      pageSize: 50,
      interval: this.interval
    };
    this.internalDataService.setHomepageData(this.homepageData)
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingMachinesData, this.getMachinesDataPolling, this.getMachinesData, "Europe/Rome", 4, 5, false, true) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // machines

  // polling
  getMachinesPolling(_this: any) {
    try {

      if (_this.pollingTime > 0) {
        _this.pollingMachines = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getMachines(_this, count);
        });
      } else {
        _this.getMachines(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get machines
  getMachines(_this: any, count: any) {
    try {

      let url = '/apif/machines';
      if (_this.appConfig.requests != null && _this.appConfig.requests.machines != null) url = _this.appConfig.requests.machines;

      _this.apiService.sendGetRequest(url, {})
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingMachines))
        )
        .subscribe(
          async (data: any) => {
            // console.log(data);

            let machines = data.body;

            for (let i = 0; i < machines.length; i++) {
              machines[i].profile = await _this.internalDataService.getMachineProfile(_this, machines[i].machineId);
            }

            _this.unparsedMachineList = _this.clonerService.deepClone(machines);
            let clonedMachineList = _this.clonerService.deepClone(_this.unparsedMachineList);

            // Hierarchy mode
            if (_this.hierarchyMode) {

              _this.machines.list = _this.hierarchy?.reduce((acc, val) => {
                val.loading = true;

                let assets = [val.id];
                if (val?.elements?.length) assets = _this.filterService.getAssetsFromHierarchy(val.elements)?.map(x => x.id);
                // single machine mode
                else val = _this.unparsedMachineList.find(x => x.machineId == val.id);
                let profile = clonedMachineList?.find(x => x.machineId == assets[0])?.profile ?? {};

                val.profile = { ...profile, ...val.profile };

                acc.push(val);
                return acc;
              }, []);

              _this.machines.filtered = _this.machines.list;

              _this.internalDataService.setMachinesList(_this.clonerService.deepClone(_this.machines.filtered));

              if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
            }
            else {
              _this.machines.list = machines;
              _this.machines.filtered = _this.machines.list;

              _this.internalDataService.setMachinesList(_this.machines.filtered);

              if (count == 0) {
                if (_this.machines.list?.length == 1) await _this.internalDataService.setMachineSelected(_this.machines.list?.[0]);
                _this.dispatcherService.getDispatch(_this, 300);
              }
            }


          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // machines data

  // polling
  getMachinesDataPolling(_this: any) {

    try {

      if (_this.pollingTime2 > 0) _this.pollingMachinesData = timer(0, _this.pollingTime2).subscribe((count) => _this.getMachinesData(_this, count))
      else _this.getMachinesData(_this, 0);

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get machines data
  getMultipleAssetData(_this: any, count: any) {

    try {

      let hierarchy = _this.cacheService.get("hierarchy");

      if (!hierarchy) _this.hierarchy = _this.clonerService.deepClone(_this.hierarchyUnparsed);
      else _this.hierarchy = _this.clonerService.deepClone(hierarchy)

      _this.cacheService.set("hierarchy", _this.hierarchy);

      let clonedMachineList = _this.clonerService.deepClone(_this.unparsedMachineList);

      let machineCounter = 0;

      _this.machines.list = _this.hierarchy;

      // loop on each machine
      _this.machines.list.forEach(async (element: any, idx: number) => {

        if (element.id == null) element.id = element.machineId;

        // console.log(machine);
        let assets = [element.id];
        if (element?.elements?.length) {
          assets = _this.filterService.getAssetsFromHierarchy(element.elements)?.map(x => x.id);
        }
        // single machine mode
        else {
          element = _this.unparsedMachineList.find(x => x.machineId == element.id);
        }
        let profile = clonedMachineList?.find(x => x.machineId == assets[0])?.profile ?? {};

        element.profile = { ...profile, ...element.profile };

        // Handle image
        let sources40F = _this.appInfo.sources40F ?? 'assets/images/';

        // image
        element.image = (_this.appInfo.sources40F ?? 'assets/config/') + element.machineId + "/homepage.png";
        element.imageProfile = sources40F + (element.profile.machineImage ?? 'machine-default.png');

        if (count == 0) element.loading = true;

        let payload = _this.internalDataService.buildMachinePayload(element);

        // Format interval according to the machine's timezone
        let interval = _this.clonerService.deepClone(_this.interval);

        if (interval.id == 'custom') _this.intervalService.formatTimezone(interval, element.timezone);
        else _this.intervalService.parseSingleInterval(interval, element.timezone);

        let query: any = {
          from: interval.start,
          tz: element.timezone
        };

        // Add interval end if interval end is not NOW
        if (interval != null && !interval.enabledPolling) query.to = interval.end;

        payload.assets = assets;
        payload.globalKpi = true;
        payload.definitions = profile?.definitions;

        // _this.parseMachineData(_this, element);
        // console.log({ element });

        // // _this.machines.list[idx] = element;

        // _this.machines.filtered = _this.machines.list;
        // _this.internalDataService.setMachinesList(_this.machines.filtered);
        // console.log({ payload });

        let url = '/apif/cross-kpi';

        _this.apiService.sendPostRequest(url, payload, query)
          .pipe(
            retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
            catchError(async error => {

              machineCounter++;
              console.log(error);

              let message = error.error;
              if (message != null && typeof message == 'object') {
                if (message.hasOwnProperty('error') && message.error != null) message = message.error;
                else if (message?.message != null) message = message.message;
                if (message != null && typeof message == 'object' && message.hasOwnProperty('message') && message.message != null) {
                  message = message.message;
                }
              }

              element.loading = false;
              element.error = message;

              _this.parseMachineData(_this, element);

              element = _this.getMachineCards(element, element?.profile?.homepage?.machineDatas ?? _this.appConfig?.homepage?.machineDatas);

              _this.machines.list[idx] = await _this.internalDataService.getMachineData(_this, element);

              return [];
            })
          )
          .subscribe(
            async (data: any) => {

              machineCounter++;
              // console.log(data.body);

              element.error = null;
              element.loading = false;
              let mergeData = { ...element, ...data.body };

              _this.parseMachineData(_this, mergeData);

              // mergeData = _this.getMachineCards(mergeData, element?.profile?.homepage?.machineDatas ?? _this.appConfig?.homepage?.machineDatas);

              _this.machines.list[idx] = mergeData;

              // _this.machines.filtered = _this.machines.list;
              // _this.internalDataService.setMachinesList(_this.machines.filtered);

              if (machineCounter == _this.hierarchy?.length) {
                // if (machineCounter == _this.machines.list?.length) {
                // _this.machines.list = _this.clonerService.deepClone(_this.hierarchy);
                _this.machines.filtered = _this.machines.list;
                _this.internalDataService.setMachinesList(_this.machines.filtered);
              }

            }
          );

      });

      // // If the filters are already present, load them and apply filters
      // if (_this.cacheService.get("filterButtonsHomepage") != null) {
      //   _this.filterButtons = _this.cacheService.get("filterButtonsHomepage");
      //   _this.onFiltersDialogSelect();
      // }

      // else _this.filterButtons = _this.buildFilterButtons(_this);


    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get machines data
  getMachinesData(_this: any, count: any) {

    if (_this.hierarchyMode) {
      console.log('Hierarchy mode activated');

      _this.getMultipleAssetData(_this, count);
      return;
    }

    try {

      let machineCounter = 0;
      // loop on each machine
      _this.machines.list.forEach(async (machine: any, idx: number) => {
        // console.log(machine);

        if (_this.appConfig?.requests?.machineId != null) machine.machineId = machine[_this.appConfig.requests.machineId];

        if (count == 0) machine.loading = true;

        if (_this.machineSelected.machineId == machine.machineId) await _this.internalDataService.setMachineSelected(machine);

        let payload = _this.internalDataService.buildMachinePayload(machine);

        if (machine?.components?.length) payload.components = machine.components;

        // Format interval according to the machine's timezone
        let interval = _this.clonerService.deepClone(_this.interval);

        if (interval.id == 'custom') _this.intervalService.formatTimezone(interval, machine.timezone);
        else _this.intervalService.parseSingleInterval(interval, machine.timezone);

        let query: any = {
          from: interval.start,
          timezone: machine.timezone
        };

        // Add interval end if interval end is not NOW
        if (interval != null && !interval.enabledPolling) query.to = interval.end;

        // Add machine reference if specified
        if (machine.machineReference) query.machineId = machine.machineReference;

        // Add ideal flow (aqseptence)
        if (machine.idealFlow) query.idealFlow = machine.idealFlow;

        // Add maxSpeed (mfl) - deprecated
        if (machine.maxSpeed) query.maxSpeed = machine.maxSpeed;

        // Add work day start (peroni) - mat v1.4
        if (machine.profile?.workDayStart != null) payload.workDayStart = machine.profile.workDayStart;

        // Add homepage program detail (mangiarotti)
        if (machine.profile?.homepageProgramDetail != null) payload.homepageProgramDetail = machine.profile.homepageProgramDetail;

        // Add aggrList (pedrollo)
        if (machine.profile?.homepage?.config?.aggrList?.aggr != null) query.groupby = machine.profile.homepage.config.aggrList.aggr;

        // Add homepage aggregations (peroni) - mat v1.4
        if (machine.profile?.homepageAggregations) payload.aggregationParams = machine.profile.homepageAggregations;

        // Add aggrLists (mondini)
        // TODO: Add multiple groupby
        // if (machine.profile?.homepage?.config?.aggrLists?.length) query.multipleGroupby = machine.profile.homepage.config.aggrLists?.reduce((acc, val) => {
        //   acc[val.aggr] = val?.requestVariables ?? [];
        //   return acc;
        // }, {});
        // TO UPDATE:
        if (machine.profile?.homepage?.config?.aggrLists?.length) query.groupby = machine.profile.homepage.config.aggrLists?.find(x => x.groupby)?.aggr;


        let url = '/apif/machines/data/' + machine.machineId;
        if (_this.appConfig.requests?.machinesData != null) url = _this.appConfig.requests.machinesData + machine.machineId;

        _this.apiService.sendPostRequest(url, payload, query)
          .pipe(
            retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
            catchError(async error => {

              machineCounter++;
              console.log(error);

              let message = error.error;
              if (message != null && typeof message == 'object') {
                if (message.hasOwnProperty('error') && message.error != null) message = message.error;
                else if (message?.message != null) message = message.message;
                if (message != null && typeof message == 'object' && message.hasOwnProperty('message') && message.message != null) {
                  message = message.message;
                }
              }

              machine.loading = false;
              machine.error = message;

              _this.parseMachineData(_this, machine);

              machine = _this.getMachineCards(machine, machine?.profile?.homepage?.machineDatas ?? _this.appConfig?.homepage?.machineDatas);

              _this.machines.list[idx] = await _this.internalDataService.getMachineData(_this, machine);

              return [];
            })
          )
          .subscribe(
            async (data: any) => {

              machineCounter++;
              // console.log(data.body);

              machine.error = null;
              machine.loading = false;
              let mergeData = { ...machine, ...data.body };

              if (mergeData.hasOwnProperty('neverC') && mergeData.neverC != null) {
                if (mergeData.neverC) {
                  mergeData.notConnected = false;
                  mergeData.neverConnected = true;
                }
                else {
                  mergeData.notConnected = true;
                  mergeData.neverConnected = false;
                }
              } else {
                mergeData.neverConnected = mergeData.lastUpdate == null;
                mergeData.notConnected = moment().diff(moment(mergeData.lastUpdate), 'm') > (_this.appConfig?.machineMonitoring?.warningMinutes ?? 15);
              }

              _this.parseMachineData(_this, mergeData);

              mergeData = _this.getMachineCards(mergeData, machine?.profile?.homepage?.machineDatas ?? _this.appConfig?.homepage?.machineDatas);

              _this.machines.list[idx] = await _this.internalDataService.getMachineData(_this, mergeData);

              if (machineCounter == _this.machines.list?.length) {
                _this.machines.filtered = _this.machines.list;
                _this.internalDataService.setMachinesList(_this.machines.filtered);
              }

            }
          );

      });

      // If the filters are already present, load them and apply filters
      if (_this.cacheService.get("filterButtonsHomepage") != null) {
        _this.filterButtons = _this.cacheService.get("filterButtonsHomepage");
        _this.onFiltersDialogSelect();
      }

      else _this.filterButtons = _this.buildFilterButtons(_this);


    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseMachineData(_this: any, data: any) {

    for (let box in data.profile?.homepage) {
      if (Array.isArray(data.profile.homepage[box])) {
        for (let box2 of data.profile.homepage[box]) {
          //machineName
          if (box2.variable == "machineName") continue;

          if (box == 'productionData') data[box2.variable] = this.filterService.parseObjFromConfig(data, box2, null, 'type', true, true, this.clonerService.deepClone(data.profile));
          else data[box2.variable] = this.filterService.parseObjFromConfig(data, box2);

        }
      }
    }

    data.aggrList = [];
    if (data.hasOwnProperty('groups') && data.groups != null && Object.keys(data.groups).length > 0) {

      Object.entries(data.groups).forEach((group: any) => {

        const aggr = group[0];
        const aggrKPIs = group[1];

        if (aggrKPIs != null) aggrKPIs.label = aggr;

        data.aggrList.push(aggrKPIs);

      });

      let maxNumber = 600;
      // try {
      //   maxNumber = data.profile.homepage.config.aggrList.maxNumberOfElems != null ? data.profile.homepage.config.aggrList.maxNumberOfElems : maxNumber;
      // } catch (error) { }

      try {
        data.aggrList.sort(this.filterService.sortByProperty("totCount", 'desc', true));

        data.moreElementsThanShowed = (data.aggrList.length > maxNumber);
        data.aggrList = data.aggrList.filter((x: any, index: any) => index < maxNumber);
      } catch (error) {
        console.log(error);
      }
    }

    data.aggrLists = null;
    if (data.hasOwnProperty('aggrTables') && data.aggrTables != null && Object.keys(data.aggrTables).length > 0) {

      data.aggrLists = {};

      Object.entries(data.aggrTables).forEach((aggrType: any) => {

        let aggrId = aggrType[0];
        let aggrVal = aggrType[1];

        data.aggrLists[aggrId] = [];

        Object.entries(aggrVal).forEach((group: any) => {

          const aggr = group[0];
          const aggrKPIs = group[1];

          if (aggrKPIs != null) aggrKPIs.label = aggr;

          if (aggrId == 'worstComponents' && data.profile?.additionalHMConfig?.hideInHealthMonitoringHomepage.includes(aggrKPIs.componentId)) {
            console.log(`Component "${aggrKPIs.componentId}" not added for machine "${data.machineId}" in homepage due to "additionalHMConfig.hideInHealthMonitoringHomepage" in machine profile`);
          }
          else data.aggrLists[aggrId].push(aggrKPIs);

        });

        let aggrConfig = data.profile?.homepage?.config?.aggrLists?.find(x => x.aggr == aggrId);

        // TODO: Add productionUnit in aggrLists
        // try { if (val?.productionUnit != null) val.unit = this.filterService.getProductionUnit(val?.productionUnit, this.widget?.config?.profile?.productionConfig) } catch (error) { console.log(error) }

        let sortVar = aggrConfig?.sort?.variable ?? 'totCount';
        let sortOrder = aggrConfig?.sort?.order ?? 'desc';

        try { data.aggrLists[aggrId].sort(this.filterService.sortByProperty(sortVar, sortOrder, true)) }
        catch (error) { console.log(error) }

      });
    }

    if (_this.appConfig.MAT2 && _this.hierarchyMode) {

      data.isHierarchicElement = data.elements?.length;

      if (_this.machineSelected?.machineId != null) {
        if (data.isHierarchicElement) data.selected = _this.filterService.getAssetsFromHierarchy(data?.elements)?.map(x => x.id)?.includes(_this.machineSelected?.machineId);
        else data.selected = _this.machineSelected?.machineId == data.machineId;
      }
    }

    // oee
    _this.setOeeGaugeUI(data,
      data.profile?.homepage?.gaugeVariable ?? _this.appConfig?.homepage?.gaugeVariable ?? {
        variable: 'oee',
        label: "HOMEPAGE.OEE"
      }
    );
  }

  setOeeGaugeUI(machine: any, prop: any) {
    if (machine.hasOwnProperty(prop.variable)) {
      machine.oeeGaugeUI = {
        label: this.translate.instant(prop.label ?? '-'),
        value: machine[prop.variable],
        height: 125,
        strokeWidth: 16,
        strokeFill: "white",
        linecap: "round",
        percentageFillCircle: 0.75,
        filterBackground: "url(#shadow)"
      }
    }
  }

  applyFilters(_this: any, search: string) {
    this.internalDataService.setMachinesList(_this.machines.filtered);
  }

  searchMachines(searchEvent?: any) {
    if (searchEvent != null && searchEvent.target != null && searchEvent.target.value) {
      this.homepageData.filters.search = searchEvent.target.value;
    } else {
      this.homepageData.filters.search = "";
    }
    this.internalDataService.setHomepageData(this.homepageData);
  }

  buildFilterButtons(_this: any) {
    let buttons: any[] = [];

    let keyToSearch = _this.appConfig?.homepage?.machineInfos;
    let filterWithFiltrable = true;

    if (keyToSearch == null) {
      keyToSearch = _this.appConfig?.homepage?.filterDialogVariables;
      filterWithFiltrable = false;
    }

    if (keyToSearch != null) {
      for (let attribute of keyToSearch) {
        if (filterWithFiltrable && attribute.filtrable || !filterWithFiltrable) {

          attribute = _this.clonerService.deepClone(attribute);
          let attrVal = attribute.variable;

          let options: any = _this.clonerService.deepClone(_this.machines.list.filter(machine => machine.hasOwnProperty(attrVal) && machine[attrVal] != null).map(machine => machine[attrVal]));

          options = [...new Set(options)];
          attribute.options = options.map((x: any) => {
            return {
              id: x,
              label: this.translate.instant(x),
              selected: true
            }
          });

          buttons.push(attribute);
        }
      }
    }

    return buttons;
  }

  onFiltersDialogSelect() {
    try {
      this.cacheService.set("filterButtonsHomepage", this.filterButtons);
      this.homepageData.filters.custom = this.filterButtons;
      this.internalDataService.setHomepageData(this.homepageData);
    } catch (error) { console.log(error) }
  }

  // MORONI START
  getMachineCards(machineData: any, machineCards: any) {

    try {

      machineCards.forEach((param: any, idx: number) => {

        if (param.variable != null && param.variable.startsWith('cons') && machineData.profile.hasOwnProperty('consumables') &&
          machineData.profile.consumables != null && machineData.profile.consumables.length > 0) {
          let consIx = machineData.profile.consumables.findIndex((cons: any) => cons.id == param.variable);
          if (consIx != -1) {
            // machineData.productionInfos[prodIdx] = {
            //     label: $translate.instant(machine.profile.consumables[consIx].label),
            //     suffix: machine.profile.consumables[consIx].unit,
            //     value: filterService.parseGaugeValue(data[prod.variable], machine.profile.consumables[consIx].decimals, machine.profile.consumables[consIx].multiplier)
            // }
            machineData[param] = this.filterService.parseGaugeValue(machineData[param], machineData.profile.consumables[consIx].decimals, machineData.profile.consumables[consIx].multiplier)
          } else {
            // machine.productionInfos[prodIdx] = {
            //     label: prod.variable,
            //     suffix: "",
            //     value: filterService.parseGaugeValue(data[prod.variable], 0, 1)
            // }
            machineData[param] = this.filterService.parseGaugeValue(machineData[param], machineData.profile.consumables[consIx].decimals, machineData.profile.consumables[consIx].multiplier)
          }
        } else if (machineData.hasOwnProperty(param.variable)) {

          machineData[param.variable] = param.isTime ?
            this.filterService.parseTime(machineData[param.variable], 's', 'HH:mm:ss') :
            machineData[param.variable] != null ? (this.filterService.parseGaugeValue(machineData[param.variable], (param.decimals != null ? param.decimals : 0), 1)) : '-'

          // if (prod.hasOwnProperty('infos')) {
          //     machine.productionInfos[prodIdx] = {
          //         label: prod.infos.hasOwnProperty('label') ? $translate.instant(prod.infos.label) : '',
          //         suffix: prod.infos.hasOwnProperty('unit') ? prod.infos.unit : null,
          //         value: prod.infos.isTime ?
          //         filterService.parseTime(data[prod.variable], 's', 'HH:mm:ss') :
          //         filterService.parseGaugeValue(data[prod.variable], (prod.decimals != null ? prod.decimals : 0), 1),
          //     }
          // } else {
          //     let productionInfo = customService.getProductionInfoFromId(prod.variable, machine.profile);
          //     machine.productionInfos[prodIdx] = {
          //         label: productionInfo.label,
          //         suffix: productionInfo.unit,
          //         value: filterService.parseGaugeValue(data[prod.variable], (prod.decimals != null ? prod.decimals : 0), 1)

          //     }
          // }
        }
      });

    } catch (error) { }

    return machineData;
  }
  // MORONI END

  toggleHierarchyMode(flag) {

    this.hierarchyMode = flag;
    this.cacheService.resetAll();
    localStorage.setItem('hierarchyMode', `${this.hierarchyMode}`);

    this.router.navigateByUrl(this.router.url);
  }

  navigateBack() {

    try {
      this.hierarchy = this.hierarchyHistory.pop();
      this.cacheService.set("hierarchy", this.hierarchy);

      this.breadcrumb.pop();
      this.internalDataService.setBreadcrumb(this.breadcrumb);

      try { this.pollingMachinesData.unsubscribe() } catch (error) { }

      this.pageState.next(4);
      this.getMachinesDataPolling(this);

    } catch (error) {
      console.log(error);
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {
    this.homepageReloadSub = this.internalDataService.homepageCardReload.subscribe(value => {
      if (value) this.getMachinesData(this, 1);
    });
    this.dispatcherService.getDispatch(this, 300)
  }

  // CHANGES
  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    // console.log('ngOnDestroy');
    try { this.internalDataService.setHierarchyElementSelected(null) } catch (error) { }
    try { this.internalDataService.setHierarchyHistory(null) } catch (error) { }
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingMachines.unsubscribe() } catch (error) { }
    try { this.pollingMachinesData.unsubscribe() } catch (error) { }
    try { this.hierarchyElementSelectedSub.unsubscribe() } catch (error) { }
    try { this.hierarchyHistorySub.unsubscribe() } catch (error) { }
    try { this.homepageReloadSub.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
  }

}