<!-- icon -->
<span *ngIf="icon != null" fxLayout="row" fxLayoutAlign="center center" matTooltip="{{ icon.tooltip | translate }}"
  [style.margin-right]="left ? '8px' : '0'" [style.margin-left]="right ? '8px' : '0'">

  <!-- standard mat icon -->
  <ng-container *ngIf="icon?.type == null">
    <mat-icon class="icon" [ngClass]="iconClass">{{ icon }}</mat-icon>
  </ng-container>

  <!-- icon.type -->
  <ng-container *ngIf="icon?.type != null">

    <!-- icon -->
    <mat-icon *ngIf="icon.type == 'icon'" class="icon" [ngClass]="iconClass != null ? iconClass : icon.class"
      [style.color]="iconColor != null ? iconColor : (icon.color != null ? icon.color : 'unset')">
      {{ icon.icon }}
    </mat-icon>

    <!-- svg -->
    <mat-icon *ngIf="icon.type == 'svg'" svgIcon="{{ icon.icon }}" class="icon"
      [ngClass]="iconClass != null ? iconClass : icon.class"
      [style.color]="iconColor != null ? iconColor : (icon.color != null ? icon.color : 'unset')">
    </mat-icon>

    <!-- fontawesome -->
    <i *ngIf="icon.type == 'fontawesome' || icon.type == 'phosphor'" class="icon {{ icon.icon }}"
      [ngClass]="iconClass != null ? iconClass : icon.class" [class.big]="big"
      [style.color]="iconColor != null ? iconColor : (icon.color != null ? icon.color : 'unset')">
    </i>

  </ng-container>

</span>