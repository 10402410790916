import { Component, Input, OnInit } from '@angular/core';
import { ClonerService } from 'src/app/services/clone.service';

@Component({
  selector: 'ff-apex-chart-with-switch',
  templateUrl: './apex-chart-with-switch.component.html',
  styleUrls: ['./apex-chart-with-switch.component.scss']
})
export class ApexChartWithSwitchComponent implements OnInit {

  @Input() widget: any;

  public expandedModeSwitch: any = {
    title: "GLOBAL.GANTT_EXPANDED_VIEW",
    tooltip: "GLOBAL.GANTT_EXPANDED_VIEW_TOOLTIP",
    checked: false,
    checkedLabel: "on",
    uncheckedLabel: "off"
  };

  constructor(
    public clonerService: ClonerService,
  ) { }

  switchChange(flag) {
    this.expandedModeSwitch.checked = flag;

    if (flag) {
      this.widget.data.ganttData.seriesNotExpanded = this.clonerService.deepClone(this.widget.data.ganttData.series);
      this.widget.data.ganttData.series = this.clonerService.deepClone(this.widget.data.ganttData.seriesExpanded);
    }
    else {
      this.widget.data.ganttData.series = this.clonerService.deepClone(this.widget.data.ganttData.seriesNotExpanded);
    }

  }

  ngOnInit(): void { }

}
