import { Injectable } from '@angular/core';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

@Injectable({
  providedIn: 'root'
})
export class DispatcherService {

  constructor(
    public translate: FfTranslateService
  ) { }

  // Dispatcher
  public getDispatch(_this: any, code: number, error?: any) {
    // get pageStates and current pageState from _this
    let states = _this.pageStates || [];
    let state = _this.pageState.value || 0;
    // find current pageState in pageStates list 
    let stateCurrent = states.find((x: any) => {
      return x.state === state;
    });
    if (stateCurrent != undefined) {
      // find passed code in codes list 
      let codeTarget = stateCurrent.codes.find((x: any) => {
        return x.code == code;
      });
      if (codeTarget != undefined) {
        // update pageState to next
        _this.pageState.next(codeTarget.nextState);

        // update loading message
        let loadMsg = { message: this.translate.instant('GLOBAL.LOADING') };
        if (codeTarget.loadingMsg) loadMsg.message = this.translate.instant(codeTarget.loadingMsg);
        _this.loadingData = loadMsg;

        // update error message
        if (error != null) {
          let defaultErrMsg = {
            type: 0,
            status: 500,
            message: 'GLOBAL.FAILED_REQUEST'
          }

          let errMsg = Object.assign(defaultErrMsg, error);
          _this.errorData = errMsg;
        }

        // return next step function
        return codeTarget.function(_this);
      }
      // passed code NOT FOUND in codes list 
      else {

      }
    }
    // current pageState NOT FOUND in pageStates list 
    else {

    }
  }


  // completeDispatch
  public completeDispatch(_this: any) {
    // console.log('completeDispatch');
  }

  // errorDispatch
  public errorDispatch(_this: any) {
    // console.error('errorDispatch');
    return _this;
  }


}
