<div mat-dialog-title fxLayout="row" class="w100">
    <span>{{ dialogData.title }}</span>
    <span fxFlex></span>
    <button mat-dialog-close class="close">
        <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography alternative-color bigger">

    <div class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="start center">

        <span fxFlex fxShow="false" fxShow.gt-sm></span>

        <button *ngIf="showResetInterval" mat-button class="filter-button md-gray-dark" aria-label="remove filters"
            (click)="resetDefaultInterval()">
            <mat-icon class="icon">refresh</mat-icon>
            <span style="padding-left: 8px;">{{ 'CONTINUOUS_EXPLORATION.RESET_INTERVAL' | translate }}</span>
        </button>

        <!-- intervals -->
        <ff-interval-selector *ngIf="dialogData.customInterval == null" [config]="intervalConfig"
            [fullWidthStart]="interval.id == 'custom'" (selected)="selectInterval($event)">
        </ff-interval-selector>

    </div>

    <div class="tab-content w100" fxLayout="column"
        fxLayoutAlign="{{ pageState.value < 3 || pageState.value == 0 ? 'center center' : 'start stretch' }}">

        <!-- <ff-loading-card [loadingData]="loadingData" -->
        <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 3" [loadingData]="loadingData"
            style="margin: auto;">
        </ff-loading-card>

        <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>

        <ff-dashboard *ngIf="dashboardData != null && pageState.value >= pageStateReady"
            (clicked)="onButtonClick($event)" [completeDashboardConfig]="completeDashboardConfig">
        </ff-dashboard>
    </div>

</mat-dialog-content>