<div fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.gt-sm="center center">
    <span *ngIf="expandedModeSwitch?.title != null" left>
        {{ expandedModeSwitch.title | translate }}
    </span>
    <span fxFlex fxHide.gt-sm></span>
    <ff-icon icon="info" matTooltip="{{ expandedModeSwitch?.tooltip | translate }}" left></ff-icon>
    <ui-switch [checked]="expandedModeSwitch?.checked" (change)="switchChange($event)"
        [checkedLabel]="expandedModeSwitch?.checkedLabel" [uncheckedLabel]="expandedModeSwitch?.uncheckedLabel">
    </ui-switch>
</div>

<ff-apex-chart [widget]="widget" fxLayout="column" fxLayoutAlign="space-between start" style="width: 100%">
</ff-apex-chart>