import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Subscription, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { FfDashboardDialogComponent } from '../ff-dashboard-dialog/ff-dashboard-dialog.component';

@Component({
  selector: 'ff-wilson',
  templateUrl: './ff-wilson.component.html',
  styleUrls: ['./ff-wilson.component.scss']
})
export class FfWilsonComponent implements OnInit {

  dragInProgress: any = false;

  machineProfile: any;
  dashboardConfig: any;
  appInfo: any;
  machineSelectedSub: any = Subscription;
  attemptedDashboardSearchMachineId: any;

  disableTextarea: any = false;
  isOpenedChat: any = false;
  fullscreen: any = false;
  disableChat: any = false;
  isButtonPresent: any = false;
  messages: any = [];

  answers = [
    "Hi there! How can I help you?",
    "Yes, you need to use protective gloves (kevlar) and safety glasses when lubricating the flanges. It is also important to use non-grip grease such as Copper Paste (CFG), Neverseez (Bostik), or C5A (Felpro), MOLYKOTE® HSC PLUS PASTE or equivalent.",
    "First, you need to clean the flanges and apply a protective paste for high temperatures application range, in order to ensure optimal coupling and protection against fretting corrosion. Then, you need to lubricate the flanges with the non-grip grease. Finally, you need to close the clamps.",
    "Yes, you should also lubricate the joints. The first rotary joint greasing must take place after about 200 hours of operations and every 2000 working hours thereafter, as shown in the lubrication chapter. Make sure to use OKS 432 grease, which is readily available at bearing suppliers. Replace only the consumed grease volume.",
    "If a joint is leaking, it should be replaced or repaired before consequential damage can occur. Never continue operation with leaking joints for prolonged periods of time. Contact the manufacturer’s technical assistance service for help with replacing or repairing the joint.",
    "You can contact the manufacturer’s technical assistance service at the following link: https://www.mflgroup.com/EN/customer-support.",
    "You're welcome! Is there anything else I can help you with?",
    "Alright, if you have any further questions, please don't hesitate to reach out. Have a great day!"
  ]

  defaultPlotLayout: any = {
    "legend": {
      "x": 0,
      "y": -0.25,
      "orientation": "h"
    },
    "hoverlabel": {
      "align": "left"
    },
    "xaxis": {
      "showgrid": false,
      "zeroline": false,
      "ticktext": {
        "variable": "start",
        "format": "date"
      }
    },
    "yaxis": {
      "showgrid": false,
      "zeroline": false
    },
    "margin": {
      "t": 30,
      "r": 60,
      "b": 60,
      "l": 60,
      "pad": 5
    }
  };

  defaultPlotParams: any = {
    "responsive": true
  };

  defaultPlotAttributes: any = {
    "plotDataAttribute": "timeserie",
    "plotFormatType": "arrayOfObjects",
  };


  defaultTableConfig: any = {
    "rowsVariable": "timeserie",
    "completeCardFrame": true,
    "cardFrame": true,
    "paginator": {
      "options": [
        10,
        25,
        50,
        100
      ],
      "size": 25,
      "position": "bottom right"
    },
    "sort": {
      "variable": "timeStart",
      "order": "desc"
    },
    "export": {
      "filename": "GLOBAL.TABLE"
    }
  };

  constructor(
    public apiService: ApiService,
    public appConfigService: AppConfigService,
    public internalDataService: InternalDataService,
    public cacheService: CacheService,
    public clonerService: ClonerService,
    public translate: FfTranslateService,
    public dialog: MatDialog,

  ) {

    this.appInfo = this.appConfigService.getAppInfo;

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(async value => {
      if (Object.keys(value).length != 0) {
        let clonedMachine: any = this.clonerService.deepClone(value);
        let machineId = clonedMachine?.machineId;
        if (this.attemptedDashboardSearchMachineId != machineId) {
          this.machineProfile = await this.internalDataService.getMachineProfile(this, machineId);
          this.getDashboard(this, machineId);
        }
      }
    });
  }

  toggleExpandChat() {
    if (this.dragInProgress) return;
    this.isOpenedChat = this.isOpenedChat != null ? !this.isOpenedChat : true;
    if (this.isOpenedChat) this.scrollBar();
  }

  toggleFullscreen() {
    this.fullscreen = this.fullscreen != null ? !this.fullscreen : true;
  }

  triggerFunction(event) {
    let text: any = document.getElementById("message-to-send");

    // CTRL + UP
    if (event.ctrlKey && (event.key == "Up" || event.key == "ArrowUp")) {
      text.value = this.messages?.at(-2)?.content;
    }

    // CTRL + ENTER or SHIFT + ENTER
    if ((event.shiftKey || event.ctrlKey) && event.key === 'Enter') {
      text.value += '\n';
    }

    // ENTER
    else if (event.key === 'Enter') {
      event.preventDefault();
      this.sendMessage();
    }
  }

  sendMessage(button?: any) {

    let elem: any = document.getElementById("message-to-send");

    let newMessage: any = {
      senderClasses: "align-right",
      timestamp: moment().format("HH:mm:ss"),
      messageClasses: "other-message float-right",
      content: button?.text ?? elem.value,
      buttonId: button?.id,
    };

    try { this.messages.at(-1).buttons = [] } catch (error) { }

    this.messages.push(newMessage);

    this.disableTextarea = true;

    this.messages.push({
      timestamp: moment().format("HH:mm:ss"),
      messageClasses: "my-message"
    });

    elem.value = '';
    elem.style.height = "";
    this.scrollBar();

    setTimeout(() => {
      this.messages[this.messages.length - 1] = { ...newMessage, ...{ content: this.answers[0], messageClasses: "my-message" } };

      this.answers.splice(0, 1);

      // this.sendPostRequest(newMessage);

      this.scrollBar();

      this.disableTextarea = false;

    }, 1500)

  }

  scrollBar() {
    setTimeout(() => {
      document.getElementById("chat_chat_chat").scrollTop = document.getElementById("chat_chat_chat").scrollHeight;
    }, 150);
  }


  sendPostRequest(newMessage: any) {

    let url = '/apif/bot';

    if (this.cacheService.get("conversationId") == null) this.cacheService.set("conversationId", new Date().getTime());

    let payload = {
      user_name: this.cacheService.get("user")?.user,
      machineId: this.cacheService.get("machineId"),
      question: newMessage?.content ?? '-',
      lang: this.translate.currentLang ?? 'en',
      timezone: "Europe/Rome",
      conversation_id: this.cacheService.get("conversationId"),
      id_button: newMessage?.buttonId,
    };

    this.messages.push({
      timestamp: moment().format("HH:mm:ss"),
      messageClasses: "my-message"
    });

    let _this = this;

    this.disableTextarea = true;

    _this.apiService.sendPostRequest(url, payload)
      .pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          _this.parseResponse(error, true);

          return [];
        }
        ))
      .subscribe(
        (data: any) => {
          // console.log(data.body);

          _this.parseResponse(_this.clonerService.deepClone(data.body));

        }
      );

  }

  sendPostRequestP(_this, url, payload) {
    return new Promise((res, rej) => {

      _this.apiService.sendPostRequest(url, payload)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {

            res({
              response: error ?? {},
              isError: true
            });
            return [];
          }
          ))
        .subscribe((data: any) => {
          res({
            response: data?.body ?? {},
            isError: false
          })
        });
    });
  }


  async parseResponse(parsedRequest: any, isError: any = false) {

    // console.log(parsedRequest);

    let response: any = this.clonerService.deepClone(parsedRequest);
    if (parsedRequest?.intent != 'print' && !isError) {

      if (parsedRequest?.intent == 'actStatus') parsedRequest = { ...parsedRequest, ...{ variables: this.machineProfile?.actualStatus ?? [] } };
      let url = '/apif/chatbot/' + this.cacheService.get("machineId");
      let additionalReq: any = await this.sendPostRequestP(this, url, parsedRequest);

      response = additionalReq?.response;
      isError = additionalReq?.isError;
    }

    let message: any = {
      timestamp: moment().format("HH:mm:ss"),
      messageClasses: "my-message",
      content: (isError ? this.translate.instant("BOT.ERROR") : response?.answer) ?? '-',
    };

    let customMessage: any = {};
    if (!isError) {
      switch (response?.intent ?? 'print') {
        case 'print':
          if (response?.buttons?.length > 0) customMessage = { buttons: response?.buttons ?? [] };
          break;
        default:
          if (response?.plotData != null && (Object.keys(response?.plotData?.data)?.length > 1 || response?.plotData?.data?.timeserie?.length > 0)) {

            if (response?.plotData?.config?.tableInfos?.length > 0 &&
              response?.plotData?.config?.tableInfos?.filter(info => !["timeStart", "timeEnd"].includes(info.variable)).every(info => response?.plotData?.data?.timeserie?.every(trace => trace[info?.variable] == null))) {
              customMessage = {
                content: this.translate.instant("BOT.NO_DATA_AVAILABLE"),
              };
              break;
            }
            customMessage = {
              type: response?.intent,
              plotData: response?.plotData,
            };
            break;
          }
          customMessage = {
            content: this.translate.instant("BOT.NO_DATA_AVAILABLE"),
          };
          break;

      }

    }

    this.messages[this.messages.length - 1] = { ...message, ...customMessage };
    this.disableTextarea = this.messages[this.messages.length - 1]?.buttons?.length > 0;
    this.isButtonPresent = this.messages[this.messages.length - 1]?.buttons?.length > 0;
    this.scrollBar();

  }

  openBotDialog(index: any, plotType: any = 'kpiTrend') {

    let currentMessage: any = this.clonerService.deepClone(this.messages?.[index] ?? {});

    let plotData = currentMessage?.plotData ?? {};

    let layout = {
      ...this.defaultPlotLayout,
      ...plotData?.config?.layout ?? {},
    };

    let plotParams = {
      ...this.defaultPlotParams,
      ...plotData?.config?.params ?? {},
    };

    try { plotData.config.layout = this.clonerService.deepClone(layout) }
    catch (error) { console.log(error) }

    try { plotData.config.plotParams = this.clonerService.deepClone(plotParams) }
    catch (error) { console.log(error) }

    let dashboardConfig = this.getDashboardConfigFromPlotType(plotType, plotData);

    let completeDashboardConfig = {
      dashboardData: plotData?.data,
      dashboardConfig: dashboardConfig,
      machineProfile: this.machineProfile,
    };

    this.isOpenedChat = false;

    this.dialog.open(FfDashboardDialogComponent, {
      panelClass: 'ff-dialog',
      width: "80%",
      height: "80%",
      data: {
        title: "GLOBAL.PLOT",
        completeDashboardConfig: completeDashboardConfig
      },
    });
  }

  getDashboardConfigFromPlotType(plotType: any, data: any) {

    // console.log(plotType);

    let customDashboard = this.dashboardConfig?.[plotType ?? 'default'] ?? {};

    let widgets = customDashboard?.widgets?.reduce((acc, widget) => {

      if (widget.type == 'ff-plotly-chart') {
        widget.config = {
          ...widget.config ?? {},
          ...this.defaultPlotAttributes,
          ...{ plotConfig: data?.config ?? {} },
        }
      }

      if (widget.type == 'ff-table-sortable') {
        widget.config = {
          ...widget.config ?? {},
          ...this.defaultTableConfig,
          ...{ tableInfos: data?.config?.tableInfos ?? [] },
        }
      }

      acc.push(widget);
      return acc;
    }, []);

    customDashboard = { ...customDashboard, ...{ widgets: widgets } };

    return customDashboard;

  }

  getDashboard(_this: any, machineId: any = null) {

    try {

      const sources40F = _this.appInfo.sources40F != null ? _this.appInfo.sources40F : 'assets/config/';
      let dashboardName1: any = (machineId != null ? (machineId + '_') : '') + 'dashboard-bot.json';

      _this.apiService.sendGetRequest(sources40F + dashboardName1).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          if (machineId != null) _this.attemptedDashboardSearchMachineId = machineId;

          if (!this.dashboardConfig) {

            let dashboardName2: any = 'dashboard-bot.json';
            _this.apiService.sendGetRequest(sources40F + dashboardName2).pipe(
              retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
              catchError(error => {

                return throwError("No file " + sources40F + dashboardName2);
              }))
              .subscribe(
                (data: any) => {

                  _this.dashboardConfig = data.body;
                },
              );
          }

          return throwError("No file " + sources40F + dashboardName1);

        }))
        .subscribe(
          (data: any) => {

            _this.dashboardConfig = data.body;
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      console.log(testError);

    }
  }

  ngOnInit() {
    this.getDashboard(this);
  }

  dragStart(event) {
    this.dragInProgress = true;
  }

  dragEnd(event) {
    setTimeout(() => this.dragInProgress = false, 100);
  }

}
