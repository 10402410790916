import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { FilterByPropertyPipe } from 'src/app/pipes/filterByProperty.pipe';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { DisplayImageDialogComponent } from '../display-image-dialog/display-image-dialog.component';

@Component({
  selector: 'ff-table-sortable',
  templateUrl: './ff-table-sortable.component.html',
  styleUrls: ['./ff-table-sortable.component.scss'],
  animations: [
    trigger('accordionArrowState', [
      state('false', style({ transform: 'rotate(0deg)' })),
      state('true', style({ transform: 'rotate(-180deg)' })),
      transition('* => true', animate('400ms ease-in')),
      transition('false <=> true', animate('400ms ease-in'))
    ])
  ]
})
export class FfTableSortableComponent implements OnInit, OnChanges {

  isMobile: boolean;

  @Input() tableConfig: any;
  @Output() tableConfigChange = new EventEmitter<any>();
  @Output() clicked = new EventEmitter<any>();
  @Output() inputTag = new EventEmitter<any>();
  @HostBinding('class') classes: any;

  tableConfigExample = {
    removeAnimations: false,    // boolean: true = remove animations from table
    hideNoData: false,          // boolean: true = hide "no data available" if table is empty
    isNestedTable: true,        // boolean: true = nested table. nestedTableConfig key is required if true
    fixedDesktopView: false,     // boolean: true = desktop table even if "isMobile"
    tableInfos: [],             // array of columns
    filterVariables: [],        // variables (properties of each row) filtables
    rows: [],                   // array of rows
    rowsFiltered: [],
    cardFrame: true,            // boolean true = card around table
    completeCardFrame: true,    // boolean true = card around table and surrounding div
    title: "GLOBAL.TITLE",      // string = optional title
    staticIcon: {               // static icon, same for each row
      type: 'icon',
      icon: 'home'
    },
    filters: {
      searchInput: true,        // boolean true = input search in component
      switch: {                 // != null filter rows
        title: '',              // switch title 
        condition: '',          // variable name assigned to a boolean value
        checked: false,
        checkedLabel: 'on',
        uncheckedLabel: 'off',
      },
    },
    paginator: {
      options: [25, 50, 100],   // options of rows for page
      size: 50,                 // number of rows for page
      position: 'bottom right'  // position of paginator row
    },
    sort: {
      sortVariables: [],        // variables (properties of each row) sortables
      variable: 'timeStart',    // variable to sort
      order: 'desc'             // order to sort
    },
    export: {                   // object, if present, table exportable
      buttonText: 'Export',     // string: text to show in export button
      filename: 'table'         // string: name of exported file
    },
    additionalButtons: [                        // optional - Array of objects: Buttons to add at the end of each row
      {
        clickFunction: "delete",                // string:  button id - (required)
        icon: "delete",                         // string:  button icon (mat-icon standard as of 24/09/2021) - (optional)
        defaultDisabled: true,                  // boolean: true if disabled by default, false otherwise - (optional)
        tooltip: "DATA_MAPPING.DELETE_MAPPING", // string:  tooltip string - (optional)
        class: "md-red-i rounded-button",       // string:  space separated classes to add - (optional)
        tdClass: "w50px"                        // string:  column classes (applied both to headers and rows) - (optional)

        // For each row, an optional parameter can be passed that allows to disable the button:
        // "disabledButtons": {
        //    "<button.clickFunction>": true/false // boolean: true if disabled, false if not (required)
        // }
      }
    ]
  };

  limits = [];
  paginatorTop: boolean = false;
  paginatorBottom: boolean = false;
  paginatorAlign: string = 'end center';
  paginatorAlignOrderSelector: number = 1;
  paginatorAlignOrderButtons: number = 2;
  paginatorOptions = [];
  paginatorSize: number;
  pageCounter: string;
  pageCounterMobile: string;
  tbodyFade = false;

  searchInput: boolean = false;
  searchText: string;

  exportTableConfig;

  notCollapsedItems: any[] = [];
  filterButtons: any[];

  appConfig: any;

  constructor(
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public clonerService: ClonerService,
    public internalDataService: InternalDataService,
    public appConfigService: AppConfigService,
    public cacheService: CacheService,
    public router: Router,
    public dialog: MatDialog,
  ) {
    this.isMobile = window.innerWidth <= 959;
    this.appConfig = this.appConfigService.getAppConfig;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobile = window.innerWidth <= 959;
  }

  ngOnInit() {
    // console.log('ngOnInit', this.tableConfig);

    if (this.tableConfig) {
      this.classes = this.tableConfig.completeCardFrame ? 'complete-card-frame' : null;
      // paginator
      if (this.tableConfig.paginator) {
        if (this.tableConfig.paginator.position) {
          this.paginatorTop = this.tableConfig.paginator.position.includes('top');
          this.paginatorBottom = this.tableConfig.paginator.position.includes('bottom');
          this.paginatorAlign = this.tableConfig.paginator.position.includes('left') ? 'start center' : 'end center';
          this.paginatorAlignOrderSelector = this.tableConfig.paginator.position.includes('left') ? 2 : 1;
          this.paginatorAlignOrderButtons = this.tableConfig.paginator.position.includes('left') ? 1 : 2;
        }
        if (this.tableConfig.paginator.options && this.tableConfig.paginator.size) {
          if (!this.paginatorOptions.length && !this.paginatorSize) {
            this.paginatorOptions = this.tableConfig.paginator.options;
            if (this.paginatorOptions.indexOf(this.tableConfig.paginator.size) != -1) this.paginatorSize = this.tableConfig.paginator.size;
            else this.paginatorSize = this.paginatorOptions[0];
          }
          if (!this.limits.length) {
            this.limits = [0, this.tableConfig.paginator.size];
            this.updatePageCounter();
          }
        }
      }
      // filters
      if (this.tableConfig.filters) {
        // search input
        if (this.tableConfig.filters.searchInput) {
          this.searchInput = true;
        }
      }
      //sort
      if (this.tableConfig.sort) {
        if (this.tableConfig.sort.variable && this.tableConfig.sort.order) {
          let info = this.tableConfig.tableInfos.find(x => x.variable === this.tableConfig.sort.variable);
          if (info) this.sortByProperty(info, true)
        }
        this.tableConfig.sort.sortVariables = this.tableConfig.tableInfos.filter((info) => !info.hideMobile).filter((info: any) => info.hasOwnProperty('orderBy'));
      } else {
        this.tableConfig.sort = {
          sortVariables: this.tableConfig.tableInfos.filter((info) => !info.hideMobile).filter((info: any) => info.hasOwnProperty('orderBy'))
        };
      }
      // export
      if (this.tableConfig.export) {
        this.exportTableConfiguration();
      }
      if (this.tableConfig.defaultTableInfos?.length == 0) {
        this.tableConfig.defaultTableInfos = this.clonerService.deepClone(this.tableConfig.tableInfos);
      }
      if (this.tableConfig.addFilterableColumns) {
        let filterButtons = [
          {
            variable: "variable",
            label: "GLOBAL.COLUMN_FILTER",
            type: "columnSelection"
          }
        ]
        this.filterButtons = this.filterService.buildFilterButtons(this, filterButtons, this.tableConfig.tableInfos, false);
      }
      // init slider
      if (this.tableConfig.filters?.slider) this.initSlider(this);

      this.filterRows(true);
    }
  }

  initSlider(_this) {
    try {
      let translateConfig = this.tableConfig.filters?.slider?.translate;
      this.tableConfig.sliderConf = {
        show: true,
        max: this.tableConfig.sliderConf?.max ?? this.tableConfig.filters?.slider?.max ?? 1,
        min: this.tableConfig.sliderConf?.min != null && !isNaN(this.tableConfig.sliderConf.min) ? this.tableConfig.sliderConf.min : (this.tableConfig.filters?.slider?.min ?? 0),
        options: {
          floor: this.tableConfig.filters?.slider?.min ?? 0,
          ceil: this.tableConfig.filters?.slider?.max ?? 1,
          step: this.tableConfig.filters?.slider?.step ?? 0.1,
          translate: (index) => {
            try { return _this.filterService.parseGaugeValue(index, translateConfig?.decimals ?? 0, translateConfig?.multiplier ?? 1) + (translateConfig?.unit ?? '') }
            catch (error) { return index }
          },
        },
        userChangeEnd: () => _this.filterRows()
      }
    } catch (error) { console.log(error) }
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log('ngOnChanges');
    // console.log('ngOnChanges', changes);

    // IMPORTANT: update only properties that could change on fly
    this.tableConfig.rows = changes.tableConfig.currentValue.rows;
    this.tableConfig.filters = changes.tableConfig.currentValue.filters;
    this.updatePageCounter();

    if (this.tableConfig.export) {
      this.exportTableConfiguration();
    }

    this.filterRows();
  }

  ngAfterViewChecked() {
    this.updatePageCounter();
  }

  firstPage() {
    this.tbodyFade = true;
    setTimeout(() => {
      this.tbodyFade = false;
      if (!(this.limits[0] - this.tableConfig.paginator.size >= 0)) return;
      this.limits = [0, this.tableConfig.paginator.size];
      this.updatePageCounter();
    }, 100);
  }

  previousPage() {
    this.tbodyFade = true;
    setTimeout(() => {
      this.tbodyFade = false;
      if (!(this.limits[0] - this.tableConfig.paginator.size >= 0)) return;
      this.limits = [this.limits[0] - this.tableConfig.paginator.size, this.limits[0]];
      this.updatePageCounter();
    }, 100);
  }

  nextPage() {
    this.tbodyFade = true;
    setTimeout(() => {
      this.tbodyFade = false;
      if (this.limits[1] + this.tableConfig.paginator.size > this.tableConfig.rowsFiltered.length + this.tableConfig.paginator.size) return;
      this.limits = [this.limits[1], this.limits[1] + this.tableConfig.paginator.size];
      this.updatePageCounter();
    }, 100);
  }

  lastPage() {
    this.tbodyFade = true;
    setTimeout(() => {
      this.tbodyFade = false;
      if (this.limits[1] + this.tableConfig.paginator.size > this.tableConfig.rowsFiltered.length + this.tableConfig.paginator.size) return;
      try {
        let numberOfPages = Math.floor(this.tableConfig.rowsFiltered.length / this.tableConfig.paginator.size);
        let elemsOfLastPage = this.tableConfig.rowsFiltered.length - numberOfPages * this.tableConfig.paginator.size;
        this.limits = [this.tableConfig.rowsFiltered.length - elemsOfLastPage, this.tableConfig.rowsFiltered.length];
        this.updatePageCounter();
      } catch (error) { console.log(error) }
    }, 100);
  }

  previousPageDisabled() {
    if (!this.tableConfig || !this.tableConfig.paginator || !this.tableConfig.paginator.size) return;
    return !(this.limits[0] - this.tableConfig.paginator.size >= 0);
  }

  nextPageDisabled() {
    if (!this.tableConfig || !this.tableConfig.paginator || !this.tableConfig.paginator.size) return;
    return (this.limits[1] + this.tableConfig.paginator.size >= this.tableConfig.rowsFiltered.length + this.tableConfig.paginator.size);
  }

  updatePageCounter() {
    if (!this.tableConfig || !this.tableConfig.paginator || !this.tableConfig.paginator.size || !this.tableConfig.rowsFiltered) return;
    let current = Math.ceil((this.limits[0] - 1) / this.tableConfig.paginator.size) + 1;
    let tot = Math.ceil(this.tableConfig.rowsFiltered.length / this.tableConfig.paginator.size);
    this.pageCounter = (this.limits[0] + 1) + "-" + (this.limits[1] > this.tableConfig.rowsFiltered.length ? this.tableConfig.rowsFiltered.length : this.limits[1]) + " / " +
      this.tableConfig.rowsFiltered.length + " - " + this.translate.instant("GLOBAL.PAGE_COUNTER", { x: current, y: tot });
    this.pageCounterMobile = current + '/' + tot;
  }

  updatePageSize(size) {
    this.tbodyFade = true;
    setTimeout(() => {
      this.tbodyFade = false;
      this.tableConfig.paginator.size = size;
      this.limits = [0, this.tableConfig.paginator.size];
      this.updatePageCounter();
    }, 250);
  }

  sortByPropertyId(infoOrderBy) {
    let info = this.tableConfig.tableInfos.find(x => x.orderBy === infoOrderBy);
    if (info) this.sortByProperty(info);
  }

  sortByProperty(info, sameDirection?) {
    // console.log('sortByProperty', info.orderBy);
    if (!info || info.orderBy == null) return;
    if (!this.tableConfig.sort) {
      this.tableConfig.sort = {
        variable: info.orderBy,
        order: 'desc'
      }
    }
    let discardNull = this.tableConfig?.sort?.avoidDiscardnull == null
    let property = info.orderBy;
    this.tableConfig.sort.variable = property;
    let sortAsc = property != this.tableConfig.sort.variable || this.tableConfig.sort.order == 'desc';
    if (!sameDirection) {
      this.tableConfig.sort.order = sortAsc ? 'asc' : 'desc';
      info.sorted = { down: !sortAsc };
    } else {
      this.tableConfig.sort.order = sortAsc ? 'desc' : 'asc';
      info.sorted = { down: sortAsc };
    }

    if (!this.tableConfig?.removeAnimations) {
      this.tbodyFade = true;
      setTimeout(() => {
        try {
          this.tbodyFade = false;
          if (this.tableConfig && this.tableConfig.paginator && this.tableConfig.paginator.size) {
            this.limits = [0, this.tableConfig.paginator.size];
            this.updatePageCounter();
          }
          this.tableConfig?.rowsFiltered?.sort(this.filterService.sortByProperty(property, this.tableConfig.sort.order, discardNull));
        } catch (error) { }
      }, 250);
    } else {
      try {
        if (this.tableConfig && this.tableConfig.paginator && this.tableConfig.paginator.size) {
          this.limits = [0, this.tableConfig.paginator.size];
          this.updatePageCounter();
        }
        this.tableConfig?.rowsFiltered?.sort(this.filterService.sortByProperty(property, this.tableConfig.sort.order, discardNull));
      } catch (error) { }
    }

    try { this.colorRowsById(this.tableConfig?.rowsFiltered, this.tableConfig?.sort) } catch (error) { }

    // this.notCollapsedItems = []
    // this.sortEmitter(this.tableConfig.sort)
  }

  sortRows(firstTime?) {
    // console.log('sortRows');
    if (firstTime) {
      if (this.tableConfig.sort) {
        if (this.tableConfig.sort.variable && this.tableConfig.sort.order) {
          let info = this.tableConfig.tableInfos.find(x => x.orderBy === this.tableConfig.sort.variable);
          if (info) this.sortByProperty(info, true)
        }
        this.tableConfig.sort.sortVariables = this.tableConfig.tableInfos.filter((info) => !info.hideMobile).filter((info: any) => info.hasOwnProperty('orderBy'));
      } else {
        this.tableConfig.sort = {
          sortVariables: this.tableConfig.tableInfos.filter((info) => !info.hideMobile).filter((info: any) => info.hasOwnProperty('orderBy'))
        };
      }
    } else {
      if (!this.tableConfig.sort || !this.tableConfig.sort.variable || !this.tableConfig.sort.order) {
        return;
      }
      let info = this.tableConfig.tableInfos.find(x => x.orderBy === this.tableConfig.sort.variable);
      if (info) this.sortByProperty(info, true);
    }
  }

  filterRows(firstTime?) {
    // console.log('filterRows', firstTime, this.tableConfig.filterVariables);
    let filtered: any = this.clonerService.deepClone(this.tableConfig.rows);
    if (!this.tableConfig.rows?.length) {
      this.tableConfig.rowsFiltered = filtered;
      return;
    }

    if (this.tableConfig.filterVariables?.length > 0) {
      // console.log('search', this.searchText)
      // searchText
      if (this.searchText != null && this.searchText != '') {

        if (this.tableConfig.isNestedTable) {

          try {
            filtered = filtered?.reduce((accFirst, firstLevel) => {

              // FIRST LEVEL CHECK - if a searched value is present in the main object values, stop the loop
              let firstLevelCheck = this.filterService.filterObjBySearch(firstLevel, this.searchText, this.tableConfig.filterVariables);
              if (firstLevelCheck) {
                accFirst.push(firstLevel);
                return accFirst;
              }

              let secondLevelKey = this.tableConfig.nestedTableConfig.variable;
              if (firstLevel?.[secondLevelKey]?.length > 0) {

                firstLevel[secondLevelKey] = firstLevel[secondLevelKey].reduce((accSecond, secondLevel) => {

                  // SECOND LEVEL CHECK - if a searched value is present in the values, stop the loop
                  let secondLevelCheck = this.filterService.filterObjBySearch(secondLevel, this.searchText, this.tableConfig.filterVariables);
                  if (secondLevelCheck) {
                    accSecond.push(secondLevel);
                    return accSecond;
                  }

                  if (this.tableConfig.nestedTableConfig?.isNestedTable && this.tableConfig.nestedTableConfig?.nestedTableConfig?.variable) {

                    let thirdLevelKey = this.tableConfig.nestedTableConfig.nestedTableConfig.variable;
                    if (secondLevel?.[thirdLevelKey]?.length > 0) {

                      secondLevel[thirdLevelKey] = secondLevel[thirdLevelKey].reduce((accThird, thirdLevel) => {

                        // THIRD LEVEL CHECK - if a searched value is present in the third level, stop the loop
                        let thirdLevelCheck = this.filterService.filterObjBySearch(thirdLevel, this.searchText, this.tableConfig.filterVariables);
                        if (thirdLevelCheck) {
                          accThird.push(thirdLevel);
                          return accThird;
                        }

                        return accThird;

                      }, []);

                      if (secondLevel[thirdLevelKey].length > 0) {
                        accSecond.push(secondLevel);
                        return accSecond;
                      }

                    }
                  }

                  return accSecond;

                }, []);

                if (firstLevel[secondLevelKey].length > 0) {
                  accFirst.push(firstLevel);
                  return accFirst;
                }
              }

              return accFirst;
            }, []);

          } catch (error) { console.log(error) }

        } else {
          filtered = this.filterService.filterBySearch(this.tableConfig.rows, this.searchText, this.tableConfig.filterVariables);
        }

      }
    }

    if (this.tableConfig.filters?.switch != null) {
      try {
        let condition = this.tableConfig.filters.switch?.condition;

        if (this.tableConfig.filters.switch.checked && condition != null) {
          // condition as array es. ["active", "!=", true]
          if (Array.isArray(condition) && condition.length > 0) {
            let filterPipe = new FilterByPropertyPipe();
            if (condition.every(x => Array.isArray(x) && x?.length == 3)) condition.forEach(c => filtered = filterPipe.transform(filtered, c));
            else if (condition.length == 3) filtered = filterPipe.transform(filtered, condition);
          }
          // condition as string es. "active"
          else {
            filtered = filtered.filter((x: any) => x[condition]);

            if (this.tableConfig?.isNestedTable) {
              filtered = filtered.reduce((acc, val) => {
                let secondLevelKey = this.tableConfig.nestedTableConfig.variable;
                val[secondLevelKey] = val[secondLevelKey].filter(x => x[condition]);
                acc.push(val);
                return acc;
              }, []);

              if (this.tableConfig?.nestedTableConfig?.isNestedTable) {
                filtered = filtered.reduce((acc, val) => {
                  let secondLevelKey = this.tableConfig.nestedTableConfig.variable;
                  val[secondLevelKey].forEach(third => {
                    let thirdLevelKey = this.tableConfig.nestedTableConfig.nestedTableConfig.variable;
                    third[thirdLevelKey] = third[thirdLevelKey].filter(x => x[condition]);
                  });
                  acc.push(val);
                  return acc;
                }, []);
              }
            }

          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    if (this.tableConfig.filters?.slider != null) {

      if (this.tableConfig?.sliderConf?.min != null && this.tableConfig?.sliderConf?.max != null) {

        let lowerVariable = this.tableConfig.filters.slider.lowerVariable ?? 'state';
        let upperVariable = this.tableConfig.filters.slider.upperVariable ?? 'state';

        if (this.tableConfig.isNestedTable) {

          try {
            filtered = filtered?.reduce((accFirst, firstLevel) => {

              // FIRST LEVEL CHECK - if a searched value is present in the main object values, stop the loop
              let firstLevelCheck = firstLevel[lowerVariable] >= this.tableConfig.sliderConf.min && firstLevel[upperVariable] <= this.tableConfig.sliderConf.max;
              if (firstLevelCheck) {
                accFirst.push(firstLevel);
                return accFirst;
              }

              let secondLevelKey = this.tableConfig.nestedTableConfig.variable;
              if (firstLevel?.[secondLevelKey]?.length > 0) {

                firstLevel[secondLevelKey] = firstLevel[secondLevelKey].reduce((accSecond, secondLevel) => {

                  // SECOND LEVEL CHECK - if a searched value is present in the values, stop the loop
                  let secondLevelCheck = secondLevel[lowerVariable] >= this.tableConfig.sliderConf.min && secondLevel[upperVariable] <= this.tableConfig.sliderConf.max;
                  if (secondLevelCheck) {
                    accSecond.push(secondLevel);
                    return accSecond;
                  }

                  if (this.tableConfig.nestedTableConfig?.isNestedTable && this.tableConfig.nestedTableConfig?.nestedTableConfig?.variable) {

                    let thirdLevelKey = this.tableConfig.nestedTableConfig.nestedTableConfig.variable;
                    if (secondLevel?.[thirdLevelKey]?.length > 0) {

                      secondLevel[thirdLevelKey] = secondLevel[thirdLevelKey].reduce((accThird, thirdLevel) => {

                        // THIRD LEVEL CHECK - if a searched value is present in the third level, stop the loop
                        let thirdLevelCheck = thirdLevel[lowerVariable] >= this.tableConfig.sliderConf.min && thirdLevel[upperVariable] <= this.tableConfig.sliderConf.max;
                        if (thirdLevelCheck) {
                          accThird.push(thirdLevel);
                          return accThird;
                        }

                        return accThird;

                      }, []);

                      if (secondLevel[thirdLevelKey].length > 0) {
                        accSecond.push(secondLevel);
                        return accSecond;
                      }

                    }
                  }

                  return accSecond;

                }, []);

                if (firstLevel[secondLevelKey].length > 0) {
                  accFirst.push(firstLevel);
                  return accFirst;
                }
              }

              return accFirst;
            }, []);

          } catch (error) { console.log(error) }

        } else {
          filtered = this.tableConfig.rows.filter(x => x[lowerVariable] >= this.tableConfig.sliderConf.min && x[upperVariable] <= this.tableConfig.sliderConf.max);
        }

      }
    }

    this.colorRowsById(filtered, this.tableConfig?.sort);

    this.tableConfig.rowsFiltered = filtered;


    if (firstTime) this.tableConfig.rowsFiltered.forEach(x => x.notCollapsed = false);
    else this.tableConfig.rowsFiltered.forEach(x => {
      if (this.notCollapsedItems.length > 0 && this.notCollapsedItems.some(y => y == JSON.stringify(x))) x.notCollapsed = true
    });

    // this.tableConfig.tableInfos.forEach(info => {
    //   if(info.type == 'icon') {
    //     this.tableConfig.rowsFiltered.forEach(row => {
    //       let obj = { 
    //         icon: {}
    //       }
    //       obj.icon = Array.isArray(info.icon) ? info.icon.find(x => x.id == row[info.variable]) : info.icon
    //       if(info.attribute && Array.isArray(info.iconConfig)) obj.icon[info.attribute] = info.iconConfig.find(x => x.id == row[info.attrVariable])[info.attribute]

    //       row[info.variable] = obj
    //     })
    //   }
    // })
    if (this.tableConfig.export) this.exportTableConfiguration();

    this.sortRows(firstTime);
  }

  colorRowsById(data, sortConfig?) {

    if (this.tableConfig.filters?.colorRowsById != null) {

      let config: any = this.clonerService.deepClone(this.tableConfig.filters?.colorRowsById ?? {});

      let i = 0;

      let colors = ["#F0F0F080", "#FFFFFF80"];
      if (this.appConfigService.getAppInfo?.darkTheme) colors = ['#182529', '#181b1e'];

      let uniqueIds = data?.sort(this.filterService.sortByProperty(sortConfig?.variable ?? config.variable ?? "timeStart", sortConfig?.order ?? "desc", true))?.reduce((acc, val) => {
        // let id = `${val.timeStart}.${val.timeEnd}`;
        let id = val?.[config.variable];
        if (acc.find(x => x.id == id) == null) {
          i++
          acc.push({
            id: id,
            color: i % 2 == 0 ? colors[0] : colors[1]
          });
        }
        return acc;
      }, []);

      return data?.reduce((acc, val) => {
        val.rowBgColor = uniqueIds?.find(x => x.id == val?.[config.variable])?.color;
        acc.push(val);
        return acc;
      }, []);

    }
  }

  exportTableConfiguration() {
    if (!this.tableConfig.export) return;

    let tableInfosCopy: any = this.tableConfig.defaultTableInfos ?? this.tableConfig.tableInfos;

    let exportConfig: any = this.clonerService.deepClone(this.tableConfig.export);

    // export file name
    let filename = this.translate.instant(exportConfig?.filename ?? 'GLOBAL.EXPORT');

    // Add export date
    if (exportConfig?.addDateToFileName) filename = filename + ' - ' + moment().format("YYYY/MM/DD_HH:mm:ss");

    // Add machine name
    if (this.tableConfig?.machine?.machineName) filename = filename + ' - ' + this.tableConfig?.machine?.machineName;

    // Export table config
    this.exportTableConfig = {
      title: filename,
      // type: 'pdf',
      payload: {
        translations: {},
        rows: []
      }
    };
    if (exportConfig?.buttonText) this.exportTableConfig.buttonText = exportConfig.buttonText;

    let actualRows: any = this.clonerService.deepClone(this.tableConfig?.rowsFiltered ?? this.tableConfig?.rows ?? []);

    if (exportConfig?.exportNestedTablesInSingleLine && this.tableConfig.isNestedTable) {

      try {
        let firstLevelKey = exportConfig.exportNestedTablesInSingleLine?.firstLevelKey ?? 'group';
        let firstLevelNestedKey = this.tableConfig.nestedTableConfig?.variable ?? 'subgroups';

        let obj = {};

        actualRows = actualRows.reduce((acc, firstLevel) => {

          // Add the value of the main key
          obj[firstLevelKey] = firstLevel?.[firstLevelKey];

          firstLevel?.[firstLevelNestedKey]?.forEach(secondLevel => {

            if (!this.tableConfig.nestedTableConfig?.isNestedTable) {
              acc.push({ ...secondLevel, ...obj })
            }

            // DOUBLE NESTED
            else {
              let secondLevelKey = exportConfig.exportNestedTablesInSingleLine?.secondLevelKey ?? 'subgroup';
              let secondLevelNestedKey = this.tableConfig.nestedTableConfig?.nestedTableConfig?.variable ?? 'components';

              // Add the value of the main key
              obj[secondLevelKey] = secondLevel?.[secondLevelKey];

              secondLevel?.[secondLevelNestedKey].forEach(thirdLevel => acc.push({ ...thirdLevel, ...obj }));
            }
          });
          return acc;
        }, []);
      } catch (error) { console.log(error) }

      // console.log({ actualRows });

    }

    try {
      let translations = {};
      let tableConfig = this.tableConfig;
      let profile = tableConfig?.profile ?? {};

      tableInfosCopy?.filter(x => !x.excludeDownload)?.forEach(info => {
        let unit = info?.suffix ?? info?.unit;

        // Unit from production config
        if (info.productionUnit != null && profile?.productionConfig != null) unit = this.filterService.getProductionUnit(info.productionUnit, profile.productionConfig, true);

        // Loop on profile array
        if (info?.parseFromProfileConfig && profile != null && tableConfig.additionalConfigParams?.loopOnProfileArray != null) {

          let loopProfileConfig: any = this.clonerService.deepClone(tableConfig?.additionalConfigParams?.loopOnProfileArray);

          let key = loopProfileConfig?.key ?? 'consumables';
          let profileArray: any = this.clonerService.deepClone(profile?.[key]) ?? [];

          profileArray?.forEach(elem => {

            let id = elem?.[loopProfileConfig?.idVariable ?? 'id'];
            let label = elem?.[loopProfileConfig?.labelVariable ?? 'label'];

            let unit = elem.unit;
            if (loopProfileConfig?.unitVariable != null) unit = elem?.[loopProfileConfig?.unitVariable ?? 'unit'];

            let newObj: any = {
              variable: info.variable?.replace("{{id}}", id),
              label: info.label?.replace("{{label}}", label ?? '-'),
              unit: info.unit?.replace("{{unit}}", unit ?? '-'),
            };

            translations[`${loopProfileConfig?.key}_${newObj.variable}`] = this.translate.instant(newObj.label ?? '-') + (newObj.unit != null ? (' [' + this.translate.instant(newObj.unit) + ']') : '');

          });

        }

        // Normal row
        else translations[info.variable] = this.translate.instant(info.label ?? '-') + (unit != null ? (' [' + this.translate.instant(unit) + ']') : '');

      });

      this.exportTableConfig.payload.translations = translations;

      let parsedRows = actualRows?.sort(this.filterService.sortByProperty(this.tableConfig.sort?.variable ?? 'timeStart', 'desc', true)).reduce((rows, row) => {

        let isMultiSingleCellList = false;
        let parsedRow = tableInfosCopy?.reduce((acc, info) => {

          // Exclude columns that have flag "excludeDownload"
          if (info?.excludeDownload) return acc;

          // Handling "singleCellList" (MFL - prod trace)
          if (info?.type == 'singleCellList' && row?.[info?.variable]?.length > 0) {
            acc[info.variable] = this.filterService.parseObjFromConfig(row?.[info?.variable][0], info, true);
            if (row?.[info?.variable]?.length > 1) isMultiSingleCellList = true;
            return acc;
          }

          // Loop on profile array
          if (info?.parseFromProfileConfig && profile != null && tableConfig.additionalConfigParams?.loopOnProfileArray != null) {

            let loopProfileConfig: any = this.clonerService.deepClone(tableConfig?.additionalConfigParams?.loopOnProfileArray);

            let key = loopProfileConfig?.key ?? 'consumables';
            let profileArray: any = this.clonerService.deepClone(profile?.[key]) ?? [];

            profileArray?.forEach(elem => {

              let id = elem?.[loopProfileConfig?.idVariable ?? 'id'];
              let label = elem?.[loopProfileConfig?.labelVariable ?? 'label'];

              let unit = elem.unit;
              if (loopProfileConfig?.unitVariable != null) unit = elem?.[loopProfileConfig?.unitVariable ?? 'unit'];

              let newObj: any = Object.assign(this.clonerService.deepClone(info), {
                variable: info.variable?.replace("{{id}}", id),
                label: info.label?.replace("{{label}}", label ?? '-'),
                unit: info.unit?.replace("{{unit}}", unit ?? '-'),
              });

              acc[`${loopProfileConfig?.key}_${newObj.variable}`] = this.filterService.parseObjFromConfig(row, newObj);

            });
            return acc;

          }

          // Parse value
          acc[info.variable] = this.filterService.parseObjFromConfig(row, info);
          return acc;
        }, {});

        rows.push(parsedRow);

        // If "singleCellList" (MFL) and length > 1, write multiple lines
        if (isMultiSingleCellList) {

          let firstColumnSingleCell = tableInfosCopy?.find(x => x.type == 'singleCellList');

          row?.[firstColumnSingleCell.variable]?.forEach((v, index) => {

            if (index > 0) {
              let parsedRow = tableInfosCopy?.reduce((acc, val) => {

                // Exclude columns that have flag "excludeDownload"
                if (val?.excludeDownload) return acc;

                // Handling "singleCellList" (MFL - prod trace)
                if (val?.type == 'singleCellList' && row?.[val?.variable]?.length > 0) {
                  acc[val.variable] = this.filterService.parseObjFromConfig(row?.[val?.variable][index], val, true);
                  if (row?.[val?.variable]?.length > 1) isMultiSingleCellList = true;
                  return acc;
                }

                // Parse value
                acc[val.variable] = this.filterService.parseObjFromConfig(row, val);
                return acc;
              }, {});

              rows.push(parsedRow);

            }

          })

        }

        return rows;

      }, []);

      this.exportTableConfig.payload.rows = parsedRows;

      this.tableConfig.parsedExport = this.clonerService.deepClone(this.exportTableConfig.payload);


    } catch (error) {
      console.log(error);
    }
  }

  toggleCollapse(item) {
    let obj = this.clonerService.deepClone(item)
    if (obj['notCollapsed'] != null) delete obj['notCollapsed']
    let stringItem = JSON.stringify(obj)
    let idx: number = this.notCollapsedItems.findIndex(x => x == stringItem)

    idx != -1 ? this.notCollapsedItems.splice(idx, 1) : this.notCollapsedItems.push(stringItem)

    item.notCollapsed = !item.notCollapsed;
  }

  switchChange(event: boolean) {
    try {
      this.tableConfig.filters.switch.checked = event;
      this.filterRows();
    } catch (error) { console.log(error) }
  }

  // If item has urlPath or urlQueryParams, navigate
  // navigateFromRow(item) { if (item?.urlPath != null) this.router.navigate(item.urlPath, { queryParams: item?.urlQueryParams }) }
  clickRow(item) {
    if (this.multiselect) {
      item.multiselected = !item.multiselected
    } else {
      if (item?.urlPath != null) this.router.navigate(item.urlPath, { queryParams: item?.urlQueryParams })
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  multiselect = false
  toggleMultiselect() {
    this.multiselect = !this.multiselect

    if (!this.multiselect) {
      this.tableConfig.rowsFiltered.forEach(x => x.multiselected = false)
    }
  }

  toggleAllMultiselect() {
    // TODO if paginator, action on item in page, not on all items in rowsFiltered
    if (this.tableConfig.rowsFiltered.some(x => x.multiselected == true)) {
      this.tableConfig.rowsFiltered.forEach(x => x.multiselected = false)
    } else {
      this.tableConfig.rowsFiltered.forEach(x => x.multiselected = true)
    }
  }

  // ADDITIONAL PARSING
  // Parse icon class
  getDynamicIconAttribute(col: any, row: any, attr: any = 'class') {
    if (attr != 'label' && col[attr] != null) return col[attr];
    else {
      // Icon config from a particular attribute in profile (default timeStates)
      if (col?.configFromProfile != null && col.iconConfig == null) col.iconConfig = this.clonerService.deepClone(this.tableConfig.profile?.[col.configFromProfile ?? 'timeStates']);
      // Find value in iconConfig
      if (col != null && col.iconConfig != null && Array.isArray(col.iconConfig) && col.iconConfig.length > 0) {
        if (col.attribute != attr && !col.attribute.includes(attr)) return null;
        let attribute = Array.isArray(col.attribute) ? col.attribute.find((x: any) => x == attr) : col.attribute
        let ix = col.iconConfig.findIndex(x => x[col.referenceIdKey ?? 'id'] == row[col.attrVariable]);
        if (ix != -1) return col.iconConfig[ix][attribute ?? 'class'];
      }
    }
    return attr == 'class' ? 'md-gray-i-2' : (attr == 'label' ? '' : 'inherit');
  }

  mapFromProfile(info: any, item: any) {

    let v = this.filterService.mapFromProfile(info, item, this.tableConfig.profile);
    // let mappingKey = info?.mappingConfig?.key ?? 'timeStates';
    // if (typeof mappingKey === 'string' && mappingKey.includes('.')) {
    //   mappingKey = mappingKey.split('.');
    // }

    // let container = [];
    // if (Array.isArray(mappingKey)) {
    //   container = this.tableConfig?.profile;
    //   for (let m_key of mappingKey) {
    //     container = container?.[m_key];
    //   }
    // }
    // else {
    //   container = this.tableConfig?.profile?.[mappingKey];
    // }

    // let v = container?.find(x => x?.[info?.mappingConfig?.idKey ?? 'id'] == item?.[info?.variable])?.[info?.mappingConfig?.outputKey ?? 'label'];

    // let v = this.tableConfig?.profile?.[info?.mappingConfig?.key ?? 'timeStates']?.find(x => x?.[info?.mappingConfig?.idKey ?? 'id'] == item?.[info?.variable])?.[info?.mappingConfig?.outputKey ?? 'label'];
    if (v == null) return null;
    try { return info?.mappingConfig?.translate ? this.translate.instant(v ?? item?.[info?.variable] ?? '-') : (v ?? item?.[info?.variable]) }
    catch (error) { return v ?? item?.[info?.variable] }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // EVENT EMITTERS

  // INPUT TAG CHANGE
  onInputTagChange(completeRows) { this.inputTag.emit(completeRows) }

  openInNew(link) { window.open(link, '_blank') }

  toggleExpandRow(item) {
    let action = !item.expandedNesting;
    item.expandedNesting = action;
    if (this.tableConfig?.nestedTableConfig?.isNestedTable) {
      try { item?.[this.tableConfig?.nestedTableConfig?.variable].forEach(x => x.expandedNesting = action) }
      catch (error) { console.log(error) }
    }
  }

  toggleCheckbox(item, info, nestedTableIndex) {
    // console.log("toggleCheckbox");

    let variable = info?.variable;
    let action = !item[variable];
    item[variable] = action;
    if (this.tableConfig?.nestedTableConfig?.isNestedTable) {
      try {
        if (nestedTableIndex == 1 && this.tableConfig.nestedTableConfig?.nestedTableConfig?.variable) {
          item?.[this.tableConfig.nestedTableConfig?.nestedTableConfig?.variable]?.forEach(c => c[variable] = action);
        } else if (nestedTableIndex == 0) {
          item?.[this.tableConfig?.nestedTableConfig?.variable]?.forEach(x => {
            x[variable] = action;
            if (this.tableConfig.nestedTableConfig?.nestedTableConfig?.variable) {
              x?.[this.tableConfig.nestedTableConfig?.nestedTableConfig?.variable]?.forEach(c => c[variable] = action);
            }

          })
        }
      }
      catch (error) { console.log(error) }
    }
  }

  collapseAll() {
    if (this.tableConfig?.isNestedTable) {
      try { this.tableConfig.rowsFiltered.forEach(item => item.expandedNesting = false) }
      catch (error) { console.log(error) }
    }
  }

  expandAll() {
    if (this.tableConfig?.isNestedTable) {
      try {
        this.tableConfig.rowsFiltered.forEach(item => {
          item.expandedNesting = true;
          if (this.tableConfig?.nestedTableConfig?.isNestedTable) {
            try { item?.[this.tableConfig?.nestedTableConfig?.variable].forEach(x => x.expandedNesting = true) }
            catch (error) { console.log(error) }
          }
        });
      }
      catch (error) { console.log(error) }
    }
  }

  openImage(item, info) {

    let title = item?.[info?.imageVariable];

    if (info?.imageTitleType != null) {
      if (info?.imageTitleType == 'advancedTranslate') {
        title = this.advancedTranslate(info?.advancedTranslateConfig, item);
      }
    }

    this.dialog.open(DisplayImageDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: title,
        imageUrl: this.parseImageSource(item, info)
      }
    });

  }

  parseImageSource(item, info) {

    let image = null;
    // Image name directly in config
    if (info?.imageRegex == null) image = 'assets/images/components/' + item[info.imageVariable];
    // Image with additional regexes
    else image = info.imageRegex?.replace("{image}", item[info.imageVariable])?.replace("{machineId}", this.cacheService.get("machineId"));

    return image;
  }

  advancedTranslate(config, item) {
    try {
      let conf = config ?? {};

      let machineId = this.cacheService.get("machineId");

      let name: any = "";

      if (conf.enableCustomVariables) {

        let c_customVariables = this.cacheService.get("customVariables");

        // Cached custom translations
        if (this.appConfig?.addCustomVariables && c_customVariables?.machineId == machineId && c_customVariables?.value != null) {

          let aspectName = item?.[conf.aspect];
          aspectName = (aspectName != null && typeof aspectName == 'string') ? aspectName.replace(/\$\$\$/g, ' - ') : aspectName;
          let varName = aspectName;
          if (!conf?.excludeVariableKeyUsingVariables && conf.variable != null) {
            varName += `.${conf.variable}`;
          }

          let codeConfig = this.internalDataService.buildTranslationsFromVariablesFile(conf.key, varName, c_customVariables?.value, this.appConfigService.getDefaultCustomVariables, conf.idVariable ?? 'id');

          if (conf.parseObjFromConfig) name = this.filterService.parseObjFromConfig(item?.[conf.variable], { ...codeConfig }, true, 'type', true);
          else name = codeConfig?.[conf.outputKey ?? 'label'];

          if (conf.translateValue && typeof name == 'object') name = name?.[this.translate.currentLang] ?? name;

          if (conf.addUnit && codeConfig?.unit != null) name += ' [' + this.translate.instant(codeConfig?.unit ?? '-') + ']';

          return name;

        }

        let c_customTranslations = this.cacheService.get("customTranslations");

        // Cached custom translations
        if (c_customTranslations != null && c_customTranslations?.machineId == machineId && c_customTranslations?.value != null && this.appConfig?.addCustomTranslations) {

          let aspectName = item?.[conf.aspect];
          aspectName = (aspectName != null && typeof aspectName == 'string') ? aspectName.replace(/\$\$\$/g, ' - ') : aspectName;
          let varName = aspectName + (conf.variable != null ? ('.' + conf.variable) : '');

          let codeConfig = this.internalDataService.buildTranslationsFromFile(conf.key, varName, c_customTranslations?.value, this.appConfigService.getDefaultCustomTranslations);
          name = codeConfig?.label;

          if (conf.addUnit && codeConfig?.unit != null) name += ' [' + this.translate.instant(codeConfig?.unit ?? '-') + ']';

          return name;

        }
      }

      return this.internalDataService.parseLabelWithAssetId(conf.key, item?.[conf.aspect], conf.variable, machineId);

    } catch (error) {
      return config?.key ?? '-';
    }
  }

  onFiltersDialogSelect() {
    let cloneTableInfos: any = this.clonerService.deepClone(this.tableConfig.defaultTableInfos);
    this.tableConfig.tableInfos = this.filterButtons[0].options?.reduce((acc, val) => {
      try {
        let info = cloneTableInfos.find(x => x.variable == val?.id);
        info.hideInTable = !val?.selected;
        acc.push(info);
      } catch (error) {
        console.log(error)
      }
      return acc;
    }, []);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // USEFUL FUNCTIONS

  checkSplittableItem(item, info) {
    try { return item[info.variable]?.split(info.variableToSplit)?.length > 0 }
    catch (error) { return false }
  }
  transformTypeToListType(info) {
    return this.clonerService.deepClone({ ...info, ...{ type: info.listType } })
  }

  transformListValueToObject(variableName, value) {
    let obj: any = {};
    obj[variableName] = value;
    return obj;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // CLICK EMIT
  log(item, info, nestedTableIndex = 0, multiselect = false) {
    let items = []
    if (this.multiselect) {
      this.tableConfig.rowsFiltered.forEach(x => { if (x.multiselected) items.push(x) })
    }

    this.clicked.emit({
      row: item,
      rows: items,
      buttonInfos: info,
      nestedTableIndex: nestedTableIndex,
      tableInfos: this.tableConfig?.tableInfos,
      tableConfig: this.clonerService.deepClone(this.tableConfig),
    });
  }

}
