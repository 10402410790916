<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    [fxLayoutAlign]="pageState.value >= 5 ? 'start stretch' : (pageState.value == 0 ? 'start center' : 'center center')"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar -->
    <div *ngIf="aggregationChangeovers?.length > 0 || availableMachines?.list?.length > 0" class="ff-toolbar pb-3 pt-1"
      fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <span fxFlex fxShow="false" fxShow.gt-sm></span>

      <!-- aggregationChangeovers dropdown -->
      <div *ngIf="!isMobile && aggregationChangeovers?.length > 0" class="aggregation-selector" fxLayout="column"
        fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

        <div class="box" fxLayout="row" fxLayoutAlign="start center">
          <!-- <mat-icon class="icon">event_available</mat-icon> -->
          <mat-form-field appearance="fill">
            <mat-label>{{ 'GLOBAL.DROPDOWN_AGGREGATION_SELECTION' | translate }}</mat-label>
            <mat-select [(ngModel)]="aggrDropdown" name="aggr" style="text-transform: capitalize;">
              <!-- <mat-option (click)="changePageAggregation(aggrDropdown)">---</mat-option> -->

              <mat-option *ngFor="let aggr of aggregationChangeovers" [value]="aggr.id"
                (click)="changePageAggregation(aggrDropdown)">

                <span style="text-transform: capitalize;">{{ aggr.label }}</span>

              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <ff-available-machines-selection *ngIf="availableMachines?.list?.length > 0"
        [availableMachines]="availableMachines" (selected)="machineSelectionChange($event)">
      </ff-available-machines-selection>

    </div>

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 6" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <ff-dashboard *ngIf="dashboardData != null && pageState.value >= 6"
      [completeDashboardConfig]="completeDashboardConfig">
    </ff-dashboard>

  </div>