import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-scrap-analytics',
  templateUrl: './scrap-analytics.component.html',
  styleUrls: ['./scrap-analytics.component.scss']
})
export class LeanScrapAnalyticsComponent implements OnInit, OnDestroy {

  public isAllowedUser: boolean = true;

  public loadingData: any;
  public errorData: any;
  public searchEvents: any;

  public appConfig: any;
  public appInfo: any;
  public isMobile: any;
  public machineProfiles: any;

  public productionColumns: string[] = [];

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public pollingTime: any;
  public pollingEvents: any;

  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;

  public interval: any;
  public intervalConfig: any;

  public intervalAggregations: any;
  public aggregationsTime: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public dashboardConfig: any;

  public scrapAnalyticsData: any;

  public aggrId: string = "";
  public aggrList: any;

  public tabName: any = "scrapAnalytics";
  public sectionName: any = "leanAnalytics";
  public dashboardName: any = "lean-scrap-analytics";
  public dashboardNameComplete: any;

  public colorArray: any;
  public scrapsConfig: any;

  public eventsColumns: any = [];
  public events: any = {
    list: [],
    filtered: [],
    pageOptions: [25, 50, 100],
    pageSize: 50,
  };

  public eventsData = new MatTableDataSource<any[]>();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set MatSort(ms: MatSort) {
    this.sort = ms;
    this.setSort();
  }

  setSort() {
    this.eventsData.sort = this.sort;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3.5, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3.5,
      codes: [
        { code: 300, function: this.initCardConfig, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getDataPolling, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService
  ) {
    let innerWidth = window.innerWidth;
    this.isMobile = innerWidth <= 959 ? true : false
    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['LEAN_ANALYTICS.TITLE', 'SCRAP_ANALYTICS.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs('leanAnalytics');
    this.availableMachines = this.appConfig?.[this.tabName]?.availableMachines ?? [];

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig.leanAnalytics.pollingAlarms;
    this.pollingEvents = Subscription;

    // this.aggrList = this.appConfig.scrapAnalytics?.aggrList ?? [];
    // this.aggrId = this.appConfig.scrapAnalytics?.aggrListDefault;

    this.interval = this.intervalService.getIntervalById('last30Days');

    this.intervalConfig = {
      list: this.intervalService.getDefaultIntervals(2),
      selected: this.interval
    };

    this.scrapAnalyticsData = null

  }

  openAggrDialog(aggr: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        // console.log('afterClosed', result);
        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
      }

      this.pageState.next(isClickedSelect ? 5 : 6);

      if (!this.interval.enabledPolling) {
        this.getData(this, 0);
      } else {
        this.getDataPolling(this);
      }

    });
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-la-scraps");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs('leanAnalytics');
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'leanAnalytics');
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getDataPolling, this.getData, this.machine.timezone) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval aggr
  selectAggregation(aggr: any) {

    if (aggr != null) {

      try {
        this.pollingEvents.unsubscribe();
      } catch (error) {
        // console.log(error)
      }

      this.intervalAggregations.selected = aggr;

      this.pageState.next(5);
      this.getDataPolling(this);

    }
  }

  machineSelectionChange(machine: any) {

    this.filterService.filterAggregationsByMachine(this, machine);

    if (machine != null) {

      try {
        this.pollingEvents.unsubscribe();
      } catch (error) {
      }

      this.pageState.next(5);
      this.getDataPolling(this);

    }
  }

  initCardConfig(_this) {

    try {

      _this.tableInfos = _this.appConfig.scrapAnalytics.tableInfos;

      _this.aggregations?.forEach((aggr: any) => {
        let idx = _this.tableInfos.findIndex((x: any) => x.variable == aggr.id);
        if (idx == -1) {
          _this.tableInfos.push({
            variable: aggr.id,
            label: _this.translate.instant(aggr.label),
            // hideInExportPdf: true,
            suffix: null
          });
        }
      });

      _this.eventsColumns.push('icon');
      _this.tableInfos.forEach((element: any) => {
        _this.eventsColumns.push(element.variable);
      });

    } catch (error) {
      console.log(error);
      _this.tableInfos = [];
    }
    _this.dispatcherService.getDispatch(_this, 300);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // aggregation id
  // updateAggrId(aggrId: any) {
  //   this.aggrId = aggrId;
  //   this.productionColumns = [];
  //   this.leanAnalyticsInfo.forEach((element: any) => {
  //     if (!element.offset || (element.offset && element.offset.includes(this.aggrId))) {
  //       this.productionColumns.push(element.variable);
  //     } 
  //   });
  // }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // events

  // polling
  getDataPolling(_this: any) {
    try {

      if (_this.cacheService.get("intervalLong") == null) {

        _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.cacheService.set("intervalLong", _this.intervalConfig);

      } else {
        _this.intervalConfig = _this.cacheService.get("intervalLong");
        _this.interval = _this.cacheService.get("intervalLong").selected;
      }

      if (_this.pollingTime > 0) _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => _this.getData(_this, count));
      else _this.getData(_this, 0);

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getData(_this: any, count?: any) {
    try {

      if (count == 0 && _this.intervalAggrList?.length > 0) _this.internalDataService.forceAggregationList(_this, _this.intervalAggrList);

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone,
        unit: _this.intervalAggregations.selected.unit != null ? _this.intervalAggregations.selected.unit : 'hour',
        value: _this.intervalAggregations.selected.value != null ? _this.intervalAggregations.selected.value : 1,
      };

      if (_this.interval != null && !_this.interval.enabledPolling) query.to = _this.interval.end;

      let url = '/apif/production-analytics/scrap-analytics/' + _this.machineId;

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      _this.filterService.filterAggregationsByMachine(_this, query?.machineId);

      try {
        if (_this.machine.profile.tables != null) {
          _this.eventTypes = _this.clonerService.deepClone(
            _this.machine.profile.tables.reduce((acc, val) => {
              if (!val?.showInScrapAnalytics) return acc;
              let obj = {
                selected: !val.defaultDisabled,
                label: _this.translate.instant(val.label),
                enabled: true,
                disabled: false,
                id: val.eventId,
                value: val.label,
              };
              acc.push(obj);
              return acc;
            }, [])
          );

          _this.parsedTablesCopy = _this.clonerService.deepClone(_this.machine.profile.tables?.filter(x => x.showInScrapAnalytics));
          payload.tables = _this.parsedTablesCopy;

        }
      } catch (error) {
        console.log(error);
      }

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
        )
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.scrapAnalyticsData = _this.parseData(_this, data.body);

            try {

              _this.scrapAnalyticsColors = _this.appConfig.scrapAnalytics?.scrapColors ?? _this.internalDataService.defaultBreakdownsColors;

              _this.colorArray = [];

              try {
                if (data.body.scraps != null && Object.keys(data.body.scraps).length > 0) {
                  _this.colorArray = Object.keys(data.body.scraps).map((x: any, idx: any) => {
                    return {
                      id: x,
                      color: _this.scrapAnalyticsColors[idx]
                    }
                  });
                }
              } catch (error) {
                console.log(error);
              }

              _this.scrapAnalyticsData.dataConfig = {};
              _this.scrapAnalyticsData.aggrDataConfig = {};

              // set plot data and configs
              _this.scrapAnalyticsData.dataConfig.title = _this.internalDataService.buildExportFileTitle(_this.breadcrumb, _this.interval);
              _this.scrapAnalyticsData.dataConfig.plotDataAttribute = 'plotData';
              // _this.scrapAnalyticsData.aggrDataConfig.plotDataAttribute = 'aggrPlotData';

              _this.scrapAnalyticsData.plotData = _this.buildPlotConfig(_this.scrapAnalyticsData);
              // _this.scrapAnalyticsData.aggrPlotData = _this.buildAggrPlotConfig( _this.scrapAnalyticsData);

              _this.buildPlotConfig(_this.scrapAnalyticsData);

              // set table data and config
              _this.scrapAnalyticsData.tableData = _this.buildTableData(_this, _this.scrapAnalyticsData);
              _this.scrapAnalyticsData.dataConfig.tableDataAttribute = 'tableData';
              _this.scrapAnalyticsData.dataConfig.orderAttribute = _this.appConfig.scrapAnalytics.orderAttribute != null ? _this.appConfig.scrapAnalytics.orderAttribute : 'perc';

              _this.scrapAnalyticsData.tableInfo = _this.clonerService.deepClone(_this.appConfig.scrapAnalytics.scrapInfo);

              _this.scrapAnalyticsData.tableColumns = [];
              _this.scrapAnalyticsData.tableInfo.forEach((element: any) => {
                _this.scrapAnalyticsData.tableColumns.push(element.variable);
              });

              try {
                if (_this.aggrDropdown != null) {
                  if (_this.scrapAnalyticsData.tableInfo.findIndex((x: any) => x.variable == _this.aggrDropdown) == -1) _this.scrapAnalyticsData.tableInfo.push({
                    variable: _this.aggrDropdown,
                    orderBy: _this.aggrDropdown,
                    label: _this.translate.instant(_this.aggregations.find((x: any) => x.id == _this.aggrDropdown).label).capitalize(),
                  });
                  if (!_this.scrapAnalyticsData.tableColumns.includes(_this.aggrDropdown)) _this.scrapAnalyticsData.tableColumns.push(_this.aggrDropdown);
                }
              } catch (error) {
                console.log(error);
              }

              // console.log(_this.scrapAnalyticsData)


            } catch (error) {
              console.log(error);
            }

            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseData(_this: any, data: any) {
    try {
      if (data != null && Object.keys(data).length > 0) {
        return data;
      }
      return {};

    } catch (error) {
      console.log(error);
      return {};
    }
  }

  buildTableData(_this: any, data: any) {
    let tableData = [];
    let totalNum = 0;
    try {
      if (data.scraps) {
        for (let id in data.scraps) {
          let tot = data.scraps[id];
          totalNum += tot;
        }
        for (let id in data.scraps) {
          let tot = data.scraps[id];
          tableData.push({
            scrapId: id,
            // scrapLabel: this.internalDataService.parseLabelWithAssetId("SCRAP_REASONS", id, null, this.machineId),
            scrapLabel: this.internalDataService.getCustomVariableConfig(id, null, 'scrapReasons', this.machineId)?.label,
            total: tot,
            perc: tot / totalNum
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
    return tableData;
  }

  buildPlotConfig(data: any) {

    let traces: any = [];

    // CUMULATIVE + TREND PLOT
    if (data?.timeserie?.scraps != null && Object.keys(data.timeserie.scraps)?.length > 0) {
      for (let [scrapId, scrapTS] of Object.entries(data.timeserie.scraps)) {

        if (!Array.isArray(scrapTS) || scrapTS.length == 0 || scrapId == 'timestamp') continue;

        let color = null;
        let label = scrapId;
        let scrapConfig = this.colorArray.find((scrap: any) => scrap.id == scrapId);

        if (scrapConfig) {
          color = scrapConfig.color;
          // label = this.internalDataService.parseLabelWithAssetId("SCRAP_REASONS", scrapConfig.id, null, this.machineId);
          label = this.internalDataService.getCustomVariableConfig(scrapConfig.id, null, 'scrapReasons', this.machineId)?.label;
        }

        // TREND TRACE
        traces.push({
          x: data.timeserie.timestamps,
          y: scrapTS,
          name: label,
          hoverinfo: "x+y+name",
          traceType: 'line',
          visible: false,
          line: {
            shape: 'spline',
            color: color,
            width: 3
          },
          connectgaps: true,
        });

        // CUMULATIVE TRACE
        let totSum = 0;
        traces.push({
          x: data.timeserie.timestamps,
          y: scrapTS.map((x: any) => totSum += x),
          name: label,
          hoverinfo: "x+y+name",
          traceType: 'area',
          visible: true,
          stackgroup: 'one',
          line: {
            shape: 'spline',
            color: color,
            width: 3
          },
          connectgaps: true,
        });

      };
    }

    // PARETO + PIE CHART
    let scrapRangeX = null;
    if (data.scraps != null && Object.keys(data.scraps)?.length > 0) {

      let sortedScraps = Object.entries(data.scraps).sort((first: any, second: any) => { return second[1] - first[1]; });
      let scrapIds = sortedScraps.map((scrap: any) => scrap[0]);
      let scrapVals = sortedScraps.map((scrap: any) => scrap[1]);

      let colors = scrapIds.map((scrapId: any) => this.colorArray.find((scrap: any) => scrap.id == scrapId)?.color);
      // let scrapLabels = scrapIds.map((scrapId: any) => this.internalDataService.parseLabelWithAssetId("SCRAP_REASONS", scrapId, null, this.machineId));
      let scrapLabels = scrapIds.map((scrapId: any) => this.internalDataService.getCustomVariableConfig(scrapId, null, 'scrapReasons', this.machineId)?.label);

      let totCumSum = 0;
      // update hover text
      let hoverText = sortedScraps.map((x: any) => {

        let scrapId = x[0];
        let scrapCount = x[1];

        let hover = '';

        hover += scrapId + '<br>';

        hover += "<b>" + this.translate.instant('BREAKDOWNS.DESCRIPTION') + "</b>: ";
        hover += this.internalDataService.getCustomVariableConfig(scrapId, null, 'scrapReasons', this.machineId)?.label;
        hover += '<br>';

        hover += "<b>" + this.translate.instant('BREAKDOWNS.COUNT') + "</b>: ";
        hover += scrapCount + '<br>';

        totCumSum += (scrapCount ? scrapCount / data.totBad : 0);
        if (totCumSum != null) {
          hover += "<b>" + this.translate.instant('BREAKDOWNS.CUMULATIVE_PERCENTAGE') + "</b>: ";
          hover += this.filterService.parseGaugeValue(totCumSum, 1, 100) + '%<br>';
        }
        return hover;
      });

      // PARETO TRACE BARS
      traces.push({
        x: scrapLabels,
        y: scrapVals,
        width: data.scraps.length < 3 ? 0.4 : null,
        type: 'bar',
        hoverinfo: 'text',
        text: hoverText,
        visible: false,
        marker: {
          color: colors,
        },
        traceType: 'pareto',
        name: this.translate.instant("BREAKDOWNS.COUNT")
      })

      // PARETO TRACE PERCENTAGE LINE
      let totSum = 0;
      traces.push({
        x: scrapLabels,
        y: data.totBad ? scrapVals.map((x: any) => totSum += (x ? x / data.totBad : 0)) : scrapVals.map(() => null),
        yaxis: 'y2',
        type: 'scatter',
        visible: false,
        traceType: 'pareto',
        hoverinfo: 'skip',
        marker: {
          color: 'black',
        },
        line: {
          color: 'black',
        },
        name: this.translate.instant("BREAKDOWNS.CUMULATIVE_PERCENTAGE")
      });

      if (data.scraps.length > 0 && data.scraps.length < 20) {
        scrapRangeX = [-0.5, data.scraps.length - 0.5];
      } else if (data.scraps.length >= 20) {
        scrapRangeX = [-0.5, 19.5];
      } else {
        scrapRangeX = [-0.5, 0.5];
      }

      // PIE CHART
      this.scrapAnalyticsData.pieChartData = {
        traces: [
          {
            values: Object.values(data.scraps),
            labels: Object.keys(data.scraps)?.map(x => this.internalDataService.getCustomVariableConfig(x, null, 'scrapReasons', this.machineId)?.label),
            marker: { colors: Object.keys(data.scraps)?.map((scrapId: any) => this.colorArray.find((scrap: any) => scrap.id == scrapId)?.color) },
            type: 'pie',
            hole: 0.5
          }
        ],
        layout: {
          margin: {
            t: 20,
            r: 20,
            b: 20,
            l: 20,
            pad: 0
          },
          showlegend: false
        }
      };

    }

    // BOTTONI 1 CUMULATIVE, 2 PARETO, 3 TREND
    let updatemenus: any = [{
      buttons: [
        {
          args: [
            {
              'visible': traces.map((x: any) => x.traceType == 'area'),
              'mode': 'lines+markers',
            },
            {
              'yaxis2.visible': false,
              'xaxis.range': null,
              'xaxis.ticktext': null,
              'xaxis.type': null,
            }
          ],
          label: this.translate.instant("SCRAP_ANALYTICS.CUMULATIVE"),
          method: 'update'
        },
        {
          args: [
            {
              'visible': traces.map((x: any) => x.traceType == 'pareto'),
              'mode': 'lines+markers',
            },
            {
              'yaxis2.visible': true,
              'xaxis.range': scrapRangeX,
              'xaxis.ticktext': data.scraps != null && Object.keys(data.scraps).length > 0 ? Object.keys(data.scraps) : [],
              'xaxis.type': 'category',
            }
          ],
          label: this.translate.instant("SCRAP_ANALYTICS.PARETO"),
          method: 'update'
        },
        {
          args: [
            {
              'visible': traces.map((x: any) => x.traceType == 'line'),
              'mode': 'lines+markers',
            },
            {
              'yaxis2.visible': false,
              'xaxis.range': null,
              'xaxis.ticktext': null,
              'xaxis.type': null,
            }
          ],
          label: this.translate.instant("SCRAP_ANALYTICS.TREND"),
          method: 'update'
        }
      ],
      direction: 'left',
      // pad: {
      //   'r': 10,
      //   't': 10
      // },
      showactive: true,
      type: 'buttons',
      x: 0.0,
      y: 1.3,
      xanchor: 'left',
      yanchor: 'top'
    }];

    let plotLayout = {
      updatemenus: updatemenus,
      xaxis: {
        showgrid: false,
        zeroline: false,
        // type: 'category'
      },
      yaxis: {
        zeroline: false,
        showgrid: false,
        title: {
          text: this.translate.instant("GLOBAL.TOTAL_BAD") + " [" + this.filterService.getProductionUnit("totProdAct", this.machine.profile.productionConfig) + ']',
        },
        rangemode: "tozero"
      },
      yaxis2: {
        zeroline: false,
        showgrid: false,
        visible: false,
        overlaying: 'y',
        side: 'right',
        tickformat: '%',
        range: [0, 1.05]
      },
      margin: {
        t: 0,
        r: 60,
        b: 0,
        l: 60,
        pad: 0
      },
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: -0.3
      }
    };

    // console.log({ plotLayout });
    // console.log({ traces });
    let _this = this;

    // _this.hiddenEventsMsg = false;
    if (data.hasOwnProperty('table') && data.table != null &&
      Array.isArray(data.table) && data.table.length > 0) {

      let parsedTable: any = [];

      // if (data.hiddenEvents) {
      //   _this.hiddenEventsMsg = _this.translate.instant('CYCLE_TIMELINE.HIDDEN_EVENTS_WARNING', { shown: data.table.length, tot: data.hiddenEvents })
      // }

      data.table.filter((x: any, index: any) => index < 100000).forEach((event: any) => {
        // data.table.forEach((event: any) => {
        // console.log(event);
        event = _this.internalDataService.getEventInfo(_this, event);
        event.timestampP = moment(event.timestamp).format("DD MMM YYYY HH:mm:ss.SSS");
        event.durationP = event.duration != null ? _this.filterService.parseTime(event.duration, 's', 'HH:mm:ss') : null;
        // event.eventName = event.eventId;
        // event.description = "Descrizione casuale";
        parsedTable.push(event);
      });


      _this.events.list = _this.clonerService.deepClone(parsedTable);

      _this.aggregations?.forEach((aggr: any, aggrIdx: any) => {
        let c_aggrList = _this.cacheService.get(aggr.id + "List");
        if (c_aggrList == null) {
          let ll: any = _this.clonerService.deepClone(_this.events.list.map((x: any) => x[aggr.id]).filter(_this.filterService.onlyUnique));
          _this[aggr.id + "List"] = ll.map((x: any) => {
            return {
              enabled: true,
              id: x,
              value: x
            };
          });
        } else {
          _this[aggr.id + "List"] = _this.clonerService.deepClone(c_aggrList);
        }

      });

    } else {
      try {
        _this.events.list = [];
      } catch (error) {
        console.log(error);
      }
    }

    _this.filterEvents();

    return {
      layout: plotLayout,
      traces: traces
    };

  }

  filterEvents() {

    try { this.events.query.page = 1 } catch (error) { }

    let filtered: any = this.clonerService.deepClone(this.events.list);


    // Filter by search input description
    let filterVariables = ['description'];
    try {
      filtered = filtered.filter((data: any) => filterVariables.some((x: any) => {
        if (this.searchEvents == null || this.searchEvents == '') return true;
        if (typeof data[x] == 'string') return String(data[x]).toLowerCase().includes(this.searchEvents.toLowerCase());
        else if (Array.isArray(data[x])) return data[x].some((y: any) => Object.values(y).some((z: any) => String(z).toLowerCase().includes(this.searchEvents.toLowerCase())))
      }));
    } catch (error) {
      console.log(error);
    }

    // console.log('events filtered', filtered);

    this.events.filtered = filtered;

    const cd = new MatTableDataSource<any[]>();

    cd.sort = this.sort;
    cd.paginator = this.paginator;
    cd.data = filtered;

    this.eventsData = cd;
  }

  filterSearchEvents(searchEvents: any) {
    this.searchEvents = searchEvents;
    this.filterEvents();
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pageState.unsubscribe(); } catch (error) { }
    try { this.pollingEvents.unsubscribe(); } catch (error) { }
    try { this.machineSelectedSub.unsubscribe(); } catch (error) { }
  }

}