import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';

declare var Plotly: any;

function hexToRgb(hex: string) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}

function getRgbString(rgbObj: any, opacity: any = null) {
  if (!opacity) return `rgb(${rgbObj.r},${rgbObj.g},${rgbObj.b})`
  return `rgba(${rgbObj.r},${rgbObj.g},${rgbObj.b},${opacity})`
}

@Component({
  selector: 'app-fault-analysis',
  templateUrl: './fault-analysis.component.html',
  styleUrls: ['./fault-analysis.component.scss'],
})
export class LeanFaultAnalysisComponent implements OnInit, OnDestroy {

  public isAllowedUser: boolean = true;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public isMobile: any;
  public isSmThanTablet: any;
  public mobileListener: Subscription;
  public machineProfiles: any;

  public backButton: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public pollingTime: any;
  public pollingEvents: any;

  public maxStringsLength: any;
  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;
  public aggrPlotTitle: any;

  public excludeLine: boolean = true;

  public interval: any;
  public intervalConfig: any;

  public intervalAggregations: any;

  public dashboardConfig: any;

  public breakdownsData: any;
  public faultData: any;
  public breakdownsColors: any;
  public breakdownsColorsRGB = {};

  public mobileData: any;
  public currentSortingProperty: any;
  public sortDirection: any;
  public collapsed: any;

  public tabName: any = "faultAnalysis";
  public sectionName: any = "leanAnalytics";
  public dashboardName: any = "breakdowns-alarms";
  public colorsArrayName: any = "alarmsColors";
  public tablePropName: any = "alarmsInfo";
  public pollingPropName: any = "pollingAlarms";
  public dashboardNameComplete: any;

  public selectedCodes: Array<any> = [];
  public selectedCodesColor: any = {};
  public requiredCodes: Array<any> = [];
  public codeKeys: any = [];
  public codeKey: any = 'code';

  public referenceTables: any = [];
  public referenceTable: any = 'alarms';
  public faultTraces: any = [];

  public selectedProp: any = 'duration';

  public colorPath: any = 0;
  public maxFaultsSelectable: any = 4;

  public filterButtons: any;
  public filterButtonsConfig: any = [
    {
      variable: "alarmCode",
      label: "ALARMS.CODE",
      type: "advancedTranslate",
      config: {
        maxSel: this.maxFaultsSelectable,
      },
      advancedTranslationType: "alarm",
      advancedTranslateConfig: {
        key: "message"
      }
    }
  ];

  public updatemenus: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getEventsBreakdowns, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.buildParetoConfig, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.getData, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    public mobile: MobileService
  ) {

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile;
      this.isSmThanTablet = value.isSmThanTablet;
    })

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['LEAN_ANALYTICS.TITLE', 'BREAKDOWNS.TITLE'];
    this.internalDataService.setCalendarPage(true);
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.availableMachines = this.appConfig?.[this.tabName]?.availableMachines ?? [];

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig?.[this.tabName]?.[this.pollingPropName] ?? 0;
    this.pollingEvents = Subscription;

    this.resetTraces();
    this.breakdownsData = null;
    this.faultData = null;
    this.breakdownsColors = ["#FF5757", "#f9cb9c", "#b45f06", "#711111"];
    this.breakdownsColorsRGB = {};
    for (let color of this.breakdownsColors) {
      this.breakdownsColorsRGB[color] = hexToRgb(color);

    }

    this.selectedCodesColor = {};
    this.selectedProp = 'duration';

    this.updatemenus = [{
      buttons: [{
        args: [{
          'visible': []
        }, {
          'yaxis.ticksuffix': ' h',
          'id': 'duration'
        }],
        label: this.translate.instant("BREAKDOWNS.DURATION"),
        method: 'update'
      }, {
        args: [{
          'visible': []
        }, {
          'yaxis.ticksuffix': null,
          'id': 'count'
        }],
        label: this.translate.instant("BREAKDOWNS.COUNT"),
        method: 'update'
      }],
      direction: 'left',
      pad: {
        'r': 10,
      },
      showactive: true,
      type: 'buttons',
      x: 0,
      y: 1.2,
      xanchor: 'left',
      yanchor: 'top'
    }];

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // OPEN AGGR DIALOG

  openAggrDialog(aggr: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines?.selected,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {

        this.resetTraces();

        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);

        this.pageState.next(5);

        if (!this.interval.enabledPolling) this.getEvents(this, 0)
        else this.getEventsBreakdowns(this)
      }


    });
  };


  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    _this.referenceTable = [];

    _this.dashboardConfig.widgets = [
      {
        "type": "ff-table-sortable",
        "flex": 100,
        "config": {
          rowsVariable: "table",
          // paginator: {
          //   options: [25, 50, 100],   // options of rows for page
          //   size: 50,                 // number of rows for page
          //   position: 'bottom right'  // position of paginator row
          // },
          cardFrame: true,
          completeCardFrame: true,
          sort: {
            sortVariables: [],        // variables (properties of each row) sortables
            variable: 'timeStart',    // variable to sort
            order: 'desc'             // order to sort
          },
          // export: {                   // object, if present, table exportable
          //   buttonText: 'Export',     // string: text to show in export button
          //   filename: 'table'         // string: name of exported file
          // },
          tableInfos: [
            {
              variable: "codeP",
              orderBy: "code",
              label: "BREAKDOWNS.ALARM_CODE"
            },
            {
              variable: "msgP",
              orderBy: "msgP",
              label: "BREAKDOWNS.ALARM_MESSAGE"
            },
            {
              variable: "duration",
              orderBy: "duration",
              label: "BREAKDOWNS.DURATION",
              type: "time"
            },
            {
              variable: "count",
              orderBy: "count",
              label: "BREAKDOWNS.COUNT"
            },
            {
              variable: "mat",
              orderBy: "mat",
              label: "BREAKDOWNS.MAT",
              type: "time"
            }
          ]
        }

      },
      // {
      //   "type": "ff-value",
      //   "title": "BREAKDOWNS.TOTAL_STOPS",
      //   "flex": 50,
      //   "config": [
      //     {
      //       "variable": "stopsNumber",
      //       "decimals": 0,
      //       "iconClass": "md-red",
      //       "icon": {
      //         "icon": "cancel",
      //         "type": "icon"
      //       }
      //     }
      //   ]
      // },
      // {
      //   "type": "ff-value",
      //   "title": "BREAKDOWNS.DOWNTIME",
      //   "flex": 50,
      //   "config": [
      //     {
      //       "variable": "downtime",
      //       "format": "time",
      //       "iconClass": "md-primary",
      //       "icon": {
      //         "icon": "timer_quarter",
      //         "type": "svg"
      //       }
      //     }
      //   ]
      // }
    ]

    _this.referenceTable = 'alarms';

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-la-breakdowns");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) {
    this.resetTraces();

    this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getEventsBreakdowns, this.getEvents, this.machine.timezone);

  };

  selectAggregation(aggr: any) {

    if (aggr != null) {

      try { this.pollingEvents.unsubscribe() } catch (error) { }

      this.intervalAggregations.selected = aggr;

      this.faultTraces = []
      this.requiredCodes = this.selectedCodes;

      this.pageState.next(7);
      this.getData(this);

    }
  }

  machineSelectionChange(machine: any) {

    this.filterService.filterAggregationsByMachine(this, machine);

    if (machine != null) {

      try { this.pollingEvents.unsubscribe() } catch (error) { }

      this.resetTraces();

      this.pageState.next(5);
      this.getEventsBreakdowns(this);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // events

  // polling
  getEventsBreakdowns(_this: any) {
    try {

      if (_this.cacheService.get("intervalBreakdowns") != null) {

        _this.interval = _this.cacheService.get("intervalBreakdowns");

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.backButton = [_this.machineId, "machine-supervision", "state-timeline-advanced"];
        _this.internalDataService.setBackButton(_this.backButton);

      } else {
        if (_this.cacheService.get("intervalLong") == null) {

          _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

          _this.intervalConfig = {
            list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
            selected: _this.interval
          };

          _this.cacheService.set("intervalLong", _this.intervalConfig);

        } else {
          _this.intervalConfig = _this.cacheService.get("intervalLong");
          _this.interval = _this.cacheService.get("intervalLong").selected;
        }
      }

      if (_this.pollingTime > 0) {
        _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getEvents(_this, count);
        });
      } else {
        _this.getEvents(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getEvents(_this: any, count?: any) {
    try {

      let url = '/apif/production-analytics/breakdowns/' + _this.machineId;

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone,
        codeKey: _this.codeKey ?? 'code',
        referenceTable: _this.referenceTable,
        // aggrUnit: _this.aggrDropdown
      };

      if (_this.aggrDropdown != null) query.aggrUnit = _this.aggrDropdown;
      if (_this.machine.profile?.additionalTags?.["lean-analytics/breakdowns"] != null) query.tag = _this.machine.profile?.additionalTags?.["lean-analytics/breakdowns"];

      if (_this.interval != null && !_this.interval.enabledPolling) query.to = _this.interval.end;

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      _this.filterService.filterAggregationsByMachine(_this, query.machineId);

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {

            _this.breakdownsData = _this.parseBreakdownsData(_this, data.body);

            try {

              try {
                if (_this.appConfig?.[_this.tabName]?.[_this.colorsArrayName] &&
                  _this.appConfig?.[_this.tabName]?.[_this.colorsArrayName]?.length > 0)
                  _this.breakdownsColors = _this.appConfig?.[_this.tabName]?.[_this.colorsArrayName];
              } catch (error) { console.log(error) }

              _this.colorArray = [];

              try {

                let customColors = null;
                if (_this.aggrDropdown != null) customColors = _this.aggregations?.find((x: any) => x.id == _this.aggrDropdown)?.colors;

                if (customColors != null) {
                  _this.colorArray = (Object.entries(customColors) as any)?.reduce((acc, [aggrName, color]) => {
                    acc.push({
                      id: aggrName,
                      color: color
                    });
                    return acc;
                  }, []);
                }

                else {
                  _this.colorArray = _this.aggrDropdown != null ? _this.breakdownsData[_this.aggrDropdown].map((x: any) => x.id).filter(_this.filterService.onlyUnique).map((x: any, idx: any) => {
                    return {
                      id: x,
                      color: _this.breakdownsColors[idx]
                    }
                  }) : [];
                }
              } catch (error) {
                console.log(error);
              }

              _this.breakdownsData.dataConfig = {};
              _this.breakdownsData.aggrDataConfig = {};

              // set plot data and configs
              _this.breakdownsData.dataConfig.title = _this.internalDataService.buildExportFileTitle(_this.breadcrumb, _this.interval, 'DD/MM/YYYY HH:mm');
              _this.breakdownsData.dataConfig.plotDataAttribute = 'plotData';
              _this.breakdownsData.aggrDataConfig.plotDataAttribute = 'aggrPlotData';

              // _this.breakdownsData.plotData = _this.buildPlotConfig(_this, _this.breakdownsData);

            } catch (error) {
              console.log(error);
            }

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseBreakdownsData(_this: any, data: any) {
    try {

      if (data != null && Object.keys(data).length > 0) {
        if (data.faults != null && data.faults.length > 0) {
          data.faults.forEach((x: any) => {
            x.selected = false;
            x.codeP = _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'code', _this.machineId);
            x.msgP = x.message ?? _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'message', _this.machineId);
            x.durationP = _this.filterService.parseTime(x.duration, 's', 'HH:mm:ss');
          });
        } else {
          data.faults = [];
        }

        return data;
      }
      return {};

    } catch (error) {
      console.log(error);
      return {};
    }
  }

  buildParetoConfig(_this: any) {

    let data = _this.breakdownsData;
    let aggrId: any = _this.aggrDropdown;

    // let colorArray = aggrId != null ? data.faults.map((x: any) => x[aggrId]).filter((val: any, id: any, array: any) => array.indexOf(val) == id) : [];

    let traces: any = [];

    if (!data.hasOwnProperty('faults') || data.faults == null || data.faults.length == 0) {
      _this.pageState.next(6);
      _this.dispatcherService.getDispatch(_this, 300);
      return {
        layout: {},
        traces: []
      };
    }

    let range = null;

    let durationProp = 'duration';
    let sortingPropertyList = [durationProp, 'count'];
    // for each property
    sortingPropertyList.forEach((prop) => {

      // sort events
      data.faults = data.faults.sort(_this.filterService.sortByProperty(prop, 'desc', true)).filter((x: any) => x[prop] != null && x[prop] != 0);

      // count total of events property
      let totSum = 0;
      data.faults.forEach((x: any) => totSum += x[prop]);

      // calculate cumulative percentage
      data.faults.forEach((x: any, idx: any) => {
        try {
          x.cumPerc = !isNaN(x[prop] / totSum + data.faults[idx - 1].cumPerc) ? x[prop] / totSum + data.faults[idx - 1].cumPerc : 0;
        } catch (error) {
          x.cumPerc = x[prop] / totSum;
        }
      });

      // update hover text
      let hoverText = data.faults.map((x: any) => {
        let hover = '';
        x.mat = null;
        if (x.alarmCode != null) {
          hover += _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'code', _this.machineId) + '<br>';
          hover += "<b>" + _this.translate.instant('BREAKDOWNS.DESCRIPTION') + "</b>: ";
          hover += x.message ?? _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'message', _this.machineId);
          hover += '<br>';
        }
        if (x.count != null) {
          hover += "<b>" + _this.translate.instant('BREAKDOWNS.COUNT') + "</b>: ";
          hover += x.count + '<br>';
          x.mat = x.count;
        }
        if (x.duration != null) {
          hover += "<b>" + _this.translate.instant('BREAKDOWNS.DURATION') + "</b>: ";
          hover += _this.filterService.parseTime(x.duration, 's', 'HH:mm:ss') + '<br>';
          if (x.mat) {
            x.mat = _this.filterService.parseTime(x.duration / x.mat, 's', 'HH:mm:ss');
            hover += "<b>" + _this.translate.instant('BREAKDOWNS.MAT') + "</b>: ";
            hover += x.mat + '<br>';
          }
        }
        if (x.cumPerc != null) {
          hover += "<b>" + _this.translate.instant('BREAKDOWNS.CUMULATIVE_PERCENTAGE') + "</b>: ";
          hover += _this.filterService.parseGaugeValue(x.cumPerc, 1, 100) + '%<br>';
        }
        if (aggrId != null && x[aggrId] != null) {
          hover += "<b>" + _this.translate.instant(_this.aggregations.find((x: any) => x.id == _this.aggrDropdown).label).capitalize() + "</b>: ";
          hover += x[aggrId];
        }
        return hover;
      });

      if (_this.selectedCodes.length == 0) {
        let alarmCode = _this.cacheService.get("selectedFault");
        let idx = data.faults?.findIndex(x => x.alarmCode == alarmCode);
        if (idx == -1) {
          idx = 0;
          alarmCode = data.faults[0].alarmCode
        }

        _this.requiredCodes = [alarmCode];
        _this.selectedCodes = [alarmCode];
        data.faults[idx].selected = true;
        _this.selectedCodesColor[alarmCode] = _this.breakdownsColors[0];
        _this.cacheService.set("selectedFault", null);
      }

      let _marker = {};
      if (_this.colorPath) {

        let colorArray = data.faults.map((x: any) => {
          let _selected = _this.selectedCodes.includes(x.alarmCode)
          if (_selected) return getRgbString(_this.breakdownsColorsRGB[_this.selectedCodesColor[x.alarmCode]], 0.3);
          else return _this.breakdownsColors[0];
        })

        let lineColorArray = data.faults.map((x: any) => {
          let _selected = _this.selectedCodes.includes(x.alarmCode)
          if (_selected) return getRgbString(_this.breakdownsColorsRGB[_this.selectedCodesColor[x.alarmCode]], 1);
          else return _this.breakdownsColors[0];
        })

        _marker = {
          line: { width: 3, color: lineColorArray },
          color: colorArray
        }

      } else {

        let colorArray = data.faults.map((x: any) => {
          let _selected = _this.selectedCodes.includes(x.alarmCode);
          if (_selected) return _this.selectedCodesColor[x.alarmCode];
          else return _this.breakdownsColors[0];
        });

        let opacityArray = data.faults.map((x: any) => {
          let _selected = _this.selectedCodes.includes(x.alarmCode);
          if (_selected) return 1;
          else return 0.3;
        });

        _marker = {
          opacity: opacityArray,
          color: colorArray
        }

      }

      traces.push({
        x: data.faults.map((x: any) => aggrId != null ? x.alarmCode + '__' + x[aggrId] : x.alarmCode),
        y: data.faults.map((x: any) => prop == durationProp ? (x[prop] != null ? x[prop] / 3600 : null) : x[prop]),
        width: data.faults.length < 3 ? 0.4 : null,
        marker: _marker,
        type: 'bar',
        id: prop,
        hoverinfo: 'text',
        text: hoverText,
        visible: prop == _this.selectedProp,
        traceType: prop,
        showlegend: false,
        name: prop == durationProp ? _this.translate.instant("BREAKDOWNS.DURATION") : _this.translate.instant("BREAKDOWNS.COUNT")
      });

      // set range
      if (data.faults.length > 0 && data.faults.length < 30) {
        range = [-0.5, data.faults.length - 0.5];
      } else if (data.faults.length >= 30) {
        range = [-0.5, 29.5];
      } else {
        range = [-0.5, 0.5];
      }
      // range = [-0.5, data.faults.length - 0.5];

    });

    let _tickettext = data.faults.map((x: any) => _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'code', _this.machineId) != null ? _this.internalDataService.parseAlarmsLabel(x.alarmCode, 'code', _this.machineId).toString().substring(0, (_this.maxStringsLength != null ? _this.maxStringsLength : 15)) : null);

    let maxTicketLen = 0;
    _tickettext.forEach((ticket: any) => { if (ticket && ticket.length > maxTicketLen) maxTicketLen = ticket.length })
    let plotLayout: any = {
      // updatemenus: updatemenus,
      legend: {
        x: 0,
        y: -0.25,
        orientation: 'h',
        traceorder: 'normal'
      },
      hoverlabel: { align: 'left' },
      xaxis: {
        showgrid: false,
        zeroline: false,
        type: 'category',
        range: range,
        tickangle: 45,
        ticktext: _tickettext,
        tickvals: data.faults.map((x: any) => aggrId != null ? x.alarmCode + '__' + x[aggrId] : x.alarmCode),
      },
      yaxis: {
        showgrid: false,
        zeroline: false,
        ticksuffix: (_this.selectedProp == 'duration') ? ' h' : null,
        rangemode: "tozero"
      },

      margin: {
        t: 20,
        r: 60,
        b: maxTicketLen + 60,
        l: 60,
        pad: 5
      },
    };

    let dragLayers: any = document.getElementsByClassName('nsewdrag');

    let completeFaults = _this.clonerService.deepClone(data.faults);
    _this.breakdownsData.table = _this.parseAlarmsTable(completeFaults);

    _this.breakdownsData.plotData = {
      layout: plotLayout,
      traces: traces,
      params: {
        displayModeBar: false,
        plotId: 'pareto',
        actions: {
          plotly_click:
            function (data: any) {
              _this.alarmCode = data.points[0].x;
              let fault = _this.breakdownsData.faults.find((_fault: any) => _fault.alarmCode == _this.alarmCode);
              if (_this.selectedCodes.includes(fault.alarmCode)) {

                if (_this.selectedCodes.length == 1) return;

                let faultTrendDiv: any = document.getElementById('faultTrend');
                let faultTrendData = faultTrendDiv.data;
                let tracesToDelete = [];

                faultTrendData.forEach((traceData: any, index: number) => {
                  if (traceData.id == _this.alarmCode) tracesToDelete.push(index);
                })
                // let traceNumber = 0;

                Plotly.deleteTraces('faultTrend', tracesToDelete);
                let codeIdx = _this.selectedCodes.indexOf(fault.alarmCode);
                _this.selectedCodes.splice(codeIdx, 1);
                _this.requiredCodes = [];
                fault.selected = false;

                _this.filterButtons = _this.filterService.buildFilterButtons(_this, _this.filterButtonsConfig, _this.breakdownsData.faults.sort(_this.filterService.sortByProperty("duration", "desc", true)), false, 'selected', true);
                delete _this.selectedCodesColor[fault.alarmCode];

                _this.breakdownsData.table = _this.parseAlarmsTable(completeFaults);
                _this.updatePareto(_this);

                _this.pageState.next(6);
                _this.dispatcherService.getDispatch(_this, 300);

              } else {

                if (_this.selectedCodes.length >= _this.maxFaultsSelectable) return;

                fault.selected = true;
                _this.selectedCodes.push(fault.alarmCode);
                _this.requiredCodes = [fault.alarmCode];
                _this.updatePareto(_this);

                _this.breakdownsData.table = _this.parseAlarmsTable(completeFaults);

                _this.pageState.next(6);
                _this.dispatcherService.getDispatch(_this, 300);

              }
            },
          plotly_hover:
            function (data: any) {
              let _cursor = 'not-allowed';
              if (_this.selectedCodes.length < _this.maxFaultsSelectable || _this.selectedCodes.includes(data.points[0].x)) _cursor = 'pointer';
              for (let i = 0; i < dragLayers?.length; i++) {
                try { dragLayers[i].style.cursor = _cursor } catch (error) { }
              }
            },
          plotly_unhover:
            function (data: any) {
              for (let i = 0; i < dragLayers?.length; i++) {
                try { dragLayers[i].style.cursor = '' } catch (error) { }
              }
            }
        }
      }
    };

    _this.dispatcherService.getDispatch(_this, 300);

  }

  parseAlarmsTable(data) {

    let parsedTable = data?.reduce((acc, val) => {

      if (this.selectedCodes?.includes(val?.alarmCode) && acc.find(x => x.alarmCode == val?.alarmCode) == null) {
        val.mat = (val.duration > 0 && val.count > 0) ? (val.duration / val.count) : null;
        delete val.selected;
        acc.push(val);
      }
      return acc;

    }, []);

    return parsedTable;
  }

  onFiltersDialogSelect(_filter: any) {

    this.selectedCodes = _filter.options.filter((_alData: any) => _alData.selected).map((_alData: any) => _alData.id);
    this.requiredCodes = this.selectedCodes;
    this.selectedCodesColor = {};
    this.faultTraces = [];

    this.breakdownsData.faults.forEach((fault: any) => fault.selected = this.selectedCodes.includes(fault.alarmCode));

    let completeFaults = this.clonerService.deepClone(this.breakdownsData.faults);
    this.breakdownsData.table = this.parseAlarmsTable(completeFaults);

    this.updatemenus[0].buttons[0].args[0].visible = [];
    this.updatemenus[0].buttons[1].args[0].visible = [];

    this.updatePareto(this);

    this.pageState.next(6);
    this.dispatcherService.getDispatch(this, 300);
  }

  updatePareto(_this: any) {

    let plotDiv: any = document.getElementById('pareto');

    let plotsData = plotDiv.data;
    let traceNumber = 0;

    for (let code of _this.selectedCodes) {
      if (!_this.selectedCodesColor.hasOwnProperty(code)) {
        let unavailableColors = Object.values(_this.selectedCodesColor);
        let _color = _this.breakdownsColors.find((color: any) => !unavailableColors.includes(color));
        _this.selectedCodesColor[code] = _color;
      }
    }

    for (let traceData of plotsData) {

      let _marker = {};
      if (this.colorPath) {

        let colorArray = traceData.x.map((code: any) => {
          if (_this.selectedCodes.includes(code)) return getRgbString(this.breakdownsColorsRGB[_this.selectedCodesColor[code]], 0.3);
          else return _this.breakdownsColors[0];
        })

        let lineColorArray = traceData.x.map((code: any) => {
          if (_this.selectedCodes.includes(code)) return getRgbString(this.breakdownsColorsRGB[_this.selectedCodesColor[code]], 1);
          else return this.breakdownsColors[0];
        })

        _marker = {
          line: { width: 3, color: lineColorArray },
          color: colorArray
        }

      } else {

        let colorArray = traceData.x.map((code: any) => {
          if (_this.selectedCodes.includes(code)) return _this.selectedCodesColor[code];
          else return _this.breakdownsColors[0];
        })

        let opacityArray = traceData.x.map((code: any) => {
          if (_this.selectedCodes.includes(code)) return 1;
          else return 0.3;
        })

        _marker = {
          opacity: opacityArray,
          color: colorArray
        }

      }

      // var update = {marker:{color:  colorArray, opacity: opacityArray, line: {width:1.5, color: lineColorArray}}};
      var update = { marker: _marker };
      Plotly.restyle('pareto', update, [traceNumber]);
      traceNumber++
    }
  }

  getDataPolling(_this: any) {
    try {

      if (_this.cacheService.get("intervalLong") == null) {

        _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.cacheService.set("intervalLong", _this.intervalConfig);

      } else {
        _this.intervalConfig = _this.cacheService.get("intervalLong");
        _this.interval = _this.cacheService.get("intervalLong").selected;
      }

      if (_this.pollingTime > 0) {
        _this.pollingProds = timer(0, _this.pollingTime).subscribe(count => _this.getData(_this, count));
      } else {
        _this.getData(_this, 0)
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }


  getData(_this: any, count?: any) {

    try {

      if (_this.breakdownsData?.faults && _this.breakdownsData.faults.length > 0) {

        if (_this.requiredCodes.length > 0) {

          _this.faultData = {};
          _this.filterButtons = _this.filterService.buildFilterButtons(_this, _this.filterButtonsConfig, _this.breakdownsData.faults.sort(_this.filterService.sortByProperty("duration", "desc", true)), false, 'selected', true);

          let url = `/apif/production-analytics/fault-analysis/${_this.machineId}`;

          let payload = _this.internalDataService.buildMachinePayload(_this.machine);
          payload['faults'] = _this.requiredCodes;
          _this.internalDataService.buildAggregationsPayload(_this);
          payload.filters = _this.aggregationsPayload;

          let query: any = {
            from: _this.interval.start,
            unit: _this.intervalAggregations.selected?.unit ?? 'day',
            value: _this.intervalAggregations.selected?.value ?? 1,
            tz: _this.machine.timezone,
            // codeKey: _this.codeKey ?? 'code',
            // referenceTable: _this.referenceTable,
          };

          if (_this.aggrDropdown != null) query.aggrUnit = _this.aggrDropdown;
          if (_this.machine.profile?.additionalTags?.["lean-analytics/breakdowns"] != null) query.tag = _this.machine.profile?.additionalTags?.["lean-analytics/breakdowns"];

          if (_this.interval != null && !_this.interval.enabledPolling) {
            query.to = _this.interval.end;
          }

          _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);
          _this.filterService.filterAggregationsByMachine(_this, query.machineId);
          _this.apiService.sendPostRequest(url, payload, query)
            .pipe(
              retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
              catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
            .subscribe(
              (data: any) => {

                _this.faultData.dataConfig = {};

                // set plot data and configs
                _this.faultData.dataConfig.title = _this.internalDataService.buildExportFileTitle(_this.breadcrumb, _this.interval);
                _this.faultData.dataConfig.plotDataAttribute = 'plotData';
                _this.faultData.dataConfig.fixedDesktopView = true;
                _this.buildFaultConfig(_this, data.body);

                _this.dispatcherService.getDispatch(_this, 300);

              }
            );
        } else {
          setTimeout(function () {
            _this.dispatcherService.getDispatch(_this, 300)
          }, 10);
        }

      } else {
        _this.faultData = {
          dataConfig: {},
          plotConfig: {
            layout: {},
            traces: []
          }
        };
        _this.dispatcherService.getDispatch(_this, 300);
        // if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
      }
    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  buildFaultConfig(_this: any, data: any) {

    let faults = data.timeserie?.reduce((faultsObject, fault) => (faultsObject[fault.code] ? faultsObject[fault.code].push(fault) : faultsObject[fault.code] = [fault], faultsObject), {});

    for (let code of Object.keys(faults ?? {})) {

      let faultData = faults[code];
      let color = _this.selectedCodesColor[code]
      for (let prop of ['duration', 'count']) {

        let isDuration = prop == 'duration';

        let hoverText = faultData.reduce((acc, x) => {

          let hover = "";
          if (x.code != null) {
            hover += _this.internalDataService.parseAlarmsLabel(x.code, 'code', _this.machineId) + '<br>';
            hover += "<b>" + _this.translate.instant('BREAKDOWNS.DESCRIPTION') + "</b>: ";
            hover += x.message ?? _this.internalDataService.parseAlarmsLabel(x.code, 'message', _this.machineId);
            hover += '<br>';
          }
          if (x.timeStart != null) {
            hover += "<b>" + _this.translate.instant('GLOBAL.DATE') + "</b>: ";
            hover += _this.filterService.parseMoment(x.timeStart, 'default') + '<br>';
          }

          if (x.count != null) {
            hover += "<b>" + _this.translate.instant('BREAKDOWNS.COUNT') + "</b>: ";
            hover += x.count + '<br>';
            x.mat = x.count;
          }
          if (x.duration != null) {
            hover += "<b>" + _this.translate.instant('BREAKDOWNS.DURATION') + "</b>: ";
            hover += _this.filterService.parseTime(x.duration, 's', 'HH:mm:ss') + '<br>';
            if (x.mat) {
              x.mat = _this.filterService.parseTime(x.duration / x.mat, 's', 'HH:mm:ss');
              hover += "<b>" + _this.translate.instant('BREAKDOWNS.MAT') + "</b>: ";
              hover += x.mat + '<br>';
            }
          }
          acc.push(hover);
          return acc;
        }, []);

        this.faultTraces.push({
          x: faultData.map((x: any) => x.timeStart),
          y: faultData.map((y: any) => isDuration ? (y[prop] != null ? y[prop] / 3600 : null) : y[prop]),
          marker: {
            color: color,
          },
          line: {
            shape: 'spline',
            width: 2,
          },
          type: "scatter",
          mode: 'lines+markers',
          hoverinfo: 'text',
          text: hoverText,
          visible: prop == _this.selectedProp,
          traceType: prop,
          showlegend: true,
          id: code,
          name: _this.internalDataService.parseAlarmsLabel(code, 'code', _this.machineId)
        })

      };
    };

    // set table data and config
    let plotLayout: any = {
      font: {
        size: 12,
      },
      updatemenus: this.updatemenus,
      showlegend: true,
      hovermode: 'closest',
      margin: {
        t: 0,
        r: 60,
        b: 0,
        l: 60,
        pad: 5
      },
      xaxis: {
        // domain: [0, 0.2],
        showgrid: false,
        zeroline: false,

      },
      yaxis: {
        zeroline: false,
        showgrid: false,
        ticksuffix: (_this.selectedProp == 'duration') ? ' h' : null,
        // type: 'category'
        // showticklabels: false,
      },
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: -0.25
      }
    };

    _this.faultData.plotData = {
      layout: plotLayout,
      traces: this.faultTraces,
      params: {
        plotId: 'faultTrend',
        actions: {
          plotly_update:
            function (data: any) {

              let divs = ["pareto", "faultTrend"];
              divs.forEach(div => {
                let paretoDiv: any = document.getElementById(div);
                let paretoTraces = paretoDiv.data;
                let traceNumber = 0;
                let prop = data.layout.id;
                _this.selectedProp = prop;
                let ticksuffix = data.layout['yaxis.ticksuffix'];
                for (let paretoTrace of paretoTraces) {

                  let update = { 'visible': paretoTrace.traceType == prop };

                  Plotly.restyle(div, update, [traceNumber]);
                  traceNumber++;
                }
                Plotly.relayout(div, { 'yaxis.ticksuffix': ticksuffix })
              })
            }
        },
      },
    };
  }

  resetTraces() {
    this.faultTraces = [];
    this.requiredCodes = [];
    this.selectedCodes = [];
    this.selectedCodesColor = {};
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);
  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    this.internalDataService.setCalendarPage(false);
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingEvents.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.internalDataService.setBackButton([]) } catch (error) { }
  }

}