<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" aria-label="close">close</mat-icon>
  </button>
</div>
<mat-dialog-content>

  <!-- <pre>{{dialog|json}}</pre> -->
  <div fxLayout="column" fxLayoutAlign="start start">
    <ff-tabs [tabs]="dialog.filterTabsCopy"></ff-tabs>

    <div fxFlex fxLayout="row" fxLayoutAlign="start start" class="w100 h100">
      <div *ngFor="let tab of dialog.filterTabsCopy">
        <div *ngIf="tab.selected" fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content">

          <div *ngFor="let elem of tab.list">
            <div *ngIf="elem.selected == null || elem.selected" fxLayout="row" fxLayoutAlign="start center"
              style="padding: 4px 8px;">
              <mat-icon *ngIf="elem.enabled" svgIcon="radio_button" class="icon small left clickable radio_button"
                (click)="elem.enabled = !elem.enabled">
              </mat-icon>
              <mat-icon *ngIf="!elem.enabled" svgIcon="radio_button_disabled" (click)="elem.enabled = !elem.enabled"
                class="icon small left clickable radio_button_disabled"></mat-icon>
              <div [ngClass]="{'state-container': tab.id == 2}" [style.background-color]="elem.color" fxLayout="row"
                fxLayoutAlign="center center">{{ elem.value | translate }}</div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <!-- <button mat-button mat-dialog-close>
      {{'GLOBAL.CANCEL'|translate}}
    </button> -->
  <button mat-button [mat-dialog-close]="dialog" cdkFocusInitial class="filter-button">
    {{'GLOBAL.SELECT'|translate}}
  </button>
</mat-dialog-actions>
