import { LabelType } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';
// import { AnnealerDialogComponent } from './annealer-dialog/annealer-dialog.component';
// import { DatapointDialogComponent } from './datapoint-dialog/datapoint-dialog.component';
// import { ImageDialogComponent } from './image-dialog/image-dialog.component';
// import { SynopticSelectionDialogComponent } from './synoptic-selection-dialog/synoptic-selection-dialog.component';
import { SctDatapointDialogComponent } from './sct-datapoint-dialog/sct-datapoint-dialog.component';
// import { SctSetZoomDialogComponent } from './sct-set-zoom-dialog/sct-set-zoom-dialog.component';


@Component({
  selector: 'app-sct-heatmap',
  templateUrl: './sct-heatmap.component.html',
  styleUrls: ['./sct-heatmap.component.scss']
})
export class SctHeatmapComponent implements OnInit {

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // VARIABLES

  public isAllowedUser: boolean = true

  public loadingData: any
  public errorData: any

  public mobileListener: Subscription
  public isMobile: boolean
  public isSmThanTablet: boolean

  public appConfig: any
  public appInfo: any
  public machineProfiles: any

  public backButton: any
  public breadcrumb: any
  public tabs: any

  public machineId: any
  public machineSelectedSub: Subscription
  public machine: any

  public currentSynopticId: any
  public synopticConfig: any
  public synopticConfigDefault: any

  public monitoringData: any

  public pollingTime: any
  public pollingMachines: any
  public pollingCycles: any

  public datapointSub: Subscription
  public dialogData: any
  public dashboardConfig: any
  public completeDashboardConfig: any
  public showDialog: boolean = false
  public hideSynopticSelectionButton: any = false

  public historyView: boolean = false
  public cycleFrom: null
  public cycleTo: null
  public cycleDuration: null

  public pollingDropdownList: any = [
    { id: 5, s: 5000, label: '5 sec' },
    { id: 10, s: 10000, label: '10 sec' },
    { id: 30, s: 30000, label: '30 sec' },
    { id: 60, s: 60000, label: '60 sec' }
  ]
  public pollingDropdown: any

  public stepDropdownList: any = [
    { id: 1, s: 60, label: '1 min' },
    { id: 5, s: 300, label: '5 min' },
    { id: 15, s: 900, label: '15 min' },
    { id: 30, s: 1800, label: '30 min' },
    { id: 60, s: 3600, label: '60 min' },
  ]
  public stepDropdown: any

  public sliderConf: any = {}
  public sliderValue: any
  public sliderPlayStop = false
  public sliderRunning = true
  public sliderDisabled = false

  public cyclesReviewView: boolean = false
  public cyclesList: []

  // private annealerDialog: MatDialogRef<AnnealerDialogComponent>

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1)
  public pageStateReady: number = 8
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        // { code: 300, function: this.getAssetInfo, nextState: 5, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getSynopticConfig, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getMonitoringData, nextState: 7, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 },
        // { code: 302, function: this.getTabularConfig, nextState: 6 },
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.getMonitoringData, nextState: 7, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 },
        { code: 302, function: this.plotMonitoringData_cycleReview, nextState: 9 }
      ]
    },
    {
      state: 8,
      codes: [
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 },
        { code: 302, function: this.plotMonitoringData_cycleReview, nextState: 9 }
      ]
    },
    {
      state: 9,
      codes: [
        // { code: 300, nextState: 9 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 },
        // { code: 302, nextState: 9 }
      ]
    }
  ]

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public intervalService: IntervalService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public mobile: MobileService
  ) {

    this.mobile.mobileListener.subscribe((value: any) => {
      this.isSmThanTablet = value.isSmThanTablet
      this.isMobile = value.isMobile

      // console.log(this.pageState.value, this.pageStateReady)
      // if (this.pageState.value >= this.pageStateReady) {
      //   this.plotMonitoringData_cycleReview(this)
      // }
    })

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.monitoringData = null;

    // this.breadcrumb = ['PLANT_SUPERVISION.TITLE'];
    // this.internalDataService.setBreadcrumb(this.breadcrumb);

    // this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
    //   if (Object.keys(value).length != 0) {
    //     let newBreadcrumb = Object.assign([], this.breadcrumb);
    //     newBreadcrumb.push(value.machineName);
    //     this.internalDataService.setBreadcrumb(newBreadcrumb);
    //   }
    // });

    this.tabs = this.internalDataService.getPageTabs('sctHeatmap');

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));
    this.pageState.subscribe();
    // this.pollingTime = this.appConfig?.sctHeatmap?.polling ?? 10000;
    this.pollingTime = 10000;

    this.pollingMachines = Subscription;
    this.pollingCycles = Subscription;

    this.currentSynopticId = 'mainPlant';

    // datapoint subscription
    this.datapointSub = this.internalDataService.onDatapointUpdate().subscribe(dp => {

      let title = (dp.dialogTitle != null && dp.dialogTitle != '') ? this.translate.instant(dp.dialogTitle) : null;
      if (title == null) title = dp.title;
      if (title == null) title = (dp.label != null && dp.label != '') ? this.translate.instant(dp.label) : 'Undefined';

      if (dp.action == 'chartCrane') {
        title = this.translate.instant('PLANT_SUPERVISION.CRANE_POSITION');
      }

      if (dp.action == 'sctDatapoint') {
        title = this.translate.instant('PLANT_SUPERVISION.HEATMAP_DIALOG') + ' [x: ' + dp.x + ' | y: ' + dp.y + ']';
      }

      this.showDialog = true;
      this.dialogData = {
        showDialog: true,
        title: title,
        unit: this.translate.instant((dp.unit != null && dp.unit != '') ? dp.unit : '-'),
        defaultUnit: dp.defaultUnit,
        yAxisRange: dp.yAxisRange,
        datapointList: dp.datapointList ?? [dp.datapoint],
        machineId: this.machineId,
        machine: this.machine,
        timezone: this.machine?.timezone,
        completeDatapoint: this.clonerService.deepClone(dp)
      };

      // console.log("action", dp.action);
      const datapointDialog = this.dialog.open(SctDatapointDialogComponent, {
        width: this.isSmThanTablet ? 'fit-content' : '60%',
        data: this.dialogData,
        panelClass: 'datapoint-dialog'
      });

      datapointDialog.afterClosed().subscribe(result => {
        // console.log(`Dialog result: ${result}`);
      });

      // }

    });

  }

  updateBreadcrumb(_this: any) {

    if (_this.historyView) _this.breadcrumb = ['PLANT_SUPERVISION.CYCLE_HISTORY']
    else if (_this.cyclesReviewView) _this.breadcrumb = ['PLANT_SUPERVISION.CYCLES_REVIEW']
    else _this.breadcrumb = ['PLANT_SUPERVISION.TITLE']

    _this.internalDataService.setBreadcrumb(_this.breadcrumb);

    _this.machineSelectedSub = _this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], _this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        _this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-ms-telemetry"); // TODO
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs('sctHeatmap');
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'sctHeatmap');
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      let dashboardName = 'sct-heatmap'
      if (_this.cyclesReviewView) dashboardName = 'sct-heatmap-cycles-review'

      _this.internalDataService.getDashboard(_this, _this.machineId, dashboardName)
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      }
      _this.dispatcherService.getDispatch(_this, 301, testError)
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SYNOPTIC CONFIG

  public getSynopticConfig(_this: any) {
    try {
      let url = '/apif/get-custom-file';
      let fileName = 'synopticConfig.json';

      let profileId = null;
      try { profileId = _this.machineProfiles.profiles.find(x => x?.machineIdList?.includes(_this.machineId)).id }
      catch (error) { }

      let query: any = {
        fromWeb: _this.appInfo?.sources40F != null ? 1 : 0,
        source: _this.appInfo?.sources40F ?? '/assets/config/',
        machineId: _this.machineId,
        fileName: fileName,
      };

      if (profileId != null) query.profileId = profileId;

      _this.apiService.sendGetRequest(url, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {
          error = {
            ...error, ...{
              error: {
                "customMessage": "Missing file: default-" + fileName,
                "customCode": "404",
              }
            }
          }
          return _this.internalDataService.parseStandardHTTPError(_this, error);
        }))
        .subscribe(
          (data: any) => {

            // console.log(data.body);
            _this.synopticConfigDefault = data.body;
            if (Object.keys(_this.synopticConfigDefault).length <= 1) _this.hideSynopticSelectionButton = true;

            _this.dispatcherService.getDispatch(_this, 300);

          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }

  }

  public lastUpdate = null

  getMonitoringData(_this: any) {
    if (_this.cyclesReviewView) _this.getMonitoringData_cycleReview(_this)
    else _this.getMonitoringData_main(_this)
  }

  getMonitoringData_main(_this: any) {
    // console.log('getMonitoringData')
    try { _this.pollingMachines.unsubscribe() } catch (error) { }

    try {

      let pollingTime = _this.pollingTime
      if (_this.historyView) pollingTime = _this.pollingDropdown.s

      _this.pollingMachines = timer(0, pollingTime).subscribe((count => {

        let query: any = {
          tz: _this.machine.timezone,
        };

        let url = '/apif/sct-heatmap/' + _this.machineId;

        let assetConfig = {
          lastUpdate: _this.lastUpdate,
          numCranes: parseInt(_this.machine.numOfCranes),
          numCols: parseInt(_this.machine.numOfColumns),
          numRows: parseInt(_this.machine.numOfRows),
          xMax: parseInt(_this.machine.xMax),
          yMax: parseInt(_this.machine.yMax),
          wTarget: parseFloat(_this.machine.irrigationTarget),
        }

        let heatmap: any = _this.clonerService.deepClone(_this.machine.profile?.heatmap ?? []);

        let payload = {
          assetConfig: assetConfig,
          widgetData: heatmap
        };

        if (_this.historyView) {
          if (!_this.sliderPlayStop && count > 0) return

          _this.sliderDisabled = true
          _this.sliderConf.options = Object.assign({}, _this.sliderConf.options, { disabled: _this.sliderDisabled });

          _this.sliderRunning = true

          query.from = _this.cycleFrom
          // query.to = _this.filterService.parseMoment(_this.sliderConf.value, 'yyyy-MM-ddTHH:mm:ssZ')
          _this.sliderConf.value = _this.nextDateStep(_this, _this.sliderConf.value)

          query.to = _this.filterService.parseMoment(_this.sliderConf.value, 'YYYY-MM-DDTHH:mm:ss.SSS')
        }

        let errorSnack = {
          // text: snackBarOptions?.text
          // horizontalPosition: snackBarOptions?.horizontalPosition
          // verticalPosition: snackBarOptions?.verticalPosition
          // duration: snackBarOptions?.duration
          // closeButton: snackBarOptions?.closeButton
          classes: 'md-red'
        }

        _this.apiService.sendPostRequest(url, payload, query).pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          // catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, count == 0 ? _this.pollingMachines : null)))
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, null, errorSnack, true)))
          .subscribe(
            (data: any) => {

              _this.sliderRunning = false
              _this.sliderDisabled = false
              _this.sliderConf.options = Object.assign({}, _this.sliderConf.options, { disabled: _this.sliderDisabled });

              _this.monitoringData = _this.parseMonitoringData(data.body);

              // console.log(_this.monitoringData)
              // console.log(_this.dashboardConfig)
              // console.log(_this.machine)

              if (_this.machine.numOfCranes == "1" || _this.machine.numOfCranes == 1) {
                let cr2 = _this.dashboardConfig.widgets.findIndex(w => w.crane == 2)
                if (cr2 != -1) _this.dashboardConfig.widgets.splice(cr2, 1)
              }

              _this.completeDashboardConfig = {
                dashboardData: _this.clonerService.deepClone(_this.monitoringData),
                machineProfile: _this.machine.profile,
                dashboardConfig: _this.dashboardConfig,
                asColumn: _this.dashboardConfig?.asColumn
              };

              if (data.body.hasOwnProperty('lastUpdate')) _this.lastUpdate = data.body.lastUpdate

              if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
            }
          );

      }));
    } catch (error) {
      console.log(error);
    }
  }

  getMonitoringData_cycleReview(_this: any) {
    // console.log('getMonitoringData_cycleReview')
    try { _this.pollingMachines.unsubscribe() } catch (error) { }

    try {

      let pollingTime = 500

      if (_this.cyclesList && _this.cyclesList.length > 0) {

        _this.resetCyclesList(_this)

        let stepsArray = []
        _this.cyclesList.forEach(x => {
          stepsArray.push(moment(x.timeStart).toDate())
        });
        _this.initSlider(_this, stepsArray, false)

        let cycleIdLoading
        let cycleLoadingCount = 1

        _this.pollingMachines = timer(0, pollingTime).subscribe((count => {

          _this.cyclesReviewLoadingData = {
            "message": "Loading (" + cycleLoadingCount + "/" + _this.cyclesList.length + ")"
          }

          // get first cycle in list, not laoded 
          let cycleInfo = _this.getCycleReviewInfo(_this, false)
          if (!cycleInfo) {
            _this.pollingMachines.unsubscribe()
            return
          }

          if (cycleInfo.cycleId == cycleIdLoading && cycleInfo.loading && !cycleInfo.loaded) return
          else cycleIdLoading = cycleInfo.cycleId
          cycleInfo.loading = true

          let query: any = {
            tz: _this.machine.timezone,
            from: cycleInfo.timeStart,
            to: cycleInfo.timestamp
          };

          let url = '/apif/' + _this.machineId + '/sct-heatmap/cycles-review'

          let assetConfig = {
            // lastUpdate: _this.lastUpdate,
            numCranes: parseInt(_this.machine.numOfCranes),
            numCols: parseInt(_this.machine.numOfColumns),
            numRows: parseInt(_this.machine.numOfRows),
            xMax: parseInt(_this.machine.xMax),
            yMax: parseInt(_this.machine.yMax),
            wTarget: parseInt(_this.machine.irrigationTarget),
          }

          // let heatmap: any = _this.clonerService.deepClone(_this.machine.profile?.heatmap ?? []);
          let payload = {
            assetConfig: assetConfig,
            // widgetData: heatmap
          }

          _this.apiService.sendPostRequest(url, payload, query).pipe(
            retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
            catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, null)))
            .subscribe(
              (data: any) => {

                data.body.cycleId = cycleInfo.cycleId
                data.body.cycleType = parseInt(cycleInfo.aggr2)

                cycleInfo.monitoringData = _this.parseMonitoringData(data.body)
                cycleInfo.monitoringData.cycleFrom = cycleInfo.timeStart
                cycleInfo.monitoringData.cycleTo = cycleInfo.timestamp

                if (count < 1) {
                  _this.monitoringData = cycleInfo.monitoringData
                  _this.cycleDuration = _this.filterService.parseDuration(null, null, 'hh:mm:ss', cycleInfo.timeStart, cycleInfo.timestamp)

                  _this.completeDashboardConfig = {
                    dashboardData: _this.clonerService.deepClone(_this.monitoringData),
                    machineProfile: _this.machine.profile,
                    dashboardConfig: _this.dashboardConfig,
                    asColumn: _this.dashboardConfig?.asColumn
                  }
                }

                cycleInfo.loading = false
                cycleInfo.loaded = true
                cycleLoadingCount += 1

                if (_this.allLoadedCyclesList(_this)) {
                  _this.dispatcherService.getDispatch(_this, 302)
                  try { _this.pollingMachines.unsubscribe() } catch (error) { }

                  _this.countTotal = 0
                  _this.count = 0

                  _this.sliderPlayStop = true
                  _this.sliderDisabled = false
                  _this.sliderConf.options = Object.assign({}, _this.sliderConf.options, { disabled: _this.sliderDisabled });
                  _this.sliderRunning = false
                }
                else _this.dispatcherService.getDispatch(_this, 300)

              }
            );

        }));

      }
      else {
        _this.monitoringData = { dataConfig: {} }
        _this.cycleDuration = null

        _this.completeDashboardConfig = {
          dashboardData: _this.clonerService.deepClone(_this.monitoringData),
          machineProfile: _this.machine.profile,
          dashboardConfig: _this.dashboardConfig,
          asColumn: _this.dashboardConfig?.asColumn
        }

        _this.dispatcherService.getDispatch(_this, 302)
      }

    } catch (error) {
      console.log(error);
    }

  }

  resetCyclesList(_this: any) {
    _this.cyclesList.forEach(x => { x.loading = false, x.loaded = false })
  }

  getCycleReviewInfo(_this: any, loaded: false) {
    let cycleInfo
    if (!loaded) cycleInfo = _this.cyclesList.find(x => !x.loaded)
    else cycleInfo = _this.cyclesList.find(x => x.loaded)
    return cycleInfo
  }

  allLoadedCyclesList(_this: any) {
    return _this.cyclesList.every(x => x.loaded == true)
  }

  parseMonitoringData(data: any) {
    try {

      if (data != null && Object.keys(data).length > 0) {

        data.dataConfig = {
          plotDataAttribute: 'plotData',
        }

        // neverC enabled
        if (data.hasOwnProperty('neverC') && data.neverC != null) {
          if (data.neverC) {
            data.notConnected = false;
            data.neverConnected = true;
            data.lastUpdateP = data.lastUpdate != null ? moment(data.lastUpdate).tz(this.machine.timezone).format("DD MMM YYYY - HH:mm:ss") : this.translate.instant("MACHINE_STATES.NEVER_CONNECTED");
          }
          else {
            data.notConnected = true;
            data.neverConnected = false;
            data.lastUpdateP = data.lastUpdate != null ? moment(data.lastUpdate).tz(this.machine.timezone).format("DD MMM YYYY - HH:mm:ss") : this.translate.instant("MACHINE_STATES.NOT_CONNECTED");
          }
        }

        // No neverC property
        else {
          data.lastUpdateP = data.lastUpdate != null ? moment(data.lastUpdate).tz(this.machine.timezone).format("DD MMM YYYY - HH:mm:ss") : this.translate.instant("MACHINE_STATES.NEVER_CONNECTED");

          data.neverConnected = data.lastUpdate == null;
          let connectionTime = this.machine.profile?.ukwStateThresholdSeconds != null ? this.machine.profile?.ukwStateThresholdSeconds / 60 : (this.appConfig.sctHeatmap?.warningMinutes ?? 15);
          data.notConnected = moment().diff(moment(data.lastUpdate), 'm') > connectionTime;
        }

        if (data.hasOwnProperty('cycleType')) {
          data.cycleTypeP = this.translate.instant('cycleTypes.' + data.cycleType);
        }

        // // Parse tabular data
        // if (this.dashboardConfig && Array.isArray(this.dashboardConfig)) {
        //   this.dashboardConfig.forEach((col: any) => {
        //     col.widgets.forEach((widget: any) => {
        //       if (Array.isArray(widget.config) && widget.config.length > 0) {
        //         widget.config.forEach((config: any) => {
        //           if (config.type === 'date') {
        //             data[config.letiable] = this.filterService.parseMoment(data[config.letiable], 'default')
        //           }
        //         });
        //       }
        //     });
        //   })
        // }

        data.plotData = this.buildPlotConfig(this, data);

        if (data.position && data.position[0]) {
          data.positionMM_1 = data.position[0]?.xAct + ' / ' + data.position[0]?.yAct + ' mm'
          data.positionRL_1 = 'X: ' + (parseInt(data.position[0]?.xRel) + 1) + ' / Y: ' + (parseInt(data.position[0]?.yRel) + 1)
          data.positionTemp_1 = data.position[0]?.temp + ' °C'
        } else {
          data.positionMM_1 = null
          data.positionRL_1 = null
          data.positionTemp_1 = null
        }

        if (data.position && data.position[1]) {
          data.positionMM_2 = data.position[1]?.xAct + ' / ' + data.position[1]?.yAct + ' mm'
          data.positionRL_2 = 'X: ' + (parseInt(data.position[1]?.xRel) + 1) + ' / Y: ' + (parseInt(data.position[1]?.yRel) + 1)
          data.positionTemp_2 = data.position[1]?.temp + ' °C'
        } else {
          data.positionMM_2 = null
          data.positionRL_2 = null
          data.positionTemp_2 = null
        }

        this.internalDataService.setMonitoringData(data);

        return data;

      } else {
        data = {
          dataConfig: {
            plotDataAttribute: 'plotData',
          }
        }
      }
      return data;

    } catch (error) {
      console.log(error);
      return {};
    }
  }

  plotMonitoringData_cycleReview(_this: any) {
    // console.log('plotMonitoringData_cycleReview')
    try { _this.pollingCycles.unsubscribe() } catch (error) { }
    try {
      let pollingTimeCycles = _this.pollingDropdown.s

      if (!_this.cyclesList || _this.cyclesList.length == 0) {
        _this.dispatcherService.getDispatch(_this, 300);
        return
      }

      _this.pollingCycles = timer(0, pollingTimeCycles).subscribe((count => {

        if (!_this.sliderPlayStop) {
          try { _this.pollingCycles.unsubscribe() } catch (error) { }
          return
        }

        _this.countTotal += (count == 0) ? 0 : 1
        _this.count = count
        let cycleInfo = _this.cyclesList[_this.countTotal % _this.cyclesList.length]

        _this.sliderConf.value = _this.nextDateStep(_this, cycleInfo.timeStart)

        cycleInfo.monitoringData = _this.parseMonitoringData(cycleInfo.monitoringData)
        cycleInfo.monitoringData.cycleFrom = cycleInfo.timeStart
        cycleInfo.monitoringData.cycleTo = cycleInfo.timestamp

        _this.monitoringData = cycleInfo.monitoringData

        _this.cycleDuration = _this.filterService.parseDuration(null, null, 'hh:mm:ss', cycleInfo.timeStart, cycleInfo.timestamp)

        _this.monitoringData.pieChartData = {
          traces: [
            {
              values: [cycleInfo.auxCycle4, cycleInfo.auxCycle5, cycleInfo.auxCycle6, cycleInfo.auxCycle7],
              labels: [
                _this.translate.instant('LINE_STATES.IDLE'),
                _this.translate.instant('LINE_STATES.EXECUTE'),
                _this.translate.instant('LINE_STATES.FAULT'),
                _this.translate.instant('LINE_STATES.MANUAL'),
              ],
              // marker: { colors: Object.keys(data.scraps)?.map((scrapId: any) => this.colorArray.find((scrap: any) => scrap.id == scrapId)?.color) },
              type: 'pie',
              hole: 0.5
            }
          ],
          layout: {
            margin: {
              t: 20,
              r: 20,
              b: 20,
              l: 20,
              pad: 0
            },
            showlegend: true,
            legend: { orientation: "h" }
          }
        };

        _this.completeDashboardConfig = {
          dashboardData: _this.clonerService.deepClone(_this.monitoringData),
          machineProfile: _this.machine.profile,
          dashboardConfig: _this.dashboardConfig,
          asColumn: _this.dashboardConfig?.asColumn
        }

        _this.dispatcherService.getDispatch(_this, 300);

      }));

    } catch (error) {
      console.log(error);
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // HEATMAP

  public heatmapParams = null

  public slidersz = {
    xMin: null,
    xMax: null,
    yMin: null,
    yMax: null
  }

  public c_plotType: any
  public colorScaleHeat: any
  public colorScaleWFlow: any
  public colorScaleWIrr: any
  public colorScaleWTargetPerc: any

  buildPlotConfig(_this: any, data: any) {
    // console.log(_this.machine)

    // this.slidersz = {
    //   xMin: 5,
    //   xMax: 8,
    //   yMin: 3,
    //   yMax: 9
    // }

    let widgetParams: any = {};
    let traces = [];

    widgetParams = {
      data: traces,
      layout: {},
      heatmapDetail: true,
      utils: {
      },
      chartBackgroundCustom: true
    }

    if (!data || !data.history) {
      return {
        layout: {},
        traces: traces,
        params: widgetParams
      }
    }

    // TODO
    // if (cacheService.getItem('plotDialog')) {
    //   return;
    // }

    if (_this.machine != null && _this.machine.hasOwnProperty('colorScale')) {
      _this.cacheService.set('colorArray-' + _this.machine.machineId, _this.machine.colorScale);
    }

    let x_data = [];
    let y_data = [];

    let z_dataHeat = [];
    let z_dataHeatFull = [];

    let z_dataWFlow = [];
    let z_dataWFlowFull = [];

    let z_dataWIrr = [];
    let z_dataWIrrFull = [];

    let z_dataWTargetPerc = [];
    let z_dataWTargetPercFull = [];

    let nCol = 100;
    let nRow = 100;
    let xMax = null;
    let yMax = null;

    if (_this.machine != null && _this.machine.hasOwnProperty('numOfColumns') &&
      _this.machine.numOfColumns != null) {
      nCol = parseInt(_this.machine.numOfColumns);
    }
    if (data.hasOwnProperty('totRows') && data.totRows != null) {
      nRow = data.totRows;
    }
    if (data.hasOwnProperty('xMax') && data.xMax != null) {
      xMax = data.xMax;
    }
    if (data.hasOwnProperty('yMax') && data.yMax != null) {
      yMax = data.yMax;
    }

    // let nCol = _this.machine.numOfColumns;
    // let nRow = _this.machine.numOfRows;
    // let xMax = _this.machine.xMax;
    // let yMax = _this.machine.yMax;

    let hover_z = [];
    let hover_row = [];
    let row = [];

    // initialize matrix
    for (let i = 0; i < nRow; i++) {
      row = [];
      hover_row = [];
      for (let j = 0; j < nCol; j++) {
        row.push(null);
        hover_row.push("No value");
      }
      hover_z.push(hover_row);

      z_dataHeat.push(_this.clonerService.deepClone(row));
      z_dataHeatFull.push(_this.clonerService.deepClone(row));

      z_dataWFlow.push(_this.clonerService.deepClone(row));
      z_dataWFlowFull.push(_this.clonerService.deepClone(row));

      z_dataWIrr.push(_this.clonerService.deepClone(row));
      z_dataWIrrFull.push(_this.clonerService.deepClone(row));

      z_dataWTargetPerc.push(_this.clonerService.deepClone(row));
      z_dataWTargetPercFull.push(_this.clonerService.deepClone(row));
    }

    let imagesHeatmap = [];
    let imagesContour = [];

    data.history.sort(function (a, b) {
      return a.xRel - b.xRel;
    });
    data.history.sort(function (a, b) {
      return a.yRel - b.yRel;
    });

    // colors //////////////////////////////////////////////////////////////////////////////

    ///////////////////////////////////////
    // temperature

    let colorArray: any = [
      [30, "blue"],
      [40, "orange"],
      [65, "red"],
      [90, "purple"]
    ];

    if (_this.machine.hasOwnProperty('machineId')) {
      let c_colorArray = _this.cacheService.get('colorArray-' + _this.machine.machineId);
      if (c_colorArray != null) {
        try {
          colorArray = JSON.parse(c_colorArray);
        } catch (e) {
          console.log(e);
        }
      }
    }

    let tolerance = 10;
    colorArray.unshift([colorArray[0][0] - tolerance, colorArray[0][1]]);
    colorArray.unshift([colorArray[0][0] - 0.1, 'white']);
    colorArray.push([colorArray[colorArray.length - 1][0] + tolerance, colorArray[colorArray.length - 1][1]]);

    let neverPassedValue = -99;

    let colorMin: any = 100;
    let colorMax: any = 0;
    let colorDelta: any = 0;

    _this.colorScaleHeat = {
      colorscale: []
    };

    if (colorArray != null && Array.isArray(colorArray) && colorArray.length > 0) {
      colorArray.forEach(function (item) {
        if (item[0] < colorMin) colorMin = item[0];
        if (item[0] > colorMax) colorMax = item[0];
      });

      _this.colorScaleHeat.cmin = colorMin;
      _this.colorScaleHeat.cmax = colorMax;
      colorDelta = 1 / (colorMax - colorMin);

      colorArray.forEach(function (item) {
        _this.colorScaleHeat.colorscale.push([(item[0] - colorMin) * colorDelta, item[1]]);
      });
    }

    _this.colorScaleHeat.showscale = true;

    ///////////////////////////////////////
    // wFlow

    // _this.machine.flowRange = 22;
    // _this.machine.waterType1 = "#000FFF;WATER_TYPE.TYPE_1";
    // _this.machine.waterType2 = "#F000FF;WATER_TYPE.TYPE_2";
    // _this.machine.waterType3 = "#FF0000;WATER_TYPE.TYPE_3";

    let wFlowAddValue = 18;

    if (_this.machine.hasOwnProperty('flowRange') && _this.machine.flowRange != null && _this.machine.flowRange != 0) {
      wFlowAddValue = _this.machine.flowRange;
    }

    let colorArrayWFlow = [
      // [0, "white"],
      // [wFlowAddValue, "brown"],
      // [wFlowAddValue, "white"],
      // [(wFlowAddValue * 2), "green"],
      // [(wFlowAddValue * 2), "white"],
      // [(wFlowAddValue * 3), "blue"]
    ];

    // let wFlowStrNull = "WATER_TYPE.TYPE_NULL";
    let wFlowStr0 = "WATER_TYPE.TYPE_0";
    let wFlowColor0 = "black";

    let wFlowStr1 = "WATER_TYPE.TYPE_1";
    let wFlowColor1 = "brown";

    let wFlowStr2 = "WATER_TYPE.TYPE_2";
    let wFlowColor2 = "green";

    let wFlowStr3 = "WATER_TYPE.TYPE_3";
    let wFlowColor3 = "blue";

    if (_this.machine.hasOwnProperty('waterType1') && _this.machine.waterType1 != null) {
      let ft0 = _this.machine.waterType1;
      ft0 = ft0.split(";");
      colorArrayWFlow.push([0, "white"]);
      wFlowColor1 = ft0[0];
      colorArrayWFlow.push([wFlowAddValue, wFlowColor1]);
      wFlowStr1 = ft0[1];
    } else {
      colorArrayWFlow.push([0, "white"]);
      colorArrayWFlow.push([wFlowAddValue, wFlowColor1]);
    }

    if (_this.machine.hasOwnProperty('waterType2') && _this.machine.waterType2 != null) {
      let ft1 = _this.machine.waterType2;
      ft1 = ft1.split(";");
      colorArrayWFlow.push([wFlowAddValue, "white"]);
      wFlowColor2 = ft1[0];
      colorArrayWFlow.push([wFlowAddValue * 2, wFlowColor2]);
      wFlowStr2 = ft1[1];
    }

    if (_this.machine.hasOwnProperty('waterType3') && _this.machine.waterType3 != null) {
      let ft2 = _this.machine.waterType3;
      ft2 = ft2.split(";");
      colorArrayWFlow.push([wFlowAddValue * 2, "white"]);
      wFlowColor3 = ft2[0];
      colorArrayWFlow.push([wFlowAddValue * 3, wFlowColor3]);
      wFlowStr3 = ft2[1];
    }

    if (_this.machine.hasOwnProperty('waterType0') && _this.machine.waterType0 != null) {
      let ft0 = _this.machine.waterType0;
      ft0 = ft0.split(";");
      wFlowColor0 = ft0[0];
      wFlowStr0 = ft0[1];
    }

    _this.colorScaleWFlow = {
      colorscale: []
    };

    colorMin = 100;
    colorMax = 0;
    colorDelta = 0;
    if (colorArrayWFlow != null && Array.isArray(colorArrayWFlow) && colorArrayWFlow.length > 0) {
      colorArrayWFlow.forEach(function (item) {
        if (item[0] < colorMin) colorMin = item[0];
        if (item[0] > colorMax) colorMax = item[0];
      });

      _this.colorScaleWFlow.cmin = colorMin;
      _this.colorScaleWFlow.cmax = colorMax;
      colorDelta = 1 / (colorMax - colorMin);

      colorArrayWFlow.forEach(item => {
        _this.colorScaleWFlow.colorscale.push([(item[0] - colorMin) * colorDelta, item[1]]);
      });
    }

    let colorbarWFlow = _this.customColorbarTicks(colorArrayWFlow);
    _this.colorScaleWFlow.showscale = true;
    _this.colorScaleWFlow.colorbar = colorbarWFlow

    // console.log('colorScaleWIrr', _this.colorScaleWFlow)

    ///////////////////////////////////////
    // wIrr

    var wIrrAddValue = 70;
    if (_this.machine != null && _this.machine.hasOwnProperty('irrigationRange') &&
      _this.machine.irrigationRange != null) {
      wIrrAddValue = parseInt(_this.machine.irrigationRange);
    }

    var colorArrayWIrr = [
      // [0, "white"],
      // [wIrrAddValue, "brown"],
      // [wIrrAddValue, "white"],
      // [(wIrrAddValue * 2), "green"],
      // [(wIrrAddValue * 2), "white"],
      // [(wIrrAddValue * 3), "blue"]
    ];

    colorArrayWIrr.push([0, "white"]);
    colorArrayWIrr.push([wIrrAddValue, wFlowColor1]);

    if (_this.machine.hasOwnProperty('waterType2') && _this.machine.waterType2 != null) {
      colorArrayWIrr.push([wIrrAddValue, "white"]);
      colorArrayWIrr.push([wIrrAddValue * 2, wFlowColor2]);
    }

    if (_this.machine.hasOwnProperty('waterType3') && _this.machine.waterType3 != null) {
      colorArrayWIrr.push([wIrrAddValue * 2, "white"]);
      colorArrayWIrr.push([wIrrAddValue * 3, wFlowColor3]);
    }

    _this.colorScaleWIrr = {
      colorscale: []
    };

    colorMin = 100;
    colorMax = 0;
    colorDelta = 0;
    if (colorArrayWIrr != null && Array.isArray(colorArrayWIrr) && colorArrayWIrr.length > 0) {
      colorArrayWIrr.forEach(function (item) {
        if (item[0] < colorMin) colorMin = item[0];
        if (item[0] > colorMax) colorMax = item[0];
      });

      _this.colorScaleWIrr.cmin = colorMin;
      _this.colorScaleWIrr.cmax = colorMax;
      colorDelta = 1 / (colorMax - colorMin);

      colorArrayWIrr.forEach(function (item) {
        _this.colorScaleWIrr.colorscale.push([(item[0] - colorMin) * colorDelta, item[1]]);
      });

      // // add water type 0 (if no water type assigned, ma irr readed)
      // _this.colorScaleWIrr.colorscale[_this.colorScaleWIrr.colorscale.length - 1][0] = 0.9999999999999999;
      // _this.colorScaleWIrr.colorscale.push([1, wFlowColor0]);
    }

    // _this.colorScaleWIrr.colorscale = colorArrayWIrr;

    _this.colorScaleWIrr.showscale = true;
    var colorbarWIrr = _this.customColorbarTicks(colorArrayWIrr);
    _this.colorScaleWIrr.colorbar = colorbarWIrr;

    // console.log('colorScaleWIrr', _this.colorScaleWIrr)

    ///////////////////////////////////////
    // wTargetPerc

    var colorArrayWTargetPerc = [
      [0, "white"],
      [0.01, "red"],
      [0.1, "red"],
      [50, "orange"],
      [100, "green"],
      [100.1, "green"],
      // [110, "pink"],
      [120, "purple"]
    ];

    _this.colorScaleWTargetPerc = {
      colorscale: []
    };

    colorMin = 100;
    colorMax = 0;
    colorDelta = 0;
    if (colorArrayWTargetPerc != null && Array.isArray(colorArrayWTargetPerc) && colorArrayWTargetPerc.length > 0) {
      colorArrayWTargetPerc.forEach((item: any) => {
        if (item[0] < colorMin) colorMin = item[0];
        if (item[0] > colorMax) colorMax = item[0];
      });

      _this.colorScaleWTargetPerc.cmin = colorMin;
      _this.colorScaleWTargetPerc.cmax = colorMax;
      colorDelta = 1 / (colorMax - colorMin);

      colorArrayWTargetPerc.forEach((item: any) => {
        _this.colorScaleWTargetPerc.colorscale.push([(item[0] - colorMin) * colorDelta, item[1]]);
      });
    }

    _this.colorScaleWTargetPerc.showscale = true;

    ///////////////////////////////////////
    // set values

    let z_min = 10000;
    let z_max = 0;

    let xTicks = [];
    let yTicks = [];

    data.history.forEach((pos) => {

      let hoverText = "";

      // x
      if (xMax != null && pos.xRel != null && nRow != null) {
        hoverText += "<b>x:        </b>";
        hoverText += (xMax * pos.xRel / nCol).toFixed(0) + ' - ' + (xMax * parseInt(pos.xRel + 1) / nCol).toFixed(0) + " mm";
        hoverText += " (" + Math.round((pos.xRel + 1 + Number.EPSILON) * 100) / 100 + ")";
        hoverText += "<br>";
        let kTick = xTicks.findIndex(function (x) {
          return x.from === ((xMax * pos.xRel / nCol).toFixed(0)).toString();
        });
        if (kTick == -1) {
          xTicks.push({
            from: (xMax * pos.xRel / nCol).toFixed(0),
            to: (xMax * parseInt(pos.xRel + 1) / nCol).toFixed(0)
          });
        }
      }

      // y
      if (yMax != null && pos.yRel != null && nCol != null) {
        hoverText += "<b>y:        </b>";
        hoverText += (yMax * pos.yRel / nRow).toFixed(0) + ' - ' + (yMax * parseInt(pos.yRel + 1) / nRow).toFixed(0) + " mm";
        hoverText += " (" + Math.round((pos.yRel + 1 + Number.EPSILON) * 100) / 100 + ")";
        hoverText += "<br>";
        let kTick = yTicks.findIndex(function (y) {
          return y.from === ((yMax * pos.yRel / nRow).toFixed(0)).toString();
        });
        if (kTick == -1) {
          yTicks.push({
            from: (yMax * pos.yRel / nRow).toFixed(0),
            to: (yMax * parseInt(pos.yRel + 1) / nRow).toFixed(0)
          });
        }
      }

      let skipIteration = false;
      if (pos.hasOwnProperty('temperature') && pos.temperature == null) {
        hoverText += "<br>No valid data";
        skipIteration = true;
      }

      // temperature
      if (pos.temperature != null && pos.temperature != 0) {
        if (pos.temperature < z_min) {
          z_min = pos.temperature;
        }
        if (pos.temperature > z_max) {
          z_max = pos.temperature;
        }
        if (!skipIteration) hoverText += "<b>Temp: </b>" + Math.round((pos.temperature + Number.EPSILON) * 100) / 100 + ' °C<br>';
      }
      // else {
      //     // pos.temperature = neverPassedValue;
      // }

      let maxRound = 300;
      let minRound = 201;
      if (pos.xRel < 12) {
        maxRound = 100;
        minRound = 0;
      } else if (pos.xRel < 24) {
        maxRound = 200;
        minRound = 101;
      }

      // pos.wFlow = Math.round((Math.random() * (maxRound - minRound) + minRound) * 100) / 100;
      // pos.wIrr = Math.round((Math.random() * (maxRound - minRound) + minRound) * 100) / 100;
      // pos.wTargetPerc = Math.round((pos.temperature + 15) * 100) / 100;

      // water
      if (pos.wType != null) {
        // let wTypeStr = $translate.instant('WATER_TYPE.TYPE_' + pos.wType);
        let wTypeStr = "";
        switch (pos.wType) {
          // case null:
          case 0:
            wTypeStr = wFlowStr0;
            break;
          case 1:
            wTypeStr = wFlowStr1;
            break;
          case 2:
            wTypeStr = wFlowStr2;
            break;
          case 3:
            wTypeStr = wFlowStr3;
            break;
          default:
            wTypeStr = "WATER_TYPE.TYPE_" + pos.wType;
            break;
        }
        wTypeStr = _this.translate.instant(wTypeStr);
        if (!skipIteration) hoverText += "<br><b>Type:  </b>" + wTypeStr + "<br>";
      }
      // }
      if (pos.wFlow != null) {
        if (!skipIteration) hoverText += "<b>Flow:  </b>" + parseFloat(pos.wFlow).toFixed(2) + " m³/h<br>";
      }
      if (pos.wIrr != null) {
        if (!skipIteration) hoverText += "<b>Irrig:   </b>" + parseFloat(pos.wIrr).toFixed(2) + " L/m<br>";
      }
      if (pos.wTargetPerc != null) {
        if (!skipIteration) hoverText += "<b>Targt: </b>" + parseFloat(pos.wTargetPerc).toFixed(1) + " % (" + parseFloat(pos.wTarget).toFixed(2) + " m³)<br>";
      }

      // timestamp
      if (pos.timestamp != null) {
        // if (!skipIteration) hoverText += "<b>Last update: </b>" + _this.filterService.parseMoment(pos.timestamp, 'default') + '<br>';
        if (!skipIteration) hoverText += "<br>" + _this.filterService.parseMoment(pos.timestamp, 'default') + '<br>';
      } else {
        // if (!skipIteration) hoverText += "<b>Last update: </b> Never passed! <br>";
        if (!skipIteration) hoverText += "<br>Never passed! <br>";
      }

      if (!x_data.includes(pos.xRel)) x_data.push(pos.xRel);
      if (!y_data.includes(pos.yRel)) y_data.push(pos.yRel);

      if (!_this.slidersz || _this.slidersz.xMin == null ||
        (_this.slidersz.xMin <= pos.xRel && _this.slidersz.xMax >= pos.xRel &&
          _this.slidersz.yMin <= pos.yRel && _this.slidersz.yMax >= pos.yRel)) {

        try {

          hover_z[pos.yRel][pos.xRel] = hoverText;
          z_dataHeat[pos.yRel][pos.xRel] = pos.temperature;
          z_dataHeatFull[pos.yRel][pos.xRel] = pos.temperature;

          if (pos.wIrr == 0) pos.wIrr = 0.1;
          if (!pos.wIrr) pos.wIrr = neverPassedValue;

          if (pos.wType == 0) {
            z_dataWFlow[pos.yRel][pos.xRel] = pos.wFlow;
            z_dataWFlowFull[pos.yRel][pos.xRel] = pos.wFlow;

            z_dataWIrr[pos.yRel][pos.xRel] = pos.wIrr;
            z_dataWIrrFull[pos.yRel][pos.xRel] = pos.wIrr;
          }

          if (pos.wType == 1) {
            if (_this.machine.hasOwnProperty('waterType2') && _this.machine.waterType2 != null) {
              z_dataWFlow[pos.yRel][pos.xRel] = pos.wFlow + wFlowAddValue;
              z_dataWFlowFull[pos.yRel][pos.xRel] = pos.wFlow + wFlowAddValue;

              z_dataWIrr[pos.yRel][pos.xRel] = pos.wIrr + wIrrAddValue;
              z_dataWIrrFull[pos.yRel][pos.xRel] = pos.wIrr + wIrrAddValue;
            } else {
              z_dataWFlow[pos.yRel][pos.xRel] = pos.wFlow;
              z_dataWFlowFull[pos.yRel][pos.xRel] = pos.wFlow;

              z_dataWIrr[pos.yRel][pos.xRel] = pos.wIrr;
              z_dataWIrrFull[pos.yRel][pos.xRel] = pos.wIrr;
            }
          }

          if (pos.wType == 2) {
            if (_this.machine.hasOwnProperty('waterType3') && _this.machine.waterType3 != null) {
              z_dataWFlow[pos.yRel][pos.xRel] = pos.wFlow + (wFlowAddValue * 2);
              z_dataWFlowFull[pos.yRel][pos.xRel] = pos.wFlow + (wFlowAddValue * 2);

              z_dataWIrr[pos.yRel][pos.xRel] = pos.wIrr + (wIrrAddValue * 2);
              z_dataWIrrFull[pos.yRel][pos.xRel] = pos.wIrr + (wIrrAddValue * 2);
            } else {
              z_dataWFlow[pos.yRel][pos.xRel] = pos.wFlow;
              z_dataWFlowFull[pos.yRel][pos.xRel] = pos.wFlow;

              z_dataWIrr[pos.yRel][pos.xRel] = pos.wIrr;
              z_dataWIrrFull[pos.yRel][pos.xRel] = pos.wIrr;
            }
          }

          if (!pos.wTarget) pos.wTarget = neverPassedValue;
          if (!pos.wTargetPerc) pos.wTargetPerc = neverPassedValue;

          z_dataWTargetPerc[pos.yRel][pos.xRel] = pos.wTargetPerc;
          z_dataWTargetPercFull[pos.yRel][pos.xRel] = pos.wTargetPerc;

          // console.log('pos:', pos);

        } catch (error) {
          console.log(pos.xRel, pos.yRel, pos.temperature);
        }

      }

      else {
        try {
          // hover_z[pos.yRel][pos.xRel] = hoverText;
          if (pos.xRel >= _this.slidersz.xMin - 1 && pos.xRel <= _this.slidersz.xMax + 1 &&
            pos.yRel >= _this.slidersz.yMin - 1 && pos.yRel <= _this.slidersz.yMax + 1) {
            z_dataHeat[pos.yRel][pos.xRel] = null;

            z_dataWFlow[pos.yRel][pos.xRel] = null;
            z_dataWFlowFull[pos.yRel][pos.xRel] = null;
            z_dataWIrr[pos.yRel][pos.xRel] = null;
            z_dataWIrrFull[pos.yRel][pos.xRel] = null;
            z_dataWTargetPerc[pos.yRel][pos.xRel] = null;
            z_dataWTargetPercFull[pos.yRel][pos.xRel] = null;
          } else {
            z_dataHeat[pos.yRel][pos.xRel] = neverPassedValue;

            z_dataWFlow[pos.yRel][pos.xRel] = neverPassedValue;
            z_dataWFlowFull[pos.yRel][pos.xRel] = neverPassedValue;
            z_dataWIrr[pos.yRel][pos.xRel] = neverPassedValue;
            z_dataWIrrFull[pos.yRel][pos.xRel] = neverPassedValue;
            z_dataWTargetPerc[pos.yRel][pos.xRel] = neverPassedValue;
            z_dataWTargetPercFull[pos.yRel][pos.xRel] = neverPassedValue;
          }

          // z_dataHeat[pos.yRel][pos.xRel] = null;
          z_dataHeatFull[pos.yRel][pos.xRel] = pos.temperature;
        } catch (error) {
          console.log(pos.xRel, pos.yRel, pos.temperature);
        }
      }

    });

    // CRANE POSITION
    if (data.hasOwnProperty('position') && data.position.length > 0) {
      for (let p = 0; p < data.position.length; p++) {
        let id = data.position[p].id;
        let posXrel = data.position[p].xRel;
        let posYrel = data.position[p].yRel;
        imagesHeatmap.push(_this.setAnnotationFromXandY(posXrel, posYrel, nRow, nCol, id));
        imagesContour.push(_this.setAnnotationFromXandY(posXrel, posYrel, nRow, nCol, id));
      }
    }

    // HOTTEST CELL
    if (data.hasOwnProperty('hottestCell') && data.hottestCell != null) {
      let hottestXY = data.hottestCell.split(';');
      if (_this.isNumber(hottestXY[0]) && _this.isNumber(hottestXY[1])) {
        imagesHeatmap.push(_this.setAnnotationFromXandY(hottestXY[0], hottestXY[1], nRow, nCol, null, "assets/images/square-b.png"));
        imagesContour.push(_this.setAnnotationFromXandY(hottestXY[0], hottestXY[1], nRow, nCol, null, "assets/images/square-b.png"));
      }
    }

    // COLDEST CELL
    if (data.hasOwnProperty('coldestCell') && data.coldestCell != null) {
      let coldestXY = data.coldestCell.split(';');
      if (_this.isNumber(coldestXY[0]) && _this.isNumber(coldestXY[1])) {
        imagesHeatmap.push(_this.setAnnotationFromXandY(coldestXY[0], coldestXY[1], nRow, nCol, null, "assets/images/square-w.png"));
        imagesContour.push(_this.setAnnotationFromXandY(coldestXY[0], coldestXY[1], nRow, nCol, null, "assets/images/square-w.png"));
      }
    }

    // WATER IRRIGATION ROWS
    if (data && data.hasOwnProperty('waterRowSettings') && data.waterRowSettings != null && data.waterRowSettings.length > 0) {
      for (let w = 0; w < nRow; w++) {
        // if (!data.waterRowSettings[w]) {
        //     imagesHeatmap.push(_this.setAnnotationFromXandY(nCol - 0.5, w, nRow, nCol, null, "assets/images/water_disabled.png", 0.5, 0.5, "middle", "left"));
        //     imagesContour.push(_this.setAnnotationFromXandY(nCol - 0.5, w, nRow, nCol, null, "assets/images/water_disabled.png", 0.5, 0.5, "middle", "left"));
        // }
        if (data.waterRowSettings[w]) {
          imagesHeatmap.push(_this.setAnnotationFromXandY(nCol - 1, w, nRow, nCol, null, "assets/images/water.png", 0.5, 0.5, "middle", "left"));
          imagesContour.push(_this.setAnnotationFromXandY(nCol - 1, w, nRow, nCol, null, "assets/images/water.png", 0.5, 0.5, "middle", "left"));
          // imagesHeatmap.push(_this.setAnnotationFromXandY(nCol, w, nRow, nCol, null, "assets/images/water.png", 0.5, 0.5, "middle", "left"));
          // imagesContour.push(_this.setAnnotationFromXandY(nCol, w, nRow, nCol, null, "assets/images/water.png", 0.5, 0.5, "middle", "left"));
        }
      }
    }

    // let plotTypeDefault = 'surface';
    let plotTypeDefault = 'contour';

    _this.c_heatPlotType = _this.cacheService.get('heatPlotType');
    if (_this.c_heatPlotType == null) {
      _this.c_heatPlotType = plotTypeDefault;
    }

    _this.c_plotType = _this.cacheService.get('plotType');
    if (_this.c_plotType == null) {
      _this.c_plotType = plotTypeDefault;
    }

    _this.reactorTitle = 'plotTypeTitle.' + _this.c_plotType;

    // console.log('x_data', x_data);
    // console.log('y_data', y_data);
    // console.log('z_dataHeat', z_dataHeat);
    // console.log('z_dataWFlow', z_dataWFlow);
    // console.log('z_dataWIrr', z_dataWIrr);
    // console.log('z_dataWTargetPerc', z_dataWTargetPerc);
    // console.log(_this.c_plotType)

    traces = [
      // heat trace
      {
        x: x_data,
        y: y_data,
        z: z_dataHeat,
        type: _this.c_heatPlotType,
        text: hover_z,
        contours: {
          coloring: "heatmap",
          showlines: false
        },
        coloraxis: 'coloraxis',
        hoverinfo: "text",
        visible: false
      },
      // flow trace
      {
        x: x_data,
        y: y_data,
        z: z_dataWFlow,
        type: 'heatmap',
        text: hover_z,
        showscale: false,
        contours: {
          coloring: "heatmap",
          showlines: false
        },
        coloraxis: 'coloraxis',
        hoverinfo: "text",
        visible: false
      },
      // irrig trace
      {
        x: x_data,
        y: y_data,
        z: z_dataWIrr,
        type: 'heatmap',
        text: hover_z,
        showscale: false,
        contours: {
          coloring: "heatmap",
          showlines: false
        },
        coloraxis: 'coloraxis',
        hoverinfo: "text",
        visible: false
      },
      // target trace
      {
        x: x_data,
        y: y_data,
        z: z_dataWTargetPerc,
        type: 'heatmap',
        text: hover_z,
        showscale: false,
        contours: {
          coloring: "heatmap",
          showlines: false
        },
        coloraxis: 'coloraxis',
        hoverinfo: "text",
        visible: false
      }
    ];

    // check last plotType for update
    if (_this.c_plotType == 'surface' || _this.c_plotType == 'heatmap' || _this.c_plotType == 'contour') {
      traces[0].type = _this.c_plotType;
      traces[0].visible = true;
    }
    if (_this.c_plotType == 'wFlow') {
      traces[1].visible = true;
    }
    if (_this.c_plotType == 'wIrrig') {
      traces[2].visible = true;
    }
    if (_this.c_plotType == 'wTargt') {
      traces[3].visible = true;
    }

    let loadDirection = 0;
    // 0 = load up, unload down     // DEFAULT
    // 1 = load down, unload up
    // 2 = load left, unload right  // TODO
    // 3 = load right, unload left  // TODO
    if (_this.machine.hasOwnProperty('loadDirection')) {
      if (_this.machine.loadDirection == 1) {
        loadDirection = 1;
      } else if (_this.machine.loadDirection == 2) {
        loadDirection = 2;
      } else if (_this.machine.loadDirection == 3) {
        loadDirection = 3;
      }
    }

    let yUnload = -0.11;
    let yLoad = 1.06;
    let txtLoad = "PLANT_SUPERVISION.LOAD_UP";
    let txtUnload = "PLANT_SUPERVISION.UNLOAD_DOWN";

    if (loadDirection == 0) {
      yLoad = 1.05;
      yUnload = -0.11;
      txtLoad = "PLANT_SUPERVISION.LOAD_UP";
      txtUnload = "PLANT_SUPERVISION.UNLOAD_DOWN";
    }
    if (loadDirection == 1) {
      yLoad = -0.2;
      yUnload = 1.05;
      txtLoad = "PLANT_SUPERVISION.LOAD_DOWN";
      txtUnload = "PLANT_SUPERVISION.UNLOAD_UP";
    }
    // if (loadDirection == 2) {
    //     yUnload = 0;
    //     yLoad = 0;
    // } 
    // if (loadDirection == 3) {
    //     yUnload = 0;
    //     yLoad = 0;
    // }

    let annotations01 = [{
      text: _this.translate.instant('PLANT_SUPERVISION.MAINTENANCE'),
      x: -0.07,
      // y: 0.5,
      xanchor: 'right',
      yanchor: 'middle',
      xref: 'paper',
      yref: 'paper',
      showarrow: false,
      textangle: -90,
      font: {
        size: 19
      }
    }, {
      text: _this.translate.instant(txtUnload),
      // x: 0.5,
      y: yUnload,
      xanchor: 'middle',
      yanchor: 'bottom',
      xref: 'paper',
      yref: 'paper',
      showarrow: false,
      font: {
        size: 19
      }
    }, {
      text: _this.translate.instant(txtLoad),
      // x: 0.5,
      y: yLoad,
      xanchor: 'middle',
      yanchor: 'top',
      xref: 'paper',
      yref: 'paper',
      showarrow: false,
      font: {
        size: 19
      }
    }];

    let xRelEven = x_data.filter(function (x, idx) {
      return (idx % 2 == 0);
    });
    let yRelEven = y_data.filter(function (y, idx) {
      return (idx % 2 == 0);
    });

    let xActEven: any = xRelEven.map(x => (x * xMax / nCol).toFixed(0));
    let yActEven: any = yRelEven.map(y => (y * yMax / nRow).toFixed(0));

    if (!xRelEven.includes(nCol - 1)) {
      xRelEven.push(nCol - 1);
      xActEven.push(xMax.toString());
    } else {
      xActEven[xActEven.length - 1] = xMax;
    }
    if (!yRelEven.includes(nRow - 1)) {
      yRelEven.push(nRow - 1);
      yActEven.push(yMax.toString());
    } else {
      yActEven[yActEven.length - 1] = yMax;
    }

    let xRelEvenP1 = xRelEven.map(x => (x + 1).toString());
    let yRelEvenP1 = yRelEven.map(y => (y + 1).toString());

    let xActEvenAvg = xActEven.map((x, i) => {
      if (i == 0) {
        return 0;
      }
      if (i != xActEven.length - 1) {
        let k = xTicks.findIndex((h) => {
          return parseInt(h.from) == parseInt(x);
        });
        if (k != -1) {
          x = ((parseInt(xTicks[k].from) + parseInt(xTicks[k].to)) / 2).toFixed(0);
        }
      }
      return parseInt(x);
    });
    let yActEvenAvg = yActEven.map((y, i) => {
      if (i == 0) {
        return 0;
      }
      if (i != yActEven.length - 1) {
        let k = yTicks.findIndex((h) => {
          return parseInt(h.from) == parseInt(y);
        });
        if (k != -1) {
          y = ((parseInt(yTicks[k].from) + parseInt(yTicks[k].to)) / 2).toFixed(0);
        }
      }
      return y;
    });

    // let xRelEvenHM = xRelEven.map((x, idx, array) => (idx != array.length - 1) ? parseFloat(x) - 0.5 : parseFloat(x) + 0.5);
    // let yRelEvenHM = yRelEven.map((x, idx, array) => (idx != array.length - 1) ? parseFloat(x) - 0.5 : parseFloat(x) + 0.5);

    let xRelEvenHM = _this.clonerService.deepClone(xRelEven);
    xRelEvenHM[0] = xRelEvenHM[0] - 0.5;
    xRelEvenHM[xRelEvenHM.length - 1] = xRelEvenHM[xRelEvenHM.length - 1] + 0.5;
    let yRelEvenHM = _this.clonerService.deepClone(yRelEven);
    yRelEvenHM[0] = yRelEvenHM[0] - 0.5;
    yRelEvenHM[yRelEvenHM.length - 1] = yRelEvenHM[yRelEvenHM.length - 1] + 0.5;

    let xRangeCnt = [xRelEvenHM[0], xRelEvenHM[xRelEvenHM.length - 1]];
    _this.cacheService.set('xRangeCnt', xRangeCnt);
    let yRangeCnt = [yRelEvenHM[0], yRelEvenHM[yRelEvenHM.length - 1]];
    _this.cacheService.set('yRangeCnt', yRangeCnt);
    // console.log(xRangeCnt, yRangeCnt);

    let c_axisTickTextType = _this.cacheService.get('axisTickTextType');
    if (c_axisTickTextType == null) {
      c_axisTickTextType = 'mm';
    }

    let updatemenus = [
      {
        buttons: [
          {
            args: [{
              'type': 'surface',
              'visible': [true, false, false, false],
              'plotType': 'surface'
            }, {
              'images': [],
              'annotations': [],
              'xaxis.tickvals': (c_axisTickTextType == 'mm') ? xRelEvenHM : xRelEven,
              'yaxis.tickvals': (c_axisTickTextType == 'mm') ? yRelEvenHM : yRelEven,
              'xaxis.range': xRangeCnt,
              'yaxis.range': yRangeCnt,
              // 'xaxis.autorange': false,
              // 'yaxis.autorange': false,
              'coloraxis': _this.colorScaleHeat,
              'xaxis.visible': false,
              'yaxis.visible': false,
            }],
            label: (_this.translate.instant('plotType.surface') != 'plotType.surface') ? _this.translate.instant('plotType.surface') : "3D Surface",
            method: 'update'
          },
          {
            args: [{
              'type': 'heatmap',
              'visible': [true, false, false, false],
              'plotType': 'heatmap'
            }, {
              'images': imagesHeatmap,
              'annotations': annotations01,
              'xaxis.tickvals': (c_axisTickTextType == 'mm') ? xRelEvenHM : xRelEven,
              'yaxis.tickvals': (c_axisTickTextType == 'mm') ? yRelEvenHM : yRelEven,
              'xaxis.range': xRangeCnt,
              'yaxis.range': yRangeCnt,
              // 'xaxis.autorange': false,
              // 'yaxis.autorange': false,
              'coloraxis': _this.colorScaleHeat,
              'xaxis.visible': true,
              'yaxis.visible': true,
            }],
            label: (_this.translate.instant('plotType.heatmap') != 'plotType.heatmap') ? _this.translate.instant('plotType.heatmap') : "Heatmap",
            method: 'update'
          },
          {
            args: [{
              'type': 'contour',
              'visible': [true, false, false, false],
              'plotType': 'contour'
            }, {
              'images': imagesContour,
              'annotations': annotations01,
              'xaxis.tickvals': (c_axisTickTextType == 'mm') ? xRelEvenHM : xRelEven,
              'yaxis.tickvals': (c_axisTickTextType == 'mm') ? yRelEvenHM : yRelEven,
              'xaxis.range': xRangeCnt,
              'yaxis.range': yRangeCnt,
              // 'xaxis.autorange': false,
              // 'yaxis.autorange': false,
              'coloraxis': _this.colorScaleHeat,
              'xaxis.visible': true,
              'yaxis.visible': true,
            }],
            label: (_this.translate.instant('plotType.contour') != 'plotType.contour') ? _this.translate.instant('plotType.contour') : "Contour",
            method: 'update'
          },
          {
            args: [{
              'type': 'heatmap',
              'visible': [false, true, false, false],
              'plotType': 'wFlow'
            }, {
              'images': imagesHeatmap,
              'annotations': annotations01,
              'xaxis.tickvals': (c_axisTickTextType == 'mm') ? xRelEvenHM : xRelEven,
              'yaxis.tickvals': (c_axisTickTextType == 'mm') ? yRelEvenHM : yRelEven,
              'xaxis.range': xRangeCnt,
              'yaxis.range': yRangeCnt,
              // 'xaxis.autorange': false,
              // 'yaxis.autorange': false,
              'coloraxis': _this.colorScaleWFlow,
              'xaxis.visible': true,
              'yaxis.visible': true,
            }],
            label: (_this.translate.instant('plotType.wFlow') != 'plotType.wFlow') ? _this.translate.instant('plotType.wFlow') : "Flow",
            method: 'update'
          },
          {
            args: [{
              'type': 'heatmap',
              'visible': [false, false, true, false],
              'plotType': 'wIrrig'
            }, {
              'images': imagesHeatmap,
              'annotations': annotations01,
              'xaxis.tickvals': (c_axisTickTextType == 'mm') ? xRelEvenHM : xRelEven,
              'yaxis.tickvals': (c_axisTickTextType == 'mm') ? yRelEvenHM : yRelEven,
              'xaxis.range': xRangeCnt,
              'yaxis.range': yRangeCnt,
              // 'xaxis.autorange': false,
              // 'yaxis.autorange': false,
              'coloraxis': _this.colorScaleWIrr,
              'xaxis.visible': true,
              'yaxis.visible': true,
            }],
            label: (_this.translate.instant('plotType.wIrrig') != 'plotType.wIrrig') ? _this.translate.instant('plotType.wIrrig') : "Irrig",
            method: 'update'
          },
          {
            args: [{
              'type': 'heatmap',
              'visible': [false, false, false, true],
              'plotType': 'wTargt'
            }, {
              'images': imagesHeatmap,
              'annotations': annotations01,
              'xaxis.tickvals': (c_axisTickTextType == 'mm') ? xRelEvenHM : xRelEven,
              'yaxis.tickvals': (c_axisTickTextType == 'mm') ? yRelEvenHM : yRelEven,
              'xaxis.range': xRangeCnt,
              'yaxis.range': yRangeCnt,
              // 'xaxis.autorange': false,
              // 'yaxis.autorange': false,
              'coloraxis': _this.colorScaleWTargetPerc,
              'xaxis.visible': true,
              'yaxis.visible': true,
            }],
            label: (_this.translate.instant('plotType.wTargt') != 'plotType.wTargt') ? _this.translate.instant('plotType.wTargt') : "Target",
            method: 'update'
          }
        ],
        direction: 'left',
        pad: {
          r: 10,
          t: 10
        },
        showactive: false,
        type: 'buttons',
        x: 0.05,
        xanchor: 'left',
        y: 1.15,
        yanchor: 'top'
      },
      {
        buttons: [{
          args: [{
            'plotType': _this.cacheService.get('plotType')
          }, {
            'xaxis.ticktext': xActEvenAvg,
            'yaxis.ticktext': yActEvenAvg,
            'xaxis.tickvals': xRelEvenHM,
            'yaxis.tickvals': yRelEvenHM,
            'scene.xaxis.ticktext': xActEvenAvg,
            'scene.yaxis.ticktext': yActEvenAvg,
            'axisTickTextType': 'mm'
          }],
          label: 'mm',
          method: 'update'
        },
        {
          args: [{
            'plotType': _this.cacheService.get('plotType')
          }, {
            'xaxis.ticktext': xRelEvenP1,
            'yaxis.ticktext': yRelEvenP1,
            'xaxis.tickvals': xRelEven,
            'yaxis.tickvals': yRelEven,
            'scene.xaxis.ticktext': xRelEvenP1,
            'scene.yaxis.ticktext': yRelEvenP1,
            'axisTickTextType': 'coordinate'
          }],
          label: 'Coordinate',
          method: 'update'
        }],
        direction: 'up',
        pad: {
          'r': 10,
          't': 30
        },
        showactive: true,
        // active: c_axisTickTextType == 'mm' ? 0 : 1,
        type: 'dropdown',
        x: -0.15,
        xanchor: 'left',
        y: -0.2,
        yanchor: 'bottom'
      // }, {
      //   buttons: [
      //     {
      //       args: [{
      //         'setZoom': true
      //       }, {}],
      //       label: _this.translate.instant('PLANT_SUPERVISION.SET_ZOOM'),
      //       method: 'update'
      //     }
      //   ],
      //   direction: 'left',
      //   pad: {
      //     r: 10,
      //     t: 10
      //   },
      //   showactive: false,
      //   type: 'buttons',
      //   x: -0.15,
      //   xanchor: 'left',
      //   y: 1.15,
      //   yanchor: 'top'
      }
    ];

    let plotLayout: any = {
      // autosize: true,
      coloraxis: _this.getColorScale(_this),
      scene: {
        aspectmode: "manual",
        aspectratio: {
          x: 1,
          // y: (nRow / nCol),
          y: (yMax / xMax),
          z: 0.25,
        },
        camera: {
          up: {
            x: 0,
            y: 0,
            z: 1
          },
          center: {
            x: 0,
            y: 0,
            z: 0
          },
          eye: {
            x: -0.75,
            y: -0.75,
            z: 0.25
          },
        },
        xaxis: {
          tickmode: "array",
          tickvals: xRelEvenHM,
          // ticktext: xActEven,
          ticktext: c_axisTickTextType == 'mm' ? xActEvenAvg : xRelEvenP1,
          // tickfont: { size: 25 },
          range: xRangeCnt
        },
        yaxis: {
          tickmode: "array",
          tickvals: xRelEvenHM,
          // ticktext: yActEven,
          ticktext: c_axisTickTextType == 'mm' ? yActEvenAvg : yRelEvenP1,
          // tickfont: { size: 25 },
          range: yRangeCnt
        },
        zaxis: {
          // tickfont: { size: 25 },
          range: [z_min - 2, z_max + 2]
        }
      },
      uirevision: true,
      xaxis: {
        tickmode: "array",
        tickvals: _this.isTypeHeatmap(traces[0].type) ? xRelEvenHM : xRelEvenHM,
        ticktext: c_axisTickTextType == 'mm' ? xActEvenAvg : xRelEvenP1,
        showgrid: false,
        zeroline: false,
        // autorange: true,
        autorange: false,
        range: _this.isTypeHeatmap(traces[0].type) ? xRangeCnt : xRangeCnt,
        visible: _this.c_plotType == 'surface' ? false : true
      },
      yaxis: {
        tickmode: "array",
        tickvals: _this.isTypeHeatmap(traces[0].type) ? yRelEvenHM : yRelEvenHM,
        ticktext: c_axisTickTextType == 'mm' ? yActEvenAvg : yRelEvenP1,
        showgrid: false,
        zeroline: false,
        // autorange: true,
        autorange: false,
        range: _this.isTypeHeatmap(traces[0].type) ? yRangeCnt : yRangeCnt,
        scaleanchor: "x",
        scaleratio: 1,
        visible: _this.c_plotType == 'surface' ? false : true
      },
      zaxis: {
        autorange: true
      },
      // autocontour: false,
      margin: {
        t: 5,
        r: 5,
        b: 100,
        l: 5,
        pad: 5
        // pad: traces[0].type == 'surface' ? 25 : (_this.isTypeHeatmap(traces[0].type) ? 25 : 100),
      },
      // images: traces[0].type == 'surface' ? [] : (_this.isTypeHeatmap(traces[0].type) ? imagesHeatmap : imagesContour),
      // annotations: traces[0].type == 'surface' ? [] : annotations01,
      images: _this.c_plotType == 'surface' ? [] : (_this.isTypeHeatmap(_this.c_plotType) ? imagesHeatmap : imagesContour),
      annotations: _this.c_plotType == 'surface' ? [] : annotations01,
      updatemenus: updatemenus,
      paper_bgcolor: 'transparent',
      plot_bgcolor: 'transparent'
    };

    _this.cacheService.set("defaultLayoutXaxis", plotLayout.xaxis);
    _this.cacheService.set("defaultLayoutYaxis", plotLayout.yaxis);

    // console.log(traces)
    // console.log(plotLayout.scene.camera)

    widgetParams = {
      data: traces,
      layout: plotLayout,
      heatmapDetail: true,
      utils: {
        xMax: xMax,
        yMax: yMax,
        xTicks: xTicks,
        yTicks: yTicks,
        z_dataHeat: z_dataHeatFull
      },
      chartBackgroundCustom: true,
      displayModeBar: true,
      modeBarButtonsToRemove: ['resetCameraDefault3d', 'orbitRotation', 'tableRotation']
    };

    // if (mobile()) {
    //   let width = null;
    //   if ($('#' + widget.id + '-card') != null && $('#' + widget.id + '-card').width() != null) width = $('#' + widget.id + '-card').width() - 25;

    //   widgetParams.layout.height = 500;
    //   widgetParams.layout.width = width;
    //   widgetParams.layout.updatemenus[0].y = 1.2;
    //   // widgetParams.displayModeBar = false;
    // }

    _this.cacheService.set('heatPlotType', _this.c_heatPlotType);

    _this.cacheService.set('plotType', _this.c_plotType);
    widgetParams.plotType = _this.c_plotType

    _this.cacheService.set('axisTickTextType', c_axisTickTextType);

    if (!_this.historyView && !_this.cyclesReviewView) widgetParams.plotId = 'sct-chart'

    widgetParams.actions = {
      plotly_update:
        function (data: any) {
          // console.log('plotly_update', data)

          // if (!data.data[0].setZoom) {
            _this.updateMenuTrigger(_this, data.data[0].plotType, data.layout.axisTickTextType)
            if (_this.c_plotType) _this.reactorTitle = 'plotTypeTitle.' + _this.c_plotType;
          // }
          // else {
          //   console.log('setZoom')
          //   _this.openSetZoomDialog(_this)
          // }
        }
    }

    return {
      layout: plotLayout,
      traces: traces,
      params: widgetParams
    }

  }

  updateMenuTrigger(_this, newType, newTickType) {
    // console.log('updateMenuTrigger')
    _this.c_plotType = newType
    _this.c_axisTickTextType = newTickType

    if (_this.c_heatPlotType != null) _this.cacheService.set('heatPlotType', _this.c_heatPlotType);
    if (_this.c_plotType != null) _this.cacheService.set('plotType', _this.c_plotType);
    if (_this.c_axisTickTextType != null) _this.cacheService.set('axisTickTextType', _this.c_axisTickTextType);
    // console.log(_this.c_heatPlotType, _this.c_plotType, _this.c_axisTickTextType)
  }

  setAnnotationFromXandY(x, y, nRow, nCol, actPos, img, sizex, sizey, yanchor, xanchor) {
    if (x != null && y != null && nRow != null && nCol != null) {
      let xPos = null;
      let yPos = null;
      if (sizex == null) sizex = 1;
      if (sizey == null) sizey = 1;
      if (yanchor == null) yanchor = "middle";
      if (xanchor == null) xanchor = "center";

      // if (x == 0) {
      //     xPos = 0.5;
      // } else if (x == nCol - 1) {
      //     xPos = nCol - 1.5;
      // } else {
      xPos = x;
      // }

      // if (y == 0) {
      //     yPos = 0.6;
      // } else if (y == nRow - 1) {
      //     yPos = nRow - 1.6;
      // } else {
      yPos = y;
      // }

      let src
      if (img) {
        src = img;
      } else {
        src = actPos != null ? ("assets/images/" + actPos + ".png") : ("assets/images/crane.png");
      }

      return {
        source: src,
        xref: "x",
        yref: "y",
        layer: "above",
        x: xPos,
        y: yPos,
        sizex: sizex,
        sizey: sizey,
        // xanchor: "left",
        yanchor: yanchor,
        xanchor: xanchor,
      };
    }
  }

  public recipeColors = ['rgb(30, 117, 180)', 'rgb(109, 174, 219)', 'rgb(29, 53, 87)'];
  public defaultPlotlyColors = ['#1f77b4', '#2ca02c', '#d62728', '#ff7f0e', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf', '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];

  customColorbarTicks(colorArray) {
    let tickvals = []
    let values = colorArray.map(x => x[0]).filter((x, i, self) => self.indexOf(x) === i)
    let median = values.map((x, i, self) => {
      if (x > 0) return x - (self[1] / 2)
      else return 0
    })
    values.forEach((value, i, self) => {
      if (value <= 0) tickvals.push(value)
      else {
        let valuePlus = value + (self[1] * 0.04)
        let valueLess = value - (self[1] * 0.04)
        tickvals.push(valueLess)
        tickvals.push(valuePlus)
      }
    })

    tickvals = [...tickvals, ...median].filter((x, i, self) => self.indexOf(x) === i).sort((a, b) => {
      if (b - a < 0) return 1
      if (b - a == 0) return 0
      if (b - a > 0) return -1
    })

    let ticksNumberPerBlock = 3
    let blocksNumber = tickvals.length / ticksNumberPerBlock
    let ticktext = []
    for (let i = 0; i < blocksNumber; i++) {
      ticktext.push([0, values[1] / 2, values[1]])
    }
    ticktext = ticktext.flat()
    let colorbar = {
      tickvals: tickvals,
      ticktext: ticktext
    }
    // console.log('colorbar', colorbar)
    return colorbar
  }

  isTypeHeatmap(type) {
    if (type == 'heatmap' || type == 'wFlow' || type == 'wIrrig' || type == 'wTargt') return true;
    return false;
  }

  getColorScale(_this) {
    if (_this.c_plotType == 'surface' || _this.c_plotType == 'heatmap' || _this.c_plotType == 'contour') return _this.colorScaleHeat;
    if (_this.c_plotType == 'wFlow') return _this.colorScaleWFlow;
    if (_this.c_plotType == 'wIrrig') return _this.colorScaleWIrr;
    if (_this.c_plotType == 'wTargt') return _this.colorScaleWTargetPerc;
  }

  isNumber(value) {
    return (parseInt(value) % 1 === 0) ? true : false;
  }

  // openSetZoomDialog(_this) {

  //   let dialogData = {
  //     showDialog: true,
  //     title: "hello",
  //     // unit: this.translate.instant((dp.unit != null && dp.unit != '') ? dp.unit : '-'),
  //     // defaultUnit: dp.defaultUnit,
  //     // yAxisRange: dp.yAxisRange,
  //     // datapointList: dp.datapointList ?? [dp.datapoint],
  //     machineId: this.machineId,
  //     machine: this.machine,
  //     timezone: this.machine?.timezone,
  //     // completeDatapoint: this.clonerService.deepClone(dp)
  //   };

  //   const setZoomDialog = _this.dialog.open(SctSetZoomDialogComponent, {
  //     width: _this.isSmThanTablet ? 'fit-content' : '60%',
  //     data: dialogData,
  //     panelClass: 'datapoint-dialog'
  //   });

  //   setZoomDialog.afterClosed().subscribe(result => {
  //     // console.log(`Dialog result: ${result}`);
  //   });
  // }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  

  // public dateSteps = []
  public disabledPlay = false
  public sldRun: any = {
    // value: dateSteps[0],
    options: {
      // stepsArray: dateSteps,
      disabled: true,
      showSelectionBar: true,
      translate: function (date) {
        if (date != null)
          //   return date.toDateString()
          return moment(date).format("MM/DD HH:mm")
        return ''
      },
      onStart: function (id) {
        // stopPollingMD()
      },
      onEnd: function (id) {
        // restartGetMonitoringData()
      }
    }
  }

  nextDateStep(_this, date) {
    let next = moment(date)
    next.add(_this.stepDropdown.s, 'seconds')
    return next;
  }

  updateStepsDates() {
    let dates = []
    let over = false
    let date: any = moment(this.cycleFrom).toDate()
    let cycleTo = moment(this.cycleTo).toDate()
    while (!over) {
      // dates.push(date)
      if (this.filterService.filterDate(date, cycleTo, 'after')) {
        over = true
        // dates.push(cycleTo)
      }

      if (!over) dates.push(date)
      if (over) dates.push(cycleTo)

      // // date = getTo_RunningStep(date)
      date = this.nextDateStep(this, date)
      date = moment(date).toDate()
    }
    return dates
  }

  // initSldRun() {
  //   this.updateStepsDates()
  //   // this.sldRun.value = this.sldRun.options.stepsArray[0]
  //   this.sldRun.value = this.sldRun.options.stepsArray[this.sldRun.options.stepsArray.length - 1]
  //   this.disabledPlay = true
  // }

  initSlider(_this: any, stepsArray = [], last = true) {
    // console.log('initSlider')
    if (stepsArray.length == 0) stepsArray = _this.updateStepsDates()

    _this.sliderValue = last ? stepsArray[stepsArray.length - 1] : stepsArray[0]

    _this.sliderConf = {
      show: true,
      // disabled: false,
      value: _this.sliderValue,
      options: {
        showSelectionBar: true,
        floor: 0,
        ceil: stepsArray[stepsArray.length - 1],
        step: stepsArray.length,

        stepsArray: stepsArray.map((date: Date) => {
          return { value: date.getTime() };
        }),
        translate: (value: number, label: LabelType): string => {
          return _this.filterService.parseMoment(value, 'DD/MM HH:mm');
        }
      },
      userChangeEnd: function () {
        // _this.filterElements();
        _this.sliderRunning = false

        if (!_this.cyclesReviewView) _this.getMonitoringData(_this)
        else {
          _this.countTotal = _this.sliderConf.options.stepsArray.findIndex(x => x.value == _this.sliderConf.value)
          _this.plotMonitoringData_cycleReview(_this)
        }
      }

    };
  }

  sliderPlay(play) {
    if (play) {
      this.sliderPlayStop = true

      if (!this.cyclesReviewView) this.getMonitoringData(this)
      else this.plotMonitoringData_cycleReview(this)

    } else {
      this.sliderPlayStop = false
    }
  }

  changeStepDropdown(stepDropdown) {
    this.stepDropdown = stepDropdown;
    this.getMonitoringData(this)
  }

  changePollingDropdown(pollingDropdown) {
    this.pollingDropdown = pollingDropdown

    if (!this.cyclesReviewView) this.getMonitoringData(this)
    else this.plotMonitoringData_cycleReview(this)
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.stepDropdown = this.clonerService.deepClone(this.stepDropdownList[1])
    this.pollingDropdown = this.clonerService.deepClone(this.pollingDropdownList[1])

    // historyView
    let queryParams = this.route.snapshot.queryParams
    if (queryParams.hasOwnProperty('from') || queryParams.hasOwnProperty('to')) {
      this.historyView = true
      this.cycleFrom = queryParams.from
      this.cycleTo = queryParams.to
      this.cycleDuration = this.filterService.parseDuration(null, null, 'hh:mm:ss', this.cycleFrom, this.cycleTo)

      this.backButton = [this.machineId, "machine-recorder", "cycle-list"]
      this.internalDataService.setBackButton(this.backButton)
    }

    // cyclesReviewView
    if (this.route.snapshot?.routeConfig?.path?.endsWith('cycles-review')) {
      this.cyclesReviewView = true

      this.backButton = [this.machineId, "machine-recorder", "cycle-list"]
      this.internalDataService.setBackButton(this.backButton)

      this.cyclesList = this.cacheService.get('selectedCycles')
      if (this.cyclesList && this.cyclesList.length > 0) {
        // this.cyclesList = JSON.parse(sessionStorage.getItem('selectedCycles'))
        this.cyclesList.sort(this.filterService.sortByProperty('timeStart', 'asc', true))
      }

      this.pollingDropdownList = [
        { id: 1, s: 1000, label: '1 sec' },
        { id: 2, s: 2000, label: '2 sec' },
        { id: 5, s: 5000, label: '5 sec' },
        { id: 10, s: 10000, label: '10 sec' }
      ]
      this.pollingDropdown = this.clonerService.deepClone(this.pollingDropdownList[1])
    }

    this.initSlider(this)

    this.updateBreadcrumb(this)

    this.dispatcherService.getDispatch(this, 300)

  }

  ngOnChanges() {
    // console.log(this);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pollingMachines.unsubscribe() } catch (error) { }
    try { this.pollingCycles.unsubscribe() } catch (error) { }
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.datapointSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
  }

}