<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"
      [intAggrs]="intervalAggregations" (selectedAggr)="selectAggregation($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    [fxLayoutAlign]="pageState.value >= 5 ? 'start stretch' : (pageState.value == 0 ? 'start center' : 'center center')"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar -->
    <div class="ff-toolbar pb-3 pt-1" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center" *ngIf="aggregations != null || availableMachines != null">

      <!-- aggregations -->
      <button mat-button class="filter-button" *ngFor="let aggr of aggregations" (click)="openAggrDialog(aggr)"
        [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
        <mat-icon *ngIf="aggr.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': aggr.label}">
          {{aggr.icon.icon }}</mat-icon>
        <mat-icon *ngIf="aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
          translate-attr="{'aria-label': aggr.label}"></mat-icon>
        <i *ngIf="aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor'" class="icon {{ aggr.icon.icon }}"
          translate-attr="{'aria-label': aggr.label}"></i>
        <span>{{ aggr.selectionLabel }}</span>
        <div fxLayout="row" fxLayoutAlign="center center"
          *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
          {{ aggregationsPayload[aggr.id].length }}
        </div>
      </button>

      <span fxFlex fxShow="false" fxShow.gt-sm></span>

      <ff-available-machines-selection [availableMachines]="availableMachines"
        (selected)="machineSelectionChange($event)"></ff-available-machines-selection>

    </div>

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 6" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 6" fxLayout="column" class="w100 h100">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
        class="h100 w100" fxLayoutGap="8px">

        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="w100 h100" fxLayoutGap="8px">
          <ff-widget fxFlex="100" fxShow.lt-md="false"
            [widget]="{type: 'ff-plotly-chart', data: leanAnalyticsData, machineProfile: machine.profile, config: leanAnalyticsData.dataConfig, customHeight: '100%' }">
          </ff-widget>
        </div>

        <div fxFlex.gt-sm="{{dashboardConfig.secondColumnFlex}}" fxLayout="column" fxLayoutAlign="start stretch"
          class="w100 h100" fxLayoutGap.gt-sm="8px" fxLayout.sm="row wrap" fxLayoutAlign.sm="start stretch">

          <ff-widget *ngFor="let w of dashboardConfig.widgets" fxFlex.sm="0 1 calc(50% - 8px)" fxFlex.gt-sm="{{w.flex}}"
            [fxShow.lt-md]="!w.hideMobile" [widget]="{ 
              type: w.type,
              data: leanAnalyticsData,
              machineProfile: machine.profile,
              config: w.config,
              title: w.title,
              tooltip: w.tooltip,
              unit: w.unit,
              productionUnit: w.productionUnit }">
          </ff-widget>

        </div>

      </div>

    </div>

  </div>

</div>