import { Injectable, NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';

import { ErrorPageComponent } from './pages/error-page/error-page.component';
// import { TestPageComponent } from './pages/test-page/test-page.component';
import { MachineSettingsComponent } from './pages/machine-settings/machine-settings.component';
import { OptionsComponent } from './pages/options/options.component';

// homepage
import { HomepageGridComponent } from './pages/homepage/grid/grid.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { HomepageListComponent } from './pages/homepage/list/list.component';
import { HomepageMapComponent } from './pages/homepage/map/map.component';

// Machine Supervision
import { AlarmsTableComponent } from './pages/machine-supervision/alarms/alarms.component';
import { ClothMonitoringComponent } from './pages/machine-supervision/cloth-monitoring/cloth-monitoring.component';
import { CycleTimelineComponent } from './pages/machine-supervision/cycle-timeline/cycle-timeline.component';
import { SignalationsTableComponent } from './pages/machine-supervision/signalations/signalations.component';
import { StatesTimelineComponent } from './pages/machine-supervision/states/states.component';

// Manual Input
import { ProdSheetComponent } from './pages/manual-input/prod-sheet/prod-sheet.component';
import { StopsComponent } from './pages/manual-input/stops/stops.component';

// Cycle Traceability
import { CycleDashboardComponent } from './pages/cycle-traceability/cycle-detail/cycle-dashboard/cycle-dashboard.component';
import { CycleDetailTimelineComponent } from './pages/cycle-traceability/cycle-detail/cycle-detail-timeline/cycle-detail-timeline.component';
import { PhaseVariablesComponent } from './pages/cycle-traceability/cycle-detail/phase-variables/phase-variables.component';
import { ProcessLogComponent } from './pages/cycle-traceability/cycle-detail/process-log/process-log.component';
import { CycleTraceabilityComponent } from './pages/cycle-traceability/cycles/cycles.component';

// Production Traceability
import { ProductionTraceabilityDetailComponent } from './pages/lean-analytics/production-traceability-detail/production-traceability-detail.component';
import { ProductionTraceabilityComponent } from './pages/lean-analytics/production-traceability/production-traceability.component';

// Breakdowns
import { BreakdownsAlarmsComponent } from './pages/breakdowns/alarms/alarms.component';
import { BreakdownsSignalationsComponent } from './pages/breakdowns/signalations/signalations.component';
import { MachineStatusLogComponent } from './pages/machine-supervision/machine-status-log/machine-status-log.component';

// Lean Analytics
import { AlertsComponent } from './pages/alerts/alerts.component';
import { CompleteConsumptionComponent } from './pages/consumption-analytics/complete-consumption/complete-consumption.component';
import { DoubleKpiAnalyticsComponent } from './pages/kpi-analytics/double-kpi-analytics/double-kpi-analytics.component';
import { SingleKpiAnalyticsComponent } from './pages/kpi-analytics/single-kpi-analytics/single-kpi-analytics.component';
import { LeanBreakdownsSignalationsComponent } from './pages/lean-analytics/breakdowns-signalations/breakdowns-signalations.component';
import { LeanBreakdownsComponent } from './pages/lean-analytics/breakdowns/breakdowns.component';
import { LeanFaultAnalysisComponent } from './pages/lean-analytics/fault-analysis/fault-analysis.component';
import { LeanOeeTrendComponent } from './pages/lean-analytics/oee-trend/oee-trend.component';
import { LeanProductionTrendComponent } from './pages/lean-analytics/production-trend/production-trend.component';
import { LeanScrapAnalyticsComponent } from './pages/lean-analytics/scrap-analytics/scrap-analytics.component';
import { ContinuousExplorationComponent } from './pages/machine-recorder/continuous-exploration/continuous-exploration.component';
import { CycleExplorationComponent } from './pages/machine-recorder/cycle-exploration/cycle-exploration.component';
import { RbaSettingsComponent } from './pages/machine-settings/rba-settings/rba-settings.component';
import { StateTimelineAdvancedComponent } from './pages/machine-supervision/state-timeline-advanced/state-timeline-advanced.component';
import { CalendarComponent } from './pages/planning-calendar/calendar/calendar.component';
import { ShiftTemplatesComponent } from './pages/planning-calendar/shift-templates/shift-templates.component';
// import { LeanProductionTrendComponent } from './pages/lean-analytics/production-trend/production-trend.component';

class UserToken { }
class Permissions {
  canActivate(user: UserToken, id: string): boolean {
    let currentUser: any = localStorage.getItem('user');
    try {
      currentUser = JSON.parse(currentUser);
    } catch (error) {
      console.log(error);
    }
    console.log(currentUser);

    return false;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CanActivateTeam implements CanActivate {
  constructor(private permissions: Permissions, private currentUser: UserToken, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    console.log(route, state);

    this.router.navigateByUrl('/error-page');
    return this.permissions.canActivate(this.currentUser, route.params.id);
  }
}

import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { BrandConsumptionComponent } from './pages/consumption-analytics/brand-consumption/brand-consumption.component';
import { ConsumptionSupplySettingsComponent } from './pages/consumption-analytics/consumption-supply-settings/consumption-supply-settings.component';
import { ConsumptionSupplyComponent } from './pages/consumption-analytics/consumption-supply/consumption-supply.component';
import { MultiplePeriodsComponent } from './pages/consumption-analytics/multiple-periods/multiple-periods.component';
import { GcaVariablesComponent } from './pages/cycle-traceability/cycle-detail/gca-variables/gca-variables.component';
import { GcaSettingsComponent } from './pages/cycle-traceability/gca-settings/gca-settings.component';
import { GcaVariablesConfigComponent } from './pages/cycle-traceability/gca-variables-config/gca-variables-config.component';
import { DataMappingComponent } from './pages/data-mapping/data-mapping.component';
import { HealthTraceabilityComponent } from './pages/health-monitoring/health-traceability/health-traceability.component';
import { HealthTrendComponent } from './pages/health-monitoring/health-trend/health-trend.component';
import { HmContinuousExplorationComponent } from './pages/health-monitoring/hm-continuous-exploration/hm-continuous-exploration.component';
import { HmCycleExplorationComponent } from './pages/health-monitoring/hm-cycle-exploration/hm-cycle-exploration.component';
import { HmGridComponent } from './pages/health-monitoring/hm-grid/hm-grid.component';
import { ConnectedMachinesListComponent } from './pages/homepage/connected-machines-list/connected-machines-list.component';
import { ChangeoverComponent } from './pages/lean-analytics/changeover/changeover.component';
import { EfficiencyLossComponent } from './pages/lean-analytics/efficiency-loss/efficiency-loss.component';
import { MachineKpisComponent } from './pages/lean-analytics/machine-kpis/machine-kpis.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { CycleListComponent } from './pages/machine-recorder/cycle-list/cycle-list.component';
import { ConsumablesSettingsComponent } from './pages/machine-settings/consumables-settings/consumables-settings.component';
import { MultiMachinesGridComponent } from './pages/machine-supervision/multi-machines-grid/multi-machines-grid.component';
import { RemoteMonitoringArComponent } from './pages/machine-supervision/remote-monitoring-ar/remote-monitoring-ar.component';
import { OnlyDatapointLinkComponent } from './pages/machine-supervision/remote-monitoring/only-datapoint-link/only-datapoint-link.component';
import { RemoteMonitoringComponent } from './pages/machine-supervision/remote-monitoring/remote-monitoring.component';
import { PerformanceSettingsComponent } from './pages/performance-settings/performance-settings.component';
import { OperatorsComponent } from './pages/planning-calendar/operators/operators.component';
import { PlannerComponent } from './pages/planning-calendar/planner/planner.component';
import { ProgramTraceabilityDetailComponent } from './pages/program-traceability/program-traceability-detail/program-traceability-detail.component';
import { StandardDashboardTablePageComponent } from './pages/standard-dashboard-table-page/standard-dashboard-table-page.component';
import { TechnicalSupportComponent } from './pages/technical-support/technical-support.component';
import { LastVideoStreamingComponent } from './pages/video-streaming/last-video-streaming/last-video-streaming.component';
import { LiveStreamingComponent } from './pages/video-streaming/live-streaming/live-streaming.component';
import { PastVideosComponent } from './pages/video-streaming/past-videos/past-videos.component';
import { SessionDetailComponent } from './pages/video-streaming/session-detail/session-detail.component';

// Time machine (fiera drinktec)
import { TimeMachineComponent } from './pages/machine-supervision/time-machine/time-machine.component';
// Time machine (DXF)
// import { TimeMachineComponent } from './pages/machine-supervision/time-machine/time-machine_dxf.component';

import { HmFeaturesScatterMatrix } from './pages/health-monitoring/hm-features-scatter-matrix/hm-features-scatter-matrix.component';
import { HmFeaturesTrendComponent } from './pages/health-monitoring/hm-features-trend/hm-features-trend.component';
import { CrossKpiAnalyticsComponent } from './pages/kpi-analytics/cross-kpi-analytics/cross-kpi-analytics.component';
import { SctHeatmapComponent } from './pages/machine-supervision/sct-heatmap/sct-heatmap.component';
import { ShiftInvalidationComponent } from './pages/lean-analytics/shift-invalidation/shift-invalidation.component';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate() ?
      true :
      // NOTE: this warning message will only be shown when navigating elsewhere within your angular app;
      // when navigating away from your angular app, the browser will show a generic warning message
      // see http://stackoverflow.com/a/42207299/7307355
      confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
  }
}

// export class MyComponent implements ComponentCanDeactivate {
//   // @HostListener allows us to also guard against browser refresh, close, etc.
//   @HostListener('window:beforeunload')
//   canDeactivate(): Observable<boolean> | boolean {
//     // insert logic to check if there are pending changes here;
//     // returning true will navigate without confirmation
//     // returning false will show a confirm dialog before navigating away
//   }
// }
const routes: Routes = [
  { path: '', redirectTo: 'homepage', pathMatch: 'full' },
  { path: 'login-page', component: LoginPageComponent },
  { path: 'error-page', component: ErrorPageComponent },
  // { path: 'test-page', component: TestPageComponent },
  {
    path: 'homepage', component: HomepageComponent,
    children: [
      { path: '', redirectTo: 'grid', pathMatch: 'prefix' },
      { path: 'grid', component: HomepageGridComponent },
      { path: 'list', component: HomepageListComponent },
      { path: 'map', component: HomepageMapComponent },
      { path: 'connected-machines-list', component: ConnectedMachinesListComponent },
    ]
  },
  {
    path: ':machineId/machine-supervision', children: [
      {
        path: '',
        redirectTo: 'remote-monitoring',
        pathMatch: 'prefix',
      },
      { path: 'remote-monitoring', component: RemoteMonitoringComponent },
      { path: 'remote-monitoring-ar', component: RemoteMonitoringArComponent },
      { path: 'alarms', component: AlarmsTableComponent },
      { path: 'signalations', component: SignalationsTableComponent },
      { path: 'state-timeline', component: StatesTimelineComponent },
      { path: 'state-timeline-advanced', component: StateTimelineAdvancedComponent },
      { path: 'cycle-timeline', component: CycleTimelineComponent },
      { path: 'machine-status-log', component: MachineStatusLogComponent },
      { path: 'cloth-monitoring', component: ClothMonitoringComponent },
      { path: 'machines-grid', component: MultiMachinesGridComponent }
    ]
  },
  {
    path: ':machineId/sct-heatmap', component: SctHeatmapComponent
  },
  {
    path: ':machineId/sct-heatmap-history', component: SctHeatmapComponent
  },
  {
    path: ':machineId/sct-heatmap-cycles-review', component: SctHeatmapComponent
  },
  {
    path: ':machineId/time-machine/time-machine', component: TimeMachineComponent
  },
  {
    path: ':machineId/cycle-traceability', children: [
      { path: '', redirectTo: 'cycles', pathMatch: 'prefix' },
      { path: 'cycles', component: CycleTraceabilityComponent },
      { path: 'gca-variables-config', component: GcaVariablesConfigComponent, canDeactivate: [PendingChangesGuard] },
      { path: 'gca-settings', component: GcaSettingsComponent, canDeactivate: [PendingChangesGuard] },
    ]
  },
  {
    path: ':machineId/cycle-traceability/:cycleId', children: [
      { path: '', redirectTo: 'cycle-timeline', pathMatch: 'prefix' },
      { path: 'cycle-timeline', component: CycleDetailTimelineComponent },
      { path: 'phase-variables', component: PhaseVariablesComponent },
      { path: 'cycle-dashboard', component: CycleDashboardComponent },
      { path: 'process-log', component: ProcessLogComponent },
      { path: 'gca-variables', component: GcaVariablesComponent },
    ]
  },
  {
    path: ':machineId/breakdowns', children: [
      { path: '', redirectTo: 'alarms', pathMatch: 'prefix' },
      { path: 'alarms', component: BreakdownsAlarmsComponent },
      { path: 'signalations', component: BreakdownsSignalationsComponent }
    ]
  },
  {
    path: ':machineId/consumption-analytics', children: [
      { path: '', redirectTo: 'complete', pathMatch: 'prefix' },
      { path: 'complete', component: CompleteConsumptionComponent },
      { path: 'brand', component: BrandConsumptionComponent },
      { path: 'multiple-periods', component: MultiplePeriodsComponent },
      { path: 'supply', component: ConsumptionSupplyComponent },
      { path: 'supply-settings', component: ConsumptionSupplySettingsComponent, canDeactivate: [PendingChangesGuard] },
    ]
  },
  {
    path: ':machineId/lean-analytics', children: [
      { path: '', redirectTo: 'oee-trend', pathMatch: 'prefix' },
      { path: 'oee-trend', component: LeanOeeTrendComponent },
      { path: 'production-trend', component: LeanProductionTrendComponent },
      { path: 'breakdowns', component: LeanBreakdownsComponent },
      { path: 'fault-analysis', component: LeanFaultAnalysisComponent },
      { path: 'breakdowns-signalations', component: LeanBreakdownsSignalationsComponent },
      { path: 'production-traceability', component: ProductionTraceabilityComponent },
      { path: 'production-traceability-detail', component: ProductionTraceabilityDetailComponent },
      { path: 'scrap-analytics', component: LeanScrapAnalyticsComponent },
      { path: 'efficiency-loss', component: EfficiencyLossComponent },
      { path: 'changeover', component: ChangeoverComponent },
      { path: 'machines-kpi', component: MachineKpisComponent },
      { path: 'shift-invalidation', component: ShiftInvalidationComponent },
    ]
  },
  {
    path: ':machineId/machine-recorder', children: [
      { path: '', redirectTo: 'continuous-exploration', pathMatch: 'prefix' },
      { path: 'continuous-exploration', component: ContinuousExplorationComponent },
      { path: 'cycle-exploration', component: CycleExplorationComponent },
      { path: 'cycle-list', component: CycleListComponent },
    ]
  },
  { path: ':machineId/health-monitoring', component: HmGridComponent },
  { path: ':machineId/performance-settings', component: PerformanceSettingsComponent },
  { path: ':machineId/mat-xr/:widgetId', component: OnlyDatapointLinkComponent },
  {
    path: ':machineId/video-streaming', children: [
      { path: '', redirectTo: 'live-streaming', pathMatch: 'prefix' },
      { path: 'live-streaming', component: LiveStreamingComponent },
      { path: 'past-videos', component: PastVideosComponent },
      { path: 'session-detail', component: SessionDetailComponent },
      { path: 'last-video', component: LastVideoStreamingComponent },
    ]
  },
  {
    path: ':machineId/health-monitoring', children: [
      { path: '', redirectTo: 'health-traceability', pathMatch: 'prefix' },
      { path: 'health-traceability', component: HealthTraceabilityComponent },
      { path: 'health-trend', component: HealthTrendComponent },
      { path: 'continuous-exploration', component: HmContinuousExplorationComponent },
      { path: 'cycle-exploration', component: HmCycleExplorationComponent },
      { path: 'features-trend', component: HmFeaturesTrendComponent },
      { path: 'features-scatter-matrix', component: HmFeaturesScatterMatrix },
    ]
  },
  {
    path: 'manual-input', children: [
      { path: '', redirectTo: 'prod-sheet', pathMatch: 'prefix' },
      { path: 'prod-sheet', component: ProdSheetComponent },
      { path: 'stops-sheet', component: StopsComponent },
    ]
  },
  {
    path: ':machineId/machine-settings', children: [
      { path: '', redirectTo: 'machine-settings', pathMatch: 'prefix' },
      { path: 'machine-settings', component: MachineSettingsComponent },
      { path: 'rba-settings', component: RbaSettingsComponent },
    ]
  },
  { path: ':machineId/consumables-settings', component: ConsumablesSettingsComponent, canDeactivate: [PendingChangesGuard] },
  {
    path: ':machineId/planning-calendar', children: [
      { path: '', redirectTo: 'calendar', pathMatch: 'prefix' },
      { path: 'calendar', component: CalendarComponent },
      { path: 'shift-templates', component: ShiftTemplatesComponent },
      { path: 'operators', component: OperatorsComponent, canDeactivate: [PendingChangesGuard] },
      { path: 'planner', component: PlannerComponent, canDeactivate: [PendingChangesGuard] }
    ]
  },
  {
    path: ':machineId/kpi-analytics', children: [
      { path: '', redirectTo: 'single', pathMatch: 'prefix' },
      { path: 'single', component: SingleKpiAnalyticsComponent },
      { path: 'double', component: DoubleKpiAnalyticsComponent },
      { path: 'cross', component: CrossKpiAnalyticsComponent },
    ]
  },
  { path: 'options', component: OptionsComponent },
  { path: 'data-mapping', component: DataMappingComponent },
  {
    path: ':machineId/alerts', children: [
      { path: '', redirectTo: 'rba', pathMatch: 'prefix' },
      { path: 'rba', component: AlertsComponent },
      { path: 'rba-settings', component: RbaSettingsComponent, canDeactivate: [PendingChangesGuard] },
    ]
  },
  { path: ':machineId/program-traceability', component: ProductionTraceabilityComponent },
  { path: ':machineId/standard-dashboard-table-page', component: StandardDashboardTablePageComponent },
  { path: ':machineId/program-traceability/detail', component: ProgramTraceabilityDetailComponent },
  { path: ':machineId/program-traceability/variables-timeseries', component: ContinuousExplorationComponent },

  { path: ':machineId/technical-support', component: TechnicalSupportComponent },

  { path: '**', redirectTo: 'error-page' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [CanActivateTeam, UserToken, Permissions, PendingChangesGuard]
})
export class AppRoutingModule { }
