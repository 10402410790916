<ff-loading-card *ngIf="cyclesReviewView && pageState.value >= pageStateReady && pageState.value < 9"
  [loadingData]="cyclesReviewLoadingData"
  style="position: absolute; left: calc(40vw - 70px); top: calc(50vh - 30px); z-index: 9;">
</ff-loading-card>

<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100"
  [ngClass]="{'cyclesReviewLoading': cyclesReviewView && pageState.value >= pageStateReady && pageState.value < 9 }">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

  </div>

  <div *ngIf="cyclesReviewView && (cyclesList == null || cyclesList?.length == 0)" class="w100" fxLayout="row"
    fxLayoutAlign="center center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="icon md-red" style="margin: 2px 4px 0 0;">warning</mat-icon>
      <span class="suggestion">{{ 'CYCLE_EXPLORATION.SELECT_CYCLES' | translate }}</span>
    </div>
  </div>

  <!-- tab content -->
  <div class="tab-content pad-x-2em pad-y-1em widpad-x-2em h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1}">

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="!isMobile&& pageState.value >= pageStateReady" fxLayout="row" fxLayoutAlign="start start"
      class="h100 w100" fxLayoutGap="8px">
      <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="h100" fxLayoutGap="8px">

        <!-- header -->
        <div fxFlex="5" fxLayout="row" fxLayoutAlign="start center">

          <div fxLayout="row" fxLayoutAlign="start center" class="last-update md-gray">
            <span>
              {{ 'PLANT_SUPERVISION.CYCLE_TYPE' | translate }}:
              <strong>{{ monitoringData.cycleTypeP | placeholder:'-'}}</strong>
            </span>
          </div>

          <div *ngIf="historyView || cyclesReviewView" fxLayout="row" fxLayoutAlign="start center"
            class="last-update md-gray">
            <span>
              {{ 'GLOBAL.DURATION' | translate }}:
              <strong>{{ cycleDuration | placeholder:'-'}}</strong>
            </span>
          </div>

          <span fxFlex></span>

          <!-- <div fxFlex fxLayout="column">
            <pre style="margin: 0;">{{sliderPlayStop}}</pre>
            <pre style="margin: 0;">{{countTotal}} | {{count}}</pre>
          </div> -->

          <div *ngIf="(historyView || cyclesReviewView) && cycleDuration != null" fxLayout="row"
            fxLayoutAlign="center center">

            <button *ngIf="!sliderPlayStop" (click)="sliderPlay(true)" class="filter-button" fxLayout="row"
              fxLayoutAlign="center center" [disabled]="sliderRunning">
              <mat-icon class="icon">play_arrow</mat-icon>
            </button>
            <button *ngIf="sliderPlayStop" (click)="sliderPlay(false)" class="filter-button" fxLayout="row"
              fxLayoutAlign="center center" [disabled]="sliderRunning">
              <mat-icon class="icon">pause</mat-icon>
            </button>

            <!-- pollingDropdown -->
            <div class="select-box" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
              fxLayoutAlign.gt-sm="start center" style="margin-right: 8px;">

              <mat-form-field appearance="fill">
                <mat-label>{{ 'PLANT_SUPERVISION.POLLING' | translate }}</mat-label>
                <mat-select [(ngModel)]="pollingDropdown.id" name="step">
                  <mat-option *ngFor="let pd of pollingDropdownList" [value]="pd.id" (click)="changePollingDropdown(pd)"
                    [disabled]="sliderRunning" class="completely">
                    <span>{{ pd.label | translate }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>

            <!-- stepDropdown -->
            <div *ngIf="historyView" class="select-box" fxLayout="column" fxLayoutAlign="center start"
              fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center" style="margin-right: 8px;">

              <mat-form-field appearance="fill">
                <mat-label>{{ 'PLANT_SUPERVISION.STEP' | translate }}</mat-label>
                <mat-select [(ngModel)]="stepDropdown.id" name="step">
                  <mat-option *ngFor="let sd of stepDropdownList" [value]="sd.id" (click)="changeStepDropdown(sd)"
                    [disabled]="sliderRunning" class="completely">
                    <span>{{ sd.label | translate }}</span>
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>

            <div fxLayout="row" fxLayoutAlign="start center" class="ngx-slider-container" style="margin-right: 16px">
              <ngx-slider [(value)]="sliderConf.value" [options]="sliderConf.options"
                (userChangeEnd)="sliderConf.userChangeEnd($event)"></ngx-slider>
            </div>

          </div>

          <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!historyView && !cyclesReviewView"
            class="last-update {{monitoringData?.notConnected ? 'md-orange' : (monitoringData?.neverConnected ? 'md-gray' :'md-green') }}">
            <mat-icon class="icon left">
              {{monitoringData?.notConnected ? 'warning' : (monitoringData?.neverConnected ? 'remove' : 'check')}}
            </mat-icon>
            <span>{{ 'REMOTE_MONITORING.LAST_UPDATE' | translate }}: {{ monitoringData?.lastUpdateP }}</span>
          </div>

        </div>

        <!-- main widget -->
        <ff-widget fxFlex="95" [widget]="{
            type: 'ff-plotly-chart', 
            data: monitoringData, 
            machineProfile: machine.profile, 
            config: monitoringData.dataConfig, 
            excludeTitleHeight: true, 
            title: reactorTitle 
          }">
        </ff-widget>

        <div>

        </div>

      </div>

      <!-- widgets column -->
      <!-- <div fxFlex="{{ !isSmThanTablet ? dashboardConfig.secondColumnFlex : dashboardConfig.secondColumnFlex + 10 }}" -->
      <div fxFlex="{{ dashboardConfig.secondColumnFlex }}" fxLayout="column" fxLayoutAlign="start stretch" class="h100"
        fxLayoutGap="8px">

        <ff-dashboard *ngIf="completeDashboardConfig != null" [completeDashboardConfig]="completeDashboardConfig">
        </ff-dashboard>
      </div>

    </div>

    <div *ngIf="isMobile && pageState.value >= pageStateReady" fxLayout="row wrap" fxLayoutAlign="start stretch"
      class="h100 w100" fxLayoutGap="8px grid">
      <span fxFlex="100">
        <button *ngIf="!hideSynopticSelectionButton" mat-button (click)="openMatDialog()" class="filter-button"
          fxLayout="row" fxLayoutAlign="center center">
          {{ 'SYNOPTICS.' + currentSynopticId | translate }}
          <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
            style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
          </mat-icon>
        </button>
      </span>

      <span fxFlex="100">
        <div fxLayout="row" fxLayoutAlign="start center"
          class="last-update {{monitoringData?.notConnected ? 'md-orange' : (monitoringData?.neverConnected ? 'md-gray' :'md-green') }}">

          <mat-icon class="icon left">
            {{monitoringData?.notConnected ? 'warning' : (monitoringData?.neverConnected ? 'remove' : 'check')}}
          </mat-icon>
          <span>{{ 'REMOTE_MONITORING.LAST_UPDATE' | translate }}: {{ monitoringData?.lastUpdateP }}</span>
        </div>
      </span>

      <ff-widget fxFlex="100"
        [widget]="{type: 'ff-synoptic', data: monitoringData, synopticConfig: synopticConfig, mobile: true }">
      </ff-widget>
      <ff-widget *ngFor="let w of dashboardConfig?.widgets" fxFlex="100" [widget]="{ 
          type: w.type,
          data: monitoringData,
          machineProfile: machine?.profile,
          config: w.config,
          title: w.title,
          unit: w.unit,
          productionUnit: w.productionUnit  }">
      </ff-widget>

    </div>

  </div>

</div>