import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import * as moment from 'moment';
import { AppConfigService } from 'src/app/services/app-config.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'ff-last-update',
  templateUrl: './last-udpate.component.html',
  styleUrls: ['./last-udpate.component.scss']
})
export class LastUdpateComponent implements OnInit, OnChanges {

  @Input() widget: any;
  @HostBinding('class.compressed') compressed: any;
  appConfig: any;

  constructor(
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public appConfigService: AppConfigService,
  ) {
    this.appConfig = this.appConfigService.getAppConfig;
  }

  setValue() {

    if (this.widget?.compressed != null) this.compressed = this.widget.compressed;

    let warningMinutes = this.widget?.config?.warningMinutes ?? this.appConfig?.machineMonitoring?.warningMinutes ?? 15;

    let val = this.widget?.data?.[this.widget?.config?.variable];

    let neverConnected = val == null;

    if (neverConnected) {
      this.widget = {
        ...this.widget,
        ...{
          class: 'md-gray',
          label: this.translate.instant("MACHINE_STATES.NEVER_CONNECTED"),
          icon: {
            icon: "remove_circle",
            type: "icon"
          }
        }
      }
      return;
    }

    let notConnected = moment().diff(moment(val), 'm') > warningMinutes;

    this.widget = {
      ...this.widget,
      ...{
        class: notConnected ? 'md-orange' : 'md-green',
        label: this.filterService.parseMoment(val, 'default'),
        icon: {
          icon: notConnected ? "warning" : "check",
          type: "icon"
        }
      }
    }

  }

  ngOnInit(): void {
    this.setValue();
  }

  ngOnChanges(): void {
    this.setValue();
  }

}
