import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

declare var Plotly: any;

@Component({
  selector: 'ff-datapoint-dialog',
  templateUrl: './datapoint-dialog.component.html',
  styleUrls: ['./datapoint-dialog.component.scss']
})
export class DatapointDialogComponent implements OnInit, OnDestroy {

  pollingDatapoint: any;
  pollingDatapointDialogTime: any = 60000;
  plotState: number = 0;
  public dialogIntervals: any = {
    selected: 'last2Hours',
    list: [
      "last24Hours",
      "last12Hours",
      "last8Hours",
      "last4Hours",
      "last2Hours",
      "lastHour",
      "last30Minutes",
    ]
  };

  public appConfig: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public apiService: ApiService,
    public appConfigService: AppConfigService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
  ) {
    this.appConfig = this.appConfigService.getAppConfig;
    this.dialogIntervals.list = this.intervalService.getIntervalList(this.dialogIntervals.list);
  }

  getDatapointHistory() {
    try {

      let interval: any = this.intervalService.getIntervalById(this.dialogIntervals.selected);

      let query = {
        from: interval.start,
        tz: this.dialog.timezone,
      };

      let payload: any = null;
      let datapointList = this.dialog.datapointList.map((x: any) => typeof x == 'string' ? x : (Array.isArray(x) ? x : x.dp));

      if (this.appConfig.MAT2) {
        datapointList = this.dialog.datapointList;
        payload = this.internalDataService.buildMachinePayload(this.dialog.machine);
        payload.requestedVariables = datapointList;
      }
      else {
        payload = datapointList;
      }

      let _this = this;

      this.pollingDatapoint = timer(0, this.pollingDatapointDialogTime).subscribe((count => {
        this.apiService.sendPostRequest('/apif/machine-monitoring/datapoints/' + this.dialog.machineId, payload, query).pipe(
          retryWhen(this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {

            this.plotState = 2;

            return _this.internalDataService.parseStandardHTTPError(_this, error, count == 0 ? _this.pollingDatapoint : null)

          }))
          .subscribe(
            (data: any) => {

              this.plotState = 1;

              let plotData: any = this.parsePlotData(data.body);

              let plotConfig: any = {
                responsive: true,
                displaylogo: false
              };

              try {
                if (plotData.data.every((x: any) => x.x.length == 0)) {
                  this.plotState = 2;
                } else {
                  setTimeout(() => {
                    try {
                      Plotly.react("datapoint-dialog", plotData.data, plotData.layout, plotConfig);
                    } catch (error) {
                      console.log(error);
                    }
                  }, 50);
                }
              } catch (error) {
                console.log(error);
              }
            },
          );

      }));
    } catch (error) {
      console.log(error);

    }
  }

  parsePlotData(plotData: any) {

    let traces = [];

    for (const aspectName of Object.keys(plotData)) {

      const aspectValue = plotData[aspectName];

      for (const variable of Object.keys(aspectValue.variables)) {

        let tConfIdx = this.dialog.datapointList.findIndex((x: any) => typeof x == 'string' ? false : x.dp == aspectName + '.' + variable);
        let tShow: any = true;

        let multiplier = this.dialog?.completeDatapoint?.multiplier;

        if (tConfIdx != -1) tShow = this.dialog.datapointList[tConfIdx].show ? true : 'legendonly';

        traces.push({
          x: aspectValue.timestamps.map((x: any) => new Date(x)),
          y: aspectValue.variables[variable]?.map(x => {
            if (x == null) return null;
            let val = this.filterService.convertUnit(this.dialog.defaultUnit, x).value;
            if (val == null) return null;
            if (multiplier == null || multiplier == 1) return val;
            return val * multiplier;
          }),
          type: 'scatter',
          visible: tShow,
          name: this.internalDataService.parseDatapointLabel(aspectName, variable, this.dialog.machineId)
          // this.translate.instant("DATAPOINTS." + this.dialog.machineId + "." + aspectName + '.' + variable),
        });

      }

    }

    const fixedSetpoint = this.dialog?.completeDatapoint?.fixedSetpoint;

    if (traces?.length > 0 && fixedSetpoint != null) {
      let lastTrace = traces.at(-1);

      let value = fixedSetpoint?.value ?? fixedSetpoint;
      let label = this.internalDataService.parseLabelFromCustomTranslations(fixedSetpoint?.label);

      traces.push({
        x: lastTrace.x,
        y: lastTrace.y?.map(_ => value),
        type: 'scatter',
        line: {
          dash: 'dot',
        },
        name: label
      });
    }

    let plotLayout: any = {
      uirevision: true,
      font: {
        size: 12,
      },
      xaxis: {
        domain: [0.02, 1],
        zeroline: false,
        showgrid: false,
      },
      yaxis: {
        title: this.dialog.unit ?? '',
        zeroline: false,
        showgrid: false,
      },
      showlegend: true,
      margin: {
        t: 30,
        r: 30,
        b: 30,
        l: 60,
        pad: 10
      },
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: -0.3
      },
    };

    let mainFontColor = this.appConfig?.plotlyDefaultColors?.font ?? '#000000';

    let dark = {
      plot_bgcolor: '#00000000',
      paper_bgcolor: '#00000000',
      modebar: {
        bgcolor: '#00000000',
        color: "#64697E",
        activecolor: "#64697E",
      }
    }

    plotLayout = { ...plotLayout, ...dark };

    // Merge legend
    let legendDark = { font: { color: mainFontColor } };
    plotLayout.legend = plotLayout.legend != null ? { ...plotLayout.legend, ...legendDark } : legendDark;

    // Merge x axis
    let xAxisDark = { color: mainFontColor };
    if (plotLayout.xaxis1 == null) {
      plotLayout.xaxis = plotLayout.xaxis != null ? { ...plotLayout.xaxis, ...xAxisDark } : xAxisDark;
    }

    for (let i = 1; i < 20; i++) {
      if (plotLayout["xaxis" + i] != null) plotLayout["xaxis" + i] = plotLayout["xaxis" + i] != null ? { ...plotLayout["xaxis" + i], ...xAxisDark } : xAxisDark;
    }

    // Merge y axis
    let yAxisDark = { color: mainFontColor };
    if (plotLayout.yaxis1 == null) {
      plotLayout.yaxis = plotLayout.yaxis != null ? { ...plotLayout.yaxis, ...yAxisDark } : yAxisDark;
    }

    for (let i = 1; i < 20; i++) {
      if (plotLayout["yaxis" + i] != null) plotLayout["yaxis" + i] = plotLayout["yaxis" + i] != null ? { ...plotLayout["yaxis" + i], ...yAxisDark } : yAxisDark;
    }

    if (this.dialog.yAxisRange != null) {
      plotLayout.yaxis.autorange = false;
      plotLayout.yaxis.range = this.dialog.yAxisRange;
    }

    return {
      layout: plotLayout,
      data: traces
    }

  }

  regetData(dialogInterval: any) {
    this.plotState = 0;
    this.dialogIntervals.selected = dialogInterval;
    try {
      this.pollingDatapoint.unsubscribe();
    } catch (error) {
      console.log(error);
    }
    this.getDatapointHistory();
  }



  ngOnInit(): void {
    this.getDatapointHistory();
  }

  ngOnDestroy() {
    try {
      this.pollingDatapoint.unsubscribe();
    } catch (error) {
      console.log(error);
    }
  }

}
