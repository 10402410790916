<mat-card>
    <div class="w100" fxLayout="center" fxLayoutAlign="center center">
        <img class="sml transition-01" src="assets/images/logo-login-page.png">
    </div>
    <div class="card-body">
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label for="username">Username</label>
                <input type="text" formControlName="username" class="ff-input w100 underline-primary"
                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">Username is required</div>
                </div>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" formControlName="password" class="form-control ff-input w100 underline-primary"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
            </div>
            <div style="margin-top: 24px;" fxLayout="row" fxLayoutAlign="start center">
                <span fxFlex></span>
                <button [disabled]="loading" class="filter-button md-light-gray w100" fxLayout="row"
                    fxLayoutAlign="center center" style="margin: 0 !important;">
                    <!-- <ff-icon icon="account_box" [left]="true"></ff-icon> -->
                    LOGIN
                </button>
                <!-- <a routerLink="../register" class="btn btn-link">Register</a> -->
            </div>
            <div *ngIf="error" fxLayout="row" fxLayoutAlign="center center">
                <span class="error">{{ error }}</span>
            </div>
        </form>
    </div>
</mat-card>