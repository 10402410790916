import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';
import { BoxPlotDialogComponent } from '../box-plot-dialog/box-plot-dialog.component';

@Component({
  selector: 'app-single-kpi-analytics',
  templateUrl: './single-kpi-analytics.component.html',
  styleUrls: ['./single-kpi-analytics.component.scss']
})
export class SingleKpiAnalyticsComponent implements OnInit, OnDestroy {

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // COMPONENT CONFIGURATION
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  

  // // // // // // // //
  // PAGE CONFIG
  // // // // // // // //

  // Section name: "appConfig[sectionName]"
  public sectionName: any = "kpiAnalytics";

  // Tab name: "appConfig[sectionName][tabName]"
  public tabName: any = "single";

  // Permission to load the page (in case authentication is provided)
  public permission: any = "mat-kpi";

  // Dashboard name
  public dashboardName: any = "kpi-analytics";

  // // // // // // // //
  // MAIN FUNCTION
  // // // // // // // //

  // Default Polling Time [ms]
  public defaultPollingTime: any = 0;

  // // // // // // // //
  // OTHER
  // // // // // // // //

  // Breadcrumb
  public breadcrumb: any = ['KPI_ANALYTICS.TITLE', 'SINGLE_KPI.TITLE'];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  

  public isAllowedUser: boolean = true;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public productionColumns: string[] = [];

  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public pollingTime: any;
  public pollingEvents: any;

  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;

  public interval: any;
  public intervalConfig: any;

  public intervalAggregations: any;
  public aggregationsTime: any;

  public forcedAggrList: any;
  public forcedAggrId: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public dashboardConfig: any;

  public kpiAnalyticsData: any;
  public KPIDropdown: any;
  public aggregationDropdown: any;
  public parameters: any;

  public boxPlotDataSub: Subscription;
  public boxPlotData: any;

  public isMobile: any;
  public isSmThanTablet: any;
  public mobileListener: Subscription;

  public hideZerosSwitch: any = {
    title: "GLOBAL.HIDE_ZEROS",
    checked: false,
    checkedLabel: "on",
    uncheckedLabel: "off"
  };

  public showBoxplotSwitch: any = {
    title: "KPI_ANALYTICS.SHOW_CUMULATIVE_BOX_PLOT",
    tooltip: "KPI_ANALYTICS.SHOW_CUMULATIVE_BOX_PLOT_TOOLTIP",
    checked: false,
    checkedLabel: "on",
    uncheckedLabel: "off"
  };

  public defaultPlotlyColors = [
    '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#008FFB',
    "#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D", "#FF9800",
    "#33B2DF", "#546E7A", "#D4526E", "#13D8AA", "#A5978B",
    "#4ECDC4", "#C7F464", "#81D4FA", "#546E7A", "#FD6A6A",
    "#2B908F", "#F9A3A4", "#90EE7E", "#FA4443", "#69D2E7",
    "#449DD1", "#F86624", "#EA3546", "#662E9B", "#C5D86D",
    "#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044",
    "#662E9B", "#F86624", "#F9C80E", "#EA3546", "#43BCCD",
    "#5C4742", "#A5978B", "#8D5B4C", "#5A2A27", "#C4BBAF",
    "#A300D6", "#7D02EB", "#5653FE", "#2983FF", "#00B1F2",
    '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd',
    '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf',
  ];

  public additionalTableConfig: any = {
    filters: {
      colorRowsById: {
        variable: "timestampP"
      }
    }
  };

  public hierarchy: any;
  public hierarchyUnparsed: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 7;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getKPIConfigs, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.parseParameters, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getDataPolling, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    public mobile: MobileService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.hierarchy = this.appConfigService.getHierarchy;

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.internalDataService.setCalendarPage(true);
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);
    this.availableMachines = this.appConfig?.[this.sectionName]?.availableMachines ?? [];

    if (this.hierarchy != null) {
      if (this.tabs?.find(x => x.id == 'cross') == null) {
        this.tabs.push(
          {
            "id": "cross",
            "disabled": false,
            "title": "CROSS_KPI.TITLE",
            "icon": {
              "icon": "timeline",
              "type": "icon"
            },
            "url": "/:machineId/kpi-analytics/cross"
          }
        );
      }
    }

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile
      this.isSmThanTablet = value.isSmThanTablet
    })

    this.pollingTime = this.appConfig?.[this.sectionName]?.pollingAlarms ?? this.defaultPollingTime;
    this.pollingEvents = Subscription;

    try {
      this.forcedAggrList = this.appConfig?.[this.sectionName]?.aggrList;
    } catch (error) { }
    try {
      this.forcedAggrId = this.appConfig?.[this.sectionName]?.aggrListDefault;
    } catch (error) { }

    this.kpiAnalyticsData = null;

    this.boxPlotDataSub = this.internalDataService.boxPlotData.subscribe(value => {
      if (value != null) {
        this.openBoxPlotDialog(value);
      }
    });

  }

  openAggrDialog(aggr: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines != null ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        // console.log('afterClosed', result);
        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
      }

      this.pageState.next(isClickedSelect ? 5 : 6);

      if (!this.interval.enabledPolling) {
        this.getData(this, 0);
      } else {
        this.getDataPolling(this);
      }

    });
  };

  openBoxPlotDialog(boxPlotData: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(BoxPlotDialogComponent, {
      panelClass: 'ff-dialog',
      minWidth: '80vw',
      data: {
        title: boxPlotData.name,
        boxPlotData: boxPlotData.data,
        statisticalData: boxPlotData.statisticalData,
        color: boxPlotData.color,
        unit: boxPlotData.unit,
        multiplier: boxPlotData.multiplier,
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {
      this.internalDataService.setBoxPlotData(null);
    });
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission(_this.permission);
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getDataPolling, this.getData, this.machine.timezone, 6, 7) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval aggr
  selectAggregation(aggr: any) {
    if (aggr != null) {
      try {
        this.pollingEvents.unsubscribe();
      } catch (error) { }

      this.intervalAggregations.selected = aggr;

      this.pageState.next(6);
      this.getDataPolling(this);
    }
  }

  machineSelectionChange(machine: any) {

    this.filterService.filterAggregationsByMachine(this, machine);

    if (machine != null) {

      try {
        this.pollingEvents.unsubscribe();
      } catch (error) { }

      this.pageState.next(6);
      this.getDataPolling(this);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // aggregation id
  // updateAggrId(aggrId: any) {
  //   this.aggrId = aggrId;
  //   this.productionColumns = [];
  //   this.kpiAnalyticsInfo.forEach((element: any) => {
  //     if (!element.offset || (element.offset && element.offset.includes(this.aggrId))) {
  //       this.productionColumns.push(element.variable);
  //     } 
  //   });
  // }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET KPI CONFIGS

  public getKPIConfigs(_this: any) {
    try {
      _this.internalDataService.getKPIConfigs(_this, _this.machineId);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  parseParameters(_this: any) {

    _this.maxRecipes = _this.parameters?.maxRecipes ?? 20;

    if (_this.parameters.hasOwnProperty('kpiParameters') && _this.parameters.kpiParameters != null) {
      _this.KPIDropdown = _this.clonerService.deepClone(_this.parameters.kpiParameters);

      // if (_this.KPIDropdown.hasOwnProperty('list') && _this.KPIDropdown.list != null && _this.KPIDropdown.list.length > 0) {
      //     _this.KPIDropdown.list.forEach((par:any) => {
      //         par.disabled = false;
      //         try {
      //             if (par.hasOwnProperty('massCounter') && par.massCounter != null && par.massCounter.length > 0) {
      //                 par.disabled = !par.massCounter.includes(parseInt(_this.asset.variables.find(x => x.name == 'massCounter').value));
      //             }
      //         } catch (error) {}
      //     });
      // }
      _this.KPIDropdown.selected = _this.KPIDropdown.list[0];
    }
    _this.getAggrDropdown(_this, _this.KPIDropdown);

    _this.dispatcherService.getDispatch(_this, 300);

  }

  getAggrDropdown(_this: any, dropdown: any) {

    if (dropdown.selected?.aggregationParameters != null) {
      if (_this.aggregationDropdown != null && _this.aggregationDropdown.hasOwnProperty('selected')) {

        if (!dropdown.selected.aggregationParameters.hasOwnProperty('list') && dropdown.selected.aggregationParameters?.fromMachineProfile == true) {
          dropdown.selected.aggregationParameters.list = _this.clonerService.deepClone(_this.machine.profile.aggregations);
        }

        dropdown.selected.aggregationParameters.list = _this.clonerService.deepClone(dropdown.selected.aggregationParameters?.list ?? []);

        if (!dropdown.selected.aggregationParameters.list?.length) {
          _this.aggregationDropdown.list = [];
          _this.aggregationDropdown.listFiltered = [];
          _this.aggregationDropdown.selected = '---';
          return;
        }

        let ix = dropdown.selected.aggregationParameters.list?.findIndex((x: any) => {
          if (dropdown.selected.aggregationParameters?.selected != null) {
            return x.id == dropdown.selected.aggregationParameters.selected;
          } else {
            return x.id == _this.aggregationDropdown.selected;
          }
        });

        _this.aggregationDropdown.list = _this.clonerService.deepClone(dropdown.selected.aggregationParameters.list);
        _this.aggregationDropdown.selected = ix != -1 ? _this.aggregationDropdown.list[ix].id : '---';
      } else {

        if (!dropdown.selected.aggregationParameters.hasOwnProperty('list') && dropdown.selected.aggregationParameters?.fromMachineProfile == true) {
          dropdown.selected.aggregationParameters.list = _this.clonerService.deepClone(_this.machine.profile.aggregations);
        }

        _this.aggregationDropdown = _this.clonerService.deepClone(dropdown.selected.aggregationParameters);
        _this.aggregationDropdown.list = _this.clonerService.deepClone(_this.aggregationDropdown.list);
        _this.aggregationDropdown.selected = '---';
      }
    }

  }

  getAggregation(dropdown: any) {
    this.getAggrDropdown(this, dropdown);
    this.pageState.next(6);
    this.getData(this, 0);
  }

  getAnalytics() {
    this.pageState.next(6);
    this.getData(this, 0);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // events

  // polling
  getDataPolling(_this: any) {

    if (_this.cacheService.get("intervalLong") == null) {

      _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

      _this.intervalConfig = {
        list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
        selected: _this.interval
      };

      _this.cacheService.set("intervalLong", _this.intervalConfig);

    } else {
      _this.intervalConfig = _this.cacheService.get("intervalLong");
      _this.interval = _this.cacheService.get("intervalLong").selected;
    }

    try {

      if (_this.pollingTime > 0) {
        _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getData(_this, count);
        });
      } else {
        _this.getData(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getData(_this: any, count?: any) {
    try {

      if (count == 0) {
        _this.internalDataService.forceAggregationList(_this, _this.forcedAggrList);
      }

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone,
        unit: _this.intervalAggregations.selected.unit != null ? _this.intervalAggregations.selected.unit : 'hour',
        value: _this.intervalAggregations.selected.value != null ? _this.intervalAggregations.selected.value : 1,
        hideZeros: +_this.hideZerosSwitch?.checked,
      };

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      if (_this.machine != null && _this.machine.idealFlow != null) {
        query.idealFlow = _this.machine.idealFlow;
      }

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      _this.filterService.filterAggregationsByMachine(_this, query?.machineId);

      var ads = null;
      if (_this.aggregationDropdown.selected != '---') ads = _this.aggregationDropdown.selected;

      let currentKPI = _this.KPIDropdown.selected;

      payload = Object.assign(payload, {
        aggrLevel: ads != null ? [ads] : [],
        aggrType: currentKPI.type,
        kpiParameters: currentKPI.parameters,
        fileName: currentKPI.fileName,
        requestedKpi: currentKPI.info
      });

      let url = '/apif/kpi/' + _this.machineId + '/' + currentKPI.id;
      // let url = '/apif/cross-kpi';

      payload.assets = ["3558", "3557"];
      payload.globalKpi = false;

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.kpiAnalyticsData = _this.parseAnalyticsData(data.body, query);
            _this.postParsing(_this);

            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseAnalyticsData(data: any, query: any) {

    let temp: any = {};
    temp.timeserie = data;
    temp.aggrType = query.type;
    temp.aggrTransl = this.getParameterTranslation(this.aggregationDropdown.list, this.aggregationDropdown.selected, 'prefix');
    temp.aggrConfig = this.aggregationDropdown;
    temp.parameter = query.parameter;
    temp.aggrLevel = query.aggrLevel;
    temp.shownRecipes = 5;
    temp.id = this.KPIDropdown.selected.id;
    temp.dropdownConfig = this.clonerService.deepClone(this.KPIDropdown.selected);
    temp.KPIlabelMovingAverage = this.getParameterTranslation(this.KPIDropdown.list, this.KPIDropdown.selected.id, 'label');
    temp.KPIlabelBoxPlot = this.getParameterTranslation(this.KPIDropdown.list, this.KPIDropdown.selected.id, 'label');
    temp.unit = this.getParameterTranslation(this.KPIDropdown.list, this.KPIDropdown.selected.id, 'label', 'unit');
    temp.multiplier = this.getMultiplier(this.KPIDropdown.list, this.KPIDropdown.selected.id);
    // console.log({ temp });

    return temp;
  }

  postParsing(_this) {

    try {

      _this.kpiAnalyticsData.dataConfig = {};

      // set plot data and configs
      _this.kpiAnalyticsData.dataConfig.title = _this.internalDataService.buildExportFileTitle(_this.breadcrumb, _this.interval);
      _this.kpiAnalyticsData.dataConfig.plotDataAttribute = 'plotData';
      _this.kpiAnalyticsData.dataConfig.fixedDesktopView = true;
      _this.kpiAnalyticsData.plotData = _this.buildPlotConfig(_this, _this.kpiAnalyticsData);

      // set table data and config
      _this.kpiAnalyticsData.dataConfig.tableDataAttribute = 'tableData';
      // _this.kpiAnalyticsData.tableInfo = _this.clonerService.deepClone(_this.appConfig.kpiAnalytics.kpiColumns);
      _this.kpiAnalyticsData.tableInfo = [
        {
          "variable": "icon",
          "type": "icon",
          "icon": {
            "icon": "timeline",
            "type": "icon"
          }
        },
        {
          "variable": "timestampP",
          "orderBy": "timestamp",
          "label": "KPI_ANALYTICS.DATE"
        },
        {
          "variable": "valueP",
          "orderBy": "value",
        },
        {
          "variable": "movingAvgP",
          "orderBy": "movingAvg",
        }
      ];

      if (_this.aggregationDropdown.selected != null && _this.aggregationDropdown.selected != '---') {
        _this.kpiAnalyticsData.tableInfo.push({
          "variable": "aggrUnitId",
          "orderBy": "aggrUnitId",
          "label": _this.translate.instant(_this.aggregationDropdown.list?.find(x => x.id == _this.aggregationDropdown.selected)?.label ?? '-')
        })
      }

      _this.kpiAnalyticsData.tableColumns = [];
      _this.kpiAnalyticsData.tableInfo.forEach((element: any) => {
        if (element.variable == 'valueP') {
          element.label = _this.translate.instant(_this.KPIDropdown.selected.label) + " (" + _this.translate.instant("KPI_ANALYTICS.ACTUAL_DATA") + ")";;
          if (_this.KPIDropdown.selected.hasOwnProperty('unit') && _this.KPIDropdown.selected.format == null) {
            element.unit = _this.KPIDropdown.selected.unit;
            // element.unitInTitle = true;
          }
          if (_this.isMobile) element.label = _this.translate.instant("KPI_ANALYTICS.ACT");
        }
        if (element.variable == 'movingAvgP') {
          element.label = _this.translate.instant(_this.KPIDropdown.selected.label) + " (" + _this.translate.instant("KPI_ANALYTICS.MOVING_AVERAGE") + ")";
          if (_this.KPIDropdown.selected.hasOwnProperty('unit') && _this.KPIDropdown.selected.format == null) {
            element.unit = _this.KPIDropdown.selected.unit;
            // element.unitInTitle = true;
          }
          if (_this.isMobile) element.label = _this.translate.instant("KPI_ANALYTICS.MOV");
        }
        _this.kpiAnalyticsData.tableColumns.push(element.variable);
      });
      _this.kpiAnalyticsData.tableData = _this.parseTableData(_this.kpiAnalyticsData);

    } catch (error) {
      console.log(error);
    }
  }

  getParameterTranslation(dropdown: any, id: any, label: any, type?: any) {

    let ix = dropdown.findIndex((x: any) => x.id == id);

    if (ix != -1) {
      let currentKpi = dropdown[ix];

      if (currentKpi?.[label] != null) {

        try { if (currentKpi?.productionUnit != null && currentKpi.unit == null) currentKpi.unit = this.filterService.getProductionUnit(currentKpi?.productionUnit, this.machine.profile.productionConfig) } catch (error) { }

        let newUnit = currentKpi.unit;

        try { newUnit = this.filterService.convertUnit(currentKpi.unit).unit } catch (error) { }

        if (type == 'unit') return currentKpi.unit ?? '';
        return this.translate.instant(currentKpi[label]) + ((currentKpi.hasOwnProperty('unit') && currentKpi.unit != null) ? ' [' + this.translate.instant(newUnit ?? '-') + ']' : '');

      }
      return "";
    }
    return null;
  }

  getMultiplier = (dropdown: any, id: any) => (dropdown.find((x: any) => x.id == id).multiplier);

  buildPlotConfig(_this: any, data: any, zoomRange?: any) {

    // check presence of mandatory variables 
    if (data.timeserie == null || data.timeserie.tracks == null || data.timeserie.tracks.length == 0) {
      return {
        layout: {},
        traces: []
      }
    }

    let traces: any = [];

    // console.log(data.timeserie.tracks);
    let aggrBarsActual = _this.getAggrBars(data.timeserie.tracks, _this.intervalAggregations.selected.unit, _this.intervalAggregations.selected.value);
    let aggrBarsMovingAvg = _this.getAggrBars(data.timeserie.tracks, _this.intervalAggregations.selected.unit, _this.intervalAggregations.selected.value, 'movingAvg');
    // console.log(aggrBars);

    data.timeserie.tracks.forEach((track: any, iComp: any) => {

      let comp: any = null;
      if (data.aggrTransl != null) {
        comp = data.aggrTransl != '' ? this.translate.instant(data.aggrTransl) : "";
        if (track.aggrUnitId != null) {
          if (data.aggrConfig != null && data.aggrConfig.hasOwnProperty('selected') && data.aggrConfig.selected != null && data.aggrConfig.selected != '---' &&
            data.aggrConfig.hasOwnProperty('list') && data.aggrConfig.list != null && Array.isArray(data.aggrConfig.list) && data.aggrConfig.list.length > 0) {
            let ix = data.aggrConfig.list.findIndex((x: any) => x.id == data.aggrConfig.selected);
            if (ix != -1) {
              let customTransl = data.aggrConfig.list[ix].singleLabel;
              if (customTransl != null) {
                track.aggrUnitId = _this.translate.instant(customTransl + '.' + track.aggrUnitId);
              }
            }
          }
          comp += " " + track.aggrUnitId;
        };
      } else {
        comp = data.KPIlabelBoxPlot;
      }

      let values: any = _this.clonerService.deepClone(data.timeserie.tracks[iComp].values.filter((x: any) => x != null).map((val: any) => _this.filterService.convertUnit(data.unit, (data.multiplier != null ? val * data.multiplier : val)).value));

      if (!this.showBoxplotSwitch.checked) {

        traces.push({
          x: values,
          boxPlotValues: values,
          name: comp,
          traceType: 'box',
          boxPlotData: track.boxplotData,
          multiplier: data.multiplier != null ? data.multiplier : 1,
          visible: iComp < _this.maxRecipes ? true : "legendonly",
          type: 'box',
          text: comp,
          hoverinfo: "name",
          connectgaps: false,
          unit: data.unit,
          marker: {
            // color: _this.defaultPlotlyColors[0],
            color: _this.defaultPlotlyColors[iComp],
          },
          legendgroup: "group" + iComp,
        });

      }

      else {

        let aggregationType = data.dropdownConfig?.aggregationType;

        let aggregatedBarsValue = aggregationType == 'sum' ? this.filterService.sum(values) : this.filterService.average(values);

        traces.push({
          x: [aggregatedBarsValue],
          boxPlotValues: values,
          name: comp,
          traceType: 'box',
          boxPlotType: "bar",
          boxPlotData: track.boxplotData,
          multiplier: data.multiplier != null ? data.multiplier : 1,
          visible: iComp < _this.maxRecipes ? true : "legendonly",
          type: 'bar',
          text: comp,
          // hoverinfo: "name",
          connectgaps: false,
          unit: data.unit,
          marker: {
            // color: _this.defaultPlotlyColors[0],
            color: _this.defaultPlotlyColors[iComp],
          },
          legendgroup: "group" + iComp,
        });
      }

    })

    let trendTimestamps: any = [];

    let c_typePlot = _this.cacheService.get('typePlot');
    if (c_typePlot == null) {
      _this.cacheService.set('typePlot', 'bar');
      c_typePlot = 'bar';
    }

    data.timeserie.tracks.forEach((track: any, iComp: any) => {

      trendTimestamps.push(track.timestamps);
      let movingAvg = _this.clonerService.deepClone(track.movingAvg.map((val: any) => val != null ? _this.filterService.convertUnit(data.unit, val * (data.multiplier ?? 1)).value : null));;
      let values = _this.clonerService.deepClone(track.values.map((val: any) => val != null ? _this.filterService.convertUnit(data.unit, val * (data.multiplier ?? 1)).value : null));;

      let comp = null;
      if (data.aggrTransl != null) {
        comp = data.aggrTransl != '' ? this.translate.instant(data.aggrTransl) : "";
        if (track.aggrUnitId != null) {
          comp += " " + track.aggrUnitId;
        }
      } else {
        comp = data.KPIlabelMovingAverage;
      }

      traces.push({
        x: trendTimestamps[iComp],
        traceType: 'actual',
        y: values,
        xaxis: 'x2',
        yaxis: 'y2',
        name: comp,
        width: aggrBarsActual.width[iComp],
        offset: aggrBarsActual.offset[iComp],
        type: c_typePlot,
        mode: 'lines+markers',
        showlegend: false,
        visible: iComp < _this.maxRecipes ? true : "legendonly",
        line: {
          color: _this.defaultPlotlyColors[iComp],
        },
        marker: {
          color: _this.defaultPlotlyColors[iComp],
        },
        connectgaps: false,
        legendgroup: "group" + iComp,
      });

      traces.push({
        x: trendTimestamps[iComp],
        traceType: 'movingAvg',
        y: movingAvg,
        xaxis: 'x2',
        yaxis: 'y2',
        name: comp,
        type: c_typePlot,
        mode: 'lines+markers',
        width: aggrBarsMovingAvg.width[iComp],
        offset: aggrBarsMovingAvg.offset[iComp],
        line: {
          color: _this.defaultPlotlyColors[iComp],
        },
        marker: {
          color: _this.defaultPlotlyColors[iComp],
        },
        visible: false,
        showlegend: false,
        connectgaps: false,
        legendgroup: "group" + iComp,
      });
    });

    let updatemenus: any = [{
      buttons: [{
        args: [{
          // 'showlegend': evenTraces,
          'visible': traces.map((x: any) => x.traceType != 'movingAvg'),
        }],
        label: this.translate.instant("KPI_ANALYTICS.ACTUAL_DATA"),
        method: 'update'
      },
      {
        args: [{
          // 'showlegend': oddTraces,
          'visible': traces.map((x: any) => x.traceType != 'actual'),
        }],
        label: this.translate.instant("KPI_ANALYTICS.MOVING_AVERAGE"),
        method: 'update'
      }
      ],
      direction: 'left',
      pad: {
        'r': 10,
        't': 10
      },
      showactive: true,
      type: 'buttons',
      x: 0,
      y: 1.3,
      xanchor: 'left',
      yanchor: 'top'
    },
    {
      buttons: [{
        args: [
          {
            'type': traces.map((x: any) => (x.traceType != 'box' ? 'bar' : 'box')),
          },
          {
            'yaxis.type': 'category'
          }
        ],
        label: _this.translate.instant("KPI_ANALYTICS.BAR_CHART"),
        method: 'update'
      }, {
        args: [
          {
            'type': traces.map((x: any) => (x.traceType != 'box' ? 'scatter' : 'box')),
          },
          {
            'yaxis.type': 'category'
          }
        ],
        label: _this.translate.instant("KPI_ANALYTICS.LINE_CHART"),
        method: 'update'
      },],
      direction: 'left',
      pad: {
        'r': 10,
        't': 10
      },
      showactive: true,
      active: c_typePlot == 'scatter' ? 1 : 0,
      type: 'buttons',
      // x: mobile() ? 0 : 0.3,
      // y: mobile() ? 1.35 : 1.3,
      x: 0.3,
      y: 1.3,
      xanchor: 'left',
      yanchor: 'top'
    },
    ];

    var shapes = _this.buildLinesFromAggrAndTime(_this.intervalAggregations.selected.unit, trendTimestamps, _this.intervalAggregations.selected.value);

    let plotLayout: any = {
      shapes: shapes,
      font: {
        // family: 'Nunito, sans-serif',
        size: 12,
      },
      updatemenus: updatemenus,
      showlegend: true,
      hovermode: 'closest',
      // grid: {
      //   rows: 1,
      //   columns: 2
      // },
      margin: {
        t: 30,
        r: 110,
        b: 50,
        l: 50,
        pad: 15
      },
      xaxis: {
        domain: [0, 0.2],
        zeroline: false,
        showgrid: false,
        automargin: true,
        title: {
          // standoff: 200,
          text: data.KPIlabelBoxPlot
        }
      },
      xaxis2: {
        domain: [0.3, 1],
        zeroline: false,
        showgrid: false,
      },
      yaxis: {
        zeroline: false,
        showgrid: false,
        showticklabels: false,
        type: 'category'
      },
      yaxis2: {
        anchor: 'x2',
        zeroline: false,
        showgrid: false,
        title: data.KPIlabelMovingAverage,
      },
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: -0.25
      }
    };

    // if (data.dropdownConfig.min != null && data.dropdownConfig.max != null) {
    //   plotLayout.yaxis2.autorange = false;
    //   plotLayout.yaxis2.range = [data.dropdownConfig.min, data.dropdownConfig.max];
    // }

    // console.log({ traces });

    return {
      layout: plotLayout,
      traces: traces,
      params: {
        plotType: true,
        boxDetail: true,
      }
    };

  }

  parseTableData(data: any) {
    let tableRows: any = [];

    // check presence of mandatory variables 
    if (data.timeserie == null || data.timeserie.tracks == null || data.timeserie.tracks.length == 0) {
      return tableRows;
    }

    data.timeserie.tracks.forEach((track: any) => {
      track.timestamps.forEach((timestamp: any, i: any) => {

        if (this.hideZerosSwitch?.checked && track.values[i] == null) {
          // Don't add
        }
        else {
          tableRows.push({
            timestampP: this.filterService.parseMoment(timestamp, this.getFormatFromAggrTime(this.intervalAggregations?.selected?.unit ?? 'day'), this.machine.timezone),
            timestamp: timestamp,
            value: track.values[i],
            movingAvg: track.movingAvg[i],
            valueP: this.filterService.parseObjFromConfig(track.values[i], this.KPIDropdown.selected, true, "format"),
            movingAvgP: this.filterService.parseObjFromConfig(track.movingAvg[i], this.KPIDropdown.selected, true, "format"),
            aggrUnitId: track.aggrUnitId,
          });
        }
      });
    });

    return tableRows;
  }

  getAggrBars(data: any, timeAggrUnit: any, timeAggrVal: any, prop?: any) {

    var copyData: any = this.clonerService.deepClone(data);

    // Get days from time aggregation
    let aggrTime = this.getDaysFromAggrUnit(timeAggrUnit, timeAggrVal)

    var barProps: any = {
      offset: [],
      width: [],
    };

    barProps.timestamps = this.clonerService.deepClone(copyData.map((aggr: any) => aggr.timestamps));
    barProps.values = this.clonerService.deepClone(copyData.map((aggr: any) => aggr[prop != null ? prop : 'values']));
    barProps.width = this.clonerService.deepClone(copyData.map((aggr: any) => aggr[prop != null ? prop : 'values']));
    barProps.offset = this.clonerService.deepClone(copyData.map((aggr: any) => aggr[prop != null ? prop : 'values']));
    barProps.flatTimestamps = barProps.timestamps.flat();
    barProps.flatValues = this.clonerService.deepClone(copyData.map((aggr: any) => aggr[prop != null ? prop : 'values']).flat());

    var occurencies: any = {};
    // console.log(barProps, copyData, aggrTime);

    barProps.timestamps.forEach((aggr: any, ix: any) => {
      aggr.forEach((time: any, timeIdx: any) => {

        if (!occurencies.hasOwnProperty(time)) {
          occurencies[time] = 0;
        }
        let occur = barProps.flatTimestamps.filter((x: any, idx: any) => (x == time && barProps.flatValues[idx] != null)).length;
        let occurFract = occur == 0 ? 0 : 1 / occur;

        if (timeAggrUnit == 'shift') {
          try {
            // console.log(copyData[ix]);
            aggrTime = moment(copyData[ix].shiftSpan[timeIdx][1]).diff(moment(copyData[ix].shiftSpan[timeIdx][0]), 's') / (3600 * 24);
          } catch (error) {
            console.log(error);
          }
        }

        barProps.width[ix][timeIdx] = occurFract * 1000 * 3600 * 24 * aggrTime * 0.75;
        barProps.offset[ix][timeIdx] = occurFract * (occurencies[time] != null ? occurencies[time] : 0) * 1000 * 3600 * 24 * aggrTime;
        if (barProps.values[ix][timeIdx] != null) {
          if (occurencies.hasOwnProperty(time)) {
            occurencies[time] += 1;
          } else {
            occurencies[time] = 0;
          }
        }
      });
    });
    return barProps;
  }

  getDaysFromAggrUnit(aggrUnit: any, aggrVal?: any) {
    let val = null;
    switch (aggrUnit) {
      case 'minute':
        val = 1 / 24 / 60;
        break;
      case 'day':
      default:
        val = 1;
        break;
      case 'hour':
        val = 1 / 24;
        break;
      case 'shift':
        val = 1 / 3;
        break;
      case 'week':
        val = 7;
        break;
      case 'month':
        val = 28;
        break;
    }
    return val * (aggrVal ?? 1);
  }

  getFormatFromAggrTime(aggrUnit: any) {
    switch (aggrUnit) {
      case 'day':
      case 'week':
      default:
        return 'MMM DD, YYYY';
      case 'shift':
      case 'hour':
      case 'minute':
        return 'MMM DD, YYYY HH:mm:ss';
      case 'month':
        return 'MMM YYYY';
    }
  }

  getFollowingAggrFromAggrUnit(aggrUnit: any) {
    switch (aggrUnit) {
      case 'day':
      default:
        return 'week';
      case 'shift':
      case 'hour':
        return 'day';
      case 'minute':
        return 'hour';
      case 'week':
        return 'month';
      case 'month':
        return 'year';
    }
  }


  getStartTime(timestamps: any) {

    var min = moment();
    timestamps.forEach((t: any) => {
      if (min.diff(moment(t[0]), 'seconds') > 0) {
        min = moment(t[0]);
      }
    })
    return (min.format('YYYY-MM-DD'));
  }

  getEndTime(timestamps: any) {

    var max = moment(0);
    timestamps.forEach((t: any) => {
      if (max.diff(moment(t[t.length - 1]), 'seconds') < 0) {
        max = moment(t[t.length - 1]);
      }
    })
    return (max.format('YYYY-MM-DD'));
  }

  buildLinesFromAggrAndTime(timeAggrUnit: any, timestamps: any, timeAggrVal?: any) {
    try {

      let timeAggrUnitP: any = timeAggrUnit + 's';

      // Calculate time start and time end from time range
      let timeStart: any = this.getStartTime(timestamps);
      let timeEnd: any = this.getEndTime(timestamps);

      // obtain following aggregation period
      let nextAggr: any = this.getFollowingAggrFromAggrUnit(timeAggrUnit);
      let nextAggrs: any = nextAggr + 's';

      // total number of periods in time range
      let nPeriodsAct: any = timeAggrUnit != 'shift' ?
        moment(timeEnd).add(1, 'days').diff(moment(timeStart), timeAggrUnit) :
        moment(timeEnd).diff(moment(timeStart), 'day') / this.getDaysFromAggrUnit(timeAggrUnit, timeAggrVal);

      nPeriodsAct = (nPeriodsAct == 0 && timeAggrUnit == 'hour') ? 24 : nPeriodsAct;

      // total number of periods in time range
      let nPeriods = moment(timeEnd).diff(moment(timeStart), nextAggr) + 1;

      let linesAct = [];
      let lines = [];

      for (let n = 0; n <= nPeriods; n++) {
        let lineTime = moment(timeStart).startOf(nextAggr).add(n, nextAggrs);
        if (lineTime.diff(moment().endOf('day')) < 0) {
          lines.push(lineTime.format("YYYY-MM-DD"));
        }
      }

      for (let n = 0; n <= nPeriodsAct; n++) {

        let lineTime = moment(timeStart).startOf(timeAggrUnit).add(n, timeAggrUnitP);
        let formatTime = "YYYY-MM-DD";

        if (timeAggrUnit == 'shift') {
          lineTime = moment(timeStart).startOf('day').add(6, 'hours').add(n * 24 * this.getDaysFromAggrUnit(timeAggrUnit, timeAggrVal), 'hours');
          formatTime = "YYYY-MM-DD HH:mm:ss";
        } else if (timeAggrUnit == 'hour') {
          formatTime = "YYYY-MM-DD HH:mm:ss";
        }

        if (lineTime.diff(moment().endOf('day')) < 0) {
          linesAct.push(lineTime.format(formatTime));
        }
      }

      let finalLines = lines.map(line => {
        return {
          type: 'line',
          yref: 'paper',
          xref: 'x2',
          x0: line,
          x1: line,
          y0: 0,
          y1: 1,
          opacity: 0.3,
          line: {
            color: 'grey',
            width: 1
          }
        }
      }).concat(linesAct.map(line => {
        return {
          type: 'line',
          yref: 'paper',
          xref: 'x2',
          x0: line,
          x1: line,
          y0: 0,
          y1: 1,
          opacity: 0.2,
          line: {
            dash: 'dot',
            color: 'grey',
            width: 1
          }
        }
      }));
      return finalLines;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  switchChange(flag) {
    this.hideZerosSwitch.checked = flag;
    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    this.pageState.next(6);
    this.getDataPolling(this);
  }

  boxPlotSwitchChange(flag) {
    this.showBoxplotSwitch.checked = flag;
    this.postParsing(this);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {

    this.internalDataService.setCalendarPage(false);
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingEvents.unsubscribe() } catch (error) { }
    try { this.boxPlotDataSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
  }

}