<form class="form-card">
  <div class="form-group row" fxLayout="row" fxLayoutAlign="start center">
    <label class="col-sm-2 side-label">{{ 'STOPS_SHEET.CAUSE' | translate }}:</label>
    <div class="col-sm-6">
      <select class="form-input" id="causes" name="causes" disabled>
        <option selected>
          {{ stop.cause }}
        </option>
      </select>
    </div>
    <label class="col-sm-2 side-label">{{ 'STOPS_SHEET.TRAFILA' | translate }}:</label>
    <div class="col-sm-2">
      <div class="btn-group" role="group" fxLayout="row" fxLayoutAlign="end center">
        <label class="custom-btn" *ngFor="let trafila of trafile; let i = index"
          [ngClass]="(stop.trafila - 1)  === i ? 'selected' : 'custom-btn-disabled'" disabled>
          <input class="hide" type="radio" id="trafila" name="trafila" [value]="trafila" [ngModel]="defaultTrafila"
            disabled>
          {{ trafila }}
        </label>
      </div>
    </div>
  </div>
  <div class="form-group row" fxLayout="row" fxLayoutAlign="start center" style="margin-top: 1vh;">
    <label class="col-sm-2 side-label">{{ 'STOPS_SHEET.START_DATE' | translate }}:</label>
    <div class="col-sm-4">
      <input type="date" class="form-input" name="startDate" [ngModel]="stop.startDate" disabled>
    </div>
    <label class="col-sm-2 side-label">{{ 'STOPS_SHEET.END_DATE' | translate }}:</label>
    <div class="col-sm-4">
      <input type="date" class="form-input" name="endtDate" [ngModel]="stop.endDate" disabled>
    </div>
  </div>
  <div class="form-group row" fxLayout="row" fxLayoutAlign="start center">
    <label class="col-sm-2 side-label">{{ 'STOPS_SHEET.START_TIME' | translate }}:</label>
    <div class="col-sm-4">
      <input type="time" class="form-input" name="startTime" [ngModel]="stop.startTime" disabled>
    </div>
    <label class="col-sm-2 side-label">{{ 'STOPS_SHEET.END_TIME' | translate }}:</label>
    <div class="col-sm-4">
      <input type="time" class="form-input" name="endtTime" [ngModel]="stop.endTime" disabled>
    </div>
  </div>
  <div class="form-group row" fxLayout="row" fxLayoutAlign="start center">
    <span fxFlex></span>
    <div fxLayout="row" fxLayoutAlign="end center">
      <button class="custom-btn custom-btn-danger" type="button" style="margin-top: 1vh;" (click)="onDeleteStop(index)">{{ 'STOPS_SHEET.DELETE' | translate }}</button>
    </div>
  </div>
</form>
<!-- <hr style="margin: 0"> -->
