<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null " class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center"
    [ngStyle]="{'margin-top': isMobile ? '12px' : '0'}">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

  </div>

  <div class="ff-streaming-container w100" [ngStyle.lt-md]="{'margin': '12px 0'}"
    *ngIf="pageState.value >= pageStateReady && !noVideoAvailable">
    <div fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" fxLayout="column"
      fxLayoutAlign="center stretch" fxLayoutGap="18px" style="background-color: transparent; margin: 12px 16px;">

      <!-- variables and components selection -->
      <!-- <button *ngIf="!isMobile" mat-button (click)="openVariablesSelectionDialog()" class="filter-button" fxLayout="row"
        fxLayoutAlign="center center" [disabled]="pageState.value < pageStateReady">
        {{ 'CONTINUOUS_EXPLORATION.VARIABLES_SELECTION' | translate }}
        <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
          style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
        </mat-icon>
      </button> -->

      <!-- <span fxFlex></span> -->
      <!-- <span style="font-weight: 800; font-size: 21px; line-height: 30px; color: #1A1919; margin: 16px 8px 8px 8px;">
        {{ 'VIDEO_STREAMING.TITLE' | translate }}
      </span> -->
      <!-- <span flex></span> -->

      <div fxLayout="column" fxLayoutAlign="center start" class="column-title-value" style="margin-right: 48px;">
        <span class="title">{{ 'VIDEO_STREAMING.STREAM_START' | translate }}</span>
        <span class="value">{{ streamStart | placeholder }}</span>
      </div>

      <span flex></span>

      <div fxLayout="column" fxLayoutAlign="center start" class="column-title-value">
        <span class="title">{{ 'LAST_VIDEO.EVENT_TYPE' | translate }}</span>

        <div *ngIf="eventConfig != null" fxLayout="row" fxLayoutAlign="center center"
          [style.background-color]="eventConfig.bgColor!= null ? eventConfig.bgColor : '#FFFFFF'"
          [style.color]="eventConfig.fontColor != null ? eventConfig.fontColor : '#373A3F'" class="state-container">
          <span>{{eventConfig?.label | translate }}</span>
        </div>
      </div>

      <span flex></span>

      <div fxLayout="column" fxLayoutAlign="center start" class="column-title-value" style="margin-right: 4px;">
        <span class="title">{{ 'VIDEO_STREAMING.STREAM_END' | translate }}</span>
        <span class="value">{{ streamEnd | placeholder }}</span>
      </div>

    </div>

    <div fxLayout="column" fxLayoutAlign="center center"
      style="margin: 12px 16px; min-height: 60px; background-color: transparent;">

      <div fxLayout="row" fxLayoutAlign="start center" class="w100">

        <!-- slider -->
        <div *ngIf="slider != null" fxLayout="row" fxLayoutAlign="start center" class="ngx-slider-container">
          <ngx-slider [(value)]="slider.min" [options]="slider.options" (userChangeEnd)="slider.userChangeEnd($event)"
            (valueChange)="slider.valueChange($event)" class="no-max-width" style="margin-right: 24px;">
          </ngx-slider>
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">

          <button class="rounded-button md-primary" (click)="play()" aria-label="Play" style="margin-right: 8px;"
            [disabled]="playerState == 1 || pageState.value < pageStateReady || notLoadedVideos">
            <mat-icon class="icon">play_arrow</mat-icon>
          </button>

          <button class="rounded-button md-primary" (click)="pause()" aria-label="Pause" style="margin-right: 8px;"
            [disabled]="playerState == 0 || pageState.value < pageStateReady || notLoadedVideos">
            <mat-icon class="icon">pause</mat-icon>
          </button>

        </div>

      </div>

    </div>
  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    fxLayoutAlign="{{ (pageState.value < 7 || pageState.value == 0) && !noVideoAvailable ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 6" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 5" fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row"
      [fxLayoutAlign.gt-sm]="noVideoAvailable ? 'start start' : 'center center'" class="w100 h100">

      <div *ngIf="noVideoAvailable" class="w100 ff-streaming-container mt-3" fxLayout="row wrap"
        fxLayoutAlign="center center">
        <p>{{ 'LAST_VIDEO.VIDEO_NOT_AVAILABLE' | translate }}</p>
      </div>

      <div fxFlex.gt-sm fxFlexOrder.gt-sm="1" fxFlexOrder="2" class="w100 h100" fxLayout="row wrap"
        fxLayoutAlign="{{ pastVideosStreamings?.length == 1 ? 'center start' : 'center start' }}"
        *ngIf="pastVideosStreamings?.length > 0" style="margin-top: 12px;"
        [ngStyle.gt-sm]="{'overflow': 'auto', 'height': 'calc(100vh - 280px)'}">

        <ng-container *ngFor="let video of pastVideosStreamings; let i = index">

          <!-- AZURE -->
          <ng-container *ngIf="!edgeVideoStreaming">
            <video fxFlex="{{ pastVideosStreamings?.length == 1 ? 48 : 48 }}" id="{{video.id}}"
              class="azuremediaplayer amp-default-skin amp-big-play-centered" tabindex="0"
              height="{{ calculateHeight(video.id) }}" width="100%" style="margin: 0 8px 8px 8px; border-radius: 20px;">
            </video>
          </ng-container>

          <!-- EDGE -->
          <ng-container *ngIf="edgeVideoStreaming">
            <video fxFlex="{{ pastVideosStreamings?.length == 1 ? 48 : 48 }}" id="{{video.id}}" class="video-js"
              height="{{ calculateHeight(video.id) }}" width="100%" style="margin: 0 8px 8px 8px; border-radius: 20px;"
              controls>
              <source [src]="video.completeName" type='video/mp4' />
            </video>
          </ng-container>

        </ng-container>

      </div>

    </div>

  </div>

</div>
