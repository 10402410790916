
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit, OnDestroy {

  public confirmClass: any;
  public cancelClass: any;
  public confirmAdditionalActionClass: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<ErrorDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.confirmClass = this.dialog.confirmClass ?? 'md-primary';
    this.cancelClass = this.dialog.cancelClass ?? 'md-gray';

    if (this.dialog?.additionalButton) this.confirmAdditionalActionClass = this.dialog.confirmAdditionalActionClass ?? 'md-gray';
  }

  ngOnDestroy() { }

  confirm() { this.dialogRef.close(true) }

  confirmAdditionalAction() { if (this.dialog?.additionalButton) this.dialogRef.close({ additionalAction: true }) }

}
