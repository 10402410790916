import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ClonerService } from 'src/app/services/clone.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'ff-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss']
})
export class GaugeComponent implements OnInit, OnChanges {

  @Input() gaugeData: any;
  @Input() widget: any;

  public height: number = 150;
  public width: number = 150;
  public viewbox: string = '0 0 ' + this.height + ' ' + this.width;
  public strokeWidth: number = 16;
  public strokeFill: string = "white";
  public color: string = "transparent";
  public cx: number = this.height / 2;
  public cy: number = this.width / 2;
  public r: number = (this.height - this.strokeWidth) / 2;
  public cxBack: number = this.height / 2;
  public cyBack: number = this.width / 2;
  public rBack: number = (this.height - this.strokeWidth) / 2;
  public linecap: string = "round";
  public percentageFillCircle: number = 0.75;
  public strokeDasharray: string = "";
  public strokeDasharrayBack: string = "";
  public filterBackground: string = "url(#shadow)";
  public multiplier: number = 100;
  public revert: boolean = false;

  constructor(
    private filtersService: FiltersService,
    private internalDataService: InternalDataService,
    private clonerService: ClonerService,
  ) { }

  ngOnInit(): void {
    this.parseGaugeData();
  }

  ngOnChanges(): void {
    if (this.widget == null && this.gaugeData != null && this.gaugeData.hasOwnProperty('skipOnChanges') && this.gaugeData.skipOnChanges) {
      // Skip On Changes
    } else {
      this.parseGaugeData();
    }
    
  }

  parseGaugeData() {

    if (this.widget != null) {
      try {
        this.widget.config.value = this.widget.data[this.widget.config.variable];
      } catch (error) { }
    }

    let gData = this.clonerService.deepClone(this.widget != null ? this.widget.config : this.gaugeData);

    this.gaugeData = this.clonerService.deepClone(gData);

    if (this.gaugeData != null) {

      let setPlaceholder = this.gaugeData.value == null;

      this.gaugeData.value = this.gaugeData.value != null ? this.gaugeData.value : 0;

      this.revert = this.gaugeData.revert != null ? this.gaugeData.revert : false;

      // height
      this.height = this.gaugeData.height != null ? this.gaugeData.height : this.height;
      // width
      this.width = this.height;
      // viewbox
      this.viewbox = '0 0 ' + this.height + ' ' + this.width;

      // strokeWidth
      this.strokeWidth = this.gaugeData.strokeWidth != null ? this.gaugeData.strokeWidth : this.strokeWidth;
      // strokeFill
      this.strokeFill = this.gaugeData.strokeFill != null ? this.gaugeData.strokeFill : this.strokeFill;
      // color
      this.color = this.gaugeData.color != null ? this.gaugeData.color : this.color;
      // linecap
      this.linecap = this.gaugeData.linecap != null ? this.gaugeData.linecap : this.linecap;

      // cx, cy, r
      this.cx = this.height / 2;
      this.cy = this.width / 2;
      this.r = (this.height - this.strokeWidth) / 2;

      // cxBack, cyBack, rBack
      this.cxBack = this.height / 2;
      this.cyBack = this.width / 2;
      this.rBack = ((this.height - this.strokeWidth) / 2) - (this.strokeWidth / 2);

      // percentageFillCircle
      this.percentageFillCircle = this.gaugeData.percentageFillCircle != null ? this.gaugeData.percentageFillCircle : this.percentageFillCircle;

      // filterBackground
      this.filterBackground = this.gaugeData.filterBackground != null ? this.gaugeData.filterBackground : this.filterBackground;

      // multiplier
      this.multiplier = this.gaugeData.multiplier != null ? this.gaugeData.multiplier : this.multiplier;

      // color
      this.color = this.internalDataService.getBarColor((!setPlaceholder ? this.gaugeData.value : -1), this.multiplier, this.revert);

      // parse value
      this.gaugeData.valueStr = !setPlaceholder ? (this.filtersService.parseGaugeValue(this.gaugeData.value, 0, this.multiplier) + '%') : '-';

      // strokeDasharray
      this.strokeDasharray = this.getStrokeDasharray(this.r, (this.gaugeData.value > 1 ? 1 : this.gaugeData.value), this.multiplier);

      // strokeDasharrayBack
      this.strokeDasharrayBack = this.getStrokeDasharray(this.r, 100);

    }
  }

  getStrokeDasharray(r: number, value?: number, multiplier?: number) {
    let circumference = r * 2 * Math.PI;
    if (value != null) {
      if (multiplier == null) multiplier = 1;
      return (circumference * this.percentageFillCircle * value * multiplier / 100) + ' ' + (circumference);
    } else {
      return (circumference * this.percentageFillCircle) + ' ' + (circumference);
    }
  }

}
