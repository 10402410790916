<table class="standard-table">
  <thead>
    <tr>
      <th *ngFor="let info of tableInfos">
        <span>{{ info.label | translate }}</span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of elemList">

      <td *ngFor="let info of tableInfos" [ngClass]="{'primary' : info.primary}">

        <div *ngIf="!info.type">
          <span>{{(item[info.variable] != null ? item[info.variable] : '-')}}</span>
          <span *ngIf="info.unit != null">&nbsp;{{info.unit}}</span>
        </div>

        <div *ngIf="info.type == 'interval'">
          <span>{{(item.timeStartP != null ? item.timeStartP : '-')}}</span><br>
          <span>{{(item.timeEndP != null ? item.timeEndP : '-')}}</span>
        </div>

        <div *ngIf="info.type == 'progressBar'">
          <kpi-bar [kpiProps]="{ 'value': item[info.variable], 'hideLabel': true }"></kpi-bar>
        </div>

        <div *ngIf="info.type == 'icon' && item[info.variable] != null && item[info.variable].icon != null"
          matTooltip="{{ item[info.variable].label |translate}}">
          <mat-icon *ngIf="item[info.variable].icon.type == 'icon'" class="icon left"
            translate-attr="{'aria-label': info.label}">
            {{item[info.variable].icon.icon}}</mat-icon>
          <mat-icon *ngIf="item[info.variable].icon.type == 'svg'" svgIcon="{{ item[info.variable].icon.icon }}"
            class="icon left" translate-attr="{'aria-label': info.label}"></mat-icon>
          <i *ngIf="item[info.variable].icon.type == 'fontawesome' || item[info.variable].icon.type == 'phosphor'"
            class="icon left {{ item[info.variable].icon.icon }}" translate-attr="{'aria-label': info.label}"></i>
        </div>

        <div *ngIf="info.type == 'link'" matTooltip="{{ 'CYCLES.NAVIGATE_TO_item_DETAIL' | translate }}"
          class="clickable" [routerLink]="item[info.variable] != null ? item[info.variable] : item.urlArray">
          <mat-icon class="icon left">open_in_new</mat-icon>
        </div>

        <div *ngIf="info.type == 'checkbox'" fxLayout="row" fxLayoutAlign="start center" class="clickable"
          (click)="toggleitemForGCA(item)">

          <mat-icon *ngIf="item[info.variable]" svgIcon="radio_button" class="icon small left radio_button">
          </mat-icon>
          <mat-icon *ngIf="!item[info.variable]" svgIcon="radio_button_disabled"
            class="icon small left radio_button_disabled">
          </mat-icon>

        </div>

        <div *ngIf="info.type == 'table' && item[info.variable] != null && item[info.variable].length > 0"
          class="td-table" (click)="toggleRow(item)">
          <table class="table-in-td">
            <tr *ngFor="let item of item[info.variable]; let i=index">
              <td *ngIf="item != null" style="padding: 0 0.25rem !important;">
                <span>{{item.label | translate}}</span>
              </td>
              <td *ngIf="item != null" style="padding: 0 0.25rem !important;">
                <span>{{item.value}}</span>
                <span *ngIf="item.unit != null">&nbsp;{{item.unit}}</span>
              </td>
            </tr>
          </table>
        </div>

        <div *ngIf="info.type == 'list' && item[info.variable] != null && item[info.variable].length > 0"
          class="td-list" (click)="toggleRow(item)">
          <ul>
            <li *ngFor="let li of item[info.variable]; let i=index">
              <div *ngIf="item[info.variable + '_max'] != null && i < item[info.variable + '_max']">
                <span>{{li.label | translate}}: </span>
                <span>{{li.value}}</span>
                <span *ngIf="li.unit != null">&nbsp;{{li.unit}}</span>
              </div>
            </li>
          </ul>
        </div>

      </td>
    </tr>
  </tbody>
</table>