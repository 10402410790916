<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
    class="w100 h100">
    <!-- error -->
    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
    </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

    <!-- toolbar tabs -->
    <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="start center">

        <!-- tabs -->
        <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

        <span fxFlex fxShow="false" fxShow.gt-sm></span>

    </div>

    <!-- tab content -->
    <div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">
        <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content">
            <mat-card fxLayout="column" class="standard-mat-card">
                <!-- <mat-card-title fxLayout="row">
                <mat-icon class="icon" svgIcon="customer-service" style="margin: 0 8px 0 0 ;"></mat-icon>
                <span class="md-headline uppercase" style="color: #21232B;">
                    {{'TECHNICAL_SUPPORT.SUBSCRIPTION_FORM' | translate | uppercase }}
                </span>
            </mat-card-title> -->
                <mat-card-content fxLayout="row wrap" fxLayoutAlign="start stretch" style="padding: 0 1rem;">
                    <div *ngFor="let item of fieldItems" fxFlex="1 1 calc(100% - 2rem)"
                        fxFlex.gt-sm="0 1 calc(50% - 2rem)" style="margin-right: 2rem;">
                        <mat-label style="line-height: 2rem;">{{ item.label | translate }}</mat-label>
                        <input matInput *ngIf="!item.hasOwnProperty('options')" class="ff-input" style="width: 100%"
                            type="item.type" [disabled]="item.disabled" [(ngModel)]="item.value"
                            (keyup)="touchedField(item)" (click)="touchedField(item)">
                        <select *ngIf="item.type == 'select' && item.hasOwnProperty('options')" class="ff-input"
                            style="width: 100%;" [disabled]="item.disabled" [(ngModel)]="item.value"
                            (click)="touchedField(item)">
                            <option *ngFor="let opt of item.options" [value]="opt.id">{{ filterService.translateElementNewFormat(opt)}}
                            </option>
                        </select>
                        <div [ngClass]="item.touched ? 'error-input' : 'transparent-input'" style="min-height: 1.4rem">
                            <span *ngIf="item.value == null || item.value == ''">{{ 'GLOBAL.REQUIRED' | translate
                                }}</span>
                            <span
                                *ngIf="item.value != null && item.value != '' && item.type == 'email' && !item.validEmail">{{
                                'GLOBAL.INVALID_EMAIL' | translate }}</span>
                            <span style="color: transparent"></span>
                        </div>
                    </div>
                    <div fxFlex="1 1 calc(100% - 8px)" style="margin-top: .5rem;">
                        <textarea id="message" placeholder="{{ 'TECHNICAL_SUPPORT.MESSAGE_PLACEHOLDER' | translate }}"
                            rows="7" class="ff-input" style="width: 100%; padding-top: 12px"
                            [(ngModel)]="message"></textarea>
                    </div>
                    <div *ngIf="!removePrivacyPolicy" fxFlex="1 1 100%" style="margin-top: 1rem;">
                        <a href="https://www.mflgroup.com/EN/privacy-policy"
                            target="_blank"><span>{{'TECHNICAL_SUPPORT.POLICY_LINK' | translate }}</span></a>
                    </div>
                    <div class="w100" fxLayout="column" fxLayout.gt-sm="row" style="margin-top: 8px;">
                        <mat-checkbox *ngIf="!removePrivacyPolicy" [(ngModel)]="gdprCheck" aria-label="Checkbox 1"
                            style="padding: 7px 0">
                            <span>{{'TECHNICAL_SUPPORT.AGREEMENT' | translate}}</span>
                        </mat-checkbox>
                        <span fxFlex></span>
                        <div>
                            <span fxFlex fxHide.gt-sm></span>
                            <button mat-button class="button button-1" [disabled]="formValidityCheck()"
                                (click)="openConfirmationDialog()">
                                <span>{{'TECHNICAL_SUPPORT.SEND_REQUEST' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>