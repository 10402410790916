import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { IntervalService } from 'src/app/services/interval.service';
@Component({
  selector: 'app-box-plot-dialog',
  templateUrl: './box-plot-dialog.component.html',
  styleUrls: ['./box-plot-dialog.component.scss']
})
export class BoxPlotDialogComponent implements OnInit, OnDestroy {

  public plotState: any = 0;
  public widget: any;
  public tableInfos: any;
  public statisticsOrder: any = [
    { id: "min", decimals: 2, showUnit: true, enableMultiplier: true },
    { id: "q1", decimals: 2, showUnit: true, enableMultiplier: true },
    { id: "median", decimals: 2, showUnit: true, enableMultiplier: true },
    { id: "q3", decimals: 2, showUnit: true, enableMultiplier: true },
    { id: "max", decimals: 2, showUnit: true, enableMultiplier: true },
    { id: "outliers", decimals: 0 },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<BoxPlotDialogComponent>,
    public apiService: ApiService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public clonerService: ClonerService,
  ) {

  }

  closeDialog() {
    this.dialogRef.close();
  }

  buildTable(data: any) {
    let table: any = [];
    if (data != null) {
      this.statisticsOrder.forEach((stat: any) => {
        if (data.hasOwnProperty(stat.id)) {
          table.push({
            label: "STATISTICAL_DATA." + stat.id,
            value: this.filterService.parseGaugeValue(
              stat.enableMultiplier
                ? this.filterService.convertUnit(this.dialog.unit, data[stat.id]).value
                : data[stat.id],
              stat.decimals,
              (stat.enableMultiplier && this.dialog.multiplier != null) ? this.dialog.multiplier : 1
            ),
            unit: stat.showUnit ? this.translate.instant(this.filterService.convertUnit(this.dialog.unit).unit ?? '-') : null
          });
        }
      })
    }
    return table;

  }

  ngOnInit(): void {

    let traces: any = [{
      x: this.dialog.boxPlotData,
      name: '',
      type: 'box',
      hoverinfo: "skip",
      marker: {
        color: this.dialog.color
      }
    }];

    let plotLayout = {
      annotations: [],
      font: {
        size: 12,
      },
      margin: {
        t: 0,
        r: 20,
        b: 50,
        l: 20,
        pad: 5
      },
      xaxis: {
        showgrid: false,
        zeroline: false,
      },
      yaxis: {
        showgrid: false,
        zeroline: false,
      }
    };

    let plotConfig = {
      boxPlotDataTrend: {
        traces: traces,
        layout: plotLayout
      }
    };

    this.widget = {
      config: { plotDataAttribute: 'boxPlotDataTrend' },
      data: plotConfig
    };

    this.tableInfos = this.buildTable(this.dialog.statisticalData);

  }

  ngOnDestroy() {
  }

}
