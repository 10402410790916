module.exports = class CustomParsing {

  constructor(data, machineId = null, machine = null, additionalConfig = null) {
    this.data = data;
    this.machineId = machineId;
    this.machine = machine;
    this.additionalConfig = additionalConfig;
  }

  display() {
      this.data.forEach(x => x.id += 'alloraaa');
      return this.data;
  }
}
