import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-edit-shift-template',
  templateUrl: './edit-shift-template.component.html',
  styleUrls: ['./edit-shift-template.component.scss']
})
export class EditShiftTemplateComponent implements OnInit, OnDestroy {

  public shiftSelectionHours: any = [];
  public shiftSelectionMinutes: any = [];
  public newShiftTemplate: any = {};
  public dayStartHour: any;
  public dayStartMinute: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<EditShiftTemplateComponent>,
    public apiService: ApiService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public clonerService: ClonerService,
  ) {

    let mult = 30;

    let minimumCalendarUnit = this.dialog?.profile?.settings?.minimumCalendarUnit ?? '30m';
    if (minimumCalendarUnit != null) {
      if (minimumCalendarUnit == '30m') mult = 30;
      else if (minimumCalendarUnit == '1h') mult = 60;
    }

    for (var s = 0; s < 60; s++) {
      if (s < 10) {
        this.shiftSelectionHours.push({
          value: s,
          label: "0" + s
        });
        if (s % mult == 0) {
          this.shiftSelectionMinutes.push({
            value: s,
            label: "0" + s
          });
        }
      } else if (s >= 10 && s < 24) {
        this.shiftSelectionHours.push({
          value: s,
          label: s
        });
        if (s % mult == 0) {
          this.shiftSelectionMinutes.push({
            value: s,
            label: s
          });
        }
      } else if (s >= 24 && s < 60 && s % mult == 0) {
        this.shiftSelectionMinutes.push({
          value: s,
          label: s
        });
      }
    }

    if (this.dialog.shiftTemplateDialog != null && this.dialog.shiftTemplateDialog.hasOwnProperty('shiftTemplate') &&
      this.dialog.shiftTemplateDialog.shiftTemplate != null) {

      this.newShiftTemplate = {
        id: this.dialog.shiftTemplateDialog.shiftTemplate.id,
        name: this.dialog.shiftTemplateDialog.shiftTemplate.name,
        color: this.dialog.shiftTemplateDialog.shiftTemplate.color,
        shifts: []
      };

      try {
        let hour = this.dialog.shiftTemplateDialog.shiftTemplate.shifts[0].from.split(":")[0];
        let minute = this.dialog.shiftTemplateDialog.shiftTemplate.shifts[0].from.split(":")[1];

        this.dayStartHour = this.shiftSelectionHours.find((x: any) => x.label == hour).value;
        this.dayStartMinute = this.shiftSelectionMinutes.find((x: any) => x.label == minute).value;

      } catch (error) {
        console.log(error);
      }

      setTimeout(() => {
        this.dialog.shiftTemplateDialog.shiftTemplate.shifts.forEach((shift: any) => this.addShift(shift));
      }, 250);

    } else {
      this.newShiftTemplate = {
        id: this.filterService.generateRandomString(),
        name: "Shift Template " + parseInt(this.dialog.shiftTemplates.length + 1),
        color: 'rgb(255, 0, 0)',
        shifts: []
      };
    }
  }

  addShift(shift?: any) {

    let _this = this;
    let shiftId = this.newShiftTemplate.shifts.length + 1;

    let min = null;
    if (shift != null) {
      try {
        let hour = shift.from.split(":")[0];
        let minute = shift.from.split(":")[1];

        let minHour = this.shiftSelectionHours.find((x: any) => x.label == hour).value;
        let minMinute = this.shiftSelectionMinutes.find((x: any) => x.label == minute).value;

        min = minHour * 3600 + minMinute * 60;
      } catch (error) {
        console.log(error);
      }
    }
    min = min != null ? min :
      (this.newShiftTemplate.shifts.length > 0 ?
        this.newShiftTemplate.shifts[this.newShiftTemplate.shifts.length - 1].slider.max :
        this.dayStartHour * 3600 + this.dayStartMinute * 60
      );

    let max = null;
    if (shift != null) {
      try {
        let hour = shift.to.split(":")[0];
        let minute = shift.to.split(":")[1];

        let minHour = this.shiftSelectionHours.find((x: any) => x.label == hour).value;
        let minMinute = this.shiftSelectionMinutes.find((x: any) => x.label == minute).value;

        max = minHour * 3600 + minMinute * 60;
        max = max > min ? max : max + 24 * 3600;
      } catch (error) {
        console.log(error);
      }
    }
    max = max != null ? max : this.dayStartHour * 3600 + this.dayStartMinute * 60 + 24 * 3600;

    this.newShiftTemplate.shifts.push({
      shiftId: shiftId,
      slider: {
        show: true,
        min: min,
        max: max,
        options: {
          showTicks: true,
          minLimit: this.newShiftTemplate.shifts.length > 0 ? this.newShiftTemplate.shifts[this.newShiftTemplate.shifts.length - 1].slider.max : this.dayStartHour * 3600 + this.dayStartMinute * 60,
          maxLimit: this.dayStartHour * 3600 + this.dayStartMinute * 60 + 24 * 3600,
          floor: this.dayStartHour * 3600 + this.dayStartMinute * 60,
          ceil: this.dayStartHour * 3600 + this.dayStartMinute * 60 + 24 * 3600,
          step: 60 * 30, // 30 minutes step
          translate: function (index: any) {
            try {
              return _this.filterService.parseTime24H(_this.filterService, index, 's', 'HH:mm');
            } catch (error) {
              return index;
            }
          },
          // onChange: function (a, min, max, type) {
          // }
        },
        userChangeEnd: function (event: any) {
          let type = event.pointerType == 1 ? 'max' : 'min';
          _this.updateMinMaxLimits(shiftId, event.value, event.highValue, type);
          // console.log('slider change', event);
          // console.log(_this.newShiftTemplate.shifts);

        }
      }
    });

    this.updateMinMaxLimits(shiftId, this.newShiftTemplate.shifts[this.newShiftTemplate.shifts.length - 1].slider.min, this.newShiftTemplate.shifts[this.newShiftTemplate.shifts.length - 1].slider.max, "min");
    this.updateMinMaxLimits(shiftId, this.newShiftTemplate.shifts[this.newShiftTemplate.shifts.length - 1].slider.min, this.newShiftTemplate.shifts[this.newShiftTemplate.shifts.length - 1].slider.max, "max");

  }

  updateMinMaxLimits(shiftId: any, min: any, max: any, type: any) {
    switch (type) {
      case 'min':
      default:
        let previousIdx = this.newShiftTemplate.shifts.findIndex((x: any) => x.shiftId == (shiftId - 1));
        if (previousIdx != -1) {
          const newOptions: any = Object.assign({}, this.newShiftTemplate.shifts[previousIdx].slider.options);
          newOptions.maxLimit = min;
          this.newShiftTemplate.shifts[previousIdx].slider.options = newOptions;
        }
        break;
      case 'max':
        let nextIdx = this.newShiftTemplate.shifts.findIndex((x: any) => x.shiftId == (shiftId + 1));
        if (nextIdx != -1) {
          const newOptions: any = Object.assign({}, this.newShiftTemplate.shifts[nextIdx].slider.options);
          newOptions.minLimit = max;
          this.newShiftTemplate.shifts[nextIdx].slider.options = newOptions;
        }
        break;
    }

  };

  checkAddShiftDisabled() {
    try {
      if (this.dayStartMinute == null || this.dayStartHour == null) return true;
      if (this.newShiftTemplate.shifts.length == 0) return false;
      return this.dayStartMinute == null || this.dayStartHour == null ||
        (
          // this.newShiftTemplate.shifts[0].slider.min == this.dayStartHour * 3600 + this.dayStartMinute * 60 &&
          this.newShiftTemplate.shifts[this.newShiftTemplate.shifts.length - 1].slider.max == this.dayStartHour * 3600 + this.dayStartMinute * 60 + 24 * 3600
        );
    } catch (error) {
      // console.log(error);
      return true;
    }
  }

  deleteShift(shift: any) {
    try {

      let shiftIdx = this.newShiftTemplate.shifts.findIndex((x: any) => x.shiftId == shift.shiftId);

      // Remove shift
      this.newShiftTemplate.shifts.splice(shiftIdx, 1);

      const newOptions: any = Object.assign({}, this.newShiftTemplate.shifts[this.newShiftTemplate.shifts.length - 1].slider.options);
      newOptions.maxLimit = this.dayStartHour * 3600 + this.dayStartMinute * 60 + 24 * 3600;
      this.newShiftTemplate.shifts[this.newShiftTemplate.shifts.length - 1].slider.options = newOptions;
      // // Fix other shifts
      // this.newShiftTemplate.shifts.forEach((ns, (idx):any) => {

      //     if (ns.shiftId == shift.shiftId - 1) {
      //         let currentIdx = idx;
      //         let nextIdx = this.newShiftTemplate.shifts.findIndex((x:any) => x.shiftId == shift.shiftId + 1);
      //         if (nextIdx != -1) {
      //             this.newShiftTemplate.shifts[nextIdx].shiftId = shift.shiftId;
      //             this.newShiftTemplate.shifts[currentIdx].slider.options.maxLimit = this.newShiftTemplate.shifts[nextIdx].slider.options.minLimit;
      //         }
      //     }
      // });

    } catch (error) {
      console.log(error);
    }
  }

  changeColor(event: any) {
    console.log(event);
  }

  checkShiftTemplateName(name: any) {
    try {
      return this.dialog.shiftTemplates.findIndex((x: any) => x.name == name) != -1 && name != this.dialog.shiftTemplateDialog.shiftTemplate.name;
    } catch (error) {
      console.log(error);
    }
    return true;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // 
  // START

  closeDialog() {
    this.dialogRef.close(this.newShiftTemplate);
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

}
