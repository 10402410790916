import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'app-machine-settings',
  templateUrl: './machine-settings.component.html',
  styleUrls: ['./machine-settings.component.scss']
})
export class MachineSettingsComponent implements OnInit, OnChanges {

  public isAllowedUser: any = true;
  public isAllowedUserWrite: any = true;

  loadingData: any;
  errorData: any;

  appConfig: any;
  appInfo: any;
  machineProfiles: any;
  machine: any;
  machineId: any;
  breadcrumb: any;
  public tabs: any;

  settingsList: any;
  settingsData: any;
  settingsVariables: any;

  public sectionName: any = 'machineSettings';
  public dashboardName: any = 'complete-consumption';

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getMachineSettings, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public route: ActivatedRoute,
    public translate: FfTranslateService,
    public clonerService: ClonerService,
  ) {

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.settingsList = this.appConfig.machineSettings.settings;
    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.breadcrumb = ['MACHINE_SETTINGS.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUserWrite = _this.internalDataService.getSpecificPermission("mat-settings-rw");
    } catch (error) { console.log(error) }

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(["mat-settings-r", "mat-settings-rw"], 'or');
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  

  public getMachineSettings(_this: any) {
    try {

      let query: any = {};

      _this.apiService.sendGetRequest('/apif/machine-settings/' + _this.machineId, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy()),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.settingsData = data.body;
            if (_this.settingsData == null) _this.settingsData = {};
            if (!_this.settingsData.variables) _this.settingsData.variables = [];
            _this.settingsVariables = _this.parseSettingsVariables(data.body);
            // console.log(_this.settingsVariables);

            _this.dispatcherService.getDispatch(_this, 300);

          },
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseSettingsVariables(data: any) {
    let variables: any = {};
    if (data.hasOwnProperty('variables')) {
      for (let v of data.variables) {
        variables[v.name] = v.value
      }
    }
    return variables;
  }

  updateSettings() {
    // console.log(this.settingsVariables, this.settingsData.variables);

    // let newVariables = [];
    this.pageState.next(4);

    for (let v1 in this.settingsVariables) {
      for (let v2 of this.settingsData.variables) {
        if (v1 == v2.name) v2.value = this.settingsVariables[v1];
        // break;
      }
    }

    let payload = this.clonerService.deepClone(this.settingsData);
    // console.log(payload);

    try {

      this.apiService.sendPutRequest('/apif/machine-settings/' + this.machineId, payload, null)
        .pipe(
          retryWhen(this.apiService.genericRetryStrategy()),
          catchError(error => this.internalDataService.parseStandardHTTPError(this, error))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            // TODO Mattia: Aggiungere toast su success/fail, stilizzare meglio nell'html, aggiungere check su numero massimo / minimo (da config)
            this.settingsData = data.body;
            if (this.settingsData == null) this.settingsData = {};
            if (!this.settingsData.variables) this.settingsData.variables = [];
            this.settingsVariables = this.parseSettingsVariables(data.body);
            // console.log(this.settingsVariables);

            this.pageState.next(6);

          },
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      this.dispatcherService.getDispatch(this, 301, errorData);
    }
  }

  ngOnInit(): void {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

}
