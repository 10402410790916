import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-image-dialog',
  templateUrl: './image-dialog.component.html',
  styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public apiService: ApiService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
  ) {
    
  }

  ngOnInit(): void {
  }

}
