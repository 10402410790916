<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <div *ngIf="isMobile" fxFlex fxLayout="column" class="tab-content h100 w100"
    [ngClass]="{ 'without-tabs': tabs.length <= 1}" fxLayoutAlign="center center">
    <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
  </div>

  <!-- tab content -->
  <div *ngIf="!isMobile" class="tab-content h100 w100" fxLayout="column"
    [fxLayoutAlign]="pageState.value >= 6 ? 'start stretch' : (pageState.value == 0 ? 'start center' : 'center center')"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 7" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div fxLayout="column" class="w100">

      <!-- dashboard -->
      <div *ngIf="prodsDataDash && pageState.value >= 7" class="w100" fxLayout.lt-sm="column" fxLayout="row wrap"
        fxLayoutAlign="start stretch" fxLayoutGap="8px grid"
        fxLayoutGap.gt-sm="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
        <ff-widget *ngFor="let w of dashboardConfig.widgets" (clicked)="tableAction($event)"
          [fxFlex.gt-sm]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
          [fxShow.lt-md]="!w.hideMobile" [fxFlex.sm]="'calc(' + w.mobileFlex + '% - 8px )'"
          [fxFlexOrder.sm]="w.mobileOrder" [widget]="{ 
              type: w.type, 
              subtype: w.subtype, 
              data: prodsDataDash, 
              machineProfile: machine.profile, 
              config: w.config, 
              title: w.title,
              interval: interval,
              intervalAggregations: intervalAggregations
            }">
        </ff-widget>
      </div>

    </div>

  </div>

</div>