<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="close">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography">

  <!-- MULTIPLE SELECTION MODE -->
  <ng-container *ngIf="!singleSelectionMode">

    <!-- MULTIPLE MACHINES -->
    <ng-container *ngIf="dialog.lineMode">
      <div *ngFor="let group of dialogList" fxLayout="column" fxLayoutAlign="center start" style="padding: 4px 0;">
        <div fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 0;" class="w100">

          <div (click)="toggleGroup(group)" class="clickable">
            <mat-icon *ngIf="group.show" svgIcon="radio_button" class="icon left clickable radio_button">
            </mat-icon>
            <mat-icon *ngIf="!group.show" svgIcon="radio_button_disabled"
              class="icon left clickable radio_button_disabled"></mat-icon>

            <span style="font-weight: 800; margin-right: 8px;">{{ group.label | translate }}</span>
          </div>

          <mat-icon *ngIf="!group.expanded" svgIcon="curtain_down" class="icon left clickable"
            (click)="toggleGroupExpansion(group)"></mat-icon>
          <mat-icon *ngIf="group.expanded" svgIcon="curtain_up" class="icon left clickable"
            (click)="toggleGroupExpansion(group)"></mat-icon>

        </div>

        <ng-container *ngIf="group.expanded">
          <div *ngFor="let trace of group.components" fxLayout="row" fxLayoutAlign="start center"
            style="padding: 4px 8px;" (click)="toggleTrace(group, trace)" class="clickable">
            <mat-icon *ngIf="trace.show" svgIcon="radio_button"
              class="icon small left clickable radio_button"></mat-icon>
            <mat-icon *ngIf="!trace.show" svgIcon="radio_button_disabled"
              class="icon small left clickable radio_button_disabled"></mat-icon>
            <span style="margin-left: 12px;">{{ trace.label | translate }}</span>
          </div>
        </ng-container>

      </div>
    </ng-container>

    <!-- SINGLE MACHINE -->
    <ng-container *ngIf="!dialog.lineMode">

      <div>

        <div class="search-input" fxLayout="row" fxLayoutAlign="start center"
          style="border: 1px solid #eaecee; margin-top: 12px">
          <mat-icon class="icon">search</mat-icon>
          <input type="text" class="w100" (keyup)="searchFromList($event)" placeholder="{{'GLOBAL.SEARCH'|translate}}">
        </div>

        <ul *ngIf="dialogList != null && dialogList.length > 1 && !availableOptions?.length">
          <li fxLayout="row" fxLayoutAlign="start center" class="clickable" (click)="setAll(allSelected)">
            <mat-icon *ngIf="allSelected" svgIcon="radio_button" class="icon left radio_button"></mat-icon>
            <mat-icon *ngIf="!allSelected" svgIcon="radio_button_disabled" class="icon left radio_button_disabled">
            </mat-icon>
            <span *ngIf="allSelected">{{ 'GLOBAL.UNSELECT_ALL' | translate }}</span>
            <span *ngIf="!allSelected && maxItemsSelectable == null">{{ 'GLOBAL.SELECT_ALL' | translate }}</span>
            <span *ngIf="!allSelected && maxItemsSelectable != null" translate="GLOBAL.SELECT_PARTIAL_NUMBER"
              [translateParams]="{n: maxItemsSelectable}"></span>
          </li>
        </ul>

        <ul>
          <li *ngFor="let component of dialogList | customSearchFilter: availableOptions : ['componentId', 'label']"
            fxLayout="row" fxLayoutAlign="start center" class="clickable" (click)="updateAllSelected(component)"
            [ngClass]="{'clickable': !component.disabled, 'disabled': component.disabled }">

            <!-- show icon -->
            <mat-icon *ngIf="component.show" svgIcon="radio_button" class="icon left radio_button"
              [ngClass]="component.class">
            </mat-icon>
            <mat-icon *ngIf="!component.show" svgIcon="radio_button_disabled" class="icon left radio_button_disabled">
            </mat-icon>

            <!-- label -->
            <span fxFlex>{{ component.componentName ?? component.componentId }}</span>

          </li>

          <li *ngIf="!(dialogList | customSearchFilter: availableOptions : ['componentId', 'label'])?.length"
            fxLayout="row" fxLayoutAlign="center center">

            <!-- label -->
            <span fxFlex>
              {{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}
            </span>

          </li>
        </ul>

      </div>

    </ng-container>

  </ng-container>

  <!-- SINGLE SELECTION MODE -->
  <ng-container *ngIf="singleSelectionMode">

    <!-- MULTIPLE MACHINES -->
    <ng-container *ngIf="dialog.lineMode">
      <div *ngFor="let group of dialogList" fxLayout="column" fxLayoutAlign="center start" style="padding: 4px 0;">
        <div fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 0;" class="w100">

          <span style="font-weight: 800; margin-right: 8px;">{{ group.label | translate }}</span>

          <mat-icon [svgIcon]="group.expanded ? 'curtain_up' : 'curtain_down'" class="icon left clickable"
            (click)="toggleGroupExpansion(group)">
          </mat-icon>

        </div>

        <ng-container *ngIf="group.expanded">
          <div *ngFor="let component of group.components" fxLayout="row" fxLayoutAlign="start center"
            style="padding: 4px 8px;" (click)="selectComponent(component, group)" class="clickable">

            <mat-icon *ngIf="component.selected" svgIcon="radio_button" class="icon small left clickable radio_button">
            </mat-icon>

            <mat-icon *ngIf="!component.selected" svgIcon="radio_button_disabled"
              class="icon small left clickable radio_button_disabled">
            </mat-icon>

            <span style="margin-left: 12px;">{{ component.label | translate }}</span>
          </div>
        </ng-container>

      </div>
    </ng-container>

    <!-- SINGLE MACHINE -->
    <ng-container *ngIf="!dialog.lineMode">

      <div>

        <div class="search-input" fxLayout="row" fxLayoutAlign="start center"
          style="border: 1px solid #eaecee; margin-top: 12px">
          <mat-icon class="icon">search</mat-icon>
          <input type="text" class="w100" (keyup)="searchFromList($event)" placeholder="{{'GLOBAL.SEARCH'|translate}}">
        </div>

        <ul>
          <li *ngFor="let component of dialogList | customSearchFilter: availableOptions : ['componentId', 'label']"
            fxLayout="row" fxLayoutAlign="start center" class="clickable" (click)="selectComponent(component)"
            [ngClass]="{'clickable': !component.disabled, 'disabled': component.disabled }">

            <!-- show icon -->
            <mat-icon *ngIf="component.selected" svgIcon="radio_button" class="icon left radio_button"
              [ngClass]="component.class">
            </mat-icon>
            <mat-icon *ngIf="!component.selected" svgIcon="radio_button_disabled"
              class="icon left radio_button_disabled">
            </mat-icon>

            <!-- label -->
            <span fxFlex>{{ component.componentName ?? component.componentId }}</span>

          </li>

          <li *ngIf="!(dialogList | customSearchFilter: availableOptions : ['componentId', 'label'])?.length"
            fxLayout="row" fxLayoutAlign="center center">

            <!-- label -->
            <span fxFlex>
              {{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}
            </span>

          </li>
        </ul>

      </div>

    </ng-container>
  </ng-container>

</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button mat-button [mat-dialog-close]="onDialogConfirm()" [disabled]="checkDisability()" class="filter-button">
    {{ 'GLOBAL.SELECT' | translate }}
  </button>
</mat-dialog-actions>