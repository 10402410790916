import { Component, Input, Output, OnInit, EventEmitter, SimpleChange } from '@angular/core';
import * as moment from 'moment';
import { ClonerService } from 'src/app/services/clone.service';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'ff-duration-input',
  templateUrl: './duration-input.component.html',
  styleUrls: ['./duration-input.component.scss']
})
export class DurationInputComponent implements OnInit {
  @Input() value: number;
  @Input() format: string;
  @Input() unit: any;
  @Output() valueChange = new EventEmitter<number>();

  public units: any;
  public chunks: any;

  constructor(
    private filterService: FiltersService,
    private clonerService: ClonerService
  ) { 
    if (this.value == null) this.value = 0
  }

  ngOnInit(): void {
    try {
      let units: any = this.format.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
      let unitsArray = units != null ? units.toLowerCase().split('').filter(this.filterService.onlyUnique) : []
      this.units = unitsArray

      let durObj = this.filterService.parseDuration(this.value, this.unit, 'durobj')

      if(durObj != null) {
        this.chunks = []
        this.units.forEach((unit: any) => {
          let max = unit != 'h' ? 60 : null
          let x = {
            value: durObj[unit],
            unit: unit,
            max: max
          }
          this.chunks.push(x)
        });
      }
    } catch(error) {
      // console.log('init', error)
    }
  }

  ngOnChanges(): void {
    try {
      let units: any = this.format.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, "")
      let unitsArray = units != null ? units.toLowerCase().split('').filter(this.filterService.onlyUnique) : []
      this.units = unitsArray

      let durObj = this.filterService.parseDuration(this.value, this.unit, 'durobj')

      if(durObj != null) {
        this.chunks = []
        this.units.forEach((unit: any) => {
          let max = unit != 'h' ? 60 : null
          let x = {
            value: durObj[unit],
            unit: unit,
            max: max
          }
          this.chunks.push(x)
        });
      }
    } catch(error) {
      // console.log('changes', error)
    }
  }

  onValueChange(chunk) {
    if (chunk.value == null) chunk.value = 0
    if (chunk.max != null && chunk.value > chunk.max) chunk.value = chunk.max
    if (typeof chunk.value == 'number') chunk.value = parseInt(chunk.value)
    if (typeof chunk.value == 'string') this.filterService.parseNumber(chunk.value)

    let values = []

    this.chunks.forEach((chunk: any) => {
      let newVal = 0;
      if (chunk.unit == 'h') newVal = chunk.value * 3600
      if (chunk.unit == 'm') newVal = chunk.value * 60
      if (chunk.unit == 's') newVal = chunk.value * 1
      if (chunk.unit == 'ms') newVal = chunk.value / 1000

      if (this.unit == 'ms') newVal = newVal * 1000
      values.push(newVal)
    });
    
    let value = values.reduce((a, b) => a + b, 0)
    this.valueChange.emit(value)
  }


}
