import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { AggregationsDialogComponent } from '../aggregations-dialog/aggregations-dialog.component';

@Component({
  selector: 'ff-aggregations-buttons',
  templateUrl: './aggregations-buttons.component.html',
  styleUrls: ['./aggregations-buttons.component.scss']
})
export class AggregationsButtonsComponent implements OnInit {

  @Input() aggregationButtons: any;
  @Input() referenceComponent: any;
  @Output() selected = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    public translate: FfTranslateService,
    public clonerService: ClonerService,
  ) { }

  checkButtons(item: any) {
    return item?.list?.length > item?.selected?.length && item?.selected?.length > 0;
  }

  filteredItems(item: any) {
    return item?.selected?.length;
  }

  openAggrDialog(
    aggr: any,
    pageStatePolling: any = 5,
  ) {

    const aggrDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label ?? '-'),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.referenceComponent?.machine),
        machineId: this.referenceComponent?.machineId,
        machineReference: this.referenceComponent?.machine?.machineReference,
        machineSelected: this.referenceComponent?.availableMachines?.list?.join(';'),
        aggregations: this.aggregationButtons,
        interval: this.clonerService.deepClone(this.referenceComponent?.interval)
      },
    });

    aggrDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {

        result = this.clonerService.deepClone(result);
        aggr.selected = this.clonerService.deepClone(result.selected);

        try { this.referenceComponent?.pollingSubscription.unsubscribe() } catch (error) { }

        this.referenceComponent?.pageState.next(pageStatePolling);

        if (!this.referenceComponent?.interval?.enabledPolling) this.referenceComponent?.mainRequest(this.referenceComponent, 0);
        else this.referenceComponent?.mainPollingFunction(this.referenceComponent);
      }

    });
  };

  ngOnInit(): void { }

}
