import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-cycle-comparison-dialog',
  templateUrl: './cycle-comparison-dialog.component.html',
  styleUrls: ['./cycle-comparison-dialog.component.scss']
})
export class CycleComparisonDialogComponent implements OnInit, OnDestroy {

  public plotState: number = 0;

  public cycles: any;
  public cyclesData: any;
  public cyclesInfo: any;
  public cyclesColumns: any = [];
  public cycleStart: any;
  public searchCycles: any;

  public availableDays: any = [];
  public selectedDay: any = moment().format("YYYY-MM-DD");

  public isCycleSelected: boolean = false;
  public selectedCycle: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  // @ViewChild(MatSort) sort!: MatSort;

  private sort!: MatSort;
  @ViewChild(MatSort) set MatSort(ms: MatSort) {
    this.sort = ms;
    this.setSort();
  }

  setSort() {
    this.cyclesData.sort = this.sort;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<CycleComparisonDialogComponent>,
    public apiService: ApiService,
    public intervalService: IntervalService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
    public clonerService: ClonerService,
  ) {

    this.cycles = {
      list: [],
      filtered: [],
      selected: null,
      pageOptions: [25, 50, 100],
      pageSize: 25,
      search: ""
    };

    this.cyclesData = new MatTableDataSource<any[]>([]);

    this.cyclesInfo = this.dialog.appConfig.cycleTraceability.cycles.cycleInfo;
    // this.cyclesColumns.push('icon');
    this.cyclesInfo.forEach((element: any) => {
      if (!element.hideDefault) this.cyclesColumns.push(element.variable);
    });

    var a = moment();
    var b = moment().subtract(360, 'days');
    let days = [];
    for (var m = moment(a); b.diff(m, 'days') < 0; m.subtract(1, 'days')) {
      days.push(moment(m).format("YYYY-MM-DD"));
    }

    this.availableDays = this.clonerService.deepClone(days);

    this.cycleStart = {
      variable: this.dialog.appConfig.cycleTraceability.cycles.sortDefaultCycles.variable,
      direction: this.dialog.appConfig.cycleTraceability.cycles.sortDefaultCycles.direction
    };

    this.selectedDay = this.dialog.comparedCycleInfos != null ? this.dialog.comparedCycleInfos.cycleDay : this.dialog.actualCycleInfos.cycleDay;
  }

  getCycleList() {

    this.plotState = 0;
    try {

      let interval: any = null;
      // interval: any = this.selectedDay != null ? this.selectedDay : this.intervalService.getIntervalById('last12Hours');

      if (this.selectedDay != null) {
        interval = {
          start: JSON.parse(JSON.stringify(moment(this.selectedDay).startOf("day"))),
          end: JSON.parse(JSON.stringify(moment(this.selectedDay).endOf("day"))),
        };
      }

      // let query = {
      //   from: interval.start
      // };

      // let payload = this.dialog.datapointList.map((x: any) => typeof x == 'string' ? x : (Array.isArray(x) ? x : x.dp));

      // this.apiService.sendPostRequest('/apif/machine-monitoring/datapoints/' + this.dialog.machineId, payload, query).pipe(

      let query: any = {
        from: interval.start,
        to: interval.end,
        tz: this.dialog.machine.timezone,
      };

      // if (this.interval != null && this.interval.id == 'custom') {
      //   query.to = this.interval.end;
      // }

      this.apiService.sendGetRequest('/apif/traceability/cycles/' + this.dialog.machine.machineId, query)
        .pipe(
          retryWhen(this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {

            this.plotState = 2;

            if (error.error instanceof ErrorEvent) {
              console.log(`Error: ${error.error.message}`);
            } else {
              console.log(`Error: ${error.message}`);
            }

            return throwError(
              'Something bad happened; please try again later.');
          }))
        .subscribe(
          (data: any) => {

            // console.log(data.body);
            this.plotState = 1;

            this.cycles.list = this.parseCyclesTable(this, data.body);
            this.filterCycles();
            // let plotData: any = this.parsePlotData(data.body);

            // let plotConfig: any = {
            //   responsive: true,
            //   displaylogo: false
            // };

            // try {
            //   if (plotData.data.every((x: any) => x.x.length == 0)) {
            //     this.plotState = 2;
            //   } else {
            //     setTimeout(() => {
            //       try {
            //         Plotly.react("prova", plotData.data, plotData.layout, plotConfig);
            //       } catch (error) {
            //         console.log(error);
            //       }
            //     }, 50);
            //   }
            // } catch (error) {
            //   console.log(error);
            // }
          },
        );

    } catch (error) {
      console.log(error);

    }
  }

  parseCyclesTable(_this: any, data: any) {
    if (data != null && data.cycles != null && data.cycles.length > 0) {
      data.cycles.forEach((element: any) => {
        // cycle attributes
        element.selected = _this.dialog.comparedCycleInfos != null ? _this.dialog.comparedCycleInfos.cycleId == element.id : false;
        element.actual = _this.dialog.actualCycleInfos.cycleId == element.id;
        element.urlArray = ["/" + _this.dialog.machine.machineId, 'cycle-traceability', element.id + '$$' + element.timeStart + '$$' + element.timeEnd, 'cycle-timeline'];
        element.timeStartP = _this.filterService.parseMoment(element.timeStart, 'default');
        element.timeEndP = _this.filterService.parseMoment(element.timeEnd, 'default');
        element.intervalP = element.timeStartP + "\n" + element.timeEndP;
        element.durationP = (element.duration != null) ?
          _this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss') :
          // _this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss', element.timeStart, element.timeEnd);
          '-';
        element.alarmTimeP = _this.filterService.parseDuration(element.alarmTime, 's', 'hh:mm:ss');
        element.signalationTimeP = _this.filterService.parseDuration(element.signalationTime, 's', 'hh:mm:ss');
        element.waitingTimeP = _this.filterService.parseDuration(element.waitingTime, 's', 'hh:mm:ss');
        element.techTimeP = _this.filterService.parseDuration(element.techTime, 's', 'hh:mm:ss');
        element.processTimeP = _this.filterService.parseDuration(element.processTime, 's', 'hh:mm:ss');
        element.maintTimeP = _this.filterService.parseDuration(element.maintTime, 's', 'hh:mm:ss');

        _this.parseCycleType(_this, element);

        // _this.parseParams(_this, element);

      });

      data.cycles.sort(_this.filterService.sortByProperty(_this.dialog.appConfig.cycleTraceability.cycles.sortDefaultCycles.variable, _this.dialog.appConfig.cycleTraceability.cycles.sortDefaultCycles.direction));

      console.log(data.cycles);

      return data.cycles;
    }
    return []
  }

  parseCycleType(_this: any, element: any) {
    try {
      if (_this.dialog.machine.profile.cycles != null &&
        _this.dialog.machine.profile.cycles.length > 0) {
        let cycleType = {};
        if (element.phases == null || element.phases.length == 0) console.log('no phases', element);
        for (let phase of element.phases) {
          for (let cycle of _this.dialog.machine.profile.cycles) {
            if (cycle.havePhases.length == 0) {
              cycleType = cycle;
            }
            let ix = cycle.havePhases.findIndex((x: any) => { return x == parseInt(phase.phaseId); })
            if (ix != -1) {
              cycleType = cycle;
              break;
            }
          }
          // if (cycleType != null) break;
        }
        element.typeP = cycleType;
      }
    } catch (error) {
      console.log('Failed to parse params')
    }
  }

  parseParams(_this: any, element: any) {
    let paramsP_all = [];
    // let paramsP = [];
    try {
      if (element.params != null) {
        for (let param in element.params) {
          let ix = _this.dialog.machine.profile.paramsR.findIndex((x: any) => { return x.variable.toLowerCase() == param.toLocaleLowerCase(); })
          if (ix != -1) {
            let p = _this.dialog.machine.profile.paramsR[ix];
            p.label = _this.translate.instant('PARAMS_R.' + _this.dialog.machine.assetId + '.' + param);
            p.value = element.params[param];
            paramsP_all.push(p)
            // paramsP.push(p)
          }
        }
      }
    } catch (error) {
      console.log('Failed to parse params')
    }
    element.paramsP_all = paramsP_all;
    element.paramsP = [];

    try {
      element.paramsP_max = _this.dialog.machine.profile.paramsRMax;
    } catch (error) { }
  }

  filterCycles() {

    let filterVariables = this.dialog.appConfig.cycleTraceability.cycles.filterVariables != null ? this.dialog.appConfig.cycleTraceability.cycles.filterVariables : ["id"];

    let filtered: any = [];

    filtered = this.cycles.list;

    // filter on searchEvent
    try {
      filtered = filtered.filter((data: any) => filterVariables.some((x: any) => this.searchCycles == null || this.searchCycles == '' ? true : String(data[x]).toLowerCase().includes(this.searchCycles.toLowerCase())));
    } catch (error) {
      console.log(error);
    }

    // filter on types (typeP)
    try {
      filtered = filtered.filter((data: any) => data.typeP.id == this.dialog.actualCycleInfos.type);
    } catch (error) {
      console.log(error);
    }

    // init default params
    // if (count == 0) {
    //   try {
    //     for (let param of this.cyclesFilters.params) {
    //       if (this.machine.profile.paramsRDefault &&
    //         this.machine.profile.paramsRDefault.includes(param.id)) {
    //         param.selected = true;
    //         for (let item of filtered) {
    //           item.paramsP.push(item.paramsP_all.find((x: any) => { return x.variable == param.id; }));
    //         }
    //       }
    //     }
    //   } catch (error) { }
    // }
    // // set params
    // else {
    //   for (let flt of filtered) {
    //     flt.paramsP = flt.paramsP_all.filter((x: any) => this.cyclesFilters.params.some((y: any) => y.selected && y.id == x.variable));
    //   }
    // }

    // sort table
    filtered.sort(
      this.filterService.sortByProperty(
        // (this.sort != null && this.sort.active != null) ? this.sort.active : 'timeStartP',
        (this.sort != null && this.sort.active != null) ? this.sort.active : this.dialog.appConfig.cycleTraceability.cycles.sortDefaultCycles.variable,
        this.dialog.appConfig.cycleTraceability.cycles.sortDefaultCycles.direction,
        true
      )
    );

    if (this.sort != null) {
      this.sort.sort(({
        id: this.dialog.appConfig.cycleTraceability.cycles.sortDefaultCycles.variable,
        start: this.dialog.appConfig.cycleTraceability.cycles.sortDefaultCycles.direction
      }) as MatSortable);
    }
    // }

    this.cycles.filtered = filtered;

    let cd = new MatTableDataSource<any>();

    if (cd.paginator) cd.paginator.firstPage();

    cd.paginator = this.paginator;
    cd.sort = this.sort;
    cd.data = filtered;

    // console.log(filtered);

    this.cyclesData = cd;
  }

  selectCycle(cycle: any) {
    this.cycles.filtered.forEach((c: any) => c.selected = false);
    cycle.selected = true;
    this.isCycleSelected = true;
    this.selectedCycle = this.clonerService.deepClone(cycle);
  }

  onDaySelection(day: any) {
    console.log(day);
    this.getCycleList();
  }


  closeDialog() {
    this.dialogRef.close({ cycle: this.selectedCycle });
  }



  ngOnInit(): void {
    this.getCycleList();
  }

  ngOnDestroy() {
  }

}
