<ff-tabs *ngIf="!isMobile" [tabs]="tabs"></ff-tabs>

<div *ngIf="!isMobile" fxFlex fxLayout="row" fxLayoutAlign="start start" class="w100 h100 tab-content-animated">
  <div *ngFor="let tab of tabs" fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content transition-01"
    [ngClass]="{'z-index-m1': tab.left || tab.right, 'translate-l100': tab.left, 'translate-r100': tab.right }">

    <!-- tab chart -->
    <div *ngIf="tab.id == 'chart'" class="w100 h100">
      <!-- Loading -->
      <div *ngIf="plotState == 0" class="data-not-found w100" fxLayout="row" fxLayoutAlign="center center"
        [ngStyle]="{'height': widget.title != null ? 'calc(50vh - 150px)':'100%'}">
        <ff-loading-card></ff-loading-card>
      </div>

      <!-- Success -->
      <div *ngIf="plotState == 1" id="{{randomId}}" class="w100" [ngStyle]="{'height': widget.title != null && !widget.excludeTitleHeight ? 'calc(50vh - 150px)':'100%', 
      'min-height': widget.title != null ? 'calc(50vh - 150px)':'100%'}"></div>

      <!-- Fail -->
      <div *ngIf="plotState == 2" class="data-not-found w100" fxLayout="row" fxLayoutAlign="center center"
        [ngStyle]="{'height': widget.title != null ? 'calc(50vh - 150px)':'100%'}">
        <span>
          {{ (widget.config?.noDataLabelPlot != null ? widget.config?.noDataLabelPlot : 'GLOBAL.NO_DATA_AVAILABLE') |
          translate }}
        </span>
      </div>

      <!-- Fail DASH -->
      <div *ngIf="plotState == 3" class="data-not-found dash w100" fxLayoutAlign="center center">
        <span>---</span>
      </div>

    </div>

    <!-- tab table -->
    <div *ngIf="tab.id == 'table'" class="w100 h100">

      <!-- Fail -->
      <div *ngIf="table?.rowsFiltered?.length == 0" class="data-not-found w100" fxLayout="row"
        fxLayoutAlign="center center">
        <span>{{ (widget.config?.noDataLabelTable != null ? widget.config?.noDataLabelTable :
          'GLOBAL.NO_DATA_AVAILABLE') | translate }}</span>
      </div>

      <!-- Success -->
      <ff-table-sortable [tableConfig]="table" (sort)="table.sort = $event"></ff-table-sortable>
    </div>

  </div>
</div>

<div *ngIf="isMobile" class="w100 h100">
  <ff-table-sortable [tableConfig]="table" (sort)="table.sort = $event" fxLayout="column" fxLayoutAlign="center center">
  </ff-table-sortable>
</div>