<div class="chat-button chatbot" cdkDrag cdkDragBoundary=".main-content" (cdkDragStarted)="dragStart($event)"
  (cdkDragEnded)="dragEnd($event)" #dragRef>

  <!-- <span *ngIf="botNotifications > 0 && !chatExpanded" class="bellnumbers">{{botNotifications}}</span>
  <md-button id="chat-button-header" *ngIf="!chatExpanded" class="md-fab md-raised md-ink-ripple" aria-label="Chat"
    ng-mousedown="startCalculatingPosition()" ng-mouseup="stopCalculatingPosition()">
    <md-tooltip md-direction="left">
      <span class="break-word">{{'GLOBAL.CHAT_BOT' | translate}}</span>
    </md-tooltip> -->
  <!-- </md-button> -->

  <button *ngIf="!isOpenedChat" id="rounded-chat-button" class="rounded-button md-primary"
    matTooltip="{{ 'GLOBAL.CHAT_BOT' | translate }}" (click)="toggleExpandChat()">
    <div fxLayout="row" fxLayoutAlign="center center">
      <img class="md-avatar" src="statics/icons/chat_bot.png" style="height: 80px; min-width: 80px;">
    </div>
  </button>


  <div *ngIf="isOpenedChat" class="chat-container" [ngClass]="{'fullScreen': fullscreen}">


    <div class="chat-bar">
      <div fxLayout="row" fxLayoutAlign="start center">

        <img src="statics/icons/chat_bot.png" style="height: 56px; padding-top: 4px;" />

        <div class="chat-with">{{ 'BOT.NAME' | translate }}</div>

        <span fxFlex></span>

        <mat-icon (click)="toggleFullscreen()" class="icon clickable">
          {{ !fullscreen ? 'fullscreen' : 'fullscreen_exit' }}</mat-icon>
        <mat-icon (click)="toggleExpandChat()" class="icon clickable">clear</mat-icon>
      </div>
    </div>
    <div class="clearfix">

      <div class="chat">

        <div class="chat-history" id="chat_chat_chat" [ngClass]="{'fullScreen': fullscreen}">
          <ul class="ff-ul">
            <li class="clearfix" *ngFor="let message of messages; let index = index">

              <div class="message-data {{message.senderClasses}}">
                <span class="message-data-time">{{message.timestamp}}</span>
              </div>

              <div fxLayout="row" fxLayoutAlign="start center" class="message {{message.messageClasses}}"
                id="message-{{index}}">

                <div *ngIf="message.content != null" [innerHTML]="message.content" style="word-break: break-word;">
                </div>

                <ff-loading-card *ngIf="message.content == null"></ff-loading-card>

              </div>
              <div class="width100" style="margin-bottom: 18px;">

                <button class="filter-button" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px"
                  *ngIf="message.type == 'kpiTrend' || message.type == 'actStatus' || message.type == 'kpiComparison'"
                  (click)="openBotDialog(index, message?.type)" style="margin-bottom: 8px;">
                  <mat-icon class="icon left">open_in_new</mat-icon>
                  <span>{{ 'BOT.OPEN_PLOT_REPORT' | translate}}</span>
                </button>

                <button class="filter-button" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px"
                  *ngIf="message.type == 'requestKpi'" (click)="openBotDialog(index, message?.type)"
                  style="margin-bottom: 8px;">
                  <mat-icon class="icon left">open_in_new</mat-icon>
                  <span>{{ 'BOT.OPEN_TABLE_REPORT' | translate}}</span>
                </button>

                <div *ngFor="let button of message.buttons" style="margin-bottom: 8px;">
                  <button class="{{message.messageClasses}} filter-button" fxLayout="row" fxLayoutAlign="center center"
                    (click)="sendMessage(button)">

                    <ff-icon [icon]="{'type':'svg', 'icon': 'touch'}"></ff-icon>
                    <span>{{ button?.text }}</span>

                  </button>
                </div>

              </div>
            </li>

          </ul>

        </div>

        <div class="chat-message clearfix" fxLayout="row" fxLayoutAlign="space-between center">

          <textarea rows="1" style="max-height: 160px" class="ff-textarea" (keydown)="triggerFunction($event)"
            oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' [readonly]="disableTextarea"
            name="message-to-send" id="message-to-send"
            placeholder="{{ (disableTextarea ? (isButtonPresent ? 'BOT.PRESS_BUTTON_TO_KEEP_CHATTING' : 'GLOBAL.LOADING') : 'BOT.PLACEHOLDER') | translate }}"></textarea>

          <div (click)="sendMessage()" style="margin-left: 8px;">
            <i class="fab fa-telegram-plane clickable" style="width: 1.5rem !important; height: 1.5rem !important;"></i>
          </div>

        </div>

      </div>

    </div>
  </div>

</div>