import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'app-aggregations-dialog',
  templateUrl: './aggregations-dialog.component.html',
  styleUrls: ['./aggregations-dialog.component.scss']
})
export class AggregationsDialogComponent implements OnInit {

  aggrListState: any = 0;
  paramBox: any = {};
  aggregations: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public apiService: ApiService,
    public translate: FfTranslateService,
    public internalDataService: InternalDataService,
  ) {
    // this.updateAllSelected();
    this.aggregations = this.dialog.aggregations;
    this.internalDataService.createAggrList(this, [], this.dialog.aggrId);
  }

  allSelected: boolean = true;

  toggleAll() {
    if (this.paramBox.selected.length === this.paramBox.list.length) {
      this.paramBox.selected = [];
    } else if (this.paramBox.selected.length >= 0) {
      this.paramBox.selected = [];
      this.paramBox.list.forEach((element: any) => this.paramBox.selected.push(element));;
    }
    this.checkAllSelected();
  };

  checkAllSelected() {
    this.allSelected = this.paramBox.selected.length === this.paramBox.list.length;
  }

  exists(item: any, list: any) {
    return list.filter((x: any) => x == item).length > 0;
  };

  toggle(item: any, list: any) {

    var idx = list.findIndex((x: any) => x == item);
    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }
    this.checkAllSelected();
  };

  getAggrList() {
    try {

      let query: any = {
        from: this.dialog.interval.start,
        to: this.dialog.interval.end,
        tz: this.dialog.machine?.timezone,
      };

      let payload: any = {}

      let machineId = null;

      if (this.dialog.machineSelected) {
        if (this.dialog.machineSelected === 'Line') {
          machineId = this.dialog.machineReference;
          if (machineId != null) query.machineId = machineId;
        } else {
          payload.machineList = this.dialog.machineSelected.split(';');
        }
          machineId = this.dialog.machineSelected === 'Line' ? this.dialog.machineReference : this.dialog.machineSelected;
      }
      else if (this.dialog.machineReference) {
        machineId = this.dialog.machineReference;
        if (machineId != null) query.machineId = machineId;
      }

      if (this.dialog.measurement) query.measurement = this.dialog.measurement;
      if (this.dialog.componentId) query.componentId = this.dialog.componentId;

      let url = '/apif/list-aggr/' + this.dialog.machineId + '/' + this.dialog.aggrId;

      this.apiService.sendPostRequest(url, payload, query).pipe(
        retryWhen(this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          this.aggrListState = 2;

          let strErr = `Error: ${error.error instanceof ErrorEvent ? error.error.message : error.message}`;

          console.log(strErr);
          return throwError(strErr);
        }))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            if (data.body != null && data.body.length > 0) {
              this.aggrListState = 1;
              this.internalDataService.createAggrList(this, data.body, this.dialog.aggrId);
              this.checkAllSelected();
            } else {
              this.aggrListState = 2;
            }

          },
        );

    } catch (error) {
      this.aggrListState = 2;
      console.log(error);
    }
  }

  ngOnInit(): void {
    this.getAggrList();
  }

}
