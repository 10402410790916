<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
  <!-- utils -->
  <!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->
</div>

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar -->
  <div fxLayout="column" class="w100" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">
    <!-- tabs -->
    <ff-tabs [tabs]="tabs"></ff-tabs>

    <span fxFlex.gt-sm></span>

    <button class="filter-button md-primary rounded-button" (click)="updateGCASettings()" fxLayout="row"
      fxLayoutAlign="start center" [disabled]="!pageStateReady || checkUpdates() || !isAllowedUserWrite">
      <mat-icon class="icon" aria-label="save">save</mat-icon>
      <span>{{'GLOBAL.SAVE'|translate}}</span>
    </button>

  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start center" class="tab-content">

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < pageStateReady" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <!-- chart -->
    <div fxLayout="row wrap" class="w100" fxLayoutGap="8px grid">
      <div *ngFor="let phases of allPhases" fxFlex="33.3" fxFlex.gt-lg="25">
        <!-- <pre>{{ phases.phaseInfos | json }}</pre> -->
        <div fxLayout="column" *ngIf="pageState.value >= 5 && phases.phases.list.length > 0"
          style="border-radius: 25px; background-color: #fff; margin-top: 12px; padding-bottom: 16px;">

          <div class="phase-data-title" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon *ngIf="!phases.phases.expanded" svgIcon="curtain_down" class="icon left clickable"
              (click)="phases.phases.expanded = !phases.phases.expanded">
            </mat-icon>
            <mat-icon *ngIf="phases.phases.expanded" svgIcon="curtain_up" class="icon left clickable"
              (click)="phases.phases.expanded = !phases.phases.expanded"></mat-icon>
            <span>{{ phases.phaseName }}</span>
            <div
              style="margin-left: 12px; border-radius: 50px; height: 20px; width: 20px; border: 1px solid rgba(0, 0, 0, 0.2);"
              [ngStyle]="{'background-color': phases.phaseColor}">
            </div>
          </div>

          <table mat-table *ngIf="phases.phases.expanded" [dataSource]="phases.phaseData" matSort>

            <ng-container matColumnDef="icon" *ngIf="isAllowedUserWrite">
              <th mat-header-cell *matHeaderCellDef class="td-icon clickable" (click)="toggleAll(phases)"
                matTooltip="{{ 'GLOBAL.SELECT_UNSELECT_ALL' | translate }}" matTooltipPosition="above">
                <div class="box-icon md-green-i" style="width: 16px;">
                  <mat-icon class="icon" svgIcon="radio_button"></mat-icon>
                </div>
              </th>
              <td mat-cell *matCellDef="let phase" class="td-icon clickable" (click)="phase.enabled = !phase.enabled">
                <div class="box-icon md-green-i" style="width: 16px;">
                  <mat-icon class="icon" svgIcon="radio_button"></mat-icon>
                </div>
              </td>
            </ng-container>

            <ng-container *ngFor="let info of phases.phaseInfos" matColumnDef="{{info.variable}}">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>
                {{info.label|translate}}
              </th>
              <td mat-cell *matCellDef="let phase">

                <div *ngIf="info.type == null">
                  <span *ngIf="info.prefix != null">{{info.prefix}}&nbsp;</span>
                  <span>{{(phase[info.variable] != null ? phase[info.variable] : '-')}}</span>
                  <span *ngIf="info.suffix != null">&nbsp;{{info.suffix}}</span>
                </div>

                <div *ngIf="info.type == 'input'">
                  <input class="ff-input minWidthSmall minHeightSmall" [(ngModel)]="phase[info.variable]" type="number"
                    min="1" max="100" required name="{{info.variable}}" [disabled]="!isAllowedUser || !phase.enabled">

                  <div *ngIf="phase.enabled && phase[info.variable] == null" class="error-input">
                    {{ 'GLOBAL.REQUIRED' | translate }}</div>
                  <div *ngIf="phase.enabled && phase[info.variable] != null && phase[info.variable] < 1"
                    class="error-input" translate="GLOBAL.MINIMUM_NUMBER" [translateParams]="{ x: 1 }"></div>
                  <div *ngIf="phase.enabled && phase[info.variable] != null && phase[info.variable] > 100"
                    class="error-input" translate="GLOBAL.MAXIMUM_NUMBER" [translateParams]="{ x: 100 }"></div>

                </div>

              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="phases.phaseColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: phases.phaseColumns;"
              [ngClass]="{ 'ff-disabled': !row.enabled || !isAllowedUserWrite }">
            </tr>
          </table>
          <!-- <mat-paginator [length]="phases.list.length" [pageSizeOptions]="phases.pageOptions" [pageSize]="phases.pageSize"
            showFirstLastButtons></mat-paginator> -->

        </div>
      </div>
    </div>

  </div>
</div>