<div id="body-container" [ngClass]="{'mindsphere': appInfo.mindsphere}" fxLayout="row"
  [class.remove-all-padding]="hideToolbar">

  <!-- sidenav -->
  <ff-sidenav [ngClass]="{ 'mobile closed' : isSmThanTablet }" class="transition-01"></ff-sidenav>

  <!-- main-container -->
  <div id="main-container" fxLayout="column" class="main-content" [class.remove-all-padding]="hideToolbar">

    <!-- main-toolbar -->
    <ff-main-toolbar></ff-main-toolbar>

    <!-- main-content -->
    <div id="main-content" fxFlex [class.remove-all-padding]="hideToolbar">
      <router-outlet></router-outlet>
    </div>

    <!-- chat-bot -->
    <ff-bot *ngIf="appInfo.bot && !isMobile"></ff-bot>
    <ff-wilson *ngIf="appInfo.wilson && !isMobile"></ff-wilson>

  </div>

</div>