<div fxLayout="row" fxLayoutAlign="center center" style="padding: 1rem;">
  <span class="title">{{dialog.title | translate }}</span>
</div>
<mat-dialog-content *ngIf="dialog.description" class="mat-typography">
  <div fxLayout="row" fxLayoutAlign="center center">
    <span *ngIf="!dialog?.bindHTML">{{ dialog.description | translate }}</span>
    <span *ngIf="dialog?.bindHTML" innerHTML="{{ dialog.description | translate }}"></span>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="!dialog.hideButtons" fxLayout="row" fxLayoutAlign="center center" style="margin-left: 8px;">
  <button class="filter-button" [ngClass]="cancelClass" mat-dialog-close>
    {{ (dialog.cancelLabel ?? 'GLOBAL.CANCEL') | translate }}
  </button>
  <button class="filter-button" [ngClass]="confirmClass" (click)="confirm()">
    {{ (dialog.confirmLabel ?? 'GLOBAL.CONFIRM') | translate }}
  </button>
  <button *ngIf="dialog.additionalButton" mat-button class="filter-button" [ngClass]="confirmAdditionalActionClass"
    (click)="confirmAdditionalAction()">
    {{ (dialog.confirmAdditionalActionLabel ?? 'GLOBAL.CONFIRM_ADDITIONAL_ACTION') | translate }}
  </button>
</mat-dialog-actions>