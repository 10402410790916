import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { CacheService } from 'src/app/services/cache.service';
import { MobileService } from 'src/app/services/mobile.service';
import { EditShiftTemplateComponent } from './edit-shift-template/edit-shift-template.component';

@Component({
  selector: 'app-shift-templates',
  templateUrl: './shift-templates.component.html',
  styleUrls: ['./shift-templates.component.scss']
})
export class ShiftTemplatesComponent implements OnInit, OnDestroy {

  public isAllowedUser: any = true;
  public isAllowedUserWrite: any = true;

  public loadingData: any;
  public errorData: any;
  public errorDataMobile: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public productionColumns: string[] = [];

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public pollingTime: any;
  public pollingEvents: any;

  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;

  public interval: any;
  public intervalConfig: any;

  public intervalAggregations: any;
  public aggregationsTime: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public dashboardConfig: any;

  public calendarData: any;
  public calendarStates: any;
  public calendarShifts: any;
  public calendarShiftsUnparsed: any;

  public calendarOptions: any = {};
  public selectedDays: any = [];
  public selectedDaysCopy: any = [];

  public shiftTemplates: any = [];
  public operatorsList: any = [];

  public mobileListener: Subscription;
  public isMobile: any;

  public shiftTemplateInfos: any = [
    {
      variable: "shiftId",
      label: "CALENDAR.SHIFT"
    },
    {
      variable: "from",
      label: "CALENDAR.START"
    },
    {
      variable: "to",
      label: "CALENDAR.END"
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 8;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getCalendarStates, nextState: 4, loadingMsg: 'LOADING.CALENDAR_STATES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getOperatorsList, nextState: 5, loadingMsg: 'LOADING.OPERATORS_LIST' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getDataPolling, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.getShiftTemplates, nextState: 7, loadingMsg: 'LOADING.SHIFT_TEMPLATES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    public cacheService: CacheService,
    public mobile: MobileService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['CALENDAR.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })

    this.tabs = this.internalDataService.getPageTabs('calendar');

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig.calendar.pollingCalendar;
    this.pollingEvents = Subscription;

    this.calendarData = null;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUserWrite = _this.internalDataService.getSpecificPermission("mat-pc-shifts-rw");
    } catch (error) { console.log(error) }

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(["mat-pc-shifts-r", "mat-pc-shifts-rw"], 'or');
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs('calendar');
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'calendar');
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET CALENDAR STATES

  public getCalendarStates(_this: any) {

    try {

      const sources40F = _this.appInfo.sources40F != null ? _this.appInfo.sources40F : 'assets/config/';

      _this.apiService.sendGetRequest(sources40F + 'calendarStates.json').pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {
          error = {
            ...error, ...{
              error: {
                "customMessage": "Missing file: calendarStates.json",
                "customCode": "404",
              }
            }
          }
          return _this.internalDataService.parseStandardHTTPError(_this, error);
        }))
        .subscribe(
          (data: any) => {

            _this.calendarStates = data.body;
            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SHIFT TEMPLATES

  public getShiftTemplates(_this: any) {

    try {

      _this.apiService.sendGetRequest('/apif/calendar-shifts/' + _this.machineId).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {

            if (data.body != null && Array.isArray(data.body) && data.body.length > 0) {
              _this.shiftTemplates = _this.clonerService.deepClone(_this.parseShiftTemplates(data.body));
            } else {
              _this.shiftTemplates = [];
            }
            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET OPERATORS LIST

  public getOperatorsList(_this: any) {

    try {

      _this.apiService.sendGetRequest('/apif/calendar-operators/' + _this.machineId).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {

            _this.operatorsList = _this.clonerService.deepClone(data.body);
            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // events

  // polling
  getDataPolling(_this: any) {
    try {

      // try {
      //   _this.intervalAggregations = {
      //     list: _this.aggregationsTime,
      //     selected: _this.aggregationsTime[0]
      //   };
      // } catch (error) {
      //   console.log(error);
      // }

      if (_this.pollingTime > 0) {
        _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getData(_this, count);
        });
      } else {
        _this.getData(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getData(_this: any, count?: any) {
    try {

      let query: any = {
        minimumCalendarUnit: _this.machine.profile?.settings?.minimumCalendarUnit ?? '30m',
      };

      _this.apiService.sendGetRequest('/apif/calendar/' + _this.machineId, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            if (_this.calendarShifts == null) {
              _this.calendarShifts = _this.clonerService.deepClone(data.body);
              _this.calendarShiftsUnparsed = _this.clonerService.deepClone(data.body);
            }
            try {

              // set plot data and configs
              _this.parseCalendarDays(_this);

            } catch (error) {
              console.log(error);
            }

            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseCalendarDays(_this: any) {

    _this.totalDays = _this.parseTotalDays(_this);

    console.log(_this.totalDays);

    _this.calendarOptions = {
      events: _this.totalDays,
      initialView: 'dayGridMonth',
      height: "100%",
      nextDayThreshold: '14:00:00',
      // views: {
      //   dayGrid: {
      //     // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
      //   },
      //   timeGrid: {
      //     // options apply to timeGridWeek and timeGridDay views
      //   },
      //   week: {
      //     titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' }
      //   },
      //   day: {
      //     // options apply to dayGridDay and timeGridDay views
      //   }
      // },
      // dayHeaderFormat: function(date:any) {
      //   console.log(date);
      //   return moment(date).format("dddd");
      // },
      // { weekday: 'short', month: 'numeric', day: 'numeric', omitCommas: true },
      // eventColor: 'red',
      navLinks: true,
      // defaultDate: c_month != null ? c_month : new Date(),
      // defaultView: c_view != null ? c_view : 'month',
      // navLinkDayClick: function (date, jsEvent) {
      //     // console.log('day', date.toISOString());
      // },
      // navLinkWeekClick: function (weekStart, jsEvent) {
      //     // console.log(weekStart);
      //     _this.copyWeek(weekStart);
      // },
      // height: window.innerHeight - 300,
      weekNumbers: true,
      selectOverlap: true,
      // eventLimit: true,
      dayMaxEvents: 3,
      headerToolbar: {
        start: 'title',
        center: '',
        end: 'timeGridWeek dayGridMonth today prev,next',
      },
      validRange: {
        start: moment().subtract(12, 'months').format("YYYY-MM-DD"), //start date here
        end: moment().add(12, 'months').format("YYYY-MM-DD") //end date here
      },
      selectable: true,
      select: function (event: any) {

        let start = event.start;
        let end = event.end;

        _this.selectedDays = [];
        for (var m = moment(start); m.diff(end, 'days') < 0; m.add(1, 'days')) {

          let day = m.format("YYYY-MM-DD");
          _this.selectedDays.push(day);
        }

        let copyCalendarShifts = _this.clonerService.deepClone(_this.calendarShifts);

        let firstTemplate: any = null;

        try {
          firstTemplate = copyCalendarShifts.filter((x: any) => x.validity == 0).find((x: any) => x.workDay == _this.selectedDays[0]).template;
        } catch (error) {
          _this.disableOperatorsList = true;
        }

        if (firstTemplate != null) {
          _this.disableOperatorsList = !_this.selectedDays.every((day: any) => copyCalendarShifts.findIndex((x: any) => x.workDay == day) != -1 && copyCalendarShifts.filter((x: any) => x.validity == 0 && x.workDay == day).every((x: any) => x.template == firstTemplate));
        }

        console.log(_this.disableOperatorsList, _this.selectedDays);

      },
      unselectAuto: false,
      // unselect: function () {
      //   setTimeout(() => {
      //     _this.selectedDays = null;
      //   }, 500);
      // },
      // eventRender: function (timeRange: any, el: any, view: any) {
      //   // console.log(timeRange, view);
      //   if (view != null) {
      //     try {
      //       if (moment(timeRange.day).diff(moment(view.start)) < 0 || moment(timeRange.day).diff(moment(view.end)) > 0) {
      //         el.css("display", 'none');
      //       } else {
      //         el.css("display", 'block');
      //       }
      //     } catch (error) {
      //       console.log(error);
      //     }
      //   }
      // },
      // viewRender: function (view, element) {
      //     try {
      //         cacheService.setItem("currentMonth", new Date(view.start));
      //         cacheService.setItem("currentView", view.name);
      //     } catch (error) {
      //         console.log(error);
      //     }
      //     // console.log('new date range start : ', view.start, 'new date range end : ', view.end);
      // },
      // eventClick: _this.alertOnEventClick,
      // dateClick: function (info) {
      //     alert('Clicked on: ' + info.dateStr);
      //     alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
      //     alert('Current view: ' + info.view.type);
      //     // change the day's background color just for fun
      //     // info.dayEl.style.backgroundColor = 'red';
      // },
      // dayClick: _this.dayClick,
      // showNonCurrentDates: false,
      displayEventEnd: false,
      displayEventTime: false,
      firstDay: 1,
      buttonText: {
        today: this.translate.instant('CALENDAR.TODAY'),
        week: this.translate.instant('CALENDAR.WEEK'),
        month: this.translate.instant('CALENDAR.MONTH'),
      },
    };

  }

  parseTotalDays(_this: any) {

    _this.selectedDays = [];
    let list: any = [];

    let workConfig = _this.calendarStates.find((x: any) => x.id == 0);
    let stateConfig: any = null;

    // console.log(_this.calendarShifts);

    if (workConfig != null && _this.calendarShifts != null && _this.calendarShifts.length > 0) {

      _this.calendarShifts.forEach((timeRange: any) => {

        // State config
        if (timeRange.hasOwnProperty('validity') && timeRange.validity != null) {
          stateConfig = _this.calendarStates.find((x: any) => x.id == timeRange.validity);
        }

        // Add background color assigned to shift template
        if (list.findIndex((x: any) => x.allDay && x.start == timeRange.workDay) == -1) {
          let ix = _this.shiftTemplates.findIndex((st: any) => st.id == timeRange.template);
          if (ix != -1) {
            list.push({
              start: timeRange.workDay,
              allDay: true,
              display: 'background',
              color: _this.shiftTemplates[ix].color
            });
          }
        }

        // Add shift
        list.push({
          // display: 'block',
          className: [stateConfig != null ? stateConfig.class : workConfig.class],
          stick: false,
          editable: false,
          isValid: stateConfig != null ? stateConfig.isValid : workConfig.isValid,
          shiftId: timeRange.shiftId,
          day: timeRange.workDay,
          title: moment(timeRange.from).format("HH:mm") + ' - ' + moment(timeRange.to).format("HH:mm") + ' - '
            + (timeRange.shiftId != null ? _this.translate.instant("CALENDAR.SHIFT") + ' ' + timeRange.shiftId : _this.translate.instant(stateConfig.label)),
          stateId: stateConfig != null ? stateConfig.id : workConfig.id,
          start: timeRange.from,
          end: timeRange.to,
        });

      });
    }

    _this.totalDaysUnfiltered = list;
    // console.log(_this.clonerService.deepClone(list.filter(x => x.allDayFlag)));
    // console.log(_this.clonerService.deepClone(list.filter(x => x.stateId != 0)));
    return _this.clonerService.deepClone(list);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // OPEN SHIFT TEMPLATES

  openShiftTemplate(selectedDays: any) {

    console.log(selectedDays);

    this.selectedDaysCopy = this.clonerService.deepClone(selectedDays);

    // const assignShiftSelectionDialog = this.dialog.open(AssignShiftTemplateDialogComponent,
    //   {
    //     panelClass: 'ff-dialog',
    //     // width: '40%',
    //     height: 'auto',
    //     data: {
    //       title: this.translate.instant("CALENDAR.SHIFT_TEMPLATE_SELECTION"),
    //       machine: this.machine,
    //       appConfig: this.appConfig,
    //       shiftTemplates: this.shiftTemplates,
    //       selectedDaysCopy: this.selectedDaysCopy
    //     },
    //   });

    // assignShiftSelectionDialog.afterClosed().subscribe((result: any) => {

    //   if (result != null && result != '') {
    //     try {
    //       console.log(result);

    //       let copyCalendarShifts: any = this.clonerService.deepClone(this.calendarShifts);
    //       let idxsList: any = [];

    //       this.selectedDaysCopy.forEach((day: any) => {
    //         copyCalendarShifts.filter((cs: any) => cs.workDay == day && cs.validity == 0).forEach((cs: any, idx: any) => {
    //           idxsList.push(copyCalendarShifts.findIndex((x: any) => x.from == cs.from && x.to == cs.to))
    //         });
    //         if (idxsList.length > 0) {
    //           idxsList.forEach((idx: any) => copyCalendarShifts[idx] = null);
    //           idxsList = [];
    //         }
    //         copyCalendarShifts = copyCalendarShifts.filter((x: any) => x != null);

    //         result.shifts.forEach((shift: any) => {

    //           copyCalendarShifts.push({
    //             validity: 0,
    //             shiftId: shift.shiftId,
    //             workDay: day,
    //             template: result.id,
    //             operatorIds: null,
    //             from: day + 'T' + shift.from + ':00.000',
    //             to: (moment(shift.to, 'HH:mm').isAfter(moment(shift.from, 'HH:mm')) ? day : moment(day).add(1, 'days').format("YYYY-MM-DD")) + 'T' + shift.to + ':00.000',
    //           });
    //         });
    //       });

    //       this.calendarShifts = this.clonerService.deepClone(copyCalendarShifts);
    //       // TODO: change parse with update
    //       // this.parseCalendarDays(this);
    //       this.updateCalendar(this);
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    // });
  }

  removeShiftTemplate(shiftTemplate: any) {

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent,
      {
        panelClass: 'ff-dialog',
        // width: '40%',
        height: 'auto',
        data: {
          title: this.translate.instant("SHIFT_TEMPLATES.DELETE_SHIFT_TEMPLATE_CONFIRM", {
            shiftTemplate: shiftTemplate.name
          }),
        },
      });

    confirmationDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          this.shiftTemplates.splice(this.shiftTemplates.findIndex((x: any) => x.id == shiftTemplate.id), 1);
          this.updateShiftTemplates(this);

        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  addShiftTemplate(shiftTemplate?: any) {

    let shiftTemplateDialog = {
      shiftTemplate: shiftTemplate
    };

    const editShiftSelectionDialog = this.dialog.open(EditShiftTemplateComponent,
      {
        panelClass: 'ff-dialog',
        // width: '40%',
        height: 'auto',
        data: {
          title: this.translate.instant("CALENDAR.SHIFT_TEMPLATE_SELECTION"),
          machine: this.machine,
          appConfig: this.appConfig,
          profile: this.machine.profile,
          shiftTemplates: this.shiftTemplates,
          shiftTemplateDialog: shiftTemplateDialog
        },
      });

    editShiftSelectionDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          console.log(result);

          if (shiftTemplate == null) {
            this.shiftTemplates.push(this.parseNewShiftTemplate(result));
          } else {
            let ix = this.shiftTemplates.findIndex((x: any) => x.id == result.id);
            if (ix != -1) {
              this.shiftTemplates[ix] = Object.assign({}, this.parseNewShiftTemplate(result));
            }
          }

          this.parseShiftTemplates(this.shiftTemplates);
          this.updateShiftTemplates(this);


        } catch (error) {
          console.log(error);
        }
      }
    });
  };


  parseNewShiftTemplate(template: any) {
    template.shifts.forEach((t: any) => {
      t.from = this.filterService.parseTime24H(this.filterService, t.slider.min, 's', 'HH:mm');
      t.to = this.filterService.parseTime24H(this.filterService, t.slider.max, 's', 'HH:mm');
      delete t.slider;
    });
    return template;
  }

  parseShiftTemplates(data: any) {
    let parsedData = data.reduce((acc, shiftTemplate) => {
      shiftTemplate.isEmpty = !this.calendarShifts.some((x: any) => x.template == shiftTemplate.id);
      acc.push(shiftTemplate);
      return acc;
    }, []);

    return parsedData;
  }

  parsePayload(data: any) {
    return data.reduce((acc, shiftTemplate) => {
      delete shiftTemplate?.isEmpty;
      delete shiftTemplate?.hide;
      acc.push(shiftTemplate);
      return acc;
    }, []);
  }

  updateShiftTemplates(_this: any) {
    try {

      let query: any = null;
      let payload: any = _this.parsePayload(_this.clonerService.deepClone(_this.shiftTemplates));

      // console.log({ payload });

      _this.apiService.sendPostRequest('/apif/calendar-shifts/' + _this.machineId, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => {
            _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingEvents.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
  }

}