<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector *ngIf="KPIDropdown != null" [config]="intervalConfig" (selected)="selectInterval($event)"
      [intAggrs]="intervalAggregations" (selectedAggr)="selectAggregation($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < 5 || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar -->
    <div class="ff-toolbar pb-3 pt-1 w100" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center"
      *ngIf="KPIDropdown != null || aggregationDropdown != null || availableMachines != null">

      <!-- kpis -->
      <div *ngIf="KPIDropdown != null" class="select-box" fxLayout="column" fxLayoutAlign="center start"
        fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

        <div class="box" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">timeline</mat-icon>
          <mat-form-field appearance="fill">
            <mat-label>KPI</mat-label>
            <mat-select [(value)]="KPIDropdown.selected">
              <mat-option *ngFor="let KPI of KPIDropdown.list" [value]="KPI" (click)="getAggregation(KPIDropdown)">
                {{ KPI.label | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="KPIDropdown.selected.tooltip != null">
          <span matTooltip="{{ KPIDropdown.selected.tooltip | translate}}">
            <ff-icon icon="info"></ff-icon>
          </span>
        </div>

      </div>

      <!-- aggregations -->
      <div
        *ngIf="aggregationDropdown != null && aggregationDropdown.listFiltered != null && aggregationDropdown.listFiltered.length > 0"
        class="select-box" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="start center" [ngStyle]="{ 
          'margin-left.px' : isMobile ? 0 : 12,
          'margin-top.px' : isMobile ? 8 : 0 
        }">

        <div class="box" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon">timeline</mat-icon>
          <mat-form-field appearance="fill">
            <mat-label>{{'KPI_ANALYTICS.AGGREGATION_TYPE'|translate}}</mat-label>
            <mat-select [(value)]="aggregationDropdown.selected" style="text-transform: capitalize;">
              <mat-option [value]="'---'" (click)="getAnalytics()">---</mat-option>
              <mat-option *ngFor="let aggregate of aggregationDropdown.listFiltered" [value]="aggregate.id"
                (click)="getAnalytics()">
                {{ aggregate.label | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="KPIDropdown != null" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.gt-sm="center center"
        class="switch-container" marginLeft>
        <span *ngIf="hideZerosSwitch?.title != null" style="margin: 0 8px;">
          {{ hideZerosSwitch.title | translate }}
        </span>
        <span fxFlex fxHide.gt-sm></span>
        <ui-switch [checked]="hideZerosSwitch?.checked" (change)="switchChange($event)"
          checkedLabel="{{hideZerosSwitch?.checkedLabel}}" uncheckedLabel="{{hideZerosSwitch?.uncheckedLabel}}">
        </ui-switch>
      </div>

      <div *ngIf="KPIDropdown != null && KPIDropdown.selected?.aggregationType == 'sum'" fxLayout="row"
        fxLayoutAlign="start center" fxLayoutAlign.gt-sm="center center" class="switch-container" marginLeft>
        <span *ngIf="showBoxplotSwitch?.title != null" style="margin: 0 8px;">
          {{ showBoxplotSwitch.title | translate }}
        </span>
        <span fxFlex fxHide.gt-sm></span>
        <ff-icon icon="info" matTooltip="{{ showBoxplotSwitch?.tooltip | translate }}"></ff-icon>
        <ui-switch [checked]="showBoxplotSwitch?.checked" (change)="boxPlotSwitchChange($event)"
          checkedLabel="{{showBoxplotSwitch?.checkedLabel}}" uncheckedLabel="{{showBoxplotSwitch?.uncheckedLabel}}">
        </ui-switch>
      </div>

      <span fxFlex fxShow="false" fxShow.gt-sm></span>

      <ff-available-machines-selection [availableMachines]="availableMachines"
        (selected)="machineSelectionChange($event)"></ff-available-machines-selection>

    </div>

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData" class="h100"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= pageStateReady" fxLayout="column" class="w100 h100"
      style="min-height: calc(100vh - 260px);">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
        class="h100 w100" fxLayoutGap="8px">

        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="w100 h100" fxLayoutGap="8px">
          <ff-widget fxFlex="100"
            [widget]="{type: 'ff-plotly-chart-table', data: kpiAnalyticsData, machineProfile: machine.profile, config: kpiAnalyticsData.dataConfig, additionalTableConfig: additionalTableConfig, customHeight: '100%' }">
          </ff-widget>
        </div>

      </div>

    </div>

  </div>

</div>