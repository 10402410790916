<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
  <!-- utils -->
  <!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->
</div>

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxFlex fxLayout="column" class="w100">

  <!-- toolbar -->
  <div fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">
    <!-- tabs -->
    <ff-tabs [tabs]="tabs"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>
    <!-- <span fxHide.gt-sm style="height: 16px;"></span> -->

  </div>

  <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="tab-content">

    <div fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 12px;">
      <mat-icon class="icon md-orange" style="font-size: 24px;">warning</mat-icon>
      <span style="margin-left: 12px; font-weight: 800; font-size: 20px;">{{ 'GLOBAL.DEMO_MODE' | translate }}</span>
    </div>

    <form class="form-card w100" (ngSubmit)="onCreateCard()" #f2="ngForm">
      <div class="form-group row" fxLayout="row" fxLayoutAlign="start center">
        <label class="col-sm-2 side-label">{{ 'STOPS_SHEET.CAUSE' | translate }}:</label>
        <div class="col-sm-6">
          <select class="form-input" id="cause" name="cause" [ngModel]="defaultCause">
            <optgroup *ngFor="let cause of causes; let i = index">
              <option [value]="cause">
                {{ cause }}
              </option>
            </optgroup>
          </select>
        </div>
        <label class="col-sm-2 side-label">{{ 'STOPS_SHEET.TRAFILA' | translate }}:</label>
        <div class="col-sm-2">
          <div class="btn-group" role="group" fxLayout="row" fxLayoutAlign="end center">
            <label class="custom-btn" *ngFor="let trafila of trafile; let i = index"
              [ngClass]="trafilaSelected === i ? 'selected' : 'custom-btn-secondary'" (click)="onSelectTrafila(i)">
              <input class="hide" type="radio" id="trafila" name="trafila" [value]="trafila" [ngModel]="defaultTrafila">
              {{ trafila }}
            </label>
          </div>
        </div>
      </div>
      <div class="form-group row" fxLayout="row" fxLayoutAlign="start center" style="margin-top: 1vh;">
        <label class="col-sm-2 side-label">{{ 'STOPS_SHEET.START_DATE' | translate }}:</label>
        <!-- <label class="col-sm-2 side-label">Data inizio:</label> -->
        <div class="col-sm-4">
          <input type="date" class="form-input" name="startDate" [ngModel]="defaultDate">
        </div>
        <label class="col-sm-2 side-label">{{ 'STOPS_SHEET.END_DATE' | translate }}:</label>
        <div class="col-sm-4">
          <input type="date" class="form-input" name="endDate" [ngModel]="defaultDate">
        </div>
      </div>
      <div class="form-group row" fxLayout="row" fxLayoutAlign="start center">
        <label class="col-sm-2 side-label">{{ 'STOPS_SHEET.START_TIME' | translate }}:</label>
        <div class="col-sm-4">
          <input type="time" class="form-input" name="startTime" ngModel required>
        </div>
        <label class="col-sm-2 side-label">{{ 'STOPS_SHEET.END_TIME' | translate }}:</label>
        <div class="col-sm-4">
          <input type="time" class="form-input" name="endTime" [ngModel]="endTime">
        </div>
      </div>
      <div class="form-group row" fxLayout="row" fxLayoutAlign="center center" style="margin-top: 1vh;">
        <span fxFlex></span>
        <div fxLayout="row" fxLayoutAlign="end center">
          <button class="custom-btn custom-btn-primary" type="submit" [disabled]="!f2.valid">
            {{ 'STOPS_SHEET.SUBMIT' | translate }}</button>
        </div>
      </div>
    </form>
    <app-stops-story *ngFor="let stopEl of stops; let i = index" [stop]="stopEl" [index]="i" class="w100">
    </app-stops-story>
  </div>
  <!-- [index]="i" -->
</div>
