<!-- ------------------------------
  cardStyle 1
  40factory
------------------------------ -->
<mat-card *ngIf="cardStyle == 1" class="card-style-1 homepage-card clickable selected"
  [ngClass]="{'selected': machineData.selected}" (click)="selectMachine()">

  <!-- header -->
  <div class="header" fxLayout="column" fxLayout.gt-sm="row">

    <!-- title -->
    <div class="title" fxFlex>
      {{machineData.machineName}}
    </div>

    <!-- status -->
    <div class="status {{machineData.stateUI.class}}">
      <!-- icon -->
      <mat-icon *ngIf="machineData.stateUI.icon.type == 'icon'" class="icon"
        translate-attr="{'aria-label': machineData.stateUI.title}">
        {{machineData.stateUI.icon.icon }}</mat-icon>
      <mat-icon *ngIf="machineData.stateUI.icon.type == 'svg'" svgIcon="{{ machineData.stateUI.icon.icon }}"
        class="icon" translate-attr="{'aria-label': machineData.stateUI.title}"></mat-icon>
      <div *ngIf="machineData.stateUI.icon.type == 'fontawesome' || machineData.stateUI.icon.type == 'phosphor'">
        <i class="icon" [ngClass]="machineData.stateUI.icon.icon"
          translate-attr="{'aria-label': machineData.stateUI.title}"></i>
      </div>
      <!-- label -->
      <span>{{machineData.stateUI.title}}</span>
    </div>

  </div>

  <!-- machineInfos + image -->
  <div class="info-image" fxLayout="column" fxLayout.gt-sm="row">

    <div class="box" fxFlex="40" fxFlexOrder="2" fxFlexOrder.gt-sm="1">
      <!-- title -->
      <p class="title">
        <span>{{'HOMEPAGE.FILTER_INFO'|translate}}</span>
      </p>
      <!-- job -->
      <p class="job">
        <span>{{'HOMEPAGE.JOB_N'|translate}}</span>
        <span>{{machineData.jobN}}</span>
      </p>
      <!-- machineInfos -->
      <div class="machineInfos" *ngIf="machineInfos != null">
        <div *ngFor="let item of machineInfos">
          <div class="box2" *ngIf="item.grid" fxLayout="row">
            <div fxLayout="column">
              <div class="title">{{item.label|translate}}</div>
              <div class="value">{{machineData[item.variable]}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- image -->
    <div class="image" fxFlex="60" fxFlexOrder="1" fxFlexOrder.gt-sm="2">
      <img [src]="machineData.image" (error)="internalDataService.handleMissingImage($event, machineData)">
    </div>

  </div>

  <!-- production -->
  <div class="production" [ngStyle]="{'display': machineData.loading ? 'none' : 'inherit'}">
    <div class="box" fxLayout="column" fxLayout.gt-lg="row">
      <!-- box left -->
      <div fxLayout="column" fxFlex="40">
        <!-- title -->
        <p class="title">
          <span>{{'HOMEPAGE.PRODUCTIVITY'|translate}}</span>
          <!-- <span>{{ interval.label |translate}}</span> -->
        </p>
      </div>
      <!-- box right -->
      <div fxLayout="column" fxFlex="60">
        <!-- title -->
        <p class="title">
          <span>{{'HOMEPAGE.PRODUCTION_DATA'|translate}}</span>
          <!-- <span>{{ interval.label |translate}}</span> -->
        </p>
        <!-- machineDatas -->
        <div class="machineDatas" *ngIf="machineDatas != null" fxLayout="column" fxLayout.gt-md="row wrap">

          <div *ngFor="let item of machineDatas" fxFlex="50">
            <div class="box2" fxLayout="row">
              <div fxLayout="column">
                <div class="title">{{item.label|translate}}</div>
                <div class="value">
                  <span>{{machineData[item.variable]}}</span>
                  <span *ngIf="item.suffix != null">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>

  <div class="production" [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}">
    <div fxLayout="row" fxLayoutAlign="center center">
      <ff-loading-card></ff-loading-card>
    </div>
  </div>

</mat-card>

<!-- ------------------------------
  cardStyle 2
  aqseptence
  sire
------------------------------ -->
<mat-card *ngIf="cardStyle == 2" class="card-style-2 homepage-card clickable selected"
  [ngClass]="{'selected': machineData.selected}" (click)="selectMachine()">

  <!-- header -->
  <div class="header" fxLayout="column" fxLayout.gt-sm="row">

    <!-- title -->
    <div class="title mb-3 mb-sm-0" fxFlex>
      {{machineData.machineName}}
    </div>

    <!-- status -->
    <div *ngIf="machineData.stateUI" class="status {{machineData.stateUI.class}}" fxLayout="row"
      fxLayoutAlign="start center">
      <!-- icon -->
      <div *ngIf="machineData.stateUI.icon">
        <mat-icon *ngIf="machineData.stateUI.icon.type == 'icon'" class="icon small"
          translate-attr="{'aria-label': machineData.stateUI.title}">
          {{machineData.stateUI.icon.icon }}</mat-icon>
        <mat-icon *ngIf="machineData.stateUI.icon.type == 'svg'" svgIcon="{{ machineData.stateUI.icon.icon }}"
          class="icon small" translate-attr="{'aria-label': machineData.stateUI.title}"></mat-icon>
        <div *ngIf="machineData.stateUI.icon.type == 'fontawesome' || machineData.stateUI.icon.type == 'phosphor'">
          <i class="icon small" [ngClass]="machineData.stateUI.icon.icon"
            translate-attr="{'aria-label': machineData.stateUI.title}"></i>
        </div>
      </div>
      <!-- label -->
      <span>{{machineData.stateUI.title}}</span>
    </div>

  </div>

  <!-- machineInfos + image -->
  <div class="info-image" fxLayout="column" fxLayout.gt-sm="row">

    <div class="box mb-3 mb-sm-0" fxFlex="40" fxFlexOrder="2" fxFlexOrder.gt-sm="1">
      <!-- title -->
      <p class="title">
        <span>{{ 'HOMEPAGE.LINE_INFO' | translate }}</span>
      </p>
      <!-- machineInfos -->
      <div class="machineInfos" *ngIf="machineInfos != null">
        <div *ngFor="let item of machineInfos">
          <div class="box2" *ngIf="item.grid" fxLayout="row">
            <div class="icon-box">
              <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                {{item.icon.icon }}</mat-icon>
              <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                translate-attr="{'aria-label': item.label}"></mat-icon>
              <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
            </div>
            <div fxLayout="column">
              <div class="title">{{item.label|translate}}</div>
              <div class="value">{{machineData[item.variable]}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- image -->
    <div class="image mb-3 mb-sm-0" fxFlex="60" fxFlexOrder="1" fxFlexOrder.gt-sm="2">
      <img [src]="machineData.image" (error)="internalDataService.handleMissingImage($event, machineData)">
    </div>

  </div>

  <!-- production -->
  <div class="production mb-3 mb-sm-0" [ngStyle]="{'display': machineData.loading ? 'none' : 'inherit'}">
    <div class="box" fxLayout="column" fxLayout.gt-md="row">

      <!-- box left -->
      <div class="h100" fxLayout="column" fxFlex="40">
        <!-- title -->
        <p class="title">
          <span>{{'HOMEPAGE.PRODUCTIVITY'|translate}}</span>
          <!-- <span>{{ interval.label |translate}}</span> -->
        </p>
        <!-- gauge -->
        <div fxLayout="row" fxLayoutAlign="center center">
          <ff-gauge [gaugeData]="machineData.oeeGaugeUI"></ff-gauge>
        </div>
      </div>

      <!-- box right -->
      <div class="h100" fxLayout="column" fxFlex="60">
        <!-- title -->
        <p class="title">
          <span>{{'HOMEPAGE.PRODUCTION_DATA'|translate}}</span>
          <!-- <span>{{ interval.label |translate}}</span> -->
        </p>
        <!-- machineDatas -->
        <div class="machineDatas" *ngIf="machineDatas != null" fxLayout="column" fxLayout.gt-md="row wrap">

          <div *ngFor="let item of machineDatas" fxFlex="50">
            <div class="box2" fxLayout="row">
              <div class="icon-box">
                <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                  {{item.icon.icon }}</mat-icon>
                <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                  translate-attr="{'aria-label': item.label}"></mat-icon>
                <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                  class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
              </div>
              <div fxLayout="column">
                <div class="title">{{item.label|translate}}</div>
                <div class="value">
                  <span>{{machineData[item.variable] != null ? machineData[item.variable] : '-' }}</span>
                  <span *ngIf="item.suffix != null">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
                </div>
              </div>
            </div>

          </div>

        </div>

      </div>

    </div>
  </div>

  <div class="production" [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}">
    <div fxLayout="row" fxLayoutAlign="center center">
      <ff-loading-card></ff-loading-card>
    </div>
  </div>

</mat-card>


<!-- ------------------------------
  cardStyle 3
  ocme
------------------------------ -->
<mat-card *ngIf="cardStyle == 3" class="card-style-3 homepage-card clickable selected"
  [ngClass]="{'selected': machineData.selected}" (click)="selectMachine()">

  <!-- header -->
  <div class="header" fxLayout="column" fxLayout.gt-sm="row">

    <!-- title -->
    <div class="title mb-3 mb-sm-0" fxFlex fxLayout="row" fxLayoutAlign="start center">
      {{machineData.machineName}}
      <mat-icon *ngIf="machineData.noShiftsPlanned" class="icon md-orange-i"
        matTooltip="{{ 'GLOBAL.NO_SHIFT_PLANNED_TODAY' | translate }}" matTooltipPosition="above">warning
      </mat-icon>
    </div>

    <!-- loading -->
    <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}">
      <ff-loading-card [loadingData]="{'class': 'padding0 mx-2'}"></ff-loading-card>
    </div>

    <!-- status -->
    <div *ngIf="machineData.stateUI" class="status {{machineData.stateUI.class}}" fxLayout="row"
      fxLayoutAlign="start center">
      <!-- icon -->
      <div *ngIf="machineData.stateUI.icon">
        <mat-icon *ngIf="machineData.stateUI.icon.type == 'icon'" class="icon small"
          translate-attr="{'aria-label': machineData.stateUI.title}">
          {{machineData.stateUI.icon.icon }}</mat-icon>
        <mat-icon *ngIf="machineData.stateUI.icon.type == 'svg'" svgIcon="{{ machineData.stateUI.icon.icon }}"
          class="icon small" translate-attr="{'aria-label': machineData.stateUI.title}"></mat-icon>
        <div *ngIf="machineData.stateUI.icon.type == 'fontawesome' || machineData.stateUI.icon.type == 'phosphor'">
          <i class="icon small" [ngClass]="machineData.stateUI.icon.icon"
            translate-attr="{'aria-label': machineData.stateUI.title}"></i>
        </div>
      </div>
      <!-- label -->
      <span>{{machineData.stateUI.title}}</span>
    </div>

    <!-- Last update detail -->
    <div fxHide.lt-md="true" class="ff-input-select clickable just-icon" (click)="openLastUpdatesDialog()"
      #tooltip="matTooltip" matTooltip="{{ 'HOMEPAGE.LAST_UPDATES_TOOLTIP' | translate }}" matTooltipPosition="above">
      <mat-icon svgIcon="touch"></mat-icon>
    </div>

  </div>

  <div fxLayout="column" fxLayout.gt-sm="row">

    <div class="box transparent mb-3 mb-sm-0" fxFlex="15">
      <!-- title -->
      <p class="title">
        <span>{{ 'HOMEPAGE.LINE_INFO' | translate }}</span>
      </p>
      <!-- machineInfos -->
      <div class="machineInfos" *ngIf="machineInfos != null">
        <div *ngFor="let item of machineInfos">
          <div class="box2" *ngIf="item.grid" fxLayout="row">
            <div class="icon-box main-color">
              <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                {{item.icon.icon }}</mat-icon>
              <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                translate-attr="{'aria-label': item.label}"></mat-icon>
              <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
            </div>
            <div fxLayout="column">
              <div class="title">{{item.label|translate}}</div>
              <div class="value">{{machineData[item.variable]}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- image -->
    <div class="image mb-3 mb-sm-0" fxFlex="40" fxLayoutAlign="center center">
      <img [src]="machineData.image" (error)="internalDataService.handleMissingImage($event, machineData)">
    </div>

    <!-- production data -->
    <div class="box mb-3 mb-sm-0" fxLayout="column" fxFlex="30">
      <!-- title -->
      <p class="title">
        <span>{{'HOMEPAGE.PRODUCTION_DATA'|translate}}</span>
        <!-- <span>{{ interval.label |translate}}</span> -->
      </p>
      <!-- machineDatas -->
      <div class="machineDatas h100" *ngIf="machineDatas != null" fxLayout="column" fxLayout.gt-md="row wrap">

        <div *ngFor="let item of machineDatas" fxFlex="50">
          <div class="box2" fxLayout="row">
            <div class="icon-box">
              <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                {{item.icon.icon }}</mat-icon>
              <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                translate-attr="{'aria-label': item.label}"></mat-icon>
              <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
            </div>
            <div fxLayout="column">
              <div class="title">{{item.label|translate}}</div>
              <div class="value">
                <span>{{machineData[item.variable] != null ? machineData[item.variable] : '-' }}</span>
                <span *ngIf="item.suffix != null">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
              </div>
            </div>
          </div>

        </div>

      </div>

    </div>

    <!-- productivity -->
    <div class="box" fxLayout="column" fxFlex="15">
      <!-- title -->
      <p class="title">
        <span>{{'HOMEPAGE.PRODUCTIVITY'|translate}}</span>
        <!-- <span>{{ interval.label |translate}}</span> -->
      </p>
      <!-- gauge -->
      <div fxLayout="column" fxLayoutAlign="space-around stretch" class="w100 h100">
        <ff-gauge [gaugeData]="machineData.oeeGaugeUI"></ff-gauge>
        <kpi-bar [kpiProps]="{ 'value': machineData.availability, 'label': 'A', 'style': 'margin-bottom: 24px;' }">
        </kpi-bar>
        <kpi-bar [kpiProps]="{ 'value': machineData.quality, 'label': 'Q', 'style': 'margin-bottom: 24px;' }"></kpi-bar>
        <kpi-bar [kpiProps]="{ 'value': machineData.performance, 'label': 'P' }"></kpi-bar>
      </div>

    </div>

  </div>

</mat-card>


<!-- ------------------------------
  cardStyle 4
  mutti
------------------------------ -->
<mat-card *ngIf="cardStyle == 4" class="card-style-4 homepage-card clickable selected"
  [ngClass]="{'selected': machineData.selected}" (click)="selectMachine()">

  <!-- header -->
  <div class="header" fxLayout="column" fxLayout.gt-sm="row">

    <!-- title -->
    <div class="title" fxFlex>{{machineData.machineName}}</div>

    <!-- status -->
    <div *ngIf="machineData.stateUI" class="status {{machineData.stateUI.class}}" fxLayout="row"
      fxLayoutAlign="start center">
      <!-- icon -->
      <div *ngIf="machineData.stateUI.icon">
        <mat-icon *ngIf="machineData.stateUI.icon.type == 'icon'" class="icon small"
          translate-attr="{'aria-label': machineData.stateUI.title}">
          {{machineData.stateUI.icon.icon }}</mat-icon>
        <mat-icon *ngIf="machineData.stateUI.icon.type == 'svg'" svgIcon="{{ machineData.stateUI.icon.icon }}"
          class="icon small" translate-attr="{'aria-label': machineData.stateUI.title}"></mat-icon>
        <div *ngIf="machineData.stateUI.icon.type == 'fontawesome' || machineData.stateUI.icon.type == 'phosphor'">
          <i class="icon small" [ngClass]="machineData.stateUI.icon.icon"
            translate-attr="{'aria-label': machineData.stateUI.title}"></i>
        </div>
      </div>
      <!-- label -->
      <span>{{machineData.stateUI.title}}</span>
    </div>

  </div>

  <div fxLayout="column" fxLayout.gt-sm="row">

    <!-- image -->
    <div class="image" fxFlex="15" fxLayoutAlign="center center">
      <img [src]="machineData.image" (error)="internalDataService.handleMissingImage($event, machineData)">
    </div>

    <!-- line info -->
    <div class="box" fxFlex="20">
      <!-- title -->
      <p class="title">
        <span>{{ 'HOMEPAGE.LINE_INFO' | translate }}</span>
      </p>
      <!-- machineInfos -->
      <div class="machineInfos" *ngIf="machineInfos != null">
        <div *ngFor="let item of machineInfos">
          <div class="box2" *ngIf="item.grid" fxLayout="row">
            <div class="icon-box">
              <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                {{item.icon.icon }}</mat-icon>
              <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                translate-attr="{'aria-label': item.label}"></mat-icon>
              <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
            </div>
            <div fxLayout="column">
              <div class="title">{{item.label|translate}}</div>
              <div class="value">{{machineData[item.variable] != null ? machineData[item.variable] : '-' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- production data -->
    <div class="box" fxLayout="column" fxFlex="30">
      <!-- title -->
      <p class="title">
        <span>{{'HOMEPAGE.PRODUCTION_DATA'|translate}}</span>
        <!-- <span>{{ interval.label |translate}}</span> -->
      </p>
      <!-- machineDatas -->
      <div class="machineDatas h100" *ngIf="machineDatas != null" fxLayout="column" fxLayout.gt-md="row wrap"
        [ngStyle]="{'display': machineData.loading ? 'none' : 'inherit'}">

        <div *ngFor="let item of machineDatas" fxFlex="50">
          <div class="box2" fxLayout="row">
            <div class="icon-box">
              <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                {{item.icon.icon }}</mat-icon>
              <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                translate-attr="{'aria-label': item.label}"></mat-icon>
              <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
            </div>
            <div fxLayout="column">
              <div class="title">{{item.label|translate}}</div>
              <div class="value">
                <span>{{machineData[item.variable] != null ? machineData[item.variable] : '-' }}</span>
                <span *ngIf="item.suffix != null">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="h100 w100">
        <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
          <ff-loading-card></ff-loading-card>
        </div>
      </div>

    </div>

    <!-- productivity -->
    <div class="box" fxLayout="column" fxFlex="15">
      <!-- title -->
      <p class="title">
        <span>{{'HOMEPAGE.PRODUCTIVITY'|translate}}</span>
        <!-- <span>{{ interval.label |translate}}</span> -->
      </p>
      <!-- gauge -->
      <div fxLayout="column" fxLayoutAlign="space-around stretch" class="w100 h100"
        [ngStyle]="{'display': machineData.loading ? 'none' : 'inherit'}">
        <ff-gauge [gaugeData]="machineData.oeeGaugeUI"></ff-gauge>
        <kpi-bar [kpiProps]="{ 'value': machineData.availability, 'label': 'A', 'style': 'margin-bottom: 24px;' }">
        </kpi-bar>
        <kpi-bar [kpiProps]="{ 'value': machineData.quality, 'label': 'Q', 'style': 'margin-bottom: 24px;' }"></kpi-bar>
        <kpi-bar [kpiProps]="{ 'value': machineData.performance, 'label': 'P' }"></kpi-bar>
      </div>

      <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="h100 w100">
        <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
          <ff-loading-card></ff-loading-card>
        </div>
      </div>

    </div>

    <!-- production order -->
    <div class="box" fxLayout="column" fxFlex="20">
      <!-- title -->
      <p class="title">
        <!-- <span>{{'HOMEPAGE.PRODUCTION_ORDER'|translate}}</span> -->
        <span>Produciton Order</span>
      </p>
      <!-- otherData -->
      <div class="machineInfos" *ngIf="machineData != null" fxLayout="column"
        [ngStyle]="{'display': machineData.loading ? 'none' : 'inherit'}">
        <div *ngFor="let item of otherData">
          <div class="box2" fxLayout="row">
            <div class="icon-box">
              <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                {{item.icon.icon }}</mat-icon>
              <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                translate-attr="{'aria-label': item.label}"></mat-icon>
              <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
            </div>
            <div fxLayout="column">
              <div class="title">{{item.label|translate}}</div>
              <div fxLayout="row">
                <div class="value">{{machineData[item.variable] != null ? machineData[item.variable] : '-' }}</div>
                <span *ngIf="item.suffix != null">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="h100 w100">
        <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
          <ff-loading-card></ff-loading-card>
        </div>
      </div>

    </div>

  </div>

</mat-card>


<!-- ------------------------------
  cardStyle 5
  sala
------------------------------ -->
<mat-card *ngIf="cardStyle == 5" class="card-style-5 homepage-card clickable selected"
  [ngClass]="{'selected': machineData.selected}" (click)="selectMachine()">

  <!-- header -->
  <div class="header" fxLayout="column" fxLayout.gt-sm="row">

    <!-- title -->
    <div class="title" fxFlex fxLayout="row" fxLayoutAlign="start center">
      {{machineData.machineName}}
      <mat-icon *ngIf="machineData.noShiftsPlanned" class="icon md-orange"
        matTooltip="{{ 'GLOBAL.NO_SHIFT_PLANNED_TODAY' | translate }}" matTooltipPosition="above">warning</mat-icon>
    </div>

    <!-- status -->
    <div *ngIf="machineData.stateUI" class="status {{machineData.stateUI.class}}" fxLayout="row"
      fxLayoutAlign="start center">
      <!-- icon -->
      <div *ngIf="machineData.stateUI.icon">
        <mat-icon *ngIf="machineData.stateUI.icon.type == 'icon'" class="icon small"
          translate-attr="{'aria-label': machineData.stateUI.title}">
          {{machineData.stateUI.icon.icon }}</mat-icon>
        <mat-icon *ngIf="machineData.stateUI.icon.type == 'svg'" svgIcon="{{ machineData.stateUI.icon.icon }}"
          class="icon small" translate-attr="{'aria-label': machineData.stateUI.title}"></mat-icon>
        <div *ngIf="machineData.stateUI.icon.type == 'fontawesome' || machineData.stateUI.icon.type == 'phosphor'">
          <i class="icon small" [ngClass]="machineData.stateUI.icon.icon"
            translate-attr="{'aria-label': machineData.stateUI.title}"></i>
        </div>
      </div>
      <!-- label -->
      <span>{{machineData.stateUI.title}}</span>
    </div>

  </div>

  <div fxLayout="column" fxLayout.gt-sm="row">

    <div class="box transparent" fxFlex="20">
      <!-- title -->
      <p class="title">
        <span>{{ 'HOMEPAGE.LINE_INFO' | translate }}</span>
      </p>
      <!-- machineInfos -->
      <div class="machineInfos" *ngIf="machineInfos != null">
        <div *ngFor="let item of machineInfos">
          <div class="box2" *ngIf="item.grid" fxLayout="row">
            <div class="icon-box main-color">
              <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                {{item.icon.icon }}</mat-icon>
              <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                translate-attr="{'aria-label': item.label}"></mat-icon>
              <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
            </div>
            <div fxLayout="column">
              <div class="title">{{item.label|translate}}</div>
              <div class="value">{{machineData[item.variable]}}</div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- image -->
    <div class="image" fxFlex="30" fxLayoutAlign="center center">
      <img [src]="machineData.image" (error)="internalDataService.handleMissingImage($event, machineData)">
    </div>

    <!-- production data -->
    <div class="box" fxLayout="column" fxFlex="30">
      <!-- title -->
      <p class="title">
        <span>{{'HOMEPAGE.PRODUCTION_DATA'|translate}}</span>
        <!-- <span *ngIf="interval != null" class="subtitle">
          &nbsp;({{ interval.label | translate }})
        </span> -->
      </p>
      <!-- machineDatas -->
      <div class="machineDatas h100" *ngIf="machineDatas != null" fxLayout="column" fxLayout.gt-md="row wrap"
        [ngStyle]="{'display': machineData.loading ? 'none' : 'inherit'}">

        <div *ngFor="let item of machineDatas" fxFlex="50">
          <div class="box2" fxLayout="row">
            <div class="icon-box">
              <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                {{item.icon.icon }}</mat-icon>
              <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                translate-attr="{'aria-label': item.label}"></mat-icon>
              <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
            </div>
            <div fxLayout="column">
              <div class="title">{{item.label|translate}}</div>
              <div class="value">
                <span>{{machineData[item.variable] != null ? machineData[item.variable] : '-' }}</span>
                <span *ngIf="item.suffix != null">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="h100 w100">
        <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
          <ff-loading-card></ff-loading-card>
        </div>
      </div>

    </div>

    <!-- productivity -->
    <div fxLayout="column" fxFlex="20">

      <!-- availability, performance -->
      <div class="box" fxLayout="column">
        <p class="title"><span>{{'HOMEPAGE.PRODUCTIVITY'|translate}}</span></p>

        <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="h100 w100">
          <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
            <ff-loading-card></ff-loading-card>
          </div>
        </div>

        <div *ngFor="let kpi of oeeInfos" fxLayout="column" fxLayoutAlign="space-around stretch" class="w100 h100"
          [ngStyle]="{'display': machineData.loading ? 'none' : 'inherit'}">
          <kpi-bar
            [kpiProps]="{'value':machineData[kpi.variable], 'label': kpi.label|translate, 'style':'margin-top: 24px;'}">
          </kpi-bar>
        </div>
      </div>

      <!-- energy consumption -->
      <div class="box" fxLayout="column" style="margin-top: 0.5rem;">
        <p class="title"><span>{{'HOMEPAGE.ENERGY_CONSUMPTION' | translate}}</span></p>

        <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="h100 w100">
          <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
            <ff-loading-card></ff-loading-card>
          </div>
        </div>

        <div fxLayout="column" fxLayoutAlign="space-around stretch" class="w100 h100"
          [ngStyle]="{'display': machineData.loading ? 'none' : 'inherit'}">

          <div class="box2" fxLayout="row">
            <div class="icon-box">
              <mat-icon class="icon" aria-label="energy">bolt</mat-icon>
            </div>
            <div fxLayout="column">
              <!-- <div class="title">{{item.label|translate}}</div> -->
              <div class="value">
                <span>{{machineData.energyConsumption != null ?
                  filterService.parseGaugeValue(machineData.energyConsumption,3,1)+' kWh' : '- kWh' }}</span>
                <!-- <span *ngIf="item.suffix != null">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span> -->
              </div>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>

</mat-card>

<!-- ------------------------------
  cardStyle 6
  crm
------------------------------ -->
<mat-card *ngIf="cardStyle == 6" class="card-style-6 homepage-card clickable selected"
  [ngClass]="{'selected': machineData.selected}" (click)="selectMachine()">

  <!-- header -->
  <div class="header" fxLayout="column" fxLayout.gt-sm="row">

    <!-- title -->
    <div class="title" fxFlex>{{machineData.machineName}}</div>

    <!-- status -->
    <div *ngIf="machineData.stateUI" class="status {{machineData.stateUI.class}}" fxLayout="row"
      fxLayoutAlign="start center">
      <!-- icon -->
      <div *ngIf="machineData.stateUI.icon">
        <mat-icon *ngIf="machineData.stateUI.icon.type == 'icon'" class="icon small"
          translate-attr="{'aria-label': machineData.stateUI.title}">
          {{machineData.stateUI.icon.icon }}</mat-icon>
        <mat-icon *ngIf="machineData.stateUI.icon.type == 'svg'" svgIcon="{{ machineData.stateUI.icon.icon }}"
          class="icon small" translate-attr="{'aria-label': machineData.stateUI.title}"></mat-icon>
        <div *ngIf="machineData.stateUI.icon.type == 'fontawesome' || machineData.stateUI.icon.type == 'phosphor'">
          <i class="icon small" [ngClass]="machineData.stateUI.icon.icon"
            translate-attr="{'aria-label': machineData.stateUI.title}"></i>
        </div>
      </div>
      <!-- label -->
      <span>{{machineData.stateUI.title}}</span>
    </div>

  </div>

  <div fxLayout="column" fxLayout.gt-sm="row">

    <!-- image -->
    <div class="image" fxFlex="20" fxLayoutAlign="center center">
      <img [src]="machineData.image" (error)="internalDataService.handleMissingImage($event, machineData)">
    </div>

    <!-- line info -->
    <div *ngIf="false" class="box" fxFlex="20">
      <!-- title -->
      <p class="title">
        <span>{{ 'HOMEPAGE.LINE_INFO' | translate }}</span>
      </p>
      <!-- machineInfos -->
      <div class="machineInfos" *ngIf="machineInfos != null">
        <div *ngFor="let item of machineInfos">
          <div class="box2" *ngIf="item.grid" fxLayout="row">
            <div class="icon-box">
              <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                {{item.icon.icon }}</mat-icon>
              <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                translate-attr="{'aria-label': item.label}"></mat-icon>
              <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
            </div>
            <div fxLayout="column">
              <div class="title">{{item.label|translate}}</div>
              <div class="value">{{machineData[item.variable] != null ? machineData[item.variable] : '-' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- production data -->
    <div class="box" fxLayout="column" fxFlex="30">
      <!-- title -->
      <p class="title">
        <span>{{'HOMEPAGE.PRODUCTION_DATA'|translate}}</span>
        <!-- <span>{{ interval.label |translate}}</span> -->
      </p>
      <!-- machineDatas -->
      <div class="machineDatas h100" *ngIf="machineDatas != null" fxLayout="column" fxLayout.gt-md="row wrap"
        [ngStyle]="{'display': machineData.loading ? 'none' : 'inherit'}">

        <div *ngFor="let item of machineDatas" fxFlex="50">
          <div class="box2" fxLayout="row">
            <div class="icon-box">
              <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                {{item.icon.icon }}</mat-icon>
              <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                translate-attr="{'aria-label': item.label}"></mat-icon>
              <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
            </div>
            <div fxLayout="column">
              <div class="title">{{item.label|translate}}</div>
              <div class="value">
                <span>{{machineData[item.variable] != null ? machineData[item.variable] : '-' }}</span>
                <span *ngIf="item.suffix != null">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="h100 w100">
        <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
          <ff-loading-card></ff-loading-card>
        </div>
      </div>

    </div>

    <!-- productivity -->
    <div class="box" fxLayout="column" fxFlex="20">
      <!-- title -->
      <p class="title">
        <span>{{'HOMEPAGE.PRODUCTIVITY'|translate}}</span>
        <!-- <span>{{ interval.label |translate}}</span> -->
      </p>
      <!-- gauge -->
      <div fxLayout="column" fxLayoutAlign="space-around stretch" class="w100 h100"
        [ngStyle]="{'display': machineData.loading ? 'none' : 'inherit'}">
        <ff-gauge [gaugeData]="machineData.oeeGaugeUI"></ff-gauge>
        <kpi-bar [kpiProps]="{ 'value': machineData.availability, 'label': 'A', 'style': 'margin-bottom: 24px;' }">
        </kpi-bar>
        <kpi-bar [kpiProps]="{ 'value': machineData.quality, 'label': 'Q', 'style': 'margin-bottom: 24px;' }"></kpi-bar>
        <kpi-bar [kpiProps]="{ 'value': machineData.performance, 'label': 'P' }"></kpi-bar>
      </div>

      <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="h100 w100">
        <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
          <ff-loading-card></ff-loading-card>
        </div>
      </div>

    </div>

    <!-- production order -->
    <div class="box" fxLayout="column" fxFlex="30">
      <!-- title -->
      <p class="title">
        <!-- <span>{{'HOMEPAGE.PRODUCTION_ORDER'|translate}}</span> -->
        <span>Production Order</span>
      </p>
      <!-- otherData -->
      <div class="machineDatas h100" *ngIf="machineDatas != null" fxLayout="column" fxLayout.gt-md="row wrap"
        [ngStyle]="{'display': machineData.loading ? 'none' : 'inherit'}">

        <div *ngFor="let item of otherData" fxFlex="50">
          <div class="box2" fxLayout="row">
            <div class="icon-box">
              <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                {{item.icon.icon }}</mat-icon>
              <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                translate-attr="{'aria-label': item.label}"></mat-icon>
              <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
            </div>
            <div fxLayout="column">
              <div class="title">{{item.label|translate}}</div>
              <div fxLayout="row">
                <div class="value">{{machineData[item.variable] != null ? machineData[item.variable] : '-' }}</div>
                <span *ngIf="item.suffix != null">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
              </div>
            </div>
          </div>
        </div>

        <div fxFlex="100">
          <div class="box2 w100" fxLayout="row">
            <kpi-bar class="w100"
              [kpiProps]="{ 'value': machineData.performance, 'label': 'HOMEPAGE.PROD_TOPROD', 'style': 'margin-top: 24px;' }">
            </kpi-bar>
          </div>
        </div>

      </div>

      <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="h100 w100">
        <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
          <ff-loading-card></ff-loading-card>
        </div>
      </div>

    </div>

  </div>

</mat-card>


















<!-- ------------------------------
  cardStyle 0 
------------------------------ -->
<mat-card *ngIf="cardStyle == 0" class="card-style-0 homepage-card clickable selected"
  [ngClass]="{'selected': machineData.selected}" (click)="selectMachine()">

  <!-- header -->
  <div class="header" fxLayout="column" fxLayoutGap="20px" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- title -->
    <div class="title" fxLayout="row" [fxFlex]="enableCalendarSwitch ? 'none' : ''" fxLayoutAlign="start center">

      <span>{{ machineData[cardConfig.config?.titleVariable] ?? machineData.machineName ?? machineData.label }}</span>

      <span *ngIf="cardConfig.config?.subtitle" class="subtitle">{{ machineData[cardConfig.config?.subtitle] }}</span>

      <mat-icon *ngIf="machineData.noShiftsPlanned" class="icon md-orange-i" style="margin-left: 8px;"
        matTooltip="{{ 'GLOBAL.NO_SHIFT_PLANNED_TODAY' | translate }}" matTooltipPosition="above">warning
      </mat-icon>

    </div>

    <!-- status-loading -->
    <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}">
      <ff-loading-card [loadingData]="{'class': 'padding0 mx-2'}"></ff-loading-card>
    </div>

    <!-- calendar toggle -->
    <div *ngIf="enableCalendarSwitch && !machineData.isHierarchicElement" fxFlex fxLayout="row"
      fxLayoutAlign="start center" class="voice">

      <ui-switch [checked]="isEnabledMachineCalendar" (change)="toggleEnableCalendarAsset($event)" checkedLabel="ON"
        uncheckedLabel="OFF">
      </ui-switch>
      <span style="margin: 0 8px;">
        {{
        (isEnabledMachineCalendar ? "CALENDAR.DISABLE_CALENDAR_PER_ASSET" : "CALENDAR.ENABLE_CALENDAR_PER_ASSET") |
        translate
        }}
      </span>
    </div>

    <!-- status -->
    <div *ngIf="machineData.stateUI" class="status {{machineData.stateUI.class}}" fxLayout="row"
      fxLayoutAlign="start center">
      <!-- icon -->
      <span *ngIf="machineData.stateUI.icon" fxLayout="column" fxLayoutAlign="center center"
        class="status-icon-container">
        <mat-icon *ngIf="machineData.stateUI.icon.type == 'icon'" class="icon small"
          translate-attr="{'aria-label': machineData.stateUI.title}">
          {{machineData.stateUI.icon.icon }}</mat-icon>
        <mat-icon *ngIf="machineData.stateUI.icon.type == 'svg'" svgIcon="{{ machineData.stateUI.icon.icon }}"
          class="icon small" translate-attr="{'aria-label': machineData.stateUI.title}"></mat-icon>
        <div *ngIf="machineData.stateUI.icon.type == 'fontawesome' || machineData.stateUI.icon.type == 'phosphor'">
          <i class="icon" [ngClass]="machineData.stateUI.icon.icon"
            translate-attr="{'aria-label': machineData.stateUI.title}"></i>
        </div>
      </span>
      <!-- label -->
      <span>{{machineData.stateUI.title}}</span>
    </div>

    <!-- Last update detail -->
    <div *ngIf="appConfig?.showLastUpdatesDetail || machineData?.profile?.showLastUpdatesDetail" fxHide.lt-md="true"
      marginLeft class="ff-input-select clickable just-icon" (click)="openLastUpdatesDialog()" #tooltip="matTooltip"
      matTooltip="{{ 'HOMEPAGE.LAST_UPDATES_TOOLTIP' | translate }}" matTooltipPosition="above">
      <mat-icon svgIcon="touch"></mat-icon>
    </div>

    <!-- additional buttons -->
    <ng-container *ngIf="cardConfig.config?.additionalButtons?.length">

      <div fxLayout="row" fxLayoutAlign="start center" style="margin-left: 8px;">
        <button *ngFor="let info of cardConfig.config?.additionalButtons" [ngClass]="info.class"
          class="{{info.label == null ? 'no-label' : ''}}" (click)="onClickFunction(info)" [disabled]="info.disabled"
          matTooltip="{{ info.tooltip | translate }}" matTooltipPosition="above" fxLayout="row"
          fxLayoutAlign="center center">

          <!-- icon -->
          <ff-icon [icon]="info.icon"></ff-icon>

          <span *ngIf="info.label != null" fxLayout="column" fxLayoutAlign="center center">
            {{ info.label | translate }}
          </span>

        </button>
      </div>

    </ng-container>

  </div>

  <span *ngIf="machineData.error" class="ff-error">
    {{ 'HOMEPAGE.CARD_ERROR' | translate }}
  </span>

  <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="start stretch"
    [ngStyle]="{'display': machineData.error ? 'none' : 'flex'}">

    <!-- image -->
    <div class="box-container image" fxLayout="column" fxLayoutAlign="center center" *ngIf="cardConfig.config.image"
      [ngClass]="{'space-on-bottom': cardConfig.config.image?.spaceOnBottom }" fxFlex="{{cardConfig.config.image.flex}}"
      fxFlexOrder="{{cardConfig.config.image.order}}">
      <div class="image" [ngClass]="cardConfig.config.image">
        <!-- loading -->
        <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="loading h100 w100">
          <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
            <ff-loading-card></ff-loading-card>
          </div>
        </div>
        <!-- content -->
        <img [ngStyle]="{
            'display': machineData.loading ? 'none' : 'flex', 
            'max-height': cardConfig.config?.image?.maxHeight
        }" [src]="machineData.image" (error)="internalDataService.handleMissingImage($event, machineData)">
      </div>

      <!-- image-location -->
      <div *ngIf="cardConfig.config.image?.location" class="image-location" fxLayout="row" fxLayoutAlign="start center">
        <div class="icon-container">
          <mat-icon class="icon" aria-label="Location">location_on</mat-icon>
        </div>
        <div class="border"></div>
        <div fxLayout="column">
          <div class="title">
            <span>{{ (cardConfig.config.image.locationLabel ?? 'HOMEPAGE.LOCATION') | translate }}</span>
          </div>
          <div class="value">
            <span>{{ machineData.locationP }}</span>
          </div>
        </div>
      </div>

    </div>

    <!-- machineInfos -->
    <div class="box-container" *ngIf="cardConfig.config.machineInfos" [fxFlex]="cardConfig.config.machineInfos.flex"
      [fxFlexOrder]="cardConfig.config.machineInfos.order">

      <div>
        <div class="title-medium" [ngClass]="{'underline': cardConfig.config?.underlineTitles}">
          <span>{{ (cardConfig.config.machineInfos?.label ?? 'HOMEPAGE.LINE_INFO') | translate }}</span>
        </div>

        <!-- loading -->
        <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="loading h100 w100">
          <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
            <ff-loading-card></ff-loading-card>
          </div>
        </div>

        <!-- content -->
        <div class="machineInfos" *ngIf="machineInfos != null" fxLayout="column" fxLayout.gt-sm="row wrap"
          [ngStyle]="{'display': machineData.loading ? 'none' : 'flex'}">
          <div *ngFor="let item of machineInfos" [fxFlex]="cardConfig.config.machineInfos.innerFlex ?? 100"
            class="box-container">

            <div class="box" *ngIf="item.grid" fxLayout="row">
              <div class="icon-box">
                <ff-icon [icon]="item.icon" class="icon"></ff-icon>
              </div>
              <div fxLayout="column">
                <div class="title">{{ filterService.translateElementNewFormat(item) }}</div>
                <div *ngIf="!item.isTime" class="value">
                  {{ machineData[item.variable] ?? '-' }}</div>
                <div *ngIf="item.isTime" class="value">{{ machineData[item.variable + 'P'] }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- productionData -->
    <div class="box-container" fxLayout="column" *ngIf="cardConfig.config.productionData"
      fxFlex="{{cardConfig.config.productionData.flex}}" fxFlexOrder="{{cardConfig.config.productionData.order}}">
      <div class="box">
        <div class="title-medium" [ngClass]="{'underline': cardConfig.config?.underlineTitles}">

          <span>
            {{ (cardConfig.config.productionData?.label ?? 'HOMEPAGE.PRODUCTION_DATA') | translate }}&nbsp;
          </span>

          <span *ngIf="interval != null && cardConfig.config.productionData.showInterval" class="subtitle">
            ({{ interval.label | translate }})
          </span>
        </div>
        <!-- loading -->
        <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="loading h100 w100">
          <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
            <ff-loading-card></ff-loading-card>
          </div>
        </div>
        <!-- content -->
        <div class="machineDatas h100" *ngIf="productionData != null" fxLayout="column" fxLayout.gt-sm="row wrap"
          fxLayoutAlign="start stretch" [ngStyle]="{'display': machineData.loading ? 'none' : 'flex'}">

          <div *ngFor="let item of productionData"
            [fxFlex]="cardConfig.config.productionData.innerFlex != null ? cardConfig.config.productionData.innerFlex : '50'"
            class="box-container">
            <div class="box" fxLayout="row" [ngClass]="item.class">
              <div class="icon-box">
                <ff-icon [icon]="item.icon" class="icon"></ff-icon>
              </div>
              <div fxLayout="column">
                <div class="title">{{ filterService.translateElementNewFormat(item) }}</div>
                <div class="value">
                  <span [ngClass]="item.classes">
                    {{ machineData[item.variable] | placeholder }}
                  </span>
                  <!-- <span *ngIf="item.suffix != null">
                    &nbsp;{{ filterService.convertUnit(item.suffix).unit | translate }}
                  </span> -->
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <!-- consumablesData -->
    <div class="box-container" fxLayout="column" *ngIf="cardConfig.config.consumablesData"
      [fxFlex]="cardConfig.config.consumablesData.flex" [fxFlexOrder]="cardConfig.config.consumablesData.order">
      <div class="box">
        <div class="title-medium" [ngClass]="{'underline': cardConfig.config?.underlineTitles}">
          <span>
            {{ (cardConfig.config.consumablesData?.label ?? 'GLOBAL.CONSUMABLES') | translate }}&nbsp;
          </span>
          <span *ngIf="interval != null && cardConfig.config.consumablesData.showInterval" class="subtitle">
            ({{ interval.label | translate }})
          </span>
        </div>
        <!-- loading -->
        <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="loading h100 w100">
          <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
            <ff-loading-card></ff-loading-card>
          </div>
        </div>
        <!-- content -->
        <div class="machineDatas h100" *ngIf="machineData.profile?.consumables?.length > 0" fxLayout="column"
          style="overflow: auto;" fxLayout.gt-sm="row wrap" fxLayoutAlign="start stretch" [ngStyle]="{
            'display': machineData.loading ? 'none' : 'flex',
            'min-height': cardConfig.config.consumablesData?.minHeight != null ? cardConfig.config.consumablesData.minHeight : '310px',
            'height': cardConfig.config.consumablesData?.height != null ? cardConfig.config.consumablesData.height : '310px'
          }">

          <div *ngFor="let item of machineData.profile?.consumables"
            [fxFlex]="cardConfig.config.consumablesData.innerFlex ?? 100" class="box-container" fxLayout.gt-sm="row"
            fxLayoutGap="8px" fxLayout="column">
            <div fxFlex="50" class="box" fxLayout="row" [ngClass]="item.class">
              <div class="icon-box">

                <ff-icon [icon]="item.icon" class="icon"></ff-icon>

                <!-- <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                  {{item.icon.icon }}</mat-icon>
                <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                  translate-attr="{'aria-label': item.label}"></mat-icon>
                <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                  class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i> -->

              </div>
              <div fxLayout="column">
                <div class="title">{{ filterService.translateElementNewFormat(item) }}</div>
                <div class="value">
                  <span [ngClass]="item.classes">
                    {{ machineData[item.id] == null ? '-' :
                    filterService.parseGaugeValue(machineData[item.id], item.decimals ?? 0, item.multiplier ?? 1) |
                    placeholder }}
                  </span>
                  <span *ngIf="item.unit != null">&nbsp;{{ item.unit | translate }}</span>
                </div>
              </div>
            </div>
            <div fxFlex="50" class="box" fxLayout="row" [ngClass]="item.class">
              <div class="icon-box">

                <!-- <ff-icon [icon]="item.icon"></ff-icon> -->

                <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                  {{item.icon.icon }}</mat-icon>
                <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                  translate-attr="{'aria-label': item.label}"></mat-icon>
                <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                  class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>

              </div>
              <div fxLayout="column">
                <div class="title" translate="GLOBAL.CONS_PER_UNIT" [translateParams]="{
                  cons: (item.label | translate),
                  unit: filterService.getProductionUnit('singleUnit', machineData.profile.productionConfig)
                }">
                </div>
                <div class="value">
                  <span [ngClass]="item.classes">{{ machineData[item.id + 'PerUnit'] == null ? '-' :
                    filterService.parseGaugeValue(machineData[item.id + 'PerUnit'], item.relativeDecimals != null ?
                    item.relativeDecimals : 2, item.relativeMultiplier != null ? item.relativeMultiplier : 1) |
                    placeholder }}</span>
                  <span>
                    {{ (item.relativeUnit != null ? item.relativeUnit : (item.unit != null ? item.unit : '-')) |
                    translate }}/{{ filterService.getProductionUnit('singleUnit',
                    machineData.profile.productionConfig) }}
                  </span>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <!-- single aggr List -->
    <div class="box-container" fxLayout="column" *ngIf="cardConfig.config.aggrList && machineData.aggrList != null && 
      machineData.aggrList.length > 0 && cardConfig.aggrList != null && cardConfig.aggrList.length > 0"
      fxFlex="{{cardConfig.config.aggrList.flex}}" fxFlexOrder="{{cardConfig.config.aggrList.order}}">
      <div class="box">
        <div class="title-medium" [ngClass]="{'underline': cardConfig.config?.underlineTitles}">
          <span>{{ (cardConfig.config.aggrList.title != null ? cardConfig.config.aggrList.title :
            'HOMEPAGE.AGGR_LIST_DATA') | translate }}&nbsp;</span>
          <span *ngIf="interval != null && cardConfig.config.aggrList.showInterval" class="subtitle">({{ interval.label
            | translate }})</span>
        </div>
        <!-- loading -->
        <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="loading h100 w100">
          <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
            <ff-loading-card></ff-loading-card>
          </div>
        </div>
        <!-- content -->
        <div class="machineDatas h100" fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="start stretch"
          [ngStyle]="{'display': machineData.loading ? 'none' : 'flex'}" style="margin-top: 8px;">

          <div [fxFlex]="cardConfig.config.aggrList.innerFlex != null ? cardConfig.config.aggrList.innerFlex : '100'"
            class="box-container aggrTable">

            <table>
              <thead>
                <tr>
                  <th class="col-icon-lg">
                  <th *ngFor="let info of cardConfig.aggrList">
                    <span>{{ info.label | translate }}</span>
                    <span *ngIf="info.unit != null && info.unitInTitle" style="text-transform:none">
                      [{{ filterService.convertUnit(info.unit).unit }}]
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of machineData.aggrList">
                  <td class="col-icon-lg">
                    <mat-icon class="icon">local_offer</mat-icon>
                  </td>
                  <td md-cell *ngFor="let info of cardConfig.aggrList" [ngClass]="{'primary' : info.primary}">
                    <span class="break-word">{{item[info.variable] | placeholder:'-'}}</span>
                    <span *ngIf="info.unit != null && !info.unitInTitle">&nbsp;{{
                      filterService.convertUnit(info.unit).unit }}</span>
                  </td>
                </tr>
                <!-- <tr *ngIf="machineData.moreElementsThanShowed">
                  <td class="col-icon-lg"></td>
                  <td md-cell *ngFor="let info of cardConfig.aggrList">
                    <span class="break-word">...</span>
                  </td>
                </tr> -->
              </tbody>
            </table>

          </div>

        </div>
      </div>
    </div>

    <!-- multiple aggr Lists -->
    <ng-container *ngIf="cardConfig.config.aggrLists && cardConfig.aggrLists != null && machineData.aggrLists != null">
      <div class="box-container" fxLayout="column" *ngFor="let aggrList of cardConfig.config.aggrLists"
        [fxFlex]="aggrList.flex" [fxFlexOrder]="aggrList.order">

        <div class="box">
          <div class="title-medium" [ngClass]="{'underline': cardConfig.config?.underlineTitles}">
            <span>{{ (aggrList.title != null ? aggrList.title : 'HOMEPAGE.AGGR_LIST_DATA') | translate }}&nbsp;</span>
            <span *ngIf="interval != null && aggrList.showInterval" class="subtitle">({{ interval.label | translate
              }})</span>
          </div>
          <!-- loading -->
          <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="loading h100 w100">
            <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
              <ff-loading-card></ff-loading-card>
            </div>
          </div>
          <!-- content -->
          <div class="machineDatas h100" fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="start stretch"
            [ngStyle]="{'display': machineData.loading ? 'none' : 'flex'}" style="margin-top: 8px;">

            <div [fxFlex]="aggrList.innerFlex != null ? aggrList.innerFlex : '100'" class="box-container aggrTable"
              [ngStyle]="{'min-height': aggrList.minHeight}">

              <table>
                <thead>
                  <tr>
                    <th class="col-icon-lg">
                    <th *ngFor="let info of cardConfig.aggrLists[aggrList.aggr]">
                      <span>{{ info.label | translate }}</span>
                      <span *ngIf="info.unit != null && info.unitInTitle" style="text-transform:none">
                        [{{ filterService.convertUnit(info.unit).unit }}]
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of machineData.aggrLists[aggrList.aggr]">
                    <td class="col-icon-lg">
                      <ff-icon *ngIf="aggrList.icon != null" [icon]="aggrList.icon"></ff-icon>
                      <mat-icon *ngIf="aggrList.icon == null" class="icon">local_offer</mat-icon>
                    </td>

                    <!-- <td md-cell *ngFor="let info of cardConfig.aggrLists[aggrList.aggr]"
                      [ngClass]="{'primary' : info.primary}">
                      <span class="break-word">{{ (item[info.variable] != null ? 
                          filterService.parseGaugeValue(item[info.variable], info.decimals != null ? info.decimals : 0, info.multiplier != null ? info.multiplier : 1)
                          : null) | placeholder:'-' }}</span>
                      <span *ngIf="info.unit != null && !info.unitInTitle">&nbsp;{{ info.unit }}</span>
                    </td> -->

                    <td md-cell *ngFor="let info of cardConfig.aggrLists[aggrList.aggr]"
                      [ngClass]="{'primary' : info.primary}">
                      <ff-single-cell [item]="item" [info]="info" [profile]="machineData.profile"
                        [machineId]="machineData.machineId">
                      </ff-single-cell>
                    </td>

                  </tr>
                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>
    </ng-container>

    <!-- submachines -->
    <ng-container
      *ngIf="cardConfig.config.subMachines != null && cardConfig.subMachinesInfos?.length 
    && machineData[cardConfig.config.subMachines?.key != null ? cardConfig.config.subMachines?.key : 'subMachines'] != null">
      <div class="box-container" fxLayout="column" fxFlex="{{cardConfig.config.subMachines.flex}}"
        fxFlexOrder="{{cardConfig.config.subMachines.order}}">
        <div class="box">
          <div class="title-medium" [ngClass]="{'underline': cardConfig.config?.underlineTitles}">
            <span>{{ (cardConfig.config.subMachines.title != null ? cardConfig.config.subMachines.title :
              'HOMEPAGE.SUBMACHINES') | translate }}&nbsp;</span>
            <span *ngIf="interval != null && cardConfig.config.subMachines.showInterval" class="subtitle">({{
              interval.label | translate }})</span>
          </div>
          <!-- loading -->
          <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="loading h100 w100">
            <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
              <ff-loading-card></ff-loading-card>
            </div>
          </div>
          <!-- content -->
          <div class="machineDatas h100" fxLayout="row wrap" fxLayoutAlign="start stretch"
            [fxLayoutGap]="cardConfig.config.subMachines?.gap != null ? cardConfig.config.subMachines?.gap : '8px'"
            [ngStyle]="{'display': machineData.loading ? 'none' : 'flex'}">
            <div
              *ngFor="let subMachine of machineData[cardConfig.config.subMachines?.key != null ? cardConfig.config.subMachines?.key : 'subMachines']"
              [fxFlex]="cardConfig.config.subMachines.innerFlex != null 
                ? 'calc(' + cardConfig.config.subMachines.innerFlex + '% - ' + (cardConfig.config.subMachines?.gap != null ? cardConfig.config.subMachines?.gap : '8px') + ')'
                : 'calc(' + 100/(machineData[cardConfig.config.subMachines?.key != null ? cardConfig.config.subMachines?.key : 'subMachines'].length) + '% -' + (cardConfig.config.subMachines?.gap != null ? cardConfig.config.subMachines?.gap : '8px')"
              class="box-container aggrTable"
              [ngStyle]="{'min-height': cardConfig.config.subMachines.minHeight, 
              'margin-bottom': (cardConfig.config.subMachines?.gap != null ? cardConfig.config.subMachines?.gap : '8px'),
              'max-width': cardConfig.config.subMachines.innerFlex != null 
                ? 'calc(' + cardConfig.config.subMachines.innerFlex + '% - ' + (cardConfig.config.subMachines?.gap != null ? cardConfig.config.subMachines?.gap : '8px') + ')'
                : 'calc(' + 100/(machineData[cardConfig.config.subMachines?.key != null ? cardConfig.config.subMachines?.key : 'subMachines'].length) + '% -' + (cardConfig.config.subMachines?.gap != null ? cardConfig.config.subMachines?.gap : '8px')}">

              <div class="homepage-card sub-machine-title">

                <span>
                  {{ subMachine[cardConfig.config.subMachines.cardTitleVariable != null ?
                  cardConfig.config.subMachines.cardTitleVariable : 'id'] }}
                </span>

                <span fxFlex></span>

                <ff-single-cell *ngIf="cardConfig.config.subMachines?.stateIconIndicator" [item]="subMachine"
                  [info]="cardConfig.config.subMachines?.stateIconIndicator" [profile]="machineData.profile">
                </ff-single-cell>

              </div>
              <table>
                <tbody>
                  <tr *ngFor="let info of cardConfig.subMachinesInfos">


                    <td md-cell [ngClass]="{'primary' : info.primary}">

                      <span>{{ info.label | translate }}</span>
                      <span *ngIf="info.unit != null && info.unitInTitle" style="text-transform: none;">
                        [{{ filterService.convertUnit(info.unit).unit }}]
                      </span>

                    </td>

                    <td md-cell [ngClass]="{'primary' : info.primary}">
                      <ff-single-cell [item]="subMachine" [info]="info" [profile]="machineData.profile">
                      </ff-single-cell>
                    </td>

                  </tr>
                </tbody>
              </table>

            </div>

          </div>
        </div>
      </div>
    </ng-container>

    <!-- productivity -->
    <div class="box-container" fxLayout="column" *ngIf="cardConfig.config.productivity"
      [fxFlex]="cardConfig.config.productivity.flex" [fxFlexOrder]="cardConfig.config.productivity.order">
      <div class="box">
        <div class="title-medium" [ngClass]="{'underline': cardConfig.config?.underlineTitles}">

          <span>
            {{ (cardConfig.config.productivity?.label ?? 'HOMEPAGE.PRODUCTIVITY') | translate }}&nbsp;
          </span>

          <span *ngIf="interval != null && cardConfig.config.productivity.showInterval" class="subtitle">
            &nbsp;({{ interval.label | translate }})
          </span>

        </div>
        <!-- loading -->
        <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="loading h100 w100">
          <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
            <ff-loading-card></ff-loading-card>
          </div>
        </div>
        <!-- content -->
        <div fxLayout="column" fxLayoutAlign="space-around stretch"
          fxLayout.gt-sm="{{ cardConfig.config.productivity.flexDirection || 'column' }}"
          fxLayoutAlign.gt-sm="{{ cardConfig.config.productivity.flexAlign || 'space-around stretch' }}"
          class="box w100 h100" [ngStyle]="{'display': machineData.loading ? 'none' : 'flex'}"
          *ngIf="cardConfig.productivity">

          <ng-container *ngIf="!cardConfig.config.productivity.gaugeAsBar">
            <div fxLayout="row" fxLayoutAlign="center center">
              <ff-gauge fxFlex *ngFor="let item of cardConfig.productivity.gauges"
                [gaugeData]="{'label': item.label, 'value': machineData[item.variable]}">
              </ff-gauge>
            </div>
          </ng-container>

          <span *ngIf="cardConfig.config.productivity.distanceBetweenGaugesAndBars"
            [style.margin-top]="cardConfig.config.productivity.distanceBetweenGaugesAndBars">
          </span>

          <div fxFlex fxLayout="column" fxLayoutAlign="center stretch">

            <ng-container *ngIf="cardConfig.config.productivity.gaugeAsBar">
              <kpi-bar *ngFor="let item of cardConfig.productivity.gauges" class="more-margin"
                [kpiProps]="{ 'value': machineData[item.variable], 'label': item.label, 'translate': true, 'style': item.style, 'bigger': true }">
              </kpi-bar>
            </ng-container>

            <kpi-bar *ngFor="let item of cardConfig.productivity.kpibars | filterByProperty:['grid', '!=', false]"
              [kpiProps]="{ 'value': machineData[item.variable], 'label': item.label, 'translate': true, 'style': item.style }">
            </kpi-bar>

          </div>

          <div *ngIf="cardConfig.config.consumablesData != null" fxLayout="column" fxLayoutAlign="start stretch"
            style="overflow: auto; max-height: 120px; min-height: 120px; height: 120px;">
            <kpi-bar *ngFor="let item of machineData.profile.consumables; let index = index"
              [kpiProps]="{ 'value': machineData[item.id + 'SI'], 'label': item.label, 'translate': true, 'style': (index == 0 ? 'margin-top: 14px;' : '') }">
            </kpi-bar>
          </div>

        </div>
      </div>
    </div>

    <!-- productionOrder -->
    <div class="box-container" fxLayout="column" *ngIf="cardConfig.config.productionOrder"
      fxFlex="{{cardConfig.config.productionOrder.flex}}" fxFlexOrder="{{cardConfig.config.productionOrder.order}}">
      <div class="box">
        <p class="title" fxLayout="row" fxLayoutAlign="start center">
          <span>{{'HOMEPAGE.PRODUCTION_ORDER'|translate}}</span>
          <span *ngIf="cardConfig.config.productionOrder.isActiveFlag" style="margin-left: 8px;" #tooltip="matTooltip"
            matTooltip="{{ (machineData.isActive == 1 ? 'HOMEPAGE.ACTIVE_JOB' : 'HOMEPAGE.NO_ACTIVE_JOB') | translate }}"
            matTooltipPosition="above">
            <mat-icon class="icon {{ machineData.isActive == 1 ? 'md-green' : 'md-gray' }}">lens</mat-icon>
          </span>
        </p>
        <!-- loading -->
        <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="loading h100 w100">
          <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
            <ff-loading-card></ff-loading-card>
          </div>
        </div>
        <!-- content -->
        <div class="machineDatas h100" *ngIf="cardConfig.productionOrder != null" fxLayout="column"
          fxLayout.gt-md="row wrap" [ngStyle]="{'display': machineData.loading ? 'none' : 'flex'}">

          <div *ngFor="let item of cardConfig.productionOrder" [fxFlex]="prodOrderColumns" class="box-container">
            <div class="box" fxLayout="row">
              <div class="icon-box">
                <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                  {{item.icon.icon }}</mat-icon>
                <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                  translate-attr="{'aria-label': item.label}"></mat-icon>
                <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                  class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
              </div>
              <div fxLayout="column">
                <div class="title">{{item.label|translate}}</div>
                <div fxLayout="row">
                  <div class="value">
                    {{machineData[item.variable] != null ? machineData[item.variable] : '-' }}
                    <span *ngIf="item.suffix != null">&nbsp;{{filterService.convertUnit(item.suffix).unit}}</span>
                  </div>
                  <!-- <div *ngIf="item.isTime" class="value">{{machineData[item.variable]}}</div> -->
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="!cardConfig.config.productionOrder.hideProdToProd" fxFlex="100" class="box-container">
            <div class="box w100" fxLayout="row">
              <kpi-bar class="w100"
                [kpiProps]="{ 'value': machineData.prodPerc, 'label': 'HOMEPAGE.PROGRESS', 'style': 'margin-top: 24px;', 'translate': true }">
              </kpi-bar>
            </div>
          </div>

        </div>
      </div>
    </div>

    <!-- materials -->
    <div class="box-container" fxLayout="column" *ngIf="cardConfig.config.materials"
      fxFlex="{{cardConfig.config.materials.flex}}" fxFlexOrder="{{cardConfig.config.materials.order}}">
      <div class="box">
        <p class="title">
          <span>Materials</span>
        </p>
        <!-- loading -->
        <div [ngStyle]="{'display': !machineData.loading ? 'none' : 'inherit'}" class="loading h100 w100">
          <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
            <ff-loading-card></ff-loading-card>
          </div>
        </div>
        <!-- content -->
        <div class="machineDatas h100" *ngIf="cardConfig.materials != null" fxLayout="column" fxLayout.gt-md="row wrap"
          [ngStyle]="{'display': machineData.loading ? 'none' : 'flex'}">

          <div *ngFor="let item of cardConfig.materials" fxFlex="50" class="box-container">
            <div class="box" fxLayout="row">
              <div class="icon-box">
                <mat-icon *ngIf="item.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': item.label}">
                  {{item.icon.icon }}</mat-icon>
                <mat-icon *ngIf="item.icon.type == 'svg'" svgIcon="{{ item.icon.icon }}" class="icon"
                  translate-attr="{'aria-label': item.label}"></mat-icon>
                <i *ngIf="item.icon.type == 'fontawesome' || item.icon.type == 'phosphor'"
                  class="icon {{ item.icon.icon }}" translate-attr="{'aria-label': item.label}"></i>
              </div>
              <div fxLayout="column">
                <div class="title">{{item.label|translate}}</div>
                <div fxLayout="row">
                  <div *ngIf="!item.isTime" class="value">
                    {{machineData[item.variable] != null ? machineData[item.variable] : '-' }}</div>
                  <div *ngIf="item.isTime" class="value">{{machineData[item.variable + 'P']}}</div>
                  <span *ngIf="item.suffix != null">&nbsp;{{ filterService.convertUnit(item.suffix).unit }}</span>
                </div>
              </div>
            </div>
          </div>

          <div fxFlex="100">
            <div class="box2 w100" fxLayout="row">
              <kpi-bar class="w100"
                [kpiProps]="{ 'value': machineData.performance, 'label': 'Prod / To Prod', 'style': 'margin-top: 24px;' }">
              </kpi-bar>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>

</mat-card>