<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" aria-label="close">close</mat-icon>
  </button>
</div>
<mat-dialog-content>

  <!-- <pre>{{dialog.config|json}}</pre> -->

  <div>

    <div class="max-limit-warning" *ngIf="maxItemsSelectable != null">
      <span translate="GLOBAL.MAXIMUM_SELECTABLE_ITEMS" [translateParams]="{n: maxItemsSelectable}"></span>
    </div>

    <div *ngIf="dialog.config?.addSearchFilter" class="search-input" fxLayout="row" fxLayoutAlign="start center"
      style="border: 1px solid #eaecee; margin-top: 12px">
      <mat-icon class="icon">search</mat-icon>
      <input type="text" class="w100" (keyup)="searchFromList($event)" placeholder="{{'GLOBAL.SEARCH'|translate}}">
    </div>

    <ul *ngIf="dialog.options != null && dialog.options.length > 1 && !availableOptions?.length">
      <li *ngIf="dialog.config == null || !dialog.config.hideSelAll" fxLayout="row" fxLayoutAlign="start center"
        class="clickable" (click)="setAll(allSelected)">
        <mat-icon *ngIf="allSelected" svgIcon="radio_button" class="icon left radio_button"></mat-icon>
        <mat-icon *ngIf="!allSelected" svgIcon="radio_button_disabled" class="icon left radio_button_disabled">
        </mat-icon>
        <span *ngIf="allSelected">{{ 'GLOBAL.UNSELECT_ALL' | translate }}</span>
        <span *ngIf="!allSelected && maxItemsSelectable == null">{{ 'GLOBAL.SELECT_ALL' | translate }}</span>
        <span *ngIf="!allSelected && maxItemsSelectable != null" translate="GLOBAL.SELECT_PARTIAL_NUMBER"
          [translateParams]="{n: maxItemsSelectable}"></span>
      </li>
    </ul>

    <ul>
      <li *ngFor="let item of dialog.options | customSearchFilter: availableOptions" fxLayout="row"
        fxLayoutAlign="start center" class="clickable" (click)="updateAllSelected(item)"
        [ngClass]="{'clickable': !item.disabled, 'disabled': item.disabled }">

        <!-- selected icon -->
        <mat-icon *ngIf="item.selected" svgIcon="radio_button" class="icon left radio_button" [ngClass]="item.class">
        </mat-icon>
        <mat-icon *ngIf="!item.selected" svgIcon="radio_button_disabled" class="icon left radio_button_disabled">
        </mat-icon>

        <!-- label -->
        <span fxFlex [class.italic]="item.id == null || item.id == ''">{{item.label}}</span>

        <!-- circle colored -->
        <mat-icon class="icon" *ngIf="item.color" [ngStyle]="{ 'color': item.color }">circle</mat-icon>

        <!-- custom icon -->
        <ff-icon [icon]="item.icon"></ff-icon>

        <mat-icon *ngIf="item.warning" class="icon md-orange" #tooltip="matTooltip" matTooltip="{{ item.warning }}">
          warning
        </mat-icon>

      </li>

      <li *ngIf="!(dialog.options | customSearchFilter: availableOptions)?.length" fxLayout="row"
        fxLayoutAlign="center center">

        <!-- label -->
        <span fxFlex>
          {{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}
        </span>

      </li>
    </ul>

  </div>
  <div fxLayout="row" fxLayoutAlign="end end">
    <button mat-button [mat-dialog-close]="dialog" [disabled]="!atLeastOneSelected()" class="filter-button">
      {{'GLOBAL.SELECT'|translate}}
    </button>
  </div>

</mat-dialog-content>