import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ClonerService } from 'src/app/services/clone.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'kpi-bar',
  templateUrl: './html-bar.component.html',
  styleUrls: ['./html-bar.component.scss']
})
export class HtmlBarComponent implements OnInit, OnChanges {

  @Input() kpiProps: any;

  // kpiVal: number;
  constructor(
    private commonFunctionsService: InternalDataService,
    private filterService: FiltersService,
    private translate: TranslateService,
    private clonerService: ClonerService,
  ) { }

  public customStyle: any = {};

  ngOnInit(): void {
    this.buildKPIBar();
  }

  ngOnChanges(): void {
    this.buildKPIBar();
  }

  buildKPIBar() {
    try {
      if (this.kpiProps.hasOwnProperty('value') && typeof this.kpiProps.value == 'number') {

        let value: any = this.clonerService.deepClone(this.kpiProps.value);
        this.kpiProps.value = this.filterService.parseGaugeValue(this.kpiProps.value, 0, 100);

        if (this.kpiProps?.options != null) {
          if (this.kpiProps.options.showLowerThan100 && value > 1) {
            this.kpiProps.value = ">100"
          }
        }

        if (this.kpiProps.fixedColor != null) this.kpiProps.color = this.kpiProps.fixedColor;
        else {
          this.kpiProps.color = this.kpiProps.type == 'kpi' ?
            this.commonFunctionsService.getKPIBarColor(this.kpiProps.label) :
            this.commonFunctionsService.getBarColor(this.kpiProps.value, 1, this.kpiProps.revert);
        }
      }
      if (this.kpiProps.hasOwnProperty('style') && this.kpiProps.style != null) {
        try {
          this.kpiProps.style.split(";").forEach((element: any) => {
            this.customStyle[element.split(":")[0]] = element.split(":")[1];
          });
        } catch (error) {
          console.log(error);
        }
      }
      if (this.kpiProps.translate) this.kpiProps.label = this.translate.instant(this.kpiProps.label);
    } catch (error) { console.log(error) }

  }
}
