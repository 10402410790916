import { Component, Input, OnInit } from '@angular/core';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'ff-info-gauge',
  templateUrl: './info-gauge.component.html',
  styleUrls: ['./info-gauge.component.scss']
})

export class InfoGaugeComponent implements OnInit {

  @Input() gaugeData: any;
  @Input() widget: any;

  public infos: any[] = [];

  public valueLabel: any;
  public valueLabelTooltip: any;
  public showInfo: any;

  public height: number = 125;
  public width: number = 125;
  public viewbox: string = '0 0 ' + this.height + ' ' + this.width;
  public strokeWidth: number = 8;
  public strokeFill: string = "transparent";
  public color: string = "transparent";
  public cx: number = this.height / 2;
  public cy: number = this.width / 2;
  public dy: any;
  public r: number = (this.height - this.strokeWidth) / 2;
  public cxBack: number = this.height / 2;
  public cyBack: number = this.width / 2;
  public rBack: number = (this.height - this.strokeWidth) / 2;
  public linecap: string = "round";
  public percentageFillCircle: number = 1;
  public strokeDasharray: string = "";
  public strokeDasharrayBack: string = "";
  public filterBackground: string = "";

  public regexConfig: any = null;
  public unit: any;
  public unitSliderConf: any;

  constructor(
    private filtersService: FiltersService,
    private translate: FfTranslateService,
    private clonerService: ClonerService
  ) {

    this.unitSliderConf = {
      checked: false
    }

  }

  ngOnInit(): void {
    // regex config for MFL (es. "metersReel{{takeUpId}}.v")
    this.regexConfig = this.widget?.config?.regexConfig;

    this.parseProdInfos();
    this.parseGaugeData();
    // console.log('init', this.widget.data)

  }

  ngOnChanges(): void {
    // console.log('changes1', this.widget.data)

    let checked = this.unitSliderConf.checked
    this.widget.convertedUnits = this.widget.units.map(unit => {
      let unitObj = this.filtersService.convertUnit(unit)
      return unitObj.unit
    })
    if (this.widget.units != null) this.unit = this.widget.units[+checked]

    this.parseProdInfos();
    this.parseGaugeData();

    // console.log('changes2', this.widget)

  }

  parseGaugeData() {

    if (this.widget != null) {
      try {
        let act = this.unit ? this.widget.config.infoConfig.act.filter((x: any) => x.suffix == this.unit).map((x: any) => x.variable)[0] : this.widget.config.infoConfig.act[0].variable;
        let a = this.widget.data[act];

        let set = this.unit ? this.widget.config.infoConfig.set.filter((x: any) => x.suffix == this.unit).map((x: any) => x.variable)[0] : this.widget.config.infoConfig.set[0].variable;
        let s = this.widget.data[set];

        if (typeof a == 'string') a = this.filtersService.parseNumber(a)
        if (typeof s == 'string') s = this.filtersService.parseNumber(a)

        this.widget.config.gaugeConfig.value = (a / s)

      } catch (error) { }

    }

    let gData = this.widget != null ? this.widget.config.gaugeConfig : this.gaugeData;

    if (this.regexConfig != null) this.parseRegex(this.regexConfig, gData, this.widget.data);

    this.valueLabel = gData?.valueLabel ?? this.widget?.data?.[gData?.variable] ?? '';

    if (gData?.maxLabelLength != null && gData?.maxLabelLength > 0 && this.valueLabel?.length > gData?.maxLabelLength) {
      this.valueLabelTooltip = this.valueLabel;
      this.valueLabel = this.valueLabel.substring(0, gData?.maxLabelLength) + '...';
    }

    this.showInfo = gData.showInfo;

    this.gaugeData = gData;

    if (this.gaugeData != null) {

      let setPlaceholder = this.gaugeData.value == null;

      this.gaugeData.value = this.gaugeData.value != null ? this.gaugeData.value : 0;

      // height
      this.gaugeData.height != null ?
        this.height = this.gaugeData.height :
        this.height = 125;
      // width
      this.width = this.height;
      // viewbox
      this.viewbox = '0 0 ' + this.height + ' ' + this.width;
      // dy
      if (this.gaugeData.dy != null) this.dy = this.gaugeData.dy

      // strokeWidth
      this.gaugeData.strokeWidth != null ?
        this.strokeWidth = this.gaugeData.strokeWidth :
        this.strokeWidth = 8;
      // strokeFill
      this.gaugeData.strokeFill != null ?
        this.strokeFill = this.gaugeData.strokeFill :
        this.strokeFill = "white";
      // color
      this.gaugeData.color != null ?
        this.color = this.gaugeData.color :
        this.color = "gray";
      // linecap
      this.linecap = "round";

      // cx, cy, r
      this.cx = this.height / 2;
      this.cy = this.width / 2;
      this.r = (this.height - this.strokeWidth) / 2;
      // cxBack, cyBack, rBack
      this.cxBack = this.height / 2;
      this.cyBack = this.width / 2;
      this.rBack = ((this.height - this.strokeWidth) / 2) - (this.strokeWidth / 2);

      // percentageFillCircle
      this.gaugeData.percentageFillCircle != null ?
        this.percentageFillCircle = this.gaugeData.percentageFillCircle :
        this.percentageFillCircle = 1;

      // filterBackground
      this.gaugeData.filterBackground != null ?
        this.filterBackground = this.gaugeData.filterBackground :
        this.filterBackground = "";

      let multiplier = gData?.multiplier ?? 100;

      let decimals = gData?.decimals ?? 0;

      let value: any = this.gaugeData.value

      // color
      this.color = this.getBarColor(!setPlaceholder ? (value * multiplier) : -1);

      // parse value
      this.gaugeData.valueStr = !setPlaceholder ? (this.filtersService.parseGaugeValue(value, decimals, multiplier) + '%') : '-';
      // strokeDasharray
      this.strokeDasharray = this.getStrokeDasharray(this.r, (value > 1 ? 1 : value), multiplier);
      // strokeDasharrayBack
      this.strokeDasharrayBack = this.getStrokeDasharray(this.r, 100);

    }

  }

  parseProdInfos() {
    let infos = this.widget != null ? this.widget.config.infoConfig : this.infos;

    let infosArray = [];

    let keysToCheck = [
      { variable: "act", checkUnit: true },
      { variable: "set", checkUnit: true },
      { variable: "default", checkUnit: false },
    ];

    // Check the unit only for set and default, remaining time is fixed
    keysToCheck.forEach(k => {
      infos?.[k?.variable]?.forEach((x: any) => {
        if (this.regexConfig != null) this.parseRegex(this.regexConfig, x, this.widget.data);
        if (this.unit == null || (x.suffix == this.unit) || !k.checkUnit) infosArray.push(x);
      });
    })

    this.infos = infosArray;

  }

  parseRegex(regexConfig, variableConfig, data) {

    try {
      if (variableConfig.variable != null) {

        // Case array
        if (Array.isArray(variableConfig.variable) && variableConfig.variable.length > 0) {
          variableConfig.variable = variableConfig.variable.reduce((acc, val) => {
            let obj = val.replaceAll("{{" + regexConfig.variable + "}}", data[regexConfig.monitoringVariable])
            acc.push(obj);
            return acc;
          }, []);
        }

        // Case single value
        else {
          variableConfig.variable = variableConfig.variable.replaceAll("{{" + regexConfig.variable + "}}", data[regexConfig.monitoringVariable])
        }
      }
    }
    catch (error) { console.log(error) }
  }


  toggleUnits() {
    this.unitSliderConf.checked = !this.unitSliderConf.checked
    this.unit = this.widget.units[+this.unitSliderConf.checked]
    this.parseProdInfos()
  }

  getStrokeDasharray(r: number, value?: number, multiplier?: number) {
    let circumference = r * 2 * Math.PI;
    if (value != null) {
      if (multiplier == null) multiplier = 1;
      return (circumference * this.percentageFillCircle * value * multiplier / 100) + ' ' + (circumference);
    } else {
      return (circumference * this.percentageFillCircle) + ' ' + (circumference);
    }
  }

  // TODO move to another service
  // TODO colors as param by config
  getBarColor(value: number): string {
    if (this.widget.config != null && this.widget.config.gaugeConfig != null &&
      this.widget.config.gaugeConfig.hasOwnProperty('fixedColor') && this.widget.config.gaugeConfig.fixedColor) {
      return this.widget.config.gaugeConfig.fixedColor
    } else {
      if (value == -1) {
        return 'transparent';
      }
      if (value >= 0 && value < 10) {
        return '#FF5757';
      } else if (value >= 10 && value < 20) {
        return '#FF7F57';
      } else if (value >= 20 && value < 30) {
        return '#FAA63E';
      } else if (value >= 30 && value < 40) {
        return '#FAC63E';
      } else if (value >= 40 && value < 50) {
        return '#FADC3E';
      } else if (value >= 50 && value < 60) {
        return '#FAF43E';
      } else if (value >= 60 && value < 70) {
        return '#D2FA3E';
      } else if (value >= 70 && value < 80) {
        return '#C5EB5C';
      } else if (value >= 80 && value < 90) {
        return '#8BE362';
      } else if (value >= 90 && value <= 100) {
        return '#62E39D';
      } else {
        return '#40a4f4';
      }
    }
  }

}
