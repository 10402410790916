import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-efficiency-loss',
  templateUrl: './efficiency-loss.component.html',
  styleUrls: ['./efficiency-loss.component.scss']
})
export class EfficiencyLossComponent implements OnInit {

  public isAllowedUser: boolean = true;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public isMobile: any;
  public machineProfiles: any;

  public backButton: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public pollingTime: any;
  public pollingEvents: any;

  public maxStringsLength: any;
  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;

  public excludeLine: boolean = true;

  public interval: any;
  public intervalConfig: any;

  public dashboardConfig: any;

  public effLossData: any;
  public effLossCodes: any;
  public effLossColors: any;

  public constLosses: any;

  public sectionName: any = "leanAnalytics";
  public tabName: any = "efficiencyLoss"

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 4;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getEventsPolling, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];


  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
  ) {

    this.isMobile = window.innerWidth <= 959;

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['LEAN_ANALYTICS.TITLE', 'EFFICIENCY_LOSS.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.effLossColors = this.appConfig.efficiencyLoss?.effLossTypes
    this.constLosses = this.appConfig.efficiencyLoss?.constLosses

  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) { this.isMobile = window.innerWidth <= 959 }

  ngOnInit(): void {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);
  }

 openAggrDialog(aggr: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {

        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
      }

      if (this.appConfig?.[this.tabName] != null && this.appConfig[this.tabName].hasOwnProperty("sessionFilters")) {
        let sessionFilters: any = this.clonerService.deepClone(this.aggregations)
        this.cacheService.set('sessionFilters', sessionFilters)
      }

      this.pageState.next(isClickedSelect ? 4 : 5);

      if (!this.interval.enabledPolling) {
        this.getEvents(this, 0);
      } else {
        this.getEventsPolling(this);
      }

    });
  };

  changePageAggregation(aggrDropdown: any) {
    this.aggrDropdown = this.clonerService.deepClone(aggrDropdown);

    this.pageState.next(5);
    if (!this.interval.enabledPolling) {
      this.getEvents(this, 0);
    } else {
      this.getEvents(this);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-la-breakdowns");
    } catch (error) {
      console.log(error)
    }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({
          machineId: _this.machineId
        });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) {
    this.intervalService.selectInterval(this, interval, null, this.getEventsPolling, this.getEvents, this.machine.timezone, 3)
  };

  machineSelectionChange(machine: any) {

    if (machine != null) {

      try {
        this.pollingEvents.unsubscribe();
      } catch (error) {}

      this.pageState.next(5);
      this.getEvents(this);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // events

  // polling
  // getEventsEffLoss(_this: any) {
  //   try {

  //     if (_this.cacheService.get("intervalEffLoss") != null) {

  //       _this.interval = _this.cacheService.get("intervalEffLoss");

  //       _this.intervalConfig = {
  //         list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
  //         selected: _this.interval
  //       };

  //       _this.backButton = [_this.machineId, "machine-supervision", "state-timeline-advanced"];
  //       _this.internalDataService.setBackButton(_this.backButton);

  //     } else {
  //       if (_this.cacheService.get("intervalLong") == null) {

  //         _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

  //         _this.intervalConfig = {
  //           list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
  //           selected: _this.interval
  //         };

  //         _this.cacheService.set("intervalLong", _this.intervalConfig);

  //       } else {
  //         _this.intervalConfig = _this.cacheService.get("intervalLong");
  //         _this.interval = _this.cacheService.get("intervalLong").selected;
  //       }
  //     }

  //     if (_this.pollingTime > 0) {
  //       _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => {
  //         _this.getEvents(_this, count);
  //       });
  //     } else {
  //       _this.getEvents(_this, 0);
  //     }

  //   } catch (error) {
  //     let errorData = {
  //       type: 0,
  //       status: 500,
  //       message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
  //     };
  //     _this.dispatcherService.getDispatch(_this, 301, errorData);
  //   }
  // }

  // get events
  getEvents(_this: any, count ? : any) {
    if (_this.cacheService.get("intervalBreakdowns") != null) {

      _this.interval = _this.cacheService.get("intervalBreakdowns");

      _this.intervalConfig = {
        list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
        selected: _this.interval
      };

      _this.backButton = [_this.machineId, "machine-supervision", "state-timeline-advanced"];
      _this.internalDataService.setBackButton(_this.backButton);
    } else {
      if (_this.cacheService.get("intervalLong") == null) {

        _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.cacheService.set("intervalLong", _this.intervalConfig);

      } else {
        _this.intervalConfig = _this.cacheService.get("intervalLong");
        _this.interval = _this.cacheService.get("intervalLong").selected;
      }
    }
  
    try {
      let url = '/apif/efficiency-loss/' + _this.machineId;

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      if (_this.appConfig?.[_this.tabName] != null && _this.appConfig[_this.tabName].hasOwnProperty("sessionFilters")) {
        let sessionFilters = _this.cacheService.get('sessionFilters')
        if(sessionFilters != null) _this.aggregations = sessionFilters
      }
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;
      payload.efficiencyLoss = _this.machine.profile?.efficiencyLoss

      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone,
        // aggrUnit: _this.aggrDropdown
      };

      if (_this.aggrDropdown != null) query.aggrUnit = _this.aggrDropdown;

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({
            maxRetryAttempts: 0
          })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data)
            
            _this.effLossData = _this.parseEffLossData(_this, data.body);

            try {
              try {
                if (_this.appConfig?.[_this.tabName]?.[_this.colorsArrayName] &&
                  _this.appConfig?.[_this.tabName]?.[_this.colorsArrayName]?.length > 0)
                  _this.effLossColors = _this.appConfig?.[_this.tabName]?.[_this.colorsArrayName];
              } catch (error) {
                console.log(error)
              }

              _this.effLossData.dataConfig = {};
              _this.effLossData.aggrDataConfig = {};

              // set plot data and configs
              _this.effLossData.dataConfig.title = _this.internalDataService.buildExportFileTitle(_this.breadcrumb, _this.interval, 'DD/MM/YYYY HH:mm');
              _this.effLossData.dataConfig.plotDataAttribute = 'plotData';
              _this.effLossData.aggrDataConfig.plotDataAttribute = 'aggrPlotData';

              _this.effLossData.plotData = _this.buildPlotConfig(_this, _this.effLossData);
              // _this.effLossData.aggrPlotData = _this.buildAggrPlotConfig(_this, _this.effLossData);

              _this.effLossData.tableInfo = _this.clonerService.deepClone(_this.appConfig.efficiencyLoss.tableInfo);

              _this.effLossData.tableInfo.find((x: any) => x.type == 'icon' && x.variable == 'type').iconConfig = _this.clonerService.deepClone(_this.effLossColors)

              // console.log(_this.effLossData.tableInfo)

              _this.effLossData.tableColumns = [];
              _this.effLossData.tableInfo.forEach((element: any) => {
                _this.effLossData.tableColumns.push(element.variable);
              });

              // set table data and config
              _this.effLossData.dataConfig.tableDataAttribute = 'pareto';
              _this.effLossData.dataConfig.orderAttribute = _this.appConfig?.[_this.tabName]?.orderAttribute != null ? _this.appConfig?.[_this.tabName]?.orderAttribute : 'perc';
              _this.effLossData.dataConfig.completeCardFrame = true,
              _this.effLossData.dataConfig.cardFrame = true


              try {
                if (_this.aggrDropdown != null) {
                  if (_this.effLossData.tableInfo.findIndex((x: any) => x.variable == _this.aggrDropdown) == -1) {
                    _this.effLossData.tableInfo.push({
                      variable: _this.aggrDropdown,
                      orderBy: _this.aggrDropdown,
                      label: _this.translate.instant(_this.aggregations.find((x: any) => x.id == _this.aggrDropdown).label).capitalize(),
                    });
                  }
                  if (!_this.effLossData.tableColumns.includes(_this.aggrDropdown)) _this.effLossData.tableColumns.push(_this.aggrDropdown);
                }
              } catch (error) {
                console.log(error);
              }

            } catch (error) {
              console.log(error);
            }

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getEventsPolling(_this: any) {
    _this.getEvents(_this, 0)
  }

  parseEffLossData(_this: any, data: any) {
    // console.log('parse')
    try {

      if (data != null && Object.keys(data).length > 0) {
        if (data.pareto != null && data.pareto.length > 0) {
          data.pareto.forEach((x: any) => {
            x.durationP = _this.filterService.parseTime(x.duration, 's', 'HH:mm:ss');
          });
        } else {
          data.pareto = [];
        }
        // console.log(data)
        return data;
      }
      return {};

    } catch (error) {
      console.log(error);
      return {};
    }
  }

  buildPlotConfig(_this: any, data: any) {

    let effLossTypes = _this.machine.profile?.efficiencyLoss?.paretoData.map((col: any) => col.type).filter(_this.filterService.onlyUnique)
    // let colorArray = aggrId != null ? data.pareto.map((x: any) => x[aggrId]).filter((val: any, id: any, array: any) => array.indexOf(val) == id) : [];

    let traces: any = [];

    if (!data.hasOwnProperty('pareto') || data.pareto == null || data.pareto.length == 0) {
      return {
        layout: {},
        traces: []
      };
    }

    let range = null;

    let durationProp = 'duration';
    let sortingPropertyList = [durationProp];
    // for each property
    sortingPropertyList.forEach((prop) => {

      // sort events
      data.pareto = data.pareto.sort(_this.filterService.sortByProperty(prop, 'desc', true)).filter((x: any) => x[prop] != null && x[prop] != 0);

      // count total of events property
      let totSum = 0;
      data.pareto.forEach((x: any) => totSum += x[prop]);


      let effLossColors = _this.effLossColors.filter((color) => effLossTypes.includes(color.id))

      effLossColors.forEach((trace: any) => {
        let hoverText = data.pareto.filter((x: any) => x.type == trace.id).map((x: any) => {
          let hover = '';
          x.mat = null;
          if (x.id != null) {
            hover += _this.internalDataService.parseAlarmsLabel(x.id, 'code', _this.machineId) + '<br>';
            hover += "<b>" + _this.translate.instant('BREAKDOWNS.DESCRIPTION') + "</b>: ";
            hover += _this.internalDataService.parseAlarmsLabel(x.id, 'message', _this.machineId);
            hover += '<br>';
          }
          if (x.count != null) {
            hover += "<b>" + _this.translate.instant('BREAKDOWNS.COUNT') + "</b>: ";
            hover += x.count + '<br>';
            x.mat = x.count;
          }
          if (x.duration != null) {
            hover += "<b>" + _this.translate.instant('BREAKDOWNS.DURATION') + "</b>: ";
            hover += _this.filterService.parseTime(x.duration, 's', 'HH:mm:ss') + '<br>';
            if (x.mat) {
              x.mat = _this.filterService.parseTime(x.duration / x.mat, 's', 'HH:mm:ss');
              hover += "<b>" + _this.translate.instant('BREAKDOWNS.MAT') + "</b>: ";
              hover += x.mat + '<br>';
            }
          }
          if (x.cumPerc != null) {
            hover += "<b>" + _this.translate.instant('BREAKDOWNS.CUMULATIVE_PERCENTAGE') + "</b>: ";
            hover += _this.filterService.parseGaugeValue(x.cumPerc, 1, 100) + '%<br>';
          }
          return hover;
        });

        let newTrace = {
          x: data.pareto.filter((x: any) => x.type == trace.id).map((x: any) => x.id),
          y: data.pareto.filter((x: any) => x.type == trace.id).map((x: any) => prop == durationProp ? (x[prop] != null ? x[prop] / 3600 : null) : x[prop]),
          width: data.pareto.length < 3 ? 0.4 : null,
          type: 'bar',
          hoverinfo: 'text',
          yaxis: 'y',
          text: hoverText,
          name: _this.translate.instant(trace.label),
          marker: {
            color: trace.color
          },
          showlegend: true,
          visible: prop == durationProp
        } 

        traces.push(newTrace)
      })

      if(this.constLosses != null) {
        this.constLosses.forEach(loss => {
          traces.push({
            x: data.pareto.map((x: any) => x.id),
            y: data.pareto.map((x: any) => x[loss.variable]/3600),
            marker: {
              color: 'rgba(0, 0, 0, 0)',
            },
            line: {
              color: loss.color,
              dash: 'dash',
              shape: 'spline'
            },
            hoverinfo: 'skip',
            yaxis: 'y',
            visible: prop == durationProp,
            name: _this.translate.instant(loss.label)
          });
        });
      }

      // add trace (y2)
      traces.push({
        x: data.pareto.map((x: any) => x.id),
        y: data.pareto.map((x: any) => x.cumPerc),
        marker: {
          color: 'black',
        },
        line: {
          color: 'black',
        },
        type: 'scatter',
        hoverinfo: 'skip',
        yaxis: 'y2',
        visible: prop == durationProp,
        name: _this.translate.instant("BREAKDOWNS.CUMULATIVE_PERCENTAGE")
      });

      // set range
      if (data.pareto.length > 0 && data.pareto.length < 20) {
        range = [-0.5, data.pareto.length - 0.5];
      } else if (data.pareto.length >= 20) {
        range = [-0.5, 19.5];
      } else {
        range = [-0.5, 0.5];
      }

    });

    let plotLayout: any = {
      // updatemenus: updatemenus,
      legend: {
        x: 0,
        y: -0.25,
        orientation: 'h',
        traceorder: 'normal'
      },
      hoverlabel: {
        align: 'left'
      },
      xaxis: {
        showgrid: false,
        zeroline: false,
        type: 'category',
        range: range,
        tickangle: 0,
        categoryorder: 'array', 
        categoryarray: data.pareto.sort(_this.filterService.sortByProperty('duration', 'desc', true)).map((x: any) => x.id),
        ticktext: data.pareto.sort(_this.filterService.sortByProperty('duration', 'desc', true)).map((x: any) => x.id),
        tickvals: data.pareto.sort(_this.filterService.sortByProperty('duration', 'desc', true)).map((x: any) => x.id),
      },
      yaxis: {
        showgrid: false,
        zeroline: false,
        ticksuffix: ' h',
        rangemode: "tozero"
      },
      margin: {
        t: 10,
        r: 60,
        b: 0,
        l: 60,
        pad: 5
      },
      yaxis2: {
        showgrid: false,
        zeroline: false,
        tickformat: '%',
        range: [0, 1.05],
        overlaying: 'y',
        side: 'right'
      },
      toImageButtonOptions: {
        format: 'png',
        filename: this.machineId + ' Efficiency Loss ' 
                  + this.filterService.parseMoment(this.interval.start, 'YYYY-MM-DD, HH:mm', this.machine.timezone) + ' - ' 
                  + this.filterService.parseMoment(this.interval.end, 'YYYY-MM-DD, HH:mm', this.machine.timezone),
        height: 500,
        width: 1000,
        scale: 1
      }
    };

    return {
      layout: plotLayout,
      traces: traces
    };

  }

}
