<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': asset.title}">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <div *ngIf="assets != null && assets.length > 0">

    <ul class="ff-ul" *ngIf="assets.length > 1" style="margin-bottom: 8px;">
      <li fxLayout="row" fxLayoutAlign="start center" class="clickable" (click)="toggleAll()">
        <mat-icon *ngIf="allSelected" svgIcon="radio_button" class="icon left radio_button"></mat-icon>
        <mat-icon *ngIf="!allSelected" svgIcon="radio_button_disabled" class="icon left radio_button_disabled">
        </mat-icon>
        <span>{{ 'GLOBAL.SELECT_ALL' | translate }}</span>
      </li>
    </ul>

    <ul class="ff-ul">
      <li *ngFor="let asset of assets" fxLayout="row" fxLayoutAlign="start center" class="clickable"
        style="margin-bottom: 4px;" (click)="toggle(asset.machineId, assetsSelected)">
        <mat-icon *ngIf="exists(asset.machineId, assetsSelected)" svgIcon="radio_button" class="icon left radio_button"
          [ngClass]="asset.class">
        </mat-icon>
        <mat-icon *ngIf="!exists(asset.machineId, assetsSelected)" svgIcon="radio_button_disabled"
          class="icon left radio_button_disabled">
        </mat-icon>
        <span fxFlex>{{ asset.machineName }}</span>
        <mat-icon class="icon" *ngIf="asset.color" [ngStyle]="{ 'color': asset.color }">circle</mat-icon>
      </li>
    </ul>

  </div>

  <div class="actions" fxLayout="row" fxLayoutAlign="end end">
    <button mat-button (click)="closeDialog()" cdkFocusInitial
      [disabled]="assets == null || assetsSelected == null|| assetsSelected.length == 0">
      {{'GLOBAL.SELECT'|translate}}
    </button>
  </div>

</mat-dialog-content>
