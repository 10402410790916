<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null " class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center"
    [ngStyle]="{'margin-top': isMobile ? '12px' : '0'}">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

  </div>

  <div class="ff-streaming-container w100" [ngStyle.lt-md]="{'margin': '12px 0'}"
    *ngIf="pageState.value >= pageStateReady">
    <div fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center" fxLayout="column"
      fxLayoutAlign="center stretch" fxLayoutGap="18px" style="background-color: transparent; margin: 12px 16px;">

      <!-- variables and components selection -->
      <ng-container *ngIf="!skipTelemetryInVideoStreaming">
        <button *ngIf="!isMobile" mat-button (click)="openVariablesSelectionDialog()" class="filter-button"
          fxLayout="row" fxLayoutAlign="center center" [disabled]="pageState.value < pageStateReady">
          {{ 'CONTINUOUS_EXPLORATION.VARIABLES_SELECTION' | translate }}
          <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
            style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
          </mat-icon>
        </button>
      </ng-container>

      <!-- refresh videos -->
      <button *ngIf="!isMobile" mat-button (click)="refreshVideos()" class="filter-button" fxLayout="row"
        fxLayoutAlign="center center"
        [disabled]="(liveVideosStreamings == null || liveVideosStreamings.length == 0) || pageState.value < pageStateReady">

        <ng-container *ngIf="!skipTelemetryInVideoStreaming">
          {{ 'VIDEO_STREAMING.REFRESH_VIDEOS' | translate }}
          <mat-icon class="icon">refresh</mat-icon>
        </ng-container>

        <ng-container *ngIf="skipTelemetryInVideoStreaming">
          {{ 'VIDEO_STREAMING.PLAY' | translate }}
          <mat-icon class="icon">play_arrow</mat-icon>
        </ng-container>

      </button>

      <span fxFlex></span>

      <!-- <span style="font-weight: 800; font-size: 21px; line-height: 30px; color: #1A1919; margin: 16px 8px 8px 8px;">
          {{ 'VIDEO_STREAMING.TITLE' | translate }}
        </span> -->
      <!-- <span flex></span> -->

      <!-- intervals -->

      <ng-container *ngIf="!skipTelemetryInVideoStreaming">
        <ff-interval-selector *ngIf="edgeVideoStreaming" [config]="intervalConfig" (selected)="selectInterval($event)">
        </ff-interval-selector>
      </ng-container>

      <div *ngIf="!edgeVideoStreaming" fxLayout="column" fxLayoutAlign="center start" class="column-title-value"
        style="margin-right: 48px;">
        <span class="title">{{ 'VIDEO_STREAMING.STREAM_START' | translate }}</span>
        <span class="value">{{ streamStart | placeholder }}</span>
      </div>

    </div>

  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < 8 || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 8" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 7" fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="center center" class="w100" [ngClass]="{'h100': !noLiveStreaming }">

      <div *ngIf="noLiveStreaming" class="w100 ff-streaming-container mt-3" fxLayout="row wrap"
        fxLayoutAlign="center center">
        <p>{{ 'VIDEO_STREAMING.NO_LIVE_STREAMING' | translate }}</p>
      </div>

      <div *ngIf="liveVideos?.length > 0 && !noLiveStreaming" fxFlex.gt-sm fxFlexOrder.gt-sm="1" fxFlexOrder="2"
        class="w100 h100" fxLayout="row wrap" style="overflow-x: hidden !important;"
        fxLayoutAlign="{{ liveVideos?.length == 1 ? 'center start' : 'center start' }}"
        [ngStyle.gt-sm]="{'overflow-y': 'auto', 'height': 'calc(100vh - ' + (!skipTelemetryInVideoStreaming ? '280' : '160' ) + 'px)'}">

        <ng-container *ngFor="let video of liveVideos; let i = index">

          <!-- AZURE -->
          <ng-container *ngIf="!edgeVideoStreaming">
            <video [fxFlex]="!skipTelemetryInVideoStreaming ? 'calc(100% - 16px)' : 'calc(50% - 16px)'"
              [id]="video.id" [height]="calculateHeight(video.id)" width="100%"
              class="video-container azuremediaplayer amp-default-skin amp-big-play-centered" tabindex="0">
            </video>
          </ng-container>

          <!-- EDGE -->
          <ng-container *ngIf="edgeVideoStreaming">
            <video [fxFlex]="!skipTelemetryInVideoStreaming ? 'calc(100% - 16px)' : 'calc(50% - 16px)'"
              [id]="video.id" class="video-container video-js" [height]="calculateHeight(video.id)" width="100%"
              controls>
              <source [src]="'streaming/' + video.name + '/stream.m3u8'" type='application/x-mpegURL' />
            </video>
          </ng-container>

        </ng-container>

      </div>

      <div *ngIf="!noLiveStreaming && !skipTelemetryInVideoStreaming" fxFlex.gt-sm fxFlexOrder.gt-sm="1"
        fxFlexOrder="2" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="start start" class="w100 h100" fxLayoutGap="8px">
        <!-- style="height: calc((100vh - 250px) * 0.45); margin-top: 8px;" -->

        <!-- dashboard -->
        <div *ngIf="dashboardData" class="w100 h100" fxLayout.lt-sm="column" fxLayout="row wrap"
          fxLayoutAlign="start stretch" fxLayoutGap="8px grid"
          fxLayoutGap.gt-sm="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
          <ff-widget *ngFor="let w of dashboardConfig.widgets" [ngStyle.gt-sm]="{'height': w.customHeight}"
            [fxFlex.gt-sm]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
            [fxShow.lt-md]="!w.hideMobile" [fxFlex.sm]="'calc(' + w.mobileFlex + '% - 8px )'"
            [fxFlexOrder.sm]="w.mobileOrder" [widget]="{ 
              type: w.type, 
              subtype: w.subtype, 
              data: dashboardData, 
              machineProfile: machine.profile, 
              config: w.config, 
              title: w.title
            }">
          </ff-widget>
        </div>

        <div *ngIf="dashboardData == null" class="w100 ff-streaming-container mt-3" fxLayout="row wrap"
          fxLayoutAlign="center center">
          <p>{{ 'SESSION_DETAIL.PRESS_PLAY_TO_START_SESSION' | translate }}</p>
        </div>

      </div>

    </div>

  </div>

</div>