import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

@Component({
  selector: 'ff-synoptic-selection-dialog',
  templateUrl: './synoptic-selection-dialog.component.html',
  styleUrls: ['./synoptic-selection-dialog.component.scss']
})
export class SynopticSelectionDialogComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public apiService: ApiService,
    public translate: FfTranslateService,
  ) { 
    
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

}
