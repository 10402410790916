import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'map-card',
  templateUrl: './map-card.component.html',
  styleUrls: ['./map-card.component.scss']
})
export class MapCardComponent implements OnInit, OnChanges {

  @Input() machineData: any;

  public appConfig: any;
  public sidenavItems: any;

  public isEnabledMachineCalendar: Boolean = true;
  public enableCalendarSwitch: Boolean = false;


  cardStyle: any;
  cardConfig: any = { config: {} };

  machineInfos: any = [];
  machineDatas: any = [];
  otherData: any = [];
  worstComponentsInfos: any = [];
  anomalyInfos: any = [];
  oeeInfos: any = [];
  productivity: any = {};
  materials: any = null;
  productionOrder: any = null;
  productionData: any = null;

  prodOrderColumns: any;

  public homepageDataSub: any;
  public interval: any;

  constructor(
    private appConfigService: AppConfigService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public dialog: MatDialog,
    public cacheService: CacheService,
    private router: Router,
    public translate: FfTranslateService,
  ) {

    this.appConfig = this.appConfigService.getAppConfig;
    this.sidenavItems = appConfigService.getSidenavItems;
    this.cardStyle = this.appConfig.homepage.gridCards.cardStyle != null ? this.appConfig.homepage.gridCards.cardStyle : 0;

    // card-style != 0
    this.machineInfos = this.appConfig.homepage?.machineInfos;
    this.machineDatas = this.appConfig.homepage?.machineDatas;
    this.otherData = this.appConfig.homepage?.otherData;
    this.worstComponentsInfos = this.appConfig.homepage?.worstComponentsInfos;
    this.anomalyInfos = this.appConfig.homepage?.anomalyInfos;
    this.oeeInfos = this.appConfig.homepage?.oeeInfos;

    this.homepageDataSub = this.internalDataService.homepageData.subscribe(value => {
      this.interval = value.interval;
    });


  }

  ngOnInit() {
    try {
      this.cardConfig = this.machineData?.profile?.homepage ?? { config: {} };
      let prodOrder = this.cardConfig?.config?.productionOrder;
      this.prodOrderColumns = prodOrder?.columns ? 100 / prodOrder?.columns : 50;
    } catch (error) { }

    try {
      // card-style != 0
      this.machineInfos = this.machineData?.profile?.homepage?.machineInfos ?? this.appConfig.homepage.machineInfos;
      this.machineDatas = this.machineData?.profile?.homepage?.machineDatas ?? this.appConfig.homepage.machineDatas;
      this.otherData = this.machineData?.profile?.homepage?.otherData ?? this.appConfig.homepage.otherData;
      this.worstComponentsInfos = this.machineData?.profile?.homepage?.worstComponentsInfos ?? this.appConfig.homepage.worstComponentsInfos;
      this.anomalyInfos = this.machineData?.profile?.homepage?.anomalyInfos ?? this.appConfig.homepage.anomalyInfos;
      this.oeeInfos = this.machineData?.profile?.homepage?.oeeInfos ?? this.appConfig.homepage.oeeInfos;
      this.productivity = this.machineData?.profile?.homepage?.productivity ?? this.appConfig.homepage.productivity;
      this.materials = this.machineData?.profile?.homepage?.materials ?? this.appConfig.homepage.materials;
      this.productionOrder = this.machineData?.profile?.homepage?.productionOrder ?? this.appConfig.homepage.productionOrder;
      this.productionData = this.machineData?.profile?.homepage?.productionData ?? this.appConfig.homepage.productionData;
    } catch (error) { console.log(error) }

    try {
      // this.enableCalendarSwitch = sidenavItems.find(sItem => (sItem.id == 'planning-calendar' && !sItem.disabled)) != null
      if (this.appConfig?.homepage?.showCalendarSwitch) {
        let calendarItem = this.sidenavItems.find(sItem => (sItem.id == 'planning-calendar'));
        let machineId = this.machineData.machineId;
        if (calendarItem == null) {
          this.enableCalendarSwitch = false;
        } else if (calendarItem.disabled == true) {
          this.enableCalendarSwitch = false;
        } else if (Array.isArray(calendarItem.enabledForAssets)  && !calendarItem.enabledForAssets.includes(machineId)) {
          this.enableCalendarSwitch = false;
        } else if (Array.isArray(calendarItem.disabledForAssets) && calendarItem.disabledForAssets.includes(machineId)) {
          this.enableCalendarSwitch = false;
        }
        else  {
          this.enableCalendarSwitch = true;
        }

        this.isEnabledMachineCalendar = this.filterService.isActiveCalendar(this.machineData.machineId);
      }

    } catch (error) {
      console.log(error);
    }

  }

  selectMachine() {
    this.internalDataService.setMachineSelected(this.machineData);
  }

  public toggleEnableCalendarAsset(value) {
    
    try { localStorage.setItem(this.machineData.machineId + '_calendar', value) }
    catch (error) { console.log(error) }


    if (value == false) {
      try { if (this.cacheService.get("intervalAggregation")?.id == 'shift') this.cacheService.set("intervalAggregation", null) }
      catch (error) { console.log(error) }
    }

    this.router.navigateByUrl(this.router.url);
    // this.machineData.loading = true;
  }

  ngOnChanges() { }

  ngOnDestroy() {
    try {
      this.homepageDataSub.unsubscribe();
    } catch (error) { }
  }

}
