<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <div class="w100" fxLayout="column" fxLayoutAlign="center start">

    <ngx-slider *ngIf="downtimeEvent.slider !=  null && downtimeEvent.slider.show" style="margin: 48px 0 24px 0;"
      [(value)]="downtimeEvent.slider.min" [(highValue)]="downtimeEvent.slider.max"
      [options]="downtimeEvent.slider.options">
    </ngx-slider>

    <div class="box w100 interval-selector alternative-color" fxLayout="row" fxLayoutAlign="start center">
      <mat-form-field appearance="fill" class="w100">
        <mat-label>{{ 'CALENDAR.INVALIDITY_SELECTION' | translate }}</mat-label>
        <mat-select [(value)]="downtimeEvent.invalidity">
          <mat-option *ngFor="let state of dialog.calendarStates | filterByProperty:['isValid', '==', false]"
            [value]="state.id">
            <span style="font-size: 16px;">{{ state.label | translate }}</span>
            <span flex></span>
            <span class="color-square-padding" [ngStyle]="{ 'background-color': state.color }">
            </span>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button class="filter-button md-primary" (click)="closeDialog()">{{ 'GLOBAL.SELECT' | translate }}</button>
</mat-dialog-actions>