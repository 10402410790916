<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

    <!-- toolbar tabs -->
    <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
        fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

        <!-- tabs -->
        <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

        <div *ngIf="prodsDataDash?.table != null && filterButtons != null && filterButtons.length > 0 && tabs.length <= 1"
            fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-evenly center">
            <button mat-button class="filter-button" *ngFor="let item of filterButtons" (click)="openFilterDialog(item)"
                [disabled]="prodsDataDash.table.length == 0" fxLayout="row" fxLayoutAlign="space-evenly center">
                <mat-icon class="icon">filter_alt</mat-icon>
                <span>{{ item.label | translate }}</span>
            </button>
        </div>

        <!-- sub aggregations dropdown -->
        <div *ngIf="tabs.length == 1 && machine.profile != null && machine.profile.subAggregations != null && machine.profile.subAggregations.length > 0"
            class="aggregation-selector" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="start center">

            <div class="box" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="fill">
                    <mat-label>{{ 'GLOBAL.DROPDOWN_AGGREGATION_SELECTION' | translate }}</mat-label>
                    <mat-select [(ngModel)]="aggrDropdown" name="aggr" style="text-transform: capitalize;">
                        <mat-option (click)="changePageAggregation(aggrDropdown)">---</mat-option>

                        <mat-option *ngFor="let aggr of machine.profile.subAggregations" [value]="aggr.id"
                            (click)="changePageAggregation(aggrDropdown)">

                            <span style="text-transform: capitalize;">{{ aggr.label | translate }}</span>

                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <button mat-button class="md-primary circular-icon-button not-clickable" matTooltipClass="ff-tooltip"
                #tooltip="matTooltip" matTooltip="{{ 'PRODUCTION_TRACEABILITY.SUB_AGGREGATIONS_INFO' | translate }}"
                matTooltipPosition="right">
                <mat-icon class="icon">info</mat-icon>
            </button>

        </div>

        <span fxFlex fxShow="false" fxShow.gt-sm></span>

        <!-- intervals -->
        <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

    </div>

    <!-- <div *ngIf="isMobile" fxFlex fxLayout="column" class="tab-content h100 w100" [ngClass]="{ 'without-tabs': tabs.length <= 1}"
      fxLayoutAlign="center center">
      <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
    </div> -->

    <!-- tab content -->
    <div class="tab-content h100 w100" fxLayout="column"
        [fxLayoutAlign]="pageState.value >= 6 ? 'start stretch' : (pageState.value == 0 ? 'start center' : 'center center')"
        [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

        <!-- toolbar -->
        <div class="ff-toolbar pb-3 pt-1" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
            fxLayoutAlign.gt-sm="start center"
            *ngIf="(aggregations != null && aggregations.length > 0) || (availableMachines != null && availableMachines.list != null && availableMachines.list.length > 0)">

            <!-- sub aggregations dropdown -->
            <div style="margin-right: 16px;"
                *ngIf="tabs.length > 1 && machine.profile != null && machine.profile.subAggregations != null && machine.profile.subAggregations.length > 0"
                fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

                <div class="interval-selector" fxLayout="row" fxLayoutAlign="start center" marginRight>
                    <mat-form-field appearance="fill">
                        <mat-label>{{ 'GLOBAL.DROPDOWN_AGGREGATION_SELECTION' | translate }}</mat-label>
                        <mat-select [(ngModel)]="aggrDropdown" name="aggr" style="text-transform: capitalize;">
                            <mat-option (click)="changePageAggregation(aggrDropdown)">---</mat-option>

                            <mat-option *ngFor="let aggr of machine.profile.subAggregations" [value]="aggr.id"
                                (click)="changePageAggregation(aggrDropdown)">

                                <span style="text-transform: capitalize;">{{ aggr.label | translate }}</span>

                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <button mat-button class="md-primary circular-icon-button not-clickable" matTooltipClass="ff-tooltip"
                    #tooltip="matTooltip" matTooltip="{{ 'PRODUCTION_TRACEABILITY.SUB_AGGREGATIONS_INFO' | translate }}"
                    matTooltipPosition="right">
                    <mat-icon class="icon">info</mat-icon>
                </button>

            </div>

            <!-- aggregations -->
            <div *ngIf="noAggrButtons == null" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-evenly center"
                [class.w100]="isSmThanTablet">
                <button mat-button class="filter-button" *ngFor="let aggr of aggregations"
                    (click)="openAggrDialog(aggr)" [disabled]="aggr.disabled" fxLayout="row"
                    fxLayoutAlign="space-evenly center">
                    <mat-icon *ngIf="aggr.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': aggr.label}">
                        {{aggr.icon.icon }}</mat-icon>
                    <mat-icon *ngIf="aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
                        translate-attr="{'aria-label': aggr.label}"></mat-icon>
                    <i *ngIf="aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor'"
                        class="icon {{ aggr.icon.icon }}" translate-attr="{'aria-label': aggr.label}"></i>
                    <span>{{ aggr.selectionLabel }}</span>
                    <div fxLayout="row" fxLayoutAlign="center center"
                        *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null"
                        class="active-filters-circle">
                        {{ aggregationsPayload[aggr.id].length }}
                    </div>
                </button>
            </div>

            <div *ngIf="prodsDataDash?.table != null && filterButtons != null && filterButtons.length > 0 && tabs.length > 1"
                fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-evenly center">
                <button mat-button class="filter-button" *ngFor="let item of filterButtons"
                    (click)="openFilterDialog(item)" [disabled]="prodsDataDash.table.length == 0" fxLayout="row"
                    fxLayoutAlign="space-evenly center">
                    <mat-icon class="icon">filter_alt</mat-icon>
                    <span>{{ item.label | translate }}</span>
                </button>
            </div>


            <span fxFlex fxShow="false" fxShow.gt-sm></span>

            <ff-available-machines-selection [availableMachines]="availableMachines"
                (selected)="machineSelectionChange($event)"></ff-available-machines-selection>

        </div>

        <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
        <!-- loading -->
        <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 7" [loadingData]="loadingData"
            style="margin: auto;">
        </ff-loading-card>

        <div fxLayout="column" class="w100">

            <!-- dashboard -->
            <div *ngIf="prodsDataDash && pageState.value >= 7" class="w100" fxLayout.lt-sm="column" fxLayout="row wrap"
                fxLayoutAlign="start stretch" fxLayoutGap="8px grid"
                fxLayoutGap.gt-sm="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
                <ff-widget *ngFor="let w of dashboardConfig.widgets"
                    [fxFlex.gt-sm]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
                    [fxShow.lt-md]="!w.hideMobile" [fxFlex.sm]="'calc(' + w.mobileFlex + '% - 8px )'"
                    (clicked)="tableAction($event)" [fxFlexOrder.sm]="w.mobileOrder" [widget]="{ 
                    type: w.type, 
                    subtype: w.subtype, 
                    data: prodsDataDash, 
                    machineProfile: machine.profile, 
                    config: w.config, 
                    title: w.title,
                    interval: interval,
                    intervalAggregations: intervalAggregations
                    }">
                </ff-widget>
            </div>

        </div>

    </div>

</div>