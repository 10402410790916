import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

@Component({
  selector: 'app-event-types-filter-dialog',
  templateUrl: './event-types-filter-dialog.component.html',
  styleUrls: ['./event-types-filter-dialog.component.scss']
})
export class EventTypesFilterDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public apiService: ApiService,
    public translate: FfTranslateService,
  ) {
  }

  ngOnInit(): void {
    try {
      if (!this.dialog.filterTabsCopy.some((tab:any) => tab.selected)) this.dialog.filterTabsCopy[0].selected = true;
    } catch (error) {
      console.log(error);
    }
    // console.log(this.dialog);
    // this.updateAllSelected();
  }

}
