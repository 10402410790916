<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>

<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content without-tabs">

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < pageStateReady" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div fxLayout="column" class="w100">

      <!-- dashboard -->
      <div *ngIf="prodsDataDash && pageState.value >= pageStateReady" class="w100" fxLayout="row wrap"
        fxLayoutAlign="start stretch"
        fxLayoutGap="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
        <ff-widget *ngFor="let w of dashboardConfig.widgets"
          [fxFlex]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
          [widget]="{ type: w.type, subtype: w.subtype, data: prodsDataDash, machineProfile: machine.profile, config: w.config, title: w.title, aggrUnit: urlAggrUnit }">
        </ff-widget>
      </div>

      <!-- table -->
      <mat-card
        [ngClass]="{'hidePaginator': (pageState.value < pageStateReady || events.list.length == 0 || prodsDataDash == null || tableInfos == null) }"
        fxLayout="column" style="margin-top: 8px;">

        <div class="phase-data-title" fxLayout="row" fxLayoutAlign="start center">
          <!-- <span>{{ 'CYCLE_TIMELINE.EVENTS_LOG' | translate }}</span> -->
          <span fxFlex></span>

          <button mat-button class="filter-button" (click)="openEventFilter()" fxLayout="row"
            fxLayoutAlign="space-evenly center">
            <mat-icon class="icon">filter_alt</mat-icon>
            <span>{{ 'CONTINUOUS_EXPLORATION.ALL_FILTERS' | translate }}</span>
          </button>

          <button *ngIf="atLeastOneFilter" mat-button class="filter-button md-red" aria-label="remove filters"
            (click)="removeAllFilters()">
            <mat-icon class="icon">filter_alt</mat-icon>
            <mat-icon class="icon">close</mat-icon>
            <!-- <ng-md-icon icon="filter-icon" class="side" size="20"></ng-md-icon>
            <ng-md-icon icon="close" size="16" style="position: absolute; top: 6px; left: 18px;"></ng-md-icon> -->
            <span style="padding-left: 8px;">{{'CONTINUOUS_EXPLORATION.REMOVE_FILTERS' | translate}}</span>
          </button>

        </div>

        <table [ngClass]="{'hidePaginator': events.filtered.length == 0 }" mat-table [dataSource]="eventsData" matSort
          matSortActive="timestamp" matSortDirection="desc" class="standard-table">

          <ng-container matColumnDef="icon">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let event" class="td-icon-large">
              <div fxLayout="row" fxLayoutAlign="center center">

                <div>
                  <img *ngIf="event?.image" [src]="event.image" alt="" class="clickable"
                    style="height: 50px; border-radius: 5px;" (click)="filterService.openImage(event)">
                </div>

                <ng-container *ngIf="event?.image == null">
                  <div>
                    <ff-icon *ngIf="event.icon1" [icon]="event.icon1"></ff-icon>
                  </div>

                  <div>
                    <ff-icon *ngIf="event.icon2" [icon]="event.icon2"></ff-icon>
                  </div>
                </ng-container>

              </div>

            </td>
          </ng-container>

          <ng-container *ngFor="let info of tableInfos" matColumnDef="{{info.variable}}">
            <th mat-header-cell *matHeaderCellDef [mat-sort-header]="info.orderBy != null ? info.orderBy : null"
              [disabled]="info.orderBy == null">
              {{info.label|translate}}
            </th>
            <td mat-cell *matCellDef="let event" [ngClass]="{ 'primary': info.primary }">

              <!-- NORMAL COLUMN -->
              <span *ngIf="info.type == null && (info.variable != 'description' || event.eventType == null)"
                class="break-word {{info.class}} {{event[info.variable] != null ? event[info.variable].class : ''}}"
                style="font-size: calc(0.4em + 1vh) !important;">
                {{ event[info.variable] | placeholder }}
              </span>

              <!-- LIST COLUMN -->
              <span
                *ngIf="(event.eventType == 'colorList' || event.eventType == 'list') && info.variable == 'description'"
                ng-class="{'minWidthListLarge': mobile()}" class="{{info.classes}}">
                <ul class="ff-ul">
                  <div *ngIf="event.eventType == 'colorList'">
                    <li *ngFor="let state of event.description" style="padding: 4px 0;">
                      <span class="color-square" [style.background-color]="state.color"
                        style="margin: 0 8px 0 0; padding: 0 9px;"></span>
                      <span>{{ state.value | translate }}</span>
                    </li>
                  </div>
                  <div *ngIf="event.eventType == 'list'">
                    <li *ngFor="let state of event.description">
                      <span [innerHTML]="state"></span>
                    </li>
                  </div>
                </ul>
              </span>

              <span *ngIf="info.type == 'timeState'">
                <div *ngIf="event.stateP != null" fxLayout="row" fxLayoutAlign="center center"
                  [style.background-color]="event.stateP.color" class="state-container full-width">
                  <span>{{event.stateP.value | translate }}</span>
                </div>
                <!-- <span *ngIf="event.stateP != null" class="color-square" [style.background-color]="event.stateP.color"
                style="padding: 0 9px;"></span> -->
              </span>
              <!-- <span *ngIf="info.prefix != null">{{info.prefix}}&nbsp;</span>
            <span>{{(event[info.variable] != null ? event[info.variable] : '-')}}</span>
            <span *ngIf="info.suffix != null">&nbsp;{{info.suffix}}</span> -->
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="eventsColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: eventsColumns;"></tr>
        </table>
        <mat-paginator [ngClass]="{'hidePaginator': (pageState.value < 7 || events.filtered.length == 0) }"
          [length]="events.filtered.length" [pageSizeOptions]="events.pageOptions" [pageSize]="events.pageSize"
          showFirstLastButtons class="standard-table">
        </mat-paginator>

        <div *ngIf="pageState.value >= pageStateReady && events.filtered.length == 0" class="w100" fxLayout="row"
          fxLayoutAlign="center center" style="margin-top: 8px;">
          <span>{{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}</span>
        </div>

      </mat-card>

    </div>

  </div>

</div>