import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClonerService } from 'src/app/services/clone.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'ff-widget',
  templateUrl: './widget-card.component.html',
  styleUrls: ['./widget-card.component.scss']
})
export class WidgetCardComponent implements OnInit {

  @Input() widget: any;
  @Output() clicked = new EventEmitter<any>();
  @Output() inputTag = new EventEmitter<any>();

  constructor(
    public clonerService: ClonerService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
  ) { }

  ngOnInit(): void {

    try {

      // if (this.widget.hasOwnProperty('self') && this.widget.self != null) {
      //   this.widget + {...this.widget, ...this.widget.self}
      // }

      if (this.widget.type == 'ff-table-sortable') {

        // Add aggregations automatically at the end
        try {
          if (this.widget.config?.addAggregationsAutomaticallyAtTheEnd && this.widget?.aggregations?.length > 0 && !this.widget.aggregatedTable) {

            this.widget.aggregations?.forEach(aggr => {
              if (this.widget.config.tableInfos.findIndex(x => x.variable == aggr.id) == -1) {
                this.widget.config.tableInfos.push({
                  variable: aggr.id,
                  orderBy: aggr.id,
                  label: aggr.label.capitalize(),
                });
              }
            });

          }
        }
        catch (error) { console.log(error) }

        // If the table is aggregated (cycle list in machine recorder), change the defaultTableInfos with "aggregatedTableInfos" 
        if (this.widget?.aggregatedTable != null && this.widget.config.aggregatedTableInfos?.length) {
          if (this.widget?.aggregatedTable) {
            if (this.widget.config.nonAggregatedTableInfos == null) this.widget.config.nonAggregatedTableInfos = this.clonerService.deepClone(this.widget.config.tableInfos);
            this.widget.config.defaultTableInfos = this.clonerService.deepClone(this.widget.config.aggregatedTableInfos);

            // Handle additional buttons
            if (this.widget.config.aggregatedTableAdditionalButtons) {
              this.widget.config.defaultAdditionalButtons = this.clonerService.deepClone(this.widget.config.additionalButtons);
              this.widget.config.additionalButtons = this.clonerService.deepClone(this.widget.config.aggregatedTableAdditionalButtons);
            }

            // Handle additional table buttons
            if (this.widget.config.aggregatedTableAdditionalTableButtons) {
              this.widget.config.defaultAdditionalTableButtons = this.clonerService.deepClone(this.widget.config.additionalTableButtons);
              this.widget.config.additionalTableButtons = this.clonerService.deepClone(this.widget.config.aggregatedTableAdditionalTableButtons);
            }

          }
          else {
            let defaultTableInfos = this.widget.config.nonAggregatedTableInfos ?? this.widget.config.tableInfos;
            if (this.widget.config.additionalTableInfosAD) this.widget.config.additionalTableInfosAD.forEach(info => {
              if (defaultTableInfos?.find(x => x.variable == info.variable) == null && this.widget.additionalParams?.isEnabledAD) defaultTableInfos.push(info);
            });

            // Handle additional buttons
            if (this.widget.config.defaultAdditionalButtons) this.widget.config.additionalButtons = this.clonerService.deepClone(this.widget.config.defaultAdditionalButtons);

            // Handle additional table buttons
            if (this.widget.config.defaultAdditionalTableButtons) this.widget.config.additionalTableButtons = this.clonerService.deepClone(this.widget.config.defaultAdditionalTableButtons);

            this.widget.config.defaultTableInfos = this.clonerService.deepClone(defaultTableInfos);
          }
        }

        // Initialize default table infos
        try { if (this.widget.config.defaultTableInfos == null) this.widget.config.defaultTableInfos = this.clonerService.deepClone(this.widget.config.tableInfos) }
        catch (error) { console.log(error) }

        // Remove columns with specific offsets
        try { this.widget.config.tableInfos = this.clonerService.deepClone(this.widget.config?.defaultTableInfos?.filter(info => info.availableOnlyWithOffsets == null || (info.availableOnlyWithOffsets.includes(this.widget?.intervalAggregations?.selected?.unit)))) }
        catch (error) { console.log(error) }

        // Add machine profile to table config
        try { if (this.widget.config?.addMachineProfileToTableConfig) this.widget.config.profile = this.clonerService.deepClone(this.widget?.machineProfile) }
        catch (error) { console.log(error) }

        // Additional parsing on the columns
        try {
          this.widget.config.tableInfos = this.widget.config.tableInfos.reduce((acc, val) => {

            // Calculate unit from "productionUnit" in every column config if present
            try { if (val?.productionUnit != null) val.unit = this.filterService.getProductionUnit(val?.productionUnit, this.widget?.config?.profile?.productionConfig) } catch (error) { console.log(error) }

            // If the table is aggregated (cycle list in machine recorder), change the column flag according to the aggregation value 
            if (this.widget?.aggregatedTable && !this.widget.config.aggregatedTableInfos?.length) val.hideInTable = val.hideInAggregatedTable;

            acc.push(val);
            return acc;
          }, []);
        }
        catch (error) { console.log(error) }

        // Insert rows in config
        try { this.widget.config.rows = this.widget?.data?.[this.widget?.config?.rowsVariable ?? 'table'] }
        catch (error) { console.log(error) }

        // Insert machine object in config
        if (this.widget?.referenceComponent?.machine) {
          try { this.widget.config.machine = this.filterService.removeKeysWithCustomRule(this.widget?.referenceComponent?.machine, (x) => x[0] != 'profile') }
          catch (error) { console.log(error) }
        }

        // Remove animations
        try { if (this.widget.config?.removeAnimationsIfActivePolling) this.widget.config.removeAnimations = this.widget?.data?.[this.widget?.config?.pollingVariable ?? 'removeAnimations'] }
        catch (error) { console.log(error) }

        // Configure additionalTableButtons disability with parametric condition
        try {
          if (this.widget.config?.additionalTableButtons?.length > 0) {
            this.widget.config?.additionalTableButtons?.forEach(x => {

              if (x?.permission != null && this.internalDataService.getSpecificPermission(x.permission)) x.disabled = false;

              let boolCond = x.disabled;
              if (!x.disabled) {
                if (Array.isArray(x.disabledCondition) && x.disabledCondition?.length > 0) {
                  if (x.disabledCondition?.every(cond => Array.isArray(cond) && cond?.length == 3)) {
                    boolCond = x.disabledCondition.some(c => this.parseDisabilityCondition(this.widget?.referenceComponent, c));
                  }
                  else if (x.disabledCondition?.length == 3) {
                    boolCond = this.parseDisabilityCondition(this.widget?.referenceComponent, x.disabledCondition);
                  }
                }
              }

              if (!x.hide) x[x.hideIfNoPermission ? 'hideInTable' : 'disabled'] = boolCond;

              if (x.hide) {
                if (Array.isArray(x.hideCondition) && x.hideCondition?.length > 0) {
                  if (x.hideCondition?.every(cond => Array.isArray(cond) && cond?.length == 3)) {
                    x.hideInTable = x.hideCondition.some(c => this.parseDisabilityCondition(this.widget?.referenceComponent, c));
                  }
                  else if (x.hideCondition?.length == 3) {
                    x.hideInTable = this.parseDisabilityCondition(this.widget?.referenceComponent, x.hideCondition);
                  }
                }
              }
            });
          }
        }
        catch (error) { console.log(error) }

        // Configure additionalButtons disability with permission
        try {
          if (this.widget.config?.additionalButtons?.length > 0) {
            this.widget.config?.additionalButtons?.forEach(x => {

              if (x?.permission != null && this.internalDataService.getSpecificPermission(x.permission)) x.defaultDisabled = false;

              if (!x.defaultDisabled) {
                // Parametric disable condition on row buttons
                if (Array.isArray(x.disabledCondition) && x.disabledCondition?.length > 0) {
                  if (x.disabledCondition?.every(cond => Array.isArray(cond) && cond?.length == 3)) {
                    x.defaultDisabled = x.disabledCondition.some(c => this.parseDisabilityCondition(this.widget?.referenceComponent, c));
                  }
                  else if (x.disabledCondition?.length == 3) {
                    x.defaultDisabled = this.parseDisabilityCondition(this.widget?.referenceComponent, x.disabledCondition);
                  }
                }
              }
            });
          }
        }
        catch (error) { console.log(error) }

        // Find keys from profile
        try {
          if (this.widget.config?.additionalConfigParams?.loopOnProfileArray) {

            let loopProfileConfig: any = this.clonerService.deepClone(this.widget.config?.additionalConfigParams?.loopOnProfileArray);

            let key = loopProfileConfig?.key ?? 'consumables';
            let profileArray: any = this.clonerService.deepClone(this.widget.machineProfile?.[key]) ?? [];

            let newInfos = [];
            this.widget.config?.tableInfos.forEach(info => {
              if (info?.parseFromProfileConfig) {
                profileArray?.forEach(elem => {

                  let id = elem?.[loopProfileConfig?.idVariable ?? 'id'];
                  let label = elem?.[loopProfileConfig?.labelVariable ?? 'label'];
                  let icon = elem?.[loopProfileConfig?.iconVariable ?? 'icon'];

                  let unit = elem.unit;
                  if (loopProfileConfig?.unitVariable != null) unit = elem?.[loopProfileConfig?.unitVariable ?? 'unit'];

                  let newObj: any = Object.assign(this.clonerService.deepClone(info), {
                    variable: info.variable?.replace("{{id}}", id),
                    orderBy: info.orderBy?.replace("{{id}}", id),
                    label: info.label?.replace("{{label}}", label ?? '-'),
                    unit: info.unit?.replace("{{unit}}", unit ?? '-'),
                    icon: icon,
                  });

                  newInfos.push(newObj);
                });
              } else {
                newInfos.push(info);
              }
            });
            this.widget.config.tableInfos = newInfos;

            // console.log(this.widget.config.tableInfos);

          }
        }
        catch (error) { console.log(error) }

        // Filtrable variables
        try { this.widget.config.filterVariables = this.widget.config.tableInfos.filter(x => x.filtrable).map(x => x.variable) }
        catch (error) { console.log(error) }

      }

    } catch (error) { console.log(error) }
  }

  parseDisabilityCondition(referenceComponent, disabledCondition) {

    let firstCondition = referenceComponent?.[disabledCondition?.[0]];
    let secondCondition = disabledCondition?.[2];

    if (disabledCondition?.[0]?.split(".")?.length > 1) {
      firstCondition = referenceComponent;
      disabledCondition?.[0]?.split(".")?.forEach(cond => firstCondition = firstCondition?.[cond]);
    }

    switch (disabledCondition?.[1]) {
      case '==': return firstCondition == secondCondition;
      case '!=': return firstCondition != secondCondition;
    }
  }

  // EVENT EMITTERS
  tableAction(item) { this.clicked.emit(item) }

  // EVENT EMITTERS
  tableInputTagChange(item) { this.inputTag.emit(item) }

}
