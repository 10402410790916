import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { EventTypesFilterDialogComponent } from 'src/app/pages/machine-recorder/continuous-exploration/event-types-filter-dialog/event-types-filter-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';



@Component({
  selector: 'app-production-traceability-detail',
  templateUrl: './production-traceability-detail.component.html',
  styleUrls: ['./production-traceability-detail.component.scss']
})
export class ProductionTraceabilityDetailComponent implements OnInit, OnDestroy {

  public isAllowedUser: any = true;

  public configurationErrors: any = [];
  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public backButton: any;
  public tabs: any = [];

  public urlStart: any;
  public urlEnd: any;
  public urlAggrUnit: any;
  public urlMachineId: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public timeStates: any;

  public aggregations: any;
  public aggregationsPayload: any;

  public intervalAggregations: any;
  public aggregationsTime: any;

  public machineSelectedId: any;

  public dashboardConfig: any;

  public detailInfoConfig: any;
  public detailData: any;

  public prods: any;
  public prodsDataTable = new MatTableDataSource<any[]>();

  public prodsDataDash: any;

  public events: any = {
    list: [],
    filtered: [],
    pageOptions: [25, 50, 100],
    pageSize: 50,
  };
  public periodsNumber: number = 8;
  public timeStatesList: any;
  public timeStateList: any;

  public cumulativeData: any;
  public punctualData: any;
  public tableInfos: any;
  public eventsColumns: any = [];
  public eventsData = new MatTableDataSource<any[]>();

  public filterTabsCopy: any;
  public filterTabs: any = [
    {
      "id": 1,
      "title": "CONTINUOUS_EXPLORATION.FILTERS.EVENT_TYPES",
      "icon": {
        "type": "icon",
        "icon": "filter_alt"
      },
      "toMap": "id",
      "toInclude": "filterId",
      "list": []
    },
    // {
    //   "id": 2,
    //   "title": "CONTINUOUS_EXPLORATION.FILTERS.STATES",
    //   "icon": {
    //     "type": "icon",
    //     "icon": "dehaze"
    //   },
    //   "toMap": "id",
    //   "toInclude": "state",
    //   "list": []
    // }
  ];
  public eventTypes: any = [];

  public enabledCSVDownload: any = 0;
  public atLeastOneFilter: any = false;
  public eventFilterOpened: any = false;
  public defaultEnableFilters: any = true;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  private sort!: MatSort;
  @ViewChild(MatSort) set MatSort(ms: MatSort) {
    this.sort = ms;
    this.setSort();
  }
  setSort() {
    this.eventsData.sort = this.sort;
  }

  public sectionName: any = "productionTraceability";
  public tabName: any = "productionTraceabilityDetail";
  public dashboardName: any = "production-traceability-detail";

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 7;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.initCardConfig, nextState: 5, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getDataPolling, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    private snackBar: MatSnackBar,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['LEAN_ANALYTICS.TITLE', 'LEAN_ANALYTICS.PRODUCTION_TRACEABILITY'];
    this.internalDataService.setCalendarPage(true);
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-la-traceability");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  initCardConfig(_this: any) {
    try {

      _this.tableInfos = _this.dashboardConfig?.tableInfos ?? _this.appConfig?.[_this.tabName]?.tableInfos;
      _this.cumulativeData = _this.dashboardConfig?.cumulativeData ?? _this.appConfig?.[_this.tabName]?.cumulativeData;
      _this.punctualData = _this.dashboardConfig?.punctualData ?? _this.appConfig?.[_this.tabName]?.punctualData;

      if (!_this.tableInfos?.length) {
        _this.configurationErrors.push({
          label: "La tabella di tracciamento degli eventi non è presente perché manca la chiave \"tableInfos\" dentro a \"appConfig.json/" + _this.tabName + "\""
        });
      }

      if (!_this.cumulativeData?.length) {
        _this.configurationErrors.push({
          label: "Il grafico \"Cumulative\" non è popolato perché manca la chiave \"cumulativeData\" dentro a \"appConfig.json/" + _this.tabName + "\""
        });
      }

      if (!_this.punctualData?.length) {
        _this.configurationErrors.push({
          label: "Il grafico \"Punctual\" non è popolato perché manca la chiave \"punctualData\" dentro a \"appConfig.json/" + _this.tabName + "\""
        });
      }

      if (_this.configurationErrors.length > 0) {
        _this.snackBar.open(_this.configurationErrors.map(x => "- " + x.label + ".").join("\n"), 'x', {
          horizontalPosition: 'right',
          verticalPosition: 'bottom',
          panelClass: ['snackbar', 'warning'],
          duration: 60000
        });
      }

      try { _this.timeStates = _this.clonerService.deepClone(_this.machine.profile.timeStates) } catch (error) { }

      if (_this.tableInfos != null && _this.tableInfos.length > 0) {
        _this.machine?.profile?.aggregations?.forEach((aggr: any) => {
          let idx = _this.tableInfos.findIndex((x: any) => x.variable == aggr.id);
          if (idx == -1) {
            _this.tableInfos.push({
              variable: aggr.id,
              label: _this.translate.instant(aggr.label),
              suffix: null
            });
          }
        });
        _this.eventsColumns.push('icon');
        _this.tableInfos.forEach((element: any) => _this.eventsColumns.push(element.variable));
      }

    } catch (error) {
      console.log(error);
      _this.tableInfos = [];
    }

    _this.dispatcherService.getDispatch(_this, 300);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // prods

  // polling
  getDataPolling(_this: any) {
    try { _this.getData(_this, 0) }
    catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get prods
  getData(_this: any, count?: any) {
    try {

      let url = '/apif/production-traceability/' + _this.machineId;

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      if (_this.machine.profile?.prodTraceVariables?.length > 0) payload.prodTraceVariables = _this.clonerService.deepClone(_this.machine.profile.prodTraceVariables);

      let query: any = {
        from: _this.urlStart,
        to: _this.urlEnd,
        tz: _this.machine.timezone,
      };

      _this.parsedTablesCopy = [];

      try {
        if (_this.machine.profile.tables != null) {
          _this.eventTypes = _this.clonerService.deepClone(_this.machine.profile.tables.map((x: any) => {
            return {
              selected: !x.defaultDisabled,
              label: _this.translate.instant(x.label),
              enabled: true,
              disabled: false,
              id: x.eventId,
              value: x.label,
            }
          }));

          _this.parsedTablesCopy = _this.clonerService.deepClone(_this.machine.profile.tables);
          payload.tables = _this.parsedTablesCopy;

        }
      } catch (error) {
        console.log(error);
      }

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      if (_this.urlMachineId) query.machineId = _this.urlMachineId;

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          async (data: any) => {
            // console.log(data);

            _this.prodsDataDash = data.body;

            // _this.prodsDataDash.from = _this.filterService.parseMoment(_this.urlStart, 'MMM DD, YYYY - HH:mm');
            // _this.prodsDataDash.to = _this.filterService.parseMoment(_this.urlEnd, 'MMM DD, YYYY - HH:mm');

            _this.prodsDataDash.from = _this.urlStart;
            _this.prodsDataDash.to = _this.urlEnd;

            _this.prodsDataDash.machineId = _this.urlMachineId;

            if (data.body != null && data.body.hasOwnProperty('timeserie')) {
              _this.prodsDataDash.detailData = _this.buildPlotConfig(_this, data.body);
              _this.parseProdsTable(_this, data.body);
            }

            if (_this.machine.profile?.addGanttInProductionTraceabilityDetail ?? _this.appConfig?.addGanttInProductionTraceabilityDetail) {
              let statesTimelineUrl = `/apif/machine-monitoring/states-timeline/${_this.machineId}`;

              query.from = _this.intervalService.formatStringDate(query.from, _this.machine.timezone)
              query.to = _this.intervalService.formatStringDate(query.to, _this.machine.timezone)

              let resp = null;
              try { resp = await this.apiService.sendPostRequestAwait(statesTimelineUrl, payload, query) }
              catch (error) { console.log(error) }

              _this.parseGantt(_this, resp?.body)
            }

            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // BUILD PLOTS

  // Apex gantt chart
  parseGantt(_this: any, data: any) {
    this.prodsDataDash.ganttData = this.filterService.parseGantt(_this, data, 450);
    // if (this.expandedModeSwitch.checked) this.prodsDataDash.ganttData.series = this.prodsDataDash.ganttData.seriesExpanded;
  }

  buildPlotConfig(_this: any, data: any) {

    let traces: any = [];

    // CUMULATIVE DATA
    // init prod cum data
    let prodCumData: any = {};
    _this.cumulativeData?.forEach(element => prodCumData[element.variable] = []);
    // populate prod cum data
    _this.cumulativeData?.forEach((element: any) => {

      if (!data.timeserie.kpis[element.variable]) return;
      if (element.toCumulate) {
        let totSum = 0;
        prodCumData[element.variable] = data.timeserie.kpis[element.variable].map((x: any) => totSum += x);
      } else {
        prodCumData[element.variable] = data.timeserie.kpis[element.variable];
      }
    });

    // init prod cum traces
    let prodCumTraces = _this.cumulativeData;
    if (prodCumTraces != null && Array.isArray(prodCumTraces) && prodCumTraces.length > 0) {
      // populate prod cum traces
      for (let tr of prodCumTraces) {
        traces.push({
          x: data.timeserie.timestamps,
          y: prodCumData[tr.variable]?.map(x => _this.filterService.convertUnit(_this.filterService.getProductionUnit(tr.yaxis == 'y2' ? 'avgProdAct' : 'totProdAct', _this.machine.profile.productionConfig), x).value),
          xaxis: 'x',
          yaxis: tr.yaxis,
          name: _this.translate.instant(tr.label),
          type: 'scatter',
          stackgroup: tr.stackgroup,
          mode: 'lines+markers',
          traceType: 'PRODUCTION_CUMULATIVE',
          traceVisibility: tr.visible,
          visible: tr.visible,
          // marker: { opacity: 0 },
          hoverinfo: "x+y+name",
          line: {
            dash: tr.dash,
            shape: 'spline',
            color: tr.color,
            width: 3
          },
          connectgaps: true,
        });
      }
    }

    // PUNCTUAL DATA
    // init prod punct data
    let prodPunctData: any = {};
    _this.punctualData?.forEach((element: any) => {
      prodPunctData[element.variable] = [];
    });
    // populate prod punct data
    _this.punctualData?.forEach((element: any) => {
      prodPunctData[element.variable] = data.timeserie.kpis[element.variable];
    });

    // init prod punct traces
    let prodPunctTraces = _this.punctualData;
    // populate prod punct traces

    if (prodPunctTraces != null && Array.isArray(prodPunctTraces) && prodPunctTraces.length > 0) {
      for (let tr of prodPunctTraces) {
        traces.push({
          x: data.timeserie.timestamps,
          // y: prodPunctData[tr.variable],
          y: prodPunctData[tr.variable]?.map(x => _this.filterService.convertUnit(_this.filterService.getProductionUnit(tr.yaxis == 'y2' ? 'avgProdAct' : 'totProdAct', _this.machine.profile.productionConfig), x).value),
          xaxis: 'x',
          yaxis: tr.yaxis,
          name: _this.translate.instant(tr.label),
          type: 'scatter',
          // fill: 'tonexty',
          mode: 'lines+markers',
          traceType: 'PRODUCTION_PUNCTUAL',
          traceVisibility: tr.visible,
          visible: false,
          // marker: { opacity: 0 },
          hoverinfo: "x+y+name",
          line: {
            dash: tr.dash,
            shape: 'spline',
            color: tr.color,
            width: 3
          },
          connectgaps: true,
        });
      }
    }

    // STATES 
    _this.clonerService.deepClone(_this.machine.profile.categories).forEach((cat: any) => {

      if (!data.timeserie?.states?.[cat.backendValueStateTimeline]) return;

      let ys = [];
      let hovertext = [];
      for (let i = 0; i < data.timeserie?.timestamps?.length; i++) {
        ys.push(_this.filterService.parseSecondsFromAggrUnit(_this.intervalAggregations.selected.unit, data.timeserie?.states?.[cat.backendValueStateTimeline][i]).value);
        hovertext.push(
          _this.translate.instant(cat.label) + ': ' + _this.filterService.parseDuration(data.timeserie?.states?.[cat.backendValueStateTimeline][i], 's', 'HH:mm:ss') +
          ' <br>' + _this.filterService.parseMoment(data.timeserie?.timestamps?.[i], 'default')
        )
      }

      traces.push({
        x: data.timeserie.timestamps,
        y: ys,
        name: _this.translate.instant(cat.label),
        xaxis: 'x',
        yaxis: 'y3',
        type: 'bar',
        visible: false,
        traceVisibility: true,
        legendgroup: cat.id,
        // timeAggrUnit: _this.intervalAggregations.selected,
        hovertext: hovertext,
        hoverinfo: 'text',
        traceType: 'STATES',
        marker: {
          color: cat.color,
          opacity: 0.9,
          line: {
            color: 'rgba(0,0,0,0.38)',
            width: 1
          }
        },
        connectgaps: true,
      });
    });

    // KPI - OEE
    let kpis = [];
    let kpisDefault = [
      { id: 'globalOee', label: "LEAN_ANALYTICS.GLOBAL_OEE" },
      { id: 'oee', label: "LEAN_ANALYTICS.OEE" },
      { id: 'availability', label: "LEAN_ANALYTICS.AVAILABILITY" },
      { id: 'quality', label: "LEAN_ANALYTICS.QUALITY" },
      { id: 'performance', label: "LEAN_ANALYTICS.PERFORMANCE" }
    ];

    if (_this.appConfig.MAT2) {

      _this.machine.profile?.defaultKpis?.forEach(kpi => {
        let kpiConfig = kpisDefault.find((x: any) => x.id == kpi.reference);
        if (kpiConfig != null) {
          kpis.push({
            id: kpiConfig.id,
            label: kpiConfig.label,
            type: 'OEE'
          });
        }
      });

    } else {

      if (_this.machine.profile.oeeKpis != null && Object.keys(_this.machine.profile.oeeKpis).length > 0) {
        for (const [key, value] of Object.entries(_this.machine.profile.oeeKpis)) {
          let ix = kpisDefault.findIndex((x: any) => x.id == key);
          if (ix != -1 && value) {
            kpis.push({
              id: kpisDefault[ix].id,
              label: kpisDefault[ix].label,
              type: 'OEE'
            });
          }
        }
      }
    }

    kpis.forEach(kpi => {
      traces.push({
        x: data.timeserie.timestamps,
        y: data.timeserie.kpis[kpi.id],
        xaxis: 'x',
        yaxis: 'y4',
        name: _this.translate.instant(kpi.label),
        type: "scatter",
        mode: 'lines+markers',
        traceType: kpi.type,
        traceVisibility: true,
        visible: false,
        // marker: { opacity: 0 },
        hoverinfo: "x+y+name",
        line: {
          shape: 'spline',
          color: _this.internalDataService.getKPIBarColor(kpi.id),
          width: 3
        },
        connectgaps: true,
      });
    });

    if (_this.machine.profile?.flags?.meterLineProdTraceDetail) {
      traces.push({
        xaxis: 'x2',
        yaxis: "y5",
        type: 'bar',
        x: [1, 4, 40],
        base: [20, 40, 0],
        name: ['Event', 'Scrap', 'Good'],
        y: ['Event', 'Scrap', 'Good'],
        marker: {
          color: ['black', 'red', 'green']
        },
        orientation: 'h',
        traceType: 'meterline',
        traceVisibility: true,
        visible: false,
        hoverinfo: "text",
        hovertext: [1, 4, 40].map((x, index) => "Meters: " + x + "<br>" + ((index == 0) ? 'Event Type: Recipe parameters change <br>From: param 1 - 30 kg <br>To: param 1 - 50kg' : '')),
      });
    }

    // UPDATE MENU BUTTONS
    let updatemenus: any = [
      {
        buttons: [
          {
            args: [
              {
                'visible': traces.map((x: any) => x.traceType == 'PRODUCTION_CUMULATIVE' ? x.traceVisibility : false),
                'mode': 'lines+markers',
              }, {
                'yaxis.visible': true,
                'yaxis2.visible': true,
                'yaxis3.visible': false,
                'yaxis4.visible': false,
                'yaxis5.visible': false,
                'xaxis2.visible': false,
              }],
            label: _this.translate.instant("LEAN_ANALYTICS.PRODUCTION_CUMULATIVE"),
            method: 'update'
          },
          {
            args: [
              {
                'visible': traces.map((x: any) => x.traceType == 'PRODUCTION_PUNCTUAL' ? x.traceVisibility : false),
                'mode': 'lines+markers',
              }, {
                'yaxis.visible': true,
                'yaxis2.visible': true,
                'yaxis3.visible': false,
                'yaxis4.visible': false,
                'yaxis5.visible': false,
                'xaxis2.visible': false,
              }],
            label: _this.translate.instant("LEAN_ANALYTICS.PRODUCTION_PUNCTUAL"),
            method: 'update'
          },
          {
            args: [
              {
                'visible': traces.map((x: any) => x.traceType == 'STATES' ? x.traceVisibility : false),
                'mode': 'lines+markers',
              }, {
                'yaxis.visible': false,
                'yaxis2.visible': false,
                'yaxis3.visible': true,
                'yaxis4.visible': false,
                'yaxis5.visible': false,
                'xaxis2.visible': false,
              }],
            label: _this.translate.instant("LEAN_ANALYTICS.STATES"),
            method: 'update'
          },
          {
            args: [
              {
                'visible': traces.map((x: any) => x.traceType == 'OEE' ? x.traceVisibility : false),
                'mode': 'lines+markers',
              }, {
                'yaxis.visible': false,
                'yaxis2.visible': false,
                'yaxis3.visible': false,
                'yaxis4.visible': true,
                'yaxis5.visible': false,
                'xaxis2.visible': false,
              }],
            label: _this.translate.instant("LEAN_ANALYTICS.OEE"),
            method: 'update'
          },
        ],
        direction: 'left',
        pad: {
          'r': 10,
          't': 10
        },
        showactive: true,
        type: 'buttons',
        x: 0.0,
        y: 1.4,
        xanchor: 'left',
        yanchor: 'top'
      }
    ];

    if (_this.machine.profile?.flags?.meterLineProdTraceDetail && updatemenus[0].buttons.findIndex(x => x.id == 'meterline') == -1) {
      updatemenus[0].buttons.push(
        {
          args: [
            {
              'visible': traces.map((x: any) => x.traceType == 'meterline' ? x.traceVisibility : false),
              'mode': 'lines+markers',
            }, {
              'yaxis.visible': false,
              'yaxis2.visible': false,
              'yaxis3.visible': false,
              'yaxis4.visible': false,
              'yaxis5.visible': true,
              'xaxis2.visible': true,
            }],
          label: _this.translate.instant("LEAN_ANALYTICS.METER_LINE"),
          id: "meterline",
          method: 'update'
        }
      )
    }

    let plotLayout = {
      showlegend: true,
      barmode: 'stack',
      height: 500,
      updatemenus: updatemenus,
      xaxis: {
        showgrid: false,
        zeroline: false,
      },
      xaxis2: {
        showgrid: false,
        zeroline: false,
        visible: false,
        overlaying: 'x',
        showticklabels: true
      },
      yaxis: {
        title: _this.filterService.getProductionUnit('totProdAct', _this.machine.profile.productionConfig, true),
        zeroline: false,
        showgrid: false
      },
      yaxis2: {
        title: _this.filterService.getProductionUnit('avgProdAct', _this.machine.profile.productionConfig, true),
        zeroline: false,
        showgrid: false,
        overlaying: 'y',
        side: 'right'
      },
      yaxis3: {
        title: {
          text: _this.filterService.parseSecondsFromAggrUnit(_this.intervalAggregations.selected.unit).unit,
          font: {
            size: 12
          }
        },
        zeroline: true,
        showgrid: false,
        visible: false,
        overlaying: 'y',
        // domain: [frac[machineIdx], frac[machineIdx + 1] - 0.05]
      },
      yaxis4: {
        range: [-0.05, 1.05],
        tickformat: '%',
        zeroline: false,
        showgrid: false,
        visible: false,
        overlaying: 'y'
      },
      yaxis5: {
        zeroline: false,
        showgrid: false,
        visible: false,
        overlaying: 'y'
      },
      margin: {
        t: 0,
        r: 60,
        b: 0,
        l: 60,
        pad: 5
      },
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: -0.3
      }
    };

    return {
      layout: plotLayout,
      traces: traces
    };
  }

  parseProdsTable(_this: any, data: any) {
    if (data != null && data.hasOwnProperty('table') && data.table != null &&
      Array.isArray(data.table) && data.table.length > 0) {

      let parsedTable: any = [];
      data.table.filter((x: any, index: any) => index < 100000).forEach((event: any) => {
        // data.table.forEach((event: any) => {
        // console.log(event);
        event = _this.getEventInfo(_this, event);
        event.timestampP = moment(event.timestamp).format("DD MMM YYYY HH:mm:ss.SSS");
        event.durationP = event.duration != null ? _this.filterService.parseTime(event.duration, 's', 'HH:mm:ss') : null;
        // event.eventName = event.eventId;
        // event.description = "Descrizione casuale";
        parsedTable.push(event);
      });

      _this.events.list = _this.clonerService.deepClone(parsedTable);

      _this.eventTypes = _this.clonerService.deepClone(_this.machine.profile.tables.map((x: any) => {
        return {
          selected: true,
          label: _this.translate.instant(x.label),
          enabled: true,
          disabled: false,
          id: x.eventId,
          value: x.label,
        }
      }));

    } else {
      try {
        _this.events.list = [];
      } catch (error) {
        console.log(error);
      }
    }

    _this.filterEvents();
  }

  filterEvents() {

    try { this.events.query.page = 1 } catch (error) { }

    let filtered: any = this.clonerService.deepClone(this.events.list);

    // Filter tabs
    this.filterTabs.forEach((tab: any) => {
      if (tab.list != null && tab.list.length > 0) {
        filtered = filtered.filter((x: any) => tab.list.filter((x: any) => x.enabled).map((event: any) => event[tab.toMap]).includes(x[tab.toInclude]))
      } else if (!this.defaultEnableFilters) {
        filtered = [];
      }
    });

    this.events.filtered = filtered;

    const cd = new MatTableDataSource<any[]>();

    cd.sort = this.sort;
    cd.paginator = this.paginator;
    cd.data = filtered;

    this.eventsData = cd;
  }

  getEventInfo(_this: any, event: any) { return _this.internalDataService.getEventInfo(_this, event) }

  openEventFilter() {

    this.filterTabsCopy = this.clonerService.deepClone(this.filterTabs);

    // console.log('filterTabsCopy', this.filterTabsCopy);
    this.filterTabsCopy[0].list = this.clonerService.deepClone(this.eventTypes);

    if (this.timeStatesList == null) {
      this.timeStatesList = this.clonerService.deepClone(this.timeStates.map((x: any) => {
        return {
          enabled: true,
          id: x.state != null && typeof x.state == 'string' && x.state.startsWith("timeState") ? (!isNaN(Number(x.state.split("timeState")[1])) ? Number(x.state.split("timeState")[1]) : x.state.split("timeState")[1]) : x.state,
          value: x.value,
          color: x.color
        };
      }));
    }

    try {
      let index = this.filterTabsCopy.findIndex((x: any) => x.id == 2);
      if (index != -1) {
        this.filterTabsCopy[index].list = this.clonerService.deepClone(this.timeStatesList);
      }
      // else {
      //   this.filterTabsCopy.splice(1, 0, {
      //     "id": 2,
      //     "title": this.translate.instant("CONTINUOUS_EXPLORATION.FILTERS.STATES"),
      //     "icon": {
      //       "type": "icon",
      //       "icon": "dehaze"
      //     },
      //     "toMap": "id",
      //     "toInclude": "state",
      //     list: this.clonerService.deepClone(this.timeStatesList),
      //   });
      // }
    } catch (error) { }

    const eventTypesFilterDialog = this.dialog.open(EventTypesFilterDialogComponent,
      {
        panelClass: 'ff-dialog',
        width: '30%',
        minHeight: '50%',
        data: {
          title: this.translate.instant("CONTINUOUS_EXPLORATION.ALL_FILTERS"),
          filterTabsCopy: this.filterTabsCopy
        },
      });

    eventTypesFilterDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {

        this.eventFilterOpened = true;

        this.filterTabs = this.clonerService.deepClone(this.filterTabsCopy);

        this.eventTypes = this.clonerService.deepClone(this.filterTabsCopy[0].list);

        // this.machine.profile.aggregations?.forEach((aggr:any, aggrIdx:any) => {
        //   this[aggr.id + "List"] = this.clonerService.deepClone(this.filterTabsCopy.find((x: any) => x.id == aggr.id).list);
        // });

        let idx = this.filterTabs.findIndex((x: any) => x.id == 2);
        if (idx != -1) {
          this.timeStatesList = this.clonerService.deepClone(this.filterTabs[idx].list);
        }

        this.cacheService.set("eventTypes", this.eventTypes);

        this.atLeastOneFilter = this.filterTabs.some((x: any) => x.id == 1 ? (x.list.some((y: any) => y.enabled == !this.defaultEnableFilters)) : x.list.some((y: any) => !y.enabled));

        this.filterEvents();

        try { this.events.query.page = 1 } catch (error) { }

      }
    })
  }

  removeAllFilters() {
    this.removeFilters(this);
  };

  removeFilters(_this: any, onlyTools?: any) {
    try {

      _this.filterTabs.filter((x: any) => onlyTools ? (typeof x.id == 'number' ? false : x.id.startsWith("aggr")) : true).forEach((tab: any) => {
        if (tab.list != null) tab.list.forEach((x: any) => x.enabled = (tab.id == 1 ? _this.defaultEnableFilters : true));
      });

      _this.eventTypes = _this.clonerService.deepClone(_this.filterTabs[0].list);
      // _this.toolList = _this.clonerService.deepClone(_this.filterTabs[1].list);

      // _this.machine.profile.aggregations.forEach((aggr: any, aggrIdx: any) => {
      //   _this[aggr.id + "List"] = _this.clonerService.deepClone(_this.filterTabs.find((x: any) => x.id == aggr.id).list);
      // });

      let idx = _this.filterTabs.findIndex((x: any) => x.id == 2);
      if (idx != -1) {
        _this.timeStatesList = _this.clonerService.deepClone(_this.filterTabs[idx].list);
      }

      _this.cacheService.set("eventTypes", _this.eventTypes);

      // _this.machine.profile.aggregations.forEach((aggr: any, aggrIdx: any) => {
      //   _this.cacheService.set(aggr.id + "List", null);
      // });

      _this.atLeastOneFilter = _this.filterTabs.some((x: any) => x.id == 1 ? (x.list.some((y: any) => y.enabled == !_this.defaultEnableFilters)) : x.list.some((y: any) => !y.enabled));

    } catch (error) {
      console.log(error);
    }
    _this.filterEvents();
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    let queryParams = this.route.snapshot.queryParams;

    this.backButton = [this.machineId, "lean-analytics", "production-traceability"];
    this.internalDataService.setBackButton(this.backButton);

    let addZ = '';

    try {
      this.urlStart = queryParams.start + addZ;
      this.urlEnd = queryParams.end + addZ;
    } catch (error) {
      console.log(error);
    }

    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
        let queryParams = this.route.snapshot.queryParams;

        try {
          this.urlStart = queryParams.start + addZ;
          this.urlEnd = queryParams.end + addZ;
          this.urlAggrUnit = queryParams?.aggrUnit;
          this.urlMachineId = queryParams.machineId;

          this.detailData = {
            detailStart: this.filterService.parseMoment(this.urlStart, 'default'),
            detailEnd: this.filterService.parseMoment(this.urlEnd, 'default')
          };

        } catch (error) {
          console.log(error);
        }

      }

    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    this.internalDataService.setCalendarPage(false);
    try {
      this.pageState.unsubscribe();
    } catch (error) { }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) { }
    try {
      this.internalDataService.setBackButton([]);
    } catch (error) { }
  }

}