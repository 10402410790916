import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'ff-available-machines-selection',
  templateUrl: './available-machines-selection.component.html',
  styleUrls: ['./available-machines-selection.component.scss']
})
export class AvailableMachinesSelectionComponent implements OnInit {

  @Input() availableMachines: any;
  @Output() selected = new EventEmitter<any>();

  machineId: any;
  machineSelectedSub: Subscription;

  constructor(
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public internalDataService: InternalDataService,
  ) {

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      try {
        if (Object.keys(value).length != 0) {
          let clonedMachine: any = this.clonerService.deepClone(value);
          this.machineId = clonedMachine?.machineId;
        }
      } catch (error) { console.log(error) }
    });
  }

  ngOnInit() {
    this.machineId = this.cacheService.get("machineId");
  }

  checkDisability(machine) {
    if (!this.availableMachines?.disabledList?.length) return false;
    return this.availableMachines?.disabledList?.includes(machine);
  }

  machineSelectionChange(machine: any) {

    let disabledList = this.availableMachines?.disabledList;

    if (!disabledList?.length || !disabledList?.includes(machine)) {
      if (machine != null) this.cacheService.set("selectedMachine", machine);
      this.selected.emit(machine);
    }
  }

}
