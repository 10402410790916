<div mat-dialog-title fxLayout="row" class="w100">

  <!-- icon -->
  <span *ngIf="dialog.icon != null" style="margin-right: 8px;" fxLayout="row" fxLayoutAlign="start center">

    <ff-icon [icon]="dialog.icon"></ff-icon>

  </span>

  <span fxLayout="column" fxLayoutAlign="center center">{{ dialog.title | translate }}</span>

  <span fxFlex></span>

  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>

</div>
<mat-dialog-content class="mat-typography attachment-button" style="min-height: calc(100vh - 300px);">

  <ng-container *ngIf="state == 0">
    <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
      <ff-loading-card></ff-loading-card>
    </div>
  </ng-container>

  <ng-container *ngIf="state == 1">

    <div fxLayout="column" fxLayoutAlign="space-between start" style="min-height: calc(100vh - 300px);">

      <!-- <ff-tabs [tabs]="tabs"></ff-tabs> -->

      <div fxFlex fxLayout="row" fxLayoutAlign="start start" class="w100 h100 tab-content-animated">
        <div *ngFor="let tab of tabs" fxFlex fxLayout="column" fxLayoutAlign="start start"
          class="tab-content transition-01 h100"
          [ngClass]="{'z-index-m1': tab.left || tab.right, 'translate-l100': tab.left, 'translate-r100': tab.right }">

          <div *ngIf="tab.id == 'form'" fxLayout="row" class="h100 w100" fxLayoutGap="16px">

            <!-- <div [fxFlex]="dialog.dialogType != 'edit' ? 50 : 100" class="w100 h100" fxLayout="column" -->
            <div [fxFlex]="50" class="w100 h100" fxLayout="column" fxLayoutAlign="start stretch">
              <div *ngFor="let info of dialog?.tableInfos | filterByProperty:['editableInDialog', '==', true]">

                <div fxLayout="row" fxLayoutAlign="center center" style="margin: 8px 0;"
                  *ngIf="dialog.row != null && checkShowCondition(info, dialog?.row)">

                  <span fxFlex="{{ dialog?.labelFlex ? dialog.labelFlex : 50}}" style="font-weight: 800;">
                    <span>{{ (info?.label != null ? info.label : '-') | translate }}</span>
                    <span *ngIf="info.unit && info.unitInTitle">&nbsp;[{{ info.unit | translate }}]</span>
                  </span>

                  <div fxFlex="{{ dialog?.labelFlex ? (100 - dialog.labelFlex) : 50}}">
                    <!-- TEMPLATE InfoValue -->
                    <ng-container [ngTemplateOutlet]="InfoValue"
                      [ngTemplateOutletContext]="{ info:info, item:dialog?.row }">
                    </ng-container>
                  </div>
                </div>
              </div>

              <div fxLayout="row" class="tip-button clickable" (click)="fileInput.click()">
                <div class="tip"><i class="fas fa-upload"></i></div>
                <div class="label">{{ 'MAINTENANCE_HISTORY.ATTACH_FILES' | translate }}</div>
              </div>

              <input hidden (change)="onFileSelected()" #fileInput type="file" id="file" [(ngModel)]="fileCopy"
                multiple>

              <!-- <pre>{{ dialog.row | json }}</pre> -->
              <div *ngIf="errors?.length > 0" fxLayout="column" fxLayoutAlign="start end" style="margin-top: 36px;">
                <span *ngFor="let error of errors" class="error">{{ error?.label }}</span>
              </div>
            </div>

            <ff-widget fxFlex="50" class="h100 w100" *ngIf="dialog.filesTableConfig?.data?.selectedFiles?.length"
              [widget]="dialog.filesTableConfig" (clicked)="onButtonClick($event)">
            </ff-widget>

          </div>

          <div *ngIf="tab.id == 'activities'" fxLayout="row" class="h100 w100">

            <div fxFlex="50" class="w100 h100">

              <ff-widget *ngIf="dialog.activitiesTableConfig != null" (clicked)="onButtonClick($event)"
                [widget]="dialog.activitiesTableConfig">
              </ff-widget>
            </div>

            <ff-widget fxFlex="50" (clicked)="onButtonClick($event)" [widget]="dialog.activitiesSelectionWidget"
              class="w100 h100">
            </ff-widget>

          </div>

          <!-- replaced components -->

          <div *ngIf="tab.id == 'components'" fxLayout="row" class="h100 w100">

            <div fxFlex="50" class="w100 h100">

              <ff-widget *ngIf="dialog.componentsTableConfig != null" (clicked)="onButtonClick($event)"
                [widget]="dialog.componentsTableConfig">
              </ff-widget>
            </div>

            <ff-widget fxFlex="50" (clicked)="onButtonClick($event)" [widget]="dialog.componentsSelectionWidget"
              class="w100 h100">
            </ff-widget>

          </div>

        </div>
      </div>
    </div>
  </ng-container>

</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">

  <div *ngIf="checkDisability()" style="margin-right: 8px;" matTooltipClass="ff-tooltip"
    matTooltip="{{ 'GLOBAL.FILL_ALL_FIELDS_TO_CONTINUE' | translate }}" matTooltipPosition="left">
    <ff-icon [icon]="{'type': 'icon', 'icon': 'warning', 'class': 'md-orange'}"></ff-icon>
  </div>

  <button *ngIf="!tabs[0].selected" class="filter-button completely"
    (click)="selectTab(tabs[2].selected ? tabs[1] : tabs[0])"
    [ngClass]="{'ff-disabled': tabs[0].selected || checkDisability() }">
    <ff-icon icon="arrow_back"></ff-icon>
  </button>

  <button *ngIf="!tabs[2].selected" class="filter-button completely"
    (click)="selectTab(tabs[1].selected ? tabs[2] : tabs[1])"
    [ngClass]="{'ff-disabled': tabs[2].selected || checkDisability()}">
    <ff-icon icon="arrow_forward"></ff-icon>

  </button>

  <button *ngIf="tabs[2].selected" class="filter-button md-gray" (click)="closeDialog()"
    [disabled]="checkDisability() || errors.length > 0 || state == 0">
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</mat-dialog-actions>


<!-- TEMPLATE InfoValue -->
<ng-template #InfoValue let-info="info" let-item="item">

  <div class="input-box" *ngIf="info.dialogEditType == 'inputText'"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <input matInput class="ff-input text w100" type="text" [matAutocomplete]="auto" [disabled]="info.disabledInDialog"
      [(ngModel)]="item[info.variable]">
    <mat-autocomplete #auto="matAutocomplete">
      <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let option of info.autocomplete; let i = index"
        [attr.data-index]="i">
        <mat-option style="padding-right: 0px" fxFlex [value]="option">
          <div>{{ option | translate }}</div>
        </mat-option>
      </div>
    </mat-autocomplete>
  </div>

  <div *ngIf="info.dialogEditType == 'inputNum'" class="input-box"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <input matInput class="ff-input number w100" type="number" [min]="0" [(ngModel)]="item[info.variable]"
      [disabled]="info.disabledInDialog">
  </div>

  <div *ngIf="info.dialogEditType == 'closedList'" class="ff-input-select w100" fxLayout="row"
    fxLayoutAlign="start center">
    <mat-select [(value)]="item[info.variable]" [disabled]="info.disabledInDialog || info.listOptions?.length == 0">
      <mat-option *ngIf="info.addNullValue" [value]="null">---</mat-option>
      <ng-container *ngIf="info.closedListType == null">
        <mat-option *ngFor="let opt of info.listOptions" [value]="opt">
          {{ opt }}
        </mat-option>
      </ng-container>
      <ng-container *ngIf="info.closedListType == 'arrayOfObjects'">
        <mat-option *ngFor="let opt of info.listOptions" [value]="opt.id">
          {{ filterService.translateElementNewFormat(opt) }}
        </mat-option>
      </ng-container>
    </mat-select>
  </div>

  <div *ngIf="info.dialogEditType == 'inputDateTime'">
    <input matInput class="ff-input datetime w100" type="datetime-local" [max]="nowTime"
      [(ngModel)]="item[info.variable]">
  </div>

  <div *ngIf="info.dialogEditType == 'inputDate'" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center" style="margin-right: 8px;">

    <div class="w100" fxLayout="row" fxLayoutAlign="start center">
      <!-- <mat-icon class="icon">event_available</mat-icon> -->

      <mat-form-field appearance="fill" class="w100">
        <!-- <mat-label>{{ 'CONSUMABLES_SETTINGS.TRAINING_END' | translate }}</mat-label> -->
        <input matInput [matDatepicker]="pickerEnd" [value]="item[info.variable]" disabled
          (dateChange)="onDaySelection($event, info.variable)">
        <mat-datepicker-toggle matSuffix [for]="pickerEnd">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #pickerEnd disabled="false"></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <textarea *ngIf="info.dialogEditType == 'textarea'" rows="1" style="max-height: 160px; min-height: 160px;"
    maxlength="250" class="ff-textarea" [(ngModel)]="item[info.variable]"
    oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"' name="textarea" id="textarea">
  </textarea>
  <span *ngIf="info.dialogEditType == 'textarea'">{{item[info.variable] != null ? item[info.variable]?.length : 0}} /
    250</span>

  <div *ngIf="info.dialogEditType == 'inputDuration'">
    <ff-duration-input format="HH:mm" unit="s" [(value)]="item[info.variable]"></ff-duration-input>
  </div>

  <div *ngIf="info.requiredInDialog && item[info.variable] == null" class="required">
    <span>{{ 'GLOBAL.REQUIRED' | translate }}</span>
  </div>

</ng-template>