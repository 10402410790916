import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { CacheService } from 'src/app/services/cache.service';
import { MobileService } from 'src/app/services/mobile.service';
import { StandardDialogServiceService } from 'src/app/services/standard-dialog.service';

@Component({
  selector: 'app-consumption-supply',
  templateUrl: './consumption-supply.component.html',
  styleUrls: ['./consumption-supply.component.scss']
})
export class ConsumptionSupplyComponent implements OnInit, OnDestroy {

  public isAllowedUser: boolean = true;
  public isSupplier: boolean = false;
  public isClient: boolean = true;
  public isSuperClient: boolean = false;

  public userData: any;

  public configurationErrors: any = [];

  public loadingData: any;
  public errorData: any;
  public errorDataMobile: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public customPlantId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public pollingTime: any;
  public pollingProds: any;
  public pollingTimeAlerts: any;
  public pollingAlerts: any;

  public intervalAggrList: any;

  public interval: any;
  public intervalConfig: any;

  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;

  public intervalAggregations: any;
  public aggregationsTime: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public dashboardConfig: any;
  public dashboardNameComplete: any;
  public prodsDataDash: any;

  public materialsDropdown: any;

  public materials: any = [];
  public emptySupplierMailingList: any = false;
  public supplierMailingList: any = [];
  public superClientMailingList: any = [];
  public clientMailingList: any = [];
  public warningList: any = [];

  public tabName: any = "supply";
  public sectionName: any = "consumptionAnalytics";
  public dashboardName: any = "consumption-supply";
  public permission: any = "mat-cm-cs";

  public mobileListener: Subscription;
  public isMobile: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 7;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getConsumptionSupplySettings, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getAlertsPolling, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.getDataPolling, nextState: 7, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];


  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    private router: Router,
    public mobile: MobileService,
    public standardDialogServiceService: StandardDialogServiceService,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })

    this.breadcrumb = ['CONSUMPTION_ANALYTICS.TITLE', 'CONSUMPTION_SUPPLY.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig?.[this.tabName]?.pollingTime ?? 0;
    this.pollingTimeAlerts = this.appConfig?.[this.tabName]?.pollingTimeAlerts ?? 300000;
    this.pollingProds = Subscription;
    this.pollingAlerts = Subscription;

    this.intervalAggrList = this.appConfig?.[this.tabName]?.intervalAggrList;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission(_this.permission);
    } catch (error) { console.log(error) }

    try {
      _this.isSupplier = _this.internalDataService.getSpecificPermission("base-supplier", 'scopes');
    } catch (error) { console.log(error) }

    try {
      _this.isClient = _this.internalDataService.getSpecificPermissions(["base-customer", "super-customer"], 'or', 'scopes');
    } catch (error) { console.log(error) }

    try {
      _this.isSuperClient = _this.internalDataService.getSpecificPermission("super-customer", 'scopes');
    } catch (error) { console.log(error) }


    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {

      let tableType = "ff-multiple-tables";
      if (_this.dashboardConfig?.widgets?.findIndex(x => x.type == tableType) == -1) {
        _this.configurationErrors.push({ label: "The table is not present because in the file \"" + _this.dashboardNameComplete + "\" there is no widget of type \"" + tableType + "\"" })

      }
      if (_this.configurationErrors.length > 0) {
        _this.internalDataService.openSnackBar(_this.configurationErrors.map(x => "- " + x.label + ".").join("\n"), 'right', 'bottom', 60000, 'x', ["warning"]);
      }
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET MACHINE SETTINGS
  public getConsumptionSupplySettings(_this: any) {
    try {

      _this.customPlantId = `${(_this.machine?.parentId ?? 'unknown')}/${(_this.machine?.plant ?? 'unknown')}`;

      let query: any = {};

      let url = `/apif/consumption-supply/settings/${_this.customPlantId}/${(_this.machine?.customer ?? 'unknown')}`;

      _this.apiService.sendGetRequest(url, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy()),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data.body);
            _this.materials = _this.clonerService.deepClone(data.body?.materials ?? []);
            _this.clientMailingList = _this.clonerService.deepClone(data.body?.clientMailingList ?? []);
            _this.superClientMailingList = _this.clonerService.deepClone(data.body?.superClientMailingList ?? []);
            _this.supplierMailingList = _this.clonerService.deepClone(data.body?.supplierMailingList ?? []);

            _this.warningList = [];
            // if (_this.clientMailingList?.length == 0) _this.warningList.push(_this.translate.instant("CONSUMPTION_SUPPLY.EMPTY_CLIENT_LIST"));
            if (_this.supplierMailingList?.length == 0) {
              _this.emptySupplierMailingList = true;
              _this.warningList.push(_this.translate.instant("CONSUMPTION_SUPPLY.EMPTY_SUPPLIER_LIST"));
            }

            _this.dispatcherService.getDispatch(_this, 300);

          },
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) {
    try { this.pollingProds.unsubscribe() } catch (error) { }
    this.intervalService.selectInterval(this, interval, this.pollingAlerts, this.getAlertsPolling, this.getAlerts, this.machine.timezone, 6, 7)
  };

  machineSelectionChange(machine: any) {
    if (machine != null) {

      try { this.pollingAlerts.unsubscribe() } catch (error) { }
      try { this.pollingProds.unsubscribe() } catch (error) { }

      this.pageState.next(6);
      this.getAlertsPolling(this);
    }
  }

  changeMaterial(materialsDropdown: any) {

    this.materialsDropdown = this.clonerService.deepClone(materialsDropdown);

    try { this.pollingAlerts.unsubscribe() } catch (error) { }
    try { this.pollingProds.unsubscribe() } catch (error) { }

    this.pageState.next(6);
    this.getAlertsPolling(this);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // prods

  // polling
  getAlertsPolling(_this: any) {
    try {

      if (_this.materialsDropdown == null) _this.materialsDropdown = _this.clonerService.deepClone(_this.materials)?.[0]?.id;

      if (_this.interval == null) {
        if (_this.cacheService.get("intervalLong") == null) {

          _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

          _this.intervalConfig = {
            list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
            selected: _this.interval
          };

          _this.cacheService.set("intervalLong", _this.intervalConfig);

        } else {
          _this.intervalConfig = _this.cacheService.get("intervalLong");
          _this.interval = _this.cacheService.get("intervalLong").selected;
        }
      }

      if (_this.pollingTimeAlerts > 0) _this.pollingAlerts = timer(0, _this.pollingTimeAlerts).subscribe(count => _this.getAlerts(_this, count));
      else _this.getAlerts(_this, 0);

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get prods
  getAlerts(_this: any, count?: any) {
    try {

      let url = '/apif/consumption-supply/alerts/' + _this.customPlantId;

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone,
        material: _this.materialsDropdown,
      };

      let payload = {};

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.prodsDataDash = Object.assign(_this.clonerService.deepClone(_this.prodsDataDash ?? {}), {
              alerts: _this.clonerService.deepClone(_this.parseProdsTable(data.body)),
            });

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // polling
  getDataPolling(_this: any) {
    try {

      if (_this.pollingTime > 0) _this.pollingProds = timer(0, _this.pollingTime).subscribe(count => _this.getData(_this, count));
      else _this.getData(_this, 0);

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get prods
  getData(_this: any, count?: any) {
    try {

      if (count == 0 && _this.intervalAggrList?.length > 0) _this.internalDataService.forceAggregationList(_this, _this.intervalAggrList);

      let url = '/apif/consumption-supply/infos/' + _this.customPlantId;

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone,
        material: _this.materialsDropdown,
      };

      let payload = {};

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.prodsDataDash = Object.assign(_this.clonerService.deepClone(_this.prodsDataDash ?? {}), _this.clonerService.deepClone(data.body));

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      console.log(errorData);

      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseProdsTable(data: any) {
    if (data != null && data.length > 0) {

      data.forEach(element => {
        element.eventTypeP = this.internalDataService.parseConsSupplyAlertsLabel(element.type != null ? String(element.type) : "-", null, this.machineId);
        element.eventType = element.acknowledged ? 'acknowledged' : element.type;
        // Disable acknowledge if alert is not active
        element.disabledButtons = {
          acknowledgeAlert: element.acknowledged || this.getAdditionalActionsAndDisabilityFromType(element.type).disabled,
        }
      });

      return data;
    }
    return []
  }


  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CLICK BUTTON
  onButtonClick(button) {
    console.log(button);

    if (button?.buttonInfos?.clickFunction != null) {
      let buttonCopy: any = this.clonerService.deepClone(button);
      let clickFunction = buttonCopy?.buttonInfos?.clickFunction;
      switch (clickFunction) {
        case 'acknowledgeAlert':
          if (this.isClient && !this.isSuperClient && this.superClientMailingList?.length > 0) buttonCopy.row.mailingListName = "superClientMailingList";
          this.confirm(buttonCopy);
          break;
        case 'removeRefill':
          this.confirm(buttonCopy);
          break;
        case 'sendOrderRequest':
        case 'addRefill':
          if (buttonCopy?.row?.material == null) buttonCopy.row.material = this.materialsDropdown;
          if (buttonCopy?.row?.materialName == null) buttonCopy.row.materialName = this.materials?.find(x => x.id == this.materialsDropdown)?.label;
          if (buttonCopy?.row?.materialUnit == null) buttonCopy.row.materialUnit = this.materials?.find(x => x.id == this.materialsDropdown)?.unit;
          if (buttonCopy?.row?.user == null) buttonCopy.row.user = this.userData?.user ?? 'unknown';
          if (buttonCopy?.row?.machineId == null) buttonCopy.row.machineId = this.machineId;
          buttonCopy.buttonInfos.reload = true;

          // Add correct mailing list
          if (clickFunction == 'sendOrderRequest' && this.isClient && !this.isSuperClient && this.superClientMailingList?.length > 0) buttonCopy.row.mailingListName = "superClientMailingList";
          buttonCopy?.tableInfos?.filter(x => x.dynamicUnit)?.forEach(info => info.unit = this.materials?.find(x => x.id == this.materialsDropdown)?.unit);
          this.standardDialogServiceService.addRow(this, buttonCopy);
          break;
      }
    };

  }

  // Confirm dialog
  confirm(button: any) {

    let row = button?.row;

    let alertId = row?.alertId;
    let type = row?.type;

    let additionalActions: any = this.getAdditionalActionsAndDisabilityFromType(type).additionalActions;

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: Object.assign({
        title: this.translate.instant(button?.buttonInfos?.confirmTitle ?? 'CONSUMPTION_SUPPLY.ACKNOWLEDGE_ALERT'),
        description: row?.eventTypeP,
        confirmLabel: button?.buttonInfos?.confirmDeleteLabel ?? 'GLOBAL.ACKNOWLEDGE',
        cancelClass: 'md-red',
        confirmClass: 'md-green',
      }, additionalActions),
    });

    confirmationDialog.afterClosed().subscribe((result: any) => {
      if (result?.additionalAction && additionalActions?.additionalButtonAfterAction != null) button.buttonInfos = button?.tableConfig?.additionalTableButtons?.find(x => x.clickFunction == additionalActions.additionalButtonAfterAction);
      if (result != null && result != '') this.acknowledgeAlert(alertId, result?.additionalAction, button);
    });

  }

  // Send order request dialog
  openSendOrderRequestDialog(row: any = {}) {

    let alertId = row?.alertId;
    let type = row?.type;

    let additionalActions = this.getAdditionalActionsAndDisabilityFromType(type).additionalActions;

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: Object.assign({
        title: this.translate.instant('CONSUMPTION_SUPPLY.ACKNOWLEDGE_ALERT'),
        confirmLabel: 'GLOBAL.ACKNOWLEDGE',
        cancelClass: 'md-red',
        confirmClass: 'md-green',
      }, additionalActions),
    });

    confirmationDialog.afterClosed().subscribe((result: any) => {
      console.log(result);

      // if (result != null && result != '') this.acknowledgeAlert(alertId, result?.additionalAction);
    });

  }

  getAdditionalActionsAndDisabilityFromType(type) {

    let additionalActions = {};
    let disabled = false;

    switch (String(type ?? '5')) {
      case '1':
      case '2':
        if (this.isClient) additionalActions = {
          additionalButton: true,
          additionalButtonAfterAction: "sendOrderRequest",
          confirmClass: 'md-blue',
          confirmAdditionalActionLabel: 'CONSUMPTION_SUPPLY.ACKNOWLEDGE_AND_REORDER',
          confirmAdditionalActionClass: 'md-green',
        };
        break;
      case '3':
        if (this.isClient) {
          additionalActions = {
            additionalButton: true,
            additionalButtonAfterAction: "addRefill",
            confirmClass: 'md-blue',
            confirmAdditionalActionLabel: 'CONSUMPTION_SUPPLY.ACKNOWLEDGE_AND_REFILL',
            confirmAdditionalActionClass: 'md-green',
          };
        } else {
          disabled = true;
        }
        break;
      case '6':
        disabled = !this.isSuperClient;
        break;
      default:
        disabled = this.isClient || !this.isSupplier;
        break;
    }

    return {
      disabled: disabled,
      additionalActions: additionalActions,
    };

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // ACKNOWLEDGE ALERT
  acknowledgeAlert(alertId: any, additionalAction: any = false, button?: any) {

    let url = '/apif/consumption-supply/acknowledge-alert/' + this.customPlantId + '/' + alertId;

    if (alertId == null) {

      if (button?.buttonInfos?.clickFunction == 'removeRefill') {
        url = '/apif/consumption-supply/remove-refill/' + this.customPlantId + '/' + button?.row?.timestamp;
      } else {
        this.internalDataService.openSnackBar('Alert Id is not provided', 'right', 'bottom', 4000, '', ["warning"]);
        return;
      }

    }

    try {

      let query: any = {
        user: this.userData?.user ?? 'unknown',
      };

      if (button?.row?.eventType != null) query.eventType = button.row.eventType;

      let _this = this;

      _this.apiService.sendPostRequest(url, {}, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {

            _this.internalDataService.openSnackBar('Request failed', 'right', 'bottom', 4000, '', ["fail"]);
            return _this.internalDataService.parseStandardHTTPError(_this, error);
          }))
        .subscribe(
          (data: any) => {
            // console.log(data.body);
            _this.internalDataService.openSnackBar('Request succeeded', 'right', 'bottom', 4000, '', ["success"]);

            this.pageState.next(6);
            this.getAlertsPolling(this);

            if (additionalAction) _this.onButtonClick(button);
            else { window.location.reload(); }

          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      console.log(errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);
  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingProds.unsubscribe() } catch (error) { }
    try { this.pollingAlerts.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
  }

}