<div class="sidenav-back-layer clickable transition-01" *ngIf="isSmThanTablet && !openedSidenav()"
  (click)="closeSidenav()">

</div>
<div class="sidenav-container transition-01" [ngClass]="{'expanded': expanded}" fxLayout="column"
  fxLayoutAlign="start start" *ngIf="!hide">

  <!-- header -->
  <div class="header" fxLayout="row">

    <!-- app logo -->
    <div class="logo" fxFlex fxLayout="row"
      [fxLayoutAlign]="appConfig?.centeredLogo ? 'center center' : 'start center'">

      <ng-container *ngIf="logoFormat == 'svg'">
        <mat-icon svgIcon="logo-01" class="logo-icon"></mat-icon>
      </ng-container>

      <ng-container *ngIf="logoFormat != 'svg'">
        <img class="big transition-01" [src]="'assets/images/logo-01.' + logoFormat">
        <img class="sml transition-01" [src]="'assets/images/logo-02.' + logoFormat">
      </ng-container>

    </div>

    <!-- toggle sidenav button -->
    <button fxHide.gt-sm="true" mat-icon-button class="sidenav-close" (click)="closeSidenav()" aria-label="close">
      <i class="fas fa-times fa-lg icon"></i>
    </button>

  </div>

  <!-- list -->
  <div class="list" fxFlex>

    <!-- item button -->
    <div *ngFor="let item of sidenavItems">
      <button mat-button *ngIf="!isMobile || (isMobile && !item.hideMobile)" class="transition-01"
        [routerLink]="item.urlArray" [queryParams]="item.queryParams"
        [class.selected]="isLinkActive(item.url, item.queryParamActive, item.queryParams)" [disabled]="item._disabled"
        (click)="closeSidenav()">
        <!-- item content -->
        <div class="item-content" fxLayout="row" fxLayoutAlign="center center">
          <!-- border -->
          <div class="border"></div>
          <!-- icon -->
          <ff-icon [icon]="item?.icon" marginLeft marginRight></ff-icon>
          <!-- label -->
          <span class="label">{{ item.title | translate }}</span>
        </div>
      </button>
    </div>
  </div>

  <!-- header -->
  <div class="header" fxLayout="row" *ngIf="appInfo != null && appInfo.impact">

    <!-- app logo -->
    <div class="logo" fxFlex fxLayout="row" fxLayoutAlign="start center">
      <img class="big transition-01" src="assets/images/logo-impact-01.png">
      <img class="sml transition-01" src="assets/images/logo-impact-02.png">
    </div>

    <!-- toggle sidenav button -->
    <button fxHide.gt-sm="true" mat-icon-button class="sidenav-close" (click)="closeSidenav()" aria-label="close">
      <i class="fas fa-times fa-lg icon"></i>
    </button>

  </div>

  <!-- footer -->
  <div class="footer">

    <button *ngFor="let item of selectedItems" class="card selected" fxLayout="column" [matTooltip]="item.value"
      [matTooltipDisabled]="expanded">

      <div class="label" fxLayout="row" fxLayoutAlign="start center">

        <span style="padding-right: 12px;">{{ item.label | translate }}</span>

        <!-- <ng-container *ngIf="assetsList?.length > 1">
          <button class="rounded-button" (click)="navigateToMachine(true)"
            matTooltip="{{ 'GLOBAL.NAVIGATE_TO_PREVIOUS_MACHINE' | translate }}"
            style="padding: 0; min-width: 0; margin-right: 2px;">
            <mat-icon class="icon" aria-label="expand">chevron_left</mat-icon>
          </button>
  
          <button class="rounded-button" (click)="navigateToMachine()" style="padding: 0; min-width: 0;"
            matTooltip="{{ 'GLOBAL.NAVIGATE_TO_NEXT_MACHINE' | translate }}">
            <mat-icon class="icon" aria-label="expand">chevron_right</mat-icon>
          </button>
        </ng-container> -->

      </div>

      <div fxLayout="row">
        <div class="value" fxFlex>
          {{ item.value }}
        </div>
      </div>

    </button>

  </div>

  <div id="toggle-sidenav-column" (click)="toggleExpanded()" class="clickable transition-01" fxLayout="column"
    fxLayoutAlign="center center" *ngIf="!isSmThanTablet">
    <mat-icon *ngIf="expanded" class="icon" aria-label="expand">chevron_left</mat-icon>
    <mat-icon *ngIf="!expanded" class="icon" aria-label="collaps">chevron_right</mat-icon>
  </div>

</div>