<div mat-dialog-title fxLayout="row" class="w100">
    <span>{{ dialog.title }}</span>
    <span fxFlex></span>
    <button mat-dialog-close class="close">
        <mat-icon class="icon" translate-attr="close">close</mat-icon>
    </button>
</div>
<mat-dialog-content class="mat-typography">

    <recursive-list [items]="hierarchy" [hierarchy]="hierarchy" (onComponentSelection)="createSelectedAssets()">
    </recursive-list>

</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">

    <button mat-button *ngIf="!selectedAssets?.length" class="md-orange circular-icon-button not-clickable left"
        #tooltip="matTooltip" matTooltip="{{ 'CROSS_KPI.SELECT_AT_LEAST_ONE_ASSET' | translate }}"
        matTooltipPosition="left">
        <mat-icon class="icon">warning</mat-icon>
    </button>

    <button class="filter-button md-primary" (click)="closeDialog()" [disabled]="!selectedAssets?.length">
        {{ 'GLOBAL.CONFIRM' | translate }}
    </button>

</mat-dialog-actions>