<div *ngFor="let item of items">

    <div [style.padding-left]="getPaddingLeft(item.level)" style="padding-bottom: 8px;" class="clickable"
        (click)="setSelected(item)">
        <mat-icon [svgIcon]="item.selected ? 'radio_button' : 'radio_button_disabled'"
            class="icon left {{ item.selected ? 'radio_button' : 'radio_button_disabled' }} completely">
        </mat-icon>

        <span>{{ item.label ?? item.id ?? '-' }}</span>
    </div>

    <ng-container *ngIf="item.elements">
        <recursive-list [items]="item.elements" [hierarchy]="hierarchy"></recursive-list>
    </ng-container>
</div>