<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title | translate }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>

<mat-dialog-content class="mat-typography">

  <div fxLayout="row" class="attachment-button clickable" (click)="fileInput.click()">
    <div class="tip"><i class="fas fa-upload"></i></div>
    <div class="label">{{ 'GLOBAL.CHOOSE_FILE' | translate }}</div>
  </div>
  <input hidden (change)="onFileSelected()" #fileInput type="file" id="file" [(ngModel)]="fileCopy"
    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
  <!-- <input type="file" [(ngModel)]="fileCopy"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"> -->

  <ff-loading-card *ngIf="dialogState == 0"></ff-loading-card>

  <div *ngIf="fileInfos != null">
    <div fxLayout="row" fxLayoutAlign="start center">
      <span style="font-weight: 600;">{{ 'GLOBAL.FILE_NAME' | translate }}</span>
      :&nbsp;<span>{{ fileInfos.name }}</span>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">
      <span style="font-weight: 600;">{{ 'GLOBAL.FILE_SIZE' | translate }}</span>
      :&nbsp;<span>{{ fileInfos.sizeP }}</span>
    </div>
  </div>

  <ff-error-card *ngIf="dialogState === 2" [errorData]="errorData"></ff-error-card>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button [disabled]="fileInfos == null || dialogState == 0" class="filter-button" (click)="sendExcelFile()">
    <span>{{ 'GLOBAL.CONFIRM' | translate }}</span>
  </button>
</mat-dialog-actions>