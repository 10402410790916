<div class="hm-container-card"
  [ngClass]="{'anomalous': compData.anomalyType == 1, 'not-evaluated': compData.anomalyType == 2 }">

  <!-- header -->
  <div class="header" fxLayout="row">

    <!-- icon -->
    <ff-rounded-icon-button *ngIf="compData != null && compData.icon != null" [inputData]="compData.icon">
    </ff-rounded-icon-button>

    <!-- title -->
    <div class="title" fxFlex>{{ lineMode ? compData.machineName + ' - ' : '' }}{{ compData.componentName }}</div>

  </div>

  <!-- dashboard -->
  <div fxLayout="column" [fxLayout.gt-lg]="cardAlign" fxLayoutAlign="start center" fxLayoutGap="8px">

    <!-- image -->
    <ng-container *ngIf="!dashboardConfig?.removeImage">
      <div class="image" [fxFlex.gt-sm]="imageFlex"><img [src]="compData.imagePath"></div>
    </ng-container>

    <!-- anomalyDetection available -->
    <div *ngIf="compData.anomalyDetection" fxFlex fxLayout.lt-md="column" class="w100" fxLayout="row wrap"
      fxLayoutAlign="start stretch" fxLayoutGap.lt-sm="8px"
      fxLayoutGap.gt-sm="{{ dashboardConfig.gap != null ? (dashboardConfig.gap + ' grid') : '8px grid' }}">
      <ff-widget *ngFor="let w of dashboardConfig.widgets"
        [fxFlex.gt-sm]="'calc(' + w.flex + '% - ' + (dashboardConfig.gap != null ? dashboardConfig.gap : '8px') + ')'"
        [fxShow.lt-md]="!w.hideMobile" [fxFlex.sm]="'calc(' + w.mobileFlex + '% - 8px )'"
        [fxFlexOrder.sm]="w.mobileOrder"
        [widget]="{ data: clonerService.deepClone(compData), type: w.type, subtype: w.subtype, class: w.class, config: w.config, title: w.title, customHeight: w.customHeight, customMinHeight: w.customMinHeight }">
      </ff-widget>
    </div>

    <!-- anomalyDetection not available -->
    <div fxFlex *ngIf="!compData.anomalyDetection" class="not-found" fxLayout="column" fxLayoutAlign="center center">
      <mat-icon svgIcon="sad_face" class="icon"></mat-icon>
      <span translate="HEALTH_MONITORING.NO_AD" style="margin-top: 8px;"></span>
    </div>

  </div>

</div>