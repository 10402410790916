<div fxLayout="row" fxLayoutAlign="start center" style="padding: 1rem;" class="w100">
  <span class="title">{{ dialog.title | translate }}</span>
  <span fxFlex></span>
  <button (click)="close()" class="button-1">
    <ff-icon [icon]="'close'"></ff-icon>
  </button>
</div>

<mat-dialog-content fxLayout="row" fxLayoutAlign="center center">

  <img *ngIf="imageType == 'url'" onerror="this.src=''" [src]="dialog.imageUrl">
  <img *ngIf="imageType == 'arrayBuffer'" onerror="this.src=''"
    [src]="sanitize('data:image/jpg;base64, ' + _arrayBufferToBase64(dialog.imageUrl))">
</mat-dialog-content>