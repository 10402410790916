import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-maintenance-intervention-dialog',
  templateUrl: './maintenance-intervention-dialog.component.html',
  styleUrls: ['./maintenance-intervention-dialog.component.scss']
})
export class MaintenanceInterventionDialogComponent implements OnInit, OnDestroy {

  public state: any = 0;
  public statesConfig: any = {
    replaceCauses: 1,
    activitiesSelectionWidget: 1,
    componentsSelectionWidget: 1,
    componentsTable: 1,
    fileUpload: 1,
    existingFiles: 1,
  };
  public replaceCauses: any = [];
  public showWidget: any = false;
  public nowTime: any;
  public srcResult: any;
  public fileInfos: any;
  public errors: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<MaintenanceInterventionDialogComponent>,
    public http: ApiService,
    public intervalService: IntervalService,
    public internalDataService: InternalDataService,
    public clonerService: ClonerService,
    public translate: FfTranslateService,
    public apiService: ApiService,
    public filterService: FiltersService,
  ) { }

  tabs: any = [
    {
      "id": "form",
      "title": "MAINTENANCE_HISTORY.FORM",
      "icon": {
        "icon": "assessment",
        "type": "icon"
      },
      "selected": true
    },
    {
      "id": "activities",
      "title": "MAINTENANCE_HISTORY.ACTIVITiES",
      "icon": {
        "icon": "list_alt",
        "type": "icon"
      },
      "right": true,
      "left": true
    },
    {
      "id": "components",
      "title": "MAINTENANCE_HISTORY.COMPONENTS",
      "icon": {
        "icon": "list_alt",
        "type": "icon"
      },
      "right": true
    }
  ];

  ngOnInit() {

    this.nowTime = new Date().toISOString().substring(0, 16);

    this.state = 0;

    if (this?.dialog?.dialogType == 'edit' && this?.dialog?.row?.interventionType == 'proposal') {
      try { delete this.dialog.row.interventionType } catch (error) { }
      try { delete this.dialog.row.operatorId } catch (error) { }
      try { this.dialog.row.proposal = true } catch (error) { }
    }

    // Populate all the lists that have a closed list from the machine profiles, with the following config:
    // - editableInDialog:  true
    // - dialogEditType:    'closedList'
    // - listFromBE:        false || none
    // - type:              'configFromProfile'
    try {
      let closedLists = this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'closedList' && !x.listFromBE);
      closedLists = closedLists?.reduce((acc, info) => {
        if (info?.type != 'configFromProfile' || this.dialog.profile == null) {
          acc.push(info);
          return acc;
        }

        let list = this.clonerService.deepClone(this.dialog.profile?.[info?.mappingConfig?.key ?? 'timeStates']);

        this.parseObjectTypeClosedList(info, list);
        acc.push(info);

        return acc;
      }, []);
      closedLists?.filter(x => x?.listOptions?.length == 1).forEach(list => this.dialog.row[list.variable] = list.listOptions[0]);
    } catch (error) { console.log(error) }

    // // // // // // // // // // // // // // // // // // // // // // 
    // FILES 
    // // // // // // // // // // // // // // // // // // // // // // 

    if (this?.dialog?.dialogType == 'edit') {

      this.statesConfig.existingFiles = 0;

      (async () => {
        let endpointUrl = this?.dialog?.filesTableConfig?.endpointUrl?.replaceAll("{machineId}", this.dialog.machineId).replaceAll("{maintId}", this.dialog.row.id);

        // console.log(endpointUrl);

        let query: any = {
          tz: this.dialog?.machine?.timezone ?? "Europe/Rome",
          lang: this.translate?.currentLang ?? 'en',
        };

        let payload: any = this.dialog.row.proposal ? { proposal: true } : {};

        try {

          let method = 'POST';
          if (endpointUrl.includes(':')) {
            endpointUrl = endpointUrl.split(':');
            method = endpointUrl[0];
            endpointUrl = endpointUrl[1];
          }

          let requestSent = null;
          if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
          else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
          else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

          const data: any = await requestSent.toPromise();

          //------------------------------------------------------------------------//
          // RESPONSE PARSING

          let copyData: any = this.clonerService.deepClone(data?.body ?? []);

          // this.dialog.activitiesSelectionWidget.data = copyData;

          if (copyData?.length > 0) {

            copyData = copyData.reduce((acc, val) => {
              val.sizeP = this.parseFileSize(val.size);
              acc.push(val);
              return acc;
            }, []);
            this.dialog.filesTableConfig.data = { selectedFiles: copyData };

            this.dialog.row.files = this.dialog.filesTableConfig.data.selectedFiles.map(x => {
              return {
                fileName: x.name,
                file: x.arrayBuffer
              };
            });
          }
          // console.log({ copyData });

          // if (this.dialog.row?.activities?.length) this.parseExistingActivities();

          // this.dialog.activitiesSelectionWidget = this.clonerService.deepClone(this.dialog.activitiesSelectionWidget);

          // console.log(this.dialog.activitiesSelectionWidget);

          this.statesConfig.existingFiles = 1;
          this.checkState();

        } catch (error) {
          this.statesConfig.existingFiles = 1;
          this.checkState();
        }

      })();
    }

    // // // // // // // // // // // // // // // // // // // // // // 
    // ACTIVITIES 
    // // // // // // // // // // // // // // // // // // // // // // 

    // Initialize data as empty array (NEW MAINTENANCE) or pre-existing list of activities (EDIT MAINTENANCE)
    try {
      this.dialog.activitiesTableConfig.data = { selectedActivitiesTable: this.dialog.row?.activities ?? [] };
      this.dialog.activitiesTableConfig = this.clonerService.deepClone(this.dialog.activitiesTableConfig);
    } catch (error) {
      console.log(error);
    }

    if (this?.dialog?.activitiesSelectionWidget?.type != null) {

      this.statesConfig.activitiesSelectionWidget = 0;

      (async () => {
        let endpointUrl = this?.dialog?.activitiesSelectionWidget?.endpointUrl?.replaceAll("{machineId}", this.dialog.machineId);

        let query: any = {
          tz: this.dialog?.machine?.timezone ?? "Europe/Rome",
          lang: this.translate?.currentLang ?? 'en',
        };

        let payload: any = {}

        try {

          let method = 'POST';
          if (endpointUrl.includes(':')) {
            endpointUrl = endpointUrl.split(':');
            method = endpointUrl[0];
            endpointUrl = endpointUrl[1];
          }

          let requestSent = null;
          if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
          else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
          else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

          const data: any = await requestSent.toPromise();

          //------------------------------------------------------------------------//
          // RESPONSE PARSING

          let copyData: any = this.clonerService.deepClone(data?.body ?? []);

          this.dialog.activitiesSelectionWidget.data = copyData;

          if (this.dialog.row?.activities?.length) this.parseExistingActivities();

          this.dialog.activitiesSelectionWidget = this.clonerService.deepClone(this.dialog.activitiesSelectionWidget);

          this.statesConfig.activitiesSelectionWidget = 1;
          this.checkState();

        } catch (error) {
          this.statesConfig.activitiesSelectionWidget = 1;
          this.checkState();
        }

      })();
    }

    // // // // // // // // // // // // // // // // // // // // // // 
    // COMPONENTS 
    // // // // // // // // // // // // // // // // // // // // // // 

    // Initialize data as empty array (NEW MAINTENANCE) or pre-existing list of replaced components (EDIT MAINTENANCE)
    try {
      // console.log(this.dialog.row?.components);

      this.dialog.componentsTableConfig.data = { selectedComponentsTable: this.dialog.row?.components ?? [] };
      this.dialog.componentsTableConfig = this.clonerService.deepClone(this.dialog.componentsTableConfig);
    } catch (error) {
      console.log(error);
    }

    if (this?.dialog?.componentsSelectionWidget?.type != null) {

      this.statesConfig.componentsSelectionWidget = 0;

      (async () => {
        let endpointUrl = this?.dialog?.componentsSelectionWidget?.endpointUrl?.replaceAll("{machineId}", this.dialog.machineId);

        let query: any = {
          tz: this.dialog?.machine?.timezone,
          lang: this.translate?.currentLang ?? 'en',
        };

        let payload = this.internalDataService.buildMachinePayload(this.dialog?.machine);
        if (this.dialog.row.proposal) payload.proposal = true;

        try {

          let method = 'POST';
          if (endpointUrl.includes(':')) {
            endpointUrl = endpointUrl.split(':');
            method = endpointUrl[0];
            endpointUrl = endpointUrl[1];
          }

          let requestSent = null;
          if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
          else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
          else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

          const data: any = await requestSent.toPromise();

          //------------------------------------------------------------------------//
          // RESPONSE PARSING

          let copyData: any = this.clonerService.deepClone(data?.body ?? []);

          this.dialog.componentsSelectionWidget.data = copyData;

          if (this.dialog.row?.components?.length) this.parseExistingComponents();

          this.dialog.componentsSelectionWidget = this.clonerService.deepClone(this.dialog.componentsSelectionWidget);

          this.statesConfig.componentsSelectionWidget = 1;
          this.checkState();

        } catch (error) {
          this.statesConfig.componentsSelectionWidget = 1;
          this.checkState();
        }

      })();


      this.statesConfig.replaceCauses = 0;

      (async () => {
        let endpointUrl = this?.dialog?.componentsSelectionWidget?.replaceCausesEndpointUrl?.replaceAll("{machineId}", this.dialog.machineId);

        let query: any = {
          tz: this.dialog?.machine?.timezone,
          lang: this.translate?.currentLang ?? 'en',
        };

        let payload: any = {}

        try {

          let method = 'POST';
          if (endpointUrl.includes(':')) {
            endpointUrl = endpointUrl.split(':');
            method = endpointUrl[0];
            endpointUrl = endpointUrl[1];
          }

          let requestSent = null;
          if (method == 'POST') requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
          else if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
          else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

          const data: any = await requestSent.toPromise();

          //------------------------------------------------------------------------//
          // RESPONSE PARSING

          let copyData: any = this.clonerService.deepClone(data?.body ?? []);
          this.replaceCauses = copyData;

          if (this.dialog.row?.components?.length) this.parseExistingComponents();

          this.statesConfig.replaceCauses = 1;
          this.checkState();

        } catch (error) {
          this.statesConfig.replaceCauses = 1;
          this.checkState();
        }

      })();
    }


  }

  parseExistingComponents() {
    try {
      this.dialog.componentsSelectionWidget.data.hierarchy.forEach(group => {
        group.subgroups.forEach(subgroup => {
          subgroup.components.forEach(comp => {
            if (this.dialog.row?.components.findIndex(component => comp.component == component.component) > -1) {
              comp.selected = true;
            }
          });
          if (subgroup.components.every(x => x.selected)) subgroup.selected = true;
        });
        if (group.subgroups.every(x => x.selected)) group.selected = true;
      });

      this.dialog.componentsTableConfig.data.selectedComponentsTable?.forEach(comp => {
        let filteredCauses = this.replaceCauses?.filter(x => x.subgroupId == comp.subgroupIdReal);
        comp.listOptions = filteredCauses;
        comp.interventionType = comp.replaceCode ?? comp.interventionType ?? filteredCauses?.[0]?.id ?? 1;
      });

      this.dialog.row.selectedComponents = this.dialog.componentsTableConfig.data.selectedComponentsTable?.map(comp => {
        return {
          id: comp.id,
          interventionType: comp.interventionType,
        };
      });

      this.dialog.componentsTableConfig = this.clonerService.deepClone(this.dialog.componentsTableConfig);
    } catch (error) {
      console.log(error);
    }
  }

  parseExistingActivities() {

    let clonedActivities = [];
    try {
      this.dialog.activitiesSelectionWidget.data.activities.forEach(group => {
        group.subgroups.forEach(subgroup => {
          subgroup.activities.forEach(activity => {
            if (this.dialog.row?.activities.findIndex(act => activity.id == act) > -1) {
              activity.selected = true;
              clonedActivities.push(activity);
            }
          });
          if (subgroup.activities.every(x => x.selected)) subgroup.selected = true;
        });
        if (group.subgroups.every(x => x.selected)) group.selected = true;
      });

      // console.log({ clonedActivities });

      this.dialog.activitiesTableConfig.data.selectedActivitiesTable = clonedActivities;

      this.dialog.row.selectedActivities = this.dialog.activitiesTableConfig.data.selectedActivitiesTable?.map(x => x.id);
      this.dialog.activitiesTableConfig = this.clonerService.deepClone(this.dialog.activitiesTableConfig);
    } catch (error) {
      console.log(error);
    }
  }

  checkState() {
    try { this.state = Object.values(this.statesConfig).every(x => x == 1) ? 1 : 0 }
    catch (error) {
      console.log(error);
      return 0;
    }
  }

  onFileSelected() {

    this.statesConfig.fileUpload = 0;

    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      let promises = [];
      for (let file of inputNode.files) {
        let filePromise = new Promise(resolve => {
          let reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = () => resolve({ arrayBuffer: reader.result, infos: file });
        });
        promises.push(filePromise);
      }
      Promise.all(promises).then((fileContents: any) => {
        // console.log({ fileContents });

        let fileInfos: any = this.dialog.filesTableConfig?.data?.selectedFiles ?? [];

        for (let file of fileContents) {

          if (fileInfos.findIndex(x => x.name == file.infos.name) == -1) {
            let infos = {
              name: file.infos.name,
              type: file.infos.type,
              size: file.infos.size,
              arrayBuffer: file.arrayBuffer,
              sizeP: this.parseFileSize(file.infos.size),
            }
            fileInfos.push(infos);
          }
        }

        // console.log(fileInfos);

        this.dialog.filesTableConfig.data = { selectedFiles: [] };

        setTimeout(() => {

          this.dialog.filesTableConfig.data = { selectedFiles: fileInfos };
          this.dialog.row.files = fileInfos.map(x => {
            return {
              fileName: x.name,
              file: x.arrayBuffer
            };
          });

          // console.log(this.dialog.row.files);
        }, 250);


      });

    }

  }

  parseFileSize(size: any) {
    if (size > 0) {

      if (size > 1000000)
        return Math.round(size / 1000000) + ' MB';
      else if (size > 1000)
        return Math.round(size / 1000) + ' kB';
      else
        return size + ' B';
    }
    return 'File is empty';
  }

  checkShowCondition(info, row) {

    if (info?.showCondition == null) return true;

    let condition = info.showCondition;

    let boolCond = true;

    try {

      if (condition?.every(cond => Array.isArray(cond) && cond?.length == 3)) boolCond = condition.some(c => this.filterService.parseDisabilityCondition(row, c));
      else if (condition?.length == 3) boolCond = this.filterService.parseDisabilityCondition(row, condition);

    } catch (error) { console.log(error) }

    // If the condition(s) is(are) not satisfied, delete the value associated with the variable
    if (!boolCond) row[info.variable] = null;

    return boolCond;

  }

  onButtonClick(item) {
    try {

      // console.log(item);

      if (item?.buttonInfos?.clickFunction == 'removeFile') {
        // console.log(item?.row);
        try {
          let idx = this.dialog.filesTableConfig.data.selectedFiles.findIndex(x => x.name == item.row.name);
          if (idx > -1) {

            if (this.dialog.filesTableConfig.data.selectedFiles[idx]?.arrayBuffer == null) {
              try { this.dialog.row.filesToRemove.push(this.dialog.filesTableConfig.data.selectedFiles[idx].name) }
              catch (error) { this.dialog.row.filesToRemove = [this.dialog.filesTableConfig.data.selectedFiles[idx].name] }
            }
            this.dialog.filesTableConfig.data.selectedFiles.splice(idx, 1);
            let selectedFiles = this.dialog.filesTableConfig.data.selectedFiles;
            this.dialog.filesTableConfig.data.selectedFiles = [];
            setTimeout(() => {

              this.dialog.filesTableConfig.data.selectedFiles = selectedFiles;
              this.dialog.row.files = selectedFiles.map(x => {
                return {
                  fileName: x.name,
                  file: x.arrayBuffer
                };
              });

              // console.log(this.dialog.row.files);
            }, 100);
          }
        } catch (error) {

        }

        return;
      }

      let rv = item?.tableConfig?.rowsVariable;
      switch (rv) {
        case "hierarchy":
        case "activities":

          let settings: any = {
            tableConfigName: rv == 'activities' ? 'activitiesTableConfig' : 'componentsTableConfig',
            selectedTableName: rv == 'activities' ? 'selectedActivitiesTable' : 'selectedComponentsTable',
            nestedKeyLabel: rv == 'activities' ? 'activities' : 'components',
            finalElement: rv == 'activities' ? 'id' : 'id',
          };

          let nestedTableIndex = item?.nestedTableIndex ?? 0;

          let compTable: any = this.dialog[settings.tableConfigName].data[settings.selectedTableName];

          if (nestedTableIndex == 2) {
            let compIdx = compTable.findIndex(comp => comp[settings.finalElement] == item.row[settings.finalElement]);
            if (compIdx == -1) {
              compTable.push(item.row);
            } else {
              compTable.splice(compIdx, 1);
            }
          } else if (nestedTableIndex == 1) {
            let toggle = item.row.selected;
            item.row[settings.nestedKeyLabel].forEach(component => {
              let compIdx = compTable.findIndex(comp => comp[settings.finalElement] == component[settings.finalElement]);
              if (compIdx == -1) {
                if (toggle) compTable.push(component);
              } else {
                if (!toggle) compTable.splice(compIdx, 1);
              }
            })
          } else if (nestedTableIndex == 0) {

            let toggle = item.row.selected;
            item.row.subgroups.forEach(subgroup => {
              subgroup[settings.nestedKeyLabel].forEach(component => {
                let compIdx = compTable.findIndex(comp => comp[settings.finalElement] == component[settings.finalElement]);
                if (compIdx == -1) {
                  if (toggle) compTable.push(component);
                } else {
                  if (!toggle) compTable.splice(compIdx, 1);
                }
              })
            })
          }

          if (rv == 'hierarchy') {
            compTable?.forEach(comp => {
              let filteredCauses = this.replaceCauses?.filter(x => x.subgroupId == comp.subgroupIdReal);
              comp.listOptions = filteredCauses;
              comp.interventionType = comp.replaceCode ?? comp.interventionType ?? filteredCauses?.[0]?.id ?? 1;
            });
          }

          this.dialog[settings.tableConfigName].data[settings.selectedTableName] = compTable;

          if (rv == 'hierarchy') {
            this.dialog.row.selectedComponents = compTable?.map(x => {
              return {
                id: x.id,
                interventionType: x.interventionType ?? 1
              };
            });
          } else {
            this.dialog.row.selectedActivities = compTable?.map(x => x.id);
          }
          this.dialog[settings.tableConfigName] = this.clonerService.deepClone(this.dialog[settings.tableConfigName]);
          break;
        case "list":

          let ordinaryMaintenanceList: any = this.dialog.activitiesSelectionWidget?.data?.ordinaryMaintenanceTable ?? [];

          let elemIdx = ordinaryMaintenanceList.findIndex(elem => elem.id == item.row.id);
          if (elemIdx == -1) {
            ordinaryMaintenanceList.push(item.row);
          } else {
            ordinaryMaintenanceList.splice(elemIdx, 1);
          }

          this.dialog.activitiesSelectionWidget.data.ordinaryMaintenanceTable = ordinaryMaintenanceList;

          this.dialog.row.ordinaryMaintenanceTable = ordinaryMaintenanceList?.map(x => x.id);
          break;
        case "selectedComponentsTable":

          let compIdx = this.dialog.row.selectedComponents.findIndex(comp => item.row.id == comp.id);
          if (compIdx > -1) this.dialog.row.selectedComponents[compIdx].interventionType = item.row.interventionType;

          break;
      }

    } catch (error) {
      console.log(error);
    }
  }

  ngOnDestroy() { }

  checkDisability() {
    let tableInfosCheck = !this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x.requiredInDialog).every(x => this?.dialog?.row?.[x?.variable] != null);

    // let selectedComponentsCheck = this.dialog.row?.selectedComponents?.length != null && this.dialog.row?.selectedComponents?.length > 0
    let selectedComponentsCheck = true;
    return tableInfosCheck || !selectedComponentsCheck;
  }

  parseObjectTypeClosedList(info: any, data: any) {

    info.listOptions = data?.filter(x => {

      // If element is not object, don't filter
      if (typeof x != 'object') return true;

      // If element has key "permission", filter according to the roles
      if (x?.permission != null && !this.filterService.getSpecificPermission(x?.permission)) return false;

      // If object doesn't have key "filterForSpecificVariableAndValue", don't filter
      if (info?.filterForSpecificVariableAndValue == null) return true;

      // Return only elements that have value associated to the specified "variable" equal to the specified "value"
      return x?.[info?.filterForSpecificVariableAndValue?.variable] == info?.filterForSpecificVariableAndValue?.value;
    })
      // Map on nestedKey if present
      .map(x => info?.nestedKeyToMap ? this.translate.instant(x?.[info?.nestedKeyToMap] ?? '-') : x) ?? [];

    if (info?.nestedKeysToKeep?.length > 0) {
      info.listOptionsDict = data?.reduce((acc, val) => {

        if (typeof val == 'object') {
          let obj: any = {}

          info?.nestedKeysToKeep?.forEach(k => {
            if (typeof k == 'object') {
              // If "primaryKey", substitute the id in the incoming object with the variable from info
              if (k?.primaryKey) obj[info.variable] = this.translate.instant(val[k.id] ?? '-');
              // Else map the newId (if present) with the correct value
              else obj[k?.newId ?? k?.id] = val[k.id];
            }
            else obj[k] = val[k];
          });
          acc.push(obj);
        }

        return acc;
      }, []);
      // console.log(info.listOptionsDict);

      if (this.dialog.dialogType == 'edit') {
        try {
          let proposalIndex = info.listOptions.findIndex(x => x.id == 'proposal');
          if (proposalIndex != -1) info.listOptions.splice(proposalIndex, 1);
        } catch (error) { console.log(error) }
      }
    }
  }

  onDaySelection(date: any, key: string) {

    this.dialog.row[key] = JSON.parse(JSON.stringify(moment(date.value)));

  }


  selectTab(sel: any) {
    // reset position of tabs content
    this.tabs.forEach((t: any) => {
      t.selected = false;
      delete t.left;
      delete t.right;
    });
    // set as selected
    sel.selected = true;
    // loop on tabs to set on left previous and right nexts
    let selPassed = false;
    this.tabs.forEach((t: any) => {
      if (t.id != sel.id) {
        if (!selPassed) t.left = true;
        else t.right = true;
      } else {
        selPassed = true;
      }
    });
  }

  buildConfigPayload(files) {

    let payload = new FormData();

    for (let file of files) {
      if (file.file != null) payload.append(file.fileName, new Blob([file.file]));
    }

    return payload
  }

  async closeDialog() {

    let inputDateTime = this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'inputDateTime');

    inputDateTime?.forEach(x => {
      try { this.dialog.row[x.variable] = this.intervalService.formatStringDate(this.dialog?.row?.[x.variable] + ':00.000', this.dialog?.machineId) }
      catch (error) { console.log(error) }
    });

    this.statesConfig.fileUpload = 0;
    this.checkState();

    let files = this.dialog?.row?.files;

    try {
      delete this.dialog.row.files;
    } catch (error) {
      console.log(error);
    }

    let machineData = this.internalDataService.buildMachinePayload(this.dialog?.machine);
    let maintenanceData = this.dialog?.row;

    let payload = { machineData: machineData, maintenanceData: maintenanceData };
    let resp = null;
    if (this.dialog.dialogType != 'edit') resp = await this.apiService.sendPostRequestAwait('/apif/cmms/maintenance/' + this.dialog?.machineId, payload);
    else resp = await this.apiService.sendPutRequestAwait('/apif/cmms/maintenance/' + this.dialog?.machineId, payload);

    if (files?.length && files?.filter(file => file.file != null)?.length > 0) {
      let payload = this.buildConfigPayload(files);
      if (this.dialog.row.interventionType == 'proposal') payload.append("interventionType", "proposal");
      await this.apiService.sendCustomPayloadWithArrayBuffer('/apif/cmms/update-files/maintenance/' + this.dialog?.machineId + '/' + resp.body?.id ?? this.dialog.row?.id, payload);
    }


    this.dialogRef.close(this.dialog?.row);
  }

}
