<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <ng-container *ngIf="!isSmThanTablet">

      <!-- change component -->
      <button mat-button class="filter-button"
        (click)="filterService.openComponentSelectionDialog(this, 'health-trend')">
        <span>{{ 'HEALTH_MONITORING.CHANGE_COMPONENT' | translate }}</span>
      </button>

      <!-- aggregations -->
      <button mat-button class="filter-button"
        *ngFor="let aggr of aggregations | filterByProperty: ['showInHealthMonitoring', '==', true]"
        (click)="openAggrDialog(aggr)" [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
        <mat-icon *ngIf="aggr.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': aggr.label}">
          {{aggr.icon.icon }}</mat-icon>
        <mat-icon *ngIf="aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
          translate-attr="{'aria-label': aggr.label}"></mat-icon>
        <i *ngIf="aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor'" class="icon {{ aggr.icon.icon }}"
          translate-attr="{'aria-label': aggr.label}"></i>
        <span>{{ aggr.selectionLabel }}</span>
        <div fxLayout="row" fxLayoutAlign="center center"
          *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
          {{ aggregationsPayload[aggr.id].length }}
        </div>
      </button>

      <!-- intervals -->
      <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>
    </ng-container>

  </div>

  <!-- toolbar tabs -->
  <div *ngIf="isSmThanTablet" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- aggregations -->
    <button mat-button class="filter-button"
      *ngFor="let aggr of aggregations | filterByProperty: ['showInHealthMonitoring', '==', true]"
      (click)="openAggrDialog(aggr)" [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
      <mat-icon *ngIf="aggr.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': aggr.label}">
        {{aggr.icon.icon }}</mat-icon>
      <mat-icon *ngIf="aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
        translate-attr="{'aria-label': aggr.label}"></mat-icon>
      <i *ngIf="aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor'" class="icon {{ aggr.icon.icon }}"
        translate-attr="{'aria-label': aggr.label}"></i>
      <span>{{ aggr.selectionLabel }}</span>
      <div fxLayout="row" fxLayoutAlign="center center"
        *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
        {{ aggregationsPayload[aggr.id].length }}
      </div>
    </button>

    <!-- change component -->
    <button mat-button class="filter-button" (click)="filterService.openComponentSelectionDialog(this, 'health-trend')">
      <span>{{ 'HEALTH_MONITORING.CHANGE_COMPONENT' | translate }}</span>
    </button>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < 7" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 7" fxLayout="row" fxLayoutAlign="start start" class="h100 w100" fxLayoutGap="8px">
      <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="h100" fxLayoutGap="8px">
        <ff-widget fxFlex="100"
          [widget]="{type: 'ff-plotly-chart', data: dashboardData, config: {'plotDataAttribute': 'plotData'}, customHeight: '100%', customMinHeight: '100%' }">
        </ff-widget>
      </div>
      <div
        fxFlex="{{ (dashboardType == 2 ? dashboardConfig.secondColumnFlex : dashboardConfig.secondColumnFlexNoClasses) }}"
        fxLayout="column" fxLayoutAlign="start stretch" class="h100" fxLayoutGap="8px">
        <div
          fxFlex="{{ 100 - (dashboardConfig.secondPlotFlexHeight != null ? (dashboardType == 2 ? dashboardConfig.secondPlotFlexHeight : 0) : (dashboardType == 2 ? dashboardConfig.secondPlotFlexHeight : 40)) }}"
          fxLayout="{{ (dashboardType == 2 ? 'row' : 'column') }}" fxLayoutAlign="start stretch" fxLayoutGap="8px">
          <ff-widget *ngFor="let w of dashboardConfig.widgets" fxFlex="{{w.flex}}"
            [widget]="{ data: dashboardData, type: w.type, subtype: w.subtype, class: w.class, machineProfile: machine.profile, config: w.config, title: w.title }">
          </ff-widget>
        </div>
        <ff-widget *ngIf="dashboardType == 2"
          fxFlex="{{ dashboardConfig.secondPlotFlexHeight != null ? dashboardConfig.secondPlotFlexHeight : 40 }}"
          [widget]="{type: 'ff-plotly-chart', data: dashboardData, config: {'plotDataAttribute': 'anomaliesClassPlotData'}, customHeight: '100%', customMinHeight: '100%' }">
        </ff-widget>
      </div>

    </div>

  </div>

</div>