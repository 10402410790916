<div mat-dialog-title fxLayout="row" class="w100">

  <span *ngIf="dialog.icon != null" style="margin-right: 8px;" fxLayout="row" fxLayoutAlign="start center">

    <ff-icon [icon]="dialog?.icon"></ff-icon>
  </span>

  <span fxLayout="column" fxLayoutAlign="center center">
    {{ dialog.title | translate }}
  </span>
  <span *ngIf="intervalConfig?.startF" fxLayout="column" fxLayoutAlign="center center">
    &nbsp; / {{ intervalConfig.startF }}
  </span>

  <span fxFlex></span>

  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>

</div>

<mat-dialog-content class="mat-typography" [class.mh100vh-300px]="statesConfig.generatedComponents == 1">

  <ng-container *ngIf="state == 0">
    <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
      <ff-loading-card></ff-loading-card>
    </div>
  </ng-container>

  <ng-container *ngIf="state == 1">

    <div fxLayout="column" fxLayoutAlign="space-between start"
      [class.mh100vh-300px]="statesConfig.generatedComponents == 1">

      <!-- <ff-tabs [tabs]="tabs"></ff-tabs> -->

      <div *ngIf="statesConfig.generatedComponents == 0">

        <div fxLayout="column">

          <div class="title" style="margin-bottom: 32px;">
            <span>{{ "MAINTENANCE_BOM.SELECT_MODE" | translate }}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center" style="margin-bottom: 12px;">
            <button style="min-width: 130px;" (click)="openDateSelector()" class="filter-button md-black" fxLayout="row"
              fxLayoutAlign="center center">
              <!-- <mat-icon class="icon">event</mat-icon> -->
              <span>{{ "MAINTENANCE_BOM.CUSTOM_RANGE_SELECTION" | translate }}</span>
            </button>
            <span>{{ "MAINTENANCE_BOM.CUSTOM_RANGE_SELECTION_DESCRIPTION" | translate }}</span>
          </div>

          <div fxLayout="row" fxLayoutAlign="start center">
            <button style="min-width: 130px;" (click)="generateAutomaticDate()" class="filter-button md-black"
              fxLayout="row" fxLayoutAlign="center center">
              <!-- <mat-icon class="icon">event</mat-icon> -->
              <span>{{ "MAINTENANCE_BOM.AUTO_RANGE_SELECTION" | translate }}</span>
            </button>
            <span>{{ "MAINTENANCE_BOM.AUTO_RANGE_SELECTION_DESCRIPTION" | translate }}</span>
          </div>

        </div>

      </div>

      <div fxFlex fxLayout="row" fxLayoutAlign="start start" class="w100 h100 tab-content-animated">

        <div *ngFor="let tab of tabs" fxFlex fxLayout="column" fxLayoutAlign="start start"
          class="tab-content transition-01 h100"
          [ngClass]="{'z-index-m1': tab.left || tab.right, 'translate-l100': tab.left, 'translate-r100': tab.right }">

          <!-- replaced components -->

          <div *ngIf="tab.id == 'components'" fxLayout="row" class="h100 w100">

            <ng-container *ngIf="statesConfig.generatedComponents">

              <div fxFlex="50" class="w100 h100">

                <ff-widget *ngIf="dialog.componentsTableConfig != null" (clicked)="onButtonClick($event)"
                  [widget]="dialog.componentsTableConfig">
                </ff-widget>
              </div>

              <ff-widget fxFlex="50" (clicked)="onButtonClick($event)" [widget]="dialog.componentsSelectionWidget"
                class="w100 h100">
              </ff-widget>
            </ng-container>

          </div>

          <div *ngIf="tab.id == 'notes'" fxLayout="column" class="h100 w100">

            <mat-card class="rba-container">

              <div class="title">
                <span>{{'RBA.MAILING_LIST' | translate}}</span>
                <span class="number" *ngIf="mailingList != null && mailingList.length > 0">
                  &nbsp;({{ mailingList.length }})
                </span>

                <span fxFlex></span>

                <button mat-button *ngIf="mailingList != null && mailingList.length < 6"
                  class="md-gray-i rounded-button elliptic" (click)="addMail(mailingList)" fxLayout="row"
                  fxLayoutAlign="space-evenly center" #tooltip="matTooltip"
                  matTooltip="{{ 'RBA.ADD_MAIL' | translate }}" matTooltipPosition="above">
                  <mat-icon class="icon side">add</mat-icon>
                </button>

              </div>

              <div class="subtitle">
                <span *ngIf="mailingList != null && mailingList.length > 0">
                  {{'RBA.MAILING_LIST_DESCRIPTION' | translate}}.
                </span>
                <span *ngIf="mailingList == null || mailingList.length == 0">
                  {{ 'RBA.NO_MAILS_CONFIGURED' | translate}}!</span>
              </div>

              <mat-card-content>

                <div fxLayout="row wrap" *ngIf="mailingList != null && mailingList.length > 0" fxLayoutGap="16px">
                  <div *ngFor="let mail of mailingList; let i = index" [fxFlex]="'calc(33.3% - 16px)'"
                    class="single-event-container three" style="background-color: transparent !important;"
                    fxLayout="row" fxLayoutAlign="start center">

                    <input class="ff-input mail" [(ngModel)]="mail.value" type="email" required name="mail">

                    <button mat-button class="md-red-i rounded-button elliptic" (click)="deleteMail(mailingList, i)"
                      fxLayout="row" fxLayoutAlign="space-evenly center" #tooltip="matTooltip"
                      matTooltip="{{ 'RBA.DELETE_MAIL' | translate }}" matTooltipPosition="above"
                      style="margin-left: 12px !important;">
                      <mat-icon class="icon side">delete</mat-icon>
                    </button>

                  </div>
                </div>
              </mat-card-content>

            </mat-card>

            <mat-card class="rba-container">

              <div class="title">
                <span>{{'MAINTENANCE_BOM.MAIL_TEXT' | translate}}</span>
                <span class="number">
                  &nbsp;({{ mailText != null ? mailText.length : 0 }}/1500)
                </span>

                <span fxFlex></span>

              </div>

              <div class="subtitle">
                <span>
                  {{ 'MAINTENANCE_BOM.MAIL_TEXT_DESCRIPTION' | translate }}
                </span>
              </div>

              <mat-card-content>

                <textarea rows="1" style="max-height: 160px; min-height: 160px;" maxlength="1500" class="ff-textarea"
                  [(ngModel)]="mailText" oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                  name="textarea" id="textarea">
                </textarea>

              </mat-card-content>

            </mat-card>

            <div fxLayout="row">
              <span fxFlex></span>

              <div fxLayout="row" style="margin-bottom: 12px;">
                <button class="filter-button" fxLayout="row" fxLayoutAlign="center center"
                  (click)="downloadPdfButtonAction()">
                  <mat-icon class="icon">download</mat-icon>
                  <span>{{ 'GLOBAL.DOWNLOAD_PDF' | translate }}</span>
                </button>
              </div>

              <button *ngIf="sapButton" [class]="sapButton.class" fxLayout="row" fxLayoutAlign="center center"
                [disabled]="sapButton.disabled">
                <ff-icon [icon]="sapButton.icon"></ff-icon>
                <span>{{ sapButton.label | translate }}</span>
              </button>

              <button class="filter-button" fxLayout="row" fxLayoutAlign="center center" (click)="sendMails()"
                [disabled]="checkDisabledSendMail()">
                <mat-icon class="icon">mail</mat-icon>
                <span>{{ 'GLOBAL.SEND_MAIL' | translate }}</span>
              </button>
            </div>

          </div>


        </div>
      </div>

    </div>
  </ng-container>

</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;"
  *ngIf="state == 1 && statesConfig.generatedComponents">

  <button class="filter-button completely" (click)="selectTab(tabs[0])" [ngClass]="{'ff-disabled': tabs[0].selected  }">
    <ff-icon icon="arrow_back"></ff-icon>
  </button>

  <button class="filter-button completely" (click)="selectTab(tabs[1])" [ngClass]="{'ff-disabled': tabs[1].selected }">
    <ff-icon icon="arrow_forward"></ff-icon>
  </button>

</mat-dialog-actions>