import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { GcaInputDialogComponent } from 'src/app/components/gca-input-dialog/gca-input-dialog.component';
import { CustomIntervalDialogComponent } from 'src/app/components/interval-selector/custom-interval-dialog/custom-interval-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';


@Component({
  selector: 'app-gca-settings',
  templateUrl: './gca-settings.component.html',
  styleUrls: ['./gca-settings.component.scss']
})
export class GcaSettingsComponent implements OnInit, OnDestroy {

  public isAllowedUser: any = true;
  public isAllowedUserWrite: any = true;
  public hasBeenTrained: any = false;
  public dirtySettings: any = false;
  public trainingState: any = 0;

  public customRule: any = {
    toBE: [],
    list: [],
    errors: [],
    string: ''
  };

  // GCA settings config
  public gcaSettingsConfig: any;

  // Default GCA settings config (auto mode = false)
  public defaultGcaSettingsConfig: any = {
    auto: false,
  };

  public autoSwitch: any = {
    checked: false,
    checkedLabel: "ON",
    uncheckedLabel: "OFF",
    description: "GCA_SETTINGS.SWITCH_DESCRIPTION"
  };

  public timeRangeSwitch: any = {
    checked: false,
    checkedLabel: "ON",
    uncheckedLabel: "OFF",
    description: "GCA_SETTINGS.TIME_RANGE_SWITCH_DESCRIPTION"
  };

  public ruleButtons: any = [
    {
      id: "kpi",
      label: "GCA_SETTINGS.KPI",
      closedListType: "arrayOfObjects",
      previousValues: [
        null,
        "operator",
        "openingBracket"
      ],
      type: "dropdown"
    },
    {
      id: "operator",
      label: "GCA_SETTINGS.OPERATOR",
      type: "operators",
      previousValues: [
        "kpi",
        "coeff",
        "closingBracket",
      ],
      buttons: [
        {
          id: "add",
          value: "+"
        },
        {
          id: "subtract",
          value: "-"
        },
        {
          id: "multiply",
          value: "*"
        },
        {
          id: "divide",
          value: "/"
        },
      ]
    },
    // {
    //   id: "brackets",
    //   label: "GCA_SETTINGS.BRACKETS",
    //   type: "operators",
    //   buttons: [
    //     {
    //       id: "openingBracket",
    //       value: "(",
    //       previousValues: [
    //         null,
    //         "operator"
    //       ],
    //     },
    //     {
    //       id: "closingBracket",
    //       value: ")",
    //       previousValues: [
    //         "kpi",
    //         "coeff",
    //       ],
    //       needs: [
    //         "openingBracket"
    //       ]
    //     },
    //   ]
    // },
    {
      id: "openingBracket",
      value: "(",
      previousValues: [
        null,
        "operator"
      ],
    },
    {
      id: "closingBracket",
      value: ")",
      previousValues: [
        "kpi",
        "coeff",
        "closingBracket",
      ],
      needs: [
        "openingBracket"
      ]
    },
    {
      id: "coeff",
      label: "GCA_SETTINGS.COEFFICIENT",
      type: "number",
      previousValues: [
        null,
        "operator",
        "openingBracket"
      ],
    },
  ]

  public unparsedGcaSettings: any;
  public unparsedCycleList: any;

  public parameters: any;
  public trainingAction: any;

  public loadingData: any;
  public loadingTrainingData: any = { message: this.translate.instant("GCA_SETTINGS.RETRAINING_CYCLES") };
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;
  public cyclesInfo: any;

  public breadcrumb: any;
  public tabs: any

  public machine: any;
  public machineId: any;
  public machineSelectedSub: Subscription;

  public interval: any;
  public intervalConfig: any;

  public cycles: any;
  public cyclesSelected: any = [];

  public completeDashboardConfig: any;
  public dashboardConfig: any;
  public dashboardData: any;

  public cyclesFilters: any;
  public filterButtons: any;

  public pollingTime: any;
  public pollingSubscription: Subscription;

  public sectionName: any = 'cycleTraceability';
  public tabName: any = 'gcaSettings';

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 8;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getKPIConfigs, nextState: 4, loadingMsg: 'LOADING.CYCLES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 5, loadingMsg: 'LOADING.CYCLES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getGcaSettings, nextState: 6, loadingMsg: 'LOADING.CYCLES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.getTrainingStatePolling, nextState: 7, loadingMsg: 'LOADING.CYCLES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public cacheService: CacheService,
    public clonerService: ClonerService,
    private router: Router,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['CYCLE_TRACEABILITY.TITLE', 'GCA_SETTINGS.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0 && this.breadcrumb?.length >= 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.tabs = this.internalDataService.getPageTabs('cycleTraceability');

    // Set pollingTime if present
    this.pollingTime = this.appConfig[this.sectionName]?.[this.tabName]?.pollingTime ?? 5000;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUserWrite = _this.internalDataService.getSpecificPermission("mat-gca-settings-rw");
    } catch (error) { console.log(error) }

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(["mat-gca-settings-r", "mat-gca-settings-rw"], 'or');
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs('cycleTraceability');
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'cycleTraceability');
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET KPI CONFIGS

  getKPIConfigs(_this: any) {
    try {
      _this.internalDataService.getKPIConfigs(_this, _this.machineId);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  getDashboard(_this: any) {

    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, 'gca-settings');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SETTINGS

  getTrainingStatePolling(_this: any) {
    try {

      if (_this.pollingTime > 0) _this.pollingSubscription = timer(0, _this.pollingTime).subscribe((count) => _this.getTrainingState(_this, count));
      else _this.getTrainingState(_this, 0);

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getTrainingState(_this: any, count?: any) {
    try {

      let query: any = {};
      let url = "/apif/gca-training-state/" + _this.machineId;

      _this.apiService.sendGetRequest(url, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
        )
        .subscribe(
          (data: any) => {

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);

            _this.trainingState = data?.body?.state ?? 0;

            if (_this.trainingState == 1) _this.hasBeenTrained = true;

            if (_this.hasBeenTrained && _this.trainingState == 0) window.location.reload();

          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SETTINGS

  getGcaSettings(_this: any) {
    try {

      try {
        let kpiIdx = _this.ruleButtons?.findIndex(x => x.id == 'kpi');
        _this.ruleButtons[kpiIdx].list = _this.parameters?.kpiParameters?.list ?? [];
      } catch (error) {
        console.log("No rule defined for \"kpi\"");
      }

      if (_this.intervalConfig == null) {
        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval,
          alternativeColor: true,
        };
      }

      let query: any = {};
      let url = "/apif/cycle-selector-config/" + _this.machineId;

      _this.apiService.sendGetRequest(url, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
        )
        .subscribe(
          (data: any) => {
            _this.dispatcherService.getDispatch(_this, 300);

            _this.parseMonitoringData(data?.body ?? {});

          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseMonitoringData(data: any) {

    // console.log(data?.cycles);

    data?.cycles?.forEach(cycle => {
      cycle.timeStartP = this.filterService.parseMoment(cycle.timeStart, 'default');
      cycle.timeEndP = this.filterService.parseMoment(cycle.timeEnd, 'default');
      cycle.intervalP = cycle.timeStartP + "\n" + cycle.timeEndP;
      cycle.durationP = cycle.duration != null ? this.filterService.parseDuration(cycle.duration, 's', 'hh:mm:ss') : '-';
      this.parseCycleType(cycle);
    });

    this.unparsedCycleList = this.clonerService.deepClone(data?.cycles ?? []);
    this.dashboardData = this.clonerService.deepClone(data);

    this.parseGcaSettings(data?.config ?? {});

  };

  parseGcaSettings(config) {

    this.gcaSettingsConfig = this.clonerService.deepClone(config ?? this.defaultGcaSettingsConfig);

    let gcaSettings: any = this.clonerService.deepClone(config ?? this.defaultGcaSettingsConfig);

    this.autoSwitch.checked = gcaSettings?.auto ?? false;
    this.timeRangeSwitch.checked = gcaSettings?.interval != null;

    if (gcaSettings?.interval != null) {

      let interval: any = {};
      let tz = this.machine.timezone;

      interval.id = 'custom';
      interval.start = JSON.parse(JSON.stringify(gcaSettings?.interval.from));
      interval.end = moment(gcaSettings?.interval.to).endOf("day");

      this.intervalService.formatTimezone(interval, tz);
      // if (moment().diff(moment(interval.end), 'm') < 0) interval.end = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      interval.startF = this.intervalService.strFormat(interval, interval.start, tz) + ' - ' + this.intervalService.strFormat(interval, interval.end, tz);

      let selected = this.clonerService.deepClone(interval);

      this.intervalConfig.selected = selected;
    }

    if (gcaSettings?.rule?.length > 0) {

      this.customRule.list = [];
      gcaSettings?.rule?.forEach((rule) => {

        let clonedRule: any = this.clonerService.deepClone(rule);
        let ruleToAddToList = {};

        if (clonedRule.id == 'kpi') {

          let ruleConfig = this.ruleButtons.find(x => x.id == 'kpi');
          ruleToAddToList = { ...ruleConfig, ...{ value: ruleConfig.list.find(x => x.kpi == clonedRule.value) } };

        } else if (clonedRule.id == 'operator') {

          let ruleConfig = this.ruleButtons.find(x => x.id == clonedRule.id);
          ruleToAddToList = { ...ruleConfig, ...{ value: ruleConfig.buttons.find(x => x.value == clonedRule.value) } };

        } else if (clonedRule.id == 'coeff') {

          let ruleConfig = this.ruleButtons.find(x => x.id == clonedRule.id);
          ruleToAddToList = { ...ruleConfig, ...{ value: { value: clonedRule.value } } };

        } else if (clonedRule.id == 'openingBracket' || clonedRule.id == 'closingBracket') {
          let ruleConfig = this.ruleButtons.find(x => x.id == clonedRule.id);
          ruleToAddToList = ruleConfig;
        }

        this.customRule.list.push(ruleToAddToList);
        this.customRule.toBE = this.clonerService.deepClone(gcaSettings?.rule);
      });

      this.parseCustomRuleStringFromList();
    }

    this.unparsedGcaSettings = this.clonerService.deepClone(gcaSettings);

    this.checkCustomRulesErrors();

    this.completeDashboardConfig = {
      dashboardData: this.clonerService.deepClone(this.dashboardData),
      machineProfile: this.machine.profile,
      dashboardConfig: this.dashboardConfig,
    };
  }

  parseCycleType(element: any) {
    try {
      let cycleType = null;
      if (this.machine.profile.cycles?.length) {

        if (element.hasOwnProperty('cycleType') && element.cycleType != null) {
          let ix = this.machine.profile.cycles.findIndex(x => x.id == element.cycleType);
          if (ix != -1) cycleType = this.machine.profile.cycles[ix];
        } else {
          for (let phase of element.phases) {
            for (let cycle of this.machine.profile.cycles) {
              if (cycle.havePhases.length == 0) cycleType = cycle;
              let ix = cycle.havePhases.findIndex((x: any) => x == parseInt(phase.phaseId))
              if (ix != -1) {
                cycleType = cycle;
                break;
              }
            }
          }
        }
        element.typeP = cycleType ?? this.machine.profile.cycles.find((x: any) => x.id == 0);
        try { element.cycleType = element.typeP.id } catch (error) { }
      }
    } catch (error) {
      console.log('Failed to parse cycle type')
    }
  }

  openCustomTimeRangeSelection(selectedInterval) {

    if (selectedInterval?.id == 'custom') {

      const customIntervalDialog = this.dialog.open(CustomIntervalDialogComponent, {
        panelClass: 'ff-dialog',
        disableClose: true,
        data: {
          title: this.translate.instant('INTERVAL.CUSTOM')
        },
      });

      customIntervalDialog.afterClosed().subscribe((result: any) => {
        // console.log('afterClosed', result);
        if (result != null && result != '') {

          let interval: any = {};
          let tz = this.machine.timezone;

          interval.start = JSON.parse(JSON.stringify(result.interval.value.start));
          interval.end = moment(result.interval.value.end).endOf("day");

          this.intervalService.formatTimezone(interval, tz);
          // if (moment().diff(moment(interval.end), 'm') < 0) interval.end = moment().utc().format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
          interval.startF = this.intervalService.strFormat(interval, interval.start, tz) + ' - ' + this.intervalService.strFormat(interval, interval.end, tz);

          let selected = this.clonerService.deepClone(interval);

          this.intervalConfig.selected = selected;

          // Parse the new interval informations
          this.switchChange(true, this.timeRangeSwitch, "timeRange");

        }
      });
    }

    // Parse the new interval informations
    this.switchChange(true, this.timeRangeSwitch, "timeRange");

  }

  checkRuleDisability(info) {

    // Default disabled
    let isDisabled = true;

    if (this.customRule.list?.length) {

      // Check that the value before the current is included in the available ones
      isDisabled = !info.previousValues.includes(this.customRule.list.at(-1).id);

      // Case "closingBracket": check that the number of openingBrackets is the same as the closing ones
      if (info?.needs?.length) isDisabled = isDisabled || (this.customRule.list.filter(x => info.needs.includes(x.id))?.length == this.customRule.list.filter(x => x.id == info.id)?.length)
    }
    // Empty list
    else {
      isDisabled = !info.previousValues.includes(null);
    }

    return isDisabled;
  }

  onKeydown(event, info) {
    if (event.key === "Enter") {
      this.addValueToCustomRule(info, {
        value: info.value,
      });
      info.value = '';
    }
  }

  addValueToCustomRule(info, type?) {

    console.log(info, type);
    if (type != null) {
      this.customRule.list.push({ ...info, ...{ value: type } });
    } else {
      this.customRule.list.push(info);
    }

    if (info.id == 'kpi') {
      this.customRule.toBE.push({
        id: "kpi",
        value: type?.kpi,
      });
    } else if (info.id == 'operator' || info.id == 'coeff') {
      this.customRule.toBE.push({
        id: info.id,
        value: type?.value,
      });
    } else if (info.id == 'openingBracket' || info.id == 'closingBracket') {
      this.customRule.toBE.push({
        id: info.id,
        value: info?.value,
      });
    }
    this.parseCustomRuleStringFromList();

  }

  parseCustomRuleStringFromList(list?: any) {

    let ruleList = list ?? this.customRule.list;

    if (this.customRule?.string == null) this.customRule.string = '';

    let ruleString: any = ruleList?.reduce((acc, info) => {

      // console.log(acc, info);

      switch (info?.id) {
        case 'kpi':
          acc += this.translate.instant(info?.value?.label ?? '-');
          break;
        case 'operator':
        case 'coeff':
          acc += info?.value?.value;
          break;
        default:
          acc += info?.value;
          break;
      }

      return acc + ' ';

    }, '');


    this.checkCustomRulesErrors();
    this.customRule.string = ruleString;

  }

  checkCustomRulesErrors() {
    try {

      this.customRule.errors = [];
      let ruleList = this.customRule.list;
      let errors = [];

      if (this.customRule.list?.length) {

        let kpiCheck = ruleList?.filter(x => x.id == 'kpi')?.length > 0;
        if (!kpiCheck) errors.push("GCA_SETTINGS.KPI_MISSING");

        let bracketCheck = ruleList?.filter(x => x.id == 'openingBracket')?.length == ruleList?.filter(x => x.id == 'closingBracket')?.length;
        if (!bracketCheck) errors.push("GCA_SETTINGS.CLOSED_BRACKET_MISSING");

        let endingCheck = ["kpi", "closingBracket", "coeff"].includes(ruleList?.at(-1)?.id);
        if (!endingCheck) errors.push("GCA_SETTINGS.ENDING_SYNTAX_ERROR");

      } else {
        errors.push("GCA_SETTINGS.EMPTY_CUSTOM_RULE");
      }

      this.customRule.errors = errors;

    } catch (error) { console.log(error) }
  }

  switchChange(value, switchType, switchId) {
    // console.log(value);

    switchType.checked = value;

    switch (switchId) {
      case 'auto':
        this.gcaSettingsConfig.auto = value ? 1 : 0;
        break;
      case 'timeRange':
        if (!value) {
          this.gcaSettingsConfig.interval = null;
        } else {
          if (this.intervalConfig?.selected != null) {
            this.gcaSettingsConfig.interval = {
              from: moment(this.intervalConfig?.selected?.start)?.format("YYYY-MM-DD"),
              to: moment(this.intervalConfig?.selected?.end)?.format("YYYY-MM-DD")
            }
          }
        }
        break;
    }
    console.log(this.gcaSettingsConfig);

  }

  onButtonClick(item) {

    console.log(item);
    this.toggleCycleForGCA(item?.row ?? {});
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CHECK FUNCTIONS

  checkObjectEquality(...objects) {
    return objects.every(obj => JSON.stringify(obj) === JSON.stringify(objects[0]));
  }

  checkUpdates(checkExit?: any) {
    try {
      // console.log('checkUpdates');

      // Check if at least one cycle has been selected for the removal
      // let cycleRemovedCheck = this.cyclesSelected?.length > 0;

      // Check if the automatic mode has been changed
      let autoModeChange = this.unparsedGcaSettings?.auto == this.gcaSettingsConfig?.auto;

      // Check if the number of cycles has changed
      let cyclesNumberChange = false;

      // Check if the selected time range has changed
      let timeRangeChange = false;

      // Check if the custom rule has changed
      let ruleChange = false;

      // Check if the custom rule has a valid format
      let validCustomRule = true;

      if (this.gcaSettingsConfig?.auto) {

        // Check if the number of cycles has changed
        cyclesNumberChange = this.unparsedGcaSettings?.numOfCycles == this.gcaSettingsConfig?.numOfCycles;

        // Check if the selected time range has changed
        timeRangeChange = this.checkObjectEquality(this.unparsedGcaSettings?.interval ?? {}, this.gcaSettingsConfig?.interval ?? {});

        // Check if the rule has changed
        ruleChange = this.checkObjectEquality(this.unparsedGcaSettings?.rule ?? {}, this.customRule.toBE ?? {});

        // Check if the custom rule has a valid format
        validCustomRule = this.customRule.list.length > 0 && this.customRule.errors.length == 0;
      }

      // let changedPropertiesCheck = cycleRemovedCheck == false && autoModeChange == true && cyclesNumberChange == true && timeRangeChange == true;
      let changedPropertiesCheck = autoModeChange == true && cyclesNumberChange == true && timeRangeChange == true && ruleChange == true;
      let invalidPropertiesCheck = validCustomRule == false;

      this.dirtySettings = !changedPropertiesCheck;

      if (checkExit) return changedPropertiesCheck;

      return invalidPropertiesCheck || changedPropertiesCheck;

    } catch (error) {
      return true;
    }
  }

  canDeactivate() {
    return this.checkUpdates(true);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // UPDATE GCA SETTINGS

  //  UPDATE GCA SET
  updateGcaSettings() {

    this.pageState.next(4);
    let url = "/apif/cycle-selector-update/" + this.machineId;

    let payload = this.buildPayload();
    console.log(payload);

    let _this = this;

    this.apiService.sendPostRequest(url, payload)
      .pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {
          console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);

          this.snackBar.open(this.translate.instant('GCA_SETTINGS.SETTINGS_UPDATE_RESPONSE.500'), '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['snackbar', 'fail']
          });
          this.pageState.next(8);

          return throwError(error.status + ': ' + error.statusText);
        })
      )
      .subscribe(
        (data: any) => {
          console.log(data.body);

          this.parseGcaSettings(data?.body ?? {});

          this.pageState.next(8);

          this.snackBar.open(this.translate.instant('GCA_SETTINGS.SETTINGS_UPDATE_RESPONSE.204'), '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['snackbar', 'success']
          });

        }
      );

  }

  buildPayload() {
    let payload: any = {};

    try {

      if (!this.gcaSettingsConfig.auto) {
        payload = {
          auto: 0
        };
        return payload;
      }

      payload = this.clonerService.deepClone(this.gcaSettingsConfig);

      // Add rule if present
      if (this.customRule.toBE?.length > 0) payload.rule = this.clonerService.deepClone(this.customRule.toBE);

    } catch (error) { console.log(error) }

    return payload;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GCA

  // SELECT / UNSELECT CYCLE
  toggleCycleForGCA(cycle: any) {
    // console.log("%c Cycle " + cycle.id, 'color: red;');
    // console.log(cycle);

    if (this.cyclesSelected != null && Array.isArray(this.cyclesSelected)) {

      if (this.cyclesSelected.length == 0) {
        this.cyclesSelected.push(cycle);
        cycle.toRemove = true;
        // this.trainingAction = !cycle.trainSet ? 'add' : 'remove';
        this.trainingAction = 'remove';
        return;
      }
      let ix = this.cyclesSelected.findIndex((x: any) => x.id == cycle.id && x.cycleType == cycle.cycleType);
      if (ix == -1) {
        this.cyclesSelected.push(cycle);
        cycle.toRemove = true;
      }
      else {
        this.cyclesSelected.splice(ix, 1);
        cycle.toRemove = false;
      }
      if (this.cyclesSelected.length == 0) this.trainingAction = null;
    }

  }

  checkCycleCheckboxClasses(cycle) {
    if (this.trainingAction == null) return "clickable";
    else if (this.trainingAction == 'add') return !cycle.trainSet ? "clickable" : "ff-disabled completely";
    else return cycle.trainSet ? "clickable" : "ff-disabled completely";
  }

  //  OPEN GCA DIALOG
  openGCADialog(type: any = 'train', action: any = 'add') {

    let dialogTitle = "";
    let confirmTitle = "";

    if (action == 'apocalypse') {

      dialogTitle = "CYCLES.CLEAN_TRAINING_SET";
      let description = "CYCLES.CLEAN_TRAINING_SET_DESCRIPTION";

      let confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
        panelClass: 'ff-dialog',
        data: {
          title: dialogTitle,
          description: description
        }
      });

      confirmationDialog.afterClosed().subscribe((result: any) => {

        if (result != null && result != '') {

          console.log('Remove training set');

          this.updateGCA(this, [], type, action);

          this.cyclesSelected = [];
          this.trainingAction = null;
          this.cycles.filtered.forEach((cycle: any) => cycle.gcaSelected = false);

        }

      });

    } else {

      dialogTitle = type == 'train' ? (action == 'add' ? "CYCLES.CYCLES_TO_TRAIN" : "CYCLES.REMOVE_FROM_TRAIN_SET") : "CYCLES.CYCLES_TO_RE_EVALUATE";
      confirmTitle = type == 'train' ? (action == 'add' ? "CYCLES.CONFIRM_TRAINING" : "CYCLES.CONFIRM_DELETION") : "CYCLES.CONFIRM_RE_EVALUATION";

      let filtersDialog = this.dialog.open(GcaInputDialogComponent, {
        panelClass: 'ff-dialog',
        data: {
          title: dialogTitle,
          confirmTitle: confirmTitle,
          list: this.cyclesSelected
        }
      });

      filtersDialog.afterClosed().subscribe((result: any) => {

        if (result != null && result != '') {

          console.log(result);

          let propsToKeep = ["id", "cycleType"];
          result = result.map((cycle: any) => this.filterService.removeKeysWithCustomRule(cycle, (x: any) => propsToKeep.includes(x[0])));

          if (type == 'train') this.updateGCA(this, result, type, action);
          else this.updateGCA(this, result, 'evaluation');

          this.cyclesSelected = [];
          this.trainingAction = null;
          this.cycles.filtered.forEach((cycle: any) => cycle.gcaSelected = false);

        }

      });
    }

  }

  //  UPDATE GCA SET
  updateGCA(_this: any, cycles: any, type: any = "train", action: any = null) {

    this.pageState.next(4);
    let url = "/apif/" + (type == 'train' ? 'gca-config' : 'gca-push') + "/" + _this.machineId;

    let query = action != null ? { action: action } : null;
    let payload = cycles;

    _this.apiService.sendPostRequest(url, payload, query)
      .pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {
          console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);

          this.snackBar.open(this.translate.instant('CYCLES.GCA_RESPONSE.' + (error.status == 409 ? '409' : '500')), '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['snackbar', 'fail']
          });

          this.pageState.next(8);
          return throwError(error.status + ': ' + error.statusText);
        })
      )
      .subscribe(
        (data: any) => {
          console.log(data.body);

          this.pageState.next(8);
          this.snackBar.open(this.translate.instant('CYCLES.GCA_RESPONSE.204'), '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: 2000,
            panelClass: ['snackbar', 'success']
          });

        }
      );

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);
  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.pollingSubscription.unsubscribe() } catch (error) { }
  }

}