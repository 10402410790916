import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import { FilterByPropertyPipe } from 'src/app/pipes/filterByProperty.pipe';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';
import { StandardDialogServiceService } from 'src/app/services/standard-dialog.service';
import { StandardParsingService } from 'src/app/services/standard-parsing.service';

@Component({
  selector: 'app-standard-dashboard-table-page',
  templateUrl: './standard-dashboard-table-page.component.html',
  styleUrls: ['./standard-dashboard-table-page.component.scss']
})
export class StandardDashboardTablePageComponent implements OnInit, OnDestroy {

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // COMPONENT CONFIGURATION
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  

  // // // // // // // //
  // PAGE CONFIG
  // // // // // // // //

  // Section name: "appConfig[sectionName]"
  public sectionName: any = null;

  // Tab name: "appConfig[sectionName][tabName]"
  public tabName: any = null;

  // Detail tab name: "appConfig[sectionName][tabName].detailTabs[detailTabName]"
  public detailTabName: any = null;

  // Back button (only in case of detailTabName != null)
  public backButton: any = null;

  // Permission to load the page (in case authentication is provided)
  public permission: any = null;

  // Additional filter buttons
  public additionalFilterButtons: any = [];

  // Custom buttons
  public customFilterButtons: any = [];

  // Count
  public count: any;

  // // // // // // // //
  // DASHBOARD CONFIG
  // // // // // // // //

  // Dashboard name: "dashboard-[dashboardName].json"
  public dashboardName: any = null;

  // Dashboard type: 
  // - default: null (table)
  // - "centralPlotLateralWidgets": centralPlotLateralWidgets (remote monitoring, prod trend ecc...) 
  public dashboardType: any = null;

  // Expected widgets
  public expectedWidgets: any = null;

  // Widget groups
  public widgetGroups: any = null;

  // // // // // // // //
  // MAIN FUNCTION
  // // // // // // // //

  // Loading message
  public loadingMessageMainFunction: any = "GLOBAL.LOADING";

  // deleteIntervalAggregationsFromQuery
  public deleteIntervalAggregationsFromQuery: any = false;

  // Default Polling Time [ms]
  public defaultPollingTime: any = 0;

  // Endpoint url: "[endpointUrl] + [machineId]"
  public endpointUrl: any = null;

  // Parsing Function (inside standardParsingService)
  public parsingFunction: any = null;

  // Parsing Function CUSTOM (inside standardParsingService - CustomParsing)
  public parsingFunctionCustom: any = null;

  // Additional parse table config
  public additionalParseTableConfig: any = null;

  // Parse response inside "table" (only for tables and plots, for standard dashboard set to false)
  public parseResponseInTableAttribute: any = true;

  // Filter variables
  public filterVariables: any = null;

  // Search
  public isEnabledSearch: any = null;

  // Slider
  public isEnabledSlider: any = null;

  // Save Button
  public isEnabledSave: any = null;

  // Parsing Function for save (inside standardParsingService)
  public parsingFunctionSave: any = null;

  // Switch
  public isEnabledSwitch: any = null;
  public switch: any = null;

  // Is empty list
  public isEmptyList: any = null;

  // Request method --> by default POST
  public requestMethod: any = "POST";

  // // // // // // // //
  // OTHER
  // // // // // // // //

  // Breadcrumb
  public breadcrumb: any = null;

  // interval
  public hideInterval: boolean = false;
  public cachedIntervalId: any = null;
  public previousInterval: any = null;
  public defaultInterval: any = null;
  public defaultIntervalListId: any = null;
  public defaultIntervalSelected: any = null;
  public showIntervalAggregations: any = null;
  public intervalAggrList: any = null;

  // true if the  machine selector is not needed for the current page
  public excludeLine: any = null;

  // true if average and sum are calculated for every parameter of the table
  public addStandardStatisticsToDashboard: any = null;

  // true if average and sum are calculated for every parameter of the table
  public groupByStandardStatistics: any = null;

  // true if machine Id in query must have all submachines
  public joinAvailableMachines: any = false;

  // true if aggregations buttons are shown
  public showAggregationButtons: any = null;

  // true if aggregations dropdown is shown
  public showAggregationDropdown: any = false;

  // Aggregation dropdown selected (only if showAggregationDropdown == true)
  public aggrDropdown: any = null;
  public aggrSelected: any = null;

  // Additional variables to pass to BE
  public requestData: any = null;

  // Table cells that have been edited
  public tableCellsEdited: any = [];

  // If the page has custom action buttons
  public customActionButtons: any = [];

  // If the custom action buttons are in tab toolbar
  public customActionButtonsInTabToolbar: any = false;

  // Page shown in mobile
  public hideMobile: any = false;

  // Custom Translations
  public customTranslations: any = null;

  // First request
  public firstRequest: any = null;

  // Additional subscription
  public customSubscription: Subscription;

  // Historic shift mode (barilla)
  public historicShiftMode: any = false;

  // Complete payload (barilla)
  public completePayload: any = {};

  // Use unparsed dashboard (barilla) --> reconfigure the dashboard from the initial configs
  public useUnparsedDashboard: any = false;

  // Detail page config (barilla)
  // If specified in query params, decodes a base64 object used as configuration for the detailed page
  public detailPageConfig: any = null;

  // Additional dashboard config (barilla)
  // Instead of iniliatizing a new variable for every new key in the dashboard, use this object
  public additionalDashboardConfig: any = null;

  // Aggregated table (cycle list, ingenya)
  // For tables with a high number of rows, enable aggregation
  public aggregatedTable: any = null;
  // Show reset interval - used in combination with the aggregated table
  public showResetInterval: any = null;

  // Check state polling function (mat docs)
  // Continuous polling to a fixed endpoint that checks the page state
  public checkStatePollingFunction: any = null;
  public pollingSubscriptionCheckState: Subscription;
  public checkStatePollingTimeMilli: any = 10000;

  // Additional variables (barilla)
  // Instead of iniliatizing a new variable for every new key in the dashboard, use this object
  public additionalVariables: any = null;

  // Unparsed response
  // Stores in a cloned object the exact response of the endpoint
  public unparsedRespone: any = null;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  

  public isAllowedUser: boolean = true;

  public configurationErrors: any = [];

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public tabs: any;

  public aggregations: any;
  public aggregationsPayload: any;

  public machineId: any;
  public machine: any;
  public machineSelectedSub: Subscription;

  public availableMachines: any;

  public pollingTime: any;
  public pollingSubscription: Subscription;

  public interval: any;
  public intervalConfig: any;

  public filterButtons: any;

  public searchElements: any;

  public sliderConf: any = {};
  public sliderConfiguration: any = {};

  public dashboardData: any;
  public dashboardNameComplete: any;
  public dashboardConfig: any;
  public completeDashboardConfig: any;

  public intervalAggregations: any;

  public mobileListener: Subscription;
  public isMobile: any;
  public isSmThanTablet: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3.5, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3.5,
      codes: [
        { code: 300, function: this.checkStatePolling, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.mainPollingFunction, nextState: 5, loadingMsg: this.loadingMessageMainFunction },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public router: Router,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public standardParsingService: StandardParsingService,
    public standardDialogServiceService: StandardDialogServiceService,
    public mobile: MobileService,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.mobileListener = this.mobile.mobileListener.subscribe(value => {
      this.isMobile = value.isMobile;
      this.isSmThanTablet = value.isSmThanTablet;
    });
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  getDashboard(_this: any) {

    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    // Single permission as string (es. "mat-ms-alarms")
    if (typeof _this.permission == 'string' || _this.permission == null) {
      try { _this.isAllowedUser = _this.internalDataService.getSpecificPermission(_this.permission) }
      catch (error) { console.log(error) }
    }

    // Multiple permissions as object
    // {
    //   "operator": "or"                                   - string:           "or" / "and"
    //   "roles": ["mat-ms-alarms", "mat-ms-signalations"]  - list of strings:  ["...", "---"]
    // }
    else if (typeof _this.permission == 'object' && Object.keys(_this.permission)?.length > 0) {
      try { _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(_this.permission?.roles ?? [], _this.permission?.operator) }
      catch (error) { console.log(error) }
    }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabsFromObject(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {

      _this.expectedWidgets?.forEach(widget => {
        let actualWidgetsLength = _this.dashboardConfig?.widgets?.filter(x => x.type == widget.id)?.length ?? 0;
        if (actualWidgetsLength != widget.number) {
          let label = "Expected " + widget.number + ' \"' + _this.translate.instant(widget.label) + "\" widget" + (widget.number != 1 ? 's' : '') + " (type \"" + widget.id + "\"), but " + actualWidgetsLength + " " + (actualWidgetsLength == 1 ? 'was' : 'were') + " found in the file \"" + _this.dashboardNameComplete + "\", (key: \"widgets\")"
          _this.configurationErrors.push({ label: label })
        }
      })

      if (_this.configurationErrors?.length > 0) _this.internalDataService.openSnackBar(_this.configurationErrors.map(x => "- " + x.label + ".").join("\n"), 'right', 'bottom', 60000, 'x', ["warning"]);

      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName, true);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) {
    if (this.additionalDashboardConfig?.removeCachedVariablesOnIntervalSelection?.length > 0) {
      this.additionalDashboardConfig?.removeCachedVariablesOnIntervalSelection.forEach(x => this.cacheService.set(x, null));
    }
    this.intervalService.selectInterval(this, interval, this.pollingSubscription, this.mainPollingFunction, this.mainRequest, this.machine.timezone, 5, 6)
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval aggr
  selectIntervalAggregation(interval: any) { this.intervalService.selectIntervalAggregation(this, interval, this.pollingSubscription, this.mainPollingFunction, this.mainRequest, 5) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // aggregation
  changePageAggregation(aggrId: any) {
    try {
      try { this.pollingSubscription.unsubscribe() } catch (error) { }
      this.aggrDropdown = aggrId;
      this.aggrSelected = this.aggregations?.find(x => x.id == aggrId);

      this.pageState.next(5);
      if (!this.interval?.enabledPolling) this.mainRequest(this, 0);
      else this.mainPollingFunction(this);
    } catch (error) { console.log(error) }
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // Check state polling function
  async checkStatePolling(_this: any) {

    if (_this.checkStatePollingFunction == null) {
      _this.dispatcherService.getDispatch(_this, 300);
      return;
    }

    if (_this.standardParsingService?.[_this.checkStatePollingFunction] == null) {
      _this.internalDataService.openSnackBar(_this.translate.instant('GLOBAL.NO_POLLING_FUNCTION_CONFIGURED', {
        clickFunction: _this.checkStatePollingFunction ?? '-'
      }), 'right', 'bottom', 4000, '', ["warning"]);
      _this.dispatcherService.getDispatch(_this, 301, {});
      return;
    }

    _this.pollingSubscriptionCheckState = timer(0, _this.checkStatePollingTimeMilli).subscribe(async (count) => await _this.standardParsingService?.[_this.checkStatePollingFunction](_this, count));

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // Main polling function
  mainPollingFunction(_this: any) {
    try {

      try {

        // Calculate interval with machine timezone
        if (_this.interval == null) {
          if (_this.cacheService.get(_this.cachedIntervalId) == null) {

            _this.interval = _this.previousInterval ?? _this.intervalService.getIntervalById(_this.defaultIntervalSelected, _this.machine.timezone);

            _this.intervalConfig = {
              list: _this.intervalService.getDefaultIntervals(_this.defaultIntervalListId, _this.machine.timezone),
              selected: _this.interval
            };

            if (_this.cachedIntervalId != null) _this.cacheService.set(_this.cachedIntervalId, _this.intervalConfig);

          } else {
            _this.intervalConfig = _this.cacheService.get(_this.cachedIntervalId);
            _this.interval = _this.cacheService.get(_this.cachedIntervalId).selected;
          }
        }

      } catch (error) { console.log(error) }

      if (_this.pollingTime > 0) _this.pollingSubscription = timer(0, _this.pollingTime).subscribe((count) => _this.mainRequest(_this, count));
      else _this.mainRequest(_this, 0);

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // Main request
  mainRequest(_this: any, count?: any) {
    try {

      let url = _this.endpointUrl + _this.machineId;

      if (count == 0 && _this.intervalAggrList?.length > 0) _this.internalDataService.forceAggregationList(_this, _this.intervalAggrList);

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      if (_this.customFilterButtons?.length > 0 && count != 0) {
        try {
          payload.customFilters = _this.customFilterButtons?.reduce((acc, button) => {
            acc[button.variable] = button.options?.reduce((a, v) => {
              if (v?.selected) a.push(v.id);
              return a;
            }, []);
            return acc;
          }, {});
        } catch (error) { }

      }

      if (_this.interval != null && _this.interval.enabledPolling) {
        _this.interval = _this.intervalService.getIntervalById(_this.intervalConfig.selected.id, _this.machine.timezone);
        _this.intervalConfig.selected = JSON.parse(JSON.stringify(_this.interval));
      }

      if (_this?.requestData) payload.customParams = _this.clonerService.deepClone(_this.requestData);

      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone,
        lang: _this.translate?.currentLang ?? 'en',
      };

      // Add additional query params to the query (es. case of reel detail)
      try {
        let standardQueryParams = ['sectionName', 'tabName', 'detailTabName', 'detailPageConfig'];
        let queryParamsToKeep = _this.filterService.removeKeysWithCustomRule(_this.route.snapshot.queryParams, (x) => !standardQueryParams.includes(x[0]));
        if (Object.keys(queryParamsToKeep)?.length > 0) query = { ...query, ...queryParamsToKeep };
      } catch (error) { console.log(error) }

      if (!_this.deleteIntervalAggregationsFromQuery) {
        if (_this.intervalAggregations?.selected?.value) query.value = _this.intervalAggregations.selected.value;
        if (_this.intervalAggregations?.selected?.unit) query.unit = _this.intervalAggregations.selected.unit;
      }

      if (_this.aggrDropdown) query.aggrUnit = _this.aggrDropdown;

      if (_this.additionalParseTableConfig?.nestedKeyToSearch) query.tableKey = _this.additionalParseTableConfig.nestedKeyToSearch;
      if (_this.interval != null && !_this.interval.enabledPolling) query.to = _this.interval.end;

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine?.machineReference, query, _this.joinAvailableMachines);

      // Barilla

      _this.historicShiftMode = _this.cacheService.get("historicShiftMode") ?? _this.historicShiftMode;

      if (_this.historicShiftMode) {
        payload.isCurrentShift = false;
        payload.shiftId = _this.shiftId;
      }

      if (!_this.additionalDashboardConfig?.excludeHistoricShiftCaching) {

        let historicShiftConfig = _this.cacheService.get("historicShiftConfig");

        if (historicShiftConfig != null) {
          query.from = historicShiftConfig?.shiftStart;
          query.to = historicShiftConfig?.shiftEnd;
          _this.shiftId = historicShiftConfig?.label;
        }

      }

      // Barilla - Batch detail

      if (_this.detailPageConfig != null && _this.additionalDashboardConfig != null) {

        let additionalParsingFunction = _this.additionalDashboardConfig.parsingFunction;

        if (_this.standardParsingService?.[additionalParsingFunction] == null) {
          _this.internalDataService.openSnackBar(_this.translate.instant('GLOBAL.NO_CLICK_FUNCTION_CONFIGURED', {
            clickFunction: additionalParsingFunction ?? '-'
          }), 'right', 'bottom', 4000, '', ["warning"]);
        }

        else {
          _this.standardParsingService?.[additionalParsingFunction](_this, query, payload);
        }
      }

      _this.completePayload = _this.clonerService.deepClone(payload);

      let requestMethodFunc = _this.apiService.sendPostRequest(url, payload, query);
      if (_this.requestMethod == "GET") requestMethodFunc = _this.apiService.sendGetRequest(url, query);

      requestMethodFunc
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingSubscription)))
        .subscribe(
          (data: any) => {
            // console.log(data);
            _this.unparsedRespone = _this.clonerService.deepClone(data?.body);
            _this.handleResponse(_this, data?.body, count);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  handleResponse(_this, data, count?, skipParsing?) {

    if (!_this.firstRequest) _this.firstRequest = _this.clonerService.deepClone(data);

    _this.count = count;

    let list: any = [];

    if (_this.standardParsingService?.[_this.parsingFunction] == null) {
      _this.internalDataService.openSnackBar(_this.translate.instant('GLOBAL.NO_CLICK_FUNCTION_CONFIGURED', {
        clickFunction: _this.parsingFunction ?? '-'
      }), 'right', 'bottom', 4000, '', ["warning"]);
      _this.dispatcherService.getDispatch(_this, 301, {});
      return;
    }

    if (skipParsing) list = _this.clonerService.deepClone(data);
    else {
      if (_this.parsingFunction == 'custom') list = _this.standardParsingService?.[_this.parsingFunction](_this.parsingFunctionCustom, data, _this.machineId, _this.machine, _this.additionalParseTableConfig, _this);
      else list = _this.standardParsingService?.[_this.parsingFunction](data, _this.machineId, _this.machine, _this.additionalParseTableConfig, _this);
    }

    // init slider
    if (_this.isEnabledSlider && _this.sliderConfiguration?.maxFromUpperVariable) _this.initSlider(_this, list);

    _this.dashboardData = {
      removeAnimations: count > 0,
    };

    if (!_this.parseResponseInTableAttribute) {
      _this.dashboardData = { ..._this.dashboardData, ...list };
    } else {
      _this.dashboardData = {
        removeAnimations: count > 0,
        list: list,
      };
    }

    _this.isEmptyList = list?.length == null || list?.length == 0;

    _this.dispatcherService.getDispatch(_this, 300);

    if (count == 0) _this.filterButtons = _this.filterService.buildFilterButtons(_this);

    _this.filterElements();

    // If redirect from maintenance calendar, open the maintenance intervention
    if (_this.cacheService.get("addMaintenanceIntervention") != null) {
      try {
        let dialogOptions: any = _this.clonerService.deepClone(_this.dashboardConfig.customActionButtons[0])
        _this.openStandardDialog({
          buttonInfos: dialogOptions,
          tableInfos: dialogOptions.tableInfos,
          row: _this.cacheService.get("addMaintenanceIntervention"),
        });
      } catch (error) {
        console.log(error);
      }
    }

  }

  onSwitchChange(value: boolean) {
    try { this.switch.checked = value; } catch (error) { }
    this.filterElements();
  }

  filterElements() {

    let standardStatistics = {};

    if (this.parseResponseInTableAttribute) {
      let filterVariables = this.filterVariables ?? [];

      let filtered: any = this.clonerService.deepClone(this.dashboardData.list);

      if (!this.aggregatedTable == true) {
        if (this.switch?.checked) {

          if (this.switch != null) {
            let condition = this.switch?.condition;

            // Specific condition/s specified
            if (condition != null) {
              try {

                // condition as array es. ["active", "!=", true]
                if (Array.isArray(condition) && condition.length > 0) {
                  let filterPipe = new FilterByPropertyPipe();
                  if (condition.every(x => Array.isArray(x) && x?.length == 3)) condition.forEach(c => filtered = filterPipe.transform(filtered, c));
                  else if (condition.length == 3) filtered = filterPipe.transform(filtered, condition);
                }

                // condition as string es. "active"
                else filtered = filtered.filter((x: any) => x[condition]);
              } catch (error) { console.log(error) }
            }

            // No condition specified --> search variable
            else filtered = filtered.filter((x: any) => x[this.switch.variable ?? 'isActive']);

          }
        }

        if (this.searchElements != null && this.searchElements != '') {
          try { filtered = this.filterService.filterBySearch(filtered, this.searchElements, filterVariables); }
          catch (error) { console.log(error) }
        }

        if (this.filterButtons?.length > 0) {
          try { this.filterButtons.forEach((filterButton: any) => filtered = filtered.filter((data: any) => filterButton.options.filter((opt: any) => opt.selected).some((opt: any) => String(data[filterButton.variable]) == (String(opt.id))))) }
          catch (error) { console.log(error) }
        }

        if (this.sliderConf?.min != null && this.sliderConf?.max != null) {

          let lowerVariable = this.sliderConfiguration?.lowerVariable ?? 'timeStartHours';
          let upperVariable = this.sliderConfiguration?.upperVariable ?? 'timeEndHours';

          try { filtered = filtered.filter((x: any) => x?.[lowerVariable] >= this.sliderConf.min && x?.[upperVariable] <= this.sliderConf.max) }
          catch (error) { console.log(error) }
        }
      }

      this.dashboardData.table = filtered;

      standardStatistics = this.addStandardStatisticsToDashboard ? this.internalDataService.addStandardStatistics(filtered, this.interval?.numberOfDays, this.groupByStandardStatistics) ?? {} : {};

    }

    this.completeDashboardConfig = {
      dashboardData: Object.assign(this.clonerService.deepClone(this.dashboardData), standardStatistics),
      machineProfile: this.machine.profile,
      dashboardConfig: this.dashboardConfig,
      aggregations: this.aggregations,
      dashboardType: this.dashboardType,
      aggregatedTable: this.aggregatedTable,
    };

    // let keysToExclude: any = Object?.keys(this.completeDashboardConfig).concat("completeDashboardConfig").concat([
    let keysToExclude: any = Object?.keys({}).concat("completeDashboardConfig").concat([
      'route',
      'router',
      'translate',
      'dialog',
      '__ngContext__'
    ]);
    let refComp: any = Object?.entries(this)?.filter(kv => !keysToExclude.includes(kv[0]) && !kv[0].toLocaleLowerCase().endsWith("service"))?.reduce((acc, val) => {
      try { acc[val[0]] = val[1] } catch (error) { }
      return acc;
    }, {})

    this.completeDashboardConfig.referenceComponent = refComp;

    // console.log(this.completeDashboardConfig);

  }

  initSlider(_this: any, list?: any) {

    try {

      let max = _this.sliderConfiguration?.max ?? 0;
      if (list?.length > 0 && _this.sliderConfiguration?.upperVariable) {
        max = list?.sort(_this.filterService.sortByProperty(_this.sliderConfiguration?.upperVariable ?? 'timeEndHours', 'desc', true))?.[0]?.[_this.sliderConfiguration?.upperVariable];
      }

      let translateConfig = _this.sliderConfiguration?.translate;
      _this.sliderConf = {
        show: true,
        max: max,
        min: _this.sliderConf?.min != null && !isNaN(_this.sliderConf.min) ? _this.sliderConf.min : (_this.sliderConfiguration?.min ?? 0),
        options: {
          floor: _this.sliderConfiguration?.min ?? 0,
          ceil: max,
          step: _this.sliderConfiguration?.step ?? 1,
          translate: (index) => {
            // return _this.filterService.parseGaugeValue(_this.filterService.convertUnit(translateConfig?.unit, index).value, translateConfig?.decimals ?? 0, translateConfig?.multiplier ?? 1) + (_this.filterService.convertUnit(translateConfig?.unit).unit ?? '') 
            try { return _this.filterService.parseObjFromConfig(index, translateConfig, true, 'type', translateConfig?.unit != null) }
            catch (error) { return index }
          },
        },
        userChangeEnd: () => _this.filterElements()
      }
    } catch (error) { console.log(error) }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // MAIN SAVE FUNCTION

  mainSaveFunction() {
    if (this.tableCellsEdited?.length > 0 && this.parsingFunctionSave != null) this.standardParsingService?.[this.parsingFunctionSave](this.tableCellsEdited, this.machineId, this.machine, this.additionalParseTableConfig);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // SELECTION CHANGE (BARILLA SUBBATCHES)

  selectionChange(value, type, parsingFunction?) {

    let additionalParsingFunction = parsingFunction ?? this.additionalDashboardConfig.onSelectionChange;

    if (this.standardParsingService?.[additionalParsingFunction] == null) {
      this.internalDataService.openSnackBar(this.translate.instant('GLOBAL.NO_CLICK_FUNCTION_CONFIGURED', {
        clickFunction: additionalParsingFunction ?? '-'
      }), 'right', 'bottom', 4000, '', ["warning"]);
      return;
    }

    let data = this.standardParsingService?.[additionalParsingFunction](this, value, type);
    this.handleResponse(this, data, null, true);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // ON FILTERS DIALOG SELECT

  onFiltersDialogSelect() { this.filterElements(); }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // ON FILTERS DIALOG SELECT

  onCustomFiltersDialogSelect() {
    this.pageState.next(5);

    setTimeout(() => {

      try {
        // this.pageState.next(6);
        this.mainRequest(this);
      } catch (error) { console.log(error) }

    }, 500);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // ON FILTERS DIALOG SELECT
  resetDefaultInterval() {

    this.showResetInterval = false;
    this.cacheService.set(this.cachedIntervalId, null);
    this.interval = null;

    if (this.defaultInterval != null) this.previousInterval = this.clonerService.deepClone(this.defaultInterval);

    this.pageState.next(5);
    this.mainPollingFunction(this);

  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {
    this.machineId = this.route.snapshot.params['machineId'];

    let queryParams = this.route.snapshot.queryParams;

    this.sectionName = queryParams.sectionName;
    this.tabName = queryParams.tabName;

    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);

    this.route?.queryParams?.subscribe((queryParam: Params) => {

      this.detailPageConfig = null;

      if (queryParam?.detailPageConfig != null) {
        try { this.detailPageConfig = JSON.parse(atob(queryParam?.detailPageConfig)) }
        catch (error) { console.log(error) }
      }

      try {
        this.sectionName = queryParam.sectionName;
        this.tabName = queryParam.tabName;
      } catch (error) { console.log("Wrong query params") }

      try {
        this.detailTabName = queryParam.detailTabName;
        if (this.detailTabName != null) {
          let queryParamsToKeep = this.filterService.removeKeysWithCustomRule(queryParams, (x) => x[0] == 'sectionName' || x[0] == 'tabName');
          this.backButton = [this.machineId, 'standard-dashboard-table-page', queryParamsToKeep];
          this.internalDataService.setBackButton(this.backButton);
        } else {
          this.internalDataService.setBackButton([]);
        }
      } catch (error) { console.log("No detail page") }

    });

    // init standard page
    this.internalDataService.initStandardPage(this, this.defaultPollingTime, true);

    this.internalDataService.setCustomSubscription(null);

    this.customSubscription = this.internalDataService.customSubscription.subscribe(value => {
      if (value == null) return;
      try { this.handleResponse(this, value.data) }
      catch (error) { console.log(error) }
    });

    // init slider
    if (this.isEnabledSlider && !this.sliderConfiguration?.maxFromUpperVariable) this.initSlider(this);

    this.dispatcherService.getDispatch(this, 300);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // EVENT EMITTERS

  // BUTTON ACTION
  buttonAction(item) { this.openStandardDialog(item) }

  // TABLE ACTION
  tableAction(item) { this.openStandardDialog(item) }

  // OPEN STANDARD DIALOG
  openStandardDialog(item) {

    console.log({ item });

    if (this.standardDialogServiceService?.[item?.buttonInfos?.clickFunction] == null) {
      this.internalDataService.openSnackBar(this.translate.instant('GLOBAL.NO_CLICK_FUNCTION_CONFIGURED', {
        clickFunction: item?.buttonInfos?.clickFunction ?? '-'
      }), 'right', 'bottom', 4000, '', ["warning"]);
      return;
    }
    this.standardDialogServiceService?.[item?.buttonInfos?.clickFunction]?.(this, item);
  }

  // TABLE INPUT TAG CHANGE
  tableInputTagChange(item) {
    console.log(item);
  }

  openDescriptionDialog(title, description) {
    let item = {
      buttonInfos: {
        clickFunction: "openHtmlDialog",
        dialogTitle: title,
        description: description,
      }
    };

    this.openStandardDialog(item);
  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY

  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingSubscription.unsubscribe() } catch (error) { }
    try { this.pollingSubscriptionCheckState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.customSubscription.unsubscribe() } catch (error) { }

    if (this.additionalDashboardConfig?.removeCachedVariablesOnPageDestroy?.length > 0) {
      this.additionalDashboardConfig?.removeCachedVariablesOnPageDestroy.forEach(x => this.cacheService.set(x, null));
    }

    try { this.internalDataService.setCalendarPage(false) } catch (error) { }
  }

}