<div *ngIf="isMobile" fxFlex fxLayout="column" class="tab-content h100 w100" fxLayoutAlign="center center">
  <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
</div>

<agm-map *ngIf="!isMobile" [latitude]="mapOptions.center.lat" [longitude]="mapOptions.center.lng"
  [zoom]="mapOptions.zoom" [disableDefaultUI]="false">

  <map-card *ngFor="let machine of machines | ffMachinesFilters: mFilters; let i = index; trackBy trackById"
    [machineData]="machine">
  </map-card>

</agm-map>
