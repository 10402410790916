import { Pipe, PipeTransform } from '@angular/core';
import { FfTranslateService } from '../services/ff-translate.service';

// If no translation, return the initial string

@Pipe({ name: 'checkTranslation' })
export class CheckTranslationPipe implements PipeTransform {
    constructor(private translate: FfTranslateService) { }

    transform(value: any, returnValue: any, showTicks: any = true, isObject: boolean = false): any {

        let isValidValue = value != null && value != undefined && value != '';
        let translated = isValidValue ? this.translate.instant(value) : value;
        let translation = translated == value ? (showTicks ? "--" : "") + returnValue + (showTicks ? "--" : "") : translated;
        return !isObject ? translation : {
            isTranslated: translated != value,
            translation: translation,
        };
    }
}