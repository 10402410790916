<div mat-dialog-title fxLayout="row" class="w100">
    <span>{{ dialog.title | translate }}</span>
    <span fxFlex></span>
    <button mat-dialog-close class="close">
        <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
    </button>
</div>

<mat-dialog-content class="mat-typography">

    <div class="input-box" style="margin-top: 8px;">
        <input matInput class="ff-input text w100" type="text" [(ngModel)]="folderName">
        <div class="required" fxLayout="row" fxLayoutAlign="start center">
            <span *ngIf="folderName == null || folderName == ''" translate="GLOBAL.REQUIRED"></span>

            <span *ngIf="folderName != null && folderName != '' && !getRegex('filePath', folderName)">
                {{ 'GLOBAL.INVALID_REGEX' | translate }}
            </span>

            <span *ngIf="folderName != null && folderName != '' && invalidValues?.includes(folderName)" fxLayout="row"
                fxLayoutAlign="start center">
                {{ 'GLOBAL.FOLDER_ALREADY_CREATED' | translate }}
            </span>
        </div>
    </div>

    <ng-container *ngIf="isSupplier && !dialog?.buttonInfos?.hideSendMailToCustomers">

        <div fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 12px;">
            <mat-checkbox [(ngModel)]="sendMailToCustomers"></mat-checkbox>
            <span right>{{ 'FILE_EXPLORER.SEND_MAIL_TO_CUSTOMERS' | translate }}</span>
            <ff-icon icon="info" right
                matTooltip="{{ 'FILE_EXPLORER.SEND_MAIL_TO_CUSTOMERS_FOLDER_TOOLTIP' | translate }}"
                matTooltipClass="myClass">
            </ff-icon>
        </div>

    </ng-container>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
    <button [disabled]="folderName == null || dialogState == 0 || invalidValues?.includes(folderName)"
        class="filter-button md-primary" (click)="uploadFolder()">
        {{ 'GLOBAL.CONFIRM' | translate}}
    </button>
</mat-dialog-actions>