import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  form: FormGroup;
  loading = false;
  submitted = false;
  error;

  sidenavHideSource: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    public http: ApiService,
    public translate: FfTranslateService,
    public internalDataService: InternalDataService,
  ) {
    this.internalDataService.setHideSidenav(true);
    this.internalDataService.setBreadcrumb([]);
  }

  ngOnInit() {
    // aggiungere magari app id per avere
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  async checkUser() {

  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  async onSubmit() {
    this.submitted = true

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    let resp: any = null;
    try {

      let url = "/apif/check-user";

      let payload = {
        user: this.f.username.value,
        password: this.f.password.value
      };

      resp = await this.http.sendPostRequest(url, payload, {}).toPromise();

      // document.cookie = `basicAuthHeader=Basic ${btoa(`${this.f.username.value}:${this.f.password.value}`)}`;
      document.cookie = `basicAuthHeader=${this.f.username.value}:${this.f.password.value}`;
      console.log(document.cookie)

      localStorage.setItem('basicAuthHeader', `${this.f.username.value}:${this.f.password.value}`);

      window.location.href = '/homepage';

    } catch (error) {

      console.log(error);
      this.error = this.translate.instant("LOGIN.INVALID_USERNAME_OR_PASSWORD")

    }

    this.loading = false;
  }
}