<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [intAggrs]="intervalAggregations" [config]="intervalConfig"
      (selected)="selectInterval($event)" (selectedAggr)="selectAggregation($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    [fxLayoutAlign]="pageState.value >= 5 ? 'start stretch' : (pageState.value == 0 ? 'start center' : 'center center')"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar -->
    <div class="ff-toolbar pb-3 pt-1" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">

      <!-- aggregations -->
      <button mat-button class="filter-button" *ngFor="let aggr of aggregations" (click)="openAggrDialog(aggr)"
        [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
        <mat-icon *ngIf="aggr.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': aggr.label}">
          {{aggr.icon.icon }}</mat-icon>
        <mat-icon *ngIf="aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
          translate-attr="{'aria-label': aggr.label}"></mat-icon>
        <i *ngIf="aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor'" class="icon {{ aggr.icon.icon }}"
          translate-attr="{'aria-label': aggr.label}"></i>
        <span>{{ aggr.selectionLabel }}</span>
        <div fxLayout="row" fxLayoutAlign="center center"
          *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
          {{ aggregationsPayload[aggr.id].length }}
        </div>
      </button>

      <span fxFlex fxShow="false" fxShow.gt-sm></span>

      <!-- filter buttons -->
      <ff-filter-buttons [filterButtons]="filterButtons" (selected)="onFiltersDialogSelect($event)"
        [ngStyle.gt-sm]="{'margin-right': availableMachines?.list?.length > 0 ? '8px' : '0'}"
        [ngStyle.lt-md]="{'margin-top': availableMachines?.list?.length > 0 ? '8px' : '0'}">
      </ff-filter-buttons>

      <ff-available-machines-selection *ngIf="availableMachines?.list?.length > 0"
        [availableMachines]="availableMachines" (selected)="machineSelectionChange($event)">
      </ff-available-machines-selection>

    </div>

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 5" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 5" fxLayout="column" class="w100 h50"
      [ngStyle]="{'min-height': isSmThanTablet ? '0px' : 'calc(100vh - 260px)'}">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
        class="h100 w100" fxLayoutGap="8px">

        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" fxFlexOrder.lt-md="2" class="w100"
          [class.h100]="!isMobile" fxLayoutGap="8px">

          <ff-widget *ngIf="pageState.value < 8" fxFlex="100" [widget]="{type: 'ff-loading-card'}" fxFlex.gt-sm="100">
          </ff-widget>

          <ff-widget *ngIf="pageState.value >= 8" fxFlex.gt-sm="100"
            [widget]="{type: 'ff-plotly-chart', data: faultData, machineProfile: machine.profile, config: faultData.dataConfig }">
          </ff-widget>
          <!-- loading -->

          <!-- <ff-widget *ngIf="pageState.value <6" fxFlex="100" [widget]="{type: 'ff-loading-card'}" fxFlex.gt-sm="45">
          </ff-widget>

          <ff-widget *ngIf="pageState.value >= 6" fxFlex.gt-sm="45"
            [widget]="{type: 'ff-plotly-chart', data: breakdownsData, machineProfile: machine.profile, config: breakdownsData.dataConfig }">
          </ff-widget>
           -->
        </div>

        <div *ngIf="pageState.value < 8" fxFlex.gt-sm="50" fxLayout="row wrap" fxLayoutAlign="stretch start"
          fxLayout.gt-sm="column" fxLayoutAlign.gt-sm="start stretch" fxFlexOrder.lt-md="1" class="w100"
          [class.h100]="!isSmThanTablet" fxLayoutGap="8px">

          <ff-widget *ngFor="let w of dashboardConfig.widgets" fxFlex="100"
            [fxFlex.sm]="'0 1 calc(' + (w.mobileFlex != null ? w.mobileFlex : 50) + '% - 12px)'"
            fxFlex.gt-sm="{{w.flex}}" [widget]="{type: 'ff-loading-card',config: w.config, title: w.title}">
          </ff-widget>

        </div>

        <div *ngIf="pageState.value >= 8" fxFlex.gt-sm="50" fxLayout="row wrap" fxLayoutAlign="stretch start"
          fxLayout.gt-sm="column" fxLayoutAlign.gt-sm="start stretch" fxFlexOrder.lt-md="1" class="w100"
          [class.h100]="!isSmThanTablet" fxLayoutGap="8px">

          <ff-widget *ngFor="let w of dashboardConfig.widgets" fxFlex="100"
            [fxFlex.sm]="'0 1 calc(' + (w.mobileFlex != null ? w.mobileFlex : 50) + '% - 12px)'"
            fxFlex.gt-sm="{{w.flex}}"
            [widget]="{ type: w.type, data: breakdownsData, machineProfile: machine.profile, config: w.config, title: w.title }">
          </ff-widget>

        </div>

      </div>

      <div class="w100" style="margin-top: 12px;">
        <ff-widget *ngIf="pageState.value <6" fxFlex="100" [widget]="{type: 'ff-loading-card'}" fxFlex.gt-sm="100">
        </ff-widget>

        <ff-widget *ngIf="pageState.value >= 6" fxFlex.gt-sm="100"
          [widget]="{type: 'ff-plotly-chart', data: breakdownsData, machineProfile: machine.profile, config: breakdownsData.dataConfig }">
        </ff-widget>

      </div>

    </div>

  </div>

</div>