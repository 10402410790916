<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div
    *ngIf="tabs.length > 1 || intervalConfig != null || (customActionButtons?.length > 0 && customActionButtonsInTabToolbar)"
    class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- custom-action-buttons -->
    <ff-custom-action-buttons *ngIf="customActionButtonsInTabToolbar && pageState.value >= 6"
      [buttons]="customActionButtons" [referenceComponent]="this" (selected)="buttonAction($event)"
      [ngStyle.gt-sm]="{'margin-right': isEnabledSearch ? '8px' : '0'}"
      [ngStyle.lt-md]="{'margin-bottom': isEnabledSearch ? '8px' : '0'}">
    </ff-custom-action-buttons>

    <!-- show reset interval -->
    <button *ngIf="(!isEnabledSlider || sliderConf == null) && showResetInterval" mat-button
      class="filter-button md-gray-dark" aria-label="remove filters" (click)="resetDefaultInterval()">
      <mat-icon class="icon">refresh</mat-icon>
      <span style="padding-left: 8px;">{{'CONTINUOUS_EXPLORATION.RESET_INTERVAL' | translate}}</span>
    </button>

    <!-- intervals -->
    <ff-interval-selector *ngIf="(!isEnabledSlider || sliderConf == null) && !hideInterval" [config]="intervalConfig"
      (selected)="selectInterval($event)" [intAggrs]="showIntervalAggregations ? intervalAggregations : null"
      (selectedAggr)="selectIntervalAggregation($event)"
      [fullWidthStart]="interval?.id == 'custom' && aggregatedTable != null">
    </ff-interval-selector>

  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < 5 || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar -->
    <div *ngIf="pageState.value >= 5 && (isEnabledSlider || sliderConf?.show)" class="ff-toolbar pb-3" fxLayout="column"
      fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <ng-container *ngIf="!aggregatedTable">
        <!-- slider -->
        <div fxLayout="row" fxLayoutAlign="start center" class="ngx-slider-container" *ngIf="sliderConf.show">
          <ngx-slider [(value)]="sliderConf.min" [(highValue)]="sliderConf.max" [options]="sliderConf.options"
            (userChangeEnd)="sliderConf.userChangeEnd($event)"></ngx-slider>
        </div>
      </ng-container>

      <span fxFlex></span>

      <!-- show reset interval -->
      <button *ngIf="showResetInterval" mat-button class="filter-button md-gray-dark" aria-label="remove filters"
        (click)="resetDefaultInterval()">
        <mat-icon class="icon">refresh</mat-icon>
        <span style="padding-left: 8px;">{{'CONTINUOUS_EXPLORATION.RESET_INTERVAL' | translate}}</span>
      </button>

      <!-- intervals -->
      <ff-interval-selector *ngIf="!hideInterval" [config]="intervalConfig" (selected)="selectInterval($event)"
        [intAggrs]="showIntervalAggregations ? intervalAggregations : null"
        (selectedAggr)="selectIntervalAggregation($event)"
        [fullWidthStart]="interval.id == 'custom' && aggregatedTable != null">
      </ff-interval-selector>

    </div>

    <!-- toolbar -->
    <div *ngIf="pageState.value >= 5 && ((isEnabledSwitch || switch != null) || showAggregationButtons || showAggregationDropdown 
      || filterButtons?.length > 0 || customFilterButtons?.length > 0 || (customActionButtons?.length > 0 && !customActionButtonsInTabToolbar)
      || isEnabledSearch || isEnabledSave) || ((additionalDashboardConfig?.addDropdowns && additionalVariables != null && additionalDashboardConfig.dropdowns?.length > 0))
      || additionalVariables?.descriptionLabel != null" class="ff-toolbar pb-3" fxLayout="column"
      fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <ng-container
        *ngIf="additionalDashboardConfig?.addDropdowns && additionalVariables != null && additionalDashboardConfig.dropdowns?.length > 0">

        <div *ngFor="let dropdown of additionalDashboardConfig.dropdowns" class="box interval-selector" fxLayout="row"
          fxLayoutAlign="start center" style="margin-right: 8px;">
          <ff-icon *ngIf="dropdown.icon != null" [icon]="dropdown.icon"></ff-icon>
          <mat-form-field appearance="fill" [style.min-width.px]="dropdown.long ? 340 : 140">
            <mat-label>{{ dropdown.label | translate }}</mat-label>
            <mat-select [(value)]="additionalVariables[dropdown.variable]">
              <mat-option *ngFor="let aggr of (additionalVariables?.[dropdown.listVariable] ?? [])"
                [value]="aggr[dropdown.idKey ?? 'id']"
                (click)="selectionChange(additionalVariables[dropdown.variable], dropdown.variable, dropdown.onSelectionChange)">
                {{ aggr[dropdown.labelKey ?? 'label'] | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </ng-container>

      <!-- switch -->

      <ng-container *ngIf="!aggregatedTable">
        <div *ngIf="isEnabledSwitch || switch != null" fxLayout="row" fxLayoutAlign="center center"
          style="margin-right: 8px;" class="switch-container">
          <span style="margin-right: 8px;">{{ switch?.title | translate }}</span>
          <ui-switch [checked]="switch?.checked" (change)="onSwitchChange($event)"
            [disabled]="dashboardData?.list?.length == 0" checkedLabel="{{switch?.checkedLabel}}"
            uncheckedLabel="{{switch?.uncheckedLabel}}">
          </ui-switch>
        </div>

        <span *ngIf="isEnabledSwitch || switch != null" fxFlex></span>

        <!-- aggregations -->
        <ff-aggregations-buttons *ngIf="showAggregationButtons" [referenceComponent]="this"
          [aggregationButtons]="aggregations">
        </ff-aggregations-buttons>

        <!-- filter buttons -->
        <ff-filter-buttons [filterButtons]="filterButtons" (selected)="onFiltersDialogSelect($event)"
          [ngStyle.gt-sm]="{'margin-left': aggregations?.length > 0 ? '8px' : '0'}"
          [ngStyle.lt-md]="{'margin-top': aggregations?.length > 0 ? '8px' : '0'}">
        </ff-filter-buttons>

        <!-- filter buttons -->
        <ff-filter-buttons [filterButtons]="customFilterButtons" (selected)="onCustomFiltersDialogSelect($event)"
          [ngStyle.gt-sm]="{'margin-left': aggregations?.length > 0 ? '8px' : '0'}"
          [ngStyle.lt-md]="{'margin-top': aggregations?.length > 0 ? '8px' : '0'}">
        </ff-filter-buttons>

        <span *ngIf="!isEnabledSwitch || switch == null" fxFlex></span>

      </ng-container>

      <ng-container *ngIf="!aggregatedTable">
        <!-- custom-action-buttons -->
        <ff-custom-action-buttons *ngIf="!customActionButtonsInTabToolbar" [buttons]="customActionButtons"
          (selected)="buttonAction($event)" [ngStyle.gt-sm]="{'margin-right': isEnabledSearch ? '8px' : '0'}"
          [ngStyle.lt-md]="{'margin-bottom': isEnabledSearch ? '8px' : '0'}">
        </ff-custom-action-buttons>

        <!-- search -->
        <span *ngIf="isEnabledSearch" style="width: 100px;"></span>
        <div *ngIf="isEnabledSearch" class="search-input" fxLayout="row" fxLayoutAlign="start center"
          style="min-width: 300px;">
          <mat-icon class="icon">search</mat-icon>
          <input type="text" [(ngModel)]="searchElements" (keyup)="filterElements()"
            placeholder="{{'GLOBAL.SEARCH'|translate}}">
        </div>

        <!-- save -->
        <span *ngIf="isEnabledSave || showAggregationDropdown" style="width: 100px;"></span>
        <div *ngIf="isEnabledSave">
          <button class="filter-button md-primary rounded-button" (click)="mainSaveFunction()" fxLayout="row"
            fxLayoutAlign="start center" [disabled]="!pageStateReady || !isAllowedUser">
            <mat-icon class="icon" aria-label="save">save</mat-icon>
            <span>{{'GLOBAL.SAVE'|translate}}</span>
          </button>
        </div>

        <!-- aggregations dropdown -->
        <div
          *ngIf="!isMobile && aggregationsToShowInDropdown?.length > 0 && showAggregationDropdown && pageState.value >= 6"
          class="select-box" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
          fxLayoutAlign.gt-sm="start center">

          <div class="box" fxLayout="row" fxLayoutAlign="start center">
            <ff-icon *ngIf="aggrSelected?.icon" [icon]="aggrSelected?.icon"></ff-icon>
            <mat-form-field appearance="fill">
              <mat-label>{{ 'GLOBAL.DROPDOWN_AGGREGATION_SELECTION' | translate }}</mat-label>
              <mat-select [(ngModel)]="aggrDropdown" name="aggr" style="text-transform: capitalize;">
                <mat-option (click)="changePageAggregation(aggrDropdown)">---</mat-option>

                <mat-option *ngFor="let aggr of aggregationsToShowInDropdown" [value]="aggr.id"
                  (click)="changePageAggregation(aggrDropdown)">

                  <span style="text-transform: capitalize;">{{ aggr.label }}</span>

                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="additionalVariables?.descriptionLabel">

          <ng-container *ngIf="additionalVariables?.descriptionType != 'dialog'">
            <button mat-button class="md-primary circular-icon-button not-clickable" matTooltipClass="ff-tooltip"
              #tooltip="matTooltip" matTooltip="{{ additionalVariables.descriptionLabel | translate }}"
              matTooltipPosition="right">
              <ff-icon icon="info"></ff-icon>
            </button>
          </ng-container>

          <ng-container *ngIf="additionalVariables?.descriptionType == 'dialog'">
            <button mat-button class="md-primary circular-icon-button"
              (click)="openDescriptionDialog(additionalVariables?.descriptionDialogTitle, additionalVariables?.descriptionLabel)">
              <ff-icon icon="info"></ff-icon>
            </button>
          </ng-container>

        </div>

      </ng-container>

    </div>

    <!-- error -->
    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 6" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <!-- dashboard -->
    <ff-dashboard *ngIf="dashboardData != null && pageState.value >= 6" (clicked)="tableAction($event)"
      (inputTag)="tableInputTagChange($event)" [completeDashboardConfig]="completeDashboardConfig">
    </ff-dashboard>

    <!-- aggr dashboard -->
    <div fxFlex fxLayout="column" fxLayoutAlign="start start"
      *ngIf="pageState.value >= 6 && aggrDropdown != null && dashboardConfig?.aggrWidget != null" fxLayout="row"
      class="w100" style="margin-top: 8px;">

      <ff-widget fxFlex="100" [widget]="dashboardConfig.aggrWidget">
      </ff-widget>

    </div>

  </div>

</div>