import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Subscription, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { EditNotesDialogComponent } from './edit-notes-dialog/edit-notes-dialog.component';

@Component({
  selector: 'app-past-videos',
  templateUrl: './past-videos.component.html',
  styleUrls: ['./past-videos.component.scss']
})
export class PastVideosComponent implements OnInit, OnDestroy {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public isMobile: any;

  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public excludeLine: boolean = false;

  public interval: any;
  public intervalConfig: any;

  public sectionName: any = 'videoStreaming';
  public edgeVideoStreaming: any = false;

  public filterButtons: any;
  public additionalFilterButtons: any;

  public tableConfig: any = {
    isNestedTable: true,
    nestedTableConfig: {
      variable: "webcams",
      additionalButtons: [
        {
          clickFunction: "editVideoNotes",
          icon: "edit",
          tooltip: "PAST_VIDEOS.EDIT_VIDEO_NOTES",
          class: "rounded-button",
          removeInEdge: true,
          tdClass: "w50px"
        },
        {
          clickFunction: "videoDetail",
          icon: "featured_video",
          tooltip: "PAST_VIDEOS.NAVIGATE_TO_WEBCAM_DETAIL",
          class: "rounded-button",
          tdClass: "w50px"
        },
        {
          clickFunction: "download",
          icon: "download_file",
          tooltip: "PAST_VIDEOS.DOWNLOAD_VIDEO",
          class: "rounded-button",
          removeInCloud: true,
          tdClass: "w50px"
        },
        // {
        //   clickFunction: "sendVideosToCloud",
        //   icon: "cloud_upload",
        //   tooltip: "PAST_VIDEOS.SEND_TO_CLOUD",
        //   class: "rounded-button",
        //   removeInCloud: true,
        //   tdClass: "w50px"
        // },
        {
          clickFunction: "deleteVideo",
          icon: "delete",
          tooltip: "PAST_VIDEOS.DELETE_VIDEO",
          class: "md-red rounded-button",
          tdClass: "w50px"
        },
      ]
    },
    tableInfos: [],
    filterVariables: [],
    rows: [],
    completeCardFrame: true,    // boolean true = card around table
    cardFrame: true,            // boolean true = card around table
    // filters: {
    //   searchInput: true,        // boolean true = input search in component
    // },
    paginator: {
      options: [10, 25, 50, 100],   // options of rows for page
      size: 10,                 // number of rows for page
      position: 'bottom right'  // position of paginator row
    },
    sort: {
      variable: 'timeStart',    // variable to sort
      order: 'desc'             // order to sort
    },
    additionalButtons: [
      {
        clickFunction: "editSessionNotes",
        icon: "edit",
        tooltip: "PAST_VIDEOS.EDIT_VIDEO_NOTES",
        class: "rounded-button",
        removeInEdge: true,
        tdClass: "w50px"
      },
      {
        clickFunction: "sessionDetail",
        icon: "open_in_new",
        tooltip: "PAST_VIDEOS.NAVIGATE_TO_SESSION_DETAIL",
        class: "rounded-button",
        tdClass: "w50px"
      },
      {
        clickFunction: "download",
        icon: "download_file",
        tooltip: "PAST_VIDEOS.DOWNLOAD_VIDEOS",
        class: "rounded-button",
        removeInCloud: true,
        tdClass: "w50px"
      },
      // {
      //   clickFunction: "sendVideosToCloud",
      //   icon: "cloud_upload",
      //   tooltip: "PAST_VIDEOS.SEND_TO_CLOUD",
      //   class: "rounded-button",
      //   removeInCloud: true,
      //   tdClass: "w50px"
      // },
      {
        clickFunction: "deleteSession",
        icon: "delete_forever",
        tooltip: "PAST_VIDEOS.DELETE_SESSION",
        class: "md-red rounded-button",
        tdClass: "w50px"
      },
    ]
  };


  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getPastVideosPolling, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {

    this.isMobile = window.innerWidth <= 959;
    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.edgeVideoStreaming = this.appInfo?.edgeVideoStreaming;

    this.breadcrumb = ['VIDEO_STREAMING.TITLE', 'PAST_VIDEOS.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    try {
      this.tableConfig.tableInfos = this.clonerService.deepClone(this.appConfig[this.sectionName].tableInfos);
    } catch (error) {
      console.log("No table config in appConfig: " + this.sectionName + '.tableInfos');
      console.log(error);
    }

    try { this.additionalFilterButtons = this.clonerService.deepClone(this.appConfig[this.sectionName].additionalFilterButtons) } catch (error) { }

    try {
      this.tableConfig.filterVariables = this.clonerService.deepClone(this.appConfig[this.sectionName].tableInfos.filter(x => x.filtrable).map(x => x.variable));
    } catch (error) { console.log(error) }

    // Remove buttons according to the platform

    // EDGE
    if (this.edgeVideoStreaming) {
      try {
        this.tableConfig.nestedTableConfig.additionalButtons = this.tableConfig.nestedTableConfig.additionalButtons.filter(x => !x.removeInEdge);
        this.tableConfig.additionalButtons = this.tableConfig.additionalButtons.filter(x => !x.removeInEdge);
      } catch (error) {
        console.log(error);
      }
    }

    // CLOUD
    else {
      try {
        this.tableConfig.nestedTableConfig.additionalButtons = this.tableConfig.nestedTableConfig.additionalButtons.filter(x => !x.removeInCloud);
        this.tableConfig.additionalButtons = this.tableConfig.additionalButtons.filter(x => !x.removeInCloud);
      } catch (error) {
        console.log(error);
      }
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO
  getAssetInfo(_this: any) {

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, null, this.getPastVideosPolling, this.getPastVideos, this.machine.timezone, 4, 5) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET HEALTH MONITORING POLLING
  getPastVideosPolling(_this: any) {

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {

      if (_this.cacheService.get("intervalLong") == null) {

        _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.cacheService.set("intervalLong", _this.intervalConfig);

      } else {
        _this.intervalConfig = _this.cacheService.get("intervalLong");
        _this.interval = _this.cacheService.get("intervalLong").selected;
      }

      _this.getPastVideos(_this);

    } catch (error) {
      let errorData = {
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getPastVideos(_this: any) {

    try {

      let url = '/apif/video-streaming/video-history/' + _this.machineId;
      // let url = '/apif/video-streaming/video-history/test';

      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone,
        minDuration: _this.appConfig?.videoStreaming?.minDuration
      };

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      _this.apiService.sendGetRequest(url, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.tableConfig.rows = _this.clonerService.deepClone(_this.parseData(data.body));
            _this.tableConfig = _this.clonerService.deepClone(_this.tableConfig);

            _this.filterButtons = _this.filterService.buildFilterButtons(_this, _this.additionalFilterButtons, _this.tableConfig.rows);
            // _this.filterData();

            _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        origin: "Front End",
        module: _this.sectionName,
        function: "getPastVideos",
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // PARSE DATA
  parseData(data: any) {
    let newData: any = [];
    if (data != null && Array.isArray(data) && data.length > 0) {

      // Parse event type
      newData = data.reduce((acc, val) => {
        let eventTypeConfig = this.machine.profile?.videoEventTypes?.find(x => x.id == val.eventId);
        val.eventTypeP = {
          value: this.translate.instant(eventTypeConfig?.label ?? val.eventId ?? "-"),
          fontColor: eventTypeConfig?.fontColor,
          bgColor: eventTypeConfig?.bgColor,
        };
        val.duration = moment(val.timeEnd).diff(moment(val.timeStart), 's');
        acc.push(val);
        return acc;
      }, []);

      // Create nested webcams item
      newData = newData.reduce((acc, val) => {
        let sessionIdx = acc.findIndex(x => x.session == val.session);
        // let sessionIdx = acc.findIndex(x => x.session.startsWith("0_1635866"));
        let obj = val;
        obj.disabledButtons = {
          sendVideosToCloud: val.sentToCloud
        };
        if (sessionIdx != -1) {
          try { acc[sessionIdx].webcams.push(this.clonerService.deepClone(val)) }
          catch (error) { acc[sessionIdx].webcams = [this.clonerService.deepClone(val)] }
        } else {
          acc.push(Object.assign(val, { webcams: [this.clonerService.deepClone(val)] }));
        }
        return acc;
      }, []);

      // Remove nested items if there is only 1 or 0 webcams
      newData = newData.reduce((acc, val) => {

        if (val.webcams == null || val.webcams.length <= 1) delete val.webcams;
        if (val.hasOwnProperty('webcams')) {

          // Calculate session end
          val.timeEnd = val.webcams.reduce((timeEnd, webcam) => {
            let timestamp: any = webcam?.timeEnd;
            if (moment(timestamp).diff(moment(timeEnd)) > 0) timeEnd = timestamp;
            return timeEnd;
          }, "1970-01-01T00:00:00.000");

          // Calculate session start
          val.timeStart = val.webcams.reduce((timeStart, webcam) => {
            let timestamp: any = webcam?.timeStart;
            if (moment(timestamp).diff(moment(timeStart)) < 0) timeStart = timestamp;
            return timeStart;
          }, "2100-01-01T00:00:00.000");

          // Calculate session duration
          val.duration = moment(val.timeEnd).diff(moment(val.timeStart), 's');

          // Join webcam notes with comma          
          val.notes = val.webcams?.filter(x => x.notes != null)?.map(x => x.notes).filter(this.filterService.onlyUnique).join(", ");

          delete val.webcam;
        }
        acc.push(val);
        return acc;
      }, []);

    }
    return newData;
  }

  // FILTER DATA
  filterData() {

    let filtered: any = this.clonerService.deepClone(this.tableConfig.rows);

    if (this.filterButtons?.length > 0) {
      try { this.filterButtons.forEach((filterButton: any) => filtered = filtered.filter((data: any) => filterButton.options.filter((opt: any) => opt.selected).some((opt: any) => String(data[filterButton.variable]) == (String(opt.id))))) }
      catch (error) { console.log(error) }
    }

    this.tableConfig.rowsFiltered = this.clonerService.deepClone(filtered);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // ON FILTERS DIALOG SELECT

  onFiltersDialogSelect() { this.filterData(); }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CLICK BUTTON
  onButtonClick(button) {
    if (button != null && button.buttonInfos != null && button.buttonInfos.clickFunction != null) {
      switch (button.buttonInfos.clickFunction) {
        case "deleteSession":
          this.deleteSessionConfirm(button.row);
          break;
        case "deleteVideo":
          this.deleteVideoConfirm(button.row);
          break;
        case "sessionDetail":
          this.navigateToSessionDetail(button.row);
          break;
        case "videoDetail":
          this.navigateToVideoDetail(button.row);
          break;
        case "editSessionNotes":
          this.editVideoNotes(button.row);
          break;
        case "editVideoNotes":
          this.editVideoNotes(button.row);
          break;
        case "sendVideosToCloud":
          this.sendVideosToCloudConfirm(button.row);
          break;
        case "download":

          if (button?.row?.webcams?.length > 0) {
            button?.row?.webcams?.forEach(x => {
              let link = document.createElement('a');
              link.href = x?.completeName;
              link.download = x?.name;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
          } else {

            let link = document.createElement('a');
            link.href = button?.row?.completeName;
            link.download = button?.row?.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }

          break;
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // NAVIGATION TO DETAIL

  // VIDEO DETAIL
  navigateToVideoDetail(session: any) {
    let url: any = [this.machineId, 'video-streaming', 'session-detail'].map((x: any) => "/" + x).join("")
      + "?videoIds=" + [session.name] + "&timeStart=" + session.timeStart;

    if (session?.type != null) url += '&type=' + session.type;

    this.router.navigateByUrl(url);
  }

  // SESSION DETAIL
  navigateToSessionDetail(session: any) {
    let url: any = [this.machineId, 'video-streaming', 'session-detail'].map((x: any) => "/" + x).join("")
      + "?videoIds=" + (session.webcams?.map(x => x.name) ?? [session.name]) + "&timeStart=" + session.timeStart;

    if (session?.type != null) url += '&type=' + session.type;

    this.router.navigateByUrl(url);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DELETE VIDEOS

  // DELETE SINGLE VIDEO
  deleteVideoConfirm(video: any) {

    let confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant('PAST_VIDEOS.CONFIRM_DELETE_VIDEO', {
          videoId: video.webcam,
          sessionId: video.session,
        }),
      }
    });

    confirmationDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') this.deleteListOfVideos([video.videoId ?? video.name], video?.type);
    });
  }

  // DELETE SESSION
  deleteSessionConfirm(session: any) {

    let confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant('PAST_VIDEOS.CONFIRM_DELETE_SESSION', {
          sessionId: session.session
        }),
      }
    });

    confirmationDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') this.deleteListOfVideos(session.webcams != null && session.webcams.length > 0 ? session.webcams.map(x => x.videoId ?? x.name) : [session.videoId ?? session.name], session?.type);
    });
  }

  // DELETE LIST OF VIDEOS
  deleteListOfVideos(list: any, videoType?) {

    if (list != null && list.length > 0) {

      this.pageState.next(4);
      let url = '/apif/video-streaming/delete-videos';

      let payload = this.clonerService.deepClone(list);

      let query: any = {
        timezone: this.machine.timezone
      };

      if (videoType) query.videoType = videoType;

      console.log(payload);

      let _this = this;

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {
            console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);
            let errorData = {
              type: 0,
              status: error.status,
              message: error.statusText
            };

            _this.snackBar.open(_this.translate.instant('PAST_VIDEOS.VIDEOS_DELETED_FAILED'), '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              panelClass: ['snackbar', 'fail'],
              duration: 4000
            });

            _this.pageState.next(5);

            return throwError(errorData.status + ': ' + errorData.message);
          })
        )
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.snackBar.open(_this.translate.instant('PAST_VIDEOS.VIDEOS_DELETED_SUCCESSFULLY'), '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              panelClass: ['snackbar', 'success'],
              duration: 4000
            });

            _this.pageState.next(4);
            _this.getPastVideosPolling(_this);

          }
        );

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // EDIT VIDEOS
  editVideoNotes(session: any) {

    let confirmationDialog = this.dialog.open(EditNotesDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant('PAST_VIDEOS.EDIT_VIDEO_NOTES'),
        value: session.notes ?? ''
      }
    });

    confirmationDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') this.editNotes(session.webcams?.map(x => x.videoId ?? x.name) ?? [session.videoId ?? session.name], result);
    });
  }

  // EDIT LIST OF VIDEOS
  editNotes(list: any, text: any) {

    if (list != null && list.length > 0) {

      this.pageState.next(4);
      let url = '/apif/video-streaming/edit-notes/' + this.machineId;
      // let url = '/apif/video-streaming/edit-notes/test';

      let payload = {
        list: this.clonerService.deepClone(list),
        text: text
      };

      let query = {
        timezone: this.machine.timezone
      };

      console.log(payload);

      let _this = this;

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {
            console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);
            let errorData = {
              type: 0,
              status: error.status,
              message: error.statusText
            };

            _this.snackBar.open(_this.translate.instant('PAST_VIDEOS.EDIT_NOTES_FAILED'), '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              panelClass: ['snackbar', 'fail'],
              duration: 4000
            });

            _this.pageState.next(5);

            return throwError(errorData.status + ': ' + errorData.message);
          })
        )
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.snackBar.open(_this.translate.instant('PAST_VIDEOS.EDIT_NOTES_SUCCESS'), '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              panelClass: ['snackbar', 'success'],
              duration: 4000
            });

            _this.pageState.next(4);
            _this.getPastVideosPolling(_this);

          }
        );

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // SEND VIDEOS TO CLOUD

  // SEND SESSION
  sendVideosToCloudConfirm(session: any) {

    let confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant('PAST_VIDEOS.CONFIRM_SEND_TO_CLOUD_SESSION', {
          sessionId: session.session
        }),
        description: this.translate.instant('PAST_VIDEOS.WARNING_SEND_TO_CLOUD')
      }
    });

    confirmationDialog.afterClosed().subscribe((result: any) => {
      console.log(session);

      if (result != null && result != '') this.sendListOfVideosToCloud(session.webcams != null && session.webcams.length > 0 ? session.webcams.map(x => x.videoId ?? x.name) : [session.videoId ?? session.name], session?.type);
    });
  }

  // DELETE LIST OF VIDEOS
  sendListOfVideosToCloud(list: any, videoType?) {

    if (list != null && list.length > 0) {

      this.pageState.next(4);
      let url = '/apif/video-streaming/send-to-cloud/' + this.machineId;

      let payload = this.clonerService.deepClone(list);

      let query: any = {
        timezone: this.machine.timezone
      };

      if (videoType) query.videoType = videoType;

      console.log(payload);

      let _this = this;

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {
            console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);
            let errorData = {
              type: 0,
              status: error.status,
              message: error.statusText
            };

            _this.snackBar.open(_this.translate.instant('GLOBAL.FAILED_REQUEST'), '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              panelClass: ['snackbar', 'fail'],
              duration: 4000
            });

            _this.pageState.next(5);

            return throwError(errorData.status + ': ' + errorData.message);
          })
        )
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.snackBar.open(_this.translate.instant('GLOBAL.SUCCESSFUL_REQUEST'), '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              panelClass: ['snackbar', 'success'],
              duration: 4000
            });

            _this.pageState.next(4);
            _this.getPastVideosPolling(_this);

          }
        );

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);
  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pageState.unsubscribe();
    } catch (error) { }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) { }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) { this.isMobile = window.innerWidth <= 959 }

}