import { Component, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';
import { CycleSelectionDialogNewComponent } from '../../machine-recorder/cycle-selection-dialog-new/cycle-selection-dialog-new.component';
import { CycleSelectionDialogComponent } from '../../machine-recorder/cycle-selection-dialog/cycle-selection-dialog.component';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';


@Component({
  selector: 'app-health-trend',
  templateUrl: './health-trend.component.html',
  styleUrls: ['./health-trend.component.scss']
})
export class HealthTrendComponent implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public backButton: any;
  public tabs: any;

  public HMComponentSelectedSub: Subscription;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public pollingTime: any;
  public pollingEvents: any;

  public dashboardConfig: any;
  public dashboardType: any = 1;

  public defaultInterval: any;
  public interval: any;
  public intervalConfig: any;

  public componentConfig: any = {};
  public componentsConfig: any;
  public componentId: any;
  public machineRefId: any;

  public sectionName: any = "healthMonitoringDetail";
  public dashboardName: any = "health-trend";

  public dashboardData: any = {};

  public barColors: any;

  public showResetInterval: boolean = false;
  public zoomedIntervalVariablesSub: Subscription;
  public clickCycleDetailSub: Subscription;

  public cachedIntervalId: any = "intervalHM";

  public componentTableInfos: any = [];

  public availableMachines: any;
  public aggregations: any;

  public componentsHealthConfig: any = [
    {
      id: 0,
      label: "HEALTH_MONITORING.NOT_ANOMALOUS",
      class: "md-green-i",
      icon: {
        type: "icon",
        icon: "check"
      }
    },
    {
      id: 1,
      label: "HEALTH_MONITORING.ANOMALOUS",
      class: "md-red-i",
      icon: {
        type: "icon",
        icon: "close"
      }
    },
    {
      id: 2,
      label: "HEALTH_MONITORING.NOT_EVALUATED",
      class: "md-gray-i",
      icon: {
        type: "icon",
        icon: "remove"
      }
    }
  ];

  public hidePareto: any = false;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getComponentsConfig, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getHealthTraceabilityPolling, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  public isMobile: any;
  public isSmThanTablet: any;
  public mobileListener: Subscription;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public router: Router,
    public mobile: MobileService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));
    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile;
      this.isSmThanTablet = value.isSmThanTablet;
    })

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['HEALTH_MONITORING.TITLE', 'HEALTH_TREND.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    try {
      this.componentTableInfos = this.appConfig.machineRecorder.cycleExploration.tableInfos;
    } catch (error) {
      console.log(error);
    }

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb[2] = value.machineName;
        this.breadcrumb = newBreadcrumb;
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.HMComponentSelectedSub = this.internalDataService.HMComponentSelected.subscribe(value => {
      if (value != null) {
        this.breadcrumb = this.internalDataService.getHMBreadcrumb(this, value);
        this.internalDataService.setBreadcrumb(this.clonerService.deepClone(this.breadcrumb));
      }
    });

    this.pollingTime = 0;
    this.pollingEvents = Subscription;

    this.barColors = this.internalDataService.defaultPlotlyColors;

    this.zoomedIntervalVariablesSub = this.internalDataService.zoomedIntervalVariables.subscribe(value => {
      if (value != null) {

        this.showResetInterval = false;

        this.pageState.next(6);
        this.getHealthTraceabilityPolling(this, value);

      }
    });

    this.clickCycleDetailSub = this.internalDataService.clickCycleDetail.subscribe(value => {
      if (value != null) {
        console.log(value);
        this.openCycleSelection(value);

      }
    });
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
    } catch (error) {
      console.log(error);
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET COMPONENTS CONFIG

  getComponentsConfig(_this: any) {

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getComponentsConfig(_this, _this.machineId, _this.machineRefId, _this.componentId, true);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getHealthTraceabilityPolling, this.getHealthTraceability, this.machine.timezone, 6, 7) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {

    let _func: any = this;
    let funcName: any = "Unknown";
    try { funcName = _func.function.name } catch (error) { }

    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        origin: "Front End",
        module: _this.sectionName,
        function: funcName,
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SYNOPTIC CONFIG

  // polling
  getHealthTraceabilityPolling(_this: any, range: any = null) {
    try {

      if (range == null) {
        if (_this.cacheService.get(_this.cachedIntervalId) == null) {

          _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

          _this.intervalConfig = {
            list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
            selected: _this.interval
          };

          _this.cacheService.set(_this.cachedIntervalId, _this.clonerService.deepClone(_this.intervalConfig));

        } else {
          _this.intervalConfig = _this.clonerService.deepClone(_this.cacheService.get(_this.cachedIntervalId));
          _this.interval = _this.intervalConfig.selected;
        }
      }

      if (_this.defaultInterval == null) _this.defaultInterval = _this.clonerService.deepClone(_this.interval);

      if (_this.pollingTime > 0) _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => _this.getHealthTraceability(_this, count, range));
      else _this.getHealthTraceability(_this, 0, range);

    } catch (error) {
      console.log(error);
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getHealthTraceability(_this: any, count?: any, range?: any) {
    try {

      let url = 'apif/condition-monitoring/analysis/' + _this.machineId + '/' + _this.componentId;


      if (range != null) {
        if (range.hasOwnProperty('from') && range.from != null && range.hasOwnProperty('to') && range.to != null) {
          console.log('range', range);

          _this.showResetInterval = true;
          _this.interval.start = range.from;
          _this.interval.end = range.to;
          _this.intervalService.formatTimezone(_this.interval, _this.machine.timezone);

          // _this.interval.startDate = moment.tz(range.from, _this.machine.timezone).toDate(); //.add(100, 'ms').toDate();
          // _this.interval.endDate = moment.tz(range.to, _this.machine.timezone).toDate(); //.add(100, 'ms').toDate();
          // _this.interval.startP = moment.tz(range.from, _this.machine.timezone).format('MMM DD, YYYY - HH:mm:ss')
          // _this.interval.endP = moment.tz(range.to, _this.machine.timezone).format('MMM DD, YYYY - HH:mm:ss')
          // _this.interval.start = JSON.parse(JSON.stringify(_this.interval.startDate));

          // let lastEnd = _this.interval.end;
          // _this.interval.end = JSON.parse(JSON.stringify(_this.interval.endDate));

          // if (lastEnd != _this.interval.end) _this.interval.enabledPolling = false;

        } else {
          _this.showResetInterval = false;
          _this.interval = _this.clonerService.deepClone(_this.defaultInterval);
        }
      }

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone,
      };

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      _this.internalDataService.buildAggregationsPayload(_this);
      // payload.filters = _this.aggregationsPayload;

      let payload = {
        filters: _this.aggregationsPayload
      }

      _this.apiService.sendPostRequest(url, payload, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.dashboardData = Object.assign(_this.clonerService.deepClone(_this.componentConfig), _this.parseData(data.body));

            _this.dashboardData.dataConfig = {};

            _this.dashboardData.plotData = _this.buildPlotConfig(_this, _this.dashboardData);

            if (!_this.hidePareto) {
              try {
                _this.dashboardType = _this.dashboardData.classes.filter(x => x.id != 'ukw').length > 0 ? 2 : 1;
              } catch (error) { _this.dashboardType = 1 }

              if (_this.dashboardType == 2) _this.dashboardData.anomaliesClassPlotData = _this.buildAnomaliesClassPlotConfig(_this, _this.dashboardData);
            }

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);

          }
        );

    } catch (error) {
      console.log(error);
    }

  }

  parseData(data: any) {

    // COMPONENTS HEALTH CONFIG
    let componentsHealthConfig = this.componentsHealthConfig;

    if (this.machine.profile.hasOwnProperty('componentsHealth') &&
      this.machine.profile.componentsHealth != null &&
      Array.isArray(this.machine.profile.componentsHealth) &&
      this.machine.profile.componentsHealth.length > 0) {
      componentsHealthConfig = this.machine.profile.componentsHealth;
    }

    data.componentsHealthConfig = this.clonerService.deepClone(componentsHealthConfig);

    return data;

  }

  // BUILD PLOT
  buildPlotConfig(_this: any, data: any) {
    try {

      // check presence of mandatory variables 
      if (!data.hasOwnProperty('timeserie') || data.timeserie == null || !Array.isArray(data.timeserie) || data.timeserie.length == 0 ||
        data.timeserie.every(x => x.timestamp == null) ||
        !data.hasOwnProperty('bins') || data.bins == null || !Array.isArray(data.bins) || data.bins.length == 0 ||
        data.bins.every(x => x.timestamp == null)) {
        return {
          layout: {},
          traces: []
        };
      }

      let traces: any = [];

      traces.push({
        x: data.timeserie.map(x => x.timestamp),
        y: data.timeserie.map(x => x.value),
        name: _this.translate.instant('CONDITION_ANALYSIS.HEALTH'),
        type: 'scatter',
        mode: 'lines+markers',
      });
      if (data.hasOwnProperty('threshold') && data.threshold != null) {
        traces.push({
          x: data.timeserie.map(x => x.timestamp),
          y: data.timeserie.map(x => data.threshold),
          name: _this.translate.instant('CONDITION_ANALYSIS.THRESHOLD'),
          type: 'scatter',
          mode: 'lines+markers',
          line: {
            color: "#FF5757",
            dash: "dash"
          }
        });
      }
      traces.push({
        x: data.timeserie.map(x => x.timestamp),
        y: data.timeserie.map(x => x.cycleTime),
        yaxis: 'y3',
        visible: true,
        name: _this.translate.instant('CONDITION_ANALYSIS.CYCLE_TIME'),
        type: 'scatter',
        mode: 'lines+markers',
      });
      // uniqueClasses = data.uniqueClasses;
      data.bins.forEach(function (bin) {
        if (bin.hasOwnProperty('classes') && bin.classes != null) {
          bin.classes.forEach(cl => {
            // if (uniqueClasses.length == 0 || (uniqueClasses.findIndex(x => x.id == cl.id) == -1)) {
            //     uniqueClasses.push(cl);
            // }
            bin[cl.id] = cl.value;
          });
        }
      });
      let uniqueClasses: any = (data.uniqueClasses != null && data.uniqueClasses.length > 0) ? data.uniqueClasses.sort((a, b) => {
        if (a.id < b.id) {
          return 1;
        }
        if (a.id > b.id) {
          return -1;
        }
        return 0;
      }) : [];

      // console.log(uniqueClasses);
      var hoverTextInfo = data.bins.map(function (bin) {
        var hover = '';
        if (bin.timeStart != null) hover += '<b>Start </b>: ' + _this.filterService.parseMoment(bin.timeStart.substring(0, bin.timeStart.length - 1), 'MMM DD, YYYY - HH:mm:ss') + '<br>';
        if (bin.timeEnd != null) hover += '<b>End </b>: ' + _this.filterService.parseMoment(bin.timeEnd.substring(0, bin.timeEnd.length - 1), 'MMM DD, YYYY - HH:mm:ss') + '<br>';
        if (bin.nonAnomalousData != null) hover += '<b>' + _this.translate.instant("CONDITION_ANALYSIS.NON_ANOMALOUS_CYCLES") + '</b>: ' + bin.nonAnomalousData + '<br>';
        if (bin.trainingData != null) hover += '<b>' + _this.translate.instant("HEALTH_MONITORING.TRAINING_CYCLES") + '</b>: ' + bin.trainingData + '<br>';
        Object.entries(bin).forEach((obj: any) => {

          let key = obj[0];
          let val = obj[1];

          if (key != "timestamp" && key != "timeStart" && key != "timeEnd" && key != 'anomalousData' && key != 'nonAnomalousData' && key != 'classes' && key != 'trainingData') {
            var kx = uniqueClasses.findIndex(x => x.id == key);
            hover += '<b>' + (kx != -1 ? (uniqueClasses[kx].name != null ? uniqueClasses[kx].name : uniqueClasses[kx].id) : 'Not found') + '</b>: ' +
              val + '<br>';
          }
        });
        return hover;
      });
      // console.log(hoverTextInfo);
      var timeRange = null;
      try {
        timeRange = data.bins.map(bin => [bin.timeStart, bin.timeEnd])
      } catch (error) {
        timeRange = data.bins.map(bin => bin);
      }
      traces.push({
        x: data.bins.map(x => x.timestamp),
        y: data.bins.map(x => x.nonAnomalousData),
        offsetgroup: 0,
        base: 0,
        xaxis: 'x',
        yaxis: 'y2',
        timeRange: timeRange,
        name: _this.translate.instant('CONDITION_ANALYSIS.NON_ANOMALOUS_CYCLES'),
        type: "bar",
        marker: {
          color: 'rgb(98, 227, 157)'
        },
        text: hoverTextInfo,
        hoverinfo: "text",
      });

      traces.push({
        x: data.bins.map(x => x.timestamp),
        y: data.bins.map(x => x.trainingData),
        offsetgroup: 0,
        xaxis: 'x',
        yaxis: 'y2',
        timeRange: timeRange,
        name: _this.translate.instant('HEALTH_MONITORING.TRAINING_CYCLES'),
        type: "bar",
        marker: {
          color: 'rgb(109, 174, 219)'
        },
        hoverinfo: "skip",
      });

      uniqueClasses.forEach(function (classId, idx) {
        traces.push({
          x: data.bins.map(x => x.timestamp),
          y: data.bins.map(x => x[classId.id] != null ? x[classId.id] : null),
          base: idx > 0 ? traces[parseInt(traces.length) - 1].y.map(function (num, idx) {
            return num + traces[parseInt(traces.length) - 1].base[idx];
          }) : traces[parseInt(traces.length) - 1].y.map(x => 0),
          offsetgroup: 1,
          legendgroup: 1,
          showlegend: idx > 0 ? false : true,
          xaxis: 'x',
          yaxis: 'y2',
          marker: {
            color: classId.color != null ? classId.color : _this.barColors[idx],
          },
          name: _this.translate.instant('CONDITION_ANALYSIS.ANOMALOUS_CYCLES'),
          hoverinfo: "skip",
          type: "bar"
        });
      });

      let plotLayout = {
        // uirevision: true,
        margin: {
          t: 30,
          r: 110,
          b: 50,
          l: 100,
          pad: 15
        },
        xaxis: {
          zeroline: false,
          showgrid: false,
          position: 0

        },
        yaxis: {
          fixedrange: true,
          zeroline: false,
          showgrid: false,
          domain: [0.5, 1],
          title: {
            text: _this.translate.instant('CONDITION_ANALYSIS.HEALTH')
          },
          tickformat: "%",
          range: [-0.05, 1.05]
        },
        yaxis2: {
          fixedrange: true,
          zeroline: false,
          showgrid: false,
          title: {
            text: _this.translate.instant('CONDITION_ANALYSIS.CYCLE_COUNT')
          },
          domain: [0, 0.45]
        },
        yaxis3: {
          fixedrange: true,
          zeroline: false,
          showgrid: false,
          overlaying: 'y',
          side: 'right',
          ticksuffix: " s",
          title: {
            text: _this.translate.instant('CONDITION_ANALYSIS.CYCLE_TIME')
          },
          domain: [0.5, 1]
        },
        shapes: [{
          type: 'line',
          xref: 'paper',
          yref: 'paper',
          x0: 0,
          x1: 1,
          y0: 0.5,
          y1: 0.5
        }],
        legend: {
          orientation: 'h',
          x: 0,
          y: -0.15
        }
      };

      return {
        layout: plotLayout,
        traces: traces,
        params: {
          onClickCycleDetail: true,
          relayoutVariables: true,
          // displayModeBar: false,
        }
      };
    }
    catch (e) {
      console.log(e);
      return {
        layout: {},
        traces: []
      };
    }

  }

  // BUILD PLOT
  buildAnomaliesClassPlotConfig(_this: any, data: any) {
    try {

      // check presence of mandatory variables 
      if (!data.hasOwnProperty('classes') || data.classes == null || !Array.isArray(data.classes) || data.classes.length == 0) {
        return {
          layout: {},
          traces: []
        };
      }

      let traces = [];

      data.classes.sort(_this.filterService.sortByProperty('value', 'desc', true));
      traces.push({
        y: data.classes.map(x => (x.name != null ? x.name : x.id)),
        x: data.classes.map(x => x.value),
        name: _this.translate.instant('CONDITION_ANALYSIS.ANOMALIES'),
        type: 'bar',
        traceType: "anomalyBar",
        orientation: 'h',
        marker: {
          color: data.classes.map((x, idx) => (x.color != null ? x.color : _this.barColors[idx])),
        },
      });

      // traces.push({
      //   x: data.classes.map(x => (x.name != null ? x.name : x.id)),
      //   y: data.classes.map(x => x.cumPerc),
      //   name: _this.translate.instant("BREAKDOWNS.CUMULATIVE_PERCENTAGE"),
      //   hovertext: data.classes.map(x => {
      //     return x.name + '<br>' +
      //       '<b>' + _this.translate.instant("CONDITION_ANALYSIS.ANOMALIES") + ':</b> ' + _this.filterService.parseGaugeValue(x.value, 0, 1) + '<br>' +
      //       '<b>' + _this.translate.instant("BREAKDOWNS.CLASS_PERCENTAGE") + ':</b> ' + _this.filterService.parseGaugeValue(x.percentage, 1, 100) + '%<br>' +
      //       '<b>' + _this.translate.instant("BREAKDOWNS.CUMULATIVE_PERCENTAGE") + ':</b> ' + _this.filterService.parseGaugeValue(x.cumPerc, 1, 100) + '%'
      //   }),
      //   type: 'line',
      //   yaxis: 'y2',
      //   hoverinfo: "x+text",
      //   line: {
      //     color: 'black'
      //   }
      // });

      let plotLayout = {
        showlegend: false,
        margin: {
          t: 30,
          r: 30,
          b: 30,
          l: 30,
          pad: 15
        },
        xaxis: {
          automargin: true,
          zeroline: false,
          showgrid: false,
          title: {
            text: _this.translate.instant('CONDITION_ANALYSIS.ANOMALIES')
          },
        },
        yaxis: {
          automargin: true,
          zeroline: false,
          showgrid: false,
          type: 'category',
        },
        // yaxis2: {
        //   zeroline: false,
        //   showgrid: false,
        //   overlaying: 'y',
        //   range: [-0.05, 1.05],
        //   tickformat: '%',
        //   side: 'right',
        //   title: {
        //     text: _this.translate.instant("BREAKDOWNS.CUMULATIVE_PERCENTAGE")
        //   },
        // },
      };

      return {
        layout: plotLayout,
        traces: traces,
        params: {
          displayModeBar: false,
        }
      };
    }
    catch (e) {
      console.log(e);
      return {
        layout: {},
        traces: []
      };
    }

  }

  openCycleSelection(row?: any) {

    let title = 'CYCLE_EXPLORATION.ACTUAL_CYCLE_SELECTION';

    let data = {
      title: this.translate.instant(title),
      // TODO: isEnabledAD: true
      isEnabledAD: true,
      machine: this.machine,
      machineId: this.machineId,
      componentId: this.componentId,
      tableInfos: this.componentTableInfos,
      availableMachines: this.availableMachines,
      aggregations: this.aggregations,
      isDaySelectable: false,
      customInterval: {
        start: row.from,
        end: row.to,
      }
    };

    const selectionDialog: any = this.appConfig?.newCycleSelectionDialog ? CycleSelectionDialogNewComponent : CycleSelectionDialogComponent;
    const cycleSelectionDialog = this.dialog.open(selectionDialog,
      {
        panelClass: 'ff-dialog',
        width: '90%',
        height: '90%',
        data: data,
      });

    cycleSelectionDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {

          let a = moment();
          let b = moment().subtract(365, 'days');

          let days: any = [];
          for (let m = moment(a); b.diff(m, 'days') < 0; m.subtract(1, 'days')) {
            days.push(moment(m).format("YYYY-MM-DD"));
          }

          this.cacheService.set("selectedCycle", result.cycle);
          this.cacheService.set("cycleDays", {
            list: days,
            selected: result.cycle.day,
          });

          let url: any = [this.machineId, 'health-monitoring', 'cycle-exploration'];
          this.router.navigate(url, { queryParams: { machineRefId: this.machineRefId, componentId: this.componentId } })

        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  resetDefaultInterval() {
    this.interval = this.clonerService.deepClone(this.defaultInterval);
    this.showResetInterval = false;
    this.pageState.next(6);
    this.getHealthTraceability(this, 0);
  }

  openAggrDialog(aggr: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval)),
        measurement: "anomalyDetectionAggr",
        componentId: this.componentId
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        // console.log('afterClosed', result);
        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
        this.pageState.next(5);

        // if (this.appConfig?.[this.sectionName] != null && this.appConfig[this.sectionName].hasOwnProperty("sessionFilters")) {
        //   let sessionFilters: any = this.clonerService.deepClone(this.aggregations)
        //   this.cacheService.set('sessionFilters', sessionFilters)
        // }

        this.getHealthTraceabilityPolling(this);
      }

    });
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    let queryParams = this.route.snapshot.queryParams;

    this.backButton = [this.machineId, "health-monitoring"];
    this.internalDataService.setBackButton(this.backButton);

    this.componentId = queryParams.componentId;
    this.machineRefId = queryParams.machineRefId;

    this.route.params.subscribe((params: Params) => {

      this.machineId = params['machineId'];

      try {
        let queryParams = this.route.snapshot.queryParams;
        this.componentId = queryParams.componentId;
        this.machineRefId = queryParams.machineRefId;
      } catch (error) { console.log("Wrong query params") }

      this.internalDataService.setHMComponentSelected({
        machineRefId: this.machineRefId,
        componentId: this.componentId,
      });

    });

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pollingEvents.unsubscribe() } catch (error) { }
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.HMComponentSelectedSub.unsubscribe() } catch (error) { }
    try { this.internalDataService.setBackButton([]) } catch (error) { }
    try {
      this.internalDataService.setClickCycleDetail(null);
      this.clickCycleDetailSub.unsubscribe();
    } catch (error) { }
    try {
      this.internalDataService.setZoomedIntervalVariables(null);
      this.zoomedIntervalVariablesSub.unsubscribe();
    } catch (error) { }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) { this.isMobile = window.innerWidth <= 959 }
}