import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'ff-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})

export class SidenavComponent implements OnInit, OnDestroy {

  sidenavItemsSub: Subscription;
  sidenavItems: any;

  logoFormat: any = 'png';

  machineSelectedSub: Subscription;

  assetsListSub: Subscription;
  assetsList: any = [];

  selectedItems: any = [];
  appInfo: any = null;
  appConfig: any = null;

  expanded: boolean;

  hide: boolean;

  sidenavHideSub: Subscription;
  mobileListener: Subscription;
  isMobile: any;
  isTablet: any;
  isSmThanTablet: any;

  constructor(
    public internalDataService: InternalDataService,
    public router: Router,
    public mobile: MobileService,
    public appConfigService: AppConfigService,
    public cacheService: CacheService,
    public clonerService: ClonerService,
  ) {

    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    this.appInfo = appConfigService.getAppInfo;
    this.appConfig = appConfigService.getAppConfig;

    this.mobileListener = this.mobile.mobileListener.subscribe((value) => {
      this.isMobile = value.isMobile
      this.isTablet = value.isTablet
      this.isSmThanTablet = value.isSmThanTablet
      if (value.isSmThanTablet) this.expanded = true;
    })

    // update sidenavItems
    this.internalDataService.updateSidenavItems();

    // init sidenav items subscription
    this.sidenavItemsSub = this.internalDataService.sidenavItems.subscribe(value => this.sidenavItems = value);

    // init machine selected subscription
    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => this.updateMachineSelected(value));

    // init assets list subscription
    this.assetsListSub = this.internalDataService.selectedAssetsList.subscribe(value => this.assetsList = value);

    // Logo format --> can be .png, .jpg, .svg, ecc...
    this.logoFormat = this.appConfig?.logoFormat ?? this.logoFormat;

    let localSidenav = localStorage.getItem('sidenav');
    if (localSidenav != null) this.expanded = localSidenav === 'true';
    else if (this.isSmThanTablet) this.expanded = true;
    else this.expanded = true;

    this.sidenavHideSub = this.internalDataService.hideSidenavs.subscribe(value => {
      // console.log(value);
      this.hide = value;

    });

  }

  isLinkActive(url: any, queryParamId?: any, inputUrlQueryParams?: any) {

    let isActive: any = false;
    let splittedUrl = url?.split("/")?.filter(x => x != '') ?? [];

    // Normal page
    if (queryParamId == null) isActive = this.router.url.split("/")?.some(x => splittedUrl.indexOf(x) != -1 || splittedUrl.indexOf("/" + x) != -1);

    // Detail page / Standard dashboard page
    else {
      let urlQueryParams = this.router.url.split("?")?.[1];
      let queryParams: any = urlQueryParams?.split("&")?.reduce((acc, x) => {
        let splittedQueryParam = x.split("=");
        let k: any = splittedQueryParam?.[0];
        let v: any = splittedQueryParam?.[1];
        if (k != null && v != null) acc[k] = v;
        return acc;
      }, {});

      isActive = queryParams?.[queryParamId] == inputUrlQueryParams?.[queryParamId];

    }

    return isActive;
  }

  closeSidenav() {
    let sidenavs = document.getElementsByTagName('ff-sidenav');
    if (sidenavs.length > 0) sidenavs[0].classList.add('closed');
  }

  openedSidenav() {
    let sidenavs = document.getElementsByTagName('ff-sidenav');
    if (sidenavs.length > 0) return sidenavs[0].classList.contains('closed');
    return false;
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
    localStorage.setItem('sidenav', this.expanded.toString());
  }

  updateMachineSelected(value: any) {
    if (value != null && !Array.isArray(value)) {

      let label = 'HOMEPAGE.MACHINE';
      let selectedItem = {
        label: label,
        value: value.machineName ?? value.name,
        data: value,
        // icon: {
        //   icon: "fas fa-check",
        //   type: "fontawesome"
        // }
      };
      let selectedIndex = this.selectedItems.findIndex((x: any) => x.label == label);
      if (selectedIndex == -1) this.selectedItems.push(selectedItem);
      else this.selectedItems[selectedIndex] = selectedItem;

    }
  }

  navigateToMachine(previous = false) {
    let assets: any = this.clonerService.deepClone(this.assetsList);
    if (!assets?.length) return;

    let machineId = this.cacheService.get("machineId");

    if (machineId == null) return;

    try {

      let newMachine = null;
      let idx = assets?.findIndex(x => x.machineId == machineId);

      if (idx > -1) {

        let newIdx = (idx == assets.length - 1) ? 0 : (idx + 1);
        if (previous) newIdx = idx == 0 ? assets.length - 1 : idx - 1;

        newMachine = assets[newIdx];
      }

      this.internalDataService.setMachineSelected(newMachine);
      this.router.navigateByUrl(this.router.url.replace(machineId, newMachine?.machineId));

    } catch (error) { console.log(error) }

  }

  // // // // // // // // // // // // 
  // START 
  // // // // // // // // // // // // 

  ngOnInit(): void { }

  ngOnDestroy(): void {
    try { this.sidenavItemsSub.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
  }
}

