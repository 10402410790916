import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'import-file-dialog',
  templateUrl: './import-file-dialog.component.html',
  styleUrls: ['./import-file-dialog.component.scss']
})
export class ImportFileDialogComponent implements OnInit {
  urlPath: any;

  public errorData: any = {
    type: 0,
    status: 500,
    message: "GLOBAL.EXCEL_ERROR"
  };

  public filePathRegex = ["..", "/"];

  public dialogState: any = 1;
  public fileCopy: any;
  public srcResult: any;
  public fileInput: any;
  public fileInfos: any;
  public files: any;
  public invalidValues: any;

  public filePath: any;
  public fileName: any;

  public isSupplier: any;

  public sendMailToCustomers: any = false;

  // Additional subscription
  public customSubscription: Subscription;

  constructor(
    public apiService: ApiService,
    public http: HttpClient,
    public configs: AppConfigService,
    public dialogRef: MatDialogRef<ImportFileDialogComponent>,
    public clonerService: ClonerService,
    public internalDataService: InternalDataService,
    public cacheService: CacheService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialog: any) {

    this.dialogState = 1;
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.filePath = this.dialog?.urlPath ?? './';
    this.invalidValues = this.dialog.invalidValues ?? [];
    this.isSupplier = this.internalDataService.getSpecificPermission("base-supplier");
  }


  onFileSelected() {

    this.dialogState = 0;

    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {

      let promises = [];

      for (let file of inputNode.files) {
        let filePromise = new Promise(resolve => {
          let reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = () => resolve({ arrayBuffer: reader.result, infos: file });
        });
        promises.push(filePromise);
      }

      Promise.all(promises).then((fileContents: any) => {
        // console.log({ fileContents });

        // let fileInfos: any = this.dialog.buttonInfos.filesTableConfig?.data?.selectedFiles ?? [];
        let fileInfos: any = [];

        for (let file of fileContents) {

          if (fileInfos.findIndex(x => x.name == file.infos.name) == -1) {

            let lastIndex = file.infos.name?.lastIndexOf('.');

            let infos = {
              name: file.infos.name?.slice(0, lastIndex),
              extension: file.infos.name?.slice(lastIndex + 1),
              type: file.infos.type,
              size: file.infos.size,
              file: file.arrayBuffer,
              sizeP: this.filterService.parseFileSize(file.infos.size),
            }
            fileInfos.push(infos);
          }
        }

        this.fileInfos = fileInfos;
        // console.log(fileInfos);

        // this.dialog.buttonInfos.filesTableConfig.data = { selectedFiles: [] };

        setTimeout(() => {

          // this.files = fileInfos.map(x => {
          //   return {
          //     name: x.name,
          //     size: x.size,
          //     file: x.arrayBuffer,
          //     extension: x.extension
          //   };
          // });

          this.dialogState = 1;

          // console.log(this.files);
        }, 250);


      });

    }

  }

  buildConfigPayload(files) {

    let payload = new FormData();

    for (let file of files) {
      if (file.file != null) payload.append(`${this.filePath}${file.name ?? 'unknown'}.${file.extension}`, new Blob([file.file]));
    }

    return payload
  }

  async uploadFile() {

    this.dialogState = 0;

    let url: any = '/apif/machine-documentation/upload-files/' + this.dialog.machineId;
    if (this.dialog?.buttonInfos?.endpointUrl) url = this.dialog.buttonInfos.endpointUrl.replaceAll("{machineId}", this.dialog.machineId);

    let query = {};

    if (this.sendMailToCustomers) {
      query = {
        sendMailToCustomers: true
      };
    }
    query = this.filterService.addStandardInformations(query);

    if (this.fileInfos?.length && this.fileInfos?.filter(file => file.file != null)?.length > 0) {
      let filesPayload = this.buildConfigPayload(this.fileInfos);

      let filesResp = null;

      try {
        filesResp = await this.apiService.sendCustomPayloadWithArrayBuffer(url, filesPayload, query);

        this.dialogState = 1;

        this.internalDataService.openSnackBar(
          this.translate.instant("FILE_EXPLORER.PDF_UPDATE_RESPONSE.SUCCESS"),
          'right',
          'bottom',
          4000,
          '',
          ['success']
        );
      } catch (error) {
        console.log(error);

        this.dialogState = 2;

        this.internalDataService.openSnackBar(
          this.translate.instant("FILE_EXPLORER.PDF_UPDATE_RESPONSE.FAIL"),
          'right',
          'bottom',
          4000,
          '',
          ['fail']
        );

      }

    }

    this.closeDialog();

    this.router.navigateByUrl(this.router.url);

  }

  getRegex(regex?: any, value?: any) {
    switch (regex) {
      case 'filePath':
        return this.filePathRegex?.every(x => !value?.includes(x));
      default:
        return null;
    }
  }

  checkConfirm() {
    let checkRequired = this.fileInfos?.reduce((acc, fileInfo) => {
      if (acc == true) return true;

      let checkName = fileInfo.name == null || fileInfo.name == '';
      if (checkName) return true;

      let checkRegex = !this.getRegex('filePath', fileInfo.name);
      if (checkRegex) return true;

      return false;
    }, false);

    return checkRequired;

  }

  closeDialog() {
    this.dialogRef.close();
  }

}