import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription, throwError, timer } from 'rxjs';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, retryWhen } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'app-data-mapping',
  templateUrl: './data-mapping.component.html',
  styleUrls: ['./data-mapping.component.scss']
})
export class DataMappingComponent implements OnInit {

  public isAllowedUser: any = true;
  public isAllowedUserWrite: any = true;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;

  public breadcrumb: any;

  public machines: any = {
    list: [],
    filtered: [],
    query: {
      order: "machineId",
      limitStart: 100,
      limit: 100,
      limitOptions: [12, 25, 50, 100]
    }
  };

  public typeIds: any = {
    list: [],
    configList: [],
  };

  public disableButtons: boolean = false;

  public pollingMachine: any = 5000;
  public pollingMachines: any;

  public tableConfig: any = {
    tableInfos: [],
    rows: [],
    completeCardFrame: true,            // boolean true = card around table
    cardFrame: true,            // boolean true = card around table
    filters: {
      searchInput: false,        // boolean true = input search in component
    },
    paginator: {
      options: [25, 50, 100],   // options of rows for page
      size: 50,                 // number of rows for page
      position: 'bottom right'  // position of paginator row
    },
    additionalButtons: [
      {
        clickFunction: "play",
        icon: "play_circle_outline",
        defaultDisabled: true,
        tooltip: "DATA_MAPPING.START_MAPPING",
        class: "rounded-button",
        tdClass: "w50px"
      },
      {
        clickFunction: "delete",
        icon: "delete",
        defaultDisabled: true,
        tooltip: "DATA_MAPPING.DELETE_MAPPING",
        class: "md-red rounded-button",
        tdClass: "w50px"
      }
    ]
  };

  public tableInfos: any = [];
  public sectionName: any = 'dataMapping';

  public mobileListener: Subscription;
  public errorDataMobile: any;
  public isMobile: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR
  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public translate: FfTranslateService,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    private filterService: FiltersService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    public mobile: MobileService
  ) {
    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.breadcrumb = ['DATA_MAPPING.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.pollingMachines = Subscription;

    try {
      this.tableConfig.tableInfos = this.appConfig[this.sectionName].tableInfos
    } catch (error) { console.log(error) }

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile;
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER
  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getMachines, nextState: 3, loadingMsg: 'LOADING.MACHINES' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getMappingConfigs, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getMappingStates, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 },
        { code: 302, function: this.getMappingConfigs, nextState: 4, loadingMsg: 'GLOBAL.LOADING' }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET MACHINES
  getMachines(_this: any) {

    try {
      _this.isAllowedUserWrite = _this.internalDataService.getSpecificPermission("mat-datamapping-rw");
    } catch (error) { console.log(error) }

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(["mat-datamapping-r", "mat-datamapping-rw"], 'or');
    } catch (error) { console.log(error) }

    try {

      let url = '/apif/machines';

      _this.apiService.sendGetRequest(url, {})
        .pipe(retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {

            if (data.body != null && Array.isArray(data.body) && data.body.length > 0) {

              try { _this.internalDataService.setSelectedAssetsList(data?.body ?? []) }
              catch (error) { console.log(error) }

              _this.machines.list = _this.clonerService.deepClone(data.body);

              // _this.machines.list = _this.clonerService.deepClone(data.body?.reduce((acc, val) => {
              //   val.typeId = "AqsFilter";
              //   acc.push(val);
              //   return acc;
              // }, []));
              // console.log(_this.machines.list);

              _this.tableConfig.rows = _this.machines.list;
              _this.tableConfig = _this.clonerService.deepClone(_this.tableConfig);

              try {
                _this.typeIds.list = _this.machines.list.map(x => x.typeId?.split(".")?.[1] ?? x.typeId)
                  .filter(x => x != null).filter(_this.filterService.onlyUnique);
              } catch (error) { console.log(error) }

              if (_this.typeIds.list?.length == 0) {
                let errorData = {
                  message: {
                    customMessage: "Missing property \"typeId\" in every machine",
                    customCode: "404",
                  }
                };
                _this.dispatcherService.getDispatch(_this, 301, errorData);
              } else {
                _this.dispatcherService.getDispatch(_this, 300);
              }

            } else {
              let errorData = {
                message: {
                  customMessage: "Empty asset list",
                  customCode: "404",
                }
              };
              _this.dispatcherService.getDispatch(_this, 301, errorData);
            }

          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET MAPPING CONFIS
  getMappingConfigs(_this: any) {

    if (_this.typeIds.list.length > 0) {
      _this.typeIds.list.forEach((typeId: any, idx: any) => _this.getMappingConfig(_this, typeId, idx))
    }
  }

  getMappingConfig(_this: any, typeId: any, idx: any) {

    try {

      let sources40F = _this.appInfo.sources40F ?? 'assets/config/';
      let url = sources40F + "mapping-" + typeId + '.json';

      _this.apiService.sendGetRequest(url, {})
        .pipe(retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {

            error = {
              ...error, ...{
                error: {
                  customMessage: "Missing file: " + url,
                  customCode: "404",
                }
              }
            }
            return _this.internalDataService.parseStandardHTTPError(_this, error)

          }))
        .subscribe(
          (data: any) => {

            // console.log(data.body);

            if (data.body != null) {
              _this.typeIds.configList.push({
                typeId: typeId,
                data: data.body,
              });

              if (idx == parseInt(_this.typeIds.list.length) - 1) _this.dispatcherService.getDispatch(_this, 300);

            }
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET MAPPING CONFIS
  getMappingStates(_this: any, firstInvoke: any = true) {

    if (_this.machines.list.length > 0) {
      _this.machines.list.forEach((machine: any, idx: any) => _this.getMappingState(_this, (machine.assetId ?? machine.machineId) ?? machine.machineId, idx, firstInvoke))
    }
  }

  getMappingState(_this: any, machineId: any, idx: any, firstInvoke: any) {

    try {

      let url = '/apif/mapping-state/' + machineId;
      let query = {}

      _this.apiService.sendGetRequest(url, query)
        .pipe(retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {

            // console.log(data.body);

            _this.machines.list[idx] = _this.parseStatesData(Object.assign(_this.machines.list[idx], data.body), idx, firstInvoke);

            _this.tableConfig.rows = _this.machines.list;
            _this.tableConfig = _this.clonerService.deepClone(_this.tableConfig);
            if (firstInvoke && idx == parseInt(_this.machines.list.length) - 1) {
              _this.dispatcherService.getDispatch(_this, 300);
            }

          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseStatesData(data, idx, firstInvoke) {
    data.idx = idx;
    if (data.hasOwnProperty('typeId') && data.typeId != null) {
      let ix = this.typeIds.configList.findIndex(x => x.typeId == data.typeId.split(".")[1]);

      if (ix != -1) {
        data.mapConfig = this.typeIds.configList[ix].data;
        data.errorsP = data.errors != null && Array.isArray(data.errors) && data.mapConfig != null ? data.errors.length + '/' + data.mapConfig.length.toString() : null;
        data.mappingsP = data.mappings != null && data.mapConfig != null ? data.mappings.toString() + '/' + data.mapConfig.length.toString() : null;
        data.mappingsPerc = data.mappings != null && data.mapConfig != null ? data.mappings / data.mapConfig.length : null;

        try { data.unmappingsP = (data.mapConfig.length - data.mappings - data.errors.length).toString() + '/' + data.mapConfig.length.toString() }
        catch (error) { console.log(error) }

        if (data.mapConfig.length == data.mappings) this.setMachineState(data, 5);
      }
      if ((data.state == 1 || data.state == 2 || data.state == 3 || data.state == 4) && firstInvoke) {
        // this.startMapping(data, idx);
        this.stopPollingMachine();
        this.startPollingMachineMapping(data.assetId, idx, false);
        // this.disableButtons = true;
        data.mappingBool = true;
      }
      this.setMachineState(data, data.state);
    }
    return data;
  }

  setMachineState(machine, state) {
    machine.state = state;
    machine.stateP = this.translate.instant("DATA_MAPPING.STATES." + state + '.label');
    machine.iconClass = this.translate.instant("DATA_MAPPING.STATES." + state + '.status');
    this.setDisabledButtons(machine);
  }

  setDisabledButtons(machine) {
    // console.log('setDisabledButtons', machine);

    machine.disabledButtons = {};

    try {
      this.tableConfig.additionalButtons.forEach(button => {
        switch (button.clickFunction) {
          case 'play':
            machine.disabledButtons[button.clickFunction] = !this.pageStateReady || (this.disableButtons && !machine.mappingBool) || machine.mappings >= machine.mapConfig.length || !this.isAllowedUserWrite;
            break;
          case 'pause':
            machine.disabledButtons[button.clickFunction] = !this.pageStateReady || (this.disableButtons && !machine.mappingBool) || machine.mappings >= machine.mapConfig.length || !this.isAllowedUserWrite;
            break;
          case 'delete':
            machine.disabledButtons[button.clickFunction] = !this.pageStateReady || (this.disableButtons && !machine.mappingBool) || machine.mappings == 0 || machine.state == 1 || machine.state == 4 || !this.isAllowedUserWrite;
            break;
        }
      });

    } catch (error) {

    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // POLLING MACHINES

  stopPollingMachine() {
    try { this.pollingMachines.unsubscribe() } catch (error) { }
  }

  startPollingMachineMapping(machineId, idx, firstInvoke) {

    if (this.pollingMachine > 0) {
      this.pollingMachines = timer(0, this.pollingMachine).subscribe((count) => {
        this.getMappingState(this, machineId, idx, firstInvoke);
      });
    } else {
      this.getMappingState(this, machineId, idx, firstInvoke);
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // START MAPPING

  startMapping(machine, idx) {

    let _this = this;

    machine.mappingBool = true;
    this.disableButtons = true;

    this.setMachineState(machine, 1);

    this.snackBar.open(this.translate.instant('DATA_MAPPING.HANDLING.START.START', { machineName: machine.name ?? machine?.machineName }), '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 4000
    });

    this.stopPollingMachine();
    this.startPollingMachineMapping((machine.assetId ?? machine.machineId), idx, false);

    let url = '/apif/mapping-operation/' + (machine.assetId ?? machine.machineId);
    let payload = machine.mapConfig;
    let query = null;

    _this.apiService.sendPostRequest(url, payload, query)
      .pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {
          console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);
          let errorData = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          if (error.status == -1) {

            _this.snackBar.open(_this.translate.instant('DATA_MAPPING.HANDLING.START.PAUSED', { machineName: machine.name ?? machine?.machineName }), '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              panelClass: ['snackbar', 'warning'],
              duration: 4000
            });

          } else {

            _this.snackBar.open(_this.translate.instant('DATA_MAPPING.HANDLING.START.FAIL', { machineName: machine.name ?? machine?.machineName }), '', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              panelClass: ['snackbar', 'fail'],
              duration: 4000
            });

            _this.stopPollingMachine();
            _this.dispatcherService.getDispatch(_this, 302);
          }

          return throwError(errorData.status + ': ' + errorData.message);
        })
      )
      .subscribe(
        (data: any) => {
          // console.log(data.body);

          _this.snackBar.open(_this.translate.instant('DATA_MAPPING.HANDLING.START.SUCCESS', { machineName: machine.name ?? machine?.machineName }), '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['snackbar', 'success'],
            duration: 4000
          });

          _this.setMachineState(machine, 0);
          _this.disableButtons = false;

          _this.stopPollingMachine();

          _this.pageState.next(4);

          setTimeout(() => {
            _this.tableConfig = _this.clonerService.deepClone(_this.tableConfig);
            _this.getMappingStates(_this, true);
          }, 200);
        }
      );
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // PAUSE MAPPING

  pauseMapping(machine) {

    let _this = this;

    let url = '/apif/mapping-state/' + (machine.assetId ?? machine.machineId);
    let query = {
      state: 3
    };

    machine.mappingBool = false;
    this.disableButtons = false;

    this.setMachineState(machine, 0);

    _this.apiService.sendPutRequest(url, null, query)
      .pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {
          console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);
          let errorData = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.snackBar.open(_this.translate.instant('DATA_MAPPING.HANDLING.PAUSE.FAIL', { machineName: machine.name ?? machine?.machineName }), '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['snackbar', 'fail'],
            duration: 4000
          });

          return throwError(errorData.status + ': ' + errorData.message);
        })
      )
      .subscribe(
        (data: any) => {
          // console.log(data.body);
          _this.stopPollingMachine();
        }
      );
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DELETE MAPPING

  deleteMappingConfirm(machine) {

    let confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant('DATA_MAPPING.CONFIRM_ELIMINATION', {
          machineName: machine.name ?? machine?.machineName
        }),
      }
    });

    confirmationDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') this.deleteMapping(machine);

    });
  }

  deleteMapping(machine) {

    this.disableButtons = true;
    let _this = this;

    this.setMachineState(machine, 4);

    this.snackBar.open(this.translate.instant('DATA_MAPPING.HANDLING.DELETE.START', { machineName: machine.name ?? machine?.machineName }), '', {
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      duration: 4000
    });

    var url = '/apif/mapping-operation/' + (machine.assetId ?? machine.machineId);
    var query = {};

    _this.apiService.sendDeleteRequest(url, query)
      .pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {
          console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);
          let errorData = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.snackBar.open(_this.translate.instant('DATA_MAPPING.HANDLING.DELETE.FAIL', { machineName: machine.name ?? machine?.machineName }), '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['snackbar', 'fail'],
            duration: 4000
          });

          _this.pageState.next(4);

          setTimeout(() => {
            _this.tableConfig = _this.clonerService.deepClone(_this.tableConfig);
            _this.getMappingStates(_this, true);
          }, 200);

          return throwError(errorData.status + ': ' + errorData.message);
        })
      )
      .subscribe(
        (data: any) => {
          // console.log(data.body);

          _this.disableButtons = false;

          _this.snackBar.open(_this.translate.instant('DATA_MAPPING.HANDLING.DELETE.SUCCESS', { machineName: machine.name ?? machine?.machineName }), '', {
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            panelClass: ['snackbar', 'success'],
            duration: 4000
          });

          _this.pageState.next(4);

          setTimeout(() => {
            _this.tableConfig = _this.clonerService.deepClone(_this.tableConfig);
            _this.getMappingStates(_this, true);
          }, 200);

        }
      );

  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CLICK BUTTON
  onButtonClick(button) {
    if (button != null && button.buttonInfos != null && button.buttonInfos.clickFunction != null) {
      switch (button.buttonInfos.clickFunction) {
        default:
        case "play":
          this.startMapping(button.row, button.row.idx);
          break;
        case "delete":
          this.deleteMappingConfirm(button.row);
          break;
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit(): void {
    this.dispatcherService.getDispatch(this, 300);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pageState.unsubscribe();
    } catch (err) { }
    try {
      this.pollingMachines.unsubscribe();
    } catch (err) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }

  }

}
