import { Component, HostBinding, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { StandardDialogServiceService } from 'src/app/services/standard-dialog.service';

@Component({
  selector: 'ff-value',
  templateUrl: './value.component.html',
  styleUrls: ['./value.component.scss']
})
export class ValueComponent implements OnInit, OnChanges {

  @Input() widget: any;
  @HostBinding('class') classes: any;
  @HostBinding('class.compressed') compressed: any;

  public machineId: any = null;

  constructor(
    public filterService: FiltersService,
    public internalDataService: InternalDataService,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public translate: FfTranslateService,
    public router: Router,
    public standardDialogServiceService: StandardDialogServiceService,
  ) { }

  parseWidgetData() {

    // machineId from cache
    if (this.cacheService.get("machineId") != null) this.machineId = this.cacheService.get("machineId");

    // If "productionUnit", calculate unit in title automatically
    try { if (this.widget?.productionUnit != null) this.widget.unit = this.filterService.getProductionUnit(this.widget?.productionUnit, this.widget?.machineProfile?.productionConfig) } catch (error) { }

    try {

      let conf = this.widget.config ?? [];

      // Ratio color property to set background color for icon
      this.widget.config.forEach((conf: any) => {
        if (conf.hasOwnProperty('ratioColor') && conf.ratioColor) {
          conf.style = {
            'background-color': this.internalDataService.getBarColor(this.widget.data[conf.variable] != null ? this.widget.data[conf.variable] * 100 : 0),
            'color': "white",
          };
        }
      });

      this.widget.infos = [];

      // Phase Id Variable (for AQSEPTENCE)
      let phaseIdVariable = this.widget.phaseIdVariable ?? "FilterState.phaseId";

      // Loop on all elements
      conf.filter((conf: any) => conf.phaseId == null ? true : this.widget.data?.[phaseIdVariable] == conf.phaseId).forEach((v: any) => {

        if (v.label != null) {
          try { v.label = this.internalDataService.parseDatapointLabel(v.label.replaceAll('{{machineId}}', this.machineId), null, this.machineId) }
          catch (error) { console.log(error) }
        }

        let val: any = this.widget.data?.[v.variable];

        if (v?.nestedObject) {
          let splittedVar = v?.variable?.split(v?.variableToSplit ?? '.');
          val = this.clonerService.deepClone(this.widget.data);
          splittedVar?.forEach(sv => val = val?.[sv]);
        }

        if (val != null) {

          // Force value to float
          if (v?.convertTo == 'float') try { val = parseFloat(val) } catch (error) { };

          // Advanced translate
          if (v?.type == 'advancedTranslate') {
            let conf = v?.advancedTranslateConfig ?? {};
            v.value = this.internalDataService.parseLabelWithAssetId(conf?.key, val, null, this.machineId);
          }

          // Config from profile
          else if (v?.type == 'configFromProfile' && v?.mappingConfig) {

            let newVal = this.filterService.mapFromProfile(v, val, this.widget?.machineProfile, true);
            // let mappingKey = v?.mappingConfig?.key ?? 'timeStates';
            // if (typeof mappingKey === 'string' && mappingKey.includes('.')) {
            //   mappingKey = mappingKey.split('.');
            // }

            // let container = [];
            // if (Array.isArray(mappingKey)) {
            //   container = this.widget?.machineProfile;
            //   for (let m_key of mappingKey) {
            //     container = container?.[m_key];
            //   }
            // }
            // else {
            //   container = this.widget?.machineProfile?.[mappingKey];
            // }

            // let newVal = container?.find(y => y?.[v?.mappingConfig?.idKey ?? 'id'] == val)?.[v?.mappingConfig?.outputKey ?? 'label'];

            // let newVal = this.widget?.machineProfile?.[v?.mappingConfig?.key ?? 'timeStates']?.find(y => y?.[v?.mappingConfig?.idKey ?? 'id'] == val)?.[v?.mappingConfig?.outputKey ?? 'label'];
            v.value = v?.mappingConfig?.translate ? this.translate.instant(newVal ?? '-') : newVal;
          }

          else {

            // String
            if (typeof val == 'string') {
              v.value = val;

              // Date
              if (v.format == 'date') {
                let dateFormat = v.dateFormat ?? 'default';
                if (this.widget?.aggrUnit != null) dateFormat = this.filterService.getFormatFromAggrTime(this.widget.aggrUnit);
                v.value = val != null && val != '-' ? this.filterService.parseMoment(val, dateFormat) : "-";
              }
            }

            // Value
            else {

              // Time (hh:mm:ss)
              if (v.format == 'time') {
                if (v?.multiplier) val = val * v.multiplier;
                v.value = this.filterService.parseTime(val, 's', 'hh:mm:ss');
              }
              // Progress bar (do nothing)
              else if (v.type == 'progressBar') v.value = val;

              // Standard 
              else {

                // If "productionUnit", calculate unit automatically according to "productionUnit" value (es. totProdAct, avgProdSet, ecc...)
                if (v?.productionUnit != null) v.suffix = this.filterService.getProductionUnit(v?.productionUnit, this.widget?.machineProfile?.productionConfig);

                // Convert unit
                let converted = this.filterService.convertUnit(v.suffix, val);

                v.value = this.filterService.parseGaugeValue(converted.value, v.decimals, v.multiplier);
                v.unit = converted.unit;
              }
            }
          }

        } else {

          // Set value to null
          v.value = null;

          // Update production unit
          if (v?.productionUnit != null) v.suffix = this.filterService.getProductionUnit(v?.productionUnit, this.widget?.machineProfile?.productionConfig);

          // Convert unit
          v.unit = this.filterService.convertUnit(v.suffix).unit;

          if (v.format == 'time') v.value = "--:--:--";

          if (v?.defaultValue) {
            if (v?.translateDefaultValue) v.value = this.translate.instant(v.defaultValue);
            else v.value = v.defaultValue;
          }

        }

        if (v.dynamicClass != null) {

          try {
            let dynamicClass = v.dynamicClass;

            let dynamicVar = dynamicClass.variable;
            let dynamicConfig = dynamicClass.config;
            let dynamicClassName = dynamicClass.className;

            // Pre-existing dynamic config associated to value
            if (dynamicClass?.type == null) {
              let compTypeIdx = this.widget.data[dynamicConfig].findIndex((x: any) => x.id == this.widget.data[dynamicVar]);
              if (compTypeIdx != -1) v.class = this.widget.data[dynamicConfig][compTypeIdx][dynamicClassName != null ? dynamicClassName : 'class'];
            }

            // Number check --> evaluate difference between val and compared val and associate class according to the value
            else if (dynamicClass?.type == "numberCheck" && dynamicClass?.config?.length > 0) {

              let comparedValue = null;

              if (dynamicClass?.nestedObject) {
                let splittedVar = dynamicClass?.variable?.split(dynamicClass?.variableToSplit ?? '.');
                comparedValue = this.clonerService.deepClone(this.widget.data);
                splittedVar?.forEach(sv => comparedValue = comparedValue?.[sv]);
              }

              else if (dynamicClass?.fixedValue) {
                comparedValue = dynamicClass?.fixedValue;
              }

              else {
                comparedValue = this.clonerService.deepClone(this.widget.data?.[dynamicVar]);
              }

              let diffCheck = "equal";

              // If at least one value is null, set diffCheck as "null"
              if (val == null || comparedValue == null) diffCheck = "null";

              // Difference
              else {
                if (val > comparedValue * (1 + (dynamicClass?.thresholds?.upper ?? 0) / 100)) diffCheck = "higher";
                else if (val < comparedValue * (1 - (dynamicClass?.thresholds?.lower ?? 0) / 100)) diffCheck = "lower";
              }

              let classConfig = dynamicClass?.config?.find(x => x.id == diffCheck) ?? {};
              v.class = classConfig?.class;
              v.icon2 = classConfig?.icon;

            }

          } catch (error) { console.log(error) }

        }

        if (v?.buttons?.length) v.buttons = this.filterService.filterArrayWithPermission(v.buttons);

        this.widget.infos.push(v);
      });
      // }

      if (this.widget.class != null) this.classes = this.widget.class;
      if (this.widget.compressed != null) this.compressed = this.widget.compressed;
    } catch (error) { console.log(error) }

    this.widget.config?.forEach((conf: any) => {
      if (conf.hasOwnProperty('thresholds') && Array.isArray(conf.datapointList)) {
        let thresholds = conf.thresholds;
        let actual = this.widget.data[conf.datapointList[0]];
        let setPoint = this.widget.data[conf.datapointList[1]];
        conf.iconClass = this.getColorByThresholds(thresholds, setPoint, actual);
      }
    });

    // console.log(this.widget)

  }

  getColorByThresholds(thresholds: any, setPoint: any, actual: any) {
    let x0 = thresholds[0] * setPoint / 100;
    let min0 = setPoint - x0;
    let max0 = setPoint + x0;
    let color = 'md-gray'
    if (min0 <= actual && actual <= max0) {
      color = 'md-green';
    } else {
      if (thresholds.length > 1) {
        // threshold [1]
        var x1 = thresholds[1] * setPoint / 100;
        var min1 = setPoint - x1;
        var max1 = setPoint + x1;
        if (min1 <= actual && actual <= max1) {
          color = 'md-orange';
        } else {
          color = 'md-red';
        }
      } else {
        color = 'md-orange';
      }
    }
    return color;
  }

  fxLayoutBody() {
    if (this.widget.subtype && this.widget.subtype.includes('horizontal')) return 'row wrap';
    return 'column';
  }

  fxLayoutAlignBody() {
    if (this.widget.subtype && this.widget.subtype.includes('horizontal')) return 'start space-around';
    return 'space-around start';
  }

  fxLayoutGap() {
    try { return this.widget.gap ?? '0' }
    catch (error) { return '0' }
  }

  dpClick(dp: any) {
    if (dp != null && dp.action != null) {
      this.internalDataService.setDatapoint(dp);
    }
  }

  onIcon2Click(clickConfig) {
    if (clickConfig == null) return;
    switch (clickConfig?.clickFunction) {

      case 'link':
        let url = clickConfig?.url?.replaceAll("{machineId}", this.machineId);
        this.router.navigateByUrl(url);
        return

      case 'datapoint':
        this.dpClick(clickConfig?.datapoint)
        return

      default:
        console.log("clickFunction \"" + clickConfig?.clickFunction + "\" not configured yet");

    }
  }

  buttonClick(item, info) {

    let infos: any = {
      row: item,
      buttonInfos: info,
    };

    if (this.standardDialogServiceService?.[infos?.buttonInfos?.clickFunction] == null) {
      this.internalDataService.openSnackBar(this.translate.instant('GLOBAL.NO_CLICK_FUNCTION_CONFIGURED', {
        clickFunction: infos?.buttonInfos?.clickFunction ?? '-'
      }), 'right', 'bottom', 4000, '', ["warning"]);
      return;
    }
    this.standardDialogServiceService?.[infos?.buttonInfos?.clickFunction]?.(this.widget.referenceComponent, infos);

  }

  ngOnInit(): void {
    this.parseWidgetData();
  }

  ngOnChanges(): void {
    this.parseWidgetData();
  }

}
