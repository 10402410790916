<div *ngIf="filterButtons?.length > 0" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="8px">

  <button *ngFor="let item of filterButtons;" (click)="openFilterDialog(item)" class="filter-button"
    [disabled]="item.disabled || item?.options?.length == 0" fxLayout="row" fxLayoutAlign="center center">

    <mat-icon class="icon">filter_alt</mat-icon>

    <span>{{ item.label | translate }}</span>

    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="checkFilterButtons(item)" class="active-filters-circle">
      {{ filteredItems(item) }}
    </div>

  </button>

</div>