<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <div fxLayout="row" fxLayoutAlign="start center" class="selectors-container">

    <div class="interval-selector" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">

      <div class="box" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="icon">event_available</mat-icon>
        <mat-form-field appearance="fill">
          <mat-label>{{'PROCESS_LOG.DAY_SELECTION'|translate}}</mat-label>
          <mat-select [(value)]="selectedDay">
            <mat-option *ngFor="let day of availableDays" [value]="day" (click)="onDaySelection(selectedDay)">{{ day }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <span fxFlex></span>

    <!-- search -->
    <div class="search-input" fxLayout="row" fxLayoutAlign="center center">
      <mat-icon class="icon">search</mat-icon>
      <input type="text" [(ngModel)]="searchCycles" (keyup)="filterCycles()"
        placeholder="{{'GLOBAL.SEARCH'|translate}}">
    </div>

  </div>

  <!-- table -->
  <mat-card fxLayout="column">
    <!-- main table -->
    <table mat-table [dataSource]="cyclesData" matSort *ngIf="plotState == 1 && cycles.filtered.length > 0">

      <!-- cycles rows definition -->
      <ng-container *ngFor="let infoCy of cyclesInfo" matColumnDef="{{infoCy.variable}}">

        <!-- column header -->
        <th mat-header-cell *matHeaderCellDef [mat-sort-header]="infoCy.orderBy != null ? infoCy.orderBy : null"
          [disabled]="infoCy.orderBy == null">
          <div fxLayout="row">
            <!-- icon -->
            <div *ngIf="infoCy.iconTH">
              <mat-icon *ngIf="infoCy.iconTH.type == 'icon'" class="icon left"
                translate-attr="{'aria-label': infoCy.label}">
                {{infoCy.iconTH.icon}}</mat-icon>
              <mat-icon *ngIf="infoCy.iconTH.type == 'svg'" svgIcon="{{ infoCy.iconTH.icon }}" class="icon left"
                translate-attr="{'aria-label': infoCy.label}"></mat-icon>
              <i *ngIf="infoCy.iconTH.type == 'fontawesome' || infoCy.iconTH.type == 'phosphor'"
                class="icon left {{ infoCy.iconTH.icon }}" translate-attr="{'aria-label': infoCy.label}"></i>
            </div>
            <!-- prefix, label, suffix -->
            <span *ngIf="infoCy.prefix != null">{{infoCy.prefix}}&nbsp;</span>
            <span>{{infoCy.label|translate}}</span>
            <span *ngIf="infoCy.suffix != null">&nbsp;[{{infoCy.suffix}}]</span>
          </div>
        </th>

        <!-- column body != list -->
        <td mat-cell *matCellDef="let cycle" class="clickable" [ngClass]="{ 'primary': infoCy.primary }">

          <div *ngIf="!infoCy.type">
            <span>{{(cycle[infoCy.variable] != null ? cycle[infoCy.variable] : '-')}}</span>
            <span *ngIf="infoCy.unit != null">&nbsp;{{infoCy.unit}}</span>
          </div>

          <div *ngIf="infoCy.type == 'interval'">
            <span>{{(cycle.timeStartP != null ? cycle.timeStartP : '-')}}</span><br>
            <span>{{(cycle.timeEndP != null ? cycle.timeEndP : '-')}}</span>
            <!-- <span>{{cycle.paramsP|json}}</span> -->
          </div>

          <div *ngIf="infoCy.type == 'progressBar'">
            <kpi-bar [kpiProps]="{ 'value': cycle[infoCy.variable], 'hideLabel': true }"></kpi-bar>
          </div>

          <div *ngIf="infoCy.type == 'icon'" matTooltip="{{ cycle[infoCy.variable].label |translate}}">
            <mat-icon *ngIf="cycle[infoCy.variable].icon.type == 'icon'" class="icon left"
              translate-attr="{'aria-label': infoCy.label}">
              {{cycle[infoCy.variable].icon.icon}}</mat-icon>
            <mat-icon *ngIf="cycle[infoCy.variable].icon.type == 'svg'" svgIcon="{{ cycle[infoCy.variable].icon.icon }}"
              class="icon left" translate-attr="{'aria-label': infoCy.label}"></mat-icon>
            <i *ngIf="cycle[infoCy.variable].icon.type == 'fontawesome' || cycle[infoCy.variable].icon.type == 'phosphor'"
              class="icon left {{ cycle[infoCy.variable].icon.icon }}"
              translate-attr="{'aria-label': infoCy.label}"></i>
          </div>

          <div *ngIf="infoCy.type == 'table'" class="td-table">
            <table class="table-in-td">
              <tr *ngFor="let item of cycle[infoCy.variable]; let i=index">
                <td style="padding: 0 0.25rem !important;">
                  <span>{{item.label | translate}}</span>
                </td>
                <td style="padding: 0 0.25rem !important;">
                  <span>{{item.value}}</span>
                  <span *ngIf="item.unit != null">&nbsp;{{item.unit}}</span>
                </td>
              </tr>
            </table>
          </div>

          <div *ngIf="infoCy.type == 'list'" class="td-list">
            <ul>
              <li *ngFor="let li of cycle[infoCy.variable]; let i=index">
                <div *ngIf="cycle[infoCy.variable + '_max'] != null && i < cycle[infoCy.variable + '_max']">
                  <span>{{li.label | translate}}: </span>
                  <span>{{li.value}}</span>
                  <span *ngIf="li.unit != null">&nbsp;{{li.unit}}</span>
                </div>
              </li>
            </ul>
          </div>

        </td>

      </ng-container>

      <!-- header row -->
      <tr mat-header-row *matHeaderRowDef="cyclesColumns"></tr>
      <!-- cycles rows -->
      <tr mat-row *matRowDef="let cycle; columns: cyclesColumns;"
        [ngClass]="{'selected': cycle.selected, 'actual': cycle.actual, 'comparison': cycle.comparison}"
        (click)="selectCycle(cycle)"></tr>

    </table>

    <mat-paginator [ngClass]="{'hidePaginator': (plotState == 1 || cycles.filtered.length == 0) }"
      [length]="cycles.filtered.length" [pageSizeOptions]="cycles.pageOptions" [pageSize]="cycles.pageSize"
      showFirstLastButtons></mat-paginator>

  </mat-card>

  <div *ngIf="plotState == 2" fxLayout="row" fxLayoutAlign="center center">
    <div class="not-found" fxLayout="row" fxLayoutAlign="center center">
      <span>{{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}</span>
    </div>
  </div>

  <div *ngIf="plotState == 0" class="h100 w100">
    <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
      <ff-loading-card></ff-loading-card>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button mat-button mat-dialog-close>{{ 'GLOBAL.CANCEL' | translate }}</button>
  <button mat-button (click)="closeDialog()" [disabled]="!isCycleSelected">{{ 'GLOBAL.SELECT' | translate }}</button>
</mat-dialog-actions>