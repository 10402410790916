import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';



@Component({
  selector: 'app-cycle-list',
  templateUrl: './cycle-list.component.html',
  styleUrls: ['./cycle-list.component.scss']
})
export class CycleListComponent implements OnInit, OnDestroy {

  public isAllowedUser: boolean = true;

  public configurationErrors: any = [];

  // Switch from single widgets to complete dashboard
  public useUnparsedDashboard: any = null;
  public completeDashboardConfig: any;

  public loadingData: any;
  public errorData: any;
  public errorDataMobile: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public pollingTime: any;
  public pollingProds: any;

  public intervalAggrList: any;

  public interval: any;
  public intervalConfig: any;
  public defaultIntervalId: any = 'last30Days';
  public intervalListId: any = 2;
  public customIntervalArray: any = null;

  public variables: any = {
    completeList: [],
    list: [],
    selected: []
  };

  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;

  public intervalAggregations: any;
  public aggregationsTime: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public dashboardConfig: any;

  public dashboardData: any;

  public tabName: any = "cycleList";
  public sectionName: any = "machineRecorder";
  public dashboardName: any = "cycle-list";
  public dashboardNameComplete: any;

  public mobileListener: any;
  public isMobile: any;

  public showResetInterval: any = false;

  public aggregatedTable: any = false;
  public maxTableLengthBeforeAutomaticAggregation: any = 1000;
  public numberOfRowsAfterAutomaticAggregation: any = 50;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getVariablesConfig, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 },
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getDataPolling, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    private router: Router,
    public mobile: MobileService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['MACHINE_RECORDER.TITLE', 'CYCLE_LIST.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);
    this.availableMachines = this.appConfig?.[this.sectionName]?.[this.tabName]?.availableMachines ?? [];

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig?.[this.tabName]?.pollingTime ?? 0;
    this.pollingProds = Subscription;

    this.intervalAggrList = this.appConfig?.[this.tabName]?.intervalAggrList;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  openAggrDialog(aggr: any) {

    try {
      this.pollingProds.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        // console.log('afterClosed', result);
        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
      }

      this.pageState.next(isClickedSelect ? 5 : 6);

      if (!this.interval.enabledPolling) {
        this.getData(this, 0);
      } else {
        this.getDataPolling(this);
      }

    });
  };

  changePageAggregation(aggrDropdown: any) {
    this.aggrDropdown = this.clonerService.deepClone(aggrDropdown);
    this.pageState.next(5);
    if (!this.interval.enabledPolling) {
      this.getData(this, 0);
    } else {
      // this.getEventsBreakdowns(this);
      this.getData(this, 0);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-la-traceability");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {

      let tableType = "ff-table-sortable";
      if (_this.dashboardConfig?.widgets?.findIndex(x => x.type == tableType) == -1) {
        _this.configurationErrors.push({ label: "The table is not present because in the file \"" + _this.dashboardNameComplete + "\" there is no widget of type \"" + tableType + "\"" })

      }
      if (_this.configurationErrors.length > 0) {
        _this.internalDataService.openSnackBar(_this.configurationErrors.map(x => "- " + x.label + ".").join("\n"), 'right', 'bottom', 60000, 'x', ["warning"]);
      }
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingProds, this.getDataPolling, this.getData, this.machine.timezone) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval aggr
  selectAggregation(aggr: any) {
    if (aggr != null) {
      try {
        this.pollingProds.unsubscribe();
      } catch (error) { }

      this.intervalAggregations.selected = aggr;

      this.pageState.next(5);
      this.getDataPolling(this);
    }
  }

  machineSelectionChange(machine: any) {

    if (machine != null) {

      try {
        this.pollingProds.unsubscribe();
      } catch (error) {
      }

      this.pageState.next(5);
      this.getVariablesConfig(this);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET VARIABLES CONFIG

  getVariablesConfig(_this: any) {
    try {

      let machineReference = _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference);

      _this.internalDataService.getVariablesConfig(_this, _this.machineId, machineReference, null, false);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // polling
  getDataPolling(_this: any) {
    try {

      if (_this.cacheService.get("intervalCycles") == null) {

        _this.interval = _this.intervalService.getIntervalById(_this.defaultIntervalId, _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(_this.intervalListId, _this.machine.timezone, _this.customIntervalArray),
          selected: _this.interval
        };

        _this.cacheService.set("intervalCycles", _this.intervalConfig);

      } else {
        _this.intervalConfig = _this.cacheService.get("intervalCycles");
        _this.interval = _this.cacheService.get("intervalCycles").selected;
      }

      if (_this.pollingTime > 0) {
        _this.pollingProds = timer(0, _this.pollingTime).subscribe(count => _this.getData(_this, count));
      } else { _this.getData(_this, 0) }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get prods
  getData(_this: any, count?: any) {
    try {

      if (count == 0 && _this.intervalAggrList?.length > 0) _this.internalDataService.forceAggregationList(_this, _this.intervalAggrList);

      // let url = '/apif/production-traceability/' + _this.machineId;
      let url = '/apif/condition-monitoring/cycles-detail/' + _this.machineId + '/GLOBAL';

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone,
        anomalyDetection: false,
        tags: ""
      };

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      let payload: any = {};

      if (_this.machine.profile.additionalCycleVariables?.length > 0) payload.additionalCycleVariables = _this.clonerService.deepClone(_this.machine.profile.additionalCycleVariables);

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.dashboardData = {
              table: _this.clonerService.deepClone(_this.parseProdsTable(data.body)),
              totCycles: data.body.length,
              avgDuration: _this.filterService.average(data.body, 'cycleTime'),
              totDuration: _this.filterService.sum(data.body, 'cycleTime'),
              cyclesHour: data.body.length / _this.filterService.sum(data.body, 'cycleTime') * 3600,
            };

            _this.filterService.createCompleteDashboardConfig(_this);

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseProdsTable(data: any) {

    let newData: any = this.clonerService.deepClone(data ?? []);

    if (newData != null && newData.length > 0) {
      newData.forEach((cycle: any) => {

        cycle.day = cycle.timestamp != null ? moment(cycle.timestamp).format("YYYY-MM-DD") : null;
        cycle.timeStart = cycle.timeStart ?? moment(cycle.timestamp).subtract(cycle.cycleTime, 's');
        cycle.cycleTimeP = this.filterService.parseTime(cycle.cycleTime, 's', "HH:mm:ss");
        cycle.timeStartP = this.filterService.parseMoment(cycle.timeStart, "HH:mm:ss");
        cycle.timeEndP = this.filterService.parseMoment(cycle.timestamp, "HH:mm:ss");

      });
    }

    this.aggregatedTable = false;

    this.maxTableLengthBeforeAutomaticAggregation = this.machine.profile?.cycleTraceability?.maxTableLengthBeforeAutomaticAggregation ?? this.appConfig.machineRecorder?.maxTableLengthBeforeAutomaticAggregation ?? this.maxTableLengthBeforeAutomaticAggregation;
    this.maxTableLengthBeforeAutomaticAggregation = this.maxTableLengthBeforeAutomaticAggregation > 2000 ? 2000 : this.maxTableLengthBeforeAutomaticAggregation;

    this.numberOfRowsAfterAutomaticAggregation = this.machine.profile?.cycleTraceability?.numberOfRowsAfterAutomaticAggregation ?? this.appConfig.machineRecorder?.numberOfRowsAfterAutomaticAggregation ?? this.numberOfRowsAfterAutomaticAggregation;
    this.numberOfRowsAfterAutomaticAggregation = this.numberOfRowsAfterAutomaticAggregation > 200 ? 200 : this.numberOfRowsAfterAutomaticAggregation;

    if (newData?.length > this.maxTableLengthBeforeAutomaticAggregation) {

      // console.time('cycleAggregator');

      this.aggregatedTable = true;

      let bucketNumber = 1;
      let aggrData = newData.sort(this.filterService.sortByProperty('timestamp', 'asc', true)).reduce((acc, val, index) => {

        let aggrObj = {
          timeStart: val?.timeStart,
          timeEnd: val?.timestamp,
          cycles: 1,
          duration: [val.cycleTime],
        };

        if (index < newData?.length / this.numberOfRowsAfterAutomaticAggregation * bucketNumber) {
          try {
            acc[bucketNumber].timeEnd = aggrObj?.timeEnd;
            acc[bucketNumber].cycles++;
            acc[bucketNumber].duration.push(val.cycleTime);
          } catch (error) {
            acc[bucketNumber] = aggrObj;
          }
        } else {
          bucketNumber++;
          acc[bucketNumber] = aggrObj;
        }

        return acc;

      }, {});

      aggrData = (Object.entries(aggrData) as any)?.reduce((acc, val) => {

        let aggrVal = val?.[1];
        if (aggrVal?.duration?.length > 0) aggrVal.avgDuration = this.filterService.average(aggrVal.duration);
        acc.push(aggrVal);
        return acc;
      }, []);

      // console.timeEnd('cycleAggregator');

      return aggrData;

    }

    return newData;
  }


  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CLICK BUTTON
  async onButtonClick(button) {
    if (button != null && button.buttonInfos != null && button.buttonInfos.clickFunction != null) {
      switch (button.buttonInfos.clickFunction) {

        case "cycleDetail":

          if (!this.aggregatedTable) {
            try {

              let a = moment();
              let b = moment().subtract(365, 'days');

              let daysList: any = [];
              for (let m = moment(a); b.diff(m, 'days') < 0; m.subtract(1, 'days')) {
                daysList.push(moment(m).format("YYYY-MM-DD"));
              }

              this.cacheService.set('cycleDays', {
                list: daysList,
                selected: button.row.day
              });

              this.cacheService.set('totalCycles', {
                list: this.clonerService.deepClone(this.dashboardData.list),
                selected: button.row
              });

              this.cacheService.set('selectedCycle', button.row);

              let url: any = [this.machineId, 'machine-recorder', 'cycle-exploration'].map((x: any) => "/" + x).join("");
              this.router.navigateByUrl(url);
            } catch (error) {
              console.log(error);
            }
          } else {

            let range: any = {
              from: button?.row?.timeStart,
              to: button?.row?.timeEnd,
            };

            if (range != null && range.from != null && range.to != null) {
              console.log('range', range);

              this.showResetInterval = true;

              this.interval.id = 'custom';
              this.interval.startDate = moment.tz(range.from, this.machine.timezone).toDate(); //.add(100, 'ms').toDate();
              this.interval.endDate = moment.tz(range.to, this.machine.timezone).toDate(); //.add(100, 'ms').toDate();

              this.interval.start_shortDate = moment.tz(range.from, this.machine.timezone).format('MMM DD, YYYY - HH:mm:ss')
              this.interval.end_shortDate = moment.tz(range.to, this.machine.timezone).format('MMM DD, YYYY - HH:mm:ss')
              this.interval.startF = this.interval.start_shortDate + ' - ' + this.interval.end_shortDate;

              this.interval.start = JSON.parse(JSON.stringify(this.interval.startDate));
              this.interval.end = JSON.parse(JSON.stringify(this.interval.endDate));

              this.intervalConfig = {
                list: this.intervalService.getDefaultIntervals(2, this.machine.timezone),
                selected: this.interval
              };

              this.cacheService.set("intervalCycles", this.intervalConfig);

              this.pageState.next(6);
              this.getDataPolling(this);

            }
          }

          break;

        case 'downloadCyclesRawData':
          try {

            this.pageState.next(3);

            let endpointUrl = "apif/cycle-traceability/download-cycles-raw-data/" + this.machineId;

            let payload = {
              cycles: button?.tableConfig?.rowsFiltered,
              variables: this.variables.completeList,
            };

            let maxCycles = button?.buttonInfos?.maxCycles ?? this.machine.profile?.maxDownloadableCyclesInCycleTraceability ?? this.appConfig.machineRecorder?.maxDownloadableCyclesInCycleTraceability ?? 50;

            if (payload?.cycles?.length > maxCycles) {

              this.internalDataService.openSnackBar(
                this.translate.instant('CYCLE_LIST.DOWNLOAD_CYCLES_RAW_DATA_WARNING', {
                  n: maxCycles
                }),
                'right',
                'bottom',
                4000,
                'x',
                ['warning']
              );

              payload.cycles = payload?.cycles.filter((_, i) => i < maxCycles);

            }

            let intervalStart = this.filterService.parseMoment(this.interval.start, "YYYYMMDDHHmmSS");
            let intervalEnd = this.filterService.parseMoment(this.interval.end, "YYYYMMDDHHmmSS");

            let query = {
              tz: this.machine.timezone,
              fileName: intervalStart + '_' + intervalEnd,//this.translate.instant("CYCLE_LIST.DOWNLOAD_CYCLES_RAW_DATA_FILE_NAME"),
            };

            let resp = await this.apiService.asyncDownload(endpointUrl, payload, query);
            this.apiService.handleDownload(resp);

            this.pageState.next(7);

          } catch (error) {
            console.log(error);

            this.internalDataService.openSnackBar(
              this.translate.instant('CYCLE_LIST.DOWNLOAD_CYCLES_RAW_DATA_RESPONSE_FAIL'),
              'right',
              'bottom',
              4000,
              '',
              ['fail']
            );

            this.pageState.next(7);

          }

          break;

        case 'cycleHeatmapHistory':
          try {
            // this.cacheService.set('selectedCycle', button.row);

            let from = button?.row?.timeStart
            let to = button?.row?.timestamp

            let url: any = [this.machineId, 'sct-heatmap-history'].map((x: any) => "/" + x).join("")
            url += '?from=' + from + '&to=' + to

            this.router.navigateByUrl(url);
          } catch (error) {
            console.log(error);
          }

          break;

        case 'cycleHeatmapCyclesReview':
          try {
            this.cacheService.set('selectedCycles', button.rows)
            // sessionStorage.setItem('selectedCycles', JSON.stringify(button.rows))

            let url: any = [this.machineId, 'sct-heatmap-cycles-review'].map((x: any) => "/" + x).join("")
            this.router.navigateByUrl(url)

          } catch (error) {
            console.log(error)
          }

          break;


        default:
          console.log(button);
          break;

      }
    }
  }

  resetDefaultInterval() {

    this.showResetInterval = false;
    this.cacheService.set("intervalCycles", null);

    this.pageState.next(6);
    this.getDataPolling(this);

  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);

    try { this.cacheService.reset('selectedCycles') } catch (error) { }
  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingProds.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
    try { this.cacheService.set("intervalCycles", null) } catch (error) { console.log(error) }
  }

}