<div fxLayout="row" fxLayoutAlign="space-between center" [ngStyle]="customStyle">
  <div class="AQP-bar-container" [class.big]="kpiProps.big" [class.bigger]="kpiProps.bigger">
    <span class="AQP-label-1" *ngIf="!kpiProps.hideLabel">
      {{kpiProps.label}}
    </span>
    <div class="AQP-bar-background" style="width: 100%; left: 0;" [ngClass]="kpiProps.class"></div>
    <div class="AQP-bar" [ngStyle]="{'width': kpiProps.value + '%', 'background-color': kpiProps.color}"></div>
  </div>
  <span class="AQP-label-2">
    {{ kpiProps.value != null ? kpiProps.value : '-' }}%
  </span>
</div>