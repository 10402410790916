import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { AddEventDialogComponent } from './add-event-dialog/add-event-dialog.component';
import { EditVariableDialogComponent } from './edit-variable-dialog/edit-variable-dialog.component';

@Component({
  selector: 'app-rba-settings',
  templateUrl: './rba-settings.component.html',
  styleUrls: ['./rba-settings.component.scss']
})
export class RbaSettingsComponent implements OnInit, OnChanges, OnDestroy {

  public pageAdmin: any = false;
  public isAllowedUser: any = true;
  public isAllowedUserWrite: any = true;

  loadingData: any;
  errorData: any;

  appConfig: any;
  appInfo: any;
  machineProfiles: any;
  machine: any;
  machineId: any;
  breadcrumb: any;
  public tabs: any;

  settingsList: any;
  settingsData: any;
  settingsVariables: any;

  public availableMachines: any;
  public excludeLine: boolean = true;

  public machineSelectedSub: Subscription;
  public sectionName: any = 'alerts';
  public allowedUser: any = true;

  public isCalendarPresent: boolean;
  public settings: any;
  public settingsUnparsed: any;
  public settingsInitial: any;

  public alertsConfig: any;
  public dropdowns: any;
  public mailRegex: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 8;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAlertsConfig, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getKPIConfigs, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getAllowedVariables, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.getRBA, nextState: 7, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public route: ActivatedRoute,
    public translate: FfTranslateService,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public dialog: MatDialog,
  ) {

    let sidenavItems = this.appConfigService.getSidenavItems;

    this.isCalendarPresent = sidenavItems.find(sItem => (sItem.id == 'planning-calendar')) != null;

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['RBA.TITLE', "RBA_SETTINGS.TITLE"];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUserWrite = _this.internalDataService.getSpecificPermission("mat-sn-config-rw");
    } catch (error) { console.log(error) }

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(["mat-sn-config-r", "mat-sn-config-rw"], 'or');
    } catch (error) { console.log(error) }

    try {
      _this.pageAdmin = _this.internalDataService.getSpecificPermission("smart-notifications-admin");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ALERTS CONFIG

  public getAlertsConfig(_this: any) {

    try {

      let c_alertsConfig: any = _this.cacheService.get('alertsConfig');

      if (c_alertsConfig == null) {

        let fileName = "alertsConfig.json";

        let c_alConfig = _this.cacheService.get(fileName) ?? {};

        if (c_alConfig?.value != null && c_alConfig?.assetId == _this.machineId) {

          _this.cacheService.set('alertsConfig', c_alConfig?.value);
          _this.alertsConfig = c_alConfig?.value;
          _this.dispatcherService.getDispatch(_this, 300);

          return;
        }

        const sources40F = _this.appInfo.sources40F ?? 'assets/config/';
        let url = (sources40F ?? 'config/') + 'alertsConfig.json';
        let query: any = {};

        _this.apiService.sendGetRequest(url, query)
          .pipe(
            retryWhen(_this.apiService.genericRetryStrategy()),
            catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
          )
          .subscribe(
            (data: any) => {

              _this.cacheService.set('alertsConfig', data.body);
              _this.alertsConfig = data.body;
              _this.dispatcherService.getDispatch(_this, 300);

            },
          );
      } else {
        _this.alertsConfig = c_alertsConfig;
        _this.dispatcherService.getDispatch(_this, 300);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET KPI CONFIGS

  public getKPIConfigs(_this: any) {
    try {
      _this.internalDataService.getKPIConfigs(_this, _this.machineId);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ALLOWED VARIABLES

  public getAllowedVariables(_this: any) {

    try {

      let url = '/apif/rba-variables/' + _this.machine.typeId;
      let query: any = null;

      let fileName = null;

      if (_this.appConfig.rbaVariablesFromFile) {

        url = '/apif/get-custom-file';
        fileName = 'rba-variables.json';

        let profileId = null;
        try { profileId = _this.machineProfiles.profiles.find(x => x?.machineIdList?.includes(_this.machineId)).id }
        catch (error) { console.log(error) }

        query = {
          machineId: _this.machineId,
          fromWeb: _this.appInfo?.sources40F != null ? 1 : 0,
          source: _this.appInfo?.sources40F ?? '/assets/config/',
          fileName: fileName,
        };

        if (profileId != null) query.profileId = profileId;

        if (_this.cacheService.get(fileName)?.value != null &&
          _this.cacheService.get(fileName)?.assetId == _this.machineId) {

          let defaultRbaVariables = _this.clonerService.deepClone(_this.cacheService.get(fileName)?.value);
          _this.dropdowns = _this.parseDropdownVariables(_this, defaultRbaVariables);
          _this.dispatcherService.getDispatch(_this, 300);
          return;
        }

      }

      _this.apiService.sendGetRequest(url, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy()),
          catchError(error => {
            error = {
              ...error, ...{
                error: {
                  "customMessage": "Missing file: default-" + fileName,
                  "customCode": "404",
                }
              }
            }
            return _this.internalDataService.parseStandardHTTPError(_this, error);
          }))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            _this.dropdowns = _this.parseDropdownVariables(_this, data.body);
            _this.dispatcherService.getDispatch(_this, 300);

          },
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseDropdownVariables(_this: any, data: any) {
    if (data != null) {

      // Operators
      data.operators = ["\u003C", "\u2264", "=", "\u2265", "\u003E"];

      // Time aggregations
      data.timeAggrs = [
        {
          kpi: "minutes",
          label: this.translate.instant("INTERVAL.MINUTES"),
          disabledIf: "kpiVar"
        },
        {
          kpi: "hours",
          label: this.translate.instant("INTERVAL.HOURS")
        },
      ];

      // Aggregation types
      data.aggregationTypes = [
        {
          kpi: "all",
          label: this.translate.instant("RBA.ALL_VALUES")
        },
        {
          kpi: "mean",
          label: this.translate.instant("RBA.MEAN_VALUE")
        },
      ];

      // Calendar
      try {
        let isEnabledCalendar = _this.filterService.isActiveCalendar(_this.machineId);

        if (_this.isCalendarPresent && isEnabledCalendar) {
          data.timeAggrs.push(
            {
              kpi: "shifts",
              label: this.translate.instant("INTERVAL.SHIFTS")
            }
          );

        }
      } catch (error) { console.log(error) }


      // Time aggregations - add days after shifts (if calendar)
      data.timeAggrs = data.timeAggrs.concat([
        {
          kpi: "days",
          label: this.translate.instant("INTERVAL.DAYS")
        },
        // {
        //   kpi: "weeks",
        //   label: this.translate.instant("INTERVAL.WEEKS")
        // }
      ]);

      // Warning types --> for the moment only warning is used (info is used for cloths monitoring)
      data.warningTypes = [
        {
          kpi: "info",
          label: this.translate.instant("ALERTS.WARNING_TYPES.INFO")
        },
        {
          kpi: "warning",
          label: this.translate.instant("ALERTS.WARNING_TYPES.WARNING")
        },
        {
          kpi: "alarm",
          label: this.translate.instant("ALERTS.WARNING_TYPES.ALARM")
        }
      ];

      if (_this.parameters != null) {
        try {
          data.kpis = _this.clonerService.deepClone(_this.parameters.kpiParameters.list)?.reduce((acc, val) => {
            val.translLabel = this.translate.instant(val.label ?? '-');
            val.kpi = val.kpi ?? val.id;
            acc.push(val);
            return acc;
          }, [])
        }
        catch (error) { console.log(error) }
      }

      // // // // // // // //
      // NUMBERS
      // // // // // // // //

      if (this.appConfig.addCustomVariables) {
        data.numbers = ((this.clonerService.deepClone(data.numbers) as any) ?? [])?.reduce((acc, val) => {
          acc.push({
            kpi: val.id,
            label: `${val.translations?.[this.translate.currentLang]}`,
            unit: val.unit ?? '-',
            multiplier: val.multiplier ?? 1
          })
          return acc;
        }, [])
      }

      else {

        let numericVarsConfig = _this.alertsConfig.find(x => x.alertType == 'numericVar');

        if (Array.isArray(data.numbers) && data.numbers?.length && numericVarsConfig?.parameters?.find(x => x.id == 1)?.type == 'dropdown') {
          data.numbers = data.numbers?.reduce((acc, val) => {
            let varName = val?.variable ?? val;
            acc.push({
              kpi: `${varName}`,
              label: `${this.internalDataService.parseDatapointLabel(varName, null, this.machineId)}`,
              unit: val?.unit ?? '',
            })
            return acc;

          }, []);
        }

        else if (numericVarsConfig?.parameters?.find(x => x.id == 1)?.type == 'dropdown') {
          data.numbers = (Object.entries(data.numbers ?? {}) as any)?.reduce((acc, [key, val]) => {

            val?.forEach(v => {
              acc.push({
                kpi: `${key}.${v}`,
                label: `${this.internalDataService.parseDatapointLabel(key, v, this.machineId)}`,
              })
            });

            return acc;

          }, []);
        }

      }

      // // // // // // // //
      // BOOLEANS
      // // // // // // // //

      if (this.appConfig.addCustomVariables) {
        data.booleans = ((this.clonerService.deepClone(data.booleans) as any) ?? [])?.reduce((acc, val) => {
          acc.push({
            kpi: val.id,
            label: val.translations?.[this.translate.currentLang],
          })
          return acc;
        }, [])
      }

      else {

        let booleanVarsConfig = _this.alertsConfig.find(x => x.alertType == 'boolVar');

        if (Array.isArray(data.booleans) && data.booleans?.length && booleanVarsConfig?.parameters?.find(x => x.id == 1)?.type == 'dropdown') {
          data.booleans = data.booleans?.reduce((acc, val) => {
            let varName = val?.variable ?? val;
            acc.push({
              kpi: `${varName}`,
              label: `${this.internalDataService.parseDatapointLabel(varName, null, this.machineId)}`,
              unit: val?.unit ?? '',
            })
            return acc;

          }, []);
        }

        else if (booleanVarsConfig?.parameters?.find(x => x.id == 1)?.type == 'dropdown') {
          data.booleans = (Object.entries(data.booleans ?? {}) as any)?.reduce((acc, [key, val]) => {

            val?.forEach(v => {
              acc.push({
                kpi: `${key}.${v}`,
                label: `${this.internalDataService.parseDatapointLabel(key, v, this.machineId)}`,
              })
            });

            return acc;

          }, []);
        }

      }

    }
    // console.log({ data });
    return data;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET RBA

  public getRBA(_this: any) {

    try {

      let url = '/apif/rba-config/' + _this.machineId;

      _this.apiService.sendGetRequest(url, null)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy()),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
        )
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            if (!data.body.hasOwnProperty("alerts") || data.body.alerts == null || data.body.alerts.length == 0) {
              data.body.alerts = [];
            }

            try {
              data.body.alerts = data.body.alerts.filter(x => x != null && x.hasOwnProperty('parameters') && x.parameters != null && x.parameters.length > 0)
            } catch (error) {
              console.log(error);
            }

            _this.settingsUnparsed = _this.clonerService.deepClone(data.body);
            _this.settings = _this.parseSettings(_this, data.body);
            _this.settingsInitial = _this.clonerService.deepClone(_this.parseSettings(_this, data.body));

            _this.dispatcherService.getDispatch(_this, 300);

          },
        );


    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseSettings(_this: any, data: any) {

    try {

      let copyData = _this.clonerService.deepClone(data);
      copyData.alerts.forEach((alert: any) => {

        alert.checked = !!+alert.checked;
        alert.deactivationAlert = !!+alert.deactivationAlert;
        alert.useCalendar = !!+alert.useCalendar;

        alert.type = _this.internalDataService.getAlertType(alert.alertType, 'type');
        alert.typeIcon = _this.internalDataService.getAlertIcon(alert.type);

        alert.description = "alerts." + alert.alertType + ".description";
        alert.name = "alerts." + alert.alertType + ".name";
        alert.shiftAggrSelected = false;
        // PARAMETERS
        if (alert.hasOwnProperty('parameters') && alert.parameters.length > 0) {

          alert.parameters.forEach((p: any, pIdx: any) => {
            if (_this.alertsConfig != null && _this.alertsConfig.length > 0) {

              let alertTypeIx = _this.alertsConfig.findIndex((x: any) => x.alertType == alert.alertType);

              if (alertTypeIx != -1) {

                if (_this.alertsConfig[alertTypeIx].addMachineSelectionParameter != null) {
                  alert.addMachineSelectionParameter = _this.alertsConfig[alertTypeIx].addMachineSelectionParameter;
                  if (alert.machines == null || alert.machines.length == 0) alert.machines = [_this.availableMachines.selected];
                }

                let expIdx = _this.alertsConfig[alertTypeIx].parameters.findIndex((x: any) => x.id == p.id);

                if (expIdx != -1) {

                  p = Object.assign(p, _this.alertsConfig[alertTypeIx].parameters[expIdx]);

                  if ((p.type == 'dropdown' || p.type == 'dialog') && p.hasOwnProperty("dropdownType") &&
                    p.dropdownType != null && _this.dropdowns != null &&
                    _this.dropdowns.hasOwnProperty(p.dropdownType)) {
                    // p.valueLabel = ('DATAPOINTS.' + this.machineId + '.' + p.value) == this.translate.instant('DATAPOINTS.' + this.machineId + '.' + p.value) ? null : this.translate.instant('DATAPOINTS.' + this.machineId + '.' + p.value);
                    p.valueLabel = this.internalDataService.parseDatapointLabel(p.value, null, this.machineId);

                    p = Object.assign(p, {
                      list: _this.dropdowns[p.dropdownType]
                    });

                    if (p.paramType == 'AGGR-TYPE') {
                      if (p.value == 'all') p.valueLabel = this.translate.instant('RBA.ALL_VALUES_SHORT').toLowerCase();
                      else if (p.value == 'mean') p.valueLabel = this.translate.instant('RBA.MEAN_VALUE_SHORT').toLowerCase();
                    }
                    else if (p.dropdownType == 'timeAggrs') {
                      let shiftAggr = p.list.find(elem => elem.kpi == 'shifts');
                      if (shiftAggr) shiftAggr.disabled = !alert.useCalendar;
                      if (p.value == 'shifts') alert.shiftAggrSelected = true;

                    }
                  }
                }
              }
            }

            p.label = _this.translate.instant("alerts." + alert.alertType + ".parameters." + p.id + ".label");
            p.description = _this.translate.instant("alerts." + alert.alertType + ".parameters." + p.id + ".description");

            alert.parameters[pIdx] = _this.clonerService.deepClone(p);
          });

          alert.parameters.forEach((p: any) => _this.onVariableChange(alert, p));
          alert.parameters.forEach((p: any) => {
            if (p.value != null && typeof (p.value) == 'number') {
              p.value = (this.filterService.convertUnit(p.unit, p.value * (p?.multiplier ?? 1)).value).toFixed(0);
            }
          });

        }

        // CUSTOM MAILING LIST
        if (this.appConfig?.alerts?.flags?.customMailingList && this.appConfig?.alerts?.flags?.removeGlobalMailingList) {
          alert.isCustomMailingList = true;
          alert.mailingList = alert?.mailingList ?? [];
        };
        if (alert.isCustomMailingList && alert?.mailingList?.length > 0) {
          alert.mailingList = alert?.mailingList?.reduce((acc, val, index) => {
            acc.push({ id: index, value: val });
            return acc;
          }, []);
        }
      });

      if (this.appConfig?.alerts?.flags?.removeGlobalMailingList) {
        copyData.mailingList = [];
      } else if (copyData.hasOwnProperty('mailingList') && copyData.mailingList != null && copyData.mailingList.length > 0) {
        copyData.mailingList = copyData.mailingList.map((mail: any, index: any) => {
          return {
            value: mail,
            id: index
          }
        })
      }
      return copyData;

    } catch (error) {
      console.log(error);
      return {};
    }
  }

  onCalendarChange(alert: any,) {
    alert.parameters.forEach((p: any, pIdx: any) => {

      if ((p.type == 'dropdown' || p.type == 'dialog') && p.hasOwnProperty("dropdownType") &&
        p.dropdownType != null && this.dropdowns != null &&
        this.dropdowns.hasOwnProperty(p.dropdownType)) {
        if (p.dropdownType == 'timeAggrs') {
          let shiftAggr = p.list.find(elem => elem.kpi == 'shifts');
          if (shiftAggr) shiftAggr.disabled = !alert.useCalendar;
          if (p.value == 'shifts') alert.shiftAggrSelected = true;
        }
      }
    });
  }

  onVariableChange(alert: any, param: any) {

    if (param?.paramType == 'KPI' || (param?.paramType == 'DYNAMIC-VAR' && param?.type == 'dropdown')) {
      try {

        let currentKPI = param.list.find((x: any) => (x.kpi ?? x.id) == param.value);

        if (currentKPI != null) {
          if (param?.paramType == 'KPI') param.kpiLabel = this.translate.instant(currentKPI.label ?? '-');
          else if (param?.paramType == 'DYNAMIC-VAR') param.valueLabel = currentKPI.label;
          alert.parameters[2].unit = currentKPI.unit;
          alert.parameters[2].multiplier = currentKPI.multiplier;
        }

      } catch (error) {
        console.log(error);
      }
    }
    else if (param.paramType == 'TIME-AGGR') {
      alert.shiftAggrSelected = alert.parameters[4].value == 'shifts';
    }
    else if (param.paramType == 'AGGR-TYPE') {
      if (alert.parameters[5].value == 'all') alert.parameters[5].valueLabel = this.translate.instant('RBA.ALL_VALUES_SHORT').toLowerCase();
      else if (alert.parameters[5].value == 'mean') alert.parameters[5].valueLabel = this.translate.instant('RBA.MEAN_VALUE_SHORT').toLowerCase();
    }
  }

  openEditVariable(alert: any, parameter: any) {

    if (this.isAllowedUser && this.isAllowedUserWrite) {
      let currentAlert = this.clonerService.deepClone(alert);
      let currentParameter = this.clonerService.deepClone(parameter);

      let addMachineSelection = false;
      try {
        addMachineSelection = this.machine.profile.flags.addMachineSelectionToVariables;
      } catch (error) { }

      const editVariableDialog = this.dialog.open(EditVariableDialogComponent, {
        panelClass: 'ff-dialog',
        width: '40%',
        data: {
          title: this.translate.instant("RBA.EDIT_VARIABLE"),
          addMachineSelection: addMachineSelection,
          machineId: this.machineId,
          currentAlert: currentAlert,
          currentParameter: currentParameter,
        },
      });

      editVariableDialog.afterClosed().subscribe((result: any) => {

        let isClickedSelect = result != null && result != '';
        if (isClickedSelect) {
          parameter.value = result.aspect + '.' + result.variable;
          parameter.valueLabel = this.internalDataService.parseDatapointLabel(result.aspect, result.variable, this.machineId);
          // parameter.valueLabel = ('DATAPOINTS.' + this.machineId + '.' + result.aspect + '.' + result.variable) == this.translate.instant('DATAPOINTS.' + this.machineId + '.' + result.aspect + '.' + result.variable) ?
          //   null : this.translate.instant('DATAPOINTS.' + this.machineId + '.' + result.aspect + '.' + result.variable);
        }

      });
    }

  };

  removeAlert(alertId: any) {

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent,
      {
        panelClass: 'ff-dialog',
        // width: '40%',
        height: 'auto',
        data: {
          title: this.translate.instant("RBA.DELETE_EVENT_CONFIRM"),
        },
      });

    confirmationDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          // console.log(result);

          this.settings.alerts.splice(this.settings.alerts.findIndex((x: any) => x.id == alertId), 1);

        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  addEvent() {

    const addEventDialog = this.dialog.open(AddEventDialogComponent, {
      panelClass: 'ff-dialog',
      width: '50%',
      data: {
        title: this.translate.instant("RBA.ADD_EVENT"),
        machineId: this.machineId,
        alertsConfig: this.clonerService.deepClone(this.alertsConfig),
        dropdowns: this.clonerService.deepClone(this.dropdowns),
        availableMachines: this.availableMachines,
        isCalendarPresent: this.isCalendarPresent,
        machine: this.machine,
        // currentAlert: currentAlert,
        // currentParameter: currentParameter,
      },
    });

    addEventDialog.afterClosed().subscribe((result: any) => {

      // console.log(result);

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) this.settings.alerts.push(result);

    });
  }

  parsePayload(data: any) {
    let copyData: any = this.clonerService.deepClone(data);

    let newData: any = Object.assign({}, {
      mailingList: copyData.mailingList != null && copyData.mailingList.length > 0 ? copyData.mailingList.map((mail: any) => mail.value) : [],
      etag: copyData.etag,
      alerts: [],
    });

    copyData.alerts.forEach((alert: any) => {
      let dataExtended: any = this.clonerService.deepClone(alert);
      alert = {};
      alert.alertType = dataExtended.alertType;
      alert.checked = +dataExtended.checked;
      alert.deactivationAlert = +dataExtended.deactivationAlert;
      alert.useCalendar = +dataExtended.useCalendar;
      alert.id = dataExtended.id;
      alert.parameters = [];
      if (dataExtended.addMachineSelectionParameter && dataExtended.machines != null && dataExtended.machines.length > 0) alert.machines = dataExtended.machines;

      dataExtended.parameters.forEach((p: any) => {
        alert.parameters.push({
          id: p.id,
          value: p.value == undefined ? null : p.multiplier != null ? this.filterService.convertUnit(p.unit, p.value / p.multiplier, true).value : p.value,
          paramType: p.paramType,
          description: p.description,
        });
      });

      try {
        let variableIndex = dataExtended.parameters.findIndex(x => x.id == 1);
        if (variableIndex != -1) {
          let label = dataExtended.parameters[variableIndex].kpiLabel;
          if (label == null) label = dataExtended.parameters[variableIndex].valueLabel;
          if (label == null) label = dataExtended.parameters[variableIndex].label;
          alert.label = label;
        }
      } catch (error) {
        console.log(error);
      }

      try {
        let valueIndex = dataExtended.parameters.findIndex(x => x.id == 3);
        if (valueIndex != -1) {
          let multiplier = dataExtended.parameters[valueIndex].multiplier;
          if (multiplier == null) multiplier = dataExtended.parameters[valueIndex].multiplier;
          if (multiplier != null) alert.multiplier = multiplier;
        }
      } catch (error) {
        console.log(error);
      }

      try {
        let valueIndex = dataExtended.parameters.findIndex(x => x.id == 3);
        if (valueIndex != -1) {
          let unit = dataExtended.parameters[valueIndex].unit;
          if (unit == null) unit = dataExtended.parameters[valueIndex].unit;
          if (unit != null && unit != '-') alert.unit = unit;
        }
      } catch (error) {
        console.log(error);
      }

      // // // // // // // // // //
      // CUSTOM MAILING LIST
      // // // // // // // // // //
      if (dataExtended?.isCustomMailingList) {
        alert.isCustomMailingList = true;
        alert.mailingList = dataExtended?.mailingList?.reduce((acc, val) => {
          acc.push(val.value);
          return acc;
        }, []);
      }

      // // // // // // // // // //
      // CUSTOM TEXT
      // // // // // // // // // //
      if (dataExtended?.isCustomText) {
        alert.isCustomText = true;
        alert.customText = dataExtended?.customText;
      }

      // // // // // // // // // //
      // ADMIN ALERT
      // // // // // // // // // //
      if (this.appConfig?.alerts?.flags?.alertsWithPermissions) {
        if (dataExtended?.isAdmin) alert.isAdmin = 1;
        else alert.isAdmin = 0;
      }

      newData.alerts.push(alert);

    });

    return newData;
  }

  // UPDATE SETTINGS
  updateSettings() {

    // this.pageState.next(4);

    let url = '/apif/rba-config/' + this.machineId;
    let payload = this.parsePayload(this.settings);

    console.log(payload);

    let _this = this;
    try {

      this.apiService.sendPutRequest(url, payload, null)
        .pipe(
          retryWhen(this.apiService.genericRetryStrategy()),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
        )
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            if (!data.body.hasOwnProperty("alerts") || data.body.alerts == null || data.body.alerts.length == 0) {
              data.body.alerts = [];
            }

            try {
              data.body.alerts = data.body.alerts.filter(x => x != null && x.hasOwnProperty('parameters') && x.parameters != null && x.parameters.length > 0)
            } catch (error) {
              console.log(error);
            }

            _this.settingsUnparsed = _this.clonerService.deepClone(data.body);
            _this.settings = _this.parseSettings(_this, data.body);
            _this.settingsInitial = _this.clonerService.deepClone(_this.parseSettings(_this, data.body));

            _this.pageState.next(8);

          },
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      this.dispatcherService.getDispatch(this, 301, errorData);
    }
  }

  // ADD MAIL
  addMail(mailingList: any) {
    mailingList.push({
      id: mailingList.length,
      value: ""
    });
  }

  // DELETE MAIL
  deleteMail(mailingList: any, index: any) {
    mailingList.splice(index, 1);
  };

  checkUpdates(checkExit?: any) {
    try {
      // console.log('checkUpdates');

      // Check alert settings
      let isChangedAlerts = null;
      let isInvalidAlerts = !this.settings.alerts.every((al: any) => al.parameters.every((p: any) => p.notRequired || (p.value != null && p.value !== '' && p.value != undefined)));
      if (!isInvalidAlerts || checkExit) {
        if (this.settingsUnparsed.alerts.length == this.settings.alerts.length) {
          isChangedAlerts = !this.settings.alerts.every((al: any, alIdx: any) => {

            // Check that all the parameters value are the same
            let paramCheck = al.parameters.every((p: any, pIdx: any) => this.settingsUnparsed.alerts[alIdx].parameters[pIdx].value == (p.multiplier != null ? p.value / p.multiplier : p.value));

            // Check that all the alerts check are the same of the initial value (enabled or disabled)
            let checkedAlertsCheck = (al.checked == this.settingsUnparsed.alerts[alIdx].checked || al.checked == !!+this.settingsUnparsed.alerts[alIdx].checked);

            // Check that all the deactivation flags are the same of the initial value (enabled or disabled)
            let deactivationFlagCheck = (al.deactivationAlert == this.settingsUnparsed.alerts[alIdx].deactivationAlert || al.deactivationAlert == !!+this.settingsUnparsed.alerts[alIdx].deactivationAlert);

            // Check that all the calendar flags are the same of the initial value (enabled or disabled)
            let useCalendarCheck = (al.useCalendar == this.settingsUnparsed.alerts[alIdx].useCalendar || al.useCalendar == !!+this.settingsUnparsed.alerts[alIdx].useCalendar);

            // Check if the list of machines associated with the alert is changed
            let machinesListCheck = true;

            // If the machine list for the specific alert is absent or empty, avoid checking
            if (al.machines == null || al.machines?.length == 0) machinesListCheck = true;
            // Otherwise check
            else {
              machinesListCheck = al.machines.length == this.settingsUnparsed.alerts[alIdx].machines.length &&
                al.machines.every(m =>
                  (this.settingsUnparsed.alerts[alIdx].machines != null && this.settingsUnparsed.alerts[alIdx].machines.length > 0)
                    ? this.settingsUnparsed.alerts[alIdx].machines.includes(m)
                    : true
                );
            }

            // Check if the list of mail associated with the alert is changed
            let customMailingListCheck = true;

            // If the custom mailing list for the specific alert is absent or empty, avoid checking
            if (!al.isCustomMailingList) customMailingListCheck = true;
            // Otherwise check
            else {
              // if (al.mailingList == null || al.mailingList.length == 0) customMailingListCheck = false;
              // else {
              customMailingListCheck = al?.mailingList?.length == this.settingsUnparsed.alerts?.[alIdx]?.mailingList?.length &&
                al.mailingList.every(m =>
                  (this.settingsUnparsed.alerts[alIdx].mailingList != null && this.settingsUnparsed.alerts[alIdx].mailingList.length > 0)
                    ? this.settingsUnparsed.alerts[alIdx].mailingList.includes(m?.value)
                    : true
                );
              // }
            }

            // Check if the custom text associated with the alert is changed
            let customTextCheck = true;

            // If the custom mailing list for the specific alert is absent or empty, avoid checking
            if (!al.isCustomText) customTextCheck = true;
            else customTextCheck = al.customText == this.settingsUnparsed.alerts[alIdx].customText;

            return paramCheck && checkedAlertsCheck && deactivationFlagCheck && useCalendarCheck && machinesListCheck && customMailingListCheck && customTextCheck;
          }
          )
        } else {
          isChangedAlerts = true;
        }
      } else {
        isChangedAlerts = false;
      }

      // Check Mailing List
      let isChangedMail = null;
      let isInvalidMail = !this.settings.mailingList.every((mail: any) => mail.value != null && mail.value != '' && RegExp(this.mailRegex, 'g').exec(mail.value));
      if (!isInvalidMail || checkExit) {
        if (this.settingsUnparsed.mailingList.length == this.settings.mailingList.length) {
          isChangedMail = !this.settings.mailingList.every((mail: any, mailIdx: any) => mail.value == this.settingsUnparsed.mailingList[mailIdx]);
        }
        else {
          isChangedMail = true;
        }
      } else {
        isChangedMail = false;
      }

      return isChangedAlerts == false && isChangedMail == false;

    } catch (error) {
      return true;
    }
  }

  canDeactivate() {
    return this.checkUpdates(true);
  }

  exists(item: any, list: any) {
    return list.filter((x: any) => x == item).length > 0;
  };

  toggle(item: any, list: any) {

    var idx = list.findIndex((x: any) => x == item);
    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(item);
    }
    // this.checkAllSelected();
  };

  toggleCustomMailingList(alert) {

    if (this.appConfig?.alerts?.flags?.customMailingList == null) return;

    if (alert.isCustomMailingList == null) {
      alert.isCustomMailingList = true;
      alert.mailingList = alert.mailingList ?? [];
      return;
    }
    alert.isCustomMailingList = !alert.isCustomMailingList;
    if (alert.isCustomMailingList == true) {
      alert.mailingList = alert.mailingList ?? [];
    }

  };

  searchFromList(searchEvent, parameter) {
    parameter.availableOptions = searchEvent.target.value;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  

  ngOnInit(): void {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  ngOnDestroy() {
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
  }

}
