<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
  <!-- utils -->
  <!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->
</div>

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar -->
  <div fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">
    <!-- tabs -->
    <ff-tabs [tabs]="tabs"></ff-tabs>
  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start center" class="tab-content">

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < pageStateReady" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <!-- <div fxLayout="row" fxLayoutAlign="start center" class="w100">
      
      <span fxFlex></span>

      <button *ngIf="isComparedCycle" mat-button (click)="removeComparedCycle()" class="filter-button md-red-i"
        fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="icon">cancel</mat-icon>
        {{ 'PROCESS_LOG.REMOVE_COMPARED_CYCLE' | translate }}
      </button>
  
      <button mat-button (click)="openCycleComparison()" class="filter-button" fxLayout="row"
        fxLayoutAlign="center center">
        {{ 'CYCLE_TIMELINE.PARAMETER_COMPARISON' | translate }}
        <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': 'PROCESS_LOG.CYCLE_COMPARISON'}"
          style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
        </mat-icon>
      </button>
    </div> -->

    <!-- chart -->
    <div fxLayout="row wrap" class="w100" fxLayoutGap="8px grid">
      <div *ngFor="let phases of allPhases" fxFlex="33.3" fxFlex.gt-lg="25">
        <!-- <pre>{{ phases.phaseInfos | json }}</pre> -->
        <div fxLayout="column" *ngIf="pageState.value >= 5 && phases.phases.list.length > 0"
          style="border-radius: 25px; background-color: #fff; margin-top: 12px; padding-bottom: 16px;">

          <div class="phase-data-title" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon *ngIf="!phases.phases.expanded" svgIcon="curtain_down" class="icon left clickable"
              (click)="phases.phases.expanded = !phases.phases.expanded">
            </mat-icon>
            <mat-icon *ngIf="phases.phases.expanded" svgIcon="curtain_up" class="icon left clickable"
              (click)="phases.phases.expanded = !phases.phases.expanded"></mat-icon>
            <span>{{ phases.phaseName }}</span>
            <div
              style="margin-left: 12px; border-radius: 50px; height: 20px; width: 20px; border: 1px solid rgba(0, 0, 0, 0.2);"
              [ngStyle]="{'background-color': phases.phaseColor}">
            </div>
          </div>

          <table mat-table *ngIf="phases.phases.expanded" [dataSource]="phases.phaseData" matSort>

            <ng-container matColumnDef="icon">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let phase" class="td-icon">
                <div class="box-icon md-primary">
                  <!-- [ngClass]="{ 'md-gray-i-2': phase.timeEnd != null, 'md-red-i': phase.timeEnd == null }"> -->
                  <mat-icon class="icon" aria-label="phase">info</mat-icon>
                  <!-- <mat-icon class="icon" svgIcon="{{.icon}}" aria-label="phase"></mat-icon> -->
                  <!-- <i class="icon {{.icon}}" aria-label="phase"></i> -->
                </div>
              </td>
            </ng-container>

            <ng-container *ngFor="let info of phases.phaseInfos" matColumnDef="{{info.variable}}">
              <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>
                {{info.label|translate}}
              </th>
              <td mat-cell *matCellDef="let phase">
                <span *ngIf="info.prefix != null">{{info.prefix}}&nbsp;</span>
                <span>{{(phase[info.variable] != null ? phase[info.variable] : '-')}}</span>
                <span *ngIf="info.suffix != null">&nbsp;{{info.suffix}}</span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="phases.phaseColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: phases.phaseColumns;"></tr>
          </table>
          <!-- <mat-paginator [length]="phases.list.length" [pageSizeOptions]="phases.pageOptions" [pageSize]="phases.pageSize"
          showFirstLastButtons></mat-paginator> -->

        </div>
      </div>
    </div>

  </div>
</div>