import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-ingenya-image-dialog',
  templateUrl: './ingenya-image-dialog.component.html',
  styleUrls: ['./ingenya-image-dialog.component.scss']
})
export class IngenyaImageDialogComponent implements OnInit, OnDestroy {

  image: any;
  noImage: boolean = false;
  customActionButtons: any = [];

  clonedRow: any;
  changedUserLabels: any = [];
  state: any = 1;

  zoomLevel: any = 1;
  minZoom: any = 1;
  maxZoom: any = 20;
  zoomStep: any = 0.5;

  sliderConf: any;

  imageWidth: any = 600;
  imageHeight: any = 300;

  appConfig: any;

  sliderConfiguration: any = {
    label: "IMAGES_LABELLING.ZOOM",
    default: this.zoomLevel,
    min: this.minZoom,
    max: this.maxZoom,
    step: this.zoomStep,
    translate: {
      unit: "x"
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public appConfigService: AppConfigService,
    public dialogRef: MatDialogRef<IngenyaImageDialogComponent>,
    public http: ApiService,
    public intervalService: IntervalService,
    public clonerService: ClonerService,
    public translate: FfTranslateService,
    public apiService: ApiService,
    public filterService: FiltersService,
    public cacheService: CacheService,
    public internalDataService: InternalDataService,
    public additionalDialog: MatDialog,
    public router: Router,
  ) { }

  ngOnInit() {

    this.appConfig = this.appConfigService.getAppConfig;
    this.customActionButtons = this.dialog.customActionButtons;

    this.imageWidth = this.dialog.profile?.ingenya?.dimensions?.width ?? this.appConfig?.ingenya?.imagesLabelling?.dimensions?.width ?? this.imageWidth;
    this.imageHeight = this.dialog.profile?.ingenya?.dimensions?.height ?? this.appConfig?.ingenya?.imagesLabelling?.dimensions?.height ?? this.imageHeight;
    this.initImage();

    this.initSlider(this);

    try {
      let closedLists = this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'closedList' && !x.listFromBE);
      closedLists = closedLists?.reduce((acc, info) => {
        if (info?.type != 'configFromProfile' || this.dialog.profile == null) {
          acc.push(info);
          return acc;
        }

        let list = this.clonerService.deepClone(this.dialog.profile?.[info?.mappingConfig?.key ?? 'timeStates']);

        this.parseObjectTypeClosedList(info, list);
        acc.push(info);

        return acc;
      }, []);
      closedLists?.filter(x => x?.listOptions?.length == 1).forEach(list => this.dialog.row[list.variable] = list.listOptions[0]);
    } catch (error) { console.log(error) }

    this.getClosedListValuesFromBE(this?.dialog?.tableInfos);
  }

  initImage() {

    this.clonedRow = this.clonerService.deepClone(this.dialog.row ?? {});

    let imageConfig = this.dialog.tableInfos?.find(x => x.variable == 'image');

    try {

      // Image name directly in config
      if (imageConfig?.imageRegex == null) this.image = this.dialog.row[imageConfig.imageVariable];
      // Image with additional regexes
      else this.image = imageConfig.imageRegex?.replace("{image}", this.dialog.row[imageConfig.imageVariable]);

    } catch (error) { console.log(error) }


  }
  adjustZoom() {
    // console.log(this.sliderConf);

    let img = document.getElementById("image");

    try { img.style.transform = "scale(" + this.sliderConf?.min + ")" }
    catch (error) { console.log(error) }

    let imageContainter = document.getElementById("imageContainter");

    // try { imageContainter.style.overflow = "unset" }
    // catch (error) { console.log(error) }
    try { imageContainter.style.opacity = "0.99" }
    catch (error) { console.log(error) }

    setTimeout(() => {
      try { imageContainter.style.overflow = "auto" }
      catch (error) { console.log(error) }
      try { imageContainter.style.opacity = "1" }
      catch (error) { console.log(error) }
    }, 100);

  }


  advancedTranslate(config, item) {
    try {
      let conf = config ?? {};

      let stringToCheck = item;
      if (typeof item == 'object') stringToCheck = item?.[conf?.aspect];

      return this.internalDataService.parseLabelWithAssetId(conf?.key, stringToCheck, conf?.variable, this.cacheService.get("machineId"));
    } catch (error) {
      return config?.key ?? '-';
    }
  }

  mapFromProfile(info: any, item: any, isCalculatedVariable = false) {
    let val = isCalculatedVariable ? item : item?.[info?.variable];
    let v = this.dialog.profile?.[info?.mappingConfig?.key ?? 'timeStates']?.find(x => x?.[info?.mappingConfig?.idKey ?? 'id'] == val)?.[info?.mappingConfig?.outputKey ?? 'label'];
    return info?.mappingConfig?.translate ? this.translate.instant(v ?? '-') : v;
  }

  initSlider(_this: any, list?: any) {

    try {

      let max = _this.sliderConfiguration?.max ?? 0;
      // if (list?.length > 0 && _this.sliderConfiguration?.upperVariable) {
      //   max = list?.sort(_this.filterService.sortByProperty(_this.sliderConfiguration?.upperVariable ?? 'timeEndHours', 'desc', true))?.[0]?.[_this.sliderConfiguration?.upperVariable];
      // }

      let translateConfig = _this.sliderConfiguration?.translate;
      _this.sliderConf = {
        show: true,
        // max: _this.sliderConfiguration.max,
        min: _this.sliderConf?.min != null && !isNaN(_this.sliderConf.min) ? _this.sliderConf.min : _this.sliderConfiguration?.default,
        options: {
          floor: _this.sliderConfiguration?.min ?? 0,
          ceil: max,
          step: _this.sliderConfiguration?.step ?? 1,
          translate: (index) => {
            // return _this.filterService.parseGaugeValue(_this.filterService.convertUnit(translateConfig?.unit, index).value, translateConfig?.decimals ?? 0, translateConfig?.multiplier ?? 1) + (_this.filterService.convertUnit(translateConfig?.unit).unit ?? '') 
            try { return _this.filterService.parseObjFromConfig(index, translateConfig, true, 'type', translateConfig?.unit != null, true) }
            catch (error) { return index }
          },
        },
        userChangeEnd: () => _this.adjustZoom()
      }
    } catch (error) { console.log(error) }

  }

  buttonAction(item) {
    console.log(item);
    let img = document.getElementById("image");

    switch (item?.buttonInfos?.clickFunction) {
      case 'zoomIn':
        this.zoomLevel = this.zoomLevel >= this.maxZoom ? this.maxZoom : this.zoomLevel + 0.5;
        img.style.transform = "scale(" + this.zoomLevel + ")";
        break;
      case 'zoomOut':
        this.zoomLevel = this.zoomLevel <= this.minZoom ? this.minZoom : this.zoomLevel - 0.5;
        img.style.transform = "scale(" + this.zoomLevel + ")";
        break;
      case 'downloadImage':
        let link = document.createElement('a');
        link.href = this.image;
        link.download = this.dialog.row.id;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        break;
      case 'navigateToMachineRecorder':
        this.navigateToMachineRecorder();
        break;
    }

  }

  navigateToMachineRecorder() {

    let currentTimestamp = this.dialog.row.timestamp;

    let timeStart = moment(currentTimestamp).subtract(30, 's').format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    let timeEnd = moment(currentTimestamp).add(30, 's').format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

    let condAnalObj = {
      from: timeStart,
      to: timeEnd,
    }
    this.cacheService.set('condAnalFromTo', condAnalObj)
    this.router.navigate([this.cacheService.get("machineId") + '/machine-recorder/continuous-exploration']);

    this.dialogRef.close();
  }

  ngOnDestroy() { }

  checkDisability() {
    let tableInfosCheck = !this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x.requiredInDialog).every(x => (this?.dialog?.row?.[x?.variable] != null && this?.dialog?.row?.[x?.variable] != ''));
    return tableInfosCheck;
  }


  parseObjectTypeClosedList(info: any, data: any, nestedKeyLabel: any = null) {

    info.listOptions = data?.filter(x => {

      // If element is not object, don't filter
      if (typeof x != 'object') return true;

      // If object doesn't have key "filterForSpecificVariableAndValue", don't filter
      if (info?.filterForSpecificVariableAndValue == null) return true;

      // Return only elements that have value associated to the specified "variable" equal to the specified "value"
      return x?.[info?.filterForSpecificVariableAndValue?.variable] == info?.filterForSpecificVariableAndValue?.value;
    })
      // Map on nestedKey if present
      .map(x => info?.nestedKeyToMap ? this.translate.instant(x?.[info?.nestedKeyToMap] ?? '-') : x) ?? [];

    if (info?.nestedKeysToKeep?.length > 0) {
      info.listOptionsDict = data?.reduce((acc, val) => {

        if (typeof val == 'object') {
          let obj: any = {}

          info?.nestedKeysToKeep?.forEach(k => {
            if (typeof k == 'object') {
              // If "primaryKey", substitute the id in the incoming object with the variable from info
              if (k?.primaryKey) obj[info.variable] = this.translate.instant(val[k.id] ?? '-');
              // Else map the newId (if present) with the correct value
              else obj[k?.newId ?? k?.id] = val[k.id];
            }
            else obj[k] = val[k];
          });
          acc.push(obj);
        }

        return acc;
      }, []);
      console.log(info.listOptionsDict);
      if (nestedKeyLabel != null) this.changedClosedListElem(null, this.dialog.row?.[nestedKeyLabel]?.[0], info);
    }
  }

  changedClosedListElem(event, item, info) {
    if (info?.dialogEditType == 'closedList' && info?.listFromBE && info?.nestedKeysToKeep?.length > 0) {
      let ix = info?.listOptionsDict?.findIndex(x => x?.[info?.variable] == item?.[info?.variable]);
      try {
        if (ix != -1) Object.entries(info?.listOptionsDict[ix])?.forEach((kv: any) => item[kv[0]] = kv[1]);
      } catch (error) { console.log(error) }
      // console.log(item, this.dialog.row);
    }

  }


  getClosedListValuesFromBE(tableInfos: any, nestedKeyLabel: any = null) {

    try {

      let closedLists = tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'closedList' && x?.listFromBE);

      if (closedLists?.length > 0) {

        let actIndex = 0;
        closedLists?.forEach((info, index) => {

          (async () => {
            info.endpointUrl = info?.endpointUrl?.replaceAll("{machineId}", this.dialog.machineId);

            let endpointUrl = info.endpointUrl;

            let query: any = {
              tz: this.dialog?.machine?.timezone
            };

            let payload: any = {}

            if (info?.filterBE != null) {
              let obj: any = {
                id: info?.filterBE,
                values: [this.dialog?.row?.[info?.filterBE]]
              };
              payload.filter = obj;
            }

            try {

              let method = 'POST';
              if (endpointUrl.includes(':')) {
                endpointUrl = endpointUrl.split(':');
                method = endpointUrl[0];
                endpointUrl = endpointUrl[1];
              }

              let requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
              if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
              else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

              const data: any = await requestSent.toPromise();

              //------------------------------------------------------------------------//
              // RESPONSE PARSING

              let copyData: any = this.clonerService.deepClone(data?.body ?? []);

              actIndex++;

              this.parseObjectTypeClosedList(info, copyData, nestedKeyLabel);

              if (actIndex == closedLists.length) this.state = 1;
              imageZoom("image", "myresult");

            } catch (error) {

              actIndex++;
              info.listOptions = [];
              if (actIndex == closedLists.length) this.state = 1;
              imageZoom("image", "myresult");

            }

          })();

        })
      } else {
        this.state = 1;
        setTimeout(() => imageZoom("image", "myresult"), 200);
      }
    } catch (error) { console.log(error) }
  }

  selectionChange(userLabel) {

    let index = this.changedUserLabels?.findIndex(x => x.timestamp == this.clonedRow.timestamp);

    let copyNewRow: any = this.clonerService.deepClone(this.dialog.row);

    // User label changed respect to initial value
    if (userLabel != this.clonedRow.labelUser) {
      // If the element was not present, add it to the list
      if (index == -1) this.changedUserLabels.push(copyNewRow);
      // If the element was already present, replace the previous value
      else this.changedUserLabels[index] = copyNewRow;
    }

    // User label  same as initial value
    else {
      // If the value was already changed, remove it from the list
      if (index > -1) this.changedUserLabels.splice(index, 1);
    }

    console.log(this.changedUserLabels);

  }

  previousImage() {
    this.state = 0;

    setTimeout(() => {

      try {
        let index = this.dialog.completeTable?.findIndex(x => x.timestamp == this.dialog.row.timestamp);
        if (index != -1) {
          index -= 1;
          if (index < 0) index = this.dialog.completeTable.length - 1;
          this.dialog.row = this.dialog.completeTable[index];

          this.initImage();
        }

        this.state = 1;

        setTimeout(() => {
          try { imageZoom("image", "myresult") } catch (error) { }
        }, 100);

      } catch (error) { console.log(error) }

    }, 100);
  }

  nextImage() {
    this.state = 0;

    setTimeout(() => {

      try {
        let index = this.dialog.completeTable?.findIndex(x => x.timestamp == this.dialog.row.timestamp);
        if (index != -1) {
          index += 1;
          if (index > this.dialog.completeTable.length - 1) index = 0;
          this.dialog.row = this.dialog.completeTable[index];

          this.initImage();
        }

        this.state = 1;

        setTimeout(() => {
          try { imageZoom("image", "myresult") } catch (error) { }
        }, 100);

      } catch (error) { console.log(error) }

    }, 50);
  }

  async confirmUpdateImages() {

    const confirmationDialog = this.additionalDialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant('IMAGES_LABELLING.CONFIRM_UPDATE_IMAGES', {
          n: this.changedUserLabels?.length ?? 0
        }),
      },
    });

    confirmationDialog.afterClosed().subscribe(async (result: any) => {
      if (result != null && result != '') await this.updateImage();
    });
  }

  async updateImage() {

    if (this.changedUserLabels?.length == 0) {

      this.internalDataService.openSnackBar(
        this.translate.instant('No image changed!'),
        'right',
        'bottom',
        4000,
        '',
        ['fail']
      );

      return;
    }

    this.state = 0;
    let resp = null;
    let responseCode = 200;

    try {
      resp = await this.apiService.sendPostRequestAwait('/apif/ingenya/edit-images/' + this.dialog?.machineId, this.changedUserLabels);
      responseCode = resp.status;
    } catch (error) {
      console.log(error);
      responseCode = error.status;
    }

    this.internalDataService.openSnackBar(
      this.translate.instant('IMAGES_LABELLING.UPDATE_IMAGE_RESPONSE.' + (responseCode == 200 ? 'SUCCESS' : 'FAIL')),
      'right',
      'bottom',
      4000,
      '',
      [responseCode == 200 ? 'success' : 'fail']
    );

    this.dialogRef.close({});
  }

  async closeDialog() {
    this.dialogRef.close(this.dialog?.row);
  }

}


function imageZoom(imgID, resultID) {
  var img, lens, result, cx, cy;
  img = document.getElementById(imgID);
  result = document.getElementById(resultID);
  /*create lens:*/
  lens = document.createElement("DIV");
  lens.setAttribute("class", "img-zoom-lens");
  /*insert lens:*/
  img.parentElement.insertBefore(lens, img);
  /*calculate the ratio between result DIV and lens:*/
  cx = result.offsetWidth / lens.offsetWidth;
  cy = result.offsetHeight / lens.offsetHeight;
  /*set background properties for the result DIV:*/
  result.style.backgroundImage = "url('" + img.src + "')";
  result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
  /*execute a function when someone moves the cursor over the image, or the lens:*/
  lens.addEventListener("mousemove", moveLens);
  img.addEventListener("mousemove", moveLens);
  /*and also for touch screens:*/
  lens.addEventListener("touchmove", moveLens);
  img.addEventListener("touchmove", moveLens);
  function moveLens(e) {
    var pos, x, y;
    /*prevent any other actions that may occur when moving over the image:*/
    e.preventDefault();
    /*get the cursor's x and y positions:*/
    pos = getCursorPos(e);
    /*calculate the position of the lens:*/
    x = pos.x - (lens.offsetWidth / 2);
    y = pos.y - (lens.offsetHeight / 2);
    /*prevent the lens from being positioned outside the image:*/
    if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
    if (x < 0) { x = 0; }
    if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
    if (y < 0) { y = 0; }
    /*set the position of the lens:*/
    lens.style.left = x + "px";
    lens.style.top = y + "px";
    /*display what the lens "sees":*/
    result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
  }
  function getCursorPos(e) {
    var a, x = 0, y = 0;
    e = e || window.event;
    /*get the x and y positions of the image:*/
    a = img.getBoundingClientRect();
    /*calculate the cursor's x and y coordinates, relative to the image:*/
    x = e.pageX - a.left;
    y = e.pageY - a.top;
    /*consider any page scrolling:*/
    x = x - window.pageXOffset;
    y = y - window.pageYOffset;
    return { x: x, y: y };
  }
}