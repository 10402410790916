import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomSearchFilterPipe } from 'src/app/pipes/custom-search-filter.pipe';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'app-component-variables-selection',
  templateUrl: './component-variables-selection.component.html',
  styleUrls: ['./component-variables-selection.component.scss']
})
export class ComponentVariablesSelectionComponent implements OnInit {

  public maxItemsSelectable: any = 12;
  public maxLimit: any = 40;
  public allShownTraces: any = 0;
  public showOnlyTraces: boolean = false;
  public machineId: any;
  public appConfig: any;
  public showTraceName: any = false;

  orderBy: any = "order";
  availableOptions: any;
  availableOptionsVariablesConfig: any = ["id", "label", "unit", "group"];
  customSearchFilterPipe: CustomSearchFilterPipe;
  maxLimitReached: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public translate: FfTranslateService,
    public internalDataService: InternalDataService,
    public cacheService: CacheService,
    public filterService: FiltersService,
    public appConfigService: AppConfigService,
  ) {

    this.appConfig = this.appConfigService.getAppConfig;

    this.maxItemsSelectable = this.appConfig?.machineRecorder?.maxNumberOfSelectableVariables ?? this.maxItemsSelectable;

    if (this.maxItemsSelectable > this.maxLimit) {
      console.warn(`The maximum number of variables to be shown in the plot cannot be higher than ${this.maxLimit}! Please change the value of the variable \"appConfig.machineRecorder.maxNumberOfSelectableVariables\"`);
      this.maxItemsSelectable = this.maxLimit;
    }

    if (this.dialog != null && this.dialog.hideComponents) this.showOnlyTraces = true;

    if (this.cacheService.get("machineId") != null) this.machineId = this.cacheService.get("machineId");

    this.showTraceName = this.appConfig?.showUnparsedTracesNamesInComponentsVariablesSelection;

    this.customSearchFilterPipe = new CustomSearchFilterPipe(this.filterService);
  }

  changeComponentSelected(comp: any) {
    this.dialog.componentId = comp.key;
    try {
      Object.entries(this.dialog.groups).forEach((pc: any) => {
        let compId = pc[0];
        let compValues = pc[1];

        if (compId != comp.key) compValues.plotsConfig.list.forEach((group: any) => this.toggleGroup(group, true));

      });
    } catch (error) {
      console.log(error);
    }
    try {
      if (comp.value.plotsConfig.list.some((group: any) => group.show)) {
        if (comp.value.plotsConfig.list.every((group: any) => group.show)) {
          comp.value.plotsConfig.list.forEach((group: any) => this.toggleGroup(group, true));
        } else {
          comp.value.plotsConfig.list.forEach((group: any) => this.toggleGroup(group, null, true));
        }
      } else {
        comp.value.plotsConfig.list.forEach((group: any) => this.toggleGroup(group, null, true));
      }
    } catch (error) {
      console.log(error);
    }

    this.recalcShownTraces();
  }

  toggleGroupExpansion(group: any) {
    group.expanded = group.expanded != null ? !group.expanded : true;
  }

  toggleTrace(group: any, trace: any) {
    trace.show = trace.show != null ? !trace.show : true;
    group.show = group.traces.some((x: any) => x.show);
    group.shownTraces = group.traces.filter((x: any) => x.show).length;
    this.recalcShownTraces();
  }

  toggleGroup(group: any, disableAll?: any, enableAll?: any) {

    // Show all traces if enable all is specified
    if (enableAll) group.show = true;
    else group.show = disableAll != null ? false : (group.show != null ? !group.show : true);

    let filteredVariables = this.customSearchFilterPipe.transform(
      group.traces,
      this.availableOptions,
      this.availableOptionsVariablesConfig,
    );
    group.traces.forEach((trace: any) => {

      if (filteredVariables.findIndex(x => x.id == trace.id) != -1) trace.show = group.show;

      // If the variable is not in the available ones (after search) do not select it
      else trace.show = false;

    });
    group.shownTraces = group.traces.filter((x: any) => x.show).length;

    this.recalcShownTraces();
  }

  recalcShownTraces() {
    try {

      let shownTraces = 0;
      this.allShownTraces = 0;
      this.maxLimitReached = false;

      if (this.dialog.groups?.[this.dialog.componentId]?.expanded == null) this.dialog.groups[this.dialog.componentId].expanded = true;
      this.dialog.groups[this.dialog.componentId].plotsConfig.list = this.dialog.groups?.[this.dialog.componentId]?.plotsConfig?.list?.reduce((groups, group) => {

        group.traces = group.traces?.reduce((traces, trace) => {
          if (shownTraces >= this.maxItemsSelectable) {
            trace.show = false;
            this.maxLimitReached = true;
          }
          if (trace.show) shownTraces++;
          traces.push(trace);
          return traces;
        }, []);

        group.shownTraces = group.traces.filter((x: any) => x.show).length;

        if (group.shownTraces == 0) group.show = false;

        this.allShownTraces += group.shownTraces;

        groups.push(group);
        return groups;
      }, []) ?? [];

    } catch (error) {
      console.log(error);
    }
  }

  checkGroupDisabled(group) {
    if (group.show) return false;
    if (this.maxLimitReached) return true;
    return false;
  }

  checkTraceDisabled(group, trace) {
    if (trace.show) return false;
    if (this.maxLimitReached) return true;
    return false;
  }

  ngOnInit(): void {
    this.recalcShownTraces();
  }

  searchFromList(searchEvent) {
    this.availableOptions = searchEvent.target.value;
  }

  sortFunction = (a, b) => {
    if (!a.value[this.orderBy] && !b.value[this.orderBy]) {
      // if neither has an [this.orderBy] property, sort by key in ascending [this.orderBy]
      return a.key.localeCompare(b.key);
    } else if (!a.value[this.orderBy]) {
      // if a has no [this.orderBy] property, put it last
      return 1;
    } else if (!b.value[this.orderBy]) {
      // if b has no [this.orderBy] property, put it last
      return -1;
    } else {
      // sort by [this.orderBy] property in ascending[this.orderBy]
      return a.value[this.orderBy] - b.value[this.orderBy];
    }
  }

  ngOnDestroy() {}

}
