<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

  </div>

  <div *ngIf="isMobile" fxFlex fxLayout="column" class="tab-content" [ngClass]="{ 'without-tabs': tabs.length <= 1}"
    fxLayoutAlign="center center">
    <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
  </div>

  <!-- tab content -->
  <div *ngIf="!isMobile" fxFlex fxLayout="column" class="tab-content" [ngClass]="{ 'without-tabs': tabs.length <= 1}"
    fxLayoutAlign="{{ pageState.value < 8 || pageState.value == 0 ? 'center center' : 'start start' }}">

    <!-- error -->
    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 8" [loadingData]="loadingData">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 8 && shiftTemplates.length == 0" fxLayout="row" fxLayoutAlign="center center"
      class="w100" style="min-height: 100px;">
      <div class="not-found" fxLayout="row" fxLayoutAlign="center center">
        <span>{{ 'SHIFT_TEMPLATES.NOT_FOUND' | translate }}</span>
      </div>
    </div>

    <div *ngFor="let shiftTemplate of shiftTemplates" class="w100">
      <div *ngIf="shiftTemplate != null && shiftTemplate.shifts != null && shiftTemplate.shifts.length > 0"
        class="shift-template">

        <!-- accordion title -->
        <mat-card fxLayout.gt-md="row" fxLayout="column">

          <!-- title -->
          <div fxLayout="row" fxLayoutAlign="start center" class="clickable"
            (click)="shiftTemplate.hide = !shiftTemplate.hide">
            <button mat-button class="circular-icon-button" style="margin-right: 8px !important;">
              <mat-icon class="icon transition-01"
                [ngStyle]="{'transform': shiftTemplate.hide ? 'rotate(180deg)':'rotate(0deg)' }">expand_more</mat-icon>
            </button>
            <span class="shift-template-title">{{ shiftTemplate.name }}</span>
            <span class="color-square" [ngStyle]="{'background-color': shiftTemplate.color}"
              style="margin-left: 1rem;"></span>
          </div>

          <span fxFlex class="clickable" (click)="shiftTemplate.hide = !shiftTemplate.hide"></span>

          <!-- buttons -->
          <div fxLayout="row" fxLayoutAlign="start center">

            <button mat-button *ngIf="!shiftTemplate.isEmpty && isAllowedUserWrite"
              class="md-orange circular-icon-button not-clickable left"
              matTooltip="{{ 'SHIFT_TEMPLATES.NOT_EDITABLE' | translate }}" matTooltipPosition="left">
              <mat-icon class="icon">warning</mat-icon>
            </button>

            <button mat-button *ngIf="isAllowedUserWrite" class="filter-button"
              (click)="addShiftTemplate(shiftTemplate)" [disabled]="!pageStateReady || !shiftTemplate.isEmpty">
              <mat-icon class="icon">edit</mat-icon>
              <span>{{ 'GLOBAL.EDIT' | translate }}</span>
            </button>

            <button mat-button *ngIf="isAllowedUserWrite" class="filter-button-no-background md-red"
              (click)="removeShiftTemplate(shiftTemplate)" [disabled]="!pageStateReady || !shiftTemplate.isEmpty">
              <mat-icon class="icon">delete</mat-icon>
              <span>{{ 'GLOBAL.REMOVE' | translate }}</span>
            </button>

          </div>
        </mat-card>
        <!-- accordion content -->
        <div flex class="transition-01" [ngStyle]="{'max-height': shiftTemplate.hide ? '800px':'0' }"
          style="overflow: hidden; height: auto;">

          <mat-card style="padding: 0 1rem 1rem;" class="homepage-list">
            <table class="w100">
              <thead>
                <tr>
                  <th *ngFor="let info of shiftTemplateInfos">
                    <span><b>{{ info.label | translate }}</b></span>
                  </th>
              </thead>
              <tbody>
                <tr *ngFor="let shift of shiftTemplate.shifts">
                  <td *ngFor="let info of shiftTemplateInfos">
                    <span>{{ shift[info.variable] }}</span>
                  </td>
                </tr>
              </tbody>
            </table>

          </mat-card>
        </div>
      </div>
    </div>

    <!-- add button -->
    <button *ngIf="pageStateReady && isAllowedUserWrite" mat-button class="filter-button" (click)="addShiftTemplate()"
      style="position: absolute; top: 58px; right: 1rem;" #tooltip="matTooltip"
      matTooltip="{{ 'SHIFT_TEMPLATES.ADD_SHIFT_TEMPLATE' | translate }}" matTooltipPosition="left">
      <mat-icon class="icon" style="margin: 0 2px 0 0;">add</mat-icon>
    </button>

  </div>

</div>