<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
  <!-- utils -->
  <!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->
</div>

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxFlex fxLayout="column" class="w100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1 && !hierarchyMode"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="center center" class="tab-content">

    <!-- toolbar -->
    <div class="ff-toolbar pb-3 pt-1" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center" *ngIf="!appConfig?.homepage?.removeHomepageToolbar">

      <ff-filter-buttons [filterButtons]="filterButtons" (selected)="onFiltersDialogSelect($event)">
      </ff-filter-buttons>

      <!-- back button -->
      <button *ngIf="hierarchyMode && hierarchyHistory?.length > 0" mat-icon-button class="back-button sidenav-open"
        (click)="navigateBack()" aria-label="menu">
        <i class="fas fa-arrow-left fa-lg icon"></i>
      </button>

      <span fxFlex></span>

      <!-- hierarchyMode -->
      <div *ngIf="hierarchy != null" fxLayout="row" fxLayoutAlign="center center" class="switch-container">
        <span marginRight>{{ 'HOMEPAGE.HIERARCHY_SWITCH' | translate }}</span>
        <ui-switch *ngIf="hierarchy != null" [checked]="hierarchyMode" (change)="toggleHierarchyMode($event)"
          checkedLabel="ON" uncheckedLabel="OFF">
        </ui-switch>
      </div>

      <!-- search -->
      <div class="search-input" fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.lt-md="start center">
        <mat-icon class="icon">search</mat-icon>
        <input type="text" (keyup)="searchMachines($event)" placeholder="{{'GLOBAL.SEARCH'|translate}}">
      </div>

    </div>

    <div *ngIf="machineSelected == null || machineSelected?.machineId == null || machineSelected?.length == 0"
      fxLayout="row" fxLayoutAlign="start center" style="margin: 8px 0;">
      <mat-icon class="icon md-blue-i">info</mat-icon>
      <span class="suggestion">{{ 'HOMEPAGE.CLICK_TO_SELECT' | translate }}</span>
    </div>

    <!-- content -->
    <router-outlet></router-outlet>

  </div>

</div>


<!-- <div *ngIf="machines.filtered.length == 0 && statePageReady" class="no-machines" fxLayout="row"
    fxLayoutAlign="center center">
    <span>{{ 'HOMEPAGE.NOT_FOUND' | translate }}</span>
  </div> -->