import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { MobileService } from 'src/app/services/mobile.service';
import { FiltersDialogComponent } from '../filters-dialog/filters-dialog.component';

@Component({
  selector: 'ff-filter-buttons',
  templateUrl: './filter-buttons.component.html',
  styleUrls: ['./filter-buttons.component.scss']
})
export class FilterButtonsComponent implements OnInit {

  @Input() filterButtons: any;
  @Output() selected = new EventEmitter<any>();
  public isSmThanTablet: any;
  public mobileListener: any;

  constructor(
    public dialog: MatDialog,
    public translate: FfTranslateService,
    public clonerService: ClonerService,
    private mobile: MobileService
  ) {
    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isSmThanTablet = value.isSmThanTablet
    })
  }

  checkFilterButtons(item: any) {
    return item?.options?.length > item?.options?.filter(x => x.selected)?.length;
  }

  filteredItems(item: any) {
    return item?.options?.filter(x => x.selected)?.length;
  }

  openFilterDialog(button: any) {

    const filtersDialog = this.dialog.open(FiltersDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(button.label),
        variable: button.variable,
        options: button.options,
        config: button.config,
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') {
        button.options = this.clonerService.deepClone(result?.options);

        try { button.updateSelection() }
        catch { console.log('non sono un filter button') }

        this.selected.emit(this.clonerService.deepClone(button));
      }
    });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    this.mobileListener.unsubscribe()
  }

}
