<div mat-dialog-title fxLayout="row" class="w100">

  <!-- icon -->
  <span *ngIf="dialog.icon != null" style="margin-right: 8px;" fxLayout="row" fxLayoutAlign="start center">

    <ff-icon [icon]="dialog.icon"></ff-icon>

  </span>

  <span fxLayout="column" fxLayoutAlign="center center">{{ dialog.title | translate }}</span>

  <span fxFlex></span>

  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>

</div>
<mat-dialog-content class="mat-typography">

  <ng-container *ngIf="state == 0">
    <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
      <ff-loading-card></ff-loading-card>
    </div>
  </ng-container>

  <ng-container *ngIf="state == 1">
    <div *ngFor="let info of dialog?.tableInfos | filterByProperty:['editableInDialog', '==', true]">

      <div fxLayout="row" fxLayoutAlign="center center" style="margin: 8px 0;"
        *ngIf="dialog.row != null && checkShowCondition(info, dialog?.row)">

        <span fxFlex="{{ dialog?.labelFlex ? dialog.labelFlex : 50}}" style="font-weight: 800;">
          <span>{{ (info?.label != null ? info.label : '-') | translate }}</span>
          <span *ngIf="info.unit && info.unitInTitle">&nbsp;[{{ info.unit | translate }}]</span>
        </span>

        <div fxFlex="{{ dialog?.labelFlex ? (100 - dialog.labelFlex) : 50}}">
          <!-- TEMPLATE InfoValue -->
          <ng-container [ngTemplateOutlet]="InfoValue" [ngTemplateOutletContext]="{ info:info, item:dialog?.row }">
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngIf="errors?.length > 0" fxLayout="column" fxLayoutAlign="start end" style="margin-top: 36px;">
      <span *ngFor="let error of errors" class="error">{{ error?.label }}</span>
    </div>

  </ng-container>

</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button class="filter-button md-primary" (click)="closeDialog()" [disabled]="checkDisability() || errors.length > 0">
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</mat-dialog-actions>


<!-- TEMPLATE InfoValue -->
<ng-template #InfoValue let-info="info" let-item="item">

  <div class="input-box" *ngIf="info.dialogEditType == 'inputText'"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <input matInput class="ff-input text w100" type="text" [matAutocomplete]="auto" [disabled]="info.disabledInDialog"
      [(ngModel)]="item[info.variable]">
    <mat-autocomplete #auto="matAutocomplete">
      <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let option of info.autocomplete; let i = index"
        [attr.data-index]="i">
        <mat-option style="padding-right: 0px" fxFlex [value]="option">
          <div>{{ option | translate }}</div>
        </mat-option>
      </div>
    </mat-autocomplete>
  </div>

  <div *ngIf="info.dialogEditType == 'inputNum'" class="input-box"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <input matInput class="ff-input number w100" type="number" [min]="info?.min ?? 0" [max]="info?.max"
      [(ngModel)]="item[info.variable]" [disabled]="info.disabledInDialog">
  </div>

  <div *ngIf="info.dialogEditType == 'closedList'" class="ff-input-select w100" fxLayout="row"
    fxLayoutAlign="start center">
    <mat-select [(value)]="item[info.variable]" [disabled]="info.disabledInDialog || info.listOptions?.length == 0"
      (selectionChange)="changedClosedListElem($event, item, info)">
      <mat-option *ngIf="info.addNullValue" [value]="null">---</mat-option>
      <ng-container *ngIf="info.closedListType == null">
        <mat-option *ngFor="let opt of info.listOptions" [value]="opt">
          {{ opt }}
        </mat-option>
      </ng-container>
      <ng-container *ngIf="info.closedListType == 'arrayOfObjects'">
        <mat-option *ngFor="let opt of info.listOptions" [value]="opt.id">
          {{ filterService.translateElementNewFormat(opt) }}
        </mat-option>
      </ng-container>
    </mat-select>
  </div>

  <div *ngIf="info.dialogEditType == 'inputDateTime'">
    <input matInput class="ff-input datetime w100" type="datetime-local"
      [max]="dialog?.maxDate ?? (!info.avoidCheckFuture ? nowTime : null)" [min]="dialog?.minDate"
      [(ngModel)]="item[info.variable]" (ngModelChange)="checkDate($event, item, info)">
  </div>

  <div *ngIf="info.dialogEditType == 'inputDuration'">
    <ff-duration-input format="HH:mm" unit="s" [(value)]="item[info.variable]"></ff-duration-input>
  </div>

  <div *ngIf="info.dialogEditType == 'checkbox'">
    <ff-checkbox [checkBoxConfig]="info.checkboxConfig" (changedValue)="onCheckboxChange(item, info, $event)">
    </ff-checkbox>
  </div>

  <div *ngIf="info?.max != null && item[info.variable] != null && item[info.variable] > info?.max" class="required">
    <span translate="GLOBAL.MAXIMUM_NUMBER" [translateParams]="{x: info?.max}"></span>
  </div>

  <div *ngIf="info?.min != null && item[info.variable] != null && item[info.variable] < info?.min" class="required">
    <span translate="GLOBAL.MINIMUM_NUMBER" [translateParams]="{x: info?.min}">
    </span>
  </div>

  <div *ngIf="info.requiredInDialog && item[info.variable] == null" class="required">
    <span>{{ 'GLOBAL.REQUIRED' | translate }}</span>
  </div>

  <div *ngIf="info.dialogEditType == 'customMultipleInput'" fxLayout="column" fxLayoutGap="8px">
    <div *ngFor="let subItem of info?.multipleInputs?.subItemList; let subItemIndex = index; let last = last;"
      fxLayout="row wrap" fxLayoutGap="8px">

      <div *ngFor="let subInfo of info?.multipleInputs?.subInputs" [fxFlex]="subInfo?.flex">
        <ng-container [ngTemplateOutlet]="InfoValue"
          [ngTemplateOutletContext]="{ info:subInfo, item:dialog?.row[info?.multipleInputs?.nestedKey][subItemIndex] }">
        </ng-container>
      </div>

      <span style="margin-right: 8px !important;"></span>

      <button *ngIf="info?.multipleInputs?.addable && last" class="rounded-button"
        (click)="info?.multipleInputs?.subItemList.push({}); dialog?.row[info?.multipleInputs?.nestedKey].push({})"
        matTooltip="{{ 'GLOBAL.ADD' | translate }}" matTooltipPosition="above">
        <mat-icon class="icon">add</mat-icon>
      </button>

    </div>
  </div>

</ng-template>