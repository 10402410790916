import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss']
})
export class PlannerComponent implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;
  public tableInfo: any;
  public dialogFields: any;
  public payloadVariables: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machine: any;
  public machineSelectedSub: Subscription;

  public availableMachines: any;
  public aggregations: any;
  public excludeAggr: any = [];
  public totals: any = []
  public totalsData: any;

  public interval: any;
  public intervalConfig: any;

  public searchPrograms: any;
  public programs: any;

  public tableConfig: any;
  public editMode: number = 0;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 4;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getPrograms, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    private snackBar: MatSnackBar,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.payloadVariables = this.appConfig.planner.payloadVariables;

    this.breadcrumb = ['CALENDAR.TITLE', 'PLANNER.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs('calendar');

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.programs = {
      list: [],
      filtered: [],
      selected: null,
      pageOptions: [10, 25, 50, 100],
      pageSize: 25,
      search: "",
      switch: {
        checked: false,
        checkedLabel: 'on',
        uncheckedLabel: 'off',
      },
    };

    this.tableConfig = this.appConfig.planner.tableConfig

    this.tableConfig.additionalButtons = this.clonerService.deepClone(this.appConfig.planner.tableActions)

    this.tableConfig.removeAnimations = false
    this.tableConfig.cardFrame = true
    this.tableConfig.completeCardFrame = true

    this.excludeAggr = this.appConfig?.planner?.excludeAggr ?? []
    this.totals = this.appConfig?.planner?.totals ?? []

    this.pageState.subscribe()

    // this.alarmsData = new MatTableDataSource<any[]>([]);
  }

    // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    // try {
    //   _this.isAllowedUserWrite = _this.internalDataService.getSpecificPermission("mat-pc-calendar-rw");
    // } catch (error) { console.log(error) }

    // try {
    //   _this.isAllowedUser = _this.internalDataService.getSpecificPermissions(["mat-pc-calendar-r", "mat-pc-calendar-rw"], 'or');
    // } catch (error) { console.log(error) }

    // if (!_this.isAllowedUser) {
      
    //   let isCachedMachineId = _this.cacheService.get("machineId");
    //   if (isCachedMachineId == null) {
    //     _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
    //     _this.tabs = _this.internalDataService.getPageTabs('calendar');
    //   }

    //   let testError = {
    //     type: 0,
    //     status: 401,
    //     message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
    //   };
    //   _this.dispatcherService.getDispatch(_this, 301, testError);
    // } else {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'calendar');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
    // }
  }

  getPrograms(_this: any) {
    if (_this.cacheService.get("intervalLong") == null) {

      let intervalId = _this.appConfig.planner.intervalId ? _this.appConfig.planner.intervalId : "last90Days"
      _this.interval = _this.interval != null ? _this.interval : _this.intervalService.getIntervalById( intervalId, _this.machine.timezone);

      _this.intervalConfig = {
        list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
        selected: _this.interval
      };

      _this.cacheService.set("intervalLong", _this.intervalConfig);

    } else {
      _this.intervalConfig = _this.cacheService.get("intervalLong");
      _this.interval = _this.cacheService.get("intervalLong").selected;
    }

    let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      if (_this.appConfig?.planner != null && _this.appConfig?.planner.hasOwnProperty("sessionFilters")) {
        let sessionFilters = _this.cacheService.get('sessionFilters')
        if(sessionFilters != null) _this.aggregations = sessionFilters
      }
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;
      payload.totals = _this.totals.map(x => x.beVariable)

    try {

      let url = '/apif/program-planner/' + _this.machineId;

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone
      };

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({
            maxRetryAttempts: 0
          })),
          catchError(error => {
            console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);
            let errorData = {
              type: 0,
              status: error.status,
              message: error.statusText
            };

            if (_this.editMode == 1) {
              _this.snackBar.open(errorData.message, 'x', {
                horizontalPosition: 'right',
                verticalPosition: 'bottom',
                panelClass: ['snackbar', 'fail'],
                duration: 10000
              });
              _this.editMode = 0;
            }
            _this.dispatcherService.getDispatch(_this, 301, errorData);

            return throwError(errorData.status + ': ' + errorData.message);
          })
        )
        .subscribe(
          (data: any) => {
            let res = data.body
            if(res.hasOwnProperty('programs'))
            _this.programs.list = res.hasOwnProperty('programs') ? _this.parseData(res.programs) : []
            _this.totalsData = res.hasOwnProperty('totals') ? _this.clonerService.deepClone(res.totals) : {}
            _this.filterPrograms()

            if (_this.editMode == 1) {
              _this.snackBar.open(_this.translate.instant('PLANNER.SAVE_SUCCESS'), 'x', {
                horizontalPosition: 'right',
                verticalPosition: 'bottom',
                panelClass: ['snackbar', 'success'],
                duration: 10000
              });
              _this.editMode = 0;
            }

            _this.dispatcherService.getDispatch(_this, 300)
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseData(data: any) {
    let datetime = this.tableConfig.tableInfos.filter((info: any) => info.type == 'inputDateTime').map((info: any) => info.variable)
    let multipliers = this.tableConfig.tableInfos.filter((info: any) => info.hasOwnProperty('multiplier')).map((info: any) => info.variable)
    let required = this.tableConfig.tableInfos.filter((info: any) => info.required).map((info: any) => info.variable)
    let uniqueId = this.tableConfig.tableInfos.find((info: any) => info.uniqueId)?.variable
    uniqueId = uniqueId != null ? uniqueId : 'programId'

    if (data != null && Array.isArray(data)) {
      try {
        data.forEach((element: any) => {
          element.isBlank = this.tableConfig.tableInfos
            .map((info: any) => info.variable)
            .some((key: any) => element[key] == null || element[key] == '') 

          element.update = this.clonerService.deepClone(element[uniqueId])

          datetime.forEach((x: any) => {
            element[x] = element[x] != null && element[x] != '' ? moment(element[x]).format('YYYY-MM-DDTHH:mm') : null
          });

          multipliers.forEach((x: any) => {
            let multiplier = this.tableConfig.tableInfos.find((y: any) => y.variable ==  x).multiplier
            element[x] = element[x]/multiplier
          });
        })
        return data
      } catch (error) {
        return []
      }
    }
  }

  programSwitchChange(value: boolean) {
    this.programs.switch.checked = value;
    this.filterPrograms();
  }

  openAggrDialog(aggr: any) {
    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {

        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
      }

      if (this.appConfig?.planner != null && this.appConfig?.planner.hasOwnProperty("sessionFilters")) {
        let sessionFilters: any = this.clonerService.deepClone(this.aggregations)
        this.cacheService.set('sessionFilters', sessionFilters)
      }

      this.pageState.next(isClickedSelect ? 4 : 5);

      this.getPrograms(this);

    });
  };

  filterPrograms() {
    let filterVariables = this.tableConfig.tableInfos.map((info: any) => info.variable);

    let filtered: any = [];

    try {
      filtered = this.programs.list.filter((x: any) => this.programs.switch.checked ? x.isBlank : true);
    } catch (error) {
      console.log(error);
    }

    try {
      filtered = filtered.filter((data: any) => filterVariables.some((x: any) => this.searchPrograms == null || this.searchPrograms == '' ? true : String(data[x]).toLowerCase().includes(this.searchPrograms.toLowerCase())));
    } catch (error) {
      console.log(error);
    }

    this.programs.filtered = filtered;
    this.tableConfig.rows = filtered;

    this.tableConfig = this.clonerService.deepClone(this.tableConfig);
  }


  ngOnInit(): void {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )
  
    this.dispatcherService.getDispatch(this, 300)
  }

  selectInterval(interval: any) { 
    this.intervalService.selectInterval(this, interval, null, this.getPrograms, this.getPrograms, this.machine.timezone) 
    // this.pageState.next(4)
    this.pageState.next(5)
  };

  onAddButton() {
    console.log('mmssa')

    let x = {
      isBlank: true
    }
    this.tableConfig.tableInfos.forEach((info: any) => {
      x[info.variable] = null
    });
    if (this.tableConfig.rows == null) this.tableConfig.rows = []
    this.tableConfig.rows.unshift(x)
    this.tableConfig = this.clonerService.deepClone(this.tableConfig)
  }

  onSaveButton(clicked?: any, deactivate?: any) {
    let rows: any = this.clonerService.deepClone(this.tableConfig.rowsFiltered)
    rows.forEach((element: any) => {
      if (element.hasOwnProperty('notCollapsed')) delete element.notCollapsed
    });
    let blankRequiredField = false
    let required = this.tableConfig.tableInfos.filter((info: any) => info.required).map((info: any) => info.variable)
    if (required.length > 0) {
      blankRequiredField = required.some((info: any) => {
       return rows.some((row: any) => row[info] == null || row[info] == '');
      })
    }
    rows = rows.sort(this.filterService.sortByProperty('timeStart', 'asc', false))
    let sortedList : any = this.clonerService.deepClone(this.programs.list)
    sortedList = sortedList != null ? sortedList.sort(this.filterService.sortByProperty('timeStart', 'asc', false)) : []
    sortedList.forEach(element => {
      if (element.hasOwnProperty('notCollapsed')) delete element.notCollapsed
    });
    // console.log(sortedList, rows)

    let canDeactivate = JSON.stringify(sortedList) == JSON.stringify(rows)
    if(deactivate) {
      console.log('deact')
      return canDeactivate
    }
    let disabled = blankRequiredField ? true : canDeactivate
    if(!clicked) return disabled
    else this.editProgram(rows)
  }

  canDeactivate() {
    return this.onSaveButton(null, true)
  }

  parseToBE(data: any) {
    console.log('g')
    let variables = this.tableConfig.tableInfos.map((info: any) => info.variable)
    let beVariables = []
    let required = this.tableConfig.tableInfos.filter((info: any) => info.required).map((info: any) => info.variable)
    let datetime = this.tableConfig.tableInfos.filter((info: any) => info.type == 'inputDateTime').map((info: any) => info.variable)
    let multipliers = this.tableConfig.tableInfos.filter((info: any) => info.hasOwnProperty('multiplier')).map((info: any) => info.variable)
    let uniqueId = this.tableConfig.tableInfos.find((info: any) => info.uniqueId)?.variable
    uniqueId = uniqueId != null ? uniqueId : 'programId'

    if (data != null && Array.isArray(data)) {
      try {
        data.forEach((element: any) => {
          datetime.forEach((x: any) => {
            element[x] = moment(element[x]).toISOString()
          });

          multipliers.forEach((x: any) => {
            let multiplier = this.tableConfig.tableInfos.find((y: any) => y.variable ==  x).multiplier
            element[x] = element[x]*multiplier
          });

          Object.keys(element).forEach((key: any) => beVariables.push(key))
          beVariables = beVariables.filter(this.filterService.onlyUnique)

          variables = variables.concat(beVariables).filter(this.filterService.onlyUnique)

          variables.forEach(variable => {
            if(variable.endsWith('Old')) delete element[variable]
          });
          if(element.hasOwnProperty('notCollapsed')) delete element.notCollapsed
          

          if(element.hasOwnProperty('update') && element.update == element[uniqueId]) delete element.update
        })
        return data
      } catch (error) {
        
      }
    }
  }

  editProgram(data) {
    let query = {
      update: 1
    }
    data = this.clonerService.deepClone(data)
    let payload = this.parseToBE(data)

    try {

      let url = '/apif/program-planner/' + this.machineId;

      this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(this.apiService.genericRetryStrategy({
            maxRetryAttempts: 0
          })),
          catchError(error => {
            console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);
            let errorData = {
              type: 0,
              status: error.status,
              message: error.statusText
            };

            this.snackBar.open(errorData.message, 'x', {
              horizontalPosition: 'right',
              verticalPosition: 'bottom',
              panelClass: ['snackbar', 'fail'],
              duration: 10000
            });

            this.dispatcherService.getDispatch(this, 301, errorData);
            return throwError(errorData.status + ': ' + errorData.message);
          })
        )
        .subscribe(
          (data: any) => {  
            console.log('done')
            this.editMode = 1
            this.pageState.next(3)
            this.dispatcherService.getDispatch(this, 300)
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      this.editMode = 0
      this.snackBar.open(errorData.message, 'x', {
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
        panelClass: ['snackbar', 'fail'],
        duration: 10000
      });
    }
  }

}
