<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1}">

    <div *ngIf="aggregations?.length > 0 || availableMachines?.list?.length > 0" class="ff-toolbar pb-3 pt-1"
      fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <!-- aggregations -->
      <button mat-button class="filter-button"
        *ngFor="let aggr of aggregations | filterByProperty: [excludeAggr, '!includes', 'id']"
        (click)="openAggrDialog(aggr)" [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
        <mat-icon *ngIf="aggr.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': aggr.label}">
          {{aggr.icon.icon }}</mat-icon>
        <mat-icon *ngIf="aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
          translate-attr="{'aria-label': aggr.label}"></mat-icon>
        <i *ngIf="aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor'" class="icon {{ aggr.icon.icon }}"
          translate-attr="{'aria-label': aggr.label}"></i>
        <span>{{ aggr.selectionLabel }}</span>
        <div fxLayout="row" fxLayoutAlign="center center"
          *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
          {{ aggregationsPayload[aggr.id].length }}
        </div>
      </button>

      <span fxFlex fxShow="false" fxShow.gt-sm></span>

      <ff-available-machines-selection *ngIf="availableMachines?.list?.length > 0"
        [availableMachines]="availableMachines" (selected)="machineSelectionChange($event)">
      </ff-available-machines-selection>

    </div>

    <div fxFlexAlign="row" class="w100 mb-3" *ngIf="pageState.value >= 5 && totals?.length > 0">
      <div *ngIf="pageState.value >= 5" class="ff-toolbar py-1 w100 h100" fxLayout="row" fxLayoutAlign="start center"
        fxLayoutAlign.gt-sm="start end">

        <div class="mx-2">
          <button mat-button class="button button-1" (click)="onAddButton()">
            <span style="margin-right: 1rem; font-size: 1.2rem; text-transform: uppercase;">{{ 'GLOBAL.ADD' | translate
              }}</span>
            <mat-icon class="side">add</mat-icon>
          </button>
        </div>

        <div class="mx-2">
          <button mat-button class="button button-1 w100" [disabled]="onSaveButton()" (click)="onSaveButton(true)">
            <span style="margin-right: 1rem; font-size: 1.2rem; text-transform: uppercase;">{{ 'GLOBAL.SAVE' | translate
              }}</span>
            <mat-icon class="side">save</mat-icon>
          </button>
        </div>
      </div>

      <span fxFlex></span>

      <ff-widget *ngFor="let total of totals" fxFlex.gt-sm="20" style="margin-right: 8px;"
        [widget]="{type: 'ff-value', data: totalsData, config: [total]}">
      </ff-widget>
    </div>


    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value <= pageStateReady" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <!-- table -->
    <ff-table-sortable *ngIf="pageState.value >= 5 && tableConfig!= null" class="p-1"
      [(tableConfig)]="tableConfig"></ff-table-sortable>

  </div>

</div>