
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-display-image-dialog',
  templateUrl: './display-image-dialog.component.html',
  styleUrls: ['./display-image-dialog.component.scss']
})
export class DisplayImageDialogComponent implements OnInit, OnDestroy {

  imageType: any = "url";

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<DisplayImageDialogComponent>,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    this.imageType = this.dialog?.imageUrl instanceof ArrayBuffer ? 'arrayBuffer' : 'url';
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  close() { this.dialogRef.close() }

  _arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  ngOnDestroy() { }

}