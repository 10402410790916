import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { throwError } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { AddMaintenanceComponentDialogComponent } from '../components/add-maintenance-component-dialog/add-maintenance-component-dialog.component';
import { BarillaShiftSelectionDialogComponent } from '../components/barilla-shift-selection-dialog/barilla-shift-selection-dialog.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { CreateFolderDialogComponent } from '../components/create-folder-dialog/create-folder-dialog.component';
import { DisplayImageDialogComponent } from '../components/display-image-dialog/display-image-dialog.component';
import { EditPdfDialogComponent } from '../components/edit-pdf-dialog/edit-pdf-dialog.component';
import { FfDashboardDialogComponent } from '../components/ff-dashboard-dialog/ff-dashboard-dialog.component';
import { FiltersDialogComponent } from '../components/filters-dialog/filters-dialog.component';
import { ImportExcelDialogComponent } from '../components/import-excel-dialog/import-excel-dialog.component';
import { ImportFileDialogComponent } from '../components/import-file-dialog/import-file-dialog.component';
import { IngenyaImageDialogComponent } from '../components/ingenya-image-dialog/ingenya-image-dialog.component';
import { MaintenanceBomGeneratorDialogComponent } from '../components/maintenance-bom-generator-dialog/maintenance-bom-generator-dialog.component';
import { MaintenanceCalendarTemplatesDialogComponent } from '../components/maintenance-calendar-templates-dialog/maintenance-calendar-templates-dialog.component';
import { MaintenanceComponentDialogComponent } from '../components/maintenance-component-dialog/maintenance-component-dialog.component';
import { MaintenanceInterventionDialogComponent } from '../components/maintenance-intervention-dialog/maintenance-intervention-dialog.component';
import { MultipleInputDialogComponent } from '../components/multiple-input-dialog/multiple-input-dialog.component';
import { SingleInputDialogComponent } from '../components/single-input-dialog/single-input-dialog.component';
import { ApiService } from './api.service';
import { AppConfigService } from './app-config.service';
import { CacheService } from './cache.service';
import { ClonerService } from './clone.service';
import { FiltersService } from './filters.service';
import { InternalDataService } from './internal-data.service';
import { IntervalService } from './interval.service';

@Injectable({
  providedIn: 'root'
})
export class StandardDialogServiceService {

  appConfig: any;

  constructor(
    public translate: FfTranslateService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public filterService: FiltersService,
    public internalDataService: InternalDataService,
    public intervalService: IntervalService,
    public router: Router,
    public apiService: ApiService,
    public cacheService: CacheService,
    public appConfigService: AppConfigService,
  ) {
    this.appConfig = this.appConfigService.getAppConfig;
  }


  // // // // // //
  // PERONI
  // // // // // //
  rectifyQuantity(_this, infos, title = 'GLOBAL.RECTIFY_QUANTITY', key = 'rectQty') {

    let row = infos?.row ?? {};
    let tableInfos = infos?.tableInfos ?? [];
    let buttonInfos = infos?.buttonInfos ?? {};

    if (row == null || Object.keys(row)?.length == 0) return;

    const sigleInputDialog = this.dialog.open(SingleInputDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(title ?? 'GLOBAL.RECTIFY_QUANTITY'),
        icon: buttonInfos?.icon,
        machineId: _this.machineId,
        row: this.clonerService.deepClone(row),
        key: key ?? 'rectQty'
      },
    });

    sigleInputDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') this.sendRequest(_this, result, buttonInfos?.endpointUrl ?? '/apif/rectify', 'GLOBAL.RECTIFY_STATUS.FAIL', 'GLOBAL.RECTIFY_STATUS.SUCCESS');
    });
  }

  printAggregatedTable(_this, infos, title = 'GLOBAL.ADD_DIALOG') {

    let table = [];
    try {

      let cols = ["prodId{label}", "prodId{brand}"];
      let rows = infos?.tableConfig?.rowsFiltered ?? [];

      // console.log(rows);

      let lines: any = rows.map(x => x.lineId).filter(this.filterService.onlyUnique);

      let rowsGroupedByProdId = this.filterService.groupBy(rows, "prodId{label}");

      Object.entries(rowsGroupedByProdId).forEach(kv => {
        let prodId = kv[0];
        let val = kv[1];

        let groupedLine = this.filterService.groupBy(val, "lineId");
        // console.log(prodId, groupedLine);

        let prodInt = val?.[0]?.["prodId"];
        try { prodInt = parseInt(prodId) } catch (error) { }

        let obj = {
          "prodId": prodInt,
          "prodId{label}": prodId,
          "prodId{brand}": val?.[0]?.["prodId{brand}"],
        };

        Object.entries(groupedLine).forEach((lineVal, idx) => {
          console.log(lineVal, idx);

          let line = lineVal[0];
          let v = lineVal[1];

          let qty = this.filterService.sum(v, 'qty');
          // console.log(prodId, line, qty);

          obj[line] = qty;

          if (idx == Object.keys(groupedLine).length - 1) {
            table.push(obj);
          }

        });
      });

      console.table(table);

      let tableInfos = [
        {
          "variable": "prodId{label}",
          "orderBy": "prodId",
          "label": "GLOBAL.RECIPE",
          "primary": true
        },
        {
          "variable": "prodId{brand}",
          "orderBy": "prodId{brand}",
          "label": "GLOBAL.BRAND",
          "primary": true
        }
      ].concat(lines.sort().map(line => {
        return {
          variable: line,
          orderBy: line,
          label: this.translate.instant("GLOBAL.LINE") + ' ' + line,
          type: 'value',
          decimals: 1,
          unit: "hL",
          unitInTitle: true
        }
      }));


      let dashboardConfig = {
        widgets: [
          {
            "type": "ff-table-sortable",
            "flex": 100,
            "config": {
              "rowsVariable": "table",
              "tableInfos": tableInfos,
              "completeCardFrame": true,
              "cardFrame": true,
              "paginator": {
                "options": [
                  10,
                  25,
                  50,
                  100
                ],
                "size": 25,
                "position": "bottom right"
              },
              "sort": {
                "variable": "prodId{label}",
                "order": "asc"
              },
              "export": {
                "filename": "GLOBAL.TABLE"
              }
            }
          }
        ]
      };

      let completeDashboardConfig = {
        dashboardData: { "table": table },
        dashboardConfig: dashboardConfig,
      };

      this.dialog.open(FfDashboardDialogComponent, {
        panelClass: 'ff-dialog',
        width: "80%",
        height: "80%",
        data: {
          title: "GLOBAL.TABLE",
          completeDashboardConfig: completeDashboardConfig
        },
      });

    } catch (error) {
      console.log(error)
    }

  }

  async showTable(_this, infos, title) {
    try {

      let table = await _this.apiService.sendPostRequest(infos.buttonInfos.endpointUrl + _this['machineId'] + '?compId=uju', infos.row, null).toPromise();

      table = table.body;

      let dashboardConfig = {
        widgets: [
          {
            "type": "ff-table-sortable",
            "flex": 100,
            "config": {
              "rowsVariable": "table",
              "tableInfos": infos.buttonInfos.tableInfos,
              "completeCardFrame": true,
              "cardFrame": true,
              "paginator": {
                "options": [
                  10,
                  25,
                  50,
                  100
                ],
                "size": 25,
                "position": "bottom right"
              },
              "sort": {
                "variable": "timestamp",
                "order": "asc"
              },
              "export": {
                "filename": title
              }
            }
          }
        ]
      };

      let completeDashboardConfig = {
        dashboardData: { "table": table },
        dashboardConfig: dashboardConfig,
      };

      this.dialog.open(FfDashboardDialogComponent, {
        panelClass: 'ff-dialog',
        width: "80%",
        height: "80%",
        data: {
          title: "GLOBAL.TABLE",
          completeDashboardConfig: completeDashboardConfig
        },
      });
    } catch (error) {
      console.log(error)
    }
  }

  // // // // // //
  // BARILLA
  // // // // // //

  // BATCH DETAIL
  barillaBatchDetail(_this, infos) {

    let row = infos?.row ?? {};

    this.router.navigate(
      row?.detailUrlPath,
      { queryParams: row.detailQueryParams }
    );
  }

  // STANDARD ACTIONS

  // CONFIRM ACTION
  confirmAction(_this, infos, title = 'GLOBAL.CONFIRM') {

    let row = infos?.row ?? {};
    let buttonInfos = infos?.buttonInfos ?? {};
    let description = buttonInfos?.description;

    if (buttonInfos?.addStandardInformations) row = this.filterService.addStandardInformations(row);
    if (_this.allDeviations != null) row.allDeviations = this.clonerService.deepClone(_this.allDeviations);
    if (buttonInfos?.descriptionFromFunction) description = this?.[buttonInfos.descriptionFromFunction](_this, infos);

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(buttonInfos.dialogTitle ?? title ?? 'GLOBAL.CONFIRMATION_DIALOG'),
        description: description,
        icon: buttonInfos.icon,
      },
    });

    confirmationDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') {
        if (buttonInfos?.afterConfirmationFunction) {
          _this?.[buttonInfos.afterConfirmationFunction](_this, row);
          return;
        }
        this.sendRequest(
          _this,
          row,
          buttonInfos?.endpointUrl ?? '/apif/confirm-something',
          buttonInfos?.failedRequestTitle ?? 'GLOBAL.FAILED_REQUEST',
          buttonInfos?.successfulRequestTitle ?? 'GLOBAL.SUCCESSFUL_REQUEST',
          buttonInfos?.nextState ?? 5,
          buttonInfos?.mainPollingFunction ?? "mainPollingFunction",
          buttonInfos?.machineIdKey ?? 'machineId',
          buttonInfos?.reload ?? false,
          buttonInfos?.gentleReload ?? false,
        );
      }
    });

  }

  // ADD ROW
  addRow(_this, infos, title = 'GLOBAL.ADD_DIALOG') {

    let row = infos?.row ?? {};
    let tableInfos = infos?.buttonInfos?.dialogTableInfos ?? infos?.tableInfos ?? [];
    let buttonInfos = infos?.buttonInfos ?? {};

    if (buttonInfos?.addStandardInformations) row = this.filterService.addStandardInformations(row);
    if (buttonInfos?.addRowsVariable) row.rowsVariable = infos.tableConfig?.rowsVariable;

    let data: any = {
      title: this.translate.instant(buttonInfos?.dialogTitle ?? title ?? 'GLOBAL.ADD_DIALOG'),
      icon: buttonInfos?.icon,
      machineId: _this.machineId,
      row: this.clonerService.deepClone(row),
      tableInfos: this.clonerService.deepClone(tableInfos),
      labelFlex: buttonInfos?.dialogLabelFlex,
      profile: _this?.machine?.profile,
      machine: this.clonerService.deepClone(_this.machine)
    };

    if (buttonInfos?.intervalBetweenMinDateAndMaxDate) {
      let sortedList = infos.tableConfig?.rows?.sort(this.filterService.sortByProperty("timeStart", "desc", true));
      data.minDate = sortedList?.[0]?.timeEnd;
      if (data.minDate != null) data.minDate = this.intervalService.unformatStringDate(data.minDate, data.machine?.timezone);
    }

    const multipleInputDialog = this.dialog.open(MultipleInputDialogComponent, {
      panelClass: 'ff-dialog',
      // width: "30%",
      width: buttonInfos?.dialogWidth ?? "30%",
      data: data,
    });

    multipleInputDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') this.sendRequest(
        _this,
        result,
        buttonInfos?.endpointUrl ?? '/apif/add-something',
        buttonInfos?.failedRequestTitle ?? 'GLOBAL.FAILED_REQUEST',
        buttonInfos?.successfulRequestTitle ?? 'GLOBAL.SUCCESSFUL_REQUEST',
        buttonInfos?.nextState ?? 5,
        buttonInfos?.mainPollingFunction ?? "mainPollingFunction",
        buttonInfos?.machineIdKey ?? 'machineId',
        buttonInfos?.reload ?? false
      );
    });
  }

  // EDIT ROW
  editRow(_this, infos, title = 'GLOBAL.EDIT_DIALOG') {

    let row = infos?.row ?? {};
    let tableInfos = infos?.tableInfos ?? [];
    let buttonInfos = infos?.buttonInfos ?? {};

    if (buttonInfos?.addStandardInformations) row = this.filterService.addStandardInformations(row);
    if (buttonInfos?.addRowsVariable) row.rowsVariable = infos.tableConfig?.rowsVariable;

    let data: any = {
      title: this.translate.instant(buttonInfos?.dialogTitle ?? title ?? 'GLOBAL.EDIT_DIALOG'),
      icon: buttonInfos?.icon,
      machineId: _this.machineId,
      row: this.clonerService.deepClone(row),
      tableInfos: this.clonerService.deepClone(tableInfos),
      labelFlex: buttonInfos?.dialogLabelFlex,
      profile: _this?.machine?.profile,
      machine: this.clonerService.deepClone(_this.machine)
    };

    if (buttonInfos?.intervalBetweenMinDateAndMaxDate) {
      let sortedList = infos.tableConfig?.rows?.sort(this.filterService.sortByProperty("timeStart", "desc", true));
      let currentIdx = sortedList?.findIndex(x => x?.timeStart == row?.timeStart);
      data.minDate = sortedList?.[currentIdx + 1]?.timeEnd;
      data.maxDate = sortedList?.[currentIdx - 1]?.timeStart;
      if (data.minDate != null) data.minDate = this.intervalService.unformatStringDate(data.minDate, data.machine?.timezone);
      if (data.maxDate != null) data.maxDate = this.intervalService.unformatStringDate(data.maxDate, data.machine?.timezone);
    }

    const multipleInputDialog = this.dialog.open(MultipleInputDialogComponent, {
      panelClass: 'ff-dialog',
      width: buttonInfos?.dialogWidth ?? "30%",
      data: data,
    });

    multipleInputDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') this.sendRequest(_this, result, buttonInfos?.endpointUrl ?? '/apif/edit-something');
    });
  }

  // DELETE ROW
  deleteRow(_this, infos, title = 'GLOBAL.CONFIRMATION_DIALOG') {

    let row = infos?.row ?? {};
    let tableInfos = infos?.tableInfos ?? [];
    let buttonInfos = infos?.buttonInfos ?? {};

    if (buttonInfos?.addStandardInformations) row = this.filterService.addStandardInformations(row);
    if (buttonInfos?.addRowsVariable) row.rowsVariable = infos.tableConfig?.rowsVariable;

    const multipleInputDialog = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? title ?? 'GLOBAL.CONFIRMATION_DIALOG'),
        icon: buttonInfos?.icon,
      },
    });

    multipleInputDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') this.sendRequest(_this, row, buttonInfos?.endpointUrl ?? '/apif/delete-something');
    });
  }

  // HEATMAP
  async heatmapAggrConsDetail(_this, infos) {

    try {

      // let title = this.translate.instant("GLOBAL.COST_PER_PIECE");
      let title = '';
      title += this.translate.instant(infos?.xVal?.label);
      title += ' - ' + this.translate.instant(infos?.yVal?.label);
      title += ' - ' + _this.intervalConfig.selected.startF;

      this.dialog.open(FfDashboardDialogComponent, {
        panelClass: 'ff-dialog',
        width: "80%",
        height: "80%",
        data: {
          title: title,
          type: "await",
          _this: _this,
          infos: infos,
        },
      });

    } catch (error) {
      console.log(error)
    }
  }

  // // // // // // // // // // // // // //
  // CMMS
  // // // // // // // // // // // // // //

  // MAINTENANCE INTERVENTION (ADD / EDIT)
  maintenanceIntervention(_this, infos, title = 'GLOBAL.ADD_DIALOG') {

    let row = infos?.row ?? {};
    let tableInfos = infos?.tableInfos ?? [];
    let buttonInfos = infos?.buttonInfos ?? {};

    console.log({ infos });

    const maintInterventionDialog = this.dialog.open(MaintenanceInterventionDialogComponent, {
      panelClass: 'ff-dialog',
      // width: "30%",
      width: buttonInfos?.dialogWidth ?? "30%",
      minWidth: buttonInfos?.dialogMinWidth ?? '30vw',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? title ?? 'GLOBAL.ADD_DIALOG'),
        icon: buttonInfos?.icon,
        machineId: _this.machineId,
        machine: _this.machine,
        row: this.clonerService.deepClone(row),
        tableInfos: this.clonerService.deepClone(tableInfos),
        dialogType: buttonInfos?.dialogType ?? 'add',
        componentsSelectionWidget: this.clonerService.deepClone(buttonInfos?.componentsSelectionWidget ?? {}),
        activitiesSelectionWidget: this.clonerService.deepClone(buttonInfos?.activitiesSelectionWidget ?? {}),
        componentsTableConfig: this.clonerService.deepClone(buttonInfos?.componentsTableConfig ?? {}),
        activitiesTableConfig: this.clonerService.deepClone(buttonInfos?.activitiesTableConfig ?? {}),
        filesTableConfig: this.clonerService.deepClone(buttonInfos?.filesTableConfig ?? {}),
        labelFlex: buttonInfos?.dialogLabelFlex,
        profile: _this?.machine?.profile,
      },
    });

    maintInterventionDialog.afterClosed().subscribe((result: any) => {

      _this.cacheService.set("addMaintenanceIntervention", null);
      if (result != null && result != '') {
        _this.pageState.next(4);

        setTimeout(() => {

          try {
            _this.pageState.next(5);
            _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
          } catch (error) { console.log(error) }
          _this.internalDataService.openSnackBar(_this.translate.instant('GLOBAL.SUCCESSFUL_REQUEST'), 'right', 'bottom', 4000, '', ["success"]);

        }, 500);
      }
    });
  }

  // DELETE MAINTENANCE INTERVENTION
  deleteMaintenance(_this, infos, title = 'GLOBAL.ADD_DIALOG') {

    let row = infos?.row ?? {};
    let tableInfos = infos?.tableInfos ?? [];
    let buttonInfos = infos?.buttonInfos ?? {};

    console.log({ infos });

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? title ?? 'GLOBAL.CONFIRMATION_DIALOG'),
        icon: buttonInfos?.icon,
      },
    });

    confirmationDialog.afterClosed().subscribe((result: any) => {
      console.log(result);

      if (result != null && result != '') {

        _this.pageState.next(4);
        let endpointUrl = buttonInfos?.endpointUrl?.replaceAll("{machineId}", _this.machineId).replaceAll("{maintId}", row.id) ?? '/apif/delete-something';
        console.log({ endpointUrl });

        let query = {
          interventionType: row?.interventionType,
          tz: _this?.machine?.timezone,
        };


        let payload = _this.internalDataService.buildMachinePayload(_this.machine);
        let requestSent = _this.apiService.sendPostRequest(endpointUrl, payload, query);

        requestSent.pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => {
            console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);
            let errorData = {
              type: 0,
              status: error.status,
              message: error.statusText
            };

            try {
              _this.pageState.next(5);
              _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
            } catch (error) { console.log(error) }
            _this.internalDataService.openSnackBar(_this.translate.instant('GLOBAL.FAILED_REQUEST'), 'right', 'bottom', 4000, '', ["fail"]);


            return throwError(errorData.status + ': ' + errorData.message);
          })
        )
          .subscribe(
            (data: any) => {
              // console.log(data.body);
              try {
                _this.pageState.next(5);
                _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
              } catch (error) { console.log(error) }
              _this.internalDataService.openSnackBar(_this.translate.instant('GLOBAL.SUCCESSFUL_REQUEST'), 'right', 'bottom', 4000, '', ["success"]);

            }
          );

      }
    });
  }

  // MAINTENANCE COMPONENT DETAIL
  maintComponentDetail(_this, infos, title = 'GLOBAL.ADD_DIALOG') {

    let row = infos?.row ?? {};
    let tableInfos = infos?.tableInfos ?? [];
    let buttonInfos = infos?.buttonInfos ?? {};

    console.log({ infos });

    const maintComponentDialog = this.dialog.open(MaintenanceComponentDialogComponent, {
      panelClass: 'ff-dialog',
      // width: "30%",
      width: buttonInfos?.dialogWidth ?? "30%",
      minWidth: buttonInfos?.dialogMinWidth ?? '30vw',
      maxWidth: buttonInfos?.dialogMaxWidth ?? '90vw',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? title ?? 'GLOBAL.ADD_DIALOG'),
        icon: buttonInfos?.icon,
        machineId: _this.machineId,
        endpointUrl: buttonInfos?.endpointUrl,
        row: this.clonerService.deepClone(row),
        widgets: this.clonerService.deepClone(buttonInfos?.widgets ?? []),
        tableInfos: this.clonerService.deepClone(tableInfos),
        rowType: buttonInfos?.rowType,
        labelFlex: buttonInfos?.dialogLabelFlex,
        newComponentLabel: buttonInfos?.newComponentLabel,
        buttonInfos: this.clonerService.deepClone(buttonInfos),
        machine: _this?.machine,
      },
    });

    maintComponentDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        _this.pageState.next(4);

        setTimeout(() => {
          try {
            _this.pageState.next(5);
            _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
          } catch (error) { console.log(error) }
        }, 500);
      }
    });
  }

  // MAINTENANCE BOM GENERATOR
  maintBomGenerator(_this, infos, title = 'MAINTENANCE.BOM_LONG') {

    let row = infos?.row ?? {};
    let tableInfos = infos?.tableInfos ?? [];
    let buttonInfos = infos?.buttonInfos ?? {};

    console.log({ infos });

    const maintBomGeneratorDialog = this.dialog.open(MaintenanceBomGeneratorDialogComponent, {
      panelClass: 'ff-dialog',
      // width: "30%",
      width: buttonInfos?.dialogWidth ?? "30%",
      minWidth: buttonInfos?.dialogMinWidth ?? '30vw',
      maxWidth: buttonInfos?.dialogMaxWidth ?? '90vw',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? title ?? 'GLOBAL.ADD_DIALOG'),
        buttonInfos: this.clonerService.deepClone(buttonInfos),
        icon: buttonInfos?.icon,
        endpointUrl: buttonInfos?.endpointUrl,
        generateAutoDateEndpointUrl: buttonInfos?.generateAutoDateEndpointUrl,
        machineId: _this.machineId,
        timezone: _this.machine.timezone,
        machine: _this.machine,
        user: _this.userData?.user_name,
        dashboardData: _this.dashboardData,
        row: this.clonerService.deepClone(row),
        tableInfos: this.clonerService.deepClone(tableInfos),
        dialogType: buttonInfos?.dialogType ?? 'add',
        componentsSelectionWidget: this.clonerService.deepClone(buttonInfos?.componentsSelectionWidget ?? {}),
        activitiesSelectionWidget: this.clonerService.deepClone(buttonInfos?.activitiesSelectionWidget ?? {}),
        componentsTableConfig: this.clonerService.deepClone(buttonInfos?.componentsTableConfig ?? {}),
        activitiesTableConfig: this.clonerService.deepClone(buttonInfos?.activitiesTableConfig ?? {}),
        filesTableConfig: this.clonerService.deepClone(buttonInfos?.filesTableConfig ?? {}),
        labelFlex: buttonInfos?.dialogLabelFlex,
        profile: _this?.machine?.profile,
      },
    });

    maintBomGeneratorDialog.afterClosed().subscribe((result: any) => {

      // if (result != null && result != '') {
      //   _this.pageState.next(4);

      //   setTimeout(() => {

      //     try {
      //       _this.pageState.next(5);
      //       _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
      //     } catch (error) { console.log(error) }
      //     _this.internalDataService.openSnackBar(_this.translate.instant('GLOBAL.SUCCESSFUL_REQUEST'), 'right', 'bottom', 4000, '', ["success"]);

      //   }, 500);
      // }
    });
  }

  // ADD NEW COMPONENT
  addComponent(_this, infos, title = 'MAINTENANCE_STATE.ADD_COMPONENT') {

    // console.log({ infos });

    let row = infos?.row ?? {};
    let tableInfos = infos?.tableInfos ?? [];
    let buttonInfos = infos?.buttonInfos ?? {};

    tableInfos = this.clonerService.deepClone(tableInfos);

    if (buttonInfos.clickFunction == 'addComponent') {
      tableInfos = tableInfos.reduce((acc, val) => {
        if (val.disabledInAddDialog != null) val.disabledInDialog = val.disabledInAddDialog;
        acc.push(val);
        return acc;
      }, []);
    }

    const addMaintCompDialog = this.dialog.open(AddMaintenanceComponentDialogComponent, {
      panelClass: 'ff-dialog',
      // width: "30%",
      width: buttonInfos?.dialogWidth ?? "30%",
      minWidth: buttonInfos?.dialogMinWidth ?? '30vw',
      maxWidth: buttonInfos?.dialogMaxWidth ?? '90vw',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? title ?? 'GLOBAL.ADD_DIALOG'),
        icon: buttonInfos?.icon,
        endpointUrl: buttonInfos?.endpointUrl,
        machineId: _this.machineId,
        timezone: _this.machine.timezone,
        machine: _this.machine,
        user: _this.userData?.user_name,
        row: this.clonerService.deepClone(row),
        tableInfos: this.clonerService.deepClone(tableInfos),
        rowType: buttonInfos?.rowType,
        labelFlex: buttonInfos?.dialogLabelFlex,
        newComponentLabel: buttonInfos?.newComponentLabel,
        buttonInfos: this.clonerService.deepClone(buttonInfos),
        profile: _this?.machine?.profile,
      },
    });

    addMaintCompDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        _this.pageState.next(4);

        setTimeout(() => {
          try {
            _this.pageState.next(5);
            _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
          } catch (error) { console.log(error) }
        }, 500);
      }
    });
  }

  async downloadAttachments(_this, infos, title) {

    let fileName = 'unknown';

    let buttonInfos = infos?.buttonInfos;

    if (!buttonInfos?.skipReload) _this.pageState.next(4);

    if (buttonInfos?.fileName) fileName = this.translate.instant(buttonInfos?.fileName);
    else fileName = infos?.row?.[buttonInfos?.fileNameKey ?? "timestamp"];

    let payload: any = { fileName: fileName, tz: _this?.machine?.timezone };
    if (infos?.row?.interventionType != null) payload.interventionType = infos?.row?.interventionType;

    try { payload.cmmsConfig = _this.machine?.profile?.cmmsConfig } catch (error) { }

    let url = '/apif/cmms/download-files/' + _this.machineId + '/' + infos?.row?.id;

    if (buttonInfos?.endpointUrl) url = buttonInfos.endpointUrl.replaceAll("{machineId}", _this.machineId).replaceAll("{id}", infos?.row?.id)

    let resp = await _this.apiService.asyncDownload(url, payload);
    _this.apiService.handleDownload(resp);

    if (!buttonInfos?.skipReload) {
      _this.pageState.next(5);
      _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
    }

  }

  async downloadImages(_this, infos, title) {

    _this.pageState.next(4);

    let imagesList = [];
    try {

      let tableInfoConfig = infos.tableConfig?.tableInfos?.find(x => x.imageRegex != null);
      let imagesPath = tableInfoConfig?.imageRegex;

      if (imagesPath != null) {
        imagesList = infos.tableConfig?.rowsFiltered?.reduce((acc, val) => {

          let imageName = infos?.buttonInfos?.imagesNameRegex;

          let finalName = '';
          imageName?.split("{")?.forEach((name, idx) => {
            if (idx == 0) finalName += name;
            else {
              let varName = name?.split("}")?.[0];
              let newName = name.replace(varName + '}', val?.[varName] ?? '-');
              finalName += newName;
            }
          });

          acc.push({
            path: imagesPath?.replaceAll("{image}", val?.[tableInfoConfig?.imageVariable]),
            name: finalName
          });
          return acc;
        }, []);
      }
    } catch (error) {
      console.log(error);

      return;
    }

    let fileName = 'unknown';

    if (infos?.buttonInfos?.fileName) fileName = this.translate.instant(infos?.buttonInfos?.fileName);
    else fileName = infos?.row?.[infos?.buttonInfos?.fileNameKey ?? "timestamp"];

    let payload = {
      fileName: fileName,
      imagesList: imagesList
    };

    let url = '/apif/cmms/download-files/' + _this.machineId + '/' + infos?.row?.id;

    if (infos?.buttonInfos?.endpointUrl) url = infos.buttonInfos.endpointUrl.replaceAll("{machineId}", _this.machineId).replaceAll("{id}", infos?.row?.id)

    let resp = await _this.apiService.asyncDownload(url, payload);
    _this.apiService.handleDownload(resp);
    _this.pageState.next(5);
    _this[infos?.buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);

  }

  downloadImage(_this, infos) {

    let row = infos?.row ?? {};
    let tableInfos = infos?.tableInfos ?? [];
    let buttonInfos = infos?.buttonInfos ?? {};

    let imageConfig = tableInfos?.find(x => x.variable == 'image');
    let image = null;

    try {

      // Image name directly in config
      if (imageConfig?.imageRegex == null) image = row[imageConfig.imageVariable];
      // Image with additional regexes
      else image = imageConfig.imageRegex?.replace("{image}", row[imageConfig.imageVariable]);

    } catch (error) { console.log(error) }

    let link = document.createElement('a');
    link.href = image;
    link.download = row.id;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  navigateToMachineRecorder(_this, infos) {

    try {

      let row = infos?.row ?? {};
      let tableInfos = infos?.tableInfos ?? [];
      let buttonInfos = infos?.buttonInfos ?? {};

      let currentTimestamp = row.timestamp;

      let timeStart = moment(currentTimestamp).subtract(30, 's').format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
      let timeEnd = moment(currentTimestamp).add(30, 's').format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

      let condAnalObj = {
        from: timeStart,
        to: timeEnd,
      }
      _this.cacheService.set('condAnalFromTo', condAnalObj);
      _this.router.navigate([_this.machineId + '/machine-recorder/continuous-exploration']);

    } catch (error) { console.log(error) }
  }

  // MAINTENANCE TEMPLATES
  maintenanceTemplates(_this, infos, title = 'MAINTENANCE_CALENDAR.VIEW_MAINTENANCE_TEMPLATES') {

    let row = infos?.row ?? {};
    let buttonInfos = infos?.buttonInfos ?? {};

    console.log({ infos });

    const maintenanceCalendarTemplatesDialog = this.dialog.open(MaintenanceCalendarTemplatesDialogComponent, {
      panelClass: 'ff-dialog',
      // width: "30%",
      width: buttonInfos?.dialogWidth ?? "30%",
      minWidth: buttonInfos?.dialogMinWidth ?? '30vw',
      maxWidth: buttonInfos?.dialogMaxWidth ?? '90vw',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? title ?? 'GLOBAL.ADD_DIALOG'),
        icon: buttonInfos?.icon,
        machineId: _this.machineId,
        buttonInfos: this.clonerService.deepClone(buttonInfos),
        tableInfos: this.clonerService.deepClone(buttonInfos?.tableInfos),
        activitiesSelectionWidget: this.clonerService.deepClone(buttonInfos?.activitiesSelectionWidget ?? {}),
        activitiesTableConfig: this.clonerService.deepClone(buttonInfos?.activitiesTableConfig ?? {}),
        row: this.clonerService.deepClone(row),
        profile: _this?.machine?.profile,
        calendarEvents: _this.dashboardData?.list,
      },
    });

    maintenanceCalendarTemplatesDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        _this.pageState.next(4);

        setTimeout(() => {

          try {
            _this.pageState.next(5);
            _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
          } catch (error) { console.log(error) }
          // _this.internalDataService.openSnackBar(_this.translate.instant('GLOBAL.SUCCESSFUL_REQUEST'), 'right', 'bottom', 4000, '', ["success"]);

        }, 500);
      }
    });
  }

  // IMPORT EXCEL
  importExcel(_this, infos, title = 'MAINTENANCE_CALENDAR.VIEW_MAINTENANCE_TEMPLATES') {

    let row = infos?.row ?? {};
    let buttonInfos = infos?.buttonInfos ?? {};

    console.log({ infos });

    const importExcelDialog = this.dialog.open(ImportExcelDialogComponent, {
      panelClass: 'ff-dialog',
      width: 'fit-content',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? title ?? 'GLOBAL.EXCEL'),
        icon: buttonInfos?.icon,
        machineId: _this.machineId,
        buttonInfos: this.clonerService.deepClone(buttonInfos),
        tableInfos: this.clonerService.deepClone(buttonInfos?.tableInfos),
        activitiesSelectionWidget: this.clonerService.deepClone(buttonInfos?.activitiesSelectionWidget ?? {}),
        activitiesTableConfig: this.clonerService.deepClone(buttonInfos?.activitiesTableConfig ?? {}),
        row: this.clonerService.deepClone(row),
        profile: _this?.machine?.profile,
        tz: _this?.machine?.timezone,
        calendarEvents: _this.dashboardData?.list,
      },
    });

    importExcelDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {

        console.log(result);

        _this.pageState.next(4);

        setTimeout(() => {

          try {
            _this.pageState.next(5);
            _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
          } catch (error) { console.log(error) }
          // _this.internalDataService.openSnackBar(_this.translate.instant('GLOBAL.SUCCESSFUL_REQUEST'), 'right', 'bottom', 4000, '', ["success"]);

        }, 500);
      }
    });
  }

  // // // // // // // // // // // // // //
  // INGENYA
  // // // // // // // // // // // // // //

  // IMAGE DETAIL
  ingenyaImageDetail(_this, infos, title = 'INGENYA.IMAGE_DIALOG') {

    let row = infos?.row ?? {};
    let tableInfos = infos?.tableInfos ?? [];
    let buttonInfos = infos?.buttonInfos ?? {};

    const newDialog = this.dialog.open(IngenyaImageDialogComponent, {
      panelClass: 'ff-dialog',
      // width: "30%",
      width: buttonInfos?.dialogWidth ?? "30%",
      minWidth: buttonInfos?.dialogMinWidth ?? '30vw',
      maxWidth: buttonInfos?.dialogMaxWidth ?? '90vw',
      data: {
        // title: this.translate.instant(buttonInfos?.dialogTitle ?? title ?? 'GLOBAL.ADD_DIALOG') + ' - ' + row?.id,
        title: this.translate.instant(buttonInfos?.dialogTitle ?? title ?? 'GLOBAL.ADD_DIALOG'),
        icon: buttonInfos?.icon,
        machineId: _this.machineId,
        endpointUrl: buttonInfos?.endpointUrl,
        row: this.clonerService.deepClone(row),
        widgets: this.clonerService.deepClone(buttonInfos?.widgets ?? []),
        tableInfos: this.clonerService.deepClone(tableInfos),
        customActionButtons: this.clonerService.deepClone(buttonInfos?.customActionButtons ?? []),
        profile: _this?.machine?.profile,
        completeTable: _this.dashboardData?.table ?? [],
      },
    });

    newDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        _this.pageState.next(4);

        setTimeout(() => {
          try {
            _this.pageState.next(5);
            _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
          } catch (error) { console.log(error) }
        }, 500);
      }
    });
  }

  downloadModel(_this, infos) {

    let row = infos?.row ?? {};
    let buttonInfos = infos?.buttonInfos ?? {};

    let link = document.createElement('a');
    link.href = infos?.buttonInfos?.path != null ? (infos?.buttonInfos?.path?.replace("{model}", row?.[buttonInfos?.variable ?? 'filename'])) : "/models/model.h5";
    link.download = "model.h5";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  downloadFromPath(_this, infos) {

    let row = infos?.row ?? {};
    let buttonInfos = infos?.buttonInfos ?? {};

    // Multiple files
    if (buttonInfos?.paths?.length > 0) buttonInfos?.paths?.forEach(x => this.filterService.downloadFromBrowser(x));
    // Single file
    else this.filterService.downloadFromBrowser(buttonInfos?.path, buttonInfos?.fileName);
  }

  sendRetrainModelRequest(_this, infos) {
    this.sendRequest(_this, {}, infos?.buttonInfos?.endpointUrl ?? "/apif/ingenya/send-retrain-model-request/")
  }

  // MACHINE DOCUMENTATION

  async viewFile(_this, infos) {

    let row: any = this.clonerService.deepClone(infos?.row);

    let resp: any = null;

    if (row.type != 'folder' && row.type != 'pdf') {
      // Get file content from BE
      let url = '/apif/machine-documentation/get-file-content/' + _this.machineId;
      if (infos?.buttonInfos?.endpointUrl) url = infos.buttonInfos.endpointUrl.replaceAll("{machineId}", _this.machineId);

      let payload = { filePath: row?.completePath };

      resp = await _this.apiService.asyncDownload(url, payload, null, "arraybuffer");
    }

    // console.log({ row });

    switch (row.type) {
      case 'image':

        _this.dialog.open(DisplayImageDialogComponent, {
          panelClass: 'ff-dialog',
          data: {
            // title: row?.completePath ?? 'unknown',
            title: row?.name ?? 'unknown',
            imageUrl: resp?.body
          }
        });
        break;
      case 'pdf':

        _this.dialog.open(EditPdfDialogComponent, {
          panelClass: 'ff-dialog',
          width: "30%",
          // minWidth: '90vw',
          maxWidth: '90vw',
          data: {
            // title: row?.completePath ?? 'unknown',
            title: row?.name ?? 'unknown',
            machineId: _this.machineId,
            row: this.clonerService.deepClone(row),
            buttonInfos: this.clonerService.deepClone(infos?.buttonInfos ?? {}),
          }
        });
        break;
      case 'folder':

        var actualObj: any = this.clonerService.deepClone(_this.firstRequest);

        // Create currentUrlPath object
        try {
          _this.currentUrlPath = row.completePath.split("/").reduce((acc, val, index) => {
            if (index > 1) actualObj = actualObj.elements.find(x => x.name == val);
            if (index > 0) acc.push(actualObj);
            return acc;
          }, []);
        } catch (error) { console.log(error) }

        _this.pageState.next(5);
        _this.handleResponse(_this, row);

        break;

      default:
        this.internalDataService.openSnackBar(this.translate.instant('FILE_EXPLORER.NO_CLICK_FUNCTION_CONFIGURED', {
          clickFunction: row.type ?? '-'
        }), 'right', 'bottom', 4000, '', ["warning"]);
        return;
    }

  }

  async downloadFile(_this, infos) {

    _this.pageState.next(4);

    let row: any = this.clonerService.deepClone(infos?.row);

    _this.loadingData = {
      message: this.translate.instant("GLOBAL.DOWNLOADING_FILE", {
        fileName: row?.name,
      }),
    };

    let url: any = '/apif/machine-documentation/get-file-content/' + _this.machineId;

    let payload: any = {
      filePath: row?.completePath,
      disablePdfEditing: this.appConfig.disablePdfEditing
    };

    let resp: any = null;

    switch (row.type) {
      case 'folder':

        // Get zipped folder content from BE
        if (infos?.buttonInfos?.folderEndpointUrl) url = infos.buttonInfos.folderEndpointUrl.replaceAll("{machineId}", _this.machineId);

        try {
          resp = await _this.apiService.asyncDownload(url, payload, null, "arraybuffer");
          _this.apiService.handleDownload(resp);
        } catch (error) {
          console.log(error);
        }

        break;

      default:
        // this.internalDataService.openSnackBar(this.translate.instant('FILE_EXPLORER.NO_CLICK_FUNCTION_CONFIGURED', {
        //   clickFunction: row.type ?? '-'
        // }), 'right', 'bottom', 4000, '', ["warning"]);

        // Get file content from BE
        if (infos?.buttonInfos?.endpointUrl) url = infos.buttonInfos.endpointUrl.replaceAll("{machineId}", _this.machineId);

        try {
          resp = await _this.apiService.asyncDownload(url, payload, null, "arraybuffer");
          _this.apiService.handleDownload(resp);
        } catch (error) {
          console.log(error);
        }

        break;
    }


    try {
      _this.pageState.next(5);
      _this.mainPollingFunction(_this);
    } catch (error) { console.log(error) }


  }

  async uploadFile(_this, infos) {

    let row = infos?.row ?? {};
    let buttonInfos = infos?.buttonInfos ?? {};

    // console.log(_this?.currentUrlPathString);

    let invalidValues = _this.dashboardData?.elements?.reduce((acc, val) => {
      if (val?.name?.split(".")?.length > 1) acc.push(val.name);
      return acc;
    }, []);

    // console.log({ invalidValues });

    const dialog = this.dialog.open(ImportFileDialogComponent, {
      panelClass: 'ff-dialog',
      width: buttonInfos?.dialogWidth ?? "fit-content",
      // minWidth: buttonInfos?.dialogMinWidth ?? 'fit-content',
      // maxWidth: buttonInfos?.dialogMaxWidth ?? 'fit-content',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? 'FILE_EXPLORER.UPLOAD_FILE'),
        icon: buttonInfos?.icon,
        machineId: _this.machineId,
        buttonInfos: this.clonerService.deepClone(buttonInfos),
        invalidValues: invalidValues,
        row: this.clonerService.deepClone(row),
        profile: _this?.machine?.profile,
        urlPath: _this?.currentUrlPathString,
      },
    });

    dialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {

        console.log(result);

        _this.pageState.next(4);

        setTimeout(() => {

          try {
            _this.pageState.next(5);
            _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
          } catch (error) { console.log(error) }
          // _this.internalDataService.openSnackBar(_this.translate.instant('GLOBAL.SUCCESSFUL_REQUEST'), 'right', 'bottom', 4000, '', ["success"]);

        }, 500);
      }
    });
  }

  async deleteFile(_this, infos) {

    let row: any = this.clonerService.deepClone(infos?.row);
    let buttonInfos: any = this.clonerService.deepClone(infos?.buttonInfos);

    const confirmationDialog = this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? 'GLOBAL.CONFIRM_DELETE_FILE_OR_FOLDER', {
          type: this.translate.instant("GLOBAL." + (row?.type != 'folder' ? 'file' : 'folder')),
          name: row?.name,
        }),
        icon: buttonInfos?.icon,
      },
    });

    confirmationDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') {

        let url: any = '/apif/machine-documentation/delete-file/' + _this.machineId;
        let payload: any = { filePath: row?.completePath };

        switch (row.type) {
          case 'folder':

            if (buttonInfos?.folderEndpointUrl) url = buttonInfos.folderEndpointUrl.replaceAll("{machineId}", _this.machineId);
            this.sendRequest(_this, payload, url);
            break;

          default:

            if (buttonInfos?.endpointUrl) url = buttonInfos.endpointUrl.replaceAll("{machineId}", _this.machineId);
            this.sendRequest(_this, payload, url);

            return;
        }

      }
    });

  }

  async createFolder(_this, infos) {

    let row = infos?.row ?? {};
    let buttonInfos = infos?.buttonInfos ?? {};

    let invalidValues = _this.dashboardData?.elements?.reduce((acc, val) => {
      if (val?.name?.split(".")?.length == 1) acc.push(val.name);
      return acc;
    }, []);

    // console.log({ invalidValues });

    const dialog = this.dialog.open(CreateFolderDialogComponent, {
      panelClass: 'ff-dialog',
      width: 'fit-content',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? 'FILE_EXPLORER.NEW_FOLDER'),
        icon: buttonInfos?.icon,
        machineId: _this.machineId,
        buttonInfos: this.clonerService.deepClone(buttonInfos),
        row: this.clonerService.deepClone(row),
        invalidValues: invalidValues,
        profile: _this?.machine?.profile,
        urlPath: _this?.currentUrlPathString,
      },
    });

    dialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {

        console.log(result);

        _this.pageState.next(4);

        setTimeout(() => {

          try {
            _this.pageState.next(5);
            _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
          } catch (error) { console.log(error) }
          // _this.internalDataService.openSnackBar(_this.translate.instant('GLOBAL.SUCCESSFUL_REQUEST'), 'right', 'bottom', 4000, '', ["success"]);

        }, 500);
      }
    });
  }

  async getFATLogs(_this, infos, title = 'GLOBAL.ADD_DIALOG') {

    let row: any = this.clonerService.deepClone(infos?.row);
    let buttonInfos: any = this.clonerService.deepClone(infos?.buttonInfos);

    let url = `/apif/machine-documentation/get-fat-logs/${_this.machineId}`;

    if (infos?.buttonInfos?.endpointUrl) url = buttonInfos.endpointUrl.replaceAll("{machineId}", _this.machineId);

    let query = { fileId: row?.id };

    let data = await _this.apiService.sendPostRequest(url, null, query).toPromise();

    let resp = data?.body;

    if (resp?.update == null) {
      resp = {
        update: {
          status: -1
        }
      };
    }

    let actionName = this.translate.instant(infos?.tableConfig?.profile?.historicFatActions.find(x => x.id == row?.action)?.label);

    if (resp.update != null) {
      resp.update.status = resp.status;
      resp.update.exp_date = resp.exp_date;
      resp.update.uploadedFilesPerc = (resp.update.uploadedFiles ?? 0) / resp.update.totFiles;
      resp.update.deletedFilesPerc = (resp.update.deletedFiles ?? 0) / resp.update.totFiles;
      // resp.update.status = 3;
    }

    let actionId = row?.action ?? 'default';
    if (resp?.avoidFileDeletion) actionId = actionId + 'AvoidDeletion';

    let completeDashboardConfig = {
      dashboardData: resp?.update,
      dashboardConfig: { widgets: buttonInfos?.widgets?.[actionId] },
      machineProfile: _this.machine?.profile,
    };

    this.dialog.open(FfDashboardDialogComponent, {
      panelClass: 'ff-dialog',
      width: "60%",
      // height: "80%",
      data: {
        alternativeColor: true,
        title: actionName + ' - ' + this.filterService.parseMoment(row?.timestamp, "default", _this.machine.timezone),
        completeDashboardConfig: completeDashboardConfig
      },
    });

  }

  refreshPage(_this) {
    _this.router.navigateByUrl(_this.router.url);
  }

  // // // // // // // // // // // // // //
  // BARILLA START
  // // // // // // // // // // // // // //

  // MAINTENANCE COMPONENT DETAIL
  selectShift(_this, infos, title = 'GLOBAL.SELECT_SHIFT') {

    let row = infos?.row ?? {};
    let tableInfos = infos?.tableInfos ?? [];
    let buttonInfos = infos?.buttonInfos ?? {};

    console.log({ infos });

    const dialog = this.dialog.open(BarillaShiftSelectionDialogComponent, {
      panelClass: 'ff-dialog',
      // width: "30%",
      width: buttonInfos?.dialogWidth ?? "30%",
      minWidth: buttonInfos?.dialogMinWidth ?? '30vw',
      maxWidth: buttonInfos?.dialogMaxWidth ?? '90vw',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? title ?? 'GLOBAL.ADD_DIALOG'),
        icon: buttonInfos?.icon,
        machineId: _this.machineId,
        endpointUrl: buttonInfos?.endpointUrl,
        completePayload: _this.completePayload,
        row: this.clonerService.deepClone(row),
        widgets: this.clonerService.deepClone(buttonInfos?.widgets ?? []),
        tableInfos: this.clonerService.deepClone(tableInfos),
        rowType: buttonInfos?.rowType,
        labelFlex: buttonInfos?.dialogLabelFlex,
        buttonInfos: this.clonerService.deepClone(buttonInfos),
        machine: _this.machine,
        profile: _this?.machine?.profile,
      },
    });

    dialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        _this.pageState.next(4);

        if (_this.interval == null) _this.interval = {};
        _this.interval.start = result.shiftStart;
        _this.interval.end = result.shiftEnd;

        _this.historicShiftMode = true;
        _this.shiftId = result.label;
        _this.cacheService.set("historicShiftConfig", result);

        if (moment(_this.interval.end).diff(moment()) > 0) {
          _this.interval.end = "undefined";
          _this.historicShiftMode = false;
          _this.cacheService.set("historicShiftConfig", null);
        }

        _this.cacheService.set("historicShiftMode", _this.historicShiftMode);

        setTimeout(() => {
          try {
            _this.pageState.next(5);
            _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
          } catch (error) { console.log(error) }
        }, 500);
      }
    });
  }

  getCurrentShift(_this, infos, title = 'GLOBAL.SELECT_SHIFT') {

    let row = infos?.row ?? {};
    let tableInfos = infos?.tableInfos ?? [];
    let buttonInfos = infos?.buttonInfos ?? {};

    console.log({ infos });
    _this.pageState.next(4);

    _this.cacheService.set("historicShiftConfig", null);
    _this.cacheService.set("historicShiftMode", null);

    _this.interval.end = "undefined";
    _this.historicShiftMode = false;
    _this.shiftId = null;

    setTimeout(() => {
      try {
        _this.pageState.next(5);
        _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
      } catch (error) { console.log(error) }
    }, 500);

  }

  selectWidgetGroups(_this, infos, title = 'GLOBAL.SELECT_ROLLING_MILLS') {

    let buttonInfos: any = infos?.buttonInfos ?? {};
    let referenceVariable = buttonInfos?.referenceVariable ?? "widgetGroups";
    let fromAdditionalVariables = buttonInfos?.fromAdditionalVariables;
    let maxSel = buttonInfos?.maxSel;

    let elem = _this[referenceVariable];

    if (fromAdditionalVariables) elem = _this.additionalVariables[referenceVariable];

    let valueToCheck = this.clonerService.deepClone(elem);
    if (buttonInfos?.cachedValueId && this.cacheService.get(buttonInfos?.cachedValueId) != null) {
      valueToCheck = this.cacheService.get(buttonInfos?.cachedValueId)
    }

    let groups = (Object.entries(valueToCheck ?? {}) as any)?.reduce((acc, [key, val]) => {
      if (acc.findIndex(x => x.id == key) == -1) acc.push({ ...{ id: key }, ...val });
      return acc;
    }, []);

    let filtersDialog = this.dialog.open(FiltersDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? title),
        variable: "id",
        options: groups,
        config: {
          maxSel: maxSel
        },
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') {
        // console.log('afterClosed', result);

        let val = result?.options?.reduce((acc, val) => {
          acc[val.id] = { selected: val.selected, label: val.label };
          return acc;
        }, {});

        if (!fromAdditionalVariables) _this.widgetGroups = val;
        else elem = val;

        if (buttonInfos?.cachedValueId) {
          this.cacheService.set(buttonInfos?.cachedValueId, this.clonerService.deepClone(elem));
        }
        // console.log(_this.widgetGroups);

        // TODO: optimize and avoid recalling the main function. At the moment widgetGroups is not updated if the function is not called

        // setTimeout(() => {
        try {
          _this.pageState.next(5);
          _this[buttonInfos?.mainPollingFunction ?? "mainPollingFunction"](_this);
        } catch (error) { console.log(error) }
        // }, 500);

      }
    });


  }

  async barillaNavigateToPairCorrelations(_this, infos) {

    this.cacheService.set("selectedBarillaBatch", infos?.value?.parent_batch_id);
    this.cacheService.set("selectedBarillaVariable", infos?.yVariable);

    // console.log(this.cacheService.get("selectedBarillaBatch"));
    // console.log(this.cacheService.get("selectedBarillaVariable"));

    this.router.navigate([_this.machineId + '/standard-dashboard-table-page'], {
      queryParams: {
        sectionName: "subbatchesAnalysis",
        tabName: "pairCorrelations"
      }
    });

  }

  // // // // // // // // // // // // // //
  // BARILLA END
  // // // // // // // // // // // // // //

  // AGGREGATED TABLE DETAIL
  tableDetail(_this, infos) {

    let range: any = {
      from: infos?.row?.timeStart,
      to: infos?.row?.timeEnd,
    };

    if (range != null && range.from != null && range.to != null) {
      // console.log('range', range);

      _this.showResetInterval = true;

      _this.interval.id = 'custom';
      _this.interval.startDate = moment.tz(range.from, _this.machine.timezone).toDate(); //.add(100, 'ms').toDate();
      _this.interval.endDate = moment.tz(range.to, _this.machine.timezone).toDate(); //.add(100, 'ms').toDate();

      _this.interval.start_shortDate = moment.tz(range.from, _this.machine.timezone).format('MMM DD, YYYY - HH:mm:ss')
      _this.interval.end_shortDate = moment.tz(range.to, _this.machine.timezone).format('MMM DD, YYYY - HH:mm:ss')
      _this.interval.startF = _this.interval.start_shortDate + ' - ' + _this.interval.end_shortDate;

      _this.interval.start = JSON.parse(JSON.stringify(_this.interval.startDate));
      _this.interval.end = JSON.parse(JSON.stringify(_this.interval.endDate));

      _this.interval.enabledPolling = false;

      _this.intervalConfig = {
        list: _this.intervalService.getDefaultIntervals(_this.defaultIntervalListId, _this.machine.timezone),
        selected: _this.interval
      };

      if (_this.cachedIntervalId != null) _this.cacheService.set(_this.cachedIntervalId, _this.intervalConfig);

      _this.pageState.next(5);
      _this.mainPollingFunction(_this);

    }
  }

  openHtmlDialog(_this, infos, title = "TITLE") {

    let buttonInfos = infos?.buttonInfos;

    this.dialog.open(ConfirmationDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? title),
        description: buttonInfos?.description,
        hideButtons: true,
        bindHTML: true
      },
    });
  }


  // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // CONFIRM ACTION - BUILD DESCRIPTION FUNCTIONS

  // SHIFT VALIDATION - GOGLIO MAT2
  shiftValidation(_this, infos) {

    // console.log({ infos });

    let row = infos?.row ?? {};

    let labelToTranslate = row?.validity ? 'SHIFT_INVALIDATION.INVALIDATE_SHIFT' : 'SHIFT_INVALIDATION.VALIDATE_SHIFT';

    return this.translate.instant(labelToTranslate, {
      // shiftId: row?.["calendar.shiftIdx"] ?? 'unknown',
      shiftId: row?.shiftId ?? 'unknown',
      day: row.day
    });

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // COMMON FUNCTIONS

  // SEND REQUEST
  sendRequest(
    _this,
    payload,
    endpointUrl,
    failedRequestTitle = 'GLOBAL.FAILED_REQUEST',
    successfulRequestTitle = 'GLOBAL.SUCCESSFUL_REQUEST',
    nextState = 5,
    mainPollingFunction = "mainPollingFunction",
    machineIdKey = 'machineId',
    reload = false,
    gentleReload = false,
  ) {

    _this.pageState.next(4);

    if (endpointUrl == null) return;

    if (_this.machineId != null) endpointUrl = endpointUrl?.replaceAll("{machineId}", _this.machineId);

    let method = 'POST';
    if (endpointUrl.includes(':')) {
      endpointUrl = endpointUrl.split(':');
      method = endpointUrl[0];
      endpointUrl = endpointUrl[1];
    }

    if (!endpointUrl.endsWith('/')) endpointUrl += '/';
    let url = endpointUrl + _this[machineIdKey];

    let query: any = {
      tz: _this.machine?.timezone
    };

    if (this.cacheService.get("enableSAT")) query.enableSAT = true;

    console.log(payload);
    let requestSent = null;
    if (method == 'POST' || method == null) requestSent = this.apiService.sendPostRequest(url, payload, query);
    else if (method == 'PUT') requestSent = this.apiService.sendPutRequest(url, payload, query);
    else if (method == 'DELETE') requestSent = this.apiService.sendDeleteRequest(url, payload);
    else if (method == 'PATCH') requestSent = this.apiService.sendPatchRequest(url, payload, query);
    else if (method == 'CUSTOM') requestSent = this.apiService.sendCustomPayloadWithArrayBuffer(url, payload);


    requestSent.pipe(
      retryWhen(this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
      catchError(error => {
        console.log((error.error instanceof ErrorEvent) ? error.error.message : error.message);
        let errorData = {
          type: 0,
          status: error.status,
          message: error.statusText
        };

        this.internalDataService.openSnackBar(this.translate.instant(failedRequestTitle ?? 'GLOBAL.FAILED_REQUEST'), 'right', 'bottom', 4000, '', ["fail"]);

        try {
          _this.pageState.next(nextState);
          _this[mainPollingFunction](_this);
        } catch (error) { console.log(error) }

        if (gentleReload) this.refreshPage(_this);

        return throwError(errorData.status + ': ' + errorData.message);
      })
    )
      .subscribe(
        (data: any) => {
          // console.log(data.body);

          this.internalDataService.openSnackBar(this.translate.instant(successfulRequestTitle ?? 'GLOBAL.SUCCESSFUL_REQUEST'), 'right', 'bottom', 4000, '', ["success"]);

          try {
            _this.pageState.next(nextState);
            _this[mainPollingFunction](_this);
          } catch (error) { console.log(error) }

          if (reload) window.location.reload();
          if (gentleReload) this.refreshPage(this);

        }
      );

  }
}
