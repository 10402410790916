import { Component, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConnectionTimelineDialogComponent } from 'src/app/components/connection-timeline-dialog/connection-timeline-dialog.component';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { MobileService } from 'src/app/services/mobile.service';
import { MachinesFiltersPipe } from '../machines.filters';

@Component({
  selector: 'connected-machines-list',
  templateUrl: './connected-machines-list.component.html',
  styleUrls: ['./connected-machines-list.component.scss']
})
export class ConnectedMachinesListComponent implements OnInit, OnChanges, OnDestroy {

  public appConfig: any;

  machineProfiles: any = [];
  machineFiltersPipe: any = new MachinesFiltersPipe();

  public homepageDataSub: any;
  public mFilters: any;

  public machinesListSub: Subscription;
  public machines: any;

  public mobileListener: Subscription;
  public isMobile: any;
  public errorDataMobile: any;

  tableConfig: any;

  constructor(
    private internalDataService: InternalDataService,
    public appConfigService: AppConfigService,
    public filterService: FiltersService,
    public mobile: MobileService,
    public cacheService: CacheService,
    private router: Router,
    public translate: FfTranslateService,
    public clonerService: ClonerService,
    public dialog: MatDialog,
  ) {

    this.appConfig = this.appConfigService.getAppConfig;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })

    this.tableConfig = {
      ...(this.appConfig?.homepage?.connectedMachinesList?.connectedMachinesTableConfig ?? {}), ...{
        rows: this.parseMachineList(this.machines),
        rowsFiltered: this.parseMachineList(this.machines),
      }
    }

    this.homepageDataSub = this.internalDataService.homepageData.subscribe(value => {
      this.mFilters = value.filters;

      this.tableConfig.rows = this.parseMachineList(this.machines);
      this.tableConfig = { ...this.tableConfig };
    });

    this.machinesListSub = this.internalDataService.machinesList.subscribe(value => {

      this.machines = value;

      this.tableConfig.rows = this.parseMachineList(this.machines);
      this.tableConfig = { ...this.tableConfig };
    });

  }

  parseMachineList(data) {

    let selectedMachineId = this.cacheService.get("machineId");
    let parsedData = this.machineFiltersPipe.transform(data, this.mFilters)?.reduce((acc, val) => {

      if (selectedMachineId != null && val?.machineId == selectedMachineId) val.selected = true;
      else val.selected = false;

      val.disabledButtons = {
        openStatesDetailDialog: val?.stateUI == null || val?.connectionString == 'neverConnected'
      }
      acc.push(val);
      return acc;
    }, []);

    // console.log({ parsedData });

    return parsedData;

  }

  trackById = (index, machine: any): string => {
    return machine.machineId;
  };

  selectMachine(machine: any) {
    this.internalDataService.setMachineSelected(machine);
  }

  ngOnInit(): void {
  }

  tableAction(item) {

    console.log({ item });

    let buttonInfos: any = this.clonerService.deepClone(item?.buttonInfos ?? {})
    let row: any = this.clonerService.deepClone(item?.row ?? {})
    let tableInfos: any = this.clonerService.deepClone(item?.tableInfos ?? [])

    if (buttonInfos?.clickFunction == 'openStatesDetailDialog') {

      const confirmationDialog = this.dialog.open(ConnectionTimelineDialogComponent, {
        panelClass: 'ff-dialog',
        width: buttonInfos?.dialogWidth ?? "50%",
        data: {
          title: this.translate.instant('HOMEPAGE.MACHINE_CONNECTION_TIMELINE', {
            machineName: row?.machineName ?? row?.name
          }),
          icon: buttonInfos?.icon,
          machineId: row?.machineId,
          row: this.clonerService.deepClone(row),
          tableInfos: this.clonerService.deepClone(tableInfos),
          labelFlex: buttonInfos?.dialogLabelFlex,
          profile: row?.profile,
        },
      });

    }

    else if (buttonInfos?.clickFunction == 'selectMachine') {
      this.selectMachine(row);
      this.tableConfig.rows = this.parseMachineList(this.machines);
      this.tableConfig = { ...this.tableConfig };
    }

  }

  ngOnChanges() { }

  ngOnDestroy() {
    try { this.homepageDataSub.unsubscribe() } catch (error) { }
    try { this.machinesListSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) {

    }
  }
}