<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="row wrap" fxLayoutAlign="start stretch" class="w100">

  <div *ngIf="isMobile" fxFlex fxLayout="column" class="tab-content h100 w100" fxLayoutAlign="center center">
    <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
  </div>

  <mat-card *ngIf="!isMobile" class="w100">
    <ff-table-sortable [tableConfig]="tableConfig" (clicked)="onButtonClick($event)"></ff-table-sortable>
  </mat-card>

</div>