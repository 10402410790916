import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';

import { CacheService } from 'src/app/services/cache.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'app-brand-consumption',
  templateUrl: './brand-consumption.component.html',
  styleUrls: ['./brand-consumption.component.scss']
})
export class BrandConsumptionComponent implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public isMobile: any;
  public mobileListener: Subscription;

  public machineProfiles: any;

  public productionColumns: string[] = [];

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public mainAggregation: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public pollingTime: any;
  public pollingEvents: any;

  public maxStringsLength: any;

  public aggregations: any;
  public aggregationsPayload: any;
  public aggregationsCustom: any;

  public consDropdown: any = null;
  public consIcon: any = null;

  public excludeLine: boolean = true;

  public interval: any;
  public intervalConfig: any;
  public intervalAggregations: any;

  public dashboardConfig: any;

  public aggrList: any;
  public aggrId: any;

  public consumables: any = [];
  public brandSelectionButton: any;

  public consData: any;
  public colors: any = [
    '#66DA26', '#546E7A', '#E91E63', '#FF9800',
    "#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D",
    "#33B2DF", "#D4526E", "#13D8AA", "#A5978B",
    "#4ECDC4", "#C7F464", "#81D4FA", "#FD6A6A",
    "#2B908F", "#F9A3A4", "#90EE7E", "#FA4443",
    "#449DD1", "#F86624", "#EA3546", "#662E9B",
    "#D7263D", "#1B998B", "#2E294E", "#F46036",
    "#F9C80E", "#43BCCD", "#69D2E7", '#008FFB',
    "#5C4742", "#8D5B4C", "#5A2A27", "#C4BBAF",
    "#A300D6", "#7D02EB", "#5653FE", "#2983FF",
    "#C5D86D", "#E2C044", "#00B1F2",
  ];

  public sectionName: any = 'consumptionAnalytics';
  public dashboardName: any = 'brand-consumption';

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 7;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getBrands, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getEventsBreakdowns, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    public mobile: MobileService,
  ) {
    this.mobile.mobileListener.subscribe((value) => this.isMobile = value.isMobile)
    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['BRAND_CONSUMPTION.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    try {
      this.aggrList = this.appConfig[this.sectionName].aggrList;
    } catch (error) { }

    try {
      this.aggrId = this.appConfig[this.sectionName].aggrListDefault;
    } catch (error) { }

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig.consumptionAnalytics.polling != null ? this.appConfig.consumptionAnalytics.polling : 0;
    this.pollingEvents = Subscription;

    this.consData = null

    this.brandSelectionButton = {
      // label: this.mainAggregation.label,
      variable: 'brandSelection',
      options: [],
      config: {
        maxSel: 10,
        hideSelAll: true
      },
    }

  }

  customTranslate(machine: any) {
    if (machine === 'Line') {
      return this.translate.instant('GLOBAL.LINE')
    }
    else {
      return machine
    }
  }
  openAggrDialog(aggr: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        // console.log('afterClosed', result);
        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
      }

      this.pageState.next(isClickedSelect ? 5 : 6);

      if (!this.interval.enabledPolling) {
        this.getEvents(this, 0);
      } else {
        this.getEventsBreakdowns(this);
      }

    });
  };

  changeConsumable(consDropdown: any) {

    this.consDropdown = this.clonerService.deepClone(consDropdown);

    if (this.aggregations == null || this.aggregations.list == null || this.aggregations.list.length == 0) {
      try {
        let consIdx = this.consumables.findIndex((x: any) => x.id == this.consDropdown);
        if (consIdx != -1 && this.consumables[consIdx].icon != null) this.consIcon = this.consumables[consIdx].icon;
        if (consIdx != -1 && this.consumables[consIdx].hasOwnProperty('aggregations') &&
          this.consumables[consIdx].aggregations != null &&
          this.consumables[consIdx].aggregations.length > 0) {
          this.aggregationsCustom = this.consumables[consIdx].aggregations;
        } else {
          this.aggregationsCustom = null;
        }
      } catch (error) {

      }
    }

    this.pageState.next(6);
    if (!this.interval.enabledPolling) {
      this.getEvents(this, 0);
    } else {
      this.getEventsBreakdowns(this);
    }
  }

  onBrandDialogSelect(event: any) {
    this.brandSelectionButton.options = event.options;
    this.pageState.next(6);
    if (!this.interval.enabledPolling) {
      this.getEvents(this, 0);
    } else {
      this.getEventsBreakdowns(this);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
    } catch (error) {
      let testError = {
        module: 'complete-consumption',
        function: 'getAssetInfo',
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getEventsBreakdowns, this.getEvents, this.machine.timezone) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval aggr
  selectAggregation(aggr: any) {

    if (aggr != null) {

      try {
        this.pollingEvents.unsubscribe();
      } catch (error) {
        // console.log(error)
      }

      this.intervalAggregations.selected = aggr;

      this.pageState.next(5);
      this.getEventsBreakdowns(this);

    }
  }

  machineSelectionChange(machine: any) {

    if (machine != null) {

      try {
        this.pollingEvents.unsubscribe();
      } catch (error) { }

      this.pageState.next(5);
      this.getEventsBreakdowns(this);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      console.log(error)
      let testError = {
        module: 'complete-consumption',
        function: 'getDashboard',
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // brands

  getBrands(_this: any) {

    _this.mainAggregation = _this.dashboardConfigUnparsed.mainAggregation;
    _this.brandSelectionButton.label = _this.mainAggregation.label;
    let query = {};
    _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

    query['measurement'] = 'consData';
    _this.apiService.sendGetRequest(`/apif/list-aggr/${_this.machineId}/${_this.mainAggregation.id}`, query)
      .pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
      )
      .subscribe(
        (data: any) => {
          if (data.body != null) {
            let options = _this.clonerService.deepClone(data.body);
            options = options.map((option: any, idx: number) => Object({
              id: option,
              label: option,
              selected: idx < 5
            }));

            _this.brandSelectionButton.options = options;
          } else _this.brandSelectionButton.options = [];

          _this.dispatcherService.getDispatch(_this, 300);
        }
      );
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // events

  // polling
  getEventsBreakdowns(_this: any) {
    try {


      if (_this.cacheService.get("intervalLong") == null) {

        _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.cacheService.set("intervalLong", _this.intervalConfig);

      } else {
        _this.intervalConfig = _this.cacheService.get("intervalLong");
        _this.interval = _this.cacheService.get("intervalLong").selected;
      }

      if (_this.pollingTime > 0) {
        _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getEvents(_this, count);
        });
      } else {
        _this.getEvents(_this, 0);
      }

    } catch (error) {
      let errorData = {
        module: 'complete-consumption',
        function: 'getEventsBreakdowns',
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getEvents(_this: any, count?: any) {
    try {

      if (count == 0 && _this.aggrList != null && _this.aggrList.length > 0) {
        _this.internalDataService.forceAggregationList(_this, _this.aggrList);
      }

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;
      payload.filters[_this.mainAggregation.id] = _this.brandSelectionButton?.options != null ? _this.brandSelectionButton?.options.filter((opt: any) => opt.selected)
        .map((opt: any) => opt.id) : null;

      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone,
        aggrUnit: _this.mainAggregation.id,
        unit: _this.intervalAggregations.selected.unit != null ? _this.intervalAggregations.selected.unit : 'hour',
        value: _this.intervalAggregations.selected.value != null ? _this.intervalAggregations.selected.value : 1,
        periods: _this.machine.profile.lastPeriods != null ? _this.machine.profile.lastPeriods : 3,
      };

      if (_this.consDropdown == null) {
        _this.consumables = _this.clonerService.deepClone(_this.machine?.profile?.consumables?.filter(x => x.brand) ?? []);
        try {
          _this.consDropdown = _this.clonerService.deepClone(_this.consumables)[0].id;
          _this.consIcon = _this.clonerService.deepClone(_this.consumables)[0].icon;
        } catch (error) {
          console.log(error);
        }
      }

      if (_this.aggregations == null || _this.aggregations.length == 0) {
        try {
          let consIdx = _this.consumables.findIndex((x: any) => x.id == _this.consDropdown);
          if (consIdx != -1 && _this.consumables[consIdx].hasOwnProperty('aggregations') &&
            _this.consumables[consIdx].aggregations != null &&
            _this.consumables[consIdx].aggregations.length > 0) {
            _this.aggregationsCustom = _this.clonerService.deepClone(_this.consumables)[consIdx].aggregations;
          } else {
            _this.aggregationsCustom = null;
          }
        } catch (error) {
          console.log(error);
        }
      }

      query.consumable = _this.consDropdown;

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      _this.apiService.sendPostRequest('/apif/consumption-monitoring/complete-consumption/' + _this.machineId, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.consData = _this.parseData(_this, data.body);
            let consSettings = _this.consumables.find((x: any) => x.id == _this.consDropdown);
            let consUnit = consSettings != null ? consSettings.unit : '-';
            let productionUnit = _this.translate.instant("PRODUCTION_UNITS.CYCLE");

            try {

              _this.consData.dataConfig = {};
              _this.consData.aggrDataConfig = {};

              // set plot data and configs
              _this.consData.dataConfig.title = _this.internalDataService.buildExportFileTitle(_this.breadcrumb, _this.interval);
              _this.consData.dataConfig.plotDataAttribute = 'plotData';
              _this.consData.aggrDataConfig.plotDataAttribute = 'aggrPlotData';

              _this.consData.plotData = _this.buildPlotConfig(_this, _this.consData);
              _this.consData.aggrPlotData = _this.buildAggrPlotConfig(_this, _this.consData);

              // set table data and config
              _this.consData.dataConfig.tableDataAttribute = 'faults';

              _this.consData.tableInfo = _this.clonerService.deepClone(_this.appConfig.consumptionAnalytics.brandConsumption);

              _this.consData.tableColumns = [];
              _this.consData.tableInfo.forEach((element: any) => {
                _this.consData.tableColumns.push(element.variable);
              });

              // Add consumable unit
              try {
                _this.consData.tableInfo.forEach((conf: any) => {
                  if (conf.consUnit) conf.suffix = consUnit;
                  if (conf.relativeUnit) conf.suffix += ('/' + productionUnit);
                });
              } catch (error) {
                console.log(error);
              }

              try {
                if (_this.aggrDropdown != null) {
                  let currentAggregationList: any = _this.clonerService.deepClone(_this.aggregationsCustom != null ? _this.aggregationsCustom : _this.aggregations);
                  if (_this.consData.tableInfo.findIndex((x: any) => x.variable == "aggregation") == -1) _this.consData.tableInfo.push({
                    variable: "aggregation",
                    orderBy: "aggregation",
                    label: _this.translate.instant(currentAggregationList.find((x: any) => x.id == _this.aggrDropdown).label).capitalize(),
                  });
                  if (!_this.consData.tableColumns.includes("aggregation")) _this.consData.tableColumns.push("aggregation");
                }
              } catch (error) {
                console.log(error);
              }

            } catch (error) {
              console.log(error);
            }

            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      console.log(error);
      let errorData = {
        module: 'complete-consumption',
        function: 'getEvents',
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseData(_this: any, data: any) {

    try {

      let dashboardCopy = _this.clonerService.deepClone(_this.dashboardConfigUnparsed);
      let consSettings = _this.consumables.find((x: any) => x.id == _this.consDropdown);
      let consUnit = consSettings != null ? consSettings.unit : '-';

      dashboardCopy.widgets.filter((x: any) => x.objectVariable != null).forEach((widget: any) => {

        if (data.hasOwnProperty(widget.objectVariable) && data[widget.objectVariable] != null) {

          widget.config.forEach((conf: any) => {

            let newDataKey = !data.hasOwnProperty(widget.objectVariable + '$$' + conf.variable) ? (widget.objectVariable + '$$' + conf.variable) : (widget.objectVariable + '$$$$$$' + conf.variable);
            data[newDataKey] = data[widget.objectVariable][conf.variable] != null ? (data[widget.objectVariable][conf.variable] * (consSettings.multiplier != null ? consSettings.multiplier : 1)) : null;

            let customConsUnit = consUnit;

            if (widget.objectVariable == 'rel') customConsUnit += ('/' + _this.translate.instant("PRODUCTION_UNITS.CYCLE"));
            if (widget.objectVariable == 'avg') customConsUnit += ('/' + (_this.intervalAggregations.selected.unit != null ? _this.intervalAggregations.selected.unit : 'hour').substring(0, 1));

            let classes: any = {
              suffix: customConsUnit,
            };

            if (conf.variable == 'LTP') {

              classes = Object.assign(classes, {
                value2: _this.filterService.parseGaugeValue(data[widget.objectVariable].perc, 1, 100, true),
                label: _this.translate.instant("GLOBAL.LAST_N_PERIODS", {
                  n: _this.machine.profile.lastPeriods != null ? _this.machine.profile.lastPeriods : 3
                }),
              });

              if (data[widget.objectVariable].hasOwnProperty('perc')) {
                classes = Object.assign(classes, data[widget.objectVariable].perc >= 0 ? {
                  class: 'md-red-i'
                } : {
                  class: 'md-green-i'
                });
              }

            };

            if (conf.variable == 'actual') {

              classes = Object.assign(classes, {
                class: 'md-gray-i',
              });

              if (data[widget.objectVariable].hasOwnProperty('perc')) {
                classes = Object.assign(classes, data[widget.objectVariable].perc >= 0 ? {
                  icon2Class: 'md-red',
                  icon2: {
                    icon: "arrow_up",
                    type: "svg"
                  }
                } : {
                  icon2Class: 'md-green',
                  icon2: {
                    icon: "arrow_down",
                    type: "svg"
                  }
                });
              }

            };

            conf = Object.assign(conf, classes);
            conf.variable = newDataKey;

          });
        }
      });

      _this.dashboardConfig = _this.clonerService.deepClone(dashboardCopy);

    } catch (error) {

    }

    try {

      _this.colorArray = [];
      if (data != null && data.hasOwnProperty('tracks') && data.tracks != null && data.tracks.length > 0) {

        data.faults = [];
        // if (_this.aggrDropdown == null) {
        //   return data;
        // }

        try {
          _this.colorArray = data.tracks.map((x: any) => x.aggrUnitId).filter(_this.filterService.onlyUnique).map((x: any, idx: any) => {
            return {
              id: x,
              color: _this.colors[idx]
            }
          });
        } catch (error) {
          console.log(error);
        }
      }
      return data;
    }

    catch (error) {
      console.log(error);
      return {};
    }
  }

  buildPlotConfig(_this: any, data: any) {

    let aggrId: any = _this.aggrDropdown;
    let consId: any = _this.consDropdown;
    let currentAggrConfig: any = null;

    try {
      currentAggrConfig = aggrId != null ?
        (_this.aggregations != null && _this.aggregations.length > 0
          ? _this.aggregations.find((x: any) => x.id == _this.aggrDropdown)
          : _this.aggregationsCustom.find((x: any) => x.id == _this.aggrDropdown))
        : null;
    } catch (error) { }

    let aggrTransl: any = null;

    try {
      if (currentAggrConfig != null && currentAggrConfig.hasOwnProperty('singleLabel')) {
        aggrTransl = currentAggrConfig.singleLabel;
      }
    } catch (error) { }

    // let colorArray = aggrId != null ? data.faults.map((x: any) => x[aggrId]).filter((val: any, id: any, array: any) => array.indexOf(val) == id) : [];

    let traces: any = [];


    if (data == null || !data.hasOwnProperty('tracks') || data.tracks.length == 0) {
      return;
    }

    let consSettings = _this.consumables.find((x: any) => x.id == consId);
    let productionUnit = _this.translate.instant("PRODUCTION_UNITS.CYCLE");

    // widget.fauls = {
    //   list: [],
    //   query: {
    //     order: '-timestamp',
    //     limit: 9,
    //     page: 1
    //   }
    // };

    data.tracks.forEach((track: any, trackIdx: any) => {

      track.aggrUnitLabel = aggrTransl != null ? _this.translate.instant(aggrTransl + '.' + track.aggrUnitId) : track.aggrUnitId;

      if (track.timestamps != null && track.timestamps.length > 0) {
        track.timestamps.forEach((ts: any, tsIdx: any) => {

          data.faults.push({
            relConsumptionP: (track.hasOwnProperty('rel') && track.rel[tsIdx] != null) ? _this.filterService.parseGaugeValue(track.rel[tsIdx], consSettings.decimals, consSettings.multiplier) : null,
            totConsumptionP: (track.hasOwnProperty('tot') && track.tot[tsIdx] != null) ? _this.filterService.parseGaugeValue(track.tot[tsIdx], consSettings.decimals, consSettings.multiplier) : null,
            prodTimeP: (track.hasOwnProperty('prodTime') && track.prodTime[tsIdx] != null) ? _this.filterService.parseTime(track.prodTime[tsIdx], 's', 'HH:mm:ss') : null,
            timestampP: _this.filterService.parseTableDate(ts, _this.intervalAggregations.selected.unit),
            relConsumption: (track.hasOwnProperty('rel')) ? track.rel[tsIdx] : null,
            totConsumption: (track.hasOwnProperty('tot')) ? track.tot[tsIdx] : null,
            delta: (track.hasOwnProperty('deltaTarget')) ? track.deltaTarget[tsIdx] : null,
            prodTime: (track.hasOwnProperty('prodTime')) ? track.prodTime[tsIdx] : null,
            timestamp: ts,
            aggregation: track.aggrUnitLabel,
          });
        });
      }

      if (track.tot != null && track.tot.length > 0) {
        traces.push({
          x: track.timestamps,
          y: track.tot.map((x: any) => x != null ? (x * (consSettings.multiplier != null ? consSettings.multiplier : 1)) : null),
          traceType: 'tot',
          xaxis: 'x',
          yaxis: 'y',
          visible: true,
          name: track.aggrUnitId != null ? track.aggrUnitId : _this.translate.instant('COMPLETE_CONSUMPTION.TOTAL_CONSUMPTION'),
          type: 'scatter',
          mode: 'lines+markers',
          hovertext: track.tot.map((x: any) => _this.filterService.parseGaugeValue(x, consSettings.decimals, consSettings.multiplier)),
          hoverinfo: 'text+x+name',
          legendgroup: trackIdx,
          line: {
            color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId).color : _this.colors[trackIdx],
            shape: 'spline',
          },
          marker: {
            color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId).color : _this.colors[trackIdx]
          }
        });
      }

      if (track.rel != null && track.rel.length > 0) {
        traces.push({
          x: track.timestamps,
          y: track.rel.map((x: any) => x != null ? (x * (consSettings.multiplier != null ? consSettings.multiplier : 1)) : null),
          traceType: 'rel',
          xaxis: 'x',
          yaxis: 'y',
          visible: false,
          name: track.aggrUnitId != null ? track.aggrUnitId : _this.translate.instant('COMPLETE_CONSUMPTION.RELATIVE_CONSUMPTION'),
          type: 'scatter',
          mode: 'lines+markers',
          hovertext: track.rel.map((x: any) => _this.filterService.parseGaugeValue(x, consSettings.decimals, consSettings.multiplier)),
          hoverinfo: 'text+x+name',
          showlegend: true,
          line: {
            color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId).color : _this.colors[trackIdx],
            shape: 'spline',
          },
          marker: {
            color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId).color : _this.colors[trackIdx]
          }
        });
      }

      if (track.prodTime != null && track.prodTime.length > 0) {
        traces.push({
          x: track.timestamps,
          y: track.prodTime.map((x: any) => x != null ? x / 3600 : null),
          traceType: 'prodTime',
          xaxis: 'x',
          yaxis: 'y2',
          visible: true,
          name: track.aggrUnitId != null ? track.aggrUnitId : _this.translate.instant('COMPLETE_CONSUMPTION.PRODUCTIVE_TIME'),
          type: 'scatter',
          mode: 'lines+markers',
          hovertext: track.prodTime.map((x: any) => _this.filterService.parseTime(x, 's', 'HH:mm:ss')),
          hoverinfo: 'text+x+name',
          legendgroup: trackIdx,
          showlegend: track.aggrUnitId == null,
          line: {
            color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId).color : _this.colors[trackIdx],
            shape: 'spline',
            dash: 'dot',
            width: 2
          },
          marker: {
            color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId).color : _this.colors[trackIdx]
          }
        });
      }

    });

    let updatemenus: any = [{
      buttons: [{
        args: [{
          'visible': traces.map((x: any) => x.traceType == 'prodTime' || x.traceType == 'tot')
        },
        {
          'yaxis2.visible': true,
          'yaxis.title.text': _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + ']' : '[-]'),
        },
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.TOTAL"),
        method: 'update'
      },
      {
        args: [{
          'visible': traces.map((x: any) => x.traceType == 'rel')
        },
        {
          'yaxis2.visible': false,
          'yaxis.title.text': _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + (productionUnit != null ? '/' + productionUnit : '') + ']' : '[-]')
        },
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.RELATIVE"),
        method: 'update'
      }
      ],
      direction: 'left',
      pad: {
        'r': 10,
        't': 10
      },
      showactive: true,
      type: 'buttons',
      x: 0.0,
      y: 1.3,
      xanchor: 'left',
      yanchor: 'top'
    }];

    let plotLayout: any = {
      updatemenus: updatemenus,
      legend: {
        orientation: 'h',
        x: 0,
        y: -0.2
      },
      margin: {
        t: 30,
        r: 110,
        b: 50,
        l: 100,
        pad: 15
      },
      xaxis: {
        zeroline: false,
        showgrid: false,
        automargin: true,
        title: {
          standoff: 20
        }
      },
      yaxis: {
        zeroline: true,
        showgrid: false,
        automargin: true,
        title: {
          standoff: 20,
          // text: _this.translate.instant('COMPLETE_CONSUMPTION.CONSUMPTION')
          text: _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + ']' : '[-]')
        },
        rangemode: 'tozero',
      },
      yaxis2: {
        zeroline: true,
        showgrid: false,
        overlaying: 'y',
        automargin: true,
        title: {
          standoff: 20,
          text: _this.translate.instant("COMPLETE_CONSUMPTION.PRODUCTIVE_TIME") + ' [hr]',
        },
        side: 'right',
        rangemode: 'tozero',
      },
    };

    return {
      layout: plotLayout,
      traces: traces
    };

  }

  buildAggrPlotConfig(_this: any, data: any) {

    let consSettings = _this.consumables.find((x: any) => x.id == _this.consDropdown);
    let productionUnit = _this.translate.instant("PRODUCTION_UNITS.CYCLE");

    let traces: any = [];

    let orderByTot = _this.clonerService.deepClone(data.tracks.sort(_this.filterService.sortByProperty("sumTot", 'desc', true)));
    let orderByRel = _this.clonerService.deepClone(data.tracks.sort(_this.filterService.sortByProperty("sumRel", 'desc', true)));

    traces.push({
      x: orderByTot.map((x: any) => x.aggrUnitId),
      y: orderByTot.map((x: any) => x.sumTot != null ? (x.sumTot * (consSettings.multiplier != null ? consSettings.multiplier : 1)) : null),
      traceType: 'tot',
      visible: true,
      type: 'bar',
      hovertext: orderByTot.map((x: any) => {
        let hovertext = '';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.TOTAL_CONSUMPTION") + '</b>: ' + (x.sumTot != null ? _this.filterService.parseGaugeValue(x.sumTot, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.RELATIVE_CONSUMPTION") + '</b>: ' + (x.sumRel != null ? _this.filterService.parseGaugeValue(x.sumRel, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + (productionUnit != null ? '/' + productionUnit : '') + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.AVG_CONSUMPTION") + '</b>: ' + (x.sumAvg != null ? _this.filterService.parseGaugeValue(x.sumAvg, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + (_this.intervalAggregations.selected.unit != null ? '/' + _this.intervalAggregations.selected.unit : '') + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.PRODUCTIVE_TIME") + '</b>: ' + _this.filterService.parseTime(x.sumProdTime, 's', 'HH:mm:ss') + '<br>';
        return hovertext;
      }),
      hoverinfo: 'text',
      marker: {
        color: orderByTot.map((y: any) => _this.colorArray.find((x: any) => x.id == y.aggrUnitId).color)
      }
    });

    traces.push({
      x: orderByRel.map((x: any) => x.aggrUnitId),
      y: orderByRel.map((x: any) => x.sumRel != null ? (x.sumRel * (consSettings.multiplier != null ? consSettings.multiplier : 1)) : null),
      traceType: 'rel',
      visible: false,
      type: 'bar',
      hovertext: orderByRel.map((x: any) => {
        let hovertext = '';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.RELATIVE_CONSUMPTION") + '</b>: ' + (x.sumRel != null ? _this.filterService.parseGaugeValue(x.sumRel, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + (productionUnit != null ? '/' + productionUnit : '') + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.TOTAL_CONSUMPTION") + '</b>: ' + (x.sumTot != null ? _this.filterService.parseGaugeValue(x.sumTot, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.AVG_CONSUMPTION") + '</b>: ' + (x.sumAvg != null ? _this.filterService.parseGaugeValue(x.sumAvg, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + (_this.intervalAggregations.selected.unit != null ? '/' + _this.intervalAggregations.selected.unit : '') + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.PRODUCTIVE_TIME") + '</b>: ' + _this.filterService.parseTime(x.sumProdTime, 's', 'HH:mm:ss') + '<br>';
        return hovertext;
      }),
      hoverinfo: 'text',
      marker: {
        color: orderByRel.map((y: any) => _this.colorArray.find((x: any) => x.id == y.aggrUnitId).color)
      }
    });

    let updatemenus: any = [{
      buttons: [{
        args: [{
          'visible': traces.map((x: any) => x.traceType == 'tot')
        },
        {
          'yaxis.title.text': _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + ']' : '[-]')
        },
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.TOTAL"),
        method: 'update'
      },
      {
        args: [{
          'visible': traces.map((x: any) => x.traceType == 'rel')
        },
        {
          'yaxis.title.text': _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + (productionUnit != null ? '/' + productionUnit : '') + ']' : '[-]')
        }
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.RELATIVE"),
        method: 'update'
      }
      ],
      direction: 'left',
      pad: {
        'r': 10,
        't': 10
      },
      showactive: true,
      type: 'buttons',
      x: 0.0,
      y: 1.3,
      xanchor: 'left',
      yanchor: 'top'
    }];

    let plotLayout: any = {
      updatemenus: updatemenus,
      legend: {
        orientation: 'h',
        x: 0,
        y: -0.2
      },
      margin: {
        t: 30,
        r: 110,
        b: 50,
        l: 100,
        pad: 15
      },
      xaxis: {
        zeroline: false,
        showgrid: false,
        // automargin: true,
        type: 'category',
        title: {
          standoff: 20
        }
      },
      yaxis: {
        zeroline: true,
        showgrid: false,
        // automargin: true,
        title: {
          standoff: 20,
          // text: _this.translate.instant('ENERGY_CONSUMPTION.CONSUMPTION')
          text: _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + ']' : '[-]')
        },
      },
    };

    return {
      layout: plotLayout,
      traces: traces
    };

  }

  filterEvents(searchEvent?: any) {
    // TODO
  }

  getPreviousBucket() {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start), 'm') / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).subtract(duration, 'm')));
    let end = JSON.parse(JSON.stringify(moment(this.interval.end).subtract(duration, 'm')));

    this.interval.start = start;
    this.interval.end = end;

    this.interval.enabledPolling = false;

    this.interval.startF = this.intervalService.strFormat(this.interval, start) + ' - ' + this.intervalService.strFormat(this.interval, end);
    this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

    this.pageState.next(5);
    this.getEvents(this, 0);
  }

  getNextBucket() {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start), 'm') / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).add(duration, 'm')));
    let end = moment(this.interval.end).add(duration, 'm');

    if (moment().diff(end, 'm') < 5) {
      this.interval.start = start;
      this.interval.enabledPolling = true;
      this.interval.end = JSON.parse(JSON.stringify(moment()));
      this.interval.startF = this.intervalService.strFormat(this.interval, start);
      this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

      this.pageState.next(5);
      this.getEventsBreakdowns(this);
    }
    else {

      this.interval.start = start;
      this.interval.end = JSON.parse(JSON.stringify(end));

      this.interval.enabledPolling = false;
      this.interval.startF = this.intervalService.strFormat(this.interval, start) + ' - ' + this.intervalService.strFormat(this.interval, this.interval.end);
      this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

      this.pageState.next(5);
      this.getEvents(this, 0);
    }


  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    );

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pageState.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.mobileListener.unsubscribe()
    } catch (error) {

    }
  }
}