<div class="w100 h100 hm-bar" fxLayout="row" fxLayoutAlign="center center">
  <div class="bar-container bg">
    <div class="bar" *ngFor="let bar of bars; let first = first; let last = last;" [ngStyle]="{ 
          'border-radius' : first ? ('15px 15px ' + borderRadius + 'px ' + borderRadius + 'px') : (last ? ('' + borderRadius + 'px ' + borderRadius + 'px 15px 15px') : '5px'),
          'margin' : bar.margin,
          'bottom' : bar.bottom,
          'height' : bar.height,
          'background-color' : bar.color }">
    </div>
  </div>
  <div class="bar-container">
    <span class="bar-label" *ngFor="let bar of bars; let first = first; let last = last;" [ngStyle]="{ 
        'border-radius' : first ? ('15px 15px ' + borderRadius + 'px ' + borderRadius + 'px') : (last ? ('' + borderRadius + 'px ' + borderRadius + 'px 15px 15px') : '5px'),
        'margin' : bar.margin,
        'bottom' : bar.bottom,
        'height' : bar.height }">
      {{bar.end}}%
    </span>
  </div>
</div>