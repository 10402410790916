<!-- <div class="error-card" [ngClass]="'type-' + errorData.type" fxLayout="row" fxLayoutAlign="center center">
  <div class="icon-container" fxLayout="row" fxLayoutAlign="center center">
    <i *ngIf="errorData.type == 0" class="fas fa-exclamation-circle icon"></i>
    <i *ngIf="errorData.type == 1" class="fas fa-exclamation-triangle icon"></i>
    <i *ngIf="errorData.type == 2" class="fas fa-info-circle icon"></i>
  </div>
  <div class="message-container" fxLayout="column" fxLayoutAlign="start start">
    <span *ngIf="errorData.origin != null"><strong>Origin</strong>: {{ errorData.origin }}</span>
    <span *ngIf="errorData.module != null"><strong>Module</strong>: {{ errorData.module }}</span>
    <span *ngIf="errorData.function != null"><strong>Function</strong>: {{ errorData.function }}</span>
    <span *ngIf="errorData.status != null"><strong>Status</strong>: {{ errorData.status }}</span>
    <span *ngIf="errorData.statusText != null"><strong>Status Text</strong>: {{ errorData.statusText }}</span>
    <span *ngIf="errorData.url != null"><strong>URL</strong>: {{ errorData.url }}</span>
    <span *ngIf="errorData.message != null"><strong>Message</strong>: {{ errorData.message | translate }}</span>
  </div>
</div> -->
<div class="error-card" [ngClass]="'type-' + errorData.type" fxLayout="column" fxLayoutAlign="center center">

  <!-- SAD BOT ICON -->
  <div class="icon-container" fxLayout="row" fxLayoutAlign="center center">
    <mat-icon svgIcon="bot_sad" style="width: 150px; height: 150px;"></mat-icon>
  </div>

  <!-- MESSAGE -->
  <div class="message-container" fxLayout="column" fxLayoutAlign="start center">

    <span class="main-message" [translate]="statusCode">
    </span>

    <span class="main-message" [translate]="statusMessage">
    </span>

    <a *ngIf="errorData?.message?.statusUrl" [href]="errorData?.message?.statusUrl" target="_blank">
      {{ errorData?.message?.statusLabel | translate }}
    </a>

    <span translate="ERRORS.PLEASE_TRY_AGAIN_LATER"></span>

    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="!hideAdditionalInfos">
      <button (click)="reloadPage()" class="filter-button md-light-gray" fxLayout="row" fxLayoutAlign="center center"
        style="margin: 8px 8px 0 0 !important;">
        <mat-icon class="icon">refresh</mat-icon>
        {{ 'ERRORS.RELOAD_PAGE' | translate }}
      </button>
      <button (click)="openInfoDialog()" class="filter-button md-light-gray" fxLayout="row"
        fxLayoutAlign="center center" style="margin: 8px 0 0 0 !important;"
        matTooltip="{{ 'ERRORS.MORE_INFOS_TOOLTIP' | translate }}">
        <mat-icon class="icon">info</mat-icon>
        {{ 'ERRORS.MORE_INFOS' | translate }}
      </button>
    </div>

    <!-- <span *ngIf="errorData.type == 0" class="main-message" translate="ERRORS.SOMETHING_WENT_WRONG"></span>

    <span *ngIf="errorData.type == 0" translate="ERRORS.PLEASE_TRY_AGAIN_LATER"></span>

    <button *ngIf="errorData.type == 0" class="filter-button md-light-gray" (click)="reloadPage()"
      style="margin-top: 8px !important;">
      {{ 'ERRORS.RELOAD_PAGE' | translate }}
    </button> -->
  </div>

</div>