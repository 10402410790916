import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ff-checkbox',
  templateUrl: './ff-checkbox.component.html',
  styleUrls: ['./ff-checkbox.component.scss']
})
export class FfCheckboxComponent implements OnInit {

  @Input() checkBoxConfig?: any;
  @Output() changedValue = new EventEmitter<any>();

  constructor() { }

  onCheckboxSelect(value) {
    this.changedValue.emit(value);
  }

  onCheckboxClick(config) {
    if (config.disabledWhenTrue && config.checked) return;
    this.checkBoxConfig.checked = !config?.checked;
    this.onCheckboxSelect(config)
  }

  onRecurrencyChange(value) {
    this.changedValue.emit(value);
  }

  ngOnInit() {
    // console.log(this.checkBoxConfig);
  }

}
