import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';

@Component({
  selector: 'ff-custom-action-buttons',
  templateUrl: './custom-action-buttons.component.html',
  styleUrls: ['./custom-action-buttons.component.scss']
})
export class CustomActionButtonsComponent implements OnInit, OnChanges {

  @Input() buttons: any;
  @Input() referenceComponent: any;
  @Output() selected = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    public translate: FfTranslateService,
    public clonerService: ClonerService,
    public filterService: FiltersService,
  ) { }

  buttonAction(button: any) {
    this.selected.emit({
      tableInfos: button?.tableInfos ?? [],
      buttonInfos: button
    });
  };

  ngOnInit(): void {
    this.buttons = this.filterService.filterArrayWithPermission(this.buttons);

    if (this.referenceComponent == null) return;

    // Filter with "showCondition" attribute
    this.buttons = this.buttons?.reduce((acc, button) => {

      button.conditionOperatorCheck = 'every';
      if (button?.showCondition != null) {
        let boolCond = this.filterService.checkShowCondition(button, this.referenceComponent);
        // If the condition(s) is(are) not satisfied, delete the value associated with the variable
        if (!boolCond) return acc;
      }

      acc.push(button);
      return acc;
    }, []);

    // disable all buttons with "disabledCondition" attribute
    this.buttons = this.buttons?.reduce((acc, button) => {

      button.conditionOperatorCheck = 'every';
      if (button?.disabledCondition != null) {
        let boolCond = this.filterService.checkShowCondition(button, this.referenceComponent, "disabledCondition");
        // If the condition(s) is(are) not satisfied, delete the value associated with the variable
        if (boolCond) button.disabled = true;
      }

      acc.push(button);
      return acc;
    }, []);

  }

  ngOnChanges(): void {
    this.buttons = this.filterService.filterArrayWithPermission(this.buttons);

    if (this.referenceComponent == null) return;

    // Filter with "showCondition attribute"
    this.buttons = this.buttons?.reduce((acc, button) => {

      button.conditionOperatorCheck = 'every';
      let boolCond = this.filterService.checkShowCondition(button, this.referenceComponent);
      if (button?.showCondition != null) {
        // If the condition(s) is(are) not satisfied, delete the value associated with the variable
        if (!boolCond) return acc;
      }

      acc.push(button);
      return acc;
    }, []);

  }

}
