<div mat-dialog-title fxLayout="row" class="w100">
    <span>{{ dialog.title | translate }}</span>
    <span fxFlex></span>
    <button mat-dialog-close class="close">
        <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
    </button>
</div>

<mat-dialog-content class="mat-typography">

    <div fxLayout="row" fxLayoutAlign="center center" [class.ff-disabled]="dialogState == 0" class="completely">
        <div fxLayout="row" class="attachment-button" (click)="fileInput.click()" [class.clickable]="dialogState != 0">
            <div class="tip"><i class="fas fa-upload"></i></div>
            <div class="label">{{ 'GLOBAL.CHOOSE_FILE' | translate }}</div>
        </div>
    </div>

    <input hidden (change)="onFileSelected()" #fileInput type="file" id="file" [(ngModel)]="fileCopy" multiple>

    <ff-loading-card *ngIf="dialogState == 0"></ff-loading-card>

    <ng-container *ngFor="let fileInfo of fileInfos">
        <div *ngIf="fileInfo != null" fxLayout="row" fxLayoutAlign="start center" class="file-row-container">

            <div class="input-box" fxFlex="70">
                <input matInput class="ff-input text w100 completely" type="text" [(ngModel)]="fileInfo.name"
                    [disabled]="dialogState == 0">
                <div class="required" fxLayout="row" fxLayoutAlign="start center">
                    <span *ngIf="fileInfo.name == null || fileInfo.name == ''" translate="GLOBAL.REQUIRED"></span>

                    <span *ngIf="fileInfo.name != null && fileInfo.name != '' && !getRegex('filePath', fileInfo.name)">
                        {{ 'GLOBAL.INVALID_REGEX' | translate }}
                    </span>

                    <span
                        *ngIf="fileInfo.name != null && fileInfo.name != '' && invalidValues?.includes(fileInfo.name + '.' + fileInfo.extension)"
                        class="md-orange" fxLayout="row" fxLayoutAlign="start center">
                        <ff-icon icon="warning" iconClass="md-orange"></ff-icon>
                        {{ 'GLOBAL.NAME_ALREADY_IN_USE' | translate }}
                    </span>
                </div>
            </div>

            <span fxFlex="15">.{{ fileInfo.extension }}</span>

            <span right fxFlex="15">{{ fileInfo.sizeP }}</span>

        </div>
    </ng-container>

    <ng-container
        *ngIf="dialogState == 1 && fileInfos?.length > 0 && isSupplier && !dialog?.buttonInfos?.hideSendMailToCustomers">

        <div fxLayout="row" fxLayoutAlign="start center" style="padding: 4px 12px;">
            <mat-checkbox [(ngModel)]="sendMailToCustomers"></mat-checkbox>
            <span right>{{ 'FILE_EXPLORER.SEND_MAIL_TO_CUSTOMERS' | translate }}</span>
            <ff-icon icon="info" right matTooltip="{{ 'FILE_EXPLORER.SEND_MAIL_TO_CUSTOMERS_TOOLTIP' | translate }}"
                matTooltipClass="myClass">
            </ff-icon>
        </div>

    </ng-container>

    <ff-error-card *ngIf="dialogState === 2" [errorData]="errorData"></ff-error-card>

</mat-dialog-content>

<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
    <button [disabled]="fileInfos == null || dialogState == 0 || checkConfirm()" class="filter-button md-primary"
        (click)="uploadFile()">
        <span>{{ 'GLOBAL.CONFIRM' | translate }}</span>
    </button>
</mat-dialog-actions>