<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1" class="ff-toolbar" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

  </div>

  <div *ngIf="isMobile" fxFlex fxLayout="column" class="tab-content h100 w100"
    [ngClass]="{ 'without-tabs': tabs.length <= 1}" fxLayoutAlign="center center">
    <ff-error-card [errorData]="errorDataMobile"></ff-error-card>
  </div>

  <!-- tab content -->
  <div *ngIf="!isMobile" class="tab-content h100 w100" fxLayout="column"
    fxLayoutAlign="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start stretch' }}"
    [ngClass]="{ 'without-tabs': tabs.length <= 1}">

    <!-- toolbar -->
    <div *ngIf="pageState.value >= pageStateReady" class="ff-toolbar pb-3" fxLayout="column"
      fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <button mat-button class="filter-button" *ngFor="let item of filterButtons" (click)="openFilterDialog(item)"
        fxLayout="row" fxLayoutAlign="space-evenly center"
        [disabled]="item.variable == 'alarmCategories' && (machine.profile.alarmCategories == null || machine.profile.alarmCategories.length == 0 || !oneMachineSelected || !detailMode)">
        <mat-icon class="icon">filter_alt</mat-icon>
        <span>{{ item.label | translate }}</span>
      </button>

      <button *ngIf="zoomIntervalHistory != null && zoomIntervalHistory.length > 0"
        class="mat-button rounded-button md-light-gray" (click)="setPreviousInterval()" style="margin-right: 8px;"
        #tooltip="matTooltip" matTooltip="{{ 'STATE_TIMELINE.PREVIOUS_SELECTION' | translate }}"
        matTooltipPosition="above">
        <mat-icon svgIcon="arrow_left" class="icon"></mat-icon>
      </button>

      <div *ngIf="oneMachineSelected && detailMode" fxLayout="row" fxLayoutAlign="start center"
        fxLayoutAlign.gt-sm="center center" class="switch-container" marginLeft>
        <span *ngIf="expandedModeSwitch?.title != null" left>
          {{ expandedModeSwitch.title | translate }}
        </span>
        <span fxFlex fxHide.gt-sm></span>
        <ff-icon icon="info" matTooltip="{{ expandedModeSwitch?.tooltip | translate }}" left></ff-icon>
        <ui-switch [checked]="expandedModeSwitch?.checked" (change)="switchChange($event)"
          [checkedLabel]="expandedModeSwitch?.checkedLabel" [uncheckedLabel]="expandedModeSwitch?.uncheckedLabel">
        </ui-switch>
      </div>

      <span fxFlex></span>

      <button class="mat-button rounded-button md-light-gray" (click)="navigateToBreakdowns()"
        style="margin-right: 8px;" #tooltip="matTooltip"
        matTooltip="{{ 'STATE_TIMELINE.NAVIGATE_TO_BREAKDOWNS' | translate }}" matTooltipPosition="above">
        <mat-icon class="icon">open_in_new</mat-icon>
      </button>

      <!-- intervals -->
      <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"
        [intAggrs]="intervalAggregations" (selectedAggr)="selectAggregation($event)" [customAggregations]="true">
      </ff-interval-selector>

    </div>

    <!-- toolbar -->
    <div *ngIf="pageState.value >= pageStateReady && detailMode" class="ff-toolbar py-3" fxLayout="column"
      fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <div fxLayout="row" fxLayoutAlign="start center" class="switch-container">
        <span style="font-weight: 800;">{{ 'STATE_TIMELINE.CATEGORIES' | translate }}:</span>
        <div *ngFor="let category of machine.profile.categories" fxLayout="row" fxLayoutAlign="start center"
          style="margin-right: 8px;">
          <span [style.background-color]="category.color" class="color-square"></span>
          <span>{{ category.label | translate }}</span>
        </div>
      </div>

      <span fxFlex></span>

      <button class="mat-button rounded-button md-light-gray" (click)="getPreviousBucket()" style="margin-right: 8px;"
        #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.PREVIOUS_BUCKET' | translate }}"
        matTooltipPosition="above">
        <mat-icon svgIcon="arrow_left" class="icon"></mat-icon>
      </button>
      <button class="mat-button rounded-button md-light-gray" (click)="getNextBucket()"
        [disabled]="interval.enabledPolling" #tooltip="matTooltip"
        matTooltip="{{ 'CYCLE_TIMELINE.NEXT_BUCKET' | translate }}" matTooltipPosition="above">
        <mat-icon svgIcon="arrow_right" class="icon"></mat-icon>
      </button>

    </div>

    <div *ngIf="pageState.value >= pageStateReady && detailMode && machine.profile?.alarmCategories?.length > 0"
      class="ff-toolbar pb-3" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">

      <div fxLayout="row" fxLayoutAlign="start center">
        <span style="font-weight: 800;">{{ 'STATE_TIMELINE.ALARM_CATEGORIES' | translate }}:</span>
        <div *ngFor="let category of machine.profile.alarmCategories" fxLayout="row" fxLayoutAlign="start center"
          style="margin-right: 8px;">
          <span [style.background-color]="category.color" class="color-square"></span>
          <span>{{ category.value | translate }}</span>
        </div>
      </div>

      <span fxFlex></span>

    </div>

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="!detailMode && pageState.value >= pageStateReady" fxLayout="column" class="w100 h100"
      style="min-height: calc(100vh - 260px);">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
        class="h100 w100" fxLayoutGap="8px">

        <div fxFlex fxLayout="row" fxLayoutAlign="start stretch" class="w100 h100" fxLayoutGap="8px">
          <ff-widget fxFlex="75" [widget]="leftPlot"></ff-widget>

          <div fxLayout="column" fxLayoutAlign="start stretch" class="h100 w100" fxFlex="25" fxLayoutGap="8px">
            <ff-widget fxFlex [widget]="rightPlot"></ff-widget>
          </div>
        </div>

      </div>

    </div>

    <div *ngIf="!detailMode && addOeeTrend && pageState.value >= pageStateReady" fxLayout="column" class="w100 h100"
      style="min-height: calc(100vh - 260px); padding-top: 8px;">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
        class="h100 w100" fxLayoutGap="8px">

        <div fxFlex fxLayout="row" fxLayoutAlign="start stretch" class="w100 h100" fxLayoutGap="8px">
          <ff-widget fxFlex="100" [widget]="oeeTrendPlot"></ff-widget>
        </div>

      </div>
    </div>

    <!-- <ff-plotly-chart [widget]="widget" fxLayout="column" fxLayoutAlign="space-between start"
      style="height: 100%; width: 100%;"></ff-plotly-chart> -->

    <!-- chart -->
    <div *ngIf="pageState.value>= pageStateReady && ganttState == 1 && detailMode" class="w100">
      <apx-chart class="w100" [chart]="chartOptions.chart" [plotOptions]="chartOptions.plotOptions"
        [dataLabels]="chartOptions.dataLabels" [xaxis]="chartOptions.xaxis" [tooltip]="chartOptions.tooltip"
        [yaxis]="chartOptions.yaxis" [grid]="chartOptions.grid" [series]="chartOptions.series"
        [annotations]="chartOptions.annotations"></apx-chart>
    </div>

    <div *ngIf="pageState.value>= pageStateReady && ganttState == 2 && detailMode" fxLayout="row"
      fxLayoutAlign="center center" class="w100" style="margin-top: 12px;">
      <div class="not-found" fxLayout="row" fxLayoutAlign="center center">
        <span>{{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}</span>
      </div>
    </div>

  </div>

</div>