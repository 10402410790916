import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ff-rounded-icon-button',
  templateUrl: './rounded-icon-button.component.html',
  styleUrls: ['./rounded-icon-button.component.scss']
})
export class RoundedIconButtonComponent implements OnInit {

  @Input() inputData: any;

  constructor() { }

  ngOnInit(): void { }

}
