import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { AppConfigService } from 'src/app/services/app-config.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { LastUpdatesDetailDialogComponent } from '../../last-updates-detail-dialog/last-updates-detail-dialog.component';

@Component({
  selector: 'ff-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit, OnChanges {

  @Input() widget: any;
  appConfig: any;


  constructor(
    public translate: FfTranslateService,
    public dialog: MatDialog,
    public appConfigService: AppConfigService,
  ) {
    this.appConfig = this.appConfigService.getAppConfig;

  }

  setValue() {

    let config = this.widget?.config;

    try {

      let state = this.widget.machineProfile[config.listName ?? 'timeStates'].find((x: any) => x?.[config?.idVariable ?? 'state'] == this.widget.data[config.variable]);

      if (state != null) {
        this.widget.icon = state.icon;
        this.widget.class = state.class;
        this.widget.label = this.translate.instant(state.value);

        // Ferrule
        if (config?.labelConfig != null) {
          let translObj: any = {};
          translObj[config?.labelConfig?.secondParamKey ?? 'param1'] = this.widget.data?.[config?.labelConfig?.variable];
          this.widget.label = this.translate.instant(state.value, translObj);
        }

      } else {

        // Custom no data config (for ferrule)
        if (config?.noDataConfig != null) {
          this.widget.icon = config?.noDataConfig?.icon;
          this.widget.class = config?.noDataConfig?.class;
          this.widget.label = this.translate.instant(config?.noDataConfig?.label);
        }

        // Default "undefined"
        else {
          this.widget.icon = {
            icon: "remove_circle",
            type: "icon"
          };
          this.widget.class = "md-gray";
          this.widget.label = this.translate.instant("MACHINE_STATES.UNDEFINED");
        }

      }

      if (config?.calculateConnectionStateFromLastUpdateVariable) {
        let warningMinutes = this.appConfig?.machineMonitoring?.warningMinutes ?? 15;
        let val = this.widget?.data?.[this.widget?.config?.calculateConnectionStateFromLastUpdateVariable];
        this.widget.data.neverConnected = val == null;
        this.widget.data.notConnected = moment().diff(moment(val), 'm') > warningMinutes;
      }

      if (this.widget.data.notConnected) {

        this.widget.icon = {
          icon: "warning",
          type: "icon"
        };
        this.widget.class = "md-red";
        this.widget.label = this.translate.instant("MACHINE_STATES.NOT_CONNECTED");
      }

      if (this.widget.data.neverConnected) {

        this.widget.icon = {
          icon: "remove_circle",
          type: "icon"
        };
        this.widget.class = "md-gray";
        this.widget.label = this.translate.instant("MACHINE_STATES.NEVER_CONNECTED");
      }

    } catch (error) { }

    try {
      if (config.lastUpdatesDetail) this.widget.lastUpdatesDetail = this.widget.data?.[config.lastUpdatesDetailVariable ?? 'lastUpdatesDetail'];
    } catch (error) { }
  }

  // Last updates dialog
  openLastUpdatesDialog() {
    this.dialog.open(LastUpdatesDetailDialogComponent, {
      panelClass: 'ff-dialog',
      width: "30%",
      data: {
        list: this.widget.lastUpdatesDetail,
        title: this.translate.instant("HOMEPAGE.LAST_UPDATES_DETAIL"),

      },
    });
  }

  ngOnInit(): void {
    this.setValue();
  }

  ngOnChanges(): void {
    this.setValue();
  }

}
