import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[marginRight]'
})
export class MarginRightDirective {

  constructor(private el: ElementRef) {
    this.el.nativeElement.style.marginRight = '8px';
  }

}
