<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <!-- aggregations -->
  <div style="width: 600px; margin: 0; padding: 0; max-width: 100%; position: relative;">
    <img src="/assets/images/rm-dialog-image.jpg" style="width: 100%; height: auto;">
    <span *ngFor="let variable of dialog.machineVariables" style="position: absolute; left: 28%; font-weight: bold;"
      [ngStyle]="{'top': variable.top + '%', 'font-size': '20px'}">
      {{ variable.value }}
    </span>
  </div>

</mat-dialog-content>