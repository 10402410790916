<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ dialog.title | translate }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>
<mat-dialog-content class="mat-typography">

  <ff-table [tableConfig]="{'tableInfos': tableInfos, 'elemList': list }"></ff-table>

</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button mat-button class="filter-button" (click)="closeDialog()">{{ dialog.confirmTitle | translate }}</button>
</mat-dialog-actions>
