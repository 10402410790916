import { Component, Input } from '@angular/core';
import { Stop } from './stop.module';
import { StopService } from '../stop.service';

@Component({
  selector: 'app-stops-story',
  templateUrl: './stops-story.component.html',
  styleUrls: ['./stops-story.component.scss'],
})
export class StopsStoryComponent {
  @Input() stop: any;
  @Input() index: any;
  @Input() defaultTrafila: any;

  trafile = ['1', '2', '3'];

  constructor(private stopService: StopService) {}

  onDeleteStop(i: number) {
    this.stopService.deleteStop(i);
  } 
}
