import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';
import { AnnealerDialogComponent } from './annealer-dialog/annealer-dialog.component';
import { DatapointDialogComponent } from './datapoint-dialog/datapoint-dialog.component';
import { ImageDialogComponent } from './image-dialog/image-dialog.component';
import { SynopticSelectionDialogComponent } from './synoptic-selection-dialog/synoptic-selection-dialog.component';


@Component({
  selector: 'app-remote-monitoring',
  templateUrl: './remote-monitoring.component.html',
  styleUrls: ['./remote-monitoring.component.scss']
})
export class RemoteMonitoringComponent implements OnInit {

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // VARIABLES

  public isAllowedUser: boolean = true;

  public loadingData: any;
  public errorData: any;

  public mobileListener: Subscription;
  public isMobile: boolean;
  public isSmThanTablet: boolean;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public currentSynopticId: any;
  public synopticConfig: any;
  public synopticConfigDefault: any;

  public monitoringData: any;

  public pollingTime: any;
  public pollingMachines: any;

  public reelId: any;
  public reelLabel: any;

  public datapointSub: Subscription;
  public dialogData: any;
  public dashboardConfig: any;
  public showDialog: boolean = false;
  public hideSynopticSelectionButton: any = false;

  public noSynoptic: any = false;

  public repeatedCards: any = false

  private annealerDialog: MatDialogRef<AnnealerDialogComponent>

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 8;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getSynopticConfig, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getMonitoringData, nextState: 7, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 },
        { code: 302, function: this.getTabularConfig, nextState: 6 },
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.getMonitoringData, nextState: 7, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public intervalService: IntervalService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public mobile: MobileService
  ) {
    this.mobile.mobileListener.subscribe((value: any) => {
      this.isSmThanTablet = value.isSmThanTablet
      this.isMobile = value.isMobile
    })

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.monitoringData = null;

    this.breadcrumb = ['MACHINE_MONITORING.TITLE', 'REMOTE_MONITORING.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs('machineMonitoring');

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));
    this.pageState.subscribe();
    this.pollingTime = this.appConfig?.machineMonitoring?.polling ?? 10000;
    this.pollingMachines = Subscription;

    this.currentSynopticId = 'mainPlant';

    // datapoint subscription
    this.datapointSub = this.internalDataService.onDatapointUpdate().subscribe(dp => {
      if (dp.action == 'synopticLink') {
        this.currentSynopticId = dp.synopticId;
        try { this.pollingMachines.unsubscribe() }
        catch (error) { }
        if (dp.type == 'linkToPreviousSynoptic') this.currentSynopticId = (dp.synopticId == null || dp.synopticId == '') ? 'mainPlant' : dp.synopticId;
        else this.currentSynopticId = dp.synopticId;

        this.synopticConfig = this.parseSynopticConfig(this.monitoringData, this.currentSynopticId);
        this.getMonitoringData(this);
      }

      // Synoptic list
      else if (dp.action == 'synopticList') this.openMatDialog(dp.synopticList);

      // Image dialog
      else if (dp.action == 'imageDialog') this.openImageDialog(dp);

      // Annealer curves
      else if (dp.action == 'annealerCurves') this.openAnnealerDialog(dp);

      // External link
      else if (dp.action == 'externalLink') this.openExternalUrl(dp);

      // Standard datapoint
      else {

        let title = (dp.dialogTitle != null && dp.dialogTitle != '') ? this.translate.instant(dp.dialogTitle) : null;
        if (title == null) title = dp.title;
        if (title == null) title = (dp.label != null && dp.label != '') ? this.translate.instant(dp.label) : 'Undefined';

        if (dp.defaultUnit == null) dp.defaultUnit = dp.suffix ?? dp.unit;

        this.showDialog = true;
        this.dialogData = {
          showDialog: true,
          title: title,
          unit: this.translate.instant((dp.unit != null && dp.unit != '') ? dp.unit : '-'),
          defaultUnit: dp.defaultUnit,
          yAxisRange: dp.yAxisRange,
          datapointList: dp.datapointList ?? [dp.datapoint],
          machineId: this.machineId,
          machine: this.clonerService.deepClone(this.machine),
          timezone: this.machine?.timezone,
          completeDatapoint: this.clonerService.deepClone(dp)
        };

        // console.log("action", dp.action);
        const datapointDialog = this.dialog.open(DatapointDialogComponent,
          {
            width: this.isSmThanTablet ? 'fit-content' : '60%',
            data: this.dialogData,
            panelClass: 'datapoint-dialog'
          });

        datapointDialog.afterClosed().subscribe(result => {
          // console.log(`Dialog result: ${result}`);
        });

      }
    });

  }

  openMatDialog(list?: any) {

    let copySynopticDefault: any = this.clonerService.deepClone(this.synopticConfigDefault);
    if (list == null) copySynopticDefault = this.filterService.removeKeysWithCustomRule(copySynopticDefault, (x: any) => this.internalDataService.checkConfigFlag(x[1], this.machine));

    let synopticList = list ?? Object.keys(copySynopticDefault);

    const synopticDialog = this.dialog.open(SynopticSelectionDialogComponent,
      {
        panelClass: 'ff-dialog',
        data: {
          title: this.translate.instant("REMOTE_MONITORING.SYNOPTIC_SELECTION"),
          currentSynoptic: this.currentSynopticId,
          synopticList: synopticList.map((x: any) => {
            return {
              id: x,
              label: this.internalDataService.parseLabelFromCustomTranslations("SYNOPTICS." + x)
            };
          })
        },
      });

    synopticDialog.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.currentSynopticId = result;
        try { this.pollingMachines.unsubscribe() }
        catch (error) { }
        this.synopticConfig = this.parseSynopticConfig(this.monitoringData, this.currentSynopticId);
        this.getMonitoringData(this);
      }
    });
  }

  openImageDialog(widget) {

    if (this.appConfig?.machineMonitoring?.machineVariables?.length > 0) {

      let machineVariables: any = this.clonerService.deepClone(this.appConfig.machineMonitoring.machineVariables);

      if (widget?.MSVariable != null) {
        try {

          let targa = this.machine?.[widget.keyToSearchInMachine]?.find(x => x[widget?.referenceKey ?? 'id'] == widget.MSVariable);

          machineVariables.forEach(elem => elem.value = targa?.[elem.variable]);

          this.dialog.open(ImageDialogComponent,
            {
              panelClass: 'ff-dialog',
              data: {
                title: this.translate.instant('DATAPOINTS.' + widget.datapoint),
                currentSynoptic: this.currentSynopticId,
                machineVariables: machineVariables
              },
            }
          );

        } catch (error) {
          console.log(error);
        }
      }
    }

  }

  openAnnealerDialog(widget: any) {
    if (!widget.hasOwnProperty('datapointList')) {
      try {
        widget.datapointList = widget.json.params.datapointList;
      } catch (error) {
        // console.log(error);
      }
    }

    let data = {
      title: this.translate.instant('DATAPOINTS.' + widget.datapoint),
      machine: this.machine,
      widget: widget,
      monitoringData: this.monitoringData,
      interval: this.intervalService.getIntervalById('last30Minutes'),
      datapointList: widget.datapointList
    };


    this.annealerDialog = this.dialog.open(AnnealerDialogComponent,
      {
        width: '60%',
        panelClass: 'ff-dialog',
        data: data
      });

    this.annealerDialog.afterClosed().subscribe(result => {
      // console.log(result)
    });
  }

  openExternalUrl(widget) {
    if (widget?.externalUrl) window.open(widget.externalUrl, '_blank');
    else this.internalDataService.openSnackBar("Missing property \"externalUrl\"",
      'right',
      'bottom',
      4000,
      '',
      ['warning']
    );
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-ms-telemetry");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs('machineMonitoring');
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        if (_this.dashboardConfig != null && Array.isArray(_this.dashboardConfig) && _this.dashboardConfig.length > 0) _this.noSynoptic = true;
        else if (_this.dashboardConfig?.type == 'repeated-card') _this.repeatedCards = true;
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'machineMonitoring');
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, 'remote-monitoring');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // GET TABULAR CONFIG

  public getTabularConfig(_this: any) {
    try {

      // console.log(_this.machine);

      const sources40F = _this.appInfo.sources40F != null ? _this.appInfo.sources40F : 'assets/config/';

      _this.apiService.sendGetRequest(sources40F + _this.machineId + '_tabular-remote-monitoring.json').pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          _this.apiService.sendGetRequest(sources40F + 'default_tabular-remote-monitoring.json').pipe(
            retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
            catchError(error => {
              error = {
                ...error, ...{
                  error: {
                    "customMessage": "Missing file: default_tabular-remote-monitoring.json",
                    "customCode": "404",
                  }
                }
              }
              return _this.internalDataService.parseStandardHTTPError(_this, error);
            }))
            .subscribe(
              (data: any) => {
                // console.log(data);

                let tabular = data.body;
                let db = _this.clonerService.deepClone(_this.dashboardConfig)
                tabular = tabular.concat(db)
                _this.dashboardConfig = tabular


                _this.dispatcherService.getDispatch(_this, 300);
              },
            );

          return throwError('Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            // console.log(data);

            let tabular = data.body;
            let db = _this.clonerService.deepClone(_this.dashboardConfig)
            tabular = tabular.concat(db)
            _this.dashboardConfig = tabular


            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }


  parseDasboardConfig(_this: any) {

    try {

      let dashboardConfig: any = _this.clonerService.deepClone(_this.dashboardConfig.widgets)
      let ix = dashboardConfig.findIndex((w: any) => w.type == 'ff-info-gauge')
      let infoGauge: any = {}
      if (ix != -1) {

        infoGauge = dashboardConfig[ix]

        if (infoGauge.hasOwnProperty('config') && infoGauge.config.hasOwnProperty('gaugeConfig')) {
          infoGauge.config.gaugeConfig.valueLabel = _this.reelLabel
        }

        if (infoGauge.hasOwnProperty('config') && infoGauge.config.hasOwnProperty('infoConfig')) {
          let objKeys = Object.keys(infoGauge.config.infoConfig)
          objKeys.forEach((key: any) => {
            if (infoGauge.config != null && infoGauge.config.infoConfig != null && infoGauge.config.infoConfig[key] != null) {
              infoGauge.config.infoConfig[key].forEach((element: any) => {
                if (Array.isArray(element.variable)) element.variable = element.variable.join(_this.reelId, "")
              });
            }
          })
        }

        _this.dashboardConfig.widgets[ix] = infoGauge

        _this.dashboardConfig = _this.clonerService.deepClone(_this.dashboardConfig)

      }
      _this.dispatcherService.getDispatch(_this, 300)
    } catch (error) { console.log(error) }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // GET SYNOPTIC CONFIG

  public getSynopticConfig(_this: any) {
    try {

      if (_this.repeatedCards) {
        _this.dispatcherService.getDispatch(_this, 300);
        return;
      }

      let url = '/apif/get-custom-file';
      let fileName = 'synopticConfig.json';

      let profileId = null;
      try { profileId = _this.machineProfiles.profiles.find(x => x?.machineIdList?.includes(_this.machineId)).id }
      catch (error) { }

      let query: any = {
        fromWeb: _this.appInfo?.sources40F != null ? 1 : 0,
        source: _this.appInfo?.sources40F ?? '/assets/config/',
        machineId: _this.machineId,
        fileName: fileName,
      };

      if (profileId != null) query.profileId = profileId;

      _this.apiService.sendGetRequest(url, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {
          error = {
            ...error, ...{
              error: {
                "customMessage": "Missing file: default-" + fileName,
                "customCode": "404",
              }
            }
          }
          return _this.internalDataService.parseStandardHTTPError(_this, error);
        }))
        .subscribe(
          (data: any) => {

            // console.log(data.body);
            _this.synopticConfigDefault = data.body;
            if (Object.keys(_this.synopticConfigDefault).length <= 1) _this.hideSynopticSelectionButton = true;

            if (_this.noSynoptic) _this.dispatcherService.getDispatch(_this, 302);
            else _this.dispatcherService.getDispatch(_this, 300);

          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }

  }

  parseSynopticConfig(data: any, currentSynoptic: any) {

    let config = this.clonerService.deepClone(this.synopticConfigDefault ?? {});

    if (config == null || Object.keys(config).length == 0) {
      return {};
    }

    let c_syn = currentSynoptic != null ? currentSynoptic : 'mainPlant';

    // console.log(config, data);

    if (config[c_syn] != null) {

      // Synoptic image

      let sources40F = this.appInfo.sources40F != null ? this.appInfo.sources40F : 'assets/images/';
      config[c_syn].image = sources40F + config[c_syn].background;

      // Check if synoptic datapoints exist
      if (config[c_syn].hasOwnProperty('datapoints') && config[c_syn].datapoints != null && config[c_syn].datapoints.length > 0) {

        // OLD, TO REMOVE --- Filter config flag
        // config[c_syn].datapoints = this.internalDataService.filterConfigFlag(config[c_syn].datapoints, this.machine);

        // Loop on synoptic datapoints
        config[c_syn].datapoints.forEach((dp: any) => {

          // Title unit conversion
          if (dp.titleUnit != null && dp.titleUnit != '') dp.titleUnit = this.filterService.checkUnitParenthesis(dp.titleUnit);

          // Widget coloration
          if (dp.coloration != null) {
            try {
              let colorVar = data[dp.coloration.variable];
              if (colorVar != null) {
                let c_col = this.machine.profile.monitoringStates[dp.coloration.type ?? 'default'].find((x: any) => x?.[dp.coloration.idVariable ?? 'state'] == colorVar);
                dp.bgColor = c_col.bgColor ?? c_col.color;
                dp.fontColor = c_col.fontColor;
              }
            } catch (error) { }
          }

          // Table datapoint
          if (dp.type == 'table') {

            // headers
            if (dp?.headers?.length > 0) {

              dp.headers = dp.headers.map((h: any) => {
                let label: any = this.clonerService.deepClone(h?.label ?? h);
                if (label != null && label != '') {
                  label = this.internalDataService.parseLabelFromCustomTranslations(label, this.machineId);
                  if (h?.unit != null && h?.unit != '') label = label + ' ' + this.translate.instant(this.filterService.checkUnitParenthesis(h.unit) ?? '-');
                }
                if (typeof h == 'object') h.label = label;
                return typeof h == 'object' ? h : label;
              });
            }

            // MFL
            // Rows flag --> remove rows according to datapoint
            try {
              if (dp?.rowsFlags?.length) {
                dp.rowsFlags.forEach(rowFlag => {
                  if (rowFlag?.showIfIsIncludedIn?.length &&
                    rowFlag?.datapoint != null &&
                    data?.[rowFlag.datapoint] != null &&
                    rowFlag.showIfIsIncludedIn.includes(data[rowFlag.datapoint])
                  ) {
                    dp.rows[rowFlag.rowIndex].hide = true;
                  }
                });

                if (dp.rows?.every(row => row.hide)) dp.hide = true;
              }
            } catch (error) {
              console.log(error);
              console.log("error in rowFlags, datapoint: " + dp);
            }

            // MFL - Parse actual diameters for widget trafila
            if (dp.actualDiameters?.length > 0) {
              dp.actualDiametersP = dp.actualDiameters.filter(diam => {
                try { return this.machine.profile.monitoringStates.blockStates.find(x => x.state == data[diam.flag]).hideBlock == false }
                catch (error) { return false }
              }).map(x => x?.value);
            }

            // MFL - Parse setpoint diameters for widget trafila
            if (dp.setpointDiameters?.length > 0) {
              dp.setpointDiametersP = dp.setpointDiameters.filter(diam => {
                try { return this.machine.profile.monitoringStates.blockStates.find(x => x.state == data[diam.flag]).hideBlock == false }
                catch (error) { return false }
              }).map(x => x?.value);
            }

            if (dp.rows?.length) {
              dp.rows.forEach((r: any) => {
                r.forEach((c: any) => {

                  // Type alarm
                  if (c.type == 'alarm') {
                    try {
                      let colorVar = data[c.datapoint];
                      let c_col = this.machine.profile.monitoringStates?.[c.alarmType ?? 'default']?.find((x: any) => x.state == colorVar);
                      c.alarmColor = c_col?.bgColor ?? c_col?.color;
                    }
                    catch (error) { console.log(error) }
                  }

                  // MFL - Wire reduction
                  else if (c?.wireDatapoint != null) {

                    let diamList = c.wireReduction != 'setpoint' ? dp.actualDiametersP : dp.setpointDiametersP;

                    try {
                      c.iconWarning = null;
                      if (diamList.includes(c.wireDatapoint)) {

                        let cIdx = diamList.indexOf(c.wireDatapoint);
                        let pIdx = cIdx != 0 ? (cIdx - 1) : 0;

                        let currentDiam = data?.[c.wireDatapoint];
                        let initialDiameterValue = dp.initialDiameterDatapoint != null ? data?.[dp.initialDiameterDatapoint] : dp?.initialDiameterValue;
                        let previousDiam = (cIdx == 0 && pIdx == 0) ? (initialDiameterValue) : data[diamList[pIdx]];

                        let perc = (currentDiam / previousDiam) * (currentDiam / previousDiam);
                        perc = !isNaN(perc) && isFinite(perc) ? 1 - perc : null;

                        c.iconWarning = {
                          icon: 'warning',
                          type: 'icon',
                          class: 'md-orange'
                        };

                        if (dp.maxWireReduction != null && perc >= dp.maxWireReduction) c.iconWarningType = 'upper';
                        else if (dp.minWireReduction != null && perc <= dp.minWireReduction) c.iconWarningType = 'lower';
                        else {
                          c.iconWarningType = null;
                          c.iconWarning = null;
                        }

                        c.value = perc != null ? this.filterService.parseGaugeValue(perc, c.decimals, 100) : "-";
                      }

                    } catch (error) { }
                  }

                  // Default type
                  else {
                    let value = c.value;

                    let variableConfig = null;

                    // Get custom variable config from variables.json file
                    if (this.appConfig?.addCustomVariables) variableConfig = this.internalDataService.getCustomVariableConfig(c.datapoint, null, 'telemetry', this.machineId);

                    if (variableConfig != null) {
                      c.unit = c.unit ?? variableConfig.unit;
                      c.decimals = c.decimals ?? variableConfig.decimals;
                      c.multiplier = c.multiplier ?? variableConfig.multiplier ?? 1;
                    }

                    if (value != null) {
                      value = this.internalDataService.parseDatapointLabel(value, null, this.machineId);
                    }

                    else {

                      let isBetweenBrackets = false;

                      if (c.unit != null) {
                        isBetweenBrackets = c.unit.startsWith("[");
                        c.unit = isBetweenBrackets ? c.unit.substring(1, c.unit.length - 1) : c.unit;
                        c.defaultUnit = c.unit;
                      }

                      let newValues = {
                        unit: c.unit,
                        value: data[c.datapoint],
                      };

                      try { newValues = this.filterService.convertUnit(c.unit, data[c.datapoint]) }
                      catch (error) { console.log(error) }

                      data[c.datapoint] = newValues.value;
                      c.unit = isBetweenBrackets ? ('[' + newValues.unit + ']') : newValues.unit;

                      if (c.label != null) {
                        c.label = this.internalDataService.parseLabelFromCustomTranslations(c.label, this.machineId);
                      }

                      value = c.label ?? this.filterService.parseObjFromConfig(data[c.datapoint], c, true, 'parsingType');
                      if (value != null && c.unit != null && !c.hideUnit && c?.unit != '') value += ' ' + this.translate.instant(c.unit ?? '-');
                    }

                    c.value = value;
                  }

                  if (c.cellType != null) {

                    let elTable = data[c.datapoint];

                    if (c.columnType == null) {
                      let newValObj = this.machine.profile?.[c.cellType];
                      let newVal = newValObj?.find((x: any) => x?.[c?.inputVariable ?? 'id'] == elTable);
                      if (newVal != null) c.value = this.internalDataService.parseLabelFromCustomTranslations(newVal?.[c?.outputVariable ?? 'label'] ?? '-');
                    } else {
                      let newValObj = this.machine.profile?.[c.columnType]?.[c.cellType];
                      let newVal = newValObj?.find((x: any) => x?.[c?.inputVariable ?? 'state'] == elTable);
                      if (newVal != null) c.value = this.internalDataService.parseLabelFromCustomTranslations(newVal?.[c?.outputVariable ?? 'value'] ?? '-');
                    }
                  }

                  let title = c.title != null ? this.translate.instant(c.title) : null;

                  // Title null
                  if (title == null) c.title = this.internalDataService.parseLabelFromCustomTranslations(dp.title, this.machineId) + ' - ' + this.internalDataService.parseDatapointLabel(c.datapoint, null, this.machineId);

                  // Title not null
                  else {
                    c.title = title;
                    if (c.unit != null && c?.unit != '') c.title += (' ' + this.translate.instant(c.unit ?? '-'))
                  }

                  r.hide = r.every((col: any) => col.hide == true);

                });
              });

              if (dp.rows.every((x: any) => x.hide)) dp.hide = true;

            }

            if (dp?.subType == 'clothsTable' && data?.clothsData?.length) {
              dp.rows = [];

              let clothConfig = [
                {
                  "label": this.translate.instant("REMOTE_MONITORING.SAP_CODE"),
                  "variable": "sapCode"
                },
                {
                  "label": this.translate.instant("REMOTE_MONITORING.WORST_CLOTH"),
                  "variable": "worst",
                  "decimals": 0
                },
                {
                  "label": this.translate.instant("REMOTE_MONITORING.BEST_CLOTH"),
                  "variable": "best",
                  "decimals": 0
                },
                {
                  "label": this.translate.instant("REMOTE_MONITORING.AVG_CYCLES_NUMBER"),
                  "variable": "average",
                  "decimals": 1
                },
              ];

              dp.headers = clothConfig.map((x: any) => x.label);

              data.clothsData?.filter((x: any, index: any) => index < 4)?.forEach((cloth: any) => {

                let row: any = [];
                clothConfig.forEach((c_c: any) => {
                  row.push({
                    title: c_c.label,
                    value: this.filterService.parseGaugeValue(cloth?.[c_c.variable], c_c?.decimals ?? 0, 1),
                    class: c_c.class
                  });
                });

                dp.rows.push(row);
              });
            }
          }

          // Multiple thresholds - MAX two greater and two lower
          if (dp.type == 'multipleThresholds') {

            try {

              let thresholdsConfig = dp?.thresholdsConfig;

              if (thresholdsConfig != null) {

                let upperThresholds = thresholdsConfig?.upperThresholds ?? {};
                let lowerThresholds = thresholdsConfig?.lowerThresholds ?? {};
                let classesConfig = thresholdsConfig?.classesConfig ?? {};

                let ltAlarm = lowerThresholds?.alarm;
                let ltWarning = lowerThresholds?.warning;
                let utWarning = upperThresholds?.warning;
                let utAlarm = upperThresholds?.alarm;

                if (
                  (ltAlarm != null ? data?.[ltAlarm] == null : true) &&
                  (ltWarning != null ? data?.[ltWarning] == null : true) &&
                  (utWarning != null ? data?.[utWarning] == null : true) &&
                  (utAlarm != null ? data?.[utAlarm] == null : true)
                ) {
                  dp.class = classesConfig?.default ?? null;
                } else {

                  let actualVal = data[dp.datapoint];

                  // ALARM
                  if (
                    (utAlarm != null ? actualVal >= data?.[utAlarm] : false) ||
                    (ltAlarm != null ? actualVal <= data?.[ltAlarm] : false)
                  ) {
                    dp.class = classesConfig?.alarm ?? 'md-red';
                  }

                  // DEFAULT
                  else if (
                    (utAlarm != null ? actualVal <= data?.[utAlarm] : (utWarning != null ? actualVal <= data?.[utWarning] : true)) &&
                    (ltAlarm != null ? actualVal >= data?.[ltAlarm] : (ltWarning != null ? actualVal >= data?.[ltWarning] : true))
                  ) {
                    dp.class = classesConfig?.normal ?? 'md-green';
                  }

                  // WARNING
                  else {
                    dp.class = classesConfig?.warning ?? 'md-orange';
                  }

                }

              } else {
                dp.class = null;
              }

            } catch (error) {
              dp.class = null;
              console.log(error);

            }

          }

          // Two thresholds - greater and lower
          if (dp.type == 'greater_lower') {

            try {
              let grVar = dp.greaterVariable ?? dp.datapointSet;
              let lowVar = dp.lowerVariable ?? dp.datapointSet;

              let grVal = dp.greaterValue ?? this.clonerService.deepClone(data[grVar]);
              let lowVal = dp.lowerValue ?? this.clonerService.deepClone(data[lowVar]);

              // console.log(grVal, lowVal);

              if (grVal != null && lowVal != null) {
                dp.class = (data[dp.datapoint] > grVal || data[dp.datapoint] < lowVal) ? 'md-red' : 'md-green';
              } else { dp.class = null }

            } catch (error) {
              dp.class = null;
            }

          }

          // One thresholds - greater
          else if (dp.type == 'greater') {
            try {
              let grVar = dp.greaterVariable ?? dp.datapointSet;
              dp.class = data[dp.datapoint] > data[grVar] ? 'md-red' : 'md-green';
            } catch (error) {
              dp.class = null;
            }

          }

          // One thresholds - greater
          else if (dp.type == 'smaller') {
            try {
              let lowVar = dp.lowerVariable ?? dp.datapointSet;
              dp.class = data[dp.datapoint] < data[lowVar] ? 'md-red' : 'md-green';
            } catch (error) {
              dp.class = null;
            }
          }

          // wire type string
          if (dp.type == 'wireTypeString') {
            try {
              let devicesFlagArray = data[dp.datapoint].split(";").map(x => {
                return {
                  id: x.split("=")[0],
                  value: x.split("=")[1]
                }
              });
              let newValue = devicesFlagArray.findIndex(dev => dev.value == dp.takeUpIndex) != -1 ?
                devicesFlagArray[devicesFlagArray.findIndex(dev => dev.value == dp.takeUpIndex)].id :
                this.translate.instant('REMOTE_MONITORING.NO_WIRE');

              data[dp.datapoint] = newValue;
              dp.value = this.filterService.parseGaugeValue(data[dp.datapoint], dp.decimals, dp.multiplier);

            } catch (error) {
              console.log(error);
              dp.value = data[dp].value;
            }
          }

          else if (dp.type == 'linkToMainSynoptic') dp.action = "synopticLink";

          // Value datapoint
          else {

            let variableConfig = null;

            // Get custom variable config from variables.json file
            if (this.appConfig?.addCustomVariables) variableConfig = this.internalDataService.getCustomVariableConfig(dp.datapoint, null, 'telemetry', this.machineId);

            if (variableConfig != null) {
              dp.unit = dp.unit ?? variableConfig.unit;
              dp.decimals = dp.decimals ?? variableConfig.decimals;
              dp.multiplier = dp.multiplier ?? variableConfig.multiplier ?? 1;
            }

            if (typeof data[dp.datapoint] == 'string') {
              dp.value = data[dp.datapoint];
            } else {

              let isBetweenBrackets = false;

              if (dp.unit != null) {
                isBetweenBrackets = dp.unit?.startsWith("[");
                dp.unit = isBetweenBrackets ? dp.unit.substring(1, dp.unit.length - 1) : dp.unit;
              }

              dp.defaultUnit = dp.unit;

              let newValues = {
                unit: dp.unit,
                value: data[dp.datapoint],
              };

              try {
                newValues = this.filterService.convertUnit(dp.unit, data[dp.datapoint]);
              } catch (error) {
                console.log(error);
              }

              if (dp.cellType != null && dp.columnType == null) {
                let elTable = data[dp.datapoint];
                let newValObj = this.machine.profile?.[dp.cellType];
                let newVal = newValObj.find((x: any) => x.id == elTable);
                if (newVal != null) {
                  newValues.value = this.translate.instant(newVal.label ?? '-');
                  if (newVal?.class != null) dp.class = newVal.class;
                }
              }

              if (dp.cellType != null && dp.columnType != null) {
                let elTable = data[dp.datapoint]
                let newValObj = this.machine.profile?.[dp.columnType]?.[dp.cellType]
                let newVal = newValObj.find((x: any) => x.state == elTable)
                if (newVal != null) newValues.value = this.translate.instant(newVal.value ?? '-')
              }

              data[dp.datapoint] = newValues.value;
              dp.unit = isBetweenBrackets ? ('[' + newValues.unit + ']') : newValues.unit;

              dp.value = this.filterService.parseGaugeValue(data[dp.datapoint], dp.decimals, dp.multiplier);

              if (dp.value != null && dp.unit != null && !dp.hideUnit && dp?.unit != '') dp.value += ' ' + this.translate.instant(dp.unit ?? '-');
            }

            dp.title = this.internalDataService.parseLabelFromCustomTranslations(dp.title ?? dp.datapoint, this.machineId);

            if (dp.unit != null && dp.hideUnit && dp?.unit != '') dp.title += ' [' + this.translate.instant(dp.unit ?? '-') + ']';

            dp.alarmColor = null;
            if (dp.alarm != null) {
              dp.alarmColor = data.activeAlarms?.[dp.alarm] != null ? 'red' : 'green';
            }

            if (dp.type == 'alarm') {

              try {
                let colorVar = data[dp.datapoint];
                if (colorVar != null) {
                  let alarmType = dp.alarmType ?? 'default';
                  let c_col = this.machine.profile.monitoringStates[alarmType].find((x: any) => x.state == colorVar);

                  dp.alarmColor = c_col.bgColor ?? c_col.color;
                }
              } catch (error) { }
            }

            if (dp?.datapointSet != null) {

              dp.value2 = this.filterService.parseGaugeValue(this.filterService.convertUnit(dp.defaultUnit, data[dp.datapointSet]).value, dp.decimals, dp.multiplier);

              if (dp.showLabel != null) {
                let val = dp.value ?? '-';
                let val2 = dp.value2 ?? '-';
                dp.value = this.translate.instant('DATAPOINTS.ACT') + ': ' + val;
                dp.value2 = this.translate.instant('DATAPOINTS.SET') + ': ' + val2;
              }

              if (dp.value2 != null && dp.defaultUnit != null && !dp.hideUnit) dp.value2 += ' ' + dp.unit;

            }

            if (dp.hasOwnProperty('showArticle')) {
              dp.tooltip = null;
              try {
                let maxArticleLength = dp.maxArticleLength ?? 20;
                let article = data[dp.articleDatapoint ?? ("ProductionData.articleId" + dp.configFlag.index)];
                dp.value = article.length >= maxArticleLength ? (article.substring(0, maxArticleLength) + '...') : article;
                dp.tooltip = article.length >= maxArticleLength ? article : null
              } catch (error) {
                dp.value = null;
              }
            }

            if (dp?.customIcon != null) dp.showCustomIcon = dp?.customIcon?.availableValues?.includes(data?.[dp?.customIcon?.datapoint]);

            if (dp?.datapointFlagConfig != null) dp.hide = !dp?.datapointFlagConfig?.availableValues?.includes(data?.[dp?.datapointFlagConfig?.datapoint]);

          }

        });
      }

    }

    return config[c_syn];
  }

  getMonitoringData(_this: RemoteMonitoringComponent) {
    try {

      let query: any = {
        tz: _this.machine.timezone,
      };

      if (_this.machine.clothNumber != null) query.clothNumber = _this.machine.clothNumber;

      let url = '/apif/machine-monitoring/remote-monitoring/' + _this.machineId;

      let aspects: any = [];
      if (!_this.appConfig.MAT2) {
        aspects = _this.clonerService.deepClone(_this.machine.profile?.aspects ?? []);
      }
      // let aspects: any = _this.clonerService.deepClone(_this.machine.profile?.aspects ?? []);

      let config: any = _this.clonerService.deepClone(_this.synopticConfigDefault ?? {});

      let c_syn = _this.currentSynopticId ?? 'mainPlant';


      /*
        SUGGESTION: Introduce all variables from telemtry object in variables.json
        if flag "inMonitoring" == true.
        Not OK for MFL --> too many variables alltogether
      */

      // let c_customVariables = _this.cacheService.get("customVariables");

      // // Cached custom variables
      // if (_this.appConfig?.addCustomVariables && c_customVariables?.machineId == _this.machineId && c_customVariables?.value != null) {
      //   aspects = (c_customVariables?.value?.telemetry ?? []).reduce((acc, val) => {
      //     if (val?.inMonitoring == null) val.inMonitoring = true;
      //     if (!val?.inMonitoring) return acc;

      //     if (!acc.includes(val.id)) acc.push(val.id);
      //     return acc;
      //   }, aspects)
      // }

      if (config[c_syn]?.aspects == null) config[c_syn].aspects = [];
      config[c_syn]?.datapoints?.forEach(val => {

        // Add datapoint to aspects of current synoptic (if not already present)
        if (val?.datapoint != null && _this.checkValidDatapoint(val?.datapoint) == true && !config[c_syn].aspects?.includes(val?.datapoint)) {
          config[c_syn].aspects.push(val?.datapoint);
        }

        // Add datapoint to aspects of current synoptic (if not already present)
        if (val?.datapointSet != null && _this.checkValidDatapoint(val?.datapointSet) == true && !config[c_syn].aspects?.includes(val?.datapointSet)) {
          config[c_syn].aspects.push(val?.datapointSet);
        }

        if (val?.type == 'table') {
          val?.rows?.forEach(row => {
            row?.forEach(col => {
              if (col?.datapoint != null && _this.checkValidDatapoint(col?.datapoint) == true && !config[c_syn].aspects?.includes(col?.datapoint)) {
                config[c_syn].aspects.push(col?.datapoint);
              }
            });
          })
        }
      })

      if (config?.[c_syn] != null) {
        try { aspects = _this.clonerService.deepClone(aspects.concat(config[c_syn].aspects ?? []).filter(_this.filterService.onlyUnique)) }
        catch (error) { console.log(error); }
      }

      let payload: any = null;

      if (_this.appConfig.MAT2) {
        payload = _this.internalDataService.buildMachinePayload(_this.machine);
        payload.customKpis.remoteMonitoring.lastValuesVariables = (payload.customKpis.remoteMonitoring?.lastValuesVariables ?? []).concat(aspects);
      } else {
        payload = aspects;
      }

      _this.pollingMachines = timer(0, _this.pollingTime).subscribe((count => {
        _this.apiService.sendPostRequest(url, payload, query).pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, count == 0 ? _this.pollingMachines : null)))
          .subscribe(
            (data: any) => {

              _this.monitoringData = _this.parseMonitoringData(data.body);
              _this.synopticConfig = _this.parseSynopticConfig(data.body, _this.currentSynopticId);

              if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
            }
          );

      }));
    } catch (error) {
      console.log(error);
    }
  }

  parseMonitoringData(data: any) {

    try {
      if (data != null && Object.keys(data).length > 0) {

        // neverC enabled
        if (data.hasOwnProperty('neverC') && data.neverC != null) {
          if (data.neverC) {
            data.notConnected = false;
            data.neverConnected = true;
            data.lastUpdateP = data.lastUpdate != null ? moment(data.lastUpdate).tz(this.machine.timezone).format("DD MMM YYYY - HH:mm:ss") : this.translate.instant("MACHINE_STATES.NEVER_CONNECTED");
          }
          else {
            data.notConnected = true;
            data.neverConnected = false;
            data.lastUpdateP = data.lastUpdate != null ? moment(data.lastUpdate).tz(this.machine.timezone).format("DD MMM YYYY - HH:mm:ss") : this.translate.instant("MACHINE_STATES.NOT_CONNECTED");
          }
        }

        // No neverC property
        else {
          data.lastUpdateP = data.lastUpdate != null ? moment(data.lastUpdate).tz(this.machine.timezone).format("DD MMM YYYY - HH:mm:ss") : this.translate.instant("MACHINE_STATES.NEVER_CONNECTED");

          data.neverConnected = data.lastUpdate == null;
          let connectionTime = this.machine.profile?.ukwStateThresholdSeconds != null ? this.machine.profile?.ukwStateThresholdSeconds / 60 : (this.appConfig.machineMonitoring?.warningMinutes ?? 15);
          data.notConnected = moment().diff(moment(data.lastUpdate), 'm') > connectionTime;
        }


        try {
          if (this.machine.profile.hasOwnProperty('phases') && this.machine.profile.phases != null) {
            let phaseConf = this.machine.profile.phases.find((p: any) => p.inputPhases.includes(data["FilterState.phaseId"]));
            if (phaseConf != null) {
              data["FilterState.phaseId"] = phaseConf.outputPhaseId;
              data["FilterState.phaseLabel"] = this.translate.instant(phaseConf.label);
            }
          }
        } catch (error) {
          console.log(error);
        }

        // Parse Alarm Flags
        if (Object.keys(data).some((x: any) => x.startsWith('Alarms.alarmFlag'))) {
          Object.keys(data).filter((x: any) => x.startsWith('Alarms.alarmFlag')).forEach((alarmFlag: any, alarmFlagIndex: number) => {
            if (typeof data[alarmFlag] == 'string') [...data[alarmFlag]].forEach((alarm: any, index: any) => data["alarm_" + (parseInt(index) + 1 + alarmFlagIndex * 255)] = +alarm);
          });
        }

        // Parse tabular data
        if (this.noSynoptic && this.dashboardConfig && Array.isArray(this.dashboardConfig)) {
          this.dashboardConfig.forEach((col: any) => {
            col.widgets.forEach((widget: any) => {
              if (Array.isArray(widget.config) && widget.config.length > 0) {
                widget.config.forEach((config: any) => {
                  if (config.type === 'date') {
                    data[config.variable] = this.filterService.parseMoment(data[config.variable], 'default')
                  }
                });
              }
            });
          })
        }

        this.internalDataService.setMonitoringData(data);

        return data;
      }
      return {};
    } catch (error) {
      console.log(error);
      return {};
    }

  }


  checkValidDatapoint(dp) {

    // console.log({ dp });

    let checkNull = dp != null;
    let checkValidFormat = dp?.split(".")?.length > 1;
    let checkValidLabels = ["SYNOPTIC"].every(x => !dp?.startsWith(x));

    let finalCheck = checkNull && checkValidFormat && checkValidLabels;

    return finalCheck;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    )
    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() {
    // console.log(this);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // DESTROY
  ngOnDestroy() {
    try { this.pollingMachines.unsubscribe() } catch (error) { }
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.datapointSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
  }

}