import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'app-last-updates-detail-dialog',
  templateUrl: './last-updates-detail-dialog.component.html',
  styleUrls: ['./last-updates-detail-dialog.component.scss']
})
export class LastUpdatesDetailDialogComponent implements OnInit {

  public list: any = [];
  public appConfig: any;
  public warningMinutes: any = 15;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public appConfigService: AppConfigService,
    public clonerService: ClonerService,
    public internalDataService: InternalDataService,
    public translate: FfTranslateService,
  ) {
    this.appConfig = this.appConfigService.getAppConfig;

    try {
      this.warningMinutes = this.appConfig.machineMonitoring.warningMinutes != null ? this.appConfig.machineMonitoring.warningMinutes : this.warningMinutes;
    } catch (error) {
      console.log("%c Default warning minutes: " + this.warningMinutes, 'color: red; font-weight: bold;');
    }
  }

  ngOnInit(): void {
    let list: any = [];
    if (this.dialog != null && this.dialog.list != null && this.dialog.list.length > 0) {

      list = this.clonerService.deepClone(this.dialog.list);

      list = list.map((machine: any) => {
        machine.neverConnected = machine.lastUpdate == null;
        machine.notConnected = moment().diff(moment(machine.lastUpdate), 'm') > this.warningMinutes;

        machine.lastUpdateP = machine.lastUpdate != null ? moment(machine.lastUpdate).format("DD MMM YYYY - HH:mm:ss") : this.translate.instant("MACHINE_STATES.NEVER_CONNECTED");
        return machine;
      });

    }
    this.list = this.clonerService.deepClone(list);

  }
}