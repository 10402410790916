<!-- error & loading -->
<div *ngIf="pageState.value > 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
  <!-- utils -->
  <!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->
</div>

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady || pageState.value == 0" fxLayout="column" fxLayoutAlign="center start"
  class="w100 h100">

  <!-- toolbar -->
  <div fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">
    <!-- tabs -->
    <ff-tabs [tabs]="tabs"></ff-tabs>
  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start center" class="tab-content">

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value != 0 && pageState.value < pageStateReady" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div fxLayout="row" fxLayoutAlign="start center" class="w100">

      <div class="interval-selector" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="start center">

        <div *ngIf="availablePhases != null && availablePhases.length > 0" class="box" fxLayout="row"
          fxLayoutAlign="start center">
          <mat-icon class="icon">event_available</mat-icon>
          <mat-form-field appearance="fill">
            <mat-label>{{'PROCESS_LOG.PHASE_SELECTION'|translate}}</mat-label>
            <mat-select [(value)]="phaseSelectedId">
              <mat-option *ngFor="let phase of availablePhases" [value]="phase.outputPhaseId"
                [disabled]="phase.outputPhaseId != 0 && isComparedCycle"
                (click)="phaseSelectionChange(phaseSelectedId)">{{phase.label|translate}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <span fxFlex></span>

      <button *ngIf="isComparedCycle" mat-button (click)="removeComparedCycle()" class="filter-button md-red-i"
        fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="icon">cancel</mat-icon>
        {{ 'PROCESS_LOG.REMOVE_COMPARED_CYCLE' | translate }}
      </button>

      <button *ngIf="phaseSelectedId == 0" mat-button (click)="openCycleComparison()" class="filter-button"
        fxLayout="row" fxLayoutAlign="center center" [disabled]="pageState.value == 0">
        {{ 'PROCESS_LOG.CYCLE_COMPARISON' | translate }}
        <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
          style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
        </mat-icon>
      </button>

      <div *ngIf="phaseSelectedId == 0" fxLayout="row" fxLayoutAlign="center center" style="margin-right: 12px;">
        <span style="margin-right: 8px;">
          {{'PROCESS_LOG.PHASE_FLAGS'|translate}}
        </span>
        <ui-switch [checked]="showFlags" (change)="toggleShowFlags($event)"
          [disabled]="!pageStateReady || pageState.value == 0">
        </ui-switch>
      </div>

      <button mat-button (click)="openVariablesSelectionDialog()" class="filter-button" fxLayout="row"
        fxLayoutAlign="center center" [disabled]="pageState.value == 0">
        {{ 'PROCESS_LOG.VARIABLES_SELECTION' | translate }}
        <mat-icon svgIcon="curtain_right" class="icon" translate-attr="{'aria-label': datapoint.title}"
          style="width: 1rem; height: 1rem; line-height: 0; margin-left: 8px;">
        </mat-icon>
      </button>

    </div>

    <div *ngIf="pageStateReady && phaseSelectedId != 0"
      style="border-radius: 25px; background-color: white; margin: 12px 0 4px 4px;" class="w100">
      <div class="w100 ff-widget-container" fxLayout="row wrap" fxLayoutAlign="start center"
        fxLayoutGap="{{ phaseDataConfig.gap != null ? (phaseDataConfig.gap + ' grid') : '8px grid' }}">
        <ff-widget *ngFor="let w of phaseDataConfig.widgets" fxFlex.xl
          [fxFlex.lt-xl]="'calc(' + w.flex + '% - ' + (phaseDataConfig.gap != null ? phaseDataConfig.gap : '8px') + ')'"
          [widget]="{ type: w.type, data: phaseData, machineProfile: machine.profile, config: w.config, title: w.title }">
        </ff-widget>
      </div>
    </div>

    <div *ngIf="pageStateReady && phaseSelectedId == 0"
      style="border-radius: 25px; background-color: white; margin: 12px 0 4px 4px;" class="w100">
      <div class="cycle-data-title" *ngIf="isComparedCycle">
        <span>{{ 'PROCESS_LOG.ACTUAL_CYCLE' | translate }}</span>
      </div>
      <div class="w100 ff-widget-container" fxLayout="row wrap" fxLayoutAlign="start center"
        fxLayoutGap="{{ cycleDataConfig.gap != null ? (cycleDataConfig.gap + ' grid') : '8px grid' }}">
        <ff-widget *ngFor="let w of cycleDataConfig.widgets" fxFlex.xl
          [fxFlex.lt-xl]="'calc(' + w.flex + '% - ' + (cycleDataConfig.gap != null ? cycleDataConfig.gap : '8px') + ')'"
          [widget]="{ type: w.type, data: cycleData, machineProfile: machine.profile, config: w.config, title: w.title }">
        </ff-widget>
      </div>
    </div>

    <div *ngIf="pageStateReady && phaseSelectedId == 0 && isComparedCycle"
      style="border-radius: 25px; background-color: white; margin: 12px 0 4px 4px;" class="w100">
      <div class="cycle-data-title">
        <span>{{ 'PROCESS_LOG.COMPARED_CYCLE' | translate }}</span>
      </div>
      <div class="w100 ff-widget-container" fxLayout="row wrap" fxLayoutAlign="start center"
        fxLayoutGap="{{ comparedCycleDataConfig.gap != null ? (comparedCycleDataConfig.gap + ' grid') : '8px grid' }}">
        <ff-widget *ngFor="let w of comparedCycleDataConfig.widgets" fxFlex.xl
          [fxFlex.lt-xl]="'calc(' + w.flex + '% - ' + (comparedCycleDataConfig.gap != null ? comparedCycleDataConfig.gap : '8px') + ')'"
          [widget]="{ type: w.type, data: comparedCycleInfos, machineProfile: machine.profile, config: w.config, title: w.title }">
        </ff-widget>
      </div>
    </div>

    <!-- error -->
    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData" style="margin-top: 12px;"></ff-error-card>

    <div *ngFor="let group of dashboardConfig.plotsConfig.list" class="plot-container"
      [ngClass]="{'hidePlot': !group.show || pageState.value == 0}">
      <div class="phase-data-title-plots" fxLayout="row" fxLayoutAlign="start center">
        <span>{{ group.label }}</span>
        <span fxFlex></span>
        <mat-icon *ngIf="!onlyOneGroupSelected" svgIcon="faults" class="icon left clickable"
          (click)="toggleGroup(group, dashboardConfig.plotsConfig.list)">
        </mat-icon>

      </div>
      <div [id]="'prova_' + group.id" class="variables-plot"></div>
    </div>
    <!-- <div style="width: 100%; margin: auto;" fxLayout="row" fxLayoutAlign="center center">
      <i class="fad fa-spinner fa-pulse icon" style="width: 3rem; height: 3rem;"></i> 
    </div> -->

  </div>

</div>