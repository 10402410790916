import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

@Component({
  selector: 'app-variables-selection-dialog',
  templateUrl: './variables-selection-dialog.component.html',
  styleUrls: ['./variables-selection-dialog.component.scss']
})

export class VariablesSelectionDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public translate: FfTranslateService,
  ) {

  }

  toggleGroupExpansion(group: any) {
    group.expanded = group.expanded != null ? !group.expanded : true;
    console.log(group);
  }

  toggleTrace(group: any, trace: any) {
    trace.show = trace.show != null ? !trace.show : true;
    group.show = group.traces.some((x: any) => x.show);
    group.shownTraces = group.traces.filter((x: any) => x.show).length;
  }

  toggleGroup(group: any) {
    group.show = group.show != null ? !group.show : true;
    group.traces.forEach((trace: any) => trace.show = group.show);
    group.shownTraces = group.traces.filter((x: any) => x.show).length;
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
  }

}
