<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- tab content -->
  <div *ngIf="!isMobile" fxFlex fxLayout="column" class="tab-content without-tabs"
    fxLayoutAlign="{{ pageState.value < pageStateReady || pageState.value == 0 ? 'center center' : 'start start' }}">

    <!-- error -->
    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
    </ff-loading-card>

    <div id="fullcalendar" class="h100" *ngIf="pageState.value >= pageStateReady && !tableMode"
      style="border-radius: 30px; background-color: white; padding: 16px; width: calc(100% - 32px);">
      <full-calendar [options]="calendarOptions" class="w100 h100"></full-calendar>
    </div>

    <ff-widget class="w100" *ngIf="pageState.value >= pageStateReady && tableMode" fxFlex="100"
      [widget]="eventTableConfig?.widget" (clicked)="tableAction($event)">
    </ff-widget>

  </div>

</div>
