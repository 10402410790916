<span class="widget-value widget-value-title" *ngIf="widget.title != null" fxLayout="row" fxLayoutAlign="start center">
  {{ widget.title | translate }}

  <span *ngIf="widget.tooltip != null" matTooltip="{{ widget.tooltip | translate }}" matTooltipPosition="above"
    style="margin-left: 6px;">
    <mat-icon class="icon">info</mat-icon>
  </span>

</span>

<div class="w100 h100" fxLayout="column" fxLayoutAlign="start stretch" style="overflow: auto;" [ngStyle]="{
    'overflow': widget.config?.overflow != null ? widget.config.overflow : 'auto',
    'min-height': widget.config?.minHeight != null ? widget.config?.minHeight : 'unset',
    'height': widget.config?.height != null ? widget.config?.height : '100%'
  }">

  <div *ngIf="widget.rows == null || widget.rows.length == 0" class="not-found" fxLayout="row"
    fxLayoutAlign="center center">{{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}
  </div>

  <div *ngFor="let row of widget.rows" fxLayout="column" fxLayout.gt-sm="row" fxLayoutAlign="start center" class="label"
    [fxFlex.gt-sm]="widget.innerFlex != null ? widget.innerFlex : (100/row.length)" fxLayoutGap="8px">

    <div *ngFor="let elem of row" class="box2 w100 widget-value" fxLayout="row" fxLayoutAlign="start center"
      [ngClass]="elem.boxClass" class="label widget-value {{elem.action != null ? 'clickable' : '' }}">

      <!-- icon -->
      <div *ngIf="elem.icon != null" class="icon-box widget-value" [ngClass]="elem.iconClass" [ngStyle]="elem.style">
        <mat-icon *ngIf="elem.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': elem.label}"
          [ngStyle]="elem.style">
          {{elem.icon.icon }}</mat-icon>
        <mat-icon *ngIf="elem.icon.type == 'svg'" svgIcon="{{ elem.icon.icon }}" class="icon" [ngStyle]="elem.style"
          translate-attr="{'aria-label': elem.label}"></mat-icon>
        <i *ngIf="elem.icon.type == 'fontawesome' || elem.icon.type == 'phosphor'" class="icon {{ elem.icon.icon }}"
          [ngStyle]="elem.style" translate-attr="{'aria-label': elem.label}"></i>
      </div>

      <!-- content (default subtype: 'column-icon-label-value') -->
      <!-- content (subtype: 'horizontal') -->
      <div
        *ngIf="(!widget.subtype || widget.subtype == 'horizontal') && elem.type != 'progressBar' && elem.subtype != 'monitor'"
        class="content w100" fxLayout="column" fxLayoutAlign="center start">
        <div *ngIf="elem.label != null" class="title-box">{{ elem.label | translate }}</div>
        <div class="value w100" fxLayout="row">
          <span>{{ elem.value != null ? elem.value : '-' }}</span>
          <span *ngIf="elem.unit != null">&nbsp;{{ elem.unit | translate }}</span>
          <span fxFlex></span>
          <span *ngIf="elem.value2 != null">{{ elem.value2 }}</span>
        </div>
      </div>

      <div *ngIf="elem.type == 'progressBar'" class="content w100">
        <div *ngIf="elem.label != null" class="title">{{ elem.label | translate }}</div>
        <div *ngIf="elem.fixedColor == null">
          <kpi-bar
            [kpiProps]="{ 'value': elem.value, 'hideLabel': true, 'revert': elem.revert, 'class': elem.boxClass }">
          </kpi-bar>
        </div>
        <div *ngIf="elem.fixedColor != null">
          <kpi-bar
            [kpiProps]="{ 'value': elem.value, 'hideLabel': true, fixedColor: elem.fixedColor, 'revert': elem?.revert, 'class': elem.boxClass }">
          </kpi-bar>
        </div>
      </div>

      <div *ngIf="elem.subtype == 'monitor'" class="content w100">
        <div fxLayout="row" fxLayoutAlign="start center">
          <div fxFlex *ngIf="elem.label != null" class="title">{{ elem.label | translate }}</div>
          <div fxFlex="10" *ngIf="elem.fixedColor == null">
            <ff-monitor [props]="{ 'value': elem.value, colorConfigs: elem.colorConfigs }"></ff-monitor>
          </div>
          <div fxFlex="10" *ngIf="elem.fixedColor != null">

          </div>
        </div>
      </div>

      <!-- icon2 -->
      <div *ngIf="elem.icon2 != null" class="icon-box right">
        <mat-icon *ngIf="elem.icon2.type == 'icon'" class="icon" [ngClass]="elem.icon2Class"
          translate-attr="{'aria-label': elem.label}">
          {{elem.icon2.icon }}</mat-icon>
        <mat-icon *ngIf="elem.icon2.type == 'svg'" svgIcon="{{ elem.icon2.icon }}" class="icon"
          [ngClass]="elem.icon2Class" translate-attr="{'aria-label': elem.label}">
        </mat-icon>
        <i *ngIf="elem.icon2.type == 'fontawesome' || elem.icon2.type == 'phosphor'" class="icon {{ elem.icon2.icon }}"
          [ngClass]="elem.icon2Class" translate-attr="{'aria-label': elem.label}">
        </i>
      </div>

    </div>

  </div>
</div>