import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit {

  loadingData: any;
  errorData: any;

  appConfig: any;
  appInfo: any;

  breadcrumb: any;

  public langs: any;
  public convConfig: any;
  public unitSystems: any = [
    {
      current: true,
      label: 'International System',
      id: "SI"
    }
  ];
  styles: string[];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR
  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public translate: FfTranslateService,
    private clonerService: ClonerService,
    private cacheService: CacheService,
  ) {
    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.convConfig = this.appConfigService.getConvConfig;

    try { this.unitSystems = this.unitSystems.concat(this.convConfig.unitSystems) }
    catch (error) { }

    this.breadcrumb = ['OPTIONS.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.langs = this.appConfig.langs;

    if (localStorage.getItem("USID") != null) this.updateCurrentUnitSystem();

    if (!this.translate.currentLang || this.langs.findIndex((x: any) => this.translate.currentLang == x.id) == -1) {
      this.translate.use(this.translate.defaultLang);
      localStorage.setItem('language', this.translate.defaultLang);
    }
    this.updateCurrentLanguage();
    // this.styles = [
    //   'accurite',
    //   'aminstruments',
    //   'aqseptence',
    //   'axulus',
    //   'barilla',
    //   'cemas',
    //   'comerio',
    //   'comerio-ercole',
    //   'comi',
    //   'crm',
    //   'delmet',
    //   'eta',
    //   'ficep',
    //   'foodFarm',
    //   'greiner',
    //   'ims',
    //   'imv',
    //   'ipackIma',
    //   'johnsonscreens',
    //   'macchi',
    //   'mangiarotti',
    //   'mektech',
    //   'mep',
    //   'mfl',
    //   'mondini',
    //   // 'norblast',
    //   'novastilmec',
    //   'ocme',
    //   'omet',
    //   'pedrollo',
    //   'peroni',
    //   'persico',
    //   'piovan',
    //   'sala',
    //   // 'sire',
    //   'sps-lego',
    //   'stilmas',
    //   'zani'
    // ]

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER
  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 2;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 2 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // LANGUAGES
  updateCurrentLanguage() {
    for (let lang of this.langs) {
      lang.current = false;
      if (lang.id == localStorage.getItem('language')) lang.current = true;
    }
  }

  useLanguage(language: string) {
    localStorage.setItem('language', language);
    this.internalDataService.setLang(language);
    this.translate.use(language);
    try {
      moment.locale(language);
    } catch (error) {
      console.log(error);
    }
    this.updateCurrentLanguage();
  }

  updateCurrentUnitSystem() {
    for (let unitSystem of this.unitSystems) {
      unitSystem.current = false;
      if (unitSystem.id == localStorage.getItem('USID')) unitSystem.current = true;
    }
  }


  useUnitSystem(unitSystem: string) {
    localStorage.setItem('USID', unitSystem);
    this.updateCurrentUnitSystem();
  }

  changeCSS(cssId) {
    document.getElementById("customCSSColor").setAttribute("href", `${cssId}`);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit(): void {
    this.dispatcherService.getDispatch(this, 300);

    // this.internalDataService.setBackButton([], true);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (err) { }
    // try { this.internalDataService.setBackButton([]) } catch (error) { }
  }

}
