import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'app-gca-input-dialog',
  templateUrl: './gca-input-dialog.component.html',
  styleUrls: ['./gca-input-dialog.component.scss']
})
export class GcaInputDialogComponent implements OnInit {

  public list: any = [];
  public tableInfos: any = [];
  public appConfig: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<GcaInputDialogComponent>,
    public appConfigService: AppConfigService,
    public clonerService: ClonerService,
    public internalDataService: InternalDataService,
    public translate: FfTranslateService,
  ) {
    this.appConfig = this.appConfigService.getAppConfig;

    try {
      this.tableInfos = this.appConfig.cycleTraceability.cycles.cycleInfo.filter((x: any) => x.gcaSelectionDialog);
    } catch (error) { console.log(error) }

  }

  ngOnInit(): void {
    let list: any = [];
    if (this.dialog != null && this.dialog.list != null && this.dialog.list.length > 0) {
      list = this.clonerService.deepClone(this.dialog.list);
    }
    this.list = this.clonerService.deepClone(list);

  }

  closeDialog() {
    this.dialogRef.close(this.dialog.list);
  }

}