<div mat-dialog-title fxLayout="row" class="w100">
    <span>{{ dialog.title }}</span>
    <span fxFlex></span>
    <button mat-dialog-close class="close">
        <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
    </button>
</div>

<mat-dialog-content class="mat-typography">
    <div fxLayout="column" fxLayoutAlign="start center">

        <!-- intervals -->
        <ff-interval-selector class="bd" [config]="intervalConfig" (selected)="selectInterval($event)">
        </ff-interval-selector>

        <div *ngIf="state == 2" fxLayout="row" fxLayoutAlign="center center" class="w100 h100">
            <div class="not-found" fxLayout="row" fxLayoutAlign="center center">
                <span>{{ 'GLOBAL.PDF_NOT_AVAILABLE' | translate }}</span>
            </div>
        </div>

        <div *ngIf="state == 0" class="w100 h100">
            <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
                <ff-loading-card [loadingData]="loadingData"></ff-loading-card>
            </div>
        </div>

        <div *ngIf="state == 1" class="w100 h100">
            <ff-dashboard *ngIf="completeDashboardConfig != null" [completeDashboardConfig]="completeDashboardConfig"
                (clicked)="tableAction($event)">
            </ff-dashboard>
        </div>

    </div>
</mat-dialog-content>