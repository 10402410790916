<div *ngIf="aggregationButtons?.length > 0" fxLayout="column" fxLayout.gt-sm="row" fxLayoutGap="8px">
  <button *ngFor="let aggr of aggregationButtons" class="filter-button" (click)="openAggrDialog(aggr)"
    [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="center center">

    <!-- icon -->
    <ff-icon [icon]="aggr.icon"></ff-icon>

    <!-- Selection Label -->
    <span>{{ aggr.selectionLabel | translate }}</span>

    <!-- Selected -->
    <div fxLayout="row" fxLayoutAlign="center center" *ngIf="checkButtons(aggr)" class="active-filters-circle">
      {{ filteredItems(aggr) }}
    </div>

  </button>
</div>