import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { IntervalService } from 'src/app/services/interval.service';

@Component({
  selector: 'app-multiple-input-dialog',
  templateUrl: './multiple-input-dialog.component.html',
  styleUrls: ['./multiple-input-dialog.component.scss']
})
export class MultipleInputDialogComponent implements OnInit, OnDestroy {

  public state: any = 0;
  public nowTime: any;
  public errors: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<MultipleInputDialogComponent>,
    public http: ApiService,
    public intervalService: IntervalService,
    public clonerService: ClonerService,
    public translate: FfTranslateService,
    public filterService: FiltersService,
  ) { }

  parseDatetimeMaxAndMin() { this.nowTime = new Date().toISOString().substring(0, 16) }

  checkDate(newDate, item, info) {

    if (info?.timeCheck) {

      let condition = true;
      let label: any = null;

      if (info?.diffTimeCheck) {

        if (info?.diffTimeCheck?.type == 'greater') condition = moment(item?.[info?.variable]).diff(moment(item?.[info?.timeCheck?.variable])) <= info?.diffTimeCheck?.diffValue && moment(item?.[info?.variable]).diff(moment(item?.[info?.timeCheck?.variable])) > 0;
        else if (info?.diffTimeCheck?.type == 'smaller') condition = moment(item?.[info?.variable]).diff(moment(item?.[info?.timeCheck?.variable])) >= info?.diffTimeCheck?.diffValue && moment(item?.[info?.variable]).diff(moment(item?.[info?.timeCheck?.variable])) < 0;

        let id = info?.diffTimeCheck?.id ?? info?.variable;

        let label = this.translate.instant("GLOBAL.DIFF_TIME_CHECK", {
          hours: this.filterService.parseGaugeValue(info?.diffTimeCheck?.diffValue, 0, 1 / (3600 * 1000))
        });

        if (!condition) {

          if (info?.diffTimeCheck?.type == 'greater') item[info?.timeCheck?.variable] = moment(item?.[info?.variable]).subtract(info?.diffTimeCheck?.diffValue, "ms").format("YYYY-MM-DDTHH:mm");
          else item[info?.timeCheck?.variable] = moment(item?.[info?.variable]).add(info?.diffTimeCheck?.diffValue, "ms").format("YYYY-MM-DDTHH:mm");

          this.checkDate(item[info?.timeCheck?.variable], item, this.dialog?.tableInfos?.find(x => x?.variable == info?.timeCheck?.variable));

          // if (this.errors?.findIndex(x => x.id == id) == -1) {
          //   this.errors.push({
          //     id: id,
          //     label: label
          //   });
          // }
        } else {
          try {
            if (this.errors.findIndex(x => x.id == id) != -1) this.errors.splice(this.errors.findIndex(x => x.id == id), 1);
          }
          catch (error) { console.log(error) }
        }

      }

      switch (info?.timeCheck?.operator) {
        case 'lt':
          condition = moment(item?.[info?.timeCheck?.variable]).diff(moment(newDate)) >= 0;
          label = this.translate.instant("GLOBAL.TIME_AFTER_WARNING", {
            elem1: this.translate.instant(info?.label ?? '-'),
            elem2: this.translate.instant(this?.dialog?.tableInfos?.find(x => x.variable == info?.timeCheck?.variable)?.label ?? '-')?.toLowerCase(),
          });
          break;
        case 'gt':
          condition = moment(item?.[info?.timeCheck?.variable]).diff(moment(newDate)) <= 0;
          label = this.translate.instant("GLOBAL.TIME_BEFORE_WARNING", {
            elem1: this.translate.instant(info?.label ?? '-'),
            elem2: this.translate.instant(this?.dialog?.tableInfos?.find(x => x.variable == info?.timeCheck?.variable)?.label ?? '-')?.toLowerCase(),
          });
          break;
      }

      if (!condition) {
        if (this.errors?.findIndex(x => x.id == info?.variable) == -1) {
          this.errors.push({
            id: info?.variable,
            label: label
          });
        }
      } else {
        try {
          if (this.errors.findIndex(x => x.id == info?.variable) != -1) this.errors.splice(this.errors.findIndex(x => x.id == info?.variable), 1);
        }
        catch (error) { console.log(error) }
      }

    }

    if (!info.avoidCheckFuture) {
      try {
        if (moment().diff(moment(newDate)) < 0) {
          if (this.errors?.findIndex(x => x.id == 'dateInFuture' && x?.variable == info?.variable) == -1) {
            this.errors.push({
              id: 'dateInFuture',
              variable: info?.variable,
              label: this.translate.instant("GLOBAL.FUTURE_WARNING", {
                x: this.translate.instant(info?.label ?? '-'),
              })
            });
          }
        } else {
          if (this.errors.findIndex(x => x.id == 'dateInFuture' && x?.variable == info?.variable) != -1) this.errors.splice(this.errors.findIndex(x => x.id == 'dateInFuture' && x?.variable == info?.variable), 1);
        }
      } catch (error) { console.log(error) }
    }

    if (info?.intervalBetweenMinDateAndMaxDate) {

      if (this.dialog.minDate != null) {
        if (moment(this.dialog.minDate).diff(moment(newDate)) > 0) {

          if (this.errors?.findIndex(x => x.id == 'lowerConstraint' && x?.variable == info?.variable) == -1) {
            this.errors.push({
              id: 'lowerConstraint',
              variable: info?.variable,
              label: this.translate.instant("GLOBAL.MIN_DATE_WARNING", {
                elem1: this.translate.instant(info?.label ?? '-'),
                elem2: this.filterService.parseMoment(this.dialog.minDate, "default"),
              })
            });
          }

        } else {
          if (this.errors.findIndex(x => x.id == 'lowerConstraint' && x?.variable == info?.variable) != -1) this.errors.splice(this.errors.findIndex(x => x.id == 'lowerConstraint' && x?.variable == info?.variable), 1);
        }

      }

      if (this.dialog.maxDate != null) {
        if (moment(this.dialog.maxDate).diff(moment(newDate)) < 0) {

          if (this.errors?.findIndex(x => x.id == 'upperConstraint' && x?.variable == info?.variable) == -1) {
            this.errors.push({
              id: 'upperConstraint',
              variable: info?.variable,
              label: this.translate.instant("GLOBAL.MAX_DATE_WARNING", {
                elem1: this.translate.instant(info?.label ?? '-'),
                elem2: this.filterService.parseMoment(this.dialog.maxDate, "default"),
              })
            });
          }

        } else {
          if (this.errors.findIndex(x => x.id == 'upperConstraint' && x?.variable == info?.variable) != -1) this.errors.splice(this.errors.findIndex(x => x.id == 'upperConstraint' && x?.variable == info?.variable), 1);
        }

      }

    }

    // Check both the current variable and the linked variable
    if (info?.diffTimeCheck == null) {
      try { this.checkDate(item[info?.timeCheck?.variable], item, this.dialog?.tableInfos?.find(x => x?.variable == info?.timeCheck?.variable)) } catch (error) { }
    }

  }

  ngOnInit() {

    this.parseDatetimeMaxAndMin();

    this.state = 0;

    try {
      let multipleCustomInputs = this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'customMultipleInput');

      multipleCustomInputs?.forEach(customMultipleInputConfig => {

        let nestedKeyLabel = customMultipleInputConfig?.multipleInputs?.nestedKey ?? ('multiple' + customMultipleInputConfig?.variable?.capitalize()) ?? 'multiple';

        this.getClosedListValuesFromBE(customMultipleInputConfig?.multipleInputs?.subInputs, nestedKeyLabel);

        customMultipleInputConfig.multipleInputs.subItemList = [
          customMultipleInputConfig?.multipleInputs?.subInputs?.reduce((acc, val) => {
            acc[val.variable] = this.dialog?.row?.[val.variable];
            return acc;
          }, {})
        ];

        this.dialog.row[nestedKeyLabel] = this.clonerService.deepClone(customMultipleInputConfig.multipleInputs.subItemList);
      });

    } catch (error) { console.log(error) }

    try {
      let closedLists = this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'closedList' && !x.listFromBE);
      closedLists = closedLists?.reduce((acc, info) => {
        if (info?.type != 'configFromProfile' || this.dialog.profile == null) {
          acc.push(info);
          return acc;
        }

        let list = this.clonerService.deepClone(this.dialog.profile?.[info?.mappingConfig?.key ?? 'timeStates']);

        this.parseObjectTypeClosedList(info, list);
        acc.push(info);

        return acc;
      }, []);
      closedLists?.filter(x => x?.listOptions?.length == 1).forEach(list => this.dialog.row[list.variable] = list.listOptions[0]);
    } catch (error) { console.log(error) }

    try {
      let inputDateTimes = this.dialog?.tableInfos?.filter(x => x?.editableInDialog && x?.dialogEditType == 'inputDateTime' && this.dialog.row[x.variable] != null);
      inputDateTimes?.forEach(val => this.dialog.row[val.variable] = this.intervalService.unformatStringDate(this.dialog.row[val.variable], "Europe/Rome"));
    } catch (error) { console.log(error) }

    try {
      let defaultValues = this.dialog?.tableInfos?.filter(x => x?.editableInDialog && x?.defaultValue != null && this.dialog.row[x.variable] == null);
      defaultValues?.forEach(val => this.dialog.row[val.variable] = val?.defaultValue);
    } catch (error) { console.log(error) }

    this.getClosedListValuesFromBE(this?.dialog?.tableInfos);

  }

  getClosedListValuesFromBE(tableInfos: any, nestedKeyLabel: any = null) {

    try {

      let closedLists = tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'closedList' && x?.listFromBE);

      if (closedLists?.length > 0) {

        let actIndex = 0;
        closedLists?.forEach((info, index) => {

          (async () => {
            info.endpointUrl = info?.endpointUrl?.replaceAll("{machineId}", this.dialog.machineId);

            let endpointUrl = info.endpointUrl;

            let query: any = {
              tz: this.dialog?.machine?.timezone
            };

            let payload: any = {}

            if (info?.filterBE != null) {
              let obj: any = {
                id: info?.filterBE,
                values: [this.dialog?.row?.[info?.filterBE]]
              };
              payload.filter = obj;
            }

            try {

              let method = 'POST';
              if (endpointUrl.includes(':')) {
                endpointUrl = endpointUrl.split(':');
                method = endpointUrl[0];
                endpointUrl = endpointUrl[1];
              }

              let requestSent = this.http.sendPostRequest(endpointUrl, payload, query);
              if (method == 'PUT') requestSent = this.http.sendPutRequest(endpointUrl, payload, query);
              else if (method == 'DELETE') requestSent = this.http.sendDeleteRequest(endpointUrl, {});

              const data: any = await requestSent.toPromise();

              //------------------------------------------------------------------------//
              // RESPONSE PARSING

              let copyData: any = this.clonerService.deepClone(data?.body ?? []);

              actIndex++;

              this.parseObjectTypeClosedList(info, copyData, nestedKeyLabel);

              if (actIndex == closedLists.length) this.state = 1;

            } catch (error) {

              actIndex++;
              info.listOptions = [];
              if (actIndex == closedLists.length) this.state = 1;

            }

          })();

        })
      } else {
        this.state = 1;
      }
    } catch (error) { console.log(error) }
  }

  changedClosedListElem(event, item, info) {
    if (info?.dialogEditType == 'closedList' && info?.listFromBE && info?.nestedKeysToKeep?.length > 0) {
      let ix = info?.listOptionsDict?.findIndex(x => x?.[info?.variable] == item?.[info?.variable]);
      try {
        if (ix != -1) Object.entries(info?.listOptionsDict[ix])?.forEach((kv: any) => item[kv[0]] = kv[1]);
      } catch (error) { console.log(error) }
      // console.log(item, this.dialog.row);
    }

  }

  parseObjectTypeClosedList(info: any, data: any, nestedKeyLabel: any = null) {

    info.listOptions = data?.filter(x => {

      // If element is not object, don't filter
      if (typeof x != 'object') return true;

      // If object doesn't have key "filterForSpecificVariableAndValue", don't filter
      if (info?.filterForSpecificVariableAndValue == null) return true;

      // Return only elements that have value associated to the specified "variable" equal to the specified "value"
      return x?.[info?.filterForSpecificVariableAndValue?.variable] == info?.filterForSpecificVariableAndValue?.value;
    })
      // Map on nestedKey if present
      .map(x => info?.nestedKeyToMap ? this.translate.instant(x?.[info?.nestedKeyToMap] ?? '-') : x) ?? [];

    if (info?.nestedKeysToKeep?.length > 0) {
      info.listOptionsDict = data?.reduce((acc, val) => {

        if (typeof val == 'object') {
          let obj: any = {}

          info?.nestedKeysToKeep?.forEach(k => {
            if (typeof k == 'object') {
              // If "primaryKey", substitute the id in the incoming object with the variable from info
              if (k?.primaryKey) obj[info.variable] = this.translate.instant(val[k.id] ?? '-');
              // Else map the newId (if present) with the correct value
              else obj[k?.newId ?? k?.id] = val[k.id];
            }
            else obj[k] = val[k];
          });
          acc.push(obj);
        }

        return acc;
      }, []);
      console.log(info.listOptionsDict);
      if (nestedKeyLabel != null) this.changedClosedListElem(null, this.dialog.row?.[nestedKeyLabel]?.[0], info);
    }
  }

  checkShowCondition(info, row) {

    if (info?.showCondition == null) return true;

    let condition = info.showCondition;

    let boolCond = true;

    try {

      if (condition?.every(cond => Array.isArray(cond) && cond?.length == 3)) boolCond = condition.some(c => this.filterService.parseDisabilityCondition(row, c));
      else if (condition?.length == 3) boolCond = this.filterService.parseDisabilityCondition(row, condition);

    } catch (error) { console.log(error) }

    // If the condition(s) is(are) not satisfied, delete the value associated with the variable
    if (!boolCond) row[info.variable] = null;

    return boolCond;

  }

  onCheckboxChange(item, info, checkBoxConfig) {
    item[info.variable] = checkBoxConfig?.checked;
  }

  ngOnDestroy() { }

  checkDisability() {
    let tableInfosCheck = !this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x.requiredInDialog).filter(x => this.checkShowCondition(x, this.dialog?.row)).every(x => {

      let val = this?.dialog?.row?.[x?.variable];

      let isOkLower = true;
      let isOkUpper = true;
      let isValidValue = true;
      let isNotNull = true;
      let isValidRegex = true;

      if (x?.requiredInDialog) isNotNull = val != null && val !== "";
      // if (x?.min != null && !isNotNull) isOkLower = val >= x?.min;
      // if (x?.max != null && !isNotNull) isOkUpper = val <= x?.max;
      if (x?.min != null) isOkLower = val >= x?.min;
      if (x?.max != null) isOkUpper = val <= x?.max;
      // if (x?.blockedValues?.length > 0) isValidValue = !this.checkBlockedList(val, x?.blockedValues);
      // if (x?.regex != null && val != null && val !== '') isValidRegex = this.getRegex(x.regex, val);

      // console.log({ isNotNull });
      // console.log({ isValidRegex });
      // console.log({ isOkLower });
      // console.log({ isOkUpper });
      // console.log({ isValidValue });

      return isNotNull && isValidRegex && isOkLower && isOkUpper && isValidValue;
    });

    return tableInfosCheck;
  }

  closeDialog() {

    let inputDateTime = this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'inputDateTime');

    inputDateTime?.forEach(x => {
      this.dialog.row[x.variable] = moment(this.dialog?.row?.[x.variable]).format("YYYY-MM-DDTHH:mm") + ':00.000';

      try { this.dialog.row[x.variable] = this.intervalService.formatStringDate(this.dialog?.row?.[x.variable], this.dialog?.machineId) }
      catch (error) { console.log(error) }
    });

    let variablesToMultiply = this?.dialog?.tableInfos?.filter((x: any) => x?.editableInDialog && x?.dialogEditType == 'inputNum' && x?.multiplier != null);

    variablesToMultiply?.forEach(x => {
      try { this.dialog.row[x.variable] = this.dialog?.row?.[x.variable] != null ? this.dialog?.row?.[x.variable] * x.multiplier : null }
      catch (error) { console.log(error) }
    })

    // console.log(this.dialog?.row);

    this.dialogRef.close(this.dialog?.row);
  }

}
