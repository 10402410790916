<mat-toolbar fxLayoutGap class="ff-toolbar" *ngIf="!hide">

  <!-- toggle sidenav button -->
  <button [fxHide]="!isSmThanTablet" mat-icon-button class="sidenav-open" (click)="openSidenav()" aria-label="menu">
    <i class="fas fa-bars fa-lg icon"></i>
  </button>

  <!-- back button -->
  <button *ngIf="backButton.length > 0 || navigateInBrowserHistory" mat-icon-button class="back-button sidenav-open"
    (click)="navigateBack()" aria-label="menu">
    <i class="fas fa-arrow-left fa-lg icon"></i>
  </button>


  <div class="border"></div>
  <div class="breadcrumb" fxLayout="row" fxLayoutAlign="start center">
    <div *ngFor="let voice of breadcrumb; let i = index" [attr.data-index]="i" class="voice">
      <span *ngIf="!isMobile">{{voice}}</span>
      <span *ngIf="isMobile && i >= (breadcrumb.length - 2)">{{voice | shorten: 20}}</span>
      <span *ngIf="isMobile && i >= 0 && i < (breadcrumb.length - 2)" #tooltip="matTooltip" matTooltip="{{voice}}"
        (click)="tooltip.toggle()">&hellip;</span>
    </div>
  </div>

  <span fxFlex></span>

  <div *ngIf="isCalendarPage && enableCalendarSwitch" fxLayout="row" fxLayoutAlign="center center"
    class="switch-container small">
    <span class="calendar">
      {{ (isEnabledCalendarAsset ? "CALENDAR.DISABLE_CALENDAR_PER_ASSET" : "CALENDAR.ENABLE_CALENDAR_PER_ASSET") |
      translate
      }}
    </span>
    <ui-switch [disabled]='!machineSelected' [checked]="isEnabledCalendarAsset" fxLayout="row"
      fxLayoutAlign="center center" (change)="toggleEnableCalendarAsset($event)" checkedLabel="ON" uncheckedLabel="OFF"
      size="small">
    </ui-switch>
  </div>

  <div fxHide="true" fxShow.gt-sm="true" *ngIf="showToolbar" class="ff-input-select lang-toolbar"
    style="width: 0; font-size: 1rem;">
    <mat-select [(value)]="currentLanguage" (selectionChange)="useLanguage(currentLanguage.id)">
      <mat-select-trigger fxLayout="row" fxLayoutAlign="start center">
        <img class="toolbar flag" [src]="'./statics/flags/' + currentLanguage.flag">
        <span style="margin-left: 8px;">{{ currentLanguage.name | translate }}</span>
      </mat-select-trigger>
      <mat-option *ngFor="let lang of langs" [value]="lang" fxLayout="row" fxLayoutAlign="start center"
        class="lang-toolbar">
        <img class="toolbar flag" [src]="'./statics/flags/' + lang.flag" alt="{{lang.id}}">
        <span style="margin-left: 8px;">{{ lang.name | translate }}</span>
      </mat-option>
    </mat-select>
  </div>

  <div fxHide="true" fxShow.gt-sm="true" *ngIf="showUnitToolbar" class="ff-input-select lang-toolbar"
    style="width: 200px; font-size: 1rem;">
    <mat-select [(value)]="unitSystem" (selectionChange)="useSystem(unitSystem.id)">
      <mat-select-trigger fxLayout="row" fxLayoutAlign="start center">
        <span style="margin-left: 8px;">{{ unitSystem.label | translate }}</span>
      </mat-select-trigger>
      <mat-option *ngFor="let unitSystem of unitSystems" [value]="unitSystem" fxLayout="row"
        fxLayoutAlign="start center" class="lang-toolbar">
        <span style="margin-left: 8px;">{{ unitSystem.label | translate }}</span>
      </mat-option>
    </mat-select>
  </div>

  <ng-container *ngIf="checkUserToolbarPermission()">

    <button *ngIf="!isMindSphere && !isAzure && !isDemo && cacheService.get('user')?.oauth" mat-icon-button
      class="logout-button" (click)="fusionauthAdminRedirect()" aria-label="menu" fxLayout="row"
      fxLayoutAlign="center center" style="margin: 0px -4px 0px 12px;"
      matTooltip="{{ 'GLOBAL.NAVIGATE_TO_FUSIONAUTH' | translate }}">
      <i class="fas fa-user-lock fa-lg icon"></i>
    </button>

    <button *ngIf="!isMindSphere && !isDemo && cacheService.get('user')?.oauth" mat-icon-button class="logout-button"
      (click)="logout()" aria-label="menu" fxLayout="row" fxLayoutAlign="center center"
      matTooltip="{{ 'GLOBAL.LOGOUT' | translate }}">
      <i class="fas fa-sign-out fa-lg icon"></i>
    </button>

  </ng-container>

  <!-- <button mat-button class="help-button" fxLayout="row" fxLayoutAlign="space-evenly center"
      (click)="openHelpDialog($event)">
      <mat-icon svgIcon="question_mark" class="icon"></mat-icon>
      <span>{{ 'GLOBAL.HELP' | translate }}</span>
    </button> -->

  <button *ngIf="!isMindSphere" mat-icon-button [matMenuTriggerFor]="mainSettingsMenu" aria-label="settings" class="logout-button">
    <i class="fas fa-cog fa-lg"></i>
  </button>
  <mat-menu #mainSettingsMenu="matMenu" xPosition="after" yPosition="below">
    <button mat-menu-item fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="icon">info</mat-icon>
      <span>{{ 'GLOBAL.VERSION' | translate }} 1.17.0</span>
    </button>
    <button *ngIf="cacheService.get('user')?.user != null" mat-menu-item fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="icon">account_circle</mat-icon>
      <!-- <span>{{ 'GLOBAL.USER' | translate }}: {{ cacheService.get("user")?.user }}</span> -->
      <span>{{ cacheService.get("user")?.user }}</span>
    </button>
  </mat-menu>

</mat-toolbar>