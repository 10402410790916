import { Component, Input, OnInit } from '@angular/core';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'ff-mailing-list',
  templateUrl: './ff-mailing-list.component.html',
  styleUrls: ['./ff-mailing-list.component.scss']
})
export class FfMailingListComponent implements OnInit {

  @Input() widget: any;

  unparsedMailingList: any = []
  mailingList: any = []

  mailingListDescription: any = "RBA.MAILING_LIST_DESCRIPTION";
  noMailConfiguredText: any = "RBA.NO_MAILS_CONFIGURED";
  mailingListTitle: any = "RBA.MAILING_LIST";

  isAllowedUser: any = true;
  isAllowedUserWrite: any = true;

  public mailRegex: any = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  constructor(
    public clonerService: ClonerService,
    public filterService: FiltersService,
    public internalDataService: InternalDataService,
    public translate: FfTranslateService,
  ) { }

  ngOnInit(): void {

    console.log(this.widget);

    if (this.widget?.config != null) this.mailingList = this.clonerService.deepClone(this.widget.data?.[this.widget?.config?.dataAttribute ?? 'mailingList'] ?? []);

    if (this.widget?.config?.mailingListDescription) this.mailingListDescription = this.widget.config.mailingListDescription;
    if (this.widget?.config?.noMailConfiguredText) this.noMailConfiguredText = this.widget.config.noMailConfiguredText;
    if (this.widget?.config?.mailingListTitle) this.mailingListTitle = this.widget.config.mailingListTitle;

    this.initMailingList();

    // if (this.widget?.config?.fixedMails?.length > 0) {
    //   this.mailingList = this.widget?.config?.fixedMails?.map((x, i) => {
    //     return {
    //       value: x,
    //       index: this.unparsedMailingList?.length + i,
    //       immutable: true,
    //     }
    //   }).concat(this.mailingList);
    // }

  }

  // INIT MAILING LIST
  initMailingList(mailingList?) {

    this.unparsedMailingList = this.clonerService.deepClone(mailingList ?? this.mailingList);

    this.mailingList = (this.clonerService.deepClone(mailingList ?? this.mailingList) as any)?.reduce((acc, val, index) => {
      acc.push({
        value: val,
        id: index
      })
      return acc;
    }, []);
  }

  // ADD MAIL
  addMail() {
    this.mailingList.push({
      id: this.mailingList.length,
      value: ""
    });
  }

  // DELETE MAIL
  deleteMail(index: any) {
    this.mailingList.splice(index, 1);
  };

  checkUpdates(checkExit?) {

    try {
      // Check Mailing List
      let isChangedMail = null;
      let isInvalidMail = !this.mailingList.every((mail: any) => mail.value != null && mail.value != '' && RegExp(this.mailRegex, 'g').exec(mail.value));
      if (!isInvalidMail || checkExit) {
        if (this.unparsedMailingList.length == this.mailingList.length) {
          isChangedMail = !this.mailingList.every((mail: any, mailIdx: any) => mail.value == this.unparsedMailingList[mailIdx]);
        }
        else {
          isChangedMail = true;
        }
      } else {
        isChangedMail = false;
      }

      return isChangedMail == false;
    } catch (error) {
      return true;
    }
  }

  async sendUpdatedMailingList() {

    let endpointUrl = this.widget?.config?.endpointUrl ?? '/apif/ingenya/mailing-list/{machineId}';

    let request = null;
    let requestSent = null;
    let responseCode = 200;

    let payload = {
      mailingList: this.mailingList?.map(x => x.value)
    };

    try {
      requestSent = this.filterService.getRequestFromEndpointUrl(endpointUrl, this.widget?.referenceComponent?.machineId, null, payload);
      request = await requestSent.toPromise();
      responseCode = request.status;
      this.initMailingList(request?.body?.[this.widget?.config?.dataAttribute ?? 'mailingList']);
    }
    catch (error) {
      responseCode = error.status;
      this.initMailingList();
    }

    this.internalDataService.openSnackBar(
      this.translate.instant('MODEL_ANALYSIS.MAILING_LIST_RESPONSE.' + (responseCode == 200 ? 'SUCCESS' : 'FAIL')),
      'right',
      'bottom',
      4000,
      '',
      [responseCode == 200 ? 'success' : 'fail']
    );

  }

}
