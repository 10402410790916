import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { MultipleInputDialogComponent } from 'src/app/components/multiple-input-dialog/multiple-input-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';


@Component({
  selector: 'time-machine',
  templateUrl: './time-machine.component.html',
  styleUrls: ['./time-machine.component.scss']
})
export class TimeMachineComponent implements OnInit {

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // VARIABLES

  public isAllowedUser: boolean = true;
  public timeMachineMode: any = false;

  playerState: any = 0;
  pollingSliderTime: any = 1000;
  speed: any = 1;
  pollingSlider: any

  endUnparsed: any;
  startUnparsed: any;
  end: any;
  currentTimeUnparsed: any;
  currentTime: any;

  slider: any;

  streamStart: any;
  streamEnd: any;

  public loadingData: any;
  public errorData: any;

  public mobileListener: Subscription;
  public isMobile: boolean;
  public isSmThanTablet: boolean;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public currentSynopticId: any;
  public synopticConfig: any;
  public synopticConfigDefault: any;

  public monitoringData: any;

  public pollingTime: any;
  public pollingMonitoringData: any;

  public reelId: any;
  public reelLabel: any;

  public datapointSub: Subscription;
  public dialogData: any;
  public dashboardConfig: any;
  public showDialog: boolean = false;
  public hideSynopticSelectionButton: any = false;

  public noSynoptic: any = false;

  public repeatedCards: any = false

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 8;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getSynopticConfig, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getMonitoringData, nextState: 7, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 8 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  sectionName: any = "timeMachine";
  tabName: any = "timeMachine";
  start: string;
  timeMachineData: any;
  noTimeMachineData: any = false;
  isChangingSlider: boolean = false;
  numberOfTimesSliderValueChangedBetweenACountAndTheNext: any = 0;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public intervalService: IntervalService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public clonerService: ClonerService,
    public cacheService: CacheService,
    public mobile: MobileService
  ) {

    this.mobile.mobileListener.subscribe((value: any) => {
      this.isSmThanTablet = value.isSmThanTablet
      this.isMobile = value.isMobile
    });

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['LGV_MONITORING.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));
    this.pageState.subscribe();
    this.pollingTime = this.appConfig?.[this.sectionName]?.[this.tabName]?.pollingTime ?? 10000;

    this.pollingSlider = Subscription;

    this.currentSynopticId = 'mainPlant';

    // datapoint subscription
    this.datapointSub = this.internalDataService.onDatapointUpdate().subscribe(dp => {

      let title = (dp.dialogTitle != null && dp.dialogTitle != '') ? this.translate.instant(dp.dialogTitle) : null;
      if (title == null) {
        title = dp.title;
      }
      if (title == null) {
        title = (dp.label != null && dp.label != '') ? this.translate.instant(dp.label) : 'Undefined';
      }

      this.showDialog = true;
      this.dialogData = {
        showDialog: true,
        title: title,
        unit: this.translate.instant((dp.unit != null && dp.unit != '') ? dp.unit : '-'),
        defaultUnit: dp.defaultUnit,
        yAxisRange: dp.yAxisRange,
        datapointList: dp.datapointList != null ? dp.datapointList : [dp.datapoint],
        machineId: this.machineId,
        timezone: this.machine?.timezone,
        completeDatapoint: this.clonerService.deepClone(dp)
      };

    });

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-ms-telemetry");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        if (_this.dashboardConfig != null && Array.isArray(_this.dashboardConfig) && _this.dashboardConfig.length > 0) _this.noSynoptic = true;
        else if (_this.dashboardConfig?.type == 'repeated-card') _this.repeatedCards = true;
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, 'lgv-monitoring');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SYNOPTIC CONFIG

  public getSynopticConfig(_this: any) {
    try {

      let url = '/apif/get-custom-file';
      let fileName = 'lgvConfig.json';

      let profileId = null;
      try { profileId = _this.machineProfiles.profiles.find(x => x.machineIdList.includes(_this.machineId)).id } catch (error) { }

      let query: any = {
        fromWeb: _this.appInfo?.sources40F != null ? 1 : 0,
        source: _this.appInfo?.sources40F ?? '/assets/config/',
        machineId: _this.machineId,
        fileName: fileName,
      };

      if (profileId != null) query.profileId = profileId;

      _this.apiService.sendGetRequest(url, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {
          error = {
            ...error, ...{
              error: {
                "customMessage": "Missing file: default-" + fileName,
                "customCode": "404",
              }
            }
          }
          return _this.internalDataService.parseStandardHTTPError(_this, error);
        }))
        .subscribe(
          (data: any) => {

            // console.log(data.body);
            _this.synopticConfigDefault = data.body;
            if (Object.keys(_this.synopticConfigDefault).length <= 1) _this.hideSynopticSelectionButton = true;

            if (_this.noSynoptic) _this.dispatcherService.getDispatch(_this, 302);
            else _this.dispatcherService.getDispatch(_this, 300);

          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }

  }

  parseSynopticConfig(data: any, currentSynoptic: any) {

    let config = this.clonerService.deepClone(this.synopticConfigDefault ?? {});

    if (config == null || Object.keys(config).length == 0) {
      return {};
    }

    let c_syn = currentSynoptic != null ? currentSynoptic : 'mainPlant';

    // console.log(config, data);

    if (config[c_syn] != null) {

      // Synoptic image

      let sources40F = this.appInfo.sources40F != null ? this.appInfo.sources40F : 'assets/images/';
      config[c_syn].image = sources40F + config[c_syn].background;

      // Check if synoptic datapoints exist
      if (config[c_syn].hasOwnProperty('datapoints') && config[c_syn].datapoints != null && config[c_syn].datapoints.length > 0) {

        // Loop on synoptic datapoints
        config[c_syn].datapoints = config[c_syn].datapoints.reduce((acc, dp) => {

          let imageData: any = this.filterService.removeKeysWithCustomRule(data, (x) => x[0].startsWith(dp?.datapoint));

          imageData = (Object.entries(imageData) as any)?.reduce((acc, [key, val]) => {
            acc[key.split(dp?.datapoint + ".")?.[1]] = val;
            return acc;
          }, {});

          dp = {
            ...dp,
            ...imageData,
            ...{
              tooltip: dp?.tooltipInfos?.reduce((acc, val) => {
                let str = this.translate.instant(val?.label ?? '-') + ": " + data[val?.variable] + (val?.unit ? ' ' + val?.unit : '') + "\n";
                acc += str;
                return acc;
              }, "")
            }
          };

          if (dp?.state != null) {
            let timeStateConfig = this.machine.profile?.timeStates?.find(x => x.state == dp?.state);
            dp.color = timeStateConfig?.color;
          }
          acc.push(dp);
          return acc;
        }, []);

      }

    }

    return config[c_syn];
  }

  getMonitoringData(_this: any) {
    try {

      let query: any = {
        tz: _this.machine.timezone,
      };

      if (_this.machine.clothNumber != null) query.clothNumber = _this.machine.clothNumber;

      let url = '/apif/machine-monitoring/remote-monitoring/' + _this.machineId;

      let aspects: any = _this.clonerService.deepClone(_this.machine.profile?.timeMachineAspects ?? [])?.reduce((acc, val) => {
        if (val?.numerator != null && typeof val?.numerator == 'string') acc.push(val?.numerator);
        if (val?.denominator != null && typeof val?.denominator == 'string') acc.push(val?.denominator);
        return acc;
      }, []);

      let payload = aspects;

      _this.pollingMonitoringData = timer(0, _this.pollingTime).subscribe((count => {
        _this.apiService.sendPostRequest(url, payload, query).pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, count == 0 ? _this.pollingMonitoringData : null)))
          .subscribe(
            (data: any) => {

              _this.monitoringData = _this.parseMonitoringData(data.body);
              _this.synopticConfig = _this.parseSynopticConfig(data.body, _this.currentSynopticId);

              if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
            }
          );

      }));
    } catch (error) {
      console.log(error);
    }
  }

  parseMonitoringData(data: any) {

    try {
      if (data != null && Object.keys(data).length > 0) {

        // neverC enabled
        if (data.hasOwnProperty('neverC') && data.neverC != null) {
          if (data.neverC) {
            data.notConnected = false;
            data.neverConnected = true;
            data.lastUpdateP = data.lastUpdate != null ? moment(data.lastUpdate).tz(this.machine.timezone).format("DD MMM YYYY - HH:mm:ss") : this.translate.instant("MACHINE_STATES.NEVER_CONNECTED");
          }
          else {
            data.notConnected = true;
            data.neverConnected = false;
            data.lastUpdateP = data.lastUpdate != null ? moment(data.lastUpdate).tz(this.machine.timezone).format("DD MMM YYYY - HH:mm:ss") : this.translate.instant("MACHINE_STATES.NOT_CONNECTED");
          }
        }

        // No neverC property
        else {
          data.lastUpdateP = data.lastUpdate != null ? moment(data.lastUpdate).tz(this.machine.timezone).format("DD MMM YYYY - HH:mm:ss") : this.translate.instant("MACHINE_STATES.NEVER_CONNECTED");

          data.neverConnected = data.lastUpdate == null;
          let connectionTime = this.machine.profile?.ukwStateThresholdSeconds != null ? this.machine.profile?.ukwStateThresholdSeconds / 60 : (this.appConfig.machineMonitoring?.warningMinutes ?? 15);
          data.notConnected = moment(this.currentTimeUnparsed).diff(moment(data.lastUpdate), 'm') > connectionTime;
        }

        this.machine?.profile?.timeMachineAspects?.forEach(asp => {
          if (!data.hasOwnProperty(asp?.translation)) {
            data[asp?.translation] = (data[asp?.numerator] - (asp?.offset ?? 0)) / (asp?.denominator ?? 1);

            if (asp?.invertFrom100) data[asp?.translation] = 100 - data[asp?.translation];
            if (asp?.min != null && data[asp?.translation] < asp?.min) data[asp?.translation] = asp?.min;
            if (asp?.max != null && data[asp?.translation] > asp?.max) data[asp?.translation] = asp?.max;

          }
          // if (asp?.numerator != asp?.translation) try { delete data[asp?.numerator] } catch (error) { }
        });

        // console.log({ data });

        return data;
      }
      return {};
    } catch (error) {
      console.log(error);
      return {};
    }

  }

  openTimeMachineSelectionDialog() {

    let buttonInfos: any = this.dashboardConfig?.buttonInfos ?? {
      "icon": "restore",
      "dialogTitle": "GLOBAL.TIME_MACHINE_PERIOD_SELECTION",
      "tdClass": "w50px",
      "dialogLabelFlex": "30",
      "dialogWidth": "40%",
      "defaultDisabled": true,
    };

    let tableInfos = this.dashboardConfig?.tableInfos ?? [
      {
        "variable": "timeStart",
        "orderBy": "timeStart",
        "label": "INTERVAL.START",
        "timeCheck": {
          "operator": "lt",
          "variable": "timeEnd",
          "diff": "diffValue"
        },
        "diffTimeCheck": {
          "id": "timeCheck",
          "variable": "timeEnd",
          "diffValue": 4 * 3600 * 1000,
        },
        "editableInDialog": true,
        "requiredInDialog": true,
        "dialogEditType": "inputDateTime"
      },
      {
        "variable": "timeEnd",
        "orderBy": "timeEnd",
        "timeCheck": {
          "operator": "gt",
          "variable": "timeStart"
        },
        "diffTimeCheck": {
          "id": "timeCheck",
          "variable": "timeStart",
          "diffValue": 4 * 3600 * 1000,
        },
        "label": "INTERVAL.END",
        "editableInDialog": true,
        "requiredInDialog": true,
        "dialogEditType": "inputDateTime"
      }
    ];

    const timeMachineSelectionDialog = this.dialog.open(MultipleInputDialogComponent, {
      panelClass: 'ff-dialog',
      width: "30%",
      data: {
        title: this.translate.instant(buttonInfos?.dialogTitle ?? 'GLOBAL.ADD_DIALOG'),
        icon: buttonInfos?.icon,
        machineId: this.machineId,
        row: {

          // timeStart: "2022-08-05T08:10",
          // timeEnd: "2022-08-05T09:10"
          // timeStart: "2022-08-16T12:10",
          // timeEnd: "2022-08-16T19:10"
          // timeStart: "2022-08-12T11:20",
          // timeEnd: "2022-08-12T11:50"
          timeStart: moment().subtract(1, "hours").format("YYYY-MM-DDTHH:mm"),
          timeEnd: moment().format("YYYY-MM-DDTHH:mm")
        },
        tableInfos: this.clonerService.deepClone(tableInfos),
        labelFlex: buttonInfos?.dialogLabelFlex,
        profile: this?.machine?.profile,
      }
    });

    timeMachineSelectionDialog.afterClosed().subscribe(result => {
      // console.log(result)
      if (result != null && result != '') {
        this.pageState.next(7);
        this.stopPollingMonitoringData();
        this.loadingData = {
          message: this.translate.instant("LOADING.TIME_MACHINE_DATA")
        };
        this.monitoringData = this.parseMonitoringData({ lastUpdate: null });
        this.synopticConfig = this.parseSynopticConfig(this.monitoringData, this.currentSynopticId);

        this.getTimeMachineData(result);
      }
    });
  }

  exitTimeMachineMode() {
    this.noTimeMachineData = false;
    this.playerState = 0;
    this.speed = 1;
    this.pollingSliderTime = 1000;
    this.stopPollingSlider();
    this.timeMachineMode = false;
    this.pageState.next(7);
    this.getMonitoringData(this);
  }

  stopPollingMonitoringData() {
    try { this.pollingMonitoringData.unsubscribe() } catch (error) { }
  }

  getTimeMachineData(interval) {
    try {

      let query = {
        from: interval.timeStart,
        to: interval.timeEnd,
        tz: this.machine.timezone,
      };

      this.startUnparsed = moment(interval.timeStart);
      this.endUnparsed = moment(interval.timeEnd);

      this.start = this.startUnparsed.format("DD/MM/YYYY HH:mm:ss");
      this.end = this.endUnparsed.format("DD/MM/YYYY HH:mm:ss");

      let _this = this;

      let payload: any = this.machine.profile?.timeMachineAspects?.reduce((acc, val) => {
        if (val?.numerator != null && typeof val?.numerator == 'string') acc.push(val?.numerator);
        if (val?.denominator != null && typeof val?.denominator == 'string') acc.push(val?.denominator);
        return acc;
      }, []);

      this.apiService.sendPostRequest('/apif/machine-monitoring/datapoints/' + this.machineId, payload, query).pipe(
        retryWhen(this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          return _this.internalDataService.parseStandardHTTPError(_this, error);

        }))
        .subscribe(
          (data: any) => {
            // console.log(data?.body);

            let aspects: any = Object.entries(data?.body);
            _this.noTimeMachineData = aspects?.every(([k, val]) => val?.timestamps?.length == 0);
            _this.timeMachineMode = true;

            if (_this.noTimeMachineData) {
              _this.dispatcherService.getDispatch(_this, 300);
              return;
            }

            _this.timeMachineData = this.clonerService.deepClone(data?.body);
            _this.dispatcherService.getDispatch(_this, 300);

            _this.setSliderValues();
          },
        );

    } catch (error) {
      console.log(error);
    }
  }

  play() {
    this.playerState = 1;
    this.startPollingSlider();
  };

  pause() {
    this.playerState = 0;
    this.stopPollingSlider();
  };

  resetSlider(value?) {
    this.playerState = 0;
    this.numberOfTimesSliderValueChangedBetweenACountAndTheNext = 0;

    try { this.stopPollingSlider() }
    catch (error) { console.log(error) }

    this.slider = null;

    setTimeout(() => {
      this.setSliderValues(value);
      if (value != null) this.play();
    }, 5);
  };

  decreaseSpeed() {
    this.stopPollingSlider();
    this.pollingSliderTime = this.pollingSliderTime * 2;
    this.speed = this.speed / 2;
    if (this.playerState == 1) this.startPollingSlider();
  };

  increaseSpeed() {
    this.stopPollingSlider();
    this.pollingSliderTime = this.pollingSliderTime / 2;
    this.speed = this.speed * 2;
    if (this.playerState == 1) this.startPollingSlider();
  }

  startPollingSlider() {
    // this.stopPollingSlider();
    // setTimeout(() => {
    this.pollingSlider = timer(0, this.pollingSliderTime).subscribe((count) => {
      // console.log(count);
      if (count > 0) this.numberOfTimesSliderValueChangedBetweenACountAndTheNext = 0;

      this.setNewSliderValue(count == 0);
    });
    // }, 250);
  };


  stopPollingSlider() {
    try { this.pollingSlider.unsubscribe() }
    catch (error) { console.log(error) }
  }

  setSliderValues(currentValue?) {

    let _this = this;

    this.slider = {
      value: currentValue ?? 0,
      min: currentValue ?? 0,
      options: {
        showSelectionBar: true,
        animate: false,
        floor: 0,
        ceil: this.endUnparsed != null ? moment(this.endUnparsed).diff(this.startUnparsed, 'seconds') : 0,
        translate: function (value) {
          return (value != null && !isNaN(value)) ? _this.filterService.parseTime(value, 's', 'HH:mm:ss') : '00:00:00';
        }
      },
      userChangeEnd: function (slider) {

        // _this.stopPollingSlider();
        // _this.slider.min = slider?.value;
        // _this.currentTimeUnparsed = _this.filterService.parseMoment(moment(_this.startUnparsed).add(slider?.value, 'seconds'), 'YYYY-MM-DDTHH:mm:ss.SSSZ', _this.machine.timezone);
        // _this.currentTime = _this.filterService.parseMoment(moment(_this.startUnparsed).add(slider?.value, 'seconds'), 'DD/MM/YYYY HH:mm:ss', _this.machine.timezone);
        // _this.parseMonitoringData(false);
        // console.log('userChangeEnd');


        _this.isChangingSlider = false;
        if (_this.playerState == 1) _this.startPollingSlider();

      },
      userChangeStart: function (slider) {

        _this.isChangingSlider = true;
        if (_this.playerState == 1) _this.stopPollingSlider();
        // console.log('userChangeStart');
        // _this.slider.min = slider?.value;
        // _this.currentTimeUnparsed = _this.filterService.parseMoment(moment(_this.startUnparsed).add(slider?.value, 'seconds'), 'YYYY-MM-DDTHH:mm:ss.SSSZ', _this.machine.timezone);
        // _this.currentTime = _this.filterService.parseMoment(moment(_this.startUnparsed).add(slider?.value, 'seconds'), 'DD/MM/YYYY HH:mm:ss', _this.machine.timezone);
        // _this.parseMonitoringData(false);

        // _this.startPollingSlider();

      },
      valueChange: function (event,) {

        // console.log('valueChange', event);

        if (!_this.isChangingSlider) _this.numberOfTimesSliderValueChangedBetweenACountAndTheNext += 1;
        if (_this.numberOfTimesSliderValueChangedBetweenACountAndTheNext > 1) _this.resetSlider(event);

        // _this.slider.min = slider?.value;
        // _this.currentTimeUnparsed = _this.filterService.parseMoment(moment(_this.startUnparsed).add(slider?.value, 'seconds'), 'YYYY-MM-DDTHH:mm:ss.SSSZ', _this.machine.timezone);
        // _this.currentTime = _this.filterService.parseMoment(moment(_this.startUnparsed).add(slider?.value, 'seconds'), 'DD/MM/YYYY HH:mm:ss', _this.machine.timezone);
        // _this.parseMonitoringData(false);
      },
    }

    if (this.endUnparsed != null) {
      this.end = moment(this.endUnparsed).format("DD/MM/YYYY HH:mm:ss");
      this.currentTimeUnparsed = this.filterService.parseMoment(moment(this.startUnparsed).add(this.slider.min, 'seconds'), 'YYYY-MM-DDTHH:mm:ss.SSSZ', this.machine.timezone);
      this.currentTime = this.filterService.parseMoment(moment(this.startUnparsed).add(this.slider.min, 'seconds'), 'DD/MM/YYYY HH:mm:ss', this.machine.timezone);
    }
  }


  setNewSliderValue(firstInvoke) {
    // this.currentTimeUnparsed = '2020-12-02T00:00:00.000+01:00';
    try {
      this.slider.min = isNaN(this.slider.min) ? 0 : this.slider.min;

      if ((this.slider.min + 1) > this.slider.options.ceil) {
        this.slider.min = this.slider.options.ceil;
        this.stopPollingSlider();
        this.playerState = 0;
        return;
      }

      if (!firstInvoke) this.slider.min += 1;

      this.currentTime = this.filterService.parseMoment(moment(this.startUnparsed).add(this.slider.min, 'seconds'), 'DD/MM/YYYY HH:mm:ss', this.machine.timezone);
      this.currentTimeUnparsed = this.filterService.parseMoment(moment(this.startUnparsed).add(this.slider.min, 'seconds'), 'YYYY-MM-DDTHH:mm:ss.SSSZ', this.machine.timezone);

      this.filterDataFromCurrentTimestamp();
    } catch (error) {
      console.log(error);
    }

  }

  filterDataFromCurrentTimestamp() {

    if (Object.keys(this.timeMachineData)?.length > 0) {
      this.monitoringData = (Object.entries(this.timeMachineData) as any)?.reduce((acc, [aspect, val]) => {

        // console.log(aspect, val);

        let currentTimestampIdx = val?.timestamps?.findIndex(x => moment(this.currentTimeUnparsed).diff(moment(x)) < 0);
        if (currentTimestampIdx == -1) currentTimestampIdx = val?.timestamps?.length - 1;

        currentTimestampIdx = currentTimestampIdx == 0 ? 0 : currentTimestampIdx - 1;

        Object.entries(val?.variables)?.forEach(([variable, v]) => {
          acc[aspect + '.' + variable] = v[currentTimestampIdx];
          let aspectConfig = this.machine.profile.timeMachineAspects?.find(x => x?.numerator == aspect + '.' + variable);
          acc[aspectConfig?.translation] = (v[currentTimestampIdx] - (aspectConfig?.offset ?? 0)) / (aspectConfig?.denominator ?? 1);

          if (aspectConfig?.invertFrom100) acc[aspectConfig?.translation] = 100 - acc[aspectConfig?.translation];
          if (aspectConfig?.min != null && acc[aspectConfig?.translation] < aspectConfig?.min) acc[aspectConfig?.translation] = aspectConfig?.min;
          if (aspectConfig?.max != null && acc[aspectConfig?.translation] > aspectConfig?.max) acc[aspectConfig?.translation] = aspectConfig?.max;

        });

        let timestamp = val?.timestamps[currentTimestampIdx];
        if (acc.lastUpdate == null && moment(this.currentTimeUnparsed).diff(timestamp) > 0) acc.lastUpdate = timestamp;
        if (acc.lastUpdate != null && moment(acc?.lastUpdate)?.diff(moment(timestamp)) < 0) acc.lastUpdate = timestamp;

        return acc;

      }, {});

      this.monitoringData = this.parseMonitoringData(this.monitoringData);
      this.synopticConfig = this.parseSynopticConfig(this.monitoringData, this.currentSynopticId);

    }

  }



  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() {
    // console.log(this);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try { this.pollingMonitoringData.unsubscribe() } catch (error) { }
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.datapointSub.unsubscribe() } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
  }

}