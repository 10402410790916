<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null " class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar tabs -->
    <div *ngIf="aggregations != null && aggregations.length > 0" class="ff-toolbar pt-1 pb-3" fxLayout="column"
      fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

      <!-- aggregations -->
      <button mat-button class="filter-button" *ngFor="let aggr of aggregations" (click)="openAggrDialog(aggr)"
        [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
        <mat-icon *ngIf="aggr.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': aggr.label}">
          {{aggr.icon.icon }}</mat-icon>
        <mat-icon *ngIf="aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
          translate-attr="{'aria-label': aggr.label}"></mat-icon>
        <i *ngIf="aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor'" class="icon {{ aggr.icon.icon }}"
          translate-attr="{'aria-label': aggr.label}"></i>
        <span>{{ aggr.selectionLabel }}</span>
        <div fxLayout="row" fxLayoutAlign="center center"
          *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
          {{ aggregationsPayload[aggr.id].length }}
        </div>
      </button>

      <span fxFlex></span>

      <!-- aggregations dropdown -->
      <div class="aggregation-selector" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
        fxLayoutAlign.gt-sm="start center">

        <div class="box" fxLayout="row" fxLayoutAlign="start center">
          <!-- <mat-icon class="icon">event_available</mat-icon> -->
          <mat-form-field appearance="fill">
            <mat-label>{{ 'GLOBAL.DROPDOWN_AGGREGATION_SELECTION' | translate }}</mat-label>
            <mat-select [(ngModel)]="aggrDropdown" name="aggr" style="text-transform: capitalize;">
              <mat-option (click)="changePageAggregation(aggrDropdown)">---</mat-option>

              <mat-option *ngFor="let aggr of aggregationsToShowInDropdown" [value]="aggr.id"
                (click)="changePageAggregation(aggrDropdown)">

                <!-- <mat-icon *ngIf="aggr.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': aggr.label}">
                {{aggr.icon.icon }}</mat-icon>
              <mat-icon *ngIf="aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
                translate-attr="{'aria-label': aggr.label}"></mat-icon>
              <i *ngIf="aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor'" class="icon {{ aggr.icon.icon }}"
                translate-attr="{'aria-label': aggr.label}"></i> -->

                <span style="text-transform: capitalize;">{{ aggr.label }}</span>

              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

    </div>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < 6" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 6" fxLayout="column" class="w100 h100" style="min-height: calc(100vh - 260px);">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
        class="h100 w100" fxLayoutGap="8px">

        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="w100 h100" fxLayoutGap="8px">
          <ff-widget fxFlex="100"
            [widget]="{type: 'ff-plotly-chart-table', data: breakdownsData, machineProfile: machine.profile, config: breakdownsData.dataConfig }">
          </ff-widget>
        </div>

        <div fxFlex.gt-sm="{{dashboardConfig.secondColumnFlex}}" fxLayout="column" fxLayoutAlign="start stretch"
          class="w100 h100" fxLayoutGap="8px">

          <ff-widget *ngFor="let w of dashboardConfig.widgets" fxFlex.gt-sm="{{w.flex}}"
            [widget]="{ type: w.type, data: breakdownsData, machineProfile: machine.profile, config: w.config, title: w.title }">
          </ff-widget>

        </div>

      </div>

    </div>

  </div>

  <div fxFlex fxLayout="column" fxLayoutAlign="start start" *ngIf="pageState.value >= 6 && aggrDropdown != null"
    fxLayout="row" class="w100" style="margin-top: 8px;">

    <ff-widget fxFlex="100"
      [widget]="{type: 'ff-plotly-chart', data: breakdownsData, machineProfile: machine.profile, config: breakdownsData.aggrDataConfig }">
    </ff-widget>

  </div>

</div>