import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

import { IntervalService } from 'src/app/services/interval.service';

import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ClonerService } from 'src/app/services/clone.service';
import { CycleComparisonDialogComponent } from '../cycle-comparison-dialog/cycle-comparison-dialog.component';

@Component({
  selector: 'app-phase-variables',
  templateUrl: './phase-variables.component.html',
  styleUrls: ['./phase-variables.component.scss']
})
export class PhaseVariablesComponent implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public backButton: any;
  public tabs: any;

  public cycleType: any = 0;
  public cycleId: any;
  public cycleStart: any;
  public cycleDay: any;
  public cycleEnd: any;
  public cycleSelectedSub: Subscription;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public pollingTime: any;
  public pollingMachines: any;

  public monitoringData: any;

  public cycleInfoConfig: any;
  public cycleData: any;

  public dialogData: any;

  public allPhases: any;
  public allPhasesCopy: any;

  public phaseSelected: boolean = false;
  public phaseVariables: any;

  public comparedCycleDataConfig: any;
  public comparedCycleData: any;
  public comparedCycleInfos: any;
  public comparedCycleId: any;
  public isComparedCycle: boolean = false;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 5;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getStateTimelinePolling, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 5 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    public clonerService: ClonerService,
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    // TODO AGGIUNGERE AGGREGAZIONI E FILTRI
    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.monitoringData = null;


    this.breadcrumb = ['CYCLE_TRACEABILITY.TITLE', 'CYCLE_TIMELINE.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs('cycleDetails');

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        this.breadcrumb[2] = value.machineName;
        this.internalDataService.setBreadcrumb(this.breadcrumb);
      }
    });

    this.cycleSelectedSub = this.internalDataService.cycleSelected.subscribe(value => {
      if (value != null && !Array.isArray(value)) {
        this.breadcrumb[3] = (value.split("$$")[0]);
        this.internalDataService.setBreadcrumb(this.breadcrumb);
      }
    });

    this.pollingTime = 0;
    this.pollingMachines = Subscription;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'cycleDetails');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getCycleTimelineWidgets(_this: any) {

    let dashboardName = 'cycle-timeline';

    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET SYNOPTIC CONFIG

  // polling
  getStateTimelinePolling(_this: any) {
    try {

      if (_this.pollingTime > 0) {
        _this.pollingMachines = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getStatesTimeline(_this, count);
        });
      } else {
        _this.getStatesTimeline(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  getStatesTimeline(_this: any, count?: any) {
    try {

      let query: any = {
        from: _this.cycleStart,
        to: _this.cycleEnd,
        cycleType: _this.cycleType,
        cycleId: _this.cycleId,
        tz: _this.machine.timezone
      };

      _this.apiService.sendGetRequest('/apif/cycle-detail/cycle-timeline/' + _this.machineId, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError(
            'Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            let series: any = [];

            if (data.body.hasOwnProperty('phases') && data.body.phases != null && data.body.phases.length > 0) {
              _this.parsePhasesResponse(_this, data.body.phases);
            }

            // _this.phases.list = _this.parsephasesTable(data.body.phasesTable);

            // setTimeout(() => {
            //   _this.phasesData = new MatTableDataSource<Object>(_this.phases.list);
            // }, 100);

            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }
          }
        );

    } catch (error) {
      console.log(error);
    }

  }

  parsePhasesResponse(_this: any, phases: any) {

    _this.allPhasesCopy = _this.clonerService.deepClone(phases);
    _this.cycleData.type = phases.findIndex((phase: any) => phase.outputPhaseId == 13) != -1 ? 1 : 0;

    let allPhases: any = [];
    if (_this.machine.profile.phaseVariables != null) {
      let phaseColumns: any = [];
      Object.entries(_this.machine.profile.phaseVariables).filter((pvc: any) => {
        return phases.findIndex((p: any) => {
          let phaseConfig = null;
          let phaseId: any = null;
          try {
            phaseConfig = _this.machine.profile.phases.find((x: any) => x.inputPhases.includes(p.phaseId));
            if (phaseConfig != null) phaseId = phaseConfig.outputPhaseId;
          } catch (error) {
            console.log(error);
          }
          return phaseId == pvc[0];
        }) != -1
      }).forEach((props: any) => {
        phaseColumns.push('icon');

        let phaseId = props[0];

        let phaseConfig = null;
        let phaseName = null;
        let phaseColor = null;

        try {
          phaseConfig = _this.machine.profile.phases.find((x: any) => x.outputPhaseId == phaseId);
          if (phaseConfig != null) {
            phaseName = _this.translate.instant(phaseConfig.label);
            phaseColor = phaseConfig.color;
          }

        } catch (error) {
          console.log(error);
        }

        try {
          _this.appConfig.cycleDetails.phaseVariables.phasesInfo.forEach((x: any) => phaseColumns.push(x.variable));
        } catch (error) {
          console.log(error);
        }

        allPhases.push({
          phaseColumns: phaseColumns,
          phaseInfos: _this.appConfig.cycleDetails.phaseVariables.phasesInfo,
          phases: {
            list: [],
            expanded: true,
          },
          phaseId: phaseId,
          phaseName: phaseName,
          phaseColor: phaseColor,
          phaseData: new MatTableDataSource<any[]>([]),
        });

        phaseColumns = [];

      });
    }

    _this.allPhases = allPhases;

    phases.sort(_this.filterService.sortByProperty('phaseId', null, true)).forEach((state: any, index: any) => {

      let stateConfig = null;
      let color = null;
      let label = null;
      let phaseId: any = null;
      try {
        stateConfig = _this.machine.profile.phases.find((x: any) => x.inputPhases.includes(state.phaseId));
        if (stateConfig != null) {
          color = stateConfig.color;
          label = _this.translate.instant(stateConfig.label);
          phaseId = stateConfig.outputPhaseId;
        }
      } catch (error) {
        console.log(error);
      }

      let phaseList: any = [];
      let phaseVars: any = _this.machine.profile.phaseVariables[phaseId];

      let phaseColumns: any = ['icon'];

      try {
        _this.appConfig.cycleDetails.phaseVariables.phasesInfo.forEach((x: any) => phaseColumns.push(x.variable));
      } catch (error) {
        console.log(error);
      }

      phaseVars.forEach((pc: any) => {
        phaseList.push({
          value: _this.filterService.parseGaugeValue(state[pc.variable.split(".")[1]], pc.decimals, (pc.multiplier != null ? pc.multiplier : 1)),
          // label: _this.translate.instant("DATAPOINTS." + this.machineId + '.' + pc.variable.split(".")[0] + phaseId + "." + pc.variable.split(".")[1]),
          label: _this.internalDataService.parseDatapointLabel(pc.variable.split(".")[0] + phaseId, pc.variable.split(".")[1], _this.machineId),
          unit: pc.unit != null ? pc.unit : "",
        });
      });

      let idx = _this.allPhases.findIndex((x: any) => x.phaseId == phaseId);

      console.log(phaseList);

      if (idx != -1) {
        _this.allPhases[idx].phases.list = phaseList;
        _this.allPhases[idx].phaseData = new MatTableDataSource<Object>(phaseList);
      }

    });
  }

  getphasestr(code: any, attribute: string) {
    let codeTrKey = 'phases.' + this.machineId + '.' + code + '.' + attribute;
    let codeTr = this.translate.instant(codeTrKey);
    if (codeTr === codeTrKey) {
      // custom attribute not found
      codeTrKey = 'phases.default.' + code + '.' + attribute;
      codeTr = this.translate.instant(codeTrKey);
      if (codeTr === codeTrKey) {
        // default attribute not found]
        codeTr = code;
      }
    }
    return codeTr;
  }

  parsephasesTable(data: any) {
    if (data != null && data.length > 0) {
      data.forEach((element: any) => {
        element.code = this.getphasestr(element.alarmCode, 'code');
        element.message = this.getphasestr(element.alarmCode, 'message');
        element.timeStartP = this.filterService.parseMoment(element.start, 'default');
        element.timeEndP = this.filterService.parseMoment(element.end, 'default');
        element.aggr0P = this.translate.instant('MACHINES.' + element.aggr0) != 'MACHINES.' + element.aggr0 ? this.translate.instant('MACHINES.' + element.aggr0) : element.aggr0;
        element.durationP = (element.duration != null) ?
          this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss') :
          // this.filterService.parseDuration(element.duration, 's', 'hh:mm:ss', element.timeStart, element.timeEnd);
          '-';

      });
      return data;
    }
    return []
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    let cycleUrl = this.route.snapshot.params['cycleId'];

    this.backButton = [this.machineId, "cycle-traceability", "cycles"];
    this.internalDataService.setBackButton(this.backButton);

    try {
      let splittedCycleUrl = cycleUrl.split("$$");
      this.cycleId = splittedCycleUrl[0];
      this.cycleStart = splittedCycleUrl[1];
      this.cycleEnd = splittedCycleUrl[2];
      this.cycleType = splittedCycleUrl[3];
      this.cycleDay = moment(this.cycleEnd).format("YYYY-MM-DD");
    } catch (error) {
      console.log(error);
    }
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId'];
        let cycleUrl = params['cycleId'];

        try {
          let splittedCycleUrl = cycleUrl.split("$$");
          this.cycleId = splittedCycleUrl[0];
          this.cycleStart = splittedCycleUrl[1];
          this.cycleEnd = splittedCycleUrl[2];
          this.cycleType = splittedCycleUrl[3];
          this.cycleDay = moment(this.cycleEnd).format("YYYY-MM-DD");

          this.cycleData = {
            cycleId: this.cycleId,
            cycleDay: this.cycleDay,
            cycleType: this.cycleType,
            cycleStart: this.filterService.parseMoment(this.cycleStart, 'default'),
            cycleEnd: this.filterService.parseMoment(this.cycleEnd, 'default'),
            cycleDuration: moment(this.cycleEnd).diff(this.cycleStart, 's'),
          };

        } catch (error) {
          console.log(error);
        }

        this.internalDataService.setCycleSelected(cycleUrl);

      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  consoleLog(row: any) {
    console.log(row);
  }

  openCycleComparison() {

    const cycleComparisonDialog = this.dialog.open(CycleComparisonDialogComponent,
      {
        panelClass: 'ff-dialog',
        width: '90%',
        height: '90%',
        data: {
          title: this.translate.instant("PROCESS_LOG.CYCLE_SELECTION"),
          machine: this.machine,
          appConfig: this.appConfig,
          actualCycleInfos: this.cycleData,
          comparedCycleInfos: this.comparedCycleInfos
        },
      });

    cycleComparisonDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          console.log(result);

          this.isComparedCycle = true;
          this.comparedCycleId = result.cycle.id;

          this.comparedCycleInfos = {
            cycleId: result.cycle.id != null ? result.cycle.id.toString() : 'unknown',
            cycleDay: moment(result.cycle.timeEnd).format("YYYY-MM-DD"),
            cycleStart: result.cycle.timeStartP,
            cycleEnd: result.cycle.timeEndP,
            cycleDuration: moment(result.cycle.timeEnd).diff(result.cycle.timeStart, 's'),
          };

          let addInfo: any = {
            cycleId: this.cycleId,
            comparedCycleId: this.comparedCycleId,
          }
          // try {
          //   this.paramsRInfo.forEach((info: any) => {
          //     info.label = info.comparedLabel != null ? this.translate.instant(info.comparedLabel) + (info.addVariable != null ? (' (' + addInfo[info.addVariable] + ')') : '') : info.label
          //   });
          // } catch (error) {
          //   console.log(error);
          // }
          // this.paramsRColumns = ['icon'].concat(this.paramsRInfo.filter((x: any) => this.isComparedCycle ? true : !x.compared).map((x: any) => x.variable));
          // this.paramsR.list = this.parseParamsRTable(this.actualParamsR, this.machine.profile.paramsR, result.cycle.params);

          // setTimeout(() => {
          //   this.paramsRData = new MatTableDataSource<Object>(this.paramsR.list);
          // }, 100);

        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  removeComparedCycle() {
    this.isComparedCycle = false;
    this.comparedCycleId = null;
    this.comparedCycleInfos = null;
    // this.paramsRInfo = this.clonerService.deepClone(this.appConfig.cycleDetails.cycleTimeline.paramsInfo);
    // this.paramsRColumns = ['icon'].concat(this.paramsRInfo.filter((x: any) => this.isComparedCycle ? true : !x.compared).map((x: any) => x.variable));
  }

  ngOnChanges() {
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pollingMachines.unsubscribe();
    } catch (error) { }
    try {
      this.pageState.unsubscribe();
    } catch (error) { }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) { }
    try {
      this.cycleSelectedSub.unsubscribe();
    } catch (error) { }
    try {
      this.internalDataService.setBackButton([]);
    } catch (error) { }
  }

}