import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'create-folder-dialog',
  templateUrl: './create-folder-dialog.component.html',
  styleUrls: ['./create-folder-dialog.component.scss']
})
export class CreateFolderDialogComponent implements OnInit {
  urlPath: any;

  public errorData: any = {
    type: 0,
    status: 500,
    message: "GLOBAL.EXCEL_ERROR"
  };

  public filePathRegex = ["..", "/"];
  public dialogState: any = 1;

  public filePath: any;
  public folderName: any;
  public invalidValues: any;

  public isSupplier: any;
  public sendMailToCustomers: any = false;

  // Additional subscription
  public customSubscription: Subscription;

  constructor(
    public apiService: ApiService,
    public http: HttpClient,
    public configs: AppConfigService,
    public dialogRef: MatDialogRef<CreateFolderDialogComponent>,
    public clonerService: ClonerService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public router: Router,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialog: any) {

    this.dialogState = 1;
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.filePath = this.dialog?.urlPath ?? './';
    this.invalidValues = this.dialog?.invalidValues ?? [];
    this.isSupplier = this.internalDataService.getSpecificPermission("base-supplier");
  }

  async uploadFolder() {

    this.dialogState = 0;

    let payload = new FormData();

    const array = ['<q id="a"><span id="b">hey!</span></q>']; // an array consisting of a single string
    const blob = new Blob(array, { type: 'text/html' }); // the blob

    payload.append("binary", blob);
    payload.append("filePath", this.filePath + (this.folderName ?? 'unknown') + '/empty.html')

    let url: any = '/apif/machine-documentation/upload-file/' + this.dialog.machineId;
    if (this.dialog?.buttonInfos?.endpointUrl) url = this.dialog.buttonInfos.endpointUrl.replaceAll("{machineId}", this.dialog.machineId);

    let query = {};

    if (this.sendMailToCustomers) {
      query = {
        sendMailToCustomers: true
      };
      query = this.filterService.addStandardInformations(query);
    }

    let respPost: any = null;
    try {
      respPost = await this.apiService.asyncDownload(url, payload, query);

      this.dialogState = 1;

      this.internalDataService.openSnackBar(
        this.translate.instant("FILE_EXPLORER.FOLDER_CREATE_RESPONSE.SUCCESS"),
        'right',
        'bottom',
        4000,
        '',
        ['success']
      );
    } catch (error) {
      console.log(error);

      this.dialogState = 2;

      this.internalDataService.openSnackBar(
        this.translate.instant("FILE_EXPLORER.FOLDER_CREATE_RESPONSE.FAIL"),
        'right',
        'bottom',
        4000,
        '',
        ['fail']
      );

    }

    this.closeDialog();

    this.router.navigateByUrl(this.router.url);
  }

  getRegex(regex?: any, value?: any) {
    switch (regex) {
      case 'filePath':
        return this.filePathRegex?.every(x => !value?.includes(x));
      default:
        return null;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

}