<!-- toolbar -->
<div *ngIf="((searchInput || exportTableConfig) && tableConfig?.rows?.length > 0) ||
   tableConfig.title != null || tableConfig.showToolbar || tableConfig?.filters?.switch != null
   || tableConfig?.filters?.slider != null || tableConfig.additionalTableButtons?.length > 0" class="ff-toolbar pt-2"
  fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" [ngClass]="{'pb-2': exportTableConfig == null }"
  fxLayoutAlign.gt-sm="start center">

  <!-- title -->
  <span *ngIf="tableConfig.title != null" class="widget-table-sortable title" fxLayout="row"
    fxLayoutAlign="start center">
    <!-- title -->
    <button *ngIf="tableConfig.collapsable != null" class="rounded-button md-light-gray"
      (click)="tableConfig.expanded = !tableConfig.expanded" marginRight>
      <mat-icon *ngIf="!tableConfig.expanded" class="icon transition-01">expand_more</mat-icon>
      <mat-icon *ngIf="tableConfig.expanded" class="icon transition-01">expand_less</mat-icon>
    </button>
    {{ tableConfig.title | translate }}
  </span>

  <!-- subtitle -->
  <span *ngIf="tableConfig.subtitle != null" class="widget-table-sortable subtitle" [class]="tableConfig.subtitleClass"
    fxLayout="row" fxLayoutAlign="start center">
    {{ tableConfig.subtitle | translate }}
  </span>

  <!-- switch -->
  <div *ngIf="tableConfig?.filters?.switch != null" fxLayout="row" fxLayoutAlign="center center">
    <span *ngIf="tableConfig?.filters?.switch?.title != null" style="margin: 0 8px;">
      {{ tableConfig.filters.switch.title | translate }}
    </span>
    <ui-switch [checked]="tableConfig?.filters?.switch?.checked" (change)="switchChange($event)"
      [disabled]="tableConfig?.rows?.length == 0" checkedLabel="{{tableConfig?.filters?.switch?.checkedLabel}}"
      uncheckedLabel="{{tableConfig?.filters?.switch?.uncheckedLabel}}">
    </ui-switch>
  </div>

  <!-- <button class="filter-button" (click)="collapseAll()">collapse</button>
  <button class="filter-button" (click)="expandAll()">expand</button> -->

  <!-- slider span -->
  <span *ngIf="tableConfig?.filters?.slider != null" class="widget-table-sortable vertical-divider"></span>

  <!-- slider -->
  <div *ngIf="tableConfig?.filters?.slider != null" fxLayout="row" fxLayoutAlign="center center">

    <span *ngIf="tableConfig?.filters?.slider?.label != null" style="margin-right: 8px;">{{
      tableConfig?.filters?.slider?.label | translate }}</span>

    <span *ngIf="tableConfig?.filters?.slider?.tooltip != null"
      matTooltip="{{ tableConfig.filters.slider.tooltip | translate }}" matTooltipPosition="above"
      style="margin-right: 8px;">
      <mat-icon class="icon">info</mat-icon>
    </span>

    <div fxLayout="row" fxLayoutAlign="start center" class="ngx-slider-container">
      <ngx-slider [(value)]="tableConfig.sliderConf.min" [(highValue)]="tableConfig.sliderConf.max"
        [options]="tableConfig.sliderConf.options" (userChangeEnd)="tableConfig.sliderConf.userChangeEnd($event)">
      </ngx-slider>
    </div>
  </div>

  <!-- spacer span -->
  <span fxFlex></span>

  <!-- search -->
  <div *ngIf="searchInput" class="search-input" [class.inverted]="tableConfig?.invertSearchInputClass" fxLayout="row"
    fxLayoutAlign="start center">
    <mat-icon class="icon">search</mat-icon>
    <input type="text" class="ff-input" [(ngModel)]="searchText" (keyup)="filterRows()"
      placeholder="{{'GLOBAL.SEARCH'|translate}}">
  </div>

  <!-- multiselect -->
  <button *ngIf="!isMobile && tableConfig.multiselect != null" (click)="toggleMultiselect()" class="filter-button"
    [ngStyle]="{'margin-right': (exportTableConfig != null || tableConfig?.addFilterableColumns) ? '8px' : '0'}"
    matTooltip="{{ tableConfig.linkTooltip | translate }}" matTooltipPosition="above" fxLayout="row"
    fxLayoutAlign="center center">
    <ff-icon *ngIf="!multiselect" [icon]="'playlist_add'"></ff-icon>
    <ff-icon *ngIf="multiselect" [icon]="'playlist_add_check'"></ff-icon>
    <span>{{'GLOBAL.MULTISELECT'|translate}}</span>
  </button>

  <!-- additionalTableButtons -->
  <ng-container *ngIf="tableConfig.additionalTableButtons != null && tableConfig.additionalTableButtons.length > 0">
    <button *ngFor="let info of tableConfig.additionalTableButtons" [ngClass]="info.class" (click)="log({}, info)"
      [disabled]="info.disabled" [ngStyle]="{'margin-right': exportTableConfig ? '8px' : '0'}"
      [fxShow]="!info.hideInTable" matTooltip="{{ info.tooltip | translate }}" matTooltipPosition="above" fxLayout="row"
      fxLayoutAlign="center center">

      <span *ngIf="info.icon != null" fxLayout="row" fxLayoutAlign="center center">

        <ng-container *ngIf="info.icon?.type == null">
          <mat-icon class="icon">{{ info.icon }}</mat-icon>
        </ng-container>

        <ng-container *ngIf="info.icon?.type != null">
          <mat-icon *ngIf="info.icon.type == 'icon'" class="icon">
            {{info.icon.icon }}
          </mat-icon>
          <mat-icon *ngIf="info.icon.type == 'svg'" svgIcon="{{ info.icon.icon }}" class="icon"></mat-icon>
          <i *ngIf="info.icon.type == 'fontawesome' || info.icon.type == 'phosphor'"
            class="icon {{ info.icon.icon }}"></i>
        </ng-container>
      </span>

      <span *ngIf="info.label != null" fxLayout="column" fxLayoutAlign="center center">{{ info.label | translate
        }}</span>
    </button>
  </ng-container>

  <!-- link -->
  <button *ngIf="tableConfig.link != null" (click)="openInNew(tableConfig.link)" class="filter-button no-label"
    [ngStyle]="{'margin-right': (exportTableConfig != null || tableConfig?.addFilterableColumns) ? '8px' : '0'}"
    matTooltip="{{ tableConfig.linkTooltip | translate }}" matTooltipPosition="above" fxLayout="row"
    fxLayoutAlign="center center">
    <!-- icon -->
    <ff-icon [icon]="'open_in_new'"></ff-icon>
  </button>

  <!-- filter buttons -->
  <ff-filter-buttons *ngIf="tableConfig?.addFilterableColumns" [filterButtons]="filterButtons"
    (selected)="onFiltersDialogSelect($event)"
    [ngStyle.gt-sm]="{'margin-right': exportTableConfig != null ? '8px' : '0'}">
  </ff-filter-buttons>

  <!-- export -->
  <ff-export-table *ngIf="!isMobile && exportTableConfig && tableConfig?.rowsFiltered?.length > 0"
    [config]="exportTableConfig" matTooltip="{{ 'GLOBAL.EXPORT_TABLE_CSV' | translate }}" class="inToolbar">
  </ff-export-table>

</div>

<!-- multiselect toolbar -->
<div *ngIf="!isMobile && multiselect" class="ff-toolbar pt-2" fxLayout="column" fxLayoutAlign="center start"
  fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

  <button class="filter-button" (click)="toggleAllMultiselect()" fxLayout="row" fxLayoutAlign="center center"
    style="margin: 0 0.75rem !important">
    <mat-icon class="icon">done_all</mat-icon>
    <span fxLayout="column" fxLayoutAlign="center center">{{'GLOBAL.SELECT_ALL'|translate}}</span>
  </button>

  <button class="filter-button" (click)="toggleMultiselect()" fxLayout="row" fxLayoutAlign="center center"
    style="margin: 0 !important">
    <mat-icon class="icon">close</mat-icon>
    <span fxLayout="column" fxLayoutAlign="center center">{{'GLOBAL.MULTISELECT_CLOSE'|translate}}</span>
  </button>

  <!-- spacer span -->
  <span fxFlex></span>

  <!-- multiselect buttons -->
  <ng-container
    *ngIf="tableConfig.multiselect != null && tableConfig.multiselect.buttons != null && tableConfig.multiselect.buttons.length > 0">
    <button *ngFor="let multibtn of tableConfig.multiselect.buttons" class="filter-button" [ngClass]="multibtn.class"
      (click)="log({}, multibtn)" [disabled]="multibtn.disabled" matTooltip="{{ multibtn.tooltip | translate }}"
      matTooltipPosition="above" fxLayout="row" fxLayoutAlign="center center">

      <span *ngIf="multibtn.icon != null" fxLayout="row" fxLayoutAlign="center center">

        <ng-container *ngIf="multibtn.icon?.type == null">
          <mat-icon class="icon">{{ multibtn.icon }}</mat-icon>
        </ng-container>

        <ng-container *ngIf="multibtn.icon?.type != null">
          <mat-icon *ngIf="multibtn.icon.type == 'icon'" class="icon">
            {{multibtn.icon.icon}}
          </mat-icon>
          <mat-icon *ngIf="multibtn.icon.type == 'svg'" svgIcon="{{ multibtn.icon.icon }}" class="icon"></mat-icon>
          <i *ngIf="multibtn.icon.type == 'fontawesome' || multibtn.icon.type == 'phosphor'"
            class="icon {{ multibtn.icon.icon }}"></i>
        </ng-container>
      </span>

      <span *ngIf="multibtn.label != null" fxLayout="column"
        fxLayoutAlign="center center">{{multibtn.label|translate}}</span>
    </button>
  </ng-container>

</div>

<!-- desktop view -->
<mat-card *ngIf="!isMobile && !tableConfig.expanded" [ngClass]="{'hidden':!tableConfig.cardFrame}" fxLayout="column"
  class="w100">

  <!-- no results -->
  <mat-card *ngIf="!tableConfig?.rowsFiltered?.length && !tableConfig.hideNoData" style="text-align: center;">
    <span>{{ (tableConfig.noDataLabel != null ? tableConfig.noDataLabel : 'GLOBAL.NO_DATA_AVAILABLE') | translate
      }}</span>
  </mat-card>

  <!-- paginatorTop -->
  <table *ngIf="paginatorTop && tableConfig?.rowsFiltered?.length" class="widget-table-sortable">
    <tbody>
      <tr style="border: none;">
        <td style="padding: 0.75rem 0 0;">
          <div fxLayout="row" fxLayoutAlign="{{paginatorAlign}}">

            <div class="select-box" fxFlexOrder="{{paginatorAlignOrderSelector}}"
              *ngIf="paginatorOptions != null && paginatorOptions?.length > 0">
              <div class="box" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="fill" class="w140">
                  <mat-label>{{'GLOBAL.ITEMS_PER_PAGE'|translate}}</mat-label>
                  <mat-select [(value)]="paginatorSize">
                    <mat-option *ngFor="let size of paginatorOptions" [value]="size" (click)="updatePageSize(size)">
                      {{size}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlexOrder="{{paginatorAlignOrderButtons}}">
              <div fxLayout="row" style="padding: 0 1rem;">
                <span>{{pageCounter}}</span>
              </div>
              <div fxLayout="row">
                <button (click)="previousPage()" [disabled]="previousPageDisabled()" mat-button fxLayout="row"
                  fxLayoutAlign="space-evenly center" class="btn-pagArrow">
                  <ff-icon icon="arrow_back"></ff-icon>
                </button>
                <button (click)="nextPage()" [disabled]="nextPageDisabled()" mat-button fxLayout="row"
                  fxLayoutAlign="space-evenly center" class="btn-pagArrow">
                  <ff-icon icon="arrow_forward"></ff-icon>
                </button>
              </div>
            </div>

          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <!-- table -->
  <table *ngIf="tableConfig?.rowsFiltered?.length && !tableConfig.widgetRow" class="widget-table-sortable">

    <thead>
      <tr>
        <!-- multiselect icon -->
        <th *ngIf="multiselect"></th>
        <!-- staticIcon -->
        <th *ngIf="tableConfig.staticIcon"></th>
        <!-- nestedColumn -->
        <th *ngIf="tableConfig.isNestedTable &&
        !tableConfig.hideColumnsForExpander && 
        tableConfig.nestedTableConfig != null &&
        tableConfig.nestedTableConfig.variable != null" class="w50px">
        </th>
        <!-- columns -->
        <ng-container *ngFor="let info of tableConfig.tableInfos | filterByProperty:['hideInTable', '!=', true]">
          <th (click)="sortByProperty(info)"
            [ngClass]="{'clickable': info.orderBy != null, 'fixed32': info.type == 'icon'}">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div
                *ngIf="!isMobile && info.icon != null && info.type != 'icon' && info.type != 'iconWithClassAsVariable'"
                class="icon-container" style="margin-right: 8px;">
                <ff-icon [icon]="info.icon"></ff-icon>
              </div>
              <div fxLayout="row" fxLayoutAlign="start center">
                <span>{{ info.label | translate }}</span>
                <span *ngIf="info.unitInTitle && info.unit != null">
                  &nbsp;[{{ filterService.convertUnit(info.unit, 0).unit | translate }}]
                </span>
                <ff-icon *ngIf="info.tooltip != null" icon="info" matTooltip="{{ info.tooltip | translate }}"
                  matTooltipClass="ff-tooltip" right>
                </ff-icon>
              </div>
              <div *ngIf="!isMobile" class="icon-container">
                <mat-icon class="icon transition-01" [ngClass]="{'arrow_upward-up': info.sorted.down}"
                  *ngIf="info.sorted !=null && tableConfig.sort.variable == info.orderBy">
                  arrow_upward
                </mat-icon>
              </div>
            </div>
          </th>
        </ng-container>
        <!-- additional buttons -->
        <ng-container
          *ngIf="!multiselect && tableConfig.additionalButtons != null && tableConfig.additionalButtons.length > 0">
          <th *ngFor="let info of tableConfig.additionalButtons" [ngClass]="info.tdClass">
          </th>
        </ng-container>
      </tr>
    </thead>

    <tbody
      [ngClass]="{ 'opacity0': tbodyFade && !tableConfig.removeAnimations, 'transition-01': !tableConfig.removeAnimations }">
      <ng-container *ngFor="let item of tableConfig.rowsFiltered | slice:limits[0]:limits[1]">
        <tr [class]="item.rowClass" class="completely" [ngClass]="{
            'clickable': (tableConfig.selectable && !item.unselectable) || item.urlPath != null,
            'selectable': tableConfig.selectable && !item.unselectable,
            'selected': item.selected, 
            'critical': item.critical, 
            'third-color': item.thirdColor, 
            'subcritical': item.subcritical, 
            'ff-disabled': item.unselectable,
            'multisel': item.multiselected
          }" (click)="clickRow(item)">

          <!-- multiselect icon -->
          <td *ngIf="multiselect" class="w50px" [style.background-color]="item.rowBgColor">
            <button class="rounded-button" style="background: transparent;">
              <mat-icon *ngIf="item.multiselected" class="icon">radio_button_checked</mat-icon>
              <mat-icon *ngIf="!item.multiselected" class="icon">radio_button_unchecked</mat-icon>
            </button>
          </td>

          <!-- nested column icon expander -->
          <td class="w50px" [style.background-color]="item.rowBgColor"
            *ngIf="tableConfig.isNestedTable && !tableConfig.hideColumnsForExpander && tableConfig.nestedTableConfig != null && tableConfig.nestedTableConfig.variable != null">
            <button *ngIf="item != null &&
            item[tableConfig.nestedTableConfig.variable] != null &&
            item[tableConfig.nestedTableConfig.variable].length > 0" class="rounded-button md-light-gray"
              (click)="item.expandedNesting = !item.expandedNesting;">
              <mat-icon class="icon transition-01" [ngClass]="{'arrow_upward-up': item.expandedNesting}">
                expand_more
              </mat-icon>
            </button>
          </td>

          <!-- staticIcon -->
          <td *ngIf="tableConfig.staticIcon" class="td-icon" [ngClass]="tableConfig.staticIcon.class"
            [style.background-color]="item.rowBgColor">
            <mat-icon *ngIf="tableConfig.staticIcon.type == 'icon'" class="icon left" aria-label="icon">
              {{tableConfig.staticIcon.icon}}</mat-icon>
            <mat-icon *ngIf="tableConfig.staticIcon.type == 'svg'" svgIcon="{{ tableConfig.staticIcon.icon }}"
              class="icon left" aria-label="icon"></mat-icon>
            <i *ngIf="tableConfig.staticIcon.type == 'fontawesome' || tableConfig.staticIcon.type == 'phosphor'"
              class="icon left {{ tableConfig.staticIcon.icon }}" aria-label="icon"></i>
          </td>

          <!-- columns -->
          <td *ngFor="let info of tableConfig.tableInfos | filterByProperty:['hideInTable', '!=', true]"
            [ngClass]="{'primary' : info.primary, 'w50px': info.small, 'w100px': info.medium}"
            [style.background-color]="item.rowBgColor">
            <!-- TEMPLATE InfoValue -->
            <ng-container [ngTemplateOutlet]="InfoValue"
              [ngTemplateOutletContext]="{ info:info, item:item, nestedTableIndex:0 }">
            </ng-container>
          </td>

          <!-- additionalButtons -->
          <ng-container
            *ngIf="!multiselect && tableConfig.additionalButtons != null && tableConfig.additionalButtons.length > 0">
            <td *ngFor="let info of tableConfig.additionalButtons" [ngClass]="info.tdClass"
              [style.background-color]="item.rowBgColor">
              <button [ngClass]="info.class" (click)="log(item, info)"
                [ngStyle]="{'display': info.defaultHide && item.hideButtons == null ? 'none' : (item.hideButtons != null ? (item.hideButtons[info.clickFunction] ? 'none' : 'block') : 'block')}"
                [disabled]="info.defaultDisabled && item.disabledButtons == null ? true : (item.disabledButtons != null ? item.disabledButtons[info.clickFunction] : false)"
                matTooltip="{{ info.tooltip | translate }}" matTooltipPosition="above">
                <mat-icon
                  *ngIf="(item.iconButtons != null && item.iconButtons[info.clickFunction] != null) || info?.icon"
                  class="icon">
                  {{ item.iconButtons?.[info.clickFunction] != null ? item.iconButtons?.[info.clickFunction] :
                  info?.icon }}
                </mat-icon>
                <span *ngIf="info.label">{{ info.label | translate }}</span>
              </button>
            </td>
          </ng-container>

        </tr>

        <!-- isNestedTable -->
        <ng-container
          *ngIf="tableConfig.isNestedTable && tableConfig.nestedTableConfig != null && tableConfig.nestedTableConfig.variable != null && 
          item[tableConfig.nestedTableConfig.variable] != null && item[tableConfig.nestedTableConfig.variable].length > 0 && item.expandedNesting == true">

          <ng-container *ngFor="let nestedItem of item[tableConfig.nestedTableConfig.variable]">
            <tr class="nested-row" [ngClass]="{
              'clickable': tableConfig.selectable && !nestedItem.unselectable,
              'selected': nestedItem.selected,
              'critical': nestedItem.critical,
              'third-color': nestedItem.thirdColor,
              'subcritical': nestedItem.subcritical,
              'ff-disabled': nestedItem.unselectable
            }">

              <td class="w50px" *ngIf="!tableConfig.hideColumnsForExpander"></td>

              <!-- staticIcon -->
              <td *ngIf="tableConfig.staticIcon" class="td-icon" [ngClass]="tableConfig.staticIcon.class">
                <mat-icon *ngIf="tableConfig.staticIcon.type == 'icon'" class="icon left" aria-label="icon">
                  {{tableConfig.staticIcon.icon}}</mat-icon>
                <mat-icon *ngIf="tableConfig.staticIcon.type == 'svg'" svgIcon="{{ tableConfig.staticIcon.icon }}"
                  class="icon left" aria-label="icon"></mat-icon>
                <i *ngIf="tableConfig.staticIcon.type == 'fontawesome' || tableConfig.staticIcon.type == 'phosphor'"
                  class="icon left {{ tableConfig.staticIcon.icon }}" aria-label="icon"></i>
              </td>

              <!-- columns -->
              <td *ngFor="let info of tableConfig.tableInfos | filterByProperty:['hideInTable', '!=', true]"
                [ngClass]="{'primary' : info.primary, 'w50px': info.small, 'w100px': info.medium}">
                <!-- TEMPLATE InfoValue -->
                <ng-container [ngTemplateOutlet]="InfoValue"
                  [ngTemplateOutletContext]="{ info:info, item:nestedItem, nestedTableIndex:1 }">
                </ng-container>
              </td>

              <ng-container
                *ngIf="
              (tableConfig.nestedTableConfig.additionalButtons != null && tableConfig.nestedTableConfig.additionalButtons.length > 0) || 
              (tableConfig.additionalButtons != null && tableConfig.additionalButtons.length > 0 && !tableConfig.additionalButtonsOnonlyForPrimaryRow)">
                <td
                  *ngFor="let info of (tableConfig.nestedTableConfig.additionalButtons != null ? tableConfig.nestedTableConfig.additionalButtons : tableConfig.additionalButtons)"
                  [ngClass]="info.tdClass">
                  <button [ngClass]="info.class" (click)="log(nestedItem, info)"
                    [ngStyle]="{'display': info.defaultHide && nestedItem.hideButtons == null ? 'none' : (nestedItem.hideButtons != null ? (nestedItem.hideButtons[info.clickFunction] ? 'none' : 'block') : 'block')}"
                    [disabled]="info.defaultDisabled && nestedItem.disabledButtons == null ? true : (nestedItem.disabledButtons != null ? nestedItem.disabledButtons[info.clickFunction] : false)"
                    matTooltip="{{ info.tooltip | translate }}" matTooltipPosition="above">
                    <mat-icon
                      *ngIf="(nestedItem.iconButtons != null && nestedItem.iconButtons[info.clickFunction] != null) || info?.icon"
                      class="icon">
                      {{ nestedItem.iconButtons?.[info.clickFunction] != null ?
                      nestedItem.iconButtons?.[info.clickFunction] : info?.icon }}
                    </mat-icon>
                    <span *ngIf="info.label">{{ info.label | translate }}</span>
                  </button>
                </td>
              </ng-container>

            </tr>

            <ng-container *ngIf="
              tableConfig.nestedTableConfig.isNestedTable && 
              tableConfig.nestedTableConfig.nestedTableConfig != null &&
              tableConfig.nestedTableConfig.nestedTableConfig.variable != null && 
              nestedItem[tableConfig.nestedTableConfig.nestedTableConfig.variable] != null &&
              nestedItem[tableConfig.nestedTableConfig.nestedTableConfig.variable].length > 0 &&
              item.expandedNesting == true &&
              nestedItem.expandedNesting == true
             ">

              <ng-container
                *ngFor="let doubleNestedItem of nestedItem[tableConfig.nestedTableConfig.nestedTableConfig.variable]">

                <tr class="nested-row" [ngClass]="{
                 'clickable': tableConfig.nestedTableConfig.selectable && !doubleNestedItem.unselectable,
                 'selected': doubleNestedItem.selected,
                 'critical': doubleNestedItem.critical,
                 'third-color': doubleNestedItem.thirdColor,
                 'subcritical': doubleNestedItem.subcritical,
                 'ff-disabled': doubleNestedItem.unselectable
               }">

                  <!-- staticIcon -->
                  <td *ngIf="tableConfig.nestedTableConfig.staticIcon" class="td-icon"
                    [ngClass]="tableConfig.nestedTableConfig.staticIcon.class">
                    <mat-icon *ngIf="tableConfig.nestedTableConfig.staticIcon.type == 'icon'" class="icon left"
                      aria-label="icon">
                      {{tableConfig.nestedTableConfig.staticIcon.icon}}</mat-icon>
                    <mat-icon *ngIf="tableConfig.nestedTableConfig.staticIcon.type == 'svg'"
                      svgIcon="{{ tableConfig.nestedTableConfig.staticIcon.icon }}" class="icon left" aria-label="icon">
                    </mat-icon>
                    <i *ngIf="tableConfig.nestedTableConfig.staticIcon.type == 'fontawesome' || tableConfig.nestedTableConfig.staticIcon.type == 'phosphor'"
                      class="icon left {{ tableConfig.nestedTableConfig.staticIcon.icon }}" aria-label="icon"></i>
                  </td>

                  <!-- columns -->
                  <td *ngFor="let info of tableConfig.tableInfos | filterByProperty:['hideInTable', '!=', true]"
                    [ngClass]="{'primary' : info.primary, 'w50px': info.small, 'w100px': info.medium}">
                    <!-- TEMPLATE InfoValue -->
                    <ng-container [ngTemplateOutlet]="InfoValue"
                      [ngTemplateOutletContext]="{ info:info, item:doubleNestedItem, nestedTableIndex:2 }">
                    </ng-container>
                  </td>

                  <ng-container
                    *ngIf="
                      (tableConfig.nestedTableConfig.nestedTableConfig.additionalButtons != null && tableConfig.nestedTableConfig.nestedTableConfig.additionalButtons.length > 0) || 
                      (tableConfig.nestedTableConfig.additionalButtons != null && tableConfig.nestedTableConfig.additionalButtons.length > 0)">
                    <td
                      *ngFor="let info of (tableConfig.nestedTableConfig.nestedTableConfig.additionalButtons != null ? tableConfig.nestedTableConfig.nestedTableConfig.additionalButtons : tableConfig.nestedTableConfig.additionalButtons)"
                      [ngClass]="info.tdClass">
                      <button [ngClass]="info.class" (click)="log(doubleNestedItem, info)"
                        [ngStyle]="{'display': info.defaultHide && doubleNestedItem.hideButtons == null ? 'none' : (doubleNestedItem.hideButtons != null ? (doubleNestedItem.hideButtons[info.clickFunction] ? 'none' : 'block') : 'block')}"
                        [disabled]="info.defaultDisabled && doubleNestedItem.disabledButtons == null ? true : (doubleNestedItem.disabledButtons != null ? doubleNestedItem.disabledButtons[info.clickFunction] : false)"
                        matTooltip="{{ info.tooltip | translate }}" matTooltipPosition="above">
                        <mat-icon
                          *ngIf="(doubleNestedItem.iconButtons != null && doubleNestedItem.iconButtons[info.clickFunction] != null) || info?.icon"
                          class="icon">
                          {{ doubleNestedItem.iconButtons?.[info.clickFunction] != null ?
                          doubleNestedItem.iconButtons?.[info.clickFunction] : info?.icon }}
                        </mat-icon>
                        <span *ngIf="info.label">{{ info.label | translate }}</span>
                      </button>
                    </td>
                  </ng-container>

                </tr>


              </ng-container>
            </ng-container>

          </ng-container>

        </ng-container>

      </ng-container>
    </tbody>
  </table>

  <!-- value row -->
  <div *ngIf="tableConfig.widgetRow && tableConfig?.rowsFiltered.length > 0">
    <!-- cards -->
    <mat-card *ngFor="let item of tableConfig.rowsFiltered" class="widget-table-sortable mobile-card" [ngClass]="{
        'clickable': (tableConfig.selectable && !item.unselectable) || item.urlPath != null,
        'selectable': tableConfig.selectable && !item.unselectable,
        'selected': item.selected, 
        'critical': item.critical, 
        'third-color': item.thirdColor, 
        'subcritical': item.subcritical, 
        'ff-disabled': item.unselectable
      }" [routerLink]="item.urlPath" [queryParams]="item.urlQueryParams">
      <!-- fxFlex.sm="0 1 calc(50% - 1rem)" -->

      <!-- staticIcon -->
      <div *ngIf="tableConfig.staticIcon" class="icon-box transition-01" style="margin-right: 0.5rem">
        <mat-icon *ngIf="tableConfig.staticIcon.type == 'icon'" class="icon left" aria-label="icon">
          {{tableConfig.staticIcon.icon}}</mat-icon>
        <mat-icon *ngIf="tableConfig.staticIcon.type == 'svg'" svgIcon="{{ tableConfig.staticIcon.icon }}"
          class="icon left" aria-label="icon"></mat-icon>
        <i *ngIf="tableConfig.staticIcon.type == 'fontawesome' || tableConfig.staticIcon.type == 'phosphor'"
          class="icon left {{ tableConfig.staticIcon.icon }}" aria-label="icon"></i>
      </div>

      <!-- dynamicIcon -->
      <div *ngFor="let info of tableConfig.tableInfos | filterByProperty:['hideInTable', '!=', true]">
        <div *ngIf="info.type == 'icon'">
          <div class="box-icon" [ngClass]="getDynamicIconAttribute(info, item, 'class')" style="margin-top: .5rem">
            <mat-icon *ngIf="info.icon && info.icon.type == 'icon'" class="icon"
              [ngStyle]="{'color': getDynamicIconAttribute(info, item, 'color')}"
              translate-attr="{'aria-label': info.label}">
              {{info.icon.icon}}</mat-icon>
            <mat-icon *ngIf="info.icon && info.icon.type == 'svg'" svgIcon="{{ info.icon.icon }}"
              [ngStyle]="{'color': getDynamicIconAttribute(info, item, 'color')}" class="icon left"
              translate-attr="{'aria-label': info.label}"></mat-icon>
            <i *ngIf="info.icon && (info.icon.type == 'fontawesome' || info.icon.type == 'phosphor')"
              class="icon left {{ info.icon.icon }}" [ngStyle]="{'color': getDynamicIconAttribute(info, item, 'color')}"
              translate-attr="{'aria-label': info.label}"></i>
          </div>
        </div>
      </div>

      <div fxLayout="row">
        <div *ngFor="let info of tableConfig.tableInfos" class="info-box" style="padding: .5rem;" fxLayout="column"
          fxLayoutAlign="center start" [ngStyle]="{'min-width': (100/tableConfig.tableInfos.length) + '%'}">
          <span fxFlex="50%" fxLayoutAlign="center end" class="label widget">{{info.label | translate}}</span>
          <div fxFlex="50%" class="value widget">
            <ng-container [ngTemplateOutlet]="InfoValue"
              [ngTemplateOutletContext]="{ info:info, item:item, nestedTableIndex:0 }">
            </ng-container>
          </div>
        </div>
      </div>

    </mat-card>
  </div>

  <!-- paginatorBottom -->
  <table *ngIf="paginatorBottom && tableConfig?.rowsFiltered?.length" class="widget-table-sortable">
    <tbody>
      <tr style="border: none;">
        <td style="padding: 0.75rem 0 0;">
          <div fxLayout="row" fxLayoutAlign="{{paginatorAlign}}">

            <div class="select-box" fxFlexOrder="{{paginatorAlignOrderSelector}}"
              *ngIf="paginatorOptions != null && paginatorOptions?.length > 0">
              <div class="box" fxLayout="row" fxLayoutAlign="start center">
                <mat-form-field appearance="fill" class="w140">
                  <mat-label>{{'GLOBAL.ITEMS_PER_PAGE'|translate}}</mat-label>
                  <mat-select [(value)]="paginatorSize">
                    <mat-option *ngFor="let size of paginatorOptions" [value]="size" (click)="updatePageSize(size)">
                      {{size}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" fxFlexOrder="{{paginatorAlignOrderButtons}}">
              <div fxLayout="row" style="padding: 0 1rem;">
                <span class="page-counter">{{pageCounter}}</span>
              </div>
              <div fxLayout="row" fxLayoutGap="4px">
                <button (click)="firstPage()" [disabled]="previousPageDisabled()" mat-button fxLayout="row"
                  matTooltip="{{ 'GLOBAL.FIRST_PAGE' | translate }}" fxLayoutAlign="space-evenly center"
                  class="btn-pagArrow rounded-button md-light-gray exclude-transition">
                  <i class="fas fa-chevron-double-left"></i>
                </button>
                <button (click)="previousPage()" [disabled]="previousPageDisabled()" mat-button fxLayout="row"
                  matTooltip="{{ 'GLOBAL.PREVIOUS_PAGE' | translate }}" fxLayoutAlign="space-evenly center"
                  class="btn-pagArrow rounded-button md-light-gray exclude-transition">
                  <i class="fas fa-chevron-left"></i>
                </button>
                <button (click)="nextPage()" [disabled]="nextPageDisabled()" mat-button fxLayout="row"
                  matTooltip="{{ 'GLOBAL.NEXT_PAGE' | translate }}" fxLayoutAlign="space-evenly center"
                  class="btn-pagArrow rounded-button md-light-gray exclude-transition">
                  <i class="fas fa-chevron-right"></i>
                </button>
                <button (click)="lastPage()" [disabled]="nextPageDisabled()" mat-button fxLayout="row"
                  matTooltip="{{ 'GLOBAL.LAST_PAGE' | translate }}" fxLayoutAlign="space-evenly center"
                  class="btn-pagArrow rounded-button md-light-gray exclude-transition">
                  <i class="fas fa-chevron-double-right"></i>
                </button>
              </div>
            </div>

          </div>
        </td>
      </tr>
    </tbody>
  </table>

</mat-card>

<!-- mobile view -->
<div *ngIf="isMobile && !tableConfig.expanded" fxLayout="column">

  <!-- toolbar -->
  <div fxLayout="row wrap" fxLayoutAlign="start stretch" class="mobile-toolbar"
    *ngIf="tableConfig?.rowsFiltered?.length > 0">

    <mat-card fxLayout="row" fxLayoutAlign="start center">
      <!-- select-box -->
      <div class="select-box" style="padding: 0;" fxFlex fxLayout="row" fxLayoutAlign="start center">
        <div class="box w100" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'GLOBAL.SORT_BY' | translate }}</mat-label>
            <mat-select [ngModel]="tableConfig.sort.variable">
              <mat-option *ngFor="let sortVar of tableConfig.sort.sortVariables" [value]="sortVar.orderBy"
                (click)="sortByProperty(sortVar, true)">
                <span style="text-transform: capitalize;">{{ sortVar.label | translate }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- arrow -->
      <button mat-button class="box-icon md-primary sortArrow" style="margin-left: 0.5rem;"
        [disabled]="tableConfig.sort.variable ==null" (click)="sortByPropertyId(tableConfig.sort.variable)">
        <mat-icon *ngIf="!tableConfig.sort.variable" class="icon transition-01">
          horizontal_rule
        </mat-icon>

        <mat-icon *ngIf="tableConfig.sort.variable" class="icon transition-01"
          [ngClass]="{'arrow_forward-up': tableConfig.sort.order == 'asc', 'arrow_forward-down': tableConfig.sort.order == 'desc' }">
          arrow_forward
        </mat-icon>
      </button>
    </mat-card>

    <mat-card *ngIf="tableConfig?.rowsFiltered?.length" fxLayout="row" fxLayoutAlign="start center">
      <div fxLayout="row" fxLayoutAlign="center center" class="w100">

        <div fxLayout="column" class="select-box" style="padding: 0;">
          <div class="box" fxLayout="row" fxLayoutAlign="start center">
            <mat-form-field appearance="fill" class="w140">
              <mat-label>{{'GLOBAL.ITEMS_PER_PAGE'|translate}}</mat-label>
              <mat-select [(value)]="paginatorSize">
                <mat-option *ngFor="let size of paginatorOptions" [value]="size" (click)="updatePageSize(size)">
                  {{size}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <span fxFlex></span>

        <div fxLayout="column">
          <div fxLayout="row">
            <button (click)="previousPage()" [disabled]="previousPageDisabled()" mat-button fxLayout="row"
              fxLayoutAlign="space-evenly center" class="btn-pagArrow">
              <ff-icon icon="arrow_back"></ff-icon>
            </button>
            <span style="opacity: .75; padding: 0.8rem 0.1rem 0">&nbsp;{{ pageCounterMobile }}</span>
            <button (click)="nextPage()" [disabled]="nextPageDisabled()" mat-button fxLayout="row"
              fxLayoutAlign="space-evenly center" class="btn-pagArrow">
              <ff-icon icon="arrow_forward"></ff-icon>
            </button>
          </div>
        </div>
      </div>
    </mat-card>

  </div>

  <!-- no results -->
  <mat-card *ngIf="!tableConfig?.rowsFiltered?.length && !tableConfig.hideNoData" style="text-align: center;">
    <span>{{'GLOBAL.NO_DATA_AVAILABLE'|translate}}</span>
  </mat-card>

  <!-- cards -->
  <div *ngIf="!tableConfig?.fixedDesktopView">
    <mat-card *ngFor="let item of tableConfig.rowsFiltered | slice:limits[0]:limits[1];" (click)="toggleCollapse(item)"
      class="widget-table-sortable mobile-card transition-01 clickable" [ngClass]="{'opacity0':tbodyFade}">
      <!-- fxFlex.sm="0 1 calc(50% - 1rem)" -->

      <!-- staticIcon -->
      <div *ngIf="tableConfig.staticIcon" class="icon-box transition-01" style="margin-right: 0.5rem">
        <mat-icon *ngIf="tableConfig.staticIcon.type == 'icon'" class="icon left" aria-label="icon">
          {{tableConfig.staticIcon.icon}}</mat-icon>
        <mat-icon *ngIf="tableConfig.staticIcon.type == 'svg'" svgIcon="{{ tableConfig.staticIcon.icon }}"
          class="icon left" aria-label="icon"></mat-icon>
        <i *ngIf="tableConfig.staticIcon.type == 'fontawesome' || tableConfig.staticIcon.type == 'phosphor'"
          class="icon left {{ tableConfig.staticIcon.icon }}" aria-label="icon"></i>
      </div>

      <!-- dynamicIcon -->
      <div *ngFor="let info of tableConfig.tableInfos | filterByProperty:['hideInTable', '!=', true]">
        <div *ngIf="info.type == 'icon'">
          <div class="box-icon" [ngClass]="getDynamicIconAttribute(info, item, 'class')" style="margin-top: .5rem">
            <mat-icon *ngIf="info.icon && info.icon.type == 'icon'" class="icon"
              [ngStyle]="{'color': getDynamicIconAttribute(info, item, 'color')}"
              translate-attr="{'aria-label': info.label}">
              {{info.icon.icon}}</mat-icon>
            <mat-icon *ngIf="info.icon && info.icon.type == 'svg'" svgIcon="{{ info.icon.icon }}"
              [ngStyle]="{'color': getDynamicIconAttribute(info, item, 'color')}" class="icon left"
              translate-attr="{'aria-label': info.label}"></mat-icon>
            <i *ngIf="info.icon && (info.icon.type == 'fontawesome' || info.icon.type == 'phosphor')"
              class="icon left {{ info.icon.icon }}" [ngStyle]="{'color': getDynamicIconAttribute(info, item, 'color')}"
              translate-attr="{'aria-label': info.label}"></i>
          </div>
        </div>
      </div>

      <!-- columns -->

      <!-- columns -->
      <!-- <div fxFlex>
      <div *ngFor="let info of tableConfig.tableInfos" class="info-box transition-01"
        [ngClass]="{'collapsed': (!item.notCollapsed && tableConfig.sort.variable != null && tableConfig.sort.variable != info.variable) || (!item.notCollapsed && tableConfig.sort.variable == null && i != 0) }">
        <span class="label">{{info.label | translate}}</span>
        <div class="value">
          <ng-container [ngTemplateOutlet]="InfoValue" [ngTemplateOutletContext]="{ info:info, item:item }">
          </ng-container>
        </div>
      </div>
    </div> -->

      <!-- collapsed -->
      <div *ngIf="!item.notCollapsed" fxFlex>
        <div
          *ngFor="let info of tableConfig.tableInfos | filterByProperty: ['orderBy', '==', tableConfig.sort.variable ] | filterByProperty:['hideInTable', '!=', true]; let i = index;"
          class="info-box" style="padding: .5rem">
          <span class="label">{{info.label | translate}}</span>
          <div class="value">
            <ng-container [ngTemplateOutlet]="InfoValue"
              [ngTemplateOutletContext]="{ info:info, item:item, nestedTableIndex:0 }">
            </ng-container>
          </div>
        </div>
      </div>

      <!-- open -->
      <div *ngIf="item.notCollapsed" fxFlex>
        <div
          *ngFor="let info of tableConfig.tableInfos | filterByProperty: ['type', '!=', 'icon'] | filterByProperty:['hideInTable', '!=', true]; let i = index;"
          class="info-box" style="padding: .5rem;">
          <span class="label">{{info.label | translate}}</span>
          <div class="value">
            <ng-container [ngTemplateOutlet]="InfoValue"
              [ngTemplateOutletContext]="{ info:info, item:item, nestedTableIndex:0 }">
            </ng-container>
          </div>
        </div>

        <ng-container *ngIf="tableConfig.additionalButtons != null && tableConfig.additionalButtons.length > 0">
          <td *ngFor="let info of tableConfig.additionalButtons" [ngClass]="info.tdClass">
            <button [ngClass]="info.class" (click)="log(item, info)"
              [ngStyle]="{'display': info.defaultHide && item.hideButtons == null ? 'none' : (item.hideButtons != null ? (item.hideButtons[info.clickFunction] ? 'none' : 'block') : 'block')}"
              [disabled]="info.defaultDisabled && item.disabledButtons == null ? true : (item.disabledButtons != null ? item.disabledButtons[info.clickFunction] : false)"
              matTooltip="{{ info.tooltip | translate }}" matTooltipPosition="above">
              <mat-icon *ngIf="(item.iconButtons != null && item.iconButtons[info.clickFunction] != null) || info?.icon"
                class="icon">
                {{ item.iconButtons?.[info.clickFunction] != null ? item.iconButtons?.[info.clickFunction] : info?.icon
                }}
              </mat-icon>
              <span *ngIf="info.label">{{ info.label | translate }}</span>
            </button>
          </td>
        </ng-container>

      </div>


      <!-- chevron -->
      <div class="icon-box" style="margin-left: 0.5rem;" [@accordionArrowState]="item.notCollapsed">
        <!-- [ngClass]="{'chevron-up': item.notCollapsed, 'chevron-down': !item.notCollapsed}"> -->
        <mat-icon class="icon" aria-label="chevron"
          style="font-size: 2.5rem; height: fit-content; width: fit-content; margin-top: .5rem;">
          keyboard_arrow_down</mat-icon>
      </div>

    </mat-card>
  </div>

  <!-- table -->
  <div *ngIf="tableConfig?.fixedDesktopView">
    <table *ngIf="tableConfig?.rowsFiltered?.length && !tableConfig.widgetRow" class="widget-table-sortable">

      <thead>
        <tr>
          <!-- staticIcon -->
          <th *ngIf="tableConfig.staticIcon"></th>
          <!-- nestedColumn -->
          <th *ngIf="tableConfig.isNestedTable &&
          tableConfig.nestedTableConfig != null &&
          tableConfig.nestedTableConfig.variable != null" class="w50px">
          </th>
          <!-- columns -->
          <ng-container *ngFor="let info of tableConfig.tableInfos | filterByProperty:['hideInTable', '!=', true]">
            <th>
              <div fxLayout="row" fxLayoutAlign="start center">
                <div>
                  <span>{{ info.label | translate }}</span>
                  <span *ngIf="info.unitInTitle && info.unit != null">&nbsp;[{{ info.unit | translate }}]</span>
                </div>
              </div>
            </th>
          </ng-container>
        </tr>
      </thead>

      <tbody
        [ngClass]="{ 'opacity0': tbodyFade && !tableConfig.removeAnimations, 'transition-01': !tableConfig.removeAnimations }">
        <ng-container *ngFor="let item of tableConfig.rowsFiltered | slice:limits[0]:limits[1]">
          <tr [class]="item.rowClass" [ngClass]="{
              'clickable': (tableConfig.selectable && !item.unselectable) || item.urlPath != null,
              'selectable': tableConfig.selectable && !item.unselectable,
              'selected': item.selected, 
              'critical': item.critical, 
              'third-color': item.thirdColor, 
              'subcritical': item.subcritical, 
              'ff-disabled': item.unselectable
            }" (click)="clickRow(item)">

            <!-- nested column icon expander -->
            <td class="w50px"
              *ngIf="tableConfig.isNestedTable && tableConfig.nestedTableConfig != null && tableConfig.nestedTableConfig.variable != null">
              <button *ngIf="item != null &&
              item[tableConfig.nestedTableConfig.variable] != null &&
              item[tableConfig.nestedTableConfig.variable].length > 0" class="rounded-button md-light-gray"
                (click)="item.expandedNesting = !item.expandedNesting;">
                <mat-icon class="icon transition-01" [ngClass]="{'arrow_upward-up': item.expandedNesting}">
                  expand_more
                </mat-icon>
              </button>
            </td>

            <!-- staticIcon -->
            <td *ngIf="tableConfig.staticIcon" class="td-icon" [ngClass]="tableConfig.staticIcon.class">
              <mat-icon *ngIf="tableConfig.staticIcon.type == 'icon'" class="icon left" aria-label="icon">
                {{tableConfig.staticIcon.icon}}</mat-icon>
              <mat-icon *ngIf="tableConfig.staticIcon.type == 'svg'" svgIcon="{{ tableConfig.staticIcon.icon }}"
                class="icon left" aria-label="icon"></mat-icon>
              <i *ngIf="tableConfig.staticIcon.type == 'fontawesome'"
                class="icon left {{ tableConfig.staticIcon.icon }}" aria-label="icon"></i>
            </td>

            <!-- columns -->
            <td *ngFor="let info of tableConfig.tableInfos | filterByProperty:['hideInTable', '!=', true]"
              [ngClass]="{'primary' : info.primary, 'w50px': info.small, 'w100px': info.medium}">
              <!-- TEMPLATE InfoValue -->
              <ng-container [ngTemplateOutlet]="InfoValue"
                [ngTemplateOutletContext]="{ info:info, item:item, nestedTableIndex:0 }">
              </ng-container>
            </td>

            <ng-container *ngIf="tableConfig.additionalButtons != null && tableConfig.additionalButtons.length > 0">
              <td *ngFor="let info of tableConfig.additionalButtons" [ngClass]="info.tdClass">
                <button [ngClass]="info.class" (click)="log(item, info)"
                  [ngStyle]="{'display': info.defaultHide && item.hideButtons == null ? 'none' : (item.hideButtons != null ? (item.hideButtons[info.clickFunction] ? 'none' : 'block') : 'block')}"
                  [disabled]="info.defaultDisabled && item.disabledButtons == null ? true : (item.disabledButtons != null ? item.disabledButtons[info.clickFunction] : false)"
                  matTooltip="{{ info.tooltip | translate }}" matTooltipPosition="above">
                  <mat-icon
                    *ngIf="(item.iconButtons != null && item.iconButtons[info.clickFunction] != null) || info?.icon"
                    class="icon">
                    {{ item.iconButtons?.[info.clickFunction] != null ? item.iconButtons?.[info.clickFunction] :
                    info?.icon }}
                  </mat-icon>
                  <span *ngIf="info.label">{{ info.label | translate }}</span>
                </button>
              </td>
            </ng-container>

          </tr>

          <ng-container
            *ngIf="tableConfig.isNestedTable && tableConfig.nestedTableConfig != null && tableConfig.nestedTableConfig.variable != null && 
            item[tableConfig.nestedTableConfig.variable] != null && item[tableConfig.nestedTableConfig.variable].length > 0">
            <tr *ngFor="let nestedItem of item[tableConfig.nestedTableConfig.variable]" class="nested-row" [ngClass]="{
                'clickable': tableConfig.selectable && !nestedItem.unselectable,
                'selected': nestedItem.selected,
                'critical': nestedItem.critical,
                'third-color': nestedItem.thirdColor,
                'subcritical': nestedItem.subcritical,
                'ff-disabled': nestedItem.unselectable,
                'add-row-animation': item.expandedNesting == true,
                'remove-row-animation': item.expandedNesting == false,
                'do-not-show': item.expandedNesting == null
              }">

              <td class="w50px"></td>

              <!-- staticIcon -->
              <td *ngIf="tableConfig.staticIcon" class="td-icon" [ngClass]="tableConfig.staticIcon.class">
                <mat-icon *ngIf="tableConfig.staticIcon.type == 'icon'" class="icon left" aria-label="icon">
                  {{tableConfig.staticIcon.icon}}</mat-icon>
                <mat-icon *ngIf="tableConfig.staticIcon.type == 'svg'" svgIcon="{{ tableConfig.staticIcon.icon }}"
                  class="icon left" aria-label="icon"></mat-icon>
                <i *ngIf="tableConfig.staticIcon.type == 'fontawesome' || tableConfig.staticIcon.type == 'phosphor'"
                  class="icon left {{ tableConfig.staticIcon.icon }}" aria-label="icon"></i>
              </td>

              <!-- columns -->
              <td *ngFor="let info of tableConfig.tableInfos | filterByProperty:['hideInTable', '!=', true]"
                [ngClass]="{'primary' : info.primary, 'w50px': info.small, 'w100px': info.medium}">
                <!-- TEMPLATE InfoValue -->
                <ng-container [ngTemplateOutlet]="InfoValue"
                  [ngTemplateOutletContext]="{ info:info, item:nestedItem, nestedTableIndex:1 }">
                </ng-container>
              </td>

              <ng-container
                *ngIf="
                (tableConfig.nestedTableConfig.additionalButtons != null && tableConfig.nestedTableConfig.additionalButtons.length > 0) || 
                (tableConfig.additionalButtons != null && tableConfig.additionalButtons.length > 0 && !tableConfig.additionalButtonsOnonlyForPrimaryRow)">
                <td
                  *ngFor="let info of (tableConfig.nestedTableConfig.additionalButtons != null ? tableConfig.nestedTableConfig.additionalButtons : tableConfig.additionalButtons)"
                  [ngClass]="info.tdClass">
                  <button [ngClass]="info.class" (click)="log(nestedItem, info)"
                    [ngStyle]="{'display': info.defaultHide && nestedItem.hideButtons == null ? 'none' : (nestedItem.hideButtons != null ? (nestedItem.hideButtons[info.clickFunction] ? 'none' : 'block') : 'block')}"
                    [disabled]="info.defaultDisabled && nestedItem.disabledButtons == null ? true : (nestedItem.disabledButtons != null ? nestedItem.disabledButtons[info.clickFunction] : false)"
                    matTooltip="{{ info.tooltip | translate }}" matTooltipPosition="above">
                    <mat-icon
                      *ngIf="(nestedItem.iconButtons != null && nestedItem.iconButtons[info.clickFunction] != null) || info?.icon"
                      class="icon">
                      {{ nestedItem.iconButtons?.[info.clickFunction] != null ?
                      nestedItem.iconButtons?.[info.clickFunction] : info?.icon }}
                    </mat-icon>
                    <span *ngIf="info.label">{{ info.label | translate }}</span>
                  </button>
                </td>
              </ng-container>

            </tr>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>

<!-- TEMPLATE InfoValue -->
<ng-template #InfoValue let-info="info" let-item="item" let-nestedTableIndex="nestedTableIndex">

  <div *ngIf="!info.type && (info.variable != 'description' || item.eventType == null)" fxLayout="row"
    fxLayoutAlign="start center">

    <ff-icon style="margin-right: 4px;"
      *ngIf="info.iconVariableConfig != null && info.iconVariableConfig.position == 'before'"
      [icon]="info.iconVariableConfig.icon" [iconColor]="item[info.iconVariableConfig?.colorVariable]">
    </ff-icon>

    <div *ngIf="info.expander != null && info.expander == nestedTableIndex" fxLayout="row" fxLayoutAlign="start center"
      style="margin-right: 8px; margin-left: 2px;">
      <button *ngIf="item != null" class="rounded-button md-light-gray" (click)="toggleExpandRow(item)">
        <mat-icon class="icon transition-01" [ngClass]="{'arrow_upward-up': item.expandedNesting}">
          expand_more
        </mat-icon>
      </button>
    </div>

    <img *ngIf="info.imageVariable != null && !item?.noImage" class="clickable" (error)="item.noImage = true"
      [src]="parseImageSource(item, info)" style="height: 50px; border-radius: 12px;" (click)="openImage(item, info)">

    <span [style.text-overflow]="isMobile ? 'ellipsis' : 'none'">
      {{ item[info.variable] | shorten: (isMobile ? 25 : 4000) | placeholder:(info.placeholder != null ?
      info.placeholder : '-') }}
    </span>

    <span *ngIf="info.unit != null && !info.unitInTitle && (item.customUnit == null || !info.enableCustomUnit)">
      &nbsp;{{ filterService.convertUnit(info.unit, item[info.variable]).unit | translate }}
    </span>

    <span *ngIf="item.customUnit != null && info.enableCustomUnit">
      &nbsp;{{ filterService.convertUnit(item.customUnit).unit | translate }}
    </span>

    <mat-icon *ngIf="info.icon != null" class="icon {{item.iconClass}}" style="margin-left: 8px;">lens</mat-icon>

    <ff-icon style="margin-left: 4px;"
      *ngIf="info.iconVariableConfig != null && (info.iconVariableConfig.position == 'after' || info.iconVariableConfig.position == null)"
      [icon]="info.iconVariableConfig.icon" [iconColor]="item[info.iconVariableConfig?.colorVariable]">
    </ff-icon>

  </div>

  <div *ngIf="info.type == 'interval'">
    <span>
      {{
      item.timeStartP ?? filterService.parseMoment(item.timeStart, 'default')
      | placeholder:(info.placeholder ?? '-')
      }}
    </span>
    <br>
    <span>
      {{ item.timeEndP ?? filterService.parseMoment(item.timeEnd, 'default')
      | placeholder:(info.placeholder ?? '-')
      }}
    </span>
  </div>

  <div *ngIf="info.type == 'general'" fxLayout="row" fxLayoutAlign="start center">
    <span>{{ filterService.parseObjFromConfig(item, info, false, 'columnType', !info?.unitInTitle, true,
      tableConfig?.profile) | placeholder }}</span>
  </div>

  <div *ngIf="info.type == 'date'" fxLayout="row" fxLayoutAlign="start center">

    <div *ngIf="info.expander != null && info.expander == nestedTableIndex" fxLayout="row" fxLayoutAlign="start center"
      style="margin-right: 8px; margin-left: 2px;">
      <button *ngIf="item != null" class="rounded-button md-light-gray" (click)="toggleExpandRow(item)">
        <mat-icon class="icon transition-01" [ngClass]="{'arrow_upward-up': item.expandedNesting}">
          expand_more
        </mat-icon>
      </button>
    </div>

    <span>{{
      (item[info.variable] != null ?
      filterService.parseMoment(item[info.variable], (info.format ?? 'default'), (info?.timezone ? item?.timezone :
      null)) :
      null) | placeholder:(info.placeholder != null ? info.placeholder : '-') }}</span><br>
  </div>

  <div *ngIf="info.type == 'configFromProfile'" fxLayout="row" fxLayoutAlign="start center">

    <ff-icon style="margin-right: 4px;"
      *ngIf="info.iconVariableConfig != null && info.iconVariableConfig.position == 'before'"
      [icon]="info.iconVariableConfig.icon" [iconColor]="item[info.iconVariableConfig?.colorVariable]">
    </ff-icon>

    <span>{{ mapFromProfile(info, item) | placeholder:(info.placeholder != null ? info.placeholder : '-') }}</span>
    <span *ngIf="info.unit != null && !info.unitInTitle">&nbsp;{{ info.unit | translate }}</span>

    <ff-icon style="margin-left: 4px;"
      *ngIf="info.iconVariableConfig != null && (info.iconVariableConfig.position == 'after' || info.iconVariableConfig.position == null)"
      [icon]="info.iconVariableConfig.icon" [iconColor]="item[info.iconVariableConfig?.colorVariable]">
    </ff-icon>
  </div>

  <div *ngIf="info.type == 'value'">
    <span>{{ item[info.variable] != null ? filterService.parseGaugeValue(filterService.convertUnit(info.unit,
      item[info.variable]).value, info.decimals, info.multiplier) : '-' }}</span>
    <span *ngIf="info.unit != null && !info.unitInTitle && (item.customUnit == null || !info.enableCustomUnit)">&nbsp;{{
      filterService.convertUnit(info.unit, item[info.variable]).unit | translate }}</span>
    <span *ngIf="item.customUnit != null && info.enableCustomUnit">&nbsp;{{
      filterService.convertUnit(item.customUnit).unit | translate }}</span>
  </div>

  <div *ngIf="info.type == 'time'">
    <span>{{ item[info.variable] != null ? filterService.parseTime(item[info.variable], 's', (info.format != null ?
      info.format : 'HH:mm:ss')) : '-' }}</span>
    <span *ngIf="info.unit != null && !info.unitInTitle">&nbsp;{{ info.unit | translate }}</span>
  </div>

  <div *ngIf="info.type == 'progressBar'">

    <kpi-bar
      *ngIf="info.fixedColor == null && (info.placeholder == null || (info.placeholder != null && item[info.variable] != null))"
      [kpiProps]="{ 'value': item[info.variable], 'hideLabel': true, 'revert': info.revert, 'options': info.options }">
    </kpi-bar>

    <kpi-bar
      *ngIf="info.fixedColor != null && (info.placeholder == null || (info.placeholder != null && item[info.variable] != null))"
      [kpiProps]="{ 'value': item[info.variable], 'hideLabel': true, 'fixedColor': info.fixedColor, 'revert': info.revert, 'options': info.options }">
    </kpi-bar>

  </div>

  <div *ngIf="info.type == 'icon' && info.icon != null && info.icon.icon != null">
    <div class="box-icon fixed32" [ngClass]="getDynamicIconAttribute(info, item, 'class')"
      matTooltip="{{ getDynamicIconAttribute(info, item, 'label') | translate }}">
      <ff-icon [icon]="info.icon" [ngStyle]="{'color': getDynamicIconAttribute(info, item, 'color')}"></ff-icon>
    </div>
  </div>

  <div *ngIf="info.type == 'iconWithClassAsVariable' && info.icon != null && info.icon.icon != null">
    <div class="box-icon fixed32">
      <ff-icon [icon]="info.icon" [iconClass]="item[info.variable]"></ff-icon>
    </div>
  </div>

  <div *ngIf="info.type == 'iconVariable' && item[info.variable] != null && item[info.variable].icon != null"
    matTooltip="{{ (item[info.variable].label ?? item[info.variable].title) | translate }}" fxLayout="row"
    fxLayoutAlign="center center" [class.clickable]="item.iconClickFunction != null"
    (click)="item.iconClickFunction != null ? log(item, item.iconClickFunction) : null">
    <ff-icon [icon]="item[info.variable].icon" [big]="info.biggerIcon"></ff-icon>
  </div>

  <div
    *ngIf="info.type == 'multipleIconsVariable' && info?.config?.loopOnProfileArray?.key != null && tableConfig?.profile[info.config.loopOnProfileArray.key]?.length > 0"
    fxLayout="row" fxLayoutAlign="center center">

    <div *ngFor="let elem of tableConfig.profile[info.config.loopOnProfileArray.key]">

      <ff-icon *ngIf="item[elem.outputId] == true" [icon]="elem?.icon"
        matTooltip="{{ (elem?.tooltip != null ? elem.tooltip : '-') | translate }}">
      </ff-icon>

    </div>

  </div>

  <div *ngIf="info.type == 'link'" matTooltip="{{ 'CYCLES.NAVIGATE_TO_item_DETAIL' | translate }}" class="clickable"
    [routerLink]="item[info.variable] !=null ? item[info.variable] : item.urlArray">
    <mat-icon class="icon left">open_in_new</mat-icon>
  </div>

  <div *ngIf="info.type == 'checkbox'" fxLayout="row" fxLayoutAlign="start center" class="clickable"
    (click)="toggleCheckbox(item, info, nestedTableIndex); log(item, info, nestedTableIndex)">

    <mat-icon *ngIf="item[info.variable]" svgIcon="radio_button" class="icon small left radio_button">
    </mat-icon>
    <mat-icon *ngIf="!item[info.variable]" svgIcon="radio_button_disabled"
      class="icon small left radio_button_disabled">
    </mat-icon>

  </div>

  <div *ngIf="info.type == 'image'" fxLayout="row" fxLayoutAlign="start center">
    <img *ngIf="item[info.variable] != null" [src]="'assets/images/' + item[info.variable]"
      style="height: 50px; border-radius: 12px;">
  </div>

  <div *ngIf="info.type == 'imageVariable'" fxLayout="row" fxLayoutAlign="start center">
    <img *ngIf="!item?.noImage" class="clickable" (error)="item.noImage = true" [src]="parseImageSource(item, info)"
      style="height: 50px; border-radius: 12px;" (click)="openImage(item, info)">
  </div>

  <div *ngIf="info.type == 'table' && item[info.variable] !=null && item[info.variable].length > 0" class="td-table">
    <table class="table-in-td" class="widget-table-sortable">
      <tr *ngFor="let item of item[info.variable]; let i=index">
        <td *ngIf="item !=null" style="padding: 0 0.25rem !important;">
          <span>{{item.label | translate}}</span>
        </td>
        <td *ngIf="item !=null" style="padding: 0 0.25rem !important;">
          <span>{{item.value}}</span>
          <span *ngIf="item.unit !=null">&nbsp;{{item.unit}}</span>
        </td>
      </tr>
    </table>
  </div>

  <!-- LIST COLUMN -->
  <span *ngIf="(item.eventType == 'colorList' || item.eventType == 'list') && info.variable == 'description'"
    ng-class="{'minWidthListLarge': mobile()}" class="{{info.classes}}">
    <ul class="ff-ul">
      <div *ngIf="item.eventType == 'colorList'">
        <li *ngFor="let state of item.description" style="padding: 4px 0;">
          <span class="color-square" [style.background-color]="state.color"
            style="margin: 0 8px 0 0; padding: 0 9px;"></span>
          <span>{{ state.value | translate }}</span>
        </li>
      </div>
      <div *ngIf="item.eventType == 'list'">
        <li *ngFor="let state of item.description">
          <span>{{ state }}</span>
        </li>
      </div>
    </ul>
  </span>

  <span *ngIf="info.type == 'timeState'">
    <div *ngIf="item[info.variable] != null" fxLayout="row" fxLayoutAlign="center center"
      [style.background-color]="item[info.variable].bgColor!= null ? item[info.variable].bgColor : '#FFFFFF'"
      [style.color]="item[info.variable].fontColor != null ? item[info.variable].fontColor : '#373A3F'"
      class="state-container">
      <span>{{item[info.variable]?.value | translate }}</span>
    </div>
  </span>

  <span *ngIf="info.type == 'stateUI'">
    <div *ngIf="item[info.variable] != null" fxLayout="row" fxLayoutAlign="center center"
      class="state-container {{item[info.variable]?.class}}">
      <ff-icon *ngIf="item[info.variable]?.icon != null" [icon]="item[info.variable]?.icon" left></ff-icon>
      <span>{{item[info.variable]?.title | translate }}</span>
    </div>
  </span>

  <div class="td-list"
    *ngIf="info.type == 'listToSplit' && item[info.variable] != null && item[info.variable]?.split(info.variableToSplit)?.length > 0">
    <ul style="list-style: none; padding: 0;">
      <li *ngFor="let li of item[info.variable]?.split(info.variableToSplit)">
        <span>{{ li }}</span>
      </li>
    </ul>
  </div>

  <div *ngIf="info.type == 'singleCellList' && item[info.variable]?.length" class="padding0"
    [class.td-list]="item[info.variable]?.length > 1">
    <ul style="list-style: none; padding: 0;">
      <li *ngFor="let li of item[info.variable]; let last = last"
        [class.pb-4]="item[info.variable]?.length > 1 && !last">

        <ng-container [ngTemplateOutlet]="InfoValue"
          [ngTemplateOutletContext]="{ info:transformTypeToListType(info), item:transformListValueToObject(info.variable, li), nestedTableIndex:0 }">
        </ng-container>

      </li>
    </ul>
  </div>

  <div *ngIf="info.type == 'list' && item[info.variable] != null && item[info.variable].length > 0" class="td-list">
    <ul style="list-style: none; padding: 0;">
      <li *ngFor="let li of item[info.variable]; let i=index">
        <!-- <div *ngIf="item[info.variable + '_max'] && i < item[info.variable + '_max']"> -->
        <span *ngIf="li != null && li.label != null"><strong>{{li.label | translate}}: </strong></span>
        <span *ngIf="li != null && li.value !=null">{{li.value}}</span>
        <span *ngIf="li != null && li.label == null && li.value == null">{{ li }}</span>
        <span *ngIf="li != null && li.unit !=null">&nbsp;{{li.unit}}</span>
        <!-- </div> -->
      </li>
    </ul>
  </div>

  <div *ngIf="info.type == 'objectList' && item[info.variable] != null && item[info.variable].length > 0"
    class="td-list">
    <ul style="list-style: none; padding: 0;">
      <ng-container *ngFor="let li of item[info.variable]; let i=index">
        <li *ngIf="i < (info.maxItemsToShow != null ? info.maxItemsToShow : 20)">
          <span>{{ li[info.keyToShow != null ? info.keyToShow : 'label'] }}</span>
        </li>
        <li *ngIf="i == (info.maxItemsToShow != null ? (info.maxItemsToShow - 1) : 19)">...</li>
      </ng-container>
    </ul>
  </div>

  <div class="input-box" *ngIf="info.type == 'inputText'"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <input matInput class="ff-input text" type="text" [matAutocomplete]="auto" [(ngModel)]="item[info.variable]">
    <!-- <span *ngIf="info.hasOwnProperty('unit')" matSuffix>{{ info.unit }}</span> -->
    <mat-autocomplete #auto="matAutocomplete">
      <div fxLayout="row" fxLayoutAlign="center center" *ngFor="let option of info.autocomplete; let i = index"
        [attr.data-index]="i">
        <mat-option style="padding-right: 0px" fxFlex [value]="option">
          <div>{{ option | translate }}</div>
        </mat-option>
        <!-- <span fxFlex></span> -->
      </div>
    </mat-autocomplete>
  </div>

  <div *ngIf="info.type == 'closedList'" class="ff-input-select w100" fxLayout="row" fxLayoutAlign="start center">
    <mat-select [(value)]="item[info.variable]" [disabled]="info.listOptions?.length == 0"
      (selectionChange)="log(item, info, nestedTableIndex)">

      <mat-option *ngIf="info.addNullValue" [value]="null">---</mat-option>

      <ng-container *ngIf="info.closedListType == null">
        <mat-option *ngFor="let opt of info.listOptions" [value]="opt">
          {{ opt }}
        </mat-option>
      </ng-container>

      <ng-container *ngIf="info.closedListType == 'arrayOfObjects' && info.listRowByRow == null">
        <mat-option *ngFor="let opt of info.listOptions" [value]="opt.id">
          {{ filterService.translateElementNewFormat(opt) }}
        </mat-option>
      </ng-container>

      <ng-container *ngIf="info.closedListType == 'arrayOfObjects' && info.listRowByRow != null">
        <mat-option *ngFor="let opt of item.listOptions" [value]="opt.id">
          {{ filterService.translateElementNewFormat(opt) }}
        </mat-option>
      </ng-container>

    </mat-select>
  </div>

  <div *ngIf="info.type == 'advancedTranslate'" fxLayout="row" fxLayoutAlign="start center">

    <ff-icon style="margin-right: 4px;"
      *ngIf="info.iconVariableConfig != null && info.iconVariableConfig.position == 'before'"
      [icon]="info.iconVariableConfig.icon" [iconColor]="item[info.iconVariableConfig?.colorVariable]">
    </ff-icon>

    <span>{{ advancedTranslate(info?.advancedTranslateConfig, item) | placeholder:(info.placeholder != null ?
      info.placeholder : '-') }}</span>

    <span *ngIf="info.unit != null && !info.unitInTitle">&nbsp;{{ info.unit | translate }}</span>

    <mat-icon *ngIf="info.icon != null" class="icon {{item.iconClass}}" style="margin-left: 8px;">lens</mat-icon>

    <ff-icon style="margin-left: 4px;"
      *ngIf="info.iconVariableConfig != null && (info.iconVariableConfig.position == 'after' || info.iconVariableConfig.position == null)"
      [icon]="info.iconVariableConfig.icon" [iconColor]="item[info.iconVariableConfig?.colorVariable]">
    </ff-icon>

  </div>

  <div *ngIf="info.type == 'led'">
    <mat-icon class="icon"
      [ngStyle]="{'color': item?.[info.variable] ? (info.trueColor ?? '#62E39D') : (info.falseColor ?? '#FF5757') }">
      lens
    </mat-icon>
  </div>

  <div *ngIf="info.type == 'immutableCheckbox'">
    <input *ngIf="info.placeholder != '' || item[info.variable] != null" type="checkbox" style="transform: scale(1.2)"
      class="ff-disabled completely" [checked]="filterService.parseBool(item[info.variable])" />
  </div>

  <div *ngIf="info.type == 'inputNum'" class="input-box"
    [attr.data-ff-suffix]="info.unit != null && !info.unitInTitle ? info.unit : ''">
    <input matInput class="ff-input number" type="number" [min]="0" [(ngModel)]="item[info.variable]"
      (keyup)="onInputTagChange(tableConfig.rowsFiltered)">
  </div>

  <div *ngIf="info.type == 'inputDateTime'">
    <input matInput class="ff-input datetime" type="datetime-local" [(ngModel)]="item[info.variable]">
  </div>

  <div *ngIf="info.type == 'inputDuration'">
    <ff-duration-input format="HH:mm" unit="s" [(value)]="item[info.variable]"></ff-duration-input>
  </div>

</ng-template>