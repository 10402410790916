<!-- table toolbar -->
<mat-card fxLayout="column" style="margin-top: 8px;" *ngIf="reelTable?.data?.filteredRows?.length > 0">
  <div fxLayout="row" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="20">
      <ui-switch [checked]="reelTable.paramSwitch.checked" (change)="reelSwitchChange('paramSwitch', $event)"
        checkedLabel="{{reelTable.paramSwitch.checkedLabel}}" uncheckedLabel="{{reelTable.paramSwitch.uncheckedLabel}}">
      </ui-switch>
      <div style="margin-left: 8px;">
        <span *ngIf="reelTable.paramSwitch.checked">{{ 'REEL_DETAILS.ONLY_CHANGED_PARAMETERS' | translate
          }}</span>&nbsp;
        <span *ngIf="!reelTable.paramSwitch.checked">{{ 'REEL_DETAILS.ALL_PARAMETERS' | translate }}</span>&nbsp;
      </div>
    </div>

    <!-- <div fxLayout="row" fxLayoutAlign="start center" fxFlex="20">
      <ui-switch [checked]="reelTable.recipeSwitch.checked" (change)="reelSwitchChange('recipeSwitch', $event)"
        checkedLabel="{{reelTable.recipeSwitch.checkedLabel}}"
        uncheckedLabel="{{reelTable.recipeSwitch.uncheckedLabel}}">
      </ui-switch>
      <div style="margin-left: 8px;">
        <span *ngIf="reelTable.recipeSwitch.checked">{{ 'REEL_DETAILS.RECIPE_AUTO_SET' | translate }}</span>&nbsp;
        <span *ngIf="!reelTable.recipeSwitch.checked">{{ 'REEL_DETAILS.RECIPE_SET' | translate }}</span>&nbsp;
      </div>
    </div> -->

    <span fxFlex></span>

    <div *ngIf="removeBTN" fxLayout="row" fxLayoutAlign="start center">
      <button mat-button class="filter-button md-red" (click)="removeFilters()" fxLayout="row"
        fxLayoutAlign="space-evenly center">
        <mat-icon class="icon">close</mat-icon>
        <span>{{'REEL_DETAILS.REMOVE_FILTERS' | translate}}</span>
      </button>
    </div>

    <div fxLayout="row" fxLayoutAlign="start center">
      <button mat-button class="filter-button" (click)="openFilterDialog()" fxLayout="row"
        fxLayoutAlign="space-evenly center">
        <mat-icon class="icon">filter_alt</mat-icon>
        <span>{{'REEL_DETAILS.GROUP_SELECTION' | translate}}</span>
      </button>
    </div>

    <!-- search -->
    <!-- <span style="width: 100px;"></span> -->
    <div class="search-input" fxLayout="row" fxLayoutAlign="start center" style="min-width: 200px;">
      <mat-icon class="icon">search</mat-icon>
      <input type="text" [(ngModel)]="reelTable.search" (keyup)="filterTableData()"
        placeholder="{{'GLOBAL.SEARCH'|translate}}">
    </div>
  </div>
</mat-card>

<!-- table -->
<mat-card fxLayout="column" style="margin-top: 8px;" *ngIf="reelTable?.data?.filteredRows?.length > 0">

  <!-- <pre>{{ reelTable?.data?.filteredRows?.length | json }}</pre> -->

  <table class="standard-table">
    <thead>
      <tr>
        <th *ngFor="let header of reelTable.data.headers" style="text-align: center;">
          <div [class.recipe-set-header]="header.class != null" fxLayout="row" fxLayoutAlign="center center">
            <span>{{ !header.label ? header : header.label | translate }}</span>
            <mat-icon *ngIf="header.tooltip" class="icon" matTooltip="{{header.tooltip}}" style="margin-left: 8px;">
              info</mat-icon>
          </div>
        </th>
      </tr>
    </thead>
    <tbody *ngIf="reelTable.data.filteredRows && reelTable.data.filteredRows.length > 0">
      <tr *ngFor="let row of reelTable.data.filteredRows | slice: startPage : endPage">
        <td *ngFor="let col of row.values" [ngStyle]="{'min-width': col.isBefore ? '140px' : 'unset'}">

          <!-- LABEL COLUMN -->
          <div class="label-table-square-one" [ngClass]="col.class" style="text-align: center;">
            <p class="value">
              <span class="break-word">{{ col.value | placeholder:'-' }}</span>
              <span *ngIf="col.unit != '' && col.unit != null" class="break-word">&nbsp;[{{ col.unit }}]</span>
            </p>
          </div>

        </td>

      </tr>
    </tbody>
  </table>

  <mat-paginator #paginator *ngIf="reelTable.data.filteredRows?.length > 0"
    [length]="reelTable.data.filteredRows.length" [pageSizeOptions]="reelTable.data.query.limitOptions"
    [pageSize]="reelTable.data.query.limit" showFirstLastButtons>
  </mat-paginator>

</mat-card>

<!-- no results -->
<mat-card fxLayout="column" style="margin-top: 8px; text-align: center;"
  *ngIf="reelTable?.data?.filteredRows?.length == 0">
  <span>{{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}</span>
</mat-card>