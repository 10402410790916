import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import WebViewer from '@pdftron/pdfjs-express';
// import WebViewer from '@pdftron/webviewer';
import { Subscription, timer } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { CacheService } from 'src/app/services/cache.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { environment } from 'src/environments/environment';
import xml2js from 'xml2js';

@Component({
  selector: 'edit-pdf-dialog',
  templateUrl: './edit-pdf-dialog.component.html',
  styleUrls: ['./edit-pdf-dialog.component.scss']
})
export class EditPdfDialogComponent implements OnInit, OnDestroy {

  @ViewChild('viewer', { static: false }) viewer: ElementRef;
  @ViewChild('loading', { static: false }) loadingElement: ElementRef;

  wvInstance: any;
  pdfFile: any;

  pollingSubscription: Subscription;

  loadingData: any = {
    message: this.translate.instant("LOADING.PDF"),
  };

  triggerFalseClick() { this.loadingElement.nativeElement.click() }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<EditPdfDialogComponent>,
    public apiService: ApiService,
    public cacheService: CacheService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public internalDataService: InternalDataService,
  ) {
    dialogRef.disableClose = true;
  }

  async ngOnInit() {

    // If end FAT/SAT is in progress, disable all actions if the file is in the folder FAT/SAT
    let disabledDueToEndFatInProgress = this.dialog?.row?.disabledDueToEndFatInProgress;

    // Keep clicking on the title because for some reason the iframe does not update if a continuous clicking is not enabled
    this.pollingSubscription = timer(0, 100).subscribe((count) => this.triggerFalseClick());

    // Set loading state
    this.dialog.state = 0;

    // Get .pdf file content from BE
    let url: any = '/apif/machine-documentation/get-file-content/' + this.dialog.machineId;
    if (this.dialog?.buttonInfos?.endpointUrl) url = this.dialog.buttonInfos.endpointUrl.replaceAll("{machineId}", this.dialog.machineId);
    let payload: any = { filePath: this.dialog?.row?.completePath };

    let resp: any = null;
    try { resp = await this.apiService.asyncDownload(url, payload, null, "arraybuffer") }
    catch (error) { console.log(error) }

    // If the request fails, show error message and don't show pdf viewer
    if (resp == null) {
      this.dialog.state = 2;
      return;
    };

    // PDF file content
    this.pdfFile = resp?.body;

    // HTML element
    let element = this.viewer.nativeElement;

    // Init _this
    let _this = this;

    // Init WebViewer config
    let config: any = {
      licenseKey: environment.pdfjs.licenseKey,
      path: "../lib",
      annotationUser: this.cacheService.get("user")?.user ?? 'Unknown',
      disableFlattenedAnnotations: true,
      disabledElements: elemsToHide,
      extension: 'pdf',
    };

    // // // // // // // // // // // //
    // Init PDF viewer
    // // // // // // // // // // // //

    WebViewer(config, element)
      .then(async instance => {

        // PDF JS EXPRESS
        let documentViewer = instance.docViewer;
        let annotationManager = instance.annotManager;
        // PDFTRON
        // Import document viewer and annotation manager
        // const { documentViewer, annotationManager } = instance.Core;

        // Transform pdf arrayBuffer to blob
        let fileToDownload = this.filterService.arrayBufferToBlob(this.pdfFile);

        // Load blob
        // PDF JS EXPRESS
        instance.loadDocument(fileToDownload, { filename: this.dialog.row?.name });

        // PDFTRON
        // instance.UI.loadDocument(fileToDownload, { filename: this.dialog.row?.name });

        this.wvInstance = instance;

        documentViewer.addEventListener('documentLoaded', async () => {

          // PDFTRON
          // this.wvInstance.disableElements(["thumbnailControl", "documentControl", "pageManipulationOverlay"]);

          _this.loadingData = {
            message: _this.translate.instant("LOADING.PDF_ANNOTATIONS")
          };

          // Once the document is loaded, set the minimum height to inherit
          try {
            let allWebviewers: any = document.querySelectorAll(`[id^="webviewer"]`);
            allWebviewers?.forEach(wv => {
              wv.style.minHeight = "inherit";
              wv.style.borderBottomLeftRadius = "20px";
              wv.style.borderBottomRightRadius = "20px";
              wv.style.display = "block";
            }
            );
          } catch (error) { }

          // MindSphere get iotfile url
          let filePath = 'https://gateway.eu1.mindsphere.io/api/iotfile/v3/files/' + this.dialog.machineId + '/' + this.dialog.row?.completePath;

          // Extract annotations
          let url: any = '/apif/machine-documentation/extract-pdf-annotations';

          let payload = {
            fileUrl: filePath,
            license: environment.pdfjs.serverKey,
          };

          let resp = null;
          try { resp = await this.apiService.sendPostRequestAwait(url, payload) }
          catch (error) { console.log(error) }

          let xfdf = resp?.body?.xfdf;

          // If the annotations are null, show error message and don't show pdf viewer
          if (xfdf == null) {
            this.dialog.state = 2;
            return;
          };

          // Flatten annotations
          // const xfdfString = await annotationManager.exportAnnotations({ links: false });
          // await annotationManager.deleteAnnotations(annotationManager.getAnnotationsList());
          // await annotationManager.importAnnotations(xfdfString);

          // Import annotations
          try { annotationManager.importAnnotations(xfdf) }
          catch (error) { console.log(error) }

          // Set dark theme
          this.wvInstance.setTheme("dark");

          // When the files are NOT in the FAT and SAT folders, enable all interactions
          if (!disabledDueToEndFatInProgress) {
            // Enable hidden elements
            this.wvInstance.enableElements(elemsToHide);

            // Open right and left panel
            setTimeout(() => this.wvInstance.openElements(['notesPanel', "thumbnailsPanel"]), 500);

            // Create save button
            this.wvInstance.setHeaderItems((header) => {

              header.push(
                {
                  type: 'actionButton',
                  img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
                  onClick: async () => _this.onSave(annotationManager, filePath, _this.dialog.row?.completePath)
                },
                // {
                //   type: 'actionButton',
                //   img: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm-5 16c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-10H5V5h10v4z"/></svg>',
                //   onClick: async () => _this.flattenPDF(documentViewer, annotationManager, filePath, _this.dialog.row?.completePath)
                // }
              );
            });
          }

          // When the files are in the FAT and SAT folders, disable all interactions
          else this.wvInstance.disableElements(["header", "leftPanel"]);

          this.onDocLoad();

        })

      })

  }

  async onSave(annotManager, filePath, completePath) {

    // Set loading
    this.dialog.state = 0;

    // Update size
    this.dialogRef.updateSize('auto');

    // Set loading message
    this.loadingData = {
      message: this.translate.instant("LOADING.SAVING_PDF")
    }

    let xfdf = null;

    let _this = this;

    // Export annotations
    try { xfdf = await annotManager.exportAnnotations({ links: false, widgets: false }) }
    catch (error) {
      console.log(error);
      this.dialog.state = 2;
      return;
    }

    // Manipulate annotations to find:
    // - total number of annotations
    // - last update
    // - last user update
    try {

      let parser = new xml2js.Parser(
        {
          trim: true,
          explicitArray: true
        }
      );
      parser.parseString(xfdf, async function (err, result) {
        let annotations = result?.xfdf?.annots?.[0];

        if (annotations == null || annotations == "") annotations = {}

        let numberOfAnnotations = (Object.values(annotations) as any)?.reduce((acc, val) => {
          acc += val?.length;
          return acc;
        }, 0);

        let url: any = '/apif/machine-documentation/update-annotations/' + _this.dialog.machineId;
        if (_this.dialog?.buttonInfos?.updateAnnotationsEndpointUrl) url = _this.dialog.buttonInfos.updateAnnotationsEndpointUrl.replaceAll("{machineId}", _this.dialog.machineId);

        let payload: any = {
          user: _this.cacheService.get("user")?.user ?? "Unknown",
          numberOfAnnotations: numberOfAnnotations,
          filePath: completePath,
        };

        let query = {};
        query = _this.filterService.addStandardInformations(query);

        try { await _this.apiService.sendPostRequestAwait(url, payload, query) }
        catch (error) { console.log(error) }

      });

    } catch (error) { console.log(error) }

    // Update pdf file with new annotations
    let url: any = '/apif/machine-documentation/upload-pdf-with-annotations/' + this.dialog.machineId;
    if (this.dialog?.buttonInfos?.uploadFileEndpointUrl) url = this.dialog.buttonInfos.uploadFileEndpointUrl.replaceAll("{machineId}", this.dialog.machineId);

    let payload = {
      xfdf: xfdf,
      fileUrl: filePath,
      filePath: completePath,
      license: environment.pdfjs.serverKey,
    };

    let query = {};
    query = this.filterService.addStandardInformations(query);

    let respPost: any = null;
    try {
      respPost = await this.apiService.asyncDownload(url, payload, query);
      this.internalDataService.openSnackBar(
        this.translate.instant("FILE_EXPLORER.PDF_UPDATE_RESPONSE.SUCCESS"),
        'right',
        'bottom',
        4000,
        '',
        ['success']
      );
    } catch (error) {
      console.log(error);
      this.internalDataService.openSnackBar(
        this.translate.instant("FILE_EXPLORER.PDF_UPDATE_RESPONSE.FAIL"),
        'right',
        'bottom',
        4000,
        '',
        ['fail']
      );

    }

    this.dialogRef.close();

  }

  async onDocLoad() {
    this.dialog.state = 1;
    this.dialogRef.updateSize('90%');
  }

  // async flattenPDF(documentViewer, annotManager, filePath, completePath) {

  //   // // Set loading
  //   this.dialog.state = 0;

  //   // // Update size
  //   // this.dialogRef.updateSize('auto');

  //   // // Set loading message
  //   // this.loadingData = {
  //   //   message: this.translate.instant("LOADING.SAVING_PDF")
  //   // }

  //   // let xfdf = null;

  //   // let _this = this;

  //   // // Flatten annotations
  //   // const xfdfString = await annotManager.exportAnnotations({ links: false });
  //   // await annotManager.deleteAnnotations(annotManager.getAnnotationsList());
  //   // // await annotManager.importAnnotations(xfdfString);

  //   // // const xfdfString = await annotManager.exportAnnotations({ links: false });

  //   // console.log(xfdfString);

  //   const doc = documentViewer.getDocument();
  //   const xfdfString = await annotManager.exportAnnotations();
  //   const options = { xfdfString, flatten: true };
  //   const data = await doc.getFileData(options);
  //   const arr = new Uint8Array(data);
  //   const blob = new Blob([arr], { type: 'application/pdf' });
  //   console.log({ blob });

  //   let payload = new FormData();

  //   console.log(completePath);
  //   console.log({ payload });

  //   payload.append(completePath, blob);

  //   let query = {};
  //   query = this.filterService.addStandardInformations(query);

  //   let filesResp = null;
  //   let url: any = '/apif/internal-documentation/upload-files/' + this.dialog.machineId;

  //   try {
  //     filesResp = await this.apiService.sendCustomPayloadWithArrayBuffer(url, payload, query);
  //   } catch (e) {
  //     console.log(e);

  //   }

  //   // setTimeout(() => {

  //   //   this.dialog.state = 1;
  //   // }, 500);
  //   // return payload

  //   // let url: any = '/apif/internal-documentation/flatten-annotations/' + this.dialog.machineId;
  //   // // if (this.dialog?.buttonInfos?.uploadFileEndpointUrl) url = this.dialog.buttonInfos.uploadFileEndpointUrl.replaceAll("{machineId}", this.dialog.machineId);

  //   // let payload = {
  //   //   xfdf: xfdf,
  //   //   fileUrl: filePath,
  //   //   filePath: completePath,
  //   //   license: environment.pdfjs.serverKey,
  //   // };

  //   // let query = {};
  //   // query = this.filterService.addStandardInformations(query);

  //   // let respPost: any = null;
  //   // try {
  //   //   respPost = await this.apiService.asyncDownload(url, payload, query);
  //   //   this.internalDataService.openSnackBar(
  //   //     this.translate.instant("FILE_EXPLORER.PDF_UPDATE_RESPONSE.SUCCESS"),
  //   //     'right',
  //   //     'bottom',
  //   //     4000,
  //   //     '',
  //   //     ['success']
  //   //   );
  //   // } catch (error) {
  //   //   console.log(error);
  //   //   this.internalDataService.openSnackBar(
  //   //     this.translate.instant("FILE_EXPLORER.PDF_UPDATE_RESPONSE.FAIL"),
  //   //     'right',
  //   //     'bottom',
  //   //     4000,
  //   //     '',
  //   //     ['fail']
  //   //   );

  //   // }

  //   this.dialogRef.close();
  // }

  close() { this.dialogRef.close() }

  ngOnDestroy() {
    try { this.pollingSubscription.unsubscribe() } catch (error) { }
  }

}


const elemsToHide = [
  "leftPanelButton",
  "viewControlsButton",
  "panToolButton",
  "selectToolButton",
  "fitButton",
  "zoomOutButton",
  "zoomInButton",
  "freeHandToolGroupButton",
  "freeHandToolButton",
  "freeHandToolButton2",
  "freeHandToolButton3",
  "freeHandToolButton4",
  "textToolGroupButton",
  "highlightToolButton",
  "highlightToolButton2",
  "highlightToolButton3",
  "highlightToolButton4",
  "underlineToolButton",
  "squigglyToolButton",
  "strikeoutToolButton",
  "shapeToolGroupButton",
  "rectangleToolButton",
  "ellipseToolButton",
  "lineToolButton",
  "arrowToolButton",
  "polylineToolButton",
  "polygonToolButton",
  "cloudToolButton",
  "eraserToolButton",
  "signatureToolGroupButton",
  "freeTextToolButton",
  "stickyToolButton",
  "miscToolGroupButton",
  "calloutToolButton",
  "stampToolGroupButton",
  "rubberStampToolGroupButton",
  "fileAttachmentToolGroupButton",
  "cropToolButton",
  "toolsButton",
  "searchButton",
  "menuButton",
  "notesPanelButton",
  "notesPanel",
  "outlinesPanelButton",
  "outlinesPanel",
  "thumbnailsPanel",
  "searchPanel",
  "defaultPageTransitionButton",
  "continuousPageTransitionButton",
  "singleLayoutButton",
  "doubleLayoutButton",
  "coverLayoutButton",
  "rotateCounterClockwiseButton",
  "rotateClockwiseButton",
  "menuOverlay",
  "fullScreenButton",
  "downloadButton",
  "pageNavOverlay",
  "toolsOverlay",
  "searchOverlay",
  "annotationCommentButton",
  "annotationStyleEditButton",
  "annotationDeleteButton",
  "annotationCropButton",
  "annotationRedactButton",
  "annotationGroupButton",
  "annotationUngroupButton",
  "calibrateButton",
  "linkButton",
  "fileAttachmentDownload",
  "annotationStylePopup",
  "toolStylePopup",
  "stylePopup",
  "textPopup",
  "copyTextButton",
  "textHighlightToolButton",
  "textUnderlineToolButton",
  "textSquigglyToolButton",
  "textStrikeoutToolButton",
  "contextMenuPopup",
  "panToolButton",
  "stickyToolButton",
  "highlightToolButton",
  "freeHandToolButton",
  "freeTextToolButton",
  "signatureModalCloseButton",
  "signatureModalClearButton",
  "signatureModalSignButton",
  "inkSignaturePanelButton",
  "textSignaturePanelButton",
  "imageSignaturePanelButton",
  "loadingModal",
  "errorModal",
  "passwordModal",
]