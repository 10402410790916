import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';

@Component({
  selector: 'app-import-excel-dialog',
  templateUrl: './import-excel-dialog.component.html',
  styleUrls: ['./import-excel-dialog.component.scss']
})
export class ImportExcelDialogComponent implements OnInit {

  // @ViewChild('jobForm') jobForm!: NgForm;

  ngOnInit(): void {
  }

  public errorData: any = {
    type: 0,
    status: 500,
    message: "GLOBAL.EXCEL_ERROR"
  };

  public dialogState: any = 1;
  public fileCopy: any;
  public srcResult: any;
  public fileInput: any;
  public fileInfos: any;

  constructor(
    public apiService: ApiService,
    public http: HttpClient,
    public configs: AppConfigService,
    public dialogRef: MatDialogRef<ImportExcelDialogComponent>,
    public clonerService: ClonerService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialog: any) {

    this.dialogState = 1;
    dialogRef.disableClose = true;

    // console.log(this.localData);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER
  onFileSelected() {

    this.dialogState = 0;

    const inputNode: any = document.querySelector('#file');

    if (typeof (FileReader) !== 'undefined') {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        // setTimeout(() => {

        this.srcResult = e.target.result;
        this.dialogState = 1;

        this.fileInfos = inputNode.files[0];

        this.fileInfos.sizeP = this.parseFileSize(this.fileInfos.size)

        // }, 500);

      };

      reader.readAsArrayBuffer(inputNode.files[0]);

    }
  }

  parseFileSize(size: any) {
    if (size > 0) {

      if (size > 1000000)
        return Math.round(size / 1000000) + ' MB';
      else if (size > 1000)
        return Math.round(size / 1000) + ' kB';
      else
        return size + ' B';
    }
    return 'File is empty';
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async sendExcelFile() {

    this.dialogState = 0;

    let resp = null;
    let responseCode = 200;

    let endpointUrl = this.dialog.buttonInfos?.endpointUrl?.replaceAll("{machineId}", this.dialog.machineId);

    try {
      resp = await this.apiService.sendPostRequestAwait(endpointUrl, this.srcResult, { tz: this.dialog.tz });
      responseCode = resp.status;
      this.dialogState = 1;

      this.dialogRef.close(this.srcResult);

    } catch (error) {
      console.log(error);
      responseCode = error.status;
      this.dialogState = 2;
    }




  }
}