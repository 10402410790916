<div mat-dialog-title fxLayout="row" fxLayoutAlign="start center" class="w100">
  <mat-icon class="icon">edit</mat-icon>
  <span>{{ dialog.title }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" translate-attr="{'aria-label': item.title}">close</mat-icon>
  </button>
</div>

<mat-dialog-content>

  <div fxLayout="column">

    <!-- Aspect -->
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="dialog.addMachineSelection">

      <span style="width: 150px;">{{ 'RBA.MACHINE' | translate }}</span>

      <div class="ff-input-select larger">
        <mat-select [(value)]="currentParameter.machine" (selectionChange)="resetAll()">
          <mat-option *ngFor="let aspect of dialog.currentParameter.list | keyvalue" [value]="aspect.key">
            <span>{{ aspect.key.split("_{assetId}_")[1] }}</span>
          </mat-option>
        </mat-select>
      </div>

      <div class="search-input" fxLayout="row" fxLayoutAlign="center center"
        style="cursor: default; opacity: 0; user-select: none;">
        <mat-icon class="icon">search</mat-icon>
        <input type="text" disabled>
      </div>

    </div>

    <div class="divider" *ngIf="dialog.addMachineSelection"></div>

    <!-- Aspect -->
    <div fxLayout="row" fxLayoutAlign="start center">

      <span style="width: 150px;">{{ 'RBA.ASPECT' | translate }}</span>

      <div class="ff-input-select larger">
        <mat-select [(value)]="currentParameter.aspect" (selectionChange)="resetVariable()"
          [disabled]="currentParameter.machine == null && dialog.addMachineSelection">
          <mat-option *ngFor="let aspect of currentParameter.filteredList | keyvalue" [value]="aspect.key">
            <!-- <span>{{ aspect.key.split("_{assetId}_")[0] }}</span> -->
            <span>{{ internalDataService.parseDatapointLabel(aspect.key.split("_{assetId}_")[0], "aspectName",
              dialog.machineId, true) }}</span>

          </mat-option>
        </mat-select>
      </div>

      <div class="search-input" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="icon">search</mat-icon>
        <input type="text" [(ngModel)]="searchAspect" (keyup)="updateAspectSearch(searchAspect)"
          placeholder="{{'RBA.SEARCH_ASPECTS'|translate}}">
      </div>

    </div>

    <div class="divider"></div>

    <!-- Variable -->
    <div fxLayout="row" fxLayoutAlign="start center">

      <span style="width: 150px;">{{ 'RBA.VARIABLE' | translate }}</span>

      <div class="ff-input-select larger">
        <mat-select [(value)]="currentParameter.variable" [disabled]="currentParameter.aspect == null">
          <mat-option *ngFor="let variable of currentParameter.filteredList[currentParameter.aspect]"
            [value]="variable">
            <!-- <span>{{ ('DATAPOINTS.' + dialog.machineId + '.' + currentParameter.aspect + '.' + variable) | translate }}</span> -->
            <span>{{ internalDataService.parseDatapointLabel(currentParameter.aspect, variable, dialog.machineId, true)
              }}</span>
            <!-- <span>{{ ('DATAPOINTS.' + dialog.machineId + '.' + currentParameter.aspect + '.' + variable) | checkTranslation:(variable) }}</span> -->
          </mat-option>
        </mat-select>
      </div>

      <div class="search-input" fxLayout="row" fxLayoutAlign="center center">
        <mat-icon class="icon">search</mat-icon>
        <input type="text" [(ngModel)]="searchVariable" (keyup)="updateVariableSearch(searchVariable)"
          placeholder="{{'RBA.SEARCH_VARIABLE'|translate}}">
      </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="start center" style="margin-top: 24px; font-size: 0.8rem;">
      <mat-icon class="icon left">info</mat-icon>
      <span>{{ 'RBA.INFO___' | translate }}</span>
      <!-- <span>&nbsp;{{ ('DATAPOINTS.' + currentParameter.aspect + '.' +currentParameter.variable) | translate }}</span> -->
    </div>

  </div>

</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" style="margin-right: 12px;">
  <button class="filter-button" (click)="closeDialog()"
    [disabled]="currentParameter.aspect == null || currentParameter.aspect == '' || currentParameter.variable == null || currentParameter.variable == ''">
    {{ 'GLOBAL.CONFIRM' | translate }}
  </button>
</mat-dialog-actions>