export const environment = {
  production: true,
  pdfjs: {
    // serverKey: null,
    // licenseKey: null,
    // clientKey: null,
    licenseKey: "74QkQ84ujadWbXcNMWpf",
    clientKey: "oEajY5WqBOvkBZyLInvr",
    serverKey: "D5Kox4bgZwzjsFDUD5Kh",
  }
};