import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'ff-synoptic-time-machine',
  templateUrl: './synoptic-time-machine.component.html',
  styleUrls: ['./synoptic-time-machine.component.scss']
})
export class SynopticTimeMachineComponent implements OnInit {

  @Input() widget: any;

  public innerWidth: any;
  public mobileListener: any;

  constructor(
    public internalDataService: InternalDataService,
    public translate: FfTranslateService,
    public dialog: MatDialog,
    public mobile: MobileService
  ) {
    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => this.innerWidth = value.innerWidth);
  }

  dpClick(dp: any, tableHeaderDialog?: boolean) {
    // console.log(dp)
    if (dp != null) {
      if (dp.action != null) {
        this.internalDataService.setDatapoint(dp);
      } else if (tableHeaderDialog && dp.type == 'table' && dp.datapointList != null) {
        this.internalDataService.setDatapoint(dp);
      }
    }
  }

  datapointId(index, datapoint) {
    return datapoint.datapoint;
  }

  calculateTop(datapoint) {
    let elem = document.getElementById(datapoint?.datapoint);
    return 'calc(' + datapoint.top + '% - ' + elem.offsetHeight / 2 + 'px)';
  }

  calculateLeft(datapoint) {
    let elem = document.getElementById(datapoint?.datapoint);
    return 'calc(' + datapoint.left + '% - ' + elem.offsetWidth / 2 + 'px)';
  }

  ngOnInit() { }

  ngOnChanges() { }

  ngOnDestroy() { this.mobileListener.unsubscribe() }

}
