import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';

import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { CustomIntervalDialogComponent } from 'src/app/components/interval-selector/custom-interval-dialog/custom-interval-dialog.component';

@Component({
  selector: 'app-multiple-periods',
  templateUrl: './multiple-periods.component.html',
  styleUrls: ['./multiple-periods.component.scss']
})
export class MultiplePeriodsComponent implements OnInit, OnDestroy {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public productionColumns: string[] = [];

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public maxStringsLength: any;

  public aggrDropdown: any = null;

  public aggregations: any;
  public aggregationsPayload: any;
  public aggregationsCustom: any;

  public consIcon: any = null;
  public consDropdown: any = null;

  public excludeLine: boolean = true;

  public interval: any;
  public interval2: any;
  public intervalConfig: any;
  public intervalConfig2: any;
  public intervalAggregations: any;

  public dashboardConfig: any;

  public aggrList: any;
  public aggrId: any;

  public consData: any;
  public colors: any = [
    '#66DA26', '#546E7A', '#E91E63', '#FF9800',
    "#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D",
    "#33B2DF", "#D4526E", "#13D8AA", "#A5978B",
    "#4ECDC4", "#C7F464", "#81D4FA", "#FD6A6A",
    "#2B908F", "#F9A3A4", "#90EE7E", "#FA4443",
    "#449DD1", "#F86624", "#EA3546", "#662E9B",
    "#D7263D", "#1B998B", "#2E294E", "#F46036",
    "#F9C80E", "#43BCCD", "#69D2E7", '#008FFB',
    "#5C4742", "#8D5B4C", "#5A2A27", "#C4BBAF",
    "#A300D6", "#7D02EB", "#5653FE", "#2983FF",
    "#C5D86D", "#E2C044", "#00B1F2",
  ];

  public sectionName: any = 'consumptionAnalytics';
  public dashboardName: any = 'multiple-periods';

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getEventsBreakdowns, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['CONSUMPTION_ANALYTICS.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    try {
      this.aggrList = this.appConfig[this.sectionName].aggrList;
    } catch (error) { }

    try {
      this.aggrId = this.appConfig[this.sectionName].aggrListDefault;
    } catch (error) { }

    console.log(this.aggrList, this.aggrId);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.interval = this.intervalService.getIntervalById('thisWeek');
    this.interval2 = this.intervalService.getIntervalById('lastWeek');

    this.interval.startF = this.intervalService.strFormat(this.interval, this.interval.start) + ' - ' + this.intervalService.strFormat(this.interval, this.interval.end);
    this.interval2.startF = this.intervalService.strFormat(this.interval2, this.interval2.start) + ' - ' + this.intervalService.strFormat(this.interval2, this.interval2.end);

    this.intervalConfig = {
      list: this.intervalService.getDefaultIntervals(2),
      selected: this.interval
    };

    this.intervalConfig2 = {
      list: this.intervalService.getDefaultIntervals(2),
      selected: this.interval2
    };

    this.consData = null

  }

  customTranslate(machine: any) {
    if (machine === 'Line') {
      return this.translate.instant('GLOBAL.LINE')
    }
    else {
      return machine
    }
  }
  openAggrDialog(aggr: any) {

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        // console.log('afterClosed', result);
        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
      }

      this.pageState.next(isClickedSelect ? 5 : 6);

      this.getEvents(this, 0);

    });
  };

  changePageAggregation(aggrDropdown: any) {

    this.aggrDropdown = this.clonerService.deepClone(aggrDropdown);

    this.pageState.next(5);
    this.getEvents(this, 0);
  }

  changeConsumable(consDropdown: any) {

    this.consDropdown = this.clonerService.deepClone(consDropdown);

    if (this.aggregations == null || this.aggregations.list == null || this.aggregations.list.length == 0) {
      try {
        let consIdx = this.machine.profile.consumables.findIndex((x: any) => x.id == this.consDropdown);
        if (consIdx != -1 && this.machine.profile.consumables[consIdx].icon != null) this.consIcon = this.machine.profile.consumables[consIdx].icon;
        if (consIdx != -1 && this.machine.profile.consumables[consIdx].hasOwnProperty('aggregations') &&
          this.machine.profile.consumables[consIdx].aggregations != null &&
          this.machine.profile.consumables[consIdx].aggregations.length > 0) {
          this.aggregationsCustom = this.machine.profile.consumables[consIdx].aggregations;
          this.aggrDropdown = this.aggregationsCustom.findIndex((x: any) => x.id == this.aggrDropdown) != -1 ? this.aggrDropdown : null;
        } else {
          this.aggregationsCustom = null;
          this.aggrDropdown = null;
        }
      } catch (error) {

      }
    }

    this.pageState.next(5);
    this.getEvents(this, 0);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any, periodNumber: any) {

    console.log('interval', interval);

    if (interval != null) {

      if (interval.id == 'custom') {
        // console.log(interval);

        let filtersDialog = this.dialog.open(CustomIntervalDialogComponent, {
          panelClass: 'ff-dialog',
          disableClose: true,
          data: {
            title: this.translate.instant('INTERVAL.CUSTOM')
          },
        });

        filtersDialog.afterClosed().subscribe((result: any) => {
          // console.log('afterClosed', result);
          if (result != null && result != '') {

            interval.start = JSON.parse(JSON.stringify(result.interval.value.start));
            interval.end = moment(result.interval.value.end).endOf("day");
            interval.end = JSON.parse(JSON.stringify(interval.end));
            interval.startF = this.intervalService.strFormat(interval, interval.start) + ' - ' + this.intervalService.strFormat(interval, interval.end);

            if (periodNumber != 2) {
              this.interval = interval;
              this.intervalConfig.selected = interval;
            } else {
              this.interval2 = interval;
              this.intervalConfig2.selected = interval;
            }
            this.pageState.next(5);
            this.getEvents(this, 0);

          }
        });

      } else {

        this.interval = interval;

        this.pageState.next(5);
        this.getEventsBreakdowns(this);
      }
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval aggr
  selectAggregation(aggr: any) {

    if (aggr != null) {

      this.intervalAggregations.selected = aggr;

      this.pageState.next(5);
      this.getEventsBreakdowns(this);

    }
  }

  machineSelectionChange(machine: any) {

    if (machine != null) {

      this.pageState.next(5);
      this.getEventsBreakdowns(this);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // events

  // Event
  getEventsBreakdowns(_this: any) {
    try {

      _this.getEvents(_this, 0);

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getEvents(_this: any, count?: any) {
    try {

      let url = '/apif/consumption-monitoring/complete-consumption/' + _this.machineId;

      if (count == 0 && _this.aggrList != null && _this.aggrList.length > 0) {
        _this.internalDataService.forceAggregationList(_this, _this.aggrList);
      }

      let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      if (_this.consDropdown == null) {
        try {
          _this.consDropdown = _this.clonerService.deepClone(_this.machine.profile.consumables)[0].id;
          _this.consIcon = _this.clonerService.deepClone(_this.machine.profile.consumables)[0].icon;
        } catch (error) {
          console.log(error);
        }
      }

      if (_this.aggregations == null || _this.aggregations.length == 0) {
        try {
          let consIdx = _this.machine.profile.consumables.findIndex((x: any) => x.id == _this.consDropdown);
          if (consIdx != -1 && _this.machine.profile.consumables[consIdx].hasOwnProperty('aggregations') &&
            _this.machine.profile.consumables[consIdx].aggregations != null &&
            _this.machine.profile.consumables[consIdx].aggregations.length > 0) {
            _this.aggregationsCustom = _this.clonerService.deepClone(_this.machine.profile.consumables)[consIdx].aggregations;
          } else {
            _this.aggregationsCustom = null;
          }
        } catch (error) {
          console.log(error);
        }
      }

      _this.consDatas = [];

      [0, 1].forEach((period: any) => {

        // console.log(_this.interval);
        // let interval2Start = "2021-07-20T22:00:00.000Z";
        // let interval2End = "2021-07-23T22:00:00.000Z";

        let query: any = {
          from: period == 0 ? _this.interval.start : _this.interval2.start,
          tz: _this.machine.timezone,
          unit: _this.intervalAggregations.selected.unit != null ? _this.intervalAggregations.selected.unit : 'hour',
          value: _this.intervalAggregations.selected.value != null ? _this.intervalAggregations.selected.value : 1,
          periods: _this.machine.profile.lastPeriods != null ? _this.machine.profile.lastPeriods : 3,
        };

        query.consumable = _this.consDropdown;

        if (_this.aggrDropdown != null) {
          query.aggrUnit = _this.aggrDropdown;
        }

        if (period == 1 && _this.interval2 != null && !_this.interval2.enabledPolling) query.to = _this.interval2.end;
        if (period == 0 && _this.interval != null && !_this.interval.enabledPolling) query.to = _this.interval.end;

        _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

        let consSettings = _this.machine.profile.consumables.find((x: any) => x.id == _this.consDropdown);
        let consUnit = consSettings != null ? consSettings.unit : '-';
        let productionUnit = _this.translate.instant("PRODUCTION_UNITS.CYCLE");

        _this.apiService.sendPostRequest(url, payload, query)
          .pipe(
            retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
            catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error))
          )
          .subscribe(
            (data: any) => {
              // console.log(data);
              let consData = Object.assign(data.body, { index: period });
              _this.consDatas.push(consData);

              if (_this.consDatas.length == 2) {

                _this.consDatas.sort(this.filterService.sortByProperty("index", 'asc', true));
                try {

                  _this.consData = Object.assign(data.body, { faults: [] });;
                  _this.parseData(_this, _this.consDatas);
                  _this.consData.dataConfig = {};
                  _this.consData.aggrDataConfig = {};

                  // set plot data and configs
                  _this.consData.dataConfig.title = _this.internalDataService.buildExportFileTitle(_this.breadcrumb, _this.interval);
                  _this.consData.dataConfig.plotDataAttribute = 'plotData';
                  _this.consData.aggrDataConfig.plotDataAttribute = 'aggrPlotData';

                  _this.consData.plotData = _this.buildPlotConfig(_this, _this.consDatas);
                  _this.consData.aggrPlotData = _this.buildAggrPlotConfig(_this, _this.consDatas);

                  // set table data and config
                  _this.consData.dataConfig.tableDataAttribute = 'faults';

                  _this.consData.tableInfo = _this.clonerService.deepClone(_this.appConfig.consumptionAnalytics.completeConsumption);

                  _this.consData.tableColumns = [];
                  _this.consData.tableInfo.forEach((element: any) => {
                    _this.consData.tableColumns.push(element.variable);
                  });

                  // Add consumable unit
                  try {
                    _this.consData.tableInfo.forEach((conf: any) => {
                      if (conf.consUnit) conf.suffix = consUnit;
                      if (conf.relativeUnit) conf.suffix += ('/' + productionUnit);
                    });
                  } catch (error) {
                    console.log(error);
                  }

                  try {
                    if (_this.aggrDropdown != null) {
                      let currentAggregationList: any = _this.clonerService.deepClone(_this.aggregationsCustom != null ? _this.aggregationsCustom : _this.aggregations);
                      if (_this.consData.tableInfo.findIndex((x: any) => x.variable == "aggregation") == -1) _this.consData.tableInfo.push({
                        variable: "aggregation",
                        orderBy: "aggregation",
                        label: _this.translate.instant(currentAggregationList.find((x: any) => x.id == _this.aggrDropdown).label).capitalize(),
                      });
                      if (!_this.consData.tableColumns.includes("aggregation")) _this.consData.tableColumns.push("aggregation");
                    }
                  } catch (error) {
                    console.log(error);
                  }

                } catch (error) {
                  console.log(error);
                }

                if (count == 0) {
                  console.log(_this.consDatas);
                  _this.dispatcherService.getDispatch(_this, 300);
                }

              }

            }
          );
      })

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseData(_this: any, data: any) {

    try {

      let dashboardCopy = _this.clonerService.deepClone(_this.dashboardConfigUnparsed);
      let consSettings = _this.machine.profile.consumables.find((x: any) => x.id == _this.consDropdown);
      let consUnit = consSettings != null ? consSettings.unit : '-';

      dashboardCopy.widgets.filter((x: any) => x.objectVariable != null).forEach((widget: any) => {

        widget.config = [
          {
            "label": _this.translate.instant("COMPLETE_CONSUMPTION.FIRST_PERIOD"),
            "variable": widget.objectVariable + "$$first",
            "decimals": widget.objectVariable == 'rel' ? 2 : 0
          },
          {
            "label": _this.translate.instant("COMPLETE_CONSUMPTION.SECOND_PERIOD"),
            "variable": widget.objectVariable + "$$second",
            "decimals": widget.objectVariable == 'rel' ? 2 : 0
          }
        ];

        let customConsUnit = consUnit;

        if (widget.objectVariable == 'rel') customConsUnit += ('/' + _this.translate.instant("PRODUCTION_UNITS.CYCLE"));
        if (widget.objectVariable == 'avg') customConsUnit += ('/' + (_this.intervalAggregations.selected.unit != null ? _this.intervalAggregations.selected.unit : 'hour').substring(0, 1));

        widget.config.forEach((conf: any, idx: any) => {

          try {

            let classes: any = {
              suffix: customConsUnit,
              class: 'md-light-gray',
            };

            _this.consData[conf.variable] = data[idx][widget.objectVariable].actual != null ? (data[idx][widget.objectVariable].actual * (consSettings.multiplier != null ? consSettings.multiplier : 1)) : null;

            if (idx == 0) {
              classes = Object.assign(classes, data[0][widget.objectVariable].actual >= data[1][widget.objectVariable].actual ? {
                icon2Class: 'md-red',
                icon2: {
                  icon: "arrow_up",
                  type: "svg"
                }
              } : {
                icon2Class: 'md-green',
                icon2: {
                  icon: "arrow_down",
                  type: "svg"
                }
              });
            }

            conf = Object.assign(conf, classes);
          } catch (error) {
            console.log(error);
          }

        });
      });
      _this.dashboardConfig = _this.clonerService.deepClone(dashboardCopy);

    } catch (error) {
      console.log(error);
    }
  }

  buildPlotConfig(_this: any, data: any) {

    let traces: any = [];

    let aggrId: any = _this.aggrDropdown;
    let consId: any = _this.consDropdown;
    let currentAggrConfig: any = null;

    try {
      currentAggrConfig = aggrId != null ?
        (_this.aggregations != null && _this.aggregations.length > 0
          ? _this.aggregations.find((x: any) => x.id == _this.aggrDropdown)
          : _this.aggregationsCustom.find((x: any) => x.id == _this.aggrDropdown))
        : null;
    } catch (error) { }

    let aggrTransl: any = null;

    try {
      if (currentAggrConfig != null && currentAggrConfig.hasOwnProperty('singleLabel')) {
        aggrTransl = currentAggrConfig.singleLabel;
      }
    } catch (error) { }

    let consSettings = _this.machine.profile.consumables.find((x: any) => x.id == consId);
    let productionUnit = _this.translate.instant("PRODUCTION_UNITS.CYCLE");

    if (data != null && data.length > 0) {

      data.forEach((period: any, idx: any, periods: any) => {

        // let colorArray = aggrId != null ? data.faults.map((x: any) => x[aggrId]).filter((val: any, id: any, array: any) => array.indexOf(val) == id) : [];

        if (period == null || !period.hasOwnProperty('tracks') || period.tracks.length == 0) {
          return;
        }

        period.tracks.forEach((track: any, trackIdx: any) => {

          track.aggrUnitLabel = aggrTransl != null ? _this.translate.instant(aggrTransl + '.' + track.aggrUnitId) : track.aggrUnitId;

          if (track.timestamps != null && track.timestamps.length > 0) {
            track.timestamps.filter((ts: any) => _this.consData.faults.findIndex((x: any) => x.timestamp == ts) == -1).forEach((ts: any, tsIdx: any) => {

              _this.consData.faults.push({
                relConsumptionP: (track.hasOwnProperty('rel') && track.rel[tsIdx] != null) ? _this.filterService.parseGaugeValue(track.rel[tsIdx], consSettings.decimals, consSettings.multiplier) : null,
                totConsumptionP: (track.hasOwnProperty('tot') && track.tot[tsIdx] != null) ? _this.filterService.parseGaugeValue(track.tot[tsIdx], consSettings.decimals, consSettings.multiplier) : null,
                prodTimeP: (track.hasOwnProperty('prodTime') && track.prodTime[tsIdx] != null) ? _this.filterService.parseTime(track.prodTime[tsIdx], 's', 'HH:mm:ss') : null,
                timestampP: _this.filterService.parseTableDate(ts, _this.intervalAggregations.selected.unit),
                relConsumption: (track.hasOwnProperty('rel')) ? track.rel[tsIdx] : null,
                totConsumption: (track.hasOwnProperty('tot')) ? track.tot[tsIdx] : null,
                delta: (track.hasOwnProperty('deltaTarget')) ? track.deltaTarget[tsIdx] : null,
                prodTime: (track.hasOwnProperty('prodTime')) ? track.prodTime[tsIdx] : null,
                timestamp: ts,
                aggregation: track.aggrUnitLabel,
              });
            });
          }

          let timestampsDelta: any = null;
          try {
            if (idx == 1) timestampsDelta = moment(track.timestamps[0]).diff(periods[0].tracks[0].timestamps[0].substring(0, periods[0].tracks[0].timestamps[0].length - 1));
          } catch (error) { console.log(error); }

          let timestamps: any = _this.clonerService.deepClone(track.timestamps);
          try {
            if (timestampsDelta != null) timestamps.forEach((x: any, idx: any) => timestamps[idx] = moment(x).add(-timestampsDelta).format("YYYY-MM-DDTHH:mm:ss.SSS"));
          } catch (error) { console.log(error); }

          if (track.tot != null && track.tot.length > 0) {
            traces.push({
              x: idx == 0 ? track.timestamps : timestamps,
              y: track.tot.map((x: any) => x != null ? (x * (consSettings.multiplier != null ? consSettings.multiplier : 1)) : null),
              traceType: 'tot',
              xaxis: 'x',
              yaxis: 'y',
              visible: true,
              name: track.aggrUnitId != null ? track.aggrUnitLabel : (idx == 0 ? _this.translate.instant('COMPLETE_CONSUMPTION.FIRST_PERIOD') : _this.translate.instant('COMPLETE_CONSUMPTION.SECOND_PERIOD')),
              type: 'scatter',
              mode: 'lines+markers',
              hovertext: track.timestamps.map((ts: any, ix: any) => {
                let hover: any = "<b>" + _this.translate.instant("GLOBAL.DATE") + "</b>: " + _this.filterService.parseTableDate(ts, _this.intervalAggregations.selected.unit) + '<br>';
                hover += "<b>" + _this.translate.instant("GLOBAL.VALUE") + "</b>: " + _this.filterService.parseGaugeValue(track.tot[ix], consSettings.decimals, consSettings.multiplier) + ' ' +
                  ((consSettings.unit != null) ? '[' + consSettings.unit + ']' : '[-]');
                return hover;
              }),
              hoverinfo: 'text',
              // legendgroup: trackIdx,
              line: {
                color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId).color : _this.colors[trackIdx + idx],
                shape: 'spline',
                dash: idx == 0 ? 'solid' : 'dash',
              },
              marker: {
                color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId).color : _this.colors[trackIdx + idx]
              }
            });
          }

          if (track.rel != null && track.rel.length > 0) {
            traces.push({
              x: idx == 0 ? track.timestamps : timestamps,
              y: track.rel.map((x: any) => x != null ? (x * (consSettings.multiplier != null ? consSettings.multiplier : 1)) : null),
              traceType: 'rel',
              xaxis: 'x',
              yaxis: 'y',
              visible: false,
              name: track.aggrUnitId != null ? track.aggrUnitLabel : (idx == 0 ? _this.translate.instant('COMPLETE_CONSUMPTION.FIRST_PERIOD') : _this.translate.instant('COMPLETE_CONSUMPTION.SECOND_PERIOD')),
              type: 'scatter',
              mode: 'lines+markers',
              hovertext: track.timestamps.map((ts: any, ix: any) => {
                let hover: any = "<b>" + _this.translate.instant("GLOBAL.DATE") + "</b>: " + _this.filterService.parseTableDate(ts, _this.intervalAggregations.selected.unit) + '<br>';
                hover += "<b>" + _this.translate.instant("GLOBAL.VALUE") + "</b>: " + _this.filterService.parseGaugeValue(track.rel[ix], consSettings.decimals, consSettings.multiplier) + ' ' +
                  ((consSettings.unit != null) ? '[' + consSettings.unit + (productionUnit != null ? '/' + productionUnit : '') + ']' : '[-]');
                return hover;
              }),
              hoverinfo: 'text',
              showlegend: true,
              line: {
                color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId).color : _this.colors[trackIdx + idx],
                shape: 'spline',
                dash: idx == 0 ? 'solid' : 'dash'
              },
              marker: {
                color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId).color : _this.colors[trackIdx + idx]
              }
            });
          }

          if (track.prodTime != null && track.prodTime.length > 0) {
            traces.push({
              x: track.timestamps,
              y: track.prodTime.map((x: any) => x != null ? x / 3600 : null),
              traceType: 'prodTime',
              xaxis: 'x',
              yaxis: 'y2',
              visible: true,
              name: track.aggrUnitId != null ? track.aggrUnitLabel : (idx == 0 ? _this.translate.instant('COMPLETE_CONSUMPTION.FIRST_PERIOD') : _this.translate.instant('COMPLETE_CONSUMPTION.SECOND_PERIOD')),
              type: 'scatter',
              mode: 'lines+markers',
              hovertext: track.prodTime.map((x: any) => _this.filterService.parseTime(x, 's', 'HH:mm:ss')),
              hoverinfo: 'text+x+name',
              legendgroup: trackIdx,
              showlegend: track.aggrUnitId == null,
              line: {
                color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId).color : _this.colors[trackIdx + idx],
                shape: 'spline',
                dash: 'dot',
                width: 2
              },
              marker: {
                color: _this.colorArray != null && track.aggrUnitId != null ? _this.colorArray.find((x: any) => x.id == track.aggrUnitId).color : _this.colors[trackIdx + idx]
              }
            });
          }

        });
      })
    }

    let updatemenus: any = [{
      buttons: [{
        args: [{
          'visible': traces.map((x: any) => x.traceType == 'prodTime' || x.traceType == 'tot')
        },
        {
          'yaxis2.visible': true,
          'yaxis.title.text': _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + ']' : '[-]'),
        },
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.TOTAL"),
        method: 'update'
      },
      {
        args: [{
          'visible': traces.map((x: any) => x.traceType == 'rel')
        },
        {
          'yaxis2.visible': false,
          'yaxis.title.text': _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + (productionUnit != null ? '/' + productionUnit : '') + ']' : '[-]')
        },
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.RELATIVE"),
        method: 'update'
      }
      ],
      direction: 'left',
      pad: {
        'r': 10,
        't': 10
      },
      showactive: true,
      type: 'buttons',
      x: 0.0,
      y: 1.3,
      xanchor: 'left',
      yanchor: 'top'
    }];

    let plotLayout: any = {
      updatemenus: updatemenus,
      legend: {
        orientation: 'h',
        x: 0,
        y: -0.2
      },
      margin: {
        t: 30,
        r: 110,
        b: 50,
        l: 100,
        pad: 15
      },
      xaxis: {
        zeroline: false,
        showgrid: false,
        automargin: true,
        title: {
          standoff: 20
        }
      },
      yaxis: {
        zeroline: true,
        showgrid: false,
        automargin: true,
        title: {
          standoff: 20,
          // text: _this.translate.instant('COMPLETE_CONSUMPTION.CONSUMPTION')
          text: _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + ']' : '[-]')
        },
        rangemode: 'tozero',
      },
      yaxis2: {
        zeroline: true,
        showgrid: false,
        overlaying: 'y',
        automargin: true,
        title: {
          standoff: 20,
          text: _this.translate.instant("COMPLETE_CONSUMPTION.PRODUCTIVE_TIME") + ' [hr]',
        },
        side: 'right',
        rangemode: 'tozero',
      },
    };

    return {
      layout: plotLayout,
      traces: traces
    };

  }

  buildAggrPlotConfig(_this: any, data: any) {

    if (_this.aggrDropdown == null) return;

    let currentAggrConfig: any = null;

    try {
      currentAggrConfig = _this.aggrDropdown != null ?
        (_this.aggregations != null && _this.aggregations.length > 0
          ? _this.aggregations.find((x: any) => x.id == _this.aggrDropdown)
          : _this.aggregationsCustom.find((x: any) => x.id == _this.aggrDropdown))
        : null;
    } catch (error) { }

    let aggrTransl: any = null;

    try {
      if (currentAggrConfig != null && currentAggrConfig.hasOwnProperty('singleLabel')) {
        aggrTransl = currentAggrConfig.singleLabel;
      }
    } catch (error) { }

    let consSettings = _this.machine.profile.consumables.find((x: any) => x.id == _this.consDropdown);
    let productionUnit = _this.translate.instant("PRODUCTION_UNITS.CYCLE");

    let traces: any = [];

    let orderByTot = _this.clonerService.deepClone(data.tracks.sort(_this.filterService.sortByProperty("sumTot", 'desc', true)));
    let orderByRel = _this.clonerService.deepClone(data.tracks.sort(_this.filterService.sortByProperty("sumRel", 'desc', true)));

    traces.push({
      x: orderByTot.map((x: any) => aggrTransl != null ? _this.translate.instant(aggrTransl + '.' + x.aggrUnitId) : x.aggrUnitId),
      y: orderByTot.map((x: any) => x.sumTot != null ? (x.sumTot * (consSettings.multiplier != null ? consSettings.multiplier : 1)) : null),
      traceType: 'tot',
      visible: true,
      type: 'bar',
      hovertext: orderByTot.map((x: any) => {
        let hovertext = '';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.TOTAL_CONSUMPTION") + '</b>: ' + (x.sumTot != null ? _this.filterService.parseGaugeValue(x.sumTot, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.RELATIVE_CONSUMPTION") + '</b>: ' + (x.sumRel != null ? _this.filterService.parseGaugeValue(x.sumRel, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + (productionUnit != null ? '/' + productionUnit : '') + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.AVG_CONSUMPTION") + '</b>: ' + (x.sumAvg != null ? _this.filterService.parseGaugeValue(x.sumAvg, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + (_this.intervalAggregations.selected.unit != null ? '/' + _this.intervalAggregations.selected.unit : '') + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.PRODUCTIVE_TIME") + '</b>: ' + _this.filterService.parseTime(x.sumProdTime, 's', 'HH:mm:ss') + '<br>';
        return hovertext;
      }),
      hoverinfo: 'text',
      marker: {
        color: orderByTot.map((y: any) => _this.colorArray.find((x: any) => x.id == y.aggrUnitId).color)
      }
    });

    traces.push({
      x: orderByRel.map((x: any) => aggrTransl != null ? _this.translate.instant(aggrTransl + '.' + x.aggrUnitId) : x.aggrUnitId),
      y: orderByRel.map((x: any) => x.sumRel != null ? (x.sumRel * (consSettings.multiplier != null ? consSettings.multiplier : 1)) : null),
      traceType: 'rel',
      visible: false,
      type: 'bar',
      hovertext: orderByRel.map((x: any) => {
        let hovertext = '';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.RELATIVE_CONSUMPTION") + '</b>: ' + (x.sumRel != null ? _this.filterService.parseGaugeValue(x.sumRel, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + (productionUnit != null ? '/' + productionUnit : '') + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.TOTAL_CONSUMPTION") + '</b>: ' + (x.sumTot != null ? _this.filterService.parseGaugeValue(x.sumTot, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.AVG_CONSUMPTION") + '</b>: ' + (x.sumAvg != null ? _this.filterService.parseGaugeValue(x.sumAvg, consSettings.decimals, consSettings.multiplier) : '-') + ' ' + consSettings.unit + (_this.intervalAggregations.selected.unit != null ? '/' + _this.intervalAggregations.selected.unit : '') + '<br>';
        hovertext += '<b>' + _this.translate.instant("COMPLETE_CONSUMPTION.PRODUCTIVE_TIME") + '</b>: ' + _this.filterService.parseTime(x.sumProdTime, 's', 'HH:mm:ss') + '<br>';
        return hovertext;
      }),
      hoverinfo: 'text',
      marker: {
        color: orderByRel.map((y: any) => _this.colorArray.find((x: any) => x.id == y.aggrUnitId).color)
      }
    });

    let updatemenus: any = [{
      buttons: [{
        args: [{
          'visible': traces.map((x: any) => x.traceType == 'tot')
        },
        {
          'yaxis.title.text': _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + ']' : '[-]')
        },
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.TOTAL"),
        method: 'update'
      },
      {
        args: [{
          'visible': traces.map((x: any) => x.traceType == 'rel')
        },
        {
          'yaxis.title.text': _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + (productionUnit != null ? '/' + productionUnit : '') + ']' : '[-]')
        }
        ],
        label: _this.translate.instant("COMPLETE_CONSUMPTION.RELATIVE"),
        method: 'update'
      }
      ],
      direction: 'left',
      pad: {
        'r': 10,
        't': 10
      },
      showactive: true,
      type: 'buttons',
      x: 0.0,
      y: 1.3,
      xanchor: 'left',
      yanchor: 'top'
    }];

    let plotLayout: any = {
      updatemenus: updatemenus,
      legend: {
        orientation: 'h',
        x: 0,
        y: -0.2
      },
      margin: {
        t: 30,
        r: 110,
        b: 50,
        l: 100,
        pad: 15
      },
      xaxis: {
        zeroline: false,
        showgrid: false,
        automargin: true,
        type: 'category',
        title: {
          standoff: 20
        }
      },
      yaxis: {
        zeroline: true,
        showgrid: false,
        automargin: true,
        title: {
          standoff: 20,
          // text: _this.translate.instant('ENERGY_CONSUMPTION.CONSUMPTION')
          text: _this.translate.instant(consSettings.label) + ' ' + ((consSettings.unit != null) ? '[' + consSettings.unit + ']' : '[-]')
        },
      },
    };

    return {
      layout: plotLayout,
      traces: traces
    };

  }

  filterEvents(searchEvent?: any) {
    // TODO
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    );

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pageState.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) {
      // console.log(error)
    }
  }

}