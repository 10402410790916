<span class="title" *ngIf="widget.title != null">{{ widget.title | translate }}</span>
<apx-chart *ngIf="chartOptions.chart != null" class="w100" 
    [chart]="chartOptions.chart"
    [plotOptions]="chartOptions.plotOptions"
    [labels]="chartOptions.labels" 
    [dataLabels]="chartOptions.dataLabels" 
    [xaxis]="chartOptions.xaxis" 
    [colors]="chartOptions.colors" 
    [tooltip]="chartOptions.tooltip"
    [yaxis]="chartOptions.yaxis" 
    [grid]="chartOptions.grid" 
    [series]="chartOptions.series"
    [legend]="chartOptions.legend"
    [annotations]="chartOptions.annotations">
</apx-chart>
<div *ngIf="chartOptions.chart == null" class="data-not-found h100" fxLayout="row" fxLayoutAlign="center center">
    <span class="add-opacity">{{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}</span>
</div>