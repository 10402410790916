import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'ff-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarComponent implements OnInit, OnChanges {

  @Input() widget: any;

  constructor(
    public filterService: FiltersService,
    public internalDataService: InternalDataService,
  ) { }

  parseWidgetData() {
    try {
      let conf = this.widget.config != null ? this.widget.config : [];
      // console.log(conf);
      this.widget.infos = [];
      conf.filter((conf: any) => conf.phaseId == null ? true : this.widget.data["FilterState.phaseId"] == conf.phaseId).forEach((v: any) => {
        let val = this.widget.data?.[v.variable];

        v.value = val;
        this.widget.infos.push(v);
      });
    } catch (error) { console.log(error) }
  }

  dpClick(dp: any) {
    if (dp != null && dp.action != null) {
      this.internalDataService.setDatapoint(dp);
    }
  }

  ngOnInit(): void {
    this.parseWidgetData();
  }

  ngOnChanges(): void {
    this.parseWidgetData();
  }

}
