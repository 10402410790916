<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
</div>
<!-- utils -->
<!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null " class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)" [hideStart]="true"
      [hideIntervalSelector]="true" [intAggrs]="intervalAggregations" (selectedAggr)="selectAggregation($event)">
    </ff-interval-selector>

  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start start" class="tab-content"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar tabs -->
    <div class="ff-toolbar pt-1 pb-3" fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">

      <!-- consumables dropdown -->
      <div *ngIf="machine.profile.consumables != null && machine.profile.consumables.length > 0"
        style="margin-right: 12px;" class="interval-selector" fxLayout="column" fxLayoutAlign="center start"
        fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

        <div class="box" fxLayout="row" fxLayoutAlign="start center">
          <!-- icon -->
          <mat-icon *ngIf="consIcon != null && consIcon.type == 'icon'" class="icon">
            {{ consIcon.icon }}
          </mat-icon>
          <mat-icon *ngIf="consIcon != null && consIcon.type == 'svg'" svgIcon="{{ consIcon.icon }}" class="icon">
          </mat-icon>
          <i *ngIf="consIcon != null && (consIcon.type == 'fontawesome' || consIcon.type == 'phosphor')"
            class="icon {{ consIcon.icon }}"></i>
          <mat-form-field appearance="fill">
            <mat-label>{{ 'GLOBAL.DROPDOWN_CONSUMABLE_SELECTION' | translate }}</mat-label>
            <mat-select [(ngModel)]="consDropdown" name="cons" style="text-transform: capitalize;">
              <mat-option *ngFor="let cons of machine.profile.consumables" [value]="cons.id"
                (click)="changeConsumable(consDropdown)">
                <span style="text-transform: capitalize;">{{ cons.label | translate }}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <span fxFlex></span>

      <!-- intervals -->
      <ff-interval-selector style="margin-right: 8px;" [config]="intervalConfig" (selected)="selectInterval($event, 1)"
        [hideIntervalSelector]="true" [clickableDate]="true" [intervalLabel]="'COMPLETE_CONSUMPTION.FIRST_PERIOD'">
      </ff-interval-selector>

      <!-- intervals -->
      <ff-interval-selector [config]="intervalConfig2" (selected)="selectInterval($event, 2)"
        [hideIntervalSelector]="true" [clickableDate]="true" [intervalLabel]="'COMPLETE_CONSUMPTION.SECOND_PERIOD'">
      </ff-interval-selector>

      <ff-available-machines-selection [availableMachines]="availableMachines"
        (selected)="machineSelectionChange($event)"></ff-available-machines-selection>

    </div>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < 6" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 6" fxLayout="column" class="w100 h100" style="min-height: calc(100vh - 260px);">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
        class="h100 w100" fxLayoutGap="8px">

        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" class="w100 h100" fxLayoutGap="8px">
          <ff-widget fxFlex="100"
            [widget]="{type: 'ff-plotly-chart-table', data: consData, machineProfile: machine.profile, config: consData.dataConfig }">
          </ff-widget>
        </div>

        <div fxFlex.gt-sm="{{dashboardConfig.secondColumnFlex}}" fxLayout="column" fxLayoutAlign="start stretch"
          class="w100 h100" fxLayoutGap="8px">

          <ff-widget *ngFor="let w of dashboardConfig.widgets" fxFlex.gt-sm="{{w.flex}}"
            [widget]="{ type: w.type, data: consData, machineProfile: machine.profile, config: w.config, title: w.title }">
          </ff-widget>

        </div>

      </div>

    </div>

  </div>

  <div fxFlex fxLayout="column" fxLayoutAlign="start start" *ngIf="pageState.value >= 6 && aggrDropdown != null"
    fxLayout="row" class="w100" style="margin-top: 8px;">

    <ff-widget fxFlex="100"
      [widget]="{type: 'ff-plotly-chart', data: consData, machineProfile: machine.profile, config: consData.aggrDataConfig }">
    </ff-widget>

  </div>

</div>