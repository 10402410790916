import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, throwError, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

import { deserialize } from 'bson';
import { all as mergeAll } from 'deepmerge';
import { loadAsync } from 'jszip';
import { IntervalService } from 'src/app/services/interval.service';

declare var Plotly: any;

import { MatDialog } from '@angular/material/dialog';
import { ClonerService } from 'src/app/services/clone.service';
import { VariablesSelectionDialogComponent } from '../process-log/variables-selection-dialog/variables-selection-dialog.component';

@Component({
  selector: 'app-gca-variables',
  templateUrl: './gca-variables.component.html',
  styleUrls: ['./gca-variables.component.scss']
})
export class GcaVariablesComponent implements OnInit {

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public backButton: any;
  public tabs: any;

  public cycleType: any = 0;
  public cycleId: any;
  public cycleDay: any;
  public cycleStart: any;
  public cycleEnd: any;
  public cycleSelectedSub: Subscription;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;
  public machineProfile: any;

  public pollingTime: any;
  public pollingMachines: any;

  public currentSynopticId: any;
  public synopticConfig: any;
  public synopticConfigDefault: any;
  public monitoringData: any;
  public monitoringDataUnparsed: any;
  public dashboardConfig: any;

  public phases: any;
  public phaseSelectedId: any;
  public availablePhases: any = [];
  public phaseDataConfig: any;
  public phaseFlags: any;
  public phaseData: any;

  public plotData: any;
  public gcaVariables: any;

  public cycleDataConfig: any;
  public cycleData: any;
  public zoomRange: any;

  public onlyOneGroupSelected: any;

  public dialogData: any;
  public showDialog: boolean = false;
  public chartOptions: any;

  public bsonResp: any;
  public cycleConfig: any;

  public interval: any;
  public intervalConfig: any;

  public phaseSelected: boolean = false;
  public showFlags: boolean = true;

  public defaultPlotlyColors = [
    '#66DA26', '#546E7A', '#E91E63', '#FF9800', '#008FFB',
    "#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D", "#FF9800",
    "#33B2DF", "#546E7A", "#D4526E", "#13D8AA", "#A5978B",
    "#4ECDC4", "#C7F464", "#81D4FA", "#546E7A", "#FD6A6A",
    "#2B908F", "#F9A3A4", "#90EE7E", "#FA4443", "#69D2E7",
    "#449DD1", "#F86624", "#EA3546", "#662E9B", "#C5D86D",
    "#D7263D", "#1B998B", "#2E294E", "#F46036", "#E2C044",
    "#662E9B", "#F86624", "#F9C80E", "#EA3546", "#43BCCD",
    "#5C4742", "#A5978B", "#8D5B4C", "#5A2A27", "#C4BBAF",
    "#A300D6", "#7D02EB", "#5653FE", "#2983FF", "#00B1F2",
    '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd',
    '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf',
  ];


  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 9;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getCycleTimelineWidgets, nextState: 4, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getCyclePhases, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 },
        { code: 302, function: this.getFileContent, nextState: 7, loadingMsg: 'GLOBAL.LOADING' },
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getCycleDetail, nextState: 6, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.getFileContent, nextState: 7, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 7,
      codes: [
        { code: 300, function: this.getGCAVariables, nextState: 8, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 8,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 9 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    // TODO AGGIUNGERE AGGREGAZIONI E FILTRI
    this.machineProfiles = this.appConfigService.getMachineProfiles;
    this.monitoringData = null;


    this.breadcrumb = ['CYCLE_TRACEABILITY.TITLE', 'GCA_VARIABLES.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs('cycleDetails');

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        this.breadcrumb[2] = value.machineName;
        this.internalDataService.setBreadcrumb(this.breadcrumb);
      }
    });

    this.cycleSelectedSub = this.internalDataService.cycleSelected.subscribe(value => {
      if (value != null && !Array.isArray(value)) {
        this.breadcrumb[3] = (value.split("$$")[0]);
        this.internalDataService.setBreadcrumb(this.breadcrumb);
      }
    });

    this.pollingTime = 0;
    this.pollingMachines = Subscription;

  }

  openVariablesSelectionDialog() {
    const variablesDialog = this.dialog.open(VariablesSelectionDialogComponent,
      {
        panelClass: 'ff-dialog',
        width: '40%',
        // height: '90%',
        data: {
          title: this.translate.instant("GCA_VARIABLES.VARIABLES_SELECTION"),
          groups: this.clonerService.deepClone(this.dashboardConfig)
        },
      });

    variablesDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {
          this.dashboardConfig = this.clonerService.deepClone(result.groups);
          let plotData: any = this.parsePlotData(this.bsonResp, this.dashboardConfig, this.zoomRange);

          // plotData.forEach((plotNum: any) => this.drawPlot(plotNum.id, plotNum.data, plotNum.layout, plotNum.config));
          setTimeout(() => plotData.forEach((plotNum: any) => this.drawPlot(this, plotNum.id, plotNum.data, plotNum.layout, plotNum.config)), 50);

        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  toggleGroup(group: any, groups: any) {
    try {
      group.show = group.show != null ? !group.show : true;
      group.traces.forEach((trace: any) => trace.show = group.show);
      group.shownTraces = group.traces.filter((x: any) => x.show).length;
      this.onlyOneGroupSelected = groups.filter((x: any) => x.show).length == 1;

    } catch (error) {
      console.log(error);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  public getAssetInfo(_this: any) {
    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, 'cycleDetails');
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  phaseSelectionChange(phaseId: any) {
    this.zoomRange = null;
    this.phaseSelectedId = phaseId;
    this.pageState.next(4);
    this.getCycleTimelineWidgets(this, true);
  }

  public getCycleTimelineWidgets(_this: any, skipFileList: boolean = false) {

    try {

      _this.phaseSelectedId = _this.phaseSelectedId == null ? 1 : _this.phaseSelectedId;
      const sources40F = (_this.appInfo.sources40F != null ? _this.appInfo.sources40F : 'assets/config/');
      let folder = _this.machineId;
      let fileName: any = null;
      let totalFileName: any = null;

      if (_this.phaseSelectedId == 0) {
        fileName = 'processLogConfig.json';
        totalFileName = folder + '/processLogConfig.json';
      } else {
        fileName = 'phase' + _this.phaseSelectedId + '_processLogConfig.json';
        totalFileName = folder + '/phase' + _this.phaseSelectedId + '_processLogConfig.json';
      }

      let url = sources40F + totalFileName;

      _this.apiService.sendGetRequest(url).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 1,
            status: 404,
            message: _this.translate.instant("GCA_VARIABLES.NO_CONFIG_FILE", {
              filename: fileName,
              folder: folder,
            }),
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError(
            'Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.dashboardConfig = _this.parsePlotsConfig(data.body);

            _this.phaseDataConfig = {
              "gap": "8px",
              "widgets": [
                {
                  "type": "ff-value",
                  "flex": 22,
                  "config": [
                    {
                      "variable": "phaseStart",
                      "label": "CYCLE_TIMELINE.PHASE_START",
                      "iconClass": "md-primary",
                      "icon": {
                        "icon": "play_arrow",
                        "type": "icon"
                      }
                    }
                  ]
                },
                {
                  "type": "ff-value",
                  "flex": 22,
                  "config": [
                    {
                      "variable": "phaseEnd",
                      "label": "CYCLE_TIMELINE.PHASE_END",
                      "iconClass": "md-primary",
                      "icon": {
                        "icon": "stop",
                        "type": "icon"
                      }
                    }
                  ]
                },
                {
                  "type": "ff-value",
                  "flex": 22,
                  "config": [
                    {
                      "variable": "phaseDuration",
                      "label": "CYCLE_TIMELINE.DURATION",
                      "format": "time",
                      "iconClass": "md-primary",
                      "icon": {
                        "icon": "timer_quarter",
                        "type": "svg"
                      }
                    }
                  ]
                },
                {
                  "type": "ff-bar",
                  "flex": 22,
                  "config": [
                    {
                      "variable": "gca",
                      "label": "CYCLES.MEAN_GCA_EVALUATION",
                      "iconClass": "md-blue",
                      "icon": {
                        "icon": "health",
                        "type": "svg"
                      }
                    }
                  ]
                },
                {
                  "type": "ff-led",
                  "flex": 12,
                  "config": {
                    "variable": "trainSet",
                    "label": "CYCLE_TIMELINE.TRAINING_CYCLE",
                    "trueColor": '#4BDD7D',
                    "falseColor": '#5f6de8',
                  }
                },
              ]
            };

            if (skipFileList) {
              try {
                _this.addGCAEvalToTraces(_this);
              } catch (error) { console.log(error) }
            }
            _this.dispatcherService.getDispatch(_this, skipFileList ? 302 : 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  parsePlotsConfig(data: any) {

    try {
      // let maxPlots = data.maxPlots != null ? data.maxPlots : 5;
      let maxPlots = 5;
      let tracesIndex = 0;
      data.plotsConfig.list = data.plotsConfig.list.filter((group: any) => !group.doubleAxis);
      data.plotsConfig.list.forEach((group: any, index: any) => {
        if (tracesIndex >= maxPlots) {
          group.show = false;
          group.traces.forEach((trace: any) => trace.show = false);
        }
        group.traces.forEach((trace: any) => {
          tracesIndex += 1;
          if (tracesIndex > maxPlots) trace.show = false;
          // trace.gca = Math.random();
          let isPhaseVar = trace.name != null && trace.name.startsWith("PhaseVars");
          // trace.label = this.translate.instant("DATAPOINTS." + this.machineId + '.' + (!isPhaseVar ? trace.name : trace.name.split(".")[0] + this.phaseSelectedId + '.' + trace.name.split(".")[1]));
          trace.label = this.internalDataService.parseDatapointLabel((!isPhaseVar ? trace.name : trace.name.split(".")[0] + this.phaseSelectedId + '.' + trace.name.split(".")[1]), null, this.machineId);
        });
        group.shownTraces = group.traces.filter((x: any) => x.show).length;
        group.allTraces = group.traces.length;
      });

    } catch (error) { }

    return data;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET CYCLE DETAIL

  getCycleDetail(_this: any, count?: any) {
    try {

      let url = '/apif/cycle-detail/cycle-timeline/' + _this.machineId;

      let query: any = {
        from: _this.cycleStart,
        to: _this.cycleEnd,
        cycleType: _this.cycleType,
        cycleId: _this.cycleId,
        tz: _this.machine.timezone
      };

      _this.apiService.sendGetRequest(url, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          if (error.error instanceof ErrorEvent) {
            console.log(`Error: ${error.error.message}`);
          } else {
            console.log(`Error: ${error.message}`);
          }

          let testError = {
            type: 0,
            status: error.status,
            message: error.statusText
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError('Something bad happened; please try again later.');
        }))
        .subscribe(
          (data: any) => {
            _this.cycleConfig = _this.clonerService.deepClone(data.body);

            try {
              _this.addGCAEvalToTraces(_this);
            } catch (error) { console.log(error) }

            _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      console.log(error);
    }

  }

  addGCAEvalToTraces(_this: any) {
    try {

      let currentPhaseConfig: any = null;
      try {
        let currentPhaseConfigIdx = _this.cycleConfig.phases.findIndex((x: any) => x.phaseId == _this.phaseSelectedId);
        if (currentPhaseConfigIdx != -1) {
          currentPhaseConfig = _this.cycleConfig.phases[currentPhaseConfigIdx];
          _this.dashboardConfig.plotsConfig.list.forEach((group: any) => {
            group.traces.forEach((trace: any) => {
              trace.gca = currentPhaseConfig.gcaVariables != null ? currentPhaseConfig.gcaVariables[trace.name] : null;
            });
          });
        }
      } catch (error) { }

    } catch (error) { console.log(error) }
  }

  // polling
  getFileContentPolling(_this: any) {
    try {

      if (_this.pollingTime > 0) {
        _this.pollingMachines = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getFileContent(_this, count);
        });
      } else {
        _this.getFileContent(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET CYCLE PHASES

  getCyclePhases(_this: any) {

    try {

      let fileName = "AQS";
      let url = "/apif/IOTfilelist/" + _this.machineId + "/" + fileName;

      let query = {
        cycleId: _this.cycleId,
        cycleType: _this.cycleType
      }

      _this.apiService.sendGetRequest(url, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data.body);

            if (data.body != null && data.body.length > 0) {

              _this.availablePhases = [].concat(
                _this.clonerService.deepClone(
                  _this.machine.profile.phases
                    .filter((phase: any) => data.body.findIndex((x: any) => x.phaseId == phase.outputPhaseId) != -1)
                    .map((pc: any) => {
                      let files = data.body.find((x: any) => x.phaseId == pc.outputPhaseId)?.files;
                      return files != null ? Object.assign(pc, { files: files }) : pc;
                    })
                )
              );

              _this.cycleData.type = _this.cycleType;
              _this.dispatcherService.getDispatch(_this, 300);

            } else {

              let noCycleError = {
                type: 1,
                status: 404,
                message: _this.translate.instant("GCA_VARIABLES.NO_PHASE_AVAILABLE_FOR_CURRENT_CYCLE")
              };

              _this.dispatcherService.getDispatch(_this, 301, noCycleError);
            }

          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET BSON FILE

  getFileContent(_this: any) {

    try {

      let actualPhaseId = _this.availablePhases.findIndex((phase: any) => phase.outputPhaseId == _this.phaseSelectedId);
      let actualPhase = actualPhaseId != -1 ? _this.availablePhases[actualPhaseId] : {};
      let timestamp = actualPhase.timestamp != null ? '_' + actualPhase.timestamp : '';

      // let fileName = "AQS_cycle" + cycleId + (_this.phaseSelectedId != 0 ? ("_phase" + _this.phaseSelectedId) : (_this.availablePhases.findIndex((phase: any) => phase.outputPhaseId == 13) != -1 ? 'hpw' : '')) + timestamp + ".bson";

      if (actualPhaseId == -1) {
        actualPhaseId = actualPhaseId != -1 ? actualPhaseId : 0;
        _this.phaseSelectedId = _this.availablePhases[0].outputPhaseId;

        try {
          _this.addGCAEvalToTraces(_this);
        } catch (error) { console.log(error) }

        _this.pageState.next(4);
        _this.getCycleTimelineWidgets(_this, true);

        return;
      }

      let files = [_this.availablePhases[actualPhaseId].files[_this.availablePhases[actualPhaseId].files.length - 1]];

      _this.bsonRespArray = [];
      _this.bsonRespArrayUnparsed = [];

      if (files != null && files.length > 0) {
        files.forEach((file, index) => {
          let url = "/apif/IOTfilecontent/" + _this.machineId + "/" + file.fileName;

          _this.apiService.getArrayBuffer(url).pipe(
            retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
            catchError(error => {

              if (error.error instanceof ErrorEvent) {
                console.log(`Error: ${error.error.message}`);
              } else {
                console.log(`Error: ${error.message}`);
              }

              let testError = {
                type: 1,
                status: 404,
                message: _this.translate.instant("GCA_VARIABLES.NO_PHASE_DATA")
              };

              _this.dispatcherService.getDispatch(_this, 301, testError);
              return throwError('Something bad happened; please try again later.');
            }))
            .subscribe(
              (data: any) => {
                // console.log(data);

                if (file.extension == 'zip') {
                  loadAsync(data.body).then((zip) => {
                    Object.keys(zip.files).forEach((filename) => {
                      zip.files[filename].async("arraybuffer").then((fileData) => {
                        _this.parseBSON(_this, fileData, files.length);
                      });
                    });
                  });
                } else {
                  _this.parseBSON(_this, data.body, files.length);
                }

              },
            );
        })
      }

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  parseBSON(_this: any, fileData: any, filesLength?: any) {

    try {

      _this.bsonResp = deserialize(fileData);
      _this.bsonRespArrayUnparsed.push(_this.clonerService.deepClone(_this.bsonResp));

      // Downsample if data is too big
      // let timestamps = [];
      // try {
      //   timestamps = _this.bsonResp[Object.keys(_this.bsonResp)[0]].timestamp;
      //   if (timestamps.length > 200) {
      //     if (!_this.isComparedCycle) _this.bsonResp = _this.downsample(_this.bsonResp);
      //     else _this.comparedCycleData = _this.downsample(_this.comparedCycleData, 200, true);
      //   }
      // } catch (e) { console.log(e) };

      _this.bsonRespArray.push(_this.clonerService.deepClone(_this.bsonResp));
      console.log(_this.bsonRespArray);

      _this.bsonResp = _this.bsonRespArray.reduce((newResp, data) => {
        newResp = mergeAll([newResp, data]);
        return newResp;
      }, {});

      _this.bsonRespUnparsed = _this.bsonRespArrayUnparsed.reduce((newResp, data) => {
        newResp = mergeAll([newResp, data]);
        return newResp;
      }, {});


      console.log(_this.bsonResp);

      if (_this.bsonRespArray.length == filesLength) {

        let mergedTimestamps = _this.bsonResp[Object.keys(_this.bsonResp)[0]].timestamp.sort();

        try {
          let phaseStart = mergedTimestamps[0];
          let phaseEnd = mergedTimestamps[mergedTimestamps.length - 1];
          let phaseDuration = moment(phaseEnd).diff(phaseStart, 's');

          let currentPhaseConfig = null;
          try {
            let currentPhaseConfigIdx = _this.cycleConfig.phases.findIndex((x: any) => x.phaseId == _this.phaseSelectedId);
            if (currentPhaseConfigIdx != -1) currentPhaseConfig = _this.cycleConfig.phases[currentPhaseConfigIdx];
          } catch (error) { }

          _this.phaseData = {
            phaseStart: _this.filterService.parseMoment(phaseStart, 'default'),
            phaseEnd: _this.filterService.parseMoment(phaseEnd, 'default'),
            phaseDuration: phaseDuration,
            phaseName: _this.phaseSelectedId,
            gca: currentPhaseConfig != null ? currentPhaseConfig.gca : null,
            trainSet: _this.cycleConfig != null && _this.cycleConfig.total != null ? _this.cycleConfig.total.trainSet : null,
          };

        } catch (error) {

        }

        // let plotData = _this.parsePlotData(_this.bsonResp, _this.dashboardConfig, _this.zoomRange, _this.comparedCycleData);
        // setTimeout(() => plotData.forEach((plotNum: any) => _this.drawPlot(_this, plotNum.id, plotNum.data, plotNum.layout, plotNum.config)), 50);

        _this.dispatcherService.getDispatch(_this, 300);
      }

    } catch (error) {
      console.log(error);
    }
  }

  downsample(data: any, finalArrayLength: number = 200, comparedCycle: any = false) {

    if (data == null || Object.keys(data).length == 0) return null;

    try {

      let newData: any = {};

      Object.entries(data).forEach(kv => {
        let aspect = kv[0];
        let variables: any = kv[1];

        newData[aspect] = {}

        let newVariables: any = {};
        if (this.zoomRange != null && this.zoomRange.from != null && this.zoomRange.to != null) {

          let indexes = [];

          for (let j = 0; j < variables.timestamp.length; j++) {
            if (moment(variables.timestamp[j]).diff(this.zoomRange.from) > 0) {
              indexes.push(j);
              break;
            }
          }
          for (let i = variables.timestamp.length; i > 0; i--) {
            if (moment(variables.timestamp[i]).diff(this.zoomRange.to) < 0) {
              indexes.push(i);
              break;
            }
          }

          // console.log(indexes);

          // let indexes = variables.timestamp.reduce((acc, x, idx) => {
          //   if (moment(x).diff(this.zoomRange.from) > 0 && moment(x).diff(this.zoomRange.to) < 0) acc.push(idx);
          //   return acc;
          // }, []);
          Object.entries(variables).forEach(nv => {
            let name = nv[0];
            let values: any = nv[1];

            newVariables[name] = values.slice(indexes[0], indexes[1]);

          });
        } else {
          newVariables = this.clonerService.deepClone(variables);
        }

        let pointsToJump = Math.floor(newVariables.timestamp.length / finalArrayLength);

        // console.log(newVariables);

        Object.entries(newVariables).forEach(nv => {
          let name = nv[0];
          let value: any = nv[1];

          let newVal = this.clonerService.deepClone(value);
          if (pointsToJump > 1) {
            newVal = value.reduce((acc, val, index, ar) => {

              if (name == 'timestamp') {
                if (index % pointsToJump == 0 && index > 0) acc.push(val);
              } else {
                if (index % pointsToJump == 0 && index > 0) acc.push(this.filterService.average(ar.slice(index - pointsToJump, index)));
              }
              return acc;
            }, []);
          }

          newData[aspect][name] = newVal;

        });

      });

      return newData;

    } catch (error) {
      console.log(error);
      return data;
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET GCA VARIABLES

  getGCAVariables(_this: any) {

    try {

      let url = "/apif/gca-variables/" + _this.machineId;

      let query = null;
      let payload = null;

      _this.apiService.sendPostRequest(url, payload, query).pipe(
        retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
        catchError(error => {

          console.log(`Error: ${((error.error instanceof ErrorEvent) ? error.error.message : error.message)}`)

          let testError = {
            type: 1,
            status: error.status,
            message: error.message
          };

          _this.dispatcherService.getDispatch(_this, 301, testError);
          return throwError('Something bad happened; please try again later.');

        }))
        .subscribe(
          (data: any) => {
            console.log(data.body);

            _this.gcaVariables = _this.clonerService.deepClone(data.body);

            let plotData = _this.parsePlotData(_this.bsonResp, _this.dashboardConfig, _this.zoomRange);
            setTimeout(() => plotData.forEach((plotNum: any) => _this.drawPlot(_this, plotNum.id, plotNum.data, plotNum.layout, plotNum.config)), 50);

            _this.dispatcherService.getDispatch(_this, 300);
          },
        );

    } catch (error) {

      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };

      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // PLOTS

  parsePlotData(plotData: any, plotsConfig: any, zoomRange?: any) {

    let plotsArray: any = [];
    let traces: any = [];

    let plotLayout: any = {
      uirevision: true,
      font: {
        size: 12,
      },
      xaxis: {
        // domain: [0, 1],
        showgrid: false,
      },
      yaxis: {
        showgrid: false,
        zeroline: false,
        automargin: true,
      },
      showlegend: true,
      margin: {
        t: 30,
        r: 20,
        b: 30,
        l: 60,
        pad: 10
      },
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: -0.3
      },
    };

    if (
      zoomRange != null &&
      zoomRange.hasOwnProperty('from') && zoomRange.from != null &&
      zoomRange.hasOwnProperty('to') && zoomRange.to != null
    ) {
      plotLayout.xaxis.autorange = false;
      plotLayout.xaxis.range = [zoomRange.from, zoomRange.to];
    } else {
      plotLayout.xaxis.autorange = true;
    }

    let currentPhaseGCAVariables: any = null;
    let gcaTracesToAdd = ["min", "max"];
    if (this.gcaVariables != null && Object.keys(this.gcaVariables).length > 0 &&
      Object.keys(this.gcaVariables).includes(this.phaseSelectedId.toString())) {
      currentPhaseGCAVariables = this.gcaVariables[this.phaseSelectedId]
    }

    if (plotsConfig != null && plotsConfig.hasOwnProperty('plotsConfig') && plotsConfig.plotsConfig != null &&
      plotsConfig.plotsConfig.hasOwnProperty('list') && plotsConfig.plotsConfig.list != null && plotsConfig.plotsConfig.list.length > 0) {

      plotsConfig.plotsConfig.list.forEach((group: any, groupIdx: any) => {

        traces = [];
        if (group != null && group.hasOwnProperty('traces') && group.traces.length > 0) {

          let plotLayoutCopy: any = this.clonerService.deepClone(plotLayout);
          plotLayoutCopy.yaxis.title = group.unit != null ? group.unit : '-';

          group.traces.forEach((trace: any, traceIdx: any) => {

            for (const aspect of Object.keys(plotData)) {
              if (plotData[aspect].hasOwnProperty(trace.name) && trace.show) {

                let traceName = trace.label;
                let hovertext = plotData[aspect][trace.name].map((x: any, xIdx: any) => {
                  return "<b>" + this.translate.instant("GCA_VARIABLES.VARIABLE") + ": </b>" + traceName + '<br>' +
                    "<b>" + this.translate.instant("GCA_VARIABLES.VALUE") + ": </b>" + this.filterService.parseGaugeValue(x, 2, 1) +
                    " " + (trace.unit != null ? trace.unit : group.unit) + '<br>' +
                    "<b>" + this.translate.instant("GCA_VARIABLES.TIME") + ": </b>" + moment(plotData[aspect].timestamp[xIdx]).format("HH:mm:ss.SSS") + '<br>';
                });

                traces = [{
                  x: plotData[aspect].timestamp.map((x: any) => new Date(x)),
                  y: plotData[aspect][trace.name],
                  type: 'scatter',
                  // yaxis: trace.yAxis != null ? trace.yAxis : 'y',
                  name: this.translate.instant("GCA_VARIABLES.REAL_TREND"),
                  // name: trace.label,
                  // legendgroup: traceIdx,
                  hoverinfo: 'text',
                  hovertext: hovertext,
                  line: {
                    dash: 'solid',
                    color: this.defaultPlotlyColors[traceIdx],
                    // shape: 'spline'
                  },
                }];

                if (currentPhaseGCAVariables != null &&
                  currentPhaseGCAVariables.hasOwnProperty(trace.name) &&
                  currentPhaseGCAVariables[trace.name] != null &&
                  Object.keys(currentPhaseGCAVariables[trace.name]).length > 0) {

                  let pvGCAConfig = this.filterService.removeKeysWithCustomRule(currentPhaseGCAVariables[trace.name], (x: any) => gcaTracesToAdd.includes(x[0]));

                  if (pvGCAConfig != null && Object.keys(pvGCAConfig).length > 0) {
                    Object.entries(pvGCAConfig).forEach((kv: any) => {

                      let GCAName = kv[0];
                      let GCAVals = kv[1];

                      // create timestamps
                      let deltaTimeMilliSeconds = (moment(plotData[aspect].timestamp[plotData[aspect].timestamp.length - 1]).diff(moment(plotData[aspect].timestamp[0]))) / GCAVals.length;
                      let GCATimestamps = GCAVals.map((x: any, idx: any) => moment(plotData[aspect].timestamp[0]).add(idx * deltaTimeMilliSeconds).format("YYYY-MM-DDTHH:mm:ss.SSS"))

                      let GCAhovertext = GCAVals.map((x: any, xIdx: any) => {
                        return "<b>" + this.translate.instant("GCA_VARIABLES.VARIABLE") + ": </b>" + GCAName + '<br>' +
                          "<b>" + this.translate.instant("GCA_VARIABLES.VALUE") + ": </b>" + this.filterService.parseGaugeValue(x, 2, 1) +
                          " " + (trace.unit != null ? trace.unit : group.unit) + '<br>' +
                          "<b>" + this.translate.instant("GCA_VARIABLES.TIME") + ": </b>" + moment(GCATimestamps[xIdx]).format("HH:mm:ss.SSS") + '<br>';
                      });

                      traces.push({
                        // x: plotData[aspect].timestamp.map((x: any) => new Date(x)),
                        x: GCATimestamps,
                        y: GCAVals,
                        type: 'scatter',
                        name: GCAName,
                        hoverinfo: 'text',
                        hovertext: GCAhovertext,
                        line: {
                          dash: 'dash',
                          color: "red",
                          // shape: 'spline'
                        },
                      })
                    });
                  }

                }

                plotsArray.push({
                  id: 'prova_' + group.id + '_' + trace.label, data: traces, layout: plotLayoutCopy, config: {
                    zoomPlots: true
                  }
                });
              };
            }

          });
        }
      });

      // console.log(plotsArray);

      return plotsArray;
    }

    return {
      layout: {},
      traces: []
    };
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT
  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    let cycleUrl = this.route.snapshot.params['cycleId'];

    this.backButton = [this.machineId, "cycle-traceability", "cycles"];
    this.internalDataService.setBackButton(this.backButton);

    try {
      let splittedCycleUrl = cycleUrl.split("$$");
      this.cycleId = splittedCycleUrl[0];
      this.cycleStart = splittedCycleUrl[1];
      this.cycleEnd = splittedCycleUrl[2];
      this.cycleType = splittedCycleUrl[3];
      this.cycleDay = moment(this.cycleEnd).format("YYYY-MM-DD");
    } catch (error) {
      console.log(error);
    }
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId'];
        let cycleUrl = params['cycleId'];

        try {
          let splittedCycleUrl = cycleUrl.split("$$");
          this.cycleId = splittedCycleUrl[0];
          this.cycleStart = splittedCycleUrl[1];
          this.cycleEnd = splittedCycleUrl[2];
          this.cycleType = splittedCycleUrl[3];
          this.cycleDay = moment(this.cycleEnd).format("YYYY-MM-DD");

          this.cycleData = {
            cycleId: this.cycleId,
            cycleDay: this.cycleDay,
            cycleType: this.cycleType,
            cycleStart: this.filterService.parseMoment(this.cycleStart, 'default'),
            cycleEnd: this.filterService.parseMoment(this.cycleEnd, 'default'),
            cycleDuration: moment(this.cycleEnd).diff(this.cycleStart, 's'),
          };

        } catch (error) {
          console.log(error);
        }

        this.internalDataService.setCycleSelected(cycleUrl);

      }
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  ngOnChanges() {
  }

  drawPlot(_this: any, divId: any, data: any, layout: any, params: any, comp?: any) {
    let plotDiv: any = document.getElementById(divId);
    // Plotly.purge(plotDiv);
    if (plotDiv) {
      var config: any = {
        responsive: true,
        displaylogo: false,
        // scrollZoom: true
      };

      if (params.hasOwnProperty('displayModeBar') && params.displayModeBar != null) {
        config.displayModeBar = params.displayModeBar;
      }
      if (params.hasOwnProperty('modeBarButtonsToRemove') && params.modeBarButtonsToRemove != null) {
        config.modeBarButtonsToRemove = params.modeBarButtonsToRemove;
      }
      // var newPlot = _checkNewPlot(plotDiv);
      // if (newPlot) {
      //NEW PLOT
      setTimeout(function () {
        var newPlot = _checkNewPlot(plotDiv);
        if (newPlot) {
          Plotly.react(plotDiv, data, layout, config);

          if (params && params.hasOwnProperty('zoomPlots') && params.zoomPlots) {
            plotDiv.on('plotly_relayout',
              function (eventdata: any) {
                // console.log(eventdata);

                if (eventdata.hasOwnProperty('autosize') && eventdata.autosize == true) {
                  return;
                }

                _this.zoomRange = {
                  from: eventdata['xaxis.range[0]'],
                  to: eventdata['xaxis.range[1]']
                };

                let plotData: any = _this.parsePlotData(_this.bsonResp, _this.dashboardConfig, _this.zoomRange, _this.comparedCycleData);

                setTimeout(() => plotData.forEach((plotNum: any) => _this.drawPlot(_this, plotNum.id, plotNum.data, plotNum.layout, plotNum.config)), 50);

              }
            );
          }

        } else {
          Plotly.react(plotDiv, data, layout, config);
        }
      }, 5);
    } else {
      console.warn('WARNING:', 'ElementById ' + divId + ' not found.', 'Retry.');
    }
  };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pollingMachines.unsubscribe();
    } catch (error) { }
    try {
      this.pageState.unsubscribe();
    } catch (error) { }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) { }
    try {
      this.cycleSelectedSub.unsubscribe();
    } catch (error) { }
    try {
      this.internalDataService.setBackButton([]);
    } catch (error) { }
  }

}

function _checkNewPlot(elem: any) {
  if (elem.hasChildNodes()) {
    var childNodes = elem.childNodes;
    for (var i = 0; i < childNodes.length; i++) {
      if (childNodes[i].classList && childNodes[i].classList.contains('plot-container')) {
        return false;
      }
    }
  }
  return true;
}