<!-- content -->
<div fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar tabs -->
  <div *ngIf="tabs.length > 1 || intervalConfig != null" class="ff-toolbar" fxLayout="column"
    fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

    <!-- tabs -->
    <ff-tabs [tabs]="tabs" *ngIf="tabs.length > 1"></ff-tabs>

    <span fxFlex fxShow="false" fxShow.gt-sm></span>

    <!-- intervals -->
    <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

  </div>

  <!-- tab content -->
  <div class="tab-content h100 w100" fxLayout="column"
    [fxLayoutAlign]="pageState.value >= 5 ? 'start stretch' : (pageState.value == 0 ? 'start center' : 'center center')"
    [ngClass]="{ 'without-tabs': tabs.length <= 1 && intervalConfig == null}">

    <!-- toolbar -->
    <div *ngIf="aggregations?.length > 0 || availableMachines?.list?.length > 0 || codeKeys?.length > 0"
      class="ff-toolbar pb-3 pt-1" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">

      <!-- aggregations -->
      <button mat-button class="filter-button" *ngFor="let aggr of aggregations" (click)="openAggrDialog(aggr)"
        [disabled]="aggr.disabled" fxLayout="row" fxLayoutAlign="space-evenly center">
        <mat-icon *ngIf="aggr.icon.type == 'icon'" class="icon" translate-attr="{'aria-label': aggr.label}">
          {{aggr.icon.icon }}</mat-icon>
        <mat-icon *ngIf="aggr.icon.type == 'svg'" svgIcon="{{ aggr.icon.icon }}" class="icon"
          translate-attr="{'aria-label': aggr.label}"></mat-icon>
        <i *ngIf="aggr.icon.type == 'fontawesome' || aggr.icon.type == 'phosphor'" class="icon {{ aggr.icon.icon }}"
          translate-attr="{'aria-label': aggr.label}"></i>
        <span>{{ aggr.selectionLabel }}</span>
        <div fxLayout="row" fxLayoutAlign="center center"
          *ngIf="aggregationsPayload != null && aggregationsPayload[aggr.id] != null" class="active-filters-circle">
          {{ aggregationsPayload[aggr.id].length }}
        </div>
      </button>

      <span fxFlex fxShow="false" fxShow.gt-sm></span>

      <!-- code keys dropdown -->
      <div [ngStyle.gt-sm]="{'margin-right': aggregations?.length > 0 ? '8px' : '0'}"
        [ngStyle.lt-md]="{'margin-bottom': aggregations?.length > 0 ? '8px' : '0'}"
        *ngIf="!isMobile && codeKeys?.length > 0" class="interval-selector" fxLayout="column"
        fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

        <div class="box" fxLayout="row" fxLayoutAlign="start center">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'GLOBAL.ALARM_TYPE_SELECTION' | translate }}</mat-label>
            <mat-select [(ngModel)]="codeKey" name="aggr" style="text-transform: capitalize;">

              <mat-option *ngFor="let aggr of codeKeys" [value]="aggr.id" (click)="onChangeCodeKey()">

                <span>{{ aggr.label | translate }}</span>

              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <!-- aggregations dropdown -->
      <div *ngIf="!isMobile && aggregationsToShowInDropdown?.length > 0" class="interval-selector" fxLayout="column"
        fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">

        <div class="box" fxLayout="row" fxLayoutAlign="start center">
          <!-- <mat-icon class="icon">event_available</mat-icon> -->
          <mat-form-field appearance="fill">
            <mat-label>{{ 'GLOBAL.DROPDOWN_AGGREGATION_SELECTION' | translate }}</mat-label>
            <mat-select [(ngModel)]="aggrDropdown" name="aggr" style="text-transform: capitalize;">
              <mat-option (click)="changePageAggregation(aggrDropdown)">---</mat-option>

              <mat-option *ngFor="let aggr of aggregationsToShowInDropdown" [value]="aggr.id"
                (click)="changePageAggregation(aggrDropdown)">

                <span style="text-transform: capitalize;">{{ aggr.label }}</span>

              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <ff-available-machines-selection *ngIf="availableMachines?.list?.length > 0"
        [availableMachines]="availableMachines" (selected)="machineSelectionChange($event)">
      </ff-available-machines-selection>

    </div>

    <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < 6" [loadingData]="loadingData"
      style="margin: auto;">
    </ff-loading-card>

    <div *ngIf="pageState.value >= 6" fxLayout="column" class="w100 h100"
      [ngStyle]="{'min-height': isSmThanTablet ? '0px' : 'calc(100vh - 260px)'}">

      <div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start start"
        class="h100 w100" fxLayoutGap="8px">

        <div fxFlex fxLayout="column" fxLayoutAlign="start stretch" fxFlexOrder.lt-md="2" class="w100"
          [class.h100]="!isMobile" fxLayoutGap="8px">
          <ff-widget fxFlex="100"
            [widget]="{type: 'ff-plotly-chart-table', data: breakdownsData, machineProfile: machine.profile, config: breakdownsData.dataConfig }">
          </ff-widget>
        </div>

        <div fxFlex.gt-sm="{{dashboardConfig.secondColumnFlex}}" fxLayout="row wrap" fxLayoutAlign="stretch start"
          fxLayout.gt-sm="column" fxLayoutAlign.gt-sm="start stretch" fxFlexOrder.lt-md="1" class="w100"
          [class.h100]="!isSmThanTablet" fxLayoutGap="8px">

          <ff-widget *ngFor="let w of dashboardConfig.widgets" fxFlex="100"
            [fxFlex.sm]="'0 1 calc(' + (w.mobileFlex != null ? w.mobileFlex : 50) + '% - 12px)'"
            fxFlex.gt-sm="{{w.flex}}"
            [widget]="{ type: w.type, data: breakdownsData, machineProfile: machine.profile, config: w.config, title: w.title }">
          </ff-widget>

        </div>

      </div>

    </div>

  </div>

  <div fxFlex fxLayout="column" fxLayoutAlign="start start"
    *ngIf="!isMobile && pageState.value >= 6 && aggrDropdown != null" fxLayout="row" class="w100"
    style="margin-top: 8px;">

    <ff-widget fxFlex="100"
      [widget]="{type: 'ff-plotly-chart', data: breakdownsData, machineProfile: machine.profile, config: breakdownsData.aggrDataConfig, title: aggrPlotTitle }">
    </ff-widget>

  </div>

</div>