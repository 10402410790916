import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { AppConfigService } from './app-config.service';
import { CacheService } from './cache.service';
import { ClonerService } from './clone.service';
import { FiltersService } from './filters.service';
import { InternalDataService } from './internal-data.service';

try { var CustomParsing = require('./CustomParsings.js') }
catch (error) { console.log("CustomParsing.js not found!") }

@Injectable({
  providedIn: 'root'
})
export class StandardParsingService {

  public appInfo: any;
  public colors: any;

  constructor(
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public internalDataService: InternalDataService,
    public clonerService: ClonerService,
    public appConfigService: AppConfigService,
    public cacheService: CacheService,
  ) {

    this.appInfo = this.appConfigService.getAppInfo;

    let isDarkTheme = this.appInfo?.darkTheme;
    this.colors = isDarkTheme ? this.internalDataService.defaultPlotlyColorsDark : this.internalDataService.defaultPlotlyColors;
  }


  parseObjectTable(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    let list: any = [];

    if (data != null && Object.keys(data)?.length > 0) {

      let copyData: any = this.clonerService.deepClone(data);

      for (const key of Object.keys(copyData)) {

        let listToSearch = additionalConfig?.nestedKeyToSearch != null ? copyData[key]?.[additionalConfig?.nestedKeyToSearch] : copyData[key];

        if (listToSearch?.length > 0) {
          listToSearch.filter(element => element.start != null || element.end != null).forEach((element: any) => {

            try { element.duration = moment(element.end).diff(moment(element.start), 's') }
            catch (error) { console.log('Could not calculate duration') }

            if (additionalConfig?.configFromProfile) {
              let stateConfig: any = this.clonerService.deepClone(machine?.profile?.[additionalConfig?.configFromProfile ?? 'timeStates']?.find((x: any) => x?.[additionalConfig?.referenceIdKey ?? 'id'] == element.id));
              if (stateConfig != null) {
                element.mode = stateConfig.mode != null ? this.translate.instant(stateConfig.mode) : null;
                element.state = stateConfig.value != null ? this.translate.instant(stateConfig.value) : null;
              }
            }

            // Slider values
            element.timeStartHours = moment(element.start).diff(moment(element.start).startOf("day"), 's');
            element.timeEndHours = element.end != null ? moment(element.end).diff(moment(element.end).startOf("day"), 's') : 0;

            // Machine name
            element.machine = key;
            if (element.machine != null) element.machineP = this.internalDataService.parseMachinesLabel(element.machine, null, machineId);

            // Parse dates
            element.timeStartP = this.filterService.parseMoment(element.start, 'default', machine.timezone);
            element.timeEndP = this.filterService.parseMoment(element.end, 'default', machine.timezone);

            list.push(element);
          });
        }

      }
    }

    return list;
  }

  parseStandardTable(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    let listToSearch = additionalConfig?.nestedKeyToSearch != null ? data?.[additionalConfig?.nestedKeyToSearch] : data;

    if (listToSearch != null && listToSearch.length > 0) {

      let copyData: any = this.clonerService.deepClone(listToSearch);

      copyData.forEach((element: any) => {

        element.isActive = element.timeEnd == null;
        element.timeStartHours = moment(element.timeStart).diff(moment(element.timeStart).startOf("day"), 's');
        element.timeEndHours = element.timeEnd != null ? moment(element.timeEnd).diff(moment(element.timeEnd).startOf("day"), 's') : 0;

        element.duration = element.duration ?? null;
        element.code = this.internalDataService.parseAlarmsLabel(element.alarmCode, 'code', machineId);
        if (!element.message) element.message = this.internalDataService.parseAlarmsLabel(element.alarmCode, 'message', machineId);

        if (element.machine != null) element.machineP = this.internalDataService.parseMachinesLabel(element.machine, null, machineId);

        element.timeStartP = this.filterService.parseMoment(element.timeStart, 'default', machine.timezone);
        element.timeEndP = this.filterService.parseMoment(element.timeEnd, 'default', machine.timezone);

      });
      return copyData;
    }
    return []
  }

  parseTBF(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    let listToSearch = additionalConfig?.nestedKeyToSearch != null ? data?.[additionalConfig?.nestedKeyToSearch] : data;

    if (listToSearch != null && listToSearch.length > 0) {

      let copyData: any = this.clonerService.deepClone(listToSearch);

      copyData.forEach((element: any) => {

        if (element["prodId{recipeType}"] == 1) {
          element.qty = element.qty != null ? element.qty * 100 : null;
          element.customUnit = 'kg';
        }

      });
      return copyData;
    }
    return []
  }

  parseWarningsTable(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    let listToSearch = additionalConfig?.nestedKeyToSearch != null ? data?.[additionalConfig?.nestedKeyToSearch] : data;

    if (listToSearch != null && listToSearch.length > 0) {

      let copyData: any = this.clonerService.deepClone(listToSearch);

      copyData.forEach((element: any) => {

        element.isActive = element.timeEnd == null;
        element.timeStartHours = moment(element.timeStart).diff(moment(element.timeStart).startOf("day"), 's');
        element.timeEndHours = element.timeEnd != null ? moment(element.timeEnd).diff(moment(element.timeEnd).startOf("day"), 's') : 0;

        element.duration = element.duration ?? null;
        element.code = this.internalDataService.parseWarningsLabel(element.alarmCode, 'code', machineId);
        if (!element.message) element.message = this.internalDataService.parseWarningsLabel(element.alarmCode, 'message', machineId);

        if (element.machine != null) element.machineP = this.internalDataService.parseMachinesLabel(element.machine, null, machineId);

        element.timeStartP = this.filterService.parseMoment(element.timeStart, 'default', machine.timezone);
        element.timeEndP = this.filterService.parseMoment(element.timeEnd, 'default', machine.timezone);

      });
      return copyData;
    }
    return []
  }

  parsePareto(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    try {
      _this.machine.profile.aggrColors = _this.aggrDropdown != null ? data?.aggregations.map((x: any) => x.id).filter(this.filterService.onlyUnique).map((x: any, idx: any) => {
        return {
          id: x,
          color: this.colors[idx]
        }
      }) : [];
    } catch (error) { console.log(error) }

    return data;
  }

  parseFailurePareto(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    try {

      if (_this.count == 0) {
        _this.customFilterButtons = this.buildFilterButtons([
          {
            variable: "groupId",
            labelVariable: "group",
            label: "MAINTENANCE.GROUP"
          },
          {
            variable: "subgroupId",
            labelVariable: "subgroup",
            label: "MAINTENANCE.SUBGROUP"
          }
        ], data?.pareto ?? []);
      }

    } catch (error) { console.log(error) }

    return data;
  }

  addNumericIdFromColumn(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    try {
      data?.forEach(row => {
        try { row[additionalConfig?.outputKey] = parseInt(row[additionalConfig?.inputKey]) }
        catch (error) { row[additionalConfig?.outputKey] = row[additionalConfig?.inputKey] }
      })
    } catch (error) { console.log(error) }

    return data;

  }

  parseReelTraceability(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    // console.log({ data });

    if (data != null && data.length > 0) {

      data.forEach((element: any) => {

        element.timeStart = element.timeFrom;
        element.timeEnd = element.timeTo;
        element.duration = element.duration ?? moment(element.timeEnd).diff(moment(element.timeStart), 's');

        element.prodSpeed = element.duration != 0 ? element.reelLength / element.duration : null;
        element.scrapBoolean = element.reelLength <= element.reelLengthSet * (1 - 5 / 100);
        element.scrapProp = element.scrapBoolean ? 'Scrap' : 'Good';
        element.energyCons = element.energyCoeff * element.reelMass;
        element.scrapMeters = element.reelLength - element.goodMeters;
        element.ratioMeters = element.scrapMeters / element.reelLength;

        try {
          element.takeUpType = _this.translate.instant(element.takeUp.split(";")[0] ?? "-");
          element.takeUp = element.takeUp.split(";")[1];
        } catch (error) { }

        try {
          element.urlPath = ["/" + _this.machineId, 'standard-dashboard-table-page'];
          element.urlQueryParams = {
            sectionName: 'reelAnalytics',
            tabName: 'reelTraceability',
            detailTabName: 'reelDetail',
            reelId: element.reelId,
            start: element.timeFrom,
            end: element.timeTo,
            reelLength: element.reelLength,
            takeUp: element.takeUp,
          };
        } catch (error) {
          console.log(error);
        }

      });

      return data;
    }
    return []

  }

  parseReelDetails(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    // console.log({ data });

    if (data != null && Object.entries(data)?.length > 0) {

      data.timeStart = data.timeFrom;
      data.timeEnd = data.timeTo;
      data.duration = data.duration ?? moment(data.timeEnd).diff(moment(data.timeStart), 's');

      data.prodSpeed = data.duration != 0 ? data.reelLength / data.duration : null;
      data.scrapBoolean = data.reelLength <= data.reelLengthSet * (1 - 5 / 100);
      data.scrapProp = data.scrapBoolean ? 'Scrap' : 'Good';
      data.energyCons = data.energyCoeff * data.reelMass;
      data.scrapMeters = data.reelLength - data.goodMeters;
      data.ratioMeters = data.scrapMeters / data.reelLength;

      try {
        data.takeUpType = _this.translate.instant(data.takeUp.split(";")[0] ?? "-");
        data.takeUp = data.takeUp.split(";")[1];
      } catch (error) { }

      try {
        let tableObj: any = {};
        Object.entries(data)?.forEach(([k, v], index) => {
          if (typeof v != 'object' && !Array.isArray(v)) tableObj[k] = v;
        });
        data.table = [tableObj];
      } catch (error) {
        console.log(error);
      }

      return data;
    }
    return [];

  }

  parseImagesTableIngenya(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    // console.log({ data });

    let newData: any = this.clonerService.deepClone(data ?? []);

    _this.aggregatedTable = false;

    let mtl = _this.additionalDashboardConfig?.maxTableLengthBeforeAutomaticAggregation ?? 2000;
    let nr = _this.additionalDashboardConfig?.numberOfRowsAfterAutomaticAggregation ?? 200;

    if (newData?.length > mtl) {

      // console.time('cycleAggregator');

      _this.aggregatedTable = true;

      let bucketNumber = 1;
      let aggrData = newData.sort(this.filterService.sortByProperty('timestamp', 'asc', true)).reduce((acc, val, index) => {

        let aggrObj: any = {
          timeStart: val?.timestamp,
          timeEnd: val?.timestamp,
          images: 1,
          badPieces: 0,
          goodPieces: 0,
          score: [val.score],
        };

        if (index < newData?.length / nr * bucketNumber) {
          try {
            // console.log(acc[bucketNumber]);

            acc[bucketNumber].timeEnd = aggrObj?.timeEnd;
            acc[bucketNumber].images++;
            if (val.labelAuto == 0) acc[bucketNumber].goodPieces++;
            else acc[bucketNumber].badPieces++;
            acc[bucketNumber].score.push(val.score);
          } catch (error) {
            acc[bucketNumber] = aggrObj;
          }
        } else {
          bucketNumber++;
          acc[bucketNumber] = aggrObj;
        }

        return acc;

      }, {});

      aggrData = (Object.entries(aggrData) as any)?.reduce((acc, val) => {

        let aggrVal = val[1];
        if (aggrVal.score?.length > 0) aggrVal.avgScore = this.filterService.average(aggrVal.score);
        acc.push(aggrVal);
        return acc;
      }, []);

      // console.timeEnd('cycleAggregator');

      // console.log({ aggrData });

      return aggrData;

    }

    let colorArray = _this.machine.profile.ingenyaLabelsColors ?? [];
    try {
      colorArray = colorArray.concat(data?.map((x: any) => x.labelAuto).filter(x => colorArray.findIndex(c => c?.id == x) == -1).filter(this.filterService.onlyUnique).map((x: any, idx: any) => {
        return {
          id: x,
          color: this.colors[idx]
        }
      })) ?? [];
    } catch (error) { console.log(error) }

    return newData?.reduce((finalData, element) => {

      element.timeStartHours = moment(element.timestamp).diff(moment(element.timestamp).startOf("day"), 's');
      element.timeEndHours = element.timestamp != null ? moment(element.timestamp).diff(moment(element.timestamp).startOf("day"), 's') : 0;

      element.autoLabelColor = colorArray?.find(x => x.id == element.labelAuto)?.color;
      element.userLabelColor = colorArray?.find(x => x.id == element.labelUser)?.color;

      finalData.push(element);

      return finalData;
    }, []) ?? [];

  }

  parseMaintHistory(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    let finalData = (data ?? [])?.reduce((acc, val) => {

      if (val?.interventionType == "proposal") {
        val.thirdColor = true;
        val?.components?.forEach(comp => comp.thirdColor = true);
      }
      acc.push(val);
      return acc;
    }, []);

    // console.log(finalData);

    return finalData;
  }

  // MACHINE DOCUMENTATION
  parseMachineDocumentation(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    _this.currentUrlPathString = (data?.elements ?? [])?.[0]?.completePath?.split("/")?.reduce((acc, val, idx, ar) => {
      if (idx + 1 == ar?.length) return acc;
      acc += val + "/";
      return acc;
    }, "");

    let folderNamesToDisable = [
      {
        folderName: "FAT",
        referenceComponentFlagId: "endFATInProgress"
      },
      {
        folderName: "SAT",
        referenceComponentFlagId: "endSATInProgress"
      }
    ];

    let satMode = _this.cacheService.get("enableSAT");
    let deviationsFolderString = `./documentation/${satMode ? 'SAT' : 'FAT'}`;

    let finalData = (data?.elements ?? [])?.reduce((acc, val) => {

      if (val?.name == 'empty.html') return acc;

      if (val.lastAnnotationDate != null && moment(val.lastAnnotationDate) > moment(val.lastUpdate)) {
        val.lastUpdate = val.lastAnnotationDate;
      }

      let filesConfig: any = this.clonerService.deepClone(machine?.profile?.filesConfig ?? _this.appConfig?.machineDocumentation?.fileExplorer?.filesConfig ?? []);
      let fileConfig: any = this.clonerService.deepClone(filesConfig?.find(file => {

        // If the element has nested elements, return folder
        if (val?.elements?.length > 0) return file.id == 'folder';

        // Find extension
        let extension = val?.name?.split(".")?.[1];

        // If no extension, then type is unknown
        if (extension == null) return file.id == 'unknown';

        // Find if extension is included in list of extensions from config
        return file.extensions?.includes(extension);
      }));

      // If the extension is unknown, assign default fileConfig
      if (fileConfig == null) fileConfig = this.clonerService.deepClone(filesConfig?.find(file => file.id == 'unknown'));

      val.icon = fileConfig;
      val.type = fileConfig?.id;
      val.disabledButtons = fileConfig?.disabledButtons;
      val.iconButtons = fileConfig?.iconButtons;
      val.iconClickFunction = fileConfig?.iconClickFunction;

      // Disable all interactions if the folder is FAT/SAT and the end FAT/SAT is in progress
      _this.disableTableInteractions = folderNamesToDisable.some(x => val.completePath?.includes(`/${x?.folderName}/`) && _this?.[x.referenceComponentFlagId]);

      // Disable all interactions if the folder is FAT/SAT and the end FAT/SAT is in progress
      val.disabledDueToEndFatInProgress = _this.disableTableInteractions;

      val.hideButtons = {
        deleteFile: this.internalDataService.getSpecificPermission("base-customer") || val.disabledDueToEndFatInProgress,
      };

      if (val.completePath == deviationsFolderString) {
        val.elements?.forEach((nVal, nIndex) => {
          if (nVal.elements?.length > 0 && nVal.numberOfAnnotations == null) {
            val.elements[nIndex].numberOfAnnotations = this.filterService.calculateNumberOfComments(nVal);
            val.elements[nIndex].numberOfFiles = this.filterService.calculateNumberOfNestedRows(nVal);
            val.elements[nIndex].numberOfFilesWithComments = this.filterService.calculateNumberOfNestedRows(nVal, "elements", true);
            val.elements[nIndex].commentedFilesNames = this.filterService.getCommentedFilesList(nVal, "elements", true);
          }
        })
      }

      acc.push(val);
      return acc;
    }, []);

    let finalList: any = {
      elements: finalData
    };

    if (_this.count == 0) {

      let deviationsFolder = finalData?.find(x => x.completePath == deviationsFolderString)?.elements;
      _this.allDeviations = deviationsFolder?.reduce((acc, val) => {
        if (val?.numberOfFiles) {
          acc.push({
            path: val?.name,
            numberOfFiles: val?.numberOfFiles,
            numberOfAnnotations: val?.numberOfAnnotations,
            numberOfFilesWithComments: val?.numberOfFilesWithComments,
            commentedFilesNames: val?.commentedFilesNames,
          })
        }
        return acc;
      }, []) ?? [];

      // console.log(_this.allDeviations);
    }

    // if (finalList.elements?.length > 0) {
    //   finalList.numberOfAnnotations = this.filterService.calculateNumberOfComments(finalList);
    //   finalList.numberOfFiles = this.filterService.calculateNumberOfNestedRows(finalList);
    // }

    // console.log({ finalList });

    return finalList;
  }

  parseMachineDocumentationWithoutFAT(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    _this.currentUrlPathString = (data?.elements ?? [])?.[0]?.completePath?.split("/")?.reduce((acc, val, idx, ar) => {
      if (idx + 1 == ar?.length) return acc;
      acc += val + "/";
      return acc;
    }, "");

    let finalData = (data?.elements ?? [])?.reduce((acc, val) => {

      if (val?.name == 'empty.html') return acc;

      let filesConfig: any = this.clonerService.deepClone(machine?.profile?.filesConfig ?? _this.appConfig?.machineDocumentation?.fileExplorer?.filesConfig ?? []);
      let fileConfig: any = this.clonerService.deepClone(filesConfig?.find(file => {

        // If the element has nested elements, return folder
        if (val?.elements?.length > 0) return file.id == 'folder';

        // Find extension
        let extension = val?.name?.split(".")?.[1];

        // If no extension, then type is unknown
        if (extension == null) return file.id == 'unknown';

        // Find if extension is included in list of extensions from config
        return file.extensions?.includes(extension);
      }));

      // If the extension is unknown, assign default fileConfig
      if (fileConfig == null) fileConfig = this.clonerService.deepClone(filesConfig?.find(file => file.id == 'unknown'));

      val.icon = fileConfig;
      val.type = fileConfig?.id;
      val.disabledButtons = fileConfig?.disabledButtons;
      val.iconButtons = fileConfig?.iconButtons;
      val.iconClickFunction = fileConfig?.iconClickFunction;

      let avoidDeletePermissionCheck = _this.additionalVariables?.avoidDeletePermissionCheck;
      let deleteFilePermission = _this.additionalVariables?.deleteFilePermission ?? "base-customer";

      val.hideButtons = {
        deleteFile: avoidDeletePermissionCheck ? false : this.internalDataService.getSpecificPermission(deleteFilePermission),
      };

      acc.push(val);
      return acc;
    }, []);

    let finalList: any = {
      elements: finalData
    };

    return finalList;
  }

  // INTERNAL MACHINE DOCUMENTATION
  parseInternalMachineDocumentation(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    _this.currentUrlPathString = (data?.elements ?? [])?.[0]?.completePath?.split("/")?.reduce((acc, val, idx, ar) => {
      if (idx + 1 == ar?.length) return acc;
      acc += val + "/";
      return acc;
    }, "");

    let finalData = (data?.elements ?? [])?.reduce((acc, val) => {

      if (val?.name == 'empty.html') return acc;

      if (val.lastAnnotationDate != null && moment(val.lastAnnotationDate) > moment(val.lastUpdate)) {
        val.lastUpdate = val.lastAnnotationDate;
      }

      let filesConfig: any = this.clonerService.deepClone(machine?.profile?.filesConfig ?? _this.appConfig?.machineDocumentation?.fileExplorer?.filesConfig ?? []);
      let fileConfig: any = this.clonerService.deepClone(filesConfig?.find(file => {

        // If the element has nested elements, return folder
        if (val?.elements?.length > 0) return file.id == 'folder';

        // Find extension
        let extension = val?.name?.split(".")?.[1];

        // If no extension, then type is unknown
        if (extension == null) return file.id == 'unknown';

        // Find if extension is included in list of extensions from config
        return file.extensions?.includes(extension);
      }));

      // If the extension is unknown, assign default fileConfig
      if (fileConfig == null) fileConfig = this.clonerService.deepClone(filesConfig?.find(file => file.id == 'unknown'));

      val.icon = fileConfig;
      val.type = fileConfig?.id;
      val.disabledButtons = fileConfig?.disabledButtons;
      val.iconButtons = fileConfig?.iconButtons;
      val.iconClickFunction = fileConfig?.iconClickFunction;

      acc.push(val);
      return acc;
    }, []);

    let finalList: any = {
      elements: finalData
    };

    return finalList;
  }

  parseHistoricFat(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    let parsedData = data?.reduce((acc, val, index) => {

      let historicFatConfig: any = this.clonerService.deepClone(machine?.profile?.historicFatActions ?? []);

      // val.status = 2;
      val.icon = historicFatConfig?.find(x => x.id == val.action);
      val.disabledButtons = {
        getFATLogs: !val.icon?.showReview || val.status == null || val.status == 4
      };
      acc.push(val);
      return acc;
    }, [])

    // console.log({ parsedData });
    return parsedData;

  }
  // // // // // //
  // BARILLA
  // // // // // //
  parseRollingMillsSettings(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    // let parsedData = data?.sort(this.filterService.sortByProperty("timeStart", "asc", true))?.reduce((acc, val, index) => {
    //   // val.id = val?.id ?? this.filterService.generateRandomString();
    //   acc.push(val);
    //   return acc;
    // }, [])

    // // console.log({parsedData});
    // return parsedData;
    return data;

  }

  // BATCHES
  parseBarillaBatches(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    let parsedData = data?.reduce((acc, val) => {

      let detailPageConfig = null;
      try { detailPageConfig = btoa(JSON.stringify(val)) }
      catch (error) { console.log(error) }

      try {
        val.detailUrlPath = [_this.machineId + '/standard-dashboard-table-page'];
        val.detailQueryParams = {
          sectionName: 'batchTraceability',
          tabName: 'table',
          detailTabName: 'pulitura',
          batchId: val.batchId,
          detailPageConfig: detailPageConfig
        };
      } catch (error) {
        console.log(error);
      }

      if (val?.phases?.length) {

        let phases = this.clonerService.deepClone(val?.phases)

        val.phases = val.phases?.reduce((phaseAcc, phaseVal) => {

          phaseVal.phases = phases;
          phaseVal.batchId = val.batchId;

          let detailPageConfig = null;
          try { detailPageConfig = btoa(JSON.stringify(phaseVal)) }
          catch (error) { console.log(error) }

          phaseVal.detailUrlPath = [_this.machineId + '/standard-dashboard-table-page'];
          phaseVal.detailQueryParams = {
            sectionName: 'batchTraceability',
            tabName: 'table',
            detailTabName: _this.machine.profile?.phases?.find(x => x.id == phaseVal.phaseId)?.pageName,
            batchId: val.batchId,
            detailPageConfig: detailPageConfig
          };

          phaseAcc.push(phaseVal);
          return phaseAcc;
        }, [])
      }

      acc.push(val);
      return acc;

    }, []);

    return parsedData ?? [];

  }

  // SUBBATCHES - DISTRIBUTION
  parseBarillaSubbatchesDistribution(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    if (_this.additionalVariables == null) _this.additionalVariables = {};

    let c_batches = this.cacheService.get("filteredBatches");

    // console.log({ c_batches });

    if (c_batches != null) {
      data = data.filter(x => c_batches?.[x.parent_batch_id]?.selected)
    }
    let batches: any = this.clonerService.deepClone(data ?? []);

    batches = batches?.reduce((acc, val) => {
      if (acc?.[val.parent_batch_id] != null) return acc;
      acc[val.parent_batch_id] = {
        label: val.parent_batch_id,
        selected: true
      };
      return acc;
    }, {});

    // console.log({ batches });

    _this.additionalVariables.batches = this.clonerService.deepClone(batches);

    return data;

  }

  // SUBBATCHES - COMPARISON
  parseBarillaSubbatchesComparison(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    if (_this.additionalVariables == null) _this.additionalVariables = {};
    let subbatches: any = this.clonerService.deepClone(data ?? []);

    _this.additionalVariables.subbatch1 = subbatches?.[0]?.child_batch_uid;
    _this.additionalVariables.subbatch2 = subbatches?.[1]?.child_batch_uid;

    return this.getSubbatchesComparisonTable(_this, subbatches);

  }

  onSubbatchChange(_this, subbatch, type) {

    if (_this.additionalVariables == null) _this.additionalVariables = {};
    let subbatches: any = this.clonerService.deepClone(_this.unparsedRespone ?? []);

    _this.additionalVariables[type] = subbatch;

    return this.getSubbatchesComparisonTable(_this, subbatches);

  }

  getSubbatchesComparisonTable(_this, subbatches) {

    let subbatchIds = [_this.additionalVariables.subbatch1, _this.additionalVariables.subbatch2];
    _this.additionalVariables.subbatches = subbatches;

    subbatches = subbatchIds?.reduce((acc, val) => {

      let subbatch = subbatches?.find(x => x.child_batch_uid == val);
      if (subbatch == null) return acc;
      acc.push(subbatch);
      return acc;

    }, []);

    if (subbatches?.length < 1) return [];
    let parsedData = [];

    Object.keys(subbatches[0])?.forEach((k) => {
      let obj: any = {
        id: k,
        val1: subbatches[0]?.[k],
        val2: subbatches[1]?.[k],
      };

      obj.diffPerc = Math.abs(1 - (obj["val2"] / obj["val1"]));
      if (isNaN(obj.diffPerc) || !isFinite(obj.diffPerc)) obj.diffPerc = null;
      parsedData.push(obj);
    });

    // console.log({ parsedData });

    return parsedData ?? [];

  }

  // SUBBATCHES - PAIR CORRELATION
  parseBarillaSubbatchesPairCorrelation(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    if (_this.additionalVariables == null) _this.additionalVariables = {};

    let batches: any = this.clonerService.deepClone(data ?? []);

    batches = batches?.reduce((acc, val) => {
      if (acc.find(x => x.parent_batch_id == val.parent_batch_id) != null) return acc;
      acc.push(val);
      return acc;
    }, []);

    // console.log({ batches });
    let variablesList = _this.additionalDashboardConfig?.variablesList ?? Object.keys(data?.[0]);

    _this.additionalVariables.batch = this.cacheService.get("selectedBarillaBatch") ?? data?.[0]?.parent_batch_id;
    _this.additionalVariables.variable = this.cacheService.get("selectedBarillaVariable") ?? variablesList[0];

    if (!variablesList?.includes(_this.additionalVariables.variable)) _this.additionalVariables.variable = variablesList[0];

    this.cacheService.set("selectedBarillaBatch", null);
    this.cacheService.set("selectedBarillaVariable", null);

    _this.additionalVariables.variables = variablesList?.map(x => {
      return {
        id: x,
        label: x
      };
    });
    _this.additionalVariables.batches = this.clonerService.deepClone(batches);

    return this.getSubbatchesPairCorrelationData(_this, data);

  }

  onSubbatchPairChange(_this, variable, type) {

    if (_this.additionalVariables == null) _this.additionalVariables = {};
    let data: any = this.clonerService.deepClone(_this.unparsedRespone ?? []);

    _this.additionalVariables[type] = variable;

    return this.getSubbatchesPairCorrelationData(_this, data);

  }

  getSubbatchesPairCorrelationData(_this, batches) {

    let parsedData = (batches ?? [])?.reduce((acc, val) => {
      if (val.parent_batch_id != _this.additionalVariables.batch) return acc;
      val.yVar = val?.[_this.additionalVariables.variable];
      acc.push(val);
      return acc;
    }, []);

    // console.log({ parsedData });

    return parsedData;
  }

  // // // // // // // // // // // // // // // // // // // // //
  // OPERATOR KNOWLEDGE

  parseOperatorKnowledge(data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    let mainVariable = additionalConfig?.mainVariable ?? 'OKformula';
    let timeVariable = additionalConfig?.timeVariable ?? '_time';

    try {

      let mainVariableMax = this.filterService.getMaxFromArray(data.bar, mainVariable);
      data.bar = data.bar.reduce((acc, val) => {
        val[mainVariable + val.id] = val?.[mainVariable];
        val[mainVariable + 'Max'] = mainVariableMax;
        acc.push(val);
        return acc;
      }, []);
      data.bar.sort(this.filterService.sortByProperty(timeVariable, "asc", true));
    }
    catch (error) { console.log(error) }

    this.createAggregationColors(_this, data, additionalConfig);
    return data;
  }

  createAggregationColors(_this, data, additionalConfig?) {
    try {
      _this.machine.profile.aggrColors = data?.[additionalConfig?.aggregationKeyToMap ?? 'scatter'].map((x: any) => x.id).filter(this.filterService.onlyUnique).map((x: any, idx: any) => {
        return {
          id: x,
          color: this.colors[idx]
        }
      });
    } catch (error) { console.log(error) }
  }

  // // // // // // // // // // // // // // // // // // // // //
  // DO NOTHING
  doNothing(data: any) { return data }

  // // // // // // // // // // // // // // // // // // // // //
  // DETAIL SECTION WITH CUSTOM DASHBOARD
  // // // // // // // // // // // // // // // // // // // // //

  barillaGetBatchConfig(_this, query, payload) {

    if (_this.detailPageConfig?.batchId != null) {

      let newBreadcrumb = _this.clonerService.deepClone(_this.breadcrumb);
      newBreadcrumb[2] = this.translate.instant("BATCH_TRACEABILITY.BATCH_X", {
        batchId: _this.detailPageConfig?.batchId
      });
      _this.internalDataService.setBreadcrumb(newBreadcrumb);

    }

    let phaseId = _this.additionalDashboardConfig?.phaseId;

    if (phaseId == null) return;

    let phaseConfig: any = _this.detailPageConfig?.phases?.find(x => x.phaseId == phaseId);

    if (phaseConfig?.phaseStart != null) query.from = phaseConfig.phaseStart;
    if (phaseConfig?.phaseEnd != null) query.to = phaseConfig.phaseEnd;

  }

  // // // // // // // // // // // // // // // // // // // // //
  // POLLING STATE FUNCTIONS
  // // // // // // // // // // // // // // // // // // // // //

  async checkFatState(_this: any, count: any) {

    let url = `/apif/machine-documentation/check-fat-status/${_this.machineId}`;

    let payload = this.filterService.addStandardInformations({});

    let data = await _this.apiService.sendPostRequest(url, payload).toPromise();

    let copyData: any = this.clonerService.deepClone(data?.body ?? []);

    let satMode = _this.cacheService.get("enableSAT");

    let endFAT = satMode ? copyData?.endSAT : copyData?.endFAT;
    let updFAT = satMode ? copyData?.updSAT : copyData?.updFAT;

    _this.endFATInProgress = false;
    _this.endSATInProgress = false;

    if ([0, 1].includes(copyData?.endFAT?.status)) _this.endFATInProgress = true;
    if ([0, 1].includes(copyData?.endSAT?.status)) _this.endSATInProgress = true;

    if ([0, 1].includes(endFAT?.status)) _this.filesDeleteDate = endFAT?.exp_date;
    else _this.filesDeleteDate = null;

    if (_this.filesDeleteDate != null && _this.completeDashboardConfig?.dashboardData?.filesDeleteDate == null) {
      try {
        _this.completeDashboardConfig.dashboardData.filesDeleteDate = _this.filesDeleteDate;
        _this.completeDashboardConfig = { ..._this.completeDashboardConfig }
      } catch (error) { }
    }

    if (!satMode) _this.noEndFatAvailable = endFAT?.status == -1 || endFAT?.status == 4;

    // if (_this.noEndFatAvailable) _this.cacheService.set("enableSAT", false);

    if (updFAT?.status == -1) {
      _this.noFatAvailable = true;

      if (_this.pageState.value == 4) {
        _this.dispatcherService.getDispatch(_this, 300);
      }
    }
    else {
      _this.noFatAvailable = false;

      if ([0, 1].includes(updFAT?.status)) {
        _this.loadingData = {
          progressBarConfig: {
            value: (updFAT?.update?.uploadedFiles ?? 0) / updFAT?.update?.totFiles
          },
          message: this.translate.instant("FILE_EXPLORER.UPLOADING_FILES") + ` ${updFAT?.update?.uploadedFiles ?? 0}/${updFAT?.update?.totFiles ?? 0}`,
        };

        _this.pageState.next(4);
      }
      else {

        if ([0, 1].includes(_this.previousFATState)) {
          _this.internalDataService.openSnackBar(_this.translate.instant('FILE_EXPLORER.FAT_COMPLETED'), 'right', 'bottom', 4000, '', ["success"]);
        }
        if (_this.pageState.value == 4) {
          _this.dispatcherService.getDispatch(_this, 300);
        }

        // if (count > 0) _this.pageState.next(6);
        // if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
      }

    }

    _this.previousFATState = updFAT?.status;

    // console.log(copyData);

  }

  // Internal Acceptance Test
  async checkIatState(_this: any, count: any) {

    let url = `/apif/internal-documentation/check-iat-status/${_this.machineId}`;

    let payload = this.filterService.addStandardInformations({});

    let data = await _this.apiService.sendPostRequest(url, payload).toPromise();

    let copyData: any = this.clonerService.deepClone(data?.body ?? []);

    let IAT = copyData?.updIAT;

    if (_this.filesDeleteDate != null && _this.completeDashboardConfig?.dashboardData?.filesDeleteDate == null) {
      try {
        _this.completeDashboardConfig.dashboardData.filesDeleteDate = _this.filesDeleteDate;
        _this.completeDashboardConfig = { ..._this.completeDashboardConfig }
      } catch (error) { }
    }

    if (IAT?.status == -1) {
      _this.noFatAvailable = true;

      if (_this.pageState.value == 4) {
        _this.dispatcherService.getDispatch(_this, 300);
      }
    }
    else {
      _this.noFatAvailable = false;

      if ([0, 1].includes(IAT?.status)) {
        _this.loadingData = {
          progressBarConfig: {
            value: (IAT?.update?.uploadedFiles ?? 0) / IAT?.update?.totFiles
          },
          message: this.translate.instant("FILE_EXPLORER.UPLOADING_FILES") + ` ${IAT?.update?.uploadedFiles ?? 0}/${IAT?.update?.totFiles ?? 0}`,
        };

        _this.pageState.next(4);
      }
      else {

        if ([0, 1].includes(_this.previousFATState)) {
          _this.internalDataService.openSnackBar(_this.translate.instant('FILE_EXPLORER.FAT_COMPLETED'), 'right', 'bottom', 4000, '', ["success"]);
        }
        if (_this.pageState.value == 4) {
          _this.dispatcherService.getDispatch(_this, 300);
        }

      }

    }

    _this.previousFATState = IAT?.status;

  }

  // // // // // // // // // // // // // // // // // // // // //
  // USEFUL FUNCTIONS
  // // // // // // // // // // // // // // // // // // // // //

  buildFilterButtons(additionalFilterButtons, list) {

    let buttons: any[] = [];

    if (additionalFilterButtons?.length > 0) {

      additionalFilterButtons?.forEach((attribute: any) => {

        attribute = this.clonerService.deepClone(attribute);

        attribute.options = this.clonerService.deepClone(
          list?.reduce((acc, x) => {

            let variable = x[attribute.variable];
            let label = x[attribute.labelVariable];
            let transl = label != null && label != '' ? this.translate.instant(label.toString()) : variable;
            let val = {
              id: variable,
              label: transl,
              selected: true
            }

            if (acc?.findIndex(a => a.id == variable) == -1) acc.push(val);
            return acc;
          }, [])
        );

        buttons.push(attribute);

      });

    }

    return buttons;
  }

  // // // // // // // // // // // // // // // // // // // // //
  // CUSTOM FUNCTIONS
  // // // // // // // // // // // // // // // // // // // // //
  custom(customFunctionName: any, data: any, machineId?: any, machine?: any, additionalConfig?: any, _this?: any) {

    try {
      return new CustomParsing(data, machineId, machine, additionalConfig)?.[customFunctionName]();
    } catch (error) {
      console.log(error);
      return [];
    }

  }

}
