import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { FiltersDialogComponent } from 'src/app/components/filters-dialog/filters-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';
import { ComponentVariablesSelectionComponent } from '../component-variables-selection/component-variables-selection.component';
import { TimeStatesDialogComponent } from '../time-states-dialog/time-states-dialog.component';
import { EventTypesFilterDialogComponent } from './event-types-filter-dialog/event-types-filter-dialog.component';

import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PlotlyChartComponent } from 'src/app/components/widgets/plotly-chart/plotly-chart.component';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-continuous-exploration',
  templateUrl: './continuous-exploration.component.html',
  styleUrls: ['./continuous-exploration.component.scss']
})
export class ContinuousExplorationComponent implements OnInit, OnDestroy {

  @ViewChild('container', { read: ViewContainerRef }) containerRef!: ViewContainerRef;

  public componentsRef: any = {};
  public outOfThresholdVariables: any = [];
  public outOfThresholdTableInfos: any = [];

  public loadingData: any;
  public errorData: any;
  public errorDataMobile: any;

  public appConfig: any;
  public appInfo: any;
  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public spaceForEvents: boolean = false;

  public componentId: any;
  public componentsList: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public pollingTime: any;
  public pollingEvents: any;

  public maxStringsLength: any;

  public aggrDropdown: any = null;
  public timeStates: any = null;

  public aggregations: any;
  public aggregationsPayload: any;
  public aggregationsCustom: any;

  public consDropdown: any = null;
  public searchEvents: any;

  public excludeLine: boolean = true;
  public isFlags: boolean = false;

  public defaultInterval: any;

  public interval: any;
  public intervalConfig: any;
  public intervalAggregations: any;
  public showResetInterval: any;
  public aggrBackground: any;

  public csv: any;

  public minIntervalSeconds: number;
  public returnStates: any = 0;
  public enabledCSVDownload: any = 0;
  public atLeastOneFilter: any = false;
  public eventFilterOpened: any = false;
  public defaultEnableFilters: any = true;

  public intervalSeconds: any;
  public intervalMilliSeconds: any;

  public queryParams: any;
  public backButton: any;
  public timeAggrUnit: any = [1, 'hours']

  public buffers: any;
  public plotsConfig: any;
  public plotsConfigComponents: any;
  public traces: any;
  public variables: any = {
    list: [],
    selected: []
  };
  public events: any = {
    list: [],
    filtered: [],
    pageOptions: [25, 50, 100],
    pageSize: 50,
  };
  public periodsNumber: number = 8;
  public timeStatesList: any;
  public timeStateList: any;

  public filterTabsCopy: any;
  public filterTabs: any = [
    {
      "id": 1,
      "title": "CONTINUOUS_EXPLORATION.FILTERS.EVENT_TYPES",
      "icon": {
        "type": "icon",
        "icon": "filter_alt"
      },
      "toMap": "id",
      "toInclude": "filterId",
      "list": []
    },
    {
      "id": 2,
      "title": "CONTINUOUS_EXPLORATION.FILTERS.STATES",
      "icon": {
        "type": "icon",
        "icon": "dehaze"
      },
      "toMap": "id",
      "toInclude": "state",
      "list": []
    }
  ];
  public eventTypes: any = [];

  public parsedTablesCopy: any;
  public updatedTablesCopy: any;
  public atLeastOneComponentHasTablesAsAttribute: any;

  public variablesData: any;
  public monitoringData: any;

  public colors: any = [
    '#66DA26', '#546E7A', '#E91E63', '#FF9800',
    "#3F51B5", "#03A9F4", "#4CAF50", "#F9CE1D",
    "#33B2DF", "#D4526E", "#13D8AA", "#A5978B",
    "#4ECDC4", "#C7F464", "#81D4FA", "#FD6A6A",
    "#2B908F", "#F9A3A4", "#90EE7E", "#FA4443",
    "#449DD1", "#F86624", "#EA3546", "#662E9B",
    "#D7263D", "#1B998B", "#2E294E", "#F46036",
    "#F9C80E", "#43BCCD", "#69D2E7", '#008FFB',
    "#5C4742", "#8D5B4C", "#5A2A27", "#C4BBAF",
    "#A300D6", "#7D02EB", "#5653FE", "#2983FF",
    "#C5D86D", "#E2C044", "#00B1F2",
  ];

  public sectionName: any = 'machineRecorder';
  public dashboardName: any = 'machine-recorder';
  public dashboardConfig: any;

  public hiddenEventsMsg: any = false;

  public zoomedIntervalVariablesSub: Subscription;
  public zoomedIntervalVariables: any;
  public zoomedIntervalVariablesHistory: any = [];
  public maxZoomReached: boolean = false;

  public unparsedTableInfos: any;
  public eventsTableInfos: any;
  public tableInfos: any;
  public eventsColumns: any = [];
  public eventsData = new MatTableDataSource<any[]>();

  public mobileListener: Subscription;
  public isMobile: any;

  public showTraceName: any = false;

  public plotId: any = "continuous-exploration";
  public exportCsv: any = false;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set MatSort(ms: MatSort) {
    this.sort = ms;
    this.setSort();
  }

  setSort() {
    this.eventsData.sort = this.sort;
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 3, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.parseMachineProfile, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getVariablesConfig, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 },
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.getVariablesData, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 },
        { code: 302, function: this.dispatcherService.completeDispatch, nextState: 7 },
      ]
    },
    {
      state: 6,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 7 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    public mobile: MobileService
  ) {

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;

    this.showTraceName = this.appConfig?.showUnparsedTracesNamesInComponentsVariablesSelection;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile
      this.errorDataMobile = {
        type: 0,
        message: this.translate.instant('GLOBAL.MOBILE_NOT_AVAILABLE')
      };
    })

    this.breadcrumb = ['MACHINE_RECORDER.TITLE', 'CONTINUOUS_EXPLORATION.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.machineId = this.route.snapshot.params['machineId'];
    this.queryParams = this.route.snapshot.queryParams;

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);
    this.availableMachines = this.appConfig?.[this.sectionName]?.availableMachines ?? [];

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb = Object.assign([], this.breadcrumb);
        if (!newBreadcrumb.includes(value.machineName)) newBreadcrumb.push(value.machineName);
        if (this.queryParams.hasOwnProperty('variablesTimeseriesMode')) newBreadcrumb.push(this.queryParams.id)
        this.breadcrumb = newBreadcrumb;
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    let minIntervalSeconds = parseInt(this.appConfig.machineRecorder?.maxZoomSeconds);

    if (isNaN(minIntervalSeconds)) minIntervalSeconds = 5;
    else minIntervalSeconds = Math.max(1, minIntervalSeconds); // al minimo è uno

    this.minIntervalSeconds = minIntervalSeconds;

    this.pollingTime = this.appConfig.machineRecorder?.pollingContExploration ?? 0;
    this.pollingEvents = Subscription;

    this.interval = this.intervalService.getIntervalById('today');

    this.intervalConfig = {
      list: this.intervalService.getDefaultIntervals(2),
      selected: this.interval
    };

    this.monitoringData = null;


    this.zoomedIntervalVariablesSub = this.internalDataService.zoomedIntervalVariables.subscribe(value => {
      if (value != null) {

        this.showResetInterval = false;

        this.aggregations?.forEach((aggr: any, aggrIdx: any) => {
          this.cacheService.set(aggr.id + "List", null);
        });
        // this.cacheService.set("eventTypes", null);

        if (this.eventFilterOpened) {
          this.removeFilters(this, true);
        }

        this.pageState.next(6);
        this.getVariablesDataPolling(this, value);

        try {
          this.events.query.page = 1;
        } catch (error) { }

      }
    });

  }

  customTranslate(machine: any) {
    return machine === 'Line' ? this.translate.instant('GLOBAL.LINE') : machine;
  }

  openEventsDialog() {
    // console.log(button)

    let dialogOptions = this.clonerService.deepClone(this.eventTypes);

    let filtersDialog = this.dialog.open(FiltersDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant('CONTINUOUS_EXPLORATION.EVENT_SELECTION'),
        variable: this.translate.instant('button.label'),
        options: dialogOptions,
        forceSelection: true
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {
      if (result != null && result != '') {
        this.eventTypes = result.options;
        this.cacheService.set("eventTypes", this.eventTypes);
        this.pageState.next(6);

        if (!this.interval.enabledPolling) {
          this.getVariablesData(this, 0);
        } else {
          this.getVariablesDataPolling(this);
        }
      }
    });
  }

  openAggrDialog(aggr: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        // console.log('afterClosed', result);
        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
        this.pageState.next(6);

        if (!this.interval.enabledPolling) {
          this.getVariablesData(this, 0);
        } else {
          this.getVariablesDataPolling(this);
        }
      }


    });
  };

  openTimeStatesDialog() {

    let c_timeStates = this.cacheService.get("timeStates");
    this.timeStateList = this.clonerService.deepClone((c_timeStates != null ? c_timeStates : this.timeStates).filter((x: any) => x.show));

    console.log(c_timeStates, this.timeStateList)

    let filtersDialog = this.dialog.open(TimeStatesDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant("CONTINUOUS_EXPLORATION.STATE_SELECTION"),
        timeStateList: this.timeStateList
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        try {
          result.timeStateList.forEach((s: any) => {
            let sIdx = this.timeStates.findIndex((x: any) => x.state == s.state);
            if (sIdx != -1) {
              this.timeStates[sIdx].enabled = s.enabled;
            }
          })
        } catch (error) {
          console.log(error);
        }
        this.cacheService.set("timeStates", this.timeStates);
        this.pageState.next(5);
        setTimeout(() => {
          this.drawPlot(this, this.variablesData, true);
        }, 250);
      }


    });
  };

  openEventFilter() {

    this.filterTabsCopy = this.clonerService.deepClone(this.filterTabs);

    console.log('filterTabsCopy', this.filterTabsCopy);

    this.filterTabsCopy[0].list = this.clonerService.deepClone(this.eventTypes);

    // this.aggregations?.forEach((aggr: any, aggrIdx: any) => {
    //   this.filterTabsCopy.find((x: any) => x.id == aggr.id).list = this.clonerService.deepClone(this[aggr.id + "List"]);
    // });

    if (this.returnStates) {
      if (this.timeStatesList == null) {
        this.timeStatesList = this.clonerService.deepClone(this.timeStates.map((x: any) => {
          return {
            enabled: true,
            id: x.state != null && typeof x.state == 'string' && x.state.startsWith("timeState") ? (!isNaN(Number(x.state.split("timeState")[1])) ? Number(x.state.split("timeState")[1]) : x.state.split("timeState")[1]) : x.state,
            value: x.value,
            color: x.color
          };
        }));
      }

      try {
        let index = this.filterTabsCopy.findIndex((x: any) => x.id == 2);
        if (index != -1) {
          this.filterTabsCopy[index].list = this.clonerService.deepClone(this.timeStatesList);
        } else {

          if (this.timeStates?.length > 0) {
            this.filterTabsCopy.splice(1, 0, {
              "id": 2,
              "title": this.translate.instant("CONTINUOUS_EXPLORATION.FILTERS.STATES"),
              "icon": {
                "type": "icon",
                "icon": "dehaze"
              },
              "toMap": "id",
              "toInclude": "state",
              list: this.clonerService.deepClone(this.timeStatesList),
            });
          }
        }
      } catch (error) { }

    }

    const eventTypesFilterDialog = this.dialog.open(EventTypesFilterDialogComponent,
      {
        panelClass: 'ff-dialog',
        width: 'fit-content',
        minHeight: '50%',
        data: {
          title: this.translate.instant("CONTINUOUS_EXPLORATION.ALL_FILTERS"),
          filterTabsCopy: this.filterTabsCopy
        },
      });

    eventTypesFilterDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {

        this.eventFilterOpened = true;

        this.filterTabs = this.clonerService.deepClone(this.filterTabsCopy);

        this.eventTypes = this.clonerService.deepClone(this.filterTabsCopy[0].list);

        // this.aggregations?.forEach((aggr:any, aggrIdx:any) => {
        //   this[aggr.id + "List"] = this.clonerService.deepClone(this.filterTabsCopy.find((x: any) => x.id == aggr.id).list);
        // });

        if (this.returnStates) {
          let idx = this.filterTabs.findIndex((x: any) => x.id == 2);
          if (idx != -1) {
            this.timeStatesList = this.clonerService.deepClone(this.filterTabs[idx].list);
          }
        }

        this.cacheService.set("eventTypes", this.eventTypes);

        this.atLeastOneFilter = this.filterTabs.some((x: any) => x.id == 1 ? (x.list.some((y: any) => y.enabled == !this.defaultEnableFilters)) : x.list.some((y: any) => !y.enabled));

        this.filterEvents();
        this.drawPlot(this, this.variablesData);
        try {
          this.events.query.page = 1;
        } catch (error) { }

      }
    })
  }

  changePageAggregation(aggrDropdown: any) {

    this.aggrDropdown = this.clonerService.deepClone(aggrDropdown);

    this.pageState.next(6);
    if (!this.interval.enabledPolling) {
      this.getVariablesData(this, 0);
    } else {
      this.getVariablesDataPolling(this);
    }
  }

  openVariablesSelectionDialog() {
    const variablesDialog = this.dialog.open(ComponentVariablesSelectionComponent,
      {
        panelClass: 'ff-dialog',
        width: '40%',
        // height: '90%',
        data: {
          title: this.translate.instant("CONTINUOUS_EXPLORATION.VARIABLES_SELECTION"),
          componentId: this.componentId,
          groups: this.clonerService.deepClone(this.plotsConfigComponents)
        },
      });

    variablesDialog.afterClosed().subscribe((result: any) => {

      if (result != null && result != '') {
        try {

          // console.log(result);

          let recalcTraces = false;

          if (result.groups?.[result?.componentId]?.tables?.length) {

            recalcTraces = true;
            this.atLeastOneComponentHasTablesAsAttribute = true;

            this.updatedTablesCopy = this.clonerService.deepClone(this.parsedTablesCopy);

            result.groups?.[result.componentId]?.tables.forEach(table => {

              let parsedTableIdx = -1;

              if (Array.isArray(table.id)) parsedTableIdx = this.updatedTablesCopy.findIndex(x => x.id?.includes(table.id[0]));
              else parsedTableIdx = this.updatedTablesCopy.findIndex(x => x.id == table.id);

              if (parsedTableIdx != -1) this.updatedTablesCopy[parsedTableIdx] = { ...this.updatedTablesCopy[parsedTableIdx], ...table };

            });
          } else {
            if (this.atLeastOneComponentHasTablesAsAttribute) {
              recalcTraces = true;
              this.atLeastOneComponentHasTablesAsAttribute = false;
            }
            this.updatedTablesCopy = null;
          }

          this.componentId = result.componentId;

          let componentName = this.internalDataService.parseComponentsLabel(this.componentId, null, this.machineId);

          if (!this.queryParams.hasOwnProperty('variablesTimeseriesMode')) {
            let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
            newBreadcrumb.push(componentName);
            this.internalDataService.setBreadcrumb(newBreadcrumb);
          }

          this.plotsConfigComponents = this.clonerService.deepClone(result.groups);
          this.plotsConfig = this.clonerService.deepClone(result.groups[this.componentId].plotsConfig);

          this.internalDataService.setTracesSelected(this, this.machineId, this.plotsConfig, false, false, false);

          this.internalDataService.setCachedMachineRecorderTraces(this);

          // if (recalcTraces) {

          this.pageState.next(6);
          this.getVariablesData(this);

          // } else {
          //   this.pageState.next(5);
          //   setTimeout(() => {
          //     this.drawPlot(this, this.variablesData, true);
          //   }, 250);
          // }

        } catch (error) {
          console.log(error);
        }
      }
    });
  }
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // GET ASSET INFO

  async getAssetInfo(_this: any) {

    await _this.getDashboard();

    try {
      _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  initCardConfig(_this: any) {
    try {

      _this.exportCsv = _this.appConfig.machineRecorder?.exportCsv ?? _this.machine.profile.machineRecorder?.exportCsv;

      if (_this.exportCsv) {

        _this.eventsTableInfos = _this.appConfig[_this.sectionName]?.eventsTableInfos ?? [];
        _this.outOfThresholdTableInfos = _this.appConfig[_this.sectionName]?.outOfThresholdTableInfos ?? [];

        _this.unparsedTableInfos = _this.appConfig[_this.sectionName]?.tableInfos ?? [];
        _this.tableInfos = _this.unparsedTableInfos?.filter(x => !x.hideInTable);
      }

      else {
        _this.tableInfos = _this.appConfig[_this.sectionName].tableInfos;
      }


      _this.aggregations?.forEach((aggr: any) => {
        let idx = _this.tableInfos.findIndex((x: any) => x.variable == aggr.id);
        if (idx == -1) {
          _this.tableInfos.push({
            variable: aggr.id,
            label: _this.translate.instant(aggr.label),
            // hideInExportPdf: true,
            suffix: null
          });
        }
      });

      _this.eventsColumns.push('icon');
      _this.tableInfos.forEach((element: any) => {
        _this.eventsColumns.push(element.variable);
      });

    } catch (error) {
      console.log(error);
      _this.tableInfos = [];
    }
    try {

      _this.filterTabs = _this.machine.profile.filterTabs != null ? _this.machine.profile.filterTabs : _this.filterTabs;

      _this.aggregations?.forEach((aggr: any) => {
        let idx = _this.filterTabs.findIndex((x: any) => x.id == aggr.id);
        if (idx == -1) {
          _this.filterTabs.push({
            id: aggr.id,
            title: _this.translate.instant(aggr.label),
            icon: aggr.icon,
            toMap: "id",
            toInclude: aggr.id,
            list: []
          });
        }
      });
    } catch (error) {
      console.log(error);
    }

  }

  async getDashboard() {
    try {

      let url = '/apif/get-custom-file';
      let dashboardName = 'dashboard-' + this.dashboardName + '.json';

      let query: any = {
        machineId: this.machineId,
        fileName: dashboardName,
        fromWeb: this.appInfo?.sources40F != null ? 1 : 0,
        source: this.appInfo?.sources40F ?? '/assets/config/',
      };

      try {
        let resp = await this.apiService.sendGetRequestAwait(url, query);
        Object.entries(resp?.body ?? {})?.forEach(([k, v]: any) => {
          this[k] = v;
        })
      } catch (error) {
        console.log(error);
      }

    } catch (error) { }
  }


  parseMachineProfile(_this: any) {

    _this.initCardConfig(_this);

    try { _this.timeStates = _this.clonerService.deepClone(_this.machine.profile.timeStates) } catch (error) { }

    // If no table is defined, remove the event tab
    if (!_this.machine.profile.tables?.length) _this.filterTabs = _this.filterTabs.filter(x => x.id != 1);

    let useCategories = false;
    if (_this.machine?.profile?.useCategoriesAsStatesInContinuousExploration == false) useCategories = false;
    else if (_this.machine?.profile?.useCategoriesAsStatesInContinuousExploration || _this.appConfig?.useCategoriesAsStatesInContinuousExploration) useCategories = true;

    if (useCategories) {
      _this.timeStates = _this.machine.profile.categories?.concat(
        {
          "id": 5,
          "backendValue": "NC",
          "backendValueStateTimeline": "NC",
          "label": "LINE_STATES.NOT_CONNECTED",
          "color": "#ffbf00",
          "colorMachineRecorder": "#ffbf0080"
        }
      ).reduce((acc, val) => {
        let obj = {
          ...val, ...{
            state: val.backendValueStateTimeline,
            value: val.label,
            color: val.colorMachineRecorder,
            enabled: true,
            show: true,
          }
        };
        acc.push(obj);
        return acc;
      }, []);
    }

    // If no timestate is defined, remove the filter tab
    if (!_this.timeStates?.length) _this.filterTabs = _this.filterTabs.filter(x => x.id != 2);

    try { _this.aggrBackground = _this.machine.profile.aggrBackground } catch (error) { }

    let defaultInterval = _this.machine.profile.defaultIntervalMachineRecorder ?? 'today';
    _this.defaultInterval = defaultInterval;

    var c_condAnal = _this.cacheService.get("condAnalFromTo");

    if (c_condAnal != null) {
      _this.timeAggrUnit = c_condAnal.timeAggrUnit
      _this.interval.id = 'custom'
      _this.interval.start = c_condAnal.from
      _this.interval.end = c_condAnal.to
      _this.interval.startF = _this.filterService.parseMoment(_this.interval.start, 'MMM DD, YYYY HH:mm', _this.machine.timezone) + ' - ' + _this.filterService.parseMoment(_this.interval.end, 'MMM DD, YYYY HH:mm', _this.machine.timezone)
    } else {

      _this.interval = _this.intervalService.getIntervalById(defaultInterval, _this.machine.timezone);

      _this.intervalConfig = {
        list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
        selected: _this.interval
      };

    }

    _this.defaultInterval = _this.clonerService.deepClone(_this.interval);

    _this.dispatcherService.getDispatch(_this, 300);

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // GET VARIABLES CONFIG

  getVariablesConfig(_this: any) {
    try {

      _this.componentsList = _this.machine.components?.map((x: any) => {
        return {
          label: _this.internalDataService.parseComponentsLabel(x, null, _this.machineId),
          id: x,
        };
      });

      if (_this.componentId == null) _this.componentId = _this.cacheService.get("selectedComponent")?.assetId == _this.machineId ? _this.cacheService.get("selectedComponent")?.value : null;
      if (_this.componentId == null) _this.componentId = _this.componentsList?.[0]?.id;

      let componentName = _this.internalDataService.parseComponentsLabel(_this.componentId, null, _this.machineId);

      if (!_this.queryParams.hasOwnProperty('variablesTimeseriesMode') && componentName != null) {
        let newBreadcrumb = _this.clonerService.deepClone(_this.breadcrumb);
        newBreadcrumb.push(componentName);
        _this.internalDataService.setBreadcrumb(newBreadcrumb);
      }

      // if (!_this.availableMachines?.list?.includes(_this.machine.machineReference)) {
      //   _this.availableMachines.list.push(_this.machine.machineReference);
      //   _this.availableMachines.selected = _this.machine.machineReference
      // }

      let machineReference = _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference);

      _this.internalDataService.getVariablesConfig(_this, _this.machineId, machineReference, null, false, false, null, false, true);

    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getVariablesDataPolling, this.getVariablesData, this.machine.timezone, 6) };

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //

  machineSelectionChange(machine: any) {

    if (machine != null) {

      this.cacheService.reset("selectedComponent");
      this.cacheService.reset("plotsConfigComponents");
      this.cacheService.reset("plotsConfig");

      try { this.pollingEvents.unsubscribe() } catch (error) { }

      this.pageState.next(5);
      this.getVariablesConfig(this);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // events

  // polling
  getVariablesDataPolling(_this: any, range?: any) {
    try {

      if (_this.pollingTime > 0) _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => _this.getVariablesData(_this, count, range))
      else _this.getVariablesData(_this, 0, range);

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getVariablesData(_this: any, count?: any, range?: any) {
    try {

      let url = '/apif/variables-exploration/' + _this.machineId + '/' + _this.componentId;

      _this.maxZoomReached = false;

      if (range != null) {
        if (range.hasOwnProperty('from') && range.from != null &&
          range.hasOwnProperty('to') && range.to != null
        ) {
          console.log('range', range);

          _this.showResetInterval = true;

          _this.interval.startDate = moment.tz(range.from, _this.machine.timezone).toDate(); //.add(100, 'ms').toDate();
          _this.interval.endDate = moment.tz(range.to, _this.machine.timezone).toDate(); //.add(100, 'ms').toDate();

          let _interval = (_this.interval.endDate - _this.interval.startDate) / 1000;
          if (_interval <= _this.minIntervalSeconds) {
            _this.interval.endDate = new Date(_this.interval.startDate);
            _this.interval.endDate.setSeconds(_this.interval.endDate.getSeconds() + _this.minIntervalSeconds);
            _this.maxZoomReached = true;
          }

          _this.interval.startDate.setMilliseconds(0);
          _this.interval.endDate.setMilliseconds(0);

          _this.interval.start = JSON.parse(JSON.stringify(_this.interval.startDate));
          let lastEnd = _this.interval.end;
          _this.interval.end = JSON.parse(JSON.stringify(_this.interval.endDate));

          // let _start = moment(_this.interval.startDate).format('MMM DD YYYY, HH:mm');
          // let _end = moment(_this.interval.endDate).format('HH:mm');

          let _start = _this.intervalService.strFormat(null, _this.interval.startDate, _this.machine.timezone, 'MMM DD YYYY, HH:mm');
          let _end = _this.intervalService.strFormat(null, _this.interval.endDate, _this.machine.timezone, 'HH:mm');

          this.interval.startF = `${_start} - ${_end}`;

          this.intervalConfig.selected = JSON.parse(JSON.stringify(this.interval));

          if (lastEnd != _this.interval.end) _this.interval.enabledPolling = false;

        } else {
          _this.showResetInterval = false;
          _this.interval = _this.clonerService.deepClone(_this.defaultInterval);
        }
      }

      let seconds = 3600;
      _this.parsedTablesCopy = [];

      try {
        seconds = moment(_this.interval.end).diff(moment(_this.interval.start), 's');
        _this.intervalSeconds = moment(_this.interval.end).diff(moment(_this.interval.start), 's');
        _this.intervalMilliSeconds = moment(_this.interval.end).diff(moment(_this.interval.start));

        let c_eventTypes = _this.cacheService.get("eventTypes");
        if (c_eventTypes == null) {
          _this.eventTypes = _this.clonerService.deepClone(_this.machine.profile.tables?.map((x: any) => {
            return {
              selected: !x.defaultDisabled,
              label: _this.translate.instant(x.label),
              enabled: true,
              disabled: false,
              id: x.eventId,
              value: x.label,
            }
          })) ?? [];
        } else {
          _this.eventTypes = _this.clonerService.deepClone(c_eventTypes);
        }

        if (_this.machine.profile.tables != null) {
          _this.parsedTablesCopy = _this.clonerService.deepClone(_this.machine.profile.tables.filter((table: any) => {

            let eventType = _this.eventTypes.find(ev => ev.id == table.eventId);

            if (!eventType) return false;

            let isEventSelected = eventType.selected;

            if (table.timeRange) {

              let unit = table.timeRange.slice(-1)
              let value = parseFloat(table.timeRange.slice(0, -1))

              if (unit == 'm') return value >= seconds / 60;
              else if (unit == 'h') return value >= seconds / 3600;
              else if (unit == 'd') return value >= seconds / (24 * 3600);

            }

            let isEventSelectable = !table.hasOwnProperty('daysRange') || table.daysRange === null || table.daysRange >= seconds / (24 * 3600);
            eventType.enabled = isEventSelectable;
            eventType.disabled = !eventType.enabled;

            if (!isEventSelectable) {
              let dayDecimals = table.daysRange % 1;
              let days = Math.floor(table.daysRange);
              let hours = dayDecimals * 24;
              let hourDecimals = hours % 1;
              hours = Math.floor(hours);
              let minutes = Math.floor(hourDecimals * 60);

              let timeRangeArray = [];
              if (days > 0) timeRangeArray.push(`${days} ${_this.translate.instant("INTERVAL.DAYS")}`);
              if (hours > 0) timeRangeArray.push(`${hours} ${_this.translate.instant("INTERVAL.HOURS")}`);
              if (minutes > 0) timeRangeArray.push(`${minutes} ${_this.translate.instant("INTERVAL.MINUTES")}`);

              let timeRangeStr = timeRangeArray.join(', ');

              // eventType.warning = `Evento non selezionabile per un intervallo maggiore di ${timeRangeStr}`;
              eventType.warning = _this.translate.instant("CONTINUOUS_EXPLORATION.UNSELECTABLE_EVENT_TIMERANGE", { time: timeRangeStr });
            } else {
              eventType.warning = null;
            }

            return isEventSelectable && isEventSelected;

          }));
        }
      } catch (error) {
        console.log(error);
      }

      try {
        _this.parsedTablesCopy.forEach((table: any) => {
          if (table.hasOwnProperty('filters')) {

            // for (const [param, condition] of Object.entries(table.filters)) {
            //   for (const [operator, setpoint] of Object.entries(condition)) {
            //     if (typeof (setpoint) === "number") {
            //       if (seconds > 3600) {
            //         // console.log(setpoint);
            //         table.filters[param][operator] = String(Math.min(setpoint * seconds / 3600, 1800));
            //       } else {
            //         delete table.filters[param];
            //       }
            //     }
            //   }
            // }

            Object.entries(table.filters).forEach((filt: any) => {

              let param = filt[0];
              let condition = filt[1];

              Object.entries(condition).forEach((cond: any) => {
                let operator = cond[0];
                let setpoint = cond[1];

                if (typeof (setpoint) === "number") {
                  if (seconds > 3600) {
                    table.filters[param][operator] = String(Math.min(setpoint * seconds / 3600, 1800));
                  } else {
                    delete table.filters[param];
                  }
                }
              });
            });

          }
        });
      } catch (error) {
        console.log(error);
      }


      _this.returnStates = 0;

      try {
        let statesTable = _this.machine.profile?.tables?.find(x => x.id == 3);
        if (statesTable != null) {
          _this.returnStates = seconds < (statesTable?.statesMaxTimeRange ?? (25 * 3600))
        }
      } catch (error) { console.log(error) }

      _this.enabledCSVDownload = 0;
      try {
        _this.enabledCSVDownload = seconds < (_this.machine.profile.exportCSVSeconds != null ? _this.machine.profile.exportCSVSeconds : (3600 * 12 * 60));
        _this.csvTimeCheck = !_this.enabledCSVDownload ? _this.translate.instant("CONTINUOUS_EXPLORATION.CSV_TIME_CHECK", {
          time: _this.filterService.parseTime(_this.machine.profile.exportCSVSeconds, 's', 'HH:mm:ss'),
        }) : null;
      } catch (error) {
        console.log(error);
      }

      let query: any = {
        from: _this.interval.start,
        to: _this.interval.end,
        tz: _this.machine.timezone,
      };

      if (_this.returnStates == 1) query.returnStates = 1;

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      _this.filterService.filterAggregationsByMachine(_this, query?.machineId);

      let payload: any = {
        variables: _this.variables.selected,
        tables: _this.updatedTablesCopy ?? _this.parsedTablesCopy,
      };

      if (_this.aggrBackground != null) payload.aggrBackground = _this.aggrBackground;

      _this.internalDataService.buildAggregationsPayload(_this);
      payload.filters = _this.aggregationsPayload;

      _this.csv = {
        query: _this.clonerService.deepClone(query),
        payload: _this.clonerService.deepClone(payload),
      }

      _this.apiService.sendPostRequest(url, payload, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error, _this.pollingEvents))
        )
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.monitoringData = _this.clonerService.deepClone(data.body);
            _this.monitoringData.dataConfig = {};
            _this.monitoringData.dataConfig.plotDataAttribute = 'plotData';
            _this.monitoringData.dataConfig.flagEventsDataAttribute = 'flagData';

            _this.monitoringData.dataConfig.noDataPolicy = 'plot-axis';
            _this.monitoringData.dataConfig.noDataLabel = _this.translate.instant('GLOBAL.NO_DATA_AVAILABLE');

            _this.parseData(_this, data.body);

            if (count == 0) {
              _this.dispatcherService.getDispatch(_this, 300);
            }

          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  parseData(_this: any, data: any) {

    if (data?.states?.length) {

      try {
        let statesCopy = _this.clonerService.deepClone(data.states);

        data.states.forEach((state: any, stateIdx: any) => {

          let previousIdx = stateIdx - 1;

          if (previousIdx > -1 && state.timeStart != data.states[previousIdx].timeEnd) {
            statesCopy.push({
              timeEnd: state.timeStart,
              timeStart: data.states[previousIdx].timeEnd,
              value: 'NC'
            });
          }

          // console.log(moment(state.timeStart), moment(_this.interval.start).tz(_this.machine.timezone), moment(state.timeStart).diff(moment(_this.interval.start).tz(_this.machine.timezone)));

          // All events between the interval start and the first value of the states are filled with state NC
          if (stateIdx == 0 &&
            moment(state.timeStart).diff(moment(_this.interval.start).tz(_this.machine.timezone)) > 0) {
            statesCopy.push({
              timeEnd: state.timeStart,
              timeStart: moment(_this.interval.start).tz(_this.machine.timezone).format("YYYY-MM-DDTHH:mm:ss.SSS") + 'Z',
              value: 'NC'
            });
          }

          // All events between the last value of the states and the interval end are filled with state NC
          if (stateIdx === data.states.length - 1 &&
            moment(state.timeEnd).diff(moment(_this.interval.end).tz(_this.machine.timezone)) < 0) {
            statesCopy.push({
              timeEnd: moment(_this.interval.end).tz(_this.machine.timezone).format("YYYY-MM-DDTHH:mm:ss.SSS") + 'Z',
              timeStart: state.timeEnd,
              value: 'NC'
            });
          }

        });
        data.states = _this.clonerService.deepClone(statesCopy)
      } catch (error) {
        console.log(error);
      }
    }

    _this.hiddenEventsMsg = false;
    if (data.hasOwnProperty('table') && data.table != null &&
      Array.isArray(data.table) && data.table.length > 0) {

      let parsedTable: any = [];

      if (data.hiddenEvents) {
        _this.hiddenEventsMsg = _this.translate.instant('CYCLE_TIMELINE.HIDDEN_EVENTS_WARNING', { shown: data.table.length, tot: data.hiddenEvents })
      }

      data.table.filter((x: any, index: any) => index < 100000).forEach((event: any) => {
        // data.table.forEach((event: any) => {
        // console.log(event);
        event = _this.getEventInfo(_this, event);
        event.timestampP = moment(event.timestamp).format("DD MMM YYYY HH:mm:ss.SSS");
        event.durationP = event.duration != null ? _this.filterService.parseTime(event.duration, 's', 'HH:mm:ss') : null;
        // event.eventName = event.eventId;
        // event.description = "Descrizione casuale";
        parsedTable.push(event);
      });

      // console.log(_this.parsedTablesCopy);
      _this.filtersInfo = _this.machine.profile.tables?.map((x: any) => {
        if (x.daysRange != null) {
          return _this.translate.instant("CONTINUOUS_EXPLORATION.TIME_INTERVAL", {
            name: _this.translate.instant(x.label),
            duration: _this.filterService.parseTime(x.daysRange * 24 * 3600, 's', 'HH:mm:ss'),
          });
        }
        return _this.translate.instant("CONTINUOUS_EXPLORATION.NOT_SHOWN", {
          name: _this.translate.instant(x.label),
        });
      });
      _this.parsedTablesCopy.forEach((table: any) => {

        let ix = _this.machine.profile.tables.findIndex((x: any) => x.eventId == table.eventId);
        if (ix != -1) {
          let descr = _this.translate.instant(table.label);
          if (table.hasOwnProperty('filters') && table.filters != null && table.filters.hasOwnProperty("\"duration\"") &&
            table.filters["\"duration\""] != null && table.filters["\"duration\""][">"] != null) {
            try {
              descr = _this.translate.instant("CONTINUOUS_EXPLORATION.FILTER_DURATION", {
                name: _this.translate.instant(table.label),
                duration: _this.filterService.parseTime(table.filters["\"duration\""][">"], 's', 'HH:mm:ss'),
              });
            } catch (error) {
              console.log(error);
            }
          } else {
            descr = null;
          }
          _this.filtersInfo[ix] = descr;
        }

      });

      try { _this.filtersDontExist = _this.filtersInfo.every((x: any) => x == null) }
      catch (error) { console.log(error) }

      _this.events.list = _this.clonerService.deepClone(parsedTable);

      _this.aggregations?.forEach((aggr: any, aggrIdx: any) => {
        let c_aggrList = _this.cacheService.get(aggr.id + "List");
        if (c_aggrList == null) {
          _this[aggr.id + "List"] = _this.clonerService.deepClone(_this.events.list.map((x: any) => x[aggr.id]).filter(_this.filterService.onlyUnique)).map((x: any) => {
            return {
              enabled: true,
              id: x,
              value: x
            };
          });
        } else {
          _this[aggr.id + "List"] = _this.clonerService.deepClone(c_aggrList);
        }

        try {
          _this.filterTabs.find((x: any) => x.id == aggr.id).list = _this.clonerService.deepClone(_this[aggr.id + "List"]);
        } catch (error) {
          console.log(error);
        }
      });

      if (!_this.returnStates) {
        let ix = _this.filterTabs.findIndex((x: any) => x.id == 2);
        if (ix != -1) _this.filterTabs.splice(ix, 1);
      }

    } else {
      try {
        _this.events.list = [];
      } catch (error) {
        console.log(error);
      }
    }

    _this.variablesData = data;
    _this.filterEvents();

    _this.drawPlot(_this, _this.variablesData);

  }

  filterSearchEvents(searchEvents: any) {
    this.searchEvents = searchEvents;
    this.filterEvents();
    this.drawPlot(this, this.variablesData);
  }

  filterEvents() {

    try { this.events.query.page = 1 } catch (error) { }

    let filtered: any = this.clonerService.deepClone(this.events.list);

    // Filter all events that started before the interval start
    // filtered = filtered.filter((x: any) => moment(x.timestamp).diff(moment(this.interval.start).tz(this.machine.timezone)) > 0);

    // Filter all events that finished after the interval end
    // filtered = filtered.filter((x: any) => moment(x.timestamp).diff(moment(this.interval.end).tz(this.machine.timezone)) < 0);

    // Filter tabs
    this.filterTabs.forEach((tab: any) => {
      if (tab.list != null && tab.list.length > 0) {
        filtered = filtered.filter((x: any) => tab.list.filter((x: any) => x.enabled).map((event: any) => event[tab.toMap]).includes(x[tab.toInclude]))
      } else if (!this.defaultEnableFilters) {
        filtered = [];
      }
    });

    // Filter by search input description
    let filterVariables = ['description'];
    try {
      filtered = filtered.filter((data: any) => filterVariables.some((x: any) => {
        if (this.searchEvents == null || this.searchEvents == '') return true;
        if (typeof data[x] == 'string') return String(data[x]).toLowerCase().includes(this.searchEvents.toLowerCase());
        else if (Array.isArray(data[x])) return data[x].some((y: any) => Object.values(y).some((z: any) => String(z).toLowerCase().includes(this.searchEvents.toLowerCase())))
      }));
    } catch (error) {
      console.log(error);
    }

    // console.log('events filtered', filtered);

    this.events.filtered = filtered;

    const cd = new MatTableDataSource<any[]>();

    cd.sort = this.sort;
    cd.paginator = this.paginator;
    cd.data = filtered;

    this.eventsData = cd;
  }

  removeAllFilters() {
    this.removeFilters(this);
    this.drawPlot(this, this.variablesData);
  };

  removeFilters(_this: any, onlyTools?: any) {
    try {

      _this.filterTabs.filter((x: any) => onlyTools ? (typeof x.id == 'number' ? false : x.id.startsWith("aggr")) : true).forEach((tab: any) => {
        if (tab.list != null) tab.list.forEach((x: any) => x.enabled = (tab.id == 1 ? _this.defaultEnableFilters : true));
      });

      _this.eventTypes = _this.clonerService.deepClone(_this.filterTabs[0].list);
      // _this.toolList = _this.clonerService.deepClone(_this.filterTabs[1].list);

      _this.aggregations?.forEach((aggr: any, aggrIdx: any) => {
        _this[aggr.id + "List"] = _this.clonerService.deepClone(_this.filterTabs.find((x: any) => x.id == aggr.id).list);
      });

      if (_this.returnStates) {
        let idx = _this.filterTabs.findIndex((x: any) => x.id == 2);
        if (idx != -1) _this.timeStatesList = _this.clonerService.deepClone(_this.filterTabs[idx].list);
      }

      _this.cacheService.set("eventTypes", _this.eventTypes);

      _this.aggregations?.forEach((aggr: any, aggrIdx: any) => {
        _this.cacheService.set(aggr.id + "List", null);
      });

      _this.atLeastOneFilter = _this.filterTabs.some((x: any) => x.id == 1 ? (x.list.some((y: any) => y.enabled == !_this.defaultEnableFilters)) : x.list.some((y: any) => !y.enabled));

    } catch (error) {
      console.log(error);
    }
    _this.filterEvents();
  };

  drawPlot(_this: any, data: any, skipGetData?: any) {

    _this.traces = [];

    let filteredConfigsLength = _this.plotsConfig.list.filter((c: any) => c.show).length;

    let domain = _this.getDomain(filteredConfigsLength);

    // Default layout
    let layout: any = {
      // font: {
      //   family: 'Montserrat, sans-serif'
      // },
      colorway: _this.colors,
      margin: {
        t: 40,
        b: 50,
        r: 25,
        l: 25,
        pad: 0
      },
      legend: {
        orientation: 'h',
        traceorder: 'normal',
        x: 0,
        y: -0.15
      },
      xaxis: {
        type: 'date',
        fixedrange: _this.maxZoomReached,
        domain: domain,
        zeroline: false,
        showgrid: false,
        // showticklabels: false
      },
      // defaultRange: [parsedStartDate, parsedEndDate],
      // height: chartHeight,
    };

    // TODO: Add as default. It is not default because it has not been test thouroughly
    if (this.appConfig?.plotLimitsDefinedBySelectedIntervalInMachineRecorder) {

      let parsedStartDate = _this.intervalService.strFormat(null, _this.interval.start, _this.machine.timezone, 'YYYY-MM-DDTHH:mm:ss.SSS');
      let parsedEndDate = _this.intervalService.strFormat(null, _this.interval.end, _this.machine.timezone, 'YYYY-MM-DDTHH:mm:ss.SSS');

      layout.xaxis.range = [parsedStartDate, parsedEndDate];

    }

    if (layout.colorway == null) layout.colorway = this.clonerService.deepClone(this?.appInfo?.darkTheme ? this.internalDataService.defaultPlotlyColorsDark : this.internalDataService.defaultPlotlyColors);

    let mainFontColor = this.appConfig?.plotlyDefaultColors?.font ?? '#000000';

    let dark = {
      plot_bgcolor: '#00000000',
      paper_bgcolor: '#00000000',
      modebar: {
        bgcolor: '#00000000',
        color: "#64697E",
        activecolor: "#64697E",
      }
    }

    layout = { ...layout, ...dark };

    let singlePlotsConfig = null;
    if (_this.exportCsv) singlePlotsConfig = _this.createDividedPlots(this.clonerService.deepClone(layout), data);

    let configIdx = 0;

    _this.plotsConfig.list.filter((c: any) => c.show).forEach((config: any) => {

      configIdx += 1;
      layout['yaxis' + configIdx] = {
        fixedrange: true,
        zeroline: false,
        showgrid: false,
        automargin: true,
        anchor: 'free',
        // anchor: configIdx != 2 ? 'free' : 'x',
        position: configIdx > 1 ? (configIdx % 2 != 0 ? 0.05 * (configIdx - 1) / 2 : (1 - (0.05 * (configIdx - 2) / 2))) : null,
        overlaying: configIdx > 1 ? 'y' : null,
        side: configIdx % 2 == 0 ? 'right' : 'left',
        title: {
          // standoff: 50,
          text: this.filterService.convertUnit(config.unit).unit
        }
      };

      let noData = true;
      Object.entries(data.aspects).forEach((aspectValues: any) => {

        const aspect = aspectValues[0];
        const values = aspectValues[1];

        if (config?.traces?.length > 0 && Object.keys(values).length > 0 && values?.variables != null) {

          config.traces.filter((x: any) => values.variables.hasOwnProperty(x.id) && aspect == x.aspect).forEach((x: any, idx: any) => {

            if (!values.timestamps || values.timestamps.length == 0) return;

            let traceName = x.label;

            let multiplier = x.multiplier ?? config.multiplier ?? 1;
            let decimals = x.decimals ?? config.decimals ?? 2;

            let hovertext = values.variables[x.id].map((v: any, xIdx: any) => {
              let hover = "<b>" + _this.translate.instant("CONTINUOUS_EXPLORATION.VARIABLE") + ": </b>" + (x.paramRFlag ? x.id + ' - ' : '') + traceName + '<br>' +
                "<b>" + _this.translate.instant("CONTINUOUS_EXPLORATION.VALUE") + ": </b>" + (v != null ? _this.filterService.parseGaugeValue(this.filterService.convertUnit(config.unit, v * multiplier).value, decimals, 1) : '-') +
                " " + layout['yaxis' + configIdx].title.text + '<br>' +
                "<b>" + _this.translate.instant("CONTINUOUS_EXPLORATION.TIME") + ": </b>" + moment(values.timestamps[xIdx]).format("MMM DD, YYYY HH:mm:ss.SSS") + '<br>';
              if (_this.showTraceName) hover += "<b>" + _this.translate.instant("CONTINUOUS_EXPLORATION.VARIABLE_NAME") + ": </b>" + (x.paramRFlag ? x.id + ' - ' : '') + x.name + '<br>';
              return hover;
            });

            if (noData) noData = values.timestamps.length > 0;

            _this.traces.push({
              showlegend: true,
              yaxis: 'y' + configIdx,
              name: traceName,
              id: x.id,
              legendgroup: x.id,
              visible: x.show,
              // mode: 'lines',
              mode: x.traceType == 'setpoint' ? 'lines' : 'lines+markers',
              type: 'scatter',
              text: hovertext,
              line: {
                dash: x.traceType == 'setpoint' ? 'dot' : 'solid',
              },
              hoverinfo: "text",
              x: values.timestamps,//.map((x: any) => x.substring(0, x.length - 6)),
              y: values.variables[x.id].map((x: any) => x != null ? this.filterService.convertUnit(config.unit, x * multiplier).value : null),
            });
          });
        }

      });
    });

    try {
      Object.entries(data.aspects).forEach((aspectValues: any) => {

        const aspect = aspectValues[0];
        const values = aspectValues[1];

        _this.aggregations?.forEach((aggr: any) => {
          if (values != null && values.hasOwnProperty('variables') && values.variables != null &&
            values.variables.hasOwnProperty(aggr.id) && values.variables[aggr.id] != null && values.variables[aggr.id].length > 0) {

            let yValuesIdx = _this.traces.findIndex((x: any) => x.yaxis == 'y1');
            let yVals = (yValuesIdx != -1) ? _this.clonerService.deepClone(_this.traces[yValuesIdx].y) : [];

            _this.traces.push({
              name: aggr.label,
              id: aggr.id,
              showlegend: false,
              mode: 'lines',
              type: 'scatter',
              line: {
                width: 0
              },
              hovertext: values.variables[aggr.id].map((x: any) => '<b>' + aggr.label.capitalize() + '</b>: ' + x),
              hoverinfo: "text",
              x: values.timestamps,
              y: yVals,
            });
          }
        });

      });
    } catch (error) {
      console.log(error);
    }

    layout.shapes = [];
    layout.annotations = [];
    if ((_this.backgroundType == null || _this.backgroundType == 0) && data.hasOwnProperty('states') && data.states != null && data.states.length > 0) {

      data.states.forEach((bar: any) => {
        let color = null;
        try {

          let c_timeStates = _this.cacheService.get("timeStates");

          let stateId = bar.value;

          let useCategories = false;
          if (_this.machine?.profile?.useCategoriesAsStatesInContinuousExploration == false) useCategories = false;
          else if (_this.machine?.profile?.useCategoriesAsStatesInContinuousExploration || _this.appConfig?.useCategoriesAsStatesInContinuousExploration) useCategories = true;

          if (useCategories) {
            stateId = (c_timeStates ?? _this.timeStates)?.filter((x: any) => x.enabled)?.find((x: any) => x.id == (_this.machine.profile.timeStates.find(state => state.state == bar.value)?.category))?.backendValueStateTimeline;
          }

          let stateIdx = (c_timeStates != null ? c_timeStates : _this.timeStates).filter((x: any) => x.enabled).findIndex((x: any) => x.state == stateId);
          if (stateIdx != -1) {
            color = (c_timeStates != null ? c_timeStates : _this.timeStates).filter((x: any) => x.enabled)[stateIdx].color;
          }
        } catch (error) {
          console.log(error);
        }
        layout.shapes.push({
          type: 'rect',
          xref: 'x',
          yref: 'paper',
          x0: bar.timeStart,
          y0: 0,
          x1: bar.timeEnd,
          y1: 1,
          fillcolor: color,
          opacity: 0.8,
          line: {
            width: 0
          }
        });
      });
    }

    if (_this.backgroundType == 1 && data.hasOwnProperty('aggr2') && data.aggr2 != null && data.aggr2.length > 0) {

      data.aggr2.forEach((bar: any) => {
        let color = null;
        try {

          let c_programLevels = _this.cacheService.get("programLevels");

          let stateIdx = (c_programLevels != null ? c_programLevels : _this.machine.profile.programLevels).filter((x: any) => x.enabled).findIndex((x: any) => x.id == bar.value);
          if (stateIdx != -1) {
            color = (c_programLevels != null ? c_programLevels : _this.machine.profile.programLevels).filter((x: any) => x.enabled)[stateIdx].color;
          }
        } catch (error) {
          console.log(error);
        }
        layout.shapes.push({
          type: 'rect',
          xref: 'x',
          yref: 'paper',
          x0: bar.timeStart,
          y0: 0,
          x1: bar.timeEnd,
          y1: 1,
          fillcolor: color,
          opacity: 0.2,
          line: {
            width: 0
          }
        });
      });
    }

    _this.isFlags = false;
    _this.spaceForEvents = false;

    if (_this.machine.profile?.flags?.flags && data.eventIntervals) {

      _this.isFlags = true;

      data.eventIntervals.forEach((ip: any) => {

        layout.shapes.push({
          type: 'line',
          yref: 'paper',
          xref: 'x',
          x0: new Date(ip.start),
          x1: new Date(ip.start),
          y0: 0,
          y1: 1,
          opacity: 0.4,
          line: {
            color: 'grey',
            width: 1,
            dash: 'dash'
          }
        });

        _this.spaceForEvents ||= ip.events > 0;

        if (ip.events == 1 && ip.first) {

          ip.singleEvent = true;
          let info = _this.getEventInfo(_this, ip.first);

          try {
            let ipStart = moment(ip.start);
            let ipEnd = moment(ip.end);

            ip.percP = 'calc(' + _this.filterService.parseGaugeValue(moment(ip.first.timestamp).diff(ipStart) / ipEnd.diff(ipStart), 0, 100) + '% - 10px)';
            ip.percPplus = 'calc(' + _this.filterService.parseGaugeValue(moment(ip.first.timestamp).diff(ipStart) / ipEnd.diff(ipStart), 0, 100) + '% - 20px)';
          } catch (error) {
            console.log(error);
            ip.perc = 'auto';
            ip.percPplus = 'auto';
          }

          ip.name = info.eventName;
          ip.timestampP = moment(info.timestamp).format("HH:mm:ss.SSS");
          ip.icon = info.icon1;
          ip.icon2 = info.icon2;
          ip.value = info;

        }
        else if (ip.eventTypes) {
          ip.eventTypesL = Object.entries(ip.eventTypes).map((evTypeInfo: any) => {

            let evType = evTypeInfo[0];
            let occurrences = evTypeInfo[1];

            let info = _this.getEventInfo(_this, { eventId: evType });

            // let defaultList: any =
            return {
              id: evType,
              label: info.eventName,
              icon: info.icon1,
              icon2: info.icon2?.icon,
              occurrences: occurrences,
            }

          });
        }

      });

      try {
        layout.shapes.push({
          type: 'line',
          yref: 'paper',
          xref: 'x',
          x0: new Date(data.eventIntervals.at(-1).end),
          x1: new Date(data.eventIntervals.at(-1).end),
          y0: 0,
          y1: 1,
          opacity: 0.4,
          line: {
            color: 'grey',
            width: 1,
            dash: 'dash'
          }
        });
      } catch (error) { console.log(error) }

      _this.monitoringData.flagData = _this.clonerService.deepClone(data.eventIntervals);

    }

    // console.log(_this.traces, layout);

    // Merge legend
    let legendDark = { font: { color: mainFontColor } };
    layout.legend = layout.legend != null ? { ...layout.legend, ...legendDark } : legendDark;

    // Merge x axis
    let xAxisDark = { color: mainFontColor };
    if (layout.xaxis1 == null) {
      layout.xaxis = layout.xaxis != null ? { ...layout.xaxis, ...xAxisDark } : xAxisDark;
    }

    for (let i = 1; i < 20; i++) {
      if (layout["xaxis" + i] != null) layout["xaxis" + i] = layout["xaxis" + i] != null ? { ...layout["xaxis" + i], ...xAxisDark } : xAxisDark;
    }

    // Merge y axis
    let yAxisDark = { color: mainFontColor };
    if (layout.yaxis1 == null) {
      layout.yaxis = layout.yaxis != null ? { ...layout.yaxis, ...yAxisDark } : yAxisDark;
    }

    for (let i = 1; i < 20; i++) {
      if (layout["yaxis" + i] != null) layout["yaxis" + i] = layout["yaxis" + i] != null ? { ...layout["yaxis" + i], ...yAxisDark } : yAxisDark;
    }

    if (_this.exportCsv && singlePlotsConfig != null) {
      singlePlotsConfig = singlePlotsConfig?.map((config, i) => {
        config.layout.shapes = layout.shapes;
        // config.plotName = `${i + 1}) ${config.plotName}`;
        config.plotName = `${config.plotName}`;
        config.params = {
          plotId: `single-plots`,
          displayModeBar: false,
          exportImage: true,
        }
        return config;
      });

      this.cacheService.set("maxPlotImages", singlePlotsConfig?.length);

      singlePlotsConfig?.forEach(config => {
        let widget = { type: 'ff-plotly-chart', data: { plotData: config }, machineProfile: this.machine.profile, config: { plotDataAttribute: "plotData" } };
        this.generateComponentInHtml(widget);
      });
    }

    _this.monitoringData.plotData = {
      layout: layout,
      traces: _this.traces,
      params: {
        plotId: _this.plotId,
        relayoutVariables: true,
        showFlagAnnotations: true,
        displayModeBar: false,
        // exportImage: true,
        cursor: _this.maxZoomReached ? 'default' : null
      }
    };

    if (_this.traces.every((trace: any) => trace.x.length == 0) && layout.shapes.length == 0) {
      _this.plotState = 2;
    } else {
      _this.plotState = 1;
    }

    _this.dispatcherService.getDispatch(_this, skipGetData ? 302 : 300);

  }

  createDividedPlots(layout, data) {

    let parsedData = [];

    this.outOfThresholdVariables = [];

    this.plotsConfig.list.filter((c: any) => c.show).forEach((config: any) => {

      // console.log({ config });

      config.traces.forEach(trace => {
        console.log({ trace });

        // });

        let plotName = trace.label + ' [' + this.filterService.convertUnit(config.unit).unit + ']'

        let layoutCopy: any = this.clonerService.deepClone(layout);

        let traces = [];

        layoutCopy.yaxis = {
          zeroline: false,
          showgrid: false,
          automargin: true,
          title: {
            text: plotName
          }
        };

        let noData = true;
        Object.entries(data.aspects).forEach(([aspect, values]: any) => {

          if (config?.traces?.length > 0 && Object.keys(values).length > 0 && values?.variables != null) {

            config.traces.filter((x: any) => values.variables.hasOwnProperty(x.id) && aspect == x.aspect && trace.id == x.id).forEach((x: any, idx: any) => {

              if (!values.timestamps || values.timestamps.length == 0) return;

              let traceName = x.label;

              let multiplier = x.multiplier ?? config.multiplier ?? 1;

              if (noData) noData = values.timestamps.length > 0;

              let lowerThreshold = 40;
              let upperThreshold = 120;

              let toAdd = values.variables[x.id].reduce((acc, val, i) => {

                if (val == null) return acc;
                if (i > 0) {
                  if (values.variables[x.id][i - 1] <= lowerThreshold || values.variables[x.id][i - 1] >= upperThreshold) {
                    return acc;
                  }
                }
                console.log({ val }, i);

                if (val <= lowerThreshold) {
                  acc.push({
                    variable: trace.name,
                    value: this.filterService.parseGaugeValue(val, 2, 1),
                    label: trace.label,
                    timestamp: moment(values.timestamps[i]).format("DD MMM YYYY HH:mm:ss.SSS"),
                    type: "lower",
                    lowerThreshold: lowerThreshold,
                    upperThreshold: upperThreshold,
                    thresholdValue: lowerThreshold
                  })
                }
                if (val >= upperThreshold) {
                  acc.push({
                    variable: trace.name,
                    value: this.filterService.parseGaugeValue(val, 2, 1),
                    label: trace.label,
                    timestamp: moment(values.timestamps[i]).format("DD MMM YYYY HH:mm:ss.SSS"),
                    type: "upper",
                    lowerThreshold: lowerThreshold,
                    upperThreshold: upperThreshold,
                    thresholdValue: upperThreshold
                  })
                }
                return acc;

              }, []);

              this.outOfThresholdVariables = this.outOfThresholdVariables.concat(toAdd);

              traces.push(
                {
                  showlegend: true,
                  name: traceName,
                  id: x.id,
                  visible: x.show,
                  mode: x.traceType == 'setpoint' ? 'lines' : 'lines+markers',
                  type: 'scatter',
                  line: {
                    dash: x.traceType == 'setpoint' ? 'dot' : 'solid',
                  },
                  x: values.timestamps,
                  y: values.variables[x.id].map((x: any) => x != null ? this.filterService.convertUnit(config.unit, x * multiplier).value : null),
                },
                {
                  // showlegend: true,
                  name: "lowerThreshold",
                  id: "lowerThreshold",
                  visible: true,
                  mode: 'lines',
                  type: 'scatter',
                  line: {
                    dash: 'dot',
                    color: "red"
                  },
                  x: values.timestamps,
                  y: values.variables[x.id].map((x: any) => lowerThreshold),
                },
                {
                  // showlegend: true,
                  name: "upperThreshold",
                  id: "upperThreshold",
                  visible: true,
                  mode: 'lines',
                  type: 'scatter',
                  line: {
                    dash: 'dot',
                    color: "red"
                  },
                  x: values.timestamps,
                  y: values.variables[x.id].map((x: any) => upperThreshold),
                }
              );
            });
          }

        });

        parsedData.push({ layout: layoutCopy, traces: traces, plotName: plotName });
      });
    })

    console.log({ parsedData });

    console.log(this.outOfThresholdVariables);

    return parsedData;

  }

  getDomain(range: any) {
    if (range != null && range > 0) {
      // solo due assi
      if (range < 3) {
        return [0, 1];
        // lunghezza pari
      } else if (range % 2 == 0) {
        return [0.05 * (range - 1) / 2, 1 - (0.05 * (range - 1) / 2)];
        // lunghezza dispari
      } else if (range % 2 != 0) {
        return [0.05 * (range - 1) / 2, 1 - (0.05 * (range - 3) / 2)];
      }
    }
    return [0, 1];
  }

  getEventInfo(_this: any, event: any) { return _this.internalDataService.getEventInfo(_this, event) }

  resetDefaultInterval() {

    this.showResetInterval = false;
    this.interval = this.clonerService.deepClone(this.defaultInterval);

    this.aggregations?.forEach((aggr: any, aggrIdx: any) => {
      this.cacheService.set(aggr.id + "List", null);
    });
    this.cacheService.set("eventTypes", null);
    if (this.eventFilterOpened) this.removeFilters(this, true);

    this.pageState.next(6);
    this.getVariablesData(this);

  };

  /////////////
  // PLOT BUTTONS

  getZoomedBucket() {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let delta = moment(this.interval.end).diff(moment(this.interval.start)) * 0.25;

    let start = JSON.parse(JSON.stringify(moment(this.interval.start).add(delta)));
    let end = JSON.parse(JSON.stringify(moment(this.interval.end).subtract(delta)));

    let zoomVariables = {
      from: start,
      to: end,
    };

    this.internalDataService.setZoomedIntervalVariables(zoomVariables);
  }

  getUnzoomedBucket() {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let delta = moment(this.interval.end).diff(moment(this.interval.start)) * 0.5;

    let start = moment(this.interval.start).subtract(delta);
    let end = moment(this.interval.end).add(delta);
    let now = moment.utc();

    if (end > now) {

      // Shift the unzoomed interval of an offset = end - now

      start = start.subtract(end.diff(now));
      end = now;

      this.interval.enabledPolling = true;
    } else {
      this.interval.enabledPolling = false;
    }

    start = JSON.parse(JSON.stringify(start));
    end = JSON.parse(JSON.stringify(end));

    let zoomVariables = {
      from: start,
      to: end,//eventData['xaxis.range[1]'],
    };

    this.internalDataService.setZoomedIntervalVariables(zoomVariables);
  }

  getPreviousBucket() {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start)) / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).subtract(duration)));
    let end = JSON.parse(JSON.stringify(moment(this.interval.end).subtract(duration)));

    let zoomVariables = {
      from: start,
      to: end,//eventData['xaxis.range[1]'],
    };

    this.internalDataService.setZoomedIntervalVariables(zoomVariables);
  }

  // Navigate to next bucket
  getNextBucket() {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) {
      // console.log(error)
    }

    let duration = moment(this.interval.end).diff(moment(this.interval.start)) / 2;
    let start = JSON.parse(JSON.stringify(moment(this.interval.start).add(duration)));
    let end = moment(this.interval.end).add(duration);

    if (end > moment.utc()) {
      end = moment.utc();
      this.interval.enabledPolling = true;
    } else {
      this.interval.enabledPolling = false;
    }


    this.interval.start = start;
    this.interval.end = JSON.parse(JSON.stringify(end));

    let zoomVariables = {
      from: start,
      to: end,//eventData['xaxis.range[1]'],
    };

    this.internalDataService.setZoomedIntervalVariables(zoomVariables);
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // INIT

  ngOnInit() {

    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
      }
    );

    this.queryParams = this.route.snapshot.queryParams;

    if (this.queryParams.hasOwnProperty('variablesTimeseriesMode')) {
      this.backButton = [this.machineId, "program-traceability", "detail", this.queryParams];
      this.internalDataService.setBackButton(this.backButton);
    }
    // let addZ = 'Z';
    let addZ = '';

    // let newBreadcrumb = Object.assign([], this.breadcrumb);
    // newBreadcrumb.push(this.machineId);
    // newBreadcrumb.push(this.programId);
    // this.internalDataService.setBreadcrumb(newBreadcrumb);

    this.route.params.subscribe(
      (params: Params) => {
        this.machineId = params['machineId']
        this.queryParams = this.route.snapshot.queryParams;

        if (this.queryParams.hasOwnProperty('variablesTimeseriesMode')) {
          try {

            this.breadcrumb = ['PROGRAM_TRACEABILITY.TITLE', 'CONTINUOUS_EXPLORATION.TITLE']
            let newBreadcrumb = Object.assign([], this.breadcrumb);
            newBreadcrumb.push(this.machineId);
            newBreadcrumb.push(this.queryParams.id);
            this.internalDataService.setBreadcrumb(newBreadcrumb);

            this.tabs = this.internalDataService.getPageTabs('programTraceabilityDetail');
            try {
              this.tabs.forEach((tab: any) => tab.urlQueryParams = this.queryParams)
            } catch (err) { }

          } catch (error) {
            console.log(error);
          }

        }

      }

      // this.internalDataService.setCycleSelected(pageUrl);
    )

    this.dispatcherService.getDispatch(this, 300);

  }

  generateComponentInHtml(val) {
    const componentRef = this.containerRef.createComponent(PlotlyChartComponent);
    componentRef.instance.widget = val;
    this.componentsRef[val.id] = componentRef;
  }


  async generatePDF() {

    // console.log('generatePDF');

    // // // // // // // // // // // // //
    // PLOTS
    // // // // // // // // // // // // //
    // Get plot images from the cache
    let plotImages = this.cacheService.get(`plot-${this.plotId}-image`)?.map((x, i) => {
      let pageBreak = i != 0;
      let options: any = {
        text: x.id,
        style: 'subheader'
      }
      if (pageBreak) options.pageBreak = 'before'
      return [options, { image: x.image }]
    }).flat();

    // console.log({ plotImages });

    // // // // // // // // // // // // //
    // BATCH INFO TABLE
    // // // // // // // // // // // // //

    let batchInfos = {
      start: this.intervalService.strFormat(null, this.interval.start, this.machine.timezone, "YYYY-MM-DD HH:mm:ss"),
      end: this.intervalService.strFormat(null, this.interval.end, this.machine.timezone, "YYYY-MM-DD HH:mm:ss"),
      totProd: 5000,
      totPlanned: 5500,
      description: "Pharmacelan gusseted peelable bag 150 x 300 mm (LxH) - Confezione da 1800pz",
      code: "PH22001",
      batch: "1234",
      odl: " - ",
      bom: " - ",
      sigla: " - "
    };

    let batchTableInfos = [
      {
        variable: "description",
        label: "Descrizione"
      },
      {
        variable: "code",
        label: "Codice"
      },
      {
        variable: "batch",
        label: "Lotto"
      },
      {
        variable: "start",
        label: "Data inizio produzione"
      },
      {
        variable: "end",
        label: "Data fine produzione"
      },
      {
        variable: "odl",
        label: "Nr. ODL"
      },
      {
        variable: "bom",
        label: "Verifica distinta base"
      },
      {
        variable: "sigla",
        label: "Sigla"
      },
      {
        variable: "totProd",
        label: "Pezzi Prodotti"
        // label: "Produced pieces"
      },
      {
        variable: "totPlanned",
        label: "Pezzi pianificati"
        // label: "Planned pieces"
      }
    ];

    let parsedBatchInfos = batchTableInfos?.map(info => {

      return [
        {
          text: this.translate.instant(info?.label ?? ''),
          style: "tableHeader"
        },
        {
          text: batchInfos?.[info?.variable] ?? '',
          style: "tableCell"
        }
      ]
    });

    let bodyBatchInfos = parsedBatchInfos;

    // console.log({ parsedAlarms });

    // // // // // // // // // // // // //
    // ALARMS TABLE
    // // // // // // // // // // // // //
    // let alarms = this.events.list?.filter(x => x.eventId == 1 || x.eventId == 2);
    // let tableInfos = this.unparsedTableInfos?.filter(x => !x.hideInExportPdf) ?? [];

    // let parsedTableInfos = tableInfos?.reduce((acc, val) => {
    //   acc.push({
    //     text: this.translate.instant(val?.label ?? ''),
    //     style: "tableHeader"
    //   })
    //   return acc;
    // }, []) ?? [];

    // let parsedAlarms = alarms?.reduce((rows, row) => {
    //   let pRow = tableInfos?.map(info => {
    //     // console.log({});

    //     return {
    //       text: row?.[info?.variable] ?? '',
    //       style: "tableCell"
    //     }
    //   });
    //   rows.push(pRow);
    //   return rows;
    // }, []) ?? [];

    // console.log({ parsedAlarms });

    // // // // // // // // // // // // //
    // PARAMETERS TABLE
    // // // // // // // // // // // // //
    let parameterChanges = this.events.list?.filter(x => x.eventId == "historyParams");

    let eventsTableInfos = this.eventsTableInfos?.filter(x => !x.hideInExportPdf) ?? [];

    let parsedEventsTableInfos = eventsTableInfos?.reduce((acc, val) => {
      acc.push({
        text: this.translate.instant(val?.label ?? ''),
        style: "tableHeader"
      })
      return acc;
    }, []) ?? [];

    let parsedParamChanges = parameterChanges?.reduce((rows, row) => {
      let pRow = eventsTableInfos?.map(info => {
        // console.log({});

        let val = row?.[info?.variable];

        if (Array.isArray(val) && val?.length > 1) val = val[0];
        val = val ?? ' - ';

        return {
          text: val,
          style: "tableCell"
        }
      });
      rows.push(pRow);
      return rows;
    }, []) ?? [];

    console.log({ parsedParamChanges });

    // // // // // // // // // // // // //
    // PARAMETERS TABLE
    // // // // // // // // // // // // //
    let outOfThresholdVariables: any = this.clonerService.deepClone(this.outOfThresholdVariables);

    let outOfThresholdTableInfos = this.outOfThresholdTableInfos ?? [];

    let parsedoutOfThresholdTableInfos = outOfThresholdTableInfos?.reduce((acc, val) => {
      acc.push({
        text: this.translate.instant(val?.label ?? ''),
        style: "tableHeader"
      })
      return acc;
    }, []) ?? [];

    let parsedThresholds = outOfThresholdVariables?.reduce((rows, row) => {
      let pRow = outOfThresholdTableInfos?.map(info => {
        console.log({ info }, { row });

        let val = row?.[info?.variable];

        if (Array.isArray(val) && val?.length > 1) val = val[0];
        val = val ?? ' - ';

        return {
          text: val,
          style: "tableCell"
        }
      });
      rows.push(pRow);
      return rows;
    }, []) ?? [];

    console.log({ parsedThresholds });

    // // // // // // // // // // // // //
    // ff-table-sortable
    // // // // // // // // // // // // //
    // console.log(this.dashboardConfig);
    // let table = this.dashboardConfig.widgets?.find(x => x.type == "ff-table-sortable");

    // let rows = table?.config?.rows ?? [];
    // let tableInfos = table?.config?.tableInfos ?? [];
    // let parsedExport = table?.config?.parsedExport ?? {};

    // let parsedTableInfos = (Object.values(parsedExport?.translations ?? []) as any)?.reduce((acc, val) => {
    //   acc.push({
    //     text: val ?? '',
    //     style: "tableHeader"
    //   })
    //   return acc;
    // }, []) ?? [];

    // let parsedRows = parsedExport?.rows?.reduce((rows, row) => {
    //   let pRow = (Object.keys(parsedExport?.translations ?? []) as any)?.map(info => {
    //     return {
    //       text: row?.[info] ?? '',
    //       style: "tableCell"
    //     }
    //   });
    //   rows.push(pRow);
    //   return rows;
    // }, []) ?? [];

    // console.log({ parsedExport });


    // Get image
    let resp: any = null;
    try { resp = await this.apiService.sendGetRequestAwait("assets/images/logo-01.png", null, "arraybuffer") }
    catch (error) { console.log(error) }

    // console.log(resp);
    const imageData = resp?.body;

    // let bodyAlarms = [parsedTableInfos, ...parsedAlarms];
    let bodyParamChanges = [parsedEventsTableInfos, ...parsedParamChanges];
    let bodyThresholds = [parsedoutOfThresholdTableInfos, ...parsedThresholds];

    // console.log({ bodyBatchInfos });


    // console.log({ bodyParamChanges });

    const headerColumnWidth = 250;
    let widths = parsedoutOfThresholdTableInfos.map(x => headerColumnWidth);

    const docDefinition = {
      defaultStyle: {

      },
      pageSize: {
        width: Math.max(parsedoutOfThresholdTableInfos?.length * (headerColumnWidth + 20), 850),
        // width: Math.max(5000, 850),
        // width: 800,
        // width: 1200,
        // width: 612,
        height: 792
        // height: 600
      },
      pageMargins: [40, 60, 40, 60],

      header: function (currentPage, pageCount) {
        return {
          columns: [
            { image: imageData, height: 30, fit: [NaN, 30], alignment: 'left' },
            { text: new Date().toLocaleDateString(), alignment: 'right', style: 'subheader' }
          ]
        };
      },
      content: [
        { text: `${this.translate.instant("GLOBAL.BATCH_REPORT")} - Lotto 1234`, style: 'header' },
        // { text: "Informazioni Lotto", style: 'subheader' },
        {
          table: {
            headerRows: 1,
            // widths: widths,
            body: bodyBatchInfos,
          },
          layout: tableLayout
        },
        { text: "", style: 'marginDiv' },
        { text: 'Evoluzione Variabili', style: "header", pageBreak: 'before' },
        ...plotImages,
        // htmlImage,
        // htmlToPdfmake(elem),
        // { text: this.translate.instant("GLOBAL.ALARMS_TABLE"), style: 'subheader', pageBreak: 'before' },
        // {
        //   table: {
        //     headerRows: 1,
        //     widths: widths,
        //     body: bodyAlarms,
        //   },
        //   layout: tableLayout
        // },
        { text: this.translate.instant("GLOBAL.OUT_OF_THRESHOLD_TABLE"), style: 'subheader', pageBreak: 'before' },
        {
          table: {
            headerRows: 1,
            widths: widths,
            body: bodyThresholds,
          },
          layout: tableLayout
        },
        { text: this.translate.instant("GLOBAL.PARAMETER_CHANGES_TABLE"), style: 'subheader', pageBreak: 'before' },
        {
          table: {
            headerRows: 1,
            widths: widths,
            body: bodyParamChanges,
          },
          layout: tableLayout
        }
      ],
      styles: {
        marginDiv: {
          margin: [16, 16, 16, 16]
        },
        header: {
          fontSize: 24,
          bold: true,
          margin: [8, 8, 8, 8]
        },
        subheader: {
          fontSize: 18,
          bold: true,
          margin: [8, 8, 8, 8]
        },
        tableHeader: {
          bold: true,
          margin: [8, 8, 8, 8],
          fontSize: 10,
          alignment: 'center',
          color: 'black'
        },
        tableCell: {
          // fillColor: '#eee',
          margin: [8, 8, 8, 8],
          fontSize: 10,
          alignment: 'center'
        }

      }
    };
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.download('Batch report - Batch Id 1234.pdf');
  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //
  // DESTROY

  ngOnDestroy() {
    try { this.pageState.unsubscribe() } catch (error) { }
    try { this.pollingEvents.unsubscribe() } catch (error) { }
    try { this.machineSelectedSub.unsubscribe() } catch (error) { }
    try { this.internalDataService.setBackButton([]) } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
    try { this.internalDataService.setZoomedIntervalVariables(null) } catch (error) { }
    try { this.zoomedIntervalVariablesSub.unsubscribe() } catch (error) { }
  }

}

const tableLayout = {
  hLineWidth: function (i, node) {
    return i === 0 || i === node.table.body.length ? 2 : 1;
  },
  vLineWidth: function (i, node) {
    return 1;
  },
  hLineColor: function (i, node) {
    return i === 0 || i === node.table.body.length ? '#ccc' : '#ddd';
  },
  fillColor: function (i, node) {
    return i === 0 ? '#f2f2f2' : null;
  },
  margin: [8, 8, 8, 8],
  fontSize: 10,
};