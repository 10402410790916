import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { catchError, retryWhen } from 'rxjs/operators';
import { FfTranslateService } from 'src/app/services/ff-translate.service';

import { MatDialog } from '@angular/material/dialog';
import { AggregationsDialogComponent } from 'src/app/components/aggregations-dialog/aggregations-dialog.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { DispatcherService } from 'src/app/services/dispatcher.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';
import { IntervalService } from 'src/app/services/interval.service';
import { MobileService } from 'src/app/services/mobile.service';

@Component({
  selector: 'app-changeover',
  templateUrl: './changeover.component.html',
  styleUrls: ['./changeover.component.scss']
})
export class ChangeoverComponent implements OnInit, OnDestroy {

  public isAllowedUser: boolean = true;

  public loadingData: any;
  public errorData: any;

  public appConfig: any;
  public appInfo: any;
  public isMobile: any;
  public isSmThanTablet: any;
  public mobileListener: Subscription;
  public machineProfiles: any;

  public breadcrumb: any;
  public tabs: any;

  public machineId: any;
  public machineSelectedSub: Subscription;
  public machine: any;

  public availableMachines: any;
  public machineSelectedId: any;

  public pollingTime: any;
  public pollingEvents: any;

  public maxStringsLength: any;
  public aggrDropdown: any = null;
  public aggregations: any;
  public aggregationsPayload: any;
  public aggregationChangeovers: any;

  public excludeLine: boolean = true;

  public interval: any;
  public intervalConfig: any;

  public dashboardConfig: any;
  public completeDashboardConfig: any;

  public dashboardData: any;

  public mobileData: any;
  public currentSortingProperty: any;
  public sortDirection: any;
  public collapsed: any;

  public tabName: any = "changeover";
  public sectionName: any = "leanAnalytics";
  public dashboardName: any = "changeover";
  public colorsArrayName: any = "signalationsColors";
  public tablePropName: any = "signalationsInfo";
  public pollingPropName: any = "pollingSignalations";
  public dashboardNameComplete: any;

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DISPATCHER

  public pageState: BehaviorSubject<number> = new BehaviorSubject(1);
  public pageStateReady: number = 6;
  public pageStates: any = [
    {
      state: 0,
      codes: [
        { code: 300, function: null, nextState: 1 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 1,
      codes: [
        { code: 300, function: this.internalDataService.getUserData, nextState: 2, loadingMsg: 'LOADING.USER' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 2,
      codes: [
        { code: 300, function: this.getDashboard, nextState: 3, loadingMsg: 'LOADING.DASHBOARD_CONFIG' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 3,
      codes: [
        { code: 300, function: this.getAssetInfo, nextState: 4, loadingMsg: 'LOADING.MACHINE_INFO' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 4,
      codes: [
        { code: 300, function: this.getEventsBreakdowns, nextState: 5, loadingMsg: 'GLOBAL.LOADING' },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    },
    {
      state: 5,
      codes: [
        { code: 300, function: this.dispatcherService.completeDispatch, nextState: 6 },
        { code: 301, function: this.dispatcherService.errorDispatch, nextState: 0 }
      ]
    }
  ];

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // CONSTRUCTOR

  constructor(
    public appConfigService: AppConfigService,
    public apiService: ApiService,
    public dispatcherService: DispatcherService,
    public internalDataService: InternalDataService,
    public filterService: FiltersService,
    public translate: FfTranslateService,
    public route: ActivatedRoute,
    public intervalService: IntervalService,
    public dialog: MatDialog,
    private clonerService: ClonerService,
    private cacheService: CacheService,
    public mobile: MobileService
  ) {

    this.mobileListener = this.mobile.mobileListener.subscribe((value: any) => {
      this.isMobile = value.isMobile;
      this.isSmThanTablet = value.isSmThanTablet
    })

    // this.pageState.subscribe((value) => console.log('pageState.subscribe', value));

    this.appConfig = this.appConfigService.getAppConfig;
    this.appInfo = this.appConfigService.getAppInfo;
    this.machineProfiles = this.appConfigService.getMachineProfiles;

    this.breadcrumb = ['LEAN_ANALYTICS.TITLE', 'CHANGEOVER.TITLE'];
    this.internalDataService.setBreadcrumb(this.breadcrumb);

    this.tabs = this.internalDataService.getPageTabs(this.sectionName);

    this.machineSelectedSub = this.internalDataService.machineSelected.subscribe(value => {
      if (Object.keys(value).length != 0) {
        let newBreadcrumb: any = this.clonerService.deepClone(this.breadcrumb);
        newBreadcrumb.push(value.machineName);
        this.internalDataService.setBreadcrumb(newBreadcrumb);
      }
    });

    this.pollingTime = this.appConfig?.[this.tabName]?.[this.pollingPropName] ?? 0;
    this.pollingEvents = Subscription;

    this.dashboardData = null;

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // OPEN AGGR DIALOG

  openAggrDialog(aggr: any) {

    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }

    let filtersDialog = this.dialog.open(AggregationsDialogComponent, {
      panelClass: 'ff-dialog',
      data: {
        title: this.translate.instant(aggr.label),
        aggrId: aggr.id,
        machine: this.clonerService.deepClone(this.machine),
        machineId: this.machineId,
        aggregations: this.aggregations,
        machineReference: this.machine.machineReference,
        machineSelected: this.availableMachines ? this.availableMachines.selected : null,
        interval: JSON.parse(JSON.stringify(this.interval))
      },
    });

    filtersDialog.afterClosed().subscribe((result: any) => {

      let isClickedSelect = result != null && result != '';
      if (isClickedSelect) {
        // console.log('afterClosed', result);
        result = JSON.parse(JSON.stringify(result));
        aggr.selected = this.clonerService.deepClone(result.selected);
      }

      this.pageState.next(isClickedSelect ? 5 : 6);

      if (!this.interval.enabledPolling) {
        this.getEvents(this, 0);
      } else {
        this.getEventsBreakdowns(this);
      }

    });
  };

  changePageAggregation(aggrDropdown: any) {
    this.aggrDropdown = this.clonerService.deepClone(aggrDropdown);

    this.pageState.next(5);
    if (!this.interval.enabledPolling) {
      this.getEvents(this, 0);
    } else {
      this.getEventsBreakdowns(this);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET ASSET INFO

  getAssetInfo(_this: any) {

    try {
      _this.isAllowedUser = _this.internalDataService.getSpecificPermission("mat-la-changeover");
    } catch (error) { console.log(error) }

    if (!_this.isAllowedUser) {

      let isCachedMachineId = _this.cacheService.get("machineId");
      if (isCachedMachineId == null) {
        _this.internalDataService.setMachineSelected({ machineId: _this.machineId });
        _this.tabs = _this.internalDataService.getPageTabs(_this.sectionName);
      }

      let testError = {
        type: 0,
        status: 401,
        message: _this.translate.instant("GLOBAL.INSUFFICIENT_PERMISSIONS")
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    } else {
      try {
        _this.internalDataService.getMachineInfo(_this, _this.machineId, _this.machineProfiles, null, _this.sectionName);
      } catch (error) {
        let testError = {
          type: 0,
          status: 500,
          message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
        };
        _this.dispatcherService.getDispatch(_this, 301, testError);
      }
    }

  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // interval
  selectInterval(interval: any) { this.intervalService.selectInterval(this, interval, this.pollingEvents, this.getEventsBreakdowns, this.getEvents, this.machine.timezone) };

  machineSelectionChange(machine: any) {

    if (machine != null) {

      try {
        this.pollingEvents.unsubscribe();
      } catch (error) {
      }

      this.pageState.next(5);
      this.getEventsBreakdowns(this);

    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // GET DASHBOARD

  public getDashboard(_this: any) {
    try {
      _this.internalDataService.getDashboard(_this, _this.machineId, _this.dashboardName);
    } catch (error) {
      let testError = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, testError);
    }
  }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // events

  // polling
  getEventsBreakdowns(_this: any) {
    try {

      if (_this.cacheService.get("intervalLong") == null) {

        _this.interval = _this.intervalService.getIntervalById('last30Days', _this.machine.timezone);

        _this.intervalConfig = {
          list: _this.intervalService.getDefaultIntervals(2, _this.machine.timezone),
          selected: _this.interval
        };

        _this.cacheService.set("intervalLong", _this.intervalConfig);

      } else {
        _this.intervalConfig = _this.cacheService.get("intervalLong");
        _this.interval = _this.cacheService.get("intervalLong").selected;
      }

      if (_this.pollingTime > 0) {
        _this.pollingEvents = timer(0, _this.pollingTime).subscribe((count) => {
          _this.getEvents(_this, count);
        });
      } else {
        _this.getEvents(_this, 0);
      }

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  // get events
  getEvents(_this: any, count?: any) {
    try {

      let url = '/apif/production-analytics/changeover/' + _this.machineId;

      // let payload = _this.internalDataService.buildMachinePayload(_this.machine);
      // _this.internalDataService.buildAggregationsPayload(_this);
      // payload.filters = _this.aggregationsPayload;

      let query: any = {
        from: _this.interval.start,
        tz: _this.machine.timezone,
      };

      if (_this.aggrDropdown == null) {
        _this.aggrDropdown = _this.aggregations?.[0]?.id;
      }
      if (_this.aggrDropdown != null) query.aggrId = _this.aggrDropdown;

      if (_this.interval != null && !_this.interval.enabledPolling) {
        query.to = _this.interval.end;
      }

      _this.internalDataService.setMachineDropdownSelected(_this.availableMachines, _this.machine.machineReference, query);

      _this.apiService.sendGetRequest(url, query)
        .pipe(
          retryWhen(_this.apiService.genericRetryStrategy({ maxRetryAttempts: 0 })),
          catchError(error => _this.internalDataService.parseStandardHTTPError(_this, error)))
        .subscribe(
          (data: any) => {
            // console.log(data);

            _this.dashboardData = _this.clonerService.deepClone(data.body);
            _this.filterElements();

            if (count == 0) _this.dispatcherService.getDispatch(_this, 300);
          }
        );

    } catch (error) {
      let errorData = {
        type: 0,
        status: 500,
        message: (error.error instanceof ErrorEvent) ? error.error.message : error.message
      };
      _this.dispatcherService.getDispatch(_this, 301, errorData);
    }
  }

  filterElements() {


    this.completeDashboardConfig = {
      dashboardData: this.clonerService.deepClone(this.dashboardData),
      machineProfile: this.machine.profile,
      dashboardConfig: this.dashboardConfig,
      aggregations: this.aggregations,
      dashboardType: "centralPlotLateralWidgets",
    };


    let keysToExclude: any = Object?.keys(this.completeDashboardConfig).concat("completeDashboardConfig").concat([
      'route',
      'router',
      'translate',
      'dialog',
      '__ngContext__'
    ]);
    let refComp: any = Object?.entries(this)?.filter(kv => !keysToExclude.includes(kv[0]) && !kv[0].toLocaleLowerCase().endsWith("service"))?.reduce((acc, val) => {
      try { acc[val[0]] = val[1] } catch (error) { }
      return acc;
    }, {})

    this.completeDashboardConfig.referenceComponent = refComp;

    // console.log(this.completeDashboardConfig);
  }
  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // INIT

  ngOnInit() {
    this.machineId = this.route.snapshot.params['machineId'];
    this.route.params.subscribe((params: Params) => this.machineId = params['machineId']);
    this.dispatcherService.getDispatch(this, 300);
  }

  ngOnChanges() { }

  // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // // //  
  // DESTROY
  ngOnDestroy() {
    try {
      this.pageState.unsubscribe();
    } catch (error) { }
    try {
      this.pollingEvents.unsubscribe();
    } catch (error) { }
    try {
      this.machineSelectedSub.unsubscribe();
    } catch (error) { }
    try { this.mobileListener.unsubscribe() } catch (error) { }
  }

}