import { Component, Input, OnInit } from '@angular/core';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'recursive-list',
  templateUrl: './recursive-list.component.html',
  styleUrls: ['./recursive-list.component.scss']
})
export class RecursiveListComponent implements OnInit {

  @Input() hierarchy: any[];
  @Input() items: any[];

  constructor(public internalDataService: InternalDataService) { }

  ngOnInit() {
    this.updateSelectedItems(this.hierarchy);
  }

  setComponentSelected() {
    this.internalDataService.setOnMultiAssetSelection(true);
  }

  updateSelectedItems(elements) {
    for (const element of elements) {
      if (element.elements) {
        this.updateSelectedItems(element.elements);
        element.selected = element.elements.some(child => child.selected);
      }
    }
  }

  setSelected(item: any, value?) {
    let selected = value ?? !item.selected;

    item.selected = selected;
    if (item.elements) {
      for (const nestedElement of item.elements) {
        this.setSelected(nestedElement, selected);
      }
    }

    this.updateSelectedItems(this.hierarchy);

    this.setComponentSelected();
  }

  getPaddingLeft(level: number): string {
    const basePadding = 0 // Adjust this value based on your styling needs
    const paddingIncrement = 12; // Adjust this value based on your styling needs

    const totalPadding = basePadding + (level - 1) * paddingIncrement;
    return `${totalPadding}px`;
  }

}
