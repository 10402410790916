<div *ngIf="availableMachines != null && availableMachines.list != null && availableMachines.list.length > 1"
  style="margin-left: 8px;" class="select-box" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
  fxLayoutAlign.gt-sm="start center">

  <div class="box" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="icon">timeline</mat-icon>
    <mat-form-field appearance="fill">
      <mat-label>{{ 'GLOBAL.MACHINE_SELECTION' | translate }}</mat-label>
      <mat-select [(value)]="availableMachines.selected">
        <mat-option *ngFor="let machine of availableMachines.list" [value]="machine"
          [disabled]="checkDisability(machine)" (click)="machineSelectionChange(machine)">
          {{ internalDataService.parseLabelWithAssetId("MACHINES", machine, null, machineId) }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>