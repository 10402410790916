<div mat-dialog-title fxLayout="row" class="w100">
  <span>{{ paramBox.selectionLabel }}</span>
  <span fxFlex></span>
  <button mat-dialog-close class="close">
    <mat-icon class="icon" aria-label="close">close</mat-icon>
  </button>
</div>
<mat-dialog-content>

  <div *ngIf="aggrListState == 0" class="h100 w100">
    <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
      <ff-loading-card></ff-loading-card>
    </div>
  </div>

  <div *ngIf="aggrListState == 2" class="h100 w100">
    <div fxLayout="row" fxLayoutAlign="center center" class="h100 w100">
      <span style="font-weight: 800; margin: 16px;">{{ paramBox.notFoundLabel }}</span>
    </div>
  </div>

  <div *ngIf="aggrListState == 1 && paramBox.list != null && paramBox.list.length > 0">

    <ul *ngIf="paramBox.list.length > 1">
      <li fxLayout="row" fxLayoutAlign="start center" class="clickable" (click)="toggleAll()">
        <mat-icon *ngIf="allSelected" svgIcon="radio_button" class="icon left radio_button"></mat-icon>
        <mat-icon *ngIf="!allSelected" svgIcon="radio_button_disabled" class="icon left radio_button_disabled">
        </mat-icon>
        <span>{{'GLOBAL.SELECT_ALL'|translate}}</span>
      </li>
    </ul>

    <ul>
      <li *ngFor="let item of paramBox.list" fxLayout="row" fxLayoutAlign="start center" class="clickable"
        (click)="toggle(item, paramBox.selected)">
        <mat-icon *ngIf="exists(item, paramBox.selected)" svgIcon="radio_button" class="icon left radio_button"
          [ngClass]="item.class">
        </mat-icon>
        <mat-icon *ngIf="!exists(item, paramBox.selected)" svgIcon="radio_button_disabled"
          class="icon left radio_button_disabled">
        </mat-icon>
        <span fxFlex>{{item}}</span>
        <mat-icon class="icon" *ngIf="item.color" [ngStyle]="{ 'color': item.color }">circle</mat-icon>
      </li>
    </ul>

  </div>

  <div fxLayout="row" fxLayoutAlign="end end">
    <button mat-button [mat-dialog-close]="paramBox" class="filter-button"
      [disabled]="paramBox == null || paramBox.selected == null|| paramBox.selected.length == 0">
      {{'GLOBAL.SELECT'|translate}}
    </button>
  </div>

</mat-dialog-content>