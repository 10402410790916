<!-- error & loading -->
<div *ngIf="pageState.value >= 0 && pageState.value < pageStateReady" fxLayout="column" fxLayoutAlign="center center"
  class="w100 h100">
  <!-- error -->
  <ff-error-card *ngIf="pageState.value == 0" [errorData]="errorData"></ff-error-card>
  <!-- loading -->
  <ff-loading-card *ngIf="pageState.value > 0 && pageState.value < pageStateReady" [loadingData]="loadingData">
  </ff-loading-card>
  <!-- utils -->
  <!-- <pre>state [{{pageState.value}}] | ready [{{pageStateReady}}]</pre> -->
</div>

<!-- content -->
<div *ngIf="pageState.value >= pageStateReady" fxLayout="column" fxLayoutAlign="center start" class="w100 h100">

  <!-- toolbar -->
  <div fxLayout="column" fxLayoutAlign="start start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center">
    <!-- tabs -->
    <ff-tabs [tabs]="tabs"></ff-tabs>
  </div>

  <!-- tab content -->
  <div fxFlex fxLayout="column" fxLayoutAlign="start center" class="tab-content">

    <!-- toolbar -->
    <div class="ff-toolbar pb-2" fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row"
      fxLayoutAlign.gt-sm="start center">

      <button class="mat-button rounded-button" (click)="getPreviousBucket()" style="margin-right: 8px;"
        #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.PREVIOUS_BUCKET' | translate }}"
        matTooltipPosition="above">
        <mat-icon svgIcon="arrow_left" class="icon"></mat-icon>
      </button>
      <button class="mat-button rounded-button" (click)="getNextBucket()" [disabled]="interval.enabledPolling"
        #tooltip="matTooltip" matTooltip="{{ 'CYCLE_TIMELINE.NEXT_BUCKET' | translate }}" matTooltipPosition="above">
        <mat-icon svgIcon="arrow_right" class="icon"></mat-icon>
      </button>
      <!-- <button class="mat-button button-1" (click)="getNextBucket()" [disabled]="interval.enabledPolling">next</button> -->

      <div style="margin-left: 12px;" fxLayout="row" fxLayoutAlign="start center">
        <span style="font-weight: 800;">{{ 'STATE_TIMELINE.CATEGORIES' | translate }}:</span>
        <div *ngFor="let category of machine.profile.categories" fxLayout="row" fxLayoutAlign="start center" style="margin-right: 8px;">
          <span [style.background-color]="category.color" class="color-square"></span>
          <span>{{ category.label | translate }}</span>
        </div>
      </div>

      <span fxFlex></span>

      <!-- intervals -->
      <ff-interval-selector [config]="intervalConfig" (selected)="selectInterval($event)"></ff-interval-selector>

    </div>

    <!-- loading -->
    <ff-loading-card *ngIf="pageState.value < pageStateReady" [loadingData]="loadingData" style="margin: auto;">
    </ff-loading-card>

    <!-- chart -->
    <div *ngIf="pageState.value >= 5 && ganttState == 1" style="border-radius: $radius-2; background-color: #fff;"
      class="w100">
      <apx-chart class="w100" [chart]="chartOptions.chart" [plotOptions]="chartOptions.plotOptions"
        [dataLabels]="chartOptions.dataLabels" [xaxis]="chartOptions.xaxis" [tooltip]="chartOptions.tooltip"
        [yaxis]="chartOptions.yaxis" [grid]="chartOptions.grid" [series]="chartOptions.series"
        [annotations]="chartOptions.annotations"></apx-chart>
    </div>

    <div *ngIf="pageState.value >= 5 && ganttState == 2" fxLayout="row" fxLayoutAlign="center center" class="w100"
      style="margin-top: 12px;">
      <div class="not-found" fxLayout="row" fxLayoutAlign="center center">
        <span>{{ 'GLOBAL.NO_DATA_AVAILABLE' | translate }}</span>
      </div>
    </div>

  </div>

</div>
