import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CustomSearchFilterPipe } from 'src/app/pipes/custom-search-filter.pipe';
import { AppConfigService } from 'src/app/services/app-config.service';
import { CacheService } from 'src/app/services/cache.service';
import { ClonerService } from 'src/app/services/clone.service';
import { FfTranslateService } from 'src/app/services/ff-translate.service';
import { FiltersService } from 'src/app/services/filters.service';
import { InternalDataService } from 'src/app/services/internal-data.service';

@Component({
  selector: 'multi-asset-selection-dialog',
  templateUrl: './multi-asset-selection-dialog.component.html',
  styleUrls: ['./multi-asset-selection-dialog.component.scss']
})
export class MultiAssetSelectionDialogComponent implements OnInit {

  public machineId: any;
  public appConfig: any;
  public hierarchy: any = [];
  public assets: any = [];

  availableOptions: any;
  availableOptionsVariablesConfig: any = ["id", "label", "unit", "group"];
  customSearchFilterPipe: CustomSearchFilterPipe;

  selectedAssets: any = [];

  public onMultiAssetSelectionSub: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog: any,
    public dialogRef: MatDialogRef<MultiAssetSelectionDialogComponent>,
    public translate: FfTranslateService,
    public internalDataService: InternalDataService,
    public cacheService: CacheService,
    public filterService: FiltersService,
    public appConfigService: AppConfigService,
    public clonerService: ClonerService,
  ) {

    this.internalDataService.setOnMultiAssetSelection(false);

    this.appConfig = this.appConfigService.getAppConfig;

    if (this.cacheService.get("machineId") != null) this.machineId = this.cacheService.get("machineId");

    this.assets = this.cacheService.get("assets");

    this.hierarchy = this.clonerService.deepClone(this.dialog.data ?? []);

    this.customSearchFilterPipe = new CustomSearchFilterPipe(this.filterService);

    this.selectedAssets = this.dialog.selectedAssets ?? this.selectedAssets;

    this.onMultiAssetSelectionSub = this.internalDataService.onMultiAssetSelection.subscribe(value => {
      if (value) this.createSelectedAssets();
    });
  }

  ngOnInit() {
    this.parseHierarchy(this.hierarchy, 1);
  }

  // Function that inserts the hierarchic level and the assetId translation for every element
  parseHierarchy(elements: any[], level: number) {
    for (let i = elements.length - 1; i >= 0; i--) {
      const element = elements[i];
      element.level = level;

      let assetConfig = this.assets.find(x => x.machineId == element.id);

      if (element.label == null && assetConfig != null) {
        element.label = assetConfig.machineName;
      }

      let isSelectedAsset = this.selectedAssets.find(x => x.id == element.id) != null;
      if (isSelectedAsset) element.selected = true;

      // Hierarchic element that is NOT an asset
      if (element.elements) {
        this.parseHierarchy(element.elements, level + 1);
      }

      // Asset
      else {
        // If the asset is not present in the complete list (no authorizations),
        // remove it from the hierarchy
        if (assetConfig == null) elements.splice(i, 1);
      }
    }
  }

  searchFromList(searchEvent) {
    this.availableOptions = searchEvent.target.value;
  }

  closeDialog() {
    this.createSelectedAssets();
    this.dialogRef.close(this.selectedAssets);
  }

  createSelectedAssets() {
    this.selectedAssets = [];
    this.parseSelectedAssets(this.hierarchy);
  }

  parseSelectedAssets(elements) {
    for (const element of elements) {
      if (element.elements) {
        this.parseSelectedAssets(element.elements);
      }
      else {
        if (element.selected) {
          let existsElement = this.selectedAssets.find(x => x.id == element.id) != null;
          if (!existsElement) {
            this.selectedAssets.push({
              id: element.id,
              label: element.label
            });
          }
        }
      }
    }

  }

  ngOnDestroy() {
    try { this.onMultiAssetSelectionSub.unsubscribe() } catch (error) { }
  }

}
